<script>
export default {
    props: {
        breakpoint: {
            type: Number,
            required: true,
        },
    },

    data() {
        return { isActive: false };
    },

    created() {
        this.mql = window.matchMedia(`(min-width: ${this.breakpoint}px)`);
        this.mql.addListener(this.onChange);
        this.isActive = this.mql.matches;
    },

    beforeDestroy() {
        this.mql.removeListener(this.onChange);
    },

    methods: {
        onChange(e) {
            this.isActive = e.matches;
        },
    },

    render() {
        return this.$scopedSlots.default({
            breakpoint: this.breakpoint,
            isActive: this.isActive,
        });
    },
};
</script>
