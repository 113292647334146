<template lang="html">
    <div
        v-if="loaded"
        data-qa="organize-stage"
        class="organize-stage"
    >
        <h2 class="secondary-title mega">
            {{ headingText }}
        </h2>
        <h5>{{ subHeadingText }}</h5>

        <pro-tip>
            <template #content>
                <div class="upload-file-info">
                    <div v-if="importFileExtension" class="file-icon">
                        {{ importFileExtension }}
                    </div>

                    {{ importInProgress.fileName }}

                    <span class="contact-count">
                        {{ $t('proTipText', { contactCount: importInProgress.contactCount }) }}
                    </span>
                </div>
            </template>
        </pro-tip>

        <p v-if="hasUnmatchedFields" class="match-message">
            <ds-icon
                v-if="stageReady"
                name="check"
                class="matched-icon"
            />
            {{ $t('matched', { matchedCount: unmatchedFields.size }) }}
        </p>

        <p v-if="!hasUnmatchedFields" class="match-message">
            {{ $t('allFieldsMatched') }}
            <a @click="next">{{ $t('global.next') }}</a>
        </p>

        <div v-if="hasUnmatchedFields" class="labels">
            <span>{{ $t('columnNames') }}</span>
            <span>{{ $t('infFields', { productName: $t('global.productName') }) }}</span>
        </div>

        <div v-for="(mapping, i) in importMappings" :key="i">
            <div v-if="isUnmatched(mapping)" class="mapping">
                <ds-input-field
                    v-model="mapping.fieldToImport"
                    readonly
                >
                    <template #help>
                        <small
                            class="help-text"
                            v-text="importInProgress.previewData[mapping.fieldToImport]"
                        />
                    </template>
                </ds-input-field>

                <div class="divider">
                    =
                </div>

                <div>
                    <ds-multiselect
                        :ref="`multiselect-${mapping.fieldToImport}`"
                        v-model="mapping.fieldToMapTo"
                        bind-value-only
                        searchable
                        :force-invalid="invalidateField(mapping.fieldToMapTo).isInvalid"
                        :options="mappingOptions"
                        @input="mappingFieldSelected"
                    />

                    <small
                        v-if="invalidateField(mapping.fieldToMapTo).isInvalid"
                        class="help-text error"
                    >
                        <span v-if="isInUse(invalidateField(mapping.fieldToMapTo).reason)">{{ $t('inUse') }}</span>
                        <span v-else>{{ $t('required') }}</span>
                    </small>
                </div>
            </div>

            <div
                v-if="showAddCustomField(mapping) && isUnmatched(mapping)"
                class="add-custom-field-box"
            >
                <contact-add-custom-field
                    standalone
                    :field-count="customFieldCount"
                    :field-max="customFieldMax"
                    :create-custom-field="createCustomField"
                    :field-name="mapping.fieldToImport"
                    event-source="Contact Import"
                    @added="loadContactsCustomFields"
                    @cancel="clearActiveField"
                >
                    <template #description>
                        <i18n tag="p" path="customFields.description.main">
                            <template #remaining>
                                <strong>{{ $t('customFields.description.remaining', { customFieldsAvailable }) }}</strong>
                            </template>
                        </i18n>

                        <custom-field-status
                            :existing="customFieldCount"
                            :max="customFieldMax"
                            :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
                            :object-name="$t('customFields.objectName')"
                        />
                    </template>
                </contact-add-custom-field>
            </div>
        </div>

        <ds-collapsible>
            <template #header>
                <p class="match-message matched" @click="toggleMatchedFields">
                    {{ matchedText }}

                    <span :class="['arrow-icon', { rotated: matchedOpened }]">
                        <ds-icon name="chevron-down" />
                    </span>
                </p>
            </template>

            <div>
                <div v-for="(mapping, i) in importMappings" :key="i">
                    <div v-if="isMatched(mapping)" class="mapping">
                        <ds-input-field
                            v-model="mapping.fieldToImport"
                            readonly
                        >
                            <template #help>
                                <small
                                    class="help-text"
                                    v-text="importInProgress.previewData[mapping.fieldToImport]"
                                />
                            </template>
                        </ds-input-field>

                        <div class="divider">
                            =
                        </div>

                        <ds-multiselect
                            v-model="mapping.fieldToMapTo"
                            bind-value-only
                            searchable
                            :force-invalid="invalidateField(mapping.fieldToMapTo).isInvalid"
                            :options="mappingOptions"
                            @input="mappingFieldSelected"
                        />
                    </div>

                    <div
                        v-if="showAddCustomField(mapping) && isMatched(mapping)"
                        class="add-custom-field-box"
                    >
                        <contact-add-custom-field
                            standalone
                            :field-count="customFieldCount"
                            :field-max="customFieldMax"
                            :create-custom-field="createCustomField"
                            :field-name="mapping.fieldToImport"
                            @added="loadContactsCustomFields"
                            @cancel="clearActiveField"
                        >
                            <template #description>
                                <i18n tag="p" path="customFields.description.main">
                                    <template #remaining>
                                        <strong>{{ $t('customFields.description.remaining', { customFieldsAvailable }) }}</strong>
                                    </template>
                                </i18n>

                                <custom-field-status
                                    :existing="customFieldCount"
                                    :max="customFieldMax"
                                    :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
                                    :object-name="$t('customFields.objectName')"
                                />
                            </template>
                        </contact-add-custom-field>
                    </div>
                </div>
            </div>
        </ds-collapsible>

        <footer :class="{ 'matched-opened': matchedOpened }">
            <!-- eslint-disable vue/no-v-html -->
            <small
                class="help-text"
                v-html="$t('helpText', { url: articleBaseUrl })"
            />
            <!-- eslint-enable vue/no-v-html -->
        </footer>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ContactAddCustomField from '@/contacts/components/details/ContactAddCustomField';
import CustomFieldStatus from '@/contacts/components/customFields/CustomFieldStatus';
import ProTip from '@/import/components/csv/ProTip';

import { IMPORT_MAPPINGS } from '@/import/import.constants';
import { CUSTOM_FIELD_RECORD_TYPE_CONTACT } from '@/customFields/customFields.constants';

export default {
    CUSTOM_FIELD_RECORD_TYPE_CONTACT,

    components: {
        ContactAddCustomField,
        ProTip,
        CustomFieldStatus,
    },

    data() {
        return {
            importMappings: [],
            matchedFields: new Set(),
            unmatchedFields: new Set(),
            activeField: null,
            mappingIndexForNewCustomField: -1,
            newCustomFieldId: null,
            loaded: false,
            articleBaseUrl: process.env.VUE_APP_KNOWLEDGEOWL_URL,
        };
    },

    created() {
        this.loadContactsCustomFields();
    },

    watch: {
        importMappings: {
            handler(oldValue, newValue) {
                if (oldValue !== newValue) {
                    this.formatMatchGroups();
                }

                const existingMappings = [];
                let ready = true;

                this.importMappings.forEach(({ fieldToMapTo }) => {
                    if (fieldToMapTo !== 'DO_NOT_IMPORT' && fieldToMapTo !== IMPORT_MAPPINGS.TAG) {
                        if (fieldToMapTo === 'NO_MATCH' || existingMappings.includes(fieldToMapTo)) {
                            ready = false;
                        } else {
                            existingMappings.push(fieldToMapTo);
                        }
                    }
                });

                this.setStageReady(ready);
                this.$store.commit('contactImport/SET_IMPORT_MAPPINGS', this.importMappings);
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            stages: ({ contactImport }) => contactImport.stages,
            importInProgress: ({ contactImport }) => contactImport.importInProgress,
            contactImportMappings: ({ contactImport }) => contactImport.importMappings,
            fileboxId: ({ contactImport }) => contactImport.fileboxId,
            matchedOpened: ({ contactImport }) => contactImport.matchedOpened,
            fieldGroups: ({ contacts }) => contacts.fieldGroups,
            account: ({ auth }) => auth.account,
        }),

        ...mapGetters({
            mappingOptions: 'contactImport/mappingOptions',
            isOrganizeStage: 'contactImport/isOrganizeStage',
            customFieldCount: 'contacts/customFieldCount',
            customFieldMax: 'settings/maxCustomFields',
        }),

        customFieldsAvailable() {
            return this.customFieldMax - this.customFieldCount;
        },

        activeStage: {
            get() {
                return this.$store.state.contactImport.activeStage;
            },

            set(stage) {
                this.$store.commit('contactImport/SET_ACTIVE_STAGE', stage);
            },
        },

        stageReady() {
            return this.stages[this.activeStage].ready;
        },

        hasUnmatchedFields() {
            return this.unmatchedFields.size > 0;
        },

        matchedPercentage() {
            return this.importMappings.length > 0
                ? Math.round((100 * this.matchedFields.size) / this.importMappings.length)
                : 0;
        },

        headingText() {
            return this.matchedPercentage === 100
                ? this.$t('heading.success')
                : this.$t('heading.partialSuccess');
        },

        subHeadingText() {
            if (this.matchedPercentage === 100) {
                return this.$t('matchedMessage.all');
            }

            if (this.matchedPercentage >= 75 && this.matchedPercentage < 100) {
                return this.$t('matchedMessage.most');
            }

            if (this.matchedPercentage >= 50 && this.matchedPercentage < 75) {
                return this.$t('matchedMessage.many');
            }

            if (this.matchedPercentage < 50 && this.matchedPercentage > 0) {
                return this.$t('matchedMessage.some');
            }

            if (this.matchedPercentage === 0) {
                return this.$t('matchedMessage.none');
            }

            return this.$t('matchedMessage.none');
        },

        matchedText() {
            return this.$t(this.matchedOpened ? 'unmatched.hide' : 'unmatched.view', {
                unmatchedCount: this.matchedFields.size,
            });
        },

        importFileExtension() {
            const fileArray = this.importInProgress && this.importInProgress.fileName
                ? this.importInProgress.fileName.split('.')
                : [];

            return fileArray.length > 0
                ? fileArray[fileArray.length - 1]
                : null;
        },
    },

    methods: {
        createCustomField(payload) {
            return this.$store.dispatch('contacts/CREATE_CUSTOM_FIELD', payload);
        },

        showAddCustomField(mapping) {
            return this.activeField === mapping.fieldToImport;
        },

        isUnmatched(mapping) {
            return mapping && this.unmatchedFields.has(mapping.fieldToImport);
        },

        isMatched(mapping) {
            return mapping && this.matchedFields.has(mapping.fieldToImport);
        },

        toggleMatchedFields() {
            this.$store.commit('contactImport/TOGGLE_MATCHED_OPENED');
        },

        formatMatchGroups() {
            const alreadyMapped = new Set();

            this.contactImportMappings.forEach(({ fieldToMapTo, fieldToImport }) => {
                if (fieldToMapTo !== IMPORT_MAPPINGS.NO_MATCH && !alreadyMapped.has(fieldToMapTo)) {
                    this.matchedFields.add(fieldToImport);

                    if (fieldToMapTo !== IMPORT_MAPPINGS.TAG) {
                        alreadyMapped.add(fieldToMapTo);
                    }
                } else {
                    this.unmatchedFields.add(fieldToImport);
                }
            });
        },

        next() {
            if (this.importMappings.find((x) => x.fieldToMapTo === 'COMPANY')) {
                this.$track('Assigned Company Name', { 'Event Source': 'Import Contacts' });
            }
            this.$emit('next');
        },

        clearActiveField() {
            this.activeField = null;
        },

        addCustomField(fieldToImport) {
            this.activeField = fieldToImport;

            const multiselectRefs = this.$refs[`multiselect-${fieldToImport}`];

            // Does the multiselect ref exist, and can it be destructured
            if (!multiselectRefs || typeof multiselectRefs[Symbol.iterator] !== 'function') {
                return;
            }

            const [multiselect] = multiselectRefs;

            if (multiselect && typeof multiselect.toggle === 'function') {
                multiselect.toggle();
            }
        },

        loadContactsCustomFields(newCustomField) {
            const message = this.$t('loadFieldsError');

            return this.$store.dispatch('contacts/LOAD_CUSTOM_FIELDS')
                .then((fieldGroups) => {
                    if (this.activeField && newCustomField) {
                        const newFieldGroup = fieldGroups.find(({ id }) => id === newCustomField.groupId);
                        const newField = newFieldGroup.fields.find(({ id }) => id === newCustomField.id);

                        this.selectCustomField(newField);
                    }
                })
                .catch(() => {
                    this.$track('Contacts - Import - Organize - error : loading contact fields');

                    this.$error({
                        message,
                        bottom: true,
                    });
                });
        },

        selectCustomField(newField) {
            this.$store.commit('contactImport/ADD_CUSTOM_FIELD', {
                ...newField,
                label: newField.name,
                id: newField.dataFormFieldId,
            });

            const activeFieldIndex = this.importMappings.findIndex(({ fieldToImport }) => fieldToImport === this.activeField);
            const trimmedFieldName = newField.name.replace(/\s/g, '');

            this.importMappings[activeFieldIndex].fieldToMapTo = `customField.${trimmedFieldName}.${newField.dataFormFieldId}`;

            this.clearActiveField();
        },

        previewImport() {
            this.matchedFields = new Set();
            this.unmatchedFields = new Set();

            this.loaded = false;
            this.$store.commit('contactImport/SET_MAP_TO_OPTIONS', []);

            return this.$store.dispatch('contactImport/PREVIEW_CONTACT_IMPORT', this.fileboxId)
                .then(() => {
                    this.importMappings = this.contactImportMappings;
                    this.loaded = true;
                })
                .catch((error) => {
                    this.handleContactImportRequestError(error);
                });
        },

        handleContactImportRequestError(error) {
            let reason = 'unknown';
            let code = '';

            if (error && error.response && error.response.status) {
                const { status } = error.response;

                if (status === 403) {
                    this.$error({
                        message: this.$t('importContacts.status.error.threshold'),
                        closeLabel: this.$t('global.gotit'),
                        bottom: true,
                    });

                    code = error.response.status;
                    reason = 'maximum contacts reached';
                } else {
                    this.$error({
                        message: this.$t('errors.importContacts'),
                        bottom: true,
                    });

                    code = error.response.status;
                }
            } else {
                this.$error({
                    message: this.$t('errors.importContacts'),
                    bottom: true,
                });
            }

            const eventName = `Contacts - Import - Organize - error : ${reason}`;
            const eventProperties = { code };

            this.$track(eventName, eventProperties);
        },

        setStageReady(isReady) {
            this.$store.commit('contactImport/SET_STAGE_READY', {
                stage: 'organize',
                isReady,
            });
        },

        invalidateField(field) {
            let isInvalid = false;
            let reason = null;

            if (field === IMPORT_MAPPINGS.NO_MATCH) {
                isInvalid = true;
            } else if (field !== IMPORT_MAPPINGS.DO_NOT_IMPORT) {
                const existing = [];

                this.importMappings.forEach(({ fieldToMapTo }) => {
                    if (fieldToMapTo === field && fieldToMapTo !== IMPORT_MAPPINGS.TAG) {
                        existing.push(fieldToMapTo);
                    }
                });

                if (existing.length > 1) {
                    isInvalid = true;
                    reason = 'existing';
                }
            }

            return { isInvalid, reason };
        },

        mappingFieldSelected(selectedOption) {
            if (selectedOption === IMPORT_MAPPINGS.ADD_AS_CUSTOM_FIELD) {
                this.$track('Contacts - Import - Organize - clicked : add a custom field');
                const field = this.importMappings.find(({ fieldToMapTo }) => fieldToMapTo === 'ADD_AS_CUSTOM_FIELD');

                this.addCustomField(field.fieldToImport);
                field.fieldToMapTo = 'unselected';
            } else if (selectedOption === IMPORT_MAPPINGS.TAG) {
                this.$track('Contacts - Import - Organize - clicked : create tags from field');
            } else {
                this.$track('Contacts - Import - Organize - clicked : field mapping selected');
            }
        },

        isInUse(reason) {
            return reason === 'existing';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .organize-stage {
        --collapsible-inner-padding: 0;
        --dropdown-height: #{px-to-rem(400px)};
        margin: 0 auto;
        max-width: px-to-rem(600px);
        padding-bottom: px-to-rem(260px);
    }

    h5 {
        color: $color-gray-700;
        margin-bottom: $gp * 3;
    }

    h2,
    h5,
    small,
    .labels,
    .divider {
        text-align: center;
    }

    .labels {
        display: grid;
        grid-template-columns: auto auto;
        margin-bottom: $gp;
        font-weight: $font-weight-semibold;
    }

    .mapping {
        --input-margin-bottom: 0;
        display: grid;
        grid-template-columns: minmax(px-to-rem(100px), 1fr) px-to-rem(50px) minmax(px-to-rem(100px), 1fr);
        margin-bottom: $gp;
    }

    .divider {
        font-size: $font-size-xl;
        margin-top: $gp / 3;
    }

    .help-text {
        margin-bottom: $gp;
        color: $color-gray-700;
        word-break: break-word;

        &.error {
            color: $color-red;
        }
    }

    .collapsible {
        border-top: 1px solid $color-gray-200;
        margin-top: $gp * 2;
    }

    .match-message {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: $gp / 2;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-size: $font-size-med;
        --icon-size: #{$icon-size};

        &.matched {
            padding: $gp * 1.5 0;
            margin-bottom: 0;
        }

        a {
            margin: 0 $gp / 3;
        }
    }

    .matched-icon {
        --icon-color: #{$color-green};
    }

    .arrow-icon {
        margin: 0 $gp / 2;
        display: flex;
        align-items: center;
        @include transition(transform);

        &.rotated {
            transform: rotate(180deg);
        }
    }

    .add-custom-field-box {
        @include card;
    }

    .upload-file-info {
        display: flex;
        flex-direction: column;
    }

    .file-icon {
        background-color: $color-navy;
        color: $color-paper;
        height: px-to-rem(48px);
        width: px-to-rem(48px);
        border-radius: $border-radius;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $gp;
    }

    .contact-count {
        color: $color-gray-700;
    }

    footer {
        border-top: 1px solid $color-gray-200;
        display: flex;
        justify-content: center;
        padding: $gp;

        &.matched-opened {
            margin-top: $gp;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "heading": {
            "success": "Good news!",
            "partialSuccess": "Now, match up your data"
        },
        "matchedMessage": {
            "all": "All of your fields were automatically matched",
            "most": "Most of your fields were automatically matched",
            "many": "Many of your fields were automatically matched",
            "some": "Some of your fields were automatically matched",
            "none": "None of your fields were automatically matched"
        },
        "allFieldsMatched": "All your data was directly matched.",
        "matched": "Please match these {matchedCount} fields:",
        "proTipText": "{contactCount} contacts",
        "unmatched": {
            "view": "View {unmatchedCount} directly matched fields",
            "hide": "Hide {unmatchedCount} directly matched fields"
        },
        "columnNames": "Your fields",
        "infFields": "{productName} fields",
        "loadFieldsError": "There was an error loading the contact fields",
        "required": "Required",
        "inUse": "Already in use. Pick another field.",
        "helpText": "Have questions? Visit our <a href='{url}/help/import-contacts' target='_blank'>Help Center</a> for more detailed instructions",
        "customFields": {
            "description": {
                "main": "You can add up to {remaining} custom fields to your contact records. Be sure to select a field type that best matches the data you plan to enter.",
                "remaining": "{customFieldsAvailable} more"
            },
            "objectName": "custom field"
        }
    }
}
</i18n>
