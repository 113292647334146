export const SITES_FILTER_VALUES = {
    PUBLISHED: 'PUBLISHED',
    DRAFT: 'DRAFT',
};

export const SITE_STATUS = {
    PUBLISHED: 'ACTIVE',
    PUBLISHED_CHANGES: 'ACTIVE_CHANGES',
    DRAFT: 'DRAFT',
    SAVED: 'SAVED',
};

export const TEMPLATE_TABS = {
    GALLERY: 'gallery',
    SAVED: 'saved',
    PUBLISHED: 'published',
};

export const BACK_END_PAGE_SIZE = 25;

export function buildMarketingSiteEmbedCode(siteUrl) {
    return `<iframe src="${siteUrl}" style="border:none; min-height: 700px; width: 1px; min-width: 100%; *width: 100%;" name="site" scrolling="no" frameborder="0" marginheight="0px" marginwidth="0px" width="100%" height="100%" referrerpolicy="unsafe-url" allowfullscreen></iframe>`;
}
