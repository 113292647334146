<template lang="html">
    <div class="publish-page-section">
        <div v-if="editLink" class="edit-button-section">
            <ds-text-button class="edit-button" data-qa="publish-section-button" @click="$emit('edit-section')">
                {{ editLink }}
            </ds-text-button>
        </div>
        <div class="section-header">
            <div class="section-title">
                {{ title }}
            </div>
        </div>
        <p v-if="description" class="helper-text">
            {{ description }}
        </p>
        <slot />
    </div>
</template>

<script>

export default {
    props: {
        title: String,
        description: String,
        editLink: String,
    },

};
</script>
<style lang="scss" scoped>
    .publish-page-section {
        padding: ($gp * 0.75) ($gp * 0.75) $gp $gp;
        display: flex;
        flex-direction: column;
        text-align: start;
    }
    .section-header {
        display: flex;
        margin-bottom: $gp / 2;
    }
    .section-title {
        flex: 1;
        text-align: start;
        font-size: $font-size-xs;
        line-height: $line-height-sm;
        color: $color-gray-800;
    }
    .edit-button-section {
        position: relative;
    }
    .edit-button {
        position: absolute;
        right: 0;
        top: 0;
        text-align: end;
    }
    .helper-text {
        text-align: start;
    }
</style>
