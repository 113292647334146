<template>
    <div class="month">
        <div v-for="week in weeks" :key="`week-${week}`" class="week">
            <day
                v-for="day in week"
                :key="`day-${day}`"
                :day="day"
                :on-select="onSelect"
                :show-availability="showAvailability"
                :is-available="dayIsAvailable(day)"
                :is-in-month="isInMonth(day)"
                :selected-date="selectedDate"
                :appt-count="countApptsForDay(day, appts)"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import calendarMixin from '@/appointments/mixins/calendar.mixin';

import Day from '@/appointments/components/Day';

export default {
    components: {
        Day,
    },

    mixins: [calendarMixin],

    props: {
        delay: {
            type: Number,
            default: 200,
        },
        month: {
            type: Date,
            required: true,
        },
        firstDay: {
            type: Number,
            required: true,
        },
        onSelect: {
            type: Function,
            default: () => {},
        },
        onMonthChange: {
            type: Function,
            default: () => {},
        },
        appts: {
            type: Array,
            default: () => ([]),
        },
        availability: {
            type: Array,
            default: () => ([]),
        },
        loading: Boolean,
        showAvailability: Boolean,
        isExternal: Boolean,
        selectedDate: Date,
        maxDate: Date,
        minDate: Date,
    },

    data() {
        return {
            previousStartDate: new Date(),
        };
    },

    watch: {
        month() {
            // first day shown on the calendar
            // may be part of the previous month
            const startDate = this.weeks[0][0];

            // last day shown on the calendar
            // may be part of the next month
            const endDate = this.weeks[this.weeks.length - 1][6];

            this.onMonthChange({
                startDate: moment(startDate).startOf('day').toDate(),
                endDate: moment(endDate).endOf('day').toDate(),
            });
        },
    },

    computed: {
        weeks() {
            const monthWeeks = [];
            const lastDate = moment(this.month).endOf('month');
            let firstDate = moment(this.month).startOf('month');
            let currentWeekDay = 0;
            let currentWeek = [];

            // Add days from previous month
            if (this.firstDay !== firstDate.day()) {
                let previousDate = moment(this.month).subtract(1, 'month').endOf('month');

                while (previousDate.day() !== this.firstDay) {
                    previousDate = previousDate.subtract(1, 'day');
                }

                while (!previousDate.isSame(firstDate, 'day')) {
                    const day = moment(previousDate).startOf('day').toDate();

                    currentWeek.push(day);
                    currentWeekDay += 1;
                    previousDate = previousDate.add(1, 'day');
                }
            }

            while (!firstDate.isAfter(lastDate)) {
                const day = moment(firstDate).startOf('day').toDate();

                if (currentWeekDay === 7) {
                    monthWeeks.push(currentWeek);
                    currentWeekDay = 0;
                    currentWeek = [];
                }

                currentWeek.push(day);
                currentWeekDay += 1;
                firstDate = firstDate.add(1, 'day');
            }

            // add days of the next month
            if (currentWeek.length > 0) {
                while (currentWeek.length !== 7) {
                    const day = moment(firstDate).startOf('day').toDate();

                    currentWeek.push(day);
                    firstDate = firstDate.add(1, 'day');
                }
                monthWeeks.push(currentWeek);
            }

            return monthWeeks;
        },
    },

    methods: {
        dayIsAvailable(selectedDay) {
            if (!this.showAvailability) {
                return true;
            }

            const firstAvail = this.availability.find(({ day: d }) => moment(selectedDay).isSame(d, 'day'));

            if (!firstAvail) {
                return false;
            }

            return firstAvail.isFree;
        },

        isInMonth(day) {
            return moment(this.month).isSame(day, 'month');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import '~@/appointments/styles/appointments';

    .week {
        @include weekGrid;
    }
</style>
