import { APPOINTMENTS_FEATURE } from '@/shared/constants/features.constants';
import { NEW_APPOINTMENT_TYPE_ROUTE_NAME } from '@/appointments/appointments.constants';

const AppointmentsPage = () => import(/* webpackChunkName: "appointments" */ '@/appointments/pages/AppointmentsPage');
const BookingPage = () => import(/* webpackChunkName: "booking" */ '@/appointments/pages/BookingPage');
const VideoCallPage = () => import(/* webpackChunkName: "booking" */ '@/appointments/pages/VideoCallPage');

const routes = [
    {
        path: '/appointments',
        name: 'calendar',
        component: AppointmentsPage,
        meta: {
            title: 'nav.calendar',
            hideHeaderOnMobile: true,
            resetIntercom: true,
            feature: APPOINTMENTS_FEATURE,
        },
    },
    {
        path: '/appointments/new',
        name: NEW_APPOINTMENT_TYPE_ROUTE_NAME,
        component: AppointmentsPage,
        meta: {
            title: 'nav.calendar',
            hideHeaderOnMobile: true,
            feature: APPOINTMENTS_FEATURE,
        },
    },
    {
        path: '/booking/:userCode/:apptType',
        name: 'appt-booking',
        component: BookingPage,
        meta: {
            title: 'nav.calendar',
            isPublic: true,
            track: true,
            props: true,
        },
    },
    {
        path: '/booking/:apptTypeGUID',
        name: 'appt-booking-confirmation',
        component: BookingPage,
        meta: {
            title: 'nav.calendar',
            isPublic: true,
            props: true,
        },
    },
    {
        path: '/videocall/:apptTypeId/:apptId',
        name: 'videocall',
        component: VideoCallPage,
        meta: {
            title: 'nav.calendar',
            isPublic: true,
        },
    },
];

export default routes;
