<template>
    <div class="save-status">
        <autosave-status v-if="isSuccess || isSaving" :saving="isSaving" />

        <ds-status
            v-else-if="isFailed"
            type="error"
            emphasis="medium"
            data-qa="save-failed"
        >
            {{ $t('saveFailed') }}
        </ds-status>
    </div>
</template>

<script>
import AutosaveStatus from '@/shared/components/AutosaveStatus';
import { SAVE_STATUS } from '@/automations/constants/automations.constants';

export default {
    components: {
        AutosaveStatus,
    },

    props: {
        status: String,
    },

    computed: {
        isSaving() {
            return this.status === SAVE_STATUS.SAVING;
        },

        isSuccess() {
            return this.status === SAVE_STATUS.SUCCESS;
        },

        isFailed() {
            return this.status === SAVE_STATUS.FAILED;
        },
    },
};
</script>

<style lang="scss" scoped>
    .save-status {
        font-size: $font-size-sm;
    }
</style>

<i18n>
{
    "en-us": {
        "saveFailed": "Save failed"
    }
}
</i18n>
