<template>
    <div class="sms-message-input">
        <automation-textarea
            v-model="localValue"
            use-merge-service
            :maxlength="$options.MAX_CHARACTER_COUNT"
            :placeholder="$t('placeholder')"
            :additional-merge-fields="additionalMergeFields"
            :submitted="submitted"
            @input="(payload) => $emit('input', payload)"
        />

        <div :class="['message-footer', { 'over-limit': messageOverLimit }]">
            <p v-if="messageOverLimit" class="limit-warning">
                {{ messageOverLimit }}
            </p>

            <p class="character-count">
                {{ characterCount }}
            </p>
        </div>
    </div>
</template>

<script>
import AutomationTextarea from '@/shared/components/AutomationTextarea';

import { MAX_CHARACTER_COUNT } from '@/broadcasts/smsBroadcast.constants';

export default {
    components: {
        AutomationTextarea,
    },

    MAX_CHARACTER_COUNT,

    props: {
        value: {
            type: String,
            required: true,
        },

        additionalMergeFields: {
            type: Array,
            default: () => [],
        },

        submitted: Boolean,
    },

    data() {
        return {
            hasFocus: false,
            localValue: this.value,
            isMergeFieldMenuOpen: false,
        };
    },

    watch: {
        value(val) {
            this.localValue = val;
        },
    },

    computed: {
        characterCount() {
            return `${this.localValue.length}/${this.$options.MAX_CHARACTER_COUNT}`;
        },

        messageOverLimit() {
            return this.localValue.length > this.$options.MAX_CHARACTER_COUNT ? this.$t('overCharacterLimitError') : '';
        },
    },
};
</script>

<style lang="scss" scoped>
    .message-footer {
        display: flex;
        justify-content: flex-end;
        font-size: $font-size-xsmall;

        &.over-limit {
            justify-content: space-between;
        }
    }

    .limit-warning {
        color: $color-orange;
    }

    .character-count {
        color: $color-ink-600;
    }
</style>

<i18n>
{
    "en-us": {
        "placeholder": "Enter message*",
        "emptyError": "Please enter a message",
        "overCharacterLimitError": "Using merge fields may exceed character limit"
    }
}
</i18n>
