import { NOTIFICATION_TYPES } from '@/shared/constants/notifications.constants';

export default {
    emailNotificationsEnabled({ notificationPreferences }) {
        return notificationPreferences.deliveryType === NOTIFICATION_TYPES.EMAIL;
    },

    domainsDkim({ domains }) {
        const FILTERED_DOMAINS = [
            'hotmail.com',
            'yahoo.com',
            'aol.com',
            'gmail.com',
            'outlook.com',
            'live.com',
            'me.com',
            'icloud.com',
            'msn.com',
        ];

        return domains.filter(({ domain }) => {
            return FILTERED_DOMAINS.indexOf(domain) === -1;
        });
    },

    hasDomainThatRequiresDkim(_, { domainsDkim }) {
        return domainsDkim.some((domain) => !domain.dkimName || !domain.verified);
    },

    maxCustomFields({ maxTotalCustomFields }) {
        return maxTotalCustomFields || 100;
    },

    hellosignDocumentById: ({ integrations }) => (documentId) => {
        return integrations.hellosignDocuments?.find((document) => {
            return document.documentId === documentId;
        });
    },

    isBlendrConnected: ({ integrations }) => (blendrType) => {
        return integrations.blendrBlends?.some(({ name, setupComplete }) => {
            return (name === blendrType || name.includes(blendrType)) && setupComplete;
        });
    },

    shopifyProductById: ({ integrations }) => (productId) => {
        return integrations.shopifyProducts?.find((product) => {
            return +product.productId === +productId;
        });
    },

    typeformFormById: ({ integrations }) => (formId) => {
        return integrations.typeformForms?.find((form) => {
            return form.formId === formId;
        });
    },
};
