<template lang="html">
    <ds-dropdown
        v-if="appLoaded"
        class="nav-account-dropdown"
        :is-open="dropdownOpen"
        @close="dropdownOpen = false"
    >
        <template #default>
            <ds-avatar
                data-qa="account-dropdown"
                :class="['account-dropdown', { active: dropdownOpen }]"
                type="user"
                :image-url="user.emailSignatureURL"
                :name="user.givenName"
                :size="32"
                @click.native="dropdownOpen = !dropdownOpen"
            />
        </template>

        <template #menu>
            <div class="menu-wrapper">
                <nav-account-links
                    hide-notifications
                    hide-help
                    data-qa="nav-dropdown-links"
                    @click.native="dropdownOpen = false"
                />
                <sample-data-cta event-source="Nav Account Dropdown" />
            </div>
        </template>
    </ds-dropdown>
</template>

<script>
import { mapState } from 'vuex';

import NavAccountLinks from '@/nav/components/NavAccountLinks';
import SampleDataCta from '@/sampleData/components/SampleDataCta';

export default {
    components: {
        NavAccountLinks,
        SampleDataCta,
    },

    data() {
        return {
            dropdownOpen: false,
        };
    },

    computed: {
        ...mapState({
            appLoaded: ({ global }) => global.appLoaded,
            user: ({ auth }) => auth.user || {},
        }),
    },
};
</script>

<style lang="scss" scoped>
    .nav-account-dropdown {
        --dropdown-height: #{px-to-rem(600px)};
        @include margin-start($gp / 2);
    }

    .account-dropdown {
        @include transition(box-shadow);
        cursor: pointer;

        &:hover,
        &.active {
            box-shadow: $elevation-z2;
        }
    }

    .menu-wrapper {
        padding: $gp / 2 0;
    }
</style>
