<template>
    <ds-popover class="call-widget-tooltip" :is-open="isTooltipVisible" position="top">
        <template #reference>
            <slot />
        </template>

        <template #default>
            <div class="tooltip-content">
                {{ tooltip }}
            </div>
        </template>
    </ds-popover>
</template>

<script>

const IDLE = 'IDLE';
const PENDING = 'PENDING';
const ACTIVE = 'ACTIVE';
const CLICKED = 'CLICKED';

export default {
    props: {
        delay: {
            type: Number,
            default: 300,
        },
        disabled: Boolean,
        tooltip: String,
    },

    data() {
        return {
            status: IDLE,
            timeout: null,
        };
    },

    mounted() {
        this.$el.addEventListener('mouseenter', this.handleEnter);
        this.$el.addEventListener('mouseleave', this.handleLeave);
        this.$el.addEventListener('mousedown', this.handleMouseDown);
        this.$el.addEventListener('mouseup', this.handleMouseUp);
    },

    beforeDestroy() {
        this.$el.removeEventListener('mouseenter', this.handleEnter);
        this.$el.removeEventListener('mouseleave', this.handleLeave);
        this.$el.removeEventListener('mousedown', this.handleMouseDown);
        this.$el.removeEventListener('mouseup', this.handleMouseUp);
    },

    computed: {
        isTooltipVisible() {
            return !this.disabled && this.status === ACTIVE;
        },
    },

    methods: {
        handleMouseDown() {
            clearTimeout(this.timeout);
            this.status = CLICKED;
        },

        handleMouseUp() {
            this.status = IDLE;
        },

        handleEnter() {
            if (this.status === CLICKED) return;

            this.status = PENDING;

            this.timeout = setTimeout(() => {
                this.status = ACTIVE;
            }, this.delay);
        },

        handleLeave() {
            clearTimeout(this.timeout);
            this.timeout = null;

            if (this.status === CLICKED) return;

            this.status = IDLE;
        },
    },
};
</script>

<style lang="scss" scoped>
.call-widget-tooltip {
    --popover-min-width: min-content;
    --popover-width: min-content;
}

.tooltip-content {
    padding: $gp / 2 $gp;
    white-space: nowrap;
}
</style>
