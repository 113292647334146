export const CUSTOM_FIELD_CATEGORY = 'customFields';

export const ALLOWED_MERGE_SERVICE_FIELDS = [
    '[[contact.uid]]',
    '[[contact.first_name]]',
    '[[contact.middle_name]]',
    '[[contact.last_name]]',
    '[[contact.title]]',
    '[[contact.job_title]]',
    '[[contact.email1.address]]',
    '[[contact.email2.address]]',
    '[[contact.email3.address]]',
    '[[contact.phone1.type]]',
    '[[contact.phone1.number]]',
    '[[contact.phone2.type]]',
    '[[contact.phone2.number]]',
    '[[contact.phone3.type]]',
    '[[contact.phone3.number]]',
    '[[contact.fax1.number]]',
    '[[contact.address1.line1]]',
    '[[contact.address1.line2]]',
    '[[contact.address1.locality]]',
    '[[contact.address1.region]]',
    '[[contact.address1.postal_code]]',
    '[[contact.address1.country]]',
    '[[contact.address2.line1]]',
    '[[contact.address2.line2]]',
    '[[contact.address2.locality]]',
    '[[contact.address2.region]]',
    '[[contact.address2.postal_code]]',
    '[[contact.address2.country]]',
    '[[contact.company.name]]',
    '[[contact.website]]',
    '[[contact.twitter]]',
    '[[contact.facebook]]',
    '[[contact.linkedin]]',
    '[[contact.birthday]]',
    '[[contact.anniversary]]',
    '[[contact.spouse.name]]',
    '[[contact.time_zone]]',
    '[[contact.contact_type]]',
    '{{profile.company.name}}',
    '{{profile.company.line1}}',
    '{{profile.company.line2}}',
    '{{profile.company.locality}}',
    '{{profile.company.region}}',
    '{{profile.company.postal_code}}',
    '{{profile.company.country_code}}',
    '{{profile.contact.email}}',
    '{{profile.contact.phone}}',
    '{{profile.logo_url}}',
    '{{profile.website_url}}',
    '{{profile.time_zone}}',
    '{{today.date}}',
    '{{today.time}}',
    '{{today.date_and_time}}',
    '{{user.first_name}}',
    '{{user.last_name}}',
    '{{user.email}}',
];
