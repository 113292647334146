import axios from 'axios';
import sentry from '@/analytics/sentry';
import clonedeep from 'lodash.clonedeep';

import {
    FORM_TYPE_CONTACTS, FORM_AUDIENCE_PUBLIC,
    DEFAULT_STYLING, FORM_BACKGROUND_COLOR,
    FORM_BUTTON_BACKGROUND_COLOR, FORM_BUTTON_TEXT_COLOR, FORM_BUTTON_ALIGNMENT,
} from '@/customForms/customForm.constants';
import { ALLOWED_VALUE_KEY_PATTERN, FIELD_TYPE_EXTERNAL } from '@/smartForms/smartform.constants';
import { trackFormButtonTextUpdated, trackFormStyleChange } from '@/customForms/analytics';

export default {
    LOAD_FORM(context, payload) {
        return loadForm(context, payload);
    },

    CREATE_FORM(context, payload) {
        return createForm(context, payload);
    },

    SAVE_FORM(context, payload) {
        return saveForm(context, payload);
    },

    DELETE_FORM(context, payload) {
        return deleteForm(context, payload);
    },

    SAVE_STYLING(context, payload) {
        return saveStyling(context, payload);
    },

    CREATE_STYLING(context, payload) {
        return createStyling(context, payload);
    },

    LOAD_STYLING(context, payload) {
        return loadStyling(context, payload);
    },

    LOAD_FORMS(context, payload) {
        return loadForms(context, payload);
    },

    UPDATE_PUBLIC_LIST_SORT(context, payload) {
        return updatePublicListSort(context, payload);
    },

    SET_NEW_FORM_SOURCE(context, payload) {
        return setNewFormSource(context, payload);
    },

    RELOAD_FORMS(context) {
        return reloadForms(context);
    },

    LOAD_FORM_FIELD_TEMPLATE_LIST(context, payload) {
        return loadFormTemplateList(context, payload);
    },

};

const loadForm = async ({ commit }, { formId, isNew = false }) => {
    const url = `${process.env.VUE_APP_FORM_SERVICE_URL}/forms/${formId}?includeFieldInputDetails=true`;

    commit('LOAD_FORM_START');

    try {
        const { data: form } = await axios.get(url);

        commit('SET_FORM', form);

        // remove when new form builder flow is at 100%
        if (isNew) {
            form.isNew = true;
        }

        return form;
    } catch (e) {
        sentry.captureException(e);
        commit('LOAD_FORM_ERROR');
    }

    return null;
};

const createForm = async ({ commit, rootState, dispatch }, form) => {
    const formUrl = `${process.env.VUE_APP_FORM_SERVICE_URL}/forms?includeFieldInputDetails=true`;
    const postData = {
        ...form,
        userId: rootState.auth.user.casId,
        accountId: rootState.auth.account.appName,
    };

    commit('LOAD_FORM_START');

    if (form.audience === FORM_AUDIENCE_PUBLIC) {
        commit('LOAD_STYLING_START');
    }

    try {
        const { data: savedForm } = await axios.post(formUrl, postData);

        commit('SET_IS_NEW_FORM', true);
        commit('SET_FORM', { ...savedForm, isNew: true });
        dispatch('LOAD_FORMS');

        return savedForm;
    } catch (e) {
        sentry.captureException(e);
        commit('LOAD_FORM_ERROR');
    }

    return null;
};

const saveForm = async ({ commit, state }, form) => {
    const oldButtonLabel = state.builder?.form?.buttonLabel;
    const newButtonLabel = form?.buttonLabel;

    const formToSave = JSON.parse(JSON.stringify(form));

    if (newButtonLabel !== oldButtonLabel) {
        trackFormButtonTextUpdated('Form Builder', newButtonLabel);
    }

    // Necessary for when another user was the last person to update
    formToSave.updatedBy = null;

    if (formToSave.fields) {
        formToSave.fields = formToSave.fields.reduce((fields, field) => {
            if (field.fieldType === FIELD_TYPE_EXTERNAL) {
                field.fieldName = null;
            }

            if (field.helperText && field.helperText.text === '') {
                field.helperText = null;
            }

            if (field.allowedValues) {
                const fieldOptionKeys = Object.keys(field.allowedValues);

                for (const key of fieldOptionKeys) {
                    const fieldOptionValue = field.allowedValues[key].value;

                    if (fieldOptionValue && !fieldOptionValue.match(ALLOWED_VALUE_KEY_PATTERN)) {
                        delete field.allowedValues[key].value; // Removing temporary value, smart-forms-api will create this value
                    }
                }
            }

            return fields.concat([field]);
        }, []);
    }

    commit('SAVE_FORM_START');

    try {
        const { data: savedForm } = await axios.put(`${process.env.VUE_APP_FORM_SERVICE_URL}/forms/${form.id}?includeFieldInputDetails=true`, formToSave);

        commit('SET_FORM', savedForm);
        commit('SYNC_FORMS_LIST', savedForm);

        return savedForm;
    } catch (e) {
        sentry.captureException(e);
        commit('SAVE_FORM_ERROR');
    }

    return null;
};

const deleteForm = ({ commit }, { id }) => {
    const url = `${process.env.VUE_APP_FORM_SERVICE_URL}/forms/${id}`;

    return axios
        .delete(url)
        .then(() => {
            commit('REMOVE_FROM_LIST', id);
        })
        .catch((e) => {
            sentry.log('Failed to delete form', { message: e.message });
        });
};

const createStyling = async ({ commit, rootState, state }, { styling, form }) => {
    const stylingData = { ...DEFAULT_STYLING, ...styling };

    try {
        const { data: savedStyling } = await axios.post(`${process.env.VUE_APP_FORM_SERVICE_URL}/formStylings`, {
            ...stylingData,
            userId: rootState.auth.user.casId,
            accountId: rootState.auth.account.appName,
        });

        await saveForm({ commit, state }, { ...clonedeep(form), formStyling: { id: savedStyling.id } });
        commit('SET_STYLING_SUCCESS', savedStyling);

        return savedStyling;
    } catch (e) {
        sentry.captureException(e);

        commit('LOAD_STYLING_ERROR');
    }

    return null;
};

const loadStyling = async ({ commit }, { formStyling }) => {
    commit('LOAD_STYLING_START');

    try {
        const { data: styling } = await axios.get(`${process.env.VUE_APP_FORM_SERVICE_URL}/formStylings/${formStyling.id}`);

        commit('SET_STYLING_SUCCESS', styling);

        return styling;
    } catch (e) {
        sentry.captureException(e);

        commit('LOAD_STYLING_ERROR');
    }

    return null;
};

const saveStyling = async ({ commit, state }, { formStyling }) => {
    commit('SAVE_STYLING_START');

    trackStyleChange({ state }, { formStyling });

    // Necessary for when another user was the last person to update
    formStyling.updatedById = null;

    try {
        const { data: { id, options, properties } } = await axios.put(`${process.env.VUE_APP_FORM_SERVICE_URL}/formStylings/${formStyling.id}`, formStyling);
        const savedFormStyling = { id, options, properties };

        commit('SET_STYLING_SUCCESS', savedFormStyling);

        return savedFormStyling;
    } catch (e) {
        sentry.captureException(e);

        commit('SAVE_STYLING_ERROR');
    }

    return null;
};

const updatePublicListSort = ({ commit }, payload) => {
    commit('UPDATE_SORT', payload); // update sort field and ascending
};

const reloadForms = async ({ commit, rootState, state }) => {
    const { isNewForm } = state.builder;

    // this is important to set the table into a loading state to prevent side effects from mutating sort attributes
    commit('LOAD_FORMS_START');

    if (isNewForm) {
        commit('UPDATE_SORT', { field: 'createTime', ascending: false });
    } else {
        commit('UPDATE_SORT', { field: 'updateTime', ascending: false });
    }

    commit('SET_IS_NEW_FORM', false);

    loadForms({ commit, rootState, state });
};

const loadForms = async ({ commit, rootState, state }, formType = FORM_TYPE_CONTACTS) => {
    const { publicListSortField, shouldPublicListPageSortAscending } = state;

    commit('LOAD_FORMS_START');
    const sortDirection = (shouldPublicListPageSortAscending ? 'ASC' : 'DESC');

    try {
        const { data } = await axios.get(`${process.env.VUE_APP_FORM_SERVICE_URL}/forms`, {
            params: {
                orderBy: `${publicListSortField}:${sortDirection}`,
                audiences: FORM_AUDIENCE_PUBLIC,
                formType,
                accountId: rootState.auth.account.appName,
                pageSize: 1000,
            },
        });

        commit('LOAD_FORMS_SUCCESS', { forms: data.forms, formType });
    } catch (e) {
        commit('LOAD_FORMS_ERROR');
        sentry.captureException(e);
    }
};

const setNewFormSource = ({ commit }, newFormSource) => {
    commit('SET_NEW_FORM_SOURCE', newFormSource);
};

const loadFormTemplateList = async (_, audience) => {
    const url = `${process.env.VUE_APP_FORM_SERVICE_URL}/formTemplates?audiences=${audience}`;

    try {
        const { data: form } = await axios.get(url);

        return form;
    } catch (e) {
        sentry.captureException(e);
    }

    return null;
};

const trackStyleChange = ({ state }, { formStyling }) => {
    const oldBackgroundColor = state.builder?.styling?.properties?.formPage[FORM_BACKGROUND_COLOR];
    const newBackgroundColor = formStyling?.properties?.formPage[FORM_BACKGROUND_COLOR];

    const oldButtonColor = state.builder?.styling?.properties?.buttons[FORM_BUTTON_BACKGROUND_COLOR];
    const newButtonColor = formStyling?.properties?.buttons[FORM_BUTTON_BACKGROUND_COLOR];

    const oldTextColor = state.builder?.styling?.properties?.buttons[FORM_BUTTON_TEXT_COLOR];
    const newTextColor = formStyling?.properties?.buttons[FORM_BUTTON_TEXT_COLOR];

    const oldTextAlignment = state.builder?.styling?.properties?.buttons[FORM_BUTTON_ALIGNMENT];
    const newTexAlignment = formStyling?.properties?.buttons[FORM_BUTTON_ALIGNMENT];

    if (newBackgroundColor !== oldBackgroundColor) {
        trackFormStyleChange('Form Builder', 'Form background color');
    }

    if (oldButtonColor !== newButtonColor) {
        trackFormStyleChange('Form Builder', 'Button color');
    }

    if (oldTextColor !== newTextColor) {
        trackFormStyleChange('Form Builder', 'Button text color');
    }

    if (oldTextAlignment !== newTexAlignment) {
        trackFormStyleChange('Form Builder', 'Button alignment');
    }
};
