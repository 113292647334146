import { whenAvailable } from '@/shared/utils/window.util';
import sentry from './sentry';

export default {
    setup({
        userType,
        email,
        casId,
        partner,
        buildNumber,
        appName,
        blockInfoRole,
        givenName,
        familyName,
        authToken,
    }) {
        try {
            if (process.env.VUE_APP_LOGGING !== 'disabled') {
                whenAvailable('pendo', (pendo) => {
                    pendo.TOOLTIP_ARROW_SIZE = 8;
                    pendo.initialize({
                        apiKey: process.env.VUE_APP_PENDO_API_KEY,

                        usePendoAgentAPI: false,

                        visitor: {
                            id: casId,
                            email,
                            firstName: givenName,
                            lastName: familyName,
                            userType,
                            isPartner: partner,
                            authToken,
                        },

                        account: {
                            id: appName,
                            block: blockInfoRole,
                            appVersion: buildNumber,
                        },
                    });
                });
            }
        } catch (e) {
            sentry.captureException(e);
        }
    },
};
