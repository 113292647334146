import sentry from '@/analytics/sentry';
import { ERROR_CODE_DUPLICATE_EMAIL, ERROR_CODE_INVALID_EMAIL } from '@/contacts/contacts.constants';

export default {
    methods: {
        error_handleGdpr(error, message) {
            if (error && error.graphQLErrors && error.graphQLErrors[0]) {
                const errorDetail = error.graphQLErrors[0].extensions?.exception?.detail;

                if (!errorDetail) {
                    sentry.log('Unknown contact creation error', { error, message });

                    return;
                }

                if (errorDetail.includes('403')) {
                    this.emailInvalid = true;
                    this.errorText = 'errors.addContactGdpr';

                    return;
                }
            }

            if (!this.showFormLevelError) {
                if (error && error.response && error.response.status === 403) {
                    this.$error({
                        message: this.$t('errors.addContactGdpr'),
                        bottom: true,
                    });
                } else {
                    this.$error({
                        message,
                        bottom: true,
                    });
                }
            }
        },

        error_handleAddContactError(error, email) {
            if (error.code === ERROR_CODE_INVALID_EMAIL) {
                this.emailInvalid = true;
                this.errorText = 'errors.invalidEmail';

                return;
            }

            if (error.code === ERROR_CODE_DUPLICATE_EMAIL) {
                this.$track('Contacts - error: Duplicate contact entry attempted');

                this.emailInvalid = true;
                this.duplicatesPath = this.getPath(error.duplicateContactIds, email);
                this.errorText = 'errors.addDuplicateContact';

                return;
            }

            this.error_handleGdpr(error, this.$t('errors.addContact'));
        },

        getPath(duplicates, email) {
            return duplicates.length > 1
                ? `/contacts/list/all?filter=${encodeURIComponent(`EMAIL=IS|${email}`)}`
                : `/contacts/list/all/contact/${duplicates[0]}/activity`;
        },
    },
};
