import {
    FORM_STATUSES,
    FORM_TYPE_CONTACTS,
} from '@/customForms/customForm.constants';

export default {
    forms: [],
    firstLoad: true,
    builder: {
        areFieldsValid: true,
        areDetailsValid: false,
    },
    states: {
        failed: false,
        imported: false,
        importing: false,
        ready: false,
        saving: false,
    },
    autoSaveTimeStamp: null,
    form: {
        id: 0,
        accountId: '',
        audience: '',
        buttonLabel: '',
        description: '',
        everPublished: false,
        fields: [],
        formStyling: null,
        formType: FORM_TYPE_CONTACTS,
        isNew: false,
        status: FORM_STATUSES.NOT_PUBLISHED,
        title: '',
        userId: '',
        usesCustomFieldDataFormFieldId: true,
    },
    smartFormListSortField: 'createTime',
    smartFormListSortAscending: false,
};
