import Vue from 'vue';
import VueI18n from 'vue-i18n';
import moment from 'moment';

import supportedLocales from '@/shared/i18n/locales';
import numberFormats from '@/shared/i18n/number.json';

Vue.use(VueI18n);

export const US_LONG_DATE_FORMATS = {
    LT: 'hh:mm A',
    LTS: 'hh:mm:ss A',
    L: 'MM/DD/YY',
    l: 'M/D/YYYY',
    LL: 'ddd, MMM Do, YYYY',
    ll: 'MMM D, YYYY',
    LLL: 'MMMM Do YYYY LT',
    lll: 'MMM D, YYYY, LT',
    LLLL: 'dddd, MMMM Do YYYY LT',
    llll: 'ddd, MMM D YYYY',
};

export const EU_LONG_DATE_FORMATS = {
    LT: 'hh:mm A',
    LTS: 'hh:mm:ss A',
    L: 'DD/MM/YY',
    l: 'D/M/YYYY',
    LL: 'Do MMMM YYYY',
    ll: 'ddd, D MMM, YYYY',
    LLL: 'Do MMMM YYYY LT',
    lll: 'D MMM, YYYY, LT',
    LLLL: 'dddd, Do MMMM YYYY LT',
    llll: 'ddd, D MMM YYYY',
};

let locale;

const localStorageData = window.localStorage['keap-web']
    ? JSON.parse(window.localStorage['keap-web'])
    : {};

if (localStorageData && localStorageData.global && localStorageData.global.locale) {
    ({ locale } = localStorageData.global);
} else {
    locale = navigator.language.toLowerCase();
}

const hasSupportedLocale = supportedLocales.find((supportedLocale) => supportedLocale.value === locale);

locale = hasSupportedLocale ? locale : 'en-us';

if (typeof locale !== 'string') {
    locale = 'en-us';
}

const language = locale.split('-')[0];
const rtl = locale === 'ar-iq';
const messages = {
    'en-us': require('@/shared/i18n/en-us.json'),
};

const longDateFormat = locale === 'en-us' ? US_LONG_DATE_FORMATS : EU_LONG_DATE_FORMATS;

if (moment.locale() === language) {
    moment.updateLocale(language, { longDateFormat });
} else {
    moment.locale(language, { longDateFormat });
}

document.documentElement.setAttribute('lang', language);

if (rtl) {
    document.documentElement.setAttribute('dir', 'rtl');
} else {
    document.documentElement.setAttribute('dir', 'ltr');
}

export const i18n = new VueI18n({
    locale,
    fallbackLocale: 'en-us',
    silentTranslationWarn: true,
    messages,
    numberFormats,
    missing: () => {},
});
