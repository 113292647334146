<template lang="html">
    <form v-if="showForm" ref="form" data-qa="add-tag">
        <ds-input-field
            v-model="tagName"
            type="text"
            name="title"
            :label="$t('tag.name')"
            data-qa="title"
            required
            autofocus
            :submitted="submitted"
        />

        <ds-multiselect
            ref="multiselect"
            v-model="selectedCategory"
            searchable
            :placeholder="$t('tag.category.title')"
            data-input-label="search-tag-categories"
            data-qa="tag-category"
            allow-add
            :options="tagCategoryOptions"
            @add="addNewCategory"
            @input="hideAddTagCategoryInline"
        />

        <div v-if="showAddTagCategoryInline" class="tag-category-container">
            <add-tag-category
                class="tag-category-inline"
                :inline="inline"
                @hideField="closeNewCategoryInline"
            />

            <div class="check-button-container">
                <ds-icon-button name="check" />
            </div>
        </div>

        <ds-text-area-field
            v-model="tagDescription"
            type="text"
            name="long"
            :label="$t('tag.description')"
            :submitted="submitted"
        />

        <div v-if="inline" class="tag-button-container">
            <ds-filled-button
                class="create-tag-button"
                @click="save"
            >
                {{ $t('createAndApplyTag') }}
            </ds-filled-button>

            <ds-text-button
                class="cancel-tag-button"
                @click="closeInlineTagCreation"
            >
                {{ $t('global.cancel') }}
            </ds-text-button>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import AddTagCategory from './AddTagCategory';

import contactModalSubtitle from '@/shared/mixins/contact-modal-subtitle.mixin';

export default {
    components: {
        AddTagCategory,
    },

    mixins: [contactModalSubtitle],

    props: {
        inline: Boolean,
        eventSource: {
            type: String,
            default: 'Add New Tag Modal',
        },
    },

    data() {
        return {
            nestedModal_title: this.$t('tag.add'),
            nestedModal_actionText: this.$t('global.save'),
            nestedModal_actionMethod: 'save',
            nestedModal_dataQaAction: 'tag.save',
            tagName: '',
            tagDescription: '',
            selectedCategory: null,
            submitted: false,
            tagsLoaded: false,
            tagCategoriesLoaded: false,
            showAddTagCategoryInline: false,
        };
    },

    mounted() {
        this.$bus.$on('ADD_TAG_CATEGORY', this.onNewTagCategoryAdded);

        if (this.inline) {
            this.loadTagsInline();
        }
    },

    beforeDestroy() {
        this.$bus.$off('ADD_TAG_CATEGORY');
    },

    computed: {
        ...mapState({
            allTags: ({ tags }) => tags.tags,
        }),

        ...mapGetters({
            tagCategoryOptions: 'tags/tagCategoryOptions',
        }),

        showForm() {
            return this.tagsLoaded && this.tagCategoriesLoaded;
        },

        nestedModal_actionDisabled() {
            return !this.tagName.trim().length;
        },
    },

    methods: {
        nestedModal_open(tag) {
            if (tag) {
                this.tagName = tag;
            }

            this.$store.dispatch('tags/LOAD_TAG_CATEGORIES')
                .then(() => {
                    this.tagCategoriesLoaded = true;
                });

            this.$store.dispatch('tags/LOAD_TAGS')
                .then(() => {
                    this.tagsLoaded = true;
                });
        },

        nestedModal_reset() {
            this.tagName = '';
            this.tagDescription = '';
            this.selectedCategory = null;
            this.submitted = false;
        },

        loadTagsInline() {
            this.$store.dispatch('tags/LOAD_TAG_CATEGORIES')
                .then(() => {
                    this.tagCategoriesLoaded = true;
                });

            this.$store.dispatch('tags/LOAD_TAGS')
                .then(() => {
                    this.tagsLoaded = true;
                });
        },

        save() {
            const existingTag = this.hasDuplicate();

            if (existingTag) {
                this.$bus.$emit('ADD_TAG', existingTag);

                if (this.inline) {
                    this.closeInlineTagCreation();
                } else {
                    this.$bus.$emit('POP_NESTED_MODAL');
                }
            } else {
                this.submitted = true;

                if (this.$refs.form && this.$refs.form.checkValidity()) {
                    const successMessage = this.$t('tagAdded');
                    const { eventSource } = this;
                    const newTag = {
                        name: this.tagName,
                        description: this.tagDescription,
                        categoryId: this.selectedCategory ? this.selectedCategory.value : null,
                    };

                    return this.$store.dispatch('tags/CREATE_TAG', {
                        newTag,
                        eventSource,
                    })
                        .then((tag) => {
                            this.$bus.$emit('ADD_TAG', tag);

                            if (this.inline) {
                                this.closeInlineTagCreation();
                            } else {
                                this.$bus.$emit('POP_NESTED_MODAL');
                            }

                            this.$toast({ message: successMessage });
                        })
                        .catch(() => {
                            this.$error({
                                message: this.$t('errors.tags.create'),
                            });
                        });
                }
            }

            return Promise.resolve();
        },

        closeInlineTagCreation() {
            this.$emit('showAddTag', false);
        },

        hideAddTagCategoryInline() {
            this.showAddTagCategoryInline = false;
        },

        hasDuplicate() {
            return this.allTags.find((tag) => {
                const sameName = tag.name === this.tagName;
                const sameCategory = (tag.category && this.selectedCategory)
                    ? tag.category.id === this.selectedCategory.value
                    : !(tag.category || this.selectedCategory);

                return sameName && sameCategory;
            });
        },

        addNewCategory() {
            this.$refs.multiselect.toggle();

            if (this.inline) {
                return this.addNewCategoryInline();
            }

            return this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddTagCategory,
            });
        },

        addNewCategoryInline() {
            this.selectedCategory = {};
            this.showAddTagCategoryInline = true;
        },

        closeNewCategoryInline() {
            this.showAddTagCategoryInline = false;
        },

        onNewTagCategoryAdded(newCategory) {
            this.selectedCategory = this.tagCategoryOptions.find((tagCategoryOption) => {
                return newCategory.id === tagCategoryOption.value;
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .create-tag-button {
        @include margin-end($gp / 2);
    }

    .cancel-tag-button {
        @include margin-start($gp / 2);
    }

    .tag-category-container {
        display: flex;
    }

    .tag-category-inline {
        @include margin-start($gp * 2);
        display: flex;
        align-items: center;
        flex: 5;
    }

    .check-button-container {
        flex: 1;
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "createAndApplyTag": "Create and apply tag",
        "tagAdded": "Tag added successfully"
    }
}
</i18n>
