<template lang="html">
    <mobile-app-cta
        :title="$t('title')"
        :desktop-title="$t('desktopTitle')"
        :illustration-path="`images/dashboard/scan-contact.svg`"
    />
</template>

<script>
import MobileAppCta from '@/import/components/MobileAppCTA';

export default {
    components: { MobileAppCta },

    data() {
        return {
            nestedModal_title: this.$t('modalTitle'),
        };
    },
};
</script>

<i18n>
{
    "en-us": {
        "modalTitle": "Download the Keap app",
        "title": "Easily scan business cards using the Keap mobile app.",
        "desktopTitle": "Save time. Scan your business cards with the Keap mobile app."
    }
}
</i18n>
