export default {
    findScheduleByBroadcastId: ({ schedules }) => (broadcastId) => {
        if (schedules[broadcastId]) {
            return schedules[broadcastId];
        }

        const date = new Date();
        const hours = date.getHours().toString();
        const minutes = date.getMinutes().toString();

        return {
            selectedTiming: 'bestTime',
            schedulingInfo: {
                date: new Date(),
                timeZone: {
                    value: 'Contact',
                    label: "Contact's time zone",
                },
                time: hours + minutes,
            },
        };
    },

    findStatusByBroadcastId: ({ statuses }) => (broadcastId) => {
        if (statuses[broadcastId]) {
            return statuses[broadcastId];
        }

        return {
            details: '',
            audience: '',
            timing: '',
            currentStep: 0,
        };
    },
};
