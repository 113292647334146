import sentry from '@/analytics/sentry';
import { campaignApi as api } from '@/campaigns/api';

export default {
    LOAD_CAMPAIGNS_LIST(context, payload) {
        return loadCampaignsList(context, payload);
    },

    LOAD_RECENT_CAMPAIGNS_LIST(context, payload) {
        return getRecentCampaigns(context, payload);
    },

    DELETE_CAMPAIGN(context, id) {
        return deleteCampaign(context, id);
    },

    LOAD_CAMPAIGN_SEQUENCES(context, payload) {
        return loadCampaignSequences(context, payload);
    },

    LOAD_CAMPAIGN_STATS(context, campaignId) {
        return loadCampaignStats(context, campaignId);
    },
};

const loadCampaignsList = async ({ commit }, {
    limit = 20,
    offset = 0,
    search = '',
    reset = false,
}) => {
    try {
        const { data: { campaignList } } = await api.getCampaignList({ limit, offset, search });

        if (reset) {
            commit('SET_CAMPAIGNS', campaignList);
        } else {
            commit('APPEND_CAMPAIGNS', campaignList);
        }

        return campaignList.length;
    } catch (e) {
        sentry.log('Load campaign list failed');
        throw e;
    }
};

const getRecentCampaigns = async (context, {
    limit = 20,
    offset = 0,
    orderBy = 'date_created',
    orderDirection = 'descending',
}) => {
    try {
        const { data: { campaignList } } = await api.getCampaignList({
            limit, offset, orderBy, orderDirection,
        });

        return (campaignList || []);
    } catch (e) {
        sentry.log('Load campaign list failed');
        throw e;
    }
};

const loadCampaignSequences = async (context, { id }) => {
    try {
        const { data: { campaignSequences } } = await api.getCampaignSequences(id);

        return campaignSequences;
    } catch (e) {
        sentry.log('Loading campaign sequences failed', { id });
        throw e;
    }
};

const deleteCampaign = async ({ commit }, campaign) => {
    const { id } = campaign;

    try {
        const { data: { deleteCampaign: successful } } = await api.deleteCampaign(id);

        commit('DELETE_CAMPAIGN', id);

        return successful;
    } catch (e) {
        sentry.log('Delete campaign failed', { id });
        throw e;
    }
};

const loadCampaignStats = async (context, campaignId) => {
    try {
        const { data: { campaignStats } } = await api.getCampaignStats(campaignId);

        return campaignStats;
    } catch (e) {
        sentry.log('Loading campaign stats failed', { campaignId });
        throw e;
    }
};
