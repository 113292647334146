export const TUTORIAL_TYPES = {
    APPLE_PRIVACY_ISSUES_CONTACT_RECORD_GUIDE: 'ApplePrivacyIssuesContactRecordGuide',
    APPOINTMENTS_DEFAULT_APPOINTMENTS: 'AppointmentsDefaultAppointments',
    CONTACT_LIST_MULTISELECT: 'ContactListMultiselect',
    EMAIL_TEMPLATES: 'EmailTemplates',
    EMAIL_TEMPLATES_BUTTON: 'EmailTemplatesButton',
    MANAGE_SMART_FORMS: 'ManageSmartForms',
    SMS_TEMPLATES: 'SmsTemplates',
    SMS_TEMPLATES_BUTTON: 'SmsTemplatesButton',
    SMS_BROADCASTS: {
        BEACON: 'SmsBroadcastBeacon',
        RECIPIENTS: 'WriteSmsBroadcastRecipients',
        MESSAGE: 'WriteSmsBroadcastMessage',
        TIMING: 'WriteSmsBroadcastTiming',
        SEND: 'WriteSmsBroadcastSend',
    },
    TEXT_AUTOMATION_PRICING_ALERT: 'TextAutomationPricingAlert',
    TEXT_AUTOMATION_CHARGEEBEE_PRICING_ALERT: 'TextAutomationChargeebeePricingAlert',
    TEXT_AUTOMATION_WALKTHROUGH_STEP: 'TextAutomationWalkthroughStep',
    SMS_BROADCASTS_WALKTHROUGH_STEP: 'SmsBroadcastWalkthroughStep',
    KEAP_TOGGLE: 'FTU_KeapToggle',
    CHECKOUT_FORMS_PREVIEW: 'CheckoutFormsPreview',
    REFERRAL_EMAIL_TEMPLATE: 'ReferralEmailTemplate',
    ONBOARDING: {
        GETTING_STARTED_COMPLETED: 'onboardingChecklist|CelebrateCompletion',
        INTRO_SLIDES: 'SimpleOnboarding',
        TOURS: {
            APPOINTMENTS: 'ONBOARDING_TOURS_APPOINTMENTS',
            BROADCASTS: 'ONBOARDING_TOURS_BROADCASTS',
            CAMPAIGNS: 'ONBOARDING_TOURS_CAMPAIGNS',
            MESSAGES: 'ONBOARDING_TOURS_MESSAGES',
            MONEY: 'ONBOARDING_TOURS_MONEY',
            PIPELINE: 'ONBOARDING_TOURS_PIPELINE',
            SMS_BROADCASTS: 'ONBOARDING_TOURS_SMS_BROADCASTS',
            TEXT_AUTOMATION: 'ONBOARDING_TOURS_TEXT_AUTOMATION',
        },
    },
};
