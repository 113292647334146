<template lang="html">
    <div
        v-if="showCta"
        class="sample-data-wrapper"
    >
        <ds-icon-button
            name="x"
            class="dismiss-icon"
            @click="dismissCta"
        />

        <div class="sample-data-cta">
            <ds-illustration
                class="innovation-illustration"
                name="innovation"
            />

            <p>{{ $t('message') }}</p>

            <ds-text-button
                class="sample-cta-button"
                @click.native="addSampleData"
            >
                {{ $t('cta') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import AddSampleDataModal from './AddSampleDataModal';

import { FF_KEAP_SAMPLE_DATA } from '@/shared/constants/featureFlag.constants';

import '@/shared/images/innovation.svg';

export default {
    props: {
        eventSource: String,
    },

    mounted() {
        if (this.sampleDataEnabled) {
            this.$store.dispatch('sampleData/LOAD_SAMPLE_DATA_STATUS');
        }
    },

    computed: {
        ...mapState({
            sampleDataEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SAMPLE_DATA],
            sampleDataStarted: ({ sampleData }) => sampleData.sampleDataStarted,
            sampleDataSuccess: ({ sampleData }) => sampleData.sampleDataSuccess,
            sampleDataCtaDismissed: ({ sampleData }) => sampleData.sampleDataCtaDismissed,
            sampleDataProgress: ({ sampleData }) => sampleData.sampleDataProgress,
            sampleDataTotal: ({ sampleData }) => sampleData.sampleDataTotal,
        }),

        showCta() {
            return this.sampleDataEnabled
                && !this.sampleDataSuccess
                && !this.sampleDataStarted
                && !this.sampleDataCtaDismissed;
        },
    },

    methods: {
        addSampleData() {
            this.$store.dispatch('sampleData/CREATE_SAMPLE_DATA', this.eventSource);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddSampleDataModal,
                modalSize: 'sm',
                showRootClose: false,
                interceptClose: this.tryClose,
            });
        },

        dismissCta() {
            this.$store.dispatch('sampleData/DISMISS_SAMPLE_DATA_CTA');
        },

        tryClose() {
            if (this.sampleDataProgress === this.sampleDataTotal) {
                this.$bus.$emit('POP_NESTED_MODAL');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .sample-data-wrapper {
        border-top: solid px-to-rem(1px) $color-gray-200;
    }

    .sample-data-cta {
        cursor: default;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 $gp * 2 $gp * 1.5;
    }

    .innovation-illustration {
        width: px-to-rem(60px);
        height: px-to-rem(60px);
    }

    .dismiss-icon {
        --icon-size: #{20px};
        margin: $gp / 2 $gp / 2 0;
    }

    p {
        font-size: $font-size-med;
        margin: $gp 0 $gp / 2;
    }
</style>

<i18n>
{
    "en-us": {
        "message": "See what contacts look like in Keap.",
        "cta": "Add sample contacts"
    }
}
</i18n>
