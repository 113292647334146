export default {
    methods: {
        dom_scrollToRef(ref) {
            window.scrollTo(0, this.$refs[ref].offsetTop - 50);
        },

        dom_isTouchDevice() {
            return 'ontouchstart' in window || navigator.msMaxTouchPoints;
        },

        dom_isRtl() {
            return document.documentElement.dir === 'rtl';
        },

        dom_isMac() {
            return navigator.platform.toLowerCase().includes('mac');
        },
    },
};
