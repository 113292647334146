var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-dropdown"},[_c('ds-multiselect',_vm._b({ref:"company",attrs:{"bind-value-only":"","searchable":"","show-search-icon":"","data-qa":"company","allow-add":_vm.allowAdd,"placeholder":_vm.$t('forms.company'),"options":_vm.searchResults,"add-text":_vm.addCompanyText,"value":_vm.value,"value-prop":_vm.valueProp,"label-prop":"companyName","loading":_vm.loading},on:{"add":_vm.addCompany,"input":_vm.$listeners.input,"updateSearch":_vm.updateSearch},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
var highlight = ref.highlight;
var isSelected = ref.isSelected;
var filter = ref.filter;
return [_c('div',{class:['menu-item', {
                    highlight: highlight,
                    separator: isSelected,
                }]},[(isSelected)?_c('div',{staticClass:"option-row prevent-select",attrs:{"data-qa":option.companyName},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeCompany($event)}}},[_c('ds-checkbox',{attrs:{"value":isSelected},on:{"input":_vm.removeCompany}},[_c('div',{staticClass:"option-text"},[(option.companyName)?_c('ds-text-highlighter',{attrs:{"highlight":filter,"content":option.companyName}}):_vm._e()],1)])],1):_c('div',{staticClass:"option-row prevent-select",attrs:{"data-qa":option.companyName}},[_c('ds-icon',{staticClass:"category-icon",attrs:{"name":"company"}}),_c('div',{staticClass:"option-text"},[(option.companyName)?_c('ds-text-highlighter',{attrs:{"highlight":filter,"content":option.companyName}}):_vm._e()],1)],1)])]}}])},'ds-multiselect',_vm.$attrs,false)),(_vm.overRenderLimit)?_c('ds-popover',{staticClass:"company-help",attrs:{"position":"right","data-qa":"companySearchHelp"},scopedSlots:_vm._u([{key:"reference",fn:function(){return [_c('ds-icon-button',{staticClass:"help-icon",attrs:{"name":"help-circle"}})]},proxy:true}],null,false,1856324091)},[_c('div',{staticClass:"popover-content small"},[_vm._v(" "+_vm._s(_vm.$t('contactList.companies.searchHelp'))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }