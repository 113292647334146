export const PROMO_CODE_EDIT_ROUTE = 'settings.products.promo.codes.edit';
export const PROMO_CODE_CREATE_ROUTE = 'settings.products.promo.codes.new';

export const PROMO_CODE_SUBROUTES = [
    PROMO_CODE_CREATE_ROUTE,
    PROMO_CODE_EDIT_ROUTE,
];

export const DISCOUNT_TYPES = {
    percent: 'PERCENT',
    amount: 'AMOUNT',
};

export const PAYMENT_METHOD_TYPES = {
    creditCard: 'Credit Card',
    cash: 'Cash',
    check: 'Check',
};

export const PAYMENT_REFERENCE_TYPES = {
    product: 'product',
    invoice: 'invoice',
};

export const PROMO_CODE_STATUS = {
    active: 'Active',
    inactive: 'InActive',
};

export const DEFAULT_ROW_LIMIT = 3;

export const DEFAULT_PROMO_CODE = {
    id: 0,
    name: '',
    description: '',
    type: 'PERCENT',
    amount: '',
    status: 'Active',
    spaCriteria: {
        spaDateRangeCriteria: {},
    },
};

export const DEFAULT_PROMO_DATE_RANGE_TIMES = {
    startTime: '0000',
    endTime: '2359',
};
