<template>
    <form
        class="email-form"
        data-qa="email-form"
        @submit.prevent="handleAction"
        @reset.prevent="reset"
    >
        <div class="input-row">
            <label>{{ toLabel }}:</label>

            <div v-if="singleRecipient" class="select-wrapper">
                <email-input v-if="useEmailInput" v-model="recipients" />

                <ds-tooltip v-else-if="hasOneToAddress">
                    <template #reference>
                        <ds-chip data-qa="contact-email-to-input">
                            {{ singleRecipientLabel }}
                        </ds-chip>
                    </template>

                    <template #default>
                        <span>{{ singleRecipientEmail }}</span>
                    </template>
                </ds-tooltip>

                <ds-select-field
                    v-else-if="hasMultipleToAddresses"
                    v-model="to"
                    class="select-wrapper"
                    :options="recipientList"
                    label-prop="fullName"
                    value-prop="email"
                    @input="selectRecipient"
                />

                <contact-search-field
                    v-else-if="hasNoToAddresses"
                    v-model="to"
                    data-qa="contact-email-to-input"
                    allow-add
                    :add-text="$t('addNewContact')"
                    @add="handleAddNewContact"
                    @input="selectRecipient"
                />
            </div>

            <div v-else class="select-wrapper">
                <recipient-list
                    :selected="recipients"
                    :options="unselectedRecipients"
                    @add="addRecipient"
                    @remove="removeRecipient"
                />
            </div>

            <invalid-icon v-if="showRecipientError" :message="recipientErrorMessage" solid-background />
        </div>

        <div v-if="showFrom" class="input-row">
            <label>{{ $t('label.from') }}:</label>

            <div class="select-wrapper">
                <ds-select-field
                    v-model="from"
                    data-qa="contact-email-from-select"
                    :options="fromList"
                />
            </div>
        </div>

        <div class="input-row">
            <label>{{ $t('label.subject') }}:</label>

            <ds-input-field
                v-model="subject"
                data-qa="contact-email-subject-input"
                data-input-label="subject"
            />

            <invalid-icon v-if="showSubjectError" :message="$t('error.subject')" solid-background />
        </div>

        <div class="email-content">
            <email-content
                ref="emailContent"
                v-model="body"
                v-track="'Contacts - contact email - clicked : Body'"
                hide-borders
                :hide-merge="hideMerge"
                :placeholder="$t('messagePlaceholder')"
                :signature-user-id="signatureUserId"
                :show-button="showButton"
                :button-text="buttonText"
                :button-tooltip="buttonTooltip"
                :signature-enabled="signatureEnabled"
                :hide-signature="disableSignature"
            />

            <invalid-icon v-if="showEmailBodyError" :message="$t('error.body')" solid-background />
        </div>

        <div data-qa="contact-email-attachments">
            <inline-error
                v-if="attachmentError"
                :error-text="attachmentError"
            />

            <div
                v-for="(attachment, i) in attachments"
                :key="attachment.id"
                class="attachments-container"
            >
                <file-attachment
                    :attachment="attachment"
                    :index="i"
                    @delete="deleteAttachment"
                />
            </div>
        </div>

        <div v-if="showErrorBanner" class="error-banner-container">
            <error-banner :message="$t('error.banner')" />
        </div>

        <div class="actions">
            <section class="button-row">
                <attachment-button
                    v-if="showAddAttachment"
                    @add-attachment-clicked="addAttachment"
                />

                <appointment-button
                    @setup-appointments="setupAppointments"
                    @appointment-link-insert="insertAppointmentLink"
                    @appointment-button-clicked="handleAppointmentButtonClick"
                />

                <span
                    v-if="googleBusinessListingsLoading"
                    class="icon-spinner"
                    data-qa="review-spinner"
                >
                    <ds-spinner :size="22" />
                </span>

                <review-button
                    v-else-if="googleBusinessListingsSuccess"
                    @review-link-insert="insertReviewLink"
                />

                <ds-guide
                    ref="guide"
                    has-close
                    :show="!templatesTutorialCompleted"
                    position="right"
                    :body-text="$t('guideBody')"
                    :confirm-text="$t('tryEmailTemplates')"
                    @confirm="handleTemplateGuideConfirm"
                    @cancel="completeTemplateGuide"
                >
                    <template #reference>
                        <template-button
                            :templates="sortedEmailTemplates"
                            :template-type="templateType"
                        />
                    </template>
                </ds-guide>
            </section>

            <section class="button-row">
                <ds-filled-button
                    data-name="contact-email-send-button"
                    data-button-label="send"
                    data-qa="send-button"
                    type="submit"
                    :loading="actionPending"
                >
                    {{ actionLabel }}
                </ds-filled-button>
            </section>
        </div>
    </form>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import AddContactModal from '@/contacts/components/add/AddContactModal';
import AppointmentButton from '@/communication/components/AppointmentButton';
import AttachFileModalContent from '@/contacts/components/email/AttachFileModalContent';
import ContactSearchField from '@/contacts/components/ContactSearchField';
import EmailInput from '@/contacts/components/email/EmailInput';
import AttachmentButton from '@/shared/components/Email/AttachmentButton';
import EmailContent from '@/shared/components/Email/EmailContent';
import FileAttachment from '@/shared/components/Email/FileAttachment';
import InlineError from '@/contacts/components/email/InlineError';
import RecipientList from '@/pipeline/components/deal/RecipientList';
import ReviewButton from '@/communication/components/ReviewButton';
import TemplateButton from '@/shared/components/Templates/TemplateButton';
import EmailTemplateManager from '@/shared/components/Templates/EmailTemplateManager';
import InvalidIcon from '@/shared/components/Email/InvalidIcon';
import ErrorBanner from '@/shared/components/Email/ErrorBanner';

import searchContacts from '@/shared/mixins/search-contacts.mixin';
import { MEDIA_TYPES } from '@/shared/constants/communicationTemplates.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { FF_KEAP_GMB } from '@/shared/constants/featureFlag.constants';
import {
    LOADING,
    SUCCESS,
} from '@/contacts/loadingStatuses';

export default {
    components: {
        AppointmentButton,
        AttachmentButton,
        ContactSearchField,
        EmailContent,
        EmailInput,
        FileAttachment,
        InlineError,
        RecipientList,
        ReviewButton,
        TemplateButton,
        InvalidIcon,
        ErrorBanner,
    },

    mixins: [searchContacts],

    props: {
        action: {
            type: Function,
            required: true,
        },
        actionLabel: {
            type: String,
            required: true,
        },
        addAllRecipients: Boolean,
        attachCallback: {
            type: Function,
            required: false,
        },
        buttonText: {
            type: String,
            default: '',
        },
        buttonTooltip: {
            type: Array,
            default: () => [],
        },
        deleteCallback: {
            type: Function,
            required: false,
        },
        disableAttachments: Boolean,
        disableSignature: Boolean,
        fromList: {
            type: Array,
            default: () => [],
        },
        hideMerge: {
            type: Boolean,
            default: true,
        },
        initialBody: String,
        initialSubject: String,
        initialTemplateKind: String,
        lastSentEmail: Object,
        selectedFrom: Object,
        selectedTo: [String, Object],
        shouldReset: {
            type: Boolean,
            default: true,
        },
        templateManagerOptions: {
            type: Object,
            default: () => ({
                shouldOpen: false,
                createTemplateOptions: {
                    title: '',
                    content: '',
                    subject: '',
                },
            }),
        },
        showButton: Boolean,
        singleRecipient: Boolean,
        toList: {
            type: Array,
            default: () => [],
        },
        useEmailInput: Boolean,
    },

    data() {
        return {
            actionPending: false,
            attachmentError: null,
            body: '',
            from: null,
            previousBody: null,
            previousSubject: null,
            recipients: [],
            recipientList: [],
            showErrors: false,
            subject: '',
            templateType: MEDIA_TYPES.HTML,
            to: null,
        };
    },

    mounted() {
        this.load();

        this.$bus.$on('INSERT_COMMUNICATION_TEMPLATE', this.insertTemplate);
        this.$bus.$on('REMOVE_ERROR', this.clearAttachmentError);
        this.$bus.$on('SET_SIGNATURE_ENABLED', this.setSignatureEnabled);
        this.$bus.$on('UPLOAD_ERROR', this.setAttachmentError);

        if (this.templateManagerOptions?.shouldOpen) {
            setTimeout(() => {
                this.openTemplateManager();
            }, 150);
        }
    },

    beforeDestroy() {
        this.reset();

        this.$bus.$off('INSERT_COMMUNICATION_TEMPLATE', this.insertTemplate);
        this.$bus.$off('REMOVE_ERROR', this.clearAttachmentError);
        this.$bus.$off('SET_SIGNATURE_ENABLED', this.setSignatureEnabled);
        this.$bus.$off('UPLOAD_ERROR', this.setAttachmentError);
    },

    watch: {
        body() {
            this.$store.commit('SET_NESTED_MODAL_DIRTY', this.isDirty);
        },

        fromList() {
            this.load();
        },

        initialBody() {
            this.load();
        },

        initialSubject() {
            this.load();
        },

        subject() {
            this.$store.commit('SET_NESTED_MODAL_DIRTY', this.isDirty);
        },

        toList() {
            this.load();
        },
    },

    computed: {
        ...mapState({
            attachments: ({ email }) => email.attachments,
            signatureEnabled: ({ email }) => email.options.signatureEnabled,
            templatesTutorialCompleted: ({ tutorials }) => tutorials.items[TUTORIAL_TYPES.EMAIL_TEMPLATES_BUTTON],
            user: ({ auth }) => auth.user,
            gmbEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_GMB],
            googleBusinessListings: ({ reviews }) => reviews.googleBusinessListings,
            googleBusinessListingsLoadingStatus: ({ reviews }) => reviews.googleBusinessListingsLoadingStatus,
        }),

        ...mapGetters({
            bookingUrl: 'calendar/bookingUrl',
            getUserSignature: 'email/getUserSignature',
            sortedEmailTemplates: 'communicationTemplates/sortedEmailTemplates',
        }),

        allRecipientsHaveEmails() {
            return this.recipients.every((recipient) => recipient.email != null);
        },

        hasNoToAddresses() {
            return this.recipientList.length === 0;
        },

        hasOneToAddress() {
            return this.recipientList.length === 1;
        },

        hasMultipleToAddresses() {
            return this.recipientList.length > 1;
        },

        isDirty() {
            return Boolean(this.subject || this.body);
        },

        recipientErrorMessage() {
            return (this.recipients.length > 0 && !this.allRecipientsHaveEmails)
                ? this.$t('error.noEmail')
                : this.$t('error.recipient');
        },

        showAddAttachment() {
            return !this.disableAttachments && Array.isArray(this.attachments) && this.attachments.length < 5;
        },

        showEmailBodyError() {
            return this.showErrors && !this.isBodyValid;
        },

        showErrorBanner() {
            return this.showErrors && !this.valid;
        },

        showFrom() {
            return this.fromList.length > 1;
        },

        showRecipientError() {
            return this.showErrors && (this.recipients.length === 0 || !this.allRecipientsHaveEmails);
        },

        showSignature() {
            return this.signatureEnabled && !this.disableSignature;
        },

        showSubjectError() {
            return this.showErrors && !this.subject;
        },

        signature() {
            return this.signatureEnabled ? this.getUserSignature(this.signatureUserId) : null;
        },

        singleRecipientEmail() {
            if (this.recipients.length === 0) {
                return '';
            }

            return this.recipients[0].email;
        },

        singleRecipientLabel() {
            if (this.recipients.length === 0) {
                return '';
            }

            return this.recipients[0].fullName || this.recipients[0].email;
        },

        toLabel() {
            return this.$t(this.singleRecipient ? 'label.to' : 'label.recipients');
        },

        unselectedRecipients() {
            return this.recipientList.filter(({ email, id }) => {
                return !this.recipients.some(({ id: selectedId }) => Number(selectedId) === Number(id) && Boolean(email));
            });
        },

        userId() {
            return this.user ? this.user.id : 0;
        },

        signatureUserId() {
            const isFromValid = this.showFrom && this.from && this.from.value > 0;

            return isFromValid ? this.from.value : this.userId;
        },

        isBodyValid() {
            const stripHtml = (str) => str && str.replace(/(<([^>]+)>)/ig, '').trim();

            return Boolean(stripHtml(this.body));
        },

        valid() {
            return this.allRecipientsHaveEmails
                && this.recipients.length > 0
                && Boolean(this.subject)
                && this.isBodyValid;
        },

        googleBusinessListingsLoading() {
            return this.googleBusinessListingsLoadingStatus === LOADING;
        },

        googleBusinessListingsSuccess() {
            return this.googleBusinessListingsLoadingStatus === SUCCESS;
        },
    },

    methods: {
        addContactSuccess(recipient) {
            this.$bus.$emit('CONTACT_LIST_REFRESH');

            this.selectRecipient(this.formatRecipientFullName({ ...recipient }));
        },

        async load() {
            if (this.shouldReset) {
                this.reset();
            }

            if (this.gmbEnabled) {
                if (!this.googleBusinessListingsSuccess) {
                    this.$store.dispatch('reviews/INITIALIZE_GOOGLE_MY_BUSINESS_LISTINGS');
                }
            } else {
                this.$store.dispatch('reviews/SAVE_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', SUCCESS);
            }

            this.loadRecipients();
            await this.loadContent();
        },

        loadRecipients() {
            this.recipientList = this.toList.map((recipient) => ({
                ...recipient,
                fullName: recipient.fullName || `${recipient.firstName || ''} ${recipient.lastName || ''}`.trim(),
            }));

            if (this.recipientList.length === 1 || this.addAllRecipients) {
                this.recipients = this.recipientList;
            }

            if (this.fromList.length === 1) {
                [this.from] = this.fromList;
            }

            if (this.selectedFrom) {
                this.from = this.selectedFrom;
            }

            if (this.selectedTo) {
                this.selectRecipient(this.selectedTo);
            }
        },

        async loadContent() {
            const {
                initialBody,
                initialSubject,
                initialTemplateKind,
                lastSentEmail,
            } = this;

            if (initialTemplateKind) {
                await this.$store.dispatch('communicationTemplates/LOAD_EMAIL_TEMPLATES');

                const initialTemplate = this.sortedEmailTemplates.find(({ templateKind }) => templateKind === initialTemplateKind);

                if (initialTemplate) {
                    this.subject = initialTemplate.subject;
                    this.body = initialTemplate.content;
                    this.setEmailBody(initialTemplate.content);
                }
            } else {
                this.$store.dispatch('communicationTemplates/LOAD_EMAIL_TEMPLATES');

                this.subject = initialSubject;
                this.body = initialBody;
                this.setEmailBody(initialBody);
            }

            if (lastSentEmail != null) {
                if (Array.isArray(lastSentEmail.attachments)) {
                    lastSentEmail.attachments.forEach((attachment) => {
                        this.$store.commit('email/ADD_ATTACHMENT', attachment);
                    });
                }

                this.$store.dispatch('email/GET_USER_SIGNATURE', this.signatureUserId);
                this.subject = lastSentEmail.email.subject;
                this.body = lastSentEmail.email.body;
                this.setEmailBody(lastSentEmail.email.body);
            }
        },

        reset() {
            this.actionPending = false;
            this.showErrors = false;
            this.$store.commit('SET_NESTED_MODAL_DIRTY', false);
            this.$store.commit('email/DELETE_ATTACHMENTS');

            if (this.$refs.emailContent && typeof this.$refs.emailContent.clear === 'function') {
                this.$refs.emailContent.clear();
            }

            this.clearAttachmentError();
        },

        addAttachment() {
            const payload = { component: AttachFileModalContent };

            if (typeof this.attachCallback === 'function') {
                payload.props = { callback: this.attachCallback };
            }

            this.clearAttachmentError();
            this.$bus.$emit('PUSH_NESTED_MODAL', payload);
        },

        addRecipient(recipient) {
            this.recipients.push(recipient);
        },

        clearAttachmentError() {
            this.attachmentError = null;
        },

        completeTemplateGuide() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.EMAIL_TEMPLATES_BUTTON,
                value: true,
                forUser: true,
            });
        },

        async deleteAttachment({ id, index }) {
            const message = this.$t('error.deleteAttachment');

            try {
                await this.$store.dispatch('contacts/DELETE_CONTACT_FILE', id);

                this.$store.commit('email/DELETE_ATTACHMENT', index);

                if (typeof this.deleteCallback === 'function') {
                    this.deleteCallback(id);
                }
            } catch (error) {
                this.$error({ message });
            }
        },

        async handleAction() {
            if (!this.valid) {
                this.showErrors = true;
            } else {
                const {
                    body,
                    from,
                    recipients,
                    signature,
                    subject,
                } = this;

                this.actionPending = true;

                await this.action({
                    body,
                    from: from.label,
                    userId: from.value,
                    fromObj: from,
                    recipients,
                    signature,
                    subject,
                });

                this.actionPending = false;
                this.reset();
                this.$bus.$emit('POP_NESTED_MODAL');
            }
        },

        handleAppointmentButtonClick() {
            this.$track('Contacts - contact email - clicked: Appointment Button');
        },

        handleTemplateGuideConfirm() {
            this.completeTemplateGuide();
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: EmailTemplateManager,
                modalSize: 'xl',
            });
        },

        insertAppointmentLink(appointmentType) {
            this.$track('Contacts - contact email - clicked: Inserted appointment link');

            const apptUrl = this.bookingUrl(appointmentType);
            const apptLink = `<a href="${apptUrl}" target="_blank" rel="noopener noreferrer">${apptUrl}</a>`;

            this.$refs.emailContent.insertValueAtCursor(apptLink, true);
        },

        insertReviewLink(link) {
            this.$track('Contacts - contact email - clicked: Inserted review link');

            const reviewLink = `<a href="${link}" target="_blank" rel="noopener noreferrer">${link}</a>`;

            this.$refs.emailContent.insertValueAtCursor(reviewLink, true);
        },

        // eslint-disable-next-line
        insertTemplate({ content, subject, id, title }) {
            this.previousSubject = this.subject || '';
            this.previousBody = this.body || '';

            if (this.subject || this.body) {
                this.$toast({
                    message: this.$t('templateInserted'),
                    action: this.undoInsertTemplate,
                    actionText: this.$t('undo'),
                    timer: 10000,
                });
            }

            this.subject = subject;
            this.setEmailBody(content);

            this.$emit('template-inserted', { id, title });
        },

        openTemplateManager() {
            const { source, templateManagerOptions } = this;

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: EmailTemplateManager,
                props: {
                    source,
                    templateManagerOptions,
                },
                modalSize: 'xl',
            });
        },

        removeRecipient(recipient) {
            this.recipients = this.recipients.filter(({ id }) => recipient.id !== id);
        },

        selectRecipient(recipient) {
            this.to = recipient;
            this.recipients = [recipient];
        },

        setAttachmentError({ error }) {
            this.attachmentError = error;
        },

        setEmailBody(body) {
            if (this.$refs.emailContent != null && typeof this.$refs.emailContent.setContent === 'function') {
                this.$refs.emailContent.setContent(body);
            }
        },

        setSignatureEnabled(value) {
            this.$store.commit('email/SET_SIGNATURE_ENABLED', value);
        },

        setupAppointments() {
            this.$router.push({ name: 'calendar' });
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        undoInsertTemplate() {
            this.subject = this.previousSubject;
            this.setEmailBody(this.previousBody);

            this.previousSubject = '';
            this.previousBody = '';
        },

        handleAddNewContact() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddContactModal,
                showRootClose: true,
                props: {
                    emailRequired: true,
                },
                data: {
                    hideSuccessModal: true,
                    successCallback: this.addContactSuccess,
                },
            });
        },

        formatRecipientFullName(recipient) {
            return {
                ...recipient,
                fullName: recipient?.fullName || `${recipient?.givenName || ''} ${recipient?.familyName || ''}`.trim(),
            };
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .email-form {
        --input-margin-bottom: 0;
        --input-padding-left: 0;
        --input-border: none;
        --input-focus-border: none;
        --select-field-focus-border: none;
        --quill-editor-border-radius: 0;
        --quill-editor-bottom-border-color: transparent;
        --multiselect-input-border: none;
    }

    input,
    textarea {
        border: 0;
        background-color: transparent;
        flex: 1;
    }

    .input-row {
        position: relative;
        display: flex;
        border-bottom: 1px solid $color-gray-200;
        align-items: center;
        padding: $gp / 2 $gp;
        height: px-to-rem(50px);

        label {
            @include margin-end($gp / 2);
            color: $color-gray-700;
            font-weight: $font-weight-regular;
        }

        input {
            flex: 1;
        }
    }

    .select-wrapper {
        width: 100%;
    }

    .icon-spinner {
        padding-top: $gp / 2;
    }

    .email-content {
        position: relative;
    }

    .attachments-container {
        margin-top: $gp / 2;
        padding: 0 $gp;
    }

    .add-attachment-container {
        display: flex;
    }

    .add-attachment-icon {
        --icon-color: #{$color-blue};
        --icon-size: #{px-to-rem(20px)};
        @include margin-end($gp / 2);
    }

    .attachment-error {
        align-items: center;
        border-radius: $border-radius;
        border: 1px solid $color-red;
        color: $color-red;
        display: flex;
        margin-bottom: $gp;
    }

    .error-banner-container {
        padding: $gp;
    }

    .actions {
        display: flex;
        justify-content: space-between;
        padding: $gp;
    }

    .action-button-tooltip {
        --tooltip-width: #{px-to-rem(200px)};
    }
</style>

<i18n>
{
    "en-us": {
        "label": {
            "from": "From",
            "recipients": "Recipients",
            "subject": "Subject",
            "to": "To"
        },
        "addNewContact": "Add new contact",
        "messagePlaceholder": "Type your message here",
        "templateInserted": "Template inserted",
        "undo": "Undo",
        "guideBody": "Stop typing the same message over and over.",
        "tryEmailTemplates": "Try email templates",
        "error": {
            "deleteAttachment": "There was an error while deleting your attachment. Please try again.",
            "recipient": "Oops, you need to add a valid recipient",
            "noEmail": "Oops, this recipient doesn't have an email address",
            "subject": "Oops, you need to add a subject line",
            "body": "Oops, you need to add content",
            "banner": "You're missing some information. Once you correct the issues, you can send your email."
        }
    }
}
</i18n>
