<template>
    <div ref="container" class="templates-container">
        <div v-if="templates.length === 0 && isLoading" class="templates-spinner-container">
            <ds-spinner />
        </div>

        <template-gallery-filters
            v-if="!unlayerTemplateAdminEnabled && !isLoading"
            v-model="industry"
            @update-industry="updateIndustry"
        />

        <div class="templates">
            <ds-card class="start-from-scratch">
                <div>
                    <ds-illustration name="notes-gray" />
                </div>

                <strong>{{ $t('startFromScratchText') }}</strong>

                <ds-outline-button data-qa="template-blank" @click="startFromScratch">
                    {{ $t('startFromScratchLabel') }}
                </ds-outline-button>
            </ds-card>

            <template v-for="template in templates">
                <template-card
                    v-if="unlayerTemplateAdminEnabled || template.previewUrl"
                    :key="template.id"
                    :template="template"
                    :data-qa="`${dataQaName.concat(template.id)}`"
                    :is-previewing="template.id === previewTemplateId"
                    @select="handleTemplateSelected(template.id)"
                    @preview="handleTemplatePreview(template.id)"
                />
                <template-html-card
                    v-else
                    :key="template.id"
                    :template="template"
                    :data-qa="`global-template-${template.id}`"
                    :is-previewing="template.id === previewTemplateId"
                    @select="handleTemplateSelected(template.id)"
                    @preview="handleTemplatePreview(template.id)"
                />
            </template>

            <template v-if="isLoading && templates.length > 0">
                <template-placeholder-card
                    v-for="n in pageSize"
                    :key="`placeholder-${n}`"
                />
            </template>
        </div>

        <scroll-target v-if="!isLoading && !isFullyLoaded" :root="$refs.container" @intersect="fetchPage" />
        <template-preview
            :preview-html="previewTemplateHtml"
            :preview-name="previewName"
            allow-selection
            @close="closeTemplatePreview"
            @select="handleSelectPreview"
        />
    </div>
</template>

<script>
import {
    getGlobalTemplate,
    getUnlayerSiteTemplate,
    listGlobalTemplates,
    listUnlayerSiteTemplates,
} from '@/marketingSites/api/sites-datasource-graphql';

import TemplatePlaceholderCard from './TemplatePlaceholderCard';
import TemplatePreview from '@/marketingSites/components/wizard/TemplatePreview';
import TemplateCard from '@/marketingSites/components/wizard/TemplateCard';
import TemplateHtmlCard from '@/marketingSites/components/wizard/TemplateHtmlCard';
import {
    FF_KEAP_LANDING_PAGES_MAX,
    FF_KEAP_UNLAYER_EMAIL_TEMPLATE_ADMIN,
} from '@/shared/constants/featureFlag.constants';
import TemplateGalleryFilters from '@/marketingSites/components/wizard/TemplateGalleryFilters';
import { mapGetters, mapState } from 'vuex';
import { MARKETING_SITE_TEMPLATE_TYPES } from '@/marketingSites/provider/site-provider.utils';
import { createBlankSiteUnlayerSiteTemplate } from '@/marketingSites/unlayer/unlayer.constants';

const ScrollTarget = {
    props: {
        root: HTMLElement,
    },

    mounted() {
        const options = {
            root: this.root,
            rootMargin: '0px 0px 800px 0px',
        };

        const callback = (entries) => {
            const entry = entries[0];

            if (entry.isIntersecting) {
                this.$emit('intersect');
            }
        };

        this.intersectionObserver = new IntersectionObserver(callback, options);
        this.intersectionObserver.observe(this.$el);
    },

    beforeDestroy() {
        this.intersectionObserver?.observe(this.$el);
    },

    render(h) {
        return h('div');
    },
};

export default {
    components: {
        TemplateCard,
        TemplateHtmlCard,
        TemplatePreview,
        TemplatePlaceholderCard,
        TemplateGalleryFilters,
        ScrollTarget,
    },

    data() {
        return {
            nextPageToken: null,
            pageSize: 50,
            templates: [],
            isLoading: false,
            isFullyLoaded: false,
            error: null,
            previewTemplateHtml: null,
            previewTemplateId: '',
            previewTemplate: null,
            industry: '',
        };
    },

    created() {
        this.fetchPage();
    },

    computed: {
        previewName() {
            return this.previewTemplate?.name;
        },

        dataQaName() {
            return this.unlayerTemplateAdminEnabled ? 'unlayer-template-' : 'global-template-card-';
        },

        ...mapState({
            unlayerTemplateAdminEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_UNLAYER_EMAIL_TEMPLATE_ADMIN],
            isUnlayerLandingPageMaxEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_LANDING_PAGES_MAX],
        }),

        ...mapGetters({
            hasUnlayerLandingPageMaxFeature: 'auth/hasUnlayerLandingPageMaxFeature',
        }),

        isUnlayerLandingPageMax() {
            return this.isUnlayerLandingPageMaxEnabled && this.hasUnlayerLandingPageMaxFeature;
        },

        templateMedia() {
            const media = [];

            media.push('LANDING_PAGE');

            if (this.isUnlayerLandingPageMax) {
                media.push('FUNNEL');
            }

            return media;
        },
    },

    methods: {
        async fetchPage() {
            this.isLoading = true;

            try {
                const { nextPageToken: pageToken, pageSize } = this;

                let data;

                if (this.unlayerTemplateAdminEnabled) {
                    data = await listUnlayerSiteTemplates({ pageSize, pageToken });
                } else {
                    const industryFilter = this.industry === 'All' ? '' : this.industry;

                    data = await listGlobalTemplates({
                        pageToken, industry: industryFilter, media: this.templateMedia, usage: '',
                    });
                }
                const { templates, nextPageToken } = data;

                this.templates.splice(0);
                this.templates.push(...templates);
                this.nextPageToken = nextPageToken;
                this.isFullyLoaded = true;
            } catch (error) {
                this.error = error;
            }

            this.isLoading = false;
        },

        handleSelectPreview() {
            this.handleTemplateSelected(this.previewTemplateId);
        },

        async handleTemplateSelected(templateId) {
            const { unlayer, global } = MARKETING_SITE_TEMPLATE_TYPES;
            const isGoldenApp = this.unlayerTemplateAdminEnabled;
            const templateType = isGoldenApp ? unlayer : global;

            if (templateId) {
                this.$emit('select-pending', { templateId, templateType });

                if (this.unlayerTemplateAdminEnabled) { // is golden app
                    const templateSource = await getUnlayerSiteTemplate(templateId);

                    this.$emit('select', { templateSource, templateType: unlayer });
                } else {
                    const templateSource = await getGlobalTemplate(templateId);

                    this.$emit('select', { templateSource, templateType: global });
                }
            }
        },

        startFromScratch() {
            const templateSource = createBlankSiteUnlayerSiteTemplate({
                defaultSiteName: this.$t('marketingSites.defaultName'),
                defaultThankYouPageName: this.$t('marketingSites.defaultThankYouPageName'),
                defaultLandingPageName: this.$t('marketingSites.defaultPageName'),
            });

            this.$emit('select', { templateSource, templateType: MARKETING_SITE_TEMPLATE_TYPES.blank });
        },

        closeTemplatePreview() {
            this.previewTemplateHtml = null;
            this.previewTemplateId = null;
            this.previewTemplate = null;
        },

        async handleTemplatePreview(templateId) {
            if (templateId) {
                try {
                    let data;

                    if (this.unlayerTemplateAdminEnabled) {
                        data = await getUnlayerSiteTemplate(templateId);

                        this.previewTemplate = data;
                        this.previewTemplateId = data.id;
                        this.previewTemplateHtml = data?.pages[0]?.html;
                    } else {
                        data = await getGlobalTemplate(templateId);

                        this.previewTemplate = data;
                        this.previewTemplateId = templateId;
                        this.previewTemplateHtml = data.html;
                    }
                } finally {
                    this.isPreviewing = false;
                }
            }
        },

        updateIndustry(value) {
            this.industry = value;
            this.fetchPage();
        },
    },
};
</script>

<style lang="scss" scoped>
$card-size: px-to-rem(300);

.templates-container {
    flex: 1;
    padding: $gp * 2;
    @include overflowY;
}

.templates {
    display: grid;
    grid-template-columns: repeat(auto-fill, $card-size);
    grid-gap: $gp * 3 / 2;
    justify-content: center;

    &> * {
        width: $card-size;
        height: $card-size;
    }
}

.templates-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.start-from-scratch {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $gp * 2;

    strong {
        margin: $gp 0;
        text-align: center;
        font-size: $font-size-lg;
    }
}
</style>

<i18n>
{
    "en-us": {
        "startFromScratchLabel": "Start from scratch",
        "startFromScratchText": "Don't want to start from a template?"
    }
}
</i18n>
