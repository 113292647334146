export default {
    tagOptions({ tags }) {
        return tags.map(({ category, name, id }) => {
            const e = document.createElement('div');
            const catAndName = (category ? `${category.name} -> ` : '') + name;

            e.innerHTML = catAndName;

            return {
                value: id,
                label: e.childNodes[0] && e.childNodes[0].nodeValue ? e.childNodes[0].nodeValue : catAndName,
            };
        }).sort((firstTag, secondTag) => {
            return firstTag.label < secondTag.label ? -1 : firstTag.label > secondTag.label ? 1 : 0;
        });
    },

    tagCategoryOptions({ tagCategories }) {
        return tagCategories.map(({ name, id }) => {
            return {
                value: id,
                label: name,
            };
        }).sort((firstCategory, secondCategory) => {
            return firstCategory.label < secondCategory.label ? -1 : firstCategory.label > secondCategory.label ? 1 : 0;
        });
    },

    tagById: ({ tags }) => (id) => {
        return tags.find((tag) => {
            // eslint-disable-next-line eqeqeq
            return tag.id == id;
        });
    },
};
