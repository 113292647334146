import { mapState, mapGetters } from 'vuex';

import SingleEmailModal from '@/contacts/components/email/SingleEmailModal';
import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';

import { CONTACT_RECORD } from '@/contacts/contactEmailTypes';
import {
    FF_KEAP_GMB,
} from '@/shared/constants/featureFlag.constants';

export default {
    mixins: [closeConfirmation],

    computed: {
        ...mapState({
            googlePlaceId: ({ global }) => global.googlePlaceId,
            gmbEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_GMB],
            googleReviewLink: ({ reviews }) => reviews.googleReviewLink,
        }),

        ...mapGetters({
            isOverlayActive: 'isOverlayActive',
        }),

        reviews_reviewUrl() {
            if (this.gmbEnabled) {
                return this.googleReviewLink || null;
            }

            return this.googlePlaceId
                ? `https://search.google.com/local/writereview?placeid=${this.googlePlaceId}`
                : null;
        },

        reviews_reviewLinkText() {
            return `<p><a href="${this.reviews_reviewUrl}">${this.reviews_reviewUrl}</a></p>`;
        },
    },

    methods: {
        reviews_requestReview() {
            this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);

            if (!this.googlePlaceId && !this.gmbEnabled) {
                this.$error({ message: this.$t('errors.reviews.placeId') });

                return;
            }

            this.$track('Reviews - clicked : Request Review');

            const initialSubject = this.$t('reviews.emailSubject');

            let initialBody = '';

            initialBody += `<p>${this.$t('reviews.emailBodyLine1')}</p>`;
            initialBody += `<p>${this.$t('reviews.emailBodyLine2')}</p>`;
            initialBody += this.reviews_reviewLinkText;

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SingleEmailModal,
                showRootClose: !this.isOverlayActive,
                props: {
                    title: this.$t('reviews.emailTitle'),
                    initialSubject,
                    initialBody,
                    source: CONTACT_RECORD,
                },
                interceptClose: () => this.confirmCloseDraft(() => this.$bus.$emit('POP_NESTED_MODAL')),
            });
        },
    },
};
