const AutomationsPage = () => import(/* webpackChunkName: "automations" */ '@/automations/pages/AutomationsPage');
const AutomationsRedirectPage = () => import(/* webpackChunkName: "automations" */ '@/automations/pages/AutomationsRedirectPage');
const BuildAutomationPage = () => import(/* webpackChunkName: "automations" */ '@/automations/pages/BuildAutomationPage');
const BuildAutomationFromTemplatePage = () => import(/* webpackChunkName: "automations" */ '@/automations/pages/BuildAutomationFromTemplatePage');
const BuildTemplatePage = () => import(/* webpackChunkName: "automations" */ '@/automations/pages/BuildTemplatePage');

import { ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS } from '@/settings/settings.constants';
import { CAMPAIGN_BUILDER_FEATURE, EASY_AUTOMATION_FEATURE } from '@/shared/constants/features.constants';

const routes = [
    {
        path: '/automations',
        name: 'automations',
        component: AutomationsRedirectPage,
        meta: {
            title: 'nav.automations',
            anyFeature: [CAMPAIGN_BUILDER_FEATURE, EASY_AUTOMATION_FEATURE],
        },
    },
    {
        path: '/automations/advanced',
        name: 'automations.advanced',
        component: AutomationsPage,
        meta: {
            title: 'nav.automations',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
            feature: CAMPAIGN_BUILDER_FEATURE,
        },
    },
    {
        path: '/automations/easy',
        name: 'automations.easy',
        component: AutomationsPage,
        meta: {
            title: 'nav.automations',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
            feature: EASY_AUTOMATION_FEATURE,
        },
        children: [
            {
                path: ':automationId/quickview',
                name: 'automations.easy.quickview',
                component: AutomationsPage,
                meta: {
                    title: 'nav.automations',
                    hideHeaderOnMobile: true,
                    permission: ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
                    feature: EASY_AUTOMATION_FEATURE,
                },
            },
            {
                path: ':automationId/participants',
                name: 'automations.easy.participants',
                component: AutomationsPage,
                meta: {
                    title: 'nav.automations',
                    hideHeaderOnMobile: true,
                    permission: ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
                    feature: EASY_AUTOMATION_FEATURE,
                },
            },
        ],
    },
    {
        path: '/automations/build/:id',
        name: 'build-automation',
        component: BuildAutomationPage,
        meta: {
            title: 'nav.automations',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
            feature: EASY_AUTOMATION_FEATURE,
        },
    },
    {
        path: '/automations/template/:id',
        name: 'build-from-template',
        component: BuildAutomationFromTemplatePage,
        meta: {
            title: 'nav.automations',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
            feature: EASY_AUTOMATION_FEATURE,
        },
    },
    {
        path: '/automations/template/build/:id',
        name: 'build-template',
        component: BuildTemplatePage,
        meta: {
            title: 'nav.automations',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
            feature: EASY_AUTOMATION_FEATURE,
        },
    },
];

export default routes;
