<template>
    <div :class="['when-card', { 'configured': triggerSelected, active, inactive }]" @click="$emit('selected')">
        <header class="header">
            <ds-icon class="cloud-icon" name="cloud-lightning" />

            <span class="header-text">{{ $t('header') }}</span>
        </header>

        <div v-if="!triggerSelected" class="description">
            <p class="description-title">
                {{ $t('description') }}
            </p>

            <ds-icon-button class="add-button" name="add-fill" />
        </div>

        <div v-else class="description">
            <div v-for="(trigger, i) in triggers" :key="i" class="trigger-container">
                <invalid-icon v-if="isInvalid(trigger)" class="invalid-icon" />

                <automation-display-name
                    :class="['description-title', { 'alert-text': isInvalid(trigger) }]"
                    :value="trigger"
                    :data-qa="`trigger_${i}`"
                    :capitalize="shouldCapitalize(trigger)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { isValidTrigger } from '@/automations/utils/automations.utils';
import InvalidIcon from '@/shared/components/Email/InvalidIcon';
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import { HELLOSIGN_DOCUMENT_SIGNED } from '@/automations/constants/automations.constants';

export default {
    components: {
        AutomationDisplayName,
        InvalidIcon,
    },

    props: {
        triggers: {
            type: Array,
            default: () => ([]),
        },
        active: Boolean,
        inactive: Boolean,
        showErrors: Boolean,
    },

    computed: {
        triggerSelected() {
            return this.triggers.length;
        },
    },

    methods: {
        isInvalid(trigger) {
            return this.showErrors && !isValidTrigger(trigger);
        },

        shouldCapitalize(trigger) {
            return trigger?.type === HELLOSIGN_DOCUMENT_SIGNED;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/whenThenCard';
</style>

<i18n>
{
    "en-us": {
        "header": "When",
        "description": "...this occurs, start my automation."
    }
}
</i18n>
