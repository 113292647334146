<template>
    <ds-tooltip
        position="top"
        :disabled="!message"
        :class="['invalid-icon', { 'solid-background': solidBackground }]"
    >
        <template #reference>
            <ds-icon name="alert-octagon" />
        </template>

        <div>{{ message }}</div>
    </ds-tooltip>
</template>

<script>
export default {
    props: {
        message: String,
        solidBackground: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    .invalid-icon {
        --icon-color: #{$color-red-500};
        position: absolute !important; // NOTE: Container must be `position: relative` to render properly
        z-index: 3;
        top: 50%;
        left: 100%;
        transform: translate(-200%, -50%);

        &.solid-background {
            background-color: $color-paper;
        }
    }
</style>
