<template lang="html">
    <mobile-app-cta
        :title="$t('title')"
        :desktop-title="$t('desktopTitle')"
        :illustration-path="`images/dashboard/keap-mobile-app.svg`"
    />
</template>

<script>
import MobileAppCta from '@/import/components/MobileAppCTA';

export default {
    components: { MobileAppCta },

    data() {
        return {
            nestedModal_title: this.$t('modalTitle'),
        };
    },
};
</script>

<i18n>
{
    "en-us": {
        "modalTitle": "Download the Keap app",
        "title": "Get the Keap mobile app to quickly import contacts",
        "desktopTitle": "Save time importing contacts with the Keap mobile app"
    }
}
</i18n>
