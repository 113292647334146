<template>
    <controlled-modal
        :is-open="isOpen"
        size="med"
        show-close
        @close="close"
    >
        <template #default>
            <div class="content">
                <div class="modal-text">
                    <div class="ehawk-header">
                        {{ ehawkModalTitle }}
                    </div>

                    <span v-if="ehawkModalBody">
                        {{ ehawkModalBody }}
                    </span>
                    <i18n
                        v-else
                        path="modalDescription"
                        tag="span"
                    >
                        <template #link>
                            <a
                                class="add-details"
                                target="_blank"
                                rel="noopener noreferrer"
                                data-qa="no-details"
                                :href="$options.deliverLink"
                            >
                                {{ $t('descriptionLink') }}
                            </a>
                        </template>
                    </i18n>
                </div>

                <form v-if="!ehawkFormSubmitted" @submit.prevent="submit">
                    <ds-input-field
                        v-model="websiteField"
                        name="required"
                        :label="$t('businessWebsite')"
                        required
                        data-qa="website"
                    />

                    <ds-input-field
                        v-model="servicesField"
                        name="required"
                        :label="$t('businessInfo')"
                        required
                        data-qa="business-info"
                    >
                        <template #help>
                            {{ $t('businessInfoHelper') }}
                        </template>
                    </ds-input-field>

                    <ds-filled-button type="submit" data-qa="submit-ehawk">
                        {{ $t('submit') }}
                    </ds-filled-button>
                </form>

                <div v-else class="success-message">
                    <div class="success-content">
                        <ds-icon name="check-circle" class="check-icon" />

                        <div>{{ $t('formSubmitted') }}</div>
                    </div>
                </div>
            </div>

            <div class="help-section">
                <i18n
                    path="help"
                    tag="span"
                    class="modal-text"
                >
                    <template #phone>
                        <a :href="`tel:${$options.phoneNumber}`">{{ $options.phoneNumber }}</a>
                    </template>
                </i18n>
            </div>
        </template>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';
import { SLACK_CHANNELS } from '@/shared/constants/slack.constants';
import { mapState } from 'vuex';

const deliverLink = 'https://keap.com/resources/maximize-your-email-deliverability';
const phoneNumber = '+1 480 372 9520';

export default {
    deliverLink,
    phoneNumber,

    components: {
        ControlledModal,
    },

    props: {
        isOpen: Boolean,
    },

    data() {
        return {
            websiteField: '',
            servicesField: '',
        };
    },

    beforeDestroy() {
        this.close();
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            ehawkPassed: ({ ehawk }) => ehawk.passed,
            ehawkChecked: ({ ehawk }) => ehawk.checked,
            ehawkFormSubmitted: ({ ehawk }) => ehawk.formSubmitted,
            ehawkModalTitle: ({ ehawk }) => ehawk.modalTitle,
            ehawkModalBody: ({ ehawk }) => ehawk.modalBody,
        }),
    },

    methods: {
        close() {
            this.$emit('close');
            this.websiteField = '';
            this.servicesField = '';
        },

        async submit() {
            const encoded = encodeURI(this.user.email);

            const businessInfo = [
                `The following person was blocked by <https://portal.ehawk.net/vet/search?et=email&exact-search=yes&search_keyword=${encoded}%7CE-Hawk|eHawk> _or_ reCAPTCHA and submitted the form for us to reconsider their free trial request.`,
                `Website: ${this.websiteField}`,
                `Products/Services: ${this.servicesField}`,
                `Customer Name: ${this.user.fullName}`,
                `Customer Email: ${this.user.email}`,
            ];

            await this.$slack(SLACK_CHANNELS.FREE_TRIAL_RECONSIDERATION, {
                pretext: ':crossed_fingers: Free Trial Reconsideration Request',
                text: businessInfo.join('\n'),
                user: this.user,
            });

            await this.$store.dispatch('ehawk/SAVE_EHAWK_FORM_SUBMISSION');
        },
    },
};
</script>

<style lang="scss" scoped>
    .ehawk-header {
        font-size: $font-size-huge;
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        margin-bottom: $gp;
    }

    .modal-text {
        padding-bottom: $gp * 1.5;
        text-align: center;
    }

    .helper-text {
        font-size: $font-size-xs;
        color: $color-gray-800;
        margin-bottom: $gp / 2;
    }

    .send-button {
        @include margin(0 $gp / 2 0 auto);
    }

    .content {
        display: block;
        align-self: center;
        max-width: px-to-rem(432px);
        padding-bottom: $gp;
    }

    .help-section {
        max-width: px-to-rem(150px);
        align-self: center;
        text-align: center;
        color: $color-gray-800;
    }

    .check-icon {
        color: #5FC15D;
    }

    .success-message {
        justify-content: center;
        display: flex;
    }

    .success-content {
        --icon-margin: #{-$gp / 4} 0 0;
        max-width: px-to-rem(225px);
        padding: $gp / 2;
        background-color: #E8F6E8;
        border-radius: 10px;
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "modalDescription": "Before you send emails, we need to know a little bit more about your business. This is to help you {link} Share details with us below and someone will get right back to you.",
        "descriptionLink": "successfully deliver emails to the right inbox.",
        "submit": "Submit",
        "businessWebsite": "Your business website",
        "businessInfo": "Products or services you provide",
        "businessInfoHelper": "e.g. Tree trimming",
        "help": "Need help right now? Call Keap support at {phone}",
        "formSubmitted": "Form submitted successfully. We’ll get back to you soon."
    }
}
</i18n>
