<template>
    <controlled-modal
        class="s"
        :is-open="isOpen"
        show-close
        size="med"
        :title="title"
        @close="close"
    >
        <code-embedder
            :embed-content="embedContent"
            :help-url="helpUrl"
            :source="source"
            :heading="heading"
            :content-type="contentType"
        />
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';
import CodeEmbedder from '@/shared/components/Embed/CodeEmbedder.vue';

export default {
    components: {
        ControlledModal,
        CodeEmbedder,
    },

    props: {
        embedContent: String,
        helpUrl: String,
        isOpen: Boolean,
        title: String,
        source: String,
        heading: String,
        contentType: String,
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
