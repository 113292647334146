<template lang="html">
    <div class="main-nav-links" data-qa="main-nav-links">
        <ul>
            <li v-for="tab in navItems" :key="tab.label">
                <nav-item
                    v-if="!tab.hidden"
                    :extra-label="tab.extraLabel"
                    :icon="tab.icon"
                    :label="tab.label"
                    :show-icon-badge="tab.showIconBadge"
                    :to="tab.to"
                    :expanded="navOpen"
                    :data-qa="`nav-item-${tab.id}`"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import NavItem from '@/nav/components/NavItem';
import {
    ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
    ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS,
    ROLE_NAVIGATION_CAN_ACCESS_REPORTING,
} from '@/settings/settings.constants';
import {
    FF_KEAP_SINGLE_PIPELINE,
} from '@/shared/constants/featureFlag.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { PRO } from '@/nav/components/FeatureLabel';

export default {
    components: {
        NavItem,
    },

    computed: {
        ...mapState({
            navOpen: ({ global }) => global.navOpen,
            showSinglePipeline: ({ featureFlags }) => featureFlags[FF_KEAP_SINGLE_PIPELINE],
            tutorials: ({ tutorials }) => tutorials.items,
            showGettingStartedBadge: ({ onboarding }) => onboarding.showBadge,
        }),

        ...mapGetters({
            isPaid: 'auth/isPaid',
            hasOnboardingAndGettingStartedFeature: 'auth/hasOnboardingAndGettingStartedFeature',
            hasAppointmentsFeature: 'auth/hasAppointmentsFeature',
            hasInvoicesAndQuotesFeature: 'auth/hasInvoicesAndQuotesFeature',
            hasEmailBroadcastFeature: 'auth/hasEmailBroadcastFeature',
            hasSmsBroadcastFeature: 'auth/hasSmsBroadcastFeature',
            hasEasyAutomationsFeature: 'auth/hasEasyAutomationsFeature',
            hasCampaignBuilderFeature: 'auth/hasCampaignBuilderFeature',
            hasSinglePipelineFeature: 'auth/hasSinglePipelineFeature',
            hasMultiplePipelinesFeature: 'auth/hasMultiplePipelinesFeature',
            hasReportsFeature: 'auth/hasReportsFeature',
            hasAnalyticsFeature: 'auth/hasAnalyticsFeature',
            hasPermission: 'auth/hasPermission',
            unlayerBuilderEnabled: 'broadcasts/unlayerBuilderEnabled',
            hasUnreadMessages: 'communication/hasUnreadMessages',
            showCommunicationCenter: 'communication/showCommunicationCenter',
        }),

        navItems() {
            return [
                {
                    id: 'getting-started',
                    to: '/getting-started',
                    icon: 'lightbulb',
                    label: this.$t('nav.gettingStarted'),
                    showIconBadge: this.showGettingStartedBadge,
                    hidden: this.tutorials[TUTORIAL_TYPES.ONBOARDING.GETTING_STARTED_COMPLETED] || !this.hasOnboardingAndGettingStartedFeature,
                },
                {
                    id: 'dashboard',
                    to: '/dashboard',
                    icon: 'home',
                    label: this.$t('nav.dashboard'),
                },
                {
                    id: 'contacts',
                    to: '/contacts',
                    icon: 'user',
                    label: this.$t('nav.contacts'),
                },
                {
                    id: 'communication',
                    to: '/communication',
                    icon: 'message',
                    label: this.$t('nav.communication'),
                    showIconBadge: this.hasUnreadMessages,
                    hidden: !this.showCommunicationCenter,
                },
                {
                    id: 'tasks',
                    to: '/tasks',
                    icon: 'tasks',
                    label: this.$t('nav.tasks'),
                },
                {
                    id: 'appointments',
                    to: '/appointments',
                    icon: 'calendar',
                    label: this.$t('nav.calendar'),
                    hidden: !this.hasAppointmentsFeature,
                },
                {
                    id: 'billing',
                    to: '/sales',
                    icon: 'sales',
                    label: this.$t('nav.billing'),
                    hidden: !this.hasInvoicesAndQuotesFeature,
                },
                {
                    id: 'broadcasts',
                    to: this.hasEmailBroadcastFeature ? '/broadcasts/email' : '/broadcasts/text',
                    icon: 'megaphone',
                    label: this.$t('nav.broadcasts'),
                    hidden: !this.hasPermission(ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS)
                        || !(this.hasEmailBroadcastFeature || this.hasSmsBroadcastFeature),
                },
                {
                    id: 'automations',
                    to: '/automations',
                    icon: 'zap',
                    label: this.$t('nav.automations'),
                    hidden: !this.hasPermission(ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS)
                        || !(this.hasEasyAutomationsFeature || this.hasCampaignBuilderFeature),
                },
                {
                    id: 'pipeline',
                    to: '/pipeline',
                    icon: 'pipeline',
                    label: this.$t('nav.pipeline'),
                    // this will never be hidden when flag is on
                    hidden: this.shouldHidePipeline,
                    // remove label when flag is on
                    extraLabel: !this.showSinglePipeline && !this.isPaid ? PRO : '',
                },
                {
                    id: 'reports',
                    to: '/reports',
                    icon: 'notes',
                    label: this.$t('nav.reports'),
                    hidden: !this.hasPermission(ROLE_NAVIGATION_CAN_ACCESS_REPORTING)
                        || !(this.hasReportsFeature || this.hasAnalyticsFeature),
                    extraLabel: !this.isPaid ? PRO : '',
                },
            ].filter(({ hidden }) => !hidden);
        },

        shouldHidePipeline() {
            if (this.showSinglePipeline || this.hasSinglePipelineFeature) return false;

            return !this.hasMultiplePipelinesFeature;
        },
    },
};
</script>
