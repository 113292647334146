import moment from 'moment';
import { TIMING_UNIT, RELATIVE_TIMER } from '@/automations/constants/automations.constants.js';
import { EVENT_TYPES, OFFSET_TYPES } from '@/automations/constants/automations.timer.constants';

// the backend requires the steps to happen sequentially,
// so this function sorts based on relative timer values, and moves the non-timer steps that follow to the right position
// all relative timers in an automation must be the same type (EXACT, EVENT)
// if the relative timers are type DATE (wait until 1/1/2020) it will sort based on the dates
// if the relative timers are type EVENT with offset (wait until 1 hour before/after appointment) it will sort based on offset times
export const reorderSteps = (stepList, stepListIndex) => {
    const stepsByTimer = [];
    let stepByTimer = null;

    // reset flash property
    stepList.forEach((step) => { step.flash = undefined; });
    // flag the step we are editing so we can know its new index if it moves
    stepList[stepListIndex].editing = true;

    // chunk the list of steps by relative timer, so we can move the steps that are associated with the timer
    // stepsByTimer: [{
    //     delayStep: {}, // optional
    //     steps: [],
    // }]
    for (let i = 0, j = 0; i < stepList.length; i++) {
        const step = stepList[i];

        if (step.type === RELATIVE_TIMER) {
            if (stepByTimer !== null) {
                stepsByTimer.push(stepByTimer);
            }

            stepByTimer = { delayStep: step, steps: [] };

            j = i;
        } else if (stepByTimer?.steps) {
            stepByTimer.steps.push(step);
        } else {
            stepByTimer = { steps: [step] };
        }

        if (i === stepList.length - 1 && j !== i) {
            stepsByTimer.push(stepByTimer);
        }
    }

    // compare configJson
    // if event type is DATE, compare the dates to each other
    // if event type is APPOINTMENT, sort sequentially based on offset to that event
    stepsByTimer.sort((a, b) => {
        const configJsonA = a.delayStep?.configJson;
        const configJsonB = b.delayStep?.configJson;

        if (!configJsonA || !configJsonB) {
            return 0;
        }

        const eventType = configJsonA?.eventType;

        const adjustedA = getAdjustedDate(configJsonA);
        const adjustedB = getAdjustedDate(configJsonB);

        if (eventType && eventType === EVENT_TYPES.DATE) {
            if (moment(adjustedA).isBefore(moment(adjustedB))) {
                return -1;
            }

            if (moment(adjustedA).isAfter(moment(adjustedB))) {
                return 1;
            }

            return 0;
        }

        if (eventType && (eventType === EVENT_TYPES.APPOINTMENT || eventType === EVENT_TYPES.INVOICE_DUE)) {
            return adjustedA - adjustedB;
        }

        return 0;
    });

    let reorderedSteps = [];

    // flatten stepsByTimer into one list, separate the delay steps from steps
    stepsByTimer.forEach(({ steps, delayStep }) => {
        if (delayStep) {
            reorderedSteps.push(delayStep);
        }

        if (delayStep?.editing) {
            // flag the steps of the editing delay timer that may have moved
            steps.forEach((step) => { step.flash = true; });
        }

        reorderedSteps = reorderedSteps.concat(steps);
    });

    const updatedIndex = reorderedSteps.findIndex(({ editing }) => editing);

    // unset the editing flag
    reorderedSteps[updatedIndex].editing = undefined;

    return { reorderedSteps, updatedIndex };
};

export const getAdjustedDate = ({
    eventType, exactDate, startTime, offsetType, count, unit,
}) => {
    if (eventType === EVENT_TYPES.DATE) {
        return moment(exactDate).startOf('day').add(startTime / 100, 'hours');
    }

    let minutesIntoDay = 0;

    if (startTime) {
        minutesIntoDay = (startTime / 100) * 60;
    }

    // number of minutes relative to an event (before/after/none);
    const minutes = convertToMinutes({ count, unit });

    switch (offsetType) {
    case OFFSET_TYPES.BEFORE:
        return (minutes * -1) + minutesIntoDay;

    case OFFSET_TYPES.AFTER:
        return minutes + minutesIntoDay;

    default:
        return minutesIntoDay;
    }
};

export const convertToMinutes = ({ count, unit }) => {
    switch (unit) {
    case TIMING_UNIT.MINUTES:
        return +count;

    case TIMING_UNIT.HOURS:
        return count * 60;

    case TIMING_UNIT.DAYS:
        return count * 60 * 24;

    case TIMING_UNIT.WEEKS:
        return count * 60 * 24 * 7;

    case TIMING_UNIT.MONTHS:
        return count * 60 * 24 * 30;

    default:
        return 0;
    }
};
