import Vue from 'vue';
import gql from 'graphql-tag';
import firebase from 'firebase/app';
import firebaseUtils from '@/shared/utils/firebase.util';
import 'firebase/database';

export const getCampaignFulfillment = async (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query campaignFulfillment($id: ID!) {
                campaignFulfillment(id: $id)
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const installCampaignTemplate = async (payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation installCampaignTemplate($payload: InstallCampaignInput!) {
                installCampaignTemplate(payload: $payload)
            }
        `,
        variables: {
            payload,
        },
        fetchPolicy: 'no-cache',
    });
};

export const getInstalledTemplates = async (templateIds) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query installedTemplates($templateIds: [String]!) {
                installedTemplates(templateIds: $templateIds)
            }
        `,
        variables: {
            templateIds,
        },
        fetchPolicy: 'no-cache',
    });
};

export const campaignTemplatesQuery = async () => {
    const snapshot = await firebase.database()
        .ref('strategies')
        .once('value');

    return firebaseUtils.arrayFromMap(snapshot.val());
};

export const getTemplateByUid = async (uid) => {
    const snapshot = await firebase.database()
        .ref(`strategies/${uid}`)
        .once('value');

    return snapshot.val();
};

export const incrementInstallCount = async (uid, newTemplate) => {
    return firebase.database().ref(`strategies/${uid}`).set(newTemplate);
};
