<template>
    <div class="sms-modal">
        <section v-if="!contact.phone" class="recipients">
            <recipient-search hide-add-contact prevent-redirect @selected="setContact" />
        </section>

        <section v-else class="sms-sending">
            <div ref="messages" class="messages">
                <div v-if="noMessages" class="no-messages">
                    <span class="no-messages-text">{{ $t('noMessages', { fullName }) }}</span>
                </div>

                <div v-else class="message-groups">
                    <message-list-group
                        v-for="(messageGroup, i) in messageGroups"
                        :key="i"
                        :group="messageGroup"
                    />
                </div>
            </div>

            <conversation-input
                :contact-id="contact.id"
                :first-name="contact.firstName"
                :last-name="contact.lastName"
                :phone-number="contact.phone"
                :initial-message="initialMessage"
                @sent="handleSent"
            />
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import MessageListGroup from '@/communication/components/MessageListGroup';
import RecipientSearch from '@/communication/components/RecipientSearch';

import { displayFullName } from '@/contacts/contact-info-utils';

// ConversationInput causes the modal to fail to render when imported traditional way, see FS-39200
const ConversationInput = () => import(/* webpackChunkName: "communication" */'@/communication/components/ConversationInput');

export default {
    components: { ConversationInput, MessageListGroup, RecipientSearch },

    props: {
        contact: {
            required: true,
            type: Object,
        },
        initialMessage: {
            default: '',
            type: String,
        },
    },

    mounted() /* istanbul ignore next */ {
        this.$bus.$on('CONVERSATION_SCROLL_TO_BOTTOM', this.autoScrollToBottom);
        this.load();
    },

    beforeDestroy() /* istanbul ignore next */ {
        this.$bus.$off('CONVERSATION_SCROLL_TO_BOTTOM', this.autoScrollToBottom);
    },

    watch: {
        'contact.phone': {
            handler(value) {
                if (value) {
                    this.load();
                }
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters({
            getActiveConversationMessageGroups: 'communication/activeConversationMessageGroups',
        }),

        fullName() {
            return displayFullName(this.contact);
        },

        messageGroups() {
            return this.getActiveConversationMessageGroups(this.contact.phone);
        },

        noMessages() {
            return this.messageGroups.length === 0;
        },
    },

    methods: {
        async load() {
            if (this.contact.phone) {
                await this.$store.dispatch('communication/LOAD_SMS_MESSAGES', { phoneNumber: this.contact.phone });

                this.scrollToBottom();
            }
        },

        handleSent() {
            this.$emit('send');
            this.scrollToBottom();
        },

        scrollToBottom() /* istanbul ignore next */ {
            const element = this.$refs.messages;

            if (element) {
                element.scrollTop = element.scrollHeight;
            }
        },

        setContact(contact) {
            const phone = contact.phone || contact.phoneNumber;

            this.$emit('update:contact', { ...contact, phone });
        },
    },
};
</script>

<style lang="scss" scoped>
    .recipients {
        min-height: px-to-rem(622px);
    }

    .no-messages {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        height: 100%;

        &-text {
            padding-bottom: $gp * 2.5;
            color: $color-gray-700;
        }
    }

    .messages {
        @include overflowY;
        height: px-to-rem(425px);
    }
</style>

<i18n>
{
    "en-us": {
        "noMessages": "This is the beginning of your conversation with {fullName}"
    }
}
</i18n>
