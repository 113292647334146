<template>
    <div data-qa="help-resources-panel-items">
        <ds-list-item
            as="a"
            :title="$t('learningAndSupport.keapHelp.title')"
            class="help-item"
            border-style="none"
            href="https://help.keap.com"
            target="_blank"
            rel="noopener noreferrer"
        >
            <template #leadingSlot>
                <ds-icon class="help-icon" name="help-circle" />
            </template>
        </ds-list-item>

        <support-chat v-if="isPaidUser" data-qa="support-chat">
            <template v-slot:online="{chatId, startChat}">
                <ds-list-item
                    :id="chatId"
                    as="a"
                    class="help-item"
                    href="javascript://Chat"
                    :title="$t('learningAndSupport.chatWithSupport.title')"
                    border-style="none"
                    style="display: none"
                    @click="startChat"
                >
                    <template #leadingSlot>
                        <ds-icon class="help-icon" name="conversation" />
                    </template>
                </ds-list-item>
            </template>

            <template v-slot:offline="{chatId, url}">
                <ds-list-item
                    :id="chatId"
                    as="a"
                    class="help-item"
                    :href="url"
                    :title="$t('learningAndSupport.chatWithSupport.title')"
                    target="_blank"
                    border-style="none"
                    rel="noopener noreferrer"
                >
                    <template #leadingSlot>
                        <ds-icon class="help-icon" name="conversation" />
                    </template>
                </ds-list-item>
            </template>
        </support-chat>

        <ds-list-item
            as="a"
            :title="$t('learningAndSupport.whatsNew.title')"
            class="help-item"
            border-style="none"
            href="https://help.keap.com/help/release-notes"
            target="_blank"
            rel="noopener noreferrer"
        >
            <template #leadingSlot>
                <ds-icon class="help-icon" name="gift" />
            </template>
        </ds-list-item>

        <share-feedback>
            <template v-slot:default="{ openModal }">
                <ds-list-item
                    :title="$t('learningAndSupport.shareFeedback.title')"
                    class="help-item"
                    clickable
                    border-style="none"
                    @click="openModal"
                >
                    <template #leadingSlot>
                        <ds-icon class="help-icon" name="message-square" />
                    </template>
                </ds-list-item>
            </template>
        </share-feedback>

        <ds-list-item
            as="a"
            :title="$t('learningAndSupport.newCustomerWebinars.title')"
            class="help-item"
            border-style="none"
            href="https://keap.com/customer-webinars"
            target="_blank"
            rel="noopener noreferrer"
        >
            <template #leadingSlot>
                <ds-icon class="help-icon" name="lightbulb" />
            </template>
        </ds-list-item>

        <ds-list-item
            as="a"
            :title="$t('learningAndSupport.automationWebinars.title')"
            class="help-item"
            border-style="none"
            href="https://keap.com/product-updates/automation-webinars"
            target="_blank"
            rel="noopener noreferrer"
        >
            <template #leadingSlot>
                <ds-icon class="help-icon" name="campaign" />
            </template>
        </ds-list-item>

        <ds-list-item
            :title="$t('learningAndSupport.moreResources.title')"
            class="help-item"
            clickable
            border-style="none"
            @click="viewMoreResources"
        >
            <template #leadingSlot>
                <ds-icon class="help-icon" name="add" />
            </template>
        </ds-list-item>

        <portal to="root">
            <share-feedback-modal />
        </portal>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import moment from 'moment-timezone';

import ShareFeedback from './ShareFeedback';
import ShareFeedbackModal from './ShareFeedbackModal';
import SupportChat from './SupportChat';

import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';

export default {
    components: {
        ShareFeedback,
        SupportChat,
        ShareFeedbackModal,
    },

    props: {
        isOpen: Boolean,
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            isPaid: 'auth/isPaid',
        }),

        isPaidUser() {
            return this.user && this.isPaid;
        },

        isBusinessHours() {
            // Removing time restrictions per https://jira.infusionsoft.com/browse/FS-34217
            // but leaving this computed for when support needs hours restricted again
            const userTimeZone = moment.tz.guess();
            const userTime = moment.tz(moment().local().format(TIME_FORMAT), TIME_FORMAT, userTimeZone);
            const isKeapWeekend = moment().tz('America/Phoenix').day() === 0 || moment().tz('America/Phoenix').day() === 6;

            let openTime;
            let closeTime;

            if (!isKeapWeekend) {
                openTime = moment.tz('6:00 am', TIME_FORMAT, 'America/Phoenix');
                closeTime = moment.tz('9:00 pm', TIME_FORMAT, 'America/Phoenix');
            } else {
                openTime = moment.tz('9:00 am', TIME_FORMAT, 'America/Phoenix');
                closeTime = moment.tz('6:00 pm', TIME_FORMAT, 'America/Phoenix');
            }

            return this.isOpen && userTime.isBetween(openTime, closeTime);
        },
    },

    methods: {
        viewMoreResources() {
            this.$router.push({
                name: 'learningAndSupport',
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    .help-item {
        text-decoration: none;
        --list-item-title-color: #{$color-blue};

        .help-icon {
            --icon-color: #{$color-blue};
        }
    }
</style>
