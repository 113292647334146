<template lang="html">
    <ds-text-button
        v-track="'Reviews - clicked : Copy Link'"
        class="small"
        data-qa="copy-link-button"
        @click="copyLink"
    >
        <span>{{ $t('copyLink') }}</span>
    </ds-text-button>
</template>

<script>
import reviewsMixin from '../mixins/reviews.mixin';

export default {
    mixins: [reviewsMixin],

    methods: {
        copyLink() {
            this.$track('Reviews - clicked : Copy link');

            const message = this.$t('success.reviews.linkCopied');

            return this.$copyText(this.reviews_reviewUrl).then(() => {
                this.$toast({ message });
            });
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "copyLink": "Copy review link"
    }
}
</i18n>
