export const CAMPAIGN_BUILDER_FEATURE = 'campaignBuilderNav';
export const MOBILE_ONLY_FEATURE = 'mobileOnly';
export const MULTIPLE_PIPELINES_FEATURE = 'pipeline';
export const RECURRING_PAYMENTS_FEATURE = 'recurringPayments';
export const REPORTS_FEATURE = 'reports';
export const SMART_FORMS_EDITING_FEATURE = 'smartFormsEditing';
export const STAFF_USER_ROLE_FEATURE = 'staffUserRole';
export const WEB_UI_FEATURE = 'webUI';
export const CHECKOUT_FORMS_FEATURE = 'checkoutForms';
export const LEAD_SCORE_FEATURE = 'leadScore';
export const ANALYTICS_FEATURE = 'Analytics';
export const PIPELINE_ANALYTICS_FEATURE = 'pipelineAnalytics';
export const CUSTOM_DASHBOARD = 'customDashboard';
export const SMS_BROADCAST_FEATURE = 'smsBroadcast';
export const EMAIL_BROADCAST_FEATURE = 'emailBroadcast';
export const SHOPIFY_INTEGRATION_FEATURE = 'shopifyIntegration';
export const UNLAYER_EMAIL_BUILDER_FEATURE = 'unlayerEmailBuilder';
export const ADVANCED_ECOMMERCE = 'advancedEcommerce';
export const GENERAL_DASHBOARD_WIDGET = 'dashboardWidgets';
export const APPOINTMENTS_FEATURE = 'appointments';
export const INVOICE_AND_QUOTES_FEATURE = 'invoices';
export const GOOGLE_REVIEW_FEATURE = 'googleReview';
export const CONTACT_LISTS_FEATURE = 'contactLists';
export const COMPANIES_FEATURE = 'companies';
export const UPLOAD_FILE_FEATURE = 'uploadFile';
export const EASY_AUTOMATION_FEATURE = 'automations';
export const CALL_FROM_APP_FEATURE = 'callFromApp';
export const GENERAL_SETTINGS = 'generalSettings';
export const API_SETTINGS = 'api';
export const INTEGRATIONS_SETTINGS = 'integrations';
export const LANDING_PAGES_FEATURE = 'landingPages';
export const SINGLE_PIPELINE_FEATURE = 'singlePipeline';
export const HELP_AND_SUPPORT = 'help';
export const ONBOARDING_AND_GETTING_STARTED_FEATURE = 'onboarding';
export const MARKETING_SITES_ROUTE = 'marketing-sites';
export const MARKETING_SITES_CREATE_ROUTE = 'marketing.sites.create';
export const MARKETING_SITES_EDIT_ROUTE = 'marketing.sites.edit';
export const UNLAYER_LANDING_PAGE_MAX_FEATURE = 'unlayerLandingPageMax';
