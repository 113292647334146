<template>
    <div class="sms-text-box">
        <rich-text-box
            v-model="localValue"
            :max-character-count="$options.MAX_CHARACTER_COUNT"
            :longest-mergeable-field-length="longestMergeableFieldLength"
            :has-error="hasError"
            :placeholder="$t('placeholder')"
            :merge-fields="mergeFields"
            :disable-chip-tooltip="disableTooltip"
            @blur="$emit('blur')"
            @focus="$emit('focus')"
        >
            <template #footer>
                <sms-text-box-footer
                    :hide-custom-fields="hideCustomFields"
                    :use-merge-service="useMergeService"
                    :additional-merge-fields="additionalMergeFields"
                    :source="source"
                />
            </template>
        </rich-text-box>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { MAX_CHARACTER_COUNT } from '@/broadcasts/smsBroadcast.constants';
import SmsTextBoxFooter from '@/shared/components/Text/SmsTextBoxFooter';
import { FF_KEAP_SMS_NONCUSTOM_MERGEFIELDS } from '@/shared/constants/featureFlag.constants';

export const AUTOMATIONS_SOURCE = 'automations';
export const BROADCASTS_SOURCE = 'broadcasts';

export default {
    MAX_CHARACTER_COUNT,

    components: {
        RichTextBox: () => import(/* webpackChunkName: "richTextBox" */ '@/shared/components/Text/RichTextBox'),
        SmsTextBoxFooter,
    },

    props: {
        additionalMergeFields: Array,
        hasError: Boolean,
        hideCustomFields: Boolean,
        longestMergeableFieldLength: {
            type: Number,
            default: 0,
        },
        mergeFields: {
            type: Object,
            default: () => ({}),
        },
        source: {
            type: String,
            required: true,
            validator: (source) => [AUTOMATIONS_SOURCE, BROADCASTS_SOURCE].includes(source),
        },
        value: {
            type: String,
            default: '',
        },
        useMergeService: Boolean,
    },

    data() {
        return {
            localValue: this.value,
        };
    },

    watch: {
        value(val) {
            if (this.localValue !== val) {
                this.localValue = val;
            }
        },

        localValue(val) {
            if (val !== this.value) {
                this.$emit('input', val);
            }
        },
    },

    computed: {
        ...mapState({
            nonCustomFieldsEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_NONCUSTOM_MERGEFIELDS],
        }),

        disableTooltip() {
            return this.source === AUTOMATIONS_SOURCE || this.nonCustomFieldsEnabled;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "placeholder": "Enter message"
    }
}
</i18n>
