import { render, staticRenderFns } from "./InvalidIcon.vue?vue&type=template&id=203f6b3b&scoped=true&"
import script from "./InvalidIcon.vue?vue&type=script&lang=js&"
export * from "./InvalidIcon.vue?vue&type=script&lang=js&"
import style0 from "./InvalidIcon.vue?vue&type=style&index=0&id=203f6b3b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "203f6b3b",
  null
  
)

export default component.exports