import Vue from 'vue';
import gql from 'graphql-tag';

const PIPELINE_DETAILS_QUERY = `{
    id
    name
    stages {
        id
        name
        order
        deals {
            id
            name
            order
            status
            value {
                amount
                currency
            }
            estimatedCloseDate
            closedDate
            outstandingTasks
            taskIds
            ownerId
            owner {
                id
                givenName
                familyName
                email
            }
            owners {
                id
                givenName
                familyName
                email
            }
            contacts {
                id
                primaryContact
                givenName
                familyName
                ownerId
                email
            }
            stage {
                id
                name
                pipelineId
                order
            }
            stageAssignmentDate
        }
    }
    partialError {
        contactError
        taskError
    }
}`;

export const loadPipelineWithFilter = (pipelineId, filters) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query pipeline($pipelineId: ID!, $filters: [PipelineFilterInput]!) {
                filteredPipeline(pipelineId: $pipelineId, filters: $filters)
                    ${PIPELINE_DETAILS_QUERY}
            }
        `,
        variables: {
            pipelineId,
            filters,
        },
        fetchPolicy: 'no-cache',
    });
};

export const createPipeline = ({ name, stages }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createPipeline($createRequest: CreatePipelineRequestInput) {
                createPipeline(createRequest: $createRequest)
                    ${PIPELINE_DETAILS_QUERY}
            }
        `,
        variables: {
            createRequest: { name, stages },
        },
        fetchPolicy: 'no-cache',
    });
};

export const getOutcomeOptions = (pipelineId) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query pipelineOutcomeOptions($pipelineId: ID!) {
                pipelineOutcomeOptions(pipelineId: $pipelineId) {
                    outcomes {
                        value
                        label
                    }
                }
            }
        `,
        variables: {
            pipelineId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const updateOutcomeOptions = (pipelineId, outcomes) => {
    Vue.prototype.$graphql.mutate({
        mutation: gql`
        mutation updatePipelineOutcomeOptions($pipelineId: ID!, $request: PipelineOutcomeOptionsInput!) {
            updatePipelineOutcomeOptions(pipelineId: $pipelineId, updateRequest: $request) {
                outcomes {
                    value
                    label
                }
            }
        }
    `,
        variables: {
            pipelineId,
            request: {
                outcomes,
            },
        },
        fetchPolicy: 'no-cache',
    });
};

export const getPipelineDealCount = (pipelineId, filters) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query pipelineDealCount($pipelineId: ID!, $filters: [PipelineFilterInput]!) {
                pipelineDealCount(pipelineId: $pipelineId, filters: $filters) {
                    count
                }
            }
        `,
        variables: {
            pipelineId,
            filters,
        },
        fetchPolicy: 'no-cache',
    });
};
