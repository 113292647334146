<template>
    <div class="select-appointment" data-qa="select-appointment">
        <div class="date-picker-wrapper">
            <calendar
                :on-select="toggleDay"
                :selected="selectedDate"
                :disable-select="!timesLoaded"
                :month="selectedDate"
                :availability="availableDaysInMonth"
                :min-date="minCalendarDate"
                :max-date="maxCalendarDate"
                :on-next-month-click="nextMonthClickHandler"
                :on-prev-month-click="prevMonthClickHandler"
                :on-month-scroll="selectMonthScrollHandler"
                :on-today-click="todayClickHandler"
                show-availability
            />
        </div>

        <div class="time-picker-wrapper">
            <div v-if="timesLoaded">
                <p class="time-zone mobile-view">
                    {{ timezoneMessage.displayMessage }}
                    <strong>{{ timezoneMessage.timezone }}</strong>
                </p>

                <div class="day-picker">
                    <h5>{{ formatTime(selectedDate, 'dddd, MMMM D YYYY', true) }}</h5>

                    <div class="button-group">
                        <ds-icon-button
                            data-qa="time-picker-prev-day"
                            name="chevron-left"
                            @click="prevDay"
                        />

                        <ds-icon-button
                            data-qa="time-picker-next-day"
                            name="chevron-right"
                            @click="nextDay"
                        />
                    </div>
                </div>

                <div v-if="hasAvailableTimes">
                    <div class="time-picker">
                        <div
                            v-for="(block, i) in availability.times"
                            :key="`${block.start}-${i}`"
                            :data-qa="`${block.start}-${i}`"
                            class="time-block-list"
                        >
                            <div class="time-block-wrapper">
                                <div
                                    data-qa="time-block"
                                    :class="['time-block', { 'selected': isSelected(block) }]"
                                    @click="selectBlock(block)"
                                >
                                    <span>
                                        <span class="start-time">{{ formatTime(block.start) }}</span>

                                        - {{ formatTime(block.end) }}
                                    </span>

                                    <span class="book-now"> {{ $t('appointments.bookNow') }}</span>
                                </div>

                                <ds-filled-button
                                    class="confirm"
                                    :class="[{ 'confirm-selected': isSelected(block) }]"
                                    @click="confirmBlock(block)"
                                >
                                    {{ $t('confirm') }}
                                </ds-filled-button>
                            </div>
                        </div>
                    </div>

                    <p class="time-zone computer-view">
                        {{ timezoneMessage.displayMessage }}
                        <strong>{{ timezoneMessage.timezone }}</strong>
                    </p>
                </div>

                <div v-else class="empty-availability">
                    <h2 class="error-title">
                        {{ $t('emptyAvailability.title') }}
                    </h2>

                    <p class="error-message">
                        {{ $t('emptyAvailability.subtitle') }}
                    </p>
                </div>
            </div>

            <div v-else class="loading-spinner">
                <ds-spinner />
            </div>
        </div>
    </div>
</template>

<script>
import isEqual from 'lodash.isequal';
import debounce from 'lodash.debounce';
import moment from 'moment';

import { mapState } from 'vuex';
import { BOOKING_MAX_DAYS } from '@/appointments/appointments.constants';
import { LOADING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';

import { browserTimezone } from '@/shared/utils/date.util';
import bookingAvailabilityMixin from '@/appointments/mixins/booking-availability.mixin';

import Calendar from '@/appointments/components/Calendar';

export default {
    components: {
        Calendar,
    },

    mixins: [bookingAvailabilityMixin],

    props: {
        showConfirm: Boolean,

        selectedBlock: Object,

        providerTimezone: String,

        debounceDelay: {
            type: Number,
            default: LOADING_DEBOUNCE_DELAY,
        },
    },

    data() {
        return {
            timesLoaded: true,
            localSelectedBlock: this.selectedBlock,
            browserTimezone,
        };
    },

    mounted() {
        if (this.debounceDelay > 0) {
            this.loadAvailability = debounce(this.loadAvailability, this.debounceDelay);
        }
    },

    computed: {
        ...mapState({
            availability: ({ booking }) => booking.apptData.availability,
            bookingLink: ({ booking }) => booking.apptData.bookingLink,
        }),

        maxCalendarDate() {
            if (!this.hasAvailableDaysInMonth) {
                return moment().startOf('day').toDate();
            }

            return moment().endOf('day').add(BOOKING_MAX_DAYS, 'days').toDate();
        },

        minCalendarDate() {
            return moment().startOf('day').toDate();
        },

        selectedDate() {
            return this.availability ? moment(this.availability.selectedDay).toDate() : null;
        },

        availableDaysInMonth() {
            const current = moment(this.selectedDate);

            return this.availability.days.filter(({ day }) => current.isSame(day, 'month'));
        },

        hasAvailableDaysInMonth() {
            return this.availableDaysInMonth.some(({ isFree }) => isFree);
        },

        hasAvailableTimes() {
            return this.timesLoaded && this.availability.times.length > 0;
        },

        timezoneMessage() {
            return this.providerTimezone
                ? { displayMessage: this.$t('timezone.calendar'), timezone: this.providerTimezone }
                : { displayMessage: this.$t('timezone.local'), timezone: this.browserTimezone };
        },
    },

    methods: {
        isSelected(block) {
            return this.showConfirm && isEqual(this.localSelectedBlock, block);
        },

        analyticsHandler(el = '', data, action = 'clicked') {
            this.$track(`Appointments - Appointment booking page - ${action} : ${el}`, data);
        },

        formatTime(date, formatStr = TIME_FORMAT, useLocalTz = false) {
            const timeZone = !useLocalTz && this.providerTimezone ? this.providerTimezone : moment.tz.guess();

            return moment(date).tz(timeZone).format(formatStr);
        },

        getNextAvailableDayInMonth(date) {
            const nextAvailableDay = this.availableDaysInMonth
                .find(({ day, isFree }) => isFree && moment(day).isSameOrAfter(date));

            return nextAvailableDay && nextAvailableDay.day ? nextAvailableDay.day : null;
        },

        selectMonthScrollHandler(date) {
            this.timesLoaded = false;
            this.loadAvailability(moment(date).startOf('month').toDate());
        },

        nextMonthClickHandler(date) {
            this.timesLoaded = false;
            const firstOfNextMonth = moment(date).startOf('month').toDate();

            this.loadAvailability(firstOfNextMonth);
            this.analyticsHandler('Arrow', { option: 'next month' });
        },

        prevMonthClickHandler(date) {
            this.timesLoaded = false;
            const firstOfPrevMonth = moment(date).startOf('month').toDate();

            this.loadAvailability(firstOfPrevMonth);
            this.analyticsHandler('Arrow', { option: 'previous month' });
        },

        async loadAvailability(startDateTime) {
            this.timesLoaded = false;

            const { bookingLink } = this;
            const message = this.$t('availabilityLoadError');

            await this.$store.dispatch('booking/LOAD_APPOINTMENT_TYPE_AVAILABILITY', { bookingLink, startDateTime })
                .catch(() => {
                    this.$error({ message });
                });

            const nextAvailableDay = this.getNextAvailableDayInMonth(startDateTime);
            const newSelectedDate = moment(nextAvailableDay).toDate();

            if (!nextAvailableDay) {
                this.timesLoaded = true;

                return;
            }

            await this.$store.dispatch('booking/UPDATE_SELECTED_DATE', newSelectedDate)
                .catch(() => {
                    this.$error({ message });
                });

            this.timesLoaded = true;
        },

        selectBlock(block) {
            if (!this.showConfirm) {
                this.localSelectedBlock = block;
                this.confirmBlock(block);
            } else {
                this.localSelectedBlock = isEqual(this.localSelectedBlock, block) ? {} : block;
            }
        },

        confirmBlock(block) {
            const errorMessage = this.$t('appointments.notAvailable');

            return this.$store.dispatch('booking/REFRESH_AVAILABILITY')
                .then(() => {
                    if (this.bookingAvailability_isAvailable(block, this.availability)) {
                        this.analyticsHandler('Date view', { option: 'time of day' });

                        this.$emit('update:selectedBlock', this.localSelectedBlock);
                    } else {
                        this.$error({ message: errorMessage, bottom: true });

                        this.localSelectedBlock = {};
                    }
                });
        },

        toggleDay({ day, isUnavailable }) {
            const isToday = moment(day).isSame(moment(), 'day');

            if (isToday) {
                this.loadAvailability(moment(day).toDate());
            }

            if (!isToday && !isUnavailable && this.timesLoaded && this.hasAvailableDaysInMonth) {
                this.analyticsHandler('Date view', { option: 'date in a month' });
                this.timesLoaded = false;

                return this.$store.dispatch('booking/UPDATE_SELECTED_DATE', day).then(() => {
                    this.timesLoaded = true;
                });
            }

            return Promise.resolve();
        },

        todayClickHandler() {
            this.analyticsHandler('Date view', { option: 'today' });
        },

        prevDay() {
            const date = moment(this.selectedDate).subtract(1, 'day').toDate();

            this.analyticsHandler('Arrow', { option: 'prev day' });

            return this.$store.dispatch('booking/UPDATE_SELECTED_DATE', date);
        },

        nextDay() {
            const date = moment(this.selectedDate).add(1, 'day').toDate();

            this.analyticsHandler('Arrow', { option: 'next day' });

            return this.$store.dispatch('booking/UPDATE_SELECTED_DATE', date);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    @import '~@/appointments/styles/appointments';

    .select-appointment {
        display: flex;
        justify-content: center;
    }

    .time-picker-wrapper {
        text-align: center;
    }

    .loading-spinner {
        padding-top: $gp * 5;
    }

    .date-picker-wrapper {
        padding-bottom: 0;
        @include border-end(1px solid $color-gray-400);
    }

    .date-picker-wrapper, .time-picker-wrapper {
        @include overflowY;
        padding: $gp * 1.5;
        flex: 1;
        height: $appointment-picker-height;
        overflow-x: hidden;
    }

    .day-picker {
        margin-bottom: $gp;
        display: flex;
        align-items: center;
        justify-content: center;

        h5 {
            @include margin-end($gp / 2);
        }
    }

    .time-block-wrapper {
        display: flex;
        align-items: center;
        margin: 0 auto $gp / 2;
        max-width: px-to-rem(330px);
    }

    .time-block {
        @include transition(margin);
        @include text-align-start;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $color-blue-050;
        color: $color-blue;
        padding: $gp * .75 $gp;
        border-radius: $button-border-radius;
        cursor: pointer;
        flex: 1;

        &.selected {
            @include margin-end($gp / 2);

            .book-now {
                display: none;
            }
        }

        &:hover {
            background: $color-blue-050;

            .book-now {
                font-weight: $font-weight-semibold;
            }
        }
    }

    .start-time {
        font-weight: $font-weight-semibold;
    }

    .confirm {
        @include transition(padding-right, padding-left, max-width);
        max-width: px-to-rem(85px);

        &:not(.confirm-selected) {
            max-width: 0;
            @include padding-start(0);
            @include padding-end(0);
            border: none;
        }
    }

    .time-zone {
        margin-top: $gp;
        display: flex;
        flex-direction: column;
        color: $color-gray-800;
    }

    .empty-availability {
        padding: $gp * 3 $gp * 3 0;

        .error-title {
            padding-bottom: $gp;
        }
    }

    .mobile-view {
        display: none;
    }

    @media($medium) {
        .time-picker {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: px-to-rem(288px);
        }
    }

    @media($small) {
        .date-picker-wrapper, .time-picker-wrapper {
            height: auto;
            overflow: visible;
        }

        .time-picker-wrapper {
            padding-top: 0;

            .time-zone {
                margin: $gp;
            }
        }

        .empty-availability {
            padding-top: $gp * 2;
        }

        .date-picker-wrapper {
            border-right: 0;
            padding: $gp;
        }

        .select-appointment {
            min-width: auto;
            flex-direction: column;
        }

        .time-zone {
            font-size: $font-size-small;
        }

        .time-block-list {
            max-width: px-to-rem(500px);
            margin: 0 auto;

            .time-block-wrapper {
                margin: 0 $gp * 1.5 $gp / 2;
                max-width: 100%;
            }
        }

        .mobile-view {
            display: block;
        }

        .computer-view {
            display: none;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "timezone": {
            "local": "Times displayed in your time zone",
            "calendar": "Times displayed in your calendar's time zone"
        },
        "confirm": "Confirm",
        "emptyAvailability": {
            "title": "No availability for the selected date",
            "subtitle": "Try selecting another date"
        },
        "availabilityLoadError": "Something went wrong while loading availability, please try again."
    }
}
</i18n>
