import { normalizeHtml } from '@/shared/utils/quill.util';

import { BLOCK_TYPES, BUILDER_WIDTH } from '@/broadcasts/broadcasts.constants';

const CAN_SPAM_ADDRESS_MERGE_FIELD = '~Company.CanSpamAddressBlock~';
const OPT_OUT_LINK_MERGE_FIELD = '~OptOut_BARD~';

export const getBardJson = (emailRows) => {
    const rows = emailRows.map(({ properties, type }) => {
        if (type === BLOCK_TYPES.TEXT) {
            properties.content = normalizeHtml(properties.content);
        }

        return {
            blocks: [{
                blockType: type,
                [type]: properties,
            }],
        };
    });
    const bardJson = {
        version: '2.1.0',
        contentWidth: BUILDER_WIDTH,
        category: '',
        templateKind: 'email',
        globals: {
            fontImports: ['https://fonts.googleapis.com/css?family=Merriweather|Open+Sans|Lato'],
            alignment: 'left',
            backgroundColor: '#ffffff',
            text: {
                h1: {
                    color: 'inherit',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: '24px',
                    padding: '0',
                    margin: '16px 0',
                    lineHeight: 'normal',
                },
                h2: {
                    color: 'inherit',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: '18px',
                    padding: '0',
                    margin: '16px 0',
                    lineHeight: 'normal',
                },
                p: {
                    color: 'inherit',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontSize: '14px',
                    padding: '0',
                    margin: '8px 0',
                    lineHeight: '1.6',
                },
                b: {
                    color: 'inherit',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    padding: '0',
                    margin: '0',
                    lineHeight: 'normal',
                },
                i: {
                    color: 'inherit',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    padding: '0',
                    margin: '0',
                    lineHeight: 'normal',
                },
                ul: {
                    color: 'inherit',
                    fontFamily: 'inherit',
                    fontSize: '16px',
                    padding: '0 0 0 32px',
                    margin: '16px 0',
                    lineHeight: 'normal',
                },
                ol: {
                    color: 'inherit',
                    fontFamily: 'inherit',
                    fontSize: '16px',
                    padding: '0 0 0 32px',
                    margin: '16px 0',
                    lineHeight: 'normal',
                },
                li: {
                    color: 'inherit',
                    fontFamily: 'inherit',
                    fontSize: 'inherit',
                    padding: '0',
                    margin: '0',
                    lineHeight: 'normal',
                },
                a: {
                    color: 'inherit',
                    fontSize: 'inherit',
                    padding: '0',
                    margin: '0',
                    lineHeight: 'normal',
                },
                div: {
                    color: 'inherit',
                    fontSize: '14px',
                    padding: '0',
                    margin: '0',
                    lineHeight: 'normal',
                },
            },
        },
        rows,
    };

    return bardJson;
};

export const getEmailRows = (bardJson) => {
    const emailRows = [];

    if (bardJson) {
        const { rows } = JSON.parse(bardJson);

        rows.forEach((row) => {
            row.blocks.forEach((block) => {
                emailRows.push({
                    properties: block[block.blockType],
                    type: block.blockType,
                    valid: false,
                });
            });
        });
    }

    return emailRows;
};

export const bardJsonFromHtml = ({
    button,
    html,
    signatureHtml = null,
    templateKind = 'email',
    unsubscribeText = null,
} = {}) => ({
    version: '2.1.0',
    contentWidth: 600,
    category: '',
    templateKind,
    globals: {
        fontImports: ['https://fonts.googleapis.com/css?family=Merriweather|Open+Sans|Lato'],
        alignment: 'left',
        backgroundColor: '#ffffff',
        text: {
            h1: {
                color: 'inherit',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontSize: '24px',
                padding: '0',
                margin: '16px 0',
                lineHeight: 'normal',
            },
            h2: {
                color: 'inherit',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontSize: '18px',
                padding: '0',
                margin: '16px 0',
                lineHeight: 'normal',
            },
            p: {
                color: 'inherit',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontSize: '14px',
                padding: '0',
                margin: '8px 0',
                lineHeight: '1.6',
            },
            b: {
                color: 'inherit',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                padding: '0',
                margin: '0',
                lineHeight: 'normal',
            },
            i: {
                color: 'inherit',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                padding: '0',
                margin: '0',
                lineHeight: 'normal',
            },
            ul: {
                color: 'inherit',
                fontFamily: 'inherit',
                fontSize: '16px',
                padding: '0 0 0 32px',
                margin: '16px 0',
                lineHeight: 'normal',
            },
            ol: {
                color: 'inherit',
                fontFamily: 'inherit',
                fontSize: '16px',
                padding: '0 0 0 32px',
                margin: '16px 0',
                lineHeight: 'normal',
            },
            li: {
                color: 'inherit',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                padding: '0',
                margin: '0',
                lineHeight: 'normal',
            },
            a: {
                color: 'inherit',
                fontSize: 'inherit',
                padding: '0',
                margin: '0',
                lineHeight: 'normal',
            },
            div: {
                color: 'inherit',
                fontSize: '14px',
                padding: '0',
                margin: '0',
                lineHeight: 'normal',
            },
        },
    },
    rows: bardRows({
        button,
        html,
        signatureHtml,
        unsubscribeText,
    }),
});

const bardRows = ({
    button,
    html,
    signatureHtml,
    unsubscribeText,
}) => {
    const rows = [{
        blocks: [{
            blockType: 'text',
            text: {
                content: html,
                blockPadding: 16,
            },
        }],
    }];

    if (button != null) {
        const { buttonLink, buttonText } = button;

        rows.push({
            blocks: [{
                blockType: 'html',
                html: {
                    html: buildButtonHtml({ buttonLink, buttonText }),
                },
            }],
        });
    }

    if (signatureHtml != null) {
        rows.push({
            blocks: [{
                blockType: 'signature',
                signature: {
                    content: signatureHtml,
                    blockPadding: 16,
                },
            }],
        });
    }

    if (unsubscribeText != null) {
        rows.push({
            blocks: [{
                blockType: 'emailFooter',
                emailFooter: {
                    address: CAN_SPAM_ADDRESS_MERGE_FIELD,
                    alignment: 'center',
                    linkHref: OPT_OUT_LINK_MERGE_FIELD,
                    linkText: unsubscribeText,
                    blockPadding: 16,
                },
            }],
        });
    }

    return rows;
};

export const hasSignatureBlock = (bardJson) => {
    return bardJson.rows.some((row) => {
        return row.blocks.some((block) => {
            return block.blockType === 'signature';
        });
    });
};

// TODO: Remove this after BARD v2 schema is supported in core
export const legacyBardJsonFromHtml = ({
    button = null,
    html,
    signatureHtml = null,
    unsubscribeText = null,
} = {}) => ({
    version: '1.8.0',
    'content-width': 600,
    globals: {
        'font-imports': ['http://fonts.googleapis.com/css?family=Merriweather|Open+Sans|Lato'],
        alignment: 'left',
        'background-color': '#ffffff',
        text: {
            h1: {
                color: 'inherit',
                'font-family': 'Helvetica, Arial, sans-serif',
                'font-size': '24px',
                padding: '0',
                margin: '16px 0',
                'line-height': 'normal',
            },
            h2: {
                color: 'inherit',
                'font-family': 'Helvetica, Arial, sans-serif',
                'font-size': '18px',
                padding: '0',
                margin: '16px 0',
                'line-height': 'normal',
            },
            p: {
                color: 'inherit',
                'font-family': 'Helvetica, Arial, sans-serif',
                'font-size': '14px',
                padding: '0',
                margin: '8px 0',
                'line-height': '1.6',
            },
            b: {
                color: 'inherit',
                'font-family': 'inherit',
                'font-size': 'inherit',
                padding: '0',
                margin: '0',
                'line-height': 'normal',
            },
            i: {
                color: 'inherit',
                'font-family': 'inherit',
                'font-size': 'inherit',
                padding: '0',
                margin: '0',
                'line-height': 'normal',
            },
            ul: {
                color: 'inherit',
                'font-family': 'inherit',
                'font-size': '16px',
                padding: '0 0 0 32px',
                margin: '16px 0',
                'line-height': 'normal',
            },
            ol: {
                color: 'inherit',
                'font-family': 'inherit',
                'font-size': '16px',
                padding: '0 0 0 32px',
                margin: '16px 0',
                'line-height': 'normal',
            },
            li: {
                color: 'inherit',
                'font-family': 'inherit',
                'font-size': 'inherit',
                padding: '0',
                margin: '0',
                'line-height': 'normal',
            },
            a: {
                color: 'inherit',
                'font-size': 'inherit',
                padding: '0',
                margin: '0',
                'line-height': 'normal',
            },
            div: {
                color: 'inherit',
                'font-size': '14px',
                padding: '0',
                margin: '0',
                'line-height': 'normal',
            },
        },
    },
    rows: legacyBardRows({
        button,
        html,
        signatureHtml,
        unsubscribeText,
    }),
});

const legacyBardRows = ({
    button,
    html,
    signatureHtml,
    unsubscribeText,
} = {}) => {
    const rows = [{
        blocks: [{
            'block-type': 'text',
            text: {
                content: html,
                'block-padding': 16,
            },
        }],
    }];

    if (button != null) {
        const { buttonLink, buttonText } = button;

        rows.push({
            blocks: [{
                'block-type': 'html',
                html: {
                    html: buildButtonHtml({ buttonLink, buttonText }),
                },
            }],
        });
    }

    if (signatureHtml != null) {
        rows.push({
            blocks: [{
                'block-type': 'signature',
                signature: {
                    content: signatureHtml,
                    'block-padding': 16,
                },
            }],
        });
    }

    if (unsubscribeText != null) {
        rows.push({
            blocks: [{
                'block-type': 'email-footer',
                'email-footer': {
                    address: CAN_SPAM_ADDRESS_MERGE_FIELD,
                    alignment: 'center',
                    'unsubscribe-link': OPT_OUT_LINK_MERGE_FIELD,
                    'unsubscribe-link-text': unsubscribeText,
                    'block-padding': 16,
                },
            }],
        });
    }

    return rows;
};

export const legacyHasSignatureBlock = (bardJson) => {
    return bardJson.rows.some((row) => {
        return row.blocks.some((block) => {
            return block['block-type'] === 'signature';
        });
    });
};

export const htmlFromSingleRowBardTemplate = (bardJson) => {
    if (bardJson == null) {
        return null;
    }

    const row = bardJson.rows[0];

    if (row == null) {
        return null;
    }

    const block = row.blocks[0];

    if (block == null) {
        return null;
    }

    return block.text != null ? block.text.content : null;
};

const buildButtonHtml = ({ buttonLink, buttonText }) => `
<a
    href="${buttonLink}"
    target="_blank"
    rel="noopener noreferrer"
    style="padding:11px 15px;background-color:rgb(0,64,219);border:none;border-radius:8px;color:rgb(255,255,255);display:inline-block;font-size:14px;text-align:center;text-decoration:none;"
>
    ${buttonText}
</a>
`;
