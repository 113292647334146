import moment from 'moment';

export const orderStatus = {
    PAID: 'PAID',
    UNPAID: 'UNPAID',
    SENT: 'SENT',
    VIEWED: 'VIEWED',
    REFUNDED: 'REFUNDED',
    PARTIAL_PAYMENT: 'PARTIAL_PAYMENT',
    DRAFT: 'DRAFT',
    ACCEPTED: 'ACCEPTED',
    DEPOSIT_PAID: 'DEPOSIT_PAID',
    ACCEPTED_INVOICED: 'ACCEPTED_INVOICED',
    INVOICED: 'INVOICED',
    DECLINED: 'DECLINED',
    UPDATED: 'UPDATED',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    COMPLETE: 'Complete',
};

export const frequencyStatus = {
    DAILY: '6',
    WEEKLY: '3',
    MONTHLY: '2',
    YEARLY: '1',
};

export const billingCycles = {
    DAILY: 6,
    WEEKLY: 3,
    MONTHLY: 2,
    YEARLY: 1,
};

export const recurringAttemptStatus = {
    APPROVED: 'APPROVED',
    FAILED: 'FAILED',
    ERROR: 'ERROR',
    PENDING: 'PENDING',
};

export const getStatus = (status) => {
    switch (status) {
    case orderStatus.PAID:
        return 'paid';
    case orderStatus.DEPOSIT_PAID:
        return 'depositPaid';
    case orderStatus.UNPAID:
        return 'unpaid';
    case orderStatus.SENT:
        return 'sent';
    case orderStatus.VIEWED:
        return 'viewed';
    case orderStatus.REFUNDED:
        return 'refunded';
    case orderStatus.ACCEPTED:
        return 'accepted';
    case orderStatus.DECLINED:
        return 'declined';
    case orderStatus.UPDATED:
        return 'updated';
    case orderStatus.ACTIVE:
        return 'active';
    case orderStatus.INACTIVE:
        return 'inactive';
    case orderStatus.COMPLETE:
        return 'complete';
    default:
        return 'draft';
    }
};

export const getQuoteStatus = (status, orderId = 0) => {
    if (orderId && status === orderStatus.ACCEPTED) {
        return 'acceptedInvoiced';
    }

    if (orderId) {
        return 'invoiced';
    }

    return getStatus(status);
};

export const isCompletedPayment = (finalPaymentDate) => {
    return moment(finalPaymentDate).isBefore(moment());
};

export const getRecurringStatus = (status, finalPaymentDate) => {
    if (isCompletedPayment(finalPaymentDate)) {
        return getStatus(orderStatus.COMPLETE);
    }

    return getStatus(status);
};

export const getBillingCycleStatus = (status) => {
    switch (status) {
    case frequencyStatus.DAILY:
        return 'daily';
    case frequencyStatus.WEEKLY:
        return 'weekly';
    case frequencyStatus.MONTHLY:
        return 'monthly';
    case frequencyStatus.YEARLY:
        return 'yearly';
    default:
        return 'monthly';
    }
};

export const timeUnitStatus = (recurringFrequency) => {
    switch (recurringFrequency) {
    case frequencyStatus.DAILY:
        return 'days';
    case frequencyStatus.WEEKLY:
        return 'weeks';
    case frequencyStatus.MONTHLY:
        return 'months';
    case frequencyStatus.YEARLY:
        return 'years';
    default:
        return 'months';
    }
};
