import {
    queryCommunicationTemplateList,
    mutationCreateCommunicationTemplate,
    mutationUpdateCommunicationTemplate,
    mutationDeleteCommunicationTemplate,
} from '@/shared/store/communicationTemplates/queries';
import { MEDIA_TYPES, KMN_TEMPLATE_KINDS, KBL_TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';

import sentry from '@/analytics/sentry';
import { FF_COMMUNICATION_TEMPLATES_K8S_URL } from '@/shared/constants/featureFlag.constants';

export default {
    LOAD_EMAIL_TEMPLATES(context) {
        return loadEmailTemplates(context);
    },

    CREATE_EMAIL_TEMPLATE(context, payload) {
        return createEmailTemplate(context, payload);
    },

    UPDATE_EMAIL_TEMPLATE(context, payload) {
        return updateEmailTemplate(context, payload);
    },

    DELETE_EMAIL_TEMPLATE(context, payload) {
        return deleteEmailTemplate(context, payload);
    },

    LOAD_SMS_TEMPLATES(context) {
        return loadSmsTemplates(context);
    },

    CREATE_SMS_TEMPLATE(context, payload) {
        return createSmsTemplate(context, payload);
    },

    UPDATE_SMS_TEMPLATE(context, payload) {
        return updateSmsTemplate(context, payload);
    },

    DELETE_SMS_TEMPLATE(context, payload) {
        return deleteSmsTemplate(context, payload);
    },

    LOAD_KEAP_MARKETING_NUMBER_TEMPLATES(context) {
        return loadKeapMarketingNumberTemplates(context);
    },

    CREATE_KEAP_MARKETING_NUMBER_TEMPLATE(context, payload) {
        return createKeapMarketingNumberTemplate(context, payload);
    },

    UPDATE_KEAP_MARKETING_NUMBER_TEMPLATE(context, payload) {
        return updateKeapMarketingNumberTemplate(context, payload);
    },

    DELETE_KEAP_MARKETING_NUMBER_TEMPLATE(context, payload) {
        return deleteKeapMarketingNumberTemplate(context, payload);
    },
};

const loadEmailTemplates = async ({ commit, rootState }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const emailTemplates = await queryCommunicationTemplateList({ mediaType: MEDIA_TYPES.HTML, k8sUrl });

        commit('SET_EMAIL_TEMPLATES', emailTemplates);
    } catch (error) {
        sentry.log('Load email templates failed in communicationTemplates actions');
        throw error;
    }
};

const createEmailTemplate = async ({ commit, rootState }, { payload }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const emailTemplate = await mutationCreateCommunicationTemplate({ payload, k8sUrl });

        commit('ADD_EMAIL_TEMPLATE', emailTemplate);

        return emailTemplate;
    } catch (error) {
        sentry.log('Create email template failed in communicationTemplates actions');
        throw error;
    }
};

const updateEmailTemplate = async({ commit, rootState }, { payload, templateId }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const emailTemplate = await mutationUpdateCommunicationTemplate({ payload, templateId, k8sUrl });

        commit('UPDATE_EMAIL_TEMPLATE', emailTemplate);
    } catch (error) {
        sentry.log('Update email template failed in communicationTemplates actions');
        throw error;
    }
};

const deleteEmailTemplate = async({ commit, rootState }, { templateId }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        await mutationDeleteCommunicationTemplate({ templateId, k8sUrl });

        commit('DELETE_EMAIL_TEMPLATE', templateId);
    } catch (error) {
        sentry.log('Delete email template failed in communicationTemplates actions');
        throw error;
    }
};

const loadSmsTemplates = async ({ commit, rootState }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const plainTextTemplates = await queryCommunicationTemplateList({ mediaType: MEDIA_TYPES.PLAIN_TEXT, k8sUrl });

        // currently sms templates load all tempates of media type plain text. Keap marketing number uses the same media type. this separates the sms templates from KMN templates

        const smsTemplates = plainTextTemplates.filter((template) => KBL_TEMPLATE_KINDS.includes(template.templateKind));

        commit('SET_SMS_TEMPLATES', smsTemplates);
    } catch (error) {
        sentry.log('Load SMS templates failed in communicationTemplates actions');
        throw error;
    }
};

const createSmsTemplate = async ({ commit, rootState }, { payload }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const smsTemplate = await mutationCreateCommunicationTemplate({ payload, k8sUrl });

        commit('ADD_SMS_TEMPLATE', smsTemplate);

        return smsTemplate;
    } catch (error) {
        sentry.log('Create SMS template failed in communicationTemplates actions');
        throw error;
    }
};

const updateSmsTemplate = async({ commit, rootState }, { payload, templateId }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const smsTemplate = await mutationUpdateCommunicationTemplate({ payload, templateId, k8sUrl });

        commit('UPDATE_SMS_TEMPLATE', smsTemplate);
    } catch (error) {
        sentry.log('Update SMS template failed in communicationTemplates actions');
        throw error;
    }
};

const deleteSmsTemplate = async({ commit, rootState }, { templateId }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        await mutationDeleteCommunicationTemplate({ templateId, k8sUrl });

        commit('DELETE_SMS_TEMPLATE', templateId);
    } catch (error) {
        sentry.log('Delete SMS template failed in communicationTemplates actions');
        throw error;
    }
};


const loadKeapMarketingNumberTemplates = async ({ commit, rootState }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const plainTextTemplates = await queryCommunicationTemplateList({ mediaType: MEDIA_TYPES.PLAIN_TEXT, k8sUrl });

        const keapMarketingNumberTemplates = plainTextTemplates.filter((template) => KMN_TEMPLATE_KINDS.includes(template.templateKind));

        commit('SET_KEAP_MARKETING_NUMBER_TEMPLATES', keapMarketingNumberTemplates);
    } catch (error) {
        sentry.log('Load KMN templates failed in communicationTemplates actions');
        throw error;
    }
};


const createKeapMarketingNumberTemplate = async ({ commit, rootState }, { payload }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const keapMarketingNumberTemplate = await mutationCreateCommunicationTemplate({ payload, k8sUrl });

        commit('ADD_KEAP_MARKETING_NUMBER_TEMPLATE', keapMarketingNumberTemplate);

        return keapMarketingNumberTemplate;
    } catch (error) {
        sentry.log('Create KMN template failed in communicationTemplates actions');
        throw error;
    }
};

const updateKeapMarketingNumberTemplate = async({ commit, rootState }, { payload, templateId }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        const keapMarketingNumberTemplate = await mutationUpdateCommunicationTemplate({ payload, templateId, k8sUrl });

        commit('UPDATE_KEAP_MARKETING_NUMBER_TEMPLATE', keapMarketingNumberTemplate);
    } catch (error) {
        sentry.log('Update KMN template failed in communicationTemplates actions');
        throw error;
    }
};

const deleteKeapMarketingNumberTemplate = async({ commit, rootState }, { templateId }) => {
    try {
        const k8sUrl = getK8sUrl(rootState);

        await mutationDeleteCommunicationTemplate({ templateId, k8sUrl });

        commit('DELETE_KEAP_MARKETING_NUMBER_TEMPLATE', templateId);
    } catch (error) {
        sentry.log('Delete KMN template failed in communicationTemplates actions');
        throw error;
    }
};

const getK8sUrl = (rootState) => {
    return rootState.featureFlags[FF_COMMUNICATION_TEMPLATES_K8S_URL]
        ? process.env.VUE_APP_COMMUNICATION_TEMPLATES_K8S_URL : '';
};
