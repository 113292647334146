import { ADMIN } from '@/settings/settings.constants';
import * as features from '@/shared/constants/features.constants';

export default {
    brandingConfigured: ({ account }) => {
        const { logoUrl } = account.companyProfile;

        return Boolean(logoUrl);
    },

    canSpamConfigured: ({ account }) => {
        const {
            address1,
            email,
            country,
            locality,
            postalCode,
            region,
        } = account.companyProfile;

        return Boolean(address1 && country && email && locality && postalCode && region);
    },

    coreBaseUrl: ({ session }) => {
        return `${process.env.VUE_APP_CORE_URL_PATTERN?.replace('{id}', session.coreAppId)}`;
    },

    isPartner: ({ user }) => {
        return user && user.isPartner;
    },

    referredByPartner: ({ session }) => {
        return session.referredByPartner;
    },

    getUserNameFromId: ({ users }) => (userId) => {
        const user = users.find(({ id }) => Number(id) === Number(userId));

        return user
            ? `${user.givenName || ''} ${user.familyName || ''}`.trim()
            : null;
    },

    // will return first name if exists, if not last name if exists, if not then email.
    // will also capitalize first letter if returning the name
    getUserFirstName: ({ user }) => {
        const { givenName, familyName, email } = user;
        const name = givenName || familyName;

        return name ? name.charAt(0).toUpperCase() + name.slice(1) : email;
    },

    hasStealthAccess: ({ user }) => {
        return user && (user.isLocal || user.hasStealth);
    },

    daysLeft: ({ account }) => {
        return account.trial ? Math.max(0, account.trial.daysLeft) : 0;
    },

    isPaid: ({ account }) => {
        return !account.trial;
    },

    isExpired: (_, { isPaid, daysLeft }) => {
        return !isPaid && daysLeft === 0;
    },

    canUpgrade: ({ session }, { isPaid, referredByPartner }, { global: { appLoaded } }) => {
        return Boolean(session && session.coreAppId && appLoaded && !isPaid && !referredByPartner);
    },

    hasPermission: (auth) => (permissionName) => {
        if (!auth.user) {
            return false;
        }

        const { userRole, permissions } = auth.user;
        const permission = permissions && permissions[permissionName];

        return userRole === ADMIN
            || permission == null // accept access if permission does not exist
            || permission;
    },

    isAdmin: (auth) => {
        return auth.user && auth.user.userRole === ADMIN;
    },

    showFreeTrialChat: (_, { isPaid, isPartner, referredByPartner }) => {
        return Boolean(!isPaid && !isPartner && !referredByPartner);
    },

    emailUsers: ({ users }) => {
        return users.map(({
            fullName,
            email,
            phone,
        }) => {
            const label = `"${fullName}" <${email}>`;

            return {
                label,
                value: label,
                email,
                phone,
                fullName,
            };
        });
    },

    hasFeature: ({ account }) => (featureName) => {
        return Boolean(account.features && account.features[featureName]);
    },

    hasCampaignBuilderFeature: (_, getters) => {
        return getters.hasFeature(features.CAMPAIGN_BUILDER_FEATURE);
    },

    hasMobileOnlyFeature: (_, getters) => {
        return getters.hasFeature(features.MOBILE_ONLY_FEATURE);
    },

    hasMultiplePipelinesFeature: (_, getters) => {
        return getters.hasFeature(features.MULTIPLE_PIPELINES_FEATURE);
    },

    hasRecurringPaymentsFeature: (_, getters) => {
        return getters.hasFeature(features.RECURRING_PAYMENTS_FEATURE);
    },

    hasReportsFeature: (_, getters) => {
        return getters.hasFeature(features.REPORTS_FEATURE);
    },

    hasFormsFeature: (_, getters) => {
        return getters.hasFeature(features.SMART_FORMS_EDITING_FEATURE);
    },

    hasStaffUserRoleFeature: (_, getters) => {
        return getters.hasFeature(features.STAFF_USER_ROLE_FEATURE);
    },

    hasWebUIFeature: (_, getters) => {
        return getters.hasFeature(features.WEB_UI_FEATURE);
    },

    hasCheckoutFormsFeature: (_, getters) => {
        return getters.hasFeature(features.CHECKOUT_FORMS_FEATURE);
    },

    hasLeadScoreFeature: (_, getters) => {
        return getters.hasFeature(features.LEAD_SCORE_FEATURE);
    },

    hasAnalyticsFeature: (_, getters) => {
        return getters.hasFeature(features.ANALYTICS_FEATURE);
    },

    hasPipelineAnalyticsFeature: (_, getters) => {
        return getters.hasFeature(features.PIPELINE_ANALYTICS_FEATURE);
    },

    hasCustomDashboardFeature: (_, getters) => {
        return getters.hasFeature(features.CUSTOM_DASHBOARD);
    },

    hasSmsBroadcastFeature: (_, getters) => {
        return getters.hasFeature(features.SMS_BROADCAST_FEATURE);
    },

    hasEmailBroadcastFeature: (_, getters) => {
        return getters.hasFeature(features.EMAIL_BROADCAST_FEATURE);
    },

    hasShopifyIntegrationFeature: (_, getters) => {
        return getters.hasFeature(features.SHOPIFY_INTEGRATION_FEATURE);
    },

    hasUnlayerEmailBuilderFeature: (_, getters) => {
        return getters.hasFeature(features.UNLAYER_EMAIL_BUILDER_FEATURE);
    },

    hasAdvancedEcommerceFeature: (_, getters) => {
        return getters.hasFeature(features.ADVANCED_ECOMMERCE);
    },

    hasDashboardWidgetsFeature: (_, getters) => {
        return getters.hasFeature(features.GENERAL_DASHBOARD_WIDGET);
    },

    hasInvoicesAndQuotesFeature: (_, getters) => {
        return getters.hasFeature(features.INVOICE_AND_QUOTES_FEATURE);
    },

    hasAppointmentsFeature: (_, getters) => {
        return getters.hasFeature(features.APPOINTMENTS_FEATURE);
    },

    hasGoogleReviewFeature: (_, getters) => {
        return getters.hasFeature(features.GOOGLE_REVIEW_FEATURE);
    },

    hasContactListsFeature: (_, getters) => {
        return getters.hasFeature(features.CONTACT_LISTS_FEATURE);
    },

    hasCompaniesFeature: (_, getters) => {
        return getters.hasFeature(features.COMPANIES_FEATURE);
    },

    hasUploadFileFeature: (_, getters) => {
        return getters.hasFeature(features.UPLOAD_FILE_FEATURE);
    },

    hasEasyAutomationsFeature: (_, getters) => {
        return getters.hasFeature(features.EASY_AUTOMATION_FEATURE);
    },

    hasCallFromAppFeature: (_, getters) => {
        return getters.hasFeature(features.CALL_FROM_APP_FEATURE);
    },

    hasGeneralSettingsFeature: (_, getters) => {
        return getters.hasFeature(features.GENERAL_SETTINGS);
    },

    hasApiSettingsFeature: (_, getters) => {
        return getters.hasFeature(features.API_SETTINGS);
    },

    hasIntegrationsSettingsFeature: (_, getters) => {
        return getters.hasFeature(features.INTEGRATIONS_SETTINGS);
    },

    hasLandingPagesFeature: (_, getters) => {
        return getters.hasFeature(features.LANDING_PAGES_FEATURE);
    },

    hasSinglePipelineFeature: (_, getters) => {
        return getters.hasFeature(features.SINGLE_PIPELINE_FEATURE);
    },

    hasHelpAndSupportFeature: (_, getters) => {
        return getters.hasFeature(features.HELP_AND_SUPPORT);
    },

    hasOnboardingAndGettingStartedFeature: (_, getters) => {
        return getters.hasFeature(features.ONBOARDING_AND_GETTING_STARTED_FEATURE);
    },

    hasUnlayerLandingPageMaxFeature: (_, getters) => {
        return getters.hasFeature(features.UNLAYER_LANDING_PAGE_MAX_FEATURE);
    },
};
