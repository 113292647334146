import Vue from 'vue';
import { INTEGRATION_STATUS } from '@/appointments/appointments.constants';

export default {
    SET_SELECTED_DATE(state, day) {
        Vue.set(state, 'selectedDate', day);
    },

    SET_CALENDAR_APPTS(state, appts) {
        Vue.set(state, 'apptsRaw', appts);
    },

    SET_APPOINTMENT_VIEWING_FILTER(state, filter) {
        state.appointmentFilter.appointmentViewOption = filter;
    },

    SET_CALENDAR_FILTER(state, filter) {
        state.appointmentFilter.visibleCalendars = filter;
    },

    SET_PROCESSED_APPTS(state, appts) {
        Vue.set(state, 'appts', appts);
    },

    REMOVE_CALENDAR_APPT(state, apptId) {
        const appts = state.appts && state.appts.filter((appt) => appt.id !== apptId);

        Vue.set(state, 'appts', appts);

        if (state.apptsRaw) {
            Vue.set(state, 'apptsRaw', state.apptsRaw.filter((x) => x.id !== apptId));
        }
    },

    SET_APPTS_RANGE(state, range) {
        Vue.set(state, 'apptsRange', range);
    },

    SET_APPTS_LOADED(state, loaded) {
        state.apptsLoaded = loaded;
    },

    SET_PROVIDERS_LOADED(state) {
        const { providers } = state;

        providers.forEach((provider, index) => {
            Vue.set(state.providers[index], 'isLoaded', true);
        });
    },

    SET_PROVIDER_CALENDARS(state, payload) {
        const { providers } = state;

        providers.forEach((provider, index) => {
            if (provider.isLoaded && provider.status && provider.status !== INTEGRATION_STATUS.DISCONNECTED) {
                Vue.set(state.providers[index], 'calendars', payload);
            }
        });
    },

    UPDATE_PROVIDER(state, payload) {
        const { name } = payload;
        const { providers } = state;

        const newProviders = providers.map((provider) => {
            if (provider.name === name) {
                const newProvider = {
                    ...provider,
                    ...payload,
                };

                return newProvider;
            }

            return provider;
        });

        Vue.set(state, 'providers', newProviders);
    },

    SET_APPT_TYPES_LOADED(state, loaded) {
        state.apptTypesLoaded = loaded;
    },

    SET_APPT_TYPES(state, { types, nextPage }) {
        types.forEach(injectApptTypeNameIfNull);

        if (!nextPage) {
            state.apptTypes = types;

            return;
        }

        const existingAppointmentIds = state.apptTypes.map(({ id }) => id);

        const appointmentTypes = [
            ...state.apptTypes,
            ...types.filter(({ id }) => !existingAppointmentIds.includes(id)),
        ];

        Vue.set(state, 'apptTypes', appointmentTypes);
    },

    SET_STATE_INITIALIZED(state, initialized) {
        state.stateInitialized = initialized;
    },

    LOAD_ACTIVE_APPT_TYPE(state, apptTypeId) {
        const activeApptType = state.apptTypes.find((a) => a.id === apptTypeId);

        Vue.set(state, 'activeApptType', activeApptType);
    },

    SET_ACTIVE_APPT_TYPE(state, payload) {
        Vue.set(state, 'activeApptType', payload);
    },

    ADD_APPT_TYPE(state, payload) {
        const { apptTypes } = state;

        injectApptTypeNameIfNull(payload);

        apptTypes.unshift(payload);

        Vue.set(state, 'apptTypes', apptTypes);
    },

    SET_APPT_TYPE(state, payload) {
        const { apptTypes } = state;
        const index = apptTypes.findIndex((a) => a.id === payload.id);

        injectApptTypeNameIfNull(payload);

        apptTypes.splice(index, 1, payload);
        Vue.set(state, 'apptTypes', apptTypes);
    },

    REMOVE_APPT_TYPE(state, id) {
        const { apptTypes } = state;
        const index = apptTypes.findIndex((a) => a.id === id);

        apptTypes.splice(index, 1);
        Vue.set(state, 'apptTypes', apptTypes);
    },

    SET_WIZARD_ACTIVE_STAGE(state, stage) {
        state.wizardActiveStage = stage;
    },

    SET_TEMP_APPT_TYPE(state, payload) {
        state.tempApptType = payload;
    },

    SET_CONNECT_REDIRECT(state, redirect) {
        state.connectRedirect = redirect;
    },

    SET_OPEN_APPOINTMENT(state, payload) {
        state.openAppointment = payload;
    },

    APPT_TO_DELETE(state, payload) {
        state.apptToDelete = payload;
    },
};

// Some appointment types may have been created before there was a "name" field.
// Systems were using the title from these legacy appt types to derive the
// name field on an as-needed basis using a mixin that split the title on the
// string `with`. Now that the name field is a first class property on the
// appointemnt type, we only need to parse the "name" from the "title" when it
// is blank.
const injectApptTypeNameIfNull = (apptType) => {
    if (!apptType.name) {
        apptType.name = apptType.title && apptType.title.slice(0, apptType.title.lastIndexOf('with') - 1);
    }
};
