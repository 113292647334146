<template>
    <a
        v-if="to"
        :href="href"
        class="side-nav-menu-item"
        :class="{ 'side-nav-menu-item-active': isActive }"
        @click.prevent="onLinkClick"
    >
        <ds-icon :name="icon" />

        <div class="content">
            <slot />
        </div>
    </a>

    <button
        v-else
        type="button"
        class="side-nav-menu-item"
        :class="{ 'side-nav-menu-item-active': isActive }"
        @click="$emit('click', $event)"
    >
        <ds-icon :name="icon" />

        <div class="content">
            <slot />
        </div>
    </button>
</template>

<script>
export default {
    props: {
        to: [String, Object],
        icon: { type: String, required: true },
        isActive: Boolean,
    },

    computed: {
        href() {
            const {
                to,
                $router: router,
                $route: current,
            } = this;

            if (!to) {
                return null;
            }

            return router.resolve(to, current).href;
        },
    },

    methods: {
        onLinkClick() {
            const onComplete = () => {
                this.$emit('navigation-complete');
            };

            this.$router.push(this.to, onComplete);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./side-nav-variables";

.side-nav-menu-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: px-to-rem(60);
    align-items: center;
    justify-content: center;
    background: transparent;
    font-size: px-to-rem(11);
    line-height: $line-height-sm;
    color: $color-paper;
    font-weight: $font-weight-semibold;
    text-decoration: none;

    // resets for button
    border-radius: 0;
    padding: 0;

    &:hover,
    &:focus {
        background: $side-nav-color-tertiary;
    }

    &.side-nav-menu-item-active {
        background: $side-nav-color-secondary;
        border-left: px-to-rem(6) solid $color-green;
        border-right: px-to-rem(6) solid transparent;
    }

    .content {
        margin-top: .25rem;
    }
}
</style>
