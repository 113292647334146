// ROGUE - Rows of generated unicode entries

import { getUnsupportedMergeFields } from './smsBroadcast.util';

export const sliceTextByMergeFields = ({ text, supportedMergeFields }) => {
    const unsupportedMergeFields = getUnsupportedMergeFields(text, supportedMergeFields);

    const delimiters = [...supportedMergeFields, ...unsupportedMergeFields];

    let slices = [text];

    delimiters.forEach((delimiter) => {
        slices = slices.flatMap((lineToParse) => {
            if (supportedMergeFields.includes(lineToParse)) {
                return [lineToParse];
            }

            return lineToParse
                .split(delimiter)
                .reduce((acc, v) => acc.concat(v, delimiter), [])
                .slice(0, -1);
        });
    });

    return slices;
};

export const textToRogue = ({
    text,
    maxCharacterCount,
    longestMergeableFieldLength,
    mergeFields,
}) => {
    let length = 0;
    const lines = text.split('\n');

    const rows = lines.map((line, i) => {
        const supportedMergeFields = Object.keys(mergeFields);
        const unsupportedMergeFields = getUnsupportedMergeFields(text, supportedMergeFields);

        const slices = sliceTextByMergeFields({ text: line, supportedMergeFields });

        const values = slices
            .flatMap((value) => {
                if (supportedMergeFields.includes(value)) {
                    length += longestMergeableFieldLength || value.length;
                } else {
                    length += value.length;
                }
                const hasOverflow = maxCharacterCount > 0 && length > maxCharacterCount;

                if (supportedMergeFields.includes(value)) {
                    return {
                        type: 'component',
                        component: 'chip',
                        label: mergeFields[value],
                        text: value,
                        ...(hasOverflow && { showError: true }),
                    };
                }

                if (unsupportedMergeFields.includes(value)) {
                    return {
                        type: 'text',
                        text: value,
                        showError: true,
                    };
                }

                if (hasOverflow && length - value.length < maxCharacterCount) {
                    const overflowCharacterCount = length - maxCharacterCount;
                    const validCharacterCount = value.length - overflowCharacterCount;
                    const validValue = value.slice(0, validCharacterCount);
                    const overflowValue = value.slice(validCharacterCount);

                    return [
                        {
                            type: 'text',
                            text: validValue,
                        },
                        {
                            type: 'text',
                            text: overflowValue,
                            showError: true,
                        },
                    ];
                }

                return {
                    type: 'text',
                    text: value,
                    ...(hasOverflow && { showError: true }),
                };
            });

        // Handle new line characters at the end of each line (except for the last line)
        if (i !== lines.length - 1) {
            length += 1;
        }

        return { values };
    });

    return { rows };
};
