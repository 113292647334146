import Vue from 'vue';
import gql from 'graphql-tag';
import sentry from '@/analytics/sentry';

export const loadChargebeePortalSession = async ({ portalType }) => {
    try {
        const {
            data: { chargebeeSession },
        } = await Vue.prototype.$graphql.query({
            query: gql`
                query getPortalSession($portalType: String!) {
                    chargebeeSession(portalType: $portalType) {
                        subscriptionId
                        portalSession {
                            id
                            token
                            accessUrl
                            status
                            createdAt
                            expiresAt
                            object
                            customerId
                            linkedCustomers {
                                object
                                customerId
                                email
                                hasBillingAddress
                                hasPaymentMethod
                                hasActiveSubscription
                            }
                        }
                    }
                }
            `,
            variables: {
                portalType,
            },
            fetchPolicy: 'no-cache',
        });

        return chargebeeSession;
    } catch (error) {
        sentry.captureException(error);

        throw error;
    }
};

export const createCartToken = async () => {
    try {
        const { data: { freeTrialUpgradeCartToken: { jwt } } } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation subscriptionManagement {
                    freeTrialUpgradeCartToken {
                        jwt
                    }
                }
            `,
            fetchPolicy: 'no-cache',
        });

        return jwt;
    } catch (error) {
        sentry.captureException(error);

        throw error;
    }
};
