<template lang="html">
    <section v-if="filteredPhoneList.length || filteredEmailList.length || filteredFaxList.length" class="contact-phone-email">
        <h4 class="section-title section-margin">
            {{ $t('contactDetails.sectionHeaders.phoneEmailFax') }}
        </h4>

        <div v-if="filteredPhoneList.length" class="field-grid phone-fields">
            <div v-for="{ id, phone, status } in filteredPhoneList" :key="id">
                <span
                    class="view-field"
                    :data-qa="`phone-${id}`"
                >
                    <small v-if="phone.type" class="label">
                        {{ $t(`global.phones.${phone.type.toLowerCase()}`) }} {{ $t('forms.phone').toLowerCase() }}
                    </small>

                    <a
                        :href="`tel:${formatPhoneTelLink(phone.value)}`"
                        :data-qa="`phone-link-${id}`"
                        class="fs-block"
                    >
                        {{ formatPhone(phone.value) }}
                    </a>
                </span>

                <contact-phone-opt-status v-if="showPhoneOptStatus" :status="status" />
            </div>
        </div>

        <div v-if="filteredEmailList.length" class="field-grid email-fields">
            <div v-for="emailAddress in filteredEmailList" :key="emailAddress.fieldName">
                <span
                    class="view-field"
                    :data-qa="`${emailAddress.fieldName}`"
                >
                    <small class="label">
                        {{ $t(emailAddress.label) }}
                    </small>

                    <a
                        :href="`mailto:${emailAddress.email}`"
                        class="fs-block"
                        @click.prevent="sendEmail(emailAddress.email)"
                    >
                        {{ emailAddress.email }}
                    </a>
                </span>

                <contact-email-marketability
                    v-if="Object.keys(contactEmailMarketability).length"
                    editable
                    :email-marketability="contactEmailMarketability[emailAddress.fieldName]"
                    :email-field="emailAddress.fieldName"
                />
            </div>
        </div>

        <div v-if="filteredFaxList.length" class="field-grid fax-fields">
            <div v-for="{ id, fax } in filteredFaxList" :key="id">
                <span
                    class="view-field"
                    :data-qa="`fax-${id}`"
                >
                    <small v-if="fax.type" class="label">
                        {{ $t(`global.phones.${fax.type.toLowerCase()}`) }} {{ $t('forms.fax').toLowerCase() }}
                    </small>

                    <a
                        :href="`tel:${formatPhoneTelLink(fax.value)}`"
                        :data-qa="`fax-link-${id}`"
                        class="fs-block"
                    >
                        {{ formatPhone(fax.value) }}
                    </a>
                </span>
            </div>
        </div>

        <send-email-modal
            v-if="email"
            :contact="contact"
            :email="email"
            @close="email = null"
        />
    </section>
</template>

<script>
import { mapState } from 'vuex';
import { FF_KEAP_SMS_BROADCAST } from '@/shared/constants/featureFlag.constants';
import { isValidPhoneNumber } from '@/communication/utils/sms-utils';

import SendEmailModal from '@/contacts/components/record/email/SendEmailModal';
import ContactEmailMarketability from './ContactEmailMarketability';
import ContactPhoneOptStatus from './ContactPhoneOptStatus';
import { smsOptInStatusesQuery } from '@/broadcasts/api';
import { formatPhone } from '@/shared/utils/phone.util';
import displayMixin from '@/shared/mixins/display.mixin';

export default {
    components: {
        SendEmailModal,
        ContactEmailMarketability,
        ContactPhoneOptStatus,
    },

    mixins: [displayMixin],

    props: {
        contact: Object,
        contactEmailMarketability: Object,
    },

    data() {
        return {
            email: null,
            optStatuses: [],
        };
    },

    created() {
        this.loadOptStatuses();
    },

    watch: {
        // Need to watch this value, as it is on the deferred boot
        automatedSmsNumber(value) {
            if (value) {
                this.loadOptStatuses();
            }
        },
    },

    computed: {
        ...mapState({
            automatedSmsNumber: ({ auth }) => auth.account.automatedSmsAccount?.phoneNumber,
            smsBroadcastEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_BROADCAST],
        }),

        filteredPhoneList() {
            if (!this.contact) {
                return [];
            }

            const phoneList = [];

            ['phone1', 'phone2', 'phone3', 'phone4', 'phone5']
                .forEach((fieldName) => {
                    if (this.contact[fieldName] && this.contact[fieldName].value) {
                        const { statusKind: status } = this.optStatuses.find(
                            ({ phoneNumber }) => formatPhone(phoneNumber) === formatPhone(this.contact[fieldName].value),
                        ) || {};

                        phoneList.push({
                            phone: this.contact[fieldName],
                            id: fieldName.charAt(fieldName.length - 1),
                            ...(status && { status }),
                        });
                    }
                });

            return phoneList;
        },

        filteredEmailList() {
            if (!this.contact) {
                return [];
            }

            return ['email', 'email2', 'email3']
                .filter((fieldName) => this.contact[fieldName])
                .map((fieldName) => ({
                    email: this.contact[fieldName],
                    fieldName,
                    label: this.$t(`contactDetails.fieldHeaders.${fieldName}`),
                }));
        },

        filteredFaxList() {
            if (!this.contact) {
                return [];
            }

            const faxList = [];

            ['fax1', 'fax2']
                .forEach((fieldName) => {
                    if (this.contact[fieldName] && this.contact[fieldName].value) {
                        faxList.push({
                            fax: this.contact[fieldName],
                            id: fieldName.charAt(fieldName.length - 1),
                        });
                    }
                });

            return faxList;
        },

        showPhoneOptStatus() {
            return Boolean(this.smsBroadcastEnabled && this.automatedSmsNumber);
        },
    },

    methods: {
        sendEmail(email) {
            this.email = email;
        },

        async loadOptStatuses() {
            if (this.automatedSmsNumber) {
                const phoneNumbers = this.filteredPhoneList.map(({ phone }) => phone.value).filter((number) => isValidPhoneNumber(number));

                if (phoneNumbers.length) {
                    this.optStatuses = await smsOptInStatusesQuery({
                        fromPhoneNumber: this.automatedSmsNumber,
                        phoneNumbers,
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .label {
        color: $color-gray-700;
        font-size: $font-size-xsmall;
    }

    .phone-fields,
    .email-fields {
        margin-bottom: $gp;
    }

    .fax-fields {
        margin-bottom: $gp / 2;
        margin-top: $gp;
    }
</style>
