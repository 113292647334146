<template>
    <div class="mergeable-input">
        <ds-input-field
            ref="inputField"
            v-model="localValue"
            :class="['merge-field-input', { 'input-focus' : inputFocused }]"
            :type="type"
            :label="label"
            :placeholder="placeholder"
            :data-qa="dataQa"
            :intercept-on-update="interceptInputUpdate"
            :required="required"
            :maxlength="maxlength"
            :submitted="submitted"
            autocomplete="nofill"
            @blur="blurInput"
            @focus="focusInput"
        >
            <template #help>
                <div v-if="helpText" class="help-text">
                    {{ helpText }}
                </div>
            </template>
        </ds-input-field>

        <merge-field-dropdown
            class="merge-field-dropdown"
            :use-merge-service="useMergeService"
            :additional-merge-fields="additionalMergeFields"
            @menuOpen="handleMenuOpen"
            @menuClosed="handleMenuClosed"
            @select="insertTextIntoInput"
        >
            <template #action="{ toggle }">
                <div>
                    <ds-outline-button
                        tabindex="-1"
                        :class="['merge-field-button', { 'input-focus': inputFocused }]"
                        @focus="handleMenuOpen"
                        @mousedown.prevent
                        @click="toggle"
                    >
                        <div class="merge-field-button-box">
                            <ds-icon class="merge-field-icon" name="merge-field" />

                            <span>{{ $t('mergeableInput.merge') }}</span>

                            <ds-icon class="merge-field-chevron-icon" name="chevron-down" />
                        </div>
                    </ds-outline-button>
                </div>
            </template>
        </merge-field-dropdown>
    </div>
</template>

<script>
import MergeFieldDropdown from '@/shared/components/MergeField/MergeFieldDropdown';

export default {
    components: {
        MergeFieldDropdown,
    },

    props: {
        type: {
            type: String,
            default: 'text',
        },

        label: {
            type: String,
            default: null,
        },

        helpText: {
            type: String,
            default: null,
        },

        value: {
            type: String,
            default: '',
        },

        tracking: {
            type: String,
            default: '',
        },

        maxlength: Number,

        placeholder: String,

        dataQa: String,

        required: Boolean,

        submitted: Boolean,

        additionalMergeFields: {
            type: Array,
            default: () => [],
        },

        useMergeService: Boolean,
    },

    data() {
        return {
            localValue: '',
            inputFocused: false,
            menuOpen: false,
            updateCallback: Function,
            newVal: '',
        };
    },

    created() {
        this.localValue = this.value;
    },

    watch: {
        localValue(val) {
            this.$emit('input', val);
        },

        value(val) {
            this.localValue = val;
        },
    },

    methods: {
        blurInput() {
            return this.$nextTick().then(() => {
                if (!this.menuOpen) {
                    this.inputFocused = false;
                }
            });
        },

        focusInput() {
            this.inputFocused = true;
            this.$refs.inputField.input_focus();
        },

        getActiveElement() {
            return document.activeElement;
        },

        handleMenuOpen() {
            this.inputFocused = true;
            this.$refs.inputField.input_focus();
            this.menuOpen = true;
        },

        handleMenuClosed() {
            this.menuOpen = false;

            const inputHasFocus = this.$refs.inputField.$refs.input === this.getActiveElement();

            if (!inputHasFocus) {
                this.inputFocused = false;
            }
        },

        insertTextIntoInput(text = { value: '' }) {
            const { input } = this.$refs.inputField.$refs;
            const { selectionStart, selectionEnd } = input;
            const inputValue = this.localValue || '';

            this.localValue = inputValue.substr(0, selectionStart) + text.value + inputValue.substr(selectionEnd);

            if (this.tracking) {
                this.$track(this.tracking);
            }
            input.focus();
        },

        interceptInputUpdate(newVal, updateCallback) {
            this.newVal = newVal;
            this.updateCallback = updateCallback;
            updateCallback(newVal);
            this.focusInput();
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    $button-height: $input-height - px-to-rem(2px);

    .mergeable-input {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: $gp * 1.5;
        position: relative;

        .merge-field-input {
            margin-bottom: 0;
            @include border-end(0);
        }

        .merge-field-input.input-focus {
            --input-padding-right: #{px-to-rem(110px)};
        }
    }

    .help-text {
        color: var(--help-text-color, inherit);
    }

    .merge-field-dropdown {
        @include position(absolute px-to-rem(1px) px-to-rem(1px) auto auto);
        --merge-field-dropdown-width: #{px-to-rem(97px)};
    }

    .merge-field-button {
        flex-direction: column;
        height: $button-height;
        display: none;
        padding: 0 $gp / 2;
        border: 1px solid transparent;
        @include border-radius(0 #{$input-border-radius} #{$input-border-radius} 0);

        &.input-focus {
            display: flex;
            @include border-start(1px solid $color-gray-300);
        }
    }

    .merge-field-button-box {
        display: flex;
        align-items: center;

        span {
            margin: 0 $gp / 4;
        }

        > * {
            line-height: $button-height;
        }
    }

    .merge-field-icon {
        --icon-size: #{px-to-rem(16px)};
    }

    .merge-field-chevron-icon {
        --icon-size: #{px-to-rem(14px)};
    }

    [dir=rtl] {
        .merge-field-input.input-focus {
            --input-padding-right: #{$gp};
            --input-padding-left: #{px-to-rem(110px)};
        }
    }
</style>

<i18n>
{
    "en-us": {
        "mergeableInput": {
            "merge": "Merge"
        }
    }
}
</i18n>
