<template lang="html">
    <div class="import-contacts-permission">
        <h2 class="secondary-title mega">
            {{ $t('heading') }}
        </h2>

        <div class="question">
            <p>{{ $t('questions.permission') }}</p>

            <div class="radio-group">
                <ds-radio
                    v-model="permission"
                    data-qa="has-permission"
                    :label="$t('global.yes')"
                    val="true"
                />

                <ds-radio
                    v-model="permission"
                    :label="$t('global.no')"
                    val="false"
                />
            </div>
        </div>

        <div class="notes">
            <div
                v-if="permissionBoolean === null"
                class="note"
            >
                <div>{{ $t('permissionsNotes.default') }}</div>
            </div>

            <div
                v-else-if="permissionBoolean === true"
                class="note"
            >
                <ds-icon name="check-square" class="success-icon" />
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="$t('permissionsNotes.recommended', { url: articleBaseUrl })" />
                <!-- eslint-enable vue/no-v-html -->
            </div>

            <div
                v-else-if="permissionBoolean === false"
                class="note"
            >
                <ds-icon name="alert-triangle" class="warning-icon" />
                <!-- eslint-disable vue/no-v-html -->
                <div v-html="$t('permissionsNotes.notRecommended', { url: articleBaseUrl })" />
                <!-- eslint-enable vue/no-v-html -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: Boolean,
    },

    data() {
        return {
            permission: '',
            articleBaseUrl: process.env.VUE_APP_KNOWLEDGEOWL_URL,
        };
    },

    created() {
        this.setPermission();
    },

    watch: {
        permissionBoolean(value) {
            this.$track(`Contacts - Import - Finish - clicked : marketing permission ${value}`);
            this.$store.commit('contactImport/SET_OPTIN_STATUS', value);
            this.$store.commit('contactImport/SET_STAGE_READY', {
                stage: 'final',
                isReady: value !== null,
            });

            this.$emit('input', value);
        },

        value() {
            this.setPermission();
        },
    },

    computed: {
        permissionBoolean() {
            return this.permission === '' ? null : this.permission === 'true';
        },
    },

    methods: {
        setPermission() {
            if (this.value === false) {
                this.permission = 'false';
            } else if (this.value === true) {
                this.permission = 'true';
            } else {
                this.permission = '';
            }
        },
    },
};
</script>


<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .radio-group {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: $gp * 1.5;
        justify-content: center;
        margin-top: $gp;
    }

    .question {
        text-align: center;

        p {
            margin: 0 auto;
            font-size: px-to-rem(18px);
            max-width: px-to-rem(320px);
            line-height: 1.5;
        }
    }

    .note {
        @include text-align-start;
        margin-top: $gp;
        margin-bottom: $gp * 2;
        padding: $gp;
        display: flex;

        .icon {
            @include margin-end($gp);
        }
    }

    .success-icon {
        --icon-color: #{$color-green};
    }

    .warning-icon {
        --icon-color: #{$color-red};
    }

    .secondary-title {
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "heading": "One last thing...",
        "questions": {
            "permission": "Are these contacts expecting marketing emails from you?"
        },
        "permissionsNotes": {
            "default": "Getting permission to send emails to your audience increases the success of your campaigns and helps you build trust.",
            "recommended": "Because you've obtained permission to market to these contacts, you may send them marketing emails. <br/> <br/> See our <a target='_blank' href='{url}/help/import-guide'>Email Deliverability Handbook</a> for more details.",
            "notRecommended": "Based on your response, you're unable to market to these contacts. You'll need to obtain permission to be able to send them marketing emails. You can still send them non-marketing emails. <br/> <br/> See our <a target='_blank' href='{url}/help/import-guide'>Email Deliverability Handbook</a> for more details"
        },
        "permissionsNote": "Getting permission to send emails to your audience increases the success of your campaigns and helps you build trust."
    }
}
</i18n>
