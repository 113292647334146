<template>
    <section class="sms-broadcast-setup-state">
        <ds-illustration class="illustration" name="voice-messenger-color" />

        <h3 class="title">
            {{ $t('title') }}
        </h3>

        <p class="description">
            {{ $t('description') }}
        </p>

        <div class="button-row">
            <ds-outline-button @click="setup">
                {{ $t('getNumber') }}
            </ds-outline-button>
        </div>

        <hr />

        <p class="footer-title">
            {{ footerTitle }}
        </p>

        <small class="footer-description">{{ $t('footerDescription') }}</small>
    </section>
</template>

<script>
import { trackSmsAccountProvisioningStart } from '@/broadcasts/smsAnalytics';

export default {
    props: {
        footerText: String,
        eventSource: {
            type: String,
            default: 'Broadcasts',
        },
    },

    computed: {
        footerTitle() {
            return this.footerText || this.$t('footerTitle');
        },
    },

    methods: {
        setup() {
            this.$emit('setup');

            trackSmsAccountProvisioningStart(this.eventSource);
        },
    },
};
</script>

<style lang="scss" scoped>
.sms-broadcast-setup-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: px-to-rem(640px);
    width: 100%;
}

.illustration {
    margin-bottom: $gp * 2;
}

.title {
    text-align: center;
    font-size: $font-size-xxl;
    font-family: $font-family-secondary-title;
    font-weight: 600;
    padding-bottom: $gp * 1.25;
}

.description {
    text-align: center;
    margin-bottom: $gp * 2;
    max-width: px-to-rem(500);
}

.footer-title {
    text-align: center;
    font-weight: $font-weight-semibold;
    color: $color-gray-700;
    margin-bottom: $gp / 2;
}

.footer-description {
    text-align: center;
    color: $color-gray-700;
}
</style>

<i18n>
{
    "en-us": {
        "title": "Set up your Keap Marketing Number",
        "description": "Your 1-800 Keap Marketing Number is used to send automated and broadcast messages from Keap.",
        "getNumber": "Get my Keap Marketing Number",
        "footerTitle": "Can I use my Keap Business Line for Text Broadcasts?",
        "footerDescription": "No. Your Keap Business Line is only for direct communication with your contacts. This way, if your Keap Marketing Number is blocked, you'll still be able to communicate with your Business Line."
    }
}
</i18n>
