<template lang="html">
    <div class="message-list-group">
        <span class="date">{{ date }}</span>

        <message-list
            v-for="(item, i) in group.data"
            :key="i"
            :data="item"
        />
    </div>
</template>

<script>
import moment from 'moment';

import MessageList from '@/communication/components/MessageList';

export default {
    components: {
        MessageList,
    },

    props: {
        group: {
            type: Object,
            required: true,
        },
    },

    computed: {
        date() {
            return moment(this.group.day).format('ll');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .message-list-group {
        text-align: center;
        padding: $gp 0 0;
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
    }

    .date {
        color: $color-gray-700;
    }
</style>
