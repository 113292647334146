<template>
    <ds-tooltip position="top" :disabled="showMergeFieldPopover">
        <template #reference>
            <ds-popover
                :is-open="showMergeFieldPopover"
                position="top-start"
                hide-arrow
                sticky
                @close="showMergeFieldPopover = false"
            >
                <template #reference>
                    <ds-icon-button
                        data-qa="mergeFieldButton"
                        name="merge-field"
                        @click="handleMergeFieldButtonClick"
                    />
                </template>

                <template #default>
                    <div class="merge-fields">
                        <ds-list-item
                            border-style="none"
                            :description="$t('insertMergeField')"
                        />

                        <ds-list-item
                            v-for="({title, value}, i) in mergeFields"
                            :key="i"
                            class="merge-field"
                            border-style="none"
                            :title="title"
                            @click.native="handleMergeFieldClick(value)"
                        />
                    </div>
                </template>
            </ds-popover>
        </template>

        <template #default>
            {{ $t('insertMergeField') }}
        </template>
    </ds-tooltip>
</template>

<script>
export default {
    data() {
        return {
            mergeFields: [
                { title: this.$t('recipient', { field: this.$t('firstName') }), value: 'contact.first_name' },
                { title: this.$t('recipient', { field: this.$t('lastName') }), value: 'contact.last_name' },
            ],
            showMergeFieldPopover: false,
        };
    },

    methods: {
        handleMergeFieldButtonClick() {
            this.showMergeFieldPopover = !this.showMergeFieldPopover;
        },

        handleMergeFieldClick(mergeField) {
            this.showMergeFieldPopover = false;
            this.$emit('input', `[[${mergeField}]]`);
        },
    },
};
</script>

<style lang="scss" scoped>
    .merge-fields {
        padding-bottom: $gp;
    }

    .merge-field {
        cursor: pointer;

        &:hover {
            background-color: $color-gray-050;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "insertMergeField": "Insert merge field",
        "recipient": "Recipient's {field}",
        "firstName": "first name",
        "lastName": "last name"
    }
}
</i18n>
