import moment from 'moment';
import Vue from 'vue';

import { normalizePhoneNumber } from '@/communication/utils/sms-utils';
import { ERROR_MESSAGE_STATUS } from '@/communication/communication.constants';

const dedupeMessages = (existingConversation, messages) => {
    return existingConversation.messages.filter((m) => {
        // Filter out sent messages that don't have an id
        return m.status === ERROR_MESSAGE_STATUS
            || (Boolean(m.messageId)
                && !messages.some(({ messageId }) => messageId === m.messageId));
    });
};

export default {
    CREATE_CONVERSATION({ sms: { conversations } }, {
        phoneNumber,
        message: lastMessage,
        mediaUrls = [],
        ...rest
    }) {
        const now = new Date().toISOString();
        const normalizedPhone = normalizePhoneNumber(phoneNumber);

        Vue.set(conversations, normalizedPhone, {
            ...rest,
            phoneNumber: normalizedPhone,
            lastMessage,
            createTime: now,
            updateTime: now,
            messages: [
                {
                    body: lastMessage,
                    createTime: now,
                    mediaUrls,
                    outgoing: true,
                    updateTime: now,
                },
            ],
        });
    },

    SET_CONVERSATIONS({ sms }, { conversations }) {
        conversations.forEach((conversation) => {
            const normalizedPhone = normalizePhoneNumber(conversation.phoneNumber);
            const existingConversations = sms.conversations;
            const existingConversation = existingConversations[normalizedPhone];
            const conversationMessages = existingConversation != null
                ? existingConversation.messages
                : [];

            Vue.set(existingConversations, normalizedPhone, {
                ...existingConversation,
                ...conversation,
                messages: [...conversationMessages],
            });
        });
    },

    SET_CONVERSATION_MESSAGES({ sms: { conversations } }, { messages, phoneNumber }) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);
        const conversation = conversations[normalizedPhone];

        if (conversation != null) {
            const existingMessages = conversation.messages;

            if (Array.isArray(existingMessages)
                    && existingMessages.length > 0
                    && existingMessages.length < messages.length) {
                const { body } = messages[0];
                const unreadCount = messages.length - existingMessages.length;
                const now = new Date().toISOString();

                Vue.set(conversation, 'lastMessage', body);
                Vue.set(conversation, 'unreadCount', unreadCount);
                Vue.set(conversation, 'updateTime', now);
            }

            const dedupedMessages = dedupeMessages(conversation, messages);

            Vue.set(conversation, 'messages', [...messages, ...dedupedMessages]);
        }
    },

    ADD_MESSAGES_TO_CONVERSATION({ sms: { conversations } }, { messages, phoneNumber }) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);
        const conversation = conversations[normalizedPhone];

        if (conversation != null) {
            const existingMessages = conversation.messages;
            let allMessages = [...messages];

            if (Array.isArray(existingMessages)) {
                allMessages = [...messages, ...existingMessages];
            }

            const dedupedMessages = dedupeMessages(conversation, allMessages);

            Vue.set(conversation, 'messages', [...allMessages, ...dedupedMessages]);
        }
    },

    SET_NO_MORE_DATA({ sms: { conversations } }, { phoneNumber, value }) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);
        const conversation = conversations[normalizedPhone];

        if (conversation != null) {
            Vue.set(conversation, 'noMoreData', value);
        }
    },

    UPDATE_CONVERSATION({ sms: { conversations } }, { phoneNumber, conversation }) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);

        const { messages } = conversations[normalizedPhone];

        Vue.set(conversations, normalizedPhone, {
            ...conversation,
            messages,
        });
    },

    UPDATE_CONVERSATION_MESSAGES({ sms: { conversations } }, {
        message,
        phoneNumber,
        mediaUrls = [],
    }) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);
        const conversation = conversations[normalizedPhone];

        const {
            body,
            updateTime,
        } = message;

        Vue.set(conversation, 'lastMessage', body);
        Vue.set(conversation, 'lastMessageTime', moment().toISOString());
        Vue.set(conversation, 'updateTime', updateTime);

        Vue.set(conversation, 'messages', [
            {
                ...message,
                createTime: updateTime,
                mediaUrls,
            },
            ...conversation.messages,
        ]);
    },

    DELETE_CONVERSATION({ sms: { conversations } }, { phoneNumber }) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);

        Vue.delete(conversations, normalizedPhone);
    },

    SET_CONVERSATION_READ({ sms: { conversations } }, { phoneNumber }) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);
        const conversation = conversations[normalizedPhone];

        if (conversation != null) {
            Vue.set(conversation, 'unreadCount', 0);
        }
    },

    ADD_DRAFT({ sms: { drafts } }, { file }) {
        const normalizedPhone = normalizePhoneNumber(file.phoneNumber);

        Vue.set(drafts, normalizedPhone, file);
    },

    DELETE_FILE({ sms }, phoneNumber) {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);
        const { drafts } = sms;

        delete drafts[normalizedPhone];

        Vue.set(sms, 'drafts', { ...drafts });
    },

    SET_CONVERSATION_HAS_ERROR({ sms: { conversations } }, { hasError, phoneNumber }) {
        const conversation = conversations[phoneNumber];

        if (conversation != null) {
            Vue.set(conversation, 'hasError', hasError);
        }
    },

    DELETE_ERROR_MESSAGE({ sms: { conversations } }, { messageId, phoneNumber }) {
        const conversation = conversations[phoneNumber];

        if (!conversation) {
            return;
        }

        const { messages } = conversation;
        let hasError = false;
        const filteredMessages = [];

        messages.forEach((message) => {
            const keepMessage = message.messageId !== messageId;

            hasError = hasError || (keepMessage && message.status === ERROR_MESSAGE_STATUS);

            if (keepMessage) {
                filteredMessages.push(message);
            }
        });

        Vue.set(conversation, 'messages', filteredMessages);
        Vue.set(conversation, 'hasError', hasError);
    },

    SET_CALL_CONTACT_DATA(state, {
        id, firstName, lastName, phoneNumber,
    }) {
        state.call.contact.id = id;
        state.call.contact.firstName = firstName;
        state.call.contact.lastName = lastName;
        state.call.contact.phoneNumber = phoneNumber;
    },

    SET_ACTIVE_CALL(state, activeCall) {
        state.call.activeCall = activeCall;
    },

    SET_CALL_STATUS(state, callStatus) {
        state.call.callStatus = callStatus;
    },

    SET_CALL_DEVICE(state, callDevice) {
        state.call.device = callDevice;
    },

    SET_CALL_DEVICE_STATUS(state, deviceStatus) {
        state.call.deviceStatus = deviceStatus;
    },

    SET_CALL_ACCESS_TOKEN_EXPIRATION(state, callAccessTokenExpiration) {
        state.call.accessTokenExpiration = callAccessTokenExpiration;
    },

    SET_CALL_DURATION(state, duration) {
        state.call.duration = duration;
    },

    SET_CALL_DURATION_INTERVAL_ID(state, durationIntervalId) {
        state.call.durationIntervalId = durationIntervalId;
    },

    RESET_CALL_DURATION(state) {
        state.call.duration = 0;
        clearInterval(state.call.durationIntervalId);
        state.call.durationIntervalId = null;
    },

    SET_MUTE_STATUS(state, isMuted) {
        state.call.isMuted = isMuted;
    },

    SET_MIC_DEVICES(state, micDevices) {
        state.call.mic.devices = micDevices;
    },

    SET_SPEAKER_DEVICES(state, speakerDevices) {
        state.call.speaker.devices = speakerDevices;
    },

    SET_MIC_DEVICE(state, micDevice) {
        state.call.mic.activeDevice = micDevice;
    },

    SET_SPEAKER_DEVICE(state, speakerDevice) {
        state.call.speaker.activeDevice = speakerDevice;
    },

    SET_CALL_VOLUME(state, { inputVolume, outputVolume }) {
        state.call.mic.volume = inputVolume;
        state.call.speaker.volume = outputVolume;
    },
};
