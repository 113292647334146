<template>
    <section class="sms-broadcast-preview">
        <span class="preview-title">{{ $t('preview') }}</span>

        <div class="preview-wrapper">
            <component
                :is="walkthroughComponent"
                class="walkthrough-step"
                :step="5"
            >
                <div class="phone">
                    <div class="messages-wrapper">
                        <transition name="fade" mode="out-in">
                            <div v-if="!isOptInMessageShown" key="standard" class="messages">
                                <ds-text-button dense class="opt-in-button" @click="showOptInMessage">
                                    {{ $t('showOptInMessage') }}
                                </ds-text-button>

                                <span class="date">
                                    {{ today }}
                                </span>

                                <sms-broadcast-preview-message
                                    class="message message-preview"
                                    :message="message"
                                    :merge-fields="mergeFields"
                                />
                            </div>

                            <div
                                v-else
                                key="opt-in"
                                ref="optInPreview"
                                class="opt-in-message-preview"
                                :class="{ 'hide-scroll-bar': hideScrollBar }"
                            >
                                <div class="opt-in-wrapper">
                                    <ds-text-button
                                        v-if="isHideButtonShown"
                                        dense
                                        class="opt-in-button shown"
                                        @click="hideOptInMessage"
                                    >
                                        {{ $t('hideOptInMessage') }}
                                    </ds-text-button>

                                    <div v-if="isOptInShown" class="opt-in-message-wrapper">
                                        <div class="message opt-in-message" @mouseover="isOptInMessageHovered = true" @mouseleave="isOptInMessageHovered = false">
                                            <button class="message-button" @click="isOptInMessageHovered = !isOptInMessageHovered">
                                                {{ optInMessage }}
                                            </button>

                                            <transition name="fade">
                                                <div
                                                    v-if="isOptInMessageHovered"
                                                    class="edit-overlay"
                                                    @click="isSettingsModalOpen = true"
                                                >
                                                    <ds-filled-button leading-icon="edit">
                                                        {{ $t('edit') }}
                                                    </ds-filled-button>
                                                </div>
                                            </transition>
                                        </div>

                                        <div class="message-meta-data">
                                            {{ formattedPhoneNumber }} {{ $t('optInDate') }}
                                        </div>
                                    </div>

                                    <div v-if="isReplyShown" class="opt-in-response-wrapper">
                                        <div class="message opt-in-response">
                                            {{ $t('global.yes') }}
                                        </div>

                                        <div class="message-meta-data">
                                            {{ $t('responseDate') }}
                                        </div>
                                    </div>

                                    <div
                                        v-if="isOptInConfirmationShown"
                                        ref="confirmationMessage"
                                        class="message opt-in-confirmation-message"
                                        @mouseover="isOptInConfirmationHovered = true"
                                        @mouseleave="isOptInConfirmationHovered = false"
                                    >
                                        <button class="message-button" @click="isOptInConfirmationHovered = !isOptInConfirmationHovered">
                                            {{ account.optInConfirmationMessage }}
                                        </button>

                                        <transition name="fade">
                                            <div v-if="isOptInConfirmationHovered" class="edit-overlay">
                                                <span class="confirmation-message-description">{{ $t('confirmationMessageDescription') }}</span>
                                            </div>
                                        </transition>
                                    </div>

                                    <div v-if="isMessageShown" ref="message" class="message-wrapper">
                                        <sms-broadcast-preview-message
                                            class="message message-preview"
                                            :message="message"
                                            :merge-fields="mergeFields"
                                        />

                                        <div class="message-meta-data">
                                            {{ formattedPhoneNumber }} {{ $t('messageDate') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </transition>

                        <div class="input-wrapper">
                            <div class="preview-input">
                                <ds-icon class="send-icon" name="send" />
                            </div>
                        </div>
                    </div>
                </div>
            </component>
        </div>

        <portal to="root">
            <sms-broadcast-settings-modal
                v-if="isSettingsModalOpen"
                @close="isSettingsModalOpen = false"
            />
        </portal>
    </section>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import SmsBroadcastPreviewMessage from './SmsBroadcastPreviewMessage';
import SmsBroadcastSettingsModal from './SmsBroadcastSettingsModal';
import SmsBroadcastWalkthroughStep from './walkthrough/SmsBroadcastWalkthroughStep';
import TextAutomationWalkthroughStep from '@/automations/components/TextAutomationWalkthroughStep';

import { formatPhone } from '@/shared/utils/phone.util';
import { DISPLAY_DATE_FORMAT_WITH_YEAR, TIME_FORMAT } from '@/shared/constants/dateFormats.constants';
import { AUTOMATIONS_SOURCE, BROADCASTS_SOURCE } from '@/shared/components/Text/SmsTextBox';

const OPT_IN_DELAY = 300;
const REPLY_DELAY = 1300;
const OPT_IN_CONFIRMATION_DELAY = 2300;
const MESSAGE_DELAY = 3300;
const HIDE_BUTTON_DELAY = 4300;

export default {
    components: {
        SmsBroadcastPreviewMessage, SmsBroadcastSettingsModal, SmsBroadcastWalkthroughStep, TextAutomationWalkthroughStep,
    },

    props: {
        message: String,
        mergeFields: {
            type: Object,
            default: () => ({}),
        },
        source: {
            type: String,
            validator: (source) => [AUTOMATIONS_SOURCE, BROADCASTS_SOURCE].includes(source),
        },
    },

    data() {
        return {
            hideScrollBar: false,
            isOptInMessageShown: false,
            isOptInMessageHovered: false,
            isOptInShown: false,
            isReplyShown: false,
            isOptInConfirmationShown: false,
            isOptInConfirmationHovered: false,
            isMessageShown: false,
            isHideButtonShown: false,
            isSettingsModalOpen: false,
            timeouts: {
                optInTimeout: null,
                replyTimeout: null,
                optInConfirmationTimeout: null,
                messageTimeout: null,
                hideButtonTimeout: null,
            },
        };
    },

    beforeDestroy() {
        this.clearTimeouts();
    },

    computed: {
        ...mapState({
            account: ({ auth }) => auth.account?.automatedSmsAccount || {},
        }),

        formattedPhoneNumber() {
            return formatPhone(this.account?.phoneNumber);
        },

        optInMessage() {
            return `${this.account.optInMessageIntro}${this.account.optInMessage}`;
        },

        today() {
            const formattedDate = moment().format(`${DISPLAY_DATE_FORMAT_WITH_YEAR} ${TIME_FORMAT}`);

            return `${this.$t('today')} ${formattedDate}`;
        },

        broadcastsSource() {
            return this.source === BROADCASTS_SOURCE;
        },

        automationsSource() {
            return this.source === AUTOMATIONS_SOURCE;
        },

        walkthroughComponent() {
            return this.broadcastsSource ? SmsBroadcastWalkthroughStep : TextAutomationWalkthroughStep;
        },
    },

    methods: {
        reset() {
            this.clearTimeouts();
            this.hideScrollBar = false;
            this.isOptInShown = false;
            this.isReplyShown = false;
            this.isOptInConfirmationShown = false;
            this.isMessageShown = false;
            this.isHideButtonShown = false;
        },

        clearTimeouts() {
            Object.keys(this.timeouts).forEach((timeout) => {
                clearTimeout(this.timeouts[timeout]);
            });
        },

        showOptInMessage() {
            this.isOptInMessageShown = true;
            this.hideScrollBar = true;

            this.timeouts.optInTimeout = setTimeout(async () => {
                this.isOptInShown = true;

                await this.$nextTick();

                this.$refs.message.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, OPT_IN_DELAY);

            this.timeouts.replyTimeout = setTimeout(() => {
                this.isReplyShown = true;
            }, REPLY_DELAY);

            this.timeouts.optInConfirmationTimeout = setTimeout(async () => {
                this.isOptInConfirmationShown = true;

                await this.$nextTick();

                this.$refs.confirmationMessage.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, OPT_IN_CONFIRMATION_DELAY);

            this.timeouts.messageTimeout = setTimeout(async () => {
                this.isMessageShown = true;

                await this.$nextTick();

                this.$refs.message.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }, MESSAGE_DELAY);

            this.timeouts.hideButtonTimeout = setTimeout(() => {
                this.isHideButtonShown = true;
                this.hideScrollBar = false;
            }, HIDE_BUTTON_DELAY);
        },

        hideOptInMessage() {
            this.isOptInMessageShown = false;

            this.reset();
        },
    },
};
</script>

<style lang="scss" scoped>
    .sms-broadcast-preview {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: $color-gray-050;
        border-left: 1px solid $color-ink-200;
        @include overflowY;

        @media($small) {
            display: none;
        }
    }

    .preview-wrapper {
        display: inline-flex;
        flex-direction: column;
    }

    .preview-title {
        align-self: flex-start;
        font-size: px-to-rem(18);
        font-weight: $font-weight-semibold;
        color: $color-text-subtle;
        padding: .75rem .75rem 2.5rem;
    }

    .walkthrough-step {
        --popover-min-width: #{px-to-rem(299)};
        --popover-width: #{px-to-rem(299)};
    }

    .phone {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: px-to-rem(287);
        height: px-to-rem(579);
        background-color: $color-paper;
        border: 3px solid $color-ink;
        border-radius: $border-radius * 2;
        box-shadow: $elevation-z8;
    }

    .opt-in-button {
        align-self: center;
        margin: $gp / 2 0 $gp;

        &.shown {
            margin-top: 0;
        }
    }

    .messages-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        overflow: hidden;
    }

    .messages {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin: $gp / 2 $gp;
    }

    .date {
        margin-top: auto;
        padding-bottom: $gp / 2;
        align-self: center;
        color: $color-ink-700;
        font-size: $font-size-xsmall;
    }

    .message {
        word-break: break-word;
        white-space: pre-line;
        background-color: $color-ink-100;
        font-size: $font-size-med;
        line-height: $line-height-lg;
        min-height: px-to-rem(50);
        max-width: px-to-rem(221);
        width: 100%;
        padding: $gp;
        border-radius: $border-radius;
    }

    .message-button {
        background-color: transparent;
        padding: 0;
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-align: inherit;
    }

    .input-wrapper {
        padding: 0 $gp $gp;
        width: 100%;
    }

    .preview-input {
        padding: 0 $gp * 0.375;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        height: px-to-rem(32);
        width: 100%;
        background-color: $color-gray-050;
        border-radius: $border-radius / 2;
        --icon-color: #{$color-ink-200};
        --icon-size: #{px-to-rem(18.33)};
    }

    .opt-in-message-preview {
        display: flex;
        flex-direction: column;
        flex: 1;
        @include overflowY;
        scroll-behavior: smooth;
        padding: 0 $gp / 2 0 $gp;
        margin: $gp $gp / 8 $gp / 2 0;
    }

    .opt-in-message {
        position: relative;
        overflow: hidden;

        .edit-overlay {
            cursor: pointer;
        }
    }

    .edit-overlay {
        position: absolute;
        background-color: $color-ink-500;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .opt-in-wrapper {
        display: flex;
        flex-direction: column;
    }

    .message-meta-data {
        padding: $gp / 2 0 $gp;
        color: $color-text-subtle;
    }

    .opt-in-confirmation-message {
        animation: fromBottomLeft .4s ease-in-out 0s;
        position: relative;
        overflow: hidden;
    }

    .confirmation-message-description {
        background-color: $color-paper;
        width: 80%;
        padding: $gp / 2;
        font-size: $font-size-small;
        border-radius: $border-radius / 2;
    }

    .opt-in-response-wrapper {
        display: flex;
        flex-direction: column;
        animation: fromBottomRight .2s ease-in-out 0s;

        .opt-in-response {
            width: auto;
            align-self: flex-end;
            background-color: $color-green-050;
        }

        .message-meta-data {
            align-self: flex-end;
        }
    }

    .message-wrapper {
        animation: fromBottomLeft .4s ease-in-out 0s;
        margin-top: $gp / 2;
    }

    @keyframes fromBottomLeft {
        0% {
            transform: translate(-100%, 75%);
            opacity: 0;
            visibility: hidden;
        }

        80% {
            transform: translate(-10%, 0);
            opacity: 1;
            visibility: visible;
        }

        100% {
            transform: translate(0, 0);
        }
    }

    @keyframes fromBottomRight {
        0% {
            transform: translate(100%, 75%);
            opacity: 0;
            visibility: hidden;
        }

        80% {
            transform: translate(10%, 0);
            opacity: 1;
            visibility: visible;
        }

        100% {
            transform: translate(0, 0);
        }
    }

    .opt-in-message-wrapper {
        animation: fromBottomLeft .3s ease-in-out 0s;
    }
</style>

<i18n>
{
    "en-us": {
        "preview": "Preview",
        "showOptInMessage": "Show with opt in message",
        "hideOptInMessage": "Hide opt in message",
        "today": "Today",
        "defaultMessage": "Enter message to see preview...",
        "restart": "Restart",
        "optInDate": "9:36 am",
        "responseDate": "9:37 am",
        "messageDate": "Just now",
        "edit": "Edit",
        "confirmationMessageDescription": "Due to text marketing laws, this content can’t be edited."
    }
}
</i18n>
