<template>
    <div class="container" data-qa="additional-options">
        <h2 class="headline">
            {{ $t('content.headline') }}
        </h2>

        <section class="section">
            <div class="title-content">
                <div>
                    <h6 class="title">
                        {{ $t('content.instructions.title') }}
                    </h6>

                    <div>
                        <p>
                            {{ $t('content.instructions.subtitle') }}

                            <a @click="showPreview">{{ $t('content.instructions.previewButton') }}</a>
                        </p>
                    </div>
                </div>

                <ds-toggle-switch v-model="instructionsToggle" no-label class="switch" />
            </div>

            <div v-if="instructionsToggle" class="content">
                <ds-text-area-field
                    v-model="preAppointmentInstructions"
                    type="text"
                    name="long"
                    class="input-field"
                    :label="$t('content.instructions.label')"
                    :maxlength="255"
                />

                <p class="instructions">
                    {{ $t('content.instructions.help') }}
                </p>
            </div>
        </section>

        <section v-if="hasMultipleOrderedCalendars && !shouldSelectDefaultCalendar" class="section">
            <div class="title-content">
                <div>
                    <h6 class="title">
                        {{ $t('content.calendar.title') }}
                    </h6>

                    <div>
                        <p>
                            {{ $t('content.calendar.subtitle') }}
                        </p>
                    </div>
                </div>
            </div>

            <div class="content">
                <div class="calendars-list calendars-boxes">
                    <ds-checkbox
                        v-for="{ title, id } in orderedCalendars"
                        :key="`calendar-${id}`"
                        :label="title"
                        :value="isCalendarSelected(id)"
                        @input="handleCalendarChange($event, id)"
                    />
                </div>

                <ds-select-field
                    v-if="hasMultipleCalendars"
                    v-model="localCalendarId"
                    name="calendarId"
                    label-prop="title"
                    value-prop="id"
                    bind-value-only
                    class="calendar-dropdown"
                    :label="$t('content.calendar.addNewApptsToLabel')"
                    :options="selectedCalendars"
                />
            </div>

            <help-box v-track="'Appointments - Which calendars modal - clicked : How does this work link'">
                <div>{{ $t('content.calendar.infoMessage') }}</div>
            </help-box>
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HelpBox from '@/appointments/components/HelpBox';
import BookingSampleModal from '@/appointments/components/BookingSampleModal';

export default {
    components: {
        HelpBox,
    },
    props: {
        calendarProviderId: {
            type: String,
            required: true,
            default: 'GOOGLE',
        },
        calendarId: String,
        availabilityCalendars: {
            type: Array,
            default: () => [],
        },
        appointmentDescription: {
            type: String,
            default: () => '',
        },
    },

    data() {
        return {
            instructionsToggle: Boolean(this.appointmentDescription) || false,
            localCalendarId: this.calendarId,
            localAvailabilityCalendars: this.availabilityCalendars,
            preAppointmentInstructions: this.appointmentDescription,
        };
    },

    mounted() {
        if (this.shouldSelectDefaultCalendar) {
            this.setDefaultCalendar();
        }
    },

    watch: {
        localCalendarId(value) {
            this.$emit('update:calendarId', value);
        },

        localAvailabilityCalendars(value) {
            this.localCalendarId = value.length === 1
                ? value[0]
                : null;

            this.$emit('update:availabilityCalendars', this.localAvailabilityCalendars);
        },

        preAppointmentInstructions() {
            this.$emit('update:appointmentDescription', this.preAppointmentInstructions.trim());
        },

        isReady: {
            handler(value) {
                this.$emit('update:ready', value);
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters({
            connectedCalendarProvider: 'calendar/connectedCalendarProvider',
            getOrderedCalendars: 'calendar/orderedCalendars',
        }),

        selectedCalendars() {
            return this.calendarProvider && this.calendarProvider.calendars.filter((c) => {
                return this.localAvailabilityCalendars.indexOf(c.id) !== -1;
            }) || [];
        },

        calendarProvider() {
            return this.connectedCalendarProvider(this.calendarProviderId);
        },

        orderedCalendars() {
            return this.getOrderedCalendars(this.calendarProviderId);
        },

        hasMultipleCalendars() {
            return this.localAvailabilityCalendars.length > 1;
        },

        isReady() {
            return Boolean(!this.hasMultipleOrderedCalendars || this.localCalendarId && this.localAvailabilityCalendars.length);
        },

        hasMultipleOrderedCalendars() {
            return this.orderedCalendars.length > 1;
        },

        shouldSelectDefaultCalendar() {
            return this.calendarProviderId === 'MICROSOFT';
        },
    },

    methods: {
        handleCalendarChange(event, id) {
            this.$track(`Appointments - Which calendars modal - clicked : Calendar checkbox in the list of ${this.calendarProviderId} Calendars`);

            if (event) {
                this.localAvailabilityCalendars.push(id);
            } else {
                const index = this.localAvailabilityCalendars.indexOf(id);

                this.localAvailabilityCalendars.splice(index, 1);
            }
        },

        isCalendarSelected(id) {
            return this.localAvailabilityCalendars.includes(id);
        },

        showPreview() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: BookingSampleModal,
                showClose: true,
                modalSize: 'med',
            });
        },

        setDefaultCalendar() {
            const id = this.orderedCalendars[0] && this.orderedCalendars[0].id || null;

            if (!this.localAvailabilityCalendars.includes(id)) {
                this.localAvailabilityCalendars.push(id);
            }

            this.localCalendarId = id;

            this.$emit('update:calendarId', id);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .headline {
        margin: $gp * 2 0;
        text-align: center;
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section {
        max-width: $form-width;
        width: 100%;
    }

    .input-field {
        margin-bottom: $gp / 2;
    }

    .hr {
        border-color: $color-gray-500;
        margin: $gp 0 $gp * 2 0;
    }

    .title-content {
        display: flex;
        justify-content: space-between;
    }

    .title {
        margin-bottom: $gp / 2;
        font-weight: $font-weight-semibold;
    }

    .instructions {
        color: $color-gray-700;
    }

    .switch {
        @include padding-start($gp * 3);
    }

    .calendars-list {
        margin-top: $gp;
        display: flex;
        flex-direction: column;
    }

    .calendars-list-title {
        font-size: $font-size-med;
        font-weight: $font-weight-bold;
    }

    .calendars-boxes {
        display: inline-block;
        margin-top: $gp / 2;
        --checkbox-padding: #{$gp / 2};
    }

    .calendar-dropdown {
        margin-top: $gp;
    }

    @media($small) {
        .switch {
            @include padding-start($gp);
        }
    }
</style>

<i18n>
{
    "en-us": {
        "content": {
            "headline": "Additional options",
            "instructions": {
                "title": "Pre-appointment instructions",
                "subtitle": "Need to give your contact instructions about the appointment?\nThis will display on the",
                "previewButton": "booking confirmation page",
                "label": "Appointment instructions...",
                "help": "Let your contact know what they should bring or prepare for the appointment"
            },
            "calendar": {
                "title": "Your calendars",
                "subtitle": "Which calendars should we use to determine your availability?",
                "addNewApptsToLabel": "Add new appointments to",
                "infoMessage": "NOTE: We'll use these calendars to determine your availability. This will help you avoid getting double-booked."
            }
        }
    }
}
</i18n>
