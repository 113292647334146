import {
    SUPPORTED_COUNTRIES,
} from '@/broadcasts/smsBroadcast.constants';

export default {
    showSmsMarketing(state, getters, rootState, rootGetters) {
        const { appCountryCode } = rootState.global;
        const code = appCountryCode || '';
        const supportedCountries = [...SUPPORTED_COUNTRIES];

        return supportedCountries.includes(code.toUpperCase())
            && rootGetters['auth/isPaid'];
    },
};
