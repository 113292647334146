<template lang="html">
    <div>
        <ds-modal ref="importInProgressDialog" dialog>
            <h4>{{ this.$t('pendingHeadline') }}</h4><br />
            <p>{{ this.$t('pendingMessage') }}</p>

            <ds-filled-button @click="closeDialog">
                {{ this.$t('global.gotit') }}
            </ds-filled-button>
        </ds-modal>

        <div v-if="showMask" class="app-mask" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import authMixin from '@/shared/mixins/auth.mixin';
import { IMPORT_TYPES } from '@/import/import.constants';
import ImportGoogleContactsList from '@/import/components/ImportGoogleContactsList';

const MODAL_TIMEOUT = 1000;
const MASK_TIMEOUT = 400;

export default {
    mixins: [authMixin],

    data() {
        return {
            showMask: false,
            routeToContactsListOnComplete: true,
        };
    },

    mounted() {
        this.$bus.$on('OPEN_CONTACT_IMPORT', this.tryOpenModal);
        this.checkForNewIntegration();
    },

    beforeDestroy() {
        this.$bus.$off('OPEN_CONTACT_IMPORT', this.tryOpenModal);
    },

    watch: {
        contactImportSuccess(newValue) {
            if (newValue) {
                this.handleContactImportComplete();
            }
        },

        contactImportError(newValue) {
            if (newValue) {
                this.handleContactImportComplete();
            }
        },

        provider() {
            this.checkForNewIntegration();
        },
    },

    computed: {
        ...mapState({
            contactImportSuccess: ({ contactImport }) => contactImport.states.imported,
            contactImportError: ({ contactImport }) => contactImport.states.failed,
            error: ({ contactImport }) => contactImport.error,
            importedTagId: ({ contactImport }) => contactImport.tagId,
            lastImportSource: ({ contactImport }) => contactImport.lastImportSource,
            provider: ({ route }) => route.query.provider,
        }),

        ...mapGetters({
            enabledImportTypes: 'contactImport/enabledImportTypes',
        }),

        tagFilterUrl() {
            return this.importedTagId && `TAG=INCLUDES_ANY|${this.importedTagId}`;
        },
    },

    methods: {
        tryOpenModal(options = { routeToContactsListOnComplete: true }) {
            this.routeToContactsListOnComplete = options.routeToContactsListOnComplete;

            return this.$store.dispatch('contactImport/CHECK_IMPORT_IN_PROGRESS')
                .then((inProgress) => {
                    inProgress
                        ? this.$refs.importInProgressDialog.toggleable_open()
                        : this.openModal();
                })
                .catch(() => {});
        },

        openModal() {
            this.$store.commit('contactImport/RESET_IMPORT_STATE');

            this.openContactsSelection();
        },

        openContactsSelection /* istanbul ignore next */ () {
            import(/* webpackChunkName: "importContacts" */ '@/import/components/ImportContactsSelection').then(({ default: ImportContactsSelection }) => {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: ImportContactsSelection,
                    showRootClose: true,
                    modalSize: 'fullscreen',
                });
            });
        },

        closeDialog() {
            this.$refs.importInProgressDialog.toggleable_close();
        },

        checkForNewIntegration() {
            if (!this.auth_isContactAuthSuccess) {
                return;
            }

            let component;

            this.showMask = true;

            switch (this.provider) {
            case IMPORT_TYPES.GOOGLE:
                this.$track('Contact import - connected: Google upload');
                component = ImportGoogleContactsList;
                this.auth_handleContactAuth(this.provider);
                break;

            default:
                this.$store.commit('contactImport/SET_STATE_ERROR', {});
                this.showMask = false;

                return;
            }

            setTimeout(() => {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component,
                    showRootClose: true,
                    modalSize: 'fullscreen',
                    interceptClose: this.auth_closeModal,
                });

                setTimeout(() => {
                    this.showMask = false;
                }, MASK_TIMEOUT);
            }, MODAL_TIMEOUT);
        },

        handleContactImportComplete() {
            if (this.contactImportSuccess) {
                this.$store.dispatch('contactImport/TRACK_RESULTS', true);
                this.$bus.$emit('POP_NESTED_MODAL', 2);

                intercom.logEvent(intercom.events.CONTACT_IMPORT_COMPLETED);

                amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORTED, {
                    'Event Source': this.lastImportSource,
                });

                this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'importContacts');

                if (this.routeToContactsListOnComplete) {
                    this.$router.push('/contacts/list/all?sort=createDate');
                }
            } else {
                this.$store.dispatch('contactImport/TRACK_RESULTS', false);
                this.$error({
                    message: this.$t('errors.importContacts'),
                    showIcon: true,
                });
            }

            this.$bus.$emit('CONTACT_LIST_UPDATED');
        },

        viewList() {
            this.$track('Contacts - Import - clicked : View imported contacts');
            this.$router.push(`/contacts/list/all?filter=${encodeURIComponent(this.tagFilterUrl)}`);
            this.$bus.$emit('CONTACT_LIST_UPDATED');
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "pendingHeadline": "Your import is in progress",
        "pendingMessage": "You'll be notified as soon as it's complete."
    }
}
</i18n>
