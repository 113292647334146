const BillingPage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/BillingPage');
const InvoiceDashboard = () => import(/* webpackChunkName: "billing" */ '@/money/pages/InvoiceDashboard');
const QuoteDashboard = () => import(/* webpackChunkName: "billing" */ '@/money/pages/QuoteDashboard');
const RecurringPaymentsDashboard = () => import(/* webpackChunkName: "billing" */ '@/money/pages/RecurringPaymentsDashboard');
const CheckoutFormsPage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/CheckoutFormsPage');
const InvoicePage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/InvoicePage');
const PayInvoicePage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/PayInvoicePage');
const PaymentAuthenticationReturnPage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/PaymentAuthenticationReturnPage');
const PublicCheckoutPage = () => import(/* webpackChunkName: "checkout" */ '@/money/pages/PublicCheckoutPage');
const SuccessPage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/SuccessPage');

const QuotePublicPage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/QuotePublicPage');
const AcceptedQuotePage = () => import(/* webpackChunkName: "billing" */ '@/money/pages/AcceptedQuotePage');

const ProductsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/ProductsPage');
const TaxesSettingsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/products/TaxesSettingsPage');
const PromoCodesSettingsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/products/PromoCodesSettingsPage');
const ProductsSettingsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/products/ProductsSettingsPage');
const PromoCodeDetailPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/products/PromoCodeDetailPage');

import { FF_KEAP_CHECKOUT_FORMS_API } from '@/shared/constants/featureFlag.constants';
import {
    RECURRING_PAYMENTS_FEATURE,
    CHECKOUT_FORMS_FEATURE,
    SMART_FORMS_EDITING_FEATURE,
    INVOICE_AND_QUOTES_FEATURE,
} from '@/shared/constants/features.constants';

export const moneyRoutes = [
    {
        path: '/invoice/:xid',
        name: 'invoice',
        component: InvoicePage,
        meta: {
            title: 'public.invoice.viewTitle',
            isPublic: true,
        },
    },
    {
        path: '/invoice/:xid/pay',
        name: 'invoice.pay',
        component: PayInvoicePage,
        meta: {
            title: 'public.invoice.payTitle',
            isPublic: true,
        },
    },
    {
        path: '/invoice/:xid/success',
        name: 'invoice.success',
        component: SuccessPage,
        meta: {
            title: 'public.invoice.successTitle',
            isPublic: true,
        },
    },
    {
        path: '/quote/:xid',
        name: 'quote',
        component: QuotePublicPage,
        meta: {
            title: 'public.quote.viewTitle',
            isPublic: true,
        },
    },
    {
        path: '/quote/:xid/accept',
        name: 'quote.accept',
        component: AcceptedQuotePage,
        meta: {
            title: 'public.quote.acceptTitle',
            isPublic: true,
        },
    },
    {
        path: '/checkout/:appId/:id',
        name: 'checkout',
        component: PublicCheckoutPage,
        meta: {
            title: 'public.checkout.title',
            isPublic: true,
        },
    },
    {
        path: '/payment/auth/return',
        name: 'payment.auth.return',
        component: PaymentAuthenticationReturnPage,
        meta: {
            isPublic: true,
        },
    },
    {
        path: '/sales',
        redirect: '/sales/invoices',
        name: 'billing',
        component: BillingPage,
        meta: {
            feature: INVOICE_AND_QUOTES_FEATURE,
        },
        children: [
            {
                path: 'invoices',
                name: 'invoices',
                component: InvoiceDashboard,
                meta: {
                    title: 'nav.invoices',
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
                children: [
                    {
                        path: ':invoiceId',
                        name: 'invoices.invoice',
                        component: InvoiceDashboard,
                        meta: {
                            title: 'nav.invoices',
                            hideHeaderOnMobile: true,
                        },
                    },
                    {
                        path: ':invoiceId/reminder',
                        name: 'invoices.invoice.reminder',
                        component: InvoiceDashboard,
                        meta: {
                            title: 'nav.invoices',
                            hideHeaderOnMobile: true,
                        },
                    },
                ],
            },
            {
                path: 'quotes',
                name: 'quotes',
                component: QuoteDashboard,
                meta: {
                    title: 'nav.quotes',
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
                children: [
                    {
                        path: ':quoteId',
                        name: 'quotes.quote',
                        component: QuoteDashboard,
                        meta: {
                            title: 'nav.quotes',
                            hideHeaderOnMobile: true,
                        },
                    },
                    {
                        path: ':quoteId/reminder',
                        name: 'quotes.quote.reminder',
                        component: QuoteDashboard,
                        meta: {
                            title: 'nav.quotes',
                            hideHeaderOnMobile: true,
                        },
                    },
                ],
            },
            {
                path: 'recurring-payments',
                redirect: 'recurring',
                name: 'recurring-payments',
            },
            {
                path: 'recurring',
                name: 'recurring',
                component: RecurringPaymentsDashboard,
                meta: {
                    title: 'nav.recurring',
                    feature: RECURRING_PAYMENTS_FEATURE,
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
            },
            {
                path: 'checkout',
                name: 'checkout.forms.page',
                component: CheckoutFormsPage,
                meta: {
                    title: 'settings.checkout.title',
                    feature: CHECKOUT_FORMS_FEATURE,
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
                children: [
                    {
                        path: 'edit/:formId?/:step?',
                        name: 'checkout.forms.edit',
                        component: CheckoutFormsPage,
                        props: true,
                        meta: {
                            title: 'settings.checkout.title',
                            featureFlag: FF_KEAP_CHECKOUT_FORMS_API,
                            feature: SMART_FORMS_EDITING_FEATURE,
                            hideHeaderOnMobile: true,
                        },
                    },
                ],
            },
        ],
    },
];

export const productSettingsRoutes = [
    {
        path: 'products',
        name: 'settings.products',
        component: ProductsPage,
        meta: {
            title: 'settings.products.title',
            hideHeaderOnMobile: true,
        },
        children: [
            {
                path: 'taxes',
                name: 'settings.products.taxes',
                component: TaxesSettingsPage,
                meta: {
                    title: 'nav.settings.taxes',
                },
            },
            {
                path: 'promo-codes',
                name: 'settings.products.promo.codes',
                component: PromoCodesSettingsPage,
                meta: {
                    title: 'nav.settings.promoCodes',
                },
            },
            {
                path: 'promo-codes/new',
                name: 'settings.products.promo.codes.new',
                component: PromoCodeDetailPage,
                meta: {
                    title: 'nav.settings.createPromoCode',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'promo-codes/:id',
                name: 'settings.products.promo.codes.edit',
                component: PromoCodeDetailPage,
                meta: {
                    title: 'nav.settings.editPromoCode',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: '',
                name: 'settings.products.products',
                component: ProductsSettingsPage,
                meta: {
                    title: 'nav.settings.products',
                },
            },
        ],
    },
];
