import { COLLECT_PAYMENT, TRACK_ACTIVITIES, PROMOTE_MARKET } from '@/onboarding/constants/job.constants';

export const SIMPLE_ONBOARDING_START_DATE = '2019-11-26';

export const ONBOARDING_SLIDES = {
    LEAD_CAPTURE: {
        WEBSITE: 'website',
        FORMS: 'forms',
        BUSINESS_CARDS: 'businessCards',
        REFERRALS: 'referrals',
        OTHER: 'other',
        NONE: 'none',
    },
    APPOINTMENTS: {
        HAS_TOOL: 'hasTool',
        NEEDS_TOOL: 'needsTool',
        NONE: 'none',
    },
    AUTOMATIONS: {
        QUICK_FOLLOW_UP: 'quickFollowUp',
        KEEP_LEADS_ENGAGED: 'keepLeadsEngaged',
        SCHEDULE_CONSULTATIONS: 'scheduleConsultations',
        REQUEST_REVIEWS: 'requestReviews',
        OTHER: 'other',
        NONE: 'none',
    },
};

export const ONBOARDING_SLIDE_NAMES = {
    BUSINESS_INFO_SLIDE: 'businessInfoSlide',
    CONTACTS_INFO_SLIDE: 'contactsInfoSlide',
    LEAD_CAPTURE_SLIDE: 'leadCaptureSlide',
    APPOINTMENTS_SLIDE: 'appointmentsSlide',
    AUTOMATION_SLIDE: 'automationSlide',
    MOBILE_APP_DOWNLOAD_SLIDE: 'mobileAppDownloadSlide',
};

export const SECONDARY_JOBS = [
    COLLECT_PAYMENT.id,
    TRACK_ACTIVITIES.id,
    PROMOTE_MARKET.id,
];
