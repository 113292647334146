<template>
    <ds-modal
        ref="blendrModal"
        class="modal"
        :title="title"
        show-close
        size="fullscreen"
        dialog
        @close="$emit('close')"
    >
        <iframe
            v-if="isOpen"
            data-qa="blendr-iframe"
            class="blendr-iframe"
            frameBorder="0"
            scrolling="yes"
            :title="$t('iframeTitle')"
            :src="url"
        />
    </ds-modal>
</template>

<script>
export default {
    props: {
        isOpen: Boolean,
        title: String,
        url: String,
    },

    watch: {
        isOpen(isOpen) {
            if (isOpen) {
                window.addEventListener('message', this.iframeEventListener, false);
                this.$refs.blendrModal.toggleable_open();
            } else {
                window.removeEventListener('message', this.iframeEventListener, false);
                this.$refs.blendrModal.toggleable_close();
            }
        },
    },

    methods: {
        iframeEventListener(event) {
            if (event.data.event === 'setup-complete') {
                this.$emit('setupComplete');
            }

            if (event.data.event === 'setup-failed') {
                this.$emit('setupFailed');
            }

            if (event.data.event === 'setup-canceled') {
                this.$emit('setupCanceled');
            }

            if (event.data.event === 'settings-complete') {
                this.$emit('settingsComplete');
            }

            if (event.data.event === 'settings-failed') {
                this.$emit('settingsFailed');
            }

            if (event.data.event === 'settings-canceled') {
                this.$emit('settingsCanceled');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .blendr-iframe {
        width: 100%;
        height: 90vh;
    }
</style>

<i18n>
{
    "en-us": {
        "iframeTitle": "Integration"
    }
}
</i18n>
