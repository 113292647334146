import VueRouter from 'vue-router';

import store from '@/shared/store';
import routes from '@/router/routes';
import { beforeRoute, onRoute } from '@/router/utils/router.util';
import interceptorUtils from '@/router/utils/interceptor.util';
import { handleError } from '@/shared/utils/error.util';

const router = new VueRouter({
    mode: 'history',
    scrollBehavior: (_, __, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }

        return { x: 0, y: 0 };
    },
    routes,
});

router.beforeEach(beforeRoute);
router.beforeResolve((to, from, next) => {
    return onRoute(to, from, next, store);
});
router.onError(handleError);

interceptorUtils.setupInterceptors(router);

export default router;
