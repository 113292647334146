<template lang="html">
    <ds-tooltip
        v-if="filterAutomationEnabled"
        class="automation-filter-dropdown"
        :disabled="canAddFilter"
        position="bottom"
    >
        <template #reference>
            <ds-dropdown
                class="dropdown"
                :is-open="isDropdownOpen"
                @close="closeDropdown"
            >
                <template #default>
                    <tooltip-with-delay>
                        <template #reference>
                            <ds-icon-button
                                name="filter"
                                :class="['filter-button', { 'disabled': !canAddFilter }]"
                                :disabled="!canAddFilter"
                                @click="openDropdown"
                            />
                        </template>

                        <template #default>
                            <span>{{ $t('filterAutomation') }}</span>
                        </template>
                    </tooltip-with-delay>
                </template>

                <template #menu>
                    <ds-multiselect-menu
                        v-if="isDropdownOpen"
                        v-model="selectedFilters"
                        categorized
                        multiple
                        :categories-label="$t('selectFilter')"
                        :options="automationFilterOptions"
                        @input="updateFilter"
                    />
                </template>
            </ds-dropdown>
        </template>

        <template #default>
            <small>{{ $t('maximum') }}</small>
        </template>
    </ds-tooltip>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { FF_KEAP_SINGLE_PIPELINE, FF_KEAP_SIMPLE_AUTOMATION_FILTER } from '@/shared/constants/featureFlag.constants';
import { FORM_TYPE_CONTACTS } from '@/customForms/customForm.constants';
import TooltipWithDelay from '@/shared/components/TooltipWithDelay';

export default {
    MAX_FILTERS: 5,

    components: {
        TooltipWithDelay,
    },

    data() {
        return {
            selectedFilters: [],
            initialized: false,
            isDropdownOpen: false,
        };
    },

    watch: {
        automationFilters: {
            handler(val) {
                this.selectedFilters = val;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            pipelineList: ({ pipeline }) => pipeline.pipelineList,
            publicForms: ({ customForms }) => customForms.forms[FORM_TYPE_CONTACTS],
            internalForms: ({ smartForms }) => smartForms.forms,
            automationFilters: ({ automations }) => automations.automationFilters,
            singlePipelineEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SINGLE_PIPELINE],
            filterAutomationEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_FILTER],
        }),

        ...mapGetters({
            automationFilterOptions: 'automations/automationFilterOptions',
            hasMultiplePipelinesFeature: 'auth/hasMultiplePipelinesFeature',
            hasFormsFeature: 'auth/hasFormsFeature',
        }),

        canAddFilter() {
            return this.selectedFilters.length < this.$options.MAX_FILTERS;
        },
    },

    methods: {
        updateFilter() {
            this.$store.commit('automations/SET_AUTOMATION_FILTERS', this.selectedFilters);

            if (!this.canAddFilter) {
                this.closeDropdown();
            }
        },

        async init() {
            if (this.filterAutomationEnabled) {
                const promises = [];

                if (!this.pipelineList?.length && (this.hasMultiplePipelinesFeature || this.singlePipelineEnabled)) {
                    promises.push(this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST'));
                }

                if (!this.publicForms.length) {
                    promises.push(this.$store.dispatch('customForms/LOAD_FORMS'));
                }

                if (!this.internalForms.length && this.hasFormsFeature) {
                    promises.push(this.$store.dispatch('smartForms/LOAD_SMART_FORMS'));
                }

                await Promise.all(promises);
            }

            this.selectedFilters = this.automationFilters;

            this.initialized = true;
        },

        openDropdown() {
            if (!this.initialized) {
                this.init();
            }

            if (this.canAddFilter) {
                this.isDropdownOpen = true;
            }
        },

        closeDropdown() {
            this.isDropdownOpen = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .filter-button.disabled {
        --icon-color: #{$color-ink-700};
    }
</style>

<i18n>
{
    "en-us": {
        "selectFilter": "Filter by trigger context",
        "maximum": "5 filter maximum",
        "filterAutomation": "Filter automation"
    }
}
</i18n>
