<template lang="html">
    <div :class="['recipient-list', { empty }]" @click.stop="tryOpen">
        <ds-chip
            v-for="recipient in selected"
            :key="recipient.id"
            remove
            with-interaction
            @click.native="handleRemove(recipient)"
        >
            {{ recipient.fullName }}
        </ds-chip>

        <ds-dropdown :is-open="isMenuOpen" class="recipient-options" @close="isMenuOpen = false">
            <template #menu>
                <ds-multiselect-menu
                    bind-value-only
                    label-prop="fullName"
                    value-prop="email"
                    :options="options"
                    @input="handleOptionSelect"
                />
            </template>
        </ds-dropdown>
    </div>
</template>

<script>
export default {
    name: 'RecipientList',

    props: {
        options: {
            type: Array,
            default: () => [],
        },
        selected: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return { isMenuOpen: false };
    },

    computed: {
        empty() {
            return this.options.length === 0;
        },
    },

    methods: {
        handleOptionSelect(recipient) {
            this.isMenuOpen = false;
            this.$emit('add', recipient);
        },

        handleRemove(recipient) {
            this.$emit('remove', recipient);
        },

        tryOpen() {
            if (this.empty) {
                return;
            }

            this.isMenuOpen = true;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .recipient-list {
        display: flex;
        flex-grow: 1;
        position: relative;
        flex-wrap: wrap;
        cursor: pointer;
        min-height: px-to-rem(40px);
        padding: $gp / 2 0 0 $gp / 2;

        &.empty {
            cursor: auto;
        }

        .chip {
            margin: 0 $gp / 2 $gp / 2 0;
        }
    }

    .recipient-options {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
    }
</style>
