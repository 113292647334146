import { KEAP_MOVE_EHAWK } from '@/shared/constants/featureFlag.constants';

export default {
    requiresEhawkCheck: (state, _, rootState, rootGetters) => {
        const isPaid = rootGetters['auth/isPaid'];
        const ehawkEnabled = rootState.featureFlags[KEAP_MOVE_EHAWK];
        const ehawkPassed = state.passed;

        return !isPaid && !ehawkPassed && ehawkEnabled;
    },
};
