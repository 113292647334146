import Vue from 'vue';
import camelCase from 'lodash.camelcase';

import { IMPORT_TYPES, IMPORT_STAGES } from '@/import/import.constants';

const CUSTOM_FIELDS = 'Custom fields';

export default {
    contactImportResultCount({ results }) {
        return results.length;
    },

    importPending({ states: { importing, imported, failed } }) {
        return !(!importing && !imported && !failed);
    },

    isUploadStage({ activeStage }) {
        return activeStage === IMPORT_STAGES.UPLOAD;
    },

    isOrganizeStage({ activeStage }) {
        return activeStage === IMPORT_STAGES.ORGANIZE;
    },

    isFinalStage({ activeStage }) {
        return activeStage === IMPORT_STAGES.FINAL;
    },

    enabledImportTypes() {
        return {
            [IMPORT_TYPES.CSV]: true,
            [IMPORT_TYPES.GOOGLE]: true,
            [IMPORT_TYPES.QBO]: true,
            [IMPORT_TYPES.PHONE]: true,
            [IMPORT_TYPES.BIZCARD]: true,
            [IMPORT_TYPES.CONSTANT_CONTACT]: true,
            [IMPORT_TYPES.MAILCHIMP]: true,
            [IMPORT_TYPES.HUBSPOT]: true,
        };
    },

    stagesAsArray({ stages }) {
        const stagesArr = [];

        Object.keys(stages).forEach((stageId) => {
            const { ready, valid } = stages[stageId];

            stagesArr.push({
                id: stageId,
                ready,
                valid,
            });
        });

        return stagesArr;
    },

    mappingOptions({ mapToOptions, customFields }) {
        const options = [];
        const knownGroups = [
            'actions',
            'additionalInfo',
            'addresses',
            'billingAddress',
            'optionalAddress',
            'shippingAddress',
            'customFields',
            'dates',
            'general',
            'links',
            'phoneEmail',
            'phoneAndEmail',
            'other',
            'social',
            'tag',
        ];

        Object.keys(mapToOptions).forEach((group) => {
            const optionGroup = {
                label: knownGroups.includes(camelCase(group))
                    ? `importContacts.stages.organize.fieldGroups.${camelCase(group)}`
                    : group,
                options: mapToOptions[group].map((optionValue) => {
                    const customField = customFields.find(({ label }) => label === optionValue);

                    const value = customField && customField.label && customField.id
                        ? `customField.${customField.label.replace(/\s/g, '')}.${customField.id}`
                        : optionValue;

                    const label = customField
                        ? optionValue
                        : `forms.${camelCase(optionValue)}`;

                    return { value, label };
                }).filter((option) => Boolean(option)),
            };

            if (camelCase(group) === 'actions') {
                optionGroup.options.push({
                    value: 'NO_MATCH',
                    label: 'forms.selectAnOption',
                });
            }

            if (group === CUSTOM_FIELDS) {
                customFields.forEach((customField) => {
                    if (!optionGroup.options.find(({ label }) => label === customField.label)) {
                        optionGroup.options.push({
                            value: `customField.${customField.label.replace(/\s/g, '')}.${customField.id}`,
                            label: customField.label,
                        });
                    }
                });
            }

            options.push(optionGroup);
        });

        options.sort((a, b) => b.label === 'importContacts.stages.organize.fieldGroups.actions');

        const translatedOptions = options.map(({ label, options: localOptions }) => {
            return {
                label: Vue.prototype.$i18nInstance.t(label),
                options: localOptions.map(({ value, label: optionLabel }) => {
                    return { value, label: Vue.prototype.$i18nInstance.t(optionLabel) };
                }),
            };
        });

        return translatedOptions;
    },
};
