<template>
    <transition name="fadeInOut" mode="out-in" appear>
        <div v-if="helpTipVisible" class="message" @click="toggleHelpTip">
            <ds-icon name="info-circle" />

            <slot />
        </div>

        <ds-text-button
            v-else
            leading-icon="info-circle"
            class="help-link"
            @click="toggleHelpTip"
        >
            {{ $t('calendarHelp.howDoesThisWork') }}
        </ds-text-button>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            helpTipVisible: false,
        };
    },

    methods: {
        toggleHelpTip() {
            this.helpTipVisible = !this.helpTipVisible;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .help-link {
        margin: $gp * 3.5 auto $gp * 2;
        --icon-color: #{$color-gray-800};
        --icon-size: 1.5rem;
        color: $color-gray-800;
        align-items: center;
        display: flex;
        opacity: .5;

        &:hover {
            color: $color-gray-800;
            opacity: 1.0;
        }
    }

    .message {
        background: $color-gray-050;
        display: flex;
        max-width: px-to-rem(470px);
        margin: $gp * 3 auto $gp * 1.5;
        padding: $gp;
        cursor: pointer;

        .icon {
            --icon-size: #{px-to-rem(30px)};
            @include margin-end($gp * .75);
        }
    }

    @media ($small) {
        .message {
            margin-bottom: 0;
        }
    }

    // transition: scale and fade in and out
    .fadeInOut-enter-active, .fadeInOut-leave-active {
        opacity: 1.0;
        transition: all .25s ease-out;
        transform: scale3d(1.0, 1.0, 1.0);
    }

    .fadeInOut-enter, .fadeInOut-leave-to {
        opacity: 0;
        transition: all .15s ease-in;
        transform: scale3d(.7, .7, 1.0);
    }

</style>

<i18n>
    {
        "en-us": {
            "calendarHelp": {
                "howDoesThisWork": "How does this work?"
            }
        }
    }
</i18n>
