import axios from 'axios';
import amplitude from '@/analytics/amplitude';

export const CUSTOM_CONTACT_FIELD_COUNT_USER_PROPERTIES = {
    count: 'Contact Custom Field Count',
    alwaysShownCount: 'Contact Custom Fields Always Shown Count',
};

export const CUSTOM_COMPANY_FIELD_COUNT_USER_PROPERTIES = {
    count: 'Company Custom Field',
    alwaysShownCount: 'Company Custom Fields Always Shown Count',
};

const CUSTOM_FIELD_COUNT_USER_PROPERTIES = {
    contact: CUSTOM_CONTACT_FIELD_COUNT_USER_PROPERTIES,
    company: CUSTOM_COMPANY_FIELD_COUNT_USER_PROPERTIES,
};

export const REST_TO_SPA_CUSTOM_FIELD_TYPE_MAP = {
    ListBox: 'MultiSelect',
    Dropdown: 'Select',
    WholeNumber: 'Number',
    DecimalNumber: 'NumberDecimal',
    PhoneNumber: 'Phone',
};

/**
 * To be removed upon completion of FS-38817, FS-38818, FS-38819, FS-38738
 *
 * Maps certain fieldTypes from legacy to new name
 *
 * @param {*} customField
 */
export const transformCustomField = (customField) => {
    const newType = REST_TO_SPA_CUSTOM_FIELD_TYPE_MAP[customField.fieldType];

    if (newType) {
        return { ...customField, fieldType: newType };
    }

    return customField;
};

// Gets all custom fields for the given record type.  Uses the new customfield endpoint
//
// @param recordType [String] The record type of fields.  Most commonly this is
//      either 'contact' or 'company'.  This field should be all lower case.
// @return [Promise => Array<Object>] A promise which resolves with an array of custom fields.
//   Each field has the following keys:
//      - id [String] The ID of the custom field
//      - label [String] The label of the field (corresponds to the older 'name' field.)
//      - fieldType [String] What type of field this is (e.g. dropdown, date, etc)
//      - options [Array] The options for the field (if applicable).  Only available for dropdowns/checkboxes/etc.
//      - defaultToFirstOption [Boolean] Whether or not the first option is selected by default.
export const getCustomFields = (recordType) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/customfield?recordType=${recordType}`)
        .then(({ data: { customFields } }) => {
            amplitude.v2.setUserProperties({
                [CUSTOM_FIELD_COUNT_USER_PROPERTIES[recordType].count]: customFields.length,
                [CUSTOM_FIELD_COUNT_USER_PROPERTIES[recordType].alwaysShownCount]: customFields.filter(({ alwaysShown }) => alwaysShown).length,
            });

            return customFields.map(transformCustomField);
        });
};

// Gets a customField
//
// @param fieldId [String]
// @return [Promise => Object] A promise that resolves with the custom field object if successful.
export const getCustomField = ({
    fieldId,
}) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/customfield/${fieldId}`)
        .then(({ data: { customField } }) => transformCustomField(customField));
};

// Creates a new custom field.
//
// @param recordType [String] One of 'contact' or 'company'
// @param customField [Object] The details for the custom field to create.
// @return [Promise => Object] A promise that resolves with the custom field object if successful.
export const createCustomField = ({
    recordType,
    customField: {
        name,
        values,
        type: fieldType,
        defaultToFirstOption,
        alwaysShown = false,
    },
}) => {
    const newField = {
        recordType,
        name,
        fieldType,
        alwaysShown: Number(alwaysShown),
    };

    if (values?.length) {
        newField.options = values;
        newField.defaultToFirstOption = defaultToFirstOption;
    }

    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/customfield`, newField)
        .then(({ data: { customField } }) => transformCustomField(customField));
};

// Updates the given custom field
//
// @param fieldId [String]
// @param customField [Object] The details of the custom field to create.
// @return [Promise => Object] A promise that resolves with the custom field object if successful.
export const updateCustomField = ({
    fieldId,
    customField: {
        name,
        defaultToFirstOption = false,
        values: options,
        alwaysShown = false,
    } = {},
}) => {
    const updatedCustomField = {
        name,
        defaultToFirstOption,
        options,
        alwaysShown: Number(alwaysShown),
    };

    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/customfield/${fieldId}`, updatedCustomField)
        .then(({ data: { customField } }) => transformCustomField(customField));
};

// Deletes the given custom field.
//
// @return [Promise] A promise which resolves if deleting was successful.
export const deleteCustomField = ({ fieldId }) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/customfield/${fieldId}`);
};
