import { PUBLIC_FORM_THANK_YOU_ROUTE } from '@/customForms/customForm.constants';

const ContactUsFormPage = () => import(/* webpackChunkName: "leadForms" */ '@/leadForms/pages/ContactUsFormPage');

const routes = [
    {
        path: '/contact-us/:slug',
        name: 'contactus',
        component: ContactUsFormPage,
        meta: {
            title: 'contactUs.title',
            isPublic: true,
            track: true,
        },
        children: [
            {
                path: 'thank-you',
                name: PUBLIC_FORM_THANK_YOU_ROUTE,
                component: ContactUsFormPage,
                meta: {
                    title: 'contactUs.title',
                    isPublic: true,
                    track: true,
                },
            },
        ],
    },
];

export default routes;
