import axios from 'axios';

export default {
    LOAD_NOTIFICATIONS(context, payload) {
        return loadNotifications(context, payload);
    },

    SET_NOTIFICATION_STATUS(context, payload) {
        return setNotificationStatus(context, payload);
    },

    SET_BULK_NOTIFICATION_STATUS(context, payload) {
        return setBulkNotificationStatus(context, payload);
    },

    SET_ALL_AS_READ(context) {
        return setAllAsRead(context);
    },

    START_POLL_NOTIFICATIONS(context, payload) /* istanbul ignore next */ {
        return startPollNotifications(context, payload);
    },

    STOP_POLL_NOTIFICATIONS(context, payload) /* istanbul ignore next */ {
        return stopPollNotifications(context, payload);
    },
};

const NOTIFICATION_POLL_DELAY = 60000;
let notificationsIntervalId = null;

const loadNotifications = ({ commit, rootState }, { pageToken } = {}) => {
    const accountId = rootState.auth.account.appName;
    const userId = rootState.auth.user.casId;
    const source = 'keap';
    const notificationType = 'alert';
    const orderBy = 'createTime%3ADESC';

    const pageTokenParameter = pageToken && pageToken !== 'allLoaded'
        ? `&pageToken=${pageToken}`
        : '';

    const url = `${process.env.VUE_APP_NOTIFICATIONS_API_URL}/notifications?userId=${userId}&accountId=${accountId}&source=${source}&type=${notificationType}&orderBy=${orderBy}&includeTotalSize=true${pageTokenParameter}`;

    return axios.get(url)
        .then(({ data }) => {
            commit('SET_NOTIFICATIONS_NEXT_PAGE_TOKEN', data);
            commit('SET_NOTIFICATIONS', data);
        });
};

const setNotificationStatus = ({ commit }, { id, status }) => {
    const url = `${process.env.VUE_APP_NOTIFICATIONS_API_URL}/notifications/${id}`;

    const payload = {
        mask: 'status',
        notificationId: id,
        status,
    };

    return axios.patch(url, payload)
        .then(() => {
            commit('SET_NOTIFICATION_STATUS', payload);
        });
};

const setBulkNotificationStatus = (_, { notifications, oldStatus, newStatus }) => {
    const url = `${process.env.VUE_APP_NOTIFICATIONS_API_URL}/notifications:bulkUpdateStatusById`;

    const payload = {
        oldStatus,
        newStatus,
        notificationIds: notifications.map(({ id }) => id),
    };

    return axios.post(url, payload);
};

const setAllAsRead = ({ commit, rootState }) => {
    const url = `${process.env.VUE_APP_NOTIFICATIONS_API_URL}/notifications:bulkUpdateStatusToRead`;

    const request = {
        accountId: rootState.auth.account.appName,
        userId: rootState.auth.user.casId,
    };

    return axios.post(url, request)
        .then(() => {
            commit('SET_ALL_AS_READ');
        });
};

/* istanbul ignore next */
const startPollNotifications = ({ dispatch, rootState }, payload) => {
    dispatch('STOP_POLL_NOTIFICATIONS');

    const { isPublic } = rootState.route.meta;

    if (isPublic || !rootState.auth.account.appName || !rootState.auth.user.casId) {
        return;
    }

    notificationsIntervalId = setInterval(() => {
        dispatch('LOAD_NOTIFICATIONS', payload)
            .catch(() => dispatch('STOP_POLL_NOTIFICATIONS'));
    }, NOTIFICATION_POLL_DELAY);
};

/* istanbul ignore next */
const stopPollNotifications = () => {
    if (notificationsIntervalId) {
        clearInterval(notificationsIntervalId);
        notificationsIntervalId = null;
    }
};
