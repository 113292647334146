import { frequencyStatus, billingCycles } from '@/money/constants/order-status';

export default {
    data() {
        return {
            recurringBilling_options: [
                { label: this.$t('contactRecord.sales.payments.recurring.options.daily'), value: frequencyStatus.DAILY },
                { label: this.$t('contactRecord.sales.payments.recurring.options.weekly'), value: frequencyStatus.WEEKLY },
                { label: this.$t('contactRecord.sales.payments.recurring.options.monthly'), value: frequencyStatus.MONTHLY },
                { label: this.$t('contactRecord.sales.payments.recurring.options.yearly'), value: frequencyStatus.YEARLY },
            ],
            recurringBillingCycle_options: [
                { label: this.$t('contactRecord.sales.payments.recurring.options.yearly'), value: billingCycles.YEARLY },
                { label: this.$t('contactRecord.sales.payments.recurring.options.monthly'), value: billingCycles.MONTHLY },
                { label: this.$t('contactRecord.sales.payments.recurring.options.weekly'), value: billingCycles.WEEKLY },
                { label: this.$t('contactRecord.sales.payments.recurring.options.daily'), value: billingCycles.DAILY },
            ],
        };
    },

    methods: {
        recurringBilling_getPricingCycleDisplay(price, recurringBilling, cycle) {
            let ending = '';

            if (recurringBilling) {
                switch (cycle) {
                case billingCycles.DAILY:
                    ending = this.$t('contactRecord.sales.payments.recurring.priceCycleEnding.daily');
                    break;
                case billingCycles.WEEKLY:
                    ending = this.$t('contactRecord.sales.payments.recurring.priceCycleEnding.weekly');
                    break;
                case billingCycles.MONTHLY:
                    ending = this.$t('contactRecord.sales.payments.recurring.priceCycleEnding.monthly');
                    break;
                case billingCycles.YEARLY:
                    ending = this.$t('contactRecord.sales.payments.recurring.priceCycleEnding.yearly');
                    break;
                default:
                    ending = '';
                }
            }

            return `${this.$n(price, 'currency')}${ending}`;
        },

        recurringBilling_getDurationDescription(numberOfPayments, cycle) {
            if (numberOfPayments === 0) {
                return '';
            }

            let duration = `${numberOfPayments} `;

            switch (cycle) {
            case billingCycles.DAILY:
                duration += this.$tc('contactRecord.sales.payments.recurring.durations.days', numberOfPayments);
                break;
            case billingCycles.WEEKLY:
                duration += this.$tc('contactRecord.sales.payments.recurring.durations.weeks', numberOfPayments);
                break;
            case billingCycles.MONTHLY:
                duration += this.$tc('contactRecord.sales.payments.recurring.durations.months', numberOfPayments);
                break;
            case billingCycles.YEARLY:
                duration += this.$tc('contactRecord.sales.payments.recurring.durations.years', numberOfPayments);
                break;
            default:
                duration = '';
            }

            return this.$t('contactRecord.sales.payments.recurring.productDurationDescription', { duration });
        },
    },
};
