<template>
    <div :id="id" class="successful-modal">
        <ds-modal
            ref="modal"
            size="sm"
            :show-close="showClose"
            @close="handleClose"
        >
            <div class="successful-modal-container">
                <h2 v-if="title" class="successful-title">
                    {{ title }}
                </h2>

                <slot name="header" />

                <div class="successful-message">
                    <div class="successful-subtitle">
                        {{ subtitle }}
                    </div>
                    <div class="successful-button-container" data-qa="ok-button">
                        <ds-filled-button :id="buttonId" :class="buttonClass" @click="close">
                            {{ buttonLabel }}
                        </ds-filled-button>

                        <slot name="footer" class="footer" />
                    </div>
                </div>
            </div>
        </ds-modal>
    </div>
</template>

<script>

export default {
    props: {
        id: String,
        showClose: Boolean,
        title: String,
        subtitle: String,
        buttonLabel: String,
        buttonId: String,
        buttonClass: {
            type: String,
            default: '',
        },
    },

    methods: {
        open() {
            this.$refs.modal.toggleable_open();
        },

        close() {
            this.$refs.modal.toggleable_close();
        },

        handleClose() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .successful-modal-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: $gp;
    }

    .successful-title {
        margin-bottom: $gp * 2;
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .successful-message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: $gp * 2;
        padding: $gp;
        border: 1px solid transparent;
        background-color: $color-gray-050;
        border-radius: $border-radius;

        @media($extra-small) {
            position: absolute;
            bottom: 0;
            margin: $gp * 2 auto;
        }
    }

    .successful-subtitle {
        margin: 0 0 $gp 0;
        display: flex;
        justify-content: center;
    }

    .successful-button-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: $gp;
        padding: 0 $gp;
    }

    .footer {
        padding: 0 $gp;
        margin-top: $gp;
    }
</style>
