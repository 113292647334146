export const PREFERENCE_TYPES = {
    ADD_CONTACT_FORM_ID: 'addContactForm',
    ADVANCED_AUTOMATION: 'advancedAutomationList',
    COMPANY_SORT_KEY: 'companySortKey',
    PIPELINE_FILTERS: 'pipelineFilters',
    ADD_CONTACTS_ONBOARDING_JOB_ID: 'addContactsOnboardingJobId',
    ONBOARDING_HAS_CONTACTS: 'onboardingHasContacts',
    ADD_CONTACTS_HAS_EXISTING_CONTACTS: 'addContactsHasExistingContacts',
    ONBOARDING_LEAD_CAPTURE_OPTIONS: 'onboardingLeadCaptureOptions',
    ONBOARDING_APPOINTMENTS_OPTIONS: 'onboardingAppointmentsOptions',
    ONBOARDING_AUTOMATION_OPTIONS: 'onboardingAutomationOptions',
    SELECTED_ONBOARDING_JOB: 'selectedOnboardingJob',
    SHOW_EMAIL_OPENS_DATA: 'showEmailOpensData',
};
