<template lang="html">
    <div class="attachment-error">
        <div class="error-content">
            <ds-icon name="alert-triangle-fill" class="error-icon" />
            <span class="error-text">{{ errorText }}</span>
        </div>

        <div class="dismiss-error" @click="removeError">
            <ds-icon name="x" class="close-icon" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        errorText: {
            type: String,
            default: '',
        },
    },

    methods: {
        removeError() {
            this.$bus.$emit('REMOVE_ERROR');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .error-content {
        display: flex;
        align-items: center;
        padding: $gp;
    }

    .error-text {
        @include margin-start($gp);
    }

    .dismiss-error {
        display: flex;
        @include margin-start(auto);
        @include margin-end($gp);
    }
</style>
