<template>
    <div>
        <div class="content">
            <h2 class="headline">
                {{ $t('share.title', { name: user.givenName }) }}

                <br />

                {{ $t('share.subtitle') }}
            </h2>

            <div class="thumbs-image">
                <svg>
                    <use xlink:href="#thumbs" />
                </svg>
            </div>

            <ds-filled-button
                v-track="'Appointments - Appointment creation success modal - clicked : Preview live page button'"
                as="a"
                block
                class="see-page-button"
                target="_blank"
                rel="noopener noreferrer"
                :href="pageLink"
            >
                {{ $t('share.seePageButtonLabel') }}
            </ds-filled-button>

            <div class="page-link" v-text="displayLink" />

            <div v-if="apptType" class="link-actions button-row">
                <booking-actions v-slot="{ sendInvite }">
                    <ds-text-button
                        v-track="'Appointments - Appointment creation success modal - clicked : Email link link'"
                        leading-icon="send"
                        @click="sendInvite(apptType)"
                    >
                        {{ $t('share.emailLinkLabel') }}
                    </ds-text-button>
                </booking-actions>

                <booking-actions v-slot="{ copyLink }">
                    <ds-text-button
                        v-track="'Appointments - Appointment creation success modal - clicked : Copy link link'"
                        leading-icon="copy"
                        @click="copyLink(apptType)"
                    >
                        {{ $t('global.copyLink') }}
                    </ds-text-button>
                </booking-actions>

                <ds-text-button leading-icon="code" @click="openEmbedModal">
                    {{ $t('share.embed.buttonLabel') }}
                </ds-text-button>
            </div>
        </div>

        <help-box v-track="'Appointments - Success modal - clicked : How does this work link'">
            <div>{{ $t('share.infoMessage') }}</div>
        </help-box>

        <portal to="root">
            <embed-modal
                data-qa="embed-public-form"
                :is-open="isEmbedModalOpen"
                :embed-content="embedContent"
                :title="$t('share.embed.modalTitle')"
                :heading="$t('share.embed.heading')"
                help-url="https://help.keap.com/help/embed-your-booking-calendar-on-your-website"
                source="Appointments"
                @close="closeEmbedModal"
            />
        </portal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import EmbedModal from '@/shared/components/Embed/EmbedModal';
import HelpBox from '@/appointments/components/HelpBox';
import BookingActions from '@/appointments/components/BookingActions';

import { regurgitateEmbedCode } from '@/appointments/utils/embed.util';
import '@/appointments/images/thumbs.svg';

export default {
    components: {
        EmbedModal,
        HelpBox,
        BookingActions,
    },

    data() {
        return {
            isEmbedModalOpen: false,
            helpTipVisible: false,
        };
    },

    mounted() {
        this.$emit('update:ready', true);
    },

    computed: {
        ...mapState({
            apptType: ({ calendar }) => calendar.activeApptType,
            user: ({ auth }) => auth.user || {},
        }),

        bookingToken() {
            return this.apptType && this.apptType.bookingLink || 'username/meeting';
        },

        pageLink() {
            return process.env.VUE_APP_BOOKING_BASE_URL + this.bookingToken;
        },

        displayLink() {
            return this.pageLink.replace('https://', '');
        },

        embedContent() {
            return regurgitateEmbedCode(this.pageLink);
        },
    },

    methods: {
        closeEmbedModal() {
            this.isEmbedModalOpen = false;
        },

        openEmbedModal() {
            this.isEmbedModalOpen = true;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.content {
    text-align: center;
}

.headline {
    margin-bottom: $gp * 2;
}

.see-page-button {
    margin: $gp * 1.5 auto $gp * 2.5;
    max-width: px-to-rem(260px);
}

.page-link {
    font-size: $font-size-small;
}

.link-actions {
    margin-top: $gp / 2;
    justify-content: center;
}
</style>

<i18n>
{
    "en-us": {
        "share": {
            "title": "High five, {name}!",
            "subtitle": "Your link is ready to share.",
            "description": "Now it's time to set up your client booking preferences. This way you can determine when clients can and can't book your time.",
            "seePageButtonLabel": "Preview live page",
            "infoMessage": "Anyone with your booking page link can schedule an appointment with you. Check your inbox for more details.",
            "emailLinkLabel": "Email link",
            "embed": {
                "buttonLabel": "Embed on your website",
                "modalTitle": "Embed booking form code",
                "heading": "Use an iFrame to place this booking form on your website or landing page."
            }
        }
    }
}
</i18n>
