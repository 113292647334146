export const templates = {
    SALES: 'sales',
    PROJECT: 'project',
    CUSTOM: 'custom',
};

export const operators = {
    INCLUDES_ANY: 'INCLUDES_ANY',
    EXCLUDES_ANY: 'EXCLUDES_ANY',
    BETWEEN: 'BETWEEN',
};

export const filterOptions = {
    STATUS: {
        value: 'STATUS',
        type: 'MULTISELECT',
        operators: [
            { value: operators.INCLUDES_ANY, label: 'forms.modifiers.includesany' },
            { value: operators.EXCLUDES_ANY, label: 'forms.modifiers.excludesany' },
        ],
    },
    OWNERS: {
        value: 'OWNERS',
        type: 'MULTISELECT',
        operators: [
            { value: operators.INCLUDES_ANY, label: 'forms.modifiers.includesany' },
            { value: operators.EXCLUDES_ANY, label: 'forms.modifiers.excludesany' },
        ],
    },
    CREATED: {
        value: 'CREATED',
        type: 'DATE_RANGE',
        operators: [
            { value: operators.BETWEEN, label: 'forms.modifiers.range' },
        ],
    },
    CLOSE_DATE: {
        value: 'CLOSE_DATE',
        type: 'DATE_RANGE',
        operators: [
            { value: operators.BETWEEN, label: 'forms.modifiers.range' },
        ],
    },
};
