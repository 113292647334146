<template>
    <div class="configure">
        <configure-details
            :item-id="step.id"
            :title="title"
            :subtitle="subtitle"
            :has-error="hasError"
            :hide-edit="hideEdit"
            :error-message="errorMessage || $t('error')"
            @edit="$emit('edit', { step, delayStep })"
            @remove="$emit('remove')"
        />

        <div v-if="delayStep" class="timer-section">
            <div>
                <automation-display-name
                    :value="delayStep"
                    class="configure-name"
                    data-qa="configure-name"
                />

                <p v-if="hasTimerWarning" class="timer-warning">
                    {{ $t('timerWarning') }}
                </p>
            </div>

            <ds-text-button
                class="edit-timer-button"
                leading-icon="clock"
                @click="$emit('edit', { step, delayStep, initialStep: TIMING_STEP, stepListIndex })"
            >
                {{ $t('editTimer') }}
            </ds-text-button>
        </div>

        <div :class="['button-row', { 'multiple-buttons': manageTitle }]">
            <manage-configuration
                v-if="manageTitle"
                :title="manageTitle"
                :manage-props="manageProps"
                @manage="$emit('manage')"
            />

            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import ManageConfiguration from './ManageConfiguration';
import ConfigureDetails from './ConfigureDetails';
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import { TIMING_STEP } from '@/automations/constants/automations.constants';

export default {
    components: {
        AutomationDisplayName,
        ConfigureDetails,
        ManageConfiguration,
    },

    props: {
        value: Object,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        title: String,
        subtitle: String,
        errorMessage: String,
        hideEdit: Boolean,
        manageProps: Object,
        manageTitle: String,
    },

    data() {
        return {
            step: this.value?.step ?? this.value,
            delayStep: this.value?.delayStep ?? null,
            stepListIndex: this.value?.stepListIndex ?? null,
            TIMING_STEP,
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.step = newVal.step ?? newVal;
                this.delayStep = newVal.delayStep ?? null;
                this.stepListIndex = newVal.stepListIndex ?? null;
            },
            deep: true,
        },
    },

    computed: {
        stepList() {
            const stepList = [this.step];

            if (this.delayStep) {
                stepList.unshift(this.delayStep);
            }

            return stepList;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';

    .timer-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: $gp;
        background-color: $color-gray-050;
    }

    .edit-timer-button {
        white-space: nowrap;
        @include margin-start($gp / 2);
    }

    .timer-warning {
        margin: 0;
        color: $color-orange-900;
    }
</style>

<i18n>
{
    "en-us": {
        "error": "Please fill out the required fields",
        "editTimer": "Edit timer",
        "timerWarning": "Your timer is in the past"
    }
}
</i18n>
