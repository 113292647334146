export default {
    SET_COPIED_BROADCAST_ID(state, { broadcastId }) {
        state.copiedBroadcastId = broadcastId;
    },

    SET_COPYING_BROADCAST(state, { copyingBroadcast }) {
        state.copyingBroadcast = copyingBroadcast;
    },

    SET_LAST_BROADCAST_SOURCE(state, { source }) {
        state.lastBroadcastSource = source;
    },

    SET_NEWEST_BROADCAST_ID(state, { id }) {
        state.newestBroadcastId = id;
    },
};
