<template>
    <div class="book-appointment-modal-content">
        <div v-if="showSpinner" class="loading-spinner">
            <ds-spinner />
        </div>

        <select-appointment
            v-else
            show-confirm
            :selected-block.sync="selectedDate"
            :provider-timezone="providerTimezone"
            data-qa="select-appointment"
        />

        <ds-modal ref="errorDialog" dialog size="sm">
            <h4>{{ $t('forbiddenError.title', { providerName }) }}</h4>

            <p>{{ $t('forbiddenError.description', { providerName }) }}</p>

            <ul>
                <li>{{ $t('forbiddenError.line1', { providerName }) }}</li>
                <li>{{ $t('forbiddenError.line2', { providerName }) }}</li>
                <li>{{ $t('forbiddenError.line3', { providerName }) }}</li>
            </ul>

            <p>{{ $t('forbiddenError.footer', { providerName }) }}</p>

            <div class="button-row">
                <ds-filled-button
                    v-if="actionLink"
                    trailing-icon="external-link"
                    as="a"
                    :href="actionLink"
                    target="_blank"
                >
                    {{ $t(`forbiddenError.action.${providerName.toLowerCase()}`) }}
                </ds-filled-button>

                <ds-text-button @click="toggleErrorDialog">
                    {{ $t('global.dismiss') }}
                </ds-text-button>
            </div>
        </ds-modal>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import { APPOINTMENTS_APPOINTMENT_CREATED_EVENT } from '@/shared/constants/events.constants';
import BookAppointmentSuccess from '@/appointments/components/BookAppointmentSuccess';
import SelectAppointment from '@/appointments/components/SelectAppointment';
import bookingAvailabilityMixin from '@/appointments/mixins/booking-availability.mixin';

export default {
    components: {
        SelectAppointment,
    },

    mixins: [bookingAvailabilityMixin],

    props: {
        appointmentType: {
            type: Object,
            default: () => ({}),
        },

        contact: {
            type: Object,
            default: () => ({}),
        },
        eventSource: String,
    },

    data() {
        return {
            selectedDate: {},
            bookingAppointment: false,
            loaded: false,
        };
    },

    watch: {
        selectedDate: {
            handler(val) {
                if (val && val.start) {
                    this.checkAvailability();
                }
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            availability: ({ booking }) => booking.apptData.availability,
            isPublic: ({ route }) => route.meta.isPublic,
        }),

        ...mapGetters({
            isContactPage: 'contacts/isContactPage',
            connectedProviders: 'calendar/connectedProviders',
        }),

        providerName() {
            return this.connectedProviders && this.connectedProviders.length > 0
                ? this.connectedProviders[0].provider
                : this.$t('calendarProvider');
        },

        actionLink() {
            if (this.providerName === 'Microsoft') {
                return 'https://outlook.com';
            }

            if (this.providerName === 'Google') {
                return 'https://calendar.google.com';
            }

            return null;
        },

        appointmentTitle() {
            const { title } = this.appointmentType;

            const appointmentName = title ? title.slice(0, title.lastIndexOf('with') - 1) : '';

            return appointmentName ? appointmentName.charAt(0).toUpperCase() + appointmentName.slice(1) : '';
        },

        displayName() {
            const { contact } = this;
            const { firstName, lastName, email } = contact;

            if (firstName && lastName) {
                return `${firstName} ${lastName}`;
            }

            if (firstName) {
                return firstName;
            }

            if (lastName) {
                return lastName;
            }

            if (email) {
                return email;
            }

            return this.$t('global.contact');
        },

        nestedModal_subTitle() {
            const { contact } = this;

            return contact && Object.keys(contact).length > 0
                ? this.$t('subtitleWithContact', { contactName: this.displayName })
                : this.$t('subtitle');
        },

        nestedModal_title() {
            return this.appointmentTitle;
        },

        showSpinner() {
            return !this.loaded || this.bookingAppointment;
        },

        providerTimezone() {
            const [provider] = this.connectedProviders;

            return provider?.timeZone;
        },
    },

    methods: {
        bookAppointment() {
            const payload = this.getAppointmentPayload();
            const appointmentSummary = this.$t('appointments.summary', { name: this.displayName, title: this.appointmentTitle });

            return this.$store.dispatch('booking/BOOK_APPOINTMENT', payload)
                .then(() => {
                    this.$bus.$emit('PUSH_NESTED_MODAL', {
                        component: BookAppointmentSuccess,
                        modalSize: 'sm',
                        props: {
                            appointmentSummary,
                            contact: this.contact,
                        },
                    });

                    this.$track('Appointments - Appointment booking page - success : appointment booked');
                    this.$bus.$emit(APPOINTMENTS_APPOINTMENT_CREATED_EVENT);

                    this.$store.dispatch('calendar/RELOAD_CALENDAR_APPTS');
                    this.$store.dispatch('dashboard/LOAD_TODOS');
                    this.bookingAppointment = false;
                }).catch((error) => {
                    this.handleError(error);
                    this.bookingAppointment = false;
                });
        },

        checkAvailability() {
            this.bookingAppointment = true;

            const message = this.$t('appointments.notAvailable');

            return this.$store.dispatch('booking/REFRESH_AVAILABILITY')
                .then(() => {
                    if (!this.bookingAvailability_isAvailable(this.selectedDate, this.availability)) {
                        this.$error({ message, bottom: true });

                        this.bookingAppointment = false;
                    } else {
                        this.bookAppointment();
                    }
                });
        },

        handleError(error) {
            const { isPublic, appointmentType: { title } } = this;

            const isForbidden = error && error.toString().includes('403');

            if (isForbidden) {
                if (isPublic) {
                    const contactName = title.split('with')[1];

                    return this.$error({
                        message: this.$t('publicError', { contactName }),
                        bottom: true,
                        showIcon: true,
                        closeLabel: this.$t('closeLabel'),
                    });
                }

                return this.toggleErrorDialog();
            }

            return this.$error({ message: this.$t('error') });
        },

        toggleErrorDialog() {
            this.$refs.errorDialog.toggleable_toggle();
        },

        getAppointmentPayload() {
            const {
                contact,
                selectedDate: { start, end },
                appointmentType: {
                    bookingLink,
                    description,
                    calendarId,
                    location,
                },
            } = this;

            const summary = this.$t('appointments.summary', { name: this.displayName, title: this.appointmentTitle });
            const timeZone = moment.tz.guess();

            return {
                contact,
                bookingLink,
                calendarId,
                location: location || this.$t('clientSelectLocation'),
                description,
                summary,
                start: { dateTime: start, timeZone },
                end: { dateTime: end, timeZone },
                eventSource: this.eventSource,
            };
        },

        nestedModal_open() {
            this.loaded = false;

            this.load();
        },

        nestedModal_reset() {
            this.loaded = false;
        },

        load() {
            const { bookingLink } = this.appointmentType;

            this.$store.commit('booking/SET_APPT_DATA', {
                ...this.appointmentType,
                bookingStr: bookingLink,
            });

            const availabilityLoadError = this.$t('availabilityLoadError');

            return this.$store.dispatch('booking/LOAD_APPOINTMENT_TYPE_AVAILABILITY', {
                bookingLink,
            })
                .then(() => {
                    this.loaded = true;
                }).catch(() => {
                    this.$error({ message: availabilityLoadError });
                    this.loaded = true;
                });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    @import '~@/appointments/styles/appointments';

    .book-appointment-modal-content {
        margin: $gp * -1.5;
    }

    .loading-spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $appointment-picker-height;
    }

    h4 {
        margin-bottom: $gp;
    }

    ul {
        list-style: disc outside;
        margin: 0 $gp $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "subtitle": "Pick a date and time to meet",
        "subtitleWithContact": "Pick a date and time to meet with {contactName}",
        "error": "There was a problem booking your appointment. Please try again.",
        "publicError": "Booking unsuccessful<br /> Please contact {contactName} directly for more information.",
        "clientSelectLocation": "TBD",
        "availabilityLoadError": "Something went wrong while loading availability, please try again.",
        "closeLabel": "Ok, got it",
        "calendarProvider": "calendar provider",
        "forbiddenError": {
            "title": "There's a problem with your {providerName} account",
            "description": "You can’t add or cancel appointments until you address an {providerName} issue. The problem could be:",
            "line1": "You recently created a new {providerName} account and need to verify it",
            "line2": "{providerName} has flagged your account for suspicious activity",
            "line3": "There may be a payment issue with your {providerName} account",
            "footer": "Once you log in to your {providerName} account and fix the issue, you’ll be able to add and cancel appointments in your Keap calendar.",
            "action": {
                "microsoft": "Log in to outlook.com",
                "google": "Log in to calendar.google.com"
            }
        }
    }
}
</i18n>
