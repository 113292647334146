export default {
    SET_SHOW_BADGE(state, showBadge) {
        state.showBadge = showBadge;
    },

    SET_ACTIVE_LEAD_FORM_TASK(state, taskId) {
        state.activeLeadFormTask = taskId;
    },

    SET_ACTIVE_APPOINTMENT_TYPE_TASK(state, taskId) {
        state.activeAppointmentTypeTask = taskId;
    },
};
