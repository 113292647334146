<template lang="html">
    <div :class="['email-content', { 'hide-signature': hideSignature }]">
        <quill-editor
            ref="quillEditor"
            v-model="content"
            data-qa="email-content-editor"
            :mergeable="!hideMerge"
            :placeholder="placeholder"
            :hide-borders="hideBorders"
            container-class="fs-block"
            @input="handleValueChange"
        />

        <div v-if="showButton" class="action-button">
            <ds-tooltip position="top">
                <template #reference>
                    <ds-filled-button :class="['action-button-component']" type="button">
                        {{ buttonText }}
                    </ds-filled-button>
                </template>

                <template #default>
                    <div class="action-button-tooltip">
                        <span v-for="(tooltip, i) in buttonTooltip" :key="i">{{ tooltip }}</span>
                    </div>
                </template>
            </ds-tooltip>
        </div>

        <footer v-if="!hideSignature" :class="['email-content-footer', { 'hide-borders': hideBorders }]">
            <signature-toggle
                data-qa="email-content-signature-toggle"
                :user-id="signatureUserId"
                :signature-enabled="signatureEnabled"
                @input="handleValueChange"
            />
        </footer>
    </div>
</template>

<script>
import QuillEditor from '@/shared/components/Text/QuillEditor';
import SignatureToggle from '@/shared/components/Email/SignatureToggle';

export default {
    name: 'EmailContent',

    components: {
        QuillEditor,
        SignatureToggle,
    },

    props: {
        value: String,
        placeholder: String,
        signatureUserId: [Number, String],
        signatureEnabled: Boolean,
        hideBorders: Boolean,
        hideMerge: Boolean,
        hideSignature: Boolean,
        showButton: Boolean,
        buttonText: {
            type: String,
            default: '',
        },
        buttonTooltip: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            content: this.value,
        };
    },

    methods: {
        clear() {
            this.$refs.quillEditor.clearContent();
        },

        handleValueChange() {
            this.$track('EmailContent - email - clicked : Signature Block');
            const editorContent = this.$refs.quillEditor.hasContent ? this.$refs.quillEditor.getContent() : '';

            this.$emit('input', editorContent);
        },

        insertValueAtCursor(value, shouldEmbedHtml = false) {
            this.$refs.quillEditor.insertValueAtCursor(value, shouldEmbedHtml);
        },

        setContent(content) {
            this.$refs.quillEditor.setContent(content);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss"  scoped>
    .email-content:not(.hide-signature) {
        --quill-editor-bottom-border-color: transparent;
        --quill-editor-border-radius: 0;
        --quill-editor-mobile-border-radius: 0;
    }

    .hide-signature {
        --quill-editor-mobile-border-radius: 0 0 #{$border-radius} #{$border-radius};
    }

    .email-content-footer:not(.hide-borders) {
        border: 1px solid $color-gray-200;
        border-top: none;
        border-radius: 0 0 $border-radius $border-radius;
    }

    .action-button {
        padding: $gp;
        display: flex;
        justify-content: center;

        &-component {
            cursor: inherit;
        }

        &-tooltip {
            display: flex;
            flex-direction: column;
        }
    }
</style>
