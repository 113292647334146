<template>
    <ds-text-button
        trailing-icon="external-link"
        data-qa="manage"
        @click="manage"
    >
        {{ title }}
    </ds-text-button>
</template>

<script>
export default {
    props: {
        title: String,
        manageProps: {
            type: Object,
            default: () => ({
                confirmTitle: '',
                confirmMessage: '',
                confirmButtonLabel: '',
                route: {},
            }),
        },
    },

    methods: {
        manage() {
            if (this.manageProps?.route?.name) {
                this.confirmManage();
            } else {
                this.$emit('manage');
            }
        },

        confirmManage() {
            const {
                confirmTitle,
                confirmMessage,
                confirmButtonLabel,
                route,
            } = this.manageProps;

            return this.$confirm({
                optionTitle: confirmTitle,
                optionMessage: confirmMessage,
                optionConfirmButtonLabel: confirmButtonLabel,
                size: 'sm',
            })
                .then(() => {
                    this.$router.push(route);
                }).catch(() => {});
        },
    },
};
</script>
