import axios from 'axios';
import store from '@/shared/store';

if (!window.performance.clearMarks) {
    window.performance.clearMarks = () => {};
}

if (!window.performance.clearMeasures) {
    window.performance.clearMeasures = () => {};
}

if (!window.performance.getEntriesByName) {
    window.performance.getEntriesByName = () => {};
}

if (!window.performance.getEntriesByType) {
    window.performance.getEntriesByType = () => {};
}

if (!window.performance.mark) {
    window.performance.mark = () => {};
}

if (!window.performance.measure) {
    window.performance.measure = () => {};
}

const sendHoneycombEvent = (event) => {
    axios.post(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/event`, event);
};

let hasInitialLoad = false;

const pagePerformance = (pageId, routeId = null) => {
    const uniqueRouteId = routeId || pageId.toLowerCase();

    if (!hasInitialLoad && window.performance.getEntriesByName('transition_start').length > 0) {
        // If we failed to capture a full page load performance event and the user has moved on to another page
        hasInitialLoad = true;
    }

    if (hasInitialLoad && window.performance.getEntriesByName(`transition_${uniqueRouteId}_start`).length === 0) {
        return;
    }

    const jsHeapUsed = window.performance.memory && window.performance.memory.usedJSHeapSize;
    const jsHeapTotal = window.performance.memory && window.performance.memory.totalJSHeapSize;

    const nt = window.performance.timing;

    const event = {
        type: pageId,
        url_path: window.location.pathname,
        user_agent: window.navigator.userAgent,
        window_height: window.innerHeight,
        window_width: window.innerWidth,
        screen_height: window.screen && window.screen.height,
        screen_width: window.screen && window.screen.width,
        connection_type: navigator.connection && navigator.connection.type,
        connection_type_effective: navigator.connection && navigator.connection.effectiveType,
        connection_rtt: navigator.connection && navigator.connection.rtt,
    };

    const ids = window.location.pathname.match(/\d+/g);

    if (ids) {
        let urlSimplePath = window.location.pathname;

        ids.forEach((id) => {
            urlSimplePath = urlSimplePath.replace(id, '{id}');
        });

        event.url_simple_path = urlSimplePath;
    }

    event.redirect_count = window.performance.navigation && window.performance.navigation.redirectCount;

    if (window.performance.memory) {
        event.js_heap_size_total_b = jsHeapTotal;
        event.js_heap_size_used_b = jsHeapUsed;
    }

    if (hasInitialLoad) {
        window.performance.mark(`transition_${uniqueRouteId}_end`);
        window.performance.measure(
            'transition_visually_complete',
            `transition_${uniqueRouteId}_start`,
            `transition_${uniqueRouteId}_end`,
        );

        event.timing_page_transition_visually_complete_ms = window.performance.getEntriesByName('transition_visually_complete')[0].duration;
        event.timing_page_visually_complete_ms = event.timing_page_transition_visually_complete_ms;

        if (window.newrelic && typeof event.timing_page_transition_visually_complete_ms === 'number') {
            const secondsTiming = parseFloat((event.timing_page_transition_visually_complete_ms / 1000).toFixed(3));

            window.newrelic.interaction()
                .setAttribute('timing_page_transition_visually_complete', secondsTiming)
                .setAttribute('tenant_id', store.state.auth.session.coreAppId)
                .setAttribute('url', `${window.location.pathname}${window.location.search}`)
                .save();
        }

        window.performance.clearMarks(`transition_${uniqueRouteId}_start`);
        window.performance.clearMarks(`transition_${uniqueRouteId}_end`);
        window.performance.clearMeasures('transition_visually_complete');
    } else {
        event.timing_response_end_ms = nt.responseEnd - nt.navigationStart;
        event.timing_dom_interactive_ms = nt.domInteractive - nt.navigationStart;
        event.timing_dom_complete_ms = nt.domComplete - nt.navigationStart;
        event.timing_dom_loaded_ms = nt.loadEventEnd - nt.navigationStart;
        event.timing_dns_duration_ms = nt.domainLookupEnd - nt.domainLookupStart;
        event.timing_server_duration_ms = nt.responseEnd - nt.requestStart;
        event.timing_server_request_delay_ms = nt.requestStart - nt.navigationStart;
        event.timing_page_load_visually_complete_ms = window.performance.now();
        event.timing_page_visually_complete_ms = event.timing_page_load_visually_complete_ms;

        hasInitialLoad = true;
    }

    if (store.state.auth.account.companyProfile && store.state.auth.account.companyProfile.country) {
        event.country = store.state.auth.account.companyProfile.country;
    }

    if (store.state.auth.account.blockInfoRole) {
        event.blockInfoRole = store.state.auth.account.blockInfoRole;
    }

    if (store.state.auth.session.coreAppId) {
        event.tenantId = store.state.auth.session.coreAppId;
    }

    window.performance.getEntriesByType('measure').forEach((measure) => {
        event[measure.name] = measure.duration;
    });

    if (process.env.NODE_ENV === 'production') {
        sendHoneycombEvent(event);
    }

    window.performance.clearMarks();
    window.performance.clearMeasures();
};

export default {
    sendHoneycombEvent,
    pagePerformance,
};
