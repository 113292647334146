<template lang="html">
    <ds-dropdown
        class="global-add-button"
        icons
    >
        <template #default>
            <div class="dropdown-button">
                <ds-outline-button trailing-icon="chevron-down" data-qa="global-add-button">
                    {{ $t('global.new') }}
                </ds-outline-button>
                <ds-icon-button name="add-circle" />
            </div>

            <portal to="root">
                <task-form
                    event-source="Global Action Button"
                    :is-open="isTaskModalOpen"
                    @close="isTaskModalOpen = false"
                    @cancel="isTaskModalOpen = false"
                />
            </portal>
        </template>

        <template #menu>
            <div>
                <ul class="dropdown-menu-list">
                    <li
                        v-for="(item, i) in dropdownOptions"
                        :key="i"
                        class="dropdown-menu-item"
                        :data-qa="`${item.method}-option`"
                        @click="handleSelect(item)"
                    >
                        <ds-icon :name="item.icon" />
                        <span>{{ item.label }}</span>
                    </li>
                </ul>
            </div>
        </template>
    </ds-dropdown>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';

import SingleEmailModal from '@/contacts/components/email/SingleEmailModal';
import NewNoteForm from '@/shared/components/Note/NewNoteForm';
import TaskForm from '@/tasks/components/TaskForm';
import NewAppointmentFromCalendar from '@/appointments/components/NewAppointmentFromCalendar';

import { PREFERENCE_TYPES } from '@/shared/constants/preferences.constants';
import { CONTACT_RECORD } from '../../contacts/contactEmailTypes';

export default {
    components: {
        TaskForm,
    },

    mixins: [closeConfirmation],

    data() {
        return {
            isTaskModalOpen: false,
        };
    },

    created() {
        if (this.routeName !== 'calendar') {
            this.loadApptTypes();
        }
    },

    computed: {
        ...mapState({
            routeName: ({ route }) => route.name,
            apptTypesLoaded: ({ calendar }) => calendar.apptTypesLoaded,
            keapPhoneAccount: ({ auth }) => auth.account.keapPhoneAccountInfo,
        }),

        ...mapGetters({
            getPreference: 'preferences/getPreference',
            hasApptType: 'calendar/hasApptType',
        }),

        keapPhoneEnabled() {
            return this.keapPhoneAccount != null && Boolean(this.keapPhoneAccount.phoneNumber);
        },

        dropdownOptions() {
            const dropdownOptions = [
                { method: 'newContact', label: this.$t('global.contact'), icon: 'user' },
            ];

            if (this.keapPhoneEnabled) {
                dropdownOptions.push({ method: 'newMessage', label: this.$t('global.message'), icon: 'message' });
            }

            if (this.hasApptType) {
                dropdownOptions.push({ method: 'newAppointment', label: this.$t('global.appointment'), icon: 'calendar' });
            }

            dropdownOptions.push(
                { method: 'newTask', label: this.$t('global.task'), icon: 'tasks' },
                { method: 'newEmail', label: this.$t('global.email'), icon: 'email' },
                { method: 'newNote', label: this.$t('global.note'), icon: 'note' },
            );

            return dropdownOptions;
        },
    },

    methods: {
        async loadApptTypes() {
            try {
                await this.$store.dispatch('settings/LOAD_INTEGRATIONS');

                if (!this.apptTypesLoaded) {
                    this.$store.dispatch('calendar/LOAD_APPT_TYPES');
                }
            } catch (message) {
                this.$error({ message });
            }
        },

        handleSelect({ method }) {
            if (this[method] && typeof this[method] === 'function') {
                this.$track(`Nav - global add button - clicked: ${method}`);
                this[method]();
            }
        },

        newContact() {
            this.$bus.$emit('OPEN_ADD_CONTACT_MODAL', { id: this.getPreference(PREFERENCE_TYPES.ADD_CONTACT_FORM_ID) });
        },

        newMessage() {
            this.$router.push({ name: 'communication-sms-new' });
        },

        newTask() {
            this.isTaskModalOpen = true;
        },

        newEmail() {
            this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);
            this.$store.commit('email/RESET_CURRENT_EMAIL');

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SingleEmailModal,
                showRootClose: true,
                props: {
                    source: CONTACT_RECORD,
                },
                interceptClose: () => this.confirmCloseDraft(() => this.$bus.$emit('POP_NESTED_MODAL')),
            });
        },

        newNote() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: NewNoteForm,
                showRootClose: true,
            });
        },

        newAppointment() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: NewAppointmentFromCalendar,
                showRootClose: true,
                modalSize: 'xl',
                props: { eventSource: 'Global Action Button' },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    .global-add-button {
        --dropdown-height: #{px-to-rem(600px)};
    }

    .dropdown-button {
        > *:first-child {
            display: none;
        }

        @media ($medium) {
            > *:last-child {
                display: none;
            }

            > *:first-child {
                display: inline-flex;
            }
        }
    }
</style>
