import moment from 'moment';
import createRenderer from '@infusionsoft/bard-email-renderer';

import {
    createBroadcastMutation,
    updateBroadcastMutation,
    updateBroadcastScheduleMutation,
    broadcastQuery,
    broadcastScheduleQuery,
    uploadEmailImageMutation,
    searchEmailRecipientsQuery,
    addRecipientMutation,
    getRecipientCountQuery,
    removeRecipientMutation,
    sendTestMutation,
    sendBroadcastNowMutation,
    sendBroadcastScheduleMutation,
    sendBroadcastAtOptimalTimeMutation,
} from '@/broadcasts/api';
import { CUS_8954_SEND_BROADCAST_IN_UTC } from '@/shared/constants/featureFlag.constants';
import { getBardJson } from '@/shared/utils/bard.util';


export default {
    CREATE_BROADCAST() {
        return createBroadcast();
    },

    COPY_BROADCAST(_, payload) {
        return copyBroadcast(payload);
    },

    UPDATE_BROADCAST(_, payload) {
        return updateBroadcast(payload);
    },

    UPDATE_BROADCAST_SCHEDULE(context, payload) {
        return updateBroadcastSchedule(context, payload);
    },

    LOAD_BROADCAST(_, payload) {
        return loadBroadcast(payload);
    },

    LOAD_BROADCAST_SCHEDULE(context, payload) {
        return loadBroadcastSchedule(context, payload);
    },

    UPLOAD_EMAIL_IMAGE(_, payload) {
        return uploadEmailImage(payload);
    },

    SEARCH_RECIPIENTS(_, payload) {
        return searchRecipients(payload);
    },

    ADD_RECIPIENT(_, payload) {
        return addRecipient(payload);
    },

    GET_RECIPIENT_COUNT(_, payload) {
        return getRecipientCount(payload);
    },

    REMOVE_RECIPIENT(_, payload) {
        return removeRecipient(payload);
    },

    SEND_TEST(_, payload) {
        return sendTest(payload);
    },

    SEND_BROADCAST(context, payload) {
        return sendBroadcast(context, payload);
    },
};

const createBroadcast = () => {
    return createBroadcastMutation();
};

const copyBroadcast = async (broadcastId) => {
    const {
        emailTemplate: {
            bardJson,
            from,
            htmlBody,
            id,
            previewText,
            subject,
        },
        splitTest,
    } = await loadBroadcast({ broadcastId });

    return createBroadcastMutation({
        bardJson,
        from,
        htmlBody,
        id,
        previewText,
        splitTest,
        subject,
    });
};

const updateBroadcast = ({
    emailRows,
    from,
    id,
    previewText,
    subject,
}) => {
    if (Array.isArray(emailRows) && emailRows.length === 0) {
        return null;
    }
    const bardJson = getBardJson(emailRows);
    const renderer = createRenderer(bardJson);

    return updateBroadcastMutation({
        bardJson: JSON.stringify(bardJson),
        from: from ? from.label : '',
        htmlBody: renderer.renderHtml(),
        id,
        previewText,
        subject,
    });
};

const updateBroadcastSchedule = ({ rootState }, { broadcastId, scheduleData }) => {
    const appId = rootState.auth.session.coreAppId;

    return updateBroadcastScheduleMutation({ appId, broadcastId, scheduleData });
};

const loadBroadcast = ({ broadcastId }) => {
    return broadcastQuery(broadcastId);
};

const loadBroadcastSchedule = ({ rootState }, { broadcastId }) => {
    const appId = rootState.auth.session.coreAppId;

    return broadcastScheduleQuery({ appId, broadcastId });
};

const uploadEmailImage = async ({ file }) => {
    const reader = new FileReader();
    const promise = new Promise((resolve, reject) => {
        reader.onloadend = (e) => {
            if (e.target.error || !e.target.result) {
                reject(e);
            } else {
                resolve(e.target.result);
            }
        };

        reader.onerror = (e) => {
            reject(e);
        };
    });

    reader.readAsDataURL(file);

    const sanitizedFileName = file.name.replace(/-/g, '');

    const result = await promise;
    const base64String = result.split(',').pop();

    return uploadEmailImageMutation({ fileName: sanitizedFileName, base64FileData: base64String });
};

const searchRecipients = ({ query }) => {
    return searchEmailRecipientsQuery({ query });
};

const addRecipient = ({ broadcastId, recipient: { id, name, type } }) => {
    const recipient = {
        id,
        label: name,
        category: type,
    };

    return addRecipientMutation({ broadcastId, recipient });
};

const getRecipientCount = ({ category, id }) => {
    return getRecipientCountQuery({ category, id });
};

const removeRecipient = ({ broadcastId, recipient: { id, name, type } }) => {
    const recipient = {
        id,
        label: name,
        category: type,
    };

    return removeRecipientMutation({ broadcastId, recipient });
};

const sendTest = ({
    broadcast: {
        emailRows,
        from,
        previewText,
    },
    email,
    subject,
}) => {
    const bardJson = getBardJson(emailRows);
    const renderer = createRenderer(bardJson);
    const payload = {
        fromAddress: from.label,
        html: renderer.renderHtml(),
        previewText,
        subject,
        toAddress: email,
    };

    return sendTestMutation({ payload });
};

const sendBroadcast = ({ commit, rootState: { featureFlags } }, { id, schedule: { timing, timingDetails } }) => {
    let date;
    let time;
    const payload = {};
    let response;
    let localDate;

    switch (timing) {
    case 'now':
        response = sendBroadcastNowMutation({ broadcastId: id });
        break;
    case 'schedule':
        ({ time } = timingDetails);

        if (featureFlags[CUS_8954_SEND_BROADCAST_IN_UTC]) {
            // The date, month, and year pieces are necessary to ensure the email will be scheduled on the desired date, and
            // not one day earlier or later if the users current timezone offset crosses them over to the previous or following day.
            localDate = moment(timingDetails.date).utc().local();
            date = moment(timingDetails.date).utc().date(localDate.date()).month(localDate.month()).year(localDate.year);
        } else {
            date = moment(timingDetails.date);
        }

        payload.dateTime = date.hour(time.substring(0, 2)).minute(time.substring(2, 4)).toISOString();
        payload.timeZoneId = timingDetails.timeZone.value;

        response = sendBroadcastScheduleMutation({ broadcastId: id, payload });
        break;
    case 'best':
        response = sendBroadcastAtOptimalTimeMutation({ broadcastId: id });
        break;
    default:
        break;
    }

    commit('broadcastWizard/SET_SEND_NOW_BROADCAST_ID', id, { root: true });

    return response;
};
