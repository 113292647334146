<template lang="html">
    <ds-card @click.prevent>
        <ds-card-body class="provider-card">
            <div :class="{ 'has-description': description }">
                <img v-if="image" :src="image" :alt="title" />

                {{ description }}
            </div>

            <small>{{ title }}</small>
        </ds-card-body>
    </ds-card>
</template>

<script>
export default {
    props: {
        image: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        description: {
            type: String,
            default: null,
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
$providerIconSize: #{px-to-rem(40px)};

.ds-card {
    width: $form-width;
    margin-bottom: $gp;
    max-width: 100%;
    cursor: pointer;
}

.provider-card {
    display: grid;
    color: $color-gray-800;
    text-decoration: none;
    justify-content: space-between;
    align-items: center;
    grid-template-columns: auto auto;
    width: 100%;
    min-height: px-to-rem(90px);
}

.has-description {
    display: grid;
    grid-template-columns: $providerIconSize 1fr;
    align-items: center;
    grid-gap: $gp / 2;
}

img {
    max-height: $providerIconSize;
    min-width: $providerIconSize;
}

small {
    @include text-align-end;
    max-width: px-to-rem(100px);

    @media ($medium) {
        @include text-align-start;
        max-width: 100%;
    }
}
</style>
