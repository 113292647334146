import { render, staticRenderFns } from "./TemplateCardMenu.vue?vue&type=template&id=580a502c&scoped=true&lang=html&"
var script = {}
import style0 from "./TemplateCardMenu.vue?vue&type=style&index=0&id=580a502c&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580a502c",
  null
  
)

/* custom blocks */
import block0 from "./TemplateCardMenu.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports