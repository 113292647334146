<template>
    <controlled-modal
        data-qa="sms-broadcast-feedback-modal"
        :is-open="isOpen"
        show-close
        size="med"
        @close="handleClose"
    >
        <template #title>
            <strong class="feedback-modal-title">{{ $t('title') }}</strong>
        </template>

        <template #default>
            <form novalidate @submit.stop.prevent="submit">
                <p class="description1">
                    {{ description }}
                </p>

                <p class="description2">
                    {{ $t('description.2') }}
                </p>

                <ds-text-area-field
                    v-model="feedback"
                    :invalid="showError && !feedback"
                    :label="$t('label')"
                    :submitted="showError"
                    required
                >
                    <template v-if="showError && !feedback" #help>
                        <span class="feedback-help-text">{{ $t('errorMessage') }}</span>
                    </template>
                </ds-text-area-field>

                <ds-filled-button type="submit">
                    {{ $t('submit') }}
                </ds-filled-button>
            </form>
        </template>
    </controlled-modal>
</template>

<script>
import { mapState } from 'vuex';

import ControlledModal from '@/shared/components/ControlledModal';

import { SLACK_CHANNELS } from '@/shared/constants/slack.constants';
import { SMS_FEEDBACK_MODAL_SOURCE } from '@/shared/sms.constants';

const { COMMUNICATIONS_FEEDBACK } = SLACK_CHANNELS;

export default {
    components: { ControlledModal },
    props: {
        isOpen: Boolean,
        source: String,
    },

    data() {
        return {
            feedback: '',
            showError: false,
        };
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            appName: ({ auth }) => auth.account.appName,
            editionName: ({ auth }) => auth.account.editionName,
        }),

        description() {
            if (this.source === SMS_FEEDBACK_MODAL_SOURCE.KMN_TEMPLATES) {
                return this.$t('templatesDescripton');
            }

            return this.$t('description.1');
        },
    },

    methods: {
        handleClose() {
            this.showError = false;
            this.$emit('close');
        },

        submit() {
            if (!this.feedback) {
                this.showError = true;

                return;
            }

            const pretext = [
                `App ID: *${this.appName}*`,
                'Location: *Broadcasts*',
                `Edition: *${this.editionName}*`,
            ].join('\n');

            this.$slack(COMMUNICATIONS_FEEDBACK, {
                pretext,
                text: this.feedback,
                user: this.user,
            });

            this.$toast({ message: this.$t('feedbackSent') });
            this.feedback = '';
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
    .feedback-modal-title {
        font-size: $font-size-lg;
    }

    .description1 {
        font-size: $font-size-lg;
        font-weight: 300;
        line-height: $line-height-lg;
    }

    .description2 {
        color: $color-gray-700;
    }

    .feedback-help-text {
        color: $color-red;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Share your feedback",
        "description": {
            "1": "How can we improve your experience sending text messages from your Keap Marketing Number?",
            "2": "Please share any details that will help us improve this for you."
        },
        "templatesDescripton": "How can we improve Keap Marketing Number text message templates for you?",
        "label": "Add your comments",
        "submit": "Send feedback",
        "feedbackSent": "Feedback sent. Thanks!",
        "errorMessage": "Please enter a comment in the text field above to proceed."
    }
}
</i18n>
