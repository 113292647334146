import { COMPANIES_FEATURE } from '@/shared/constants/features.constants';
import {
    CUSTOM_FIELD_RECORD_TYPE_COMPANY,
    CUSTOM_FIELD_RECORD_TYPE_CONTACT,
} from '@/customFields/customFields.constants';

const CustomFieldsSettingsPage = () => import(/* webpackChunkName: "customFields" */ './pages/CustomFieldsSettingsPage');
const CustomFieldDetailsPage = () => import(/* webpackChunkName: "customFields" */ './pages/CustomFieldDetailsPage');

export default [
    {
        path: 'custom-fields',
        redirect: '/settings/custom-fields/contact',
    },
    {
        path: 'custom-fields/contact',
        name: 'settings.custom-fields',
        props: { recordType: CUSTOM_FIELD_RECORD_TYPE_CONTACT },
        component: CustomFieldsSettingsPage,
        meta: {
            title: 'settings.custom-fields.title',
            hideHeaderOnMobile: true,
        },
        children: [
            {
                path: ':id',
                props: (route) => ({ id: route.params.id, recordType: CUSTOM_FIELD_RECORD_TYPE_CONTACT }),
                name: 'settings.custom-fields.contact.details',
                component: CustomFieldDetailsPage,
                meta: {
                    title: 'settings.custom-fields.title',
                    hideHeaderOnMobile: true,
                },
            },
        ],
    },
    {
        path: 'custom-fields/company',
        name: 'settings.custom-fields.company',
        props: { recordType: CUSTOM_FIELD_RECORD_TYPE_COMPANY },
        component: CustomFieldsSettingsPage,
        meta: {
            title: 'settings.custom-fields.title',
            hideHeaderOnMobile: true,
            feature: COMPANIES_FEATURE,
        },
        children: [
            {
                path: ':id',
                props: (route) => ({ id: route.params.id, recordType: CUSTOM_FIELD_RECORD_TYPE_COMPANY }),
                name: 'settings.custom-fields.company.details',
                component: CustomFieldDetailsPage,
                meta: {
                    title: 'settings.custom-fields.title',
                    hideHeaderOnMobile: true,
                },
            },
        ],
    },
];
