/* istanbul ignore next */
export const loadScript = () => {
    const SCRIPT_URL = '//editor.unlayer.com/embed.js';
    const SCRIPT_ID = 'unlayer-js-api';

    return new Promise((resolve, reject) => {
        if (document.getElementById(SCRIPT_ID) == null) {
            const script = document.createElement('script');

            script.setAttribute('src', SCRIPT_URL);
            script.setAttribute('id', SCRIPT_ID);

            script.onload = resolve;
            script.onerror = reject;

            document.head.appendChild(script);
        } else {
            resolve();
        }
    });
};

const toMergeTags = (mergeFields = []) => {
    const mergeTags = {};

    mergeFields.forEach(({ category, options }) => {
        mergeTags[category.label] = {
            name: category.label,
            mergeTags: {},
        };

        options.forEach(({ label, value }) => {
            const mergeCategory = mergeTags[category.label].mergeTags;

            mergeCategory[label] = {
                name: label,
                value,
            };
        });
    });

    return mergeTags;
};

// TODO: Refactor bookingLink getter into a helper function rather than a vuex
// getter.
const toSpecialLinks = ({ appointmentLinks }, appointmentTypes = []) => {
    const specialLinks = [];

    if (appointmentTypes.length > 0) {
        const appointmentSpecialLinks = {
            name: appointmentLinks,
            specialLinks: [
                ...appointmentTypes.map(({ title, bookingLink }) => ({
                    name: title,
                    href: `${process.env.VUE_APP_BOOKING_BASE_URL}${bookingLink}`,
                    target: '_blank',
                })),
            ],
        };

        specialLinks.push(appointmentSpecialLinks);
    }

    return specialLinks;
};

export const createBaseOptions = ({
    i18n = {},
    mergeFields = [],
    appointmentTypes = [],
    controlPanelRightAlign,
}) => ({
    appearance: {
        loader: {
            html: '<div class="spinner"></div>',
            css: `
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    border-radius: 50%;
    display: inline-block;
    animation: spin 1s infinite linear;
    width: 40px;
    height: 40px;
    border: solid 3px;
    border-color: #0042db;
    border-left-color: transparent;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
}`,
        },
        panels: {
            tools: {
                dock: controlPanelRightAlign ? 'right' : 'left',
            },
        },
    },
    editor: {
        autoSelectOnDrop: true,
    },
    designTagsConfig: {
        // If left at default values, these designTags delimiters will default to [[ and ]]
        // which collides with our liquid-based merge fields. Upon loading, Unlayer
        // will replace all merge tags using [[ ]] with an empty string.
        // https://docs.unlayer.com/docs/design-tags#changing-delimiter
        delimiter: ['(╯°□°)╯︵┻━┻', '┬─┬ノ(°_°ノ)'],
    },
    mergeTags: toMergeTags(mergeFields),
    specialLinks: toSpecialLinks(i18n, appointmentTypes),

    // TODO: Are there any good ways of having webpack include css generated from a scss file,
    // so we can use existing design system variables, etc?
    customCSS: [
        // Make Unlayer draggable tools look like Keap cards
        `
.blockbuilder-content-tools .blockbuilder-content-tool {
    border-color: transparent;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.blockbuilder-content-tools .blockbuilder-content-tool:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}

.blockbuilder-content-tools .blockbuilder-content-tool.disabled {
    background-color: #f0f0f0;
    opacity: 1;
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
}

.blockbuilder-content-tools .blockbuilder-content-tool.disabled > div {
    opacity: .5;
}

.blockbuilder-content-tools .blockbuilder-content-tool.disabled:hover {
    box-shadow: inset 0px 0px 0px 1px rgba(0, 0, 0, 0.12);
}`,
        // Normalize icon sizing so custom icons are consistent with default icons
        `
.blockbuilder-content-tool-icon {
    max-height: 33px;
}

.blockbuilder-content-tool-icon > img {
    max-height: 33px;
}`,
        // Normalize draggable content row elevation w/ cards.
        `
div[draggable="true"] > .blockbuilder-row-tool:hover {
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px -1px rgba(0, 0, 0, 0.2);
}`,
    ],
});
