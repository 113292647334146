<template>
    <controlled-modal
        class="confirm-modal"
        size="sm"
        dialog
        :is-open="isOpen"
        @close="$emit('close')"
    >
        <h4>{{ $t(`title.${hasData}`, { objectName }) }}</h4>

        <i18n tag="p" :path="`message.${hasData}`">
            <template #recordType>
                <span>{{ recordType }}</span>
            </template>

            <template #objectName>
                <span>{{ objectName }}</span>
            </template>

            <template #parentName>
                <span>{{ parentName }}</span>
            </template>

            <template #permanent>
                <strong>{{ $t('permanent') }}</strong>
            </template>
        </i18n>

        <section class="modal-bottom button-row">
            <ds-text-button class="cancel-button" data-qa="cancel" @click="$emit('close')">
                {{ $t('cancel') }}
            </ds-text-button>

            <ds-text-button
                negative
                class="confirm-button"
                data-qa="confirm"
                @click="$emit('confirm')"
            >
                {{ $t('confirm') }}
            </ds-text-button>
        </section>
    </controlled-modal>
</template>


<script>
import { CUSTOM_FIELD_RECORD_TYPES } from '@/customFields/customFields.constants';
import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: {
        ControlledModal,
    },

    props: {
        isOpen: Boolean,
        objectName: String,
        parentName: String,
        recordType: {
            type: String,
            required: true,
            validator: (value) => CUSTOM_FIELD_RECORD_TYPES.includes(value),
        },
        usageCount: Number,
    },

    /* documentation:start */
    docevents: [
        {
            name: 'cancel',
            docdescription: 'Cancels the modal',
        },
        {
            name: 'confirm',
            docdescription: 'Confirms and closes the modal',
        },
        {
            name: 'close',
            docdescription: 'Closes the modal',
        },
    ],
    /* documentation:end */

    computed: {
        hasData() {
            return this.usageCount !== 0
                ? 'hasData'
                : 'noData';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@infusionsoft/design-system/src/styles/common";

    h4 {
        margin-bottom: $gp;
    }

    .modal-bottom {
        margin-top: $gp * 1.5;
        width: 100%;
    }

    .confirm-button,
    .cancel-button {
        flex: unset;
    }

    .button-row {
        justify-content: flex-end;
    }
</style>


<i18n>
{
    "en-us": {
        "confirm": "Delete field",
        "cancel": "Go back",
        "title": {
            "hasData": "Delete {objectName} and all stored data?",
            "noData": "Delete custom field?"
        },
        "message": {
            "hasData": "If you delete this {objectName}, you will also lose all the data stored in it across all {parentName}. {permanent}",
            "noData": "This custom field can be safely deleted – it's not being used to store any {recordType} data. This action is permanent."
        },
        "permanent": "This action is permanent."
    }
}
</i18n>
