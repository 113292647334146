<template lang="html">
    <section class="notification-placeholder">
        <div v-for="n in 5" :key="n" class="notification-wrapper">
            <div class="notification">
                <ds-placeholder :rows="title" />
                <ds-placeholder :rows="content" />
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            title: [{ height: '1.5rem', boxes: [1] }],
            content: [
                { height: '.75rem', boxes: [1] },
                { height: '.75rem', boxes: [1] },
            ],
        };
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .notification-wrapper {
        padding: 0 $gp;
        margin-bottom: $gp / 2;
    }

    .notification {
        display: grid;
        grid-gap: $gp;
        padding: $gp;
        border-radius: $border-radius;
        box-shadow: $elevation-z1;

        &:last-child {
            border-bottom: none;
        }
    }
</style>
