export const phoneRegex = /(\+?1-?)?(\d{3})(\d{3})(\d{4})/;

export const formatPhone = (phone) => {
    if (!phone) {
        return null;
    }

    let numbers = phone.replace(/\D/g, '');

    if (numbers.startsWith('1')) {
        numbers = numbers.substring(1);
    }

    const char = { 0: '(', 3: ') ', 6: '-' };

    phone = '';

    for (let i = 0; i < numbers.length; i++) {
        phone += (char[i] || '') + numbers[i];
    }

    return phone;
};

export const formatPhoneTelLink = (phone) => {
    if (!phone) {
        return null;
    }

    phone = phone.replace('+1', '');

    return phone.replace(/[^0-9]/g, '')
        .replace(phoneRegex, '$2-$3-$4');
};

export const phoneFaxType = (phoneFax) => {
    if (!phoneFax) {
        return null;
    }

    return `${phoneFax.charAt(0)}${phoneFax.slice(1).toLowerCase()}`;
};

export const allPhones = (contactDetails) => {
    if (!contactDetails) return [];

    const {
        phone1,
        phone2,
        phone3,
        phone4,
        phone5,
    } = contactDetails;

    return [
        phone1,
        phone2,
        phone3,
        phone4,
        phone5,
    ].filter((item) => item);
};
