<template lang="html">
    <router-link
        v-track="`Navigation - clicked : ${label}`"
        class="nav-item"
        :class="{ expanded, link: to, exact }"
        :title="label"
        :to="to"
        event="click"
    >
        <slot v-if="$slots.default" />

        <div v-else class="nav-item-content">
            <ds-icon :name="icon" :show-badge="showIconBadge" />

            <span class="label">{{ label }}</span>

            <feature-label
                v-if="extraLabel"
                class="extra-label"
                :type="extraLabel"
            />
        </div>
    </router-link>
</template>

<script>
import FeatureLabel from '@/nav/components/FeatureLabel';

export default {
    components: {
        FeatureLabel,
    },

    props: {
        exact: Boolean,
        expanded: Boolean,
        extraLabel: String,
        icon: String,
        label: String,
        showIconBadge: Boolean,
        to: {
            type: [Object, String],
            default: '',
        },
    },
};
</script>

<style lang="scss" scoped>
    .nav-item {
        @include transition(background-color, color);
        display: block;
        color: $color-gray-900;
        text-decoration: none;
        padding: $gp * 0.75 $gp;
        white-space: nowrap;

        &:hover {
            background-color: rgba($color-green, 0.1);
        }

        &.link.router-link-active:not(.exact),
        &.link.router-link-exact-active.exact {
            color: $color-forest;
            background-color: rgba($color-green, 0.2);
            font-weight: $font-weight-semibold;
        }

        @media ($medium) {
            &:not(.expanded) .label,
            &:not(.expanded) .extra-label {
                opacity: 0;
            }
        }
    }

    .nav-item-content {
        display: flex;
        align-items: center;
        height: 100%;
    }

    .label {
        @include transition(opacity);
        @include margin-start($gp);
        font-size: px-to-rem(16px);
    }

    .extra-label {
        @include transition(opacity);
        @include margin-start($gp / 2);
    }
</style>
