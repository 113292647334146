<template v-if="emailMarketability">
    <ds-text-button
        class="marketability-button"
        :disabled="!statusAlterable"
        @click="clickEmail"
    >
        <ds-status
            :type="marketabilityStatus.type"
            :emphasis="marketabilityStatus.emphasis"
            data-qa="email-status"
        >
            {{ $t(emailMarketability.status) }}
        </ds-status>
    </ds-text-button>
</template>

<script>

import {
    CONFIRMED,
    NON_MARKETABLE,
    UNSUBSCRIBED,
    UNENGAGED_NON_MARKETABLE,
    UNENGAGED_MARKETABLE,
} from './emailMarketability';

export default {
    props: {
        emailMarketability: Object,
        editable: Boolean,
        emailField: String,
    },

    computed: {
        marketabilityStatus() {
            let type;
            let emphasis;

            switch (this.emailMarketability.status) {
            case CONFIRMED:
                type = 'good';
                emphasis = 'medium';
                break;
            case NON_MARKETABLE:
            case UNENGAGED_NON_MARKETABLE:
            case UNSUBSCRIBED:
                type = 'error';
                emphasis = 'medium';
                break;
            case UNENGAGED_MARKETABLE:
                type = 'warning';
                emphasis = 'low';
                break;
            default:
                type = 'default';
                emphasis = 'low';
            }

            return { type, emphasis };
        },

        statusAlterable() {
            switch (this.emailMarketability.status) {
            case CONFIRMED:
            case UNSUBSCRIBED:
                return false;
            default:
                return this.editable;
            }
        },
    },

    methods: {
        clickEmail() {
            this.$router.push({ query: { marketability: this.emailField } });
        },
    },
};
</script>

<style lang="scss" scoped>
    .marketability-button {
        text-align: left;
        padding: 2px;
        border-radius: 4px;
        margin-left: -2px;

        &:hover {
            background-color: $color-gray-100;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "Confirmed": "Confirmed",
        "SingleOptIn": "Unconfirmed",
        "UnengagedNonMarketable": "Unengaged non-marketable",
        "NonMarketable": "Non-marketable",
        "Unsubscribed": "Unsubscribed",
        "UnengagedMarketable": "Unengaged Marketable",
        "Manual": "Opt-Out"
    }
}
</i18n>
