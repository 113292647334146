import * as tasksApi from '@/tasks/api/';
import sentry from '@/analytics/sentry';

export default {
    LOAD_APP_TASKS(context, payload) {
        return loadAppTasks(context, payload || {});
    },

    LOAD_APP_TASKS_BY_DATE(context, payload) {
        return loadAppTasksByDate(context, payload || {});
    },

    LOAD_TASK(context, taskId) {
        return loadTask(context, taskId);
    },

    UPDATE_APP_TASK(context, task) {
        updateAppTask(context, task);
    },

    ADD_APP_TASKS(context, tasks) {
        addAppTasks(context, tasks);
    },

    REMOVE_APP_TASK(context, taskId) {
        removeAppTask(context, taskId);
    },
};

const removeAppTask = ({ commit }, taskId) => {
    commit('REMOVE_APP_TASK', taskId);
};

const addAppTasks = ({ commit }, payload) => {
    commit('ADD_APP_TASKS', payload);
};

const updateAppTask = ({ commit }, task) => {
    commit('UPDATE_APP_TASK', task);
};

const loadTask = (_, taskId) => {
    const result = tasksApi.loadTask(taskId);

    return result.then(({ data }) => {
        return data.task;
    })
        .catch((err) => {
            sentry.log('Load task failed', { message: err.message });
            throw err;
        });
};

const loadAppTasks = ({ commit, state }, {
    limit = 20,
    offset = 0,
    completed = false,
    assignedUserId = 0,
}) => {
    const { tasks } = state;

    const result = tasksApi.loadAppTasks({
        limit,
        offset,
        completed,
        assignedUserId,
    });

    return result.then(({ data: { appTasks } }) => {
        let merged = appTasks.tasks;

        if (offset > 0) {
            merged = tasks.concat(appTasks.tasks);
        }
        commit('SET_APP_TASKS', { tasks: merged, total: appTasks.total });

        return appTasks.tasks;
    }).catch((err) => {
        sentry.log('Load app tasks failed', { message: err.message });
        throw err;
    });
};

const loadAppTasksByDate = (_, {
    limit = 20,
    offset = 0,
    completed = false,
    assignedUserId = 0,
    since = '',
    until = '',
}) => {
    const result = tasksApi.loadAppTasksByDate({
        limit,
        offset,
        completed,
        assignedUserId,
        since,
        until,
    });

    return result.then(({ data: { appTasks } }) => {
        return appTasks.tasks;
    }).catch((err) => {
        sentry.log('Load app tasks failed', { message: err.message });
        throw err;
    });
};
