<script>
import SingleEmailModal from '@/contacts/components/email/SingleEmailModal';
import { CONTACT_RECORD } from '@/contacts/contactEmailTypes';
import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';

export default {
    mixins: [closeConfirmation],

    props: {
        contact: {
            type: Object,
            required: true,
        },

        email: {
            type: String,
            required: true,
        },

        eventSource: {
            type: String,
            default: CONTACT_RECORD,
        },
    },

    mounted() {
        const { email, contact } = this;

        this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);

        this.$bus.$emit('PUSH_NESTED_MODAL', {
            component: SingleEmailModal,
            showRootClose: true,
            props: {
                recipientList: [{ ...contact, email }],
                source: this.eventSource,
            },
            interceptClose: () => this.confirmCloseDraft(() => {
                this.$store.commit('communicationTemplates/SET_CURRENT_EMAIL_TEMPLATE', {});
                this.$bus.$emit('POP_NESTED_MODAL');
                this.$emit('close');
            }),
            interceptBack: () => this.confirmCloseDraft(() => {
                this.$store.commit('communicationTemplates/SET_CURRENT_EMAIL_TEMPLATE', {});
                this.$bus.$emit('POP_NESTED_MODAL');
                this.$emit('close');
            }),
        });
    },

    render() {
        return null;
    },
};
</script>
