import sentry from '@/analytics/sentry';
import { templatesApi as api } from '@/campaigns/api';

export default {
    // checks if marketplace template finished creating/fulfilling
    GET_CAMPAIGN_FULFILLMENT(context, payload) {
        return getCampaignFulfillment(context, payload);
    },

    INCREMENT_INSTALL_COUNT(context, payload) {
        return incrementInstallCount(context, payload);
    },

    // create campaign from marketplace template
    INSTALL_CAMPAIGN_TEMPLATE(context, payload) {
        return installCampaignTemplate(context, payload);
    },

    LOAD_CAMPAIGN_TEMPLATES(context) {
        return loadCampaignTemplates(context);
    },
};

const getCampaignFulfillment = async (context, id) => {
    try {
        return await api.getCampaignFulfillment(id);
    } catch (e) {
        sentry.log('Loading campaign fulfillment failed', { id });
        throw e;
    }
};

const incrementInstallCount = async (context, payload) => {
    if (payload?.strategySelected?.uid) {
        const { uid } = payload.strategySelected;

        try {
            const template = await api.getTemplateByUid(uid);

            if (!template) {
                throw new Error('Template not found');
            }

            const installCount = template.installCount
                ? ++template.installCount
                : 1;

            const newTemplate = {
                installCount,
                ...template,
            };

            await api.incrementInstallCount(uid, newTemplate);
        } catch (e) {
            sentry.log(`Incrementing install count failed ${uid}`, e);

            throw e;
        }
    }

    return Promise.reject();
};

const installCampaignTemplate = async ({ commit, dispatch }, { id, title }) => {
    return new Promise((resolve, reject) => {
        return api.installCampaignTemplate({
            id,
            title,
        }).then(({ data: { installCampaignTemplate: createdId } }) => {
            let retries = 0;
            const MAX_RETRIES = 20;

            const interval = window.setInterval(() => {
                dispatch('GET_CAMPAIGN_FULFILLMENT', createdId)
                    .then(({ data: { campaignFulfillment: complete } }) => {
                        if (complete) {
                            commit('INSTALL_TEMPLATE', id);
                            window.clearInterval(interval);
                            resolve(createdId);
                        } else {
                            retries++;

                            if (retries >= MAX_RETRIES) {
                                window.clearInterval(interval);
                                reject();
                            }
                        }
                    }).catch(() => {
                        window.clearInterval(interval);
                        reject();
                    });
            }, 1000);
        }).catch(reject);
    });
};

const loadCampaignTemplates = async ({ commit }) => {
    try {
        const templates = await api.campaignTemplatesQuery();

        commit('SET_CAMPAIGN_TEMPLATES', [...templates]);
    } catch (e) {
        sentry.log('Error loading campaign templates', e);

        throw e;
    }
};
