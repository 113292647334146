<template>
    <div class="contact-participant-item">
        <router-link
            v-for="contact in participant.contacts"
            :key="contact.id"
            :to="to(contact.id)"
            class="contact-list-item"
            :title="getTitle(contact)"
        >
            <ds-list-item
                clickable
                class="list-item"
                :title="getTitle(contact)"
                border-style="none"
            >
                <template #description>
                    <i18n
                        class="automation-next-step"
                        path="nextStep"
                    >
                        <template #nextStep>
                            <automation-display-name :value="participantListItem_nextStep" as="span" />
                        </template>
                    </i18n>
                </template>

                <template #leadingSlot>
                    <div
                        :class="['circle-checkbox-container circle-checkbox-hover', { checked: isChecked }]"
                        :data-qa="getTitle(contact)"
                        @click.stop.prevent="select(contact)"
                    >
                        <span class="circle-checkbox">
                            <ds-icon class="circle-check" name="check" />
                        </span>

                        <div class="circle-checkbox-avatar">
                            <contact-avatar :email="participantListItem_getEmail(contact)" :name="getTitle(contact)" />
                        </div>
                    </div>
                </template>
            </ds-list-item>
        </router-link>
    </div>
</template>

<script>
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import ContactAvatar from '@/shared/components/ContactAvatar';
import participantListItemMixin from '@/automations/components/participants/participantListItem.mixin';

import { displayFullName } from '@/contacts/contact-info-utils';

export default {
    components: {
        AutomationDisplayName,
        ContactAvatar,
    },

    mixins: [participantListItemMixin],

    props: {
        participant: {
            type: Object,
            default: () => ({ contacts: [] }),
        },
        automation: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isChecked() {
            return this.participant.selectedContactIds?.length;
        },
    },

    methods: {
        getTitle(contact) {
            return displayFullName(contact);
        },

        to(contactId) {
            const { query, params } = this.$route;

            return {
                name: 'contact.record',
                query,
                params: {
                    ...params,
                    contactId,
                    id: null,
                    tab: null,
                },
            };
        },

        select({ id: contactId }) {
            this.$emit('select', { contact: { id: contactId } });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/shared/styles/circle-checkbox";
    @import "./participantListItem";
</style>

<i18n>
{
    "en-us": {
        "nextStep": "Next step: {nextStep}"
    }
}
</i18n>
