<template>
    <ds-tooltip position="top">
        <template #reference>
            <ds-icon-button
                v-track="'Contacts - contact email - clicked : Add Attachment'"
                name="attach"
                data-qa="contact-email-add-attachment-link"
                @click="addAttachmentClicked"
            />
        </template>

        <template #default>
            <div>{{ $t('addAttachment') }}</div>
        </template>
    </ds-tooltip>
</template>

<script>
export default {
    methods: {
        addAttachmentClicked() {
            this.$emit('add-attachment-clicked');
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "addAttachment": "Add attachment"
    }
}
</i18n>
