export default {
    file: null,
    fileboxId: 0,
    importMappings: [],
    importSource: null,
    lastImportSource: null,
    mapToOptions: {},
    customFields: [],
    count: 0,
    singleOptIn: false,
    results: [],
    tagId: null,
    activeStage: 'upload',
    additionalTags: [],
    contactList: [],
    contactLimit: 0,
    qboIntegration: {
        isConnected: false,
        isLoaded: false,
    },
    microsoftPageToken: '',
    matchedOpened: false,
    importInProgress: {
        contactcount: 0,
        fileName: '',
        previewData: {},
    },
    stages: {
        upload: {
            ready: false,
            label: 'importContacts.stages.upload.title',
        },
        organize: {
            ready: false,
            label: 'importContacts.stages.organize.title',
        },
        final: {
            ready: false,
            label: 'importContacts.stages.final.title',
        },
    },

    states: {
        importing: false,
        failed: false,
        imported: false,
        ready: false,
    },

    error: {
        code: '',
        reason: '',
    },
};
