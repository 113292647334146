<template>
    <section v-if="fieldsToShow.length" class="contact-links">
        <h4 class="section-title">
            {{ $t('title') }}
        </h4>

        <div
            v-for="field in fieldsToShow"
            :key="field"
            class="field-grid padding-bottom"
        >
            <div
                class="view-field"
                :data-qa="field"
            >
                <small class="label">
                    {{ $t(`forms.${field}`) }}
                </small>

                <a :href="contact[field]" target="_blank" rel="noopener noreferrer">
                    {{ contact[field] }}
                </a>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    props: {
        contact: Object,
    },

    data() {
        return {
            fields: [
                'website',
                'linkedin',
                'twitter',
                'facebook',
            ],
        };
    },

    computed: {
        fieldsToShow() {
            return this.fields.filter((field) => this.contact[field]);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .padding-bottom {
        padding-bottom: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Links"
    }
}
</i18n>
