var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('breakpoint-query',{attrs:{"breakpoint":850},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLargeBreakpoint = ref.isActive;
return [_c('div',{staticClass:"merge-field-suggestion-footer"},[(isLargeBreakpoint && !_vm.isContextualAutomation)?_c('section',{staticClass:"insert-text"},[_c('span',{staticClass:"insert-text-label"},[_vm._v(_vm._s(_vm.$t('insert')))]),_c('div',{staticClass:"chips"},_vm._l((_vm.suggestions),function(ref){
var label = ref.label;
var value = ref.value;
return _c('ds-chip',{key:value,staticClass:"merge-chip",attrs:{"with-interaction":""},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.handleMergeFieldInsert(value)}}},[_vm._v(" "+_vm._s(label)+" ")])}),1)]):_vm._e(),_c('merge-field-dropdown',{staticClass:"merge-field-dropdown",attrs:{"position":"bottom-start","use-merge-service":_vm.useMergeService,"additional-merge-fields":_vm.additionalMergeFields},on:{"select":function (ref) {
	var value = ref.value;

	return _vm.handleMergeFieldInsert(value);
},"change":function (val) { return _vm.$emit('menu-change', val); }},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var toggle = ref.toggle;
return [_c('ds-text-button',{attrs:{"trailing-icon":"chevron-down"},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm.$t(isLargeBreakpoint && !_vm.isContextualAutomation ? 'more' : 'insert'))+" ")])]}}],null,true)})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }