<template>
    <div class="public-form-submitted-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div>
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="close" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-text-button
                            v-if="showCreateForm"
                            trailing-icon="external-link"
                            data-qa="add-new-form"
                            @click="createForm"
                        >
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            :class="['search-input', { 'hide-create': !showCreateForm }]"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-if="showAnyResult"
                        class="list-item-option"
                        as="a"
                        data-qa="public-forms-any"
                        :title="$t('anyPublicFormSubmitted.title')"
                        :description="$t('anyPublicFormSubmitted.description')"
                        @click="handleSelectAnyPublicForm"
                    />

                    <p v-if="!search" class="list-header">
                        {{ $t('formsHeader') }}
                    </p>

                    <p v-if="noPublicForms" class="empty-list">
                        {{ $t('emptyPublicForms') }}
                    </p>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(form, i) in filteredPublicForms"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :data-qa="`form_${form.id}`"
                        :title="form.title"
                        @click="handleSelection(form)"
                    />
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import { mapState } from 'vuex';
import { ANY_PUBLIC_FORM_SUBMITTED, PUBLIC_FORM_SUBMITTED } from '@/automations/constants/automations.constants';
import { FORM_TYPE_CONTACTS } from '@/customForms/customForm.constants';

export default {
    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedOption: {},
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapState({
            publicForms: ({ customForms }) => customForms.forms[FORM_TYPE_CONTACTS],
        }),

        anyPublicFormTrigger() {
            return {
                type: ANY_PUBLIC_FORM_SUBMITTED,
                sourceId: null,
                name: this.$t('anyPublicFormSubmitted.name'),
            };
        },

        publicFormTrigger() {
            const { id } = this.selectedOption;

            return {
                type: PUBLIC_FORM_SUBMITTED,
                sourceId: id,
                name: this.$t('singlePublicFormSubmitted', { id }),
            };
        },

        showAnyResult() {
            const search = this.search?.trim()?.toLowerCase();

            return this.$t('anyPublicFormSubmitted.title').toLowerCase().includes(search)
                || this.$t('anyPublicFormSubmitted.description').toLowerCase().includes(search);
        },

        filteredPublicForms() {
            const search = this.search?.trim()?.toLowerCase();

            return this.publicForms.filter(({ title }) => {
                return title.toLowerCase().includes(search);
            });
        },

        noPublicForms() {
            return !this.loading && !this.publicForms.length;
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredPublicForms.length && !this.showAnyResult;
        },

        showCreateForm() {
            return this.$route.name !== 'customForms.internal' && this.$route.name !== 'customForms.public';
        },
    },

    methods: {
        init() {
            const { trigger } = this.automationProperties;

            if (trigger) {
                this.selectedOption = {
                    ...trigger,
                    id: trigger.sourceId,
                };
            } else {
                this.selectedOption = {
                    type: ANY_PUBLIC_FORM_SUBMITTED,
                    id: null,
                    name: this.$t('publicFormSubmitted'),
                };
            }

            if (!this.publicForms.length) {
                this.loadPublicForms();
            }
        },

        async loadPublicForms() {
            this.loading = true;
            await this.$store.dispatch('customForms/LOAD_FORMS');
            this.loading = false;
        },

        handleSelection(form) {
            this.selectedOption = clonedeep(form);
            this.$emit('updated', this.publicFormTrigger);
            this.$bus.$emit('AUTOMATION_PUBLIC_FORM_UPDATED');
            this.$emit('close');
        },

        handleSelectAnyPublicForm() {
            this.$emit('updated', this.anyPublicFormTrigger);
            this.$bus.$emit('AUTOMATION_PUBLIC_FORM_UPDATED');
            this.$emit('close');
        },

        createForm() {
            return this.$confirm({
                optionTitle: this.$t('createForm.title'),
                optionMessage: this.$t('createForm.message'),
                optionConfirmButtonLabel: this.$t('createForm.confirm'),
                optionCancel: this.$t('global.cancel'),
            })
                .then(() => {
                    this.$router.push({ name: 'customForms.public' });
                }).catch(() => {});
        },

        close() {
            const { type, sourceId } = this.publicFormTrigger;

            if (type === PUBLIC_FORM_SUBMITTED && !sourceId) {
                this.handleSelectAnyPublicForm();
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "anyPublicFormSubmitted": {
            "name": "Any public form is submitted",
            "title": "Any public form",
            "description": "When any public form is submitted"
        },
        "publicFormSubmitted": "Public form is submitted",
        "singlePublicFormSubmitted": "public form ({id}) is submitted",
        "emptyPublicForms": "None available",
        "header": "Choose a form",
        "formsHeader": "Public forms",
        "buttonText": "Create new form",
        "search": "Search",
        "emptySearch": "No results found for \"{search}\"",
        "createForm": {
            "title": "Navigate to the forms page to create new forms?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Go to forms"
        }
    }
}
</i18n>
