/*
 * This script has been added by marketing. It's purpose is to be able to
 * set cookies on marketing domains when we authenticate a logged in user
 * to keap-web. This enables us to filter out paying customers from our
 * website's analytics data to get a better overall view of our actual
 * website performance for non-customers.
 *
 * For questions or concerns about this script, please connect with
 * Devin Pitcher in marketing.
 */

let alreadyChecked = false;

/* Domains to keep in sync */
const cookieDomains = [
    'keap.com',
    'infusionsoft.com',
];

const getAmplitudeDeviceId = () => {
    const maxTries = 20;
    const retryDelay = 250;
    const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}R$/gmi;

    let tries = 0;

    return new Promise((resolve) => {
        const check = () => {
            tries++;

            if (tries > maxTries) return resolve(null);

            try {
                /* Try to get the device ID from the SDK */
                const { deviceId } = window.amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).options;

                /* Ensure it's valid */
                if (regex.test(deviceId)) {
                    return resolve(deviceId);
                }

                /* Empty or not valid, throw an error so we can try again */
                throw new Error();
            } catch (e) {
                /* Try again */
                return setTimeout(check, retryDelay);
            }
        };

        /* Start checking for the device ID */
        check();
    });
};

/* This relies on a boolean if the user is paid or not */
const run = async (isPayingCustomer) => {
    if (alreadyChecked) return;

    const query = [];

    if (isPayingCustomer) {
        query.push('customer=true');
    } else {
        query.push('trial=true');
    }

    const amplitudeDeviceId = await getAmplitudeDeviceId();

    /* Append the Amplitude ID, if available. */
    if (amplitudeDeviceId) query.push(`amp=${amplitudeDeviceId}`);

    /* Load all the pixels */
    cookieDomains.forEach((domain) => {
        const pixel = new Image();

        pixel.src = `https://mktapi.${domain}/pixel/identify/?${query.join('&', query)}`;
    });

    alreadyChecked = true;
};

export default run;
