import Vue from 'vue';
import moment from 'moment';
import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';

export const dateSegment = (date, hasTime) => {
    const momentDate = moment(new Date(date));
    const showTime = hasTime ? `${momentDate.format('llll')}, ${momentDate.format(TIME_FORMAT)}` : '';

    return hasTime
        ? showTime
        : moment(momentDate).calendar(null, {
            lastDay: `[${Vue.prototype.$i18nInstance.t('global.time.yesterday')}]`,
            sameDay: `[${Vue.prototype.$i18nInstance.t('global.time.today')}]`,
            nextDay: `[${Vue.prototype.$i18nInstance.t('global.time.tomorrow')}]`,
            lastWeek: 'ddd, MMM D YYYY',
            nextWeek: 'dddd',
            sameElse: 'ddd, MMM D YYYY',
        });
};

export const isDateInvalid = (date) => {
    return typeof date === 'string' && date.includes('Invalid');
};

export const timeZone = moment.tz ? moment.tz.guess() : null;
export const timeZoneCode = moment.tz && timeZone ? moment.tz(timeZone).zoneAbbr() : null;
export const browserTimezone = timeZoneCode ? `${timeZone} - ${timeZoneCode}` : null;
