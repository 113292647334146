<template lang="html">
    <div class="location-field">
        <ds-button-toggle
            v-model="locationOption"
            block
            :options="locations"
        />

        <section>
            <div v-show="showOnlineOptions">
                <ds-radio
                    v-if="videoCallsEnabled"
                    v-model="localLocationType"
                    :label="$t('locations.twilio')"
                    :val="LOCATION_TYPES.ONLINE_TWILIO"
                />

                <ds-radio
                    v-model="localLocationType"
                    :val="LOCATION_TYPES.ONLINE_ZOOM"
                    data-qa="zoom-radio"
                >
                    {{ $t('locations.zoom') }}
                    <ds-popover position="right">
                        <template #reference>
                            <ds-icon-button
                                name="info-circle"
                            />
                        </template>

                        <template #default>
                            <div class="popover-content">
                                <i18n
                                    path="zoom.toolTip"
                                    tag="div"
                                >
                                    <template #action>
                                        <ds-text-button class="manage-zoom" @click="goToIntegrationsPage">
                                            {{ $t('zoom.toolTipAction') }}
                                        </ds-text-button>
                                    </template>
                                </i18n>
                            </div>
                        </template>
                    </ds-popover>
                </ds-radio>

                <template v-if="isZoomDisconnected && localLocationType === LOCATION_TYPES.ONLINE_ZOOM">
                    <div class="connect-zoom">
                        <ds-filled-button data-qa="connect-to-zoom" @click="goToIntegrationsPage">
                            {{ $t('zoom.connectToZoom') }}
                        </ds-filled-button>
                    </div>
                </template>

                <template v-if="!isZoomDisconnected">
                    <div class="zoom-connected" data-qa="zoom-connected">
                        {{ zoomEmail }}

                        <ds-status type="good">
                            {{ $t('zoom.connected') }}
                        </ds-status>
                    </div>
                </template>

                <ds-radio
                    v-model="localLocationType"
                    :label="$t('locations.online')"
                    :val="LOCATION_TYPES.ONLINE"
                />

                <template v-if="isOnlineSelected">
                    <ds-input-field
                        v-model="localValue"
                        :label="$t('placeholders.online')"
                    />
                </template>

                <ds-radio
                    v-model="localLocationType"
                    :label="$t('locations.clientOnline')"
                    :val="LOCATION_TYPES.CLIENT_SET_ONLINE"
                />
            </div>

            <div v-show="showPhoneOptions">
                <ds-radio
                    id="clientPhoneNumber"
                    v-model="localLocationType"
                    data-qa="client-phone-number-option"
                    :label="$t('locations.clientPhone')"
                    :val="LOCATION_TYPES.CLIENT_SET_PHONE"
                />

                <ds-radio
                    id="businessPhoneNumber"
                    v-model="localLocationType"
                    :label="$t('locations.phone')"
                    :val="LOCATION_TYPES.PHONE"
                />

                <template v-if="isPhoneSelected">
                    <keap-phone-field
                        v-if="useKeapLine"
                        :keap-phone-number="keapPhoneNumber"
                    />

                    <ds-phone-input
                        v-else
                        v-model="localValue"
                        :label="$t('placeholders.phone')"
                    />

                    <ds-checkbox
                        v-if="keapPhoneNumber"
                        v-model="useKeapLine"
                        :label="$t('locations.keapPhone')"
                    />
                </template>
            </div>

            <div v-show="showInPersonOptions">
                <ds-radio
                    v-model="localLocationType"
                    :label="$t('locations.inPerson')"
                    :val="LOCATION_TYPES.IN_PERSON"
                />

                <address-autocomplete
                    v-show="isInPersonSelected"
                    v-model="localValue"
                    :label="$t('placeholders.inPerson')"
                    use-selected-as-value
                />

                <ds-radio
                    v-model="localLocationType"
                    :label="$t('locations.clientInPerson')"
                    :val="LOCATION_TYPES.CLIENT_SET_IN_PERSON"
                />
            </div>
        </section>
    </div>
</template>

<script>
import AddressAutocomplete from '@/shared/components/Fields/AddressAutocomplete';
import KeapPhoneField from '@/shared/components/Fields/KeapPhoneField';
import { mapState, mapGetters } from 'vuex';
import clone from 'lodash.clone';
import { LOCATION_TYPES, LOCATION_OPTIONS, INTEGRATION_STATUS } from '@/appointments/appointments.constants';
import { FF_FLAGSHIP_SPA_VIDEOCALLS_ENABLED } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        AddressAutocomplete,
        KeapPhoneField,
    },

    props: {
        value: String,
        locationType: String,
        bookingLink: String,
    },

    data() {
        return {
            LOCATION_TYPES,
            useKeapLine: false,
            localPhoneValue: null,
            localClientPhoneValue: null,
            localOnlineValue: null,
            localClientOnlineValue: null,
            localInPersonValue: null,
            localClientInPersonValue: null,
            localLocationType: this.locationType || '',
            locationOption: this.setLocationOption(this.locationType),
            locations: LOCATION_OPTIONS.map(({ value, label }) => {
                return { value, label: this.$t(label) };
            }),
            onlineLocation: 'local',
        };
    },

    mounted() {
        this.localValue = clone(this.value);

        this.$emit('update:isValid', this.isValid);
    },

    watch: {
        locationOption(val) {
            this.$track(`Appointments - Set up availability modal - clicked : ${val} appointment location field`);
            this.localLocationType = '';
        },

        localValue() {
            this.emitValue();

            this.useKeapLine = Boolean(this.keapPhoneNumber) && this.localValue === this.keapPhoneNumber;
        },

        useKeapLine(val) {
            this.localPhoneValue = val
                ? this.keapPhoneNumber
                : '';
        },

        localLocationType() {
            this.emitValue();
        },

        isPhoneSelected(selected) {
            if (selected && !this.localValue) {
                this.localValue = this.keapPhoneNumber || this.companyProfilePhoneNumber || '';
            }
        },

        onlineLocation() {
            this.emitValue();
        },

        isValid(val) {
            this.$emit('update:isValid', val);
        },
    },

    computed: {
        ...mapState({
            videoCallsEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_SPA_VIDEOCALLS_ENABLED],
            keapPhoneNumber: ({ auth }) => auth.account.keapPhoneAccountInfo?.phoneNumber,
            companyProfilePhoneNumber: ({ auth }) => auth.account.companyProfile?.phone,
            providers: ({ calendar }) => calendar.providers,
        }),

        ...mapGetters({
            videoCallUrl: 'calendar/videoCallUrl',
        }),

        localValue: {
            get() {
                let value = '';

                if (this.isOnlineSelected) {
                    value = this.localOnlineValue;
                } else if (this.isInPersonSelected) {
                    value = this.localInPersonValue;
                } else if (this.isPhoneSelected) {
                    value = this.localPhoneValue;
                } else if (this.isClientOnlineSelected) {
                    value = this.localClientOnlineValue;
                } else if (this.isClientPhoneSelected) {
                    value = this.localClientPhoneValue;
                } else if (this.isClientInPersonSelected) {
                    value = this.localClientInPersonValue;
                }

                return value;
            },

            set(value) {
                if (this.isOnlineSelected) {
                    this.localOnlineValue = value;
                } else if (this.isInPersonSelected) {
                    this.localInPersonValue = value;
                } else if (this.isPhoneSelected) {
                    this.localPhoneValue = value;
                } else if (this.isClientOnlineSelected) {
                    this.localClientOnlineValue = value;
                } else if (this.isClientPhoneSelected) {
                    this.localClientPhoneValue = value;
                } else if (this.isClientInPersonSelected) {
                    this.localClientInPersonValue = value;
                }
            },
        },

        isZoomDisconnected() {
            return !this.providers.some(({ zoomStatus }) => zoomStatus === INTEGRATION_STATUS.READY);
        },

        zoomEmail() {
            let zoomProvider = {};

            if (!this.isZoomDisconnected) {
                zoomProvider = this.providers.find((provider) => {
                    return provider.zoomStatus === INTEGRATION_STATUS.READY;
                });

                return zoomProvider.zoomAccountId;
            }

            return '';
        },

        isPhoneSelected() {
            return this.localLocationType === LOCATION_TYPES.PHONE;
        },

        showPhoneOptions() {
            return this.locationOption === LOCATION_TYPES.PHONE;
        },

        isClientPhoneSelected() {
            return this.localLocationType === LOCATION_TYPES.CLIENT_SET_PHONE;
        },

        isInPersonSelected() {
            return this.localLocationType === LOCATION_TYPES.IN_PERSON;
        },

        isClientInPersonSelected() {
            return this.localLocationType === LOCATION_TYPES.CLIENT_SET_IN_PERSON;
        },

        showInPersonOptions() {
            return this.locationOption === LOCATION_TYPES.IN_PERSON;
        },

        isClientOnlineSelected() {
            return this.localLocationType === LOCATION_TYPES.CLIENT_SET_ONLINE;
        },

        showOnlineOptions() {
            return this.locationOption === LOCATION_TYPES.ONLINE;
        },

        isOnlineSelected() {
            return this.localLocationType === LOCATION_TYPES.ONLINE;
        },

        isValid() {
            const { localLocationType, localValue } = this;

            const clientWillSetLocation = [
                LOCATION_TYPES.ONLINE_TWILIO,
                LOCATION_TYPES.CLIENT_SET_ONLINE,
                LOCATION_TYPES.CLIENT_SET_PHONE,
                LOCATION_TYPES.CLIENT_SET_IN_PERSON,
            ].includes(localLocationType);

            if (localLocationType === LOCATION_TYPES.ONLINE_ZOOM && !this.isZoomDisconnected) {
                return true;
            }

            return Boolean(clientWillSetLocation || localValue);
        },
    },

    methods: {
        goToIntegrationsPage() {
            this.$router.push({ name: 'settings.integrations' });
            this.$bus.$emit('CLOSE_NESTED_MODAL');
        },

        emitValue() {
            this.$emit('input', this.localValue);
            this.$emit('update:locationType', this.localLocationType);
        },

        setLocationOption(locationType) {
            switch (locationType) {
            case LOCATION_TYPES.ONLINE:
            case LOCATION_TYPES.CLIENT_SET_ONLINE:
            case LOCATION_TYPES.ONLINE_TWILIO:
            case LOCATION_TYPES.ONLINE_ZOOM:
                return LOCATION_TYPES.ONLINE;
            case LOCATION_TYPES.PHONE:
            case LOCATION_TYPES.CLIENT_SET_PHONE:
                return LOCATION_TYPES.PHONE;
            case LOCATION_TYPES.IN_PERSON:
            case LOCATION_TYPES.CLIENT_SET_IN_PERSON:
                return LOCATION_TYPES.IN_PERSON;
            default:
                return null;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .button-group {
        margin-bottom: $gp;
        white-space: nowrap;
        max-width: px-to-rem(360px);
    }

    .location-field {
        --input-margin-bottom: 0;
        --keap-phone-field-margin-bottom: 0;
    }

    .zoom-not-connected {
        color: $color-orange;
    }

    .connect-zoom {
        padding-left: $gp;
        margin-top: -$gp / 2;
    }

    .zoom-connected {
        padding-left: $gp * 2;
        margin-top: -$gp;
    }

    .manage-zoom {
        padding: 0;

        &:hover {
            background-color: transparent;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "placeholders": {
            "online": "Zoom, Webex, Skype, etc...",
            "inPerson": "Select an address or location",
            "phone": "Your phone number"
        },
        "locations": {
            "keapPhone": "Use my Keap Business Line",
            "twilio": "Use Keap online meetings",
            "zoom": "My Zoom meeting link (one for each appointment)",
            "clientPhone": "I will call the lead or client (ask the invitee for their phone number)",
            "phone": "Ask the invitee to call me",
            "inPerson": "I will choose the location",
            "clientInPerson": "Let the invitee choose the location (ask for address)",
            "online": "Use my online meeting link",
            "clientOnline": "Ask attendees to use their online meeting link"
        },
        "zoom": {
            "connectToZoom": "Connect to Zoom",
            "toolTip": "Use this Zoom integration to avoid meeting conflicts with a unique meeting link for each appoinment booked. {action}",
            "toolTipAction": "Manage your Zoom integration",
            "connected": "Connected"
        }
    }
}
</i18n>
