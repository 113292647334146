<template>
    <div class="setup">
        <div class="wrapper">
            <div v-if="isConfigured && configureStepList.length" class="configured-card-container">
                <configure-automation-card
                    v-for="(step, i) in configureStepList"
                    :key="`step-list_${step.stepListIndex}`"
                    :value="step"
                    :icon="getIcon(step)"
                    :has-error="hasError(step.step, step.delayStep ? step.stepListIndex + 1 : step.stepListIndex)"
                    :error-message="getConfigErrorMessage(step.delayStep ? step.stepListIndex + 1 : step.stepListIndex)"
                    :has-timer-warning="step.delayStep ? hasTimerWarning(step.stepListIndex) : false"
                    :class="[{ 'next-expanded': activeIndex === i + 1, 'last-expanded': activeIndex === i - 1 }]"
                    :active="activeIndex === i"
                    :can-add-step="canAddStep"
                    :add-open="addOpenIndex === i"
                    @active="$emit('set-active-index', activeIndex === i ? null : i)"
                    @remove="handleRemove(step)"
                    @updated="(event) => handleUpdate(event, step.stepListIndex)"
                    @save="(event) => handleSave(event, step.stepListIndex)"
                    @edit="(event) => handleClickEdit(event, step.stepListIndex)"
                    @selected="(payload) => handleInsert(payload, step.stepListIndex)"
                    @open-steps="addStep(i)"
                />
            </div>

            <div v-if="canAddStep" class="add-trigger">
                <div class="and-then">
                    {{ $t('andThen') }}
                </div>

                <add-step
                    :step-list="stepList"
                    :add-open="addOpenIndex === configureStepList.length"
                    @selected="(payload) => handleInsert(payload, stepList.length)"
                    @open-steps="addStep(configureStepList.length)"
                />

                <p v-if="addOpenIndex !== configureStepList.length" class="what-else">
                    {{ $t('whatElse') }}
                </p>
            </div>

            <template v-if="!isConfigured" class="add-trigger">
                <step-options @selected="(payload) => handleInsert(payload, stepList.length)" />

                <automation-feedback />
            </template>
        </div>

        <transition name="slide-left">
            <div v-if="actionComponent" class="action-panel">
                <component
                    :is="actionComponent"
                    :save-status="saveStatus"
                    :show-errors="showErrors"
                    :automation-properties="automationProperties"
                    @close="closeActionPanel"
                    @updated="handleComponentEdit"
                />
            </div>
        </transition>

        <sms-automation-setup-modal
            :is-open="isSmsSetupModalOpen"
            @close="isSmsSetupModalOpen = false"
            @complete="handleSmsSetupComplete"
        />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import isEqual from 'lodash.isequal';
import amplitude from '@/analytics/amplitude';

import AutomationTypeCard from '@/automations/components/AutomationTypeCard';
import AutomationTypeSection from '@/automations/components/AutomationTypeSection';
import ConfigureAutomationCard from '@/automations/components/configure/ConfigureAutomationCard';
import AutomationFeedback from '@/automations/components/AutomationFeedback';
import SmsAutomationSetupModal from '@/automations/components/SmsAutomationSetupModal';
import StepOptions from '@/automations/components/steps/StepOptions';
import AddStep from '@/automations/components/steps/AddStep';

import {
    VALIDATION_MESSAGES, TIMER_STEPS, RELATIVE_TIMER, TEMPLATE_STEP, EDITOR_STEP, TIMING_STEP,
    SEND_EMAIL, SEND_SMS, MAX_STEPS, SUGGESTED_MAX, ERROR_LEVELS, INTEGRATION_TYPES,
} from '@/automations/constants/automations.constants';
import { reorderSteps } from '@/automations/utils/automations.reorder.utils';
import { isValidStep } from '@/automations/utils/automations.utils';
import { stepComponents } from '@/automations/constants/automations.component.constants';

export default {
    components: {
        AutomationTypeCard,
        AutomationTypeSection,
        ConfigureAutomationCard,
        AutomationFeedback,
        SmsAutomationSetupModal,
        StepOptions,
        AddStep,
    },

    props: {
        // step list
        value: {
            type: Array,
            default: () => ([]),
        },

        activeIndex: Number,
        isEditing: Boolean,
        saveStatus: String,
        showErrors: Boolean,
        automationErrors: {
            type: Array,
            default: () => ([]),
        },
    },

    data() {
        return {
            actionComponent: null,
            baseUrl: process.env.BASE_URL,
            stepList: this.value,
            automationProperties: {},
            editingIndex: null,
            configureStepList: [],
            SUGGESTED_MAX,
            isSmsSetupModalOpen: false,
            insertStepIndex: null,
            addOpenIndex: null,
        };
    },

    created() {
        this.setConfigureStepList(this.value);
    },

    watch: {
        value: {
            handler(value) {
                if (value) {
                    this.$set(this, 'stepList', value);
                    this.updateAutomationProperties(value);
                }
            },
            deep: true,
        },

        isEditing(val) {
            if (!val && this.actionComponent) {
                this.closeActionPanel();
            }
        },
    },

    computed: {
        ...mapState({
            automatedSmsAccount: ({ auth }) => auth.account.automatedSmsAccount,
            validatedAutomation: ({ automations }) => automations.validatedAutomation,
            contextType: ({ automations }) => automations.context?.type,
        }),

        ...mapGetters({
            automationWithFrontendErrors: 'automations/automationWithFrontendErrors',
            allSteps: 'automations/allSteps',
        }),

        canAddStep() {
            return Boolean(this.stepList.length <= MAX_STEPS && this.isConfigured);
        },

        isConfigured() {
            return Boolean(this.stepList.length);
        },
    },

    methods: {
        setConfigureStepList(stepList) {
            const configureStepList = [];

            for (let i = 0; i < stepList?.length; i++) {
                const step = stepList[i];

                if (TIMER_STEPS.includes(step.type)) {
                    configureStepList.push({
                        stepListIndex: i,
                        delayStep: step,
                        step: stepList[i + 1],
                    });
                    i++;
                } else {
                    configureStepList.push({
                        step,
                        stepListIndex: i,
                    });
                }
            }

            this.configureStepList = configureStepList;
        },

        closeActionPanel() {
            this.actionComponent = null;
            this.editingIndex = null;
            this.addOpenIndex = null;

            this.$emit('toggle-editing', false);
        },

        handleComponentEdit(events) {
            this.handleSave(events, this.editingIndex);
        },

        handleRemove({ delayStep, stepListIndex }) {
            const stepsToRemove = delayStep ? 2 : 1;

            this.stepList.splice(stepListIndex, stepsToRemove);
            this.automationProperties = {};
            this.$emit('updated', this.stepList);
        },

        needsSmsSetup(step) {
            return step?.type === SEND_SMS && !this.automatedSmsAccount?.termsAndConditionsTimeStamp;
        },

        handleSmsSetupComplete() {
            const smsStep = this.allSteps.find(({ type }) => type === SEND_SMS);

            this.selectStep(smsStep, this.insertStepIndex);
        },

        handleInsert(step, stepListIndex) {
            this.editingIndex = stepListIndex;
            this.handleSelected(step, stepListIndex);
        },

        handleSelected(step, stepListIndex) {
            if (INTEGRATION_TYPES[step.type]) {
                this.handleIntegrationSelected(step);
            } else {
                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                    'Step Added': step.type,
                    'Automation Context': this.contextType,
                });

                if (this.needsSmsSetup(step)) {
                    this.insertStepIndex = stepListIndex;
                    this.isSmsSetupModalOpen = true;
                } else {
                    this.selectStep(step, stepListIndex);
                }
            }
        },

        handleIntegrationSelected({ type, name }) {
            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                'Step Added': `${type} Integration (not connected)`,
                'Automation Context': this.contextType,
            });

            return this.$confirm({
                optionTitle: this.$t('integrations.navigate.title'),
                optionMessage: this.$t('integrations.navigate.message', { name: this.$t(name) }),
                optionConfirmButtonLabel: this.$t('integrations.navigate.confirm'),
                size: 'sm',
            }).then(() => {
                this.$router.push({ name: 'settings.integrations', params: { integration: type } });
            }).catch(() => {});
        },

        selectStep(step, stepListIndex) {
            this.automationProperties = {};

            if (this.editingIndex === null) {
                this.editingIndex = 0;
            }
            this.addOpenIndex = null;
            this.actionComponent = stepComponents[step.type];
            this.stepList.splice(stepListIndex, 0, step);
            this.$emit('set-active-index', null);

            if (this.actionComponent) {
                this.automationProperties = {
                    stepListIndex,
                    initialStep: step.type === SEND_EMAIL || step.type === SEND_SMS ? TEMPLATE_STEP : EDITOR_STEP,
                };

                this.$emit('toggle-editing', true);
            }

            this.$emit('updated', this.stepList);
        },

        handleUpdate(events, stepListIndex) {
            const stepsToRemove = TIMER_STEPS.includes(this.stepList[stepListIndex]?.type) ? 2 : 1;

            this.stepList.splice(stepListIndex, stepsToRemove);
            this.stepList.splice(stepListIndex, 0, ...events);
        },

        handleSave(events, stepListIndex) {
            // the backend requires the steps to happen sequentially,
            // so if a relative timer has changed it needs to be sorted
            const relativeTimer = events.find(({ type }) => type === RELATIVE_TIMER);
            const updatingRelativeTimer = relativeTimer && !isEqual(relativeTimer, this.stepList[stepListIndex]);

            this.handleUpdate(events, stepListIndex);

            if (updatingRelativeTimer) {
                const { reorderedSteps, updatedIndex } = reorderSteps(this.stepList, stepListIndex);

                const hasChanged = stepListIndex !== updatedIndex;

                if (hasChanged) {
                    this.editingIndex = updatedIndex;
                    this.stepList = reorderedSteps;

                    this.updateAutomationProperties(this.stepList);

                    this.$bus.$emit('AUTOMATION_STEPS_REORDERED');
                }
            }

            this.$emit('updated', this.stepList);
        },

        getIcon({ delayStep }) {
            return delayStep ? 'clock' : 'zap';
        },

        hasError(step, i) {
            return Boolean(this.showErrors && (!isValidStep(step) || this.getConfigErrorMessage(i)));
        },

        hasTimerWarning(i) {
            const stepMessages = this.validatedAutomation?.steps?.[i]?.messages;

            return Boolean(this.showErrors && stepMessages?.some(({ severity }) => severity === ERROR_LEVELS.WARNING));
        },

        getConfigErrorMessage(i) {
            const stepMessages = this.validatedAutomation?.steps?.[i]?.messages;
            const frontendStepMessages = this.automationWithFrontendErrors?.steps?.[i]?.messages;

            const hasError = stepMessages?.some(({ severity }) => severity === ERROR_LEVELS.ERROR)
                || frontendStepMessages?.some(({ severity }) => severity === ERROR_LEVELS.ERROR);

            if (hasError) {
                return this.$t(VALIDATION_MESSAGES[stepMessages?.[0]?.messageCode]?.configureMessage)
                    || this.$t(VALIDATION_MESSAGES[frontendStepMessages?.[0]?.messageCode]?.configureMessage) || '';
            }

            return '';
        },

        handleClickEdit({ step, delayStep, initialStep = EDITOR_STEP }, stepListIndex) {
            if (this.needsSmsSetup(step)) {
                this.isSmsSetupModalOpen = true;
            } else {
                if (step.type === SEND_EMAIL && initialStep !== TIMING_STEP) {
                    initialStep = (step.configJson?.subject || step.configJson?.body)
                        ? EDITOR_STEP
                        : TEMPLATE_STEP;
                }

                if (step.type === SEND_SMS && initialStep !== TIMING_STEP) {
                    initialStep = (step.configJson?.title || step.configJson?.message)
                        ? EDITOR_STEP
                        : TEMPLATE_STEP;
                }

                this.automationProperties = {
                    initialStep,
                    delayStep,
                    step,
                    stepListIndex, // index of timer or step with no timer
                };
                this.actionComponent = stepComponents[step.type];
                this.editingIndex = stepListIndex;

                if (this.actionComponent) {
                    this.$emit('toggle-editing', true);
                }
            }
        },

        addStep(index) {
            if (this.addOpenIndex === null || this.addOpenIndex !== index) {
                this.addOpenIndex = index;
            } else {
                this.addOpenIndex = null;
            }
        },

        updateAutomationProperties(stepList) {
            this.setConfigureStepList(stepList);

            if (this.editingIndex !== null) {
                let step = stepList[this.editingIndex];
                let delayStep = null;

                if (TIMER_STEPS.includes(step.type)) {
                    delayStep = step;
                    step = stepList[this.editingIndex + 1];
                }

                this.automationProperties = {
                    ...this.automationProperties,
                    delayStep,
                    step,
                    stepListIndex: this.editingIndex,
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/setup';
</style>

<i18n>
{
    "en-us": {
        "andThen": "and then",
        "whatElse": "What else needs to be automated?",
        "integrations": {
            "navigate": {
                "title": "Navigate to integrations settings?",
                "message": "Connect {name} to Keap within settings. Once connected, available automation triggers will appear in the list. A draft will be saved of this automation.",
                "confirm": "Manage integrations"
            }
        }
    }
}
</i18n>
