<template>
    <empty-state
        :title="$t('title')"
        :description="$t('description')"
        image="tasks-color"
    />
</template>

<script>
import EmptyState from '@/shared/components/EmptyState';

export default {
    components: {
        EmptyState,
    },
};
</script>

<i18n>
{
    "en-us": {
        "title": "No notifications yet",
        "description": "This is where we'll keep all your notifications."
    }
}
</i18n>
