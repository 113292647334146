<template lang="html">
    <controlled-modal
        :is-open="isOpen"
        class="send-test-sms-modal"
        size="xs"
        @close="close"
    >
        <h3> {{ title }} </h3>

        <ds-phone-input
            v-model="to"
            class="phone-input"
            required
            :label="$t('inputTitle')"
            :invalid="invalid"
            :submitted="submitted"
            @input="reset"
        >
            <template #error>
                {{ errorMessage }}
            </template>
        </ds-phone-input>

        <div class="actions">
            <ds-text-button
                data-qa="back-button"
                @click="close"
            >
                {{ $t('global.back') }}
            </ds-text-button>

            <ds-filled-button
                class="button-space"
                :loading="loading"
                data-qa="send-test-message-button"
                @click="sendTest"
            >
                {{ $t('global.send') }}
            </ds-filled-button>
        </div>
    </controlled-modal>
</template>

<script>
import { mapState } from 'vuex';

import {
    sendTestMessageMutation,
} from '@/broadcasts/api';

import { isValidPhoneNumber } from '@/communication/utils/sms-utils';
import ControlledModal from '@/shared/components/ControlledModal';

import { TOO_MANY_REQUESTS } from '@/broadcasts/smsBroadcast.constants';

export default {
    components: {
        ControlledModal,
    },

    props: {
        isOpen: Boolean,

        from: {
            type: String,
            required: true,
        },

        message: {
            type: String,
            required: true,
        },

        source: {
            type: String,
            default: 'Broadcasts',
        },

    },
    data() {
        return {
            to: '',
            invalid: false,
            submitted: false,
            loading: false,
            rateLimitReached: false,
        };
    },

    mounted() {
        this.to = this.user?.phone;
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
        }),

        errorMessage() {
            if (this.rateLimitReached && this.invalid) {
                return this.$t('rateLimitError');
            }

            if (this.invalid) {
                return this.$t('invalidPhoneError');
            }

            return '';
        },

        title() {
            if (this.source === 'Broadcasts') {
                return this.$t('broadcastTitle');
            }

            return this.$t('title');
        },
    },

    methods: {
        reset() {
            this.submitted = false;
            this.invalid = false;
            this.loading = false;
            this.rateLimitReached = false;
        },

        close() {
            this.$emit('close');

            this.to = this.user?.phone;
            this.reset();
        },

        async sendTest() {
            this.submitted = true;

            if (isValidPhoneNumber(this.to)) {
                this.loading = true;

                try {
                    await sendTestMessageMutation({
                        from: this.from,
                        to: this.to,
                        message: this.message.replace(/\[\[contact.first_name\]\]/g, this.user.givenName || ''),
                    });


                    let successMessage = this.$t('success', { to: this.to });

                    if (this.source === 'Broadcasts') {
                        successMessage = this.$t('broadcastSuccess', { to: this.to });
                    }

                    this.$toast({ message: successMessage });

                    this.$emit('close');
                } catch (error) {
                    if (
                        Array.isArray(error?.graphQLErrors)
                        && error?.graphQLErrors[0]?.extensions?.code === TOO_MANY_REQUESTS
                    ) {
                        this.rateLimitReached = true;
                        this.invalid = true;
                    } else {
                        this.$toast({ message: this.$t('error') });
                    }
                }

                this.loading = false;
            } else {
                this.invalid = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .actions {
        display: flex;
        justify-content: flex-end;
    }

    .button-space {
        @include margin-start($gp);
    }

    .phone-input {
        padding-top: $gp;
        margin: 0;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Send a test",
        "broadcastTitle": "Send a test broadcast",
        "inputTitle": "Phone Number",
        "broadcastSuccess": "Test broadcast sent to {to}",
        "success": "Test sent to {to}",
        "error": "Error sending test message",
        "invalidPhoneError": "Please enter a valid phone number",
        "rateLimitError": "You’ve exceeded your 30 free \"send a test\" messages for today."
    }
}
</i18n>
