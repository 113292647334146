const STYLES_BY_CLASS_NAME = {
    'ql-align-center': { name: 'textAlign', value: 'center' },
    'ql-align-right': { name: 'textAlign', value: 'right' },
    'ql-font-monospace': { name: 'fontFamily', value: 'monospace' },
    'ql-font-serif': { name: 'fontFamily', value: 'serif' },
    'ql-size-huge': { name: 'fontSize', value: '32px' },
    'ql-size-large': { name: 'fontSize', value: '18px' },
    'ql-size-small': { name: 'fontSize', value: '10px' },
};

const normalizeNode = (className, element) => {
    const style = STYLES_BY_CLASS_NAME[className];

    if (style != null && element) {
        // eslint-disable-next-line no-param-reassign
        element.style[style.name] = style.value;
        element.classList.remove(className);

        if (element.classList.length === 0) {
            element.removeAttribute('class');
        }
    }
};

const normalizeClassName = (className, container) => {
    const elements = container.querySelectorAll(`.${className}`);

    if (elements != null) {
        Array.from(elements).forEach((element) => normalizeNode(className, element));
    }
};

export const normalizeHtml = (html) => {
    const container = document.createElement('div');

    container.innerHTML = html;
    Object.keys(STYLES_BY_CLASS_NAME).forEach((key) => {
        normalizeClassName(key, container);
    });

    const quillEditor = container.querySelector('.ql-editor');

    return quillEditor ? quillEditor.innerHTML : container.innerHTML;
};

const walkDOM = (node, callback) => {
    const children = node.childNodes;

    children.forEach((child) => {
        walkDOM(child, callback);
    });
    callback(node);
};

const quillConversion = (element) => {
    Object.entries(STYLES_BY_CLASS_NAME).forEach(([className, style]) => {
        if (element.style && element.style[style.name] === style.value) {
            element.style[style.name] = null;

            if (element.style.length === 0) {
                element.removeAttribute('style');
            }
            element.classList.add(className);
        }
    });
};

export const applyQuillClasses = (html) => {
    const container = document.createElement('div');

    container.innerHTML = html;

    walkDOM(container, quillConversion);

    return container.innerHTML;
};
