<template>
    <div class="publish-page-info">
        <slot name="title" />

        <h3 v-if="title && !$slots.title" data-qa="title">
            {{ title }}
        </h3>

        <p v-if="description" data-qa="description">
            {{ description }}
        </p>

        <div v-if="$slots.default" class="slot">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        description: String,
        image: String,
    },
};
</script>

<style lang="scss" scoped>
    .publish-page-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    h3 {
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        max-width: $form-width;
        font-size: $font-size-jumbo;
        color: $color-gray-900;
    }

    p {
        font-size: $font-size-med;
        color: $color-gray-900;
        margin: $gp * 1.5 0 0;
        max-width: px-to-rem(565px);
    }

    .slot {
        margin-top: $gp * 1.5;
    }
</style>
