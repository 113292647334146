export default {
    data() {
        return {
            filteredContacts_search: '',
        };
    },

    computed: {
        filteredContacts_list() {
            return this.contactList && this.contactList
                .filter(({
                    fullName,
                    givenName,
                    familyName,
                    email,
                }) => {
                    if (!this.filteredContacts_search) {
                        return true;
                    }

                    const search = this.filteredContacts_search.toLowerCase();

                    const hasGivenName = givenName && givenName.toLowerCase().includes(search);
                    const hasFamilyName = familyName && familyName.toLowerCase().includes(search);
                    const hasFullName = (fullName && fullName.toLowerCase().includes(search))
                        || (givenName && familyName && `${givenName.toLowerCase()} ${familyName.toLowerCase()}`.includes(search));
                    const hasEmail = email && email.toLowerCase().includes(search);

                    return hasGivenName || hasFamilyName || hasFullName || hasEmail;
                })
                .map((contact) => {
                    contact.givenName = contact.givenName || '';
                    contact.familyName = contact.familyName || '';

                    return contact;
                });
        },
    },
};
