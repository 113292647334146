<script>
import { mapGetters, mapState } from 'vuex';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import pendo from '@/analytics/pendo';
import sentry from '@/analytics/sentry';
import fullstory from '@/analytics/fullstory';

export default {
    data() {
        return {
            setupComplete: false,
        };
    },

    mounted() {
        this.init();
    },

    watch: {
        $route() {
            this.handleRoute(this.$route);
        },
    },

    computed: {
        ...mapState({
            account: ({ auth }) => auth.account,
            authToken: ({ auth }) => auth.session.jwt,
            isMobileApp: ({ global }) => global.isMobileApp,
            user: ({ auth }) => auth.user,
            featureFlags: ({ featureFlags }) => featureFlags,
        }),

        ...mapGetters({
            isPaid: 'auth/isPaid',
            isPartner: 'auth/isPartner',
            isReferredByPartner: 'auth/referredByPartner',
            totalContacts: 'contacts/totalContacts',
        }),

        analyticsConfigData() {
            const {
                account,
                user,
                totalContacts,
                authToken,
                isPaid,
                isPartner,
                isReferredByPartner,
                domain,
                featureFlags,
            } = this;

            return {
                id: user?.id,
                userType: user?.userType,
                email: user?.email,
                phone: user?.phone,
                casId: user?.casId,
                casUsername: user?.casUsername,
                partner: user?.partner,
                givenName: user?.givenName,
                familyName: user?.familyName,
                fullName: user?.fullName,
                local: user?.local,
                buildNumber: account?.buildNumber,
                appName: account?.appName,
                editionName: account?.editionName,
                blockInfoRole: account?.blockInfoRole,
                trial: account?.trial,
                isSpaApp: account?.isSpaApp,
                appVersion: account?.isSpaApp ? 'new' : 'classic',
                businessType: account?.companyProfile?.businessType,
                companyName: account?.companyProfile?.companyName,
                goals: account?.companyProfile?.goals,
                totalContacts,
                authToken,
                isPaid,
                isPartner,
                isReferredByPartner,
                domain,
                featureFlags: featureFlags && Object.keys(featureFlags).filter((key) => featureFlags[key] === true),
            };
        },

        isLoggedIn() {
            return Boolean(this.user?.casId);
        },

        domain() {
            return this.user?.email?.split('@')[1];
        },
    },

    methods: {
        init() {
            if (!this.isLoggedIn) return;

            sentry.setUser(this.analyticsConfigData);
            amplitude.v1.setUserProperties(this.analyticsConfigData);
            amplitude.v2.setup(this.analyticsConfigData);
            pendo.setup(this.analyticsConfigData);

            this.initFullstory();

            if (!this.isMobileApp) {
                intercom.attachScripts();
                intercom.setup(this.analyticsConfigData);
            }

            this.setupComplete = true;

            this.handleRoute(this.$route);
        },

        handleRoute(route) {
            if (!this.setupComplete || this.isMobileApp || !this.isLoggedIn) return;

            intercom.showChat();

            intercom.updatePage({
                userId: this.user?.casId,
                page: route.name,
            });
        },

        initFullstory() {
            if (!this.isLoggedIn) return;

            fullstory.identify(this.user.casId, {
                email: this.user?.email,
                appName: this.account?.appName,
                firstName: this.user?.givenName,
                lastName: this.user?.familyName,
                environment: process.env.VUE_APP_ENV,
            });
        },
    },

    render() {
        return null;
    },
};
</script>
