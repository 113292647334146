<template>
    <ds-dropdown
        class="custom-fields-drop-down"
        :is-open="isDropDownOpen"
        @close="closeDropdown"
    >
        <ds-text-button
            data-qa="add-custom-field"
            leading-icon="add"
            @click="onAddClick"
        >
            {{ addText }}
        </ds-text-button>

        <template #menu>
            <div class="existing-fields">
                <ds-list-item
                    v-for="(field, index) in emptyFields"
                    :key="index"
                    data-qa="existing-field"
                    clickable
                    :title="field.label"
                    :description="getField(field)"
                    border-style="none"
                    @click="add(field)"
                />
            </div>
        </template>

        <template #footer>
            <ds-text-button
                data-qa="create-custom-field"
                class="create-custom-field"
                @click="create"
            >
                {{ createFieldText }}
            </ds-text-button>
        </template>
    </ds-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';
import contactFieldTypes from '../details/contactFieldTypes';


export default {

    props: {
        emptyFields: Array,
        createFieldText: String,
        addText: String,
    },

    data() {
        return {
            isDropDownOpen: false,
        };
    },

    computed: {
        ...mapGetters({
            customFieldCount: 'contacts/customFieldCount',
            customFieldMax: 'settings/maxCustomFields',
        }),

        customFieldsAvailable() {
            return this.customFieldMax - this.customFieldCount;
        },
    },

    methods: {
        add(field) {
            this.$emit('add', field);
            this.isDropDownOpen = false;
        },

        getField(field) {
            if (field.fieldType === contactFieldTypes.CUSTOM_FIELD_NAMES.MULTISELECT
                || field.fieldType === contactFieldTypes.CUSTOM_FIELD_NAMES.CHECKBOXES) {
                return 'Checkboxes';
            }

            return field.fieldType;
        },

        closeDropdown() {
            this.isDropDownOpen = false;
        },

        create() {
            this.$emit('create');
            this.isDropDownOpen = false;
        },

        onAddClick() {
            if (!this.emptyFields.length) {
                this.$emit('create');
            } else {
                this.isDropDownOpen = true;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .create-custom-field {
        display: block;
        margin: 0;
        text-align: center;
        width: 100%;
    }

    .custom-fields-drop-down {
        --dropdown-height: max(50vh, #{$dropdown-maxheight});
    }

    @media(min-width: 1360px) {
        .existing-fields {
            max-width: 26vw;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "customFields": {
            "loadError": "Something went wrong and we couldn't load this contact's custom fields. Please try again."
        },
        "fieldsRemaining": "{remaining}/{max} remaining",
        "existingFieldsTitle": "Fields",
        "editCustomField": "Edit custom field",
        "removeCustomField": "Delete from {contactFirstName}"
    }
}
</i18n>
