<script>
import AddCompany from '@/contacts/components/companies/AddCompany';

export default {
    props: {
        eventSource: String,
    },

    mounted() {
        this.$bus.$emit('POP_NESTED_MODAL');

        this.$bus.$emit('PUSH_NESTED_MODAL', {
            component: AddCompany,
            showRootClose: true,
            interceptClose: this.onClose,
            props: {
                eventSource: this.eventSource,
            },
        });
    },

    methods: {
        onClose() {
            this.$bus.$emit('POP_NESTED_MODAL');
            this.$emit('close');
        },
    },

    render() {
        return null;
    },
};
</script>
