import TextDownloadLinkForm from '@/communication/components/TextDownloadLinkForm';

import { MULTIPLE_PIPELINES_FEATURE } from '@/shared/constants/features.constants';
import { FORM_AUDIENCE_PUBLIC } from '@/customForms/customForm.constants';
import { NEW_APPOINTMENT_TYPE_ROUTE_NAME } from '@/appointments/appointments.constants';

import broadcastVideoPlaceholder from '../images/broadcast_video.png';
import contactRecordVideoPlaceholder from '../images/contact_record_video.png';
import googleReviewsVideoPlaceholder from '../images/google_reviews_video.png';
import contactImportVideoPlaceholder from '../images/import_contacts_video.png';
import leadFormVideoPlaceholder from '../images/lead_form_video.png';
import emailTemplatesVideoPlaceholder from '../images/email_templates_video.png';
import tagsVideoPlaceholder from '../images/tags_video.png';

export const SETUP_LEAD_FORM = {
    id: 'setupLeadForm',
    durationMins: 1,
    inlineBoldText: ['inlineBoldText1'],
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/xip29508vm?autoPlay=true',
    helpVideoPlaceholder: leadFormVideoPlaceholder,
    type: 'collectLeads',
};

export const SETUP_LEAD_FORM_NO_CONTACTS = {
    id: 'setupLeadFormNoContacts',
    durationMins: 1,
    showDescription2: true,
    inlineBoldText: ['inlineBoldText1'],
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/xip29508vm?autoPlay=true',
    helpVideoPlaceholder: leadFormVideoPlaceholder,
    type: 'collectLeads',
};

export const SETUP_PUBLIC_LEAD_FORM_COLLECTING_WEBSITE = {
    id: 'setupPublicLeadFormIsCollectingWebsite',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/public-forms',
    showDescription2: true,
    showDescription3: true,
    description3Style: 'italic',
    inlineBoldText: ['inlineBoldText1'],
    type: 'collectLeads',
};

export const SETUP_PUBLIC_LEAD_FORM_COLLECTING_ONLINE = {
    id: 'setupPublicLeadFormIsCollectingOnline',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/public-forms',
    showDescription2: true,
    showDescription3: true,
    description3Style: 'italic',
    inlineBoldText: ['inlineBoldText1'],
    type: 'collectLeads',
};

export const SCAN_BUSINESS_CARD = {
    id: 'scanBusinessCard',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/mobile-app-adding-contacts#scan-a-business-card',
    showDescription2: true,
    callToActionComponent: TextDownloadLinkForm,
    inlineBoldText: ['inlineBoldText1'],
};

export const SETUP_PUBLIC_LEAD_FORM_NOT_COLLECTING = {
    id: 'setupPublicLeadFormNotCollecting',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/public-forms',
    showDescription2: true,
    showDescription3: true,
    description3Style: 'italic',
    inlineBoldText: ['inlineBoldText1'],
    type: 'collectLeads',
};

export const SETUP_PUBLIC_LEAD_FORM_OTHER = {
    id: 'setupPublicLeadFormOther',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/public-forms',
    showDescription2: true,
    showDescription3: true,
    description3Style: 'italic',
    inlineBoldText: ['inlineBoldText1'],
    type: 'collectLeads',
};

export const CREATE_APPOINTMENT_TYPE = {
    id: 'createAppointmentType',
    durationMins: 3,
    helpArticleUrl: 'https://help.keap.com/help/appointments-setting-up-the-appointment-booking-page',
    type: 'createAppointmentType',
};

export const CREATE_APPOINTMENT_TYPE_CONSULTATION = {
    id: 'createAppointmentTypeConsultation',
    durationMins: 3,
    helpArticleUrl: 'https://help.keap.com/help/appointments-setting-up-the-appointment-booking-page',
    type: 'createAppointmentType',
};

export const CREATE_APPOINTMENT_TYPE_FOLLOW_UP = {
    id: 'createAppointmentTypeFollowUp',
    durationMins: 3,
    helpArticleUrl: 'https://help.keap.com/help/appointments-setting-up-the-appointment-booking-page',
    type: 'createAppointmentType',
};

export const TOUR_APPOINTMENTS = {
    id: 'tourAppointments',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/appointments-page',
    showDescription2: true,
    inlineBoldText: ['inlineBoldText1'],
};

export const TOUR_APPOINTMENTS_HAS_TOOL = {
    id: 'tourAppointmentsHasTool',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/appointments-page',
    showDescription2: true,
    inlineBoldText: ['inlineBoldText1'],
};

export const CREATE_APPOINTMENT_TYPE_NEEDS_TOOL = {
    id: 'createAppointmentTypeNeedsTool',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/appointments-page',
    showDescription2: true,
    inlineBoldText: ['inlineBoldText1'],
};

export const CREATE_APPOINTMENT_TYPE_NONE = {
    id: 'createAppointmentTypeNone',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/appointments-page',
};

export const QUICK_FOLLOW_UP_LEADS_AUTOMATION = {
    id: 'quickFollowUpLeadsAutomation',
    durationMins: 4,
    helpArticleUrl: 'https://help.keap.com/help/easy-automations',
    showDescription2: false,
    showDescriptionList: true,
    descriptionListHelpLinks: [
        { routeName: 'customForms.new', params: { audience: FORM_AUDIENCE_PUBLIC.toLowerCase() } },
    ],
};

export const KEEP_LEADS_ENGAGED_AUTOMATION = {
    id: 'keepLeadsEngagedAutomation',
    durationMins: 10,
    helpArticleUrl: 'https://help.keap.com/help/easy-automations',
    showDescription2: false,
    showDescriptionList: true,
    descriptionListHelpLinks: [
        { routeName: 'customForms.new', params: { audience: FORM_AUDIENCE_PUBLIC.toLowerCase() } },
        { routeName: 'settings.tags' },
    ],
};

export const SCHEDULE_CONSULTATION_LEADS_AUTOMATION = {
    id: 'scheduleConsultationLeadsAutomation',
    durationMins: 8,
    helpArticleUrl: 'https://help.keap.com/help/easy-automations',
    showDescription2: false,
    showDescriptionList: true,
    descriptionListHelpLinks: [
        { routeName: 'customForms.new', params: { audience: FORM_AUDIENCE_PUBLIC.toLowerCase() } },
        { routeName: 'calendar' },
        { routeName: NEW_APPOINTMENT_TYPE_ROUTE_NAME },
    ],
};

export const REQUEST_REVIEWS_AUTOMATION = {
    id: 'requestReviewsAutomation',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/easy-automations',
    showDescription2: false,
    showDescriptionList: true,
    descriptionListHelpLinks: [
        { routeName: 'settings.products' },
        { routeName: 'invoices' },
    ],
};

export const SEND_REFERRAL_EMAIL = {
    id: 'sendReferralEmail',
    durationMins: 4,
    helpArticleUrl: 'https://help.keap.com/help/email-templates',
    showDescription2: true,
};

export const ADD_CONTACT = {
    id: 'addContact',
    durationMins: 1,
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/mp8fhwqijj?autoPlay=true',
    helpVideoPlaceholder: contactRecordVideoPlaceholder,
    type: 'addContact',
};

export const ADD_CONTACT_HAS_CONTACTS = {
    id: 'addContactHasContacts',
    durationMins: 1,
    showDescription2: true,
    inlineBoldText: ['inlineBoldText1'],
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/mp8fhwqijj?autoPlay=true',
    helpVideoPlaceholder: contactRecordVideoPlaceholder,
};

export const IMPORT_CONTACTS = {
    id: 'importContacts',
    durationMins: 8,
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/p5kh52v9v9?autoPlay=true',
    helpVideoPlaceholder: contactImportVideoPlaceholder,
    inlineHelpLinks: ['https://keap.com/resources/maximize-your-email-deliverability'],
    inlineHelpLinkTarget: '_blank',
};

export const ADD_TAG = {
    id: 'addTag',
    durationMins: 2,
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/o7cq9nbzhx?autoPlay=true',
    helpVideoPlaceholder: tagsVideoPlaceholder,
};

export const CONNECT_TOOLS = {
    id: 'connectTools',
    durationMins: 2,
    type: 'connectTools',
};

export const ADD_TASK = {
    id: 'addTask',
    durationMins: 1,
    helpArticleUrl: 'https://help.keap.com/help/add-tasks',
};

export const CREATE_PIPELINE = {
    id: 'createPipeline',
    durationMins: 3,
    helpArticleUrl: 'https://help.keap.com/help/pipeline-multiple-pipelines',
    requiredFeature: MULTIPLE_PIPELINES_FEATURE,
};

export const EDIT_EMAIL_TEMPLATE = {
    id: 'editEmailTemplate',
    durationMins: 4,
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/tfim44hfg5?autoPlay=true',
    helpVideoPlaceholder: emailTemplatesVideoPlaceholder,
};

export const DOWNLOAD_MOBILE_APP = {
    id: 'downloadMobileApp',
    durationMins: 4,
    callToActionComponent: TextDownloadLinkForm,
};

export const SEND_BROADCAST = {
    id: 'sendBroadcast',
    durationMins: 5,
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/taoass4fes?autoPlay=true',
    helpVideoPlaceholder: broadcastVideoPlaceholder,
};

export const SEND_UNLAYER_BROADCAST = {
    id: 'sendBroadcast',
    durationMins: 5,
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/xvmqkyy2bq?autoPlay=true',
    helpVideoPlaceholder: broadcastVideoPlaceholder,
};

export const SETUP_GOOGLE_REVIEWS = {
    id: 'setupGoogleReviews',
    durationMins: 2,
    helpVideoUrl: 'https://keap.wistia.com/embed/medias/1reb5ucmmk?autoPlay=true',
    helpVideoPlaceholder: googleReviewsVideoPlaceholder,
};

export const SEND_TEST_INVOICE = {
    id: 'sendTestInvoice',
    durationMins: 4,
    helpArticleUrl: 'https://help.keap.com/help/invoices',
};

export const ADD_PRODUCTS = {
    id: 'addProducts',
    durationMins: 3,
    helpArticleUrl: 'https://help.keap.com/help/add-products',
};

export const CONNECT_PAYMENT_PROCESSOR = {
    id: 'connectPaymentProcessor',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/merchant-accounts',
};

export const CONNECT_QBO = {
    id: 'connectQbo',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/quickbooks-online-invoice-sync',
};

export const REVIEW_MY_PROFILE = {
    id: 'myProfile',
    durationMins: 2,
    inlineHelpLinks: ['https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business'],
    inlineHelpLinkTarget: '_blank',
    helpArticleUrl: 'https://help.keap.com/help/my-profile',
};

export const REVIEW_MY_BUSINESS_PROFILE = {
    id: 'businessProfile',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/business-profile',
};

export const SETUP_DKIM = {
    id: 'setupDKIM',
    durationMins: 2,
    helpArticleUrl: 'https://help.keap.com/help/dkim',
};

export const APPOINTMENT_INVITE_EASY_AUTOMATION = {
    id: 'appointmentInviteEasyAutomation',
    durationMins: 5,
    inlineHelpLinks: [
        { taskType: 'collectLeads' },
        { taskType: 'createAppointmentType' },
    ],
    inlineHelpLinkTarget: '',
    helpArticleUrl: 'https://help.keap.com/help/easy-automations',
    showDescription2: true,
    showDescriptionList: true,
    descriptionListStyle: 'semi-bold',
    buttonLoading: true,
};
