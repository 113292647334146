<template>
    <div class="automation-type-section">
        <div class="subtle section-title">
            {{ $t(section.title) }}
        </div>

        <hr />

        <div class="cards-wrapper">
            <div class="trigger-cards">
                <automation-type-card
                    v-for="automationItem in section.automationItems"
                    :key="automationItem.type"
                    :automation-item="automationItem"
                    :data-qa="`${automationType}-type_${automationItem.type}`"
                    :class="`${automationType}-card`"
                    @selected="$emit('selected', automationItem)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AutomationTypeCard from './AutomationTypeCard';

export default {
    components: {
        AutomationTypeCard,
    },

    props: {
        section: {
            type: Object,
            required: true,
        },
        automationType: {
            type: String,
            required: true,
            options: ['step', 'trigger', 'stop'],
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/setup';

    .automation-type-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: $gp * 1.5;
    }

    .section-title {
        align-self: flex-start;
    }

    hr {
        margin: $gp / 2 0 $gp * 2;
    }
</style>
