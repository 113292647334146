<template lang="html">
    <section class="recipient-search">
        <header class="search-header">
            <h4>{{ $t('to') }}</h4>

            <input
                ref="searchInput"
                v-model="query"
                class="search"
                :placeholder="$t('placeholder')"
                @input="getSmsRecipients"
                @keydown.down.prevent="handleDown"
                @keydown.up.prevent="handleUp"
                @keydown.enter.prevent.stop="handleEnter"
                @keydown.esc.prevent="handleEsc"
            />
        </header>

        <footer
            v-if="showResults"
            class="search-footer"
        >
            <div v-if="loading" class="loader">
                <ds-spinner />
            </div>

            <div v-else-if="hasResults">
                <ul
                    v-for="(result, i) in results"
                    :key="i"
                    class="search-results"
                >
                    <li v-if="hasPhoneNumbers(result)">
                        <span
                            v-for="(additionalItem, j) in result.additionalInfo"
                            :key="j"
                            class="search-result"
                            :class="{ current: isCurrentPointer(i, j) }"
                            @click="setRecipient(result, additionalItem)"
                        >
                            <contact-avatar
                                class="contact-avatar"
                                :name="result.label"
                            />

                            <span class="contact-info">
                                <ds-text-highlighter
                                    class="contact-name"
                                    :content="result.label"
                                    :highlight="query"
                                />

                                <ds-text-highlighter
                                    class="phone-number fs-block"
                                    :content="getAdditionalInfo(additionalItem)"
                                    :highlight="getMatchingPhone(additionalItem)"
                                />
                            </span>
                        </span>
                    </li>

                    <li v-else>
                        <span class="search-result missing-number">
                            <contact-avatar
                                class="contact-avatar"
                                :name="result.label"
                            />

                            <span class="contact-info">
                                <span class="contact-name">
                                    {{ result.label }}
                                </span>

                                <span class="phone-number">
                                    {{ $t('noNumber') }}
                                </span>
                            </span>
                        </span>
                    </li>
                </ul>
            </div>

            <ul v-else>
                <li
                    v-if="queryIsValidNumber"
                    class="unknown-contact"
                    @click="selectNewNumber"
                >
                    <contact-avatar />

                    <div class="contact-info">
                        <span class="contact-name">{{ $t('sendMessageTo') }}</span>

                        <span class="new-number">
                            {{ query }}
                        </span>
                    </div>
                </li>

                <li v-else class="no-results">
                    {{ $t('noResults', { query }) }}

                    <ds-text-button v-if="!hideAddContact" class="add-contact-button" @click="createNewContact">
                        {{ $t('newContact') }}
                    </ds-text-button>
                </li>
            </ul>
        </footer>
    </section>
</template>

<script>
import debounce from 'lodash.debounce';

import ContactAvatar from '@/shared/components/ContactAvatar';

import smsMixins from '@/communication/mixins/sms.mixin';
import { SEARCHING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

export default {
    components: {
        ContactAvatar,
    },

    mixins: [smsMixins],

    props: {
        delay: {
            type: Number,
            default: SEARCHING_DEBOUNCE_DELAY,
        },
        hideAddContact: Boolean,
        preventRedirect: Boolean,
    },

    data() {
        return {
            limit: 20,
            loading: false,
            offset: 0,
            query: '',
            results: [],
            pointer: {
                contactPointer: -1,
                phonePointer: -1,
            },
        };
    },

    created() /* istanbul ignore next */ {
        if (this.delay > 0) {
            this.getSmsRecipients = debounce(this.getSmsRecipients, this.delay);
        }
    },

    mounted() /* istanbul ignore next */ {
        if (this.$refs.searchInput) {
            this.$refs.searchInput.focus();
        }
    },

    computed: {
        showResults() {
            return this.query.length > 0;
        },

        hasResults() {
            return this.results.length > 0;
        },

        queryIsValidNumber() {
            const phoneNumber = this.query.replace(/\D/g, '');

            return phoneNumber.length >= 10 && phoneNumber.length <= 11;
        },
    },

    methods: {
        async getSmsRecipients() {
            this.loading = true;

            const { limit, offset } = this;
            const query = this.query.replace(/[^a-zA-Z0-9 ]/g, '');

            if (query && query.length > 0) {
                this.results = await this.$store.dispatch('communication/SMS_RECIPIENT_SEARCH', { query, limit, offset });
                this.resetPointers();
            }

            this.loading = false;
        },

        setRecipient({ id, label }, { itemValue }) {
            this.query = '';

            const [firstName, lastName] = label.split(' ');

            if (!this.preventRedirect) {
                this.sms_setRecipient(itemValue);
            }
            this.$emit('selected', {
                contactId: id,
                firstName,
                lastName,
                phoneNumber: itemValue,
            });
            this.$track('Communication - SMS - Selected contact for new conversation');
        },

        selectNewNumber() {
            this.$bus.$emit('CONVERSATION_SELECTED');
            this.$emit('selected', { phoneNumber: this.query });
            this.$track('Communication - SMS - Selected non-contact for new conversation');
        },

        createNewContact() {
            this.sms_createNewContact(this.query);
            this.query = '';
        },

        hasPhoneNumbers({ additionalInfo }) {
            return additionalInfo.length > 0;
        },

        getAdditionalInfo(additionalItem) {
            const { itemValue, itemType } = additionalItem;

            if (!itemType || itemType.length <= 0) {
                return itemValue;
            }

            return `${itemValue} - ${itemType.charAt(0).toUpperCase() + itemType.substr(1).toLowerCase()}`;
        },

        getMatchingPhone({ itemValue }) {
            const chars = this.query.replace(/\D/g, '').split('');

            const nonDigits = '\\D*';
            const regex = new RegExp(nonDigits + chars.join(nonDigits) + nonDigits, 'g');

            const matches = itemValue.match(regex);

            let match = '';

            // Remove matches with only non-digits (like leading parenthesis)
            if (
                Array.isArray(matches)
                && matches.length > 0
                && matches[0].replace(/\D/g, '') !== ''
            ) {
                [match] = matches;
            }

            return match;
        },

        /**
         * KEYBOARD NAVIGATION
         */

        resetPointers() {
            this.pointer.contactPointer = -1;
            this.pointer.phonePointer = -1;
        },

        isCurrentPointer(contactIndex, phoneIndex) {
            const { contactPointer, phonePointer } = this.pointer;

            return contactPointer === contactIndex && phonePointer === phoneIndex;
        },

        handleDown() {
            const { contactPointer, phonePointer } = this.pointer;

            if (Array.isArray(this.results[contactPointer]?.additionalInfo) && this.results[contactPointer].additionalInfo[phonePointer + 1]) {
                this.pointer.phonePointer += 1;
            } else if (this.results[contactPointer + 1] && this.hasPhoneNumbers(this.results[contactPointer + 1])) {
                this.pointer.contactPointer += 1;
                this.pointer.phonePointer = 0;
            }
        },

        handleUp() {
            const { contactPointer, phonePointer } = this.pointer;

            if (Array.isArray(this.results[contactPointer]?.additionalInfo) && this.results[contactPointer].additionalInfo[phonePointer - 1]) {
                this.pointer.phonePointer -= 1;
            } else if (this.results[contactPointer - 1]) {
                this.pointer.contactPointer -= 1;
                this.pointer.phonePointer = this.results[contactPointer - 1].additionalInfo.length - 1;
            }
        },

        handleEnter() {
            const { contactPointer, phonePointer } = this.pointer;

            if (Array.isArray(this.results[contactPointer]?.additionalInfo) && this.results[contactPointer].additionalInfo[phonePointer]) {
                this.setRecipient(
                    this.results[contactPointer],
                    this.results[contactPointer].additionalInfo[phonePointer],
                );
            } else if (this.queryIsValidNumber) {
                this.selectNewNumber();
            }
        },

        handleEsc() {
            this.query = '';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .recipient-search {
        height: 100%;
    }

    .search-header {
        display: flex;
        align-items: center;
        justify-self: flex-start;
        padding: $gp;
        border-bottom: 1px solid $color-gray-200;
        color: $color-gray-700;
    }

    .search {
        border: none;
        margin: 0 $gp / 2;
        font-size: $font-size-lg;
        height: px-to-rem(25px);
        width: $gp * 20;
    }

    .search-footer {
        height: 100%;
        overflow-y: auto;
    }

    .search-results {
        display: flex;
        flex-direction: column;
    }

    .search-result {
        display: flex;
        flex-direction: row;
        padding: $gp;
        cursor: pointer;

        .contact-name,
        .contact-avatar {
            display: none;
        }

        .phone-number {
            margin: $gp / 2 $gp * 2.5;
        }

        &:first-child .contact-name,
        &:first-child .contact-avatar {
            display: flex;
        }

        &:first-child .phone-number {
            margin: 0;
        }

        &.current,
        &:hover {
            background-color: $color-gray-100;
        }
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        margin: 0 $gp;
    }

    .contact-name {
        font-size: $font-size-med;
        margin-bottom: $gp / 4;
    }

    .phone-number {
        color: $color-text-subtle;
    }

    .no-number,
    .new-number {
        color: $color-gray-700;
    }

    .missing-number {
        color: $color-gray-600;

        .avatar {
            background-color: $color-gray-600;
        }

        .phone-number {
            color: $color-gray-600;
        }
    }

    .missing-number:hover {
        cursor: default;
    }

    .unknown-contact {
        display: flex;
        flex-direction: row;
        padding: $gp;
        border-radius: $gp / 4;
        cursor: pointer;

        &:hover {
            background-color: $color-gray-100;
        }
    }

    .no-results {
        display: flex;
        flex-direction: column;
        padding: $gp;
        color: $color-gray-700;
        font-style: italic;
    }

    .add-contact-button {
        align-self: flex-start;
        margin: $gp;
    }

    .loader {
        padding: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "to": "To:",
        "newContact": "Add new contact",
        "noNumber": "No phone number",
        "noResults": "No contacts found that match the search: \"{query}\".",
        "sendMessageTo": "Send message to:",
        "placeholder": "Type a name or phone number..."
    }
}
</i18n>
