import axios from 'axios';
import jwtDecode from 'jwt-decode';
import gql from 'graphql-tag';
import Vue from 'vue';
import {
    addQueryParamToUrlString,
    getQueryParamValue,
} from '@/shared/utils/query-param.util';
import intercom from '@/analytics/intercom';

export default {
    LOGIN(context) {
        return login(context);
    },

    LOGOUT({ commit }) {
        commit('SET_LOGOUT');
    },

    GET_ACCOUNTS(context, payload) {
        return getAccounts(context, payload);
    },

    ADD_USER(context, payload) {
        return addUser(context, payload);
    },

    ADD_PARTNER(context, payload) {
        return addPartner(context, payload);
    },

    RESEND_INVITE(context, payload) {
        return resendInvite(context, payload);
    },

    DEACTIVATE_ACCOUNT(context, payload) {
        return deactivateAccount(context, payload);
    },

    CANCEL_USER_INVITATION(context, payload) {
        return cancelUserInvitation(context, payload);
    },

    REACTIVATE_USER(context, payload) {
        return reactivateUser(context, payload);
    },

    UPDATE_USER_PROFILE(context, payload) {
        return updateUserProfile(context, payload);
    },

    SET_USER_PROFILE_PHOTO(context, payload) {
        return updateUserProfilePhoto(context, payload);
    },

    REMOVE_USER_PROFILE_PHOTO(context, payload) {
        return removeUserProfilePhoto(context, payload);
    },

    SAVE_COMPANY_LOGO(context, payload) {
        return saveCompanyLogo(context, payload);
    },

    DELETE_COMPANY_LOGO(context, payload) {
        return deleteCompanyLogo(context, payload);
    },

    SAVE_COMPANY_PROFILE(context) {
        return saveCompanyProfile(context);
    },

    AUTHORIZE_PROVIDER(context, payload) {
        return authorizeProvider(context, payload);
    },

    UPDATE_BUSINESS_INFO(context, payload) {
        return updateBusinessInfo(context, payload);
    },

    IS_CREDENTIALS_SERVICE_ALIVE(context) {
        return isCredentialsServiceAlive(context);
    },
};

const login = async ({
    state,
    commit,
    rootState,
    dispatch,
}) => {
    if (process.env.VUE_APP_APP_ID) {
        if (!state.session.coreAppId) {
            commit('SET_APP_LOADED', false, { root: true });
        }

        commit('SET_CORE_APP_ID', process.env.VUE_APP_APP_ID);

        return;
    }

    let appIdIntention = getQueryParamValue('app_id', window.location.search);

    if (!appIdIntention) {
        const { casRedirectUrlConfig } = rootState.auth.session;

        if (casRedirectUrlConfig && casRedirectUrlConfig.query && casRedirectUrlConfig.query.app_id) {
            appIdIntention = casRedirectUrlConfig.query.app_id;
        }
    }

    if (appIdIntention && appIdIntention !== state.session.coreAppId) {
        commit('SET_CORE_APP_ID', appIdIntention);
        commit('SET_APP_LOADED', false, { root: true });

        if (!state.session.logInAsSession) {
            await dispatch('GET_ACCOUNTS');
        }

        return;
    }

    if (state.session.coreAppId) {
        await dispatch('GET_ACCOUNTS');

        return;
    }

    if (state.session.jwt) {
        const { email } = jwtDecode(state.session.jwt);

        if (state.lastSession.coreAppId && email === state.lastSession.email) {
            commit('SET_CORE_APP_ID', state.lastSession.coreAppId);
            commit('SET_APP_LOADED', false, { root: true });

            await dispatch('GET_ACCOUNTS');

            return;
        }
    }

    const accounts = await dispatch('GET_ACCOUNTS');

    if (accounts && accounts.length > 0) {
        commit('SET_CORE_APP_ID', accounts[0].account.legacyId);
    }

    commit('SET_APP_LOADED', false, { root: true });
};

const getAccounts = async ({ state, commit }, limit = 5) => {
    if (state.accounts.length) {
        return state.accounts;
    }

    let url = `${process.env.VUE_APP_ACCOUNT_API_URL}/v2/userAccount?statuses=Active&userId=current`;

    url = addQueryParamToUrlString(url, {
        key: 'limit',
        value: limit,
    });

    url = addQueryParamToUrlString(url, {
        key: 'source',
        value: window.location.host, // so account-api can tell where the request comes from
    });

    const accountTypeOrder = ['KEAP', 'CRM'];

    accountTypeOrder.forEach((accountType) => {
        url = addQueryParamToUrlString(url, {
            key: 'accountTypes',
            value: accountType,
        });
    });

    const { data: { content: userAccounts } } = await axios.get(url);

    if (Array.isArray(userAccounts) && userAccounts.length) {
        userAccounts.sort((a, b) => {
            const aIndex = accountTypeOrder.indexOf(a.account.accountType);
            const bIndex = accountTypeOrder.indexOf(b.account.accountType);

            if (aIndex === bIndex) {
                return 0;
            }

            return aIndex > bIndex ? 1 : -1;
        });
    } else {
        return [];
    }

    commit('SET_ACCOUNTS_LIST', userAccounts);

    return userAccounts;
};

const addUser = (_, user) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation addUserMutation($user: AddUserInput!) {
                addUser(user: $user)
            }
        `,
        variables: {
            user,
        },
    });
};

const addPartner = (_, partner) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation addUserMutation($partner: AddPartnerInput!) {
                addPartner(partner: $partner)
            }
        `,
        variables: {
            partner,
        },
    });
};

const resendInvite = (_, { userId }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation resendInvite($userId: ID) {
                resendInvite(userId: $userId)
            }
        `,
        variables: {
            userId,
        },
    });
};

const deactivateAccount = (_, disableUserInput) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation disableUser($disableUserInput: DisableUserInput) {
                disableUser(disableUserInput: $disableUserInput)
            }
        `,
        fetchPolicy: 'no-cache',
        variables: {
            disableUserInput,
        },
    });
};

const cancelUserInvitation = (_, { userId }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation cancelInvite($userId: ID) {
                cancelInvite(userId: $userId)
            }
        `,
        fetchPolicy: 'no-cache',
        variables: {
            userId,
        },
    });
};

const reactivateUser = (_, { userId }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation reactivateUser($userId: ID) {
                reactivateUser(userId: $userId)
            }
        `,
        fetchPolicy: 'no-cache',
        variables: {
            userId,
        },
    });
};

const updateUserProfile = ({ state, commit }, { userId, request }) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/users/${userId}/profile`, request)
            .then(() => {
                if (userId === state.user?.id) {
                    commit('UPDATE_USER', request);
                }
                resolve();
            })
            .catch(reject);
    });
};

const updateUserProfilePhoto = ({ state, commit }, { userId, file }) => {
    const { user } = state;
    const { name } = file;
    const defaults = {
        is_public: true,
        file_association: 'USER',
    };

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = function (e) {
            if (e.target.error || !e.target.result) {
                reject(e);
            } else {
                const base64String = e.target.result.split(',').pop();

                axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/users/${userId}/profilePhoto`, {
                    file_name: name,
                    file_data: base64String,
                    ...defaults,
                })
                    .then(({ data: photoUrl }) => {
                        if (userId === user.id) {
                            commit('SET_USER_PROFILE_PHOTO', photoUrl);
                        }
                        resolve();
                    })
                    .catch(reject);
            }
        };

        reader.readAsDataURL(file);
    });
};

const removeUserProfilePhoto = ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/users/${userId}/profilePhoto`)
            .then(() => {
                commit('SET_USER_PROFILE_PHOTO', null);
                resolve();
            })
            .catch(reject);
    });
};

const saveCompanyLogo = ({ commit }, file) => {
    const { name } = file;
    const defaults = {
        is_public: true,
        file_association: 'COMPANY',
    };

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = function (e) {
            if (e.target.error || !e.target.result) {
                reject(e);
            } else {
                const base64String = e.target.result.split(',').pop();

                axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/logos/companyLogo`, {
                    file_name: name,
                    file_data: base64String,
                    contact_id: null,
                    ...defaults,
                })
                    .then(({ data: url }) => {
                        commit('SET_COMPANY_LOGO', url);
                        resolve();
                    })
                    .catch(reject);
            }
        };
        reader.readAsDataURL(file);
    });
};

const deleteCompanyLogo = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/logos/companyLogo`)
            .then(() => {
                commit('SET_COMPANY_LOGO', null);
                resolve();
            })
            .catch(reject);
    });
};

const saveCompanyProfile = ({
    state,
    rootState,
    commit,
    dispatch,
}) => {
    const { companyProfile } = state.account;

    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/settings/companyInfo`, companyProfile)
        .then(() => {
            const { region } = companyProfile;

            if (region) {
                const countryCode = region.substring(0, 2);

                commit('SET_APP_COUNTRY_CODE', countryCode, { root: true });
            }

            dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });

            intercom.updateUserProperties({
                user_id: rootState?.auth?.user?.casId,
                business_type: companyProfile.businessType,
            });
        });
};

const authorizeProvider = (context, { provider, redirectUrl, scope } = {}) => {
    return new Promise((resolve, reject) => {
        if (!provider) {
            reject();
        }

        return axios.get(`${process.env.VUE_APP_CREDENTIALS_API_URL}/authorize/${provider.toLowerCase()}?redirect_uri=${redirectUrl}&scope=${scope}`)
            .then(({ data: { location } }) => {
                resolve(location);
            })
            .catch(reject);
    });
};

const updateBusinessInfo = (context, { companyName, businessType }) => {
    context.commit('SET_COMPANY_NAME', companyName);
    context.commit('SET_BUSINESS_TYPE', businessType);

    return saveCompanyProfile(context);
};

const isCredentialsServiceAlive = () => {
    return axios.get(process.env.VUE_APP_CREDENTIALS_HEALTH_CHECK_URL)
        .then(() => true)
        .catch(() => false);
};
