import debounce from 'lodash.debounce';
import { mapGetters } from 'vuex';

import { SEARCHING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import sentry from '@/analytics/sentry';

const DEFAULT_LOAD_PAYLOAD = {
    limit: 100,
    sortBy: 'fullName',
    sortAscending: true,
};

export default {
    mounted() {
        this.searchContacts_query = debounce(this.searchContacts_query, SEARCHING_DEBOUNCE_DELAY);
    },

    data() {
        return {
            searchContacts_loading: false,
        };
    },

    computed: {
        ...mapGetters({
            searchContacts_results: 'contacts/contactSearchResults',
        }),
    },

    methods: {
        searchContacts_query(value) {
            const term = typeof value === 'string' ? value.trim() : '';

            if (!term) {
                this.$store.commit('contacts/SET_SEARCH_RESULTS');

                return;
            }

            this.searchContacts_loading = true;

            const searchError = this.$t('global.searchFailed');

            this.$store.dispatch('contacts/SEARCH_CONTACTS', term)
                .then(() => {
                    this.searchContacts_loading = false;
                })
                .catch(() => {
                    this.searchContacts_loading = false;
                    this.$error({ message: searchError });
                    this.$log('Contact search failed from search-contacts mixin');
                });
        },

        async searchContacts_loadContactList(payload) {
            try {
                this.searchContacts_loading = true;

                await this.$store.dispatch('contacts/LOAD_CONTACT_LIST', { ...DEFAULT_LOAD_PAYLOAD, ...payload });
                this.searchContacts_loading = false;
            } catch (e) {
                sentry.captureException(e);
            }
        },
    },
};
