<template lang="html">
    <div class="verify-listing">
        <p>
            {{ $t('message.0') }}
            <br />
            <strong>{{ $t('message.1') }}</strong>
        </p>

        <div class="business-card">
            <img :src="`${baseUrl}images/google/google-my-business-logo.png`" />

            <div class="business-details">
                <div class="company-icon">
                    <ds-icon name="company" />
                </div>

                <div>
                    <h6 v-if="businessName" class="semibold">
                        {{ businessName }}
                    </h6>

                    <p v-if="businessAddress">
                        {{ businessAddress }}
                    </p>
                </div>
            </div>

            <ds-filled-button
                :loading="loading"
                block
                data-qa="verify-business"
                @click="confirm"
            >
                {{ $t('confirm') }}
            </ds-filled-button>

            <ds-text-button class="cancel-button" @click="back">
                {{ $t('cancel') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';

export default {
    props: {
        onSuccess: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            baseUrl: process.env.BASE_URL,
            nestedModal_title: this.$t('title'),
            placeId: null,
            businessAddress: null,
            businessName: null,
            loading: false,
        };
    },

    methods: {
        nestedModal_open({ id, description, name }) {
            this.placeId = id;
            this.businessAddress = description;
            this.businessName = name;
        },

        async confirm() {
            this.loading = true;
            this.$track('Reviews - Verify Listing - clicked : confirm', { placeId: this.placeId });

            amplitude.v2.logEvent(amplitude.v2.events.GOOGLE_MY_BUSINESS_LISTING_CONNECTED);
            intercom.logEvent(intercom.events.GOOGLE_MY_BUSINESS_LISTING_CONNECTED);

            const message = this.$t('error');

            try {
                await this.$store.dispatch('SAVE_GOOGLE_PLACE_ID', { key: 'GooglePlaceId', value: this.placeId });
                await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'setupGoogleReviews');
            } catch {
                this.loading = false;
                this.$error({
                    message,
                    bottom: true,
                });
            }

            return this.loadReviews();
        },

        async loadReviews() {
            const message = this.$t('error');

            try {
                await this.$store.dispatch('reviews/LOAD_BUSINESS_REVIEWS', true);

                this.loading = false;

                this.onSuccess();
            } catch (e) {
                this.loading = false;
                this.$error({
                    message,
                    bottom: true,
                });
            }
        },

        back() {
            this.$track('Reviews - Verify Listing - clicked : try again');

            this.$bus.$emit('POP_NESTED_MODAL');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .verify-listing {
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    .business-card {
        @include card;
        background: $color-gray-050;
        width: px-to-rem(320px);
        max-width: 100%;
    }

    .business-details {
        display: grid;
        grid-template-columns: auto auto;
        @include text-align-start;
        margin: $gp * 2 0 $gp;
        font-size: $font-size-med;
        grid-gap: $gp;
    }

    .company-icon {
        color: $color-blue;
    }

    .cancel-button {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Verify your listing",
        "message": {
            "0": "We found this business listing on Google",
            "1": "Is this your business?"
        },
        "confirm": "Yes, use this listing",
        "cancel": "No, let me try again",
        "error": "Something went wrong and we could not load your reviews. Please try again."
    }
}
</i18n>
