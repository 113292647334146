<template>
    <controlled-modal
        class="automation-sms-ftu-modal"
        :is-open="isOpen"
        show-close
        size="med"
        intercept-close
        @close="$emit('close')"
    >
        <template #title>
            <i18n class="title" path="title">
                <template #bold>
                    <strong>{{ $t('titleBold') }}</strong>
                </template>
            </i18n>
        </template>

        <div class="body">
            <ds-illustration class="illustration" name="combine-contact-sources-color" />

            <div class="content">
                <span class="title-description">{{ $t('titleDescription') }}</span>

                <span class="description">{{ $t('description') }}</span>
            </div>
        </div>

        <div class="lets-go-button">
            <ds-filled-button @click="$emit('continue')">
                {{ $t('letsGo') }}
            </ds-filled-button>
        </div>

        <div class="no-thanks-button">
            <ds-text-button @click="$emit('close')">
                {{ $t('noThanks') }}
            </ds-text-button>
        </div>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: { ControlledModal },

    props: {
        isOpen: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.automation-sms-ftu-modal {
    --modal-padding: 0;
}

.title {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.illustration {
    margin: $gp * 2 auto;
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0 $gp * 3.5;
    text-align: center;

    .title-description {
        font-size: $font-size-jumbo;
        line-height: $line-height-xxl;
        padding-bottom: $gp;

        @media($extra-small) {
            padding: $gp 0;
        }
    }

    .description {
        font-size: $font-size-xlg;
        line-height: $line-height-lg;
        padding: $gp 0;
    }
}

.lets-go-button {
    margin: $gp * 1.5 auto 0;
}

.no-thanks-button {
    margin: $gp * 0.25 auto $gp * 0.75;
}
</style>

<i18n>
{
    "en-us": {
        "title": "{bold}",
        "titleBold": "Send your first automated text message",
        "titleDescription": "Get help sending your first automated text message",
        "description": "See helpful tips as you create and send your first automated text message",
        "letsGo": "Let's go",
        "noThanks": "No thanks"
    }
}
</i18n>
