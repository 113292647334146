<template lang="html">
    <form ref="form" @submit.prevent="save">
        <div class="avatar-wrapper">
            <ds-avatar type="company" :size="80" />
        </div>

        <h4 v-if="showAllFields" class="section-title editing">
            {{ $t('detailsHeading') }}
        </h4>

        <ds-input-field
            v-model.trim="localCompany"
            type="text"
            name="name"
            :label="$t('companyName')"
            data-qa="company-name"
            required
            autofocus
            :maxlength="$options.COMPANY_NAME_MAX_LENGTH"
            :submitted="submitted"
            :invalid="invalid"
            @keydown="onKeydown"
            @blur="handleDuplicateCompany()"
        >
            <template #help>
                <small class="name-length">
                    {{ companyNameLength }}/{{ $options.COMPANY_NAME_MAX_LENGTH }}
                </small>
            </template>

            <template #error>
                <small v-if="duplicateCompany">
                    <i18n
                        v-if="useDuplicate"
                        path="duplicateError.use"
                        tag="span"
                        data-qa="duplicate-error"
                    />

                    <i18n
                        v-else
                        path="duplicateError.go"
                        tag="span"
                        data-qa="duplicate-error"
                    >
                        <router-link
                            :to="duplicatePath"
                            @click.native="close"
                        >
                            {{ $t('duplicateError.goText') }}
                        </router-link>
                    </i18n>
                </small>
            </template>

            <template #trailing>
                <span
                    v-show="localCompany"
                    class="clear-icon"
                    @click="clear"
                >
                    <ds-icon name="x" />
                </span>
            </template>
        </ds-input-field>

        <ds-text-area-field
            v-if="isCompanyFormAboutFieldEnabled"
            v-model.trim="company.notes"
            data-qa="company-notes"
            class="textarea"
            :label="$t('about')"
            autocomplete="no"
            :maxlength="512"
        />

        <div class="company-view-expanded">
            <contact-search-field
                v-if="!hideAddContact"
                :value="contacts"
                data-qa="select-contacts"
                allow-add
                multiple
                :show-search-icon="!contacts.length"
                :add-text="$t('addNewContact')"
                :placeholder="$t('addContacts')"
                :filter="filterResults"
                :search-params="searchParams"
                :readonly="!localCompany"
                class="select-contacts"
                @add="handleAddNewContact"
                @input="handleInput"
            />

            <div class="phone">
                <ds-phone-input
                    v-model="company.phoneNumber.value"
                    data-qa="company-phone"
                    :label="$t('companyPhone')"
                />

                <ds-select-field
                    v-model="company.phoneNumber.type"
                    bind-value-only
                    :placeholder="$t('type')"
                    :options="fieldOptions.phone"
                    :label="$t('type')"
                />
            </div>

            <section v-if="showAllFields" class="field-section">
                <div class="field-grid fax-fields editing">
                    <div class="field-grid">
                        <ds-input-field
                            v-model="company.faxNumber.value"
                            type="tel"
                            :label="$t('companyFax')"
                            class="fs-block"
                        />

                        <div class="field-type">
                            <ds-select-field
                                v-model="company.faxNumber.type"
                                bind-value-only
                                :label="$t('type')"
                                :options="fieldOptions.fax"
                                :placeholder="$t('type')"
                            />
                        </div>
                    </div>
                </div>
            </section>

            <ds-input-field
                v-model="company.website"
                data-qa="company-website"
                :label="$t('website')"
                @blur="formatWebsite()"
            />

            <ds-text-button
                v-if="!showAllFields"
                data-qa="show-all-fields"
                class="show-all-fields"
                @click="handleShowAllFieldsClick"
            >
                {{ this.$t('showAllFields') }}
            </ds-text-button>

            <section v-if="showAllFields" class="company-address">
                <ds-input-field
                    v-model="company.emailAddress"
                    type="email"
                    :label="$t('emailLabel')"
                    class="fs-block"
                />

                <h4 class="section-title editing">
                    {{ $t('addressHeading') }}
                </h4>

                <div class="field-grid editing">
                    <ds-multiselect
                        v-model="company.address.countryCode"
                        searchable
                        show-search-icon
                        bind-value-only
                        :placeholder="$t('countryLabel')"
                        :options="countries"
                        data-qa="country"
                        @input="loadRegionOptions"
                    />

                    <address-autocomplete
                        v-model="company.address.street1"
                        :label="$t('street1')"
                        field-name="STREET_ADDRESS1"
                        autocomplete-value="nofill"
                    />

                    <ds-input-field
                        v-model="company.address.street2"
                        :label="$t('street2')"
                        autocomplete="no"
                    />

                    <ds-input-field
                        v-model="company.address.locality"
                        :label="$t('locality')"
                        autocomplete="no"
                    />

                    <div class="fs-block">
                        <ds-select-field
                            v-model="company.address.region"
                            bind-value-only
                            searchable
                            show-search-icon
                            :placeholder="$t('region')"
                            :options="regionOptions"
                            :label="$t('region')"
                            :readonly="!company.address.countryCode"
                            data-qa="region"
                        />

                        <ds-input-field
                            v-model="company.address.postalCode"
                            autocomplete="no"
                            :label="$t('postalCode')"
                        />
                    </div>
                </div>
            </section>
        </div>

        <div class="button-row">
            <ds-filled-button
                v-if="useDuplicate && invalid"
                leading-icon="check"
                @click="onUseDuplicate"
            >
                {{ $t('useDuplicateActionText') }}
            </ds-filled-button>

            <ds-filled-button
                v-else
                :disabled="disabled || invalid"
                :loading="loading"
                type="submit"
                data-qa="save-company"
            >
                {{ submitActionText }}
            </ds-filled-button>
        </div>

        <transition v-if="$slots.error" name="slideDown">
            <error-box v-if="submitted && !loading && !duplicateCompany" class="error" data-qa="error">
                <slot name="error" />
            </error-box>
        </transition>
    </form>
</template>

<script>
import ErrorBox from '@/shared/components/ErrorBox';
import {
    IDLE,
    SUCCESS,
    ERROR,
    LOADING,
} from '@/contacts/loadingStatuses';
import {
    COMPANY_NAME_MAX_LENGTH,
    DUMMY_COMPANY_ID,
    DEFAULT_COUNTRY,
} from '@/contacts/contacts.constants';
import { getCompanies, assignCompany } from '@/contacts/api';
import {
    KEAP_ADD_COMPANY_FORM_ABOUT_FIELD,
    KEAP_ADD_COMPANY_FORM_DUPLICATE_NOTIFICATION,
} from '@/shared/constants/featureFlag.constants.js';
import { PREFERENCE_TYPES } from '@/shared/constants/preferences.constants';
import { FIELD_ID_COMPANY } from '@/smartForms/smartform.constants';
import { STANDARD_COMPANY_FIELD_ID } from '@/customForms/customForm.constants';
import { mapState, mapGetters } from 'vuex';
import { displayFullName } from '@/contacts/contact-info-utils';
import { urlUtils } from '@infusionsoft/vue-utils';
import { trackCompanyAssociated } from '@/contacts/analytics';
import ContactSearchField from '@/contacts/components/ContactSearchField';
import CompanySavedModal from './CompanySavedModal';
import AddressAutocomplete from '@/shared/components/Fields/AddressAutocomplete';
import clonedeep from 'lodash.clonedeep';

const defaultCompany = {
    companyName: null,
    website: '',
    emailAddress: '',
    notes: '',
    phoneNumber: {
        value: null,
        extension: null,
        type: null,
    },
    faxNumber: {
        value: null,
        type: null,
    },
    address: {
        street1: '',
        street2: '',
        locality: '',
        region: '',
        postalCode: '',
        countryCode: '',
    },
};

export default {
    COMPANY_NAME_MAX_LENGTH,

    components: {
        ErrorBox,
        ContactSearchField,
        AddressAutocomplete,
    },

    props: {
        errorMessage: String,
        onSave: Function,
        postSave: Function,
        submitActionText: String,
        companyName: String,
        useDuplicate: Boolean,
        hideAddContact: Boolean,
        preventConfirmation: Boolean,
    },

    data() {
        return {
            localCompany: this.companyName,
            company: clonedeep(defaultCompany),
            companySaveRequestStatus: IDLE,
            duplicateCompany: null,
            contactsAdded: [],
            addContactRequestStatus: IDLE,
            newCompanyId: DUMMY_COMPANY_ID,
            showAllFields: false,
            regionOptions: [],
            confirmationModalData: {
                id: null,
                companyName: '',
                assignedContacts: [],
            },
        };
    },

    created() {
        this.$store.dispatch('LOAD_COUNTRY_OPTIONS');
        this.$bus.$on('AUTOFILL_CONTACT_ADDRESS', (addressData) => this.autoFill(addressData));
        this.$bus.$on('OPEN_ADD_CONTACT_COMPANY_MODAL', this.openAddContactModal);
    },

    mounted() {
        this.loadRegionOptions();
    },

    beforeDestroy() {
        this.$bus.$off('AUTOFILL_CONTACT_ADDRESS');
        this.$bus.$off('OPEN_ADD_CONTACT_COMPANY_MODAL', this.openAddContactModal);
    },

    computed: {
        countries() {
            const firstCountryOption = this.fieldOptions.countries.find(({ value }) => value === DEFAULT_COUNTRY);

            return firstCountryOption
                ? [firstCountryOption, ...this.fieldOptions.countries]
                : this.fieldOptions.countries;
        },

        ...mapState({
            duplicateNotificationEnabled: ({ featureFlags }) => featureFlags[KEAP_ADD_COMPANY_FORM_DUPLICATE_NOTIFICATION],
            isCompanyFormAboutFieldEnabled: ({ featureFlags }) => featureFlags[KEAP_ADD_COMPANY_FORM_ABOUT_FIELD],
        }),

        ...mapGetters({
            getPreference: 'preferences/getPreference',
            fieldOptions: 'contacts/fieldOptions',
        }),

        companyNameLength() {
            return this.localCompany?.length || 0;
        },

        disabled() {
            return !this.localCompany || this.localCompany === this.companyName;
        },

        invalid() {
            return this.companySaveRequestStatus === ERROR && Boolean(this.duplicatePath);
        },

        submitted() {
            return this.companySaveRequestStatus !== IDLE;
        },

        loading() {
            return this.companySaveRequestStatus === LOADING;
        },

        localCompanyRegExp() {
            return new RegExp(`^${this.localCompany}$`, 'gi');
        },

        duplicatePath() {
            if (!this.disabled && this.duplicateCompany) {
                const {
                    $route: {
                        query,
                        params,
                    },
                } = this;

                return {
                    name: 'contacts.company.record',
                    query,
                    params: {
                        ...params,
                        companyId: this.duplicateCompany.id,
                    },
                };
            }

            return undefined;
        },

        contacts() {
            if (!this.contactsAdded) {
                return [];
            }

            return this.contactsAdded.map(({ email, ...contact }) => contact);
        },

        searchParams() {
            return {
                filter: `COMPANY=NOT_IS-${this.localCompany}`,
            };
        },

        error() {
            return this.addContactRequestStatus === ERROR;
        },
    },

    methods: {
        async loadRegionOptions() {
            const { countryCode } = this.company.address;

            if (countryCode) {
                const regions = await this.$store.dispatch('LOAD_REGION_OPTIONS', { countryCode });

                this.regionOptions = this.mapRegionOptionsToArray(regions);
            } else {
                this.regionOptions = [];
            }
        },

        mapRegionOptionsToArray(map) {
            return Object.keys(map).map((key) => {
                return { value: key, label: map[key] };
            });
        },

        async autoFill({ addressObject }) {
            const { fields } = addressObject;
            const companyAddress = this.company.address;
            const countryRegex = new RegExp(`^${fields.countryName}$`, 'i');
            const regionRegex = new RegExp(`^${fields.region}$`, 'i');

            const matchedCountry = this.fieldOptions.countries.find(({ label, value }) => {
                return value && label?.match(countryRegex);
            });

            if (matchedCountry) {
                companyAddress.countryCode = matchedCountry.value;
                await this.loadRegionOptions();
            }

            const matchedRegion = this.regionOptions.find(({ label, value }) => {
                return value && label?.match(regionRegex);
            });

            companyAddress.region = matchedRegion?.value || null;
            companyAddress.locality = fields.locality;
            companyAddress.postalCode = fields.postalCode;
            companyAddress.street1 = fields.streetAddress1;
            companyAddress.street2 = fields.streetAddress2;
        },

        clear() {
            this.localCompany = '';

            this.company = clonedeep(defaultCompany);

            this.companySaveRequestStatus = IDLE;
        },

        onKeydown() {
            this.companySaveRequestStatus = IDLE;
        },

        onUseDuplicate() {
            this.postSave(this.duplicateCompany);
            this.close();
        },

        close() {
            this.localCompany = '';
            this.companySaveRequestStatus = IDLE;
            this.$bus.$emit('POP_NESTED_MODAL');
            this.clear();
        },

        handleDuplicateCompany() {
            return this.duplicateNotificationEnabled && this.localCompany
                ? this.checkForDuplicateCompany()
                : this.localCompany;
        },

        checkForDuplicateCompany() {
            return getCompanies({
                term: this.localCompany,
                includeCount: false,
                limit: 1000,
            }).then(({ companies }) => {
                this.duplicateCompany = companies.find(({ companyName }) => companyName.match(this.localCompanyRegExp));

                if (this.duplicateNotificationEnabled && this.duplicateCompany) {
                    this.companySaveRequestStatus = ERROR;
                }

                return Boolean(this.duplicateCompany);
            });
        },

        async save() {
            this.companySaveRequestStatus = LOADING;
            await this.checkForDuplicateCompany();

            if (this.$refs.form.checkValidity() && !(this.duplicateCompany)) {
                this.localCompany = this.localCompany
                    .replace(/\s{2,}/g, ' ')
                    .replace(/(\\n|\\r)/gm, '')
                    .trim();

                this.company.companyName = this.localCompany;
                this.confirmationModalData.companyName = this.localCompany;

                try {
                    const company = await this.onSave(this.company);

                    this.newCompanyId = company.value || company.id;
                    this.confirmationModalData.id = this.newCompanyId;

                    await this.postSave(company);

                    if (this.contactsAdded.length) {
                        this.confirmationModalData.assignedContacts = this.contactsAdded;
                        await this.addContacts();
                    }

                    this.companySaveRequestStatus = SUCCESS;

                    this.$bus.$emit('COMPANY_LIST_REFRESH');

                    this.close();

                    if (this.preventConfirmation) {
                        this.$toast({
                            message: this.$t('created', {
                                companyName: this.confirmationModalData.companyName,
                            }),
                        });
                    } else {
                        this.openConfirmationModal();
                    }

                    return null;
                } catch {
                    this.companySaveRequestStatus = ERROR;
                }
            }

            return null;
        },

        addNewContact(contact) {
            this.contactsAdded.push({
                fullName: displayFullName(contact),
                ...contact,
            });
        },

        filterResults({ id }) {
            return !this.contacts.some((contact) => Number(contact.id) === Number(id));
        },

        handleAddNewContact() {
            this.addContactRequestStatus = IDLE;
            this.openAddContactModal(
                this.getPreference(PREFERENCE_TYPES.ADD_CONTACT_FORM_ID),
            );
        },

        openAddContactModal(id) {
            const defaultValues = [Number(this.newCompanyId)];
            const savedCompany = {
                value: this.newCompanyId,
                label: this.localCompany,
            };

            if (this.newCompanyId === DUMMY_COMPANY_ID) {
                this.$store.commit('contacts/ADD_COMPANY', savedCompany);
            } else {
                this.$store.commit('contacts/UPDATE_COMPANY', savedCompany);
            }
            this.$bus.$emit('OPEN_ADD_CONTACT_MODAL', {
                id,
                data: {
                    lockCompany: true,
                    hideSuccessModal: true,
                    successCallback: this.addNewContact,
                    showFormLevelError: true,
                    showBookNow: false,
                    analyticsInitialFlow: 'Create a company form',
                    values: {
                        [FIELD_ID_COMPANY]: {
                            defaultValues,
                            companyName: this.localCompany,
                            readonly: true,
                        },
                        [STANDARD_COMPANY_FIELD_ID]: {
                            defaultValues,
                            readonly: true,
                        },
                    },
                },
                contact: {
                    company: this.newCompanyId,
                },
                showRootClose: false,
                useCompanyContext: true,
            });
        },

        addContacts() {
            this.addContactRequestStatus = LOADING;

            return assignCompany(
                this.newCompanyId,
                this.contacts.map(({ id }) => id),
            )
                .then(() => {
                    this.addContactRequestStatus = SUCCESS;
                    this.$toast({
                        message: this.$tc('success.message', this.contacts.length, {
                            contactCount: this.contacts.length,
                            companyName: this.company.companyName,
                        }),
                    });
                    this.$bus.$emit('CONTACT_LIST_REFRESH');
                    trackCompanyAssociated('Company List View');
                })
                .catch(() => {
                    this.addContactRequestStatus = ERROR;
                });
        },

        openConfirmationModal() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: CompanySavedModal,
                showClose: true,
                data: this.confirmationModalData,
                modalSize: 'med',
            });
        },

        formatWebsite() {
            this.company.website = urlUtils.formatUrl(
                this.company.website,
            );
        },

        handleInput(contacts) {
            this.addContactRequestStatus = IDLE;
            this.contactsAdded = [...contacts];
        },

        handleShowAllFieldsClick() {
            this.showAllFields = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .error {
        margin: $gp 0 0;
    }

    .slideDown-enter-active, .slideDown-leave-active {
        transition: all $animation-duration $animation-in-timing;
        overflow: hidden;
        max-height: px-to-rem(100px);
    }

    .slideDown-enter, .slideDown-leave-to {
        transition: all $animation-duration $animation-in-timing;
        overflow: hidden;
        max-height: 0;
    }

    .clear-icon {
        --icon-color: #{$color-ink};
        cursor: pointer;
    }

    .name-length {
        display: block;
        text-align: right;
    }

    .phone {
        display: grid;
        column-gap: $gp / 2;
        grid-template-columns: 3fr 1fr;
        @media ($small) {
            display: block;
        }
    }

    .company-view-expanded {
        display: flex;
        flex-direction: column;
    }

    .field-grid {
        display: grid;
        column-gap: $gp / 2;
        grid-template-columns: 3fr 1fr;

        @media ($small) {
            display: block;
        }

        &.editing {
            grid-template-columns: auto;
        }
    }

    .show-all-fields {
        margin: 1rem;
    }

    .avatar-wrapper {
        @include transition(box-shadow);
        position: relative;
        border-radius: 100%;
        padding-bottom: $gp * 1.5;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .section-title {
        font-size: $font-size-med;
        font-weight: $font-weight-bold;
        line-height: $line-height-lg;
        margin-bottom: $gp;
        &.editing {
            margin-bottom: $gp * 1.5;
        }
    }

    .addresses {
        display: grid;
        grid-gap: $gp;
        grid-template-columns: 1fr auto;
    }

    .textarea {
        --textarea-min-height: #{px-to-rem(60px)};
    }
</style>

<i18n>
{
    "en-us": {
        "duplicateError" : {
            "go": "Sorry, there's already {0} with that name.",
            "goText": "another company",
            "use": "Oops! Looks like that company already exists. You can create a new company with a different name, or use it now."
        },
        "useDuplicateActionText": "Use existing company",
        "companyName": "Company name",
        "about": "About",
        "website": "Website",
        "type": "Type",
        "addContacts": "Add Contacts",
        "addNewContact": "Add a new contact",
        "companyPhone": "Phone",
        "companyFax": "Fax",
        "emailLabel": "Email",
        "countryLabel": "Country",
        "detailsHeading": "Details",
        "addressHeading": "Address",
        "street1": "Address",
        "street2": "Apt/Suite/Other",
        "locality": "City",
        "region": "State",
        "postalCode": "Zip code",
        "countryCode": "Country code",
        "success": {
            "message": "Contact added to {companyName}  | {contactCount} contacts added to {companyName}"
        },
        "created": "{companyName} is created",
        "showAllFields": "Show more fields"
    }
}
</i18n>
