import Vue from 'vue';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

import intercom from '@/analytics/intercom';
import sentry from '@/analytics/sentry';
import amplitude from '@/analytics/amplitude';
import marketingSnippet from '@/shared/utils/marketing.util';

import { dispatchEvent } from '@/shared/utils/window.util';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { SIMPLE_ONBOARDING_START_DATE } from '@/onboarding/constants/onboarding.constants';
import { HOME_ROUTE, ONBOARDING_ROUTE, COMMUNICATION_ROUTE } from '@/router/router.constants';

export const beforeRoute = (to, from, next) => {
    if (from && from.path !== '/') {
        window.performance.clearMarks(`transition_${to.name}_start`);
        window.performance.mark(`transition_${to.name}_start`);
        window.performance.mark('transition_start');
    }

    next();
};

class AuthedRouteError extends Error {
    constructor(message) {
        super(message);
        this.name = 'AuthedRouteError';
    }
}

export const onRoute = async (to, _, next, store) => {
    if (to.meta.isPublic) {
        handlePublicRoute(to, store);
    } else {
        if (
            store.state.auth
            && store.state.auth.session
            && store.state.auth.session.casRedirectUrlConfig
        ) {
            return handleCasRedirectConfig(to, next, store);
        }

        try {
            await handleAuthedRoute(to, store);
        } catch (error) {
            if (error.name === 'AuthedRouteError') {
                return next(false);
            }

            throw error;
        }
    }

    if (shouldDoOnboarding(to, store)) {
        amplitude.v2.logEvent(amplitude.v2.events.ONBOARDING_REQUIRED);

        return next(ONBOARDING_ROUTE);
    }

    if (!routeIsValid(to, store)) {
        return next(HOME_ROUTE);
    }

    if (to.meta?.resetIntercom) {
        const casId = store.state?.auth?.user?.casId;

        intercom.reset({ casId });
    }

    store.commit('SET_MOBILE_NAV_OPEN', false);
    store.commit('SET_SUPPORT_PANEL_OPEN', false);
    dispatchEvent('appLoaded');
    store.commit('SET_APP_LOADED');
    setTitle(to, store);
    amplitude.v2.logEvent(amplitude.v2.events.PAGE_VIEWED, { Page: to.name });

    // Remove JWT parameter from request uri.
    if (to.query.ticket) {
        delete to.query.ticket;
        next(to);
    }

    return next();
};

export const isJwtExpired = (jwt) => {
    const { exp } = jwtDecode(jwt);

    if (exp) {
        return exp * 1000 < Date.now();
    }

    return true;
};

const handleAuthedRoute = async (to, store) => {
    if (to.query.ticket) {
        store.commit('auth/SET_JWT', to.query.ticket);
    }

    if (to.query.logInAs) {
        store.commit('auth/SET_SESSION_LOG_IN_AS', true);
    }

    try {
        await store.dispatch('auth/LOGIN');
    } catch (e) {
        store.commit('auth/SET_REDIRECT_URL_CONFIG', { ...to });
        store.dispatch('auth/LOGOUT');

        window.location.assign(`${process.env.VUE_APP_CAS_URL}/login?service=${window.location.origin}`);

        throw new AuthedRouteError('Error with login');
    }

    if (isJwtExpired(store.state.auth.session.jwt)) {
        store.commit('SET_BOOT_LOAD_ERROR', true);

        throw new AuthedRouteError('Expired token');
    }

    await store.dispatch('LOAD_BOOT');

    if (!store.state.auth.account.isSpaApp) {
        redirectToCore(store);
    } else {
        try {
            marketingSnippet(store.getters['auth/isPaid']);
        } catch (e) {
            sentry.captureException(e);
        }
    }
};

const handlePublicRoute = (to, store) => {
    const appId = to.query.app_id || to.params.appId;

    store.dispatch('LOAD_PUBLIC_BOOT', { appId });
};

const routeHasRequiredAccountFeature = (route, store, to) => {
    const {
        feature,
        preRouteFeatureCheck,
        anyFeature,
    } = route.meta;

    const { featureFlags } = store.state;

    if (preRouteFeatureCheck) {
        return preRouteFeatureCheck(store.getters, to, featureFlags);
    }

    if (anyFeature) {
        return anyFeature.some((featureName) => {
            return Boolean(store.getters['auth/hasFeature'](featureName));
        });
    }

    return Boolean(feature == null || store.getters['auth/hasFeature'](feature));
};

const routeHasRequiredFeatureFlag = (route, store) => {
    const { featureFlags } = store.state;
    const { featureFlag } = route.meta;

    return Boolean(!featureFlag || (featureFlags && featureFlags[featureFlag]));
};

const routeHasRequiredPermission = (route, store) => {
    const { permission } = route.meta;

    return store.getters['auth/hasPermission'](permission);
};

const routeHasRequiredRegion = (route, { getters }) => {
    if (route.path.startsWith(COMMUNICATION_ROUTE)) {
        return getters['communication/showCommunicationCenter'];
    }

    return true;
};

const routeIsValid = (to, store) => {
    return to.matched.every((route) => {
        return routeHasRequiredAccountFeature(route, store, to)
            && routeHasRequiredFeatureFlag(route, store)
            && routeHasRequiredPermission(route, store)
            && routeHasRequiredRegion(route, store);
    });
};

const setTitle = (route, store) => {
    const unreadCount = store.getters['communication/totalUnreadCount'];
    const baseTitle = Vue.prototype.$i18nInstance
        ? Vue.prototype.$i18nInstance.t(route.meta.title || 'global.title', { productName: Vue.prototype.$i18nInstance.t('global.productName') })
        : '';
    const documentTitle = unreadCount ? `(${unreadCount}) ${baseTitle}` : baseTitle;

    setTimeout(() => {
        store.commit('SET_TITLE', baseTitle);
        document.title = documentTitle;
    });
};

const handleCasRedirectConfig = (to, next, store) => {
    const redirectConfig = store.state.auth.session.casRedirectUrlConfig;

    store.commit('auth/SET_REDIRECT_URL_CONFIG', null);

    if (redirectConfig.query?.app_id) { // eslint-disable-line camelcase
        store.commit('auth/SET_CORE_APP_ID', redirectConfig.query.app_id);
    }
    const {
        redirect,
        ticket,
        logInAs,
        ...rest
    } = to.query;

    if (logInAs) {
        redirectConfig.query.logInAs = true;
    }
    redirectConfig.query = {
        ...redirectConfig.query,
        ...rest,
        loginSuccessful: true,
    };

    if (to.path) {
        redirectConfig.path = to.path;
    }

    return next(redirectConfig);
};

const redirectToCore = (store) => {
    return window.location.assign(store.getters['auth/coreBaseUrl']);
};

const shouldDoOnboarding = (to, store) => {
    const currentAccount = store.state.auth.accounts.find((account) => {
        return account.name === store.state.auth.session.coreAppId;
    });

    return currentAccount
        ? moment(currentAccount.created).diff(moment(SIMPLE_ONBOARDING_START_DATE)) > 0
        && !store.state.tutorials.items[TUTORIAL_TYPES.ONBOARDING.INTRO_SLIDES]
        && to.path !== ONBOARDING_ROUTE
        : false;
};
