import Vue from 'vue';
import { legacyBardJsonFromHtml } from '@/shared/utils/bard.util';

export default {
    ADD_ATTACHMENT(state, attachment) {
        state.attachments.push(attachment);
    },

    DELETE_ATTACHMENT(state, index) {
        const attachments = [...state.attachments];

        attachments.splice(index, 1);
        state.attachments = attachments;
    },

    DELETE_ATTACHMENTS(state) {
        state.attachments = [];
    },

    SET_ATTACHMENTS(state, attachments) {
        state.attachments = attachments;
    },

    RESET_CURRENT_EMAIL(state, { signature = null } = {}) {
        state.currentEmail = {
            bardJson: legacyBardJsonFromHtml({ html: '', signatureHtml: signature }),
            body: '',
            subject: '',
        };
    },

    SET_CURRENT_EMAIL(state, { email, signature }) {
        state.currentEmail = {
            ...email,
            bardJson: legacyBardJsonFromHtml({ html: email.body, signatureHtml: signature }),
        };
    },

    SET_EMAIL_BODY(state, { content, signature, unsubscribeText }) {
        state.currentEmail = {
            ...state.currentEmail,
            body: content,
            bardJson: legacyBardJsonFromHtml({ html: content, signatureHtml: signature, unsubscribeText }),
        };
    },

    SET_EMAIL_SUBJECT(state, { subject }) {
        state.currentEmail = {
            ...state.currentEmail,
            subject,
        };
    },

    SET_EMAIL_TEMPLATES(state, templates) {
        state.communicationTemplates = [
            ...templates,
        ];
    },

    SET_LAST_SENT_EMAIL(state, payload) {
        state.lastSentEmail = {
            ...payload,
        };
    },

    SET_PROVIDERS(state, providers) {
        Vue.set(state, 'providers', providers);
    },

    SET_SIGNATURE_ENABLED(state, signatureEnabled) {
        state.options = {
            ...state.options,
            signatureEnabled,
        };
    },

    SET_USER_SIGNATURE(state, { userId, signature }) {
        state.signatures = {
            ...state.signatures,
            [userId]: signature,
        };
    },

    UPDATE_PROVIDER(state, payload) {
        const { providers } = state;

        const newProviders = providers.map((provider) => {
            if (provider.emailProviderId === payload.emailProviderId
                && provider.emailProvider === payload.emailProvider
                && provider.userId === payload.userId) {
                const newProvider = {
                    ...payload,
                };

                return newProvider;
            }

            return provider;
        });

        Vue.set(state, 'providers', newProviders);
    },
};
