<template lang="html">
    <publish-page-section :title="$t('sectionTitle')">
        <div class="edit-site-name">
            <div class="input-div">
                <ds-input-field
                    v-model="value"
                    data-qa="site-name-input"
                    class="site-name-input"
                    label=" "
                >
                    <template #help>
                        {{ $t('helpText') }}
                    </template>
                </ds-input-field>
            </div>
            <div class="buttons">
                <ds-button class="save-button" data-qa="save-button" @click="updateSiteName">
                    Save
                </ds-button>
                <ds-text-button data-qa="cancel-button" @click="$emit('cancel')">
                    Cancel
                </ds-text-button>
            </div>
        </div>
    </publish-page-section>
</template>

<script>

import PublishPageSection from './PublishPageSection';

export default {
    components: { PublishPageSection },

    props: {
        siteName: String,
    },
    data() {
        return {
            editing: false,
            value: this.siteName,
        };
    },
    computed: {
        inputClasses() {
            return ['editable',
                ...(this.editing ? ['editing'] : []),
            ].join(',');
        },
    },

    methods: {
        updateSiteName() {
            this.$emit('save-site-name', this.value);
            this.editing = false;
        },
    },
};
</script>
<style lang="scss" scoped="true">
    .buttons {
        padding-top: $gp;
    }
    .save-button {
        @include margin-end($gp / 2);
    }
    .edit-site-name {
        --input-margin-bottom: 0;
    }
    .input-div {
        width: px-to-rem(420);
    }

    .site-name-input {
        margin-top: $gp / 2;
    }
</style>
<i18n>
{
    "en-us": {
        "sectionTitle": "Page name",
        "sectionEdit": "Edit name",
        "helpText": "This will appear in the browser title bar and landing page url."
    }
}
</i18n>
