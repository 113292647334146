<template>
    <basic-dropdown-list
        :options="options"
        class="actions-dropdown"
        @select="select"
    >
        <ds-text-button trailing-icon="chevron-down" class="medium-only">
            {{ $t('label') }}
        </ds-text-button>

        <ds-icon-button name="more-horizontal" class="small-only" />
    </basic-dropdown-list>
</template>

<script>
import BasicDropdownList from '@/shared/components/BasicDropdownList';

export default {
    components: { BasicDropdownList },

    props: {
        options: {
            type: Array,
            required: true,
        },
    },

    methods: {
        select(value) {
            this.$emit('select', value);
        },
    },
};
</script>

<style lang="scss" scoped>
    .actions-dropdown {
        --dropdown-minwidth: #{px-to-rem(210px)};
        margin: 0 $gp;
    }

    .actions-dropdown button:not(:last-child) {
        margin: 0; // override button-row inheritance
    }
</style>

<i18n>
{
    "en-us": {
        "label": "More actions"
    }
}
</i18n>
