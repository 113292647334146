import {
    STANDARD_BILLING_POSTAL_CODE_FIELD_ID,
    STANDARD_BIRTHDAY_FIELD_ID,
    STANDARD_COMPANY_FIELD_ID,
    STANDARD_EMAIL1_FIELD_ID,
    STANDARD_FAMILY_NAME_FIELD_ID,
    STANDARD_FIELDS,
    STANDARD_GIVEN_NAME_FIELD_ID,
    STANDARD_NOTE_FIELD_ID,
    STANDARD_PHONE1_FIELD_ID,
    STANDARD_PHONE1_TYPE_FIELD_ID,
    STANDARD_WEBSITE_FIELD_ID,
} from '@/customForms/customForm.constants';
import { PHONE_TYPES } from '@/contacts/contacts.constants';
import Vue from 'vue';
import {
    KEAP_INPUT_TYPE_MAPPING,
    KEAP_TOOLS,
    KEAP_TOOLS_FORM_COUNTER_KEY,
    UNLAYER_INPUT_TYPES,
} from './unlayer.constants';
import { unlayerDesign } from './unlayer-design-processor';

/**
 * Takes in default unlayer form fields from a template, and maps all the names, labels, and options to keap names,
 * while still maintaining the unlayer structure.
 * @param unlayerField
 */
export const renameUnlayerFieldsToKeapFields = (unlayerField) => {
    const { name: fieldName } = unlayerField;

    // Unlayer has a single name field (full name) that needs to be split into two fields for keap
    if (fieldName === 'name') {
        return [
            {
                ...unlayerField,
                name: STANDARD_GIVEN_NAME_FIELD_ID,
                placeholder_text: Vue.prototype.$i18nInstance.t(`landingPagesCustomForms.fields.${STANDARD_GIVEN_NAME_FIELD_ID}`),
                label: Vue.prototype.$i18nInstance.t(`landingPagesCustomForms.fields.${STANDARD_GIVEN_NAME_FIELD_ID}`),
            },
            {
                ...unlayerField,
                name: STANDARD_FAMILY_NAME_FIELD_ID,
                placeholder_text: Vue.prototype.$i18nInstance.t(`landingPagesCustomForms.fields.${STANDARD_FAMILY_NAME_FIELD_ID}`),
                label: Vue.prototype.$i18nInstance.t(`landingPagesCustomForms.fields.${STANDARD_FAMILY_NAME_FIELD_ID}`),
            },
        ];
    }
    const id = toFieldId(unlayerField);
    const noMatchFound = !id;

    return noMatchFound ? [] : [{
        ...unlayerField,
        name: id,
    }];
};

/**
 * Converts a smartForm/customForm field from contacts.fieldGroups (custom fields) definitions into a form that is
 * compatible with unlayer.
 *
 * REMOVE FF branching logic with removal of KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
 *
 * @param field The form field definition from smartForms/customForms
 * @param customField The custom field definition from the `contacts/fieldGroups` state object
 * @return {UnlayerFormField | null}
 */
export const convertCustomFieldToUnlayerField = ({ customField, isCustomFieldTechDebtEnabled = false }) => {
    const mappedInputType = isCustomFieldTechDebtEnabled
        ? KEAP_INPUT_TYPE_MAPPING[customField.fieldType]
        : KEAP_INPUT_TYPE_MAPPING[customField.type];

    if (!mappedInputType) {
        return null;
    }

    const label = isCustomFieldTechDebtEnabled
        ? customField.label
        : customField.name;
    const options = isCustomFieldTechDebtEnabled
        ? flattenOptions(customField.options.map(({ value }) => value))
        : flattenOptions(customField.values);

    return {
        show_label: mappedInputType === UNLAYER_INPUT_TYPES.RADIO
            || mappedInputType === UNLAYER_INPUT_TYPES.CHECKBOX
            || mappedInputType === UNLAYER_INPUT_TYPES.BOOLEAN,
        label,
        name: `customField.${customField.id}`,
        type: mappedInputType,
        required: true,
        options,
        group: Vue.prototype.$i18nInstance.t('landingPagesCustomForms.fieldGroups.CustomFields'),
    };
};

/**
 * Converts a smartForm/customForm field from definitions in customForm.constants.js
 *
 * @param unlayerField The form field definition from smartForms/publicForms
 */
export const convertFromUnlayerFieldToCustomFormField = (unlayerField) => {
    const { required } = unlayerField;

    const id = toFieldId(unlayerField);

    if (STANDARD_FIELDS.find((def) => def.id === id)) {
        return {
            id,
            fieldType: 'External',
            required,
        };
    }

    return null;
};

let formCount = 1;

/**
 * Within an unlayer document, converts each form into a keap#form, also making sure to
 * adjust the internal unlayer counters.
 *
 * This also converts all form fields from the unlayer naming convention into keap field names, as defined in
 * customForms.constants.js and in customFields.
 */
export function convertUnlayerFormsToKeapForms(design) {
    const designHelper = unlayerDesign(design);
    const unlayerForms = designHelper.findByType(KEAP_TOOLS.unlayerForm);

    unlayerForms
        .forEach((form, formIndex) => {
            form.content.type = 'custom';
            form.content.slug = KEAP_TOOLS.keapForm;
            form.content.values.fields = form.content.values.fields?.flatMap((fld) => renameUnlayerFieldsToKeapFields(fld));
            form.content.values.keapForm = { slug: `${Date.now()}-${formCount++}` };
            // eslint-disable-next-line no-underscore-dangle
            form.content.values._meta = {
                htmlID: `u_content_custom_${KEAP_TOOLS.keapForm}_${formIndex}`,
                htmlClassNames: `u_content_custom_${KEAP_TOOLS.keapForm}`,
            };
        });

    return {
        ...designHelper.design,
        counters: {
            ...designHelper.design.counters,
            [KEAP_TOOLS_FORM_COUNTER_KEY]: unlayerForms.length,
        },
    };
}


/**
 * Converts a smartForm/customForm field from definitions in customForm.constants.js to be compatible with unlayer
 *
 * @param field The form field definition from smartForms/publicForms
 * @param {Object} standard field definition from customForm.constants.js/STANDARD_FIELDS
 * @return {UnlayerFormField | null}
 */
export const convertKeapStandardFieldToUnlayerField = (field, standard) => {
    const fieldId = standard.id;

    const label = fieldId?.startsWith('standard.') ? Vue.prototype.$i18nInstance.t(`landingPagesCustomForms.fields.${fieldId}`) : Vue.prototype.$i18nInstance.t(`forms.standardFields.${fieldId}`);
    const opts = resolveFieldOptions()[standard.inputOptionsType] ?? [];

    const inputType = KEAP_INPUT_TYPE_MAPPING[standard.inputType];

    if (!inputType) {
        return null;
    }

    return {
        name: standard.id,
        label,
        type: inputType,
        options: opts.map((opt) => opt.label ?? opt.value).filter((opt) => !!opt).join('\n') ?? '',
        required: true,
        group: Vue.prototype.$i18nInstance.t('landingPagesCustomForms.fieldGroups.ContactFields'),
        show_label: inputType === UNLAYER_INPUT_TYPES.RADIO
            || inputType === UNLAYER_INPUT_TYPES.CHECKBOX
            || inputType === UNLAYER_INPUT_TYPES.BOOLEAN,
    };
};

export function resolveFieldOptions() {
    return {
        PhoneType: [
            { value: PHONE_TYPES.WORK, label: Vue.prototype.$i18nInstance.t('global.phones.work') },
            { value: PHONE_TYPES.HOME, label: Vue.prototype.$i18nInstance.t('global.phones.home') },
            { value: PHONE_TYPES.MOBILE, label: Vue.prototype.$i18nInstance.t('global.phones.mobile') },
            { value: PHONE_TYPES.OTHER, label: Vue.prototype.$i18nInstance.t('global.phones.other') },
        ],
        FaxType: [
            { value: PHONE_TYPES.WORK, label: Vue.prototype.$i18nInstance.t('global.phones.work') },
            { value: PHONE_TYPES.HOME, label: Vue.prototype.$i18nInstance.t('global.phones.home') },
            { value: PHONE_TYPES.OTHER, label: Vue.prototype.$i18nInstance.t('global.phones.other') },
        ],
        YesNo: [
            { label: Vue.prototype.$i18nInstance.t('options.notSelected'), value: null },
            { label: Vue.prototype.$i18nInstance.t('global.no'), value: '0' },
            { label: Vue.prototype.$i18nInstance.t('global.yes'), value: '1' },
        ],
        ContactTitle: [
            { value: 'Ms.', label: Vue.prototype.$i18nInstance.t('global.titles.ms') },
            { value: 'Mr.', label: Vue.prototype.$i18nInstance.t('global.titles.mr') },
            { value: 'Mrs.', label: Vue.prototype.$i18nInstance.t('global.titles.mrs') },
            { value: 'Dr.', label: Vue.prototype.$i18nInstance.t('global.titles.dr') },
        ],
    };
}

function flattenOptions(options) {
    if (!options) {
        return undefined;
    }

    if (Array.isArray(options)) {
        return options.join('\n');
    }

    if (typeof options === 'object') {
        return Object.values(options).join('\n');
    }

    return options;
}


const fieldToStandard = {
    first_name: STANDARD_GIVEN_NAME_FIELD_ID,
    last_name: STANDARD_FAMILY_NAME_FIELD_ID,
    email: STANDARD_EMAIL1_FIELD_ID,
    phone: STANDARD_PHONE1_FIELD_ID,
    phone_number: STANDARD_PHONE1_FIELD_ID,
    phoneType: STANDARD_PHONE1_TYPE_FIELD_ID,
    note: STANDARD_NOTE_FIELD_ID,
    company: STANDARD_COMPANY_FIELD_ID,
    birthday: STANDARD_BIRTHDAY_FIELD_ID,
    website: STANDARD_WEBSITE_FIELD_ID,
    zip_code: STANDARD_BILLING_POSTAL_CODE_FIELD_ID,
};

function toFieldId({ name }) {
    const mapped = fieldToStandard[name];

    if (!mapped) {
        return name;
    }

    return mapped;
}

export const unlayerToStandardFieldTypeMapping = Object.fromEntries(
    Object.entries(KEAP_INPUT_TYPE_MAPPING)
        .reverse() // Ensures that first one wins
        .map(([k, v]) => [v, k]),
);


/**
 * @typedef UnlayerForm
 * @property {!id} id
 * @property {string} name
 * @property {UnlayerFormField[]} fields
 * @property {?string} [embedCode] If this form can be embedded, this is the code using to embed
 * @property {?string} [previewEmbedCode] If this form can be embedded, this is the code using to embed for previews
 */

/**
 * How form fields are displayed in Unlayer
 * @typedef UnlayerFormField
 * @property {!string} type
 * @property {!string}  name
 * @property {!string}  label
 * @property {!string}  value
 * @property {string[]}  options
 * @property {boolean} show_label
 * @property {boolean} required
 */
