<template>
    <configure-step-with-timer
        :value="value"
        :title="tagName"
        :subtitle="tagDescription"
        :has-error="hasError"
        :has-timer-warning="hasTimerWarning"
        :error-message="errorMessage || $t('error')"
        :manage-title="$t('manageTags')"
        :manage-props="manageProps"
        @edit="(payload) => $emit('edit', payload)"
        @save="(payload) => $emit('save', payload)"
        @remove="$emit('remove')"
        @done="$emit('done')"
    />
</template>

<script>
import { mapState } from 'vuex';
import ConfigureStepWithTimer from './ConfigureStepWithTimer';
import { APPLY_TAG } from '@/automations/constants/automations.constants';

export default {
    components: {
        ConfigureStepWithTimer,
    },

    props: {
        value: Object,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        errorMessage: String,
    },

    data() {
        return {
            step: this.value?.step ?? this.value,
            manageProps: {
                confirmTitle: this.$t('edit.title'),
                confirmMessage: this.$t('edit.message'),
                confirmButtonLabel: this.$t('edit.confirm'),
                route: { name: 'settings.tags' },
            },
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.step = newVal.step ?? newVal;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            tags: ({ tags }) => tags.tags,
        }),

        tag() {
            const tagIds = this.step?.configJson?.tagIds;
            const tagId = tagIds?.length ? tagIds[0] : 0;

            const matchingTag = this.tags.find((t) => t.id === tagId);

            return matchingTag || null;
        },

        isApplyTag() {
            return this.step?.type === APPLY_TAG;
        },

        tagName() {
            return this.tag?.name ?? this.$t(this.isApplyTag ? 'emptyApplyTagLabel' : 'emptyRemoveTagLabel');
        },

        tagDescription() {
            return this.tag?.category?.name ?? '';
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "manageTags": "Manage tags",
        "emptyApplyTagLabel": "Apply a tag",
        "emptyRemoveTagLabel": "Remove a tag",
        "edit": {
            "title": "Navigate to the tags page to manage your tags?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Manage tags"
        },
        "error": "Please select a tag"
    }
}
</i18n>
