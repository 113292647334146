<template>
    <component
        :is="component"
        :class="{day: isToday(day) ,hidden: !isInMonth }"
        :disabled="disabled"
        @click="selectDay"
    >
        {{ day.getDate() }}
        <ds-icon v-if="apptCount > 0" name="circle-fill" />
    </component>
</template>

<script>
import moment from 'moment';
import calendarMixin from '@/appointments/mixins/calendar.mixin';

export default {
    mixins: [calendarMixin],

    props: {
        day: {
            type: Date,
            required: true,
        },

        isInMonth: {
            type: Boolean,
            required: false,
            default: true,
        },

        isAvailable: {
            type: Boolean,
            required: false,
            default: true,
        },

        showAvailability: {
            type: Boolean,
            required: false,
            default: false,
        },

        onSelect: {
            type: Function,
            required: true,
        },

        selectedDate: {
            type: Date,
            required: false,
        },

        apptCount: {
            type: Number,
            required: false,
            default: 0,
        },
    },

    computed: {
        dayName() {
            return moment(this.day).format('ddd');
        },

        selected() {
            return moment(this.selectedDate).isSame(this.day, 'day');
        },

        disabled() {
            return this.showAvailability && !this.isAvailable;
        },

        component() {
            if (this.selected) {
                return 'ds-filled-button';
            }

            if (this.isToday(this.day)) {
                return 'ds-outline-button';
            }

            return 'ds-text-button';
        },
    },

    methods: {
        selectDay() {
            if (this.selected) return;

            const { day, isInMonth } = this;

            this.onSelect({
                day,
                isPast: moment().isAfter(this.day, 'day'),
                isInMonth,
                isUnavailable: this.showAvailability && !this.isAvailable,
                isToday: this.isToday(this.day),
                isSelected: this.selected,
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    button {
        --button-border-radius: 100%;
        --button-padding-right: 0;
        --button-padding-left: 0;
        width: px-to-rem(40px);
        margin: 0 auto $gp / 4;
        position: relative;
    }

    .icon {
        --icon-size: #{px-to-rem(6px)};
        position: absolute;
        bottom: $gp / 4;
        width: 100%;
        left: 0;
    }

    .hidden {
        visibility: hidden;
    }
</style>
