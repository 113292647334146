<template>
    <contact-additional-info-view
        v-if="!editing"
        :contact="contact"
    />

    <contact-additional-info-edit
        v-else
        :contact="contact"
        :submitted="submitted"
        :disable-add-custom-fields="disableAddCustomFields"
        @customFieldAdded="updateLocalContact"
        @input="$emit('input')"
    />
</template>

<script>
import ContactAdditionalInfoView from '@/contacts/components/simple/ContactAdditionalInfoView';
import ContactAdditionalInfoEdit from '@/contacts/components/simple/ContactAdditionalInfoEdit';

export default {
    components: {
        ContactAdditionalInfoView,
        ContactAdditionalInfoEdit,
    },

    props: {
        contact: Object,
        editing: Boolean,
        submitted: Boolean,
        disableAddCustomFields: Boolean,
    },

    mounted() {
        this.$bus.$on('UPDATE_LOCAL_CONTACT', this.updateLocalContact);
    },

    methods: {
        updateLocalContact() {
            this.$emit('customFieldAdded');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";
</style>
