export default {
    payments: [],
    recurringPayments: [],
    historicalRecurringPayments: [],
    allRecurringPayments: [],
    recurringPaymentHistory: [],
    invoices: [],
    quotes: [],
    allInvoices: [],
    searchString: '',
    recentInvoices: [],
    checkoutForms: [],
    recentInvoicesDirty: false,
    allQuotes: [],
    invoice: {
        orderItems: [],
    },
    quote: {},
    editingInvoice: true,
    editingQuote: true,
    publicQuote: {},
    appSalesInfo: {},
    publicInvoice: {},
    logo: '',
    userAddress: '',
    salesTotal: 0,
    invoiceFtu: false,
    quoteFtu: false,
    paypalMerchant: '',
    paymentSubtype: {},
    publicMoneyFlags: {},
    selectedUpsell: null,
    checkoutDiscounts: {},
    appliedPromoCodes: '',
};
