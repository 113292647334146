<template>
    <div>
        <div class="primary-nav">
            <div class="primary-nav-header">
                <ds-icon-button
                    v-if="isExpandable"
                    class="nav-icon-button-inverted toggle-expand-button"
                    :aria-label="$t('nav.expand')"
                    name="expand"
                    dense
                    @click="$emit('expand')"
                />

                <div class="nav-logo">
                    <ds-logo icon-only />
                </div>

                <side-nav-menu-item icon="search" @click="$emit('expand-search')">
                    {{ $t('nav.search') }}
                </side-nav-menu-item>

                <side-nav-menu-item
                    v-for="item in nav"
                    :key="item.id"
                    :to="item.primary.to"
                    :icon="item.primary.icon"
                    :is-active="isActive(item)"
                    @navigation-complete="() => onNavigationComplete(item)"
                >
                    {{ item.primary.text }}
                </side-nav-menu-item>
            </div>

            <div class="primary-nav-footer">
                <ds-icon-button
                    name="help-circle"
                    :aria-label="$t('nav.help')"
                    class="nav-icon-button-inverted"
                    @click="onHelpOpen"
                />

                <button
                    type="button"
                    class="avatar-button"
                    :aria-label="$t('nav.account')"
                    @click="isAccountMenuOpen = true"
                >
                    <ds-avatar
                        type="user"
                        :image-url="user.emailSignatureURL"
                        :name="user.givenName"
                        :size="36"
                        aria-hidden="true"
                    />
                </button>
            </div>
        </div>

        <div class="global-new-button-container">
            <side-nav-global-new-button />
        </div>

        <div class="account-menu-container">
            <ds-dropdown
                :is-open="isAccountMenuOpen"
                position="right-end"
                class="account-menu"
                @close="isAccountMenuOpen = false"
            >
                <template #menu>
                    <div class="account-menu-content">
                        <nav-account-links
                            hide-help
                            @click.native="isAccountMenuOpen = false"
                        />
                        <sample-data-cta event-source="Nav Account Dropdown" />
                    </div>
                </template>
            </ds-dropdown>
        </div>
    </div>
</template>

<script>
import SideNavGlobalNewButton from './SideNavGlobalNewButton';
import SideNavMenuItem from './SideNavMenuItem';
import NavAccountLinks from './NavAccountLinks';
import SampleDataCta from '@/sampleData/components/SampleDataCta';

export default {
    components: {
        SideNavGlobalNewButton,
        SideNavMenuItem,
        NavAccountLinks,
        SampleDataCta,
    },

    props: {
        nav: {
            type: Array,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
        isExpandable: Boolean,
        isActive: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            isAccountMenuOpen: false,
        };
    },

    methods: {
        onNavigationComplete(config) {
            if (config.secondary) {
                this.$emit('expand');
            }
        },

        onHelpOpen() {
            this.$store.commit('SET_SUPPORT_PANEL_OPEN', true);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./side-nav-variables";

.nav-icon-button-inverted {
    color: $color-paper;

    &:hover,
    &:focus {
        background-color: $side-nav-color-tertiary;
    }
}

.toggle-expand-button {
    position: absolute;
    top: $gp / 2;
    right: $gp / 2;
}

.nav-logo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: px-to-rem(72);
    margin-top: px-to-rem(10);
    align-items: center;
    justify-content: center;

    --logo-width: #{px-to-rem(18)};
}

.global-new-button-container {
    position: absolute;
    @include position-start($gp * 5);
    top: $gp * 3;
    z-index: $side-nav-z-index-floating-menu;
}

.dropdown-menu-item {
    --icon-color: #{$color-ink-800};
}

.primary-nav {
    position: fixed;
    top: 0;
    bottom: 0;
    width: $side-nav-width-collapsed;
    @include overflowY;
    background: $side-nav-color-primary;
    color: $color-paper;
    z-index: $side-nav-z-index-base;
    display: grid;
    grid-template-rows: 1fr auto;
}

.primary-nav-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar-button {
    // reset from inherited global button styles
    background-color: transparent;
    padding: 0;

    border: px-to-rem(2) solid $color-green;
    border-radius: 50%;
    margin-top: $gp;
    margin-bottom: $gp * 2;
}

.account-menu-container {
    position: absolute;
    @include position-start(px-to-rem(78));
    bottom: $gp * 2;
    z-index: $side-nav-z-index-floating-menu;
}

.account-menu {
    --dropdown-height: #{px-to-rem(600px)};
}

.account-menu-content {
    padding: $gp / 2 0;
}
</style>

<i18n>
{
    "en-us": {
        "nav.account": "Account",
        "nav.expand": "Expand Nav",
        "nav.help": "Help",
        "nav.search": "Search"
    }
}
</i18n>
