var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['message', { outgoing: _vm.message.outgoing }]},[(_vm.showImages)?_c('div',{staticClass:"media"},_vm._l((_vm.imageUrls),function(ref,i){
var mediaUrl = ref.mediaUrl;
return _c('img',{key:i,staticClass:"media-item",attrs:{"src":mediaUrl},on:{"load":_vm.imageLoaded}})}),0):_vm._e(),(_vm.showVCards)?_c('div',{staticClass:"file-bubble"},_vm._l((_vm.vCardUrls),function(ref,i){
var filename = ref.filename;
var mediaUrl = ref.mediaUrl;
return _c('a',{key:i,attrs:{"href":mediaUrl}},[_c('div',{staticClass:"file-item"},[_c('ds-icon',{staticClass:"file-icon",attrs:{"name":"user-fill"}}),_c('span',[_vm._v(_vm._s(filename))])],1)])}),0):_vm._e(),(_vm.showFiles)?_c('div',{staticClass:"file-bubble"},_vm._l((_vm.fileUrls),function(ref,i){
var filename = ref.filename;
var mediaUrl = ref.mediaUrl;
return _c('a',{key:i,attrs:{"href":mediaUrl,"target":"_blank","rel":"noopener noreferrer"}},[_c('div',{staticClass:"file-item"},[_c('ds-icon',{staticClass:"file-icon",attrs:{"name":"attach"}}),_c('span',[_vm._v(_vm._s(filename))])],1)])}),0):_vm._e(),(_vm.showText)?_c('div',{staticClass:"chat-bubble"},_vm._l((_vm.paragraphs),function(paragraph,i){return _c('p',{key:i,domProps:{"innerHTML":_vm._s(_vm.linkifyParagraph(paragraph))}})}),0):_vm._e(),(_vm.showEmoji)?_c('div',{staticClass:"emoji-only"},[_vm._v(" "+_vm._s(_vm.message.body)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }