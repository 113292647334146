import {
    ANY_PUBLIC_FORM_SUBMITTED,
    ANY_INTERNAL_FORM_SUBMITTED,
} from '@/automations/constants/automations.constants';

export const AUTOMATION_FILTERS = {
    ALL_PIPELINES: 'pipelines',
    ALL_STAGES: 'pipeline-',
    STAGE: 'stage-',
    ALL_APPOINTMENTS: 'appointments',
    ANY_APPOINTMENT: 'any_appointment',
    USER_APPOINTMENT: 'user_appointment-',
    APPOINTMENT: 'appointment-',
    ALL_FORMS: 'forms',
    ALL_PUBLIC_FORMS: 'forms_public',
    ANY_PUBLIC_FORM_SUBMITTED: ANY_PUBLIC_FORM_SUBMITTED.toLowerCase(),
    ALL_INTERNAL_FORMS: 'forms_internal',
    ANY_INTERNAL_FORM_SUBMITTED: ANY_INTERNAL_FORM_SUBMITTED.toLowerCase(),
    FORM: 'form-',
    ALL_TAGS: 'tags',
    ALL_MONEY: 'money',
};
