export default {
    SET_SAMPLE_DATA_PROGRESS(state, value) {
        state.sampleDataProgress = value;
    },

    SET_SAMPLE_DATA_STARTED(state, value) {
        state.sampleDataStarted = value;
    },

    SET_SAMPLE_DATA_SUCCESS(state, value) {
        state.sampleDataSuccess = value;
    },

    RESET_SAMPLE_DATA_STATUS(state, value) {
        state.sampleDataSuccess = value;
    },

    SET_SAMPLE_DATA_CTA_DISMISSED(state, value) {
        state.sampleDataCtaDismissed = value;
    },

    INCREMENT_ADD_SAMPLE_DATA_ERROR_COUNT(state) {
        state.addSampleDataErrorCount += 1;
    },

    RESET_ADD_SAMPLE_DATA_ERROR_COUNT(state) {
        state.addSampleDataErrorCount = 0;
    },
};
