import Vue from 'vue';
import gql from 'graphql-tag';

export const callAccessTokenQuery = async ({
    deviceIdentifier,
    deviceType,
    identity,
}) => {
    const { data: { callAccessToken } } = await Vue.prototype.$graphql.query({
        query: gql`
            query callAccessToken($deviceIdentifier: String!, $deviceType: String!, $identity: String!) {
                callAccessToken(deviceIdentifier: $deviceIdentifier, deviceType: $deviceType, identity: $identity)
            }
        `,
        variables: {
            deviceIdentifier,
            deviceType,
            identity,
        },
        fetchPolicy: 'no-cache',
    });

    return callAccessToken;
};

export const createVoipDeviceMutation = async ({
    deviceIdentifier,
    deviceType,
    identity,
}) => {
    const { data: { createVoipDevice } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createVoipDevice($deviceIdentifier: String!, $deviceType: String!, $identity: String!) {
                createVoipDevice(deviceIdentifier: $deviceIdentifier, deviceType: $deviceType, identity: $identity)
            }
        `,
        variables: {
            deviceIdentifier,
            deviceType,
            identity,
        },
        fetchPolicy: 'no-cache',
    });

    return createVoipDevice;
};

export const deleteVoipDeviceMutation = async ({
    identity,
}) => {
    const { data: { deleteVoipDevice } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteVoipDevice($identity: String!) {
                deleteVoipDevice(identity: $identity)
            }
        `,
        variables: {
            identity,
        },
        fetchPolicy: 'no-cache',
    });

    return deleteVoipDevice;
};
