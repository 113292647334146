const TagsPage = () => import(/* webpackChunkName: "tags" */ './pages/TagsPage');
const TagDetailsPage = () => import(/* webpackChunkName: "tags" */ './pages/TagDetailsPage');
const TagContactListEmptyState = () => import(/* webpackChunkName: "tags" */ './pages/TagContactListEmptyState');

export default [
    {
        path: 'tags',
        component: TagsPage,
        children: [
            {
                path: ':tagId',
                name: 'settings.tags.details',
                component: TagDetailsPage,
                meta: {
                    title: 'settings.tags.title',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: '',
                name: 'settings.tags',
                component: TagContactListEmptyState,
                meta: {
                    title: 'settings.tags.title',
                    hideHeaderOnMobile: true,
                },
            },
        ],
    },
];
