import moment from 'moment';
import { DataTypes } from '@/reports/reports.constants';

const getValuesBetweenUTCDates = (values, startDateUTC, endDateUTC) => {
    if (!Array.isArray(values)) {
        return [];
    }

    return values.filter((entry) => {
        return (moment(entry.dateUTC) >= moment(startDateUTC)
                && moment(entry.dateUTC) <= moment(endDateUTC));
    });
};

export default {
    getDataValuesForDataType: (state) => (dataType) => {
        let aggregatedData;

        if (dataType === DataTypes.TotalSales
            || dataType === DataTypes.NumberOfSales) {
            ({ aggregatedData } = state.allSales);
        } else if (dataType === DataTypes.OpenRate
            || dataType === DataTypes.ClickRate
            || dataType === DataTypes.OptOutRate) {
            ({ aggregatedData } = state.emailEngagement);
        }

        if (!aggregatedData
            || !Array.isArray(aggregatedData.data)) {
            return [];
        }

        const [aggregatedDataTypeData] = aggregatedData.data.filter((entry) => { return entry.dataType === dataType; });

        if (!aggregatedDataTypeData
            || !Array.isArray(aggregatedDataTypeData.values)) {
            return [];
        }

        return aggregatedDataTypeData.values;
    },

    getDataTypeValuesInTimePeriod: (state, { getDataValuesForDataType }) => (dataType, periodStartUTC, periodEndUTC) => {
        const values = getDataValuesForDataType(dataType);

        return getValuesBetweenUTCDates(values, periodStartUTC, periodEndUTC);
    },

    getDataTypeValuesSumInTimePeriod: (state, { getDataValuesForDataType }) => (dataType, periodStartUTC, periodEndUTC) => {
        const values = getDataValuesForDataType(dataType);
        const valuesInPeriod = getValuesBetweenUTCDates(values, periodStartUTC, periodEndUTC);

        if (valuesInPeriod.length > 0) {
            return valuesInPeriod.reduce((total, valueEntry) => {
                return total + valueEntry.value;
            }, 0);
        }

        return 0;
    },

    getDataTypeValuesAverageInTimePeriod: (state, { getDataValuesForDataType, getDataTypeValuesSumInTimePeriod }) => (dataType, periodStartUTC, periodEndUTC) => {
        const values = getDataValuesForDataType(dataType);
        const valuesInPeriod = getValuesBetweenUTCDates(values, periodStartUTC, periodEndUTC);

        const valuesSum = getDataTypeValuesSumInTimePeriod(dataType, periodStartUTC, periodEndUTC);

        if (valuesInPeriod.length > 0) {
            return (valuesSum / valuesInPeriod.length) * 100;
        }

        return 0;
    },

    getDataTypeSumTrendPercent: (state, { getDataTypeValuesSumInTimePeriod }) => (dataType, currentPeriodStartUTC, currentPeriodEndUTC, previousPeriodStartUTC, previousPeriodEndUTC) => {
        const valuesSumInCurrentPeriod = getDataTypeValuesSumInTimePeriod(dataType, currentPeriodStartUTC, currentPeriodEndUTC);
        const valuesSumInPreviousPeriod = getDataTypeValuesSumInTimePeriod(dataType, previousPeriodStartUTC, previousPeriodEndUTC);

        let percent = 0;

        if (valuesSumInPreviousPeriod !== 0) {
            percent = (valuesSumInCurrentPeriod - valuesSumInPreviousPeriod) / valuesSumInPreviousPeriod;
        }

        return percent * 100;
    },

    getDataTypeAverageTrendPercent: (state, { getDataTypeValuesAverageInTimePeriod }) => (dataType, currentPeriodStartUTC, currentPeriodEndUTC, previousPeriodStartUTC, previousPeriodEndUTC) => {
        const valuesAverageInCurrentPeriod = getDataTypeValuesAverageInTimePeriod(dataType, currentPeriodStartUTC, currentPeriodEndUTC);
        const valuesAverageInPreviousPeriod = getDataTypeValuesAverageInTimePeriod(dataType, previousPeriodStartUTC, previousPeriodEndUTC);

        let percent = 0;

        if (valuesAverageInPreviousPeriod !== 0) {
            percent = (valuesAverageInCurrentPeriod - valuesAverageInPreviousPeriod) / valuesAverageInPreviousPeriod;
        }

        return percent * 100;
    },

    getPercentDisplayValue: () => (percent, includeSign = true) => {
        let percentAsStr = '';

        const absTrend = Math.abs(percent);

        if (absTrend === 0) {
            percentAsStr = '0';
        } else if (absTrend >= 10) {
            percentAsStr = absTrend.toFixed(0);
        } else if (absTrend >= 1) {
            percentAsStr = absTrend.toFixed(1);
        } else {
            percentAsStr = absTrend.toFixed(2);

            if (percentAsStr === '0.00') {
                percentAsStr = '0.01';
            }
        }

        percentAsStr = `${percentAsStr}%`;

        if (includeSign && percent < 0) {
            percentAsStr = `${'-'}${percentAsStr}`;
        }

        return percentAsStr;
    },
};
