export const ACCOUNT_TYPE = {
    WEPAY: 'WePay',
    AUTHORIZE: 'Authorize',
    PAYPAL: 'PayPal',
    STRIPE: 'Stripe',
    NEXUS: 'Nexus',
    EWAY: 'EWay',
};

export const STATUS_TYPE = {
    SENT: 'sent',
    ACCEPTED: 'accepted',
};

export const THANK_YOU_PAGE_TYPES = {
    HTML: 'HTML',
    REDIRECT: 'REDIRECT',
};

export const CHECKOUT_FORM_BASE_ROUTE_NAME = 'checkout.forms.edit';

export const PAYMENT_TYPES = {
    PAY_PAL: 'payPal',
    CREDIT_CARD: 'creditCard',
};

export const CHECKOUT_FORM_STATUS = {
    LEGACY: 'LEGACY',
    DRAFT: 'DRAFT',
    LIVE: 'LIVE',
};

export const SAVE_FIELDS = {
    PRODUCTS: 'products',
    EDIT_PRODUCTS: 'editProducts',
    UPSELLS: 'upsells',
    PROMOS_ENABLED: 'promosEnabled',
    THANK_YOU_URL: 'thankYouUrl',
    THANK_YOU_OPTION: 'thankYouOption',
};
