<template>
    <controlled-modal
        is-open
        show-close
        intercept-close
        class="create-custom-field-modal"
        :title="title"
        @close="closeAddCustomFieldModal"
    >
        <template v-if="isCustomFieldSettingsEnabled" #header>
            <ds-icon-button
                class="header"
                name="help-circle"
                data-qa="help-circle"
                @click.stop="helpLink"
            />
        </template>

        <div v-if="loading" class="spinner-container" data-qa="loading-spinner">
            <ds-spinner />
        </div>
        <contact-add-custom-field
            v-else
            standalone
            :create-custom-field="createCustomField || defaultCreateCustomField"
            class="add-custom-field-component wide-field"
            field-name=""
            :record-type="recordType"
            :field-count="fieldCount"
            :field-max="fieldMax"
            :existing-fields="customFields"
            :existing-field-name-prop="existingFieldNameProperty"
            :event-source="eventSource"
            @added="newCustomFieldAdded"
            @cancel="closeAddCustomFieldModal"
            @contentUpdated="handleContentUpdated"
        >
            <template #description>
                <i18n tag="p" :path="`description.${recordType}`">
                    <template #remaining>
                        <strong>{{ $t('fieldsRemaining', { customFieldsAvailable }) }}</strong>
                    </template>
                </i18n>

                <custom-field-status
                    :existing="fieldCount"
                    :max="fieldMax"
                    :record-type="recordType"
                    :object-name="$t('objectName')"
                />
            </template>
        </contact-add-custom-field>
    </controlled-modal>
</template>

<script>
import redirectMixin from '@/shared/mixins/redirect.mixin';
import ConfirmNavigationIfDirty from '@/shared/mixins/ConfirmNavigationIfDirty.mixin';

import ControlledModal from '@/shared/components/ControlledModal';
import ContactAddCustomField from '@/contacts/components/details/ContactAddCustomField';
import CustomFieldStatus from '@/contacts/components/customFields/CustomFieldStatus';
import {
    FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS,
    KEAP_CUSTOM_FIELDS_NAVIGATION_GUARD,
    KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT,
} from '@/shared/constants/featureFlag.constants';
import { createCustomField } from '@/customFields/customFields.api';

import sentry from '@/analytics/sentry';
import { mapState } from 'vuex';

export default {
    components: {
        ControlledModal,
        ContactAddCustomField,
        CustomFieldStatus,
    },

    mixins: [redirectMixin, ConfirmNavigationIfDirty],

    props: {
        recordType: {
            type: String,
            default: 'contact',
        },
        createCustomField: Function,
        customFields: Array,
        fieldCount: Number,
        fieldMax: Number,
        eventSource: {
            type: String,
            required: true,
        },
        existingFieldNameProp: {
            type: String,
            required: false,
            default: 'name',
        },
        confirmNavigationIfDirty: Boolean,
    },

    data() {
        return {
            loading: true,
            isDirty: false,
        };
    },

    async mounted() {
        await this.$store.dispatch('contacts/LOAD_CUSTOM_FIELDS').catch((e) => {
            this.$error({ message: this.$t('loadingError') });
            sentry.captureException(e);
        });

        this.loading = false;
    },

    computed: {
        ...mapState({
            isCustomFieldSettingsEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS],
            isNavigationGuardEnabled: ({ featureFlags }) => featureFlags[KEAP_CUSTOM_FIELDS_NAVIGATION_GUARD],
            isCustomFieldTechDebtEnabled: ({ featureFlags }) => featureFlags[KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT],
        }),

        title() {
            return this.$t(`modalTitle.${this.recordType}`);
        },

        customFieldsAvailable() {
            return this.fieldMax - this.fieldCount;
        },

        // This and existingNameFieldProp can be removed with KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
        existingFieldNameProperty() {
            if (this.isCustomFieldTechDebtEnabled) {
                return 'label';
            }

            return this.existingFieldNameProp;
        },
    },

    methods: {
        handleContentUpdated(isDirty) {
            if (isDirty) {
                this.$store.commit('CONFIRM_NAVIGATION_MARK_DIRTY', 'createCustomField');
            } else {
                this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'createCustomField');
            }
        },

        newCustomFieldAdded(field) {
            this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'createCustomField');
            this.$emit('new-field-added', field);
        },

        closeAddCustomFieldModal() {
            if (this.isNavigationGuardEnabled && this.confirmNavigationIfDirty) {
                this.dirty_confirmNavigationWhenDirty()
                    .then(() => {
                        this.$emit('close');
                    })
                    .catch(() => {
                        this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'createCustomField');
                    });
            } else {
                this.$emit('close');
            }
        },

        defaultCreateCustomField(payload) {
            if (this.isCustomFieldTechDebtEnabled) {
                payload.recordType = this.recordType;

                return createCustomField(payload).catch((e) => {
                    this.$error({ message: this.$t('error') });
                    sentry.captureException(e);
                });
            }

            return this.$store.dispatch('contacts/CREATE_CUSTOM_FIELD', payload).catch((e) => {
                this.$error({ message: this.$t('error') });
                sentry.captureException(e);
            });
        },

        helpLink() {
            this.redirect_url_new_tab(`${process.env.VUE_APP_KNOWLEDGEOWL_URL}/help/custom-field-types`);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
.header {
    margin-right: $gp / 2;
}
</style>

<i18n>
{
    "en-us": {
        "modalTitle" : {
            "contact": "Create custom contact field",
            "company": "Create custom company field"
        },
        "error": "There was an error adding your custom field",
        "loadingError": "There was an error loading your custom fields",
        "description": {
            "contact": "You can create up to {remaining} contact fields. This is a global action. Any fields you add here will be accessible from all of your contacts.",
            "company": "You can create up to {remaining} company fields. This is a global action. Any fields you add here will be accessible from all of your companies."
        },
        "fieldsRemaining": "{customFieldsAvailable} more",
        "objectName": "custom field"
    }
}
</i18n>
