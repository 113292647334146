<template lang="html">
    <div class="attachment" :class="{ 'deleting deleting-fade': deleting }">
        <span class="file-type">{{ fileType }}</span>
        <span class="file-name">{{ attachment.name }}</span>
        <ds-icon-button
            class="file-delete-icon"
            name="trash-2"
            type="button"
            :data-qa="qaDeleteAttachment"
            @click="deleteAttachment"
        />
    </div>
</template>

<script>

export default {
    props: {
        attachment: [Object, File],
        index: Number,
    },

    data() {
        return {
            animationDelay: 500,
            deleting: false,
        };
    },

    computed: {
        qaDeleteAttachment() {
            return `delete-attachment-button-${this.index}`;
        },

        fileType() {
            const fileName = this.attachment.name || '';

            return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
        },
    },

    methods: {
        deleteAttachment() {
            const payload = {
                id: this.attachment.id,
                index: this.index,
            };

            this.deleting = true;

            return new Promise((resolve) => {
                setTimeout(() => {
                    this.$emit('delete', payload);
                    resolve();
                }, this.animationDelay);
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .attachment {
        display: flex;
        vertical-align: middle;
        margin-bottom: $gp / 2
    }

    .deleting {
        background-color: rgba($color-red, .2);
        color: $color-red;
    }

    .deleting-fade {
        opacity: 0;
        transition: opacity .5s ease-in-out;
        -moz-transition: opacity .5s ease-in-out;
        -webkit-transition: opacity .5s ease-in-out;
    }

    .file-delete-icon {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
    }

    .file-name {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        @include margin-end($gp / 2);
    }

    .file-type {
        background-color: $color-gray-800;
        border-radius: $border-radius;
        color: $color-paper;
        @include margin-end($gp / 2);
        font-size: $font-size-xsmall;
        height: px-to-rem(40px);
        width: px-to-rem(40px);
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
