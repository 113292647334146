import gql from 'graphql-tag';
import Vue from 'vue';

const QUERY_LOAD_TASK = gql`
    query loadTask($taskId: ID!) {
        task(taskId: $taskId) {
            id
            title
            notes
            remindTimeOffsetInMillis
            dueDate
            dateCreated
            modificationDate
            completionDate
            contactId
            assignedUserId
            contact {
                id
                fullName
                email
                createDate
            }
            outcomeOptions {
                id
                name
            }
            outcomeSelections {
                id
                name
            }
        }
    }
`;

const QUERY_LOAD_APP_TASKS = gql`
    query loadAppTasks($limit: Int, $offset: Int, $completed: Boolean, $assignedUserId: ID) {
        appTasks(completed: $completed, limit: $limit, offset: $offset, assignedUserId: $assignedUserId) {
            total
            tasks {
                id
                title
                notes
                remindTimeOffsetInMillis
                dueDate
                dateCreated
                modificationDate
                completionDate
                contactId
                assignedUserId
                contact {
                    id
                    fullName
                    email
                    createDate
                }
                outcomeOptions {
                    id
                    name
                }
                outcomeSelections {
                    id
                    name
                }
            }
        }
    }
`;

const QUERY_LOAD_APP_TASKS_BY_DATE = gql`
    query loadAppTasksByRange($limit: Int, $offset: Int, $completed: Boolean, $assignedUserId: ID, $since: String, $until: String) {
        appTasks(completed: $completed, limit: $limit, offset: $offset, assignedUserId: $assignedUserId, since: $since, until: $until) {
            tasks {
                id
                title
                dueDate
                modificationDate
                completionDate
                contact {
                    id
                    fullName
                    firstName
                    lastName
                    email
                }
            }
        }
    }
`;

const QUERY_LOAD_TASK_LIST = gql`
    query taskList($contactId: ID!) {
        taskList(contactId: $contactId) {
            id
            title
            notes
            remindTimeOffsetInMillis
            dueDate
            dateCreated
            modificationDate
            completionDate
            contactId
            assignedUserId
            contact {
                id
                fullName
                email
                createDate
            }
            outcomeOptions {
                id
                name
            }
            outcomeSelections {
                id
                name
            }
        }
    }
`;

const MUTATION_ADD_TASK = gql`
    mutation createTask($createTask: CreateTaskInput!) {
        createTask(task: $createTask) {
            total
            tasks {
                id
                title
                notes
                remindTimeOffsetInMillis
                dueDate
                dateCreated
                modificationDate
                completionDate
                contactId
                assignedUserId
                contact {
                    id
                    fullName
                    email
                    createDate
                }
            }
        }
    }`;

const MUTATION_ADD_TASKS = gql`
    mutation createTasks($createTasks: CreateTaskListInput!) {
        createTasks(taskList: $createTasks) {
            total
            tasks {
                id
                title
                notes
                remindTimeOffsetInMillis
                dueDate
                dateCreated
                modificationDate
                completionDate
                contactId
                assignedUserId
                contact {
                    id
                    fullName
                    email
                    createDate
                }
            }
        }
    }`;

const MUTATION_DELETE_TASK = gql`mutation deleteTask($taskId: ID!) { deleteTask(taskId: $taskId) }`;

const MUTATION_UPDATE_TASK = gql`
    mutation updateTask($updateTask: UpdateTaskInput!, $taskId: ID!) {
        updateTask(task: $updateTask, taskId: $taskId) {
            id
            title
            notes
            remindTimeOffsetInMillis
            dueDate
            dateCreated
            modificationDate
            completionDate
            contactId
            assignedUserId
            contact {
                id
                fullName
                email
                createDate
            }
        }
    }
`;

const MUTATION_UPDATE_TASK_WITH_OUTCOME = gql`
    mutation updateTaskWithOutcome($updateTaskWithOutcome: UpdateTaskWithOutcomeInput!, $taskId: ID!) {
        updateTaskWithOutcome(task: $updateTaskWithOutcome, taskId: $taskId) {
            id
            title
            notes
            remindTimeOffsetInMillis
            dueDate
            dateCreated
            modificationDate
            completionDate
            contactId
            assignedUserId
            contact {
                id
                fullName
                email
                createDate
            }
            outcomeOptions {
                id
                name
            }
            outcomeSelections {
                id
                name
            }
        }
    }
`;

export const loadTask = (taskId) => {
    return Vue.prototype.$graphql.query({
        query: QUERY_LOAD_TASK,
        variables: { taskId },
        fetchPolicy: 'no-cache',
    });
};

export const loadTaskList = (contactId) => {
    return Vue.prototype.$graphql.query({
        query: QUERY_LOAD_TASK_LIST,
        variables: { contactId },
        fetchPolicy: 'no-cache',
    });
};

export const loadAppTasks = ({
    limit = 20,
    offset = 0,
    completed = false,
    assignedUserId = 0,
}) => {
    return Vue.prototype.$graphql.query({
        query: QUERY_LOAD_APP_TASKS,
        variables: {
            limit,
            offset,
            completed,
            assignedUserId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const loadAppTasksByDate = ({
    offset = 0,
    completed = undefined,
    assignedUserId = 0,
    since = '',
    until = '',
}) => {
    const variables = {
        offset,
        since,
        until,
    };

    if (completed !== undefined) {
        variables.completed = completed;
    }

    if (assignedUserId !== 0) {
        variables.assignedUserId = assignedUserId;
    }

    return Vue.prototype.$graphql.query({
        query: QUERY_LOAD_APP_TASKS_BY_DATE,
        variables,
        fetchPolicy: 'no-cache',
    });
};

export const deleteTask = (taskId) => {
    return Vue.prototype.$graphql.mutate({
        mutation: MUTATION_DELETE_TASK,
        variables: { taskId },
        fetchPolicy: 'no-cache',
    });
};

export const addTask = (createTask) => {
    return Vue.prototype.$graphql.mutate({
        mutation: MUTATION_ADD_TASK,
        variables: { createTask },
        fetchPolicy: 'no-cache',
    });
};

export const addTasks = (createTasks) => {
    return Vue.prototype.$graphql.mutate({
        mutation: MUTATION_ADD_TASKS,
        variables: { createTasks },
        fetchPolicy: 'no-cache',
    });
};

export const updateTask = (updatedTask, taskId) => {
    return Vue.prototype.$graphql.mutate({
        mutation: MUTATION_UPDATE_TASK,
        variables: { updateTask: updatedTask, taskId },
        fetchPolicy: 'no-cache',
    });
};

export const updateTaskWithOutcome = (updatedTaskWithOutcome, taskId) => {
    return Vue.prototype.$graphql.mutate({
        mutation: MUTATION_UPDATE_TASK_WITH_OUTCOME,
        variables: { updateTaskWithOutcome: updatedTaskWithOutcome, taskId },
        fetchPolicy: 'no-cache',
    });
};
