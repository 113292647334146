import {
    FF_CUSTOM_FORM_GROUPED_FIELDS,
} from '@/shared/constants/featureFlag.constants';

export const INPUT_TYPE_SELECT = 'Select';
export const INPUT_TYPE_RADIO = 'Radio';
export const INPUT_TYPE_CHECKBOX = 'Checkboxes';
export const INPUT_TYPE_TEXT = 'Text';
export const INPUT_TYPE_TEXT_AREA = 'TextArea';
export const INPUT_TYPE_DATE = 'Date';
export const INPUT_TYPE_COMPANY = 'Company';
export const INPUT_TYPE_CURRENCY = 'Currency';
export const INPUT_TYPE_DECIMAL = 'Decimal';
export const INPUT_TYPE_EMAIL = 'Email';
export const INPUT_TYPE_MULTISELECT = 'MultiSelect';
export const INPUT_TYPE_NUMBER = 'Number';
export const INPUT_TYPE_PHONE = 'Phone';
export const INPUT_TYPE_URL = 'Url';
export const INPUT_TYPE_YEAR = 'Year';
export const INPUT_TYPE_CREDIT_CARD = 'CreditCard';
export const INPUT_OPTIONS_TYPE_COUNTRY = 'Country';
export const FIELD_TYPE_EXTERNAL = 'External';
export const INPUT_TYPE_PHONE_GROUP = 'PhoneGroup';
export const INPUT_TYPE_ADDRESS_GROUP = 'AddressGroup';
export const INPUT_OPTIONS_TYPE_REGION = 'Region';

export const BUTTON_ALIGNMENT = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
    FULL_WIDTH: 'full width',
};
export const BUTTON_BACKGROUND_COLOR = '#2C2C2C';
export const BUTTON_TEXT_COLOR = '#ffffff';
export const FORM_PAGE_BACKGROUND_COLOR = '#fafafa';
export const CHECKOUT_BUTTON_BACKGROUND_COLOR = '#000000';
export const CHECKOUT_BUTTON_BUTTON_TEXT_COLOR = '#ffffff';
export const CHECKOUT_FORM_BACKGROUND_COLOR = '#ffffff';

export const PUBLISH_STEP = 'publish';
export const FORM_ONLY_FIELD_TYPE = 'FormOnlyField';
export const INTERNAL_DEFAULT_TEMPLATE_TYPE = 'intake form';
export const PUBLIC_DEFAULT_TEMPLATE_TYPE = 'lead form';

export const PLAIN_TEXT_INPUT_TYPES = [INPUT_TYPE_TEXT, INPUT_TYPE_URL, INPUT_TYPE_EMAIL, INPUT_TYPE_PHONE, INPUT_TYPE_DECIMAL, INPUT_TYPE_NUMBER, INPUT_TYPE_CURRENCY, INPUT_TYPE_YEAR];

export const FORM_STATUSES = {
    PUBLISHED: 'Published',
    NOT_PUBLISHED: 'NotPublished',
};

export const FORM_DISPLAY_TYPES = {
    HOSTED: 'Hosted',
    IFRAME: 'IFrame',
    IN_APP: 'InApp',
};

export const STANDARD_GIVEN_NAME_FIELD_ID = 'standard.givenName';
export const STANDARD_FAMILY_NAME_FIELD_ID = 'standard.familyName';
export const STANDARD_EMAIL1_FIELD_ID = 'standard.emailAddress1';
export const STANDARD_PHONE1_FIELD_ID = 'standard.phoneNumber1.value';
export const STANDARD_PHONE1_TYPE_FIELD_ID = 'standard.phoneNumber1.type';
export const STANDARD_TAG_FIELD_ID = 'standard.tag';
export const STANDARD_CREDIT_CARD_FIELD_ID = 'standard.creditCard';
export const STANDARD_NOTE_FIELD_ID = 'standard.note';
export const STANDARD_COMPANY_FIELD_ID = 'standard.company';
export const STANDARD_BIRTHDAY_FIELD_ID = 'standard.birthDate';
export const STANDARD_WEBSITE_FIELD_ID = 'standard.website';
export const STANDARD_BILLING_POSTAL_CODE_FIELD_ID = 'standard.billingAddress.postalCode';
export const STANDARD_BILLING_ADDRESS_GROUP = 'standard.billingAddressGroup';
export const STANDARD_BILLING_ADDRESS_REGION_ID = 'standard.billingAddress.region';
export const STANDARD_BILLING_ADDRESS_COUNTRY_CODE_ID = 'standard.billingAddress.countryCode';
export const STANDARD_SHIPPING_ADDRESS_GROUP = 'standard.shippingAddressGroup';
export const STANDARD_OPTIONAL_ADDRESS_GROUP = 'standard.optionalAddressGroup';

export const BILLING_ADDRESS = 'BillingAddress';
export const SHIPPING_ADDRESS = 'ShippingAddress';
export const OPTIONAL_ADDRESS = 'OptionalAddress';

export const FORM_CHECKOUT_PUBLIC = 'Checkout-Public';
export const FORM_CONTACTS_PUBLIC = 'Contacts-Public';
export const FORM_CONTACTS_INTERNAL = 'Contacts-Internal';

export const FORM_TYPE_CONTACTS = 'Contacts';
export const FORM_TYPE_CHECKOUT = 'Checkout';
export const FORM_TYPE_LANDING_PAGE = 'LandingPage';
export const FORM_AUDIENCE_PUBLIC = 'Public';
export const FORM_AUDIENCE_INTERNAL = 'Internal';

export const SOURCE_TYPE_INTERNAL = 'InternalForm';
export const SOURCE_TYPE_LEAD_PAGE = 'LeadPage';
export const SOURCE_TYPE_CHECKOUT_PAGE = 'CheckoutPage';

export const FORM_BACKGROUND_COLOR = '--form-page-background-color';
export const FORM_BUTTON_BACKGROUND_COLOR = '--submit-button-background-color';
export const FORM_BUTTON_TEXT_COLOR = '--submit-button-text-color';
export const FORM_BUTTON_ALIGNMENT = '--submit-button-alignment';

export const DEFAULT_LEADFORM = {
    formType: FORM_TYPE_CONTACTS,
    description: '',
    status: FORM_STATUSES.NOT_PUBLISHED,
    audience: FORM_AUDIENCE_PUBLIC,
    usesCustomFieldDataFormFieldId: true,
};

export const DEFAULT_CHECKOUT_FORM = {
    formType: FORM_TYPE_CHECKOUT,
    audience: FORM_AUDIENCE_PUBLIC,
    usesCustomFieldDataFormFieldId: true,
    fields: [
        {
            id: STANDARD_CREDIT_CARD_FIELD_ID,
            fieldType: FIELD_TYPE_EXTERNAL,
            defaultValues: [],
        },
    ],
};

export const DEFAULT_INTERNAL_FORM = {
    formType: FORM_TYPE_CONTACTS,
    status: FORM_STATUSES.NOT_PUBLISHED,
    audience: FORM_AUDIENCE_INTERNAL,
    usesCustomFieldDataFormFieldId: true,
};
const PHONE_1_FIELDS = [{
    id: STANDARD_PHONE1_FIELD_ID, legacyId: 'PHONE1', group: 'PhoneAndEmail', inputType: INPUT_TYPE_PHONE, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
},
{
    id: STANDARD_PHONE1_TYPE_FIELD_ID, legacyId: 'PHONE1_TYPE', group: 'PhoneAndEmail', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'PhoneType', reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
}, {
    id: 'standard.phoneNumber1.smsOptin', inputType: INPUT_TYPE_CHECKBOX, fieldType: FIELD_TYPE_EXTERNAL,
},
];

const PHONE_2_FIELDS = [{
    id: 'standard.phoneNumber2.value', legacyId: 'PHONE2', group: 'PhoneAndEmail', inputType: INPUT_TYPE_PHONE, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
},
{
    id: 'standard.phoneNumber2.type', legacyId: 'PHONE2_TYPE', group: 'PhoneAndEmail', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'PhoneType', reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
}, {
    id: 'standard.phoneNumber2.smsOptin', inputType: INPUT_TYPE_CHECKBOX, fieldType: FIELD_TYPE_EXTERNAL,
}];

const PHONE_3_FIELDS = [{
    id: 'standard.phoneNumber3.value', legacyId: 'PHONE3', group: 'PhoneAndEmail', inputType: INPUT_TYPE_PHONE, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
},
{
    id: 'standard.phoneNumber3.type', legacyId: 'PHONE3_TYPE', group: 'PhoneAndEmail', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'PhoneType', reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
}, {
    id: 'standard.phoneNumber3.smsOptin', inputType: INPUT_TYPE_CHECKBOX, fieldType: FIELD_TYPE_EXTERNAL,
}];

const PHONE_4_FIELDS = [{
    id: 'standard.phoneNumber4.value', legacyId: 'PHONE4', group: 'PhoneAndEmail', inputType: INPUT_TYPE_PHONE, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
},
{
    id: 'standard.phoneNumber4.type', legacyId: 'PHONE4_TYPE', group: 'PhoneAndEmail', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'PhoneType', reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
}, {
    id: 'standard.phoneNumber4.smsOptin', inputType: INPUT_TYPE_CHECKBOX, fieldType: FIELD_TYPE_EXTERNAL,
}];

const PHONE_5_FIELDS = [{
    id: 'standard.phoneNumber5.value', legacyId: 'PHONE5', group: 'PhoneAndEmail', inputType: INPUT_TYPE_PHONE, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
},
{
    id: 'standard.phoneNumber5.type', legacyId: 'PHONE5_TYPE', group: 'PhoneAndEmail', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'PhoneType', reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
}, {
    id: 'standard.phoneNumber5.smsOptin', inputType: INPUT_TYPE_CHECKBOX, fieldType: FIELD_TYPE_EXTERNAL,
}];

const FAX_1_FIELDS = [{
    id: 'standard.faxNumber1.value', legacyId: 'FAX1', group: 'PhoneAndEmail', inputType: INPUT_TYPE_PHONE, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
},
{
    id: 'standard.faxNumber1.type', legacyId: 'FAX1_TYPE', group: 'PhoneAndEmail', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'FaxType', reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
}];

export const FAX_2_FIELDS = [{
    id: 'standard.faxNumber2.value', legacyId: 'FAX2', group: 'PhoneAndEmail', inputType: INPUT_TYPE_PHONE, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
},
{
    id: 'standard.faxNumber2.type', legacyId: 'FAX2_TYPE', group: 'PhoneAndEmail', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'FaxType', reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, fieldType: FIELD_TYPE_EXTERNAL,
}];

export const BILLING_ADDRESS_FIELDS = [
    {
        id: 'standard.billingAddress.streetAddress', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'STREET_ADDRESS1', group: BILLING_ADDRESS, inputType: INPUT_TYPE_TEXT, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS,
    },
    {
        id: 'standard.billingAddress.extendedAddress', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'STREET_ADDRESS2', group: BILLING_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.billingAddress.locality', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'CITY', group: BILLING_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: STANDARD_BILLING_ADDRESS_REGION_ID, fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'STATE', group: BILLING_ADDRESS, inputType: INPUT_TYPE_SELECT, inputOptionsType: INPUT_OPTIONS_TYPE_REGION, relatedId: STANDARD_BILLING_ADDRESS_COUNTRY_CODE_ID,
    },
    {
        id: STANDARD_BILLING_POSTAL_CODE_FIELD_ID, fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'POSTAL_CODE', group: BILLING_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: STANDARD_BILLING_ADDRESS_COUNTRY_CODE_ID, fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'COUNTRY', group: BILLING_ADDRESS, inputType: INPUT_TYPE_SELECT, inputOptionsType: INPUT_OPTIONS_TYPE_COUNTRY,
    },
];

export const SHIPPING_ADDRESS_FIELDS = [
    {
        id: 'standard.shippingAddress.streetAddress', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'ADDRESS2_STREET1', group: SHIPPING_ADDRESS, inputType: INPUT_TYPE_TEXT, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS,
    },
    {
        id: 'standard.shippingAddress.extendedAddress', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'ADDRESS2_STREET2', group: SHIPPING_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.shippingAddress.locality', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'CITY2', group: SHIPPING_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.shippingAddress.region', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'STATE2', group: SHIPPING_ADDRESS, inputType: INPUT_TYPE_SELECT, inputOptionsType: INPUT_OPTIONS_TYPE_REGION, relatedId: 'standard.shippingAddress.countryCode',
    },
    {
        id: 'standard.shippingAddress.postalCode', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'POSTAL_CODE2', group: SHIPPING_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.shippingAddress.countryCode', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'COUNTRY2', group: SHIPPING_ADDRESS, inputType: INPUT_TYPE_SELECT, inputOptionsType: INPUT_OPTIONS_TYPE_COUNTRY,
    },
];

export const OPTIONAL_ADDRESS_FIELDS = [
    {
        id: 'standard.otherAddress.streetAddress', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'ADDRESS3_STREET1', group: OPTIONAL_ADDRESS, inputType: INPUT_TYPE_TEXT, reverseInternalOnlyFeatureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS,
    },
    {
        id: 'standard.otherAddress.extendedAddress', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'ADDRESS3_STREET2', group: OPTIONAL_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.otherAddress.locality', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'CITY3', group: OPTIONAL_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.otherAddress.region', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'STATE3', group: OPTIONAL_ADDRESS, inputType: INPUT_TYPE_SELECT, inputOptionsType: INPUT_OPTIONS_TYPE_REGION, relatedId: 'standard.otherAddress.countryCode',
    },
    {
        id: 'standard.otherAddress.postalCode', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'POSTAL_CODE3', group: OPTIONAL_ADDRESS, inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.otherAddress.countryCode', fieldType: FIELD_TYPE_EXTERNAL, legacyId: 'COUNTRY3', group: OPTIONAL_ADDRESS, inputType: INPUT_TYPE_SELECT, inputOptionsType: INPUT_OPTIONS_TYPE_COUNTRY,
    },
];

export const STANDARD_FIELDS = [
    // General
    {
        id: STANDARD_GIVEN_NAME_FIELD_ID, legacyId: 'FIRST_NAME', group: 'General', inputType: INPUT_TYPE_TEXT,
    },
    {
        id: STANDARD_FAMILY_NAME_FIELD_ID, legacyId: 'LAST_NAME', group: 'General', inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.jobTitle', legacyId: 'JOB_TITLE', group: 'General', inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.owner', legacyId: 'OWNER', group: 'General', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'User', internalOnly: true,
    },
    {
        id: 'standard.company', legacyId: 'COMPANY', group: 'General', inputType: INPUT_TYPE_COMPANY, inputOptionsType: 'Company', internalOnly: true,
    },
    {
        id: 'standard.contactType', legacyId: 'CONTACT_TYPE', group: 'General', inputType: INPUT_TYPE_SELECT, inputOptionsType: 'ContactType', internalOnly: true,
    },
    // Phone and email
    ...PHONE_1_FIELDS,
    ...PHONE_2_FIELDS,
    ...PHONE_3_FIELDS,
    ...PHONE_4_FIELDS,
    ...PHONE_5_FIELDS,
    ...FAX_1_FIELDS,
    ...FAX_2_FIELDS,
    {
        id: STANDARD_EMAIL1_FIELD_ID, legacyId: 'EMAIL', group: 'PhoneAndEmail', inputType: INPUT_TYPE_EMAIL,
    },
    {
        id: 'standard.phoneGroup1', group: 'PhoneAndEmail', fieldType: 'Group', inputType: INPUT_TYPE_PHONE_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...PHONE_1_FIELDS],
    },
    {
        id: 'standard.phoneGroup2', group: 'PhoneAndEmail', fieldType: 'Group', inputType: INPUT_TYPE_PHONE_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...PHONE_2_FIELDS],
    },
    {
        id: 'standard.phoneGroup3', group: 'PhoneAndEmail', fieldType: 'Group', inputType: INPUT_TYPE_PHONE_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...PHONE_3_FIELDS],
    },
    {
        id: 'standard.phoneGroup4', group: 'PhoneAndEmail', fieldType: 'Group', inputType: INPUT_TYPE_PHONE_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...PHONE_4_FIELDS],
    },
    {
        id: 'standard.phoneGroup5', group: 'PhoneAndEmail', fieldType: 'Group', inputType: INPUT_TYPE_PHONE_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...PHONE_5_FIELDS],
    },
    {
        id: 'standard.faxGroup1', group: 'PhoneAndEmail', fieldType: 'Group', inputType: INPUT_TYPE_PHONE_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...FAX_1_FIELDS],
    },
    {
        id: 'standard.faxGroup2', group: 'PhoneAndEmail', fieldType: 'Group', inputType: INPUT_TYPE_PHONE_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...FAX_2_FIELDS],
    },
    {
        id: 'standard.emailAddress2', legacyId: 'EMAIL_ADDRESS2', group: 'PhoneAndEmail', inputType: INPUT_TYPE_EMAIL,
    },
    {
        id: 'standard.emailAddress3', legacyId: 'EMAIL_ADDRESS3', group: 'PhoneAndEmail', inputType: INPUT_TYPE_EMAIL,
    },
    // Address
    ...BILLING_ADDRESS_FIELDS,
    {
        id: STANDARD_BILLING_ADDRESS_GROUP, group: 'Addresses', fieldType: 'Group', inputType: INPUT_TYPE_ADDRESS_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...BILLING_ADDRESS_FIELDS],
    },
    ...SHIPPING_ADDRESS_FIELDS,
    {
        id: STANDARD_SHIPPING_ADDRESS_GROUP, group: 'Addresses', fieldType: 'Group', inputType: INPUT_TYPE_ADDRESS_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...SHIPPING_ADDRESS_FIELDS],
    },
    ...OPTIONAL_ADDRESS_FIELDS,
    {
        id: STANDARD_OPTIONAL_ADDRESS_GROUP, group: 'Addresses', fieldType: 'Group', inputType: INPUT_TYPE_ADDRESS_GROUP, publicOnly: true, featureFlag: FF_CUSTOM_FORM_GROUPED_FIELDS, childFields: [...OPTIONAL_ADDRESS_FIELDS],
    },
    // Social
    {
        id: STANDARD_WEBSITE_FIELD_ID, legacyId: 'WEBSITE', group: 'Social', inputType: INPUT_TYPE_URL,
    },
    {
        id: 'standard.twitter', legacyId: 'TWITTER', group: 'Social', inputType: INPUT_TYPE_URL,
    },
    {
        id: 'standard.facebook', legacyId: 'FACEBOOK', group: 'Social', inputType: INPUT_TYPE_URL,
    },
    {
        id: 'standard.linkedIn', legacyId: 'LINKEDIN', group: 'Social', inputType: INPUT_TYPE_URL,
    },
    // Additional
    {
        id: 'standard.spouseName', legacyId: 'SPOUSE_NAME', group: 'Additional', inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.title', legacyId: 'TITLE', group: 'Additional', inputType: INPUT_TYPE_SELECT,
    },
    {
        id: 'standard.suffix', legacyId: 'SUFFIX', group: 'Additional', inputType: INPUT_TYPE_SELECT, internalOnly: true,
    },
    {
        id: 'standard.middleName', legacyId: 'MIDDLE_NAME', group: 'Additional', inputType: INPUT_TYPE_TEXT,
    },
    {
        id: 'standard.preferredName', legacyId: 'NICKNAME', group: 'Additional', inputType: INPUT_TYPE_TEXT,
    },
    {
        id: STANDARD_BIRTHDAY_FIELD_ID, legacyId: 'BIRTHDAY', group: 'Additional', inputType: INPUT_TYPE_DATE,
    },
    {
        id: 'standard.anniversary', legacyId: 'ANNIVERSARY', group: 'Additional', inputType: INPUT_TYPE_DATE,
    },
    // Internal
    {
        id: STANDARD_TAG_FIELD_ID, legacyId: 'FORMS_TAGS', group: 'Internal', inputType: INPUT_TYPE_MULTISELECT, inputOptionsType: 'Tags', internalOnly: true,
    },
    {
        id: STANDARD_NOTE_FIELD_ID, legacyId: 'FORMS_NOTE', group: 'Internal', inputType: INPUT_TYPE_TEXT_AREA,
    },
];

export const STANDARD_FIELD_IDS = STANDARD_FIELDS.reduce((fields, field) => {
    fields.push(field.id);
    fields.push(field.legacyId);

    return fields;
}, []);

export const COUNTRY_FIELD_IDS = STANDARD_FIELDS.reduce((fields, field) => {
    if (field.inputOptionsType === 'Country') {
        fields.push(field.id);
        fields.push(field.legacyId);
    }

    return fields;
}, []);

export const CUSTOM_FIELD_GROUP_ID = 'CustomFields';
export const COMPOSITE_FIELDS_GROUP_ID = 'CompositeFields';
export const FORM_ONLY_FIELDS_GROUP_ID = 'FormOnlyFields';
export const INTERNAL_FIELDS_GROUP_ID = 'Internal';

export const FIELD_GROUP_IDS = [
    'General',
    'PhoneAndEmail',
    CUSTOM_FIELD_GROUP_ID,
    'Social',
    'Addresses',
    BILLING_ADDRESS,
    SHIPPING_ADDRESS,
    OPTIONAL_ADDRESS,
    'Additional',
    COMPOSITE_FIELDS_GROUP_ID,
    INTERNAL_FIELDS_GROUP_ID,
    FORM_ONLY_FIELDS_GROUP_ID,
];

export const GROUP_FIELD_IDS = [
    'standard.shippingAddressGroup',
    'standard.billingAddressGroup',
    'standard.optionalAddressGroup',
    'standard.phoneGroup1',
    'standard.phoneGroup2',
    'standard.phoneGroup3',
    'standard.phoneGroup4',
    'standard.phoneGroup5',
    'standard.faxGroup1',
    'standard.faxGroup2',
];

export const DEFAULT_STYLING = {
    options: {
        hideLogo: false,
    },
    properties: {
        buttons: {
            '--submit-button-alignment': BUTTON_ALIGNMENT.LEFT,
            '--submit-button-background-color': BUTTON_BACKGROUND_COLOR,
            '--submit-button-text-color': BUTTON_TEXT_COLOR,
        },

        formPage: {
            '--form-page-background-color': FORM_PAGE_BACKGROUND_COLOR,
        },
    },
};

export const DEFAULT_CHECKOUT_FORM_STYLING = {
    options: {
        hideLogo: true,
    },
    properties: {
        buttons: {
            '--submit-button-alignment': BUTTON_ALIGNMENT.FULL_WIDTH,
            '--submit-button-background-color': CHECKOUT_BUTTON_BACKGROUND_COLOR,
            '--submit-button-text-color': CHECKOUT_BUTTON_BUTTON_TEXT_COLOR,
        },

        formPage: {
            '--form-page-background-color': CHECKOUT_FORM_BACKGROUND_COLOR,
        },
    },
};

export const PUBLIC_FORM_THANK_YOU_ROUTE = 'contactus-thank-you';

export const URL_PARAMETER_STEP = {
    setup: 'setup',
    build: 'build',
    edit: 'edit',
    publish: 'publish',
    automate: 'automate',
};

export const EDIT = 'edit';
export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
