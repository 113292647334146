export const MEDIA_TYPES = {
    HTML: 'HTML',
    PLAIN_TEXT: 'PLAIN_TEXT',
};

export const TEMPLATE_KINDS = {
    INTRODUCTION: 'INTRODUCTION',
    APPOINTMENT: 'APPOINTMENT',
    INVITATION: 'INVITATION',
    FOLLOW_UP: 'FOLLOW_UP',
    REFERRAL: 'REFERRAL',
    REVIEW: 'REVIEW',
    CUSTOM: 'CUSTOM',
    KMN_BROADCAST_CUSTOM: 'KMN_BROADCAST_CUSTOM',
    KMN_BROADCAST_APPOINTMENT: 'KMN_BROADCAST_APPOINTMENT',
    KMN_BROADCAST_REMINDER: 'KMN_BROADCAST_REMINDER',
    KMN_BROADCAST_FOLLOW_UP: 'KMN_BROADCAST_FOLLOW_UP',
    KMN_BROADCAST_DISCOUNT: 'KMN_BROADCAST_DISCOUNT',
};

export const KMN_TEMPLATE_KINDS = [
    TEMPLATE_KINDS.KMN_BROADCAST_CUSTOM,
    TEMPLATE_KINDS.KMN_BROADCAST_APPOINTMENT,
    TEMPLATE_KINDS.KMN_BROADCAST_REMINDER,
    TEMPLATE_KINDS.KMN_BROADCAST_FOLLOW_UP,
    TEMPLATE_KINDS.KMN_BROADCAST_DISCOUNT,
];

export const KBL_TEMPLATE_KINDS = [
    TEMPLATE_KINDS.CUSTOM,
    TEMPLATE_KINDS.INTRODUCTION,
    TEMPLATE_KINDS.APPOINTMENT,
    TEMPLATE_KINDS.INVITATION,
    TEMPLATE_KINDS.FOLLOW_UP,
    TEMPLATE_KINDS.REFERRAL,
    TEMPLATE_KINDS.REVIEW,
];
