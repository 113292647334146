export const APPOINTMENTS = 'appointments';
export const AUTOMATIONS = 'automations';
export const CALLS = 'calls';
export const CONVERSATION = 'conversation';
export const DETAILS = 'details';
export const EMAILS = 'emails';
export const FILES = 'files';
export const FORMS = 'forms';
export const MESSAGES = 'messages';
export const AUTOMATED_MESSAGES = 'automatedMessages';
export const AUTOMATED_MESSAGE_THREAD = 'automatedMessageThread';
export const MONEY = 'money';
export const NOTES = 'notes';
export const PIPELINE = 'pipeline'; // remove this type when removing FF_KEAP_PIPELINE_MIGRATION
export const PIPELINE_V2 = 'pipelineV2';
export const TAGS = 'tags';
export const TASKS = 'tasks';

export const isValidType = (type) => {
    const types = [
        APPOINTMENTS,
        AUTOMATIONS,
        CALLS,
        CONVERSATION,
        AUTOMATED_MESSAGE_THREAD,
        DETAILS,
        EMAILS,
        FILES,
        FORMS,
        MESSAGES,
        AUTOMATED_MESSAGES,
        MONEY,
        NOTES,
        PIPELINE,
        PIPELINE_V2,
        TAGS,
        TASKS,
    ];

    return types.includes(type);
};
