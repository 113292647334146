<template lang="html">
    <div :class="['upload-stage', { 'upload-stage-with-integration': showMailchimpIntegration }]">
        <h2 class="secondary-title mega" data-qa="upload-source-title">
            {{ title }}
        </h2>

        <p v-if="isImportSourceCompetitor">
            {{ $t('competitors.follow') }}

            <a :href="`${helpLink}`">{{ $t(`competitors.${importSource}.capitalName`) }} {{ $t(`competitors.instructions`) }} </a>

            {{ $t('competitors.description') }}
        </p>

        <p v-else>
            {{ $t('description.description1') }}

            <a :href="`${helpLink}${$t('description.descriptionLink1')}`">{{ $t('description.descriptionLink2') }} </a>

            {{ $t('description.description2') }}
        </p>

        <ds-file-upload-input
            id="file-upload-input"
            ref="uploader"
            v-model="uploader.file"
            :allowed-files-label="$t('filesAllowed')"
            :accept="fileTypesAccepted"
            :label="$t('inputLabel')"
            :progress.sync="uploader.progress"
            :max-file-size-bytes="maxFileUploadSize"
            @input="uploadFile($event)"
            @error="handleError"
        />

        <contact-count-indicator />

        <pro-tip
            v-if="!isImportSourceCompetitor"
            :title="$t('proTip.title')"
            :description="$t('proTip.description', { url: articleBaseUrl })"
        />

        <small>
            {{ $t('helpText.help1') }}

            <a :href="`${articleBaseUrl}/help/import-contacts`" target="_blank">{{ $t('helpText.helpLink') }}</a>

            {{ $t('helpText.help2') }}
        </small>

        <ds-modal
            ref="chooseWorkbookSheetDialog"
            size="sm"
            dialog
        >
            <div class="dialog-container">
                <span class="choose-sheet-title">{{ $t('chooseSheetTitle') }}</span>
                <p class="choose-sheet-message">
                    {{ $t('chooseSheetMessage') }}
                </p>
            </div>

            <div v-for="sheetName in workbookSheetNames" :key="sheetName">
                <ds-outline-button
                    class="tab-button"
                    @click="selectWorkbookSheet(sheetName)"
                >
                    {{ sheetName }}
                </ds-outline-button>
            </div>
        </ds-modal>
    </div>
</template>

<script>
import ProTip from '@/import/components/csv/ProTip';
import { mapState } from 'vuex';
import ContactCountIndicator from '@/import/components/csv/ContactCountIndicator';
import { MAX_FILE_UPLOAD_SIZE_IN_BYTES, IMPORT_HELP_LINKS, IMPORT_TYPES } from '@/import/import.constants';

export default {
    components: {
        ContactCountIndicator,
        ProTip,
    },

    props: {
        hideTip: Boolean,
    },

    data() {
        return {
            articleBaseUrl: process.env.VUE_APP_KNOWLEDGEOWL_URL,
            publicUrl: process.env.VUE_APP_PUBLIC_URL,
            uploader: {
                progress: 0,
                file: null,
            },
            delay: 1000,
            fileName: null,
            excelFileName: null,
            workbook: null,
            worksheet: null,
            workbookSheetNames: [],
            selectedSheet: null,
            fileTypesAccepted: '.csv, .xls, .xlsx',
        };
    },

    watch: {
        uploader: {
            handler(newUploader) {
                if (newUploader.file === null) {
                    this.$store.commit('contactImport/RESET_IMPORT_STATE');
                    this.setStageReady('upload', false);
                    this.setStageReady('organize', false);
                }
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            contactImportCount: ({ contactImport }) => contactImport.count,
            importSource: ({ contactImport }) => contactImport.importSource,
            errorCode: ({ contactImport }) => contactImport.error.code,
            activeStage: ({ contactImport }) => contactImport.activeStage,
            maxFileUploadSize: () => MAX_FILE_UPLOAD_SIZE_IN_BYTES,
        }),

        title() {
            return this.isImportSourceCompetitor
                ? this.$t(`competitors.${this.importSource}.title`)
                : this.$t('title');
        },

        helpLink() {
            return this.isImportSourceCompetitor
                ? IMPORT_HELP_LINKS[this.importSource]
                : this.publicUrl;
        },

        isImportSourceCompetitor() {
            return this.importSource === IMPORT_TYPES.MAILCHIMP
                 || this.importSource === IMPORT_TYPES.CONSTANT_CONTACT
                 || this.importSource === IMPORT_TYPES.HUBSPOT;
        },

        isImportSourceMailchimp() {
            return this.importSource === IMPORT_TYPES.MAILCHIMP;
        },

        showMailchimpIntegration() {
            return this.isImportSourceMailchimp;
        },
    },

    methods: {
        loadXlsx() {
            return import(/* webpackChunkName: "xlsx" */ 'xlsx');
        },

        createCsvFile(csvData) {
            if (this.fileName) {
                if (this.fileName.includes('.xlsx')) {
                    this.excelFileName = this.fileName;
                    this.fileName = this.fileName.replace('.xlsx', '.csv');
                } else {
                    this.excelFileName = this.fileName;
                    this.fileName = this.fileName.replace('.xls', '.csv');
                }
            }

            return new File([csvData], this.fileName, { type: 'text/csv' });
        },

        selectWorkbookSheet(sheetName) {
            this.$refs.chooseWorkbookSheetDialog.toggleable_close();
            this.convertAndUploadCsv(sheetName);
        },

        openWorkbookSheetPicker() {
            this.$refs.chooseWorkbookSheetDialog.toggleable_open();
        },

        convertAndUploadCsv(sheetName) {
            return this.loadXlsx().then(({ default: XLSX }) => {
                ([this.selectedSheet] = this.workbook.SheetNames);

                if (sheetName) {
                    this.selectedSheet = sheetName;
                }

                this.worksheet = this.workbook.Sheets[this.selectedSheet];

                const csv = XLSX.utils.sheet_to_csv(this.worksheet);
                const payload = {
                    csvFile: this.createCsvFile(csv, this.fileName),
                    excelFileName: this.excelFileName,
                };

                return this.$store.dispatch('contactImport/UPLOAD_FILE', payload)
                    .then(() => {
                        this.handleSuccess();
                    })
                    .catch((error) => {
                        this.handleError(error);
                    });
            });
        },

        updateDateColumnFormats(XLSX) {
            const firstSheet = this.workbook.SheetNames[0];
            const worksheet = this.workbook.Sheets[firstSheet];
            const columnCount = XLSX.utils.decode_range(worksheet['!ref']).e.c + 1;
            const cells = Object.keys(worksheet);
            const dobColumn = [];
            const dateHeaders = ['birthday', 'anniversary'];

            for (let i = 1; i <= columnCount; i++) {
                const cellCol = cells[i];
                const columnHeaderName = worksheet[cellCol].v;

                if (columnHeaderName && typeof columnHeaderName.toLowerCase === 'function' && dateHeaders.includes(columnHeaderName.toLowerCase())) {
                    dobColumn.push(cells[i].split('')[0]);
                }
            }

            cells.forEach((item, index) => {
                const cellCol = cells[index];
                const columnName = cellCol.slice(0, cellCol.search(/\d/));
                const num = cellCol.replace(columnName, '');

                const found = dobColumn.find((element) => element === columnName);

                if (found !== undefined && num > 1) {
                    delete worksheet[cells[index]].w;
                    worksheet[cells[index]].z = 'MM/dd/yyyy';
                }
            });
        },

        readAsCsv(file) {
            return this.loadXlsx().then(({ default: XLSX }) => {
                const reader = new FileReader();

                reader.onload = (e) => {
                    const data = new Uint8Array(e.target.result);

                    this.workbook = XLSX.read(data, { type: 'array' });

                    this.updateDateColumnFormats(XLSX);

                    this.workbookSheetNames = Object.keys(this.workbook.Sheets);

                    if (this.workbookSheetNames.length > 1) {
                        return this.openWorkbookSheetPicker(this.workbook);
                    }

                    return this.convertAndUploadCsv();
                };
                reader.readAsArrayBuffer(file);
            });
        },

        uploadFile(file) {
            if (file) {
                this.fileName = file.name;

                if (this.fileName && (this.fileName.includes('.xls') || this.fileName.includes('.xlsx'))) {
                    const eventName = 'Contacts - Import - Upload - uploaded : excel';

                    this.$store.commit('contactImport/SET_IMPORT_SOURCE', 'xls/xlsx');

                    this.$track(eventName);
                    this.readAsCsv(file);
                } else {
                    const eventName = 'Contacts - Import - Upload - uploaded : csv';

                    this.$track(eventName);

                    return this.$store.dispatch('contactImport/UPLOAD_FILE', this.uploader.file)
                        .then(() => {
                            this.handleSuccess();
                        })
                        .catch((error) => {
                            this.handleError(error);
                        });
                }
            }
            this.setStageReady('upload', false);

            return null;
        },

        setStageReady(stage, isReady) {
            this.$store.commit('contactImport/SET_STAGE_READY', {
                stage,
                isReady,
            });
        },

        handleSuccess() {
            this.$track('Contacts - Import - Upload - success : file uploaded');
            this.uploader.progress = 100;
            this.setStageReady('upload', true);
            this.$refs.uploader.success = true;

            setTimeout(() => {
                this.$emit('next');
            }, this.delay);
        },

        handleError(e) {
            const message = this.$t(this.getErrorMessage(e));
            const eventName = `Contacts - Import - Upload - error : ${e}`;
            const eventProperties = {
                importCount: this.contactImportCount,
                stage: this.activeStage,
                errorCode: this.errorCode,
            };

            this.$track(eventName, eventProperties);

            this.$error({
                message,
                bottom: true,
            });
        },

        getErrorMessage(e) {
            switch (e) {
            case 'fileSize':
                return 'importContactsFileSize';
            case 'fileNotDefined':
                return 'importContactsFileType';
            case 'fileType':
                return 'importContactsFileType';
            case 'multipleFiles':
                return 'importContactsMultiple';
            default:
                return 'importContactsGeneral';
            }
        },

        clear() {
            this.$refs.uploader.reset();
        },
    },
};
</script>


<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .upload-stage {
        @include centeredContainer;
        text-align: center;
    }

    h2 {
        margin-bottom: $gp;
        text-align: center;
    }

    .file-upload {
        margin: $gp * 2 0;
    }

    .choose-sheet-title {
        font-size: $font-size-lg;
    }

    .choose-sheet-message {
        font-size: $font-size-med;
        margin: $gp 0;
    }

    .dialog-container {
        @include text-align-start;
    }

    .tab-button {
        margin: $gp / 2;
        width: 100%;
    }

    small {
        color: $color-gray-600;
    }

    .upload-stage-with-integration {
        max-width: 45 * $gp;
    }

    .integration-import-group {
        display: flex;
        justify-content: space-evenly;
    }

    .integration-import-or {
        margin: auto 0;
    }

    [dir=rtl] {
        .dialog-container {
            @include text-align-end;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "title": "First, upload your file",
        "description": {
            "description1": "You can import CSV, XLSX, and XLS files. Convert your file to one of those file types, or use our ",
            "descriptionLink1": "/files/Keap-csv-upload-template.csv",
            "descriptionLink2": " downloadable template",
            "description2": "for easier data matching"
        },
        "inputLabel": "Upload your file here",
        "helpText": {
            "help1": "Have questions? Visit our ",
            "helpLink": "Help Center",
            "help2": "for more detailed instructions"
        },
        "proTip": {
            "title": "Pro tip",
            "description": "Our CSV template can be opened in most spreadsheet apps."
        },
        "chooseSheetTitle": "Which tab do you want to import?",
        "chooseSheetMessage": "Your Excel file has multiple tabs. Please select a tab to use for this import.",
        "competitors": {
            "follow": "Follow",
            "instructions": "export instructions",
            "description": "to export your contact data. Be sure to select CSV file format.",
            "mailchimp": {
                "title": "Add your contacts from Mailchimp",
                "capitalName": "Mailchimp's"
            },
            "constantContact": {
                "title": "Add your contacts from Constant Contact",
                "capitalName": "Constant Contact's"
            },
            "hubspot": {
                "title": "Add your contacts from HubSpot",
                "capitalName": "HubSpot's"
            }
        },
        "filesAllowed": "Only CSV, XLSX, and XLS files are acceptable",
        "importContactsGeneral": "File upload failed. Please ensure your file type is a .csv, .xlsx, or .xls and try again.",
        "importContactsMultiple": "File upload failed. Please select only one file at a time to upload.",
        "importContactsFileSize": "File upload failed. Your file exceeded the 10 mb size limit.",
        "importContactsFileType": "File upload failed: File type not supported. Please ensure your file type is a .csv, .xlsx, or .xls",
        "integrationImportOr": "or"
    }
}
</i18n>
