import Vue from 'vue';
import dateMixin from '@/appointments/mixins/date.mixin';
import { UPDATE_CONTACT_TYPE, BULK_UPDATE_CONTACT_TYPE } from './contactTypes/mutations';
import {
    LOAD_CONTACT_RECORD_START,
    LOAD_CONTACT_RECORD_SUCCESS,
    LOAD_CONTACT_RECORD_ERROR,
} from './contactRecord/mutations';
import {
    LOAD_TASK_LIST_START,
    LOAD_TASK_LIST_SUCCESS,
    LOAD_TASK_LIST_ERROR,
} from './tasks/mutations';
import COMPANY_MUTATIONS from './companies/mutations';

const mapToArray = (map) => {
    if (Array.isArray(map)) {
        return map;
    }

    return Object.keys(map).map((key) => {
        return { value: key, label: map[key] };
    });
};

export default {
    ...COMPANY_MUTATIONS,
    LOAD_CONTACT_RECORD_START,
    LOAD_CONTACT_RECORD_SUCCESS,
    LOAD_CONTACT_RECORD_ERROR,

    LOAD_TASK_LIST_START,
    LOAD_TASK_LIST_SUCCESS,
    LOAD_TASK_LIST_ERROR,

    SET_CONTACTS(state, contacts) {
        state.contacts = contacts;
    },

    LOAD_CONTACT_LISTS_START(state) {
        state.contactLists = [];
        state.contactListsLoaded = false;
    },

    LOAD_CONTACT_LISTS_SUCCESS(state, lists) {
        state.contactLists = lists;
        state.contactListsLoaded = true;
    },

    LOAD_CONTACT_LISTS_ERROR(state) {
        state.contactListsLoaded = true;
    },

    LOAD_CONTACT_LISTS_COUNTS_START(state) {
        state.contactLists = state.contactLists.map((list) => ({
            ...list,
            count: 0,
            loadingCount: true,
        }));
    },

    LOAD_CONTACT_LISTS_COUNT_LOADED(state, { id, count }) {
        const list = state.contactLists.find((x) => +x.id === +id);

        if (list) {
            list.count = count;
            list.loadingCount = false;
        }
    },

    ADD_CONTACT_LIST(state, list) {
        state.contactLists = [
            list,
            ...state.contactLists,
        ];
    },

    DELETE_CONTACT_LIST(state, id) {
        state.contactLists = state.contactLists.filter((x) => `${x.id}` !== `${id}`);
    },

    UPDATE_CONTACT_LIST_NAME(state, { id, name }) {
        const list = state.contactLists.find((x) => `${x.id}` === `${id}`);

        list.name = name;
    },

    SET_FILTERS_URL(state, url) {
        state.contactFilterUrl = url;
    },

    SET_CALL_DETAILS(state, callDetails) {
        state.callDetails = callDetails;
    },

    SET_CONTACT_FILTERS_METADATA(state, filters) {
        state.contactFilterMetadata = filters;
    },

    SET_CONTACT_FILTER_VALUES(state, filters) {
        state.contactFilterValues = filters;
    },

    SET_FILTERED_CONTACT_COUNT(state, count) {
        state.filteredContactCount = count;
    },

    RESET_FILTERED_CONTACT_COUNT(state) {
        state.filteredContactCount = 0;
    },

    SET_LOADING_CONTACT_COUNT(state, loading) {
        state.loadingContactCount = loading;
    },

    SET_LOADING_CONTACT_LIST(state, loading) {
        state.contactListLoading = loading;
    },

    SET_LOADING_EXPORT(state, isLoading) {
        state.loadingExport = isLoading;
    },

    SET_CONTACT_LIST_VISIBILITY(state, { id, show }) {
        const index = state.contactLists.findIndex(({ id: listId }) => id === listId);

        if (index > -1) {
            Vue.set(state.contactLists[index], 'shown', show);
        }
    },

    SET_CONTACT_LIST_SORT_FIELD(state, field) {
        state.contactListSortField = field;
    },

    SET_CONTACT_LIST_SORT_ASCENDING(state, ascending) {
        state.contactListSortAscending = ascending;
    },

    RESET_CONTACTS(state) {
        state.contacts = [];
    },

    RESET_EMAILS(state) {
        state.emails = [];
    },

    RESET_UNDOABLE_EMAILS(state) {
        state.emails = state.emails.map((email) => {
            email.pendingEmailUuid = null;

            return email;
        });
    },

    REMOVE_CONTACTS(state, { ids, checkAll }) {
        state.contacts = state.contacts.filter((contact) => {
            const index = ids.indexOf(contact.id);

            return checkAll ? index !== -1 : index === -1;
        });
    },

    SET_CAMPAIGNS(state, campaigns) {
        state.campaigns = campaigns;
    },

    SET_STRATEGIES(state, strategies) {
        state.strategies = strategies;
    },

    LOAD_ACCOUNT_CONTACT_TOTAL_SUCCESS(state, total) {
        state.accountContactTotal = total;
    },

    SET_CONTACT_TOTAL(state, total) {
        state.contactTotal = total;
    },

    INCREMENT_CONTACT_TOTAL(state) {
        state.contactTotal += 1;
    },

    SET_CONTACT(state, contact) {
        state.contact = contact;
    },

    RESET_CONTACT_RECORD(state) {
        state.contact = {};
        state.contactLoaded = false;
        state.contactDetails = {
            fieldGroups: [],
            billingRegionOptions: [],
            shippingRegionOptions: [],
            otherRegionOptions: [],
        };
    },

    UPDATE_CONTACT_INFO(state, contactDetails) {
        state.contact.firstName = contactDetails.firstName;
        state.contact.lastName = contactDetails.lastName;
        state.contact.email = contactDetails.email;
        state.contact.phone = contactDetails.phone1.value;

        if (contactDetails.company !== undefined) {
            const companyOption = state.companies.find(({ value: companyId }) => companyId === contactDetails.company);

            state.contact.company = companyOption ? companyOption.label : '';
        }
    },

    UPDATE_CONTACT_IN_LIST(state, contactDetails) {
        const contact = state.contacts.find((listedContact) => listedContact.id === contactDetails.id);

        if (contact) {
            contact.fullName = [contactDetails.firstName, contactDetails.lastName].join(' ').trim();
            contact.email = contactDetails.email;
        }
    },

    SET_CONTACT_LOADED(state, loaded) {
        state.contactLoaded = loaded;
    },

    SET_ACTIVITIES(state, activities) {
        state.activities = [...activities];
    },

    SET_ACTIVITY_CURSOR(state, activityCursor) {
        state.activityCursor = activityCursor;
    },

    ADD_ACTIVITIES(state, activities) {
        state.activities.push(...activities);
    },

    ADD_ACTIVITY_FEED_ITEM(state, { type, details }) {
        const publishRequestDate = new Date();

        const addActivity = {
            publishRequestDate,
            type,
            details,
        };

        state.activities.push(addActivity);
    },

    ADD_ACTIVITY_FEED_ITEMS(state, items) {
        items.forEach((item) => {
            const addActivity = {
                publishRequestDate: new Date(),
                type: item.type,
                details: item.details,
            };

            state.activities.push(addActivity);
        });
    },

    SET_CONTACT_DETAILS(state, contactDetails) {
        if (contactDetails.shippingStateOptions) {
            contactDetails.shippingRegionOptions = Object.keys(contactDetails.shippingStateOptions).length > 0
                ? mapToArray(contactDetails.shippingStateOptions)
                : mapToArray(state.contactDetails.shippingRegionOptions);
        }

        if (contactDetails.billingStateOptions) {
            contactDetails.billingRegionOptions = Object.keys(contactDetails.billingStateOptions).length > 0
                ? mapToArray(contactDetails.billingStateOptions)
                : mapToArray(state.contactDetails.billingRegionOptions);
        }

        if (contactDetails.otherStateOptions) {
            contactDetails.otherRegionOptions = Object.keys(contactDetails.otherStateOptions).length > 0
                ? mapToArray(contactDetails.otherStateOptions)
                : mapToArray(state.contactDetails.otherRegionOptions);
        }

        if (contactDetails.birthday) {
            contactDetails.birthday = dateMixin.dateObjectToShortDateString(contactDetails.birthday);
        }

        if (contactDetails.contact && contactDetails.contact.customFields) {
            contactDetails.contact.customFields.forEach((field) => {
                if (field.value) {
                    if (field.fieldType === 'Date') {
                        field.value = dateMixin.dateObjectToShortDateString(field.value);
                    } else if (field.fieldType === 'DateTime') {
                        field.value = new Date(field.value);
                    }
                }

                if (field.fieldType === 'Radio' && field.value === null) {
                    field.value = '';
                } else if (field.fieldType === 'ListBox') {
                    if (field.value === null) {
                        field.value = '';
                    }

                    if (typeof (field.value) === 'string' && field.value !== '') {
                        field.value = field.value.split(',');
                    }
                } else if (field.fieldType === 'Currency' || field.fieldType === 'DecimalNumber' || field.fieldType === 'Percent') {
                    if (field.value === 0 || field.value === '0') {
                        field.value = 0;
                    }

                    if (field.value) {
                        const tempvalue = Number(field.value).toFixed(2);

                        if (tempvalue !== 'NaN') {
                            field.value = tempvalue;
                        } else {
                            field.value = NaN;
                        }
                    }
                } else if (field.fieldType === 'YesNo' || field.fieldType === 'Month' || field.fieldType === 'DayOfWeek' || field.fieldType === 'WholeNumber' || field.fieldType === 'Year') {
                    if (field.value) {
                        field.value = Number(field.value);
                    }
                }
            });
        }

        if (contactDetails.contactDetails && contactDetails.contactDetails.fieldGroups.length) {
            contactDetails.contactDetails.fieldGroups.forEach((groups) => {
                groups.fieldValues.forEach((field) => {
                    if (field.contactField.name === 'OWNER') {
                        contactDetails.owners.forEach((value) => {
                            if (value.id === field.value) {
                                field.value = field.value.toString();
                            }
                        });
                    }

                    if (field.contactField.name === 'BIRTHDAY') {
                        field.value = dateMixin.dateObjectToShortDateString(field.value);
                    }

                    if (field.contactField.type === 'Currency' || field.contactField.type === 'NumberDecimal' || field.contactField.type === 'Percent') {
                        if (field.value === 0 || field.value === '0') {
                            field.value = null;
                        }

                        if (field.value) {
                            field.value = Number(field.value).toFixed(2);
                        }
                    }

                    if (field.contactField.type === 'YesNo' || field.contactField.type === 'Month' || field.contactField.type === 'DayOfWeek' || field.contactField.type === 'WholeNumber' || field.contactField.type === 'Year') {
                        if (field.value) {
                            field.value = Number(field.value);
                        }
                    }
                });
            });
        }

        state.contactDetails = contactDetails;
    },

    LOAD_CONTACT_MARKETABILITY_SUCCESS(state, contactEmailMarketability) {
        contactEmailMarketability.email = contactEmailMarketability.email1;
        delete contactEmailMarketability.email1;

        state.contactEmailMarketability = contactEmailMarketability;
    },

    LOAD_CONTACT_MARKETABILITY_ERROR(state) {
        state.contactEmailMarketability = {};
    },

    SET_REGION_OPTIONS(state, { options, addressType, countryCode }) {
        if (Array.isArray(options)) {
            state.contactDetails[`${addressType}RegionOptions`] = options;
            state.cachedRegionOptions[countryCode] = options;
        } else {
            state.contactDetails[`${addressType}RegionOptions`] = mapToArray(options);
            state.cachedRegionOptions[countryCode] = mapToArray(options);
        }
    },

    RESET_REGION_OPTIONS(state) {
        state.contactDetails.billingRegionOptions = [];
        state.contactDetails.shippingRegionOptions = [];
        state.contactDetails.otherRegionOptions = [];
    },


    SET_COUNTRIES(state, countries) {
        state.countries = mapToArray(countries);
    },

    SET_CUSTOM_REGION_OPTIONS(state, { options, countryCode }) {
        const customOptions = {};

        if (state.customRegionOptions.length === 0) {
            for (const key in options) {
                if (Object.prototype.hasOwnProperty.call(options, key)) {
                    const newKey = key.slice(3, 5);

                    customOptions[newKey] = options[key];
                }
            }

            state.customRegionOptions = mapToArray(customOptions);
            state.cachedRegionOptions[countryCode] = mapToArray(options);
        }
    },

    SET_CONTACT_TAGS(state, tags) {
        state.tags = tags;
    },

    REMOVE_CONTACT_TAG(state, tagId) {
        const tagIndex = state.tags.findIndex(({ id }) => id === tagId);

        state.tags.splice(tagIndex, 1);
    },

    APPLY_CONTACT_TAGS(state, tags) {
        const newTags = tags.map((tag) => {
            return {
                name: tag.name,
                id: tag.id,
                dateApplied: new Date(),
                category: tag.category ? tag.category.name : null,
            };
        });

        state.tags = [
            ...newTags,
            ...state.tags,
        ];
    },

    RESET_CONTACT_TAGS(state) {
        state.tags = [];
    },

    ADD_APPOINTMENT(state, appointment) {
        state.appointments.push(appointment);
    },

    UPDATE_APPOINTMENT(state, appointment) {
        const index = state.appointments.findIndex((a) => a.id === appointment.id);

        Vue.set(state.appointments, index, appointment);
    },

    REMOVE_APPOINTMENT(state, appointmentId) {
        const index = state.appointments.findIndex((a) => a.id === appointmentId);

        state.appointments.splice(index, 1);
    },

    SET_NOTES(state, notes) {
        state.notes = notes;
    },

    SET_EMAILS(state, emails) {
        const deduped = state.emails.filter((b) => {
            return !emails.some((broadcast) => broadcast.sentDate === b.sentDate);
        });

        state.emails = [
            ...deduped,
            ...emails,
        ];
    },

    SET_EMAIL_BODY(state, { emailId, body }) {
        const email = state.emails.find((e) => e.emailId === emailId);

        if (email != null) {
            email.body = body;
        }
    },

    ADD_NOTE(state, note) {
        state.notes.push(note);
    },

    UPDATE_NOTE(state, note) {
        const index = state.notes.findIndex((a) => a.id === note.id);

        Vue.set(state.notes, index, note);
    },

    REMOVE_NOTE(state, noteId) {
        const index = state.notes.findIndex((a) => a.id === noteId);

        state.notes.splice(index, 1);
    },

    SET_TASKS(state, tasks) {
        tasks.forEach((task) => {
            if (task.completionDate) {
                task.completed = true;
            } else {
                task.completed = false;
            }
        });

        state.tasks = tasks;
    },

    ADD_TASK(state, task) {
        task.completed = false;
        state.tasks.push(task);
    },

    ADD_TASKS(state, tasks) {
        tasks.forEach((task) => {
            if (task.completed !== true) {
                task.completed = false;
            }
        });

        state.tasks.push(tasks);
    },

    UPDATE_TASK(state, task) {
        const index = state.tasks.findIndex((t) => t.id === task.id);

        if (task.completionDate) {
            task.completed = true;
        } else {
            task.completed = false;
        }

        Vue.set(state.tasks, index, task);
    },

    REMOVE_TASK(state, taskId) {
        const taskIndex = state.tasks.findIndex(({ id }) => id === taskId);

        state.tasks.splice(taskIndex, 1);
    },

    SET_FILES(state, files) {
        state.files = files;
    },

    SET_SEARCH_RESULTS(state, searchResults = []) {
        state.searchResults = [
            ...searchResults,
        ];
    },

    UPDATE_DEAL({ contact }, updatedDeal) {
        contact.pipelines.forEach((pipeline) => {
            let hasContact;

            const index = pipeline.deals.findIndex((deal) => {
                hasContact = updatedDeal.contacts.some((loop) => contact.id === loop.id);

                return deal.id === updatedDeal.id;
            });

            if (hasContact) {
                Vue.set(pipeline.deals, index, updatedDeal);
            } else {
                pipeline.deals.splice(index, 1);
            }
        });
    },

    SET_CUSTOM_FIELDS(state, customFields) {
        state.customFields = customFields;
    },

    // Can be removed with FF KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
    SET_CUSTOM_FIELD_GROUPS(state, fieldGroups) {
        fieldGroups.forEach((group) => {
            group.fields = group.fields || [];
        });

        state.fieldGroups = fieldGroups;
    },

    MOVE_CUSTOM_FIELD_GROUP(state, { fromIndex, toIndex }) {
        const fieldGroup = state.fieldGroups[fromIndex];

        state.fieldGroups.splice(fromIndex, 1);
        state.fieldGroups.splice(toIndex, 0, fieldGroup);
    },

    DELETE_CUSTOM_FIELD_GROUP(state, fieldGroupId) {
        const index = state.fieldGroups.findIndex(({ id }) => fieldGroupId === id);

        state.fieldGroups.splice(index, 1);
    },

    ADD_CUSTOM_FIELD(state, field) {
        const index = state.fieldGroups.findIndex(({ id }) => field.groupId === id);

        state.fieldGroups[index].fields.push(field);
    },

    DELETE_CUSTOM_FIELD(state, { fieldId, groupId }) {
        const groupIndex = state.fieldGroups.findIndex(({ id }) => groupId === id);
        const fieldIndex = state.fieldGroups[groupIndex].fields.findIndex(({ id }) => fieldId === id);

        state.fieldGroups[groupIndex].fields.splice(fieldIndex, 1);
    },

    ADD_CUSTOM_FIELD_GROUP(state, group) {
        group.fields = [];
        state.fieldGroups.push(group);
    },

    UPDATE_CUSTOM_FIELD(state, field) {
        const groupIndex = state.fieldGroups.findIndex(({ id }) => field.groupId === id);
        const fieldIndex = state.fieldGroups[groupIndex].fields.findIndex(({ id }) => field.id === id);

        if (fieldIndex > -1) {
            Vue.set(state.fieldGroups[groupIndex].fields, fieldIndex, field);
        }
    },

    SET_LEADS_CONTACT_LIST_ID (state, leadsContactListId) {
        if (leadsContactListId) {
            state.leadsContactListId = leadsContactListId;
        }
    },

    SET_CLIENTS_CONTACT_LIST_ID(state, clientsContactListId) {
        if (clientsContactListId) {
            state.clientsContactListId = clientsContactListId;
        }
    },

    SET_NEW_LEADS_CONTACT_LIST_ID(state, newLeadsContactListId) {
        if (newLeadsContactListId) {
            state.newLeadsContactListId = newLeadsContactListId;
        }
    },

    SET_CUSTOMERS_LIST_ID (state, customersListId) {
        if (customersListId) {
            state.customersListId = customersListId;
        }
    },

    SET_CONTACT_PIPELINES(state, pipelines) {
        state.contact.pipelines = pipelines;
    },

    UPDATE_CONTACT_TYPE,
    BULK_UPDATE_CONTACT_TYPE,

    TOGGLE_GROUPED_FEED(state) {
        state.activeFeedToggle = 'grouped';
    },

    TOGGLE_CHRONOLOGICAL_FEED(state) {
        state.activeFeedToggle = 'chronological';
    },
};
