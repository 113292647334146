import { legacyBardJsonFromHtml } from '@/shared/utils/bard.util';

export default {
    attachments: [],
    currentEmail: {
        bardJson: legacyBardJsonFromHtml({ html: '' }),
        body: '',
        subject: '',
    },
    lastSentEmail: {
        email: {},
        attachments: [],
    },
    options: {
        signatureEnabled: false,
    },
    signatures: {},
    providers: [],
};
