import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import {
    FIELD_ID_COMPANY,
    FIELD_ID_NOTES,
    FIELD_ID_TAGS,
    FIELD_TYPE_EXTERNAL,
} from '@/smartForms/smartform.constants';
import {
    FORM_AUDIENCE_INTERNAL,
    FORM_AUDIENCE_PUBLIC,
    STANDARD_COMPANY_FIELD_ID,
    STANDARD_FIELD_IDS,
    STANDARD_NOTE_FIELD_ID,
    STANDARD_TAG_FIELD_ID,
    FORM_TYPE_CHECKOUT,
    FORM_TYPE_LANDING_PAGE,

} from '@/customForms/customForm.constants';
import {
    trackCompanyAssociated,
    trackContactAdded,
    trackEditContact,
    trackNoteAdded,
    trackTagApplied,
} from '@/contacts/analytics';

export const getFormTrackingInfo = (form, extraProperties = {}) => {
    if (!form) {
        return {};
    }

    let numTotalFields = 0;
    let numStandardContactFields = 0;
    let numCustomContactFields = 0;
    let numFormOnlyFields = 0;
    let numUnknownFields = 0;
    let numRequiredFields = 0;
    let numFieldsWithDefaultValues = 0;
    let numFieldsWithHelperText = 0;
    let hasTagField = false;
    let hasNoteField = false;
    const hasButtonLabel = Boolean(form.buttonLabel);

    if (Array.isArray(form.fields)) {
        form.fields.forEach((field) => {
            numTotalFields++;

            if (field.id === FIELD_ID_NOTES || field.id === STANDARD_NOTE_FIELD_ID) {
                hasNoteField = true;
            } else if (field.id === FIELD_ID_TAGS || field.id === STANDARD_TAG_FIELD_ID) {
                hasTagField = true;
            } else if (STANDARD_FIELD_IDS.includes(field.id)) {
                numStandardContactFields++;
            } else if (field.fieldType !== FIELD_TYPE_EXTERNAL) {
                numFormOnlyFields++;
            } else if (/^\d+$/.test(field.id)) {
                numCustomContactFields++;
            } else {
                numUnknownFields++;
            }

            if (field.defaultValues && field.defaultValues.length) {
                numFieldsWithDefaultValues++;
            }

            if (field.helperText && field.helperText.text) {
                numFieldsWithHelperText++;
            }

            if (field.required) {
                numRequiredFields++;
            }
        });
    }

    return {
        ...extraProperties,
        'Form Id': form.id,
        'Form Type': getFormTypeAnalytics(form),
        'Total Field Count': numTotalFields,
        'Number of Standard Contact Fields': numStandardContactFields,
        'Number of Custom Contact Fields': numCustomContactFields,
        'Number of Form-Only Fields': numFormOnlyFields,
        'Number of Unknown Fields': numUnknownFields,
        'Number of Required Fields': numRequiredFields,
        'Has Note Field': hasNoteField,
        'Has Tag Field': hasTagField,
        'Has Button Label': hasButtonLabel,
        'Number of Fields with Default Values': numFieldsWithDefaultValues,
        'Number of Fields with Helper Text': numFieldsWithHelperText,
    };
};

export const trackCustomFormViewed = (form, sourceDisplayType, sourceType, sourceUrl) => {
    setAppIdIfNecessary(form);

    amplitude.v2.logEvent(amplitude.v2.events.FORM_VIEWED, getFormTrackingInfo(form, {
        'Source Display Type': getSourceDisplayType(sourceDisplayType),
        'Source Type': sourceType,
        'Source URL': sourceUrl,
    }));
};

export const getFormSubmissionTrackingInfo = (form, submissionResponse, extraProperties = {}) => {
    const {
        newContactCreated,
        sourceDisplayType,
        sourceType,
        sourceUrl,
    } = submissionResponse;

    return {
        ...extraProperties,
        ...getFormTrackingInfo(form),
        'Source Display Type': getSourceDisplayType(sourceDisplayType),
        'Source Type': sourceType,
        'Source URL': sourceUrl,
        'New Contact was Created': Boolean(newContactCreated),
    };
};

export const trackCustomFormSubmitted = (form, submissionResponse, editedFields = [], extraProperties = {}) => {
    const submissionData = submissionResponse.data;
    const eventSource = form.audience === FORM_AUDIENCE_INTERNAL ? 'Internal Form' : 'Custom Lead Form';
    const { newContactCreated } = submissionResponse;

    setAppIdIfNecessary(form);
    amplitude.v2.logEvent(amplitude.v2.events.FORM_SUBMITTED, getFormSubmissionTrackingInfo(form, submissionResponse, extraProperties));

    if (newContactCreated) {
        trackContactAdded(eventSource);
        intercom.logEvent(intercom.events.CONTACT_ADDED);
    } else {
        trackEditContact(eventSource);
    }

    if (submissionDataIncludesAnyValue(submissionData, FIELD_ID_NOTES, STANDARD_NOTE_FIELD_ID)) {
        trackNoteAdded(eventSource);
    }

    if (submissionDataIncludesAnyValue(submissionData, FIELD_ID_TAGS, STANDARD_TAG_FIELD_ID)) {
        trackTagApplied(eventSource);
    }

    if (editedFields && submissionDataIncludesAnyValue(submissionData, FIELD_ID_COMPANY, STANDARD_COMPANY_FIELD_ID)
        && editedFields.find((fieldId) => fieldId === FIELD_ID_COMPANY || fieldId === STANDARD_COMPANY_FIELD_ID)) {
        trackCompanyAssociated(eventSource);
    }
};

const submissionDataIncludesAnyValue = (submissionData, ...ids) => {
    return submissionData && ids.find((id) => {
        const value = submissionData[id];

        return Array.isArray(value) ? value.length > 0 : Boolean(value);
    });
};

const setAppIdIfNecessary = (form) => {
    const isPublicForm = form.audience === FORM_AUDIENCE_PUBLIC;

    if (isPublicForm) {
        const appName = form.accountId;

        amplitude.v2.setUserId(appName);
    }
};

export const trackFormsStartedBuilding = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.STARTED_BUILDING_FORM, { 'Event Source': eventSource });
};

export const trackNewFormSelected = (eventSource, templateType, formType) => {
    amplitude.v2.logEvent(amplitude.v2.events.NEW_FORM_TEMPLATE_SELECTED, {
        'Event Source': eventSource,
        'Form Template': templateType,
        'Form Type': formType,
    });
};

export const trackFieldAddedToForm = (eventSource, fieldType, formType) => {
    amplitude.v2.logEvent(amplitude.v2.events.FIELD_ADDED_TO_FORM, {
        'Event Source': eventSource,
        'Field Type': fieldType,
        'Form Type': formType,
    });
};


export const trackFormButtonTextUpdated = (eventSource, buttonText) => {
    amplitude.v2.logEvent(amplitude.v2.events.UPDATED_FORM, {
        'Event Source': eventSource,
        'New Button Text': buttonText,
    });
};

export const trackFormStyleChange = (eventSource, styleChange) => {
    amplitude.v2.logEvent(amplitude.v2.events.UPDATED_FORM_STYLE, {
        'Event Source': eventSource,
        'Style Change': styleChange,
    });
};

export const trackFormCopiedCodeLink = (eventSource, whichCopied, formType) => {
    amplitude.v2.logEvent(amplitude.v2.events.COPIED_FORM_CODE_LINK, {
        'Event Source': eventSource,
        'Which Copied': whichCopied,
        'Form Type': formType,
    });
};

export const getFormTypeAnalytics = (form) => {
    if (form.formType === FORM_TYPE_CHECKOUT) {
        return 'Checkout Form';
    }

    if (form.formType === FORM_TYPE_LANDING_PAGE) {
        return 'Landing Page Form';
    }

    return form.audience === FORM_AUDIENCE_PUBLIC ? 'Public Form' : 'Internal Form';
};

export const getSourceDisplayType = (sourceDisplayType) => {
    if (sourceDisplayType?.toLowerCase() === 'iframe') {
        return 'Embedded';
    }

    return sourceDisplayType;
};

export const trackClosedFormBuilder = (eventSource, action, formType, unPublished, published) => {
    amplitude.v2.logEvent(amplitude.v2.events.CLOSED_FORM_BUILDER, {
        'Event Source': eventSource,
        'Form Action': action,
        'Form Type': formType,
        'Getting unpublished': unPublished,
        'Getting published': published,
    });
};

export const trackFormDeleted = (eventSource, formType) => {
    amplitude.v2.logEvent(amplitude.v2.events.FORM_DELETED, {
        'Event Source': eventSource,
        'Form Type': formType,
    });
};

export const trackFormFieldDeleted = (eventSource, formType, fieldType) => {
    amplitude.v2.logEvent(amplitude.v2.events.FIELD_DELETED, {
        'Event Source': eventSource,
        'Form Type': formType,
        'Field Type': fieldType,
    });
};
