<template>
    <div class="tax-modal-content">
        <div>
            <ds-input-field
                v-model="internalTax.name"
                data-qa="sales-tax-name"
                name="billing.salesTax.modal.name"
                :label="$t('billing.salesTax.modal.name')"
                required
                autofocus
            />

            <div class="input-group">
                <ds-input-field
                    v-model="internalTax.rate"
                    data-qa="sales-tax-rate"
                    type="number"
                    :min="1"
                    :max="100"
                    required
                    name="billing.salesTax.modal.rate"
                    :label="$t('billing.salesTax.modal.rate')"
                />
            </div>
        </div>

        <div class="sales-tax-button-row">
            <div class="buttons-container">
                <ds-filled-button
                    class="modal-button"
                    data-qa="sales-tax-action"
                    :disabled="!hasRequiredFieldsGiven"
                    :loading="saving"
                    @click="modalAction"
                >
                    {{ actionButtonLabel }}
                </ds-filled-button>

                <ds-text-button
                    class="modal-button"
                    data-qa="sales-tax-cancel"
                    @click="cancel"
                >
                    {{ $t('global.cancel') }}
                </ds-text-button>
            </div>
        </div>
    </div>
</template>

<script>
import amplitude from '@/analytics/amplitude';

export default {
    props: {
        editing: Boolean,
        tax: Object,
        lineItem: Boolean,
    },

    data() {
        return {
            saving: false,
            internalTax: this.tax,
        };
    },

    watch: {
        tax: {
            handler(newVal) {
                this.internalTax = newVal;
            },
            deep: true,
        },
    },

    computed: {
        nestedModal_title() {
            return this.editing
                ? this.$t('billing.salesTax.modal.editTitle')
                : this.$t('billing.salesTax.modal.addTitle');
        },

        hasRequiredFieldsGiven() {
            return Boolean(this.internalTax.name && this.internalTax.rate);
        },

        actionButtonLabel() {
            if (this.editing) {
                return this.$t('billing.salesTax.modal.editTitle');
            }

            if (this.lineItem) {
                return this.$t('buttons.create');
            }

            return this.$t('billing.salesTax.modal.addTitle');
        },
    },

    methods: {
        modalAction() {
            if (this.editing) {
                this.updateSaleTax();
            } else {
                this.addSalesTax();
            }
        },

        cancel() {
            this.reset();
            this.closeModal();
        },

        reset() {
            this.saving = false;
        },

        addSalesTax() {
            this.$track('Sales Tax - Editing Sales Tax - New sales tax added');
            this.saving = true;

            return this.$store.dispatch('billing/ADD_SALES_TAX_RATE', {
                taxInfo: this.internalTax,
            })
                .then((tax) => {
                    this.reset();
                    this.closeModal();
                    this.$bus.$emit('TAX_ADDED', tax);
                    amplitude.v2.logEvent(amplitude.v2.events.SALES_TAX_CREATED);
                })
                .catch(() => {
                    this.saving = false;
                    this.$error({
                        message: this.$t('errors.addSalesTax'),
                        bottom: true,
                    });
                });
        },

        updateSaleTax() {
            this.$track('Sales Tax - Editing Sales Tax - Updating sales tax');
            this.saving = true;

            return this.$store.dispatch('billing/EDIT_SALES_TAX_RATE', this.internalTax)
                .then(() => {
                    this.reset();
                    this.closeModal();
                })
                .catch(() => {
                    this.saving = false;
                    this.$error({
                        message: this.$t('errors.editSalesTax'),
                        bottom: true,
                    });
                });
        },

        closeModal() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .buttons-container {
        display: flex;
        justify-content: flex-start;
    }

    .sales-tax-button-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }
</style>

<i18n>
{
    "en-us": {
        "buttons": {
            "add": "Add sales tax",
            "edit": "Edit sales tax",
            "create": "Create and add sales tax"
        },
        "errors": {
            "addSalesTax": "There was an error adding a sales tax rate.  Please try again.",
            "editSalesTax": "There was an error editing the sales tax rate.  Please try again."
        }
    }
}
</i18n>
