<template>
    <div class="controlled-guide-container">
        <slot name="reference" />

        <div v-if="showGuide" class="guide-container" :class="[`beacon-${position}`]">
            <slot name="guide" />
        </div>
    </div>
</template>

<script>
import { POPOVER_POSITIONS } from '@/shared/constants/popover.constants';

export default {
    props: {
        position: {
            type: String,
            default: 'right',
            validator: (position) => POPOVER_POSITIONS.includes(position),
        },
        showGuide: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.controlled-guide-container {
    position: relative;
}

.guide-container {
    z-index: 10;
    position: absolute;
    transform: translateY(-50%);
}

$beacon-radius: px-to-rem(20);

.beacon-top {
    top: 0;
    left: 50%;
    transform: translate(-50%, -75%);
}

.beacon-right {
    top: 50%;
    left: 100%;
}

.beacon-right-start {
    top: $beacon-radius;
    left: 100%;
}

.beacon-bottom {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 75%);
}

.beacon-left-start {
    top: $beacon-radius;
    transform: translate(-100%, -50%);
}
</style>
