<template>
    <ds-modal
        ref="modal"
        :show-close="showClose"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template #header>
            <slot name="header" />
        </template>

        <template #title>
            <slot name="title" />
        </template>

        <template #default>
            <slot />
        </template>
    </ds-modal>
</template>

<script>
export default {
    name: 'ControlledModal',

    inheritAttrs: false,

    props: {
        isOpen: Boolean,
        showClose: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        if (this.isOpen) {
            this.$refs.modal.toggleable_open();
        }
    },

    watch: {
        isOpen(isOpen) {
            const ref = this.$refs.modal;

            if (isOpen) {
                ref.toggleable_open();
            } else {
                ref.toggleable_close('CONTROLLED_MODAL_SKIP_CLOSE_EMIT');
            }
        },
    },
};
</script>
