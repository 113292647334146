<template>
    <div class="error-box">
        <slot />

        <div>
            <ds-text-button
                v-if="onRetry"
                leading-icon="refresh-cw"
                class="error-box-retry-button"
                @click="onRetry"
            >
                <slot name="retry">
                    {{ $t('try.again') }}
                </slot>
            </ds-text-button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        onRetry: Function,
    },
};
</script>

<style lang="scss" scoped>
    .error-box {
        padding: (.625 * $gp) $gp;
        background-color: rgb(251, 233, 229);
        border: 1px solid $color-red;
        border-radius: .5 * $gp;
        color: $color-ink;
    }

    .error-box-retry-button {
        --icon-size: 1rem;
        padding: 0 (.25 * $gp) 0 (.5 * $gp);
        margin-top: .5 * $gp;
        margin-left: -.25 * $gp;
        font-weight: 500;
        line-height: 1rem;
    }
</style>

<i18n>
{
    "en-us": {
        "try.again": "Try again"
    }
}
</i18n>
