/*
    Appointments
*/
export const APPOINTMENT_BOOKED = 'Appointment Booked';
export const APPOINTMENT_TYPE_CREATED = 'Appointment Type Created';

/*
    Automations
*/
export const AUTOMATION_CREATED = 'Automation Created';
export const AUTOMATION_DELETED = 'Automation Deleted';
export const AUTOMATION_PREVIEWED = 'Automation Previewed';
export const AUTOMATION_TEST_SENT = 'Automation Test Sent';
export const AUTOMATION_UPDATED = 'Automation Updated';
export const AUTOMATIONS_LOADED = 'Automations Loaded';

/*
    Broadcasts
*/
export const BROADCAST_CREATED = 'Broadcast Created';
export const BROADCAST_SENT = 'Broadcast Sent';

/*
    Unlayer Builder
*/
export const UNLAYER_TEMPLATE_DOWNLOADED = 'Unlayer Template HTML Downloaded';

/*
    Comms
*/
export const CALL_MADE = 'Call Made';
export const MARKETING_NUMBER_PROVISIONED = 'SMS Marketing Number Provisioned';
export const SMS_SENT = 'SMS Sent';
export const SMS_MARKETING_FEATURE_DISCOVERED = 'Marketing SMS Discovered';
export const SMS_BEGIN_MARKETING_NUMBER_PROVISIONING = 'Begin SMS number provisioning';
export const SMS_COMPLETE_MARKETING_NUMBER_PROVISIONING = 'Complete SMS number provisioning';
export const REVIEW_KMN_SENDING_USAGE = 'Review KMN sending usage';
export const SMS_TEMPLATE_USED = 'KMN Template Used';
export const KMN_TEMPLATE_CHOSEN = 'KMN Template Chosen';
export const KMN_TEMPLATE_CREATED = 'KMN Template Created';
export const KMN_TEMPLATE_DELETED = 'KMN Template Deleted';

/*
    Checkout Forms V1
*/
export const CHECKOUT_FORM_VIEWED = 'Checkout Form Viewed';
export const CHECKOUT_FORM_ADDED = 'Add Checkout Form';
export const RECURRING_CREATED_FROM_CHECKOUT_FORM = 'Recurring Payment from Checkout Form Created';

/*
    Checkout Forms V2
*/
export const CHECKOUT_FORM_V2_ADDED = 'Add Checkout Form V2';
export const CHECKOUT_FORM_V2_PRODUCT_ADDED = 'Add product to Checkout Form V2';
export const CHECKOUT_FORM_V2_PRODUCT_EDITED = 'Edit product for Checkout Form V2';
export const CHECKOUT_FORM_V2_ADD_UPSELL = 'Add Upsell to Checkout Form V2';
export const CHECKOUT_FORM_V2_UPSELL_ADDED = 'Upsell added to Checkout Form V2';
export const CHECKOUT_FORM_V2_RECURRING_UPSELL_ADDED = 'Recurring Upsell added to Checkout Form V2';
export const CHECKOUT_FORM_V2_PUBLIC_PAGE_UPSELL_ADDED = 'Upsell added by buyer in Public Checkout Form V2';
export const CHECKOUT_FORM_V2_COPY_LINK = 'Checkout Form V2 url link copied';
export const CHECKOUT_FORM_V2_COPY_CODE = 'Checkout Form V2 embedded code copied';
export const CHECKOUT_FORM_V2_PAYMENT_MADE = 'Payment made on Checkout Form V2';

/*
    Companies
*/
export const COMPANY_ASSOCIATED = 'Company Associated';
export const COMPANY_CONTACTS_TRANFSERRED = 'Company Contacts Transferred';
export const COMPANY_CREATED = 'Company Created';
export const COMPANY_CUSTOM_FIELD_CREATED = 'Company Custom Field Created';
export const COMPANY_CUSTOM_FIELD_EDITED = 'Company Custom Field Edited';
export const COMPANY_CUSTOM_FIELD_DELETED = 'Company Custom Field Deleted';
export const COMPANY_DELETED = 'Company Deleted';
export const COMPANY_EDITED = 'Company Edited';

/*
    Contacts
*/
export const CONTACT_ADDED = 'Contact Added';
export const CONTACT_ADDED_TO_CAMPAIGN = 'Added to Campaign';
export const CONTACT_CUSTOM_FIELD_CREATED = 'Contact Custom Field Created';
export const CONTACT_CUSTOM_FIELD_EDITED = 'Contact Custom Field Edited';
export const CONTACT_CUSTOM_FIELD_DELETED = 'Contact Custom Field Deleted';
export const CONTACT_EDITED = 'Contact Edited';
export const CONTACT_FILE_UPLOADED = 'Contact File Uploaded';
export const CONTACT_LIST_CREATED = 'Contact List Created';
export const CONTACT_LIST_EDITED = 'Contact List Edited';
export const CONTACT_LIST_VIEWED = 'Saved Contact List Viewed';
export const CONTACT_RECORD_VIEWED = 'Contact Record Viewed';
export const CONTACT_INFO_VIEWED = 'Contact Info Viewed';
export const CONTACT_REMOVED_FROM_ADVANCED_AUTOMATION = 'Removed From Advanced Automation';
export const CONTACT_REMOVED_FROM_EASY_AUTOMATION = 'Removed From Easy Automation';
export const CONTACTS_BROADCAST_STARTED = 'Broadcast Started';
export const CONTACTS_IMPORTED = 'Contacts Imported';
export const CONTACTS_EXPORTED = 'Contacts Exported';
export const ALL_CONTACTS_SORTED = 'All Contacts Sorted';
export const COMPANY_CONTACT_SORTED = 'Company Contacts Sorted';
export const ALL_CONTACTS_LIST_VIEWED = 'All Contacts List Viewed';
export const CONTACTS_LIST_FILTER_MODAL_VIEWED = 'Filter Contacts Modal Viewed';
export const FILTERED_CONTACTS_LIST_VIEWED = 'Filtered Contacts List Viewed';
export const FILTERED_CONTACTS_LIST_SAVED = 'Filtered Contacts List Saved';
export const CONTACTS_IMPORT_STARTED = 'Started Contacts Import';
export const CONTACTS_IMPORT_UPLOADED = 'File Uploaded Contacts Import';
export const CONTACTS_IMPORT_FIELDS_MATCHED = 'Fields Matched Contacts Import';
export const CONTACTS_IMPORT_MARKETABILITY_SELECTED = 'Marketability Selected Contacts Import';
export const CONTACTS_IMPORT_ACCOUNT_CONNECTED = 'Account Connected Contacts Import';
export const CONTACTS_IMPORT_CONTACTS_SELECTED = 'Contacts Selected Contacts Import';
export const CONTACT_TYPE_CHANGED = 'Contact Type Changed';

/*
    Emails
*/
export const EMAIL_SENT = 'Email Sent';
export const EMAIL_TEMPLATE_CREATED = 'Email Template Created';
export const EMAIL_TEMPLATE_EDITED = 'Email Template Edited';

/*
    FTU
*/
export const FTU_CTA_SELECTED = 'FTU CTA Selected';
export const FTU_TOUR_COMPLETED = 'FTU Tour Completed';
export const FTU_TOUR_STARTED = 'FTU Tour Started';
export const FTU_VIDEO_STARTED = 'FTU Video Started';

/*
    Getting Started
*/
export const GETTING_STARTED_JOB_COMPLETED = 'Getting Started Job Completed';
export const GETTING_STARTED_TASK_COMPLETED = 'Getting Started Task Completed';
export const GETTING_STARTED_TASK_DISMISSED = 'Getting Started Task Dismissed';
export const GETTING_STARTED_TASK_STARTED = 'Getting Started Task Started';

/*
    New Onboarding
*/
export const NEW_ONBOARDING_JOB_SELECTED = 'New Onboarding Job Selected';
export const NEW_ONBOARDING_JOB_STARTED = 'New Onboarding Job Started';
export const NEW_ONBOARDING_JOB_COMPLETED = 'New Onboarding Job Completed';
export const ONBOARDING_TOUR_SKIPPED = 'Onboarding tour skipped';
export const ONBOARDING_TOUR_STARTED = 'Onboarding tour started';
export const ONBOARDING_TOUR_COMPLETED = 'Onboarding tour completed';

/*
    Integrations
*/
export const INTEGRATION_SELECTED = 'Integration Selected';
export const INTEGRATION_CONNECTED = 'Integration Connected';
export const INTEGRATION_CONNECTION_STARTED = 'Integration Connection Started';
export const INTEGRATION_CONNECTION_EDITED = 'Integration Connection Edited';
export const GOOGLE_MY_BUSINESS_LISTING_CONNECTED = 'Google My Business Listing Connected';
export const PAYMENT_PROCESSOR_CONNECTED = 'Payment Processor Connected';
export const ZAPIER_INTEGRATION_SELECTED = 'Zapier Integration Selected';

/*
    Invoices
*/
export const INVOICE_LINK_COPY = 'Invoice Link Copied from Review Card';
export const INVOICE_CREATED = 'Invoice Created';
export const INVOICE_PAID = 'Invoice Paid';
export const INVOICE_RECURRING_CREATED = 'Recurring Invoice Created';
export const INVOICE_SENT = 'Invoice Sent';
export const INVOICE_SENT_FROM_CARD = 'Invoice Sent from Review Card';
export const INVOICE_VIEWED = 'Invoice Viewed';
export const RECURRING_ITEM_BILLING_CYCLE = 'Recurring Invoice Item Billing Cycle';
export const RECURRING_ITEM_EDIT = 'Recurring Invoice Item Edited';
export const RECURRING_ITEM_SAVE = 'Recurring Invoice Item Saved';
export const RECURRING_CREATED_FROM_PUBLIC_INVOICE = 'Recurring Payment from Public Invoice Created';
export const RECURRING_CREATED_FROM_MANUAL_INVOICE_PAYMENT = 'Recurring Payment from Manual Invoice Payment Created';

/*
    Notes
*/
export const NOTE_ADDED = 'Note Added';
export const NOTE_UNSAVED = 'Unsaved Changes';
export const NOTE_EDITED = 'Note Edited';

/*
    Onboarding
*/
export const ONBOARDING_REQUIRED = 'Onboarding Required';

/*
    Payments
*/
export const RECURRING_CREATED_FROM_MANUAL_PAYMENT = 'Recurring Payment Created';

/*
    Pipeline
*/
export const PIPELINE_CREATED = 'Pipeline Created';
export const PIPELINE_DEAL_ADDED = 'Pipeline Deal Added';
export const PIPELINE_DEAL_MOVED = 'Pipeline Deal Moved';
export const PIPELINE_DEAL_UPDATED = 'Pipeline Deal Updated';

/*
    Products
*/
export const PRODUCT_ADDED = 'Product Added';

/*
    Promo Codes
*/
export const PROMO_CODE_EVENT = 'Promo Code Event';

/*
    Quotes
*/
export const QUOTE_ACCEPTED = 'Quote Accepted';
export const QUOTE_CREATED = 'Quote Created';
export const QUOTE_SENT = 'Quote Sent';
export const QUOTE_VIEWED = 'Quote Viewed';

/*
    Sample Data
*/
export const SAMPLE_DATA_ADDED = 'Sample Data Added';
export const SAMPLE_DATA_DISMISSED = 'Sample Data Dismissed';
export const SAMPLE_DATA_REMOVED = 'Sample Data Removed';

/*
    Sales Tax
*/
export const SALES_TAX_CREATED = 'New Sales Tax Created';
export const ADD_SALES_TAX_SELECTED = 'Add Sales Tax Select in Settings';
export const SALES_TAX_APPLIED = 'Sales Tax Applied';

/*
    Tags
*/
export const TAG_APPLIED = 'Tag Applied';
export const TAG_CREATED = 'Tag Created';
export const TAG_REMOVED = 'Tag Removed';

/*
    Tasks
*/
export const TASK_COMPLETED = 'Task Completed';
export const TASK_CREATED = 'Task Created';
export const TASK_UPDATED = 'Task Updated';

/*
    Misc
*/
export const ENGAGED_WITH_LCM = 'Engaged With LCM';
export const FREE_TRIAL_UPGRADED = 'Free Trial Upgraded';
export const PAGE_VIEWED = 'Page Viewed';

/*
    Forms
*/
export const FORM_VIEWED = 'Form viewed';
export const FORM_SUBMITTED = 'Form submitted';
export const STARTED_BUILDING_FORM = 'Started building a form';
export const NEW_FORM_TEMPLATE_SELECTED = 'New form template selected';
export const FIELD_ADDED_TO_FORM = 'Field added to form';
export const UPDATED_FORM = 'Updated form';
export const UPDATED_FORM_STYLE = 'Updated form style';
export const COPIED_FORM_CODE_LINK = 'Copied form code or link';
export const CLOSED_FORM_BUILDER = 'Closed form builder';
export const FORM_DELETED = 'Form deleted';
export const FIELD_DELETED = 'Field deleted';

/*
    eHawk
*/
export const EHAWK_CHECKED = 'eHawk Checked';
