<template>
    <div class="send-sms-step configure-editor">
        <transition name="fade">
            <div v-if="showTemplateSelector" class="template-selector">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="sms-body select-option">
                    <div class="button-container">
                        <ds-text-button leading-icon="add" @click="selectBlankTemplate">
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(template, i) in filteredSmsTemplates"
                        :key="i"
                        as="a"
                        class="list-item-option sms-template"
                        :data-qa="`template_${template.title}`"
                        :title="template.title"
                        :description="template.content"
                        @click="handleTemplateSelection(template, template.title)"
                    />
                </section>
            </div>

            <div v-if="showSmsEditor" class="sms-config">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editContent') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-dropdown
                        class="sms-actions"
                        icons
                        position="bottom-end"
                    >
                        <template #default>
                            <ds-icon-button name="more-vertical" />
                        </template>

                        <template #menu>
                            <ul class="dropdown-menu-list">
                                <li
                                    class="option dropdown-menu-item"
                                    data-qa="sms-settings"
                                    @click="isSmsBroadcastSettingsModalOpen = true"
                                >
                                    <ds-icon class="option-icon" name="settings" />

                                    <span>{{ $t('settings') }}</span>
                                </li>

                                <li
                                    class="option dropdown-menu-item"
                                    data-qa="sms-send-test"
                                    @click="checkSendTest"
                                >
                                    <ds-icon class="option-icon" name="message" />

                                    <span>{{ $t('sendTest') }}</span>
                                </li>
                            </ul>
                        </template>
                    </ds-dropdown>

                    <ds-filled-button data-qa="send-sms-editor-next" @click="handleNext">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="sms-form">
                    <section class="sms-content">
                        <div class="sms-content-wrapper">
                            <div class="from-container">
                                <ds-input-field
                                    data-qa="from-number"
                                    class="sms-input"
                                    :label="$t('editor.from')"
                                    readonly
                                    :value="fromNumber"
                                />

                                <ds-popover
                                    class="from-number-popover"
                                    :is-open="isFromTooltipOpen"
                                    position="right"
                                    position-fixed
                                >
                                    <template #reference>
                                        <ds-icon-button
                                            class="tooltip-button"
                                            name="help-circle"
                                            @click="toggleFromTooltip"
                                            @mouseover="openFromTooltip"
                                            @mouseleave="closeFromTooltip"
                                        />
                                    </template>

                                    <template #default>
                                        <div class="from-tooltip">
                                            <i18n tag="div" path="fromTooltip.1">
                                                <template #marketingNumber>
                                                    <strong>{{ $t('fromTooltip.2') }}</strong>
                                                </template>
                                            </i18n>
                                        </div>
                                    </template>
                                </ds-popover>
                            </div>

                            <text-automation-walkthrough-step class="phone-type-walkthrough" :step="1">
                                <sms-phone-type-selector
                                    v-if="smsAutomationPhoneTypesEnabled"
                                    v-model="phoneTypes"
                                    data-qa="sms-phone-type-selector"
                                    :show-error="showPhoneTypesError"
                                    @input="handleEdit"
                                />

                                <ds-input-field
                                    v-else
                                    v-model="toFieldDisplay"
                                    data-qa="to-field"
                                    :label="$t('editor.to')"
                                    class="sms-input"
                                    readonly
                                >
                                    <template #help>
                                        {{ $t('toFieldWarning') }}
                                    </template>
                                </ds-input-field>
                            </text-automation-walkthrough-step>

                            <ds-inline-alert
                                v-if="phoneTypes.length > 1"
                                class="multiple-phone-type-alert"
                                type="warning"
                                leading-icon
                            >
                                <span class="alert-text">
                                    {{ $t('multiplePhoneTypeAlert') }}

                                    <ds-link as="button" class="alert-link" @click="isSmsMultiplePhoneAlertModalOpen = true">
                                        {{ $t('seeYourOptions') }}
                                    </ds-link>
                                </span>
                            </ds-inline-alert>

                            <div class="message-name-container">
                                <ds-input-field
                                    v-model="title"
                                    class="sms-input"
                                    data-qa="sms-title"
                                    :label="$t('editor.messageName')"
                                    :submitted="showTitleError"
                                    :maxlength="$options.MAXLENGTH.NAME"
                                    required
                                    @input="handleEdit"
                                />

                                <ds-popover ref="messageNameToolTip" :is-open="isMessageNameTooltipOpen" position="right">
                                    <template #reference>
                                        <ds-icon-button
                                            class="tooltip-button"
                                            name="help-circle"
                                            @click="toggleMessageNameTooltip"
                                            @mouseover="openMessageNameTooltip"
                                            @mouseleave="closeMessageNameTooltip"
                                        />
                                    </template>

                                    <template #default>
                                        <div class="message-name-tooltip">
                                            {{ $t('messageNameToolTip') }}
                                        </div>
                                    </template>
                                </ds-popover>
                            </div>

                            <sms-text-box
                                v-if="showTextBox"
                                v-model="smsMessage"
                                :use-merge-service="useMergeService"
                                :additional-merge-fields="additionalMergeFields"
                                :merge-fields="getSupportedMergeFields(additionalMergeFields, useMergeService)"
                                :source="$options.AUTOMATIONS_SOURCE"
                                @input="handleMessageEdit"
                            />

                            <sms-automation-message-input
                                v-else
                                v-model="smsMessage"
                                class="sms-input"
                                :additional-merge-fields="additionalMergeFields"
                                :submitted="showMessageError"
                                @input="handleMessageEdit"
                            />

                            <sms-invalid-character-alert
                                v-if="isMessageInvalid"
                                data-qa="encoding-alert"
                                class="short-error-banner message-error-alert"
                            />

                            <ds-inline-alert
                                v-if="showErrorBanner || showSendTestError"
                                class="sms-input short-error-banner message-error-alert"
                                type="critical"
                                leading-icon
                            >
                                {{ errorMessage }}
                            </ds-inline-alert>

                            <ds-inline-alert
                                v-if="showUnsupportedMergeFieldError"
                                data-qa="unsupported-merge-field"
                                class="short-error-banner message-error-alert"
                                type="critical"
                                leading-icon
                            >
                                <template #default>
                                    {{ $t('unsupportedMergeFieldError') }}
                                </template>

                                <template #footer>
                                    <ds-inline-alert-footer-link href="https://forms.gle/Nk2GVGkbYcUU1LsZ9">
                                        {{ $t('unsupportedMergeFieldLinkDescription') }}
                                    </ds-inline-alert-footer-link>
                                </template>
                            </ds-inline-alert>

                            <text-automation-walkthrough-step :step="4">
                                <ds-inline-alert
                                    v-if="textMarketingBillableAutomationMessageEnabled && !pricingAlertTutorialComplete"
                                    class="pricing-alert"
                                    type="info"
                                    data-qa="pre-chargebee"
                                    @close="markPricingAlertTutorialComplete"
                                >
                                    <i18n class="pricing-alert-text" path="pricingAlert">
                                        <template #bold>
                                            <strong>{{ $t('pricingAlertBold') }}</strong>
                                        </template>
                                    </i18n>
                                </ds-inline-alert>

                                <ds-inline-alert
                                    v-if="!textMarketingBillableAutomationMessageEnabled && !chargeebeePricingAlertTutorialComplete"
                                    class="pricing-alert"
                                    type="subtle"
                                    data-qa="chargebee"
                                    @close="markChargeebeePricingAlertTutorialComplete"
                                >
                                    <span>{{ $t('chargebeePricingAlert') }}</span>
                                </ds-inline-alert>
                            </text-automation-walkthrough-step>

                            <div class="sms-footer-wrapper">
                                <sms-footer />
                            </div>
                        </div>
                    </section>

                    <preview
                        class="preview"
                        :message="smsMessage"
                        :merge-fields="getSupportedMergeFields(additionalMergeFields, useMergeService)"
                        :source="$options.AUTOMATIONS_SOURCE"
                    />
                </section>
            </div>

            <div v-if="showTimingStep" class="timing-editor">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editOptions') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="send-sms-step-done" @click="handleDone(true)">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="timing-body">
                    <timing-step
                        v-if="timingRefactorEnabled"
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ $t('smsTitle', { title }) }}
                    </timing-step>

                    <configure-timing-step
                        v-else
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ $t('smsTitle', { title }) }}
                    </configure-timing-step>
                </section>
            </div>
        </transition>

        <portal to="root">
            <sms-broadcast-settings-modal
                v-if="isSmsBroadcastSettingsModalOpen"
                data-qa="sms-broadcast-settings-modal"
                @close="isSmsBroadcastSettingsModalOpen = false"
            />
        </portal>

        <portal to="root">
            <send-test-message-modal
                data-qa="sms-send-test-modal"
                :from="fromNumber"
                :message="smsMessage"
                :is-open="isSendTestModalOpen"
                source="Easy Automation"
                @close="isSendTestModalOpen = false"
            />
        </portal>

        <portal to="root">
            <sms-multiple-phone-alert-modal
                :is-open="isSmsMultiplePhoneAlertModalOpen"
                @close="isSmsMultiplePhoneAlertModalOpen = false"
            />
        </portal>

        <portal to="root">
            <text-automation-ftu-modal
                class="text-auto-ftu-modal"
                :is-open="!textAutomationOnboardingTutorialComplete"
                @continue="markAutomationSmsFtuModalComplete"
                @close="markAutomationSmsWalkthroughComplete"
            />
        </portal>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import amplitude from '@/analytics/amplitude';
import { validate } from '@/automations/utils/automations.utils';

import SaveStatus from '@/automations/components/SaveStatus';
import ConfigureTimingStep from '@/automations/components/configure/ConfigureTimingStep';
import SmsAutomationMessageInput from '@/automations/components/SmsAutomationMessageInput';
import SmsTextBox, { AUTOMATIONS_SOURCE } from '@/shared/components/Text/SmsTextBox';
import Preview from '@/broadcasts/components/sms/SmsBroadcastPreview';
import SmsFooter from '@/broadcasts/components/sms/SmsBroadcastFooter';
import SmsBroadcastSettingsModal from '@/broadcasts/components/sms/SmsBroadcastSettingsModal';
import SendTestMessageModal from '@/broadcasts/components/sms/SendTestMessageModal';
import SmsPhoneTypeSelector from '@/broadcasts/components/sms/SmsPhoneTypeSelector';
import TimingStep from '@/automations/components/steps/TimingStep';
import SmsMultiplePhoneAlertModal from '@/broadcasts/components/sms/SmsMultiplePhoneAlertModal';
import SmsInvalidCharacterAlert from '@/shared/components/SmsInvalidCharacterAlert';
import TextAutomationFtuModal from '@/automations/components/TextAutomationFtuModal';
import TextAutomationWalkthroughStep from '@/automations/components/TextAutomationWalkthroughStep';

import displayMixin from '@/shared/mixins/display.mixin';

import { hasUnsupportedMergeField } from '@/broadcasts/utils/smsBroadcast.util';

import {
    SEND_SMS, EDITOR_STEP, TEMPLATE_STEP, TIMING_STEP, MAXLENGTH,
} from '@/automations/constants/automations.constants';
import { SMS_DEFAULT_PHONE_TYPES } from '@/shared/sms.constants';
import {
    FF_KEAP_SMS_RICH_TEXT_BOX,
    FF_KEAP_MERGE_SERVICE,
    FF_SIMPLE_AUTOMATION_TIMING_REFACTOR,
    FF_KEAP_SMS_AUTOMATION_PHONE_TYPES,
    TEXT_MARKETING_BILLABLE_AUTOMATION_MESSAGE,
} from '@/shared/constants/featureFlag.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { MAX_CHARACTER_COUNT } from '@/broadcasts/smsBroadcast.constants';

export default {
    MAXLENGTH,
    AUTOMATIONS_SOURCE,

    components: {
        SmsInvalidCharacterAlert,
        ConfigureTimingStep,
        Preview,
        SaveStatus,
        SendTestMessageModal,
        SmsFooter,
        SmsAutomationMessageInput,
        SmsTextBox,
        SmsBroadcastSettingsModal,
        SmsPhoneTypeSelector,
        TimingStep,
        SmsMultiplePhoneAlertModal,
        TextAutomationFtuModal,
        TextAutomationWalkthroughStep,
    },

    mixins: [displayMixin],

    props: {
        saveStatus: String,
        showErrors: Boolean,

        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            currentStep: TEMPLATE_STEP,
            timingDelay: this.automationProperties.delayStep || null,
            search: '',
            isFromTooltipOpen: false,
            isMessageNameTooltipOpen: false,
            toFieldDisplay: 'PHONE 1',
            fromNumber: this.formatPhone(this.smsPhoneNumber),
            title: '',
            smsMessage: '',
            phoneTypes: [...SMS_DEFAULT_PHONE_TYPES],
            isSmsBroadcastSettingsModalOpen: false,
            isSendTestModalOpen: false,
            showSendTestError: false,
            isSmsMultiplePhoneAlertModalOpen: false,
            isPriceAlertOpen: true,
        };
    },

    created() {
        this.init();
    },

    watch: {
        'automationProperties.delayStep': {
            handler(value) {
                this.timingDelay = value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapGetters({
            addMergeFields: 'mergeFields/addMergeFields',
            getSupportedMergeFields: 'mergeFields/getSupportedMergeFields',
            additionalMergeFields: 'automations/additionalMergeFields',
            hasAppointmentTrigger: 'automations/hasAppointmentTrigger',
        }),

        ...mapState({
            timingRefactorEnabled: ({ featureFlags }) => featureFlags[FF_SIMPLE_AUTOMATION_TIMING_REFACTOR],
            smsPhoneNumber: ({ auth }) => auth.account.automatedSmsAccount?.phoneNumber,
            showTextBox: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_RICH_TEXT_BOX],
            smsAutomationPhoneTypesEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_AUTOMATION_PHONE_TYPES],
            useMergeService: ({ featureFlags }) => featureFlags[FF_KEAP_MERGE_SERVICE],
            textMarketingBillableAutomationMessageEnabled: ({ featureFlags }) => featureFlags[TEXT_MARKETING_BILLABLE_AUTOMATION_MESSAGE],
            tutorials: ({ tutorials }) => tutorials.items,
        }),

        showTitleError() {
            return this.showErrors && !validate[SEND_SMS].title(this.smsStep);
        },

        showMessageError() {
            return (this.showErrors && !validate[SEND_SMS].message(this.smsStep)) || this.showSendTestError;
        },

        showPhoneTypesError() {
            return this.smsAutomationPhoneTypesEnabled && this.showErrors && !validate[SEND_SMS].phoneTypes(this.smsStep);
        },

        showErrorBanner() {
            return this.showErrors && !validate[SEND_SMS].isValid(this.smsStep);
        },

        showUnsupportedMergeFieldError() {
            const mergeFieldValues = Object.keys(this.getSupportedMergeFields(this.additionalMergeFields, this.useMergeService));

            return hasUnsupportedMergeField(this.smsMessage, mergeFieldValues);
        },

        isMessageInvalid() {
            return !validate[SEND_SMS].messageEncoding(this.smsStep);
        },

        showTemplateSelector() {
            return this.currentStep === TEMPLATE_STEP;
        },

        showSmsEditor() {
            return this.currentStep === EDITOR_STEP;
        },

        showTimingStep() {
            return this.currentStep === TIMING_STEP;
        },

        smsTemplates() {
            const templates = [];

            if (this.hasAppointmentTrigger) {
                templates.push({ title: this.$t('appointmentReminder.title'), content: this.$t('appointmentReminder.content') });
            }

            return [
                ...templates,
                { title: this.$t('invitationToMeet.title'), content: this.$t('invitationToMeet.content') },
                { title: this.$t('requestReview.title'), content: this.$t('requestReview.content') },
            ];
        },

        filteredSmsTemplates() {
            const search = this.search?.trim()?.toLowerCase();

            return this.smsTemplates.filter(({ title, content }) => {
                return title.toLowerCase().includes(search)
                    || content.toLowerCase().includes(search);
            });
        },

        noSearchResults() {
            return this.search && !this.filteredSmsTemplates.length;
        },

        smsStep() {
            const { title, smsMessage, phoneTypes } = this;

            const configJson = {
                title,
                message: smsMessage,
                toFieldName: 'PHONE1',
            };

            if (this.smsAutomationPhoneTypesEnabled) {
                configJson.phoneTypes = phoneTypes;
            }

            return {
                type: SEND_SMS,
                name: this.$t('smsName'),
                configJson,
            };
        },

        errorMessage() {
            if (this.showSendTestError) {
                return this.$t('errorBanner.sendTest');
            }

            if (!validate[SEND_SMS].messageLength(this.smsStep)) {
                return this.$t('errorBanner.length', { maxCharacterCount: MAX_CHARACTER_COUNT });
            }

            if (this.isMessageInvalid) {
                return this.$t('errorBanner.encoding');
            }

            return this.$t('errorBanner.required');
        },

        pricingAlertTutorialComplete() {
            return Boolean(this.tutorials[TUTORIAL_TYPES.TEXT_AUTOMATION_PRICING_ALERT]);
        },

        chargeebeePricingAlertTutorialComplete() {
            return Boolean(this.tutorials[TUTORIAL_TYPES.TEXT_AUTOMATION_CHARGEEBEE_PRICING_ALERT]);
        },

        textAutomationOnboardingTutorialComplete() {
            return Boolean(this.tutorials[TUTORIAL_TYPES.ONBOARDING.TOURS.TEXT_AUTOMATION]);
        },

        textAutomationWalkthroughStepsComplete() {
            return this.tutorials[TUTORIAL_TYPES.TEXT_AUTOMATION_WALKTHROUGH_STEP];
        },
    },

    methods: {
        init() {
            this.fromNumber = this.formatPhone(this.smsPhoneNumber);
            const { initialStep, step, delayStep } = this.automationProperties;

            if (step?.configJson) {
                const {
                    title, message, phoneTypes,
                } = step.configJson;

                this.title = title;
                this.smsMessage = message;
                this.phoneTypes = phoneTypes || [...SMS_DEFAULT_PHONE_TYPES];
            }

            this.timingDelay = delayStep || null;

            if (initialStep === EDITOR_STEP) {
                this.handleTemplateSelection({ title: this.title, content: this.smsMessage });
            } else if (initialStep === TIMING_STEP) {
                this.currentStep = TIMING_STEP;
            }
        },

        handleTemplateSelection(template, trackName) {
            if (trackName) {
                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                    'Event Source': 'Sms Template Clicked',
                    'Template Name': trackName,
                });
            }
            const { title, content } = template;

            this.title = title;
            this.smsMessage = content;

            this.currentStep = EDITOR_STEP;

            this.handleEdit();
        },

        selectBlankTemplate() {
            this.handleTemplateSelection({
                title: '',
                content: '',
            }, 'blank');
        },

        handleEdit() {
            this.handleDone(false);
        },

        handleMessageEdit(val) {
            this.showSendTestError = false;
            this.smsMessage = val;
            this.handleEdit();
        },

        handleDone(close = false) {
            const steps = [this.smsStep];
            const hasDelay = this.timingDelay && Object.keys(this.timingDelay).length;

            if (hasDelay) {
                steps.unshift(this.timingDelay);
            }

            this.$emit('updated', steps);

            if (close) {
                this.$emit('close');
            }
        },

        handleBack() {
            this.showSendTestError = false;

            if (this.currentStep === TIMING_STEP) {
                const { smsMessage, title } = this;

                this.handleTemplateSelection({ title, content: smsMessage });
            } else if (this.currentStep === EDITOR_STEP) {
                this.currentStep = TEMPLATE_STEP;
            }
        },

        handleNext() {
            this.currentStep = TIMING_STEP;
        },

        updateTiming(timingDelay) {
            this.timingDelay = timingDelay;
            this.handleEdit();
        },

        toggleFromTooltip() {
            this.isFromTooltipOpen = !this.isFromTooltipOpen;
        },

        openFromTooltip() {
            this.isFromTooltipOpen = true;
        },

        closeFromTooltip() {
            this.isFromTooltipOpen = false;
        },

        toggleMessageNameTooltip() {
            this.isMessageNameTooltipOpen = !this.isMessageNameTooltipOpen;
        },

        openMessageNameTooltip() {
            this.isMessageNameTooltipOpen = true;
        },

        closeMessageNameTooltip() {
            this.isMessageNameTooltipOpen = false;
        },

        checkSendTest() {
            if (this.smsMessage) {
                this.isSendTestModalOpen = true;
            } else {
                this.showSendTestError = true;
            }
        },

        markPricingAlertTutorialComplete() {
            this.markTutorialComplete(TUTORIAL_TYPES.TEXT_AUTOMATION_PRICING_ALERT);
        },

        markChargeebeePricingAlertTutorialComplete() {
            this.markTutorialComplete(TUTORIAL_TYPES.TEXT_AUTOMATION_CHARGEEBEE_PRICING_ALERT);
        },

        markAutomationSmsFtuModalComplete() {
            this.markTutorialComplete(TUTORIAL_TYPES.ONBOARDING.TOURS.TEXT_AUTOMATION);
            amplitude.v2.logEvent(amplitude.v2.events.ONBOARDING_TOUR_STARTED, {
                'Event source': 'Easy Automation',
                Type: 'Text Automation',
            });
        },

        async markAutomationSmsWalkthroughComplete() {
            await this.$store.dispatch('tutorials/UPDATE_TUTORIALS_FOR_USER', {
                [TUTORIAL_TYPES.ONBOARDING.TOURS.TEXT_AUTOMATION]: true,
                [TUTORIAL_TYPES.TEXT_AUTOMATION_WALKTHROUGH_STEP]: -1,
            });

            amplitude.v2.logEvent(amplitude.v2.events.ONBOARDING_TOUR_SKIPPED, {
                'Event source': 'Easy Automation',
                Type: 'Text Automation',
            });
        },

        async markTutorialComplete(key) {
            await this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key,
                value: true,
                forUser: true,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/automations/styles/stepEditor';

.sms-form {
    display: flex;
    height: calc(100vh - #{px-to-rem(56px)});
}

.sms-input {
    width: px-to-rem(300px);
}

.sms-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: px-to-rem(380px);

    .sms-content-wrapper {
        padding: $gp * 2 $gp * 1.5 0;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .alert-link {
        color: $color-text-normal;
        font-size: $font-size-med;
        text-decoration: underline;
        line-height: $line-height-lg;
    }
}

.pricing-alert-text {
    line-height: $line-height-lg;
}

.from-container, .message-name-container {
    display: flex;
}

.phone-type-walkthrough {
    --popover-min-width: #{px-to-rem(321)};
    --popover-width: #{px-to-rem(321)};
}

.from-number-popover {
    --popover-width: #{px-to-rem(320)};
    --popover-min-width: #{px-to-rem(320)};
}

.tooltip-button {
    margin: $gp / 4 $gp / 2 0;

    --icon-color: #{$color-gray-800};
    --icon-size: #{$gp};
}

.from-tooltip, .message-name-tooltip {
    padding: $gp;
}

.multiple-phone-type-alert {
    margin-bottom: $gp * 2;
}

.message-error-alert {
    margin-bottom: $gp;
}

.alert-text {
    line-height: $line-height-lg;
}

.preview {
    padding: 0 $gp * 1.5;
    flex: 1;
}

.sms-footer-wrapper {
    margin-top: auto;
}

.sms-actions {
    padding: 0 $gp 0 0;
    --dropdown-minwidth: #{px-to-rem(175px)};
    --dropdown-maxwidth: #{px-to-rem(200px)};
}

@mixin small-sms-view() {
    .sms-input,
    .sms-content {
        width: 100%;
    }

    .sms-content .sms-content-wrapper {
        padding-top: $gp * 1.5;
        padding-left: $gp;
        padding-right: $gp;
    }

    .preview {
        border: 0;
        padding: 0;
    }
}

@media($extra-small) {
    @include small-sms-view();
}

.contextual-view {
    @include small-sms-view();

    .sms-form {
        height: calc(100vh - #{px-to-rem(74px)});
    }
}
</style>

<i18n>
{
    "en-us": {
        "header": "Choose template",
        "buttonText": "Start with a blank message",
        "editContent": "Edit content",
        "editOptions": "Edit options",
        "smsTitle": "Send ({title}) text",
        "smsName": "Send text",
        "editor": {
            "from": "From",
            "to": "To",
            "messageName": "Message name"
        },
        "search": "Search",
        "emptySearch": "No results found for \"{search}\"",
       "fromTooltip": {
            "1": "This is your {marketingNumber}. Recipients will see it each time you send a text message broadcast or automated text message.",
            "2": "Keap Marketing Number"
        },
        "multiplePhoneTypeAlert": "Because you have multiple phone types added, some recipients may get this message more than once.",
        "seeYourOptions": "See your options",
        "messageNameToolTip": "The name of your text message is only visible to you.",
        "toFieldWarning": "Make sure your contacts have a valid mobile number.",
        "unsupportedMergeFieldError": "The merge field you’re attempting to enter is not yet supported",
        "unsupportedMergeFieldLinkDescription": "What merge fields would be helpful?",
        "appointmentReminder": {
            "title": "Appointment reminder",
            "content": "Hi [[contact.first_name]]. Just a reminder that your appointment is coming up on [[appointment.date]] at [[appointment.start_time]]. See you then!"
        },
        "invitationToMeet": {
            "title": "Invitation to meet",
            "content": "Hi [[contact.first_name]]. Hope you're doing well. I'd like to connect with you so I can learn about your needs. Let me know what times work best for you!"
        },
        "requestReview": {
            "title": "Review request",
            "content": "Hi [[contact.first_name]]. Great working with you! If you have time, a review from you would help me improve my services and help others find me. Thank you!"
        },
        "sendTest": "Send a test",
        "settings": "Settings",
        "pricingAlert": "Beginning Sep 11, 2021 {bold} each time a text message is sent and the recipient opts in.",
        "pricingAlertBold": "you’ll be charged $0.04 ",
        "chargebeePricingAlert": "You’ll be charged $0.04 each time a text message is sent and the recipient opts in.",
        "errorBanner": {
            "encoding": "Please remove unsupported characters",
            "length": "Please shorten your message to {maxCharacterCount} characters or less",
            "required": "Please fill out the required fields",
            "sendTest": "You must enter a message to send a test."
        }
    }
}
</i18n>
