<template>
    <controlled-modal
        :is-open="isOpen"
        size="sm"
        dialog
        show-close
        class="choose-name-modal"
        :title="modalTitle || $t('modalTitle')"
        @close="close"
    >
        <form
            class="choose-name-form"
            @submit.prevent="update"
        >
            <ds-input-field
                v-model.trim="name"

                data-qa="site-rename"
                required
                :maxlength="MAXLENGTH"
                :label="$t('inputLabel')"
            />
            <div class="button-row">
                <ds-filled-button
                    type="submit"
                    data-qa="site-rename-button"
                    :disabled="isNameInvalid"
                >
                    {{ buttonTitle || $t('confirm') }}
                </ds-filled-button>

                <ds-text-button @click="close">
                    {{ $t('global.cancel') }}
                </ds-text-button>
            </div>
        </form>
    </controlled-modal>
</template>

<script>

import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: { ControlledModal },

    props: {
        buttonTitle: String,
        modalTitle: String,
        isOpen: Boolean,
        initialName: String,
        defaultName: String,
    },

    data() {
        return {
            name: this.page?.name ?? this.defaultName,
            MAXLENGTH: 80,
        };
    },

    beforeDestroy() {
        this.close();
    },

    watch: {
        isOpen: {
            handler(newValue, oldValue) {
                if (newValue !== oldValue) {
                    if (newValue) {
                        this.name = this.initialName;
                    }
                }
            },

            immediate: true,
        },
    },

    computed: {
        isNameInvalid() {
            return !this.name;
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        update() {
            this.$emit('choose-name', this.name);
            this.close();
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
    .choose-name-form {
        padding: $gp;
    }
</style>
<i18n>
{
    "en-us": {
        "modalTitle": "Choose Name",
        "pageDefaultName": "Unnamed Page",
        "confirm": "Update",
        "inputLabel": "Name"
    }
}
</i18n>
