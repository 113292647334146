import { render, staticRenderFns } from "./GlobalAddButton.vue?vue&type=template&id=32030620&scoped=true&lang=html&"
import script from "./GlobalAddButton.vue?vue&type=script&lang=js&"
export * from "./GlobalAddButton.vue?vue&type=script&lang=js&"
import style0 from "./GlobalAddButton.vue?vue&type=style&index=0&id=32030620&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32030620",
  null
  
)

export default component.exports