export default {
    SET_EHAWK_PASSED(state, passed) {
        state.passed = passed;
    },

    SET_EHAWK_CHECKED(state, checked) {
        state.checked = checked;
    },

    SET_EHAWK_FORM_SUBMITTED(state, formSubmitted) {
        state.formSubmitted = formSubmitted;
    },

    SET_EHAWK_MODAL_OPEN(state, isModalOpen) {
        state.isModalOpen = isModalOpen;
    },

    SET_EHAWK_MODAL_CONTENT(state, { modalTitle, modalBody }) {
        state.modalTitle = modalTitle;
        state.modalBody = modalBody;
    },
};
