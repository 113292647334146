const phonePattern = new RegExp('('
    + '(?!<a[^>]*?>)' // do not match already linkified strings
    + '(?!\\s)' // do not match a leading space
    + '\\+?\\d{0,2}' // country code
    + '[(.-\\s]*?\\d{3}?[)-.\\s]*?' // region code
    + '\\d{1,4}[-.\\s]?' // first block
    + '\\d{1,4}[-.\\s]?' // next block
    + '\\d{1,9}?'// remaining numbers
    + '(?![^<]*?</a>)' // do not match already linkified strings
+ ')', 'gi');

export const linkifyPhone = (string) => {
    let linkifiedString = string;

    const matches = [];
    let currentMatch;

    do {
        currentMatch = phonePattern.exec(linkifiedString);

        if (currentMatch) {
            matches.push(currentMatch);
        }
    } while (currentMatch);

    if (matches.length > 0) {
        for (const match of matches) {
            linkifiedString = linkifiedString.replace(match[0], `<a class="sms-message-link" href="tel:${match[0]}">${match[0]}</a>`);
        }
    }

    return linkifiedString;
};

export const textToSlug = (text) => text
    .toLowerCase()
    .trim()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
