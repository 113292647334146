<template>
    <div class="company-dropdown">
        <ds-multiselect
            ref="company"
            bind-value-only
            searchable
            show-search-icon
            data-qa="company"
            :allow-add="allowAdd"
            :placeholder="$t('forms.company')"
            :options="searchResults"
            :add-text="addCompanyText"
            :value="value"
            :value-prop="valueProp"
            label-prop="companyName"
            :loading="loading"
            v-bind="$attrs"
            @add="addCompany"
            @input="$listeners.input"
            @updateSearch="updateSearch"
        >
            <template v-slot:option="{ option, highlight, isSelected, filter }">
                <div
                    :class="['menu-item', {
                        highlight,
                        separator: isSelected,
                    }]"
                >
                    <div
                        v-if="isSelected"
                        class="option-row prevent-select"
                        :data-qa="option.companyName"
                        @click.prevent.stop="removeCompany"
                    >
                        <ds-checkbox :value="isSelected" @input="removeCompany">
                            <div class="option-text">
                                <ds-text-highlighter
                                    v-if="option.companyName"
                                    :highlight="filter"
                                    :content="option.companyName"
                                />
                            </div>
                        </ds-checkbox>
                    </div>

                    <div
                        v-else
                        class="option-row prevent-select"
                        :data-qa="option.companyName"
                    >
                        <ds-icon class="category-icon" name="company" />

                        <div class="option-text">
                            <ds-text-highlighter
                                v-if="option.companyName"
                                :highlight="filter"
                                :content="option.companyName"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </ds-multiselect>

        <ds-popover
            v-if="overRenderLimit"
            position="right"
            data-qa="companySearchHelp"
            class="company-help"
        >
            <template #reference>
                <ds-icon-button name="help-circle" class="help-icon" />
            </template>
            <div class="popover-content small">
                {{ $t('contactList.companies.searchHelp') }}
            </div>
        </ds-popover>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import AddCompany from '@/contacts/components/companies/AddCompany';

import { trackCompanyCreated } from '@/contacts/analytics';
import { getCompanies } from '@/contacts/api';

import {
    IDLE,
    LOADING,
    SUCCESS,
    ERROR,
} from '@/contacts/loadingStatuses';
import { SEARCHING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

const RENDER_LIMIT = 1000;

export default {
    props: {
        allowAdd: Boolean,
        companies: Array,
        eventSource: String,
        value: [String, Number],
        valueProp: {
            type: String,
            default: 'id',
            docdescription: 'Value if not the default of "value"',
        },
    },

    data() {
        return {
            searchCriteria: '',
            companyLoadStatus: IDLE,
            localCompanies: [...this.companies],
        };
    },

    mounted() {
        if (!this.$attrs?.readonly) {
            this.load();

            this.load = debounce(this.load, SEARCHING_DEBOUNCE_DELAY);
            this.localCompanies = [...this.normalizedCompanies];
        }
    },

    watch: {
        companies() {
            this.localCompanies = [...this.normalizedCompanies];
            this.load();
        },
    },

    computed: {
        loading() {
            return this.companyLoadStatus === LOADING;
        },

        addCompanyText() {
            if (this.displayUserDefinedCompany) {
                return this.$t('addNew', { name: this.searchCriteria });
            }

            return this.$t('global.addCompany');
        },

        displayUserDefinedCompany() {
            return this.localCompanies.length && this.searchCriteria;
        },

        overRenderLimit() {
            return this.localCompanies.length >= RENDER_LIMIT;
        },

        searchCondition() {
            return new RegExp(this.searchCriteria, 'gi');
        },

        sortedCompanies() {
            return [...this.localCompanies].sort(this.sortCompanies);
        },

        searchResults() {
            if (this.searchCriteria) {
                return this.sortedCompanies.filter(({ companyName }) => companyName.match(this.searchCondition));
            }

            return this.$attrs?.readonly
                ? this.normalizedCompanies
                : this.sortedCompanies;
        },

        normalizedCompanies() {
            return this.companies.map(({
                id,
                value,
                label,
                companyName,
            }) => ({
                id: Number(id || value),
                companyName: label || companyName,
            }));
        },
    },

    methods: {
        addCompany() {
            if (this.searchCriteria === '' || this.searchResults.length) {
                this.addNewCompany();
            } else {
                this.addPredefinedCompany(this.searchCriteria);
            }
        },

        addNewCompany() {
            this.$track('Company Name Created', { 'Event Source': 'Contact Record' });
            this.$refs.company.toggle();

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddCompany,
                showRootClose: true,
                props: {
                    onAddCompany: this.onAddCompany,
                    eventSource: this.eventSource,
                    useDuplicate: true,
                    hideAddContact: true,
                    preventConfirmation: true,
                },
            });
        },

        addPredefinedCompany(companyName) {
            this.$store
                .dispatch('contacts/ADD_COMPANY', companyName)
                .then((company) => {
                    trackCompanyCreated(this.eventSource);

                    this.onAddCompany(company);
                })
                .catch(() => {
                    this.$error({
                        message: this.$t('errors.addCompany'),
                        bottom: true,
                    });
                });
        },

        onAddCompany(company) {
            this.$emit('input', Number(company.value || company.id));
        },

        removeCompany() {
            this.$emit('input', 0);
            this.$refs.company.deactivate();
        },

        sortCompanies(companyA, companyB) {
            if (companyA.id === Number(this.value)) {
                return -1;
            }

            if (companyB.id === Number(this.value)) {
                return 1;
            }

            if (companyA.companyName < companyB.companyName) {
                return -1;
            }

            if (companyA.companyName > companyB.companyName) {
                return 1;
            }

            return 0;
        },

        updateSearch(userInput) {
            this.searchCriteria = userInput;
            this.load();
        },

        load() {
            this.companyLoadStatus = LOADING;

            return getCompanies({
                term: this.searchCriteria,
                includeCount: true,
            })
                .then(({ companies }) => {
                    this.companyLoadStatus = SUCCESS;
                    this.localCompanies = Object.values([...this.localCompanies, ...companies].reduce((newCollection, { id, companyName }) => {
                        newCollection[id] = {
                            id: Number(id),
                            companyName,
                        };

                        return newCollection;
                    }, {}));

                    this.$emit('input', this.value);
                })
                .catch(() => {
                    this.companyLoadStatus = ERROR;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .company-dropdown {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .help-icon {
            margin-left: $gp / 2;
            margin-bottom: var(--input-margin-bottom, 0);
        }

        .small {
            font-size: $font-size-small;
        }
    }

    .menu-item .checkbox {
        --checkbox-padding: $gp /4;

        .option-text  {
            padding-left: $gp/2;
            --text-highlighter-matching-color: #{$color-blue};
        }
    }

    .company-help {
        align-self: start;
    }
</style>

<i18n>
{
    "en-us": {
        "addNew": "Add New: {name}"
    }
}
</i18n>
