<template lang="html">
    <div class="google-api-privacy-policy">
        <i18n path="statement" tag="small">
            <template #link>
                <a
                    :href="GOOGLE_PRIVACY_NOTICE_URL"
                    target="_blank"
                    rel="noopener noreferrer"
                >{{ $t('linkText') }}</a>
            </template>
        </i18n>
    </div>
</template>

<script>
export default {
    data() {
        return {
            GOOGLE_PRIVACY_NOTICE_URL: 'https://keap.com/legal/google-api-privacy-notice',
        };
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .google-api-privacy-policy {
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "statement": "Your information will be stored in accordance with our {link}.",
        "linkText": "Privacy Policies"
    }
}
</i18n>
