<template>
    <div class="product-purchased-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div v-if="!showAddOption">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="close" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-text-button
                            leading-icon="add"
                            data-qa="add-new-product"
                            @click="toggleAddOption"
                        >
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-if="showAnyResult"
                        as="a"
                        class="list-item-option"
                        data-qa="products_any"
                        :title="$t('anyProductPurchased.title')"
                        :description="$t('anyProductPurchased.description')"
                        @click="handleSelectAllProducts"
                    />

                    <p v-if="!search" class="list-header">
                        {{ $t('productsHeader') }}
                    </p>

                    <p v-if="noProducts" class="empty-list">
                        {{ $t('emptyProducts') }}
                    </p>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(product, i) in filteredProducts"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :data-qa="`products_${product.id}`"
                        :title="product.productName"
                        :description="product.productDesc"
                        @click="handleSelection(product)"
                    >
                        <template #trailingSlot>
                            {{ $n(product.productPrice, 'currency') }}
                        </template>
                    </ds-list-item>
                </section>
            </div>

            <automation-add-product
                v-else
                data-qa="show-add-option"
                @back="toggleAddOption"
                @product-created="handleOptionCreated"
            />
        </transition>
    </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import AutomationAddProduct from '@/automations/components/AutomationAddProduct';
import productOptions from '@/money/mixins/product-options';

import { PRODUCT_PURCHASED, ANY_PRODUCT_PURCHASED } from '@/automations/constants/automations.constants';

export default {
    components: {
        AutomationAddProduct,
    },

    mixins: [productOptions],

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedOption: {},
            showAddOption: false,
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    computed: {
        anyProductTrigger() {
            return {
                type: ANY_PRODUCT_PURCHASED,
                sourceId: null,
                name: this.$t('anyProductPurchased.name'),
            };
        },

        productTrigger() {
            const { id } = this.selectedOption;

            return {
                type: PRODUCT_PURCHASED,
                sourceId: id,
                name: this.$t('singleProductPurchased', { id }),
            };
        },

        showAnyResult() {
            const search = this.search?.trim()?.toLowerCase();

            return this.$t('anyProductPurchased.title').toLowerCase().includes(search)
                || this.$t('anyProductPurchased.description').toLowerCase().includes(search);
        },

        filteredProducts() {
            const search = this.search?.trim()?.toLowerCase();

            return this.productOptions_products.filter(({ productName, productDesc, productPrice }) => {
                return productName?.toLowerCase().includes(search)
                    || productDesc?.toLowerCase()?.includes(search)
                    || this.$n(productPrice, 'currency')?.includes(search);
            });
        },

        noProducts() {
            return !this.loading && !this.productOptions_productOptions.length;
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredProducts.length && !this.showAnyResult;
        },
    },

    methods: {
        init() {
            const { trigger } = this.automationProperties;

            if (trigger) {
                this.selectedOption = {
                    ...trigger,
                    id: trigger.sourceId,
                };
            } else {
                this.selectedOption = {
                    type: PRODUCT_PURCHASED,
                    id: null,
                    name: this.$t('productPurchased'),
                };
            }

            if (!this.productOptions_productOptions.length) {
                this.loadProducts();
            }
        },

        async loadProducts() {
            this.loading = true;
            await this.productOptions_loadProducts();
            this.loading = false;
        },

        handleSelection(product) {
            this.selectedOption = clonedeep(product);
            this.$emit('updated', this.productTrigger);
            this.$emit('close');
        },

        handleSelectAllProducts() {
            this.$emit('updated', this.anyProductTrigger);
            this.$emit('close');
        },

        toggleAddOption() {
            this.showAddOption = !this.showAddOption;
        },

        handleOptionCreated(product) {
            this.selectedOption = product;
            this.toggleAddOption();
            this.$emit('updated', this.productTrigger);
            this.$emit('close');
        },

        close() {
            const { type, sourceId } = this.productTrigger;

            if (type === PRODUCT_PURCHASED && !sourceId) {
                this.handleSelectAllProducts();
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "anyProductPurchased": {
            "name": "Any product is purchased",
            "title": "Any product or service",
            "description": "When anything is purchased"
        },
        "productPurchased": "Product is purchased",
        "singleProductPurchased": "({id}) is purchased",
        "emptyProducts": "None available",
        "header": "Choose product or service",
        "productsHeader": "Products and services",
        "buttonText": "Create a new product or service",
        "search": "Search",
        "emptySearch": "No results found for \"{search}\""
    }
}
</i18n>
