export const CONNECT_STATE = {
    SERVICE_UNAVAILABLE: 'ServiceUnavailable',
    CONNECTED: 'Connected',
    NOT_CONNECTED: 'NotConnected',
    CONNECTED_WITH_ISSUES: 'ConnectedWithIssues',
};

export const INTEGRATION_STATUS = {
    READY: 'READY',
    PENDING: 'PENDING',
    BROKEN: 'BROKEN',
    DISCONNECTED: 'DISCONNECTED',
};

export const LOCATION_TYPES = {
    ONLINE: 'ONLINE',
    ONLINE_TWILIO: 'ONLINE_TWILIO',
    ONLINE_ZOOM: 'ONLINE_ZOOM',
    PHONE: 'PHONE',
    IN_PERSON: 'IN_PERSON',
    CLIENT_SET_ONLINE: 'CLIENT_SET_ONLINE',
    CLIENT_SET_PHONE: 'CLIENT_SET_PHONE',
    CLIENT_SET_IN_PERSON: 'CLIENT_SET_IN_PERSON',
};

export const LOCATION_OPTIONS = [
    { value: LOCATION_TYPES.ONLINE, label: 'appointments.locations.ONLINE' },
    { value: LOCATION_TYPES.PHONE, label: 'appointments.locations.PHONE' },
    { value: LOCATION_TYPES.IN_PERSON, label: 'appointments.locations.IN_PERSON' },
];

export const BOOKING_MAX_DAYS = 365 * 3; // three years the farthest in the future we will allow people to book
export const BOOKING_FREEDAYS_START_OFFSET_HOURS = 4; // how far in the future to start looking for free-days

export const NEW_APPOINTMENT_TYPE_ROUTE_NAME = 'new-appointment-type';
