<template>
    <ds-dropdown
        v-show="mergeFields.length"
        :is-open="isOpen"
        class="merge-field-dropdown"
        :position="position"
        @close="closeMenu"
        @change="(payload) => $emit('change', payload)"
    >
        <template #default>
            <slot name="action" :toggle="toggle" />
        </template>

        <template #menu>
            <ds-multiselect-menu
                v-if="isOpen"
                v-model="selectedMergeField"
                data-qa="merge-field-menu"
                categorized
                :options="filteredMergeFields"
                :categories-label="$t('mergeFieldDropdown.categoryTitle')"
                @selectCategory="setSelected"
                @menuItemChanged="emitOpenMenu"
                @change="(val) => $emit('change', val)"
            />
        </template>
    </ds-dropdown>
</template>

<script>
import { mapGetters } from 'vuex';

import { CUSTOM_FIELD_CATEGORY } from '@/shared/constants/mergeFields.constants';
import { POPOVER_POSITIONS } from '@/shared/constants/popover.constants';

export default {
    props: {
        hideBorders: Boolean,
        hideCustomFields: Boolean,
        value: Object,
        additionalMergeFields: {
            type: Array,
            default: () => [],
        },
        position: {
            type: String,
            validator: (value) => POPOVER_POSITIONS.includes(value),
            default: 'bottom-end',
        },
        useMergeService: Boolean,
    },

    data() {
        return {
            isOpen: false,
            selectedMergeField: this.value,
        };
    },

    created() {
        this.load();
    },

    computed: {
        ...mapGetters({
            addMergeFields: 'mergeFields/addMergeFields',
        }),

        filteredMergeFields() {
            if (!this.hideCustomFields) {
                return this.mergeFields;
            }

            return this.mergeFields
                .filter(({ category }) => category.value !== CUSTOM_FIELD_CATEGORY);
        },

        mergeFields() {
            return this.addMergeFields(this.additionalMergeFields, this.useMergeService) || [];
        },
    },

    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
        },

        emitOpenMenu() {
            this.$emit('menuOpen');
        },

        closeMenu() {
            this.isOpen = false;
            this.$emit('menuClosed');
        },

        load() {
            const error = this.$t('mergeFieldDropdown.error.mergeFieldLoad');

            return this.$store.dispatch('mergeFields/LOAD_MERGE_FIELDS', { useMergeService: this.useMergeService })
                .catch(() => {
                    this.$error({ message: error });
                });
        },

        setSelected() {
            this.$nextTick(() => {
                this.$emit('select', this.selectedMergeField);
                this.closeMenu();
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .merge-field-dropdown {
        padding: 0;
        width: var(--merge-field-dropdown-width, auto);
    }
</style>

<i18n>
{
    "en-us": {
        "mergeFieldDropdown": {
            "categoryTitle": "Merge fields",
            "error": {
                "mergeFieldLoad": "There was a problem loading merge fields. Please try again."
            }
        }
    }
}
</i18n>
