import contactFieldTypes from '@/contacts/components/details/contactFieldTypes';

const customFieldHasValue = (field, isCustomFieldWholeNumberEnabled = false) => {
    return !customFieldIsEmpty(field, isCustomFieldWholeNumberEnabled);
};

const customFieldIsEmpty = (field, isCustomFieldWholeNumberEnabled = true) => {
    if (isCustomFieldWholeNumberEnabled) {
        const
            {
                YES_NO, WHOLE_NUMBER, DECIMAL_NUMBER, CURRENCY, PERCENT, CHECKBOXES,
            } = contactFieldTypes.CUSTOM_FIELD_NAMES;

        if ([YES_NO, WHOLE_NUMBER, DECIMAL_NUMBER, CURRENCY, PERCENT].includes(field.fieldType)) {
            return field.value === null || field.value === '';
        }

        if (CHECKBOXES === field.fieldType) {
            if (field.value === null || field.value === '') {
                return true;
            }

            return (Array.isArray(field.value) && field.value.length === 0);
        }
    } else if (field.fieldType === contactFieldTypes.CUSTOM_FIELD_NAMES.YES_NO) {
        return field.value === null || field.value === '';
    } else if (field.fieldType === contactFieldTypes.CUSTOM_FIELD_NAMES.CHECKBOXES) {
        if (field.value === null || field.value === '') {
            return true;
        }

        return (Array.isArray(field.value) && field.value.length === 0);
    }

    return !field.value;
};

const getFullName = (contact) => {
    if (!contact) {
        return '';
    }

    const {
        firstName,
        lastName,
        givenName,
        familyName,
    } = contact;

    return combineNames(firstName, lastName) || combineNames(givenName, familyName);
};

const displayFullName = (contact) => {
    if (!contact) {
        return '';
    }

    const { email, emailAddresses } = contact;

    const fullName = getFullName(contact);

    if (fullName) {
        return fullName;
    }

    if (emailAddresses && emailAddresses.length) {
        return emailAddresses[0].email;
    }

    if (email) {
        return email;
    }

    return '';
};

const combineNames = (...args) => args.filter((x) => x).join(' ');

export {
    customFieldHasValue,
    getFullName,
    displayFullName,
    customFieldIsEmpty,
};
