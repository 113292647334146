import { convert as slugify } from 'url-slug';
import { unlayerDesign } from '@/marketingSites/unlayer/unlayer-design-processor';
import { KEAP_TOOLS } from '@/marketingSites/unlayer/unlayer.constants';
import {
    getGlobalTemplate,
    getGlobalTemplatePage,
    getMarketingPage,
    getMarketingSite,
} from '@/marketingSites/api/sites-datasource-graphql';
import clonedeep from 'lodash.clonedeep';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';

/**
 * Maps a MarketingSite instance to a [CreateMarketingSiteInput] instance.
 *
 * This primary removes extra fields to not anger GraphQL
 *
 * @param {MarketingSite} siteToCopy
 * @param {string} newSiteName The name to use for the new site
 * @return {CreateMarketingSiteInput}
 */
export function mapMarketingSiteToCreateSiteInput(siteToCopy, newSiteName = siteToCopy.name, status) {
    const newSiteSlug = slugify(newSiteName);

    const pages = siteToCopy.pages.map(({ name, slug, content }) => ({
        name, slug, content,
    }));

    return {
        name: newSiteName,
        slug: newSiteSlug,
        pages,
        status: status || SITE_STATUS.DRAFT,
    };
}

/**
 * Fetches a complete site, including all pages
 * @param marketingSite
 * @return {Promise<*>}
 */
export async function loadFullSite(marketingSite) {
    if (!marketingSite.pages) {
        marketingSite = await getMarketingSite(marketingSite.id);
    }
    const siteToCopy = clonedeep(marketingSite);

    siteToCopy.pages = await Promise.all(siteToCopy.pages.map(async ({ id }) => getMarketingPage(id)));

    return siteToCopy;
}

export async function loadFullGlobalTemplate(globalTemplate) {
    if (!globalTemplate.pages) {
        globalTemplate = await getGlobalTemplate(globalTemplate.id);
    }
    const templateToCopy = clonedeep(globalTemplate);

    templateToCopy.pages = await Promise.all(templateToCopy.pages.map(async ({ id }) => getGlobalTemplatePage(id)));

    return templateToCopy;
}

/**
 * Prepares a set of marketing pages to be cloned, by resetting all keap forms within each page, so they will be re-created
 * when the cloned site is generated.
 *
 * @param {[MarketingPage]} pages
 * @return {[MarketingPage]}
 */
export function clearKeapFormsFromPages(pages) {
    const updatedPages = clonedeep(pages).map((page) => {
        const design = resetKeapForms(page.content.design);

        page.content.design = design;

        return page;
    });

    return updatedPages;
}

let formCount = 1;

/**
 * Within an unlayer document, finds all keap forms, and for each:
 * - removes the id (to trigger it to save again)
 * - removes the slug (to trigger generation of a new slug)
 *
 * @param design Unlayer design
 */
function resetKeapForms(design) {
    const designHelper = unlayerDesign(design);

    designHelper.findByType(KEAP_TOOLS.keapForm)
        .forEach(({ content = {} }) => {
            const { values: { keapForm = {} } = {} } = content;

            keapForm.slug = `${Date.now()}-${formCount++}`;
            delete keapForm.id;
        });

    return designHelper.design;
}
