<template>
    <ds-tooltip v-bind="$attrs" :is-open="isTooltipVisible" v-on="$listeners">
        <template #reference>
            <slot name="reference" />
        </template>

        <template #default>
            <slot />
        </template>
    </ds-tooltip>
</template>

<script>

const IDLE = 'IDLE';
const PENDING = 'PENDING';
const ACTIVE = 'ACTIVE';
const CLICKED = 'CLICKED';

export default {
    props: {
        delay: {
            type: Number,
            default: 300,
        },
        disabled: Boolean,
    },

    data() {
        return {
            status: IDLE,
            timeout: null,
        };
    },

    mounted() {
        this.$el.addEventListener('mouseenter', this.handleEnter);
        this.$el.addEventListener('mouseleave', this.handleLeave);
        this.$el.addEventListener('click', this.handleClick);
    },

    beforeDestroy() {
        this.$el.removeEventListener('mouseenter', this.handleEnter);
        this.$el.removeEventListener('mouseleave', this.handleLeave);
        this.$el.removeEventListener('click', this.handleClick);
    },

    computed: {
        isTooltipVisible() {
            return !this.disabled && this.status === ACTIVE;
        },
    },

    methods: {
        handleEnter() {
            this.status = PENDING;

            this.timeout = setTimeout(() => {
                this.status = ACTIVE;
            }, this.delay);
        },

        handleClick() {
            clearTimeout(this.timeout);
            this.timeout = null;
            this.status = CLICKED;
        },

        handleLeave() {
            clearTimeout(this.timeout);
            this.timeout = null;
            this.status = IDLE;
        },
    },
};
</script>
