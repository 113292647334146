<template lang="html">
    <section v-if="contactLoaded">
        <contact-general-edit
            v-if="editing"
            :contact="contact"
            :event-source="eventSource"
            :field-options="fieldOptions"
            :lock-company="lockCompany"
            @input="$emit('input')"
        />

        <contact-general-view v-else :contact="contact" />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';
import ContactGeneralView from '@/contacts/components/simple/ContactGeneralView';
import ContactGeneralEdit from '@/contacts/components/simple/ContactGeneralEdit';

export default {
    components: {
        ContactGeneralView,
        ContactGeneralEdit,
    },

    props: {
        contact: Object,
        editing: Boolean,
        eventSource: String,
        lockCompany: Boolean,
    },

    computed: {
        ...mapGetters({
            fieldOptions: 'contacts/fieldOptions',
        }),

        contactLoaded() {
            return Object.keys(this.contact).length > 0;
        },
    },
};
</script>
