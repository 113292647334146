<template>
    <div class="account-switcher" :class="{ active }">
        <header>
            <ds-icon-button name="x" @click="close" />

            <h4>{{ $t('switcher.switchAccounts') }}</h4>
        </header>

        <div v-show="!requestError && !showEmptyState" class="account-list">
            <div class="input-container">
                <ds-input-field
                    ref="input"
                    v-model.trim="searchText"
                    type="search"
                    class="search-input"
                    :placeholder="$t('switcher.search.placeholder')"
                    @input="debouncedSearch"
                />
            </div>

            <div class="account-list-header">
                <span>{{ $t('switcher.accounts') }} </span>
                <span v-if="!crmLoading || cursor">{{ crmTotalAccounts }}</span>
            </div>

            <div class="list">
                <a
                    v-for="account in crmAccountsList"
                    :key="`account-${account.id}`"
                    class="account-link"
                    :href="account.href"
                    :title="account.legacyId"
                >
                    <p class="account-name semibold">{{ account.name }}</p>
                    <p v-if="account.legacyId" class="legacy-id light">{{ account.legacyId }}</p>
                </a>

                <ds-infinite-scroll
                    ref="infiniteScroll"
                    :no-more-data="noMoreData"
                    :loading="crmLoading"
                    :limit="pageSize"
                    @load="fetchCrmAccounts"
                >
                    <div class="placeholder-container placeholder">
                        <div v-for="n in 3" :key="`account-placeholder-${n}`">
                            <div class="placeholder-top">
                                <ds-placeholder :rows="placeholderRows" class="placeholder" />
                            </div>

                            <div class="placeholder-bottom">
                                <ds-placeholder :rows="placeholderRows" class="placeholder" />
                            </div>
                        </div>
                    </div>
                </ds-infinite-scroll>
            </div>
        </div>

        <div v-show="requestError" class="error">
            <span class="error-message">{{ $t('switcher.requestError') }}</span>

            <ds-text-button class="reload-button" @click="reloadAccounts">
                {{ $t('switcher.reloadAccounts') }}
            </ds-text-button>
        </div>

        <div v-show="showEmptyState" class="empty-state-box">
            <h3 class="semibold">
                {{ $t('switcher.emptyState.title') }}
            </h3>

            <i18n tag="p" path="switcher.emptyState.body">
                <a
                    href="https://signin.infusionsoft.com/registration/createInfusionsoftId"
                    target="_blank"
                    rel="noopener"
                >
                    {{ $t('switcher.emptyState.link') }}
                </a>
            </i18n>

            <img src="../images/empty-state.png" />
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';
import { SEARCHING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

export default {
    name: 'AccountSwitcher',

    props: {
        /**
         * Debounce delay of search bar
         */
        debounceTime: {
            type: Number,
            default: SEARCHING_DEBOUNCE_DELAY,
        },

        /**
         * If running in dev environment use localhost redirects for production accounts
         */
        localPort: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            pageSize: 30,
            active: false,
            placeholderRows: [{ height: '0.5rem', boxes: [1] }],
            crmAccountsList: [],
            crmTotalAccounts: 0,
            partnerAccountsList: [],
            cursor: '',
            crmLoading: false,
            searchText: '',
            requestError: null,
            showEmptyState: false,
        };
    },

    mounted() {
        if (this.debounceTime > 0) {
            this.debouncedSearch = debounce(this.debouncedSearch, this.debounceTime);
        }
    },

    computed: {
        noMoreData() {
            return (this.crmAccountsList.length >= this.crmTotalAccounts);
        },
    },

    methods: {
        open() {
            this.reset();
            this.active = true;

            return this.fetchAllAccounts();
        },

        close() {
            this.active = false;
        },

        reset() {
            this.resetCrmAccounts();
            this.partnerAccountsList = [];
            this.requestError = null;
            this.searchText = '';
        },

        resetCrmAccounts() {
            this.cursor = '';
            this.crmAccountsList = [];
            this.showEmptyState = false;
        },

        debouncedSearch() {
            this.resetCrmAccounts();

            return this.fetchCrmAccounts();
        },

        reloadAccounts() {
            this.reset();

            return this.fetchAllAccounts();
        },

        formatAccount(account) {
            const {
                id,
                account: {
                    legacyId,
                    companyProfile: { name },
                    edition: { name: editionName },
                    accountType,
                },
            } = account;

            if (accountType === 'PARTNER_EDITION') {
                return {
                    id,
                    name: editionName,
                    legacyId: null,
                    href: `https://${process.env.VUE_APP_PARTNER_EDITION_HOST_NAME}`,
                };
            }

            const accountHref = this.localPort
                ? `http://localhost:${this.localPort}?app_id=${legacyId}`
                : `https://${legacyId}.${process.env.VUE_APP_INFUSIONSOFT_HOST_NAME}`;

            return {
                id,
                name,
                legacyId,
                href: accountHref,
            };
        },

        fetchAllAccounts() {
            return this.fetchCrmAccounts();
        },

        fetchCrmAccounts() {
            if (!this.active) {
                return Promise.resolve(null);
            }

            const executingSearch = this.crmAccountSearch;
            const newSearch = {};

            this.crmAccountSearch = newSearch;
            this.crmLoading = true;

            if (executingSearch) {
                executingSearch.cancelled = true;
            }

            return this.fetchAccountsByType(['CRM', 'KEAP'])
                .then(({ data }) => {
                    if (newSearch.cancelled) {
                        return;
                    }

                    const accounts = data.content;

                    if (!this.cursor) {
                        this.crmTotalAccounts = data.totalCount;
                    }

                    this.cursor = data.cursor;
                    this.crmAccountsList = this.crmAccountsList.concat(accounts.map(this.formatAccount));
                    this.showEmptyState = this.crmTotalAccounts === 0 && this.searchText === '';
                    this.crmLoading = false;
                })
                .catch((error) => {
                    if (newSearch.cancelled) {
                        return;
                    }

                    this.requestError = error;
                    this.crmLoading = false;
                });
        },

        fetchAccountsByType(accountTypes) {
            const params = new URLSearchParams({
                expand: 'account',
                statuses: 'Active',
                useFullText: 'true',
                limit: this.pageSize,
                userId: 'current',
                accountSwitcher: window.location.host, // so account-api can tell where the request comes from
            });

            if (this.cursor) {
                params.append('cursor', this.cursor);
            } else {
                params.append('includeTotalCount', 'true');
            }

            if (this.searchText) {
                params.append('search', this.searchText);
            }
            accountTypes.forEach((accountType) => {
                params.append('accountTypes', accountType);
            });
            params.append('sort', 'account.companyProfile.name:ASC');
            params.append('sort', 'account.legacyId:ASC');

            return axios.get(`${process.env.VUE_APP_ACCOUNT_API_URL}/v2/userAccount`, { params });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $header-height: px-to-rem(75px);

    .account-switcher {
        @include transition(transform);

        transform: translateX(100%);
        background-color: $color-paper;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: $zindex-animate-layover;
        width: px-to-rem(400px);
        display: flex;
        flex-direction: column;
        overflow: auto;

        @media($small) {
            width: 100%;
        }

        &.active {
            transform: translateX(0);
            box-shadow: $drop-shadow-z2;
        }
    }

    .close-container {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    header {
        background-color: $color-paper;
        display: flex;
        align-items: center;
        height: $nav-height;
        padding: 0 $gp;

        h4 {
            @include margin-start($gp / 2);
        }
    }

    .account-list {
        width: 100%;
        overflow: auto;
        flex-grow: 1;
    }

    .account-list-header {
        display: flex;
        justify-content: space-between;
        background-color: $color-gray-200;
        padding: $gp / 2 $gp * 1.5;
    }

    .empty-state-box {
        padding: $gp * 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        color: $color-gray-800;

        @media ($small) {
            width: 100%;
            display: flex;
            flex-direction: column;
        }

        img {
            width: px-to-rem(200px);
        }

        h3 {
            margin-bottom: $gp / 4;
        }

        p {
            padding-bottom: $gp * 2;
            font-size: px-to-rem(16px);
            text-align: center;
        }
    }

    .list {
        padding: 0 $gp * 1.5;
    }

    .placeholder-top {
        width: 100%;
        margin: $gp * 1.5 0 $gp;
    }

    .placeholder-bottom {
        width: 25%;
    }

    .account-link {
        text-decoration-color: $color-gray-800;
    }

    .account-name {
        color: $color-gray-800;
        margin: $gp 0 0;
    }

    .legacy-id {
        color: $color-gray-800;
        margin: 0;
    }

    .input-container {
        padding: 0 $gp $gp;
    }

    .search-input {
        --input-margin-bottom: 0;
    }

    .error {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $gp * 1.5;
    }

    .error-message {
        background-color: $color-red-050;
        border-radius: $border-radius;
        color: $color-red-900;
        padding: $gp $gp * 1.5;
    }

    .reload-button {
        margin: $gp * 2;
    }

    [dir=rtl] {
        .account-switcher {
            transform: translateX(-100%);
            right: auto;
            left: 0;

            &.active {
                transform: translateX(0);
            }
        }
    }
</style>

<i18n>
{
    "en-us": {
        "switcher": {
            "header": "Account Switcher",
            "switchAccounts": "Switch Accounts",
            "accounts": "Accounts",
            "partnerTools": "Partner tools",
            "search": {
                "placeholder": "Search accounts"
            },
            "reloadAccounts": "Reload accounts",
            "requestError": "There was a technical error while getting your accounts list. Please try again.",
            "emptyState": {
                "title": "Well, this is awkward",
                "body": "Looks like you have not been added to any accounts... yet. Sign up for a {0} or contact your client to be added to their Keap account.",
                "link": "Keap account"
            }
        }
    }
}
</i18n>
