import axios from 'axios';

export const fetchPublicRegionOptions = (countryCode) => {
    return axios.get(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/countryAndProvince/${countryCode}`);
};

export const fetchRegionOptions = (countryCode) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/localization/${countryCode}/states`);
};

export const fetchCountryOptions = () => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/localization/countryOptions`);
};

export const fetchPublicCountryOptions = () => {
    return axios.get(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/countryAndProvince`);
};

export const updatePlaceId = (key, value) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/settings/appSetting/${key}`, { value });
};
