<template lang="html">
    <div class="nav-account-links">
        <nav-item
            data-qa="nav-link-profile"
            class="profile-link"
            :to="{ name: 'settings.profile' }"
            :label="$t('nav.profile')"
        >
            <nav-profile />
        </nav-item>

        <nav-item
            v-if="showHelp"
            data-qa="nav-link-support"
            icon="help-circle"
            :label="$t('links.help')"
            expanded
            @click.native="openSupportPanel"
        />

        <nav-item
            v-if="showNotificationsLink"
            data-qa="nav-link-notifications"
            icon="notification"
            :label="$t('links.notifications')"
            expanded
            :show-icon-badge="hasNotifications"
            @click.native="openNotificationsPanel"
        />

        <nav-item
            :to="{ name: 'settings' }"
            :label="$t('links.settings')"
            expanded
            exact
            data-qa="nav-link-settings"
            icon="settings"
        />

        <nav-item
            v-if="showAccountSwitcher"
            data-qa="nav-link-account-switcher"
            icon="shuffle"
            :label="$t('links.switch')"
            expanded
            @click.native="openAccountSwitcher"
        />

        <nav-item
            data-qa="nav-link-logout"
            :to="{ name: 'logout' }"
            :label="$t('links.logout')"
            expanded
            icon="log-out"
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import NavItem from '@/nav/components/NavItem';
import NavProfile from '@/nav/components/NavProfile';

export default {
    components: {
        NavItem,
        NavProfile,
    },

    props: {
        hideNotifications: Boolean,
        hideHelp: Boolean,
    },

    computed: {
        ...mapState({
            accounts: ({ auth }) => auth.accounts,
            isLocal: ({ global }) => global.isLocal,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            hasNotifications: 'notifications/hasNotifications',
            showFreeTrialChat: 'auth/showFreeTrialChat',
        }),

        showHelp() {
            return !this.hideHelp && !this.showFreeTrialChat;
        },

        showAccountSwitcher() {
            return !this.isLocal && this.accounts.length > 1;
        },

        showNotificationsLink() {
            return !this.hideNotifications;
        },
    },

    methods: {
        openAccountSwitcher() {
            this.$bus.$emit('OPEN_ACCOUNT_SWITCHER');
        },

        openSupportPanel() {
            this.$store.commit('SET_SUPPORT_PANEL_OPEN', true);
        },

        openNotificationsPanel() {
            this.$store.commit('notifications/SET_NOTIFICATIONS_PANEL_OPEN', true);
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "links": {
            "help": "Help",
            "notifications": "Notifications",
            "profile": "Profile",
            "logout": "Sign out",
            "settings": "Settings",
            "switch": "Switch account"
        }
    }
}
</i18n>
