const OnboardingPage = () => import(/* webpackChunkName: "onboarding" */ '@/onboarding/pages/OnboardingPage');
const GettingStartedPage = () => import(/* webpackChunkName: "onboarding" */ '@/onboarding/pages/GettingStartedPage');

import { ONBOARDING_AND_GETTING_STARTED_FEATURE } from '@/shared/constants/features.constants';

const routes = [
    {
        path: '/onboarding',
        name: 'onboarding',
        component: OnboardingPage,
        meta: {
            title: 'nav.onboarding',
            hideNav: true,
        },
    },
    {
        path: '/getting-started',
        name: 'getting-started',
        component: GettingStartedPage,
        meta: {
            title: 'nav.gettingStarted',
            resetIntercom: true,
            feature: ONBOARDING_AND_GETTING_STARTED_FEATURE,
        },
    },
];

export default routes;
