<template>
    <empty-state
        :title="$t('title')"
        :description="$t('description')"
        image="form-internal-color"
    >
        <ds-text-button data-qa="no-results-create" @click="$sites.showCreateSite()">
            {{ $t('action') }}
        </ds-text-button>
    </empty-state>
</template>

<script>
import EmptyState from '@/shared/components/EmptyState';

export default {
    components: {
        EmptyState,
    },
    inject: ['$sites'],
};
</script>

<i18n>
{
    "en-us": {
        "title": "Promote your business with Landing pages.",
        "description": "Quickly build a landing pages with a template, and start collecting leads.",
        "action": "Create a landing page"
    }
}
</i18n>
