<template lang="html">
    <div ref="modal" class="connect-to-google">
        <transition name="fadeInOut" appear>
            <stage-indicators
                v-if="!shareStageActive"
                :active="activeStage"
                :stages="stages"
                @changeStage="changeStage"
            />
        </transition>

        <transition name="fadeInOut" mode="out-in" appear>
            <div v-if="loading" class="loading-spinner">
                <ds-spinner />
            </div>

            <connect-stage
                v-else-if="connectStageActive"
                :ready.sync="stages.connect.ready"
                @next="nextStage"
            />

            <set-availability-info-stage
                v-else-if="availabilityInfoStageActive"
                ref="setAvailabilityInfoStage"
                :calendar-provider-id="calendarProviderId"
                :title.sync="apptType.title"
                :name.sync="apptType.name"
                :title-link-part.sync="apptType.titleLinkPart"
                :host-link-part.sync="apptType.hostLinkPart"
                :location.sync="apptType.location"
                :location-type.sync="apptType.locationType"
                :ready.sync="stages.setAvailabilityInfo.ready"
            />

            <set-availability-hours-stage
                v-else-if="availabilityHoursStageActive"
                ref="setAvailabilityHoursStage"
                :calendar-provider-id="calendarProviderId"
                :availability-times.sync="apptType.availabilityTimes"
                :buffer-times.sync="apptType.buffer"
                :duration.sync="apptType.durationMinutes"
                :ready.sync="stages.setAvailabilityHours.ready"
            />

            <options-stage
                v-else-if="optionsStageActive"
                :calendar-provider-id="calendarProviderId"
                :availability-calendars.sync="apptType.availabilityCalendars"
                :appointment-description.sync="apptType.description"
                :calendar-id.sync="apptType.calendarId"
                :ready.sync="stages.options.ready"
            />

            <automate-appointment-stage
                v-else-if="automateStageActive"
                :ready.sync="stages.automate.ready"
            />

            <share-stage
                v-else-if="shareStageActive"
                :ready.sync="stages.share.ready"
            />
        </transition>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ConnectStage from '@/appointments/components/ConnectStage';
import SetAvailabilityInfoStage from '@/appointments/components/SetAvailabilityInfoStage';
import SetAvailabilityHoursStage from '@/appointments/components/SetAvailabilityHoursStage';
import OptionsStage from '@/appointments/components/OptionsStage';
import AutomateAppointmentStage from '@/appointments/components/AutomateAppointmentStage';
import StageIndicators from '@/shared/components/StageIndicators';
import ShareStage from '@/appointments/components/ShareStage';
import intercom from '@/analytics/intercom';
import { FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS_FLOW } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        ConnectStage,
        StageIndicators,
        SetAvailabilityInfoStage,
        SetAvailabilityHoursStage,
        OptionsStage,
        ShareStage,
        AutomateAppointmentStage,
    },

    props: {
        calendarProviderId: {
            type: String,
            required: false,
            default: 'GOOGLE',
        },
    },

    data() {
        return {
            activeStage: {
                ready: false,
            },
            activeData: null,
            nestedModal_stickyDesktopHeader: true,
            savingApptType: false,
            apptType: {
                name: '',
                title: '',
            },
        };
    },

    created() {
        this.activeStage = this.isConnected ? this.stages.setAvailabilityInfo : this.stages.connect;
        this.apptType = { ...this.tempApptType };
    },

    mounted() {
        this.$bus.$on('SHORTKEY_ESC', this.close);
        this.$bus.$on('CONNECT_GOOGLE_MODAL_BACK', this.previousStage);
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_ESC', this.close);
        this.$bus.$off('CONNECT_GOOGLE_MODAL_BACK', this.previousStage);

        if (this.shareStageActive) {
            this.$bus.$emit('APPOINTMENT_SETUP_SUCCESS');
            this.$store.dispatch('calendar/REDIRECT_AFTER_CONNECT', this.$router);
        }

        this.resetWizard();
    },

    destroyed() {
        this.$track(`Appointments - ${this.activeStage.name} modal - closed`);
    },

    watch: {
        apptType: {
            handler() {
                this.$store.dispatch('calendar/UPDATE_TEMP_APPT_TYPE', {
                    apptType: this.apptType,
                    calendarProviderId: this.calendarProviderId,
                });
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            activeApptType: ({ calendar }) => calendar.activeApptType,
            tempApptType: ({ calendar }) => calendar.tempApptType,
            calendarStateInitialized: ({ calendar }) => calendar.stateInitialized,
            user: ({ auth }) => auth.user,
            automateEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS_FLOW],
        }),

        ...mapGetters({
            isProviderConnected: 'calendar/isProviderConnected',
            connectedCalendarProvider: 'calendar/connectedCalendarProvider',
        }),

        stages() {
            const stages = {};
            let index = 0;

            if (!this.isConnected) {
                stages.connect = {
                    index,
                    id: 'connect',
                    ready: false,
                    hidden: false,
                    label: this.$t('connectToGoogle.stages.connect'),
                    next: 'setAvailabilityInfo',
                    prev: null,
                    showBack: false,
                    showClose: true,
                };
                index++;
            }

            stages.setAvailabilityInfo = {
                index,
                id: 'setAvailabilityInfo',
                ready: false,
                hidden: false,
                label: this.$t('connectToGoogle.stages.setAvailabilityInfo'),
                next: 'setAvailabilityHours',
                prev: this.isConnected ? null : 'connect',
                showBack: false,
                showClose: this.isConnected,
            };
            index++;

            stages.setAvailabilityHours = {
                index,
                id: 'setAvailabilityHours',
                ready: false,
                hidden: false,
                label: this.$t('connectToGoogle.stages.setAvailabilityHours'),
                next: 'options',
                prev: 'setAvailabilityInfo',
                showBack: true,
                showClose: false,
            };
            index++;

            stages.options = {
                index,
                id: 'options',
                ready: false,
                hidden: false,
                label: this.$t('connectToGoogle.stages.options'),
                next: this.automateEnabled ? 'automate' : 'share',
                prev: 'setAvailabilityHours',
                showBack: true,
                showClose: false,
            };
            index++;

            if (this.automateEnabled) {
                stages.automate = {
                    index,
                    id: 'automate',
                    ready: false,
                    hidden: false,
                    label: this.$t('connectToGoogle.stages.automate'),
                    next: 'share',
                    prev: null,
                    showBack: false,
                    showClose: false,
                };
                index++;
            }

            stages.share = {
                index,
                id: 'share',
                ready: false,
                hidden: true,
                label: null,
                next: null,
                prev: null,
                showBack: false,
                showClose: true,
            };

            return stages;
        },

        isConnected() {
            return this.isProviderConnected(this.calendarProviderId);
        },

        connectStageActive() {
            return this.activeStage.id === this.stages.connect?.id;
        },

        availabilityInfoStageActive() {
            return this.activeStage.id === this.stages.setAvailabilityInfo?.id;
        },

        availabilityHoursStageActive() {
            return this.activeStage.id === this.stages.setAvailabilityHours?.id;
        },

        optionsStageActive() {
            return this.activeStage.id === this.stages.options?.id;
        },

        automateStageActive() {
            return this.activeStage.id === this.stages.automate?.id;
        },

        shareStageActive() {
            return this.activeStage.id === this.stages.share?.id;
        },

        loading() {
            return Boolean(!this.calendarStateInitialized || this.savingApptType);
        },

        nestedModal_actionDisabled() {
            if (this.shareStageActive) {
                return false;
            }

            return !this.activeStage.ready;
        },

        nestedModal_title() {
            return this.$t('modalTitle');
        },

        nestedModal_actionLoading() {
            return this.savingApptType;
        },

        nestedModal_actionClasses() {
            return this.shareStageActive ? 'outline bold' : '';
        },

        nestedModal_actionText() {
            if (this.activeStage.index === Object.keys(this.stages).length - 2) {
                return this.$t('global.final');
            }

            if (this.shareStageActive) {
                return this.$t('global.done');
            }

            return this.$t('global.next');
        },

        nestedModal_actionMethod() {
            return this.connectStageActive ? '' : 'onAction';
        },

        nestedModal_showBack() {
            return this.activeStage.showBack;
        },

        nestedModal_showClose() {
            return this.activeStage.showClose;
        },
    },

    methods: {
        close(clearState) {
            this.$track('Appointments - Appointment creation success modal - clicked : close modal');

            if (clearState) {
                this.resetWizard();
            }

            this.$bus.$emit('POP_NESTED_MODAL');
        },

        nextStage() {
            if (!this.activeStage.next) {
                this.close(true);
            } else {
                this.activeStage = this.stages[this.activeStage.next];
                this.$store.commit('calendar/SET_WIZARD_ACTIVE_STAGE', this.activeStage);
            }
        },

        previousStage() {
            if (this.activeStage.prev != null) {
                this.activeStage = this.stages[this.activeStage.prev];
                this.$store.commit('calendar/SET_WIZARD_ACTIVE_STAGE', this.activeStage);
            } else {
                this.close(false);
            }
        },

        changeStage(stage) {
            if (this.activeStage.ready && this.activeStage.next === stage.id) {
                this.onAction();

                return;
            }

            // prevent user from changing stage
            if (stage.index > this.activeStage.index
                || this.activeStage.id === stage.id
                || stage.id === 'connect'
                || stage.id === 'automate'
                || this.activeStage.id === 'automate') {
                return;
            }

            this.activeStage = stage;
            this.$store.commit('calendar/SET_WIZARD_ACTIVE_STAGE', this.activeStage);
        },

        onAction() {
            if (this.optionsStageActive) {
                this.addApptType()
                    .then(() => {
                        this.nextStage();
                    })
                    .catch(() => {
                        this.$error({
                            message: this.$t('errors.saving'),
                            bottom: true,
                        });
                    });
            } else {
                this.nextStage();
            }
        },

        addApptType() {
            const { calendarId, availabilityCalendars, description } = this.tempApptType;

            const payloadData = {
                ...this.apptType,
                calendarId,
                availabilityCalendars,
                description,
            };

            if (payloadData.buffer) {
                payloadData.buffer.bufferType = 'SIMPLE';
            }

            this.savingApptType = true;

            return this.$store.dispatch('calendar/ADD_APPT_TYPE', {
                data: payloadData,
            })
                .then((data) => {
                    this.savingApptType = false;

                    const { buffer, locationType } = data;

                    this.$track('Appointments - Appointments page - success : appointment type created', { locationType });

                    intercom.logEvent(intercom.events.APPOINTMENT_TYPE_CREATED);

                    if (data.buffer.after > 0 || data.buffer.before > 0) {
                        this.$track('Appointments - Appointments page - success : appointment buffer created', { buffer });
                    }

                    this.$store.commit('calendar/SET_ACTIVE_APPT_TYPE', data);
                })
                .catch(() => {
                    this.savingApptType = false;

                    return Promise.reject();
                });
        },

        resetWizard() {
            this.$store.commit('calendar/SET_ACTIVE_APPT_TYPE', null);
            this.$store.commit('calendar/SET_TEMP_APPT_TYPE', null);
            this.$store.commit('calendar/SET_WIZARD_ACTIVE_STAGE', null);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .loading-spinner {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
    }

    // transition: fadeInOut
    .fadeInOut-enter-active, .fadeInOut-leave-active {
        opacity: 1.0;
        transition: opacity .5s ease-out;
        transition-delay: .5s;
    }

    .fadeInOut-enter, .fadeInOut-leave-to {
        opacity: 0;
        transition: opacity .25s ease-out;
        transition-delay: .15s;
    }
</style>

<i18n>
{
    "en-us": {
        "connectToGoogle": {
            "stages": {
                "connect": "Connect",
                "setAvailabilityInfo": "Info",
                "setAvailabilityHours": "Availability",
                "options": "Options",
                "automate": "Automate"
            }
        },
        "modalTitle": "Create a booking link"
    }
}
</i18n>
