import { SAVE_STATUS } from '@/automations/constants/automations.constants';

export default {
    automation: {},
    saveStatus: SAVE_STATUS.NONE,
    validatedAutomation: {},
    automationFilters: [],
    context: {
        type: '',
        triggerSourceId: '',
    },
    saveAutomationCallback: null,
};
