<template>
    <section>
        <h4 class="section-title">
            {{ $t('title') }}
        </h4>

        <div class="field-grid">
            <div
                v-if="contact.firstName"
                class="view-field"
                data-qa="first-name"
            >
                <small class="label">{{ $t('forms.firstName') }}</small>
                <span>{{ contact.firstName }}</span>
            </div>

            <div
                v-if="contact.middleName"
                class="view-field"
                data-qa="middle-name"
            >
                <small class="label">{{ $t('forms.middleName') }}</small>
                <span>{{ contact.middleName }}</span>
            </div>

            <div
                v-if="contact.lastName"
                class="view-field"
                data-qa="last-name"
            >
                <small class="label">{{ $t('forms.lastName') }}</small>
                <span>{{ contact.lastName }}</span>
            </div>

            <div
                v-if="contact.title"
                class="view-field"
                data-qa="title"
            >
                <small class="label">{{ $t('forms.title') }}</small>
                <span>{{ contact.title }}</span>
            </div>

            <div
                v-if="contact.suffix"
                class="view-field"
                data-qa="suffix"
            >
                <small class="label">{{ $t('forms.suffix') }}</small>
                <span>{{ contact.suffix }}</span>
            </div>

            <div
                v-if="companyName"
                class="view-field"
                data-qa="company"
            >
                <small class="label">{{ $t('forms.company') }}</small>
                <router-link
                    v-if="hasCompaniesFeature"
                    :to="toCompanyRecord"
                    data-qa="company-name-link"
                >
                    <span>{{ companyName }}</span>
                </router-link>

                <span v-else data-qa="company-name-no-link">{{ companyName }}</span>
            </div>

            <div
                v-if="contact.jobTitle"
                class="view-field"
                data-qa="job-title"
            >
                <small class="label">{{ $t('forms.jobTitle') }}</small>
                <span>{{ contact.jobTitle }}</span>
            </div>

            <div
                v-if="contact.birthday"
                class="view-field"
                data-qa="birthday"
            >
                <small class="label">{{ $t('forms.birthday') }}</small>
                <span>{{ formatBirthday }}</span>
            </div>

            <div
                v-if="contact.anniversary"
                class="view-field"
                data-qa="anniversary"
            >
                <small class="label">{{ $t('forms.anniversary') }}</small>
                <span>{{ formatAnniversary }}</span>
            </div>

            <div
                v-if="ownerName"
                class="view-field"
                data-qa="owner"
            >
                <small class="label">{{ $t('forms.owner') }}</small>
                <span>{{ ownerName }}</span>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatAnniversaryDate, formatBirthdayDate } from '@/appointments/mixins/date.mixin';
import { getCompanyInfo } from '@/contacts/api/companies';
import {
    SUCCESS, ERROR, LOADING, IDLE,
} from '@/contacts/loadingStatuses';

export default {
    props: {
        contact: Object,
    },

    data() {
        return {
            companyDetailStatus: IDLE,
            companyDetails: false,
        };
    },

    mounted() {
        this.loadCompanyDetails();
    },

    watch: {
        contact() {
            this.loadCompanyDetails();
        },
    },

    computed: {
        ...mapGetters({
            getUserNameFromId: 'auth/getUserNameFromId',
            hasCompaniesFeature: 'auth/hasCompaniesFeature',
        }),

        ownerName() {
            return this.getUserNameFromId(this.contact.owner);
        },

        formatBirthday() {
            return formatBirthdayDate(this.contact.birthday);
        },

        formatAnniversary() {
            return formatAnniversaryDate(this.contact.anniversary);
        },

        companyName() {
            return this.companyDetails?.companyName;
        },

        companyId() {
            return this.contact.company;
        },

        toCompanyRecord() {
            return {
                name: 'contacts.company.record',
                params: {
                    companyId: this.companyId,
                },
            };
        },
    },

    methods: {
        loadCompanyDetails() {
            if (this.companyId) {
                this.companyDetailStatus = LOADING;

                return getCompanyInfo(this.companyId)
                    .then(async ({ data }) => {
                        this.companyDetails = data.company;
                        this.companyDetailStatus = SUCCESS;
                    })
                    .catch(() => {
                        this.companyDetailStatus = ERROR;
                    });
            }

            return Promise.resolve();
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .add-field {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title" : "General"
    }
}
</i18n>
