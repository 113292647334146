import sortBy from 'lodash.sortby';

export default {
    getUserSignature: ({ signatures }) => (userId) => {
        return signatures[userId];
    },

    hasActiveSync: ({ providers }) => {
        return Array.isArray(providers) && providers.some((provider) => provider.enabled);
    },

    lastSyncedProvider: ({ providers }, { hasActiveSync }) => {
        return hasActiveSync
            ? sortBy(providers, ({ lastSyncCompleteDate }) => -new Date(lastSyncCompleteDate).getTime())[0]
            : null;
    },

    enabledProviders: ({ providers }) => providers.filter(({ enabled }) => enabled),

    disabledProviders: ({ providers }) => providers.filter(({ enabled }) => !enabled),
};
