<template lang="html">
    <publish-page-section
        :title="$t('sectionTitle')"
        :description="$t('sectionDescription')"
    >
        <code-embedder
            class="embed-code"
            :embed-content="embedCode"
            copy-button-component="ds-outline-button"
        />
    </publish-page-section>
</template>

<script>

import PublishPageSection from './PublishPageSection';
import CodeEmbedder from '@/shared/components/Embed/CodeEmbedder.vue';
import { buildMarketingSiteEmbedCode } from '@/marketingSites/marketingSites.constants';

export default {
    components: {
        PublishPageSection,
        CodeEmbedder,
    },

    props: {
        embedUrl: String,
    },

    computed: {
        embedCode() {
            return buildMarketingSiteEmbedCode(this.embedUrl);
        },
    },

};
</script>
<style lang="scss" scoped>
    .embed-code {
        width: px-to-rem(420);
    }
</style>
<i18n>
{
    "en-us": {
        "sectionTitle": "Add to your website",
        "sectionDescription": "Use a javascript snippet to place this landing page on your website.",
        "copyCodeButtonLabel": "Copy code"
    }
}
</i18n>
