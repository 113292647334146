<template>
    <div class="merge-field-suggestion-text-box">
        <rich-text-box
            v-model="localValue"
            :has-error="hasError"
            :placeholder="placeholder"
            :merge-fields="mergeFields"
            disable-chip-tooltip
        >
            <template #footer>
                <merge-field-suggestion-footer
                    :use-merge-service="useMergeService"
                    :additional-merge-fields="additionalMergeFields"
                />
            </template>
        </rich-text-box>
    </div>
</template>

<script>
import MergeFieldSuggestionFooter from '@/shared/components/Text/MergeFieldSuggestionFooter';

export default {
    components: {
        RichTextBox: () => import(/* webpackChunkName: "richTextBox" */ '@/shared/components/Text/RichTextBox'),
        MergeFieldSuggestionFooter,
    },

    props: {
        additionalMergeFields: Array,
        hasError: Boolean,
        mergeFields: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: String,
            default: '',
        },
        useMergeService: Boolean,
        placeholder: String,
    },

    data() {
        return {
            localValue: this.value,
        };
    },

    watch: {
        value(val) {
            if (this.localValue !== val) {
                this.localValue = val;
            }
        },

        localValue(val) {
            if (val !== this.value) {
                this.$emit('input', val);
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
.merge-field-suggestion-text-box {
    // class defined in parent
    &.input-field {
        --text-box-min-height: #{px-to-rem(47)};
    }

    // class defined in parent
    &.text-area {
        --text-box-min-height: #{px-to-rem(91)};
    }
}
</style>
