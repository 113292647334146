<template lang="html">
    <div class="integration-import">
        <template v-if="!isLoading">
            <svg class="import-contacts-svg">
                <use xlink:href="#import-contacts" />
            </svg>

            <div class="integration-group-connected">
                <div class="mailchimp-setup">
                    <h4 class="secondary-title">
                        {{ $t('title') }}
                    </h4>

                    <div class="mailchimp-import-description">
                        {{ $t('description') }}
                    </div>

                    <ds-filled-button
                        data-qa="connect"
                        @click="connectIntegration"
                    >
                        {{ $t('action') }}
                    </ds-filled-button>
                </div>
            </div>

            <blendr-modal
                data-qa="blendr-modal"
                :title="$t('mailchimpModalTitle')"
                :url="integrationUrl"
                :is-open="openModal"
                @setupComplete="$emit('setup-complete')"
                @setupCanceled="handleBlendrModalClosed"
                @setupFailed="handleBlendrModalClosed"
                @settingsComplete="handleBlendrModalClosed"
                @settingsCanceled="handleBlendrModalClosed"
                @settingsFailed="handleSettingsFailed"
                @close="handleBlendrModalClosed"
            />
        </template>

        <div v-else>
            <div class="integration-group">
                <svg class="mailchimp-svg">
                    <use xlink:href="#mailchimp" />
                </svg>

                <strong>{{ $t('sync.title') }}</strong>
                <div class="mailchimp-status">
                    <ds-placeholder class="integration-connect-placeholder" :rows="placeholder" />
                </div>
            </div>
            <div class="mailchimp-button-group">
                <div class="mailchimp-disconnected-spinner">
                    <ds-spinner />
                </div>
                <div class="mailchimp-sync-description">
                    <small>{{ $t('sync.description') }}</small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import '@/import/images/providers/import-contacts.svg';
import BlendrModal from '@/settings/components/integrations/BlendrModal';
import amplitude from '@/analytics/amplitude';

export default {
    components: {
        BlendrModal,
    },

    props: {
        integrationBlend: Object,
        isLoading: Boolean,
    },

    data() {
        return {
            openModal: false,
            integrationUrl: '',
            loadingBlend: false,
            avatarPlaceholder: [{ boxes: [1] }],
            placeholder: [
                { height: '1rem', boxes: [1] },
            ],
        };
    },

    methods: {
        connectIntegration() {
            amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTION_STARTED, {
                'Event Source': 'Contact Import',
                Integration: this.integrationBlend.name,
            });

            this.integrationUrl = this.integrationBlend.activationUrl || this.integrationBlend.settingsUrl;
            this.openModal = true;
        },

        handleBlendrModalClosed() {
            this.openModal = false;
        },

        handleSettingsFailed() {
            this.openModal = false;
            this.$store.dispatch('settings/LOAD_BLENDR_DATASOURCES');
        },
    },
};
</script>

<style lang="scss" scoped>
    .integration-import {
        @include prevent-select;
        background: $color-paper;
        position: relative;
        border: 1px solid $color-gray-200;
        border-radius: $border-radius;
        padding: $gp;
        overflow-x: hidden;
        box-shadow: $elevation-z1;
        margin: $gp * 2 0;
        width: 100%;
    }

    .integration-connect-placeholder {
        margin-top: $gp;
    }

    .import-contacts-svg {
        width: px-to-rem(40);
        height: px-to-rem(45);
    }

    .mailchimp-svg {
        width: px-to-rem(40);
        height: px-to-rem(40);
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }

    .integration-group {
        display: grid;
        grid-template-columns: repeat(30, auto);
        text-align: left;
        align-items: center;
        margin-bottom: $gp;
    }

    .mailchimp-import-description {
        padding-bottom: $gp;
        font-size: $font-size-xs;
        font-weight: $font-weight-light;
    }

    .mailchimp-sync-description {
        grid-column: 1 / 4;
    }

    .mailchimp-status {
        grid-column: 2 / 3;
        margin-top: -1 * $gp;
    }

    .avatar-placeholder {
        height: px-to-rem(50px);
        --placeholder-width: #{px-to-rem(50px)};
        --placeholder-border-radius: #{px-to-rem(50px)};
        margin: 0 auto 2 * $gp auto;
    }

    .mailchimp-button-group {
        display: grid;
        grid-template-columns: repeat(10, auto);
        text-align: left;
        align-items: center;
    }

    .mailchimp-disconnected-spinner {
        margin-bottom: 0.5 * $gp;
    }
</style>
<i18n>
{
    "en-us": {
        "title": "Import from Mailchimp",
        "description": "Import and sync contacts directly from Mailchimp into Keap",
        "action": "Connect to Mailchimp",
        "runningBlendToastMessage": "Running the {blendName} integration in the background",
        "mailchimpModalTitle": "Connect to Mailchimp",
        "sync": {
            "title": "Mailchimp Import",
            "description": "Any contacts already in Keap will be updated with Mailchimp data to avoid duplicates. We merge contacts with the same email addresses."
        }
    }
}
</i18n>
