<template>
    <div class="configure">
        <configure-details
            :title="title"
            :has-error="hasError"
            :error-message="errorMessage || $t('error')"
            :hide-edit="Boolean(errorMessage)"
            :trigger-action="triggerAction"
            @edit="$emit('edit', { trigger: value })"
            @remove="$emit('remove')"
        />

        <div class="button-row multiple-buttons">
            <manage-configuration
                class="manage-forms"
                :title="$t('manage')"
                :manage-props="manageProps"
            />

            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';
import ManageConfiguration from '@/automations/components/configure/ManageConfiguration';
import { HELLOSIGN_DOCUMENT_SIGNED } from '@/automations/constants/automations.constants';
import { BLENDR_TYPES } from '@/shared/constants/integrations.constants';

export default {
    components: {
        ConfigureDetails,
        ManageConfiguration,
    },

    props: {
        value: Object,
        hasError: Boolean,
        errorMessage: String,
        triggerAction: String,
    },

    data() {
        return {
            manageProps: {
                confirmTitle: this.$t('manageForms.title'),
                confirmMessage: this.$t('manageForms.message'),
                confirmButtonLabel: this.$t('manageForms.confirm'),
                route: { name: 'settings.integrations', params: { integration: BLENDR_TYPES.HELLO_SIGN } },
            },
        };
    },

    computed: {
        ...mapGetters({
            documentById: 'settings/hellosignDocumentById',
        }),

        document() {
            return this.documentById(this.value?.sourceId);
        },

        isSingleDocumentSigned() {
            return this.value.type === HELLOSIGN_DOCUMENT_SIGNED;
        },

        title() {
            if (this.isSingleDocumentSigned) {
                return this.document?.title ?? this.$t('emptyDocumentLabel');
            }

            return this.$t('anyDocumentSigned');
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>

<i18n>
{
    "en-us": {
        "anyDocumentSigned": "Any HelloSign document",
        "manage": "Manage integrations",
        "emptyDocumentLabel": "HelloSign document is signed",
        "manageForms": {
            "title": "Navigate to your integrations list?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Manage integrations"
        },
        "error": "Please select a document"
    }
}
</i18n>
