<template>
    <controlled-modal
        :is-open="isOpen"
        size="med"
        show-close
        @action="saveDetails"
        @close="close"
    >
        <template #header>
            <ds-filled-button
                class="save-button"
                :loading="saving"
                :disabled="isDisabled"
                @click="saveDetails"
            >
                {{ $t('global.done') }}
            </ds-filled-button>
        </template>

        <template #title>
            <h4>
                {{ $t('header') }}
            </h4>
        </template>

        <template #default>
            <ds-placeholder v-if="loading" :rows="$options.placeholder" />

            <div v-else class="share-template-modal">
                <section class="edit-section">
                    <form ref="form" class="form" @submit.prevent="saveDetails">
                        <ds-input-field
                            v-model="localTemplate.name"
                            data-qa="template-name"
                            type="text"
                            name="name"
                            :label="$t('template.name')"
                            required
                            autofocus
                            :maxlength="$options.maxlength.name"
                            :submitted="submitted"
                        />
                        <ds-text-area-field
                            v-model="localTemplate.description"
                            data-qa="template-description"
                            type="text"
                            name="description"
                            :maxlength="$options.maxlength.description"
                            :label="$t('template.description')"
                            :submitted="submitted"
                        />
                    </form>
                </section>
                <hr />
                <section class="share-details">
                    <p class="how-to-share">
                        {{ $t(displayEmailSharing ? 'howToShare' : 'copyBelow') }}
                    </p>
                    <div class="template-link">
                        <ds-input-field
                            v-model="templateLink"
                            readonly
                        />
                        <ds-outline-button
                            class="copy-button"
                            @click="copyTemplateLink"
                        >
                            <span>{{ $t('copyLink') }}</span>
                        </ds-outline-button>
                    </div>
                    <div v-if="displayEmailSharing" class="email-sharing">
                        <ds-input-field
                            v-model="emails"
                            type="text"
                            name="title"
                            :label="$t('email.placeholder')"
                        />
                        <ds-button-loading
                            class="share-button"
                            :disabled="!emails"
                            :loading="sending"
                            @click="shareEmail"
                        >
                            <span>{{ $t('share') }}</span>
                        </ds-button-loading>
                    </div>
                </section>
            </div>
        </template>
    </controlled-modal>
</template>

<script>
import { mapState } from 'vuex';
import cloneDeep from 'lodash.clonedeep';
import ControlledModal from '@/shared/components/ControlledModal';
import {
    TIMER_STEPS, TAG_APPLIED, REMOVE_TAG, APPLY_TAG, MAXLENGTH,
} from '@/automations/constants/automations.constants';
import automationDisplayNameMixin from '@/automations/components/automationDisplayName.mixin';

export default {
    placeholder: [
        { height: '2rem', boxes: [1] },
        { height: '2rem', boxes: [1] },
    ],

    maxlength: { name: MAXLENGTH.TEMPLATE.NAME, description: MAXLENGTH.TEMPLATE.DESCRIPTION },

    components: {
        ControlledModal,
    },

    mixins: [automationDisplayNameMixin],

    props: {
        isOpen: Boolean,
        createFromAutomationId: String,
        template: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            displayEmailSharing: false,
            loading: true,
            saving: false,
            sending: false,
            submitted: false,
            localTemplate: {},
            emails: '',
        };
    },

    beforeDestroy() {
        this.close();
    },

    created() {
        this.init();
    },

    watch: {
        isOpen: {
            handler(val) {
                this.init(val);
            },
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            tags: ({ tags }) => tags.tags,
        }),

        templateLink() {
            return `${process.env.VUE_APP_PUBLIC_URL}/automations/template/${this.localTemplate?.id}`;
        },

        isDisabled() {
            return !this.localTemplate.name;
        },
    },

    methods: {
        init(isOpen) {
            if ((isOpen || this.isOpen) && this.createFromAutomationId) {
                this.createFromAutomation();
            } else if ((isOpen || this.isOpen)) {
                this.localTemplate = cloneDeep(this.template);
                this.loading = false;
            }
        },

        close() {
            this.emails = '';
            this.localTemplate = {};
            this.submitted = false;
            this.$emit('close');
        },

        async copyTemplateLink() {
            const headline = this.$t('copy.successHeadline');
            const message = this.$t('copy.successMessage');
            const errorMessage = this.$t('copy.error');

            await this.$copyText(this.templateLink)
                .catch(() => {
                    this.$error({ message: errorMessage });
                });

            this.$toast({ headline, message });
        },

        async shareEmail() {
            this.sending = true;

            try {
                await this.$store.dispatch('automations/SHARE_TEMPLATE', {
                    emailAddresses: this.emails,
                    content: this.$t('email.body', { templateLink: this.templateLink }),
                    subject: this.$t('email.subject'),
                    fromUser: +this.user.id,
                    fromContactOwner: false,
                });
                this.$toast({ message: this.$t('email.sentTo', { emails: this.emails }) });
                this.close();
            } catch {
                this.$error({ message: this.$t('email.error') });
            }
            this.sending = false;
        },

        async createFromAutomation() {
            this.loading = true;

            try {
                const automation = await this.$store.dispatch('automations/LOAD_AUTOMATION', this.createFromAutomationId);
                const request = {
                    automationId: automation.id,
                    name: automation.name.substr(0, this.$options.maxlength.name),
                    description: await this.getDescription(automation),
                };
                const template = await this.$store.dispatch('automations/CREATE_AUTOMATION_TEMPLATE', request);

                this.$bus.$emit('UPDATED_AUTOMATION_SHARED_TEMPLATE');
                this.localTemplate = template;
                this.$emit('template-created');
            } catch (e) {
                this.$error({ message: this.$t('error.share') });
                this.close();
            }
            this.loading = false;
        },

        async saveDetails() {
            this.saving = true;
            this.submitted = true;

            if (this.$refs.form.checkValidity()) {
                try {
                    await this.$store.dispatch('automations/UPDATE_AUTOMATION_TEMPLATE_DETAILS', this.localTemplate);

                    this.$bus.$emit('UPDATED_AUTOMATION_SHARED_TEMPLATE');
                    this.close();
                    this.submitted = false;
                } catch {
                    this.$error({ message: this.$t('errorMessage') });
                }
            }
            this.saving = false;
        },

        async getDescription(automation) {
            let str = '';
            const firstStepIsTimer = TIMER_STEPS.includes(automation.steps[0].type);

            str += `${this.$t('templateDescription.when', { trigger: await this.getTriggerDescription(automation.triggers[0]) })}\n`;
            str += `${this.$t('templateDescription.then', { step: await this.getStepDescription(automation.steps[0]) })}`;

            if (firstStepIsTimer) {
                str += ` ${this.$t('templateDescription.and', { step: await this.getStepDescription(automation.steps[1]) })}`;
            }

            if ((!firstStepIsTimer && automation.steps.length > 1) || (firstStepIsTimer && automation.steps.length > 2)) {
                str += '...';
            }

            return str.substr(0, this.$options.maxlength.description);
        },

        async getTriggerDescription(trigger) {
            if (trigger && trigger.type) {
                const { type, name } = trigger;

                await this.loadDescriptionInformation(trigger);

                if (type === TAG_APPLIED) {
                    return this.automationDisplayName_getTagTriggerDescription(trigger);
                }

                if (this.$te(`templateDescription.${type}`)) {
                    return this.$t(`templateDescription.${type}`, { name });
                }
            }

            return '...';
        },

        async getStepDescription(step) {
            if (step && step.type) {
                const { type, name, configJson } = step;

                await this.loadDescriptionInformation(step);

                if (TIMER_STEPS.includes(type)) {
                    return this.automationDisplayName_timerStepName(step);
                }

                if (type === APPLY_TAG || type === REMOVE_TAG) {
                    return this.automationDisplayName_getTagStepDescription(step);
                }

                if (this.$te(`templateDescription.${type}`)) {
                    return this.$t(`templateDescription.${type}`, { name, title: configJson?.title });
                }
            }

            return '...';
        },

        async loadDescriptionInformation({ type }) {
            if ((type === TAG_APPLIED || type === APPLY_TAG || type === REMOVE_TAG) && !this.tags.length) {
                await this.$store.dispatch('tags/LOAD_TAGS');
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .share-template-modal {
        margin: $gp * -1.5;
    }

    .edit-section {
        margin: $gp * 1.5;
    }

    .template-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: $gp * 1.5 $gp $gp;
    }

    .edit-button {
        margin-top: $gp;
    }

    .template-card {
        width: px-to-rem(340px);
    }

    hr {
        margin: 0;
    }

    .share-details {
        padding: $gp * 1.5;
    }

    .how-to-share {
        font-size: $font-size-med;
    }

    .template-link,
    .email-sharing {
        display: flex;
        align-items: center;
        white-space: nowrap;
        --input-margin-bottom: 0;

        .input-field {
            @include margin-end($gp / 2);
        }
    }

    .copy-button,
    .share-button {
        min-width: px-to-rem(95px);
    }

    .template-link {
        margin-bottom: $gp;
    }

    .share-social {
        margin-top: $gp * 1.5;
    }

    .save-button {
        @include margin-start(auto);
        @include margin-end($gp / 2);
    }
</style>

<i18n>
{
    "en-us": {
        "header": "Share automation template",
        "editDescription": "Edit template information",
        "howToShare": "How would you like to share?",
        "copyBelow": "Copy the link below to share.",
        "share": "Share",
        "copyLink": "Copy link",
        "copy": {
            "successHeadline": "Link copied",
            "successMessage": "Template link has been copied to your clipboard.",
            "error": "The link could not be copied"
        },
        "template": {
            "name": "Template name",
            "description": "Template description"
        },
        "email": {
            "placeholder": "Enter emails to share with",
            "subject": "'An Automation has been shared with you!'",
            "body": "<p>Hi there,</p><p>You’re in luck! A new automation has been shared with you.  To get access simply click the link below and log into your Keap account. </p><p><a href='{templateLink}'>{templateLink}</a></p><p>Enjoy!</p><p>Your friends at Keap</p>",
            "sentTo": "Sent to {emails}",
            "error": "There was an error sharing the automation."
        },
        "error": {
            "share": "There was an error sharing the automation."
        },
        "templateDescription": {
            "when": "When {trigger}",
            "then": "Then {step}",
            "and": "and {step}",

            "SEND_EMAIL": "{name}",
            "CREATE_DEAL": "create a deal",
            "CREATE_TASK": "{name}",
            "SEND_NOTIFICATION": "{name}",
            "SEND_SMS": "send ({title}) text",
            "HELLOSIGN_SEND_DOCUMENT": "send HelloSign document",

            "LEAD_CAPTURE_FORM_SUBMITTED": "any public form is submitted",
            "PUBLIC_FORM_SUBMITTED": "a public form is submitted",
            "ANY_INTERNAL_FORM_SUBMITTED": "any internal form is submitted",
            "INTERNAL_FORM_SUBMITTED": "an internal form is submitted",
            "PRODUCT_PURCHASED": "a product is purchased",
            "ANY_PRODUCT_PURCHASED": "any product is purchased",
            "APPOINTMENT_SCHEDULED": "an appointment is scheduled",
            "ANY_APPOINTMENT_SCHEDULED": "any appointment is scheduled",
            "APPOINTMENT_CANCELLED": "an appointment is cancelled",
            "ANY_APPOINTMENT_CANCELLED": "any appointment is cancelled",
            "USER_APPOINTMENT_SCHEDULED": "an appointment is scheduled",
            "USER_APPOINTMENT_CANCELLED": "an appointment is cancelled",
            "ANY_INVOICE_SENT": "an invoice is sent",
            "ANY_INVOICE_PAID": "an invoice is paid",
            "ANY_QUOTE_SENT": "a quote is sent",
            "ANY_QUOTE_ACCEPTED": "a quote is accepted",
            "HELLOSIGN_DOCUMENT_SIGNED": "a HelloSign document is signed",
            "HELLOSIGN_ANY_DOCUMENT_SIGNED": "any HelloSign document is signed",
            "TYPEFORM_ANY_FORM_SUBMITTED": "any Typeform is submitted",
            "TYPEFORM_FORM_SUBMITTED": "a Typeform is submitted",
            "SHOPIFY_ANY_PRODUCT_PURCHASED": "any Shopify product is purchased",
            "SHOPIFY_PRODUCT_PURCHASED": "a Shopify product is purchased",
            "SHOPIFY_ANY_CART_ABANDONED": "any Shopify cart is abandoned",
            "SHOPIFY_CART_ABANDONED": "a Shopify cart is abandoned",
            "DEAL_ENTERED": "a deal enters stage",
            "DEAL_EXITED": "a deal exits stage"
        }
    }
}
</i18n>
