<template>
    <controlled-modal
        :is-open="isOpen"
        size="xl"
        class="kmn-template-manager-modal"
        :show-close="false"
        @close="close"
    >
        <div class="template-manager">
            <section class="template-nav">
                <header class="template-nav-header">
                    <ds-icon-button
                        name="x"
                        class="close-button"
                        data-qa="close-manager"
                        :aria-label="$t('buttonLabels.close')"
                        @click="close"
                    />

                    <div class="header-text">
                        <h4 class="title">
                            {{ $t('title') }}
                        </h4>
                    </div>

                    <ds-icon-button
                        name="add-circle"
                        class="create-template-button"
                        data-qa="template-manager-create-template"
                        :aria-label="$t('buttonLabels.create')"
                        @click="createTemplate"
                    />
                </header>

                <div class="template-items">
                    <ds-list-item
                        v-for="(template, i) in sortedKeapMarketingNumberTemplates"
                        :key="i"
                        :class="['template-item', { selected: isTemplateSelected(template)} ]"
                        :title="templateTitle(template)"
                        @click.native="handleTemplateItemClick(template)"
                    />
                </div>

                <div class="share-feedback-container">
                    <ds-text-button
                        leading-icon="message-square"
                        class="small"
                        data-qa="kmn-feedback-button"
                        @click="isFeedbackModalOpen = true"
                    >
                        {{ $t('shareFeedback') }}
                    </ds-text-button>
                </div>
            </section>

            <section v-if="selectedTemplate" :class="['template-editor', { open: editorOpen }]">
                <header class="template-editor-header">
                    <ds-icon-button
                        name="arrow-left"
                        :aria-label="$t('buttonLabels.back')"
                        @click="setEditorOpen(false)"
                    />

                    <h4 class="edit-template">
                        {{ $t('editTemplate') }}
                    </h4>

                    <ds-icon-button
                        v-if="showDelete"
                        :class="['delete-button-mobile', { 'disable-delete': saving }]"
                        :aria-label="$t('buttonLabels.delete')"
                        name="trash-2"
                        @click="deleteTemplate"
                    />
                </header>

                <section :class="['template-editor-title', { 'hide-delete': !showDelete }]">
                    <span class="label">{{ $t('titleLabel') }}</span>

                    <ds-input-field
                        ref="titleInput"
                        v-model="title"
                        :placeholder="$t('defaultTitle')"
                        @input="handleEdit"
                    />

                    <ds-icon-button
                        v-if="showDelete"
                        name="trash-2"
                        class="delete-button"
                        :aria-label="$t('buttonLabels.delete')"
                        :disabled="saving"
                        @click="deleteTemplate"
                    />
                </section>

                <textarea
                    ref="messageInput"
                    v-model="content"
                    class="message-content"
                    rows="1"
                    :placeholder="$t('contentPlaceholder')"
                    :maxlength="maxLength + 1"
                    @input="handleContentEdit"
                />

                <div class="footer">
                    <span v-if="hasError" class="character-count-error">
                        <i18n v-if="!containsSupportedCharacter" tag="div" path="unSupportedCharacterTypeError.1">
                            <template #bold>
                                <strong>{{ $t('unSupportedCharacterTypeError.2') }}</strong>
                            </template>
                        </i18n>

                        <span v-else path="characterCountError">
                            {{ $t('characterCountError', { maxLength }) }}
                        </span>
                    </span>

                    <span class="character-count">
                        {{ characterCountLabel }}
                    </span>
                </div>

                <section class="template-button-row">
                    <div class="action-buttons">
                        <sms-merge-field-button v-if="!nonCustomFieldsEnabled" @input="insertText" />

                        <merge-field-dropdown
                            v-else
                            position="top-start"
                            data-qa="merge-field-dropdown"
                            :hide-custom-fields="true"
                            :use-merge-service="nonCustomFieldsEnabled"
                            @select="({ value }) => insertText(value)"
                        >
                            <template #action="{ toggle }">
                                <ds-icon-button
                                    name="merge-field"
                                    :aria-label="$t('buttonLabels.toggleMerge')"
                                    @click="toggle"
                                />
                            </template>
                        </merge-field-dropdown>

                        <sms-link-shortener-button
                            @shorten-link="insertText"
                        />
                    </div>

                    <div v-if="saving" class="saving semibold">
                        <ds-icon name="loader" />

                        <span>{{ $t('saving') }}</span>
                    </div>

                    <div v-if="saved" class="saved">
                        <ds-status type="good" emphasis="medium">
                            {{ $t('saved') }}
                        </ds-status>
                    </div>

                    <ds-filled-button class="insert-template" @click="insertTemplate">
                        {{ $t('insertTemplate') }}
                    </ds-filled-button>

                    <ds-filled-button class="insert-template-mobile" @click="insertTemplate">
                        {{ $t('insert') }}
                    </ds-filled-button>
                </section>
            </section>

            <portal to="root">
                <sms-broadcast-feedback-modal
                    :is-open="isFeedbackModalOpen"
                    :source="smsFeedbackSource"
                    @close="isFeedbackModalOpen = false"
                />
            </portal>
        </div>
    </controlled-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';
import amplitude from '@/analytics/amplitude';

import SmsMergeFieldButton from '@/broadcasts/components/sms/SmsMergeFieldButton';
import SmsLinkShortenerButton from '@/broadcasts/components/sms/SmsLinkShortenerButton';
import ControlledModal from '@/shared/components/ControlledModal';
import SmsBroadcastFeedbackModal from '@/broadcasts/components/sms/SmsBroadcastFeedbackModal';
import MergeFieldDropdown from '@/shared/components/MergeField/MergeFieldDropdown';

import { MEDIA_TYPES, TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';
import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import { FF_KEAP_SMS_NONCUSTOM_MERGEFIELDS } from '@/shared/constants/featureFlag.constants';
import { hasSupportedMergeField } from '@/broadcasts/utils/smsBroadcast.util';
import {
    MAX_CHARACTER_COUNT,
    SUPPORTED_MERGE_FIELDS,
} from '@/broadcasts/smsBroadcast.constants';
import { SMS_ALLOWED_CHARS_REGEX, SMS_FEEDBACK_MODAL_SOURCE } from '@/shared/sms.constants';

export default {
    components: {
        SmsMergeFieldButton,
        MergeFieldDropdown,
        SmsBroadcastFeedbackModal,
        ControlledModal,
        SmsLinkShortenerButton,
    },

    props: {
        editDelay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
        isOpen: Boolean,
    },

    data() {
        return {
            selectedTemplate: null,
            editorOpen: false,
            saving: false,
            saved: false,
            timeout: null,
            title: '',
            content: '',
            maxLength: MAX_CHARACTER_COUNT,
            popoverOpen: false,
            isFeedbackModalOpen: false,
            smsFeedbackSource: SMS_FEEDBACK_MODAL_SOURCE.KMN_TEMPLATES,
        };
    },

    created() {
        if (this.editDelay > 0) {
            this.handleEdit = debounce(this.handleEdit, this.editDelay);
        }
    },

    watch: {
        isOpen: {
            handler() {
                if (Array.isArray(this.sortedKeapMarketingNumberTemplates) && this.sortedKeapMarketingNumberTemplates.length > 0) {
                    this.setTemplate(this.sortedKeapMarketingNumberTemplates[0]);
                }
            },
        },
    },

    computed: {
        ...mapState({
            tutorials: ({ tutorials }) => tutorials.items,
            nonCustomFieldsEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_NONCUSTOM_MERGEFIELDS],
        }),

        ...mapGetters({
            getSupportedMergeFields: 'mergeFields/getSupportedMergeFields',
            sortedKeapMarketingNumberTemplates: 'communicationTemplates/sortedKeapMarketingNumberTemplates',
        }),

        showDelete() {
            return Boolean(
                this.selectedTemplate
                && this.selectedTemplate.id
                && typeof this.selectedTemplate.id === 'string'
                && !this.selectedTemplate.id.startsWith('default'),
            );
        },

        hasError() {
            return !this.containsSupportedCharacter || (this.characterCount > this.maxLength);
        },

        supportedMergeFields() {
            if (!this.nonCustomFieldsEnabled) {
                return SUPPORTED_MERGE_FIELDS;
            }

            return this.getSupportedMergeFields(this.additionalMergeFields, true);
        },

        characterCount() {
            return this.content.length;
        },

        characterCountLabel() {
            const hasMergeField = hasSupportedMergeField(this.content, Object.keys(this.supportedMergeFields));

            return `${hasMergeField ? this.$t('approx') : ''} ${this.characterCount}/${this.maxLength} ${this.$t('characters')}`;
        },

        containsSupportedCharacter() {
            return this.content ? SMS_ALLOWED_CHARS_REGEX.test(this.content) : true;
        },
    },

    methods: {
        async createTemplate() {
            const payload = {
                mediaType: MEDIA_TYPES.PLAIN_TEXT,
                templateKind: TEMPLATE_KINDS.KMN_BROADCAST_CUSTOM,
                title: '',
                subject: '',
                content: '',
            };

            const createdTemplate = await this.$store.dispatch('communicationTemplates/CREATE_KEAP_MARKETING_NUMBER_TEMPLATE', { payload });

            amplitude.v2.logEvent(amplitude.v2.events.KMN_TEMPLATE_CREATED, {
                Source: 'Broadcasts',
            });
            this.setEditorOpen(true);
            this.setTemplate(createdTemplate);
        },

        async deleteTemplate() {
            this.saving = false;
            const templateId = this.selectedTemplate.id;

            try {
                await this.$confirm({
                    optionTitle: this.$t('deleteTemplate.title'),
                    optionMessage: this.$t('deleteTemplate.message'),
                    optionConfirmButtonLabel: this.$t('deleteTemplate.confirm'),
                    destructive: true,
                    optionCancel: this.$t('deleteTemplate.cancel'),
                });
            } catch {
                return;
            }

            await this.$store.dispatch('communicationTemplates/DELETE_KEAP_MARKETING_NUMBER_TEMPLATE', { templateId });

            amplitude.v2.logEvent(amplitude.v2.events.KMN_TEMPLATE_DELETED, {
                Source: 'Broadcasts',
                'Template content': this.content,
                'Template title': this.title,
                'Template Kind': this.selectedTemplate?.templateKind,
            });
            this.setEditorOpen(false);
            this.setTemplate(this.sortedKeapMarketingNumberTemplates[0]);
        },

        handleTemplateItemClick(template) {
            this.setEditorOpen(true);
            this.setTemplate(template);
        },

        handleContentEdit() {
            if (this.content === this.selectedTemplate.content) {
                return;
            }

            this.handleEdit();
        },

        async handleEdit() {
            this.saving = true;
            this.saved = false;
            const templateKind = this.selectedTemplate.templateKind || TEMPLATE_KINDS.KMN_BROADCAST_CUSTOM;
            const { content, title } = this;
            const templateId = this.selectedTemplate.id;

            const payload = {
                mediaType: MEDIA_TYPES.PLAIN_TEXT,
                templateKind,
                title,
                subject: '',
                content,
            };

            if (templateId.startsWith('default')) {
                const createdTemplate = await this.$store.dispatch('communicationTemplates/CREATE_KEAP_MARKETING_NUMBER_TEMPLATE', { payload });

                this.selectedTemplate = createdTemplate;
            } else {
                await this.$store.dispatch('communicationTemplates/UPDATE_KEAP_MARKETING_NUMBER_TEMPLATE', {
                    payload,
                    templateId,
                });
            }
            this.saving = false;
            this.saved = true;
        },

        insertText(value) /* istanbul ignore next */ {
            const { messageInput } = this.$refs;

            if (messageInput?.setRangeText) {
                messageInput.setRangeText(value);
            } else {
                messageInput.focus();
                document.execCommand('insertText', false, value);
            }

            this.content = messageInput.value;

            this.handleContentEdit();
        },

        insertTemplate() {
            const { id } = this.selectedTemplate;
            const template = {
                id,
                content: this.content,
            };

            this.$bus.$emit('INSERT_COMMUNICATION_TEMPLATE', template);
            this.close();
        },

        isTemplateSelected(template) {
            return this.selectedTemplate
                ? this.selectedTemplate.id === template.id
                : false;
        },

        close() {
            this.$emit('close');
        },

        setEditorOpen(value) {
            this.editorOpen = value;
        },

        setTemplate(template = {}) {
            this.selectedTemplate = template;
            this.title = template.title || '';
            this.content = template.content || '';
            this.saved = false;
        },

        templateTitle(template) {
            return template?.title || this.$t('defaultTitle');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $template-manager-height: #{px-to-rem(700px)};
    $template-nav-width: #{px-to-rem(360px)};

    .kmn-template-manager-modal {
        --modal-padding: 0;
    }

    .template-manager {
        display: flex;
        height: $template-manager-height;

        @media($small) {
            height: 100vh;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .template-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $template-nav-width;

        @media($small) {
            flex: 1;
            min-height: 0;
            max-width: 100%;
        }
    }

    .title {
        font-weight: $font-weight-bold;
        font-size: $font-size-lg;
    }

    .hide-template-nav,
    .insert-template{
        @media($small) {
            display: none;
        }
    }

    .template-nav-header {
        display: flex;
        padding: $gp * 1.5 $gp $gp * 2;

        @media($small) {
            width: 100%;
        }
    }

    .template-items {
        @include overflowY;
    }

    .template-item {
        cursor: pointer;

        --list-item-padding: #{$gp * 1.5};


        &.selected {
            background-color: $color-gray-050;
        }

        &:hover {
            background-color: $color-gray-050;
        }

        @media ($small) {
            &.selected {
                background-color: $color-paper;
            }
        }
    }

    .template-editor {
        display: flex;
        flex-direction: column;
        background-color: $color-paper;
        width: 100%;
        @include border-start(1px solid $color-gray-200);
        @include border-radius(0 $border-radius $border-radius 0);


        @media($small) {
            @include transition(transform);

            transform: translateX(100%);
            @include position(absolute 0 0 auto auto);
            z-index: 5;
            height: 100vh;

            &.open {
                transform: translateX(0);
            }

            &:not(.open) {
                transform: translateX(100%);
            }
        }
    }

    .template-editor-header,
    .insert-template-mobile {
        display: none;

        @media($small) {
            display: flex;
        }
    }

    .edit-template {
        flex: 1;
        margin: 0 $gp / 2;
    }

    .template-nav-header,
    .template-editor-header,
    .template-editor-title {
        border-bottom: 1px solid $color-gray-200;
    }

    .template-editor-header,
    .template-button-row {
        padding: $gp;
    }

    .template-editor-title {
        display: flex;
        align-items: center;
        font-size: $font-size-med;
        padding: $gp / 2 $gp / 2;
        white-space: nowrap;

        --input-margin-bottom: 0;
        --input-padding: 0;
        --input-border: none;
        --input-focus-border: none;
        --input-height: #{$gp * 1.5};

        .label {
            padding: 0 $gp / 2;
            color: $color-gray-700;
        }

        &.hide-delete {
            padding: $gp $gp / 2;
        }
    }

    .delete-button {
        &-mobile {
            display: none;

            &.disable-delete {
                --icon-color: #{$color-gray-700};
            }
        }

        &:disabled {
            background-color: $color-paper;
        }

        @media($small) {
            display: none;

            &-mobile {
                display: block;
            }
        }
    }

    .message-content {
        border: none;
        font-size: $font-size-med;
        margin-bottom: $gp / 2;
        resize: none;
        padding: $gp;
        flex: 1;
        outline: none;
    }

    .template-button-row {
        display: flex;
        align-items: center;
        border-top: 1px solid $color-gray-200;
    }

    .action-buttons {
        display: flex;
        flex: 1;
    }

    .saving {
        display: flex;
        align-items: center;
        color: $color-gray-700;
        --icon-size: #{$gp};
        font-size: $font-size-xs;
        padding: 0 $gp;

        .icon {
            animation: spin 4s infinite;
            padding: $gp / 8;
        }
    }

    .saved {
        padding: 0 $gp;
    }

    .close-button {
        margin: 0 $gp / 1.5 0 0;
        padding: 0;
        height: fit-content;
        width: fit-content;
    }

    .create-template-button {
        margin: 0 0 0 $gp * 2;
        padding: 0;
        height: fit-content;
        width: fit-content;
    }

    .character-count {
        color: $color-text-subtle;
        align-self: end;
        margin-top: auto;
        margin-left: auto;
    }

    .footer {
        padding: 0 $gp $gp;
        font-size: px-to-rem(12px);
        display: flex;
        flex-direction: row;
    }

    .character-count-error {
        color: $color-red;
        display: inline-block;
        max-width: 67%;
    }

    .share-feedback-container {
        margin-top: auto;
        justify-content: center;
        display: flex;
        padding: $gp / 4;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Text message templates",
        "subTitle": "Sent from Keap Marketing Number",
        "defaultTitle": "New template",
        "defaultContent": "(no content)",
        "editTemplate": "Edit template",
        "deleteTemplate": {
            "title": "Delete template?",
            "message": "Once you delete this template, it's gone forever.",
            "confirm": "Yes, delete",
            "cancel": "No, cancel"
        },
        "titleLabel": "Template name: ",
        "contentPlaceholder": "Add your text message content here...",
        "saving": "Saving",
        "saved": "Saved",
        "insertTemplate": "Insert template",
        "insert": "Insert",
        "popoverHeader": "Shorten your link",
        "destinationLink": "Destination link",
        "shortenLink": "Shorten link",
        "shortenError": "Unable to shorten link. Please try again.",
        "approx": "Approx",
        "characters": "characters",
        "characterCountError": "Your message exceeds the {maxLength}-character limit. Shorten to continue.",
        "unSupportedCharacterTypeError": {
            "1": "Your message {bold}. Your message currently has unsupported characters (i.e., emojis, special characters, hidden characters, images, GIFs, etc.). This may be the result of copy-pasting. If the problem persists, try re-typing your message manually.",
            "2": "can only contain letters and numbers"
            },
        "shareFeedback": "Share feedback",
        "buttonLabels": {
            "create": "Create template",
            "delete": "Delete template",
            "close": "Close manager",
            "back": "back",
            "toggleMerge": "toggle merge field dropdown"
        }
    }
}
</i18n>
