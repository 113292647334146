<template>
    <div v-if="hasEnabledLinks">
        <h3 class="related-links-header">
            {{ $t('nav.related.links') }}
        </h3>

        <router-link
            v-for="(link, index) in filteredLinks"
            :key="index"
            :to="link.to"
            class="related-link"
        >
            {{ link.text }}
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        links: {
            type: Array,
            required: true,
        },
    },

    computed: {
        hasEnabledLinks() {
            return this.links.some((link) => link.enabled);
        },

        filteredLinks() {
            return this.links.filter((link) => link.enabled);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./side-nav-variables";

.related-links-header {
    font-size: $font-size-med;
    font-weight: $font-weight-semibold;
    line-height: $line-height-lg;
    @include margin-start($gp);
    margin-bottom: $gp / 2;
}

.related-link {
    border-radius: $border-radius;
    color: inherit;
    display: block;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    line-height: $line-height-md;
    margin-top: $gp / 4;
    @include padding(px-to-rem(6) $gp);
    text-decoration: none;

    &:hover,
    &:focus,
    &.router-link-active {
        background: $side-nav-color-primary;
        font-weight: $font-weight-semibold;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
</style>

<i18n>
{
    "en-us": {
        "nav.related.links": "Related shortcuts"
    }
}
</i18n>
