<template lang="html">
    <add-company-form
        :error-message="$t('errors.addCompany')"
        :on-save="onSaveMethod"
        :post-save="onAddCompany"
        :submit-action-text="$t('global.addCompanyShort')"
        :use-duplicate="useDuplicate"
        :hide-add-contact="hideAddContact"
        :prevent-confirmation="preventConfirmation"
    >
        <template #error>
            <div>
                {{ $t('error') }}
            </div>
        </template>
    </add-company-form>
</template>

<script>
import AddCompanyForm from './AddCompanyForm';
import { trackCompanyCreated } from '@/contacts/analytics';

export default {
    components: {
        AddCompanyForm,
    },

    props: {
        onAddCompany: {
            type: Function,
            default: (company) => Promise.resolve(company),
        },
        eventSource: String,
        useDuplicate: Boolean,
        hideAddContact: Boolean,
        preventConfirmation: Boolean,
    },

    data() {
        return {
            nestedModal_title: this.$t('global.addCompany'),
        };
    },

    computed: {
        onSaveMethod() {
            return this.addCompanyDetails;
        },
    },

    methods: {
        addCompany(companyName) {
            return this.$store.dispatch('contacts/ADD_COMPANY', companyName)
                .then((company) => {
                    trackCompanyCreated(this.eventSource);

                    return company;
                });
        },

        addCompanyDetails(companyDetails) {
            return this.$store.dispatch('contacts/ADD_COMPANY_DETAILS', companyDetails)
                .then((company) => {
                    trackCompanyCreated(this.eventSource);

                    return company;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .button-row {
        padding-top: $gp * 2;
    }
</style>

<i18n>
{
    "en-us": {
        "error": "Company couldn't be added. Please fix the error."
    }
}
</i18n>
