var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.appLoaded)?_c('main',{class:{
            'show-nav': _vm.showNav,
            'hide-header-mobile': _vm.hideHeaderOnMobile,
            'nav-open': _vm.navOpen,
            'nav-expanded': _vm.isNavExpanded,
            'nav-redesign': _vm.isNavRedesignEnabled,
        }},[(_vm.showNav)?[_c('ds-media-query',{attrs:{"breakpoint":"medium"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var isMatch = ref.isMatch;
return [(isMatch && _vm.isNavRedesignEnabled)?_c('side-nav-menu',{attrs:{"user":_vm.user}}):_c('div',[_c('nav-header',{staticClass:"nav-header"}),_c('desktop-nav-menu')],1)]}}],null,false,2656689712)}),_c('mobile-nav-menu')]:_vm._e(),_c('section',{staticClass:"content",attrs:{"id":"content"}},[(_vm.showRouterView)?_c('router-view'):(_vm.showExpired)?_c('expired-block'):_vm._e()],1),_c('mobile-interceptor'),_c('keyboard-shortcuts'),_c('help-and-support-panel'),_c('analytics-handler'),_c('import-contacts'),_c('onboarding-tracker'),(_vm.showSidePanel)?_c('notifications-panel'):_vm._e(),(_vm.showSidePanel)?_c('contextual-automation-panel'):_vm._e(),_c('ehawk-check-modal',{attrs:{"is-open":_vm.isEhawkModalOpen},on:{"close":function($event){return _vm.$store.commit('ehawk/SET_EHAWK_MODAL_OPEN', false)}}}),_c('account-switcher',{ref:"accountSwitcher",attrs:{"local-port":_vm.localPort}}),(_vm.callsEnabled && _vm.hasKblNumber)?_c('call-widget'):_vm._e()],2):(_vm.shouldShowLogInAsError)?_c('div',{staticClass:"page-overlay",attrs:{"data-qa":"log-in-as-load-error"}},[_c('log-in-as-load-error',{attrs:{"error-messages":_vm.logInAsLoadErrors}})],1):(_vm.bootLoadError)?_c('div',{staticClass:"page-overlay",attrs:{"data-qa":"boot-load-error"}},[_c('boot-load-error')],1):_c('app-placeholder'),(_vm.shouldShowMobileOverlay)?_c('div',{staticClass:"page-overlay"},[_c('mobile-only')],1):_vm._e(),(_vm.hasSession)?_c('img',{staticStyle:{"display":"none"},attrs:{"src":_vm.coreAppUrl}}):_vm._e(),_c('portal-target',{attrs:{"name":"behind-nested-modal","multiple":""}}),_c('ds-nested-modal',{ref:"nestedModal"}),_c('portal-target',{attrs:{"name":"root","multiple":""}}),_c('ds-focus-on-keyboard-only')],1)}
var staticRenderFns = []

export { render, staticRenderFns }