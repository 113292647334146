<template>
    <div class="email-step configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div v-if="showTemplateSelector" class="template-selector">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="email-body select-option">
                    <div class="button-container">
                        <ds-text-button leading-icon="add" data-qa="blank-email" @click="selectBlankTemplate">
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(template, i) in filteredEmailTemplates"
                        :key="i"
                        as="a"
                        class="list-item-option email-template"
                        :data-qa="`template_${displayTitle(template)}`"
                        :title="displayTitle(template)"
                        :description="displayDescription(template)"
                        @click="handleTemplateSelection(template, template.title)"
                    />
                </section>
            </div>

            <div v-if="showEmailEditor" class="email-form">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editContent') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="email-editor-next" @click="handleNext">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="email-body">
                    <div class="input-row">
                        <label>{{ $t('editor.title') }}</label>

                        <ds-input-field
                            v-model="selectedTemplate.title"
                            data-qa="template-title"
                            name="title"
                            :maxlength="MAXLENGTH"
                            :placeholder="$t('editor.defaultTitle')"
                            @input="handleEdit"
                        />
                    </div>

                    <div class="input-row">
                        <label>{{ $t('editor.from') }}</label>

                        <div class="select-wrapper">
                            <ds-select-field
                                v-model="fromUserSelection"
                                data-qa="from-user"
                                bind-value-only
                                name="from-user"
                                :options="fromOptions"
                                @input="handleFromSelected"
                            />

                            <invalid-icon
                                v-if="showFromUserError"
                                data-qa="from-user-error"
                                class="from-user-error"
                                :message="$t('error.fromUser')"
                            />
                        </div>
                    </div>

                    <div class="input-row">
                        <label>{{ $t('editor.subject') }}</label>

                        <ds-input-field
                            v-model="selectedTemplate.subject"
                            data-qa="subject"
                            name="subject"
                            :maxlength="MAXLENGTH"
                            @input="handleEdit"
                        />

                        <invalid-icon v-if="showSubjectError" data-qa="subject-error" :message="$t('error.subject')" />
                    </div>

                    <div class="email-content">
                        <quill-editor
                            ref="quillEditor"
                            hide-borders
                            mergeable
                            use-merge-service
                            :formats="$options.DEFAULT_FORMATS"
                            :additional-merge-fields="additionalMergeFields"
                            class="quill-editor-wrapper"
                            @input="handleContentEdit"
                        />

                        <invalid-icon v-if="showBodyError" data-qa="body-error" :message="$t(showSizeErrorBanner ? 'error.sizeBody' : 'error.body')" />
                    </div>

                    <ds-inline-alert
                        v-if="showErrorBanner"
                        class="error-banner-container"
                        type="critical"
                        leading-icon
                    >
                        {{ $t(showSizeErrorBanner ? 'error.sizeBanner' : 'error.banner') }}
                    </ds-inline-alert>

                    <div
                        v-if="signatureEnabled && fromContactOwner"
                        class="contact-owner-signature"
                    >
                        <div class="avatar-box" />

                        <div class="signature-details">
                            <div class="line-1">
                                {{ $t('contactOwnerSignature') }}
                            </div>

                            <div class="line-2" />

                            <div class="line-3" />
                        </div>
                    </div>

                    <signature-toggle
                        data-qa="signature-toggle"
                        :signature-enabled="signatureEnabled"
                        :show-signature="fromUser !== 0"
                        :user-id="fromUser"
                        @input="updateSignatureEnabled"
                    />

                    <div class="actions">
                        <section class="button-row">
                            <appointment-button
                                data-qa="appointment-button"
                                @setup-appointments="setupAppointments"
                                @appointment-link-insert="insertAppointmentLink"
                            />

                            <review-button
                                data-qa="review-button"
                                @review-link-insert="insertReviewLink"
                            />
                        </section>
                    </div>
                </section>
            </div>

            <div v-if="showTimingStep" class="timing-editor">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editOptions') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="email-step-done" @click="handleDone(true)">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="timing-body">
                    <timing-step
                        v-if="timingRefactorEnabled"
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ $t('timing.title', { templateTitle: selectedTemplate.title }) }}
                    </timing-step>

                    <configure-timing-step
                        v-else
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ $t('timing.title', { templateTitle: selectedTemplate.title }) }}
                    </configure-timing-step>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import amplitude from '@/analytics/amplitude';

import QuillEditor, { DEFAULT_FORMATS, IMAGE_FORMAT } from '@/shared/components/Text/QuillEditor';
import ConfigureTimingStep from '@/automations/components/configure/ConfigureTimingStep';
import SignatureToggle from '@/shared/components/Email/SignatureToggle';
import TimingStep from '@/automations/components/steps/TimingStep';
import { FF_SIMPLE_AUTOMATION_TIMING_REFACTOR } from '@/shared/constants/featureFlag.constants';

import {
    SEND_EMAIL, EDITOR_STEP, TEMPLATE_STEP, TIMING_STEP, MAXLENGTH,
} from '@/automations/constants/automations.constants';
import SaveStatus from '@/automations/components/SaveStatus';
import InvalidIcon from '@/shared/components/Email/InvalidIcon';
import { getUserFrom } from '@/contacts/utils/email-utils';
import { validate } from '@/automations/utils/automations.utils';
import ReviewButton from '@/communication/components/ReviewButton';
import AppointmentButton from '@/communication/components/AppointmentButton';

const CONTACT_OWNER = 'CONTACT_OWNER';

export default {
    components: {
        QuillEditor,
        SaveStatus,
        ConfigureTimingStep,
        InvalidIcon,
        SignatureToggle,
        AppointmentButton,
        ReviewButton,
        TimingStep,
    },

    DEFAULT_FORMATS: [
        ...DEFAULT_FORMATS,
        IMAGE_FORMAT,
    ],

    props: {
        saveStatus: String,
        showErrors: Boolean,

        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            loading: false,
            currentStep: TEMPLATE_STEP,
            selectedTemplate: {},
            timingDelay: this.automationProperties.delayStep || null,
            signatureEnabled: false,
            search: '',

            fromUser: '',
            fromContactOwner: false,
            fromUserSelection: 0,
        };
    },

    created() {
        this.init();
    },

    watch: {
        'automationProperties.delayStep': {
            handler(value) {
                this.timingDelay = value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapGetters({
            bookingUrl: 'calendar/bookingUrl',
            sortedEmailTemplates: 'communicationTemplates/sortedEmailTemplates',
            displayTitle: 'communicationTemplates/displayTitle',
            displayDescription: 'communicationTemplates/displayDescription',
            additionalMergeFields: 'automations/additionalMergeFields',
        }),

        ...mapState({
            timingRefactorEnabled: ({ featureFlags }) => featureFlags[FF_SIMPLE_AUTOMATION_TIMING_REFACTOR],
            user: ({ auth }) => auth.user,
            users: ({ auth }) => auth.users,
            emailTemplates: ({ communicationTemplates }) => communicationTemplates.emailTemplates,
        }),

        fromOptions() {
            return [
                { value: CONTACT_OWNER, label: this.$t('contactOwner') },
                ...this.usersWithNames,
            ];
        },

        usersWithNames() {
            return this.users.map(({ id, email, fullName }) => getUserFrom({ id: +id, email, fullName }));
        },

        showTemplateSelector() {
            return this.currentStep === TEMPLATE_STEP;
        },

        showEmailEditor() {
            return this.currentStep === EDITOR_STEP;
        },

        showTimingStep() {
            return this.currentStep === TIMING_STEP;
        },

        MAXLENGTH() {
            return MAXLENGTH.NAME;
        },

        showErrorBanner() {
            return this.showErrors && !validate[SEND_EMAIL].isValid(this.emailStep);
        },

        showSizeErrorBanner() {
            return !validate[SEND_EMAIL].bodySize(this.emailStep);
        },

        showFromUserError() {
            return this.showErrors && !validate[SEND_EMAIL].fromUser(this.emailStep);
        },

        showSubjectError() {
            return this.showErrors && !validate[SEND_EMAIL].subject(this.emailStep);
        },

        showBodyError() {
            return this.showErrors && (!validate[SEND_EMAIL].body(this.emailStep) || this.showSizeErrorBanner);
        },

        filteredEmailTemplates() {
            const search = this.search?.trim()?.toLowerCase();

            return this.sortedEmailTemplates.filter((template) => {
                return this.displayTitle(template).toLowerCase().includes(search)
                    || this.displayDescription(template).toLowerCase().includes(search);
            });
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredEmailTemplates.length;
        },

        emailStep() {
            const { title: templateTitle, content, subject } = this.selectedTemplate;
            const name = this.$t('timing.title', { templateTitle, unsubscribeText: this.$t('unsubscribe') });

            return {
                type: SEND_EMAIL,
                name,
                configJson: {
                    fromContactOwner: this.fromContactOwner,
                    fromUser: this.fromUser,
                    body: content,
                    subject,
                    signatureEnabled: this.signatureEnabled,
                    email: {
                        title: templateTitle,
                    },
                },
            };
        },
    },

    methods: {
        init() {
            this.fromUser = +this.user.id;
            const { initialStep, step, delayStep } = this.automationProperties;

            if (step?.configJson) {
                const {
                    fromUser, email, subject, body, signatureEnabled, fromContactOwner,
                } = step.configJson;

                this.fromUser = +fromUser;
                this.fromContactOwner = fromContactOwner;
                this.signatureEnabled = signatureEnabled;

                this.selectedTemplate = {
                    title: email?.title,
                    subject,
                    content: body,
                };
            }

            this.setFromUser();

            this.timingDelay = delayStep || null;

            if (initialStep === EDITOR_STEP) {
                this.handleTemplateSelection(this.selectedTemplate);
            } else if (initialStep === TIMING_STEP) {
                this.currentStep = TIMING_STEP;
            } else {
                this.loadEmailTemplates();
            }
        },

        setFromUser() {
            this.fromUserSelection = this.fromContactOwner
                ? CONTACT_OWNER
                : +this.fromUser;
        },

        async loadEmailTemplates() {
            if (!this.emailTemplates.length) {
                this.loading = true;

                try {
                    await this.$store.dispatch('communicationTemplates/LOAD_EMAIL_TEMPLATES');
                } catch {
                    this.$error({ message: this.$t('error.loadingTemplates'), bottom: true });
                }

                this.loading = false;
            }
        },

        handleTemplateSelection(template, trackName) {
            if (trackName) {
                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                    'Event Source': 'Email Template Clicked',
                    'Template Name': trackName,
                });
            }
            this.selectedTemplate = clonedeep(template);
            this.currentStep = EDITOR_STEP;

            this.$nextTick(() => {
                template.content
                    ? this.$refs.quillEditor?.setContent(this.selectedTemplate.content)
                    : this.$refs.quillEditor?.clearContent();
            });
        },

        handleFromSelected(selection) {
            this.fromContactOwner = selection === CONTACT_OWNER;
            this.fromUser = selection === CONTACT_OWNER ? 0 : +selection;

            this.handleEdit();
        },

        selectBlankTemplate() {
            this.handleTemplateSelection({
                title: this.$t('newEmail'),
                content: '',
                subject: '',
            }, 'blank');
        },

        handleEdit() {
            this.handleDone(false);
        },

        handleContentEdit(value) {
            this.selectedTemplate.content = value;
            this.handleEdit();
        },

        setupAppointments() {
            this.$router.push({ name: 'calendar' });
        },

        insertAppointmentLink(appointmentType) {
            const apptUrl = this.bookingUrl(appointmentType);
            const apptLink = ` <a href="${apptUrl}" target="_blank" rel="noopener noreferrer">${apptUrl}</a>`;

            this.$refs.quillEditor.insertValueAtCursor(apptLink, true);
        },

        insertReviewLink(link) {
            const reviewLink = ` <a href="${link}" target="_blank" rel="noopener noreferrer">${link}</a>`;

            this.$refs.quillEditor.insertValueAtCursor(reviewLink, true);
        },

        handleDone(close = false) {
            const steps = [this.emailStep];
            const hasDelay = this.timingDelay && Object.keys(this.timingDelay).length;

            if (hasDelay) {
                steps.unshift(this.timingDelay);
            }

            this.$emit('updated', steps);

            if (close) {
                this.$emit('close');
            }
        },

        handleBack() {
            if (this.currentStep === TIMING_STEP) {
                this.handleTemplateSelection(this.selectedTemplate);
            } else if (this.currentStep === EDITOR_STEP) {
                this.loadEmailTemplates();
                this.currentStep = TEMPLATE_STEP;
            }
        },

        handleNext() {
            this.currentStep = TIMING_STEP;
        },

        updateTiming(timingDelay) {
            this.timingDelay = timingDelay;
            this.handleEdit();
        },

        updateSignatureEnabled() {
            this.signatureEnabled = !this.signatureEnabled;
            this.handleEdit();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';

    .email-form {
        --input-margin-bottom: 0;
        --input-padding-left: 0;
        --input-border: none;
        --input-focus-border: none;
        --select-field-focus-border: none;
        --quill-editor-border-radius: 0;
        --quill-editor-bottom-border-color: transparent;
        --multiselect-input-border: none;

        input,
        textarea {
            border: 0;
            background-color: transparent;
            flex: 1;
        }

        .select-wrapper {
            width: 100%;
        }

        .input-row {
            position: relative;
            display: flex;
            border-bottom: 1px solid $color-gray-200;
            align-items: center;
            padding: $gp / 2 $gp;
            height: px-to-rem(50px);

            label {
                @include margin-end($gp / 2);
                width: px-to-rem(90);
                color: $color-text-subtle;
                font-weight: $font-weight-regular;
            }

            input {
                flex: 1;
            }
        }

        .email-content {
            position: relative;
        }

        .email-form-scroll {
            @include overflowY;
        }

        .from-user-error {
            background-color: $color-paper;
        }

        .contact-owner-signature {
            display: flex;
            margin: $gp * 1.5 $gp;

            .avatar-box {
                background-color: $color-gray-100;
                height: px-to-rem(75px);
                width: px-to-rem(75px);
                @include margin-end($gp);
            }

            .signature-details {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .line-1 {
                font-size: $font-size-med;
            }

            .line-2, .line-3 {
                height: $gp;
                background-color: $color-gray-100;
            }

            .line-2 {
                width: px-to-rem(139px);
                margin-top: 3px;
            }

            .line-3 {
                width: px-to-rem(115px);
            }
        }
    }
</style>

<i18n>
{
    "en-us": {
        "header": "Choose template",
        "buttonText": "Start with a blank email",
        "editContent": "Edit content",
        "editOptions": "Edit options",
        "newEmail": "New email",
        "contactOwner": "Contact owner",
        "contactOwnerSignature": "Contact owner's signature",
        "editor": {
            "from": "From:",
            "title": "Email title:",
            "defaultTitle": "New email",
            "subject": "Subject:"
        },
        "timing": {
            "title": "Send ({templateTitle}) email"
        },
        "error": {
            "banner": "Please fill out the required fields.",
            "sizeBanner": "The email content is too large for this automation. Please remove some content, such as unhosted images.",
            "subject": "Oops, you need to add a subject line",
            "body": "Oops, you need to add content",
            "sizeBody": "The email content is too large for this automation.",
            "fromUser": "Oops, you need to set who this is from",
            "loadingTemplates": "There was a problem loading email templates."
        },
        "search": "Search",
        "emptySearch": "No results found for \"{search}\""
    }
}
</i18n>
