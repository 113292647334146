<template>
    <div class="deal-moved-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <div v-else>
            <header class="configure-editor-header">
                <ds-icon-button name="arrow-left" data-qa="close-button" @click.native="close" />

                <span class="configure-editor-header-text">{{ $t('header') }}</span>

                <save-status :status="saveStatus" class="shift-right save-status" />

                <ds-filled-button :disabled="showEmptyState" data-qa="next-button" @click="handleNext">
                    {{ $t('global.next') }}
                </ds-filled-button>
            </header>

            <div class="form">
                <automation-pipeline-empty-state v-if="showEmptyState" />

                <div v-else>
                    <ds-inline-alert
                        v-if="showErrorBanner"
                        class="error-banner-container short-error-banner"
                        type="critical"
                        leading-icon
                    >
                        {{ $t('error.banner') }}
                    </ds-inline-alert>

                    <div class="title">
                        {{ title }}
                    </div>

                    <ds-multiselect
                        v-model="selectedStage"
                        class="stage-select"
                        data-qa="stage-select"
                        required
                        categorized
                        bind-value-only
                        :submitted="submitted || showErrors"
                        :placeholder="$t('chooseStage')"
                        :options="getStageOptionsWithPipeline()"
                        @input="handleSelectStage"
                        @selectCategory="setSelectedPipeline"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { TRIGGER_ACTION_TYPES, DEAL_ENTERED, CONTEXT_TYPE } from '@/automations/constants/automations.constants';
import SaveStatus from '@/automations/components/SaveStatus';
import { isValidTrigger, getPipelineStageIds } from '@/automations/utils/automations.utils';
import AutomationPipelineEmptyState from '@/automations/components/AutomationPipelineEmptyState';

const status = { LOADING: 'LOADING', ERROR: 'ERROR', SUCCESS: 'SUCCESS' };

export default {
    components: {
        SaveStatus,
        AutomationPipelineEmptyState,
    },

    props: {
        enterOrExitType: {
            type: String,
            default: DEAL_ENTERED,
        },
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
        saveStatus: String,
        showErrors: Boolean,
        triggerAction: String,
    },

    data() {
        return {
            selectedStage: '',
            selectedPipeline: '',
            status: status.LOADING,
            submitted: false,
            showEmptyState: false,
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapGetters({
            getStageOptionsWithPipeline: 'pipeline/getStageOptionsWithPipeline',
            getAllStages: 'pipeline/getAllStages',
        }),

        ...mapState({
            pipelineList: ({ pipeline }) => pipeline.pipelineList,
            automationContext: ({ automations }) => automations.context,
        }),

        loading() {
            return this.status === status.LOADING;
        },

        dealMovedTrigger() {
            return {
                type: this.enterOrExitType,
                sourceId: this.selectedStage ? `${this.selectedPipeline}-${this.selectedStage}` : '',
                name: this.$t(this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE
                    ? this.isEnterType ? 'dealEnteredStageStop' : 'dealExitedStageStop'
                    : this.isEnterType ? 'dealEnteredStage' : 'dealExitedStage',
                { stageId: this.selectedStage }),
                configJson: {
                    pipelineId: this.selectedPipeline,
                    stageId: this.selectedStage,
                },
            };
        },

        isEnterType() {
            return this.enterOrExitType === DEAL_ENTERED;
        },

        showErrorBanner() {
            return (this.submitted || this.showErrors) && !isValidTrigger(this.dealMovedTrigger);
        },

        title() {
            return this.$t(this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE
                ? this.isEnterType ? 'enteredTitleStop' : 'exitedTitleStop'
                : this.isEnterType ? 'enteredTitle' : 'exitedTitle');
        },
    },

    methods: {
        init() {
            const { trigger } = this.automationProperties;

            if (trigger?.sourceId) {
                const ids = getPipelineStageIds(trigger.sourceId);

                this.selectedStage = ids.stageId;
                this.selectedPipeline = ids.pipelineId;

                this.handleStageChange();
            } else if (this.automationContext?.type === CONTEXT_TYPE.PIPELINE && this.automationContext?.triggerSourceId) {
                const ids = getPipelineStageIds(this.automationContext?.triggerSourceId);

                this.selectedStage = ids.stageId;
                this.selectedPipeline = ids.pipelineId;

                this.handleStageChange();
            } else {
                this.selectedOption = {
                    type: this.enterOrExitType,
                    id: null,
                    name: this.$t(this.isEnterType ? 'dealEnteredStageEmpty' : 'dealExitedStageEmpty'),
                };
            }

            this.handleEdit();

            if (!this.pipelineList.length) {
                this.loadPipelines();
            } else {
                this.status = status.SUCCESS;
            }
        },

        async loadPipelines() {
            this.status = status.LOADING;

            try {
                await this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST');
                this.status = status.SUCCESS;

                if (!this.pipelineList.length) {
                    this.showEmptyState = true;
                }
            } catch {
                this.status = status.ERROR;
            }
        },

        handleSelectStage(selectedStage) {
            this.selectedStage = selectedStage;

            this.handleStageChange();
            this.handleEdit();
        },

        handleStageChange() {
            this.$bus.$emit('AUTOMATION_STAGE_UPDATED');
        },

        handleNext() {
            if (!isValidTrigger(this.dealMovedTrigger)) {
                this.submitted = true;
            } else {
                this.handleEdit();
                this.submitted = false;
                this.close();
            }
        },

        setSelectedPipeline({ value }) {
            this.selectedPipeline = value;
        },

        handleEdit() {
            this.$emit('updated', this.dealMovedTrigger);
        },

        close() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';

    .title {
        padding-bottom: $gp;
        font-size: $font-size-med;
    }

    .error-banner-container,
    .stage-select {
        width: px-to-rem(300);
    }

    .error-banner-container {
        margin: 0 0 $gp 0;
    }

    @mixin small-deal-view() {
        .error-banner-container,
        .stage-select {
            width: 100%;
        }
    }

    @media($extra-small) {
        @include small-deal-view();
    }

    .contextual-view {
        @include small-deal-view();
    }
</style>

<i18n>
{
    "en-us": {
        "header": "Choose a stage",
        "dealEnteredStage": "a deal enters stage ({stageId})",
        "dealExitedStage": "a deal exits stage ({stageId})",
        "dealEnteredStageEmpty": "a deal enters stage",
        "dealExitedStageEmpty": "a deal exits stage",
        "dealEnteredStageStop": "the deal enters stage ({stageId})",
        "dealExitedStageStop": "the deal exits stage ({stageId})",
        "dealEnteredStageEmptyStop": "the deal enters stage",
        "dealExitedStageEmptyStop": "the deal exits stage",
        "enteredTitle": "When a deal enters:",
        "exitedTitle": "When a deal exits:",
        "enteredTitleStop": "When the deal enters:",
        "exitedTitleStop": "When the deal exits:",
        "chooseStage": "Choose stage",
        "error": {
            "banner": "Please fill out the required fields."
        }
    }
}
</i18n>
