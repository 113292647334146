<script>
export default {
    name: 'BusEvent',

    props: {
        name: {
            type: String,
            required: true,
        },
    },

    mounted() {
        this.$bus.$on(this.name, this.handleEvent);
    },

    beforeDestroy() {
        this.$bus.$off(this.name, this.handleEvent);
    },

    methods: {
        handleEvent(...args) {
            this.$emit('event', ...args);
        },
    },

    render() {
        return null;
    },
};
</script>
