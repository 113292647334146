<template>
    <div class="unlayer-container">
        <div id="unlayer-editor" class="unlayer-editor" />
    </div>
</template>

<script>
import axios from 'axios';
import gql from 'graphql-tag';

import { loadScript } from './unlayer.util';

const IMAGES_PAGE_SIZE = 25;

export default {
    name: 'UnlayerEditor',

    inject: {
        editorProvider: {
            default: {
                register() {},
                unregister() {},
                previewHtml: null,
            },
        },
    },

    props: {
        displayMode: {
            type: String,
            required: true,
            options: ['email', 'web'],
        },

        design: Object,

        options: {
            type: Object,
            default: () => ({}),
        },

        // Expose Unlayer's user id config option in order to enable user-generated
        // content blocks and uploaded images.
        unlayerUserId: String,
    },

    data() {
        return {
            blocks: [],
            userImagesPageToken: null,
        };
    },

    async mounted() {
        try {
            await loadScript();
            this.initializeUnlayer();
        } catch (e) {
            // TODO: Need design input on sad path if unlayer fails to load
        }
    },

    beforeDestroy() {
        this.$emit('unload', this.editor);
        this.editorProvider.unregister(this.editor);

        this.editor.removeEventListener('design:updated', this.handleDesignUpdate);
        this.editor.removeEventListener('onImageUpload', this.handleImageUpload);

        if (this.editorProvider.previewHtml) {
            this.editor.removeEventListener('previewHtml', this.editorProvider.previewHtml);
        }
    },

    methods: {
        initializeUnlayer() {
            const { displayMode, options, unlayerUserId } = this;

            const version = process.env.VUE_APP_UNLAYER_EDITOR_VERSION;

            this.editor = window.unlayer.createEditor({
                displayMode,
                id: 'unlayer-editor',
                projectId: parseInt(process.env.VUE_APP_UNLAYER_PROJECT_ID, 10),
                ...(version && { version }),
                ...(unlayerUserId && { user: { id: unlayerUserId } }),
                ...options,
            });

            this.editor.loadDesign(this.design);
            this.editor.addEventListener('design:updated', this.handleDesignUpdate);

            this.editorProvider.register(this.editor);


            if (this.editorProvider.previewHtml) {
                this.editor.addEventListener('previewHtml', this.editorProvider.previewHtml);
            }

            this.initializeBlockProvider();
            this.initializeImagesProvider();

            this.$emit('load');
        },

        initializeBlockProvider() {
            this.editor.registerProvider('blocks', this.fetchBlocks);
            this.editor.registerCallback('block:added', this.handleBlockAdded);
            this.editor.registerCallback('block:removed', this.handleBlockDeleted);
            this.editor.registerCallback('block:modified', this.handleBlockUpdated);
        },

        initializeImagesProvider() {
            this.editor.registerProvider('userUploads', this.fetchImages);
            this.editor.registerCallback('image', this.handleImageUpload);
            this.editor.registerCallback('image:removed', this.handleImageDeleted);
        },

        async fetchBlocks (params, done) {
            try {
                const { data: { templateBlocks } } = await this.$graphql.query({
                    query: gql`
                            query templateBlocks {
                                templateBlocks {
                                    blocks {
                                        id
                                        json
                                    }
                                }
                            }
                        `,
                    fetchPolicy: 'no-cache',
                });

                this.blocks = templateBlocks.blocks.map(({ id, json }) => ({ ...json, id }));
            } catch (error) {
                this.$toast({ message: this.$t('blocks.fetchError') });
            }
            done(this.blocks);
        },

        async handleBlockAdded(block, done) {
            try {
                const { data: { id, json } } = await this.$graphql.mutate({
                    mutation: gql`
                        mutation createTemplateBlock($block: CreateTemplateBlockInput!) {
                            createTemplateBlock(block: $block) {
                                id
                                json
                            }
                        }
                    `,
                    variables: {
                        block: {
                            json: JSON.stringify(block),
                            templateSource: 'UNLAYER',
                        },
                    },
                    fetchPolicy: 'no-cache',
                });

                done({ ...json, id });
            } catch (error) {
                this.$error({ message: this.$t('blocks.saveError'), showIcon: true });
                done();
            }
        },

        async handleBlockDeleted(block, done) {
            const { data: { deleteTemplateBlock } } = await this.$graphql.mutate({
                mutation: gql`
                    mutation deleteBlock($blockId: ID!) {
                        deleteTemplateBlock(blockId: $blockId)
                    }
                `,
                variables: { blockId: block.id },
                fetchPolicy: 'no-cache',
            });

            if (!deleteTemplateBlock) {
                this.$error({ message: this.$t('blocks.deleteError'), showIcon: true });
            }

            done(block);
        },

        async handleBlockUpdated(block, done) {
            let foundBlock = this.blocks.find(({ id }) => id === block.id);

            if (foundBlock == null) {
                this.$error({ message: this.$t('blocks.updateError'), showIcon: true });
                done();

                return;
            }

            foundBlock = {
                ...foundBlock,
                category: block.category,
                tags: block.tags,
            };

            try {
                await this.$graphql.mutate({
                    mutation: gql`
                        mutation updateBlockTemplate($block: UpdateTemplateBlockInput!) {
                            updateTemplateBlock(block: $block) {
                                id
                                json
                            }
                        }
                    `,
                    variables: {
                        block: {
                            id: block.id,
                            json: JSON.stringify(foundBlock),
                            templateSource: 'UNLAYER',
                        },
                    },
                    fetchPolicy: 'no-cache',
                });
                done(foundBlock);
            } catch (error) {
                this.$error({ message: this.$t('blocks.updateError'), showIcon: true });
                done();
            }
        },

        handleDesignUpdate() {
            this.editor.exportHtml((data) => this.$emit('design-updated', data));
        },

        async fetchImages(params, done) {
            const { data: { userUploadedImages } } = await this.$graphql.query({
                query: gql`
                        query userUploadedImages($pageToken: String, $pageSize: Int) {
                            userUploadedImages(pageToken: $pageToken, pageSize: $pageSize) {
                                nextPageToken
                                total
                                images {
                                    id
                                    location
                                    height
                                    width
                                    contentType
                                    size
                                }
                            }
                        }
                    `,
                variables: {
                    pageToken: params.page > 1 ? this.userImagesPageToken : null,
                    pageSize: IMAGES_PAGE_SIZE,
                },
                fetchPolicy: 'no-cache',
            });

            const images = userUploadedImages.images.map((image) => ({
                ...image,
                source: 'user',
                id: parseInt(image.id, 10),
            }));

            this.userImagesPageToken = userUploadedImages.nextPageToken;

            done(images, {
                hasMore: this.userImagesPageToken != null,
                page: params.page,
                perPage: IMAGES_PAGE_SIZE,
                total: userUploadedImages.total,
            });
        },

        async handleImageUpload({ attachments }, done) {
            const [file] = attachments;
            const formData = new FormData();

            formData.append('file', file);
            const { data } = await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/images`, formData);

            done({ progress: 100, url: data.location });

            // TODO: Need design work on how to handle file upload errors
            // TODO: Sometimes filebox will delete duplicate images, need to figure out how to best
            // present that feedback to the user
        },

        async handleImageDeleted(image, done) {
            const { data: { deleteUserUploadedImage } } = await this.$graphql.mutate({
                mutation: gql`
                    mutation deleteUserUploadedImage($imageId: ID!) {
                        deleteUserUploadedImage(imageId: $imageId)
                    }
                `,
                variables: { imageId: image.id },
                fetchPolicy: 'no-cache',
            });

            if (deleteUserUploadedImage) {
                done();
            }

            // TODO: Need designs for sad path/error handling
        },
    },
};
</script>

<style lang="scss" scoped>
    .unlayer-container {
        display: flex;
        height: 100%;
    }

    .unlayer-editor {
        flex: 1;
        display: flex;
    }
</style>

<i18n>
{
    "en-us": {
        "blocks": {
            "fetchError": "For some reason your blocks didn’t load. Try again after refreshing the page.",
            "saveError": "For some reason your block didn’t save. Try saving again.",
            "deleteError": "For some reason your block didn’t get deleted. Try deleting it again.",
            "updateError": "For some reason the changes to your block didn’t update. Try making them again."
        }
    }
}
</i18n>
