import { BLENDR_TYPES } from '@/shared/constants/integrations.constants';

// Triggers
export const ANY_PUBLIC_FORM_SUBMITTED = 'LEAD_CAPTURE_FORM_SUBMITTED';
export const PUBLIC_FORM_SUBMITTED = 'PUBLIC_FORM_SUBMITTED';
export const ANY_INTERNAL_FORM_SUBMITTED = 'ANY_INTERNAL_FORM_SUBMITTED';
export const INTERNAL_FORM_SUBMITTED = 'INTERNAL_FORM_SUBMITTED';
export const ANY_LANDING_PAGE_FORM_SUBMITTED = 'ANY_LANDING_PAGE_FORM_SUBMITTED';
export const LANDING_PAGE_FORM_SUBMITTED = 'LANDING_PAGE_FORM_SUBMITTED';
export const TAG_APPLIED = 'TAG_APPLIED';
export const PRODUCT_PURCHASED = 'PRODUCT_PURCHASED';
export const ANY_PRODUCT_PURCHASED = 'ANY_PRODUCT_PURCHASED';
export const APPOINTMENT_SCHEDULED = 'APPOINTMENT_SCHEDULED';
export const ANY_APPOINTMENT_SCHEDULED = 'ANY_APPOINTMENT_SCHEDULED';
export const APPOINTMENT_CANCELLED = 'APPOINTMENT_CANCELLED';
export const ANY_APPOINTMENT_CANCELLED = 'ANY_APPOINTMENT_CANCELLED';
export const USER_APPOINTMENT_SCHEDULED = 'USER_APPOINTMENT_SCHEDULED';
export const USER_APPOINTMENT_CANCELLED = 'USER_APPOINTMENT_CANCELLED';
export const ANY_INVOICE_SENT = 'ANY_INVOICE_SENT';
export const ANY_INVOICE_PAID = 'ANY_INVOICE_PAID';
export const ANY_QUOTE_SENT = 'ANY_QUOTE_SENT';
export const ANY_QUOTE_ACCEPTED = 'ANY_QUOTE_ACCEPTED';
export const ANY_HELLOSIGN_DOCUMENT_SIGNED = 'HELLOSIGN_ANY_DOCUMENT_SIGNED';
export const HELLOSIGN_DOCUMENT_SIGNED = 'HELLOSIGN_DOCUMENT_SIGNED';
export const TYPEFORM_ANY_FORM_SUBMITTED = 'TYPEFORM_ANY_FORM_SUBMITTED';
export const TYPEFORM_FORM_SUBMITTED = 'TYPEFORM_FORM_SUBMITTED';
export const SHOPIFY_ANY_PRODUCT_PURCHASED = 'SHOPIFY_ANY_PRODUCT_PURCHASED';
export const SHOPIFY_PRODUCT_PURCHASED = 'SHOPIFY_PRODUCT_PURCHASED';
export const SHOPIFY_ANY_CART_ABANDONED = 'SHOPIFY_ANY_CART_ABANDONED';
export const SHOPIFY_CART_ABANDONED = 'SHOPIFY_CART_ABANDONED';
export const DEAL_ENTERED = 'DEAL_ENTERED';
export const DEAL_EXITED = 'DEAL_EXITED';

export const TRIGGER_ACTION_TYPES = {
    ADD: 'ADD',
    REMOVE: 'REMOVE',
};

// Steps
export const SEND_EMAIL = 'SEND_EMAIL';
export const DELAY_TIMER = 'DELAY_TIMER';
export const RELATIVE_TIMER = 'RELATIVE_TIMER';
export const CREATE_DEAL = 'CREATE_DEAL';
export const CREATE_TASK = 'CREATE_TASK';
export const APPLY_TAG = 'APPLY_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const SEND_SMS = 'SEND_SMS';
export const HELLOSIGN_SEND_DOCUMENT = 'HELLOSIGN_SEND_DOCUMENT';

// Editing 'phases' for Triggers or Steps
export const TEMPLATE_STEP = 'TEMPLATE_STEP';
export const EDITOR_STEP = 'EDITOR_STEP';
export const TIMING_STEP = 'TIMING_STEP';

export const TIMER_STEPS = [
    DELAY_TIMER,
    RELATIVE_TIMER,
];

export const APPOINTMENT_TRIGGERS = [
    APPOINTMENT_SCHEDULED,
    USER_APPOINTMENT_SCHEDULED,
    ANY_APPOINTMENT_SCHEDULED,
    APPOINTMENT_CANCELLED,
    USER_APPOINTMENT_CANCELLED,
    ANY_APPOINTMENT_CANCELLED,
];

export const TIMING_UNIT = {
    MINUTES: 'MINUTES',
    HOURS: 'HOURS',
    DAYS: 'DAYS',
    WEEKS: 'WEEKS',
    MONTHS: 'MONTHS',
};

export const ERROR_LEVELS = {
    ERROR: 'ERROR',
    WARNING: 'WARNING',
};

export const MAXLENGTH = {
    NOTIFICATION_TITLE: 90,
    NOTIFICATION_PUSH_TITLE: 65,
    NAME: 200,
    DESCRIPTION: 2000,
    EMAIL_BODY: 500000,
    TEMPLATE: { NAME: 30, DESCRIPTION: 200 },
};

export const MAX_STEPS = 104;
export const MAX_TRIGGERS = 50;

export const SAVE_STATUS = {
    NONE: 'none',
    FAILED: 'failed',
    SUCCESS: 'success',
    SAVING: 'saving',
};

export const AUTOMATION_STATUS = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED',
};

export const CONTEXT_TYPE = {
    UNKNOWN: 'UNKNOWN',
    PIPELINE: 'PIPELINE',
    PUBLIC_FORMS: 'PUBLIC_FORMS',
    INTERNAL_FORMS: 'INTERNAL_FORMS',
    APPOINTMENTS: 'APPOINTMENTS',
};

export const OPEN_COMPONENT = {
    TRIGGER: 'TRIGGER',
    STEP: 'STEP',
    REMOVE_TRIGGER: 'REMOVE_TRIGGER',
};

export const PARTICIPANT_TYPE = {
    DEAL: 'DEAL',
};

export const SUGGESTED_MAX = 4;
export const NOT_SHOWN = 6;

export const TRIGGERS = {
    [ANY_APPOINTMENT_CANCELLED]: {
        type: ANY_APPOINTMENT_CANCELLED,
        name: 'automation.triggers.anyAppointmentCancelled.title',
        description: 'automation.triggers.anyAppointmentCancelled.description',
    },
    [APPOINTMENT_SCHEDULED]: {
        type: APPOINTMENT_SCHEDULED,
        name: 'automation.triggers.appointmentScheduled.title',
        description: 'automation.triggers.appointmentScheduled.description',
        image: 'appointment-scheduled.svg',
        order: 2,
        contextType: CONTEXT_TYPE.APPOINTMENTS,
    },
    [APPOINTMENT_CANCELLED]: {
        type: APPOINTMENT_CANCELLED,
        name: 'automation.triggers.appointmentCancelled.title',
        description: 'automation.triggers.appointmentCancelled.description',
        image: 'appointment-cancelled.svg',
        order: NOT_SHOWN,
        contextType: CONTEXT_TYPE.APPOINTMENTS,
    },
    [PUBLIC_FORM_SUBMITTED]: {
        type: PUBLIC_FORM_SUBMITTED,
        name: 'automation.triggers.publicFormSubmitted.title',
        description: 'automation.triggers.publicFormSubmitted.description',
        image: 'public-form-submitted.svg',
        contextType: CONTEXT_TYPE.PUBLIC_FORMS,
        order: 1,
    },
    [LANDING_PAGE_FORM_SUBMITTED]: {
        type: LANDING_PAGE_FORM_SUBMITTED,
        name: 'automation.triggers.landingPageFormSubmitted.title',
        description: 'automation.triggers.landingPageFormSubmitted.description',
        image: 'landing-page-form-submitted.svg',
        order: NOT_SHOWN,
    },
    [PRODUCT_PURCHASED]: {
        type: PRODUCT_PURCHASED,
        name: 'automation.triggers.productPurchased.title',
        description: 'automation.triggers.productPurchased.description',
        image: 'product-purchased.svg',
        order: 3,
    },
    [TAG_APPLIED]: {
        type: TAG_APPLIED,
        name: 'automation.triggers.tagApplied.title',
        description: 'automation.triggers.tagApplied.description',
        image: 'tag-applied.svg',
        order: 4,
    },
    [INTERNAL_FORM_SUBMITTED]: {
        type: INTERNAL_FORM_SUBMITTED,
        name: 'automation.triggers.internalFormSubmitted.title',
        description: 'automation.triggers.internalFormSubmitted.description',
        image: 'form-submitted.svg',
        contextType: CONTEXT_TYPE.INTERNAL_FORMS,
        order: NOT_SHOWN,
    },
    [ANY_INVOICE_SENT]: {
        type: ANY_INVOICE_SENT,
        name: 'automation.triggers.invoiceSent.title',
        description: 'automation.triggers.invoiceSent.description',
        image: 'invoice-sent.svg',
        order: NOT_SHOWN,
    },
    [ANY_INVOICE_PAID]: {
        type: ANY_INVOICE_PAID,
        name: 'automation.triggers.invoicePaid.title',
        description: 'automation.triggers.invoicePaid.description',
        image: 'invoice-paid.svg',
        order: NOT_SHOWN,
    },
    [ANY_QUOTE_SENT]: {
        type: ANY_QUOTE_SENT,
        name: 'automation.triggers.quoteSent.title',
        description: 'automation.triggers.quoteSent.description',
        image: 'quote-sent.svg',
        order: NOT_SHOWN,
    },
    [ANY_QUOTE_ACCEPTED]: {
        type: ANY_QUOTE_ACCEPTED,
        name: 'automation.triggers.quoteAccepted.title',
        description: 'automation.triggers.quoteAccepted.description',
        image: 'quote-accepted.svg',
        order: NOT_SHOWN,
    },
    [HELLOSIGN_DOCUMENT_SIGNED]: {
        type: HELLOSIGN_DOCUMENT_SIGNED,
        name: 'automation.triggers.hellosignDocumentSigned.title',
        description: 'automation.triggers.hellosignDocumentSigned.description',
        image: 'hellosign-document-signed.svg',
        order: NOT_SHOWN,
    },
    [SHOPIFY_ANY_PRODUCT_PURCHASED]: {
        type: SHOPIFY_ANY_PRODUCT_PURCHASED,
        name: 'automation.triggers.shopifyAnyProductPurchased.title',
        description: 'automation.triggers.shopifyAnyProductPurchased.description',
        image: 'shopify-product-purchased.svg',
        order: NOT_SHOWN,
    },
    [SHOPIFY_ANY_CART_ABANDONED]: {
        type: SHOPIFY_ANY_CART_ABANDONED,
        name: 'automation.triggers.shopifyAnyCartAbandoned.title',
        description: 'automation.triggers.shopifyAnyCartAbandoned.description',
        image: 'shopify-product-purchased.svg',
        order: NOT_SHOWN,
    },
    [DEAL_ENTERED]: {
        type: DEAL_ENTERED,
        name: 'automation.triggers.dealEntered.title',
        description: 'automation.triggers.dealEntered.description',
        image: 'deal-entered.svg',
        order: NOT_SHOWN,
        contextType: CONTEXT_TYPE.PIPELINE,
        participantType: PARTICIPANT_TYPE.DEAL,
    },
    [DEAL_EXITED]: {
        type: DEAL_EXITED,
        name: 'automation.triggers.dealExited.title',
        description: 'automation.triggers.dealExited.description',
        image: 'deal-exited.svg',
        order: NOT_SHOWN,
        contextType: CONTEXT_TYPE.PIPELINE,
        participantType: PARTICIPANT_TYPE.DEAL,
    },
    [TYPEFORM_FORM_SUBMITTED]: {
        type: TYPEFORM_FORM_SUBMITTED,
        name: 'automation.triggers.typeform.formSubmitted.title',
        description: 'automation.triggers.typeform.formSubmitted.description',
        image: 'integrations/typeform.svg',
        order: NOT_SHOWN,
    },
};

// integrations
export const { SHOPIFY, HELLO_SIGN: HELLOSIGN, TYPEFORM } = BLENDR_TYPES;

export const INTEGRATION_TYPES = {
    [HELLOSIGN]: {
        type: HELLOSIGN,
        name: 'automation.integrations.hellosign.title',
        image: 'integrations/hellosign.svg',
        order: NOT_SHOWN,
    },
    [SHOPIFY]: {
        type: SHOPIFY,
        name: 'automation.integrations.shopify.title',
        image: 'integrations/Shopify.svg',
        order: NOT_SHOWN,
    },
    [TYPEFORM]: {
        type: TYPEFORM,
        name: 'automation.integrations.typeform.title',
        image: 'integrations/typeform.svg',
        order: NOT_SHOWN,
    },
};

export const validationMessageCodes = {
    APPLY_TAG_LOOP: 'APPLY_TAG.LOOP',
    LIQUID_SYNTAX: 'LIQUID_SYNTAX',
    PARTICIPANT_CHANGE: 'PARTICIPANT_CHANGE',
    EXACT_DATE_PASSED: 'EXACT_DATE_PASSED',
    STOP_TRIGGER_SAME_AS_TRIGGER: 'STOP_TRIGGER.SAME_AS_TRIGGER',
    STOP_TRIGGER_MISSING_TIMER: 'STOP_TRIGGER.MISSING_TIMER',
    STOP_TRIGGER_INVALID: 'STOP_TRIGGER.STOP_TRIGGER_INVALID',
    SMS_STEP_MISSING_SMS_ACCOUNT: 'SMS_STEP.MISSING_SMS_ACCOUNT',
    HELLOSIGN_NOT_CONNECTED: 'HELLOSIGN_NOT_CONNECTED',
    SHOPIFY_NOT_CONNECTED: 'SHOPIFY_NOT_CONNECTED',
    SHOPIFY_MISSING_FEATURE: 'SHOPIFY_MISSING_FEATURE',
    TYPEFORM_NOT_CONNECTED: 'TYPEFORM_NOT_CONNECTED',
};

export const VALIDATION_MESSAGES = {
    [validationMessageCodes.APPLY_TAG_LOOP]: {
        validationMessage: 'automation.errors.applyTagLoop.banner',
        configureMessage: 'automation.errors.applyTagLoop.config',
    },
    [validationMessageCodes.LIQUID_SYNTAX]: {
        validationMessage: 'automation.errors.liquidSyntax.banner',
        configureMessage: 'automation.errors.liquidSyntax.config',
    },
    [validationMessageCodes.PARTICIPANT_CHANGE]: {
        validationMessage: 'automation.errors.participantChange.banner',
    },
    [validationMessageCodes.EXACT_DATE_PASSED]: {
        validationMessage: 'automation.errors.exactDatePassed.banner',
    },
    [validationMessageCodes.STOP_TRIGGER_SAME_AS_TRIGGER]: {
        validationMessage: 'automation.errors.stop.sameAsTrigger.banner',
        configureMessage: 'automation.errors.stop.sameAsTrigger.config',
        action: 'automation.errors.removeStop',
        icon: 'trash-2',
    },
    [validationMessageCodes.STOP_TRIGGER_MISSING_TIMER]: {
        validationMessage: 'automation.errors.stop.missingTimer.banner',
        configureMessage: 'automation.errors.stop.missingTimer.config',
        action: 'automation.errors.removeStop',
        icon: 'trash-2',
    },
    [validationMessageCodes.STOP_TRIGGER_INVALID]: {
        validationMessage: 'automation.errors.stop.invalid.banner',
        configureMessage: 'automation.errors.stop.invalid.config',
        action: 'automation.errors.removeStop',
        icon: 'trash-2',
    },
    [validationMessageCodes.SMS_STEP_MISSING_SMS_ACCOUNT]: {
        validationMessage: 'automation.errors.smsStep.missingSmsAccount.banner',
        configureMessage: 'automation.errors.smsStep.missingSmsAccount.config',
        action: 'automation.errors.smsStep.missingSmsAccount.action',
        icon: 'settings',
    },
    [validationMessageCodes.HELLOSIGN_NOT_CONNECTED]: {
        validationMessage: 'automation.errors.hellosign.missingHellosignAccount.banner',
        configureMessage: 'automation.errors.hellosign.missingHellosignAccount.config',
        action: 'automation.errors.hellosign.missingHellosignAccount.action',
        icon: 'external-link',
    },
    [validationMessageCodes.SHOPIFY_NOT_CONNECTED]: {
        validationMessage: 'automation.errors.shopify.missingShopifyAccount.banner',
        configureMessage: 'automation.errors.shopify.missingShopifyAccount.config',
        action: 'automation.errors.shopify.missingShopifyAccount.action',
        icon: 'external-link',
    },
    [validationMessageCodes.SHOPIFY_MISSING_FEATURE]: {
        validationMessage: 'automation.errors.shopify.missingShopifyFeature.banner',
        configureMessage: 'automation.errors.shopify.missingShopifyFeature.config',
        action: 'automation.errors.shopify.missingShopifyFeature.action',
        icon: 'external-link',
    },
    [validationMessageCodes.TYPEFORM_NOT_CONNECTED]: {
        validationMessage: 'automation.errors.typeform.missingTypeformAccount.banner',
        configureMessage: 'automation.errors.typeform.missingTypeformAccount.config',
        action: 'automation.errors.typeform.missingTypeformAccount.action',
        icon: 'external-link',
    },
};

export const INSTALL_AUTOMATIONS = {
    NEW_LEAD_FOLLOW_UP_APPOINTMENT_INVITE: {
        integration: 'VJG4gCzCzfA7j7K3zHMs',
        staging: 'NXbFf7wSewkD6BFihWYH',
        production: 'zmrBzjMv5Z1ioV3iFkrn',
    },

    NEW_LEAD_INTRODUCTION: {
        integration: 'l3kNM7kbljXeIHkW0CLv',
        staging: 'uanTme69Qg45B2QK8ZzJ',
        production: '1f6Dg5goJXR5geEnoVGk',
    },

    NEW_LEAD_NURTURE: {
        integration: 'EuVh40Zy8eF1hZ5Bv0gp',
        staging: 'XZeNKWCjsRjJUCeH0d0f',
        production: 'prTmETvQd9VZTs7rh6FT',
    },

    SCHEDULE_CONSULTATION_NEW_LEAD: {
        integration: 'ii7qdd3JPSu1UKV9wwRJ',
        staging: 'shHpTG4YuoMnw7PMAsqO',
        production: '7OEujZS1jy9kvxpo1pNz',
    },

    PURCHASED_PRODUCT_GET_REVIEWS: {
        integration: '6Tgz8HwTz40kF34r27ou',
        staging: 'hly8hMB5zEfZitqvSPM7',
        production: 'SYBhV51tmibqWIkUoZI9',
    },
};
