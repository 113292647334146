import { CALL_DEVICE_STATUSES } from '../constants/calls.constants';

export default {
    call: {
        accessTokenExpiration: 0,
        activeCall: null,
        callStatus: null,
        device: null,
        deviceStatus: CALL_DEVICE_STATUSES.DESTROYED,
        duration: 0,
        durationIntervalId: null,
        contact: {
            id: null,
            firstName: '',
            lastName: '',
            phoneNumber: '',
        },
        isMuted: false,
        mic: {
            activeDevice: null,
            devices: [],
            volume: 0,
        },
        speaker: {
            activeDevice: null,
            devices: [],
            volume: 0,
        },
    },
    sms: {
        conversations: {},
        drafts: {},
    },
};
