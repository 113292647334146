export const normalizeContacts = (contacts) => {
    // To maintain backwards compatability with SPA's implementation, only return Work emails in the list
    //    Work email -> emailAddresses[0]
    //    Personal email -> emailAddresses[1]
    //    Other email -> emailAddresses[2]
    contacts.filter((contact) => contact.email == null)
        .forEach((contact) => {
            contact.email = contact.emailAddresses != null && contact.emailAddresses[0] != null
                ? contact.emailAddresses[0].value
                : null;
        });
};

export const mergeContactLists = (existingContacts, newContacts) => {
    return existingContacts.concat(newContacts).reduce((map, contact) => map.set(
        contact.id,
        Object.assign(map.get(contact.id) || {}, contact),
    ), new Map()).values();
};
