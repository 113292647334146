import amplitude from 'amplitude-js';
import sentry from './sentry';
import { deprecated } from '@/shared/utils/deprecations';
import * as events from './constants/amplitude-events.constants';

export default {
    v1: {
        init() {
            if (process.env.VUE_APP_AMPLITUDE_V1_KEY && process.env.VUE_APP_LOGGING !== 'disabled') {
                amplitude.getInstance().init(process.env.VUE_APP_AMPLITUDE_V1_KEY);
            }
        },

        track(name, data = null) {
            deprecated('amplitude.v1.track has been deprecated. Please use amplitude.v2.logEvent to track events in the new Amplitude project.');

            try {
                if (process.env.VUE_APP_AMPLITUDE_V1_KEY && process.env.VUE_APP_LOGGING !== 'disabled') {
                    amplitude.getInstance().logEvent(name, data);
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },

        setUserProperties(props) {
            deprecated('amplitude.v1.setUserProperties has been deprecated. Please use amplitude.v2.setUserProperties to track user properties in the new Amplitude project.');

            try {
                if (process.env.VUE_APP_AMPLITUDE_V1_KEY && process.env.VUE_APP_LOGGING !== 'disabled') {
                    amplitude.getInstance().setUserProperties(props);
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },
    },

    v2: {
        events,

        init() {
            if (process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME
                && process.env.VUE_APP_AMPLITUDE_V2_KEY
                && process.env.VUE_APP_LOGGING !== 'disabled') {
                amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).init(process.env.VUE_APP_AMPLITUDE_V2_KEY);
            }
        },

        setup({
            appName,
            companyName,
            editionName,
            isPaid,
            isPartner,
            isReferredByPartner,
            domain,
            featureFlags = [],
        }) {
            try {
                if (process.env.VUE_APP_LOGGING !== 'disabled') {
                    const amplitudeInstance = amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME);

                    amplitudeInstance.setUserId(appName);

                    // If editionName is not set, it probably means none of these values are reliable (like on a public page)
                    if (editionName) {
                        amplitudeInstance.setUserProperties({
                            Domain: domain,
                            'Company Name': companyName,
                            'Edition Name': editionName,
                            'Is Paid': isPaid,
                            'Is Partner': isPartner,
                            'Is Referred by Partner': isReferredByPartner,
                            'Feature Flags': [],
                        });

                        // Feature flags must be appended instead of sending in initial object above.
                        // Sending the entire array of flags creates an input that is too long for Amplitude to accept.
                        // See comment in appendToUserProperty method.

                        featureFlags.forEach((flag) => {
                            this.appendToUserProperty('Feature Flags', flag);
                        });
                    }
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },

        setUserId(userId) {
            try {
                if (process.env.VUE_APP_LOGGING !== 'disabled') {
                    amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).setUserId(userId);
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },

        setUserProperties(props) {
            try {
                if (process.env.VUE_APP_LOGGING !== 'disabled') {
                    amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).setUserProperties(props);
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },

        logEvent(name, data = null) {
            if (process.env.VUE_APP_LOGGING === 'disabled') {
                return;
            }

            if (!Object.values(events).includes(name)) {
                if (process.env.NODE_ENV !== 'production') {
                    // eslint-disable-next-line no-console
                    console.warn(`"${name}" is not a valid Amplitude Event. Please ensure that all events being sent to Amplitude are approved by your leader, listed in the amplitude-events.constants.js file, and added to the production Amplitude schema.`);
                }

                return;
            }

            try {
                amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).logEvent(name, data);
            } catch (e) {
                sentry.captureException(e);
            }
        },

        incrementUserProp(propName, count) {
            try {
                if (process.env.VUE_APP_LOGGING !== 'disabled') {
                    const identify = new amplitude.Identify();

                    identify.add(propName, count);

                    amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).identify(identify);
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },

        setOnce(propName, value) {
            try {
                if (process.env.VUE_APP_LOGGING !== 'disabled') {
                    const identify = new amplitude.Identify();

                    identify.setOnce(propName, value);

                    amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).identify(identify);
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },

        appendToUserProperty(propName, value) {
            // There is a character limit of 1024 for all string values (User ID, event or user property values, etc.).
            // Arrays have a limit of 10000 characters when append/prepend is used (available for user property types only).
            // Any string that exceeds this limit will be truncated.
            // If you try to append or set more than 1024 characters at once it will get truncated, but if you append/prepend them individually (1024 max each time) you can go up to 10k.
            // See feature flags above for an example use case.

            try {
                if (process.env.VUE_APP_LOGGING !== 'disabled') {
                    const identify = new amplitude.Identify();

                    identify.append(propName, value);

                    amplitude.getInstance(process.env.VUE_APP_AMPLITUDE_V2_INSTANCE_NAME).identify(identify);
                }
            } catch (e) {
                sentry.captureException(e);
            }
        },
    },
};
