var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filteredPhoneList.length || _vm.filteredEmailList.length || _vm.filteredFaxList.length)?_c('section',{staticClass:"contact-phone-email"},[_c('h4',{staticClass:"section-title section-margin"},[_vm._v(" "+_vm._s(_vm.$t('contactDetails.sectionHeaders.phoneEmailFax'))+" ")]),(_vm.filteredPhoneList.length)?_c('div',{staticClass:"field-grid phone-fields"},_vm._l((_vm.filteredPhoneList),function(ref){
var id = ref.id;
var phone = ref.phone;
var status = ref.status;
return _c('div',{key:id},[_c('span',{staticClass:"view-field",attrs:{"data-qa":("phone-" + id)}},[(phone.type)?_c('small',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(("global.phones." + (phone.type.toLowerCase()))))+" "+_vm._s(_vm.$t('forms.phone').toLowerCase())+" ")]):_vm._e(),_c('a',{staticClass:"fs-block",attrs:{"href":("tel:" + (_vm.formatPhoneTelLink(phone.value))),"data-qa":("phone-link-" + id)}},[_vm._v(" "+_vm._s(_vm.formatPhone(phone.value))+" ")])]),(_vm.showPhoneOptStatus)?_c('contact-phone-opt-status',{attrs:{"status":status}}):_vm._e()],1)}),0):_vm._e(),(_vm.filteredEmailList.length)?_c('div',{staticClass:"field-grid email-fields"},_vm._l((_vm.filteredEmailList),function(emailAddress){return _c('div',{key:emailAddress.fieldName},[_c('span',{staticClass:"view-field",attrs:{"data-qa":("" + (emailAddress.fieldName))}},[_c('small',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(emailAddress.label))+" ")]),_c('a',{staticClass:"fs-block",attrs:{"href":("mailto:" + (emailAddress.email))},on:{"click":function($event){$event.preventDefault();return _vm.sendEmail(emailAddress.email)}}},[_vm._v(" "+_vm._s(emailAddress.email)+" ")])]),(Object.keys(_vm.contactEmailMarketability).length)?_c('contact-email-marketability',{attrs:{"editable":"","email-marketability":_vm.contactEmailMarketability[emailAddress.fieldName],"email-field":emailAddress.fieldName}}):_vm._e()],1)}),0):_vm._e(),(_vm.filteredFaxList.length)?_c('div',{staticClass:"field-grid fax-fields"},_vm._l((_vm.filteredFaxList),function(ref){
var id = ref.id;
var fax = ref.fax;
return _c('div',{key:id},[_c('span',{staticClass:"view-field",attrs:{"data-qa":("fax-" + id)}},[(fax.type)?_c('small',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.$t(("global.phones." + (fax.type.toLowerCase()))))+" "+_vm._s(_vm.$t('forms.fax').toLowerCase())+" ")]):_vm._e(),_c('a',{staticClass:"fs-block",attrs:{"href":("tel:" + (_vm.formatPhoneTelLink(fax.value))),"data-qa":("fax-link-" + id)}},[_vm._v(" "+_vm._s(_vm.formatPhone(fax.value))+" ")])])])}),0):_vm._e(),(_vm.email)?_c('send-email-modal',{attrs:{"contact":_vm.contact,"email":_vm.email},on:{"close":function($event){_vm.email = null}}}):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }