<template>
    <div class="notification-step configure-editor">
        <transition name="fade">
            <div v-if="showEditor" class="notification-form">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-header-text">{{ $t('editContent') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="notification-editor-next" @click="handleNext">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <form class="form">
                    <ds-inline-alert
                        v-if="showErrorBanner"
                        class="short-error-banner notification-form-input"
                        type="critical"
                        leading-icon
                    >
                        {{ $t('error.banner') }}
                    </ds-inline-alert>

                    <p class="notification-instructions">
                        {{ $t('notificationSubtitle') }}
                    </p>

                    <ds-multiselect
                        v-model="sendTo"
                        :placeholder="$t('notification.recipient')"
                        data-qa="send-to-dropdown"
                        class="send-to"
                        bind-value-only
                        searchable
                        multiple
                        required
                        :submitted="showSendToError"
                        name="sendTo"
                        :options="sendToOptions"
                        @input="setSendToSelection"
                    />

                    <div class="checkbox-wrapper">
                        <ds-checkbox
                            v-model="notification.sendInApp"
                            class="send-checkbox"
                            data-qa="send-in-app-checkbox"
                            :label="$t('sendDesktop')"
                            :error="showSendMethodError"
                            @input="handleEdit"
                        />
                    </div>

                    <div
                        class="checkbox-wrapper"
                    >
                        <ds-checkbox
                            v-model="notification.sendMobile"
                            class="send-checkbox"
                            data-qa="send-mobile-checkbox"
                            :label="$t('sendMobile')"
                            :error="showSendMethodError"
                            @input="handleEdit"
                        />
                    </div>

                    <div class="checkbox-wrapper">
                        <ds-checkbox
                            v-model="notification.sendEmail"
                            class="send-checkbox"
                            data-qa="send-email-checkbox"
                            :label="$t('sendEmail')"
                            :error="showSendMethodError"
                            @input="handleSendEmailEdit"
                        />
                    </div>

                    <ds-inline-alert
                        v-if="showSendMethodError"
                        class="short-error-banner send-method-error notification-form-input"
                        type="critical"
                        leading-icon
                    >
                        {{ $t('error.sendMethodError') }}
                    </ds-inline-alert>

                    <mergeable-input
                        v-model="notification.contentTitle"
                        data-qa="notification-title"
                        :class="['notification-title notification-form-input', { 'help-text-warning': titleTooLong }]"
                        type="text"
                        name="title"
                        required
                        autofocus
                        use-merge-service
                        :submitted="showContentTitleError"
                        :maxlength="titleMaxLength"
                        :label="$t('notification.title')"
                        :help-text="titleHelpText"
                        :additional-merge-fields="additionalMergeFields"
                        @input="handleEdit"
                    />

                    <mergeable-input
                        v-if="notification.sendInApp || notification.sendMobile"
                        v-model="notification.contentText"
                        :class="['notification-form-input', { 'help-text-warning': textTooLong }]"
                        data-qa="notification-content"
                        type="text"
                        name="content"
                        required
                        autofocus
                        use-merge-service
                        :submitted="showContentTextError"
                        :maxlength="maxLength.NOTIFICATION_TITLE"
                        :help-text="textHelpText"
                        :label="$t('notification.content')"
                        :additional-merge-fields="additionalMergeFields"
                        @input="handleEdit"
                    />


                    <template v-if="notification.sendEmail">
                        <label for="emailContent" class="required">
                            {{ $t('notification.emailContent') }}
                        </label>

                        <quill-editor
                            ref="quillEditor"
                            class="quill-editor-wrapper"
                            mergeable
                            use-merge-service
                            :formats="$options.DEFAULT_FORMATS"
                            :submitted="showEmailError"
                            :additional-merge-fields="additionalMergeFields"
                            @input="handleContentEdit"
                        />
                    </template>
                </form>
            </div>

            <div v-if="showTimingStep" class="timing-editor">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editOptions') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="step-done" @click="handleDone(true)">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="timing-body">
                    <timing-step
                        v-if="timingRefactorEnabled"
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ stepName }}
                    </timing-step>

                    <configure-timing-step
                        v-else
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ stepName }}
                    </configure-timing-step>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import {
    SEND_NOTIFICATION, EDITOR_STEP, TIMING_STEP, MAXLENGTH,
} from '@/automations/constants/automations.constants';
import { validate } from '@/automations/utils/automations.utils';
import amplitude from '@/analytics/amplitude';

import ConfigureTimingStep from '@/automations/components/configure/ConfigureTimingStep';
import SaveStatus from '@/automations/components/SaveStatus';
import MergeableInput from '@/shared/components/MergeField/MergeableInput';
import QuillEditor, { DEFAULT_FORMATS } from '@/shared/components/Text/QuillEditor';
import TimingStep from '@/automations/components/steps/TimingStep';
import { FF_SIMPLE_AUTOMATION_TIMING_REFACTOR } from '@/shared/constants/featureFlag.constants';

const SEND_TO_ALL_USERS = 'ALL_USERS';
const SEND_TO_CONTACT_OWNER = 'CONTACT_OWNER';

export default {
    components: {
        ConfigureTimingStep,
        MergeableInput,
        SaveStatus,
        QuillEditor,
        TimingStep,
    },

    DEFAULT_FORMATS,

    props: {
        saveStatus: String,
        showErrors: Boolean,

        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            currentStep: EDITOR_STEP,
            timingDelay: this.automationProperties.delayStep || null,
            notification: {
                contentTitle: '',
                contentText: '',
                sendEmail: false,
                sendInApp: true,
                sendMobile: false,
                emailContent: '',
                sendToUserIds: [],
                sendToContactOwner: false,
                sendToAllUsers: false,
            },
            submitted: false,
            sendTo: [],
        };
    },

    created() {
        this.init();
    },

    watch: {
        'automationProperties.delayStep': {
            handler(value) {
                this.timingDelay = value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            timingRefactorEnabled: ({ featureFlags }) => featureFlags[FF_SIMPLE_AUTOMATION_TIMING_REFACTOR],
            users: ({ auth }) => auth.users,
        }),

        ...mapGetters({
            additionalMergeFields: 'automations/additionalMergeFields',
        }),

        showEditor() {
            return this.currentStep === EDITOR_STEP;
        },

        showTimingStep() {
            return this.currentStep === TIMING_STEP;
        },

        maxLength() {
            return MAXLENGTH;
        },

        titleLength() {
            return this.notification?.contentTitle?.length ?? 0;
        },

        titleMaxLength() {
            const { sendMobile } = this.notification;
            const { NOTIFICATION_PUSH_TITLE, NOTIFICATION_TITLE } = this.maxLength;

            return sendMobile ? NOTIFICATION_PUSH_TITLE : NOTIFICATION_TITLE;
        },

        titleTooLong() {
            return this.titleLength > this.titleMaxLength;
        },

        titleHelpText() {
            const tooLong = this.$t('tooLongWarning');

            return this.titleTooLong
                ? `${this.titleLength}/${this.titleMaxLength} ${tooLong}`
                : `${this.titleLength}/${this.titleMaxLength}`;
        },

        textLength() {
            return this.notification?.contentText?.length ?? 0;
        },

        textTooLong() {
            return this.textLength > this.maxLength.NOTIFICATION_TITLE;
        },

        textHelpText() {
            const tooLong = this.$t('tooLongWarning');

            return this.textTooLong
                ? `${this.textLength}/${this.maxLength.NOTIFICATION_TITLE} ${tooLong}`
                : `${this.textLength}/${this.maxLength.NOTIFICATION_TITLE}`;
        },

        isNotificationValid() {
            return validate[SEND_NOTIFICATION].isValid(this.notificationStep);
        },

        showSendToError() {
            return (this.submitted || this.showErrors) && !validate[SEND_NOTIFICATION].sendTo(this.notificationStep);
        },

        showContentTitleError() {
            return (this.submitted || this.showErrors) && !validate[SEND_NOTIFICATION].contentTitle(this.notificationStep);
        },

        showContentTextError() {
            return (this.submitted || this.showErrors) && !validate[SEND_NOTIFICATION].contentText(this.notificationStep);
        },

        showEmailError() {
            return (this.submitted || this.showErrors) && !validate[SEND_NOTIFICATION].emailContent(this.notificationStep);
        },

        showSendMethodError() {
            return (this.submitted || this.showErrors) && !validate[SEND_NOTIFICATION].sendMethod(this.notificationStep);
        },

        showErrorBanner() {
            return this.showErrors && !this.isNotificationValid;
        },

        sendToOptions() {
            const options = [
                { value: SEND_TO_ALL_USERS, label: this.$t('allUsers') },
                { value: SEND_TO_CONTACT_OWNER, label: this.$t('contactOwner') },
            ];

            const userOptions = this.users.map((user) => {
                return {
                    label: user.fullName,
                    value: +user.id,
                };
            });

            return [...options, ...userOptions];
        },

        stepName() {
            const { contentTitle } = this.notification;

            return !contentTitle ? this.$t('stepName.default') : this.$t('stepName.hasTitle', { contentTitle });
        },

        notificationStep() {
            const {
                contentTitle,
                contentText,
                sendEmail,
                sendInApp,
                sendMobile,
                emailContent,
                sendToUserIds,
                sendToContactOwner,
                sendToAllUsers,
            } = this.notification;

            return {
                type: SEND_NOTIFICATION,
                name: this.stepName,
                configJson: {
                    contentTitle,
                    contentText,
                    sendEmail,
                    sendInApp,
                    sendMobile,
                    emailContent: sendEmail ? emailContent : '',
                    sendToUserIds,
                    sendToContactOwner,
                    sendToAllUsers,
                },
            };
        },
    },

    methods: {
        init() {
            const { initialStep, step, delayStep } = this.automationProperties;

            if (step?.configJson) {
                const {
                    contentTitle,
                    contentText,
                    sendEmail,
                    sendInApp,
                    sendMobile,
                    emailContent,
                    sendToUserIds,
                    sendToContactOwner,
                    sendToAllUsers,
                } = step.configJson;

                this.notification = {
                    contentTitle,
                    contentText,
                    sendEmail,
                    sendInApp,
                    sendMobile,
                    emailContent,
                    sendToUserIds,
                    sendToContactOwner,
                    sendToAllUsers,
                };

                this.setSendTo();
                this.setEmailContent(emailContent);

                this.timingDelay = delayStep || null;
                this.currentStep = initialStep;
            }
        },

        handleBack() {
            this.currentStep = EDITOR_STEP;
            this.setEmailContent();
        },

        handleEdit() {
            this.handleDone(false);
        },

        handleSendEmailEdit(value) {
            if (value) {
                this.setEmailContent();
            }

            this.handleEdit();
        },

        handleContentEdit(value) {
            this.notification.emailContent = value;

            this.handleEdit();
        },

        setEmailContent(content = this.notification.emailContent) {
            this.$nextTick(() => {
                this.$refs.quillEditor?.setContent(content);
            });
        },

        setSendTo() {
            const { sendToAllUsers, sendToContactOwner, sendToUserIds } = this.notification;

            if (sendToAllUsers) {
                this.sendTo.push(SEND_TO_ALL_USERS);
            }

            if (sendToContactOwner) {
                this.sendTo.push(SEND_TO_CONTACT_OWNER);
            }

            if (sendToUserIds && sendToUserIds.length) {
                this.sendTo.push(...sendToUserIds);
            }
        },

        setSendToSelection(selected) {
            this.notification = {
                ...this.notification,
                sendToAllUsers: false,
                sendToContactOwner: false,
                sendToUserIds: [],
            };

            selected.forEach((selection) => {
                if (selection === SEND_TO_ALL_USERS) {
                    this.notification.sendToAllUsers = true;
                } else if (selection === SEND_TO_CONTACT_OWNER) {
                    this.notification.sendToContactOwner = true;
                } else {
                    this.notification.sendToUserIds.push(selection);
                }
            });

            this.handleEdit();
        },

        handleDone(close = false) {
            const steps = [this.notificationStep];
            const hasDelay = this.timingDelay && Object.keys(this.timingDelay).length;

            if (hasDelay) {
                steps.unshift(this.timingDelay);
            }

            this.$emit('updated', steps);

            if (close) {
                this.$emit('close');
            }
        },

        handleNext() {
            if (this.isNotificationValid) {
                const { sendMobile, sendInApp, sendEmail } = this.notification;

                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                    'Event Source': 'Notification',
                    'Send Mobile': sendMobile,
                    'Send In App': sendInApp,
                    'Send Email': sendEmail,
                });

                this.currentStep = TIMING_STEP;
                this.submitted = false;
            } else {
                this.submitted = true;
            }
        },

        updateTiming(timingDelay) {
            this.timingDelay = timingDelay;
            this.handleEdit();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';

    .checkbox {
        --checkbox-padding: #{$gp / 2};
    }

    .send-checkbox {
        display: inline-flex;
    }

    .notification-title {
        margin-top: $gp * 2;
    }

    .help-text-warning {
        --help-text-color: #{$color-orange};
    }

    .send-method-error {
        margin: $gp 0;
    }

    .send-to {
        width: px-to-rem(350px);
    }

    .quill-editor-wrapper {
        --quill-editor-mobile-border-radius: #{0 0 $gp * .75 $gp * .75};

        margin-bottom: $gp * 1.5;
    }

    .notification-instructions {
        font-size: $font-size-med;
        margin-bottom: $gp * 1.5;
    }

    .short-error-banner {
        margin-bottom: $gp;
    }

    label.required {
        font-weight: normal;
        margin-bottom: $gp / 2;

        &:after {
            @include input-label-required;
        }
    }

    @mixin small-notification-view() {
        .send-to {
            width: 100%;
        }
    }

    .contextual-view {
        @include small-notification-view();
    }

    @media($extra-small) {
        @include small-notification-view();
    }
</style>

<i18n>
{
    "en-us": {
        "notificationSubtitle": "Send notifications to yourself or your teammates.",
        "editContent": "Create notification",
        "editOptions": "Edit options",
        "stepName": {
            "hasTitle": "Send ({contentTitle}) notification",
            "default": "Send notification"
        },
        "allUsers": "All users",
        "contactOwner": "Contact owner",
        "notification": {
            "recipient": "Notification recipient",
            "title": "Notification subject",
            "content": "Notification content",
            "emailContent": "Email notification content"
        },
        "sendDesktop": "Send desktop notification",
        "sendMobile": "Send mobile notification",
        "sendEmail": "Send email notification",
        "tooLongWarning": "You're over the character limit, text may be cut off",
        "error": {
            "banner": "Please fill out the required fields",
            "emailContent": "Oops, you need to add content",
            "sendMethodError": "Please select a notification type"
        }
    }
}
</i18n>
