export const ALLOWED_VALUE_KEY_PATTERN = '^[a-zA-Z][a-zA-Z0-9]*\\$[a-zA-Z0-9]+$';
export const INVALID_EMAIL_ERROR_MESSAGE = 'Email Address is invalid';
export const INVALID_SUFFIX_ERROR_MESSAGE = 'Suffix is invalid';
export const FIELD_ID_PHONE1 = 'PHONE1';
export const FIELD_ID_EMAIL = 'EMAIL';
export const FIELD_ID_COUNTRY = 'COUNTRY';
export const FIELD_ID_STATE = 'STATE';
export const REGION_OPTION_USA = 'USA';
export const REGION_OPTION_CAN = 'CAN';
export const REGION_OPTION_GBR = 'GBR';
export const FIELD_ID_OWNER = 'OWNER';
export const FIELD_ID_COMPANY = 'COMPANY';
export const FIELD_ID_STATES = ['STATE', 'STATE2', 'STATE3'];
export const FIELD_ID_MAPPING = {
    STATE: 'billing',
    STATE2: 'shipping',
    STATE3: 'other',
};

export const FIELD_TYPE_EXTERNAL = 'External';
export const FIELD_TYPE_SELECT = 'Select';
export const FIELD_TYPE_RADIO = 'Radio';
export const FIELD_TYPE_CHECKBOX = 'CheckboxGroup';
export const FIELD_TYPE_GROUP = 'Group';

export const INPUT_TYPE_MULTISELECT = 'MultiSelect';

export const FIELD_ID_TAGS = 'FORMS_TAGS';
export const FIELD_ID_NOTES = 'FORMS_NOTE';

const FIELD_CHECKBOXES = 'CHECKBOX';
const FIELD_SELECT = 'SELECT';
const FIELD_RADIO = 'RADIO';

const FIELD_ID_SHIPPING_ADDRESS_GROUP = 'SHIPPING_ADDRESS_GROUP';
const FIELD_ID_BILLING_ADDRESS_GROUP = 'BILLING_ADDRESS_GROUP';
const FIELD_ID_OPTIONAL_ADDRESS_GROUP = 'OPTIONAL_ADDRESS_GROUP';

const SHIPPING_ADDRESS_FIELD = {
    customField: true,
    id: FIELD_ID_SHIPPING_ADDRESS_GROUP,
    label: `smartForms.nonContactFields.${FIELD_ID_SHIPPING_ADDRESS_GROUP}`,
};

const BILLING_ADDRESS_FIELD = {
    customField: true,
    id: FIELD_ID_BILLING_ADDRESS_GROUP,
    label: `smartForms.nonContactFields.${FIELD_ID_BILLING_ADDRESS_GROUP}`,
};

const OPTIONAL_ADDRESS_FIELD = {
    customField: true,
    id: FIELD_ID_OPTIONAL_ADDRESS_GROUP,
    label: `smartForms.nonContactFields.${FIELD_ID_OPTIONAL_ADDRESS_GROUP}`,
};

export const SHIPPING_ADDRESS_FIELDS = [
    {
        id: 'ADDRESS2_STREET1',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'ADDRESS2_STREET2',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'CITY2',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'STATE2',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'POSTAL_CODE2',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'COUNTRY2',
        fieldType: 'External',
        required: false,
    },
];

const BILLING_ADDRESS_FIELDS = [
    {
        id: 'STREET_ADDRESS1',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'STREET_ADDRESS2',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'CITY',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'STATE',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'POSTAL_CODE',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'COUNTRY',
        fieldType: 'External',
        required: false,
    },
];

const OPTIONAL_ADDRESS_FIELDS = [
    {
        id: 'ADDRESS3_STREET1',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'ADDRESS3_STREET2',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'CITY3',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'STATE3',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'POSTAL_CODE3',
        fieldType: 'External',
        required: false,
    },
    {
        id: 'COUNTRY3',
        fieldType: 'External',
        required: false,
    },
];

export const COMPOUND_FIELDS = {
    [FIELD_ID_SHIPPING_ADDRESS_GROUP]: SHIPPING_ADDRESS_FIELDS,
    [FIELD_ID_BILLING_ADDRESS_GROUP]: BILLING_ADDRESS_FIELDS,
    [FIELD_ID_OPTIONAL_ADDRESS_GROUP]: OPTIONAL_ADDRESS_FIELDS,
};

export const GROUPED_FIELDS = [
    SHIPPING_ADDRESS_FIELD,
    BILLING_ADDRESS_FIELD,
    OPTIONAL_ADDRESS_FIELD,
];

export const TAG_FIELD = {
    customField: true,
    fieldType: FIELD_TYPE_EXTERNAL,
    id: FIELD_ID_TAGS,
    label: `smartForms.nonContactFields.${FIELD_ID_TAGS}`,
};

export const NOTE_FIELD = {
    customField: true,
    fieldType: FIELD_TYPE_EXTERNAL,
    id: FIELD_ID_NOTES,
    label: `smartForms.nonContactFields.${FIELD_ID_NOTES}`,
};

export const INTERNAL_FIELD_IDS = [FIELD_ID_TAGS, FIELD_ID_NOTES];
export const INTERNAL_FIELDS = [TAG_FIELD, NOTE_FIELD];

export const FORM_FIELD_IDS = [FIELD_CHECKBOXES, FIELD_SELECT, FIELD_RADIO];

export const INTERNAL_CUSTOM_CONTACT_FIELD_TYPES = ['User'];

export const NON_CONTACT_FIELD_TYPES = [FIELD_TYPE_CHECKBOX, FIELD_TYPE_SELECT, FIELD_TYPE_RADIO];

export const FORM_FIELDS = FORM_FIELD_IDS.map((FIELD_ID) => {
    let fieldType;

    switch (FIELD_ID) {
    case FIELD_CHECKBOXES:
        fieldType = FIELD_TYPE_CHECKBOX;
        break;
    case FIELD_SELECT:
        fieldType = FIELD_TYPE_SELECT;
        break;
    case FIELD_RADIO:
        fieldType = FIELD_TYPE_RADIO;
        break;
    default:
        break;
    }

    return {
        customField: true,
        id: FIELD_ID,
        fieldType,
        required: false,
        label: `smartForms.nonContactFields.${FIELD_ID}`,
    };
});

export const ADD_CONTACT_ERRORS = {};
ADD_CONTACT_ERRORS[INVALID_EMAIL_ERROR_MESSAGE] = 'error.invalidEmailErrorMessage';
ADD_CONTACT_ERRORS[INVALID_SUFFIX_ERROR_MESSAGE] = 'error.invalidSuffixErrorMessage';
