<template>
    <div class="configured-card-wrapper">
        <add-step
            v-if="isInsertingStepEnabled && value.delayStep && canAddStep"
            :add-open="addOpen"
            @open-steps="$emit('open-steps')"
            @selected="(payload) => $emit('selected', payload)"
        />

        <div class="configured-card-padding">
            <div :class="['configured-card', { expanded: active }]" :data-qa="`config_${name}`">
                <header class="configured-card-header" @click="toggleCollapse">
                    <div class="details">
                        <ds-icon :class="iconName" :name="iconName" />

                        <automation-display-name
                            :value="step"
                            :trigger-action="triggerAction"
                            capitalize
                            :class="['configure-name', { warning: hasTimerWarning && !hasError, 'alert-text': hasError }]"
                            data-qa="configure-name"
                        />
                    </div>

                    <ds-icon :name="active ? 'chevron-up' : 'chevron-down'" />
                </header>

                <ds-collapsible ref="collapsible" :opened="active">
                    <div v-if="configurationComponent">
                        <component
                            :is="configurationComponent"
                            :value="value"
                            :has-error="hasError"
                            :has-timer-warning="hasTimerWarning"
                            :error-message="errorMessage"
                            :trigger-action="triggerAction"
                            @remove="handleRemove"
                            @updated="handleUpdate"
                            @save="handleSave"
                            @done="handleDone"
                            @edit="handleEdit"
                        />
                    </div>
                </ds-collapsible>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash.debounce';

import DefaultConfigure from './DefaultConfigure';
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import AddStep from '@/automations/components/steps/AddStep';
import { configureComponents } from '@/automations/constants/automations.component.constants';
import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import { FF_KEAP_SIMPLE_AUTOMATION_INSERT_STEP } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        AutomationDisplayName,
        AddStep,
    },

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        icon: String,
        active: Boolean,
        canAddStep: Boolean,
        addOpen: Boolean,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        errorMessage: String,
        triggerAction: {
            type: String,
            default: '',
        },
        debounceDelay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
    },

    data() {
        return {
            configurationComponent: null,
        };
    },

    mounted() {
        if (this.debounceDelay > 0) {
            this.setHeight = debounce(this.setHeight, this.debounceDelay);
        }

        if (this.active) {
            this.expand();
        }
    },

    watch: {
        value: {
            handler() {
                this.setHeight();
            },
            deep: true,
        },

        active(val) {
            if (val) {
                this.expand();
            } else {
                this.configurationComponent = null;
            }
        },
    },

    computed: {
        ...mapState({
            isInsertingStepEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_INSERT_STEP],
        }),

        step() {
            return this.value.step || this.value;
        },

        name() {
            return this.value.step ? this.value.step.name : this.value.name;
        },

        iconName() {
            if (this.hasError) {
                return 'alert-octagon';
            }

            if (this.hasTimerWarning) {
                return 'alert-triangle';
            }

            return this.icon;
        },
    },

    methods: {
        setHeight() {
            this.$nextTick(() => {
                this.$refs.collapsible?.setContentHeight();
            });
        },

        setConfigComponent() {
            const type = this.value.step ? this.value.step.type : this.value.type;

            this.configurationComponent = configureComponents[type] || DefaultConfigure;
        },

        handleRemove(payload) {
            this.$emit('remove', payload);
            this.handleDone();
        },

        handleUpdate(payload) {
            // for updating timer config
            this.$emit('updated', payload);
        },

        handleSave(payload) {
            this.$emit('save', payload);
        },

        handleEdit(payload) {
            this.$emit('edit', payload);
        },

        handleDone() {
            this.$emit('active');
            this.configurationComponent = null;
        },

        expand() {
            this.setConfigComponent();
            this.setHeight();
        },

        toggleCollapse() {
            if (this.active) {
                this.handleDone();
            } else {
                this.$emit('active');
                this.setConfigComponent();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/variables';

    .configured-card {
        @include card;
        @include transition(box-shadow, background-color, border-radius, margin);
        padding: 0;
        box-shadow: $elevation-z1;
        margin: 0 auto;
        border-radius: 0;
        border-bottom: 1px solid $color-gray-200;
        --collapsible-inner-padding: 0;
        --timing-options-grid-template-columns: 1fr;
        max-width: $cards-max-width;

        &:not(.expanded):hover {
            background-color: $color-gray-050;
        }

        &.expanded {
            margin: $gp / 2 auto;
            border-radius: $border-radius;
        }
    }

    .configured-card-wrapper {
        &.last-expanded .configured-card,
        &:first-child .configured-card {
            border-top-right-radius: $border-radius;
            border-top-left-radius: $border-radius;
        }

        &.next-expanded .configured-card,
        &:last-child .configured-card {
            border-bottom-right-radius: $border-radius;
            border-bottom-left-radius: $border-radius;
        }
    }

    .configured-card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        padding: $gp;
    }

    .alert-text {
        color: $color-red-800;
    }

    .warning {
        color: $color-orange-900;
    }

    .details {
        display: flex;
        align-items: center;
        width: calc(100% - 30px);

        .clock {
            --icon-color: #{$color-yellow-700};
        }

        .zap {
            --icon-color: #{$color-blue-200};
        }

        .cloud-lightning {
            --icon-color: #{$color-green-200};
        }

        .alert-octagon {
            --icon-color: #{$color-red-500};
        }

        .alert-triangle {
            --icon-color: #{$color-orange-900};
        }

        .octagon {
            --icon-color: #{$color-red-300};
        }

        .configure-name {
            @include ellipsis-multiline(2, 40px);
            @include padding-start($gp);
            font-size: $font-size-med;
        }
    }

    @mixin small-configure-card-view() {
        .configured-card-padding {
            margin-right: $gp / 2;
            margin-left: $gp / 2;
        }

        .configured-card {
            max-width: $cards-max-width;
        }
    }

    .contextual-view {
        @include small-configure-card-view();
    }

    @media($extra-small) {
        @include small-configure-card-view();
    }
</style>
