<template>
    <div class="configure">
        <delete-configuration data-qa="delete-configuration" @remove="$emit('remove')" />
    </div>
</template>

<script>
import DeleteConfiguration from '@/automations/components/configure/DeleteConfiguration';

export default {
    components: {
        DeleteConfiguration,
    },

    props: {
        value: Object,
    },
};
</script>

<style lang="scss" scoped>
    .configure {
        display: flex;
        justify-content: flex-end;
        padding: 0 $gp $gp;
    }
</style>
