<template lang="html">
    <div :class="['keap-phone-field', { small }]">
        <template v-if="small">
            {{ keapPhoneNumber }}
        </template>

        <ds-phone-input
            v-else
            name="keapPhone"
            :label="$t('keapPhone')"
            :value="keapPhoneNumber"
            readonly
        />

        <ds-logo icon-only />
    </div>
</template>

<script>
export default {
    props: {
        keapPhoneNumber: String,
        small: Boolean,
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .keap-phone-field {
        --input-margin-bottom: 0;
        --logo-width: #{$icon-size-small};
        display: grid;
        grid-template-columns: auto #{$icon-size-small};
        grid-gap: $gp / 2;
        align-items: center;
        margin-bottom: var(--keap-phone-field-margin-bottom, #{$gp * 1.5});

        &.small {
            --logo-width: #{$gp / 2};
            display: inline-grid;
            margin-bottom: 0;
            grid-gap: $gp / 4;
            grid-template-columns: auto #{$gp / 2};
            font-weight: normal;
            color: $color-gray-700;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "keapPhone": "Keap Phone"
    }
}
</i18n>
