<template>
    <div v-if="loaded" class="invoice-sending-modal">
        <div class="modal-header">
            <section class="close">
                <ds-icon-button name="arrow-left" @click="close" />
            </section>

            <section class="title">
                <h4 :title="modalTitle">
                    {{ modalTitle }}
                </h4>

                <p v-if="contactName" class="sub-title">
                    {{ contactName }}
                </p>
            </section>

            <transition name="slide-fade" mode="in-out" appear>
                <div v-show="showSendingMethodToggle" class="header">
                    <ds-button-toggle
                        v-model="sendingMethod"
                        :options="sendingMethods"
                        prevent-single-deselect
                    />
                </div>
            </transition>
        </div>

        <invoice-email-modal
            v-if="showEmailModal"
            data-qa="invoice-email-modal"
            :invoice-source="invoiceSource"
            :payment-reminder="paymentReminder"
        />

        <sms-modal
            v-if="showSmsModal"
            data-qa="invoice-sms-modal"
            :contact="contact"
            :initial-message="smsMessage"
            @send="handleSend"
        />
    </div>

    <div v-else class="invoice-sending-modal spinner-container modal-header">
        <ds-spinner :size="120" />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import amplitude from '@/analytics/amplitude';
import { trackInvoiceSent } from '@/contacts/analytics';
import SmsModal from '@/communication/components/SmsModal';
import { SENDING_METHODS } from '@/communication/communication.constants';
import { SMS_INVOICE } from '@/communication/smsSources';
import { displayFullName } from '@/contacts/contact-info-utils';
import InvoiceEmailModal from '@/money/components/invoice/InvoiceEmailModal';
import { queryGetContact } from '@/contacts/store/queries';
import { orderStatus } from '@/money/constants/order-status';

export default {
    components: {
        InvoiceEmailModal,
        SmsModal,
    },

    props: {
        invoiceSource: String,
        isRecurring: Boolean,
        openAs: {
            type: String,
            default: SENDING_METHODS.EMAIL,
            options: [SENDING_METHODS.EMAIL, SENDING_METHODS.SMS],
        },
        paymentReminder: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            contact: {},
            isSendingModalOpen: false,
            sendingMethod: SENDING_METHODS.EMAIL,
            sendingMethods: [
                { label: this.$t('global.email'), value: SENDING_METHODS.EMAIL },
                { label: this.$t('global.text'), value: SENDING_METHODS.SMS },
            ],
            smsMessage: null,
            loaded: false,
        };
    },

    mounted() {
        this.load();
    },

    computed: {
        ...mapState({
            invoice: ({ sales }) => sales.invoice,
            keapPhoneAccount: ({ auth }) => auth.account.keapPhoneAccountInfo,
            user: ({ auth }) => auth.user,
        }),

        contactName() {
            return this.invoice && this.invoice.contact
                ? displayFullName(this.invoice.contact)
                : '';
        },

        contactPhone() {
            return this.contact
                ? this.contact.phone
                : '';
        },

        keapPhoneEnabled() {
            return Boolean(this.keapPhoneAccount && this.keapPhoneAccount.phoneNumber);
        },

        modalTitle() {
            return this.invoice.status === orderStatus.PAID
                ? this.$t('modalTitle.receipt') : this.isRecurring ? this.$t('modalTitle.recurringInvoice') : this.$t('modalTitle.invoice');
        },

        // Making these computed for immutability
        nestedModal_removeInnerPadding() {
            return true;
        },

        nestedModal_showBack() {
            return false;
        },

        nestedModal_showClose() {
            return false;
        },

        showEmailModal() {
            return this.sendingMethod === SENDING_METHODS.EMAIL;
        },

        showSendingMethodToggle() {
            return Boolean(this.contactPhone) && this.keapPhoneEnabled;
        },

        showSmsModal() {
            return this.sendingMethod === SENDING_METHODS.SMS;
        },
    },

    methods: {
        close() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        handleSend() {
            this.$store.dispatch('sales/SET_INVOICE_AS_SENT', { invoiceId: this.invoice.id });
            amplitude.v2.logEvent(amplitude.v2.events.INVOICE_SENT, { 'Send Type': 'SMS' });

            /* istanbul ignore next */
            this.$track('Communication - SMS - Message sent success', { source: SMS_INVOICE });

            trackInvoiceSent(this.invoiceSource);
        },

        load() {
            this.changeSendMethodOnOpen();
            this.loadContact();
            this.loadSmsMessage();
        },

        async loadContact() {
            this.contact = await queryGetContact(this.invoice.contactId)
                .catch(() => {});
            this.loaded = true;
        },

        async loadSmsMessage() {
            const { givenName, fullName } = this.user;
            const invoiceLink = await this.$store.dispatch('sales/GET_PUBLIC_INVOICE_URL', this.invoice.id);

            this.smsMessage = this.paymentReminder ? this.$t('smsMessage.paymentReminder', { invoiceLink, fullName, givenName })
                : this.invoice.status === orderStatus.PAID ? this.$t('smsMessage.sendReceipt', { invoiceLink })
                    : this.$t('smsMessage.sendInvoice', { invoiceLink });
        },

        changeSendMethodOnOpen() {
            this.sendingMethod = this.openAs;
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal-header {
        position: sticky;
        top: 0;
        z-index: 3;
        background-color: $color-paper;
        border-radius: $border-radius $border-radius 0 0;
        display: flex;
        border-bottom: 1px solid #{$color-gray-200};
    }

    .close {
        padding: $gp / 2;
        --icon-size: #{$gp * 1.5};
    }

    .title {
        flex: 1;
        padding: $gp 0;
        p {
            margin: 0;
        }
    }

    .sub-title {
        font-size: $font-size-med;
        color: $color-gray-700;
    }

    h4 {
        @include ellipsis;
    }

    .header {
        padding: $gp;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .slide-fade-leave-active {
        transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter,
    .slide-fade-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        height: px-to-rem(160px);
    }
</style>

<i18n>
{
    "en-us": {
        "modalTitle": {
            "invoice": "Send invoice",
            "recurringInvoice": "Send recurring invoice",
            "receipt": "Send receipt"
        },
        "smsMessage": {
            "sendInvoice": "Hello! Here is the invoice we talked about. Let me know if you have any questions. {invoiceLink}",
            "paymentReminder": "Looks like you have an invoice from {fullName} that still needs to be paid.  Reach out to {givenName} directly if you have any questions about this invoice. {invoiceLink}",
            "sendReceipt": "Hey hey, here's your receipt!"
        }
    }
}
</i18n>
