import { whenAvailable } from '@/shared/utils/window.util';
import sentry from './sentry';
import * as events from './constants/intercom-events.constants';

export default {
    events,

    attachScripts() {
        try {
            // eslint-disable-next-line
            (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://widget.intercom.io/widget/' + process.env.VUE_APP_INTERCOM_API_KEY; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (document.readyState === 'complete') { l(); } else if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
        } catch (e) {
            sentry.captureException(e);
        }
    },

    setup(props) {
        try {
            whenAvailable('Intercom', (intercom) => {
                const payload = {
                    app_id: process.env.VUE_APP_INTERCOM_API_KEY,
                    user_id: props.casId,
                    name: props.fullName,
                    phone: props.phone,
                    email: props.email,
                    company: { id: props.appName, name: props.companyName },
                    edition_name: props.editionName,
                    is_paid: props.isPaid,
                    is_partner: props.isPartner,
                    is_referred_by_partner: props.isReferredByPartner,
                };

                intercom('boot', payload);
            });
        } catch (e) {
            sentry.captureException(e);
        }
    },

    reset(payload) {
        try {
            whenAvailable('Intercom', (intercom) => {
                intercom('shutdown');

                intercom('boot', {
                    app_id: process.env.VUE_APP_INTERCOM_API_KEY,
                    user_id: payload.casId,
                });
            });
        } catch (e) {
            sentry.captureException(e);
        }
    },

    updatePage({ userId, page }) {
        try {
            whenAvailable('Intercom', (intercom) => {
                intercom('update', { user_id: userId, current_page: page });
            });
        } catch (e) {
            sentry.captureException(e);
        }
    },

    updateUserProperties(payload) {
        try {
            whenAvailable('Intercom', (intercom) => {
                intercom('update', payload);
            });
        } catch (e) {
            sentry.captureException(e);
        }
    },

    logEvent(name, data = null) {
        try {
            if (process.env.VUE_APP_LOGGING !== 'disabled') {
                if (!Object.values(events).includes(name)) {
                    if (process.env.NODE_ENV !== 'production') {
                    // eslint-disable-next-line no-console
                        console.warn(`"${name}" is not a valid Intercom Event. Please ensure that all events being sent to Intercom are intentional and approved by your leader. If this is the case, simply ensure that your event name is listed in the intercom-events.constants.js file and used appropriately.`);
                    }

                    return;
                }

                whenAvailable('Intercom', (intercom) => {
                    intercom('trackEvent', name, {
                        ...data,
                        Platform: 'Web',
                    });
                });
            }
        } catch (e) {
            sentry.captureException(e);
        }
    },

    showChat() {
        try {
            document.body.classList.remove('hide-intercom-chat');
        } catch (e) {
            sentry.captureException(e);
        }
    },

    hideChat() {
        try {
            document.body.classList.add('hide-intercom-chat');
        } catch (e) {
            sentry.captureException(e);
        }
    },
};
