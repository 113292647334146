<template>
    <section class="contact-additional-info" data-qa="contact-additional-info">
        <h4 class="section-title editing wide-field">
            {{ $t('title') }}
        </h4>

        <ds-input-field
            v-model.trim="nickname"
            type="text"
            autocomplete="no"
            :label="$t('forms.nickname')"
            :submitted="submitted"
            @input="$emit('input')"
        />

        <ds-input-field
            v-model.trim="spouseName"
            type="text"
            autocomplete="no"
            :label="$t('forms.spouseName')"
            :submitted="submitted"
            @input="$emit('input')"
        />

        <custom-field-edit
            v-for="customField in editableCustomFields"
            :key="customField.id"
            :custom-field="customField"
            :parent-name="contact.firstName"
            :edit-field-text="$t('customFields.editCustomField')"
            :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
            :custom-fields="customFieldSpecs"
            :field-id-prop="isCustomFieldTechDebtEnabled ? 'id' : 'dataFormFieldId'"
            @edit="editCustomField"
            @remove="removeCustomField"
            @clear="onClearCustomField"
            @input="onCustomFieldInput"
        />

        <contact-add-custom-field
            v-if="createCustomFieldVisible"
            data-qa="add-custom-field-form"
            class="add-custom-field-component wide-field"
            standalone
            :field-name="defaultCustomFieldName"
            :field-count="customFieldCount"
            :field-max="customFieldMax"
            :create-custom-field="createCustomField"
            :existing-fields="customFields"
            event-source="Contact Record"
            @added="customFieldAdded"
            @cancel="toggleAddCustomField"
        >
            <template #header>
                <h4 class="create-field">
                    {{ $t('customFields.createAField') }}
                </h4>
            </template>

            <template #description>
                <i18n tag="p" path="customFields.description.main">
                    <template #remaining>
                        <strong>{{ $t('customFields.description.remaining', { customFieldsAvailable }) }}</strong>
                    </template>
                </i18n>

                <custom-field-status
                    :existing="customFieldCount"
                    :max="customFieldMax"
                    :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
                    :object-name="$t('customFields.objectName')"
                />
            </template>
        </contact-add-custom-field>

        <custom-field-drop-down
            v-else-if="!disableAddCustomFields"
            data-qa="add-custom-fields"
            class="wide-field"
            :empty-fields="emptyFields"
            :add-text="$t('customFields.addCustomField')"
            :create-field-text="$t('customFields.createField')"
            @add="addExistingField"
            @create="toggleAddCustomField"
        />

        <edit-custom-field-modal-v-2
            v-if="isCustomFieldSettingsEnabled"
            :id="openCustomFieldId"
            :is-open="isEditCustomFieldModalOpen"
            :custom-fields="customFields"
            :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
            :custom-field-max="customFieldMax"
            @close="closeEditCustomField"
            @updated="updateLocalContact"
        >
            <template #title>
                <h4>{{ $t('customFields.title') }}</h4>
            </template>

            <template #description>
                <i18n tag="p" path="customFields.description.main">
                    <template #remaining>
                        <strong>{{ $t('customFields.description.remaining', { customFieldsAvailable }) }}</strong>
                    </template>
                </i18n>

                <custom-field-status
                    :existing="customFieldCount"
                    :max="customFieldMax"
                    :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
                    :object-name="$t('customFields.objectName')"
                />
            </template>
        </edit-custom-field-modal-v-2>

        <edit-custom-field-modal
            v-else
            :is-open="isEditCustomFieldModalOpen"
            :custom-field="openCustomField"
            :update-custom-field="updateCustomField"
            :delete-custom-field="deleteCustomField"
            :object-name="$t('customFields.objectName')"
            :parent-name="$t('customFields.parentName')"
            :custom-fields="customFields"
            :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
            @close="closeEditCustomField"
            @updated="updateLocalContact"
        >
            <template #title>
                <h4>{{ $t('customFields.title') }}</h4>
            </template>

            <template #description>
                <i18n tag="p" path="customFields.description.main">
                    <template #remaining>
                        <strong>{{ $t('customFields.description.remaining', { customFieldsAvailable }) }}</strong>
                    </template>
                </i18n>

                <custom-field-status
                    :existing="customFieldCount"
                    :max="customFieldMax"
                    :record-type="$options.CUSTOM_FIELD_RECORD_TYPE_CONTACT"
                    :object-name="$t('customFields.objectName')"
                />
            </template>
        </edit-custom-field-modal>
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import dateMixin from '@/appointments/mixins/date.mixin';
import displayMixin from '@/shared/mixins/display.mixin';
import contactsMixin from '@/contacts/mixins/contacts.mixin';
import {
    FLAGSHIP_CUS_7863_FIX_TO_SAVE_WHOLE_NUMBER_CUSTOM_FIELD_AS_NULL,
    FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS,
    KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT,
} from '@/shared/constants/featureFlag.constants';

import CustomFieldDropDown from '@/contacts/components/customFields/CustomFieldDropDown';
import CustomFieldEdit from '@/contacts/components/customFields/CustomFieldEdit';
import EditCustomFieldModal from '@/contacts/components/customFields/EditCustomFieldModal';
import EditCustomFieldModalV2 from '@/contacts/components/customFields/EditCustomFieldModalV2';
import customFieldUtils from '@/contacts/components/details/custom-field-utils';
import contactFieldTypes from '@/contacts/components/details/contactFieldTypes';
import ContactAddCustomField from '@/contacts/components/details/ContactAddCustomField';
import CustomFieldStatus from '@/contacts/components/customFields/CustomFieldStatus';
import { customFieldIsEmpty } from '@/contacts/contact-info-utils';
import { CUSTOM_FIELD_RECORD_TYPE_CONTACT } from '@/customFields/customFields.constants';
import { createCustomField } from '@/customFields/customFields.api';

export default {
    CUSTOM_FIELD_RECORD_TYPE_CONTACT,

    components: {
        ContactAddCustomField,
        CustomFieldDropDown,
        CustomFieldEdit,
        EditCustomFieldModal,
        EditCustomFieldModalV2,
        CustomFieldStatus,
    },

    mixins: [dateMixin, customFieldUtils, contactsMixin, displayMixin],

    props: {
        contact: Object,
        submitted: Boolean,
        disableAddCustomFields: Boolean,
    },

    data() {
        return {
            createCustomFieldVisible: false,
            defaultCustomFieldName: '',
            contactFieldTypes,
            emptyFields: [],
            isEditCustomFieldModalOpen: false,
            openCustomField: {},
            currentlyOpenCustomFieldId: 0, // rename to openCustomFieldId when removing KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
            editableCustomFields: [],
            localNickname: '',
            localSpouseName: '',
        };
    },

    created() {
        this.loadContactsCustomFields();
        this.setEmptyFields();
    },

    watch: {
        contact() {
            this.buildEditableCustomFields();
            this.contact.nickname = this.localNickname;
            this.contact.spouseName = this.localSpouseName;
        },

        emptyFields() {
            this.buildEditableCustomFields();
        },

        fieldGroups() {
            this.buildEditableCustomFields();
        },
    },

    computed: {
        ...mapState({
            fieldGroups: ({ contacts }) => contacts.fieldGroups,
            isCustomFieldWholeNumberEnabled: ({ featureFlags }) => featureFlags[FLAGSHIP_CUS_7863_FIX_TO_SAVE_WHOLE_NUMBER_CUSTOM_FIELD_AS_NULL],
            isCustomFieldSettingsEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS],
            isCustomFieldTechDebtEnabled: ({ featureFlags }) => featureFlags[KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT],
        }),

        ...mapGetters({
            fieldOptions: 'contacts/fieldOptions',
            customFieldCount: 'contacts/customFieldCount',
            customFieldMax: 'settings/maxCustomFields',
            customFieldSpecs: 'contacts/customFields', // Convert to mapState while removing KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
        }),

        customFieldsAvailable() {
            return this.customFieldMax - this.customFieldCount;
        },

        customFields() {
            return this.contact?.customFields || [];
        },

        nickname: {
            get() {
                return this.localNickname || this.contact.nickname;
            },

            set(nickname) {
                this.contact.nickname = nickname;
                this.localNickname = nickname;
            },
        },

        spouseName: {
            get() {
                return this.localSpouseName || this.contact.spouseName;
            },

            set(spouseName) {
                this.contact.spouseName = spouseName;
                this.localSpouseName = spouseName;
            },
        },

        customFieldGroup() {
            return this.fieldGroups.find((fieldGroup) => fieldGroup.name === 'Custom fields');
        },

        alwaysVisibleCustomFieldIds() {
            return this.customFieldSpecs.reduce((alwaysVisibleCustomFieldIds, customField) => {
                if (customField.alwaysShown) {
                    if (this.isCustomFieldTechDebtEnabled) {
                        alwaysVisibleCustomFieldIds.push(customField.id);
                    } else {
                        alwaysVisibleCustomFieldIds.push(customField.dataFormFieldId);
                    }
                }

                return alwaysVisibleCustomFieldIds;
            }, []);
        },

        // Should be able to remove this to just be the data prop when KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT is removed
        openCustomFieldId() {
            if (this.isCustomFieldTechDebtEnabled) {
                return this.currentlyOpenCustomFieldId;
            }

            return this.openCustomField.dataFormFieldId;
        },
    },

    methods: {
        buildEditableCustomFields() {
            if (this.editableCustomFields.length === 0) {
                this.editableCustomFields = this.customFields.filter((field) => {
                    return !this.emptyFields.some((emptyField) => emptyField.id === field.id);
                });
            } else {
                this.editableCustomFields = this.editableCustomFields.reduce((editableCustomFields, field) => {
                    const matchingCustomField = this.customFields.find((customField) => customField.id === field.id);

                    if (!this.emptyFields.some((emptyField) => emptyField.id === field.id) && matchingCustomField) {
                        editableCustomFields.push({
                            ...matchingCustomField,
                            value: field.value,
                        });
                    } else if (this.emptyFields.some((emptyField) => emptyField.id === field.id) && matchingCustomField) {
                        matchingCustomField.value = '';
                    }

                    return editableCustomFields;
                }, []);

                const newCustomFields = this.customFields.filter((field) => {
                    return !this.editableCustomFields.some((customField) => customField.id === field.id)
                        && !this.emptyFields.some((emptyField) => emptyField.id === field.id);
                });

                this.emptyFields.forEach((emptyField) => {
                    const matchingCustomField = this.customFields.find((customField) => customField.id === emptyField.id);

                    if (matchingCustomField) {
                        matchingCustomField.value = '';
                    }
                });

                this.editableCustomFields.push(...newCustomFields);
                this.onCustomFieldInput();

                if (this.customFieldSpecs.length > 0) {
                    this.editableCustomFields.forEach((field) => {
                        if (field.value) {
                            return;
                        }

                        if (this.isCustomFieldTechDebtEnabled) {
                            const matchingCustomField = this.customFieldSpecs.find((customField) => {
                                return customField.fieldType === 'Select'
                                    && customField.id === field.id
                                    && customField.defaultToFirstOption
                                    && customField.options.length > 0;
                            });

                            if (matchingCustomField?.options?.length) {
                                field.value = matchingCustomField?.options[0]?.value;
                            }
                        } else {
                            const matchingCustomField = this.customFieldSpecs.find((customField) => {
                                return customField.type === 'Select'
                                    && customField.dataFormFieldId === field.id
                                    && customField.defaultToFirstOption
                                    && customField.values.length > 0;
                            });

                            if (matchingCustomField?.values.length) {
                                field.value = matchingCustomField.values[0].toString();
                            }
                        }
                    });
                }
            }
        },

        onCustomFieldInput() {
            this.$emit('input');

            this.editableCustomFields.forEach((editableCustomField) => {
                const matchingCustomField = this.customFields.find((customField) => customField.id === editableCustomField.id);

                if (matchingCustomField) {
                    matchingCustomField.value = editableCustomField.value;
                }
            });
        },

        createCustomField(payload) {
            if (this.isCustomFieldTechDebtEnabled) {
                payload.recordType = CUSTOM_FIELD_RECORD_TYPE_CONTACT;

                return createCustomField(payload);
            }

            return this.$store.dispatch('contacts/CREATE_CUSTOM_FIELD', payload);
        },

        // Remove with FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS Flag
        updateCustomField(payload) {
            return this.$store.dispatch('contacts/UPDATE_CUSTOM_FIELD', payload);
        },

        // Remove with FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS Flag
        deleteCustomField(payload) {
            return this.$store.dispatch('contacts/DELETE_CUSTOM_FIELD', payload);
        },

        editCustomField(customField) {
            if (this.isCustomFieldTechDebtEnabled) {
                // Update to openCustomFieldId when removing KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
                this.currentlyOpenCustomFieldId = customField.id;
            } else {
                this.fieldGroups.forEach((group) => {
                    group.fields.forEach((field) => {
                        if (Number(field.dataFormFieldId) === Number(customField.id)) {
                            this.openCustomField = field;
                        }
                    });
                });
            }

            this.isEditCustomFieldModalOpen = true;
        },

        closeEditCustomField() {
            this.isEditCustomFieldModalOpen = false;
        },

        removeCustomField(field) {
            field.value = '';
            this.emptyFields.push(field);
        },

        onClearCustomField(field, defaultValue) {
            field.value = defaultValue;
            this.buildEditableCustomFields();
        },

        addExistingField(field) {
            if (!field.value) {
                const matchingCustomField = this.customFieldSpecs.find((customField) => {
                    return customField.type === 'Select'
                        && customField.dataFormFieldId === field.id
                        && customField.defaultToFirstOption
                        && customField.values.length > 0;
                });

                if (matchingCustomField?.values.length) {
                    field.value = matchingCustomField.values[0].toString();
                }
            }

            if (this.contact) {
                if (!this.contact.customFields.find((customField) => field.id === customField.id)) {
                    this.contact.customFields.push(field);
                }

                this.emptyFields = this.emptyFields.filter((item) => {
                    return item.id !== field.id;
                });
            }
        },

        updateLocalContact() {
            this.loadContactsCustomFields();
            this.$bus.$emit('UPDATE_LOCAL_CONTACT');
        },

        formatFields() {
            this.contact.customFields.forEach((field) => {
                if (field.fieldType === 'ListBox' && field.value === '') {
                    field.value = [];
                }
            });
            this.buildEditableCustomFields();
        },

        loadContactsCustomFields() {
            return this.$store.dispatch('contacts/LOAD_CUSTOM_FIELDS')
                .then(() => {
                    this.formatFields();
                })
                .catch(() => {
                    this.$error({ message: this.$t('customFields.loadError') });
                });
        },

        customFieldAdded() {
            this.$emit('customFieldAdded');
            this.updateLocalContact();
            this.toggleAddCustomField();
        },

        toggleAddCustomField() {
            this.createCustomFieldVisible = !this.createCustomFieldVisible;
        },

        setEmptyFields() {
            if (this.contact?.customFields?.length > 0) {
                if (this.isCustomFieldSettingsEnabled) {
                    this.emptyFields = this.contact.customFields.filter((field) => {
                        return !this.alwaysVisibleCustomFieldIds.includes(field.id) && customFieldIsEmpty(field, this.isCustomFieldWholeNumberEnabled);
                    });
                } else {
                    this.emptyFields = this.contact.customFields.filter((field) => customFieldIsEmpty(field, this.isCustomFieldWholeNumberEnabled));
                }
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .add-custom-field-component {
        @include card;

        grid-column: span 2;

        @media($small) {
            grid-column: auto;
        }
    }

    label {
        color: $color-text-normal;
        font-weight: normal;
    }

    .create-field {
        margin-bottom: $gp * 2;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Additional info",
        "customFields": {
            "title": "Edit custom field",
            "addCustomField": "Add custom field",
            "loadError": "Something went wrong and we couldn't load this contact's custom fields. Please try again.",
            "createField": "Create custom field",
            "createAField": "Create a custom field",
            "editCustomField": "Edit custom field",
            "objectName": "custom field",
            "parentName": "contacts",
            "description": {
                "main": "You can add up to {remaining} custom fields to your contact records. Be sure to select a field type that best matches the data you plan to enter.",
                "remaining": "{customFieldsAvailable} more"
            }
        }
    }
}
</i18n>
