var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-qa":"help-resources-panel-items"}},[_c('ds-list-item',{staticClass:"help-item",attrs:{"as":"a","title":_vm.$t('learningAndSupport.keapHelp.title'),"border-style":"none","href":"https://help.keap.com","target":"_blank","rel":"noopener noreferrer"},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"help-circle"}})]},proxy:true}])}),(_vm.isPaidUser)?_c('support-chat',{attrs:{"data-qa":"support-chat"},scopedSlots:_vm._u([{key:"online",fn:function(ref){
var chatId = ref.chatId;
var startChat = ref.startChat;
return [_c('ds-list-item',{staticClass:"help-item",staticStyle:{"display":"none"},attrs:{"id":chatId,"as":"a","href":"javascript://Chat","title":_vm.$t('learningAndSupport.chatWithSupport.title'),"border-style":"none"},on:{"click":startChat},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"conversation"}})]},proxy:true}],null,true)})]}},{key:"offline",fn:function(ref){
var chatId = ref.chatId;
var url = ref.url;
return [_c('ds-list-item',{staticClass:"help-item",attrs:{"id":chatId,"as":"a","href":url,"title":_vm.$t('learningAndSupport.chatWithSupport.title'),"target":"_blank","border-style":"none","rel":"noopener noreferrer"},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"conversation"}})]},proxy:true}],null,true)})]}}],null,false,1876449119)}):_vm._e(),_c('ds-list-item',{staticClass:"help-item",attrs:{"as":"a","title":_vm.$t('learningAndSupport.whatsNew.title'),"border-style":"none","href":"https://help.keap.com/help/release-notes","target":"_blank","rel":"noopener noreferrer"},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"gift"}})]},proxy:true}])}),_c('share-feedback',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var openModal = ref.openModal;
return [_c('ds-list-item',{staticClass:"help-item",attrs:{"title":_vm.$t('learningAndSupport.shareFeedback.title'),"clickable":"","border-style":"none"},on:{"click":openModal},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"message-square"}})]},proxy:true}],null,true)})]}}])}),_c('ds-list-item',{staticClass:"help-item",attrs:{"as":"a","title":_vm.$t('learningAndSupport.newCustomerWebinars.title'),"border-style":"none","href":"https://keap.com/customer-webinars","target":"_blank","rel":"noopener noreferrer"},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"lightbulb"}})]},proxy:true}])}),_c('ds-list-item',{staticClass:"help-item",attrs:{"as":"a","title":_vm.$t('learningAndSupport.automationWebinars.title'),"border-style":"none","href":"https://keap.com/product-updates/automation-webinars","target":"_blank","rel":"noopener noreferrer"},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"campaign"}})]},proxy:true}])}),_c('ds-list-item',{staticClass:"help-item",attrs:{"title":_vm.$t('learningAndSupport.moreResources.title'),"clickable":"","border-style":"none"},on:{"click":_vm.viewMoreResources},scopedSlots:_vm._u([{key:"leadingSlot",fn:function(){return [_c('ds-icon',{staticClass:"help-icon",attrs:{"name":"add"}})]},proxy:true}])}),_c('portal',{attrs:{"to":"root"}},[_c('share-feedback-modal')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }