const SettingsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/SettingsPage');
const SettingsBillingPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/BillingPage');
const ProfilePage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/ProfilePage');
const BusinessProfilePage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/BusinessProfilePage');
const SettingsIntegrationsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/IntegrationsPage');
const IntegrationsWaitingPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/IntegrationsWaitingPage');
const UsersPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/UsersPage');
const EmailDeliverabilitySettingsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/domain/EmailDeliverabilitySettingsPage');
const NotificationSettingsPage = () => import(/* webpackChunkName: "settings" */ '@/settings/pages/NotificationSettingsPage');
const ApiAccessTokensPage = () => import(/* webpackChunkName: "settings" */ '@/apiManagement/pages/ApiAccessTokensPage');

import tagsRoutes from '@/tags/tags.routes';
import customFieldsRoutes from '@/customFields/customFields.routes';
import { accountSettingsRoutes } from '@/account/account.routes';
import { productSettingsRoutes } from '@/money/money.routes';

import { ROLE_USER_CAN_VIEW_ALL_RECORDS } from '@/settings/settings.constants';
import { FF_PLATFORM_TENANT_ACCESS_TOKENS } from '@/shared/constants/featureFlag.constants';

const routes = [
    {
        path: '/settings',
        name: 'settings',
        component: SettingsPage,
        meta: {
            title: 'nav.settings.label',
            hideHeaderOnMobile: true,
        },
        children: [
            ...accountSettingsRoutes,
            {
                path: 'billing',
                name: 'settings.billing',
                component: SettingsBillingPage,
                meta: {
                    title: 'settings.billing.title',
                    hideHeaderOnMobile: true,
                },
            },
            ...productSettingsRoutes,
            {
                path: 'profile',
                name: 'settings.profile',
                component: ProfilePage,
                meta: {
                    title: 'settings.profile.title',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'user/:id',
                name: 'settings.user',
                component: ProfilePage,
                meta: {
                    title: 'settings.profile.title',
                    hideHeaderOnMobile: true,
                    permission: ROLE_USER_CAN_VIEW_ALL_RECORDS,
                },
            },
            {
                path: 'businessProfile',
                name: 'settings.businessProfile',
                component: BusinessProfilePage,
                meta: {
                    title: 'settings.businessProfile.title',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'notifications',
                name: 'settings.notifications',
                component: NotificationSettingsPage,
                meta: {
                    title: 'settings.notifications.title',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'integrations',
                name: 'settings.integrations',
                component: SettingsIntegrationsPage,
                meta: {
                    title: 'settings.integrations.title',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'integrations-waiting',
                name: 'settings.integrations.waiting',
                component: IntegrationsWaitingPage,
                meta: {
                    title: 'settings.integrations.title',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'users',
                name: 'settings.users',
                component: UsersPage,
                meta: {
                    title: 'settings.users.title',
                    hideHeaderOnMobile: true,
                    permission: ROLE_USER_CAN_VIEW_ALL_RECORDS,
                },
            },
            {
                path: 'forms',
                name: 'settings.forms',
                redirect: '/forms',
            },
            {
                path: 'lead-form',
                name: 'settings.leadForm',
                redirect: '/forms/public',
            },
            {
                path: 'smart-forms',
                name: 'settings.smartForms',
                redirect: '/forms/internal',
                children: [
                    {
                        path: ':formId/:tab?',
                        name: 'smartForms.form',
                        redirect: '/forms/internal/:formId/:step?',
                    },
                ],
            },
            {
                path: 'email-deliverability',
                name: 'settings.emailDeliverability',
                component: EmailDeliverabilitySettingsPage,
                meta: {
                    title: 'settings.email-deliverability.title',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'api-access-tokens',
                name: 'settings.api-access-tokens',
                component: ApiAccessTokensPage,
                meta: {
                    title: 'settings.api-access-tokens.title',
                    featureFlag: FF_PLATFORM_TENANT_ACCESS_TOKENS,
                    hideHeaderOnMobile: true,
                },
            },
            ...tagsRoutes,
            ...customFieldsRoutes,
        ],
    },
];

export default routes;
