import Vue from 'vue';

import {
    NON_CONTACT_FIELD_TYPES,
} from '@/smartForms/smartform.constants';
import { FORM_STATUSES } from '@/customForms/customForm.constants';

export default {
    SET_FORM_STATUS_PUBLISHED({ form }) {
        form.status = FORM_STATUSES.PUBLISHED;
    },

    SET_FORM_STATUS_NOT_PUBLISHED({ form }) {
        form.status = FORM_STATUSES.NOT_PUBLISHED;
    },

    SET_FORM_EVER_PUBLISHED({ form }) {
        form.everPublished = true;
    },

    SET_SMART_FORMS(state, { forms }) {
        Vue.set(state, 'forms', forms);
    },

    UPDATE_SORT(state, { field, ascending }) {
        state.smartFormListSortField = field;
        state.smartFormListSortAscending = ascending;
    },

    UPDATE_SMART_FORMS(state, form) {
        const { forms } = state;

        Vue.set(state, 'forms', [form, ...forms]);
    },

    SET_BUILDER_FORM(state, form) {
        state.form = form;
        state.builder.areFieldsValid = checkFields(state.form.fields);
    },

    UPDATE_BUILDER_FORM(state, partialForm) {
        state.form = Object.assign(state.form, partialForm);
        state.builder.areFieldsValid = checkFields(state.form.fields);
    },

    SET_FORM_DETAILS_VALID(state, valid) {
        state.builder.areDetailsValid = valid;
    },

    SET_FORM_FIELDS_VALID(state, valid) {
        state.builder.areFieldsValid = valid;
    },

    SET_STATE_READY(state, ready) {
        state.states.ready = ready;
    },

    SET_AUTO_SAVE_IN_PROGRESS(state, inProgress) {
        state.states.saving = inProgress;
        state.firstLoad = false;
    },

    SET_AUTO_SAVE_TIMESTAMP(state, timestamp) {
        state.autoSaveTimeStamp = timestamp;
    },

    RESET_SMART_FORM_BUILDER_STATE(state) {
        state.builder.areDetailsValid = true;
        state.builder.areFieldsValid = true;
        state.firstLoad = true;
    },
};

const checkFields = (fields) => {
    return fields ? fields.every((field) => {
        if (NON_CONTACT_FIELD_TYPES.includes(field.fieldType)) {
            return Boolean(field.fieldName && field.allowedValues && field.allowedValues.length);
        }

        return true;
    }) : false;
};
