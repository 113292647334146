<template>
    <div class="sms-text-box-footer button-row">
        <sms-merge-field-button v-if="broadcastsSource && !nonCustomFieldsEnabled" @input="mergeFieldSelected" />

        <component
            :is="walkthroughComponent"
            v-else
            class="walkthrough-step"
            :step="broadcastsSource ? 3 : 2"
        >
            <merge-field-dropdown
                position="bottom-start"
                :hide-custom-fields="hideCustomFields"
                :use-merge-service="useMergeService || (broadcastsSource && nonCustomFieldsEnabled)"
                :additional-merge-fields="additionalMergeFields"
                @select="({ value }) => mergeFieldSelected(value)"
            >
                <template #action="{ toggle }">
                    <ds-icon-button name="merge-field" @click="toggle" />
                </template>
            </merge-field-dropdown>
        </component>

        <component
            :is="walkthroughComponent"
            v-if="smsLinkShorteningEnabled"
            class="walkthrough-step"
            :step="broadcastsSource ? 4 : 3"
        >
            <sms-link-shortener-button
                position="bottom"
                @shorten-link="insertText"
            />
        </component>

        <template-button
            v-if="keapMarketingTemplatesEnabled && broadcastsSource"
            :templates="sortedKeapMarketingNumberTemplates"
            :template-type="templateType"
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import SmsBroadcastWalkthroughStep from '@/broadcasts/components/sms/walkthrough/SmsBroadcastWalkthroughStep';
import TextAutomationWalkthroughStep from '@/automations/components/TextAutomationWalkthroughStep';

import SmsMergeFieldButton from '@/broadcasts/components/sms/SmsMergeFieldButton';
import MergeFieldDropdown from '@/shared/components/MergeField/MergeFieldDropdown';
import SmsLinkShortenerButton from '@/broadcasts/components/sms/SmsLinkShortenerButton';
import { FF_KEAP_SMS_NONCUSTOM_MERGEFIELDS, KEAP_SMS_LINK_SHORTENING, FF_KEAP_SMS_MARKETING_TEMPLATES } from '@/shared/constants/featureFlag.constants';
import { AUTOMATIONS_SOURCE, BROADCASTS_SOURCE } from '@/shared/components/Text/SmsTextBox';
import { TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';
import TemplateButton from '@/shared/components/Templates/TemplateButton';

export default {
    components: {
        SmsMergeFieldButton,
        MergeFieldDropdown,
        SmsBroadcastWalkthroughStep,
        TextAutomationWalkthroughStep,
        TemplateButton,
        SmsLinkShortenerButton,
    },

    inject: ['$mergeFieldTextBox'],

    props: {
        hideCustomFields: Boolean,
        useMergeService: Boolean,
        additionalMergeFields: Array,
        source: {
            type: String,
            required: true,
            validator: (source) => [AUTOMATIONS_SOURCE, BROADCASTS_SOURCE].includes(source),
        },
    },

    data() {
        return {
            templateType: TEMPLATE_KINDS.KMN_BROADCAST_CUSTOM,
        };
    },

    mounted() {
        if (this.keapMarketingTemplatesEnabled) {
            this.$store.dispatch('communicationTemplates/LOAD_KEAP_MARKETING_NUMBER_TEMPLATES');
        }
    },

    computed: {
        ...mapState({
            smsLinkShorteningEnabled: ({ featureFlags }) => featureFlags[KEAP_SMS_LINK_SHORTENING],
            nonCustomFieldsEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_NONCUSTOM_MERGEFIELDS],
            keapMarketingTemplatesEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_MARKETING_TEMPLATES],
        }),

        ...mapGetters({
            sortedKeapMarketingNumberTemplates: 'communicationTemplates/sortedKeapMarketingNumberTemplates',
        }),

        broadcastsSource() {
            return this.source === BROADCASTS_SOURCE;
        },

        automationsSource() {
            return this.source === AUTOMATIONS_SOURCE;
        },

        walkthroughComponent() {
            return this.broadcastsSource ? SmsBroadcastWalkthroughStep : TextAutomationWalkthroughStep;
        },
    },

    methods: {
        insertText(val) {
            this.$mergeFieldTextBox.insertValue(val);
        },

        mergeFieldSelected(val) {
            this.$mergeFieldTextBox.insertMergeField(val);
        },
    },
};
</script>

<style lang="scss" scoped>
.sms-text-box-footer {
    background-color: $color-ink-050;

    > * {
        margin: $gp / 4;
    }
}

.walkthrough-step {
    --popover-min-width: #{px-to-rem(312)};
    --popover-width: #{px-to-rem(312)};
}
</style>
