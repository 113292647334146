import Vue from 'vue';
import gql from 'graphql-tag';

export const getCampaignList = async ({
    limit,
    offset,
    search = '',
    orderBy = null,
    orderDirection = null,
}) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query campaignList($limit: Int, $offset: Int, $search: String, $orderBy: String, $orderDirection: String) {
                campaignList(limit: $limit, offset: $offset, search: $search, orderBy: $orderBy, orderDirection: $orderDirection) {
                    id
                    name
                    description
                    dateCreated
                    publishedDate
                    publishedStatus
                }
            }
        `,
        variables: {
            limit,
            offset,
            search,
            orderBy,
            orderDirection,
        },
        fetchPolicy: 'no-cache',
    });
};

export const getCampaignSequences = async (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query campaignSequences($id: ID!) {
                campaignSequences(id: $id) {
                    id
                    name
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const getCampaignStats = async (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query campaignStats($id: ID!) {
                campaignStats(id: $id) {
                    title
                    description
                    value
                    format
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const deleteCampaign = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteCampaign($id: ID!) {
                deleteCampaign(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};
