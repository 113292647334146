<template>
    <div ref="container" class="templates-container">
        <div v-if="templates.length === 0 && isLoading" class="templates-spinner-container">
            <ds-spinner />
        </div>

        <div v-if="!isLoading && templates.length > 0" class="templates">
            <ds-card class="start-from-scratch" data-qa="start-from-scratch">
                <div>
                    <ds-illustration name="notes-gray" />
                </div>

                <strong>{{ $t('startFromScratchText') }}</strong>

                <ds-outline-button data-qa="template-blank" @click="startFromScratch">
                    {{ $t('startFromScratchLabel') }}
                </ds-outline-button>
            </ds-card>
            <template-html-card
                v-for="(template, index) in templates"
                :key="index"
                :template="template"
                :data-qa="`template-card-${index}`"
                :is-previewing="template.id === previewTemplateId"
                @select="handleTemplateSelected(template.id)"
                @preview="handleTemplatePreview(template)"
            >
                <template v-if="isMyTemplatesTab" #menu>
                    <template-card-menu
                        show-rename
                        :data-qa="`template-card-menu-${index}`"
                        @delete="confirmDelete(template, index)"
                        @rename="handleRenameTemplateModalOpen(template, index)"
                        @copy="handleCopyTemplate(template)"
                    />
                </template>
            </template-html-card>

            <template v-if="isLoading && templates.length > 0">
                <template-placeholder-card
                    v-for="n in pageSize"
                    :key="`placeholder-${n}`"
                />
            </template>
        </div>

        <div v-if="!isLoading && templates.length === 0" class="templates">
            <ds-card class="start-from-scratch" data-qa="start-from-scratch">
                <div>
                    <ds-illustration name="notes-gray" />
                </div>

                <strong>{{ $t('startFromScratchText') }}</strong>

                <ds-outline-button data-qa="template-blank" @click="startFromScratch">
                    {{ $t('startFromScratchLabel') }}
                </ds-outline-button>
            </ds-card>
        </div>

        <scroll-target v-if="!isLoading && !isFullyLoaded" :root="$refs.container" @intersect="fetchPage" />
        <template-preview
            :preview-html="previewTemplate"
            :preview-name="previewName"
            data-qa="template-preview"
            allow-selection
            @close="closeTemplatePreview"
            @select="handleSelectPreview"
        />
    </div>
</template>

<script>
import TemplatePlaceholderCard from './TemplatePlaceholderCard';
import { deleteSite, getMarketingSite, getSavedTemplates } from '@/marketingSites/api/sites-datasource-graphql';
import TemplatePreview from '@/marketingSites/components/wizard/TemplatePreview';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';
import TemplateHtmlCard from '@/marketingSites/components/wizard/TemplateHtmlCard';
import TemplateCardMenu from '@/marketingSites/components/wizard/TemplateCardMenu';
import { MARKETING_SITE_TEMPLATE_TYPES } from '@/marketingSites/provider/site-provider.utils';
import { convert as slugify } from 'url-slug';
import { createBlankSiteUnlayerSiteTemplate } from '@/marketingSites/unlayer/unlayer.constants';

const ScrollTarget = {
    props: {
        root: HTMLElement,
    },

    mounted() {
        const options = {
            root: this.root,
            rootMargin: '0px 0px 800px 0px',
        };

        const callback = (entries) => {
            const entry = entries[0];

            if (entry.isIntersecting) {
                this.$emit('intersect');
            }
        };

        this.intersectionObserver = new IntersectionObserver(callback, options);
        this.intersectionObserver.observe(this.$el);
    },

    beforeDestroy() {
        this.intersectionObserver?.observe(this.$el);
    },

    render(h) {
        return h('div');
    },
};

export default {
    components: {
        TemplateCardMenu,
        TemplateHtmlCard,
        TemplatePreview,
        TemplatePlaceholderCard,
        ScrollTarget,
    },

    inject: ['$sites', '$createSite'],

    props: {
        status: String,
    },

    data() {
        return {
            nextPageToken: null,
            pageSize: 50,
            templates: [],
            isLoading: false,
            isFullyLoaded: false,
            error: null,
            previewTemplate: null,
            previewTemplateId: '',
        };
    },

    created() {
        return this.fetchPage();
    },

    computed: {
        isMyTemplatesTab() {
            return this.status === SITE_STATUS.SAVED;
        },

        previewName() {
            return this.previewTemplate?.name;
        },
    },

    methods: {
        async fetchPage() {
            try {
                this.isLoading = true;

                const { nextPageToken: pageToken, pageSize } = this;
                const { templates, nextPageToken } = await getSavedTemplates({ pageSize, pageToken, status: this.status });

                this.templates.push(...templates);
                this.nextPageToken = nextPageToken;
                this.isFullyLoaded = true;
            } catch (error) {
                this.error = error;
            }

            this.isLoading = false;
        },

        async handleSelectPreview() {
            await this.handleTemplateSelected(this.previewTemplateId);
            this.closeTemplatePreview();
        },

        async handleTemplateSelected(templateId) {
            const { site, blank } = MARKETING_SITE_TEMPLATE_TYPES;

            const templateType = templateId ? site : blank;

            if (templateId) {
                this.$emit('select-pending', { templateId, templateType });

                const templateSource = await getMarketingSite(templateId);

                this.$emit('select', { templateSource, templateType });
            }
        },


        startFromScratch() {
            const templateSource = createBlankSiteUnlayerSiteTemplate({
                defaultSiteName: this.$t('marketingSites.defaultName'),
                defaultThankYouPageName: this.$t('marketingSites.defaultThankYouPageName'),
                defaultLandingPageName: this.$t('marketingSites.defaultPageName'),
            });

            this.$emit('select', { templateSource, templateType: MARKETING_SITE_TEMPLATE_TYPES.blank });
        },

        closeTemplatePreview() {
            this.previewTemplate = null;
            this.previewTemplateId = null;
        },

        async handleTemplatePreview(template) {
            this.previewTemplateId = template.id;
            this.previewTemplate = template.html;
        },

        async handleRenameTemplateModalOpen(template, index) {
            const { name: oldName } = template;
            const title = this.$t('renameTemplate');
            const nameInputLabel = this.$t('templateName');
            const helperText = '';
            const name = await this.$sites.chooseName(oldName, title, nameInputLabel, helperText);

            this.$sites.closeChooseNameModal();

            if (name) {
                this.$set(this.templates[index], 'name', name);
                const slugForName = slugify(name);

                this.$set(this.templates[index], 'slug', slugForName);
                const site = await getMarketingSite(template.id);

                await this.$sites.saveSite({
                    ...site,
                    name,
                    slug: slugForName,
                });
            }
        },

        async confirmDelete(template, index) {
            try {
                await this.$confirm({
                    optionTitle: this.$t('deleteConfirmTitle'),
                    optionMessage: this.$t('deleteConfirmMessage'),
                });
            } catch (error) {
                return;
            }
            const auxTemplates = this.templates;

            try {
                await deleteSite(template.id);
                this.templates.splice(index, 1);
            } catch (error) {
                this.templates = auxTemplates;
                this.$error({ message: this.$t('deleteErrorMessage') });
            }
        },

        async handleCopyTemplate(template) {
            const { name: oldName } = template;
            const title = this.$t('nameTemplate');
            const nameInputLabel = this.$t('templateName');
            const helperText = '';
            const name = await this.$sites.chooseName(oldName, title, nameInputLabel, helperText);

            try {
                if (name) {
                    const copiedSite = await this.$createSite.createUserSiteTemplate(template, name);

                    this.templates.splice(0, 0, copiedSite);

                    this.$sites.refreshSites();
                    this.$sites.closeChooseNameModal();
                }
            } catch (err) {
                this.$error({ message: this.$t('errorCopyingTemplate') });
            } finally {
                this.chooseNameModalOptions = {};
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$card-size: px-to-rem(300);

.templates-container {
    flex: 1;
    padding: $gp * 2;
    @include overflowY;
}

.templates {
    display: grid;
    grid-template-columns: repeat(auto-fill, $card-size);
    grid-gap: $gp * 3 / 2;
    justify-content: center;

    &> * {
        width: $card-size;
        height: $card-size;
    }
}

.templates-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.start-from-scratch {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $gp * 2;

    strong {
        margin: $gp 0;
        text-align: center;
        font-size: $font-size-lg;
    }
}
</style>

<i18n>
{
    "en-us": {
        "startFromScratchLabel": "Start from scratch",
        "startFromScratchText": "Don't want to start from a template?",
        "deleteConfirmTitle": "Delete Landing Page",
        "deleteConfirmMessage": "Are you sure you want to delete this landing page?",
        "deleteErrorMessage": "There was a problem deleting",
        "errorCopyingTemplate": "There was an error copying this template",
        "renameTemplate": "Rename template",
        "nameTemplate": "Name your template",
        "templateName": "Template name"
    }
}
</i18n>
