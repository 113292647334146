export const DEFAULT_SESSION_STATE = {
    jwt: null,
    casRedirectUrlConfig: null,
    coreAppId: null,
    referredByPartner: false,
    sessionAffinity: null,
    logInAsSession: false,
};

export default {
    session: { ...DEFAULT_SESSION_STATE },
    user: {},
    users: [],
    accounts: [],
    account: {
        features: {},
        trial: {},
        companyProfile: {
            logoUrl: null,
        },
        keapPhoneAccountInfo: {},
        campaignSMSAccountInfo: {},
        automatedSmsAccount: {},
    },
    businessTypeOptions: [
        'businessType.values.agriculture',
        'businessType.values.arts',
        'businessType.values.auto',
        'businessType.values.business',
        'businessType.values.construction',
        'businessType.values.education',
        'businessType.values.financial',
        'businessType.values.fitness',
        'businessType.values.healthcare',
        'businessType.values.homeServices',
        'businessType.values.manufaturing',
        'businessType.values.marketing',
        'businessType.values.news',
        'businessType.values.nonProfit',
        'businessType.values.personalServices',
        'businessType.values.publicAdmin',
        'businessType.values.realEstate',
        'businessType.values.restaurant',
        'businessType.values.retail',
        'businessType.values.software',
        'businessType.values.travel',
        'businessType.values.other',
    ],
    signatures: {},
    exitIntentComplete: false,
    lastSession: {},
};
