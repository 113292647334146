import Vue from 'vue';

export default {
    SET_APPT_DATA(state, apptData) {
        state.apptData = apptData;
    },

    SET_CONFIRMED_APPT(state, appointment) {
        state.confirmedAppt = appointment;
    },

    SET_SELECTED_DATE(state, { times, selectedDate }) {
        Vue.set(state.apptData, 'availability', {
            ...state.apptData.availability,
            selectedDay: selectedDate,
            times,
        });
    },

    SET_APPOINTMENT_TYPE_AVAILABILITY(state, availability) {
        Vue.set(state.apptData, 'availability', availability);
    },
};
