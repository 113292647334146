<template lang="html">
    <div class="nav-profile">
        <ds-avatar :image-url="profileImage" :name="user.fullName" :size="24" />

        <div class="user-details">
            <p class="user-name">
                {{ user.fullName }}
            </p>

            <p v-if="companyName" class="company">
                {{ companyName }}
            </p>

            <keap-phone-field
                v-if="keapPhoneNumber"
                :keap-phone-number="keapPhoneNumber"
                small
            />

            <p class="app-name">
                {{ $t('currentApp', { app: appName }) }}
            </p>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import displayMixin from '@/shared/mixins/display.mixin';
import KeapPhoneField from '@/shared/components/Fields/KeapPhoneField';

export default {
    components: {
        KeapPhoneField,
    },

    mixins: [displayMixin],

    computed: {
        ...mapState({
            account: ({ auth }) => auth.account,
            appName: ({ auth }) => auth.account.appName,
            keapPhoneAccountInfo: ({ auth }) => auth.account.keapPhoneAccountInfo,
            user: ({ auth }) => auth.user,
        }),

        companyName() {
            if (this.account.companyProfile && this.account.companyProfile.companyName) {
                return this.account.companyProfile.companyName;
            }

            return null;
        },

        keapPhoneNumber() {
            return this.keapPhoneAccountInfo == null ? '' : this.formatPhone(this.keapPhoneAccountInfo.phoneNumber);
        },

        profileImage() {
            return this.user.emailSignatureURL ? this.user.emailSignatureURL : '';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .nav-profile {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        overflow: hidden;
    }

    .user-details {
        @include margin-start($gp);
        overflow: hidden;
    }

    p {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0;
        max-width: 100%;
        color: $color-gray-700;
        font-size: $font-size-sm;
        line-height: 1.4;
    }

    .user-name {
        color: $color-gray-800;
        font-size: $font-size-med;
    }
</style>

<i18n>
{
    "en-us": {
        "currentApp": "Current app: {app}"
    }
}
</i18n>
