<template lang="html">
    <div class="expired-page">
        <div class="wrapper">
            <img :src="`${baseUrl}images/empty-state/empty-default.svg?v=3`" />

            <h1 class="secondary-title">
                {{ $t('title') }}
            </h1>

            <p v-if="referredByPartner">
                {{ $t('partner', { productName: $t('global.productName') }) }}
            </p>

            <div v-else class="expired-message">
                <p>{{ $t('expired', { productName: $t('global.productName') }) }}</p>

                <ds-filled-button
                    v-track="'Free trial - Expired - clicked : Subscribe'"
                    class="large"
                    @click="goToUpgrade"
                >
                    {{ $t('nav.upgradeFromTrial') }}
                </ds-filled-button>

                <a :href="`tel:+${keapSalesNumber}`">{{ formatPhone(keapSalesNumber) }}</a>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import displayMixin from '@/shared/mixins/display.mixin';
import { KEAP_SALES_NUMBER } from '@/shared/constants/phone.constants';

export default {
    mixins: [displayMixin],

    data() {
        return {
            baseUrl: process.env.BASE_URL,
            keapSalesNumber: KEAP_SALES_NUMBER,
        };
    },

    computed: {
        ...mapGetters({
            referredByPartner: 'auth/referredByPartner',
        }),
    },

    methods: {
        goToUpgrade() {
            this.$router.push({ name: 'upgrade' });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .expired-page {
        @include container;
        min-height: 100%;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: $gp * 4 0;
        text-align: center;
    }

    .expired-message {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    img {
        max-width: px-to-rem(150px);
        margin-bottom: $gp * 2;
    }

    p {
        font-size: $font-size-lg;
        max-width: px-to-rem(600px);
        line-height: 1.5;
    }

    button {
        margin: $gp 0;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "All good things must come to an end",
        "expired": "Your free trial has expired! Upgrade to our paid version here or call our sales team to unlock {productName} for your business!",
        "partner": "Your free trial has expired! Please contact your {productName} Partner to upgrade."
    }
}
</i18n>
