import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import SentryFullStory from '@sentry/fullstory';

const severity = {
    FATAL: Sentry.Severity.Fatal,
    ERROR: Sentry.Severity.Error,
    WARNING: Sentry.Severity.Warning,
    INFO: Sentry.Severity.Info,
    DEBUG: Sentry.Severity.Debug,
};

export default {
    init(Vue) {
        try {
            Sentry.init({
                // Attempt to add extra properties from unhandled promise rejections
                // to event before sending to Sentry.
                beforeSend: (event, hint) => {
                    event.extra = event.extra || {};

                    if (hint.originalException) {
                        // ignore vue router exceptions for navigation duplicated
                        if (hint.originalException.name === 'NavigationDuplicated') {
                            return null;
                        }

                        // Assign enumerable error properties to extras
                        try {
                            const keys = Object.keys(hint.originalException);

                            if (keys.length > 0) {
                                event.extra.errorProperties = {};

                                keys.forEach((key) => {
                                    event.extra.errorProperties[key] = hint.originalException[key];
                                });
                            }
                        } catch (error) {
                            this.log('Failed to assign enumerable error properties to extras');
                        }
                    }

                    return event;
                },
                dsn: process.env.VUE_APP_SENTRY_KEY,
                environment: process.env.VUE_APP_ENV,
                ignoreErrors: [
                    /^Request failed with status code \d{3}$/,
                    /Object Not Found Matching Id:\d+/,
                ],
                integrations: [
                    new Integrations.Vue({ Vue }),
                    new SentryFullStory(process.env.SENTRY_ORG),
                ],
                release: process.env.VUE_APP_SENTRY_RELEASE,
                denyUrls: [
                    // Ignore Pendo, Amplitude and FullStory errors
                    /cdn\.pendo\.io/i,
                    /api\.amplitude\.com/i,
                    /rs\.fullstory\.com/i,
                ],
            });
        } catch (e) {
            // swallow sentry failures
        }
    },

    setUser({ casId }) {
        try {
            if (casId) {
                Sentry.configureScope((scope) => {
                    scope.setUser({
                        id: casId,
                    });
                });
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    log(message, data = {}, level = severity.WARNING) {
        try {
            if (process.env.VUE_APP_LOGGING !== 'disabled') {
                Sentry.withScope((scope) => {
                    scope.setLevel(level);

                    Object.keys(data).forEach((key) => {
                        scope.setExtra(key, data[key]);
                    });

                    Sentry.captureMessage(message, { level });
                });
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    captureException(error) {
        try {
            if (process.env.VUE_APP_LOGGING !== 'disabled') {
                Sentry.captureException(error);
            }
        } catch (e) {
            // swallow sentry failures
        }
    },

    severity,
};
