export const LOAD_CONTACT_RECORD = ({ dispatch, commit }, id) => {
    commit('LOAD_CONTACT_RECORD_START');

    const promises = Promise.all([
        dispatch('LOAD_CONTACT_PAGE', id),
        dispatch('LOAD_CONTACT_DETAILS', id),
    ]);

    dispatch('LOAD_CONTACT_MARKETABILITY', id);

    return promises
        .then(() => {
            commit('LOAD_CONTACT_RECORD_SUCCESS');
        })
        .catch((error) => {
            commit('LOAD_CONTACT_RECORD_ERROR', error);
            throw error;
        });
};

// Silently refreshes contact record data
export const REFRESH_CONTACT_RECORD = ({ dispatch, commit }, id) => {
    const promises = Promise.all([
        dispatch('LOAD_CONTACT_PAGE', id),
        dispatch('LOAD_CONTACT_DETAILS', id),
    ]);

    dispatch('LOAD_CONTACT_MARKETABILITY', id);

    return promises
        .then(() => {
            commit('LOAD_CONTACT_RECORD_SUCCESS');
        })
        .catch((error) => {
            commit('LOAD_CONTACT_RECORD_ERROR', error);
            throw error;
        });
};
