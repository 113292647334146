<template>
    <ds-tooltip position="top" :disabled="isOpen">
        <template #reference>
            <ds-popover
                position="top-start"
                hide-arrow
                sticky
                :is-open="isOpen"
                @close="isOpen = false"
            >
                <template #reference>
                    <ds-icon-button
                        data-qa="review-button"
                        :class="{ 'button-disabled': !hasReviewLink }"
                        name="thumbs-up"
                        @click="isOpen = !isOpen"
                    />
                </template>

                <template #default>
                    <div v-if="hasReviewLink" class="reviews-popover">
                        <ds-list-item border-style="none" :description="$t('addReviewLink')" />

                        <template v-if="gmbEnabled">
                            <ds-list-item
                                v-for="(listing, i) in businessListings"
                                :key="i"
                                class="review-link-list-item"
                                :title="listing.name"
                                border-style="none"
                                @click.native="handleReviewLinkInsert(listing.reviewLink)"
                            >
                                <template #shortDescription>
                                    {{ listing.secondaryInfo }}
                                </template>
                            </ds-list-item>
                        </template>

                        <template v-else>
                            <ds-list-item
                                v-for="(link, i) in [reviews_reviewUrl]"
                                :key="i"
                                class="review-link-list-item"
                                :title="reviews && reviews.name"
                                :short-description="reviews && reviews.formatted_address"
                                border-style="none"
                                @click.native="handleReviewLinkInsert(link)"
                            />
                        </template>
                    </div>

                    <template v-else>
                        <ds-icon-button
                            name="x"
                            class="close-button"
                            @click="isOpen = false"
                        />
                        <div class="setup-popover">
                            <ds-illustration name="success-thumbs-up-color" class="thumbs-up-image" />

                            <h4 class="setup-title">
                                {{ $t('setupPopoverTitle') }}
                            </h4>

                            <p class="setup-message">
                                {{ $t('setupPopoverMessage') }}
                            </p>

                            <ds-outline-button v-if="!gmbEnabled" class="setup-reviews-button" @click.native="setupReviews">
                                {{ $t('setupReviews') }}
                            </ds-outline-button>
                        </div>
                    </template>
                </template>
            </ds-popover>
        </template>

        <template #default>
            <div>{{ buttonTooltip }}</div>
        </template>
    </ds-tooltip>
</template>

<script>
import { mapState } from 'vuex';
import { FF_KEAP_GMB } from '@/shared/constants/featureFlag.constants';
import SetupReviews from '@/reviews/components/SetupReviews';
import reviewsMixin from '@/reviews/mixins/reviews.mixin';

export default {
    mixins: [reviewsMixin],

    data() {
        return {
            isOpen: false,
        };
    },

    computed: {
        ...mapState({
            reviews: ({ reviews }) => reviews.reviews,
            gmbEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_GMB],
            googleReviewLink: ({ reviews }) => reviews.googleReviewLink,
            googleBusinessName: ({ reviews }) => reviews.googleBusinessName,
            googleBusinessListings: ({ reviews }) => reviews.googleBusinessListings,
        }),

        hasReviewLink() {
            if (this.gmbEnabled) {
                return Boolean(this.googleReviewLink || this.googleBusinessListings.length);
            }

            return this.reviews_reviewUrl && !this.reviews_reviewUrl.endsWith('DISCONNECTED');
        },

        buttonTooltip() {
            return this.hasReviewLink
                ? this.$t('addReviewLink')
                : this.$t('setupReviews');
        },

        businessListings() {
            return this.googleBusinessListings.map((
                {
                    locationName, metadata: { newReviewUrl }, address, primaryPhone,
                },
            ) => {
                const secondaryInfo = address ? address.addressLines[0] : primaryPhone;

                return { name: locationName, reviewLink: newReviewUrl, secondaryInfo };
            });
        },
    },

    methods: {
        setupReviews() {
            this.$track('Reviews - clicked : Try it out');

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SetupReviews,
                props: {
                    onSuccess: this.popToEmail,
                },
            });
        },

        handleReviewLinkInsert(link) {
            this.isOpen = false;

            this.$emit('review-link-insert', link);
        },

        popToEmail() {
            this.$bus.$emit('POP_NESTED_MODAL', 2);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $thumbs-up-image-size: px-to-rem(125px);

    .button-disabled {
        --icon-color: #{$color-gray-600};
    }

    .reviews-popover {
        margin-bottom: $gp;
    }

    .review-link-list-item {
        cursor: pointer;

        &:hover {
            background-color: $color-gray-050;
        }
    }

    .setup-popover {
        padding: 0 $gp $gp;
        display: flex;
        flex-direction: column;
    }

    .close-button {
        margin: $gp / 2 $gp / 2 0;
    }

    .thumbs-up-image,
    .setup-title,
    .setup-message {
        align-self: center;
        text-align: center;
        margin-bottom: $gp;
    }

    .thumbs-up-image {
        height: $thumbs-up-image-size;
        width: $thumbs-up-image-size;
        margin: -$gp 0 0;
    }

    .setup-message {
        color: $color-gray-700;
    }
</style>

<i18n>
{
    "en-us": {
        "addReviewLink": "Add review link",
        "setupPopoverTitle": "Boost your business with Google Reviews",
        "setupPopoverMessage": "From being found online to earning trust, reviews are a proven source of new business.",
        "setupReviews": "Connect to Google Reviews"
    }
}
</i18n>
