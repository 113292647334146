<template>
    <div class="side-nav-menu-panel" :class="{ 'side-nav-menu-panel-expanded': isExpanded }">
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        isExpanded: Boolean,
    },
};
</script>

<style lang="scss" scoped>
@import "./side-nav-variables";

.side-nav-menu-panel {
    position: fixed;
    top: 0;
    bottom: 0;
    width: $side-nav-width-expanded;
    @include padding(px-to-rem(52) $gp * 2 0 ($side-nav-width-collapsed + px-to-rem(26)));
    @include border-radius(0 $border-radius $border-radius 0);
    transform: translateX(-1 * $side-nav-width-expanded);
    @include transition(transform);
    will-change: transform;
    z-index: $side-nav-z-index-panel-under;
    overflow-x: hidden;
    @include overflowY;
    color: $color-paper;
    background: $side-nav-color-primary;
}

.side-nav-menu-panel-expanded {
    transform: translateX(0);
}
</style>
