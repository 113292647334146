<template>
    <div data-qa="template-tab" class="template-tab">
        <header class="template-gallery-header">
            <h4 class="stage-title">
                {{ $t('title') }}
            </h4>
            <ds-tab-header>
                <li
                    class="tab"
                    data-qa="gallery-tab"
                    :class="{ active: isGallery }"
                    @click="selectGallery"
                >
                    {{ $t("gallery") }}
                </li>

                <li
                    class="tab"
                    data-qa="saved-tab"
                    :class="{ active: isSaved }"
                    @click="selectSaved"
                >
                    {{ $t("myTemplates") }}
                </li>

                <li
                    class="tab"
                    data-qa="published-tab"
                    :class="{ active: isPublished }"
                    @click="selectPublished"
                >
                    {{ $t("published") }}
                </li>
            </ds-tab-header>
        </header>
        <template-gallery
            v-show="isGallery"
            data-qa="template-gallery"
            @select-pending="(info) => $emit('select-pending', info)"
            @select="(template) => $emit('select', template)"
        />

        <template-saved
            v-show="isSaved"
            data-qa="template-saved"
            :status="$options.SITE_STATUS.SAVED"
            @select="(template) => $emit('select', template)"
            @select-pending="(info) => $emit('select-pending', info)"
        />

        <template-saved
            v-show="isPublished"
            data-qa="template-published"
            :status="$options.SITE_STATUS.PUBLISHED"
            @select="(template) => $emit('select', template)"
            @select-pending="(info) => $emit('select-pending', info)"
        />
    </div>
</template>

<script>

import TemplateGallery from './TemplateGallery';
import TemplateSaved from './TemplateSaved';
import { TEMPLATE_TABS, SITE_STATUS } from '@/marketingSites/marketingSites.constants';

export default {
    SITE_STATUS,
    components: {
        TemplateSaved,
        TemplateGallery,
    },

    data() {
        return {
            tab: TEMPLATE_TABS.GALLERY,
        };
    },

    computed: {
        isGallery() {
            return this.tab === TEMPLATE_TABS.GALLERY;
        },

        isSaved() {
            return this.tab === TEMPLATE_TABS.SAVED;
        },

        isPublished() {
            return this.tab === TEMPLATE_TABS.PUBLISHED;
        },
    },

    methods: {
        selectGallery() {
            this.tab = TEMPLATE_TABS.GALLERY;
        },

        selectSaved() {
            this.tab = TEMPLATE_TABS.SAVED;
        },

        selectPublished() {
            this.tab = TEMPLATE_TABS.PUBLISHED;
        },
    },

};
</script>

<style lang="scss" scoped>
    .template-tab {
        height: 100%;
        display: flex;
        flex-direction: column;
        background: $color-gray-050;
    }
    .template-gallery-header {
        border-bottom: 1px solid $color-gray-200;
        background: $color-paper;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: sticky;
        top: 0;
        z-index: 5;
        padding: 0 $gp;
    }

    .stage-title {
        font-weight: 700;
        text-align: center;
        margin: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Select a template",
        "gallery": "Gallery",
        "myTemplates": "My Templates",
        "published": "Published"
    }
}
</i18n>
