export function siteStages({
    designError,
    chooseTemplate,
    createStep,
    sendError,
    publish,
} = {}, stage = 0) {
    return [
        {
            errorTooltip: designError,
            label: chooseTemplate,
            showError: false,
            status: stage > 0 ? STAGE_STATUS.COMPLETE : STAGE_STATUS.IN_PROGRESS,
        },
        {
            errorTooltip: designError,
            label: createStep,
            showError: false,
            status: stage > 1 ? STAGE_STATUS.COMPLETE : STAGE_STATUS.IN_PROGRESS,
        },
        {
            errorTooltip: sendError,
            label: publish,
            showError: false,
            status: STAGE_STATUS.IN_PROGRESS,
        }];
}

export const SITE_WIZARD_STAGE = {
    UNKNOWN: -1,
    CHOOSE_TEMPLATE: 0,
    CREATE: 1,
    PUBLISH: 2,
};

export const STAGE_STATUS = {
    IN_PROGRESS: 'inProgress',
    COMPLETE: 'complete',
    INVALID: 'invalid',
};
