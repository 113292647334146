import axios from 'axios';
import Vue from 'vue';
import gql from 'graphql-tag';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import { displayFullName } from '@/contacts/contact-info-utils';

export default {
    LOAD_DASHBOARD_TASKS(context, payload) {
        return loadDashboardTasks(context, payload);
    },

    LOAD_TODOS: (context, isInitialLoad) => {
        return loadToDos(context, isInitialLoad);
    },

    LOAD_ACTIVITY_FEED(context, payload) {
        return loadActivityFeed(context, payload);
    },

    LOAD_CUSTOM_DASHBOARD: async ({ commit, rootState }) => {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        const hiddenDashboardWidgets = await fetchHiddenDashboardWidgets(appId, userId);
        const customDashboard = await fetchCustomDashboard(appId, userId);
        const dashboardConfig = await fetchDashboardConfig(appId, userId);
        const sharedWidgets = await loadSharedWidgets(appId);

        commit('SET_HIDDEN_DASHBOARD_WIDGETS', hiddenDashboardWidgets);
        commit('SET_CUSTOM_DASHBOARD', customDashboard);
        commit('SET_DASHBOARD_CONFIG', dashboardConfig);
        commit('SET_SHARED_WIDGETS', sharedWidgets);
    },

    HIDE_DASHBOARD_WIDGET: async (context, widgetKey) => {
        const { hiddenDashboardWidgets } = context.state;

        Vue.set(hiddenDashboardWidgets, widgetKey, true);

        return updateHiddenDashboardWidgets(context, hiddenDashboardWidgets);
    },

    UPDATE_HIDDEN_DASHBOARD_WIDGETS: async (context, hiddenDashboardWidgets) => {
        const hiddenWidgets = {};

        Object.keys(hiddenDashboardWidgets).forEach((key) => {
            if (hiddenDashboardWidgets[key]) {
                hiddenWidgets[key] = true;
            }
        });

        return updateHiddenDashboardWidgets(context, hiddenWidgets);
    },

    UPDATE_CUSTOM_DASHBOARD_WIDGET: async(context, widget) => {
        return updateCustomDashboardWidget(context, widget);
    },

    UPDATE_SHARED_WIDGETS: async(context, widget) => {
        return updateSharedWidgets(context, widget);
    },

    UPDATE_SHARED_DASHBOARD_WIDGET: async(context, widget) => {
        return updateSharedDashboardWidget(context, widget);
    },

    UPDATE_DASHBOARD_CONFIG: async (context, config) => {
        return updateDashboardConfig(context, config);
    },

    DELETE_CUSTOM_DASHBOARD_WIDGET: async (context, key) => {
        return deleteCustomDashboardWidget(context, key);
    },

    DELETE_SHARED_WIDGET: async(context, key) => {
        return deleteSharedWidget(context, key);
    },

    LOAD_CONTACT_TOTAL: async (context) => {
        return loadContactTotal(context);
    },

    LOAD_SHARED_WIDGET_USERS: async(context, widgetId) => {
        return loadSharedWidgetUsers(context, widgetId);
    },
};

const loadDashboardTasks = ({ commit }) => {
    return new Promise((resolve, reject) => {
        const params = 'limit=10&offset=0&completed=false';
        const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/tasks?${params}`;

        return axios.get(url)
            .then(({ data: { tasks } }) => {
                const sorted = tasks.sort((taskA, taskB) => new Date(taskA.dueDate) - new Date(taskB.dueDate));

                commit('SET_DASHBOARD_TASKS', sorted);

                resolve();
            })
            .catch(reject);
    });
};

const loadActivityFeed = () => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query activityFeed {
                activityFeed(limit: 10) {
                    activityEvents {
                        id
                        accountId
                        type
                        details {
                            title
                            location
                            startDate
                            endDate
                            eventAuthorId
                            eventAuthorEmailAddress
                            eventAuthorGivenName
                            previousStageName
                            subject
                            body
                            noteBody
                            currentStageName
                            updateNoteBody
                            contactId
                            contact {
                                givenName
                                familyName
                            }
                        }
                        originParentReferenceId
                        originParentReferenceType
                        createdDate
                        publishRequestDate
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
    });
};

const fetchHiddenDashboardWidgets = async (appId, userId) => {
    const snapshot = await firebase.database().ref(`hiddenDashboardWidgets/${appId}/${userId}`).once('value');

    return snapshot.val() ? { ...snapshot.val() } : {};
};

const fetchCustomDashboard = async (appId, userId) => {
    const snapshot = await firebase.database().ref(`customDashboard/${appId}/${userId}`).once('value');

    return snapshot.val() ? { ...snapshot.val() } : {};
};

const fetchDashboardConfig = async (appId, userId) => {
    const snapshot = await firebase.database().ref(`dashboardConfig/${appId}/${userId}`).once('value');

    return snapshot.val() ? { ...snapshot.val() } : {};
};

const updateHiddenDashboardWidgets = async ({ commit, rootState }, hiddenWidgets) => {
    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;

    commit('SET_HIDDEN_DASHBOARD_WIDGETS', hiddenWidgets);

    return firebase.database().ref(`hiddenDashboardWidgets/${appId}/${userId}`).set(hiddenWidgets);
};

const updateCustomDashboardWidget = async ({ commit, rootState }, widget) => {
    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;

    let updatedWidget = {};

    const customDashboardRef = firebase.database().ref(`customDashboard/${appId}/${userId}`);

    if (widget.id) {
        customDashboardRef.child(widget.id).update(widget);
        updatedWidget = widget;
    } else {
        const newWidgetRef = customDashboardRef.push();

        updatedWidget = {
            id: newWidgetRef.key,
            ...widget,
        };

        newWidgetRef.set(updatedWidget);
    }

    commit('UPDATE_CUSTOM_DASHBOARD_WIDGET', updatedWidget);
};

const updateSharedDashboardWidget = async ({ commit, rootState }, widget) => {
    const appId = rootState.auth.session.coreAppId;

    let updatedWidget = {};

    const sharedDashboardRef = firebase.database().ref(`customDashboard/${appId}/sharedWidgets`);

    sharedDashboardRef.child(widget.id).update(widget);
    updatedWidget = widget;

    commit('UPDATE_SHARED_DASHBOARD_WIDGET', updatedWidget);
};

const updateDashboardConfig = async ({ commit, rootState }, dashboardConfig) => {
    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;

    commit('SET_DASHBOARD_CONFIG', dashboardConfig);

    return firebase.database().ref(`dashboardConfig/${appId}/${userId}`).set(dashboardConfig);
};

const deleteCustomDashboardWidget = async ({ commit, rootState }, key) => {
    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;

    firebase.database().ref(`customDashboard/${appId}/${userId}/${key}`).remove();

    commit('DELETE_CUSTOM_DASHBOARD_WIDGET', key);
};

const loadContactTotal = async ({ commit }) => {
    const countUrl = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/count`;

    commit('LOAD_CONTACT_TOTAL_START');

    try {
        const { data } = await axios.get(countUrl);

        commit('LOAD_CONTACT_TOTAL_SUCCESS', data);
    } catch (e) {
        commit('LOAD_CONTACT_TOTAL_ERROR');
    }
};

const loadSharedWidgets = async (appId) => {
    const snapshot = await firebase.database().ref(`customDashboard/${appId}/sharedWidgets`).once('value');

    return snapshot.val() ? { ...snapshot.val() } : {};
};

const updateSharedWidgets = async ({ commit, rootState }, sharedWidget) => {
    const appId = rootState.auth.session.coreAppId;
    let updatedWidget = {};

    const sharedWidgetsRef = firebase.database().ref(`customDashboard/${appId}/sharedWidgets`);

    if (sharedWidgetsRef.child(sharedWidget.id)) {
        sharedWidgetsRef.child(sharedWidget.id).set(sharedWidget);
        updatedWidget = sharedWidget;
    } else {
        const newWidgetRef = sharedWidgetsRef.push();

        updatedWidget = {
            id: newWidgetRef.key,
            ...sharedWidget,
        };

        newWidgetRef.set(updatedWidget);

        commit('UPDATE_SHARED_DASHBOARD_WIDGET', updatedWidget);
    }
};

const deleteSharedWidget = async ({ commit, rootState }, key) => {
    const appId = rootState.auth.session.coreAppId;

    firebase.database().ref(`customDashboard/${appId}/sharedWidgets/${key}`).remove();

    commit('DELETE_SHARED_DASHBOARD_WIDGET', key);
};

const loadSharedWidgetUsers = async ({ commit, rootState }, widgetId) => {
    const appId = rootState.auth.session.coreAppId;
    const snapshot = await firebase.database().ref(`customDashboard/${appId}/sharedWidgets/${widgetId}/sharedWith`).once('value');

    commit('SET_SHARED_WIDGET_USERS', snapshot.val() ? { ...snapshot.val() } : {});
};

const loadToDos = async ({
    commit,
    dispatch,
    rootState,
}, isInitialLoad = false) => {
    const userId = rootState.auth.user.id;
    const RANGE_DAYS = 7;
    const range = {
        startDate: moment(),
        endDate: moment().add(RANGE_DAYS, 'days').endOf('day'),
    };

    try {
        if (isInitialLoad) {
            commit('LOAD_TODOS_START');
        }

        if (!rootState.stateInitialized) {
            await dispatch('calendar/INITIALIZE_STATE', null, { root: true });
        }

        const appointments = await dispatch('calendar/LOAD_CALENDAR_APPTS', {
            dateRange: range,
            useBriefApi: false,
        }, { root: true });

        const tasks = await dispatch('tasks/LOAD_APP_TASKS_BY_DATE', {
            completed: false,
            assignedUserId: userId,
            since: '',
            until: range.endDate,
        }, { root: true });

        const appointmentsFormatted = appointments
            .map((appointment) => {
                const appointmentContact = appointment.attendees.length && appointment.attendees[0];

                return {
                    id: appointment.id,
                    toDoType: 'APPOINTMENT',
                    header: appointment.summary,
                    contact: appointmentContact,
                    contactFullName: appointmentContact?.displayName,
                    completed: false,
                    dueDate: appointment.startDate,
                    startDate: appointment.startDate,
                    endDate: appointment.endDate,
                    appointment,
                };
            });

        const tasksFormatted = tasks.filter((task) => !task.completionDate).map((task) => {
            return {
                id: task.id,
                toDoType: 'TASK',
                header: task.title,
                contact: task.contact,
                contactFullName: task.contact && displayFullName(task.contact),
                completed: false,
                dueDate: task.dueDate,
                completionDate: task.completionDate,
                task,
            };
        });

        const toDos = [
            ...appointmentsFormatted,
            ...tasksFormatted,
        ];

        commit('LOAD_TODOS_SUCCESS', toDos);
    } catch (e) {
        commit('LOAD_TODOS_ERROR');
    }
};
