<template lang="html">
    <section class="deal-status">
        <ds-select-field
            v-model="localStatus"
            data-qa="deal-status"
            name="deal-status"
            :label="$t('label')"
            bind-value-only
            :options="dealStatusOptions"
            @input="updateStatus"
        />
    </section>
</template>

<script>
import { mapGetters } from 'vuex';

export const STATUS_OPTIONS = {
    ACTIVE: 'ACTIVE',
    WON: 'WON',
    LOST: 'LOST',
};

export default {
    props: {
        status: String,
    },

    data() {
        return {
            localStatus: this.status,
        };
    },

    watch: {
        status(val) {
            this.localStatus = val;
        },
    },

    computed: {
        ...mapGetters({
            dealStatusOptions: 'pipeline/dealStatusOptions',
        }),
    },

    methods: {
        async updateStatus(val) {
            this.$emit('update', val);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .deal-status {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "label": "Deal status"
    }
}
</i18n>
