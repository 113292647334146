<template>
    <portal to="root">
        <controlled-modal
            :is-open="isOpen"
            show-close
            intercept-close
            @close="$emit('close')"
        >
            <template #title>
                <slot name="title" />
            </template>

            <template #default>
                <slot name="description" />

                <custom-field-edit-form
                    v-if="isOpen"
                    :custom-field="customField"
                    :update-custom-field="updateCustomField"
                    :delete-custom-field="deleteCustomField"
                    :object-name="objectName"
                    :parent-name="parentName"
                    :custom-fields="customFields"
                    :type-prop-name="typePropName"
                    :values-prop-name="valuesPropName"
                    :name-prop-name="namePropName"
                    :record-type="recordType"
                    is-modal-context
                    @close="$emit('close')"
                    @updated="$emit('updated')"
                />
            </template>
        </controlled-modal>
    </portal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';
import CustomFieldEditForm from '@/customFields/components/CustomFieldEditForm.vue';

import { CUSTOM_FIELD_RECORD_TYPES } from '@/customFields/customFields.constants';

export default {
    components: {
        ControlledModal,
        CustomFieldEditForm,
    },

    props: {
        isOpen: Boolean,
        customField: Object,
        deleteCustomField: Function,
        updateCustomField: Function,
        objectName: String,
        parentName: String,
        typePropName: String,
        customFields: Array,
        valuesPropName: String,
        namePropName: String,
        recordType: {
            type: String,
            required: true,
            validator: (value) => CUSTOM_FIELD_RECORD_TYPES.includes(value),
        },
    },
};
</script>
