<template>
    <div class="communication-modal">
        <div class="modal-header">
            <section class="close">
                <ds-icon-button name="x" @click="close" />
            </section>

            <section class="title">
                <h4 :title="title">
                    {{ title }}
                </h4>
            </section>

            <div v-if="keapPhoneEnabled" class="header" data-qa="communication-toggle">
                <ds-button-toggle
                    v-model="sendingMethod"
                    :options="sendingMethods"
                    prevent-single-deselect
                />
            </div>
        </div>

        <single-email-modal
            v-if="showEmailModal"
            :initial-body="initialBody"
            :initial-subject="initialSubject"
            :recipient-list="recipientList"
            :source="source"
        />

        <sms-modal
            v-if="showSmsModal"
            :contact.sync="contact"
            :initial-message="initialSmsMessage"
            @send="handleSend"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';

import SingleEmailModal from '@/contacts/components/email/SingleEmailModal';
import SmsModal from '@/communication/components/SmsModal';
import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';

import { SENDING_METHODS } from '@/communication/communication.constants';

export default {
    components: {
        SingleEmailModal,
        SmsModal,
    },

    mixins: [closeConfirmation],

    props: {
        initialBody: String,
        initialSmsMessage: String,
        initialSubject: String,
        recipientList: {
            default: () => [],
            type: Array,
        },
        source: {
            required: true,
            type: String,
        },
        title: String,
    },

    data() {
        return {
            contact: this.recipientList[0] ? this.recipientList[0] : {},
            sendingMethod: SENDING_METHODS.EMAIL,
            sendingMethods: [
                { label: this.$t('global.email'), value: SENDING_METHODS.EMAIL },
                { label: this.$t('global.text'), value: SENDING_METHODS.SMS },
            ],
        };
    },

    computed: {
        ...mapState({
            keapPhoneAccount: ({ auth }) => auth.account.keapPhoneAccountInfo,
        }),

        keapPhoneEnabled() {
            return Boolean(this.keapPhoneAccount && this.keapPhoneAccount.phoneNumber);
        },

        nestedModal_removeInnerPadding() {
            return true;
        },

        nestedModal_showBack() {
            return false;
        },

        nestedModal_showClose() {
            return false;
        },

        showEmailModal() {
            return this.sendingMethod === SENDING_METHODS.EMAIL;
        },

        showSmsModal() {
            return this.sendingMethod === SENDING_METHODS.SMS;
        },
    },

    methods: {
        close() {
            this.confirmCloseDraft(() => this.$bus.$emit('POP_NESTED_MODAL'));
        },

        handleSend() /* istanbul ignore next */ {
            this.$track('Communication - SMS - Message sent success', { source: this.source });
        },
    },
};
</script>

<style lang="scss" scoped>
    .modal-header {
        position: sticky;
        top: 0;
        z-index: 3;
        background-color: $color-paper;
        border-radius: $border-radius $border-radius 0 0;
        display: flex;
        border-bottom: 1px solid #{$color-gray-200};
    }

    .close {
        padding: $gp / 2;
        --icon-size: #{$gp * 1.5};
    }

    .title {
        flex: 1;
        padding: $gp 0;
        p {
            margin: 0;
        }
    }

    h4 {
        @include ellipsis;
    }

    .header {
        padding: $gp;
    }
</style>
