export const autocompleteFromGooglePlacesApi = (response) => {
    const { predictions } = response || {};

    return Array.isArray(predictions)
        ? predictions.map(({ place_id: id, description }) => ({
            id,
            description,
        }))
        : [];
};

export const autocompleteFromHereApi = (response) => {
    const { items } = response || {};

    return Array.isArray(items)
        ? items.map(({ id, address }) => ({
            id,
            description: address.label,
        }))
        : [];
};

export const detailsFromGooglePlacesApi = (response) => {
    const {
        place_id: id,
        address_components: addressComponents,
        formatted_address: formattedAddress,
        name,
    } = response || {};

    if (!id || !addressComponents) {
        return null;
    }

    const address1 = [];
    const details = {
        id,
        name,
        description: formattedAddress,
        fields: {},
    };

    addressComponents.forEach(({ long_name: longName, short_name: shortName, types }) => {
        if (types.includes('street_number')) {
            address1.unshift(shortName);
        }

        if (types.includes('route')) {
            address1.push(shortName);
        }

        if (types.includes('subpremise')) {
            details.fields.streetAddress2 = longName;
        }

        if (types.includes('locality')) {
            details.fields.locality = longName;
        }

        if (types.includes('postal_code')) {
            details.fields.postalCode = longName;
        }

        if (types.includes('country')) {
            details.fields.countryCode = shortName;
            details.fields.countryName = longName;
        }

        if (types.includes('administrative_area_level_1')) {
            details.fields.region = longName;
        }
    });

    details.fields.streetAddress1 = address1.join(' ');

    return details;
};

export const detailsFromHereApi = (response) => {
    const { id, address, title } = response || {};

    if (!id || !address) {
        return null;
    }

    const details = {
        id,
        description: address?.label,
        fields: {
            countryCode: address?.countryCode,
            countryName: address?.countryName,
            locality: address?.city,
            postalCode: address?.postalCode,
            region: address?.state,
        },
        name: title,
    };

    const streetAddress = [
        address?.houseNumber,
        address?.street,
    ];

    // TODO: HERE's API exposes district, subdistrict, block and subblock fields
    // which may be relevant for international addresses.
    // https://developer.here.com/documentation/geocoding-search-api/api-reference-swagger.html

    details.fields.streetAddress1 = streetAddress.filter((value) => Boolean(value)).join(' ');

    return details;
};
