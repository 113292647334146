<template lang="html">
    <aside :class="['contexual-automation-panel', { open: automationPanelOpen }]">
        <bus-event name="OPEN_AUTOMATIONS_PANEL" @event="openAutomationPanel" />

        <template v-if="automationPanelOpen">
            <header v-if="openView !== $options.VIEWS.PARTICIPANT">
                <template v-if="openView === $options.VIEWS.LIST">
                    <ds-icon-button name="x" data-qa="close-button" @click="closeAutomationPanel" />

                    <h4>{{ $t('title') }}</h4>

                    <div class="button-row">
                        <automation-filter-dropdown class="automation-filter" />

                        <ds-icon-button
                            data-qa="new-easy-automation"
                            class="add-button"
                            name="add-circle"
                            @click="() => createAutomation()"
                        />
                    </div>
                </template>

                <template v-else>
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleCloseBuilder" />

                    <h4>{{ automation.name }}</h4>

                    <automation-list-item-dropdown
                        v-if="openView === $options.VIEWS.QUICKVIEW"
                        :automation="automation"
                        @open="handleOpen(automation)"
                        @delete="handleDelete(automation)"
                        @rename="handleRenameModal(automation)"
                        @edit="editAutomation(automation)"
                        @revert="handleRevert(automation)"
                        @deactivate="handleDisable(automation, true)"
                        @share="handleShare"
                        @copy="handleCopy(automation)"
                    />
                </template>
            </header>

            <template v-if="openView === $options.VIEWS.LIST">
                <automation-filters />

                <automation-list
                    v-model="automations"
                    class="automation-list"
                    @open="handleOpen"
                    @edit="editAutomation"
                    @rename="handleRenameModal"
                    @delete="handleDelete"
                    @revert="(a) => handleRevert(a)"
                    @deactivate="(a) => handleDisable(a, true)"
                    @create-automation="() => createAutomation()"
                    @share="handleShare"
                    @copy="(a) => handleCopy(a)"
                />
            </template>

            <template v-if="isBuilderOpen">
                <build-automation
                    class="builder"
                    :is-quickview="openView === VIEWS.QUICKVIEW"
                    :button-loading="automation_buttonLoading"
                    :open="isBuilderOpen && !loading"
                    :automation.sync="automation"
                    :open-component="openComponent"
                    @close="handleCloseBuilder"
                    @save="save"
                    @open="handleOpenFromQuickview"
                    @publish="publishAutomation"
                    @disable="handleDisable(automation)"
                    @rename="handleBuilderRename"
                    @view-participants="handleViewParticipantList"
                />
            </template>

            <participant-list
                v-if="openView === $options.VIEWS.PARTICIPANT"
                :automation="automation"
                context-type
                class="participant-list content-area"
                @close="handleCloseParticipantList"
            />

            <footer class="footer">
                <ds-outline-button data-qa="close-automations" @click="closeAutomationPanel">
                    {{ $t('global.close') }}
                </ds-outline-button>

                <ds-text-button trailing-icon="arrow-long-right" @click="goToAutomations">
                    {{ $t('goToAutomations') }}
                </ds-text-button>
            </footer>

            <portal to="root">
                <share-template-modal
                    data-qa="share-template-modal"
                    :create-from-automation-id="shareAutomationId"
                    :is-open="Boolean(shareAutomationId)"
                    @close="shareAutomationId = null"
                    @template-created="toastShareModal"
                />
            </portal>

            <portal to="root">
                <automation-rename-modal
                    :automation="automation"
                    :open="isRenameModalOpen"
                    @update="updateAutomationName"
                    @close="isRenameModalOpen = false"
                />
            </portal>

            <div v-if="loading || creating" class="spinner-container">
                <ds-spinner />
            </div>
        </template>
    </aside>
</template>

<script>
import { mapState } from 'vuex';

import AutomationList from '@/automations/components/list/AutomationList';
import AutomationListItemDropdown from '@/automations/components/list/AutomationListItemDropdown';
import BuildAutomation from '@/automations/components/BuildAutomation';
import AutomationRenameModal from '@/automations/components/list/AutomationRenameModal';
import ParticipantList from '@/automations/components/participants/ParticipantList';
import ShareTemplateModal from '@/automations/components/templates/ShareTemplateModal';
import AutomationFilterDropdown from '@/automations/components/filter/AutomationFilterDropdown';
import AutomationFilters from '@/automations/components/filter/AutomationFilters';
import BusEvent from '@/shared/components/BusEvent';

import automationControls from '@/automations/mixins/automation-controls.mixin';

import {
    AUTOMATION_STATUS, CONTEXT_TYPE, OPEN_COMPONENT,
} from '@/automations/constants/automations.constants';
import { EHAWK_NOT_PASSED_ERROR } from '@/ehawk/constants/ehawk.constants';

const VIEWS = {
    LIST: 'LIST',
    QUICKVIEW: 'QUICKVIEW',
    BUILDER: 'BUILDER',
    PARTICIPANT: 'PARTICIPANT',
};

export default {
    components: {
        AutomationListItemDropdown,
        AutomationList,
        AutomationRenameModal,
        BuildAutomation,
        ParticipantList,
        ShareTemplateModal,
        AutomationFilterDropdown,
        AutomationFilters,
        BusEvent,
    },

    mixins: [automationControls],

    props: {
        isOpen: Boolean,
        settings: {
            type: Object,
            default: () => ({}),
        },
    },

    VIEWS,

    data() {
        return {
            automationPanelOpen: false,
            automations: [],
            automation: {},
            loading: false,
            creating: false,
            openComponent: null,
            openView: VIEWS.LIST,
            isRenameModalOpen: false,
            shareAutomationId: null,
            VIEWS,
        };
    },

    watch: {
        '$route.name': {
            handler(to) {
                if (this.contextType && to.indexOf('automations') >= 0) {
                    if (this.openView === VIEWS.BUILDER) {
                        this.$toast({ message: this.$t('saved') });
                    }
                    this.closeAutomationPanel();

                    if (this.automationFilters.length) {
                        this.$store.commit('automations/SET_AUTOMATION_FILTERS', []);
                    }
                }
            },
        },

        isOpen(val) {
            if (val && !this.settings?.createAutomation?.name) {
                this.openAutomationPanel(this.settings);
            }
        },

        settings: {
            handler(settings) {
                if (settings?.automationId) {
                    this.handleOpen({ automation: { id: settings.automationId } }, false);
                } else if (settings?.createAutomation?.name) {
                    this.openAutomationPanel(settings);
                } else if (!settings.createAutomation && !settings.automationId) {
                    this.handleCloseBuilder();
                }
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            contextType: ({ automations }) => automations.context?.type,
            automationFilters: ({ automations }) => automations.automationFilters,
        }),

        isBuilderOpen() {
            return this.openView === VIEWS.QUICKVIEW || this.openView === VIEWS.BUILDER;
        },
    },

    methods: {
        async openAutomationPanel({ context, createAutomation } = { context: { type: CONTEXT_TYPE.UNKNOWN } }) {
            if (this.automationPanelOpen) {
                this.closeAutomationPanel();
            }

            this.$store.commit('automations/SET_CONTEXT', context);
            this.automationPanelOpen = true;

            if (createAutomation?.name) {
                await this.createAutomation({
                    ...this.automation_defaultAutomation,
                    ...createAutomation,
                });

                this.openComponent = createAutomation.triggers ? null : OPEN_COMPONENT.TRIGGER;
            }
        },

        closeAutomationPanel() {
            this.$emit('close');
            this.$bus.$emit('CONTEXTUAL_AUTOMATION_CLOSED', this.contextType);

            this.$store.commit('automations/SET_CONTEXT', {});
            this.automationPanelOpen = false;
            this.resetBuilder();
        },

        resetBuilder() {
            this.automation = {};
            this.automations = [];
            this.openView = VIEWS.LIST;
            this.openComponent = null;
        },

        handleCloseBuilder() {
            if (this.isBuilderOpen && this.automationPanelOpen) {
                this.resetBuilder();
            }
        },

        async publishAutomation() {
            const success = await this.automation_publish(this.automation);

            this.$bus.$emit('CONTEXTUAL_AUTOMATION_STATUS_CHANGED');

            if (success) {
                this.$toast({
                    message: this.$t('automation.success.publishing'),
                });
                this.handleCloseBuilder();
            }
        },

        async save() {
            const updatedAutomation = await this.automation_save(this.automation);

            if (updatedAutomation && updatedAutomation?.hasChanges !== this.automation?.hasChanges) {
                this.$set(this.automation, 'hasChanges', updatedAutomation.hasChanges);
            }
        },

        async handleBuilderRename(name) {
            this.$emit('renamed', { id: this.automation.id, name });
            await this.automation_updateNameAndList({ id: this.automation.id, name }, false);
        },

        async updateAutomationName(automation) {
            const success = await this.automation_updateNameAndList(automation);

            if (success) {
                this.$set(this.automation, 'name', automation.name);
                this.$emit('renamed', { id: automation.id, name: automation.name });
            }
        },

        async createAutomation(automation) {
            try {
                await this.$store.dispatch('ehawk/CHECK_EHAWK', { eventSource: 'easy automation', modalTitle: this.$t('eHawkModalTitle') });
            } catch (e) {
                if (e.message !== EHAWK_NOT_PASSED_ERROR) {
                    this.$error({ message: this.$t('ehawk.errorMessage') });
                }

                return;
            }

            this.creating = true;

            const createdAutomation = await this.automation_create(automation);

            if (createdAutomation) {
                this.$emit('created');
                await this.handleOpen({ automation: { id: createdAutomation?.id } }, false);
            }

            this.creating = false;
        },

        async handleDelete({ name, id }) {
            const { list, success } = await this.automation_confirmDelete({ id, name }, this.automations);

            if (success) {
                this.$bus.$emit('CONTEXTUAL_AUTOMATION_STATUS_CHANGED');
                this.automations = list;

                if (this.openView === VIEWS.QUICKVIEW) {
                    this.handleCloseBuilder();
                }
            }
        },

        handleRenameModal(automation = {}) {
            this.automation = automation;
            this.isRenameModalOpen = true;
        },

        async handleRevert(automation) {
            await this.automation_confirmRevert(automation);

            this.$bus.$emit('CONTEXTUAL_AUTOMATION_STATUS_CHANGED');
        },

        async handleOpen({ automation }, showQuickview = true) {
            this.openView = showQuickview ? VIEWS.QUICKVIEW : VIEWS.BUILDER;
            this.openComponent = null;

            this.loading = true;
            this.automation = await this.automation_load(automation.id);
            this.loading = false;
        },

        async handleDisable(automation, confirm = false) {
            const success = confirm
                ? await this.automation_confirmDisable(automation)
                : await this.automation_disableAndUpdateList(automation);

            if (success) {
                this.$bus.$emit('CONTEXTUAL_AUTOMATION_STATUS_CHANGED');

                this.$set(this.automation, 'status', AUTOMATION_STATUS.DISABLED);
                this.$store.commit('automations/SET_AUTOMATION', this.automation);
            }
        },

        async handleCopy(automation) {
            this.creating = true;

            const newAutomation = await this.automation_copy(automation);

            if (newAutomation) {
                this.$bus.$emit('CONTEXTUAL_AUTOMATION_STATUS_CHANGED');
                this.handleOpen({ automation: { id: newAutomation?.id } }, false);
            }

            this.creating = false;
        },

        async editAutomation({ id }) {
            this.automation = await this.automation_load(id) || {};

            this.openView = VIEWS.BUILDER;
        },

        goToAutomations() {
            this.closeAutomationPanel();
            this.$router.push({ name: 'automations.easy' });
        },

        handleOpenFromQuickview({ openComponent }) {
            this.openComponent = openComponent;
            this.openView = VIEWS.BUILDER;
        },

        handleViewParticipantList() {
            this.openView = VIEWS.PARTICIPANT;
        },

        handleCloseParticipantList() {
            this.openView = VIEWS.QUICKVIEW;
        },

        toastShareModal() {
            this.$toast({
                message: this.$t('templateCreated'),
                action: () => {
                    this.shareAutomationId = null;
                    this.goToAutomations();
                },
                actionText: this.$t('templateAction'),
            });
        },

        handleShare(automation) {
            this.shareAutomationId = automation.id;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $panel-footer-height: px-to-rem(72px);

    .contexual-automation-panel {
        @include side-panel;

        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .builder {
        height: 100%;
    }

    header {
        display: flex;
        align-items: center;
        padding: 0 $gp;
        height: $nav-height;
        min-height: $nav-height;
        border-bottom: 1px solid $color-gray-200;

        h4 {
            flex: 1;
            margin: 0 $gp / 2;
        }
    }

    footer {
        position: fixed;
        padding: $gp;
        left: 0;
        bottom: 0;
        background-color: $color-paper;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $color-gray-200;
        height: $panel-footer-height;
        box-shadow: $elevation-z8;
        z-index: 3;
    }

    .automation-list {
        margin-bottom: $panel-footer-height;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "My automations",
        "goToAutomations": "Go to automations",
        "templateCreated": "Shared template created.",
        "templateAction": "Go to templates",
        "saved": "All changes saved.",
        "eHawkModalTitle": "Before you create automations, tell us about your business"
    }
}
</i18n>
