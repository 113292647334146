<script>
export default {
    props: {
        message: {
            type: String,
            default: '',
        },
    },

    mounted() {
        this.$error({ message: this.message });
    },

    render() {
        return null;
    },
};
</script>
