<template>
    <nav>
        <side-nav-menu-primary-panel
            :nav="nav"
            :user="user"
            :is-expandable="isSecondaryExpandable"
            :is-active="isActive"
            @expand="onExpand"
            @expand-search="onExpandSearch"
        />

        <side-nav-menu-secondary-panel
            :secondary="activeSecondary"
            :is-expanded="isSecondaryExpanded"
            @collapse="onCollapse"
        />

        <side-nav-menu-search-panel
            :is-expanded="isSearchPanelExpanded"
            @collapse="onCollapseSearch"
        />
    </nav>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import SideNavMenuPrimaryPanel from './SideNavMenuPrimaryPanel';
import SideNavMenuSecondaryPanel from './SideNavMenuSecondaryPanel';
import SideNavMenuSearchPanel from './SideNavMenuSearchPanel';

import { FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS } from '@/shared/constants/featureFlag.constants';

const trailingSlashRE = /\/?$/;

function isIncludedRoute (current, target) {
    return current.path.replace(trailingSlashRE, '/').indexOf(target.path.replace(trailingSlashRE, '/')) === 0;
}

export default {
    components: {
        SideNavMenuPrimaryPanel,
        SideNavMenuSecondaryPanel,
        SideNavMenuSearchPanel,
    },

    props: {
        user: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            isSecondaryExpandedPreferred: false,
            isSearchPanelExpanded: false,
        };
    },

    watch: {
        /**
         * Using a watcher here for two reasons:
         * - This is inteneded to sync local state to the store rather than being the source of truth.
         * - The computed logic is the source of truth and duplicating this in methods would make it more complex.
         */
        hasExpandedPanel(isExpanded) {
            if (isExpanded) {
                this.$store.commit('SIDE_NAV_MENU_EXPANDED');
            } else {
                this.$store.commit('SIDE_NAV_MENU_COLLAPSED');
            }
        },
    },

    computed: {
        ...mapState({
            isCustomFieldSettingsEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS],
        }),

        ...mapGetters({
            hasAppointmentsFeature: 'auth/hasAppointmentsFeature',
            hasAnalyticsFeature: 'auth/hasAnalyticsFeature',
            hasAnyPipelineFeature: 'pipeline/hasAnyPipelineFeature',
            hasCampaignBuilderFeature: 'auth/hasCampaignBuilderFeature',
            hasCheckoutFormsFeature: 'auth/hasCheckoutFormsFeature',
            hasCompaniesFeature: 'auth/hasCompaniesFeature',
            hasEasyAutomationsFeature: 'auth/hasEasyAutomationsFeature',
            hasEmailBroadcastFeature: 'auth/hasEmailBroadcastFeature',
            hasFormsFeature: 'auth/hasFormsFeature',
            hasInvoicesAndQuotesFeature: 'auth/hasInvoicesAndQuotesFeature',
            hasLandingPagesFeature: 'auth/hasLandingPagesFeature',
            hasOnboardingAndGettingStartedFeature: 'auth/hasOnboardingAndGettingStartedFeature',
            hasRecurringPaymentsFeature: 'auth/hasRecurringPaymentsFeature',
            hasReportsFeature: 'auth/hasReportsFeature',
            showCommunicationCenter: 'communication/showCommunicationCenter',
            showSmsBroadcast: 'smsBroadcasts/showSmsBroadcast',
        }),

        home() {
            return {
                id: 'home',
                primary: {
                    to: { path: '/dashboard' },
                    text: this.$t('nav.home'),
                    icon: 'home',
                },
                secondary: {
                    heading: this.$t('nav.home'),
                    links: [
                        {
                            to: { path: '/dashboard' },
                            text: this.$t('nav.dashboard'),
                            enabled: true,
                        },
                        {
                            to: { path: '/getting-started' },
                            text: this.$t('nav.getting.started'),
                            enabled: this.hasOnboardingAndGettingStartedFeature,
                        },
                    ],
                },
                enabled: true,
            };
        },

        contacts() {
            const primary = {
                to: { path: '/contacts' },
                text: this.$t('nav.contacts'),
                icon: 'contacts-users',
            };

            const secondary = {
                heading: this.$t('nav.contacts'),
                links: [
                    {
                        to: { path: '/contacts/list/all' },
                        text: this.$t('nav.contacts.people'),
                        enabled: true,
                    },
                    {
                        to: { path: '/contacts/companies' },
                        text: this.$t('nav.contacts.companies'),
                        enabled: this.hasCompaniesFeature,
                    },
                    {
                        to: { path: '/contacts/list/view' },
                        text: this.$t('nav.contacts.groups'),
                        enabled: true,
                    },
                ],
                relatedLinks: [
                    {
                        to: { path: '/settings/tags' },
                        text: this.$t('nav.tags'),
                        enabled: true,
                    },
                    {
                        to: { path: '/settings/custom-fields' },
                        text: this.$t('nav.custom.fields'),
                        enabled: this.isCustomFieldSettingsEnabled,
                    },
                    {
                        to: { path: '/forms' },
                        text: this.$t('nav.forms'),
                        enabled: this.hasFormsFeature,
                    },
                ],
            };

            return {
                id: 'contacts',
                primary,
                secondary,
                enabled: true,
                activeRoutes: [
                    { path: '/contacts/list' },
                ],
            };
        },

        myDay() {
            const path = this.hasAppointmentsFeature ? '/appointments' : '/tasks';

            const primary = {
                id: 'myDay',
                to: { path },
                text: this.$t('nav.my.day'),
                icon: 'calendar',
            };

            const secondary = {
                heading: this.$t('nav.my.day'),
                links: [
                    {
                        to: { path: '/appointments' },
                        text: this.$t('nav.appointments'),
                        enabled: this.hasAppointmentsFeature,
                    },
                    {
                        to: { path: '/tasks' },
                        text: this.$t('nav.tasks'),
                        enabled: true,
                    },
                ],
            };

            return {
                primary,
                secondary,
                enabled: true,
            };
        },

        communications() {
            let path;

            if (this.hasEmailBroadcastFeature) {
                path = '/broadcasts/email';
            } else if (this.showCommunicationCenter) {
                path = '/communication';
            } else {
                path = '/broadcasts/text';
            }

            const primary = {
                to: { path },
                text: this.$t('nav.comms'),
                icon: 'message',
            };

            const secondary = {
                heading: this.$t('nav.communications'),
                links: [
                    {
                        to: { path: '/broadcasts/email' },
                        text: this.$t('nav.email.broadcasts'),
                        enabled: this.hasEmailBroadcastFeature,
                    },
                    {
                        to: { path: '/communication' },
                        text: this.$t('nav.text.messages'),
                        enabled: this.showCommunicationCenter,
                    },
                    {
                        to: { path: '/broadcasts/text' },
                        text: this.$t('nav.text.message.broadcasts'),
                        enabled: this.showSmsBroadcast,
                    },
                ],
                relatedLinks: [
                    {
                        to: { path: '/settings/businessProfile' },
                        text: this.$t('nav.business.profile'),
                        enabled: true,
                    },
                    {
                        to: { path: '/settings/email-deliverability' },
                        text: this.$t('nav.email.authentication'),
                        enabled: true,
                    },
                ],
            };

            const enabled = secondary.links.some((link) => link.enabled);

            return {
                id: 'communications',
                primary,
                secondary,
                enabled,
            };
        },

        sales() {
            const path = this.hasAnyPipelineFeature ? '/pipeline' : '/sales';

            const primary = {
                to: { path },
                text: this.$t('nav.sales'),
                icon: 'sales',
            };

            const secondary = {
                heading: this.$t('nav.sales'),
                links: [
                    {
                        to: { path: '/pipeline' },
                        text: this.$t('nav.pipeline'),
                        enabled: this.hasAnyPipelineFeature,
                    },
                    {
                        to: { path: '/sales/quotes' },
                        text: this.$t('nav.quotes'),
                        enabled: this.hasInvoicesAndQuotesFeature,
                    },
                    {
                        to: { path: '/sales/invoices' },
                        text: this.$t('nav.invoices'),
                        enabled: this.hasInvoicesAndQuotesFeature,
                    },
                    {
                        to: { path: '/sales/recurring' },
                        text: this.$t('nav.recurring.payments'),
                        enabled: this.hasRecurringPaymentsFeature,
                    },
                    {
                        to: { path: '/sales/checkout' },
                        text: this.$t('nav.checkout.forms'),
                        enabled: this.hasCheckoutFormsFeature,
                    },
                ],
                relatedLinks: [
                    {
                        to: { path: '/settings/products' },
                        text: this.$t('nav.products.services'),
                        enabled: this.hasInvoicesAndQuotesFeature,
                    },
                    {
                        to: { path: '/settings/billing' },
                        text: this.$t('nav.payment.processing'),
                        enabled: this.hasInvoicesAndQuotesFeature,
                    },
                ],
            };

            const enabled = secondary.links.some((link) => link.enabled);

            return {
                id: 'sales',
                primary,
                secondary,
                enabled,
            };
        },

        marketing() {
            const path = this.hasFormsFeature ? '/forms' : '/marketing-sites';

            const primary = {
                to: { path },
                text: this.$t('nav.marketing'),
                icon: 'megaphone',
            };

            const secondary = {
                heading: this.$t('nav.marketing'),
                links: [
                    {
                        to: { path: '/forms' },
                        text: this.$t('nav.forms'),
                        enabled: this.hasFormsFeature,
                    },
                    {
                        to: { path: '/marketing-sites' },
                        text: this.$t('nav.landing.pages'),
                        enabled: this.hasLandingPagesFeature,
                    },
                ],
                relatedLinks: [
                    {
                        to: { path: '/broadcasts/email' },
                        text: this.$t('nav.email.broadcasts'),
                        enabled: this.hasEmailBroadcastFeature,
                    },
                    {
                        to: { path: '/broadcasts/text' },
                        text: this.$t('nav.text.message.broadcasts'),
                        enabled: this.showSmsBroadcast,
                    },
                    {
                        to: { path: '/sales/checkout' },
                        text: this.$t('nav.checkout.forms'),
                        enabled: this.hasCheckoutFormsFeature,
                    },
                ],
            };

            const enabled = secondary.links.some((link) => link.enabled);

            return {
                id: 'marketing',
                primary,
                secondary,
                enabled,
            };
        },

        automation() {
            const primary = {
                to: { path: '/automations' },
                text: this.$t('nav.automation'),
                icon: 'zap',
            };

            const secondary = {
                heading: this.$t('nav.automation'),
                links: [
                    {
                        to: { path: '/automations/easy' },
                        text: this.$t('nav.easy'),
                        enabled: this.hasEasyAutomationsFeature,
                    },
                    {
                        to: { path: '/automations/advanced' },
                        text: this.$t('nav.advanced'),
                        enabled: this.hasCampaignBuilderFeature,
                    },
                ],
            };

            const enabled = secondary.links.some((link) => link.enabled);

            return {
                id: 'automation',
                primary,
                secondary,
                enabled,
            };
        },

        reports() {
            return {
                id: 'reports',
                primary: {
                    to: { path: '/reports' },
                    text: this.$t('nav.reports'),
                    icon: 'bar-chart-2',
                },
                enabled: this.hasReportsFeature || this.hasAnalyticsFeature,
            };
        },

        nav() {
            const {
                home,
                contacts,
                myDay,
                communications,
                sales,
                marketing,
                automation,
                reports,
            } = this;

            const navItems = [
                home,
                contacts,
                myDay,
                communications,
                sales,
                marketing,
                automation,
                reports,
            ];

            return navItems.filter(({ enabled }) => enabled);
        },

        activeSecondary() {
            const active = this.nav.find((x) => this.isActive(x));

            return active?.secondary;
        },

        isSecondaryExpanded() {
            return this.isSecondaryExpandedPreferred && !!this.activeSecondary;
        },

        isSecondaryExpandable() {
            return !this.isSecondaryExpandedPreferred && !!this.activeSecondary;
        },

        hasExpandedPanel() {
            return this.isSecondaryExpanded || this.isSearchPanelExpanded;
        },
    },

    methods: {
        isActive(config) {
            const currentRoute = this.$route;

            return isIncludedRoute(currentRoute, config.primary.to)
                || config.secondary?.links.some((x) => isIncludedRoute(currentRoute, x.to))
                || config.activeRoutes?.some((x) => isIncludedRoute(currentRoute, x));
        },

        onExpand() {
            this.isSecondaryExpandedPreferred = true;
        },

        onCollapse() {
            this.isSecondaryExpandedPreferred = false;
        },

        onExpandSearch() {
            this.isSearchPanelExpanded = true;
        },

        onCollapseSearch() {
            this.isSearchPanelExpanded = false;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "nav.advanced": "Advanced",
        "nav.appointments": "Appointments",
        "nav.automation": "Automation",
        "nav.business.profile": "Business profile",
        "nav.checkout.forms": "Checkout Forms",
        "nav.communications": "Communications",
        "nav.comms": "Comms",
        "nav.contacts": "Contacts",
        "nav.contacts.companies": "Companies",
        "nav.contacts.groups": "Groups",
        "nav.contacts.people": "People",
        "nav.custom.fields": "Custom fields",
        "nav.dashboard": "Dashboard",
        "nav.easy": "Easy",
        "nav.emails": "Emails",
        "nav.email.authentication": "Email authentication",
        "nav.email.broadcasts": "Email broadcasts",
        "nav.forms": "Forms",
        "nav.getting.started": "Getting Started",
        "nav.home": "Home",
        "nav.landing.pages": "Landing Pages",
        "nav.marketing": "Marketing",
        "nav.my.day": "My Day",
        "nav.payment.processing": "Payment processing",
        "nav.products.services": "Products and services",
        "nav.reports": "Reports",
        "nav.recurring.payments": "Recurring payments",
        "nav.sales": "Sales",
        "nav.tags": "Tags",
        "nav.tasks": "Tasks",
        "nav.text.messages": "Text messages",
        "nav.text.message.broadcasts": "Text message broadcasts"
    }
}
</i18n>
