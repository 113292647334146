import axios from 'axios';

import sentry from '@/analytics/sentry';
import { FF_KEAP_HERE_API_ENABLED } from '@/shared/constants/featureFlag.constants';
import {
    autocompleteFromGooglePlacesApi,
    autocompleteFromHereApi,
    detailsFromGooglePlacesApi,
    detailsFromHereApi,
} from '@/shared/utils/location.util';

export default {
    LOAD_ADDRESS_AUTOCOMPLETE(context, input) {
        return loadAddressAutocomplete(context, input);
    },

    LOAD_PLACE_DETAIL(context, placeId) {
        return loadPlaceDetail(context, placeId);
    },
};

const loadAddressAutocomplete = async ({ rootState: { featureFlags } }, { input, placeType, useGoogleApi }) => {
    try {
        if (!useGoogleApi && featureFlags[FF_KEAP_HERE_API_ENABLED]) {
            const { data } = await axios.get(
                `${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/location/autocomplete?q=${encodeURIComponent(input)}&lang=${navigator.language}`,
            );

            return autocompleteFromHereApi(data);
        }

        const type = placeType
            ? `&types=${placeType}`
            : '';

        const { data } = await axios.get(
            `${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/google/maps/autocomplete?input=${input}${type}`,
        );

        return autocompleteFromGooglePlacesApi(data);
    } catch (error) {
        sentry.log('Address autocomplete failed');
        throw error;
    }
};

const loadPlaceDetail = async ({ rootState: { featureFlags } }, { id, useGoogleApi }) => {
    try {
        if (!useGoogleApi && featureFlags[FF_KEAP_HERE_API_ENABLED]) {
            const { data } = await axios.get(
                `${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/location/lookup?id=${id}&lang=${navigator.language}`,
            );

            return detailsFromHereApi(data);
        }

        const { data: { result } } = await axios.get(
            `${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/google/maps/place-detail?placeid=${id}`,
        );

        return detailsFromGooglePlacesApi(result);
    } catch (error) {
        sentry.log('Load place detail failed');
        throw error;
    }
};
