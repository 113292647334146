<template>
    <div class="template-manager">
        <section class="template-nav">
            <header class="template-nav-header">
                <ds-icon-button
                    name="x"
                    data-qa="close-manager"
                    @click="back"
                />

                <h3 class="header-text">
                    {{ $t('emailTemplates') }}
                </h3>

                <ds-icon-button
                    name="add-circle"
                    data-qa="template-manager-create-template"
                    @click="createTemplate"
                />
            </header>

            <div class="template-items">
                <ds-list-item
                    v-for="(template, i) in sortedSmsTemplates"
                    :key="i"
                    :class="['template-item', { selected: isTemplateSelected(template)} ]"
                    :title="templateTitle(template)"
                    :short-description="templateDescription(template)"
                    @click.native="handleTemplateItemClick(template)"
                />
            </div>
        </section>

        <section v-if="selectedTemplate" :class="['template-editor', { open: editorOpen }]">
            <header class="template-editor-header">
                <ds-icon
                    name="arrow-left"
                    @click.native="setEditorOpen(false)"
                />

                <h4 class="edit-template">
                    {{ $t('editTemplate') }}
                </h4>

                <ds-icon
                    v-if="showDelete"
                    :class="['delete-button-mobile', { 'disable-delete': saving }]"
                    name="trash-2"
                    @click.native="deleteTemplate"
                />
            </header>

            <section :class="['template-editor-title', { 'hide-delete': !showDelete }]">
                <span class="label">{{ $t('titleLabel') }}</span>

                <ds-input-field
                    ref="titleInput"
                    v-model="title"
                    :placeholder="$t('defaultTitle')"
                    @input="handleEdit"
                />

                <ds-icon-button
                    v-if="showDelete"
                    name="trash-2"
                    class="delete-button"
                    :disabled="saving"
                    @click="deleteTemplate"
                />
            </section>

            <textarea
                ref="messageInput"
                v-model="content"
                class="message-content"
                rows="1"
                :placeholder="$t('contentPlaceholder')"
                :maxlength="maxLength"
                @input="handleContentEdit"
            />

            <section class="template-button-row">
                <div class="action-buttons">
                    <appointment-button
                        @setup-appointments="setupAppointments"
                        @appointment-link-insert="insertAppointmentLink"
                    />

                    <merge-field-button @input="insertMergeField" />
                </div>

                <div v-if="saving" class="saving semibold">
                    <ds-icon name="loader" />

                    <span>{{ $t('saving') }}</span>
                </div>

                <div v-if="saved" class="saved">
                    <ds-status type="good" emphasis="medium">
                        {{ $t('saved') }}
                    </ds-status>
                </div>

                <ds-filled-button class="insert-template" @click="insertTemplate">
                    {{ $t('insertTemplate') }}
                </ds-filled-button>

                <ds-filled-button class="insert-template-mobile" @click="insertTemplate">
                    {{ $t('insert') }}
                </ds-filled-button>
            </section>
        </section>

        <section v-if="showFtu" class="ftu">
            <ds-icon name="x" class="close-ftu" @click.native="closeFtu" />

            <svg class="keap-arrow-group">
                <use xlink:href="#keap-arrow-group" />
            </svg>

            <div class="ftu-content">
                <h3>{{ $t('ftuHeader') }}</h3>

                <i18n path="overAndOver" tag="div" class="and-over">
                    <template #huge>
                        <span class="huge">{{ $t('over') }}</span>
                    </template>

                    <template #lg>
                        <span class="lg">{{ $t('andOver') }}</span>
                    </template>

                    <template #med>
                        <span class="med">{{ $t('andOver') }}</span>
                    </template>

                    <template #small>
                        <span class="small">{{ $t('andOver') }}</span>
                    </template>

                    <template #xs>
                        <span class="xs">{{ $t('andOver') }}</span>
                    </template>

                    <template #xs2>
                        <span class="xs2">{{ $t('andOver') }}</span>
                    </template>
                </i18n>

                <br />

                <div class="ftu-description">
                    <span>{{ $t('ftuDescription.1') }}</span>

                    <br />

                    <span class="description2">
                        <ds-icon name="templates" />

                        {{ $t('ftuDescription.2') }}
                    </span>
                </div>
            </div>

            <footer class="ftu-footer">
                <span @click="closeFtu">{{ $t('global.okgotit') }}</span>
            </footer>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';

import AppointmentButton from '@/communication/components/AppointmentButton';
import MergeFieldButton from '@/shared/components/Templates/MergeFieldButton';
import { MEDIA_TYPES, TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import reviewsMixin from '@/reviews/mixins/reviews.mixin';

import '@/shared/images/keap-arrow-group.svg';

export default {
    components: {
        AppointmentButton,
        MergeFieldButton,
    },

    mixins: [reviewsMixin],

    props: {
        editDelay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
    },

    data() {
        return {
            selectedTemplate: null,
            editorOpen: false,
            nestedModal_removeInnerPadding: true,
            nestedModal_showBack: false,
            saving: false,
            saved: false,
            timeout: null,
            title: '',
            content: '',
            maxLength: 1600,
        };
    },

    created() {
        if (this.editDelay > 0) {
            this.handleEdit = debounce(this.handleEdit, this.editDelay);
        }
    },

    computed: {
        ...mapState({
            tutorials: ({ tutorials }) => tutorials.items,
        }),

        ...mapGetters({
            bookingUrl: 'calendar/bookingUrl',
            sortedSmsTemplates: 'communicationTemplates/sortedSmsTemplates',
        }),

        showDelete() {
            return Boolean(
                this.selectedTemplate
                && this.selectedTemplate.id
                && typeof this.selectedTemplate.id === 'string'
                && !this.selectedTemplate.id.startsWith('default'),
            );
        },

        showFtu() {
            return !this.templatesTutorialComplete && !this.selectedTemplate;
        },

        templatesTutorialComplete() {
            return Boolean(this.tutorials[TUTORIAL_TYPES.SMS_TEMPLATES]);
        },
    },

    methods: {
        back() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        closeFtu() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.SMS_TEMPLATES,
                value: true,
            });
            this.setTemplate(this.sortedSmsTemplates[0]);
        },

        async createTemplate() {
            const payload = {
                mediaType: MEDIA_TYPES.PLAIN_TEXT,
                templateKind: TEMPLATE_KINDS.CUSTOM,
                title: '',
                subject: '',
                content: '',
            };

            const createdTemplate = await this.$store.dispatch('communicationTemplates/CREATE_SMS_TEMPLATE', { payload });

            this.$track('Text Templates - Manager - Created template');
            this.setEditorOpen(true);
            this.setTemplate(createdTemplate);
        },

        async deleteTemplate() {
            this.saving = false;
            const templateId = this.selectedTemplate.id;

            await this.$confirm({
                optionTitle: this.$t('deleteTemplate.title'),
                optionMessage: this.$t('deleteTemplate.message'),
                optionConfirmButtonLabel: this.$t('deleteTemplate.confirm'),
                destructive: true,
                optionCancel: this.$t('deleteTemplate.cancel'),
            });
            await this.$store.dispatch('communicationTemplates/DELETE_SMS_TEMPLATE', { templateId });

            this.$track('Text Templates - Manager - Deleted template');
            this.setEditorOpen(false);
            this.setTemplate(this.sortedSmsTemplates[0]);
        },

        handleTemplateItemClick(template) {
            this.setEditorOpen(true);
            this.setTemplate(template);
        },

        handleContentEdit() {
            if (this.content === this.selectedTemplate.content) {
                return;
            }

            this.handleEdit();
        },

        async handleEdit() {
            this.saving = true;
            this.saved = false;
            const templateKind = this.selectedTemplate.templateKind || TEMPLATE_KINDS.CUSTOM;
            const { content, title } = this;
            const templateId = this.selectedTemplate.id;

            const payload = {
                mediaType: MEDIA_TYPES.PLAIN_TEXT,
                templateKind,
                title,
                subject: '',
                content,
            };

            if (templateId.startsWith('default')) {
                const createdTemplate = await this.$store.dispatch('communicationTemplates/CREATE_SMS_TEMPLATE', { payload });

                this.selectedTemplate = createdTemplate;
            } else {
                await this.$store.dispatch('communicationTemplates/UPDATE_SMS_TEMPLATE', {
                    payload,
                    templateId,
                });
            }
            this.saving = false;
            this.saved = true;
        },

        insertAppointmentLink(appointmentType) {
            const apptUrl = this.bookingUrl(appointmentType);

            if (!this.content.endsWith(' ') && this.content !== '') {
                this.content += ' ';
            }

            this.content += apptUrl;
        },

        insertMergeField(mergeField) /* istanbul ignore next */ {
            const { messageInput } = this.$refs;

            if (messageInput?.setRangeText) {
                messageInput.setRangeText(mergeField);
            } else {
                messageInput.focus();
                document.execCommand('insertText', false, mergeField);
            }

            this.content = messageInput.value;

            this.handleContentEdit();
        },

        insertTemplate() {
            const { id } = this.selectedTemplate;
            const template = {
                id,
                content: this.content,
            };

            this.$bus.$emit('INSERT_COMMUNICATION_TEMPLATE', template);
            this.back();
        },

        isTemplateSelected(template) {
            return this.selectedTemplate
                ? this.selectedTemplate.id === template.id
                : false;
        },

        nestedModal_open() {
            if (this.templatesTutorialComplete && this.sortedSmsTemplates && this.sortedSmsTemplates.length > 0) {
                this.setTemplate(this.sortedSmsTemplates[0]);
            }
        },

        setEditorOpen(value) {
            this.editorOpen = value;
        },

        setTemplate(template = {}) {
            this.selectedTemplate = template;
            this.title = template.title || '';

            if (template.templateKind === 'REVIEW') {
                template.content = this.$t('defaultSmsTemplates.6.body', {
                    reviewUrl: this.reviews_reviewUrl,
                });
            }

            this.content = template.content || '';
            this.saved = false;
        },

        setupAppointments() {
            this.$router.push({ name: 'calendar' });
        },

        templateDescription(template) {
            return (template?.content && template.content.trim()) || this.$t('defaultContent');
        },

        templateTitle(template) {
            return template?.title || this.$t('defaultTitle');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $template-manager-height: #{px-to-rem(700px)};
    $template-nav-width: #{px-to-rem(360px)};
    $ftu-confirm-background-color: #297f29;

    .template-manager {
        display: flex;
        height: $template-manager-height;

        @media($small) {
            height: 100vh;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .template-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $template-nav-width;

        @media($small) {
            flex: 1;
            min-height: 0;
            max-width: 100%;
        }
    }

    .header-text {
        padding: 0 $gp / 2;
        flex: 1;
    }

    .hide-template-nav,
    .insert-template,
    .keap-arrow-group,
    .empty-state {
        @media($small) {
            display: none;
        }
    }

    .template-nav-header {
        display: flex;
        padding: $gp;
        align-items: center;

        @media($small) {
            width: 100%;
        }
    }

    .template-items {
        @include overflowY;
    }

    .template-item {
        cursor: pointer;

        &.selected {
            background-color: $color-gray-050;
        }

        &:hover {
            background-color: $color-gray-050;
        }

        @media ($small) {
            &.selected {
                background-color: $color-paper;
            }
        }
    }

    .template-editor {
        display: flex;
        flex-direction: column;
        background-color: $color-paper;
        width: 100%;
        @include border-start(1px solid $color-gray-200);
        @include border-radius(0 $border-radius $border-radius 0);
        --input-margin-bottom: 0;
        --input-padding: 0;
        --input-border: none;
        --input-focus-border: none;
        --input-height: #{$gp * 1.5};

        @media($small) {
            @include transition(transform);

            transform: translateX(100%);
            @include position(absolute 0 0 auto auto);
            z-index: 5;
            height: 100vh;

            &.open {
                transform: translateX(0);
            }

            &:not(.open) {
                transform: translateX(100%);
            }
        }
    }

    .template-editor-header,
    .insert-template-mobile {
        display: none;

        @media($small) {
            display: flex;
        }
    }

    .edit-template {
        flex: 1;
        margin: 0 $gp / 2;
    }

    .template-nav-header,
    .template-editor-header,
    .template-editor-title {
        border-bottom: 1px solid $color-gray-200;
    }

    .template-editor-header,
    .template-button-row {
        padding: $gp;
    }

    .template-editor-title {
        display: flex;
        align-items: center;
        font-size: $font-size-med;
        padding: $gp / 2 $gp / 2;
        white-space: nowrap;

        .label {
            padding: 0 $gp / 2;
            color: $color-gray-700;
        }

        &.hide-delete {
            padding: $gp $gp / 2;
        }
    }

    .delete-button {
        &-mobile {
            display: none;

            &.disable-delete {
                --icon-color: #{$color-gray-700};
            }
        }

        &:disabled {
            background-color: $color-paper;
        }

        @media($small) {
            display: none;

            &-mobile {
                display: block;
            }
        }
    }

    .message-content {
        border: none;
        font-size: $font-size-med;
        margin-bottom: $gp / 2;
        resize: none;
        padding: $gp;
        flex: 1;
        outline: none;
    }

    .template-button-row {
        display: flex;
        align-items: center;
    }

    .action-buttons {
        flex: 1;
    }

    .saving {
        display: flex;
        align-items: center;
        color: $color-gray-700;
        --icon-size: #{$gp};
        font-size: $font-size-xs;
        padding: 0 $gp;

        .icon {
            animation: spin 4s infinite;
            padding: $gp / 8;
        }
    }

    .saved {
        padding: 0 $gp;
    }

    .ftu {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        align-items: center;
        border-radius: $border-radius;
        box-shadow: $elevation-z12;
        color: $color-paper;
        background-color: $color-green;

        h3,
        .and-over {
            font-family: $font-family-empty-state-title;
            font-weight: bold;
        }

        @media($small) {
            flex: 1;
        }
    }

    .close-ftu {
        --icon-cursor: pointer;
        align-self: flex-start;
        --icon-margin: #{$gp};
        z-index: $zindex-modal + 1;
    }

    .keap-arrow-group {
        transform: scale(3)
            rotate(180deg)
            translate(-10px, -5px);
    }

    .ftu-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;

        @media($small) {
            margin: $gp;
        }
    }

    .huge {
        font-size: $font-size-huge;
    }

    .lg {
        font-size: $font-size-lg;
    }

    .med {
        font-size: $font-size-med;
    }

    .small {
        font-size: $font-size-small;
    }

    .xs {
        font-size: $font-size-xs;
    }

    .xs2 {
        font-size: $font-size-xs / 2;
    }

    .ftu-description {
        --icon-size: #{$icon-size};
        text-align: center;
        margin: 0 $gp;

        span {
            line-height: 1.5;
        }

        .description2 {
            display: flex;
            margin: $gp / 2;
        }
    }

    .ftu-footer {
        padding: $gp;
        text-align: center;
        width: 100%;
        border-radius: 0 0 $border-radius $border-radius;

        // TODO: According to Kristen Giamello, this color is missing in design system and will be added in the future
        background-color: $ftu-confirm-background-color;

        span {
            cursor: pointer;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "emailTemplates": "Text templates",
        "defaultTitle": "New template",
        "defaultContent": "(no content)",
        "editTemplate": "Edit template",
        "deleteTemplate": {
            "title": "Delete template?",
            "message": "Once you delete this template, it's gone forever.",
            "confirm": "Yes, delete",
            "cancel": "No, cancel"
        },
        "titleLabel": "Template name: ",
        "contentPlaceholder": "Add your text message content here...",
        "saving": "Saving",
        "saved": "Saved",
        "insertTemplate": "Insert template",
        "insert": "Insert",
        "ftuHeader": "Stop writing the same text",
        "overAndOver": "{huge} {lg} {med} {small} {xs} {xs2}",
        "over": "over",
        "andOver": "and over",
        "ftuDescription" : {
            "1": "Start with an easy-to-edit template. Or create your own.",
            "2": "Access your templates whenever you see this icon."
        }
    }
}
</i18n>
