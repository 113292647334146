<template lang="html">
    <div class="boot-load-error">
        <svg class="not-found-svg">
            <use xlink:href="#page-not-found" />
        </svg>

        <h2>{{ $t('title') }}</h2>
        <i18n path="description" tag="h4">
            <template #link>
                <a :href="link">{{ $t('accountCentral') }}</a>
            </template>
        </i18n>

        <i18n path="logout" tag="p">
            <template #link>
                <a href="/logout">{{ $t('loggingIn') }}</a>
            </template>
        </i18n>
    </div>
</template>

<script>
import '@/dashboard/images/page-not-found.svg';

export default {
    data() {
        return {
            link: process.env.VUE_APP_ACCOUNT_CENTRAL_URL,
        };
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .boot-load-error {
        padding: $gp * 2;
        text-align: center;
    }

    h2 {
        margin: $gp * 1.5 0 $gp * 1.5;
    }

    h4 {
        margin-bottom: $gp * 2;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Oops! Looks like you tried to log in to an app for which you aren’t a user.",
        "description": "Access {link} to select a different app",
        "logout": "Or try {link} as a different user",
        "accountCentral": "account central",
        "loggingIn": "logging in"
    }
}
</i18n>
