<template>
    <div class="product-modal">
        <bus-event name="TAX_ADDED" @event="selectSalesTax" />

        <ds-placeholder v-if="loading" :rows="placeholderBoxes" />

        <div v-else>
            <form>
                <product-info-form
                    v-model="product"
                    :show-sale-options="showSaleOptions"
                />

                <div v-if="product.subscription">
                    <ds-multiselect
                        v-model="product.recurringOption"
                        :placeholder="$t('billing.product.modal.recurring')"
                        required
                        :options="recurringBilling_options"
                    />

                    <ds-date-picker
                        v-if="showSaleOptions"
                        v-model="product.startRecurring"
                        name="start-recurring"
                        :label="$t('billing.product.modal.startRecurring')"
                        disable-before-date="today"
                    />

                    <div v-if="showSaleOptions" class="product-radio-container">
                        <ds-radio v-model="endOptionSelection" val="true">
                            <div class="end-payment-after">
                                <div class="payment-radio-start-text">
                                    {{ $t('billing.product.modal.endAfter') }}
                                </div>

                                <div class="occurrences-radio-input">
                                    <div class="payment-input-centering">
                                        <ds-input-field
                                            v-model="product.occurrences"
                                            value-prop="id"
                                            type="number"
                                            :min="1"
                                            :required="radioRequired"
                                        />
                                    </div>
                                </div>

                                <div class="payment-radio-end-text">
                                    {{ $tc('billing.product.modal.occurrence', product.occurrences) }}
                                </div>
                            </div>
                        </ds-radio>

                        <ds-radio v-model="endOptionSelection" val="false">
                            <div class="end-payments-by-date payment-margin">
                                <div class="payment-input-centering">
                                    <ds-date-picker
                                        v-model="product.endBy"
                                        name="end-by"
                                        :label="$t('billing.product.modal.endBy')"
                                        disable-before-date="today"
                                    />
                                </div>
                            </div>
                        </ds-radio>
                    </div>
                </div>

                <div v-if="!isCheckoutForm">
                    <ds-checkbox
                        v-model="chargeTax"
                        data-qa="enable-tax"
                        :label="$t('chargeTax')"
                    />

                    <div v-if="chargeTax" class="tax-form">
                        <ds-inline-alert leading-icon type="subtle" class="alert-message">
                            {{ $t('taxInfoAlert') }}
                        </ds-inline-alert>

                        <div
                            v-for="tax in defaultTaxes"
                            :key="tax.id"
                            class="tax-list-item"
                            :data-qa="`tax-${tax.name}`"
                        >
                            <div class="tax-name">
                                {{ tax.name }}
                            </div>
                            <div class="tax-rate">
                                {{ tax.rate }}%
                            </div>
                            <ds-icon-button name="x" :data-qa="`remove-tax-${tax.name}`" @click="removeTax(tax)" />
                        </div>

                        <div v-if="taxSelectorVisible" class="tax-add">
                            <ds-multiselect
                                name="selectTax"
                                data-qa="select-tax"
                                searchable
                                allow-add
                                label-prop="name"
                                value-prop="id"
                                :options="taxOptions"
                                :placeholder="$t('selectTax')"
                                :add-text="$t('addNewTax')"
                                @add="addNewSalesTax"
                                @input="selectSalesTax"
                            />
                            <ds-icon-button name="x" @click="hideTaxSelector" />
                        </div>

                        <ds-text-button
                            v-else
                            leading-icon="add"
                            data-qa="add-another-tax"
                            @click="addAnotherTax"
                        >
                            {{ $t('addAnotherTax') }}
                        </ds-text-button>
                    </div>
                </div>
            </form>

            <div class="product-button-row">
                <div class="buttons-container">
                    <ds-filled-button
                        class="modal-button"
                        data-qa="product-save"
                        :loading="saving"
                        :disabled="!hasRequiredFieldsGiven"
                        @click="modalAction"
                    >
                        {{ $t(actionButtonLabel) }}
                    </ds-filled-button>

                    <ds-text-button
                        class="modal-button"
                        @click="cancel"
                    >
                        {{ $t('global.cancel') }}
                    </ds-text-button>
                </div>

                <div v-if="product.existing" class="modal-button">
                    <ds-filled-button negative data-qa="product-delete" @click="deleteProduct">
                        {{ $t('billing.product.modal.delete') }}
                    </ds-filled-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import amplitude from '@/analytics/amplitude';
import recurringBilling from '@/money/mixins/recurring-billing';
import ProductInfoForm from './ProductInfoForm';
import SalesTaxModalContent from '@/money/components/billing/SalesTaxModalContent';
import BusEvent from '@/shared/components/BusEvent';

import { CHECKOUT_FORM_BASE_ROUTE_NAME } from '@/shared/constants/money.constants';

export default {
    components: {
        ProductInfoForm,
        BusEvent,
    },

    mixins: [recurringBilling],

    data() {
        return {
            product: {},
            showSaleOptions: false,
            actionType: '',
            status: '',
            endOptions: [{ value: 'endafter', id: 'after' }, { value: 'endBy', id: 'by' }],
            endOptionSelection: 'true',
            eventSource: 'Products Page',
            loading: false,
            saving: false,
            chargeTax: false,
            taxSelectorVisible: false,
            isUpsell: false,
            defaultTaxes: [],
        };
    },

    computed: {
        ...mapState({
            taxes: ({ billing }) => billing.taxes,
        }),

        isCheckoutForm() {
            return this.$route?.name === CHECKOUT_FORM_BASE_ROUTE_NAME;
        },

        placeholderBoxes() {
            return [
                { boxes: [1], height: '2.5rem' },
                { boxes: [1], height: '8rem' },
                { boxes: [1], height: '2.5rem' },
                { boxes: [1], height: '2.5rem' },
                { boxes: [1], height: '2.5rem' },
            ];
        },

        nestedModal_title() {
            switch (this.actionType) {
            case 'edit':
                return this.$t('billing.product.modal.editTitle');
            default:
                return this.$t('billing.product.modal.addTitle');
            }
        },

        actionButtonLabel() {
            switch (this.actionType) {
            case 'edit':
                return this.$t('global.save');
            default:
                return this.$t('global.add');
            }
        },

        hasRequiredFieldsGiven() {
            const basicRequired = this.product.rate >= 0 && this.product.name;
            const basicSubscriptionRequired = this.product.recurringOption;
            const saleOptionsBasicRequired = this.product.quantity > 0;
            const subscriptionRadioRequired = this.product.startRecurring;
            const hasEndBy = this.product.endBy;
            const hasOccurences = this.product.occurrences > 0;

            if (this.showSaleOptions && this.product.subscription) {
                if (this.radioRequired) {
                    return basicRequired && basicSubscriptionRequired && subscriptionRadioRequired && saleOptionsBasicRequired && hasOccurences;
                }

                if (!this.radioRequired) {
                    return basicRequired && basicSubscriptionRequired && subscriptionRadioRequired && saleOptionsBasicRequired && hasEndBy;
                }
            } else if (!this.showSaleOptions && this.product.subscription) {
                return basicRequired && basicSubscriptionRequired;
            }

            if (this.showSaleOptions && !this.product.subscription) {
                return basicRequired && saleOptionsBasicRequired;
            }

            return basicRequired;
        },

        radioRequired() {
            return this.endOptionSelection === 'true';
        },

        taxOptions() {
            if (this.defaultTaxes.length) {
                const defaultTaxIds = this.defaultTaxes.map((tax) => tax.id);

                return this.taxes.filter((tax) => !defaultTaxIds.includes(tax.id));
            }

            return this.taxes;
        },
    },

    methods: {
        nestedModal_open(data) {
            if (data) {
                Object.keys(data).forEach((key) => {
                    this[key] = data[key];
                });
            }

            if (this.product?.defaultTaxes?.length) {
                this.chargeTax = true;
                this.defaultTaxes = [...this.product.defaultTaxes];
            }

            if (!this.taxes) {
                this.loadTaxes();
            }
        },

        nestedModal_reset() {
            if (this.status !== 'success') {
                this.$bus.$emit('PRODUCT_CREATION_CANCELLED');
            }
        },

        cancel() {
            this.$bus.$emit('PRODUCT_CREATION_CANCELLED');
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        modalAction() {
            if (this.endOptionSelection === 'true') {
                this.product.endBy = null;
            } else {
                this.product.occurrences = null;
            }

            if (this.product.existing) {
                this.editProduct();
            } else {
                this.addProduct();
            }
        },

        editProduct() {
            this.updateProductTax();
            this.saving = true;

            return this.$store.dispatch('billing/EDIT_PRODUCT', this.product)
                .then(() => {
                    this.$bus.$emit('POP_NESTED_MODAL');
                    this.saving = false;
                })
                .catch(() => {
                    this.saving = false;
                    this.$error({
                        message: this.$t('errors.editProduct'),
                        bottom: true,
                    });
                });
        },

        addProduct() {
            this.updateProductTax();
            this.saving = true;

            return this.$store.dispatch('billing/ADD_PRODUCT', {
                productInfo: this.product,
                eventSource: this.eventSource,
            })
                .then((product) => {
                    this.status = 'success';

                    const successEvent = this.isUpsell
                        ? 'UPSELL_CREATION_SUCCESSFUL'
                        : 'PRODUCT_CREATION_SUCCESSFUL';

                    product.isUpsell = this.isUpsell;

                    this.$bus.$emit(successEvent, { product });
                    this.$bus.$emit('POP_NESTED_MODAL');
                    this.saving = false;
                })
                .catch(() => {
                    this.$error({
                        message: this.$t('errors.addProduct'),
                        bottom: true,
                    });

                    this.saving = false;
                });
        },

        deleteProduct() {
            return this.$confirm({
                optionTitle: this.$t('billing.product.modal.confirmDeleteTitle', { name: this.product.name }),
                optionMessage: this.$t('billing.product.modal.confirmDeleteMessage', { name: this.product.name }),
                optionConfirmButtonLabel: this.$t('global.delete'),
                destructive: true,
            })
                .then(() => {
                    return this.$store.dispatch('billing/DELETE_PRODUCT', this.product.id)
                        .then(() => {
                            this.$bus.$emit('POP_NESTED_MODAL');
                        })
                        .catch((e) => {
                            this.$error({
                                message: e.response.data,
                                bottom: true,
                            });
                        });
                })
                .catch(() => {});
        },

        async loadTaxes() {
            this.loading = true;

            try {
                await this.$store.dispatch('billing/LOAD_SALES_TAX_RATES');
            } catch (e) {
                this.$error({ message: this.$t('errorLoading') });
            }

            this.loading = false;
        },

        addAnotherTax() {
            this.$track('Sales Tax - Editing Product - Add another sales tax clicked');
            this.taxSelectorVisible = true;
        },

        hideTaxSelector() {
            this.taxSelectorVisible = false;
        },

        removeTax(tax) {
            const index = this.defaultTaxes.indexOf(tax);

            if (index >= 0) {
                this.defaultTaxes.splice(index, 1);
            }
        },

        addNewSalesTax() {
            this.$track('Sales Tax - Editing Product - Clicked add new sales tax');
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SalesTaxModalContent,
                showClose: true,
                modalSize: 'sm',
                props: {
                    editing: false,
                    tax: {},
                },
            });
        },

        selectSalesTax(tax) {
            this.defaultTaxes.push(tax);
            this.hideTaxSelector();
            amplitude.v2.logEvent(amplitude.v2.events.SALES_TAX_APPLIED, {
                'Event Source': 'Product',
            });
        },

        updateProductTax() {
            this.product.defaultTaxes = this.chargeTax ? this.defaultTaxes : [];
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .buttons-container {
        display: flex;
        justify-content: flex-start;
    }

    .end-payment-after {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .modal-button {
        margin: $gp / 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .occurrences-radio-input {
        align-self: flex-end;
        width: 25%;
    }

    .payment-input-centering {
        height: px-to-rem(40px);
        flex-direction: row;
        align-items: flex-end;
    }

    .payment-input-container {
        height: 100%;
    }

    .payment-margin {
        @include margin-start($gp / 2);
    }

    .payment-radio-end-text {
        margin: 0 $gp / 2 0;
    }

    .payment-radio-start-text {
        display: flex;
        margin: 0 $gp / 2 0;
    }

    .product-button-row {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
    }

    .product-checkbox {
        --checkbox-padding: #{0 0 $gp 0};
    }

    .tax-form {
        @include margin-start($gp * 2);

        margin-bottom: $gp * 2;
    }

    .alert-message {
        color: $color-text-normal;
        margin-bottom: $gp * 1.5;
    }

    .tax-list-item {
        display: flex;
        margin: 0 0 $gp * 1.5 0;
        border-bottom: 1px solid $color-gray-200;
        align-items: center;

        div {
            display: flex;
            font-size: $font-size-med;
        }
    }

    .tax-name {
        @include margin-start($gp / 2);

        flex: 3;
    }

    .tax-rate {
        flex: 1;
    }

    .tax-add {
        display: flex;

        div {
            @include margin-end($gp);

            margin-bottom: 0;
        }

        button {
            align-self: flex-start;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "errorLoading": "There was an issue loading your sales tax rates. Please try again.",
        "chargeTax": "Charge tax on this product",
        "taxInfoAlert": "Sales tax selected here will be automatically applied when you use this product in your invoices.",
        "taxName": "Sales tax name",
        "taxRate": "Sales tax rate (%)",
        "addAnotherTax": "Add another sales tax",
        "selectTax": "Select a sales tax",
        "addNewTax": "Add a new sales tax",
        "removeTax": "Remove sales tax"
    }
}
</i18n>
