export const AUTOMATION_TIMER_TYPES = {
    DELAY: 'delay',
    EXACT: 'exact',
    APPOINTMENT: 'appointment',
    INVOICE_DUE: 'invoice_due',
    ANY: null,
};

export const EVENT_TYPES = {
    DATE: 'date',
    APPOINTMENT: 'appointment',
    INVOICE_DUE: 'invoice_due',
};

export const RANGE_OPTIONS = {
    AT: 'at',
    BETWEEN: 'between',
};

export const OFFSET_TYPES = {
    BEFORE: 'before',
    AFTER: 'after',
    NONE: 'none',
};
