export default {
    methods: {
        contactRecord_hideFields(field) {
            return field && !field.visible;
        },

        contactRecord_splitName(name) {
            let firstName;
            let lastName;

            if (name.includes(' ')) {
                [firstName, ...lastName] = name.split(' ');
                lastName = lastName.join(' ');
            } else {
                [firstName, lastName] = [name, ''];
            }

            return [firstName, lastName];
        },
    },
};
