<template>
    <div>
        <h2 class="secondary-nav-heading">
            {{ heading }}
        </h2>

        <router-link
            v-for="(link, index) in filteredLinks"
            :key="index"
            :to="link.to"
            class="secondary-nav-item"
        >
            {{ link.text }}
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        heading: {
            type: String,
            required: true,
        },
        links: {
            type: Array,
            required: true,
        },
    },

    computed: {
        filteredLinks() {
            return this.links.filter((link) => link.enabled);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./side-nav-variables";

.secondary-nav-heading {
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    font-weight: $font-weight-bold;
    @include margin(0 0 $gp / 2 $gp);
}

.secondary-nav-item {
    @include padding(px-to-rem(6) $gp);
    margin-top: $gp / 4;
    color: $color-paper;
    display: block;
    font-size: $font-size-small;
    font-weight: $font-weight-light;
    line-height: $line-height-md;
    text-decoration: none;
    border-radius: $border-radius;

    &:hover,
    &:focus,
    &.router-link-active {
        background: $side-nav-color-primary;
        font-weight: $font-weight-semibold;
    }

    &:last-child {
        margin-bottom: 0;
    }
}
</style>
