<template>
    <div class="call-volume">
        <div class="volume-container" :class="{ 'high-volume': highVolume }">
            <div class="volume-level" :style="{ width: percentage }" />
        </div>
    </div>
</template>

<script>
const MAX_VOLUME = 0.6;

export default {
    props: {
        value: Number,
    },

    computed: {
        highVolume() {
            return this.value > (MAX_VOLUME / 2);
        },

        percentage() {
            const percentage = +((this.value * 100 / MAX_VOLUME).toFixed(2));

            return `${percentage > 100 ? 100 : percentage}%`;
        },
    },
};
</script>

<style lang="scss" scoped>
.call-volume {
    width: 100px;
}

.volume-container {
    background: $color-blue-050;
    border-radius: .25rem;
}

.volume-level {
    height: .5rem;
    background: $color-blue;
    border-radius: .25rem;
}

.high-volume {
    &.volume-container {
        background: $color-green-050;
    }

    .volume-level {
        background: $color-green;
    }
}
</style>
