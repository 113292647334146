import axios from 'axios';
import gql from 'graphql-tag';
import Vue from 'vue';
import sentry from '@/analytics/sentry';
import { stringify } from 'query-string';
import {
    FF_KEAP_INTEGRATIONS_BFF_MIGRATION,
} from '@/shared/constants/featureFlag.constants';
import {
    BLENDR_TYPES,
    BLENDR_SHOPIFY_SPECIFIC_PRODUCT_KEY,
    BLEND_DESCRIPTION_REGEX,
} from '@/shared/constants/integrations.constants';

export default {
    LOAD_LOCALIZATION_INFO(context, payload) {
        return loadLocalizationInfo(context, payload);
    },

    SAVE_LOCALIZATION_INFO(context, payload) {
        return saveLocalizationInfo(context, payload);
    },

    LOAD_EDIT_PROFILE_URL(context) {
        return loadeditProfileUrl(context);
    },

    GET_SUPPORT_ARTICLES(context, payload) {
        return getSupportArticles(context, payload);
    },

    GET_NOTIFICATION_PREFERENCES(context, payload) {
        return getNotificationPreferences(context, payload);
    },

    UPDATE_NOTIFICATION_PREFERENCES(context, payload) {
        return updateNotificationPreferences(context, payload);
    },

    LOAD_USERS(context) {
        return loadUsers(context);
    },

    LOAD_USER_PROFILE(context, payload) {
        return loadUserProfile(context, payload);
    },

    SET_USER_ROLE(context, payload) {
        return setUserRole(context, payload);
    },

    GET_USER_ROLES(context) {
        return getUserRoles(context);
    },

    LOAD_USER_DOMAINS(context) {
        return loadUserDomains(context);
    },

    CREATE_DKIM_SETTING(context, payload) {
        return createDkimSetting(context, payload);
    },

    REMOVE_DKIM_SETTING(context, payload) {
        return removeDkimSetting(context, payload);
    },

    LOAD_INTEGRATIONS(context) {
        return loadIntegrations(context);
    },

    LOAD_BLENDR_TEMPLATES(context) {
        return loadBlendrTemplates(context);
    },

    LOAD_HELLOSIGN_DOCUMENTS(context) {
        return loadHelloSignDocuments(context);
    },

    LOAD_SHOPIFY_PRODUCTS(context) {
        const { rootState: { featureFlags } } = context;

        return featureFlags[FF_KEAP_INTEGRATIONS_BFF_MIGRATION]
            ? loadShopifyProducts(context)
            : loadShopifyProductsFromCloudFunctions(context);
    },

    LOAD_BLENDR_DATASOURCES(context) {
        const { rootState: { featureFlags } } = context;

        return featureFlags[FF_KEAP_INTEGRATIONS_BFF_MIGRATION]
            ? loadBlendrDatasources(context)
            : loadBlendrDatasourcesFromCloudFunctions(context);
    },

    RUN_BLENDR_TEMPLATE_INSTANCE(context, payload) {
        return runBlendrTemplateInstance(context, payload);
    },

    LOAD_TYPEFORM_FORMS(context) {
        return loadTypeformForms(context);
    },
};

const loadLocalizationInfo = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/settings/localizationInfo`)
            .then(({ data: { timeZoneOptions, localizationInfo } }) => {
                commit('SET_LOCALIZATION_INFO', localizationInfo);
                commit('SET_TIME_ZONE_OPTIONS', timeZoneOptions, { root: true });
                resolve();
            })
            .catch(reject);
    });
};

const getUserRoles = ({ commit }) => {
    return Vue.prototype.$graphql.query({
        query: gql`
                query userRoles {
                    userRoles
                }
            `,
    }).then(({ data }) => {
        commit('SET_ROLES', data.userRoles);
    });
};

const saveLocalizationInfo = ({ commit }, updatedLocalizationInfo) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/settings/localizationInfo`, updatedLocalizationInfo)
            .then(() => {
                commit('SET_LOCALIZATION_INFO', updatedLocalizationInfo);
                resolve();
            })
            .catch(reject);
    });
};

const setUserRole = ({ commit }, payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation setUserRole($userRoleInput: SetUserRoleInput!) {
                setUserRole(userRoleInput: $userRoleInput)
            }
        `,
        variables: {
            userRoleInput: payload,
        },
    }).then(() => {
        commit('SET_USER_ROLE', payload.userRole);
    });
};

const loadeditProfileUrl = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/settings/casEditProfileUrl`)
            .then(({ data: url }) => {
                commit('SET_CAS_EDIT_PROFILE_URL', url);
                resolve();
            })
            .catch(reject);
    });
};

const getSupportArticles = (context, { page, search }) => {
    return axios.get(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/support?page=${page}&search=${search}`);
};

const loadUsers = ({ commit }) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query allUsers {
                allUsers {
                    users {
                        id,
                        casId,
                        givenName,
                        familyName,
                        fullName,
                        email,
                        emailSignatureURL,
                        status,
                        dateAdded,
                        userRole,
                        isPartner,
                    },
                    numUsersLeft
                }
            }
        `,
        fetchPolicy: 'no-cache',
    }).then(({ data }) => {
        commit('SET_USERS', data.allUsers.users);
        commit('SET_NUM_USERS_LEFT', data.allUsers.numUsersLeft);
    });
};

const loadUserProfile = ({ commit }, { userId }) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query user($userId: ID) {
                user(userId: $userId) {
                    id,
                    givenName,
                    familyName,
                    fullName,
                    email,
                    phone,
                    phoneType,
                    phone2,
                    phone2Type,
                    emailSignatureURL,
                    status,
                    dateAdded,
                    title,
                    userRole,
                    isPartner,
                    company,
                    website,
                    streetAddress1,
                    streetAddress2,
                    city,
                    state,
                    postalCode,
                }
            }
        `,
        variables: {
            userId,
        },
        fetchPolicy: 'no-cache',
    }).then(({ data }) => {
        commit('SET_USER', data.user);
    });
};

const getNotificationPreferences = ({ commit }, { userId, accountId }) => {
    return axios.get(`${process.env.VUE_APP_NOTIFICATIONS_API_URL}/preferences/user/${userId}/account/${accountId}`)
        .then(({ data: preferences }) => {
            commit('SET_NOTIFICATION_PREFERENCES', preferences);
        });
};

const updateNotificationPreferences = ({ commit }, { userId, accountId, deliveryType }) => {
    return axios.patch(`${process.env.VUE_APP_NOTIFICATIONS_API_URL}/preferences/user/${userId}/account/${accountId}`, {
        mask: 'deliveryType',
        deliveryType,
    })
        .then((response) => {
            commit('SET_NOTIFICATION_PREFERENCES', response.data);
        });
};

const loadUserDomains = ({ commit }) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/emailAuthorization/all/domains`)
        .then((response) => {
            commit('SET_EMAIL_DOMAINS', response.data);
        });
};

const createDkimSetting = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/emailAuthorization/create`, payload)
            .then(() => {
                dispatch('LOAD_USER_DOMAINS');
                resolve();
            })
            .catch(reject);
    });
};

const removeDkimSetting = ({ dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/emailAuthorization/delete/${payload}`)
            .then(() => {
                dispatch('LOAD_USER_DOMAINS');
                resolve();
            })
            .catch(reject);
    });
};

const loadIntegrations = async ({ commit }) => {
    const { data: { integrations } } = await Vue.prototype.$graphql.query({
        query: gql`
            query integrations {
                integrations {
                    emailSync {
                        enabled
                        userId
                        emailProvider
                        emailProviderId
                        tenant
                        lastSyncAttemptDate
                        lastSyncCompleteDate
                        totalEmailsRetrieved
                        status {
                            message
                            status
                        }
                    }
                    contactSync {
                        enabled
                    }
                    appointment {
                        id
                        userId
                        calendarProvider
                        calendarProviderAccountId
                        status
                    }
                    contactImport {
                        provider
                        providerAccountId
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
    });

    commit('SET_INTEGRATIONS', integrations);
};

const loadHelloSignDocuments = async ({
    commit,
    dispatch,
    state: { integrations },
}) => {
    try {
        let blends = integrations.blendrBlends;

        if (!blends?.length) {
            const response = await dispatch('LOAD_BLENDR_TEMPLATES');

            blends = response.blendrBlends;
        }

        const helloSignConfiguredTemplates = blends
            .reduce((acc, { name, description }) => {
                if (name?.includes(BLENDR_TYPES.HELLO_SIGN)) {
                    const match = description != null
                        ? description.match(BLEND_DESCRIPTION_REGEX.HELLOSIGN)
                        : null;

                    if (match && match.groups) {
                        acc.push({
                            title: match.groups.documentTitle.trim(),
                            documentId: match.groups.documentId.trim(),
                        });
                    } else {
                        const nameSplit = name.split('-');
                        const title = nameSplit[1];
                        const documentId = nameSplit[2];

                        acc.push({
                            title: title?.trim(),
                            documentId: documentId?.trim(),
                        });
                    }
                }

                return acc;
            }, []);

        commit('SET_HELLOSIGN_DOCUMENTS', helloSignConfiguredTemplates);
    } catch (e) {
        sentry.log('Loading HelloSign documents failed');

        throw e;
    }
};

const loadShopifyProducts = async (
    {
        commit,
        dispatch,
        state: { integrations },
        rootGetters,
    }) => {
    const shopifyConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.SHOPIFY);

    const hasShopifyFeature = rootGetters['auth/hasShopifyIntegrationFeature'];

    if (!shopifyConnected || !hasShopifyFeature) {
        return;
    }

    let blends = integrations.blendrBlends;

    if (!blends?.length) {
        const response = await dispatch('LOAD_BLENDR_TEMPLATES');

        blends = response.blendrBlends;
    }

    const shopifyBlendBundle = blends?.find((b) => b.name?.includes(BLENDR_TYPES.SHOPIFY));

    const subBlend = shopifyBlendBundle?.subBlends.find((sb) => sb.name?.includes(BLENDR_SHOPIFY_SPECIFIC_PRODUCT_KEY));

    try {
        const products = await executeBlendrTemplateInstance({
            guid: subBlend.guid,
            async: false,
        });

        commit('SET_SHOPIFY_PRODUCTS', products[0]);
    } catch (e) {
        sentry.log('Loading Shopify products failed', e);

        throw e;
    }
};

const loadTypeformForms = async ({
    commit,
    dispatch,
    state: { integrations },
}) => {
    try {
        let blends = integrations.blendrBlends;

        if (!blends?.length) {
            const response = await dispatch('LOAD_BLENDR_TEMPLATES');

            blends = response.blendrBlends;
        }

        const typeformConfiguredForms = blends
            .reduce((acc, { name, description }) => {
                if (name?.includes(BLENDR_TYPES.TYPEFORM)) {
                    const match = description != null
                        ? description.match(BLEND_DESCRIPTION_REGEX.TYPEFORM)
                        : null;

                    if (match && match.groups) {
                        acc.push({
                            title: match.groups.formTitle.trim(),
                            formId: match.groups.formId.trim(),
                        });
                    } else {
                        const nameArr = name.split('-');
                        const title = nameArr[1];
                        const formId = nameArr[2];

                        acc.push({
                            title: title?.trim(),
                            formId: formId?.trim(),
                        });
                    }
                }

                return acc;
            }, []);

        commit('SET_TYPEFORM_FORMS', typeformConfiguredForms);
    } catch (e) {
        sentry.log('Loading Typeform forms failed');

        throw e;
    }
};

const loadBlendrTemplates = async ({ commit, rootState: { featureFlags, auth } }) => {
    const blendrTemplateModel = featureFlags[FF_KEAP_INTEGRATIONS_BFF_MIGRATION]
        ? await getBlendrTemplateModel()
        : await getBlendrTemplateModelFromCloudFunctions(auth.session.coreAppId, featureFlags);

    const blendrBlends = featureFlags[FF_KEAP_INTEGRATIONS_BFF_MIGRATION]
        ? await getBlendrBlends()
        : await getBlendrBlendsFromCloudFunctions(auth.session.coreAppId);

    const unconnectedBlendrTemplates = blendrTemplateModel
        .filter(({ guid, name: templateName, multipleInstancesAllowed }) => {
            // Only return templates that do not allow multiple instances (those will be added below)
            if (multipleInstancesAllowed) {
                return false;
            }

            return !blendrBlends.some(({ templateGuid, name }) => templateGuid === guid || templateName.includes(name));
        });

    const allowedBlends = blendrBlends
        .filter(({ templateGuid, name }) => {
            // Only return blends that are a part of the allowed template model
            return blendrTemplateModel.some(({ guid, name: templateName }) => templateGuid === guid || templateName.includes(name));
        });

    const singleInstancedBlends = allowedBlends
        .filter(({ templateGuid, name }) => {
            const blendrTemplate = blendrTemplateModel.find(({ guid, name: templateName }) => templateGuid === guid || templateName.includes(name));

            // Only return blends that do not belong to a template that allows multiple
            // instances (those will be added below)
            return !blendrTemplate.multipleInstancesAllowed;
        });

    const multipleInstancedTemplates = blendrTemplateModel
        .filter(({ multipleInstancesAllowed }) => multipleInstancesAllowed);

    const blendrTemplates = [
        ...unconnectedBlendrTemplates,
        ...singleInstancedBlends,
        ...multipleInstancedTemplates,
    ];

    commit('SET_BLENDR_TEMPLATES', blendrTemplates);
    commit('SET_BLENDR_BLENDS', allowedBlends);

    return {
        blendrTemplates,
        blendrBlends: allowedBlends,
    };
};

const loadBlendrDatasources = async ({ commit }) => {
    let dataSources = [];

    try {
        const { data: { blendrDataSources } } = await Vue.prototype.$graphql.query({
            query: gql`
                query listBlendrDataSources {
                    blendrDataSources {
                        guid
                        name
                        isUsed
                        isConnected
                        createdAt
                        updatedAt
                        connector {
                            guid
                            name
                        }
                        additional {
                            message
                            link
                        }
                    }
                }
            `,
            fetchPolicy: 'no-cache',
        });

        dataSources = blendrDataSources;
    } catch (error) {
        sentry.captureException('Listing blendr data sources failed', { error });
    }

    const getAllDataSourcePromises = dataSources
        .map(({ guid }) => {
            return Vue.prototype.$graphql
                .query({
                    query: gql`
                        query getBlendrDataSource($guid: ID!) {
                            blendrDataSource(id: $guid) {
                                guid
                                name
                                isUsed
                                isConnected
                                createdAt
                                updatedAt
                                connector {
                                    guid
                                    name
                                }
                                additional {
                                    message
                                    link
                                }
                                blends {
                                    guid
                                    name
                                    description
                                }
                            }
                        }
                    `,
                    variables: {
                        guid,
                    },
                    fetchPolicy: 'no-cache',
                })
                .then(({ data: { blendrDataSource } }) => blendrDataSource)
                .catch((error) => {
                    sentry.captureException(`Getting full blendr data source object ${guid} failed`, { error });

                    return null;
                });
        });

    const allDataSources = (await Promise.all(getAllDataSourcePromises)).filter((dataSource) => dataSource != null);
    const usedDataSources = allDataSources.filter(({ isUsed }) => isUsed);

    commit('SET_ALL_BLENDR_DATASOURCES', allDataSources);
    commit('SET_USED_BLENDR_DATASOURCES', usedDataSources);
};

const runBlendrTemplateInstance = (_, options) => {
    try {
        return executeBlendrTemplateInstance(options);
    } catch (error) {
        const { guid } = options;

        sentry.captureException(`Executing blendr template instance ${guid} failed`, { error });

        return null;
    }
};

const getBlendrTemplateModel = async () => {
    try {
        const { data: { blendrTemplates: response } } = await Vue.prototype.$graphql.query({
            query: gql`
                query listBlendrTemplates {
                    blendrTemplates {
                        guid
                        name
                        icon
                        description
                        activationUrl
                        multipleInstancesAllowed
                        groups {
                            id
                            name
                        }
                        subBlends {
                            guid
                            name
                        }
                    }
                }
            `,
            fetchPolicy: 'no-cache',
        });

        return response;
    } catch (error) {
        sentry.captureException('Listing blendr templates failed', { error });

        return [];
    }
};

const getBlendrBlends = async () => {
    try {
        const { data: { blendrTemplateInstances: response } } = await Vue.prototype.$graphql.query({
            query: gql`
                query listBlendrTemplateInstances {
                    blendrTemplateInstances {
                        guid
                        templateGuid
                        icon
                        name
                        description
                        isBundle
                        settingsUrl
                        adminUrl
                        lastRunAt
                        setupComplete
                        subBlends {
                            guid
                            name
                            lastRunAt
                        }
                    }
                }
            `,
            fetchPolicy: 'no-cache',
        });

        return response;
    } catch (error) {
        sentry.captureException('Listing blendr template instances failed', { error });

        return [];
    }
};

const executeBlendrTemplateInstance = async ({ guid, async, inputs }) => {
    const { data: { runBlendrTemplateInstance: response } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation($guid: ID!, $async: Boolean, $inputs: String) {
                runBlendrTemplateInstance(id: $guid, async: $async, inputs: $inputs)
            }
        `,
        variables: {
            guid,
            async,
            inputs: JSON.stringify(inputs),
        },
    });

    return JSON.parse(response);
};

// DEPRECATED - use getBlendrTemplateModel
const getBlendrTemplateModelFromCloudFunctions = (appId, featureFlagsObject) => {
    const featureFlagsArray = Object.keys(featureFlagsObject);

    const queryString = stringify({
        appId,
        featureFlags: featureFlagsArray.join(','),
    });

    return axios
        .get(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/blendr/template-model?${queryString}`)
        .then((res) => res.data)
        .catch((error) => {
            sentry.captureException('Listing blendr templates failed', { error });

            return [];
        });
};


// DEPRECATED - use getBlendrBlends
const getBlendrBlendsFromCloudFunctions = (appId) => {
    const queryString = stringify({
        appId,
    });

    return axios
        .get(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/blendr/blends?${queryString}`)
        .then((res) => res.data)
        .catch((error) => {
            sentry.captureException('Listing blendr template instances failed', { error });

            return [];
        });
};

// DEPRECATED - use loadBlendrDatasources
const loadBlendrDatasourcesFromCloudFunctions = async ({ commit, rootState: { auth } }) => {
    const queryString = stringify({
        appId: auth.session.coreAppId,
    });

    const { data: dataSources } = await axios
        .get(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/blendr/datasources?${queryString}`)
        .then(({ data }) => data)
        .catch((e) => {
            sentry.log('Loading blendr data sources failed', { message: e.message });

            return {
                data: [],
            };
        });

    const getUsedDataSourcePromises = dataSources
        .map(({ guid }) => {
            return axios.get(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/blendr/datasources/${guid}?${queryString}`)
                .then(({ data }) => data)
                .catch((e) => {
                    sentry.log('Fetching full data source failed', { message: e.message });

                    return null;
                });
        });

    const allDataSources = (await Promise.all(getUsedDataSourcePromises)).reduce((acc, dataSource) => {
        if (dataSource != null) {
            // add params with camel casing to make the BFF migration backwards compatible
            dataSource.isUsed = dataSource.is_used;
            dataSource.isConnected = dataSource.is_connected;

            acc.push(dataSource);
        }

        return acc;
    }, []);
    const usedDataSources = allDataSources.filter(({ isUsed }) => isUsed);

    commit('SET_ALL_BLENDR_DATASOURCES', allDataSources);
    commit('SET_USED_BLENDR_DATASOURCES', usedDataSources);
};

// DEPRECATED - use loadShopifyProducts
const loadShopifyProductsFromCloudFunctions = async (
    {
        commit,
        dispatch,
        state: { integrations },
        rootState: { auth },
        rootGetters,
    }) => {
    const shopifyConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.SHOPIFY);

    const hasShopifyFeature = rootGetters['auth/hasShopifyIntegrationFeature'];

    if (!shopifyConnected || !hasShopifyFeature) {
        return;
    }

    const queryString = stringify({
        appId: auth.session.coreAppId,
    });

    let blends = integrations.blendrBlends;

    if (!blends?.length) {
        const response = await dispatch('LOAD_BLENDR_TEMPLATES');

        blends = response.blendrBlends;
    }

    const shopifyBlendBundle = blends?.find((b) => b.name?.includes(BLENDR_TYPES.SHOPIFY));

    const subBlend = shopifyBlendBundle?.subBlends.find((sb) => sb.name?.includes(BLENDR_SHOPIFY_SPECIFIC_PRODUCT_KEY));

    try {
        const response = await axios
            .post(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/blendr/blends/${subBlend.guid}/run?${queryString}&asyncBlend=false`);

        commit('SET_SHOPIFY_PRODUCTS', response.data[0]);
    } catch (e) {
        sentry.log('Loading Shopify products failed', e);

        throw e;
    }
};
