import { mapGetters, mapState } from 'vuex';

import AddContactModal from '@/contacts/components/add/AddContactModal';

import displayMixin from '@/shared/mixins/display.mixin';
import {
    normalizePhoneNumber,
} from '@/communication/utils/sms-utils';

export default {
    mixins: [displayMixin],

    computed: {
        ...mapState({
            routeName: ({ route }) => route.name,
            routeParams: ({ route }) => route.params,
            contact: ({ contacts }) => contacts.contact,
            user: ({ auth }) => auth.user,
        }),

        ...mapGetters({
            getIsExistingConversation: 'communication/isExistingConversation',
            getActiveConversation: 'communication/activeConversation',
        }),

        sms_activeConversation() {
            const phoneNumber = this.routeName === 'contact.record'
                ? normalizePhoneNumber(this.contact.phone)
                : this.routeParams.id;

            return this.getActiveConversation(phoneNumber);
        },

        sms_activeConversationContactId() {
            return this.sms_activeConversation != null
                ? this.sms_activeConversation.contactId
                : null;
        },

        sms_activeConversationFirstName() {
            return this.sms_activeConversation != null
                ? this.sms_activeConversation.firstName
                : null;
        },

        sms_activeConversationLastName() {
            return this.sms_activeConversation != null
                ? this.sms_activeConversation.lastName
                : null;
        },

        sms_activeConversationPhoneNumber() {
            return this.sms_activeConversation != null
                ? this.sms_activeConversation.phoneNumber
                : null;
        },

        sms_vCardFilename() {
            if (this.user.fullName) {
                return `${this.user.fullName.replace(/\s/g, '')}.vcf`;
            }

            return '';
        },
    },

    methods: {
        sms_setRecipient(phoneNumber) {
            this.$bus.$emit('CONVERSATION_SELECTED');

            if (this.getIsExistingConversation(phoneNumber)) {
                this.$router.push({
                    name: 'communication-sms',
                    params: { id: normalizePhoneNumber(phoneNumber) },
                });
            }
        },

        sms_createNewContact(phoneNumber) {
            phoneNumber = phoneNumber ? phoneNumber.replace(/\D/g, '') : '';

            const addContactSeedObj = {
                contact: {
                    email: '',
                    phone1: {
                        value: phoneNumber,
                    },
                },
                hideSuccessModal: true,
                successCallback: this.sms_getContactCreationCallback(),
            };

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddContactModal,
                data: addContactSeedObj,
                showRootClose: true,
                modalSize: 'med',
            });

            this.$track('Communication - SMS - Clicked add contact');
        },

        sms_getContactCreationCallback() {
            return ({
                id,
                givenName: firstName,
                familyName: lastName,
                phone: { value: phoneNumber },
            }) => {
                this.$bus.$emit('POP_ALL_NESTED_MODALS');

                if (this.getIsExistingConversation(phoneNumber)) {
                    const payload = {
                        contactId: id,
                        ...(firstName && { firstName }),
                        ...(lastName && { lastName }),
                    };

                    return this.$store.dispatch('communication/UPDATE_CONVERSATION', { payload, phoneNumber });
                }

                this.$bus.$emit('NEW_CONVERSATION_CONTACT_CREATED', {
                    contactId: id,
                    firstName,
                    lastName,
                    phoneNumber,
                });

                return Promise.resolve();
            };
        },

        sms_getDisplayName(firstName, lastName, phoneNumber) {
            const givenName = firstName || '';
            const familyName = lastName || '';

            return givenName || familyName
                ? `${givenName} ${familyName}`.trim()
                : this.formatPhone(phoneNumber);
        },
    },
};
