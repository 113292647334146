export const ROLE_ACCOUNTING_CAN_MANAGE_MERCHANT_ACCOUNTS = 'ROLE_ACCOUNTING_CAN_MANAGE_MERCHANT_ACCOUNTS';
export const ROLE_USER_CAN_DEACTIVATE = 'ROLE_USER_CAN_DEACTIVATE';
export const ROLE_USER_CAN_CANCEL_INVITATION = 'ROLE_USER_CAN_CANCEL_INVITATION';
export const ROLE_USER_CAN_ADD = 'ROLE_USER_CAN_ADD';
export const ROLE_USER_CAN_RESEND_USER_INVITATION = 'ROLE_USER_CAN_RESEND_USER_INVITATION';
export const ROLE_USER_CAN_VIEW_ALL_RECORDS = 'ROLE_USER_CAN_VIEW_ALL_RECORDS';
export const ROLE_APPLICATION_CAN_MANAGE_APP_ACCOUNT = 'ROLE_APPLICATION_CAN_MANAGE_APP_ACCOUNT';
export const ROLE_APPLICATION_CAN_IMPORT = 'ROLE_APPLICATION_CAN_IMPORT';
export const ROLE_PIPELINE_CAN_CONFIGURE = 'ROLE_PIPELINE_CAN_CONFIGURE';
export const ROLE_CONTACT_CAN_EDIT_UNASSIGNED_RECORDS = 'ROLE_CONTACT_CAN_EDIT_UNASSIGNED_RECORDS';
export const ROLE_CONTACT_CAN_VIEW_ALL_RECORDS = 'ROLE_CONTACT_CAN_VIEW_ALL_RECORDS';
export const ROLE_CONTACTACTION_CAN_VIEW_ALL_RECORDS = 'ROLE_CONTACTACTION_CAN_VIEW_ALL_RECORDS';
export const ROLE_CONTACTACTION_CAN_ASSIGN_ACTION = 'ROLE_CONTACTACTION_CAN_ASSIGN_ACTION';
export const ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS = 'ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS';
export const ROLE_NAVIGATION_CAN_ACCESS_REPORTING = 'ROLE_NAVIGATION_CAN_ACCESS_REPORTING';
export const ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS = 'ROLE_NAVIGATION_CAN_ACCESS_CAMPAIGNS';

export const ADMIN = 'ADMIN';
export const MANAGER = 'MANAGER';
export const LIMITED_ADMIN = 'LIMITED_ADMIN';
export const STAFF = 'STAFF';

export const DKIM_PROVIDER_GODADDY_ID = 1;
export const DKIM_PROVIDER_BLUEHOST_ID = 2;
export const DKIM_PROVIDER_HOSTGATOR_ID = 3;
export const DKIM_PROVIDER_DREAMHOST_ID = 4;
export const DKIM_PROVIDER_LIQUIDWEB_ID = 5;
export const DKIM_PROVIDER_INMOTION_ID = 6;
export const DKIM_PROVIDER_OTHER_ID = 7;
