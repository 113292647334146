<template>
    <div class="header-button-row">
        <ds-text-button
            v-if="isEditing"
            data-qa="save-as-template-button"
            @click="$emit('save-template')"
        >
            {{ $t('saveAsTemplateAction') }}
        </ds-text-button>
        <ds-filled-button v-if="isEditing" @click="$emit('next-step')">
            {{ $t('nextButtonLabel') }}
        </ds-filled-button>
    </div>
</template>
<script>
export default {
    props: {
        isEditing: {
            type: Boolean,
            required: true,
        },
        isPublishing: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
<style lang="scss" scoped>
    .header-button-row {
        display: flex;
        flex-direction: row;
        @include margin-end($gp / 2);

        button {
            @include margin-end($gp / 2);
        }
    }

    .actions-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        --icon-size: #{$gp};

        span {
            @include margin-end($gp / 2)
        }
    }
</style>
<i18n>
{
    "en-us": {
        "nextButtonLabel": "Next",
        "publishButtonLabel": "Publish",
        "actionsButtonLabel": "Actions",
        "saveAsTemplateAction": "Save as a template"
    }
}
</i18n>
