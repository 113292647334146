<template lang="html">
    <div class="google-map fs-block">
        <iframe
            width="390"
            height="190"
            frameborder="0"
            allowfullscreen
            :src="src"
        />
    </div>
</template>

<script>
export default {
    props: {
        address: {
            type: String,
            required: true,
        },
    },

    computed: {
        src() {
            return `https://www.google.com/maps/embed/v1/place?key=${process.env.VUE_APP_GOOGLE_MAPS_BROWSER_API_KEY}&q=${this.address}`;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .google-map {
        border-radius: $border-radius;
        box-shadow: $elevation-z1;
        overflow: hidden;
        display: inline-flex;
        align-items: center;

        iframe {
            border: 0;
            max-width: 100%;
        }
    }
</style>
