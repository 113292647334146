export const getFromAddress = (from) => {
    const matches = from.match(/<(.*)>/) || [];

    return matches[1] || from;
};

export const getUserFrom = ({ id, email, fullName }) => {
    return {
        label: fullName ? `"${fullName}" <${email}>` : email,
        value: id,
    };
};
