<template lang="html">
    <form v-if="tagCategoriesLoaded" ref="form" data-qa="add-tag">
        <ds-input-field
            ref="tagCategoryName"
            v-model="categoryName"
            type="text"
            name="title"
            :label="$t('tag.category.name')"
            required
            :submitted="submitted"
            @blur="saveTagCategoryInline"
        />

        <ds-text-area-field
            v-if="!inline"
            v-model="categoryDescription"
            type="text"
            name="long"
            :label="$t('tag.category.description')"
            :submitted="submitted"
        />
    </form>
</template>

<script>
import { mapState } from 'vuex';

import contactModalSubtitle from '@/shared/mixins/contact-modal-subtitle.mixin';

export default {
    mixins: [contactModalSubtitle],

    props: {
        inline: Boolean,
    },

    data() {
        return {
            nestedModal_title: this.$t('tag.category.add'),
            nestedModal_actionText: this.$t('global.save'),
            nestedModal_actionMethod: 'save',
            nestedModal_dataQaAction: 'category.save',
            categoryName: '',
            categoryDescription: '',
            submitted: false,
            tagCategoriesLoaded: false,
        };
    },

    mounted() {
        if (this.inline) {
            this.loadTagCategoriesInline();
        }
    },

    computed: {
        ...mapState({
            allTagCategories: ({ tags }) => tags.tagCategories,
        }),
    },

    methods: {
        nestedModal_open() {
            return this.$store.dispatch('tags/LOAD_TAG_CATEGORIES')
                .then(() => {
                    this.tagCategoriesLoaded = true;
                });
        },

        nestedModal_reset() {
            this.categoryName = '';
            this.categoryDescription = '';
            this.submitted = false;
        },

        loadTagCategoriesInline() {
            return this.$store.dispatch('tags/LOAD_TAG_CATEGORIES')
                .then(() => {
                    this.tagCategoriesLoaded = true;

                    this.$nextTick(() => {
                        this.$refs.tagCategoryName.input_focus();
                    });
                });
        },

        saveTagCategoryInline() {
            if (this.inline && this.categoryName && this.$refs.form.checkValidity()) {
                this.$store.dispatch('tags/CREATE_TAG_CATEGORY', {
                    name: this.categoryName,
                    description: this.categoryDescription,
                })
                    .then((category) => {
                        this.$bus.$emit('ADD_TAG_CATEGORY', category);
                        this.$emit('hideField');
                    })
                    .catch(() => {
                        this.$error({
                            message: this.$t('errors.tagCategories.create'),
                        });
                    });
            }
        },

        save() {
            const existingCategory = this.hasDuplicate();

            if (existingCategory) {
                this.$bus.$emit('ADD_TAG_CATEGORY', existingCategory);
                this.$bus.$emit('POP_NESTED_MODAL');
            } else {
                this.submitted = true;

                if (this.$refs.form.checkValidity()) {
                    return this.$store.dispatch('tags/CREATE_TAG_CATEGORY', {
                        name: this.categoryName,
                        description: this.categoryDescription,
                    })
                        .then((category) => {
                            this.$bus.$emit('ADD_TAG_CATEGORY', category);
                            this.$bus.$emit('POP_NESTED_MODAL');
                        })
                        .catch(() => {
                            this.$error({
                                message: this.$t('errors.tagCategories.create'),
                            });
                        });
                }
            }

            return Promise.resolve();
        },

        hasDuplicate() {
            return this.allTagCategories.find((category) => {
                return category.name === this.categoryName;
            });
        },
    },
};
</script>
