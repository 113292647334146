import axios from 'axios';

import sentry from '@/analytics/sentry';
import { SLACK_MESSAGE_TYPES } from '@/shared/constants/slack.constants';

const sendFeedback = async (
    slackChannel,
    {
        pretext,
        user,
        messageType = SLACK_MESSAGE_TYPES.SUCCESS,
        text,
    },
) => {
    const payload = {
        slack_channel: slackChannel,
        attachments: [
            {
                color: messageType,
                pretext,
                author_name: user.fullName,
                title: user.email,
                title_link: `mailto:${user.email}`,
                text,
                footer: `keap-web environment: ${process.env.VUE_APP_ENV}`,
            },
        ],
    };

    try {
        await axios.post(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/slack`, payload);
    } catch (e) {
        sentry.log('Slack message failed to send');
    }
};

export default {
    sendFeedback,
};
