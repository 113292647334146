<template>
    <div class="landing-page-form-submitted-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div>
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="close" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-text-button
                            leading-icon="add"
                            data-qa="add-new-form"
                            @click="goToLandingPageBuilder"
                        >
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-if="showAnyResult"
                        as="a"
                        class="list-item-option"
                        data-qa="landing-page-forms-any"
                        :title="$t('anyLandingPageFormSubmitted.title')"
                        :description="$t('anyLandingPageFormSubmitted.description')"
                        @click="handleSelectAnyLandingPageForm"
                    />

                    <p v-if="!search" class="list-header">
                        {{ $t('formsHeader') }}
                    </p>

                    <p v-if="noLandingPageForms" class="empty-list">
                        {{ $t('emptyLandingPageForms') }}
                    </p>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(form, i) in filteredLandingPageForms"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :data-qa="`form_${form.id}`"
                        :title="form.title"
                        :description="form.description"
                        @click="handleSelection(form)"
                    />
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import { mapState } from 'vuex';
import { FORM_TYPE_LANDING_PAGE } from '@/customForms/customForm.constants';
import { ANY_LANDING_PAGE_FORM_SUBMITTED, LANDING_PAGE_FORM_SUBMITTED } from '@/automations/constants/automations.constants';

export default {
    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedOption: {},
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapState({
            landingPageForms: ({ customForms }) => customForms.forms[FORM_TYPE_LANDING_PAGE],
        }),

        anyLandingPageFormTrigger() {
            return {
                type: ANY_LANDING_PAGE_FORM_SUBMITTED,
                sourceId: null,
                name: this.$t('anyLandingPageFormSubmitted.name'),
            };
        },

        landingPageFormTrigger() {
            const { id } = this.selectedOption;

            return {
                type: LANDING_PAGE_FORM_SUBMITTED,
                sourceId: id,
                name: this.$t('singleLandingPageFormSubmitted', { id }),
            };
        },

        showAnyResult() {
            const search = this.search?.trim()?.toLowerCase();

            return this.$t('anyLandingPageFormSubmitted.title').toLowerCase().includes(search)
                || this.$t('anyLandingPageFormSubmitted.description').toLowerCase().includes(search);
        },

        filteredLandingPageForms() {
            const search = this.search?.trim()?.toLowerCase();

            return this.landingPageForms.filter(({ title, description }) => {
                return title?.toLowerCase().includes(search)
                        || description?.toLowerCase().includes(search);
            });
        },

        noLandingPageForms() {
            return !this.loading && !this.landingPageForms.length;
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredLandingPageForms.length && !this.showAnyResult;
        },
    },

    methods: {
        init() {
            const { trigger } = this.automationProperties;

            if (trigger) {
                this.selectedOption = {
                    ...trigger,
                    id: trigger.sourceId,
                };
            } else {
                this.selectedOption = {
                    type: ANY_LANDING_PAGE_FORM_SUBMITTED,
                    id: null,
                    name: this.$t('landingPageFormSubmitted'),
                };
            }

            if (!this.landingPageForms.length) {
                this.loadLandingPageForms();
            }
        },

        async loadLandingPageForms() {
            this.loading = true;

            await this.$store.dispatch('customForms/LOAD_FORMS', FORM_TYPE_LANDING_PAGE);

            this.loading = false;
        },

        handleSelection(form) {
            this.selectedOption = clonedeep(form);
            this.$emit('updated', this.landingPageFormTrigger);
            this.$emit('close');
        },

        handleSelectAnyLandingPageForm() {
            this.$emit('updated', this.anyLandingPageFormTrigger);
            this.$emit('close');
        },

        goToLandingPageBuilder() {
            return this.$confirm({
                optionTitle: this.$t('createForm.title'),
                optionMessage: this.$t('createForm.message'),
                optionConfirmButtonLabel: this.$t('createForm.confirm'),
                optionCancel: this.$t('global.cancel'),
            })
                .then(() => {
                    this.$router.push({ name: 'marketing-sites' });
                }).catch(() => {});
        },

        close() {
            const { type, sourceId } = this.landingPageFormTrigger;

            if (type === LANDING_PAGE_FORM_SUBMITTED && !sourceId) {
                this.handleSelectAnyLandingPageForm();
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "anyLandingPageFormSubmitted": {
            "name": "Any landing page form is submitted",
            "title": "Any landing page form",
            "description": "When any landing page form is submitted"
        },
        "landingPageFormSubmitted": "Landing page form is submitted",
        "singleLandingPageFormSubmitted": "landing page form ({id}) is submitted",
        "emptyLandingPageForms": "None available",
        "header": "Choose a form",
        "formsHeader": "Landing page forms",
        "buttonText": "New landing page",
        "search": "Search landing pages",
        "emptySearch": "No results found for \"{search}\"",
        "createForm": {
            "title": "Navigate to the landing page builder to add a new form?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Go to landing page builder"
        }
    }
}
</i18n>
