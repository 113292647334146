<template>
    <ds-body-click-handler @click="handleBodyClick">
        <ds-filled-button
            v-if="isConfirming"
            ref="button"
            data-qa="delete-field"
            negative
            @click.stop="handleConfirm"
        >
            {{ $t('global.delete') }}
        </ds-filled-button>

        <ds-icon-button
            v-else
            name="x-circle-fill"
            class="button-confirm"
            data-qa="confirm-delete"
            @click.stop="handleInitialClick"
        />
    </ds-body-click-handler>
</template>

<script>
export default {
    data() {
        return {
            isConfirming: false,
        };
    },

    methods: {
        handleInitialClick() {
            this.isConfirming = true;

            this.$nextTick(() => {
                if (this.$refs.button) {
                    this.$refs.button.$el.focus();
                }
            });
        },

        handleConfirm() {
            this.isConfirming = false;
            this.$emit('action');
        },

        handleBodyClick() {
            this.isConfirming = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .button-confirm {
        --icon-color: #{$color-gray-600};
    }
</style>
