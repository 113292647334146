<template>
    <ds-popover
        class="controlled-guide"
        is-open
        hide-arrow
        :position="position"
        position-fixed
    >
        <template #reference>
            <div class="beacon" />
        </template>

        <template #default>
            <section class="guide-container">
                <div class="content-container">
                    <div v-if="content || $slots.content" class="content">
                        <slot name="content">
                            {{ content }}
                        </slot>
                    </div>

                    <div v-if="showClose" class="close-container">
                        <ds-icon-button class="close-button" name="x" @click="$emit('close')" />
                    </div>
                </div>

                <footer class="guide-footer">
                    <div v-if="info || $slots.info" class="info">
                        <slot name="info">
                            {{ info }}
                        </slot>
                    </div>

                    <div class="button-row">
                        <ds-text-button
                            v-if="showSecondary"
                            class="secondary-button"
                            dense
                            @click="$emit('secondary')"
                        >
                            {{ secondaryLabel }}
                        </ds-text-button>

                        <ds-text-button
                            v-if="showPrimary"
                            class="primary-button"
                            dense
                            @click="$emit('primary')"
                        >
                            {{ primaryLabel }}
                        </ds-text-button>
                    </div>
                </footer>
            </section>
        </template>
    </ds-popover>
</template>

<script>
import { POPOVER_POSITIONS } from '@/shared/constants/popover.constants';

export default {
    props: {
        content: String,
        isOpen: Boolean,
        info: String,
        position: {
            type: String,
            default: 'bottom',
            validator: (position) => POPOVER_POSITIONS.includes(position),
        },
        primaryLabel: String,
        secondaryLabel: String,
    },

    computed: {
        showClose() {
            return !!this.$listeners.close;
        },

        showSecondary() {
            return !!this.$listeners.secondary;
        },

        showPrimary() {
            return !!this.$listeners.primary;
        },
    },
};
</script>

<style lang="scss" scoped>
.beacon {
    background: $color-emerald;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba($color-emerald, 1);
    margin: $gp * .875;
    height: $gp * .75;
    width: $gp * .75;
    transform: scale(1);
    animation: beacon 2s infinite;
}

@keyframes beacon {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($color-emerald, 0.7)
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 $gp rgba($color-emerald, 0)
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba($color-emerald, 0)
    }
}

.guide-container {
    display: flex;
    flex-direction: column;
    color: $color-paper;
}

.content-container {
    display: flex;
    background-color: $color-ink-900;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}

.content {
    padding: $gp;
    font-size: $font-size-med;
    line-height: $line-height-lg;
}

.close-container {
    @include margin($gp / 2 $gp / 2 $gp / 2 0);
    --icon-color: #{$color-paper};
}

.guide-footer {
    display: flex;
    background: $color-ink-800;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.info {
    padding: $gp;
    font-size: $font-size-xsmall;
}

.button-row {
    @include margin($gp / 2 $gp / 2 $gp / 2 auto);
    align-self: center;
}

.close-button {
    &:hover {
        --button-background-color: #{$color-ink};
    }

    &:active {
        --button-background-color: #{$color-ink-700};
    }
}

.secondary-button,
.primary-button {
    &:hover {
        --button-background-color: #{$color-ink-900};
    }

    &:active {
        --button-background-color: #{$color-ink-600};
    }
}

.secondary-button {
    --button-text-color: #{$color-paper};

    &:active {
        --button-text-color: #{$color-paper};
    }
}

.primary-button {
    --button-text-color: #{$color-blue-100};

    &:active {
        --button-text-color: #{$color-blue-100};
    }
}
</style>
