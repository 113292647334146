<template lang="html">
    <div class="site-filters">
        <ds-dropdown
            v-if="!status.value"
            position="bottom-end"
            data-qa="site-list-filters"
        >
            <template #default>
                <ds-text-button leading-icon="filter">
                    {{ $t('filter') }}
                </ds-text-button>
            </template>

            <template #menu>
                <ul class="dropdown-menu-list">
                    <li
                        v-for="({ label, value }, i) in statusFilters"
                        :key="i"
                        :class="['dropdown-menu-item', { selected: isFilterSelected(value) }]"
                        :data-qa="value"
                        @click="handleFilterSelected(value)"
                    >
                        {{ label }}
                    </li>
                </ul>
            </template>
        </ds-dropdown>

        <ds-chip
            v-else
            remove
            data-qa="site-list-filters-chip"
            with-interaction
            @click.native="handleRemoveFilter"
        >
            {{ status.label }}
        </ds-chip>
    </div>
</template>

<script>

import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';

export default {
    props: {
        status: {
            type: Object,
            required: true,
            sync: true,
        },
    },

    data() {
        return {
            statusFilters: [
                { label: this.$t('all'), value: null },
                { label: this.$t('published'), value: SITE_STATUS.PUBLISHED },
                { label: this.$t('publishedWithChanges'), value: SITE_STATUS.PUBLISHED_CHANGES },
                { label: this.$t('drafts'), value: SITE_STATUS.DRAFT },
            ],
        };
    },

    methods: {
        handleFilterSelected(value) {
            const filter = this.statusFilters.find((f) => f.value === value) ?? {};

            this.$emit('update:status', filter);
        },

        handleRemoveFilter() {
            this.handleFilterSelected();
        },

        isFilterSelected(value) {
            return this.status.value === value;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .site-filters {
        @include padding-start($gp / 2);
    }
</style>

<i18n>
{
    "en-us": {
        "all": "All",
        "filter": "Filter",
        "drafts": "Drafts",
        "published": "Published",
        "publishedWithChanges": "Unpublished changes"
    }
}
</i18n>
