<template lang="html">
    <section
        v-if="!showEmptyState"
        class="automation-list"
    >
        <automation-list-item
            v-for="(automation, index) in localAutomations"
            :key="automation.id"
            :automation="automation"
            :active="selectedIndex === index"
            @open="$emit('open', { automation, index })"
            @delete="$emit('delete', automation)"
            @rename="$emit('rename', automation)"
            @edit="$emit('edit', automation)"
            @revert="$emit('revert', automation)"
            @deactivate="$emit('deactivate', automation)"
            @share="$emit('share', automation)"
            @copy="$emit('copy', automation)"
        />

        <ds-infinite-scroll
            :no-more-data="noMoreData"
            :loading="loading"
            :limit.sync="limit"
            @load="load"
        >
            <div>
                <ds-placeholder :rows="placeholder" class="placeholder" />
                <ds-placeholder :rows="placeholder" class="placeholder" />
            </div>
        </ds-infinite-scroll>
    </section>

    <div v-else class="empty-state-wrapper">
        <automations-empty-state @create-automation="$emit('create-automation')" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import clone from 'lodash.clonedeep';
import AutomationListItem from './AutomationListItem';
import AutomationsEmptyState from './AutomationsEmptyState';

export default {
    components: {
        AutomationListItem,
        AutomationsEmptyState,
    },

    props: {
        value: Array,
        selectedIndex: Number,
    },

    data() {
        return {
            localAutomations: clone(this.value),
            loading: false,
            loaded: false,
            noMoreData: false,
            limit: 20,
            cursor: '',
            placeholder: [
                { height: '5rem', boxes: [1] },
            ],
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.localAutomations = clone(newVal);
            },
            deep: true,
        },

        automationFilters: {
            handler() {
                this.resetAndLoad();
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            automationFilters: ({ automations }) => automations.automationFilters,
        }),

        showEmptyState() {
            return this.loaded && this.localAutomations.length === 0;
        },

        tags() {
            return this.automationFilters.map(({ value }) => value);
        },
    },

    methods: {
        async load(reset = false) {
            if (this.loading) {
                return;
            }

            this.loading = true;

            try {
                const { limit, cursor, tags } = this;
                const automations = await this.$store.dispatch('automations/LOAD_AUTOMATIONS', { limit, cursor, tags });

                this.localAutomations = reset ? automations : this.dedupeList(this.localAutomations, automations);
                this.noMoreData = limit > automations.length;

                if (this.noMoreData) {
                    this.cursor = '';
                } else {
                    this.cursor = automations?.length > 1 && automations[automations.length - 1].id;
                }

                this.$emit('input', this.localAutomations);
            } catch (e) {
                this.noMoreData = true;
                this.cursor = '';
                this.$error({ message: this.$t('error.loading') });
            }

            this.loading = false;
            this.loaded = true;
            this.$pagePerformance('Automations', 'automations');
        },

        dedupeList(list1, list2) {
            const dedupe = list2.filter((a) => {
                return !list1.some(({ id }) => id === a.id);
            });

            return [
                ...list1,
                ...dedupe,
            ];
        },

        resetAndLoad() {
            this.loaded = false;
            this.cursor = '';
            this.localAutomations = [];
            this.load(true);
        },
    },
};
</script>

<style lang="scss" scoped>
    .empty-state-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $gp;
    }

    .placeholder {
        margin-top: $gp;
    }

    .automation-list {
        @include overflowY;
        height: 100%;
    }
</style>

<i18n>
{
    "en-us": {
        "error": {
            "loading": "There was an error loading automation list."
        }
    }
}
</i18n>
