<template>
    <div class="container" data-qa="automate-appointment">
        <h2 class="headline">
            {{ $t('content.headline') }}
        </h2>

        <section class="title-content">
            <h4 class="title">
                {{ $t('content.instructions.title') }}
            </h4>

            <p>
                {{ $t('content.instructions.subtitle') }}
            </p>
        </section>

        <appointment-automations :appointment-type="activeApptType" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import AppointmentAutomations from '@/appointments/components/AppointmentAutomations';

export default {
    components: {
        AppointmentAutomations,
    },

    mounted() {
        this.$emit('update:ready', true);
    },

    computed: {
        ...mapState({
            activeApptType: ({ calendar }) => calendar.activeApptType,
        }),
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .headline {
        margin: $gp * 2 0;
        text-align: center;
    }

    .container {
        max-width: $form-width;
        width: 100%;
        margin: 0 auto;
    }

    .title {
        margin-bottom: $gp / 2;
        font-weight: $font-weight-semibold;
    }

    .instructions {
        color: $color-text-subtle;
    }
</style>

<i18n>
{
    "en-us": {
        "content": {
            "headline": "Automate follow-ups for appointments",
            "instructions": {
                "title": "Automate follow-ups for appointments",
                "subtitle": "Trigger a follow-up when a contact books or cancels an appointment."
            }
        }
    }
}
</i18n>
