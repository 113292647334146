<template>
    <span
        v-if="saving"
        class="saving semibold"
        data-qa="saving-status"
    >
        <ds-icon name="loader" class="saving-icon" />

        <span>{{ $t('saving') }}</span>
    </span>

    <ds-status
        v-else
        type="good"
        data-qa="save-success"
        emphasis="medium"
    >
        {{ $t('saved') }}
    </ds-status>
</template>

<script>
export default {
    props: {
        saving: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    .saving {
        display: inline-flex;
        align-items: center;
        color: $color-gray-700;
        --icon-color: #{$color-gray-700};
        --icon-size: 12px;
        font-size: $font-size-xs;
        margin: $gp / 8 0;
    }

    .saving-icon {
        @include margin-end($gp / 4);
        animation: spin 4s infinite;
    }
</style>

<i18n>
{
    "en-us": {
        "saving": "Saving",
        "saved": "Saved"
    }
}
</i18n>
