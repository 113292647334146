<template>
    <div class="step-indicators">
        <div
            class="steps"
            :style="{ '--grid-columns': steps.length }"
        >
            <step-indicator
                v-for="(step, i) in steps"
                :key="i"
                :label="step"
                :index="i"
                :data-qa="`step-${i}`"
                class="step"
                :class="{complete: activeStep > i}"
                :active-step="activeStep"
                :step-number="i + 1"
                @click-step="$emit('click-step', i)"
            />
        </div>
    </div>
</template>

<script>
import StepIndicator from '@/shared/components/StepIndicator';

export default {
    components: { StepIndicator },

    props: {
        activeStep: Number,
        showStepNumbers: {
            type: Boolean,
            default: false,
        },
        steps: Array,
    },

};
</script>

<style lang="scss" scoped>
    @import '~@/shared/styles/step-indicator';

    .step {
        flex-direction: row;
        padding: 0 $gp * 2;
    }
</style>
