<template>
    <div class="configure">
        <configure-details
            :title="$t(value.name)"
            :trigger-action="triggerAction"
            hide-edit
            @remove="(payload) => $emit('remove', payload)"
        />

        <div class="button-row">
            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';

export default {
    components: {
        ConfigureDetails,
    },

    props: {
        value: Object,
        triggerAction: String,
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>
