import state from './state';
import mutations from './mutations';
import getters from './getters';
import actions from './actions';

export default {
    getters,
    state,
    mutations,
    actions,
    namespaced: true,
};
