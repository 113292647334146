<template lang="html">
    <div class="message-list">
        <template v-if="showAvatar">
            <tooltip-with-delay v-if="!isContactRecord" class="message-avatar">
                <template #reference>
                    <router-link
                        v-if="avatarContactId"
                        class="avatar-link"
                        :to="{ name: 'contact.record', params: { contactId: avatarContactId } }"
                    >
                        <contact-avatar
                            :name="avatarName"
                            :email="avatarEmail"
                        />
                    </router-link>

                    <button
                        v-else
                        class="add-contact-button"
                        @click="addContact"
                    >
                        <contact-avatar
                            :name="avatarName"
                            :email="avatarEmail"
                        />
                    </button>
                </template>

                <template #default>
                    <div data-qa="conversation-header-tooltip">
                        {{ avatarContactId ? $t('viewContactRecord', { name: avatarName }) : $t('addPhoneNumber', { phoneNumber: sms_activeConversationPhoneNumber }) }}
                    </div>
                </template>
            </tooltip-with-delay>

            <contact-avatar
                v-else
                class="message-avatar"
                :name="avatarName"
                :email="avatarEmail"
            />
        </template>

        <div v-if="!isError" :class="['messages', { outgoing: data.outgoing }]">
            <message
                v-for="message in data.messages"
                :key="message.id"
                :message="message"
            />

            <div class="message-info">
                <div v-if="isAutoReply" class="auto-reply">
                    <ds-icon name="zap-fill" />
                    <span>{{ $t('autoReply') }}</span>
                    <span class="auto-reply-divider">•</span>
                </div>
                <span v-if="!data.outgoing">{{ shortName }}</span>
                <span>{{ lastUpdated }}</span>
            </div>
        </div>

        <error-message
            v-else
            :messages="data.messages"
            :message-id="data.messageId"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

import ContactAvatar from '@/shared/components/ContactAvatar';
import ErrorMessage from '@/communication/components/ErrorMessage';
import Message from '@/communication/components/Message';
import MergeConversationModal from '@/communication/components/MergeConversationModal';
import TooltipWithDelay from '@/shared/components/TooltipWithDelay';
import smsMixins from '@/communication/mixins/sms.mixin';
import { ERROR_MESSAGE_STATUS } from '@/communication/communication.constants';
import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';

export default {
    components: {
        ContactAvatar,
        ErrorMessage,
        Message,
        TooltipWithDelay,
    },

    mixins: [smsMixins],

    props: {
        data: {
            type: Object,
            requried: true,
        },
    },

    computed: {
        ...mapState({
            contact: ({ contacts }) => contacts.contact,
        }),

        avatarName() {
            const { firstName, lastName } = this.sms_activeConversation || this.contact;

            return firstName && lastName ? `${firstName} ${lastName}` : '';
        },

        avatarEmail() {
            const { email } = this.sms_activeConversation || this.contact;

            return email || '';
        },

        avatarContactId() {
            const { contactId } = this.sms_activeConversation;

            return contactId;
        },

        shortName() {
            const phoneNumber = this.sms_activeConversation ? this.sms_activeConversation.phoneNumber : this.contact.phone;
            const { firstName } = this.sms_activeConversation || this.contact;


            return !firstName ? phoneNumber : firstName;
        },

        showAvatar() {
            return !this.data.outgoing;
        },

        isContactRecord() {
            return this.$route.name === 'contact.record';
        },

        lastUpdated() {
            return moment(this.data.updateTime).format(TIME_FORMAT);
        },

        isError() {
            return this.data.status === ERROR_MESSAGE_STATUS;
        },

        isAutoReply() {
            return this.data.messages.length > 0
                ? this.data.messages[0].autoResponse
                : false;
        },
    },

    methods: {
        addContact() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: MergeConversationModal,
                showRootClose: true,
                modalSize: 'sm',
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .message-list {
        display: flex;
        align-items: flex-end;
        margin: $gp / 2 $gp;

        &:first-child {
            margin-bottom: $gp;
        }

        &:last-child {
            margin-top: $gp;
        }
    }

    .message-avatar {
        @include margin(0 $gp ($gp * 1.25) 0);
    }

    .avatar-link {
        text-decoration: none;
    }

    .add-contact-button {
        padding: 0;
        background-color: transparent;
    }

    .messages {
        display: inline-flex;
        flex-direction: column;
        width: auto;
        flex: 1;
        align-items: flex-start;
    }

    .message-info {
        font-size: $font-size-xs;
        color: $color-gray-700;
        display: flex;
        width: 100%;

        span {
            @include margin-end($gp);
        }
    }

    .name {
        margin: 0 $gp;
    }

    .outgoing .message-info {
        justify-content: flex-end;

        span {
            @include margin-end(0);
        }
    }

    .auto-reply {
        --icon-size: #{$gp * .75};
        --icon-margin: 0 #{$gp / 6};
        display: flex;
        align-items: flex-end;

        &-divider {
            padding: 0 $gp / 4;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "autoReply": "Auto-reply",
        "viewContactRecord": "View {name}'s info",
        "addPhoneNumber": "Add {phoneNumber} to contacts"
    }
}
</i18n>
