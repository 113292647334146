<template>
    <div class="successful-sales-modal">
        <successful-modal
            :id="id"
            ref="successfulModal"
            :title="title"
            :button-label="buttonLabel"
            :button-class="buttonClass"
            :subtitle="subtitle"
            @close="handleClose"
        >
            <template #header>
                <svg>
                    <use xlink:href="#credit-card-success" />
                </svg>
            </template>

            <template #footer>
                <slot />
            </template>
        </successful-modal>
    </div>
</template>

<script>
import SuccessfulModal from '@/money/components/SuccessfulModal';
import '@/money/images/credit-card-success.svg';

export default {
    components: {
        SuccessfulModal,
    },

    props: {
        id: String,
        title: String,
        subtitle: String,
        buttonLabel: String,
        buttonClass: String,
    },

    methods: {
        open() {
            this.$refs.successfulModal.open();
        },

        close() {
            this.$refs.successfulModal.close();
        },

        handleClose() {
            this.$emit('close');
        },
    },
};
</script>
