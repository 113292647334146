import Vue from 'vue';

export default {
    SET_CAMPAIGN_TEMPLATES(state, campaignTemplates) {
        state.campaignTemplates = campaignTemplates;
    },

    SET_CAMPAIGN_TEMPLATES_WITH_INSTALLED(state, installedTemplates) {
        Object.keys(state.campaignTemplates).forEach((key) => {
            if (installedTemplates.find((id) => id === state.campaignTemplates[key].id)) {
                Vue.set(state.campaignTemplates[key], 'installed', true);
            } else {
                Vue.set(state.campaignTemplates[key], 'installed', false);
            }
        });
    },

    INSTALL_TEMPLATE(state, templateId) {
        const templateIndex = state.campaignTemplates.findIndex(({ id }) => id === templateId);

        if (templateIndex > -1) {
            Vue.set(state.campaignTemplates[templateIndex], 'installed', true);
        }
    },
};
