<template lang="html">
    <div class="final-stage">
        <import-contacts-permission v-model="permission" />

        <div class="tags">
            <h5 class="apply-tags">
                {{ $t('applyTags') }}
            </h5>

            <div v-if="tagsLoaded">
                <div class="multiselect-container">
                    <ds-multiselect
                        ref="multiselect"
                        v-model="selectedTags"
                        bind-value-only
                        multiple
                        searchable
                        allow-add
                        :placeholder="$t('contactRecord.tags.applyTag.placeholder')"
                        data-input-label="search-tags"
                        :options="tagOptions"
                        @add="addNewTag"
                        @input="trackTagRemoveAndApply"
                    />
                </div>

                <div v-if="showAddTag" class="add-new-tag-container">
                    <h5 class="apply-tags">
                        {{ $t('addNewTag') }}
                    </h5>

                    <add-tag
                        :event-source="$options.CONTACTS_IMPORTED_EVENT_SOURCE"
                        inline
                        @showAddTag="setShowAddTag"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import AddTag from '@/tags/components/AddTag';
import ImportContactsPermission from '@/import/components/ImportContactsPermission';
import { trackTagApplied } from '@/contacts/analytics';
import { CONTACTS_IMPORTED_EVENT_SOURCE } from '@/contacts/components/list/amplitude';

export default {
    CONTACTS_IMPORTED_EVENT_SOURCE,

    components: {
        ImportContactsPermission,
        AddTag,
    },

    props: {
        resetPermission: Boolean,
    },

    data() {
        return {
            permission: null,
            tagsLoaded: false,
            articleBaseUrl: process.env.VUE_APP_KNOWLEDGEOWL_URL,
            showAddTag: false,
            eventSource: 'Contacts Import',
        };
    },

    created() {
        this.loadTags();
    },

    mounted() {
        this.$bus.$on('ADD_TAG', this.onNewTagAdded);
    },

    beforeDestroy() {
        this.$bus.$off('ADD_TAG');
    },

    watch: {
        resetPermission(val) {
            if (val) {
                this.permission = null;
            }
        },
    },

    computed: {
        ...mapState({
            allTags: ({ tags }) => tags.tags,
            tags: ({ contacts }) => contacts.tags,
        }),

        ...mapGetters({
            tagOptions: 'tags/tagOptions',
        }),

        selectedTags: {
            get() {
                return this.$store.state.contactImport.additionalTags;
            },

            set(tags) {
                this.$store.commit('contactImport/SET_ADDITIONAL_TAGS', tags);
            },
        },
    },

    methods: {
        loadTags() {
            this.$store.dispatch('tags/LOAD_TAGS')
                .then(() => {
                    this.tagsLoaded = true;
                });
        },

        addNewTag() {
            this.$track('Contacts - Import - Finish - clicked : Add New Tag');
            this.$refs.multiselect.toggle();

            this.setShowAddTag(true);
        },

        onNewTagAdded(newTag) {
            this.selectedTags.push(newTag.id);

            trackTagApplied(this.eventSource);
        },

        trackTagRemoveAndApply() {
            this.setShowAddTag(false);
            this.$track('Contacts - Import - Finish - clicked : Tag Removed/Applied');

            trackTagApplied(this.eventSource);
        },

        setShowAddTag(value) {
            this.showAddTag = value;
        },
    },
};
</script>


<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .final-stage {
        margin: 0 auto;
        width: px-to-rem(500px);
        max-width: 100%;
    }

    .tags {
        @include card;
    }

    .add-new-tag-container {
        border-top: 1px $color-gray-200 solid;
        padding-top: $gp;
    }

    .apply-tags {
        font-size: px-to-rem(16px);
        margin-bottom: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "applyTags": "Apply tags (Optional)",
        "addNewTag": "Add a new tag"
    }
}
</i18n>
