<template>
    <div v-if="showSendTest" class="send-test-email">
        <ds-outline-button
            v-track="'Automations - Builder - clicked : Send test'"
            class="send-test-button only-desktop"
            data-qa="send-test-button"
            leading-icon="email"
            :disabled="sending"
            @click="openModal"
        >
            {{ $t('sendTest') }}
        </ds-outline-button>

        <automation-send-test-modal
            :is-open="isSendTestModalOpen"
            :sending="sending"
            @close="isSendTestModalOpen = false"
            @send-test="sendTest"
        />
    </div>
</template>

<script>
import amplitude from '@/analytics/amplitude';
import AutomationSendTestModal from '@/automations/components/AutomationSendTestModal';

import { SEND_EMAIL } from '@/automations/constants/automations.constants';

export default {
    components: {
        AutomationSendTestModal,
    },

    props: {
        automation: {
            type: Object,
            required: true,
        },

        isTemplate: Boolean,
    },

    data() {
        return {
            isSendTestModalOpen: false,
            sending: false,
        };
    },

    computed: {
        emailsInAutomationCount() {
            return this.automation.steps?.filter(({ type }) => type === SEND_EMAIL).length;
        },

        showSendTest() {
            return this.emailsInAutomationCount > 0;
        },
    },

    methods: {
        openModal() {
            this.isSendTestModalOpen = true;
            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_TEST_SENT);
        },

        async sendTest({ emailTo, contactId }) {
            const { id } = this.automation;

            this.sending = true;

            try {
                await this.$store.dispatch('automations/SEND_TEST_AUTOMATION', {
                    id,
                    emailTo,
                    contactId,
                    isTemplate: this.isTemplate,
                });

                const message = this.$tc(
                    'sendTestMessage',
                    this.emailsInAutomationCount === 1,
                    { emailCount: this.emailsInAutomationCount, emailTo },
                );

                this.$toast({ message });
            } catch (error) {
                this.$error({ message: this.$t('errorSendingTest') });
            }

            this.isSendTestModalOpen = false;
            this.sending = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .send-test-button span {
        line-height: 1;
    }

    .contextual-view .send-test-email {
        display: none;
    }
</style>

<i18n>
{
    "en-us": {
        "sendTest": "Send test",
        "sendTestMessage": "{emailCount} test email has been sent to {emailTo} | {emailCount} test emails have been sent to {emailTo}",
        "errorSendingTest": "There was a problem sending the test emails"
    }
}
</i18n>
