import {
    CONTACT_TYPES,
    EMAIL_STATUS,
    NOTE_TYPES,
    PHONE_TYPES,
} from '@/contacts/contacts.constants';

export const MAX_ERROR_RETRY = 2;

export const SAMPLE_CONTACTS = [
    {
        givenName: '(Sample) Katherine',
        familyName: 'Edwards',
        company: 'Total Yard Maintenance',
        jobTitle: 'Project Manager',
        phone: {
            value: '646-549-8102',
            type: PHONE_TYPES.MOBILE,
            extension: '',
        },
        email: 'katherinekedwards@rhyta.com',
        website: '',
        billingAddress: {
            street1: '1964 Cantebury Drive',
            street2: '',
            locality: 'Garden City',
            postalCode: '11530',
            region: 'NY',
            countryCode: 'US',
        },
        notes: [{
            noteType: 'businessType.values.business',
            body: 'Referred by Joe. Mentioned in voicemail she is available on Tuesdays.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.business',
            body: 'Followed up, need to put a proposal together for consultant services before end of the week.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'Client referral, reached out and left a message to see what services she is looking for.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'Followed up, Kathy is looking for IT management services and help buying new hardware for their field workers.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        }],
        contactType: CONTACT_TYPES.CUSTOMER,
        optInReason: EMAIL_STATUS.SINGLE_OPT_IN,
    },
    {
        givenName: '(Sample) Franklin',
        familyName: 'Martinez',
        company: 'Happy Bear Investment',
        jobTitle: 'Owner',
        phone: {
            value: '615-397-3015',
            type: PHONE_TYPES.WORK,
            extension: '',
        },
        email: 'fmartinez@teleworm.us',
        website: 'technikitchen.com',
        billingAddress: {
            street1: '2303 Arbor Court',
            street2: '',
            locality: 'Cheyenne',
            postalCode: '82003',
            region: 'WY',
            countryCode: 'US',
        },
        notes: [{
            noteType: 'businessType.values.business',
            body: 'Met Franklin at tradeshow. Need to follow up with more details about our services.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.business',
            body: 'Met with Franklin. Discussed options for business coaching.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'Met Franklin at tradeshow. He is interested in internet upgrades for his office buildings.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'Call Franklin to discuss networking options for 2 office buildings.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        }],
        contactType: CONTACT_TYPES.LEAD,
        optInReason: EMAIL_STATUS.SINGLE_OPT_IN,
    },
    {
        givenName: '(Sample) Lisabeth',
        familyName: 'Romo',
        company: 'Matrix Architectural Service',
        jobTitle: 'Design Contractor',
        phone: {
            value: '330-742-2501',
            type: PHONE_TYPES.MOBILE,
            extension: '',
        },
        email: 'lizromo@rhyta.com',
        website: 'sciconix.com',
        billingAddress: {
            street1: '3792 Wildwood Street',
            street2: '',
            locality: 'Youngstown',
            postalCode: '44503',
            region: 'OH',
            countryCode: 'US',
        },
        notes: [{
            noteType: 'businessType.values.business',
            body: 'New lead, submitted a request from our website to learn more about our design packages.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'New lead, submitted a request from our website to learn more about our security services ',
            type: NOTE_TYPES.OTHER,
            userId: '',
        }],
        contactType: CONTACT_TYPES.LEAD,
        optInReason: EMAIL_STATUS.SINGLE_OPT_IN,
    },
    {
        givenName: '(Sample) Taylor',
        familyName: 'Broyles',
        company: 'Janeville',
        jobTitle: 'CPA',
        phone: {
            value: '614-495-7287',
            type: PHONE_TYPES.OTHER,
            extension: '',
        },
        email: 'taylorbroyles@armyspy.com',
        website: 'imaroses.com',
        billingAddress: {
            street1: '4626 Franklee Lane',
            street2: '',
            locality: 'Philadelphia',
            postalCode: '19108',
            region: 'PA',
            countryCode: 'US',
        },
        notes: [{
            noteType: 'businessType.values.business',
            body: 'Repeat costumer. Sold consultant services 6 months ago, and is now interested in Business strategy.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'Current client. Called to add on security to their IT managment monthly plan.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        }],
        contactType: CONTACT_TYPES.LEAD,
        optInReason: EMAIL_STATUS.SINGLE_OPT_IN,
    },
    {
        givenName: '(Sample) Parker',
        familyName: 'Crawford',
        company: 'Star Bright Investment Group',
        jobTitle: 'Investment Associate',
        phone: {
            value: '917-878-1751',
            type: PHONE_TYPES.HOME,
            extension: '',
        },
        email: 'crawfordparker@armyspy.com',
        website: 'efezgek.com',
        billingAddress: {
            street1: '1932 Hoffman Avenue',
            street2: '',
            locality: 'New York',
            postalCode: '10016',
            region: 'NY',
            countryCode: 'US',
        },
        notes: [{
            noteType: 'businessType.values.business',
            body: 'Referred by Joe. Looking to get more information about Business coaching and strategy.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.business',
            body: 'Met with Parker, would like to see proposal for Business coaching and strategy services.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'Client referral, they want more information about virtual hosting for VPN and cloud back up services.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        },
        {
            noteType: 'businessType.values.software',
            body: 'Met with Parker for lunch, would like to see proposal for VPN and cloud back up services.',
            type: NOTE_TYPES.OTHER,
            userId: '',
        }],
        contactType: CONTACT_TYPES.CUSTOMER,
        optInReason: EMAIL_STATUS.SINGLE_OPT_IN,
    },
];

export const SAMPLE_TASKS = [
    {
        taskType: 'businessType.values.business',
        title: 'Follow up to schedule initial consultation',
        assignedUserId: null,
        contactIds: [null],
        description: 'Wants to meet on a Tuesday or early Wednesday.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.business',
        title: 'Send reminder to accept or revise quote',
        assignedUserId: null,
        contactIds: [null],
        description: 'Quote pending, has not reached out to ask questions.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.business',
        title: 'Call back to discuss request',
        assignedUserId: null,
        contactIds: [null],
        description: 'Intersted in business strategy, sent request from website. Need to qualify.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.business',
        title: 'Send email with business strategy details',
        assignedUserId: null,
        contactIds: [null],
        description: 'Send updated business strategy detail packet.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.business',
        title: 'Schedule coaching and strategy sessions',
        assignedUserId: null,
        contactIds: [null],
        description: '2hrs per week (split time for both coaching and strategy), 4 weeks.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.software',
        title: 'Follow up to schedule setup and go over roll out plans',
        assignedUserId: null,
        contactIds: [null],
        description: 'Wants to meet on a Tuesday or early Wednesday.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.software',
        title: 'Send reminder to accept or revise quote',
        assignedUserId: null,
        contactIds: [null],
        description: 'Quote pending, has not reached out to ask questions.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.software',
        title: 'Call back to discuss request',
        assignedUserId: null,
        contactIds: [null],
        description: 'Interested in security services, sent request from website. Need to qualify.',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.software',
        title: 'Revise monthly contract after quote is accepted',
        assignedUserId: null,
        contactIds: [null],
        description: 'Adding security services to current IT managment plan',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
    {
        taskType: 'businessType.values.software',
        title: 'Schedule installation calls',
        assignedUserId: null,
        contactIds: [null],
        description: 'VPN and cloud back up services',
        dueDate: null,
        remindTimeOffsetInMillis: 300000,
    },
];

export const SAMPLE_PIPELINE = {
    name: '(Sample) Sales pipeline',
    stages: [
        { name: 'New leads' },
        { name: 'Qualified leads' },
        { name: 'Quote sent' },
        { name: 'Negotiating' },
        { name: 'Quote accepted' },
    ],
};

export const SAMPLE_DEALS = [
    {
        dealType: 'businessType.values.business',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[0].contactType,
            email: SAMPLE_CONTACTS[0].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[0].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[0].givenName} ${SAMPLE_CONTACTS[0].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Business strategy - Public form',
        owner: null,
        stage: null,
        value: { amount: 1400, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.business',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[1].contactType,
            email: SAMPLE_CONTACTS[1].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[1].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[1].givenName} ${SAMPLE_CONTACTS[1].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Business strategy - Repeat client',
        owner: null,
        stage: null,
        value: { amount: 1400, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.business',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[2].contactType,
            email: SAMPLE_CONTACTS[2].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[2].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[2].givenName} ${SAMPLE_CONTACTS[2].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Business coaching',
        owner: null,
        stage: null,
        value: { amount: 1200, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.business',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[3].contactType,
            email: SAMPLE_CONTACTS[3].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[3].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[3].givenName} ${SAMPLE_CONTACTS[3].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Consultant services - Referral',
        owner: null,
        stage: null,
        value: { amount: 800, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.business',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[4].contactType,
            email: SAMPLE_CONTACTS[4].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[4].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[4].givenName} ${SAMPLE_CONTACTS[4].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Business coaching and strategy - Referral',
        owner: null,
        stage: null,
        value: { amount: 800, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.software',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[0].contactType,
            email: SAMPLE_CONTACTS[0].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[0].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[0].givenName} ${SAMPLE_CONTACTS[0].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Monthly IT Security (2 year contract)',
        owner: null,
        stage: null,
        value: { amount: 12000, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.software',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[1].contactType,
            email: SAMPLE_CONTACTS[1].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[1].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[1].givenName} ${SAMPLE_CONTACTS[1].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Network Cabling',
        owner: null,
        stage: null,
        value: { amount: 1500, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.software',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[2].contactType,
            email: SAMPLE_CONTACTS[2].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[2].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[2].givenName} ${SAMPLE_CONTACTS[2].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: ' IT Security Services',
        owner: null,
        stage: null,
        value: { amount: 5400, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.software',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[3].contactType,
            email: SAMPLE_CONTACTS[3].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[3].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[3].givenName} ${SAMPLE_CONTACTS[3].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Current client - Security added to monthly IT managment plan',
        owner: null,
        stage: null,
        value: { amount: 1800, currencyCode: 'USD' },
    },
    {
        dealType: 'businessType.values.software',
        contacts: [{
            id: null,
            contactType: SAMPLE_CONTACTS[4].contactType,
            email: SAMPLE_CONTACTS[4].email,
            emailAddresses: [
                { type: '', value: SAMPLE_CONTACTS[4].email },
                null,
                null,
            ],
            fullName: `${SAMPLE_CONTACTS[4].givenName} ${SAMPLE_CONTACTS[4].familyName}`,
            primaryContact: true,
        }],
        estimatedCloseDate: null,
        closedDate: null,
        name: 'Monthly Virtual Hosting',
        owner: null,
        stage: null,
        value: { amount: 900, currencyCode: 'USD' },
    },
];

export const SAMPLE_PRODUCTS = [
    {
        productType: 'businessType.values.business',
        endBy: null,
        name: '(Sample) Initial Consultation',
        description: '2 hour minimum session - During this consultation, we\'ll learn more about you and your business. We\'ll also discuss short-term and long-term goals.',
        rate: 100,
    },

    {
        productType: 'businessType.values.business',
        endBy: null,
        name: '(Sample) Consultant Services',
        description: 'During these ongoing sessions, we\'ll partner with you (and your team) to provide business advice and assistance',
        rate: 150,
    },

    {
        productType: 'businessType.values.business',
        endBy: null,
        name: '(Sample) Business Coaching',
        description: 'New to business? We\'ll set up 1:1 sessions (or group workshops) based on best practices to help you and your team continue to learn and grow',
        rate: 150,
    },
    {
        productType: 'businessType.values.business',
        endBy: null,
        name: '(Sample) Consultant Strategy',
        description: 'Need help creating a business strategy to meet your goals? We\'ll set up 1:1 sessions to help you design and plan a business strategy.',
        rate: 175,
    },
    {
        productType: 'businessType.values.software',
        endBy: null,
        name: '(Sample) Monthly IT Security',
        description: 'We can keep you protected and reduce your risk and liability from phishing emails to ransomware, server exploits to network vulnerabilities.',
        rate: 225,
    },

    {
        productType: 'businessType.values.software',
        endBy: null,
        name: '(Sample) Monthly Virtutal Hosting',
        description: 'Protect your business and your most valuable asset -- your data -- from potential disaster. Backup your cloud files, local desktops and office servers to a secure environment.',
        rate: 75,
    },

    {
        productType: 'businessType.values.software',
        endBy: null,
        name: '(Sample) Network Cabling Estimate',
        description: 'Whether your upgrading WiFi, building or relocating, your network cabling needs are covered. Our network cabling specialists deliver quality network cabling solutions.',
        rate: 150,
    },
    {
        productType: 'businessType.values.software',
        endBy: null,
        name: '(Sample) Monthly IT Management',
        description: 'We have a team of IT professionals ready to support you with all of your tech needs. We support a wide variety of systems and respond to any issues or needs on the same day.',
        rate: 500,
    },
];

export const SAMPLE_INVOICES = [
    {
        allowPayment: false,
        allowPaypal: false,
        contactId: null,
    },
    {
        allowPayment: false,
        allowPaypal: false,
        contactId: null,
    },
    {
        allowPayment: false,
        allowPaypal: false,
        contactId: null,
    },
    {
        allowPayment: false,
        allowPaypal: false,
        contactId: null,
    },
    {
        allowPayment: false,
        allowPaypal: false,
        contactId: null,
    },
];

export const SAMPLE_QUOTES = [
    {
        contactId: [null],
        status: 'DRAFT',
    },
    {
        contactId: [null],
        status: 'DRAFT',
    },
    {
        contactId: [null],
        status: 'DRAFT',
    },
    {
        contactId: [null],
        status: 'DRAFT',
    },
    {
        contactId: [null],
        status: 'DRAFT',
    },
];
