<template lang="html">
    <div class="review-score">
        <div v-if="formattedRating" class="total-score">
            {{ formattedRating }}
        </div>

        <review-stars :rating="rating" small />

        <a
            v-if="reviewCount && gmbEnabled"
            :href="googleReviewLink"
            target="_blank"
            rel="noopener noreferrer"
            class="reviews-link"
        >
            {{ reviewCount }} {{ this.$t('reviews') }}
        </a>

        <small v-else-if="reviewCount">
            {{ reviewCount }} {{ this.$t('reviews') }}
        </small>
    </div>
</template>

<script>
import ReviewStars from './ReviewStars';
import { mapState } from 'vuex';
import { FF_KEAP_GMB } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        ReviewStars,
    },

    props: {
        reviewCount: [Number, String],
        rating: [Number, String],
    },

    computed: {
        ...mapState({
            gmbEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_GMB],
        }),

        formattedRating() {
            return this.rating
                ? this.rating.toFixed(1)
                : '0';
        },

        googleReviewLink() {
            return 'https://business.google.com/reviews';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .review-score {
        display: flex;
        flex-direction: column;
        text-align: center;
    }

    .total-score {
        font-size: px-to-rem(52px);
        font-weight: 300;
    }

    .reviews-link {
        font-size: $font-size-small;
    }
</style>

<i18n>
{
    "en-us": {
        "reviews": "Reviews"
    }
}
</i18n>
