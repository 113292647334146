<template>
    <div class="configure">
        <configure-details
            :title="title"
            :has-error="hasError"
            :trigger-action="triggerAction"
            :error-message="errorMessage || $t('error')"
            @edit="$emit('edit', { trigger: value })"
            @remove="$emit('remove')"
        />

        <div class="button-row multiple-buttons">
            <manage-configuration
                class="manage-forms"
                :title="$t('manageLabel')"
                :manage-props="manageProps"
            />

            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';
import ManageConfiguration from '@/automations/components/configure/ManageConfiguration';
import { LANDING_PAGE_FORM_SUBMITTED } from '@/automations/constants/automations.constants';

export default {
    components: {
        ConfigureDetails,
        ManageConfiguration,
    },

    props: {
        value: Object,
        hasError: Boolean,
        errorMessage: String,
        triggerAction: String,
    },

    data() {
        return {
            manageProps: {
                confirmTitle: this.$t('manage.title'),
                confirmMessage: this.$t('manage.message'),
                confirmButtonLabel: this.$t('manage.confirm'),
                route: { name: 'marketing-sites' },
            },
        };
    },

    computed: {
        ...mapGetters({
            formById: 'customForms/formById',
        }),

        form() {
            return this.formById(this.value?.sourceId);
        },

        isSingleLandingPageForm() {
            return this.value.type === LANDING_PAGE_FORM_SUBMITTED;
        },

        title() {
            if (this.isSingleLandingPageForm) {
                return this.form?.title ?? this.$t('emptyFormLabel');
            }

            return this.$t('anyLandingPageForm');
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>

<i18n>
{
    "en-us": {
        "anyLandingPageForm": "Any landing page form",
        "manageLabel": "Landing pages",
        "emptyFormLabel": "Landing page form is submitted",
        "manage": {
            "title": "Navigate to the landing page builder to add a new form?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Go to landing page builder"
        },
        "error": "Please select a form"
    }
}
</i18n>
