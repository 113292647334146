<template>
    <div class="filter-criteria-container">
        <div class="filter-label">
            {{ $t('label') }}
        </div>

        <div class="filter-criteria">
            <ds-multiselect
                :value="industry"
                bind-value-only
                data-qa="industry-criteria"
                label-prop="name"
                value-prop="value"
                :placeholder="$t('industryPlaceholder')"
                :options="industryOptions"
                class="filter-select"
                @input="handleIndustryChange"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        industry: String,
    },

    computed: {
        industryOptions() {
            return [
                { name: this.$t('industryOptions.all'), value: 'All' },
                { name: this.$t('industryOptions.automotive'), value: 'Automotive' },
                { name: this.$t('industryOptions.business'), value: 'Business' },
                { name: this.$t('industryOptions.computer'), value: 'Computer' },
                { name: this.$t('industryOptions.cosmetics'), value: 'Cosmetics' },
                { name: this.$t('industryOptions.education'), value: 'Education' },
                { name: this.$t('industryOptions.electronics'), value: 'Electronics' },
                { name: this.$t('industryOptions.fashion'), value: 'Fashion' },
                { name: this.$t('industryOptions.food'), value: 'Food' },
                { name: this.$t('industryOptions.health'), value: 'Health' },
                { name: this.$t('industryOptions.hr'), value: 'Hr' },
                { name: this.$t('industryOptions.manufacturing'), value: 'Manufacturing' },
                { name: this.$t('industryOptions.marketing'), value: 'Marketing' },
                { name: this.$t('industryOptions.media'), value: 'Media' },
                { name: this.$t('industryOptions.news'), value: 'News' },
                { name: this.$t('industryOptions.nonprofit'), value: 'Nonprofit' },
                { name: this.$t('industryOptions.other'), value: 'Other' },
                { name: this.$t('industryOptions.pet'), value: 'Pet' },
                { name: this.$t('industryOptions.realEstate'), value: 'RealEstate' },
                { name: this.$t('industryOptions.sports'), value: 'Sports' },
                { name: this.$t('industryOptions.transportation'), value: 'Transportation' },
                { name: this.$t('industryOptions.travel'), value: 'Travel' },
            ];
        },
    },

    methods: {
        handleIndustryChange(value) {
            this.$emit('update-industry', value);
        },
    },
};
</script>

<style lang="scss" scoped>
    .filter-criteria-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: $gp * 2;
    }

    .filter-label {
        color: $color-ink;
        margin-right: $gp;
    }

    .filter-criteria {
        width: px-to-rem(300);
    }

    .filter-select {
        margin-bottom: 0;
    }
</style>

<i18n>
{
    "en-us": {
        "label": "Filter by",
        "industryPlaceholder": "Industry",
        "industryOptions": {
            "all": "View All",
            "automotive": "Automotive",
            "business": "Business services",
            "computer": "Computer & internet",
            "cosmetics": "Cosmetics",
            "education": "Education",
            "electronics": "Electronics",
            "fashion": "Fashion",
            "food": "Food & beverage",
            "health": "Health & wellness",
            "hr": "Human resources",
            "manufacturing": "Manufacturing",
            "marketing": "Marketing & design",
            "media": "Media & entertainment",
            "news": "News, blog & magazines",
            "nonprofit": "Non-profit",
            "other": "Others",
            "pet": "Pet & animal care",
            "realEstate": "Real estate",
            "sports": "Sports",
            "transportation": "Transportation",
            "travel": "Travel & leisure"
        }
    }
}
</i18n>
