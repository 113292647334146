import axios from 'axios';
import debounce from 'lodash.debounce';
import sentry from '@/analytics/sentry';
import {
    ALLOWED_VALUE_KEY_PATTERN,
    INVALID_SUFFIX_ERROR_MESSAGE,
    INVALID_EMAIL_ERROR_MESSAGE,
    FIELD_TYPE_EXTERNAL,
    INTERNAL_FIELD_IDS,
} from '@/smartForms/smartform.constants';
import {
    FORM_AUDIENCE_INTERNAL,
    FORM_STATUSES,
    FORM_TYPE_CONTACTS,
} from '@/customForms/customForm.constants';
import { SAVING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

let loadCustomFieldsPromise;

export default {
    LOAD_SMART_FORM(context, payload) {
        return loadSmartForm(context, payload);
    },

    LOAD_SMART_FORMS(context, payload) {
        return loadSmartForms(context, payload);
    },

    SET_SMART_FORM_LIST_SORT(context, payload) {
        return setFormListSort(context, payload);
    },

    SUBMIT_SMART_FORM(context, payload) {
        return submitSmartForm(context, payload);
    },

    DELETE_SMART_FORM(context, payload) {
        return deleteSmartForm(context, payload);
    },

    CREATE_SMART_FORM(context, payload) {
        return createSmartForm(context, payload);
    },

    SAVE_SMART_FORM_BUILDER_PROGRESS(context, payload) {
        return saveSmartFormBuilderProgress(context, payload);
    },

    LOAD_SMART_FORM_FIELDS(context, payload) {
        return loadSmartFormFields(context, payload);
    },

    SET_BUILDER_FORM(context, payload) {
        return setBuilderForm(context, payload);
    },

    UPDATE_BUILDER_FORM(context, payload) {
        return updateBuilderForm(context, payload);
    },
};

const loadSmartForm = async ({ commit, rootState, dispatch }, { formId }) => {
    const [{ data }, customFields] = await Promise.all([
        axios.get(`${process.env.VUE_APP_FORM_SERVICE_URL}/forms/${formId}`, {}),
        getContactCustomFieldMetadata(rootState, dispatch),
    ]);

    processForm(customFields, data);

    setBuilderForm({ commit }, data);
};

const getContactCustomFieldMetadata = async (rootState, dispatch) => {
    await loadSmartFormFields({ rootState, dispatch });

    const { fieldGroups } = rootState.contacts;

    return fieldGroups ? fieldGroups.reduce((groupFields, group) => {
        return groupFields.concat(group.fields.filter((field) => field.customField));
    }, []) : [];
};

const processForm = (customFields, form) => {
    if (!form.usesCustomFieldDataFormFieldId) {
        form.fields.forEach((field) => {
            migrateField(field, customFields);
        });

        form.usesCustomFieldDataFormFieldId = true;
    }

    form.fields.forEach((field) => {
        populateLabel(field, customFields);
    });
};

const migrateField = (field, customFields) => {
    if (field.fieldType === FIELD_TYPE_EXTERNAL) {
        const customField = customFields.find((possibleCustomField) => possibleCustomField.id === field.id);

        if (customField && customField.dataFormFieldId) {
            field.id = customField.dataFormFieldId.toString();
        }
    }
};

const populateLabel = (field, customFields) => {
    if (field.fieldType === FIELD_TYPE_EXTERNAL) {
        const customField = customFields.find((possibleCustomField) => possibleCustomField.dataFormFieldId.toString() === field.id);

        if (customField) {
            field.label = customField.name;
        } else if (INTERNAL_FIELD_IDS.includes(field.id)) {
            field.label = `smartForms.nonContactFields.${field.id}`;
        } else if (field?.id?.startsWith('standard.')) {
            field.label = `customForms.fields.${field.id}`;
        } else {
            field.label = `forms.standardFields.${field.id}`;
        }
    }
};

const loadSmartForms = async ({ commit, rootState, dispatch }) => {
    try {
        const accountId = rootState.auth.account.appName;
        const formType = FORM_TYPE_CONTACTS;
        const pageSize = 1000;
        const url = `${process.env.VUE_APP_FORM_SERVICE_URL}/forms`;

        const [{ data }, customFields] = await Promise.all([
            axios.get(url, {
                params: {
                    orderBy: 'title:ASC',
                    audiences: FORM_AUDIENCE_INTERNAL,
                    formType,
                    accountId,
                    pageSize,
                },
            }),
            getContactCustomFieldMetadata(rootState, dispatch),
        ]);

        data.forms.forEach((form) => {
            processForm(customFields, form);
        });

        commit('SET_SMART_FORMS', data);
    } catch (e) {
        sentry.log('Failed to get internal form list', { message: e.message });
    }
};

const setFormListSort = async ({ commit }, sort) => {
    commit('UPDATE_SORT', sort);
};

const submitSmartForm = (_, {
    submissionData,
    formId,
    targetId,
    sourceType,
    sourceDisplayType,
}) => {
    const url = `${process.env.VUE_APP_FORM_SERVICE_URL}/forms/${formId}/submissions`;
    const postData = {
        data: submissionData,
        targetId,
        sourceType,
        sourceDisplayType,
    };

    return axios.post(url, postData)
        .catch((error) => {
            if (error.response && error.response.status === 400 && error.response.data) {
                const returnedError = JSON.stringify(error.response.data);

                if (returnedError.match(INVALID_EMAIL_ERROR_MESSAGE)) {
                    throw new Error(INVALID_EMAIL_ERROR_MESSAGE);
                } else if (returnedError.match(INVALID_SUFFIX_ERROR_MESSAGE)) {
                    throw new Error(INVALID_SUFFIX_ERROR_MESSAGE);
                }
            } else {
                sentry.log('Internal form submission failure', { message: error.message });
            }
        });
};

const deleteSmartForm = (context, { id }) => {
    const url = `${process.env.VUE_APP_FORM_SERVICE_URL}/forms/${id}`;

    return axios
        .delete(url)
        .catch((e) => {
            sentry.log('Failed to delete form', { message: e.message });
        });
};

const createSmartForm = ({ commit, rootState }, form) => {
    const formUrl = `${process.env.VUE_APP_FORM_SERVICE_URL}/forms`;
    const postData = {
        ...form,
        userId: rootState.auth.user.casId,
        accountId: rootState.auth.account.appName,
    };

    return axios.post(formUrl, postData)
        .then(({ data: savedForm }) => {
            setBuilderForm({ commit }, savedForm, true);

            commit('SET_FORM_DETAILS_VALID', false);
            commit('UPDATE_SMART_FORMS', savedForm);

            return savedForm;
        })
        .catch((e) => {
            sentry.log('Failed to create form', { message: e.message });
        });
};

const saveSmartFormBuilderProgress = ({ state, commit }, {
    form, shouldDebounce, status, date = Date.now(),
}) => {
    if (status === FORM_STATUSES.PUBLISHED && form.status !== FORM_STATUSES.PUBLISHED) {
        commit('SET_FORM_STATUS_PUBLISHED');

        if (form.everPublished === false) {
            commit('SET_FORM_EVER_PUBLISHED');
        }
    } else if (status === FORM_STATUSES.NOT_PUBLISHED && form.status !== FORM_STATUSES.NOT_PUBLISHED) {
        commit('SET_FORM_STATUS_NOT_PUBLISHED');
    }

    if (shouldDebounce) {
        return saveFormDebounce({ state, commit }, { form });
    }

    return saveForm({ state, commit }, { form, date });
};

const saveFormDebounce = debounce((context, payload) => {
    return saveForm(context, payload).catch({});
}, SAVING_DEBOUNCE_DELAY);

const saveForm = async ({ commit }, { form, date }) => {
    if (form != null && form.title !== '') {
        const formToSave = JSON.parse(JSON.stringify(form));

        formToSave.updatedBy = null;

        if (formToSave.fields) {
            formToSave.fields = formToSave.fields.reduce((fields, field) => {
                // filter out the dummy field
                if (!field || !field.fieldType) {
                    return fields;
                }

                if (field.fieldType === FIELD_TYPE_EXTERNAL) {
                    field.label = null;
                    field.fieldName = null;
                }

                if (field.helperText && field.helperText.text === '') {
                    field.helperText = null;
                }

                if (field.allowedValues) {
                    const fieldOptionKeys = Object.keys(field.allowedValues);

                    for (const key of fieldOptionKeys) {
                        const fieldOptionValue = field.allowedValues[key].value;

                        if (fieldOptionValue && !fieldOptionValue.match(ALLOWED_VALUE_KEY_PATTERN)) {
                            delete field.allowedValues[key].value; // Removing temporary value, smart-forms-api will create this value
                        }
                    }
                }

                return fields.concat([field]);
            }, []);
        }

        commit('SET_AUTO_SAVE_IN_PROGRESS', true);

        try {
            await axios
                .put(`${process.env.VUE_APP_FORM_SERVICE_URL}/forms/${form.id}`, formToSave);

            commit('SET_AUTO_SAVE_IN_PROGRESS', false);
            commit('SET_AUTO_SAVE_TIMESTAMP', date);
        } catch (error) {
            sentry.log('Failed to save form', { message: error.message });
        }
    }
};

const loadSmartFormFields = async ({ dispatch, rootState }) => {
    const { fieldGroups } = rootState.contacts;

    if (fieldGroups.length === 0) {
        if (!loadCustomFieldsPromise) {
            loadCustomFieldsPromise = dispatch('contacts/LOAD_CUSTOM_FIELDS', {}, { root: true });
        }
        await loadCustomFieldsPromise;
        loadCustomFieldsPromise = null;
    }

    return loadCustomFieldsPromise;
};

const setBuilderForm = ({ commit }, form, isNew = false) => {
    form.isNew = isNew;

    form.fields.forEach((field, index) => {
        if (!field.key) {
            field.key = `field${index}`;
        }

        if (!field.allowedValues && field.fieldType !== 'External') {
            field.allowedValues = [];
        }
    });

    commit('SET_BUILDER_FORM', form);

    return form;
};

const updateBuilderForm = ({ commit }, partialForm) => {
    commit('UPDATE_BUILDER_FORM', partialForm);

    return partialForm;
};
