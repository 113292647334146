import { mapState } from 'vuex';
import { SCOPES } from '@/shared/constants/integrations.constants';
import { IMPORT_TYPES } from '@/import/import.constants';
import { INTEGRATION_STATUS } from '@/appointments/appointments.constants';
import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import moment from 'moment';
import sentry from '@/analytics/sentry';

export default {
    data() {
        return {
            reloadDelay: 2000,
            isConnecting: false,
        };
    },

    computed: {
        ...mapState({
            casId: ({ auth }) => auth.user.casId,
            providers: ({ calendar }) => calendar.providers,
            appointmentTypes: ({ calendar }) => calendar.apptTypes,
            apptTypesLoaded: ({ calendar }) => calendar.apptTypesLoaded,
            businessName: ({ auth }) => auth.account.companyProfile.companyName,
            displayName: ({ auth }) => auth.user.fullName,
            calendarStateInitialized: ({ calendar }) => calendar.stateInitialized,
            routeQuery: ({ route }) => route.query,
            appId: ({ auth }) => auth.session.coreAppId,
            invoice: ({ sales }) => sales.invoice,
        }),

        auth_accountId() {
            return this.routeQuery.account_id ? this.routeQuery.account_id : '';
        },

        emailProvider() {
            return this.routeQuery.provider;
        },

        hasAuthScope() {
            const { provider, scope } = this.routeQuery;

            return Boolean(provider && scope);
        },

        hasCalendarAuth() {
            const { authType } = this.routeQuery;

            return authType === SCOPES.CALENDAR && this.hasAuthScope;
        },

        hasContactAuth() {
            const { authType } = this.routeQuery;

            return authType === SCOPES.CONTACTS && this.hasAuthScope;
        },

        hasEmailAuth() {
            const { authType } = this.routeQuery;

            return authType === SCOPES.EMAIL && this.hasAuthScope;
        },

        hasAccountingAuth() {
            const { authType } = this.routeQuery;

            return authType === SCOPES.ACCOUNTING && this.hasAuthScope;
        },

        auth_isContactAuthSuccess() {
            return this.hasContactAuth && Boolean(this.routeQuery.account_id);
        },

        isEmailAuthSuccess() {
            return this.hasEmailAuth && Boolean(this.routeQuery.account_id);
        },

        isEmailAuthFail() {
            return this.hasEmailAuth && Boolean(this.routeQuery.error);
        },

        isAccountingAuthSuccess() {
            return this.hasAccountingAuth && Boolean(this.routeQuery.account_id);
        },

        isAccountingAuthFail() {
            return this.hasAccountingAuth && Boolean(this.routeQuery.error);
        },

        activeProvider() {
            return this.providers.find(({ status }) => status === INTEGRATION_STATUS.READY);
        },
    },

    methods: {
        auth_isCalendarAuthSuccess(providerName) {
            if (!this.hasCalendarAuth) {
                return false;
            }

            return Boolean(this.routeQuery.account_id && this.routeQuery.provider === providerName && this.routeQuery.success === 'true');
        },

        auth_isCalendarAuthFail(providerName) {
            return Boolean(this.hasCalendarAuth && this.routeQuery.error && this.routeQuery.provider === providerName);
        },

        auth_handleCalendarAuth(providerId, showBannerOnError = true, routerQueryOnSuccess = {}) {
            if (this.auth_isCalendarAuthSuccess(providerId)) {
                this.isConnecting = true;

                const { displayName, businessName } = this;

                const payload = {
                    userId: this.casId,
                    calendarProvider: providerId,
                    calendarProviderAccountId: this.auth_accountId,
                    timeZone: moment.tz.guess(),
                    businessName,
                    displayName,
                };

                const headline = this.$t('success.integrations.calendar.connect.headline');
                const message = this.$t('success.integrations.calendar.connect.message');
                const errorMessage = this.$t('errors.integration.calendar.connect');

                this.$router.replace({ query: routerQueryOnSuccess });

                return this.$store.dispatch('calendar/SAVE_PROVIDER_INTEGRATION', payload)
                    .then(() => {
                        this.auth_initializeCalendarAuthState(true, showBannerOnError);
                        this.$toast({ headline, message });
                        this.isConnecting = false;

                        if (!this.apptTypesLoaded) {
                            this.$store.dispatch('calendar/LOAD_APPT_TYPES')
                                .catch(() => {});
                        }
                    })
                    .catch(() => {
                        this.isConnecting = false;

                        if (showBannerOnError) {
                            this.$error({
                                message: errorMessage,
                            });
                        }
                    });
            }

            if (this.auth_isCalendarAuthFail(providerId)) {
                this.$router.replace({ query: routerQueryOnSuccess });

                if (showBannerOnError) {
                    this.$error({
                        message: this.$t('errors.integration.calendar.connect'),
                    });
                }
            }

            return Promise.resolve();
        },

        auth_getOauthReturnUrl(params) {
            const keys = Object.keys(params);
            let url = window.location.href;

            keys.forEach((k) => {
                const value = encodeURIComponent(params[k]);
                const key = encodeURIComponent(k);

                if (url.includes('?')) {
                    url = `${url}&${key}=${value}`;
                } else {
                    url = `${url}?${key}=${value}`;
                }
            });

            return encodeURIComponent(url);
        },

        auth_initializeCalendarAuthState(reload = false, showBannerOnError = true) {
            if (this.calendarStateInitialized && !reload) {
                return Promise.resolve();
            }

            return this.$store.dispatch('calendar/INITIALIZE_STATE')
                .catch((e) => {
                    this.$store.commit('calendar/UPDATE_PROVIDER', {
                        ...this.activeProvider,
                        status: INTEGRATION_STATUS.BROKEN,
                    });

                    let message = this.$i18nInstance.t('errors.integration.calendar.api');

                    if (e && e.message) {
                        const translatedMessage = this.$i18nInstance.t(e.message);

                        if (translatedMessage !== e.message) {
                            message = translatedMessage;
                        }
                    }

                    if (showBannerOnError) {
                        this.$error({
                            message,
                        });
                    }
                });
        },

        auth_authorizeCalendar() {
            const openModal = this.routeQuery.setupCalendarModal === 'true';

            if (this.hasCalendarAuth) {
                const providerId = this.routeQuery.provider;

                return this.auth_handleCalendarAuth(providerId)
                    .then(() => {
                        if (openModal && this.apptConnect_connectToProvider) {
                            this.apptConnect_connectToProvider();
                        } else if (openModal && this.getConnectToProvider) {
                            this.getConnectToProvider()({ bus: this.$bus, router: this.$router, store: this.$store });
                        }
                    }).catch((e) => {
                        sentry.captureException(e);
                    });
            }

            return null;
        },

        auth_authorizeQboContacts() {
            this.$track('Integrations - QBO - clicked : authorize contacts');

            const scope = 'accounting';
            const provider = 'intuit';
            const redirectUrl = this.auth_getOauthReturnUrl({
                provider,
                authType: scope,
                scope,
            });
            const message = this.$t('errors.integration.auth', { provider: 'QuickBooks Online' });

            return this.$store.dispatch('auth/AUTHORIZE_PROVIDER', { provider, scope, redirectUrl })
                .then((location) => {
                    window.location.href = location;
                })
                .catch(() => {
                    this.$error({ message });
                });
        },

        auth_authorizeContacts(provider) {
            this.$track(`Contacts - Import - clicked : Sync ${provider}`);

            const scope = 'contacts';
            const redirectUrl = this.auth_getOauthReturnUrl({
                provider,
                authType: scope,
                scope,
            });

            const errorMessage = this.$t('errors.integration.auth', { provider: this.$t(provider) });

            return this.$store.dispatch('auth/AUTHORIZE_PROVIDER', { provider, scope, redirectUrl })
                .then((location) => {
                    window.location.href = location;
                })
                .catch(() => {
                    this.$error({ message: errorMessage });
                });
        },

        auth_handleContactAuth(provider) {
            if (this.auth_isContactAuthSuccess) {
                const accountId = this.routeQuery.account_id;

                switch (provider) {
                case IMPORT_TYPES.GOOGLE:
                    return this.$store.dispatch('contactImport/LOAD_GOOGLE_CONTACTS', accountId);
                default:
                    return Promise.resolve();
                }
            }

            return Promise.resolve();
        },

        auth_authorizeBusiness() {
            this.$track('Business - Integrate - Google My Business');

            const scope = 'business';
            const redirectUrl = this.auth_getOauthReturnUrl({
                authType: 'business',
            });

            const errorMessage = this.$t('errors.integration.auth', { provider: 'google' });

            return this.$store.dispatch('auth/AUTHORIZE_PROVIDER', { provider: 'google', scope, redirectUrl })
                .then((location) => {
                    window.location.href = location;
                })
                .catch(() => {
                    this.$error({ message: errorMessage });
                });
        },

        auth_closeModal() {
            this.$router.replace({ query: {} });
            this.$store.commit('contactImport/SET_CONTACT_LIST', []);
            this.$bus.$emit('POP_NESTED_MODAL', 5);
        },

        auth_handleEmailAuth() {
            if (this.isEmailAuthSuccess) {
                this.$router.replace({ query: {} });

                const payload = {
                    userId: this.casId,
                    emailProvider: this.emailProvider,
                    emailProviderId: this.auth_accountId,
                    tenant: this.appId,
                };
                const successHeadline = this.$t(`success.integrations.${payload.emailProvider}.connect.headline`);
                const successMessage = this.$t(`success.integrations.${payload.emailProvider}.connect.message`);
                const errorMessage = this.$t(`errors.integration.${payload.emailProvider}.connect`);

                return this.$store.dispatch('email/SAVE_PROVIDER_INTEGRATION', payload)
                    .then(() => {
                        this.$track('Email Sync - Integration Email Provider Card - success : track when a user connects to email');

                        if (payload.emailProvider === 'GOOGLE') {
                            amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTED, {
                                'Connected Integration': 'Gmail Email Sync',
                            });

                            intercom.logEvent(intercom.events.GMAIL_EMAIL_SYNC_CONNECTED);
                        } else {
                            amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTED, {
                                'Connected Integration': 'Microsoft Email Sync',
                            });

                            intercom.logEvent(intercom.events.MICROSOFT_EMAIL_SYNC_CONNECTED);
                        }

                        this.$toast({
                            headline: successHeadline,
                            message: successMessage,
                        });

                        this.auth_reloadEmailProviders();

                        return new Promise((resolve) => {
                            setTimeout(() => {
                                this.auth_reloadEmailProviders(); // allows for email-sync to update status in case of unsupported account
                                resolve();
                            }, this.reloadDelay);
                        });
                    })
                    .catch(() => {
                        this.$error({
                            message: errorMessage,
                        });
                    });
            }

            if (this.isEmailAuthFail) {
                this.$router.replace({ query: {} });

                this.$error({
                    message: this.$t(`errors.integration.${this.emailProvider}.connect`),
                });
            }

            return Promise.resolve();
        },

        auth_handleQboContactAuth() {
            if (this.isAccountingAuthSuccess) {
                this.$router.replace({ query: {} });

                const payload = {
                    realmId: this.auth_accountId,
                    tenantId: this.appId,
                    userId: this.casId,
                };
                const successHeadline = this.$t('success.integrations.INTUIT.connect.headline');
                const successMessage = this.$t('success.integrations.INTUIT.connect.message');
                const errorMessage = this.$t('errors.integration.INTUIT.api');
                const alreadyConnected = this.$t('errors.integration.INTUIT.duplicate');
                const dupeCheck = this.$t('errors.integration.INTUIT.dupecheck');

                return this.$store.dispatch('contactImport/SAVE_QBO_INTEGRATION', payload)
                    .then(() => {
                        this.$track('Integration - QBO Sync - success : connected to QBO');

                        amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTED, {
                            'Connected Integration': 'QuickBooks Online',
                            'Event Source': 'Integrations Page',
                        });

                        intercom.logEvent(intercom.events.QBO_CONNECTED);

                        this.$toast({
                            headline: successHeadline,
                            message: successMessage,
                        });
                    })
                    .catch((error) => {
                        this.$track('Integration - QBO Sync - failure : not connected to QBO');
                        this.$error({
                            message: error?.response.data.message === dupeCheck ? alreadyConnected : errorMessage,
                        });
                    });
            }

            if (this.isAccountingAuthFail) {
                this.$track('Integration - QBO Sync - failure', { errorMessage: this.routeQuery.error });

                this.$router.replace({ query: {} });

                this.$toast({
                    headline: this.$t('providers.INTUIT.name'),
                    message: this.$t('errors.integration.INTUIT.connect'),
                });
            }

            return Promise.resolve();
        },

        auth_reloadEmailProviders() {
            const message = this.$t(`errors.integration.${this.emailProvider}.api`);

            return this.$store.dispatch('email/LOAD_PROVIDER_INTEGRATIONS')
                .catch(() => {
                    this.$error({
                        message,
                    });
                });
        },

        auth_authorizePaypal() {
            this.$track('Integrations - PayPal - clicked : authorize PayPal');
            const message = this.$t('errors.integration.auth', { provider: 'PayPal' });
            let redirectUrl = this.auth_getOauthReturnUrl({});

            if (redirectUrl.endsWith('invoices') && this.invoice.id) {
                redirectUrl = `${redirectUrl}%2f${this.invoice.id}`;
            }

            return this.$store.dispatch('billing/INITIATE_PAYPAL_ONBOARDING', redirectUrl)
                .then((location) => {
                    const url = `${location}&displayMode=minibrowser`;

                    window.open(url, 'ppFrame', 'noopener,noreferrer');
                })
                .catch(() => {
                    this.$error({ message });
                });
        },

        auth_handlePaypalAuth() {
            this.$router.replace({ query: {} });

            const successHeadline = this.$t('success.integrations.PAYPAL.connect.headline');
            const successMessage = this.$t('success.integrations.PAYPAL.connect.message');
            const errorMessage = this.$t('billing.paypal.connecting.error');

            const routeData = {
                ...this.routeQuery,
                consentStatus: this.routeQuery.consentStatus === 'true',
                isEmailConfirmed: this.routeQuery.isEmailConfirmed === 'true',
                permissionsGranted: this.routeQuery.permissionsGranted === 'true',
            };

            if (routeData.consentStatus && routeData.permissionsGranted) {
                return this.$store.dispatch('billing/SAVE_PAYPAL_ONBOARD_DATA', routeData)
                    .then(() => {
                        this.$track('Integration - PayPal Sync - success: connected to PayPal');
                        this.$toast({
                            headline: successHeadline,
                            message: successMessage,
                        });
                        this.$store.dispatch('billing/LOAD_PAYMENT_PROCESSORS');
                    })
                    .catch(() => {
                        this.$track('Integration - PayPal Sync - failure : not connected to PayPal');
                        this.$error({ message: errorMessage });
                    });
            }


            return Promise.resolve();
        },
    },
};
