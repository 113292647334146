import { IDLE } from '@/contacts/loadingStatuses.js';
import { FORM_TYPE_CONTACTS, FORM_TYPE_LANDING_PAGE, FORM_TYPE_CHECKOUT } from '@/customForms/customForm.constants';

export default {
    builder: {
        form: { },
        formLoadingState: IDLE,
        formSavingState: IDLE,
        styling: { },
        stylingLoadingState: IDLE,
        stylingSavingState: IDLE,
        isNewForm: false,
    },
    forms: {
        [FORM_TYPE_CHECKOUT]: [],
        [FORM_TYPE_CONTACTS]: [],
        [FORM_TYPE_LANDING_PAGE]: [],
    },
    publicListSortField: 'title',
    shouldPublicListPageSortAscending: true,
    publicListPageLoadingStatus: IDLE,
    newFormSource: null,
    formType: '',
};
