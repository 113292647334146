<template>
    <div>
        <ds-tooltip position="top" :disabled="showTemplatesPopover">
            <template #reference>
                <ds-popover
                    :is-open="showTemplatesPopover"
                    class="templates-popover"
                    position="top-start"
                    hide-arrow
                    sticky
                    @close="closePopover"
                >
                    <template #reference>
                        <ds-icon-button
                            name="templates"
                            data-qa="contact-email-template-button"
                            @click="handleTemplatesButtonClick"
                        />
                    </template>

                    <template #default>
                        <div class="templates">
                            <ds-list-item border-style="none" :description="$t('insertTemplate')" />

                            <ds-list-item
                                v-for="(template, i) in templates"
                                :key="i"
                                class="template"
                                border-style="none"
                                :title="displayTitle(template)"
                                :short-description="getDescription(template, templateType)"
                                @click.native="handleTemplatesInsert(template)"
                            />
                        </div>

                        <div class="manage-templates">
                            <ds-text-button
                                data-qa="manage-templates-button"
                                @click="openTemplatesManager"
                            >
                                {{ $t('manageTemplates') }}
                            </ds-text-button>
                        </div>
                    </template>
                </ds-popover>
            </template>

            <template #default>
                <div>{{ $t('insertTemplate') }}</div>
            </template>
        </ds-tooltip>

        <portal to="root">
            <keap-marketing-sms-template-manager
                v-if="isKeapMarketingNumberTemplates"
                :is-open="isKeapMarketingNumberTemplateManagerOpen"
                @close="isKeapMarketingNumberTemplateManagerOpen = false"
            />
        </portal>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import EmailTemplateManager from '@/shared/components/Templates/EmailTemplateManager';
import SmsTemplateManager from '@/shared/components/Templates/SmsTemplateManager';
import KeapMarketingSmsTemplateManager from '@/shared/components/Templates/KeapMarketingSmsTemplateManager';
import { MEDIA_TYPES, TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { gmbReviewLinkText } from '@/reviews/reviewUtils';
import reviewsMixin from '@/reviews/mixins/reviews.mixin';

export default {

    components: {
        KeapMarketingSmsTemplateManager,
    },
    mixins: [reviewsMixin],

    props: {
        templates: {
            type: Array,
            default: () => [],
        },
        templateType: String,
    },

    data() {
        return {
            showTemplatesPopover: false,
            isKeapMarketingNumberTemplateManagerOpen: false,
        };
    },

    computed: {
        ...mapState({
            emailTemplatesTutorialCompleted: ({ tutorials }) => tutorials.items[TUTORIAL_TYPES.EMAIL_TEMPLATES_BUTTON],
            smsTemplatesTutorialCompleted: ({ tutorials }) => tutorials.items[TUTORIAL_TYPES.SMS_TEMPLATES_BUTTON],
            userId: ({ auth }) => auth.user.id,
            googleReviewLink: ({ reviews }) => reviews.googleReviewLink,
        }),

        ...mapGetters({
            displayTitle: 'communicationTemplates/displayTitle',
            displayDescription: 'communicationTemplates/displayDescription',
        }),

        isEmailTemplates() {
            return this.templateType === MEDIA_TYPES.HTML;
        },

        isSmsTemplates() {
            return this.templateType === MEDIA_TYPES.PLAIN_TEXT;
        },

        isKeapMarketingNumberTemplates() {
            return this.templateType === TEMPLATE_KINDS.KMN_BROADCAST_CUSTOM;
        },

        templatesTutorialCompleted() {
            if (this.isEmailTemplates) {
                return this.emailTemplatesTutorialCompleted;
            }

            if (this.isSmsTemplates) {
                return this.smsTemplatesTutorialCompleted;
            }

            return true;
        },
    },

    methods: {
        getDescription(template, templateType) {
            if (!this.isKeapMarketingNumberTemplates) {
                return this.displayDescription(template, templateType);
            }

            return null;
        },

        closePopover() {
            this.showTemplatesPopover = false;
        },

        handleTemplatesButtonClick() {
            this.$track('Contact Record Email - Clicked on Templates');

            this.showTemplatesPopover = !this.showTemplatesPopover;

            if (!this.templatesTutorialCompleted) {
                let key;

                if (this.isEmailTemplates) {
                    key = TUTORIAL_TYPES.EMAIL_TEMPLATES_BUTTON;
                } else if (this.isSmsTemplates) {
                    key = TUTORIAL_TYPES.SMS_TEMPLATES_BUTTON;
                }
                this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                    key,
                    value: true,
                    forUser: true,
                });
            }
        },

        handleTemplatesInsert(template) {
            this.$track('Contact Record Email - Templates - Inserted Template option');

            if (template.templateKind === 'REVIEW') {
                if (this.templateType === MEDIA_TYPES.HTML) {
                    template.content = this.$t('defaultEmailTemplates.6.body', {
                        reviewUrl: this.googleReviewLink ? gmbReviewLinkText(this.googleReviewLink) : this.reviews_reviewLinkText,
                    });
                } else {
                    template.content = this.$t('defaultSmsTemplates.6.body', {
                        reviewUrl: this.googleReviewLink ? this.googleReviewLink : this.reviews_reviewUrl,
                    });
                }
            }

            this.$store.commit('communicationTemplates/SET_CURRENT_EMAIL_TEMPLATE', template);

            this.$bus.$emit('INSERT_COMMUNICATION_TEMPLATE', template);

            this.showTemplatesPopover = false;
        },

        openTemplatesManager() {
            if (this.isEmailTemplates) {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: EmailTemplateManager,
                    modalSize: 'xl',
                });
            } else if (this.isSmsTemplates) {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: SmsTemplateManager,
                    modalSize: 'xl',
                });
            } else if (this.isKeapMarketingNumberTemplates) {
                this.isKeapMarketingNumberTemplateManagerOpen = true;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $templates-popover-height: px-to-rem(310px);

    .templates-popover {
        --popover-background-color: #{$color-paper};
    }

    .templates {
        @include overflowY;
        max-height: $templates-popover-height;
        max-width: px-to-rem(300px);
        width: 100%;
    }

    .template {
        cursor: pointer;

        &:hover {
            background-color: $color-gray-050;
        }
    }

    .manage-templates {
        display: flex;
        justify-content: center;
        margin: $gp / 2;
    }
</style>

<i18n>
{
    "en-us": {
        "insertTemplate": "Insert template",
        "manageTemplates": "Manage templates"
    }
}
</i18n>
