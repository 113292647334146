<template lang="html">
    <div class="contact-type-selector">
        <contact-type-chip
            v-for="(type, index) in contactTypes"
            :key="index"
            class="contact-type-chip"
            :data-qa="`contact-type-${type}`"
            :value="type"
            :active="isActive(type)"
            @click.native="handleClick(type)"
        />

        <div class="connecting-line" />
    </div>
</template>

<script>
import ContactTypeChip from '@/contacts/components/ContactTypeChip';
import { CONTACT_TYPES } from '@/contacts/contacts.constants';

export default {
    components: {
        ContactTypeChip,
    },

    props: {
        value: String,
    },

    data() {
        return {
            contactTypes: [
                CONTACT_TYPES.LEAD,
                CONTACT_TYPES.CUSTOMER,
                CONTACT_TYPES.OTHER,
            ],
        };
    },

    methods: {
        handleClick(type) {
            if (this.isActive(type)) {
                type = null;
            }

            this.$emit('input', type, this.value);
        },

        isActive(type) {
            return type === this.value;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .contact-type-selector {
        position: relative;
        white-space: nowrap;
        display: inline-flex;
        --chip-background-color: #{$color-gray-100};
    }

    .contact-type-chip {
        position: relative;
        z-index: 2;
        cursor: pointer;

        &:not(:first-of-type) {
            @include margin-start($gp / 2);
        }
    }

    .connecting-line {
        position: absolute;
        top: 50%;
        margin-top: px-to-rem(-1px);
        border-top: solid px-to-rem(2px) $color-gray-200;
        z-index: 1;
        width: 100%;
    }
</style>
