<template>
    <empty-state
        :title="$t('title')"
        :description="$t('description')"
        image="campaigns-gray"
    >
        <ds-filled-button
            class="create-automation-button"
            data-qa="create-automation"
            @click="$emit('create-automation')"
        >
            {{ $t('action') }}
        </ds-filled-button>
    </empty-state>
</template>

<script>
import EmptyState from '@/shared/components/EmptyState';

export default {
    components: {
        EmptyState,
    },
};
</script>

<i18n>
{
    "en-us": {
        "title": "Automate the easy way",
        "description": "Have a simple need? Automate individual workflows with Easy Automations.",
        "action": "Create automation"
    }
}
</i18n>
