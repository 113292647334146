import Vue from 'vue';
import axios from 'axios';
import ApolloClient from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';

import store from '@/shared/store';

export default {
    setupInterceptors() {
        axios.interceptors.request.use((config) => {
            const hasJwt = Boolean(store.state.auth.session.jwt);
            const isCloudFunctionCall = config.url === process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS;
            const alreadyHasAuth = Boolean(config.headers.Authorization);
            const isPublic = config.url.includes(process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL);
            const coreAppId = !isPublic ? store.state.auth.session.coreAppId : store.state.global.publicCoreAppId;
            const ignoreAuth = Boolean(config.headers['X-IS-Ignore-Auth']);

            if (hasJwt && !isCloudFunctionCall && !alreadyHasAuth && !ignoreAuth) {
                config.headers.Authorization = `Bearer ${store.state.auth.session.jwt}`;
            }

            const callingCoreApp = config.url.includes(process.env.VUE_APP_CORE_URL_PATTERN);

            if (callingCoreApp) {
                config.url = config.url.replace('{id}', coreAppId);
                config.withCredentials = true;
            }

            const callingApptsApi = config.url.includes(process.env.VUE_APP_APPTS_API_URL)
                && store.state.auth.user.casId
                && store.state.auth.account.appName;

            if (callingApptsApi) {
                config.headers['X-IS-User-Id'] = store.state.auth.user.casId;
                config.headers['X-IS-Tenant-Id'] = store.state.auth.account.appName;
            }

            window.performance.mark(`xhr_start_${config.url}_${config.method}`);

            return config;
        });

        axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (error.response && error.response.status === 401) {
                window.location = '/logout?checkCas=true';
            }

            return Promise.reject(error);
        });

        const graphQlClient = new ApolloClient({
            uri: process.env.VUE_APP_FLAGSHIP_BFF_URL,
            cache: new InMemoryCache({
                addTypename: false,
            }),
            request: async (operation) => {
                const appId = process.env.VUE_APP_APP_ID
                    ? process.env.VUE_APP_APP_ID
                    : store.state.auth.session.coreAppId;

                const { session } = store.state.auth;
                const headers = {
                    Authorization: `Bearer ${session.jwt}`,
                    'X-IS-App-Name': appId,
                };

                if (session.sessionAffinity != null) {
                    headers['X-IS-Affinity'] = session.sessionAffinity.value;
                }

                operation.setContext({ headers });
            },
            onError: ({ networkError }) => {
                if (networkError && networkError.statusCode === 401) {
                    window.location = '/logout?checkCas=true';
                }
            },
        });

        Vue.prototype.$graphql = graphQlClient;
    },
};
