<template>
    <breakpoint-query v-slot="{ isActive: isLargeBreakpoint }" :breakpoint="850">
        <div class="merge-field-suggestion-footer">
            <section v-if="isLargeBreakpoint && !isContextualAutomation" class="insert-text">
                <span class="insert-text-label">{{ $t('insert') }}</span>

                <div class="chips">
                    <ds-chip
                        v-for="{ label, value } in suggestions"
                        :key="value"
                        class="merge-chip"
                        with-interaction
                        @click.stop.native="handleMergeFieldInsert(value)"
                    >
                        {{ label }}
                    </ds-chip>
                </div>
            </section>

            <merge-field-dropdown
                class="merge-field-dropdown"
                position="bottom-start"
                :use-merge-service="useMergeService"
                :additional-merge-fields="additionalMergeFields"
                @select="({ value }) => handleMergeFieldInsert(value)"
                @change="(val) => $emit('menu-change', val)"
            >
                <template #action="{ toggle }">
                    <ds-text-button
                        trailing-icon="chevron-down"
                        @click="toggle"
                    >
                        {{ $t(isLargeBreakpoint && !isContextualAutomation ? 'more' : 'insert') }}
                    </ds-text-button>
                </template>
            </merge-field-dropdown>
        </div>
    </breakpoint-query>
</template>

<script>
import { mapState } from 'vuex';

import BreakpointQuery from '@/shared/components/BreakpointQuery';
import MergeFieldDropdown from '@/shared/components/MergeField/MergeFieldDropdown';

export default {
    components: {
        BreakpointQuery,
        MergeFieldDropdown,
    },

    inject: ['$mergeFieldTextBox'],

    props: {
        additionalMergeFields: {
            type: Array,
            default: () => [],
        },

        useMergeService: Boolean,
    },

    computed: {
        ...mapState({
            isContextualAutomation: ({ automations }) => Boolean(automations.context?.type),
        }),

        suggestions() {
            if (this.additionalMergeFields.length) {
                const categoryLabel = this.additionalMergeFields[0]?.category?.label;

                const firstOption = this.additionalMergeFields[0]?.options[0];
                const secondOption = this.additionalMergeFields[0]?.options[1];

                return [
                    { value: firstOption?.value, label: `${categoryLabel}: ${firstOption?.label}` },
                    { value: secondOption?.value, label: `${categoryLabel}: ${secondOption?.label}` },
                ];
            }

            return [{
                value: '[[contact.first_name]]',
                label: `${this.$t('mergefields.category.contact.title')}: ${this.$t('mergefields.category.contact.field.firstname.title.displayLabel')}`,
            }, {
                value: '[[contact.last_name]]',
                label: `${this.$t('mergefields.category.contact.title')}: ${this.$t('mergefields.category.contact.field.lastname.title.displayLabel')}`,
            }];
        },
    },

    methods: {
        handleMergeFieldInsert(value) {
            this.$mergeFieldTextBox.insertMergeField(value);
        },
    },
};
</script>

<style lang="scss" scoped>
.merge-field-suggestion-footer {
    background-color: $color-ink-050;
    display: flex;
    align-items: center;
    padding: $gp / 4;
    justify-content: space-between;
}

.merge-chip {
    margin: 0 px-to-rem(5px);
}

.insert-text {
    width: 80%;
    display: flex;
    align-items: center;
    @include padding-start($gp / 4);
}

.chips {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
    padding: 0 $gp / 4 $gp;
    margin-bottom: -$gp;
}

.insert-text-label {
    padding: $gp / 4 0;
    color: $color-text-subtle;
    font-size: $font-size-xs;
}
</style>

<i18n>
{
    "en-us": {
        "insert": "Insert:",
        "more": "More"
    }
}
</i18n>
