import gql from 'graphql-tag';
import Vue from 'vue';

const automationTemplateReturnType = `{
    id
    description
    name
    updatedDate
    createdDate
    status
    hasChanges
    steps {
        configJson
        name
        type
    }
    triggers {
        configJson
        name
        type
        sourceId
        action
    }
}`;

const automationTemplateSummaryReturnType = `{
    id
    description
    name
    createdDate
    hasChanges
}`;

export const deleteAutomationTemplate = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteAutomationTemplate($id: ID!) {
                deleteAutomationTemplate(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};

export const loadAutomationTemplates = (limit, cursor) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query automationTemplateList($limit: Int, $cursor: ID) {
                automationTemplateList(limit: $limit, cursor: $cursor) ${automationTemplateSummaryReturnType}
            }
        `,
        variables: {
            limit,
            cursor,
        },
        fetchPolicy: 'no-cache',
    });
};

export const loadAutomationTemplate = (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query automationTemplate($id: ID!) {
                automationTemplate(id: $id) ${automationTemplateReturnType}
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const publishAutomationTemplate = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation publishAutomationTemplate($id: ID!) {
                publishAutomationTemplate(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};

export const updateAutomationTemplate = (id, payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateAutomationTemplate($id: ID!, $payload: AutomationTemplateInput!) {
                updateAutomationTemplate(id: $id, payload: $payload) ${automationTemplateReturnType}
            }
        `,
        variables: {
            id,
            payload,
        },
    });
};

export const createAutomationTemplate = (payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createAutomationTemplate($payload: CreateAutomationTemplateInput!) {
                createAutomationTemplate(payload: $payload) {
                    id
                    name
                    description
                }
            }
        `,
        variables: {
            payload,
        },
    });
};

export const updateAutomationTemplateDetails = (id, payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateAutomationTemplateDetails($id: ID!, $payload: UpdateAutomationTemplateDetailsInput!) {
                updateAutomationTemplateDetails(id: $id, payload: $payload) ${automationTemplateSummaryReturnType}
            }
        `,
        variables: {
            id,
            payload,
        },
    });
};

export const shareTemplate = (payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation shareAutomationTemplate($payload: ShareAutomationTemplateInput!) {
                shareAutomationTemplate(payload: $payload)
            }
        `,
        variables: {
            payload,
        },
    });
};

export const deleteAutomationTemplateDraft = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteAutomationTemplateDraft($id: ID!) {
                deleteAutomationTemplateDraft(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};
