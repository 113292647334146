export default {
    shortcuts: [
        {
            name: 'shared',
            shortcuts: [
                { eventName: 'SHORTKEY_?', shortcut: ['shift', '?'] },
                { eventName: 'SHORTKEY_ESC', shortcut: ['esc'] },
                { eventName: 'SHORTKEY_A', shortcut: ['a'] },
                { eventName: 'SHORTKEY_E', shortcut: ['e'] },
                { eventName: 'SHORTKEY_C', shortcut: ['c'] },
                { eventName: 'SHORTKEY_F', shortcut: ['f'] },
                { eventName: 'SHORTKEY_I', shortcut: ['i'] },
                { eventName: 'SHORTKEY_L', shortcut: ['l'] },
            ],
        },
        {
            name: 'navigation',
            icon: 'location',
            shortcuts: [
                {
                    eventName: 'NAV_dashboard',
                    label: 'goToDashboard',
                    shortcut: ['shift', 'd'],
                },
                {
                    eventName: 'NAV_contacts',
                    label: 'goToContacts',
                    shortcut: ['shift', 'c'],
                },
                {
                    eventName: 'NAV_tasks',
                    label: 'goToTasks',
                    shortcut: ['shift', 't'],
                },
                {
                    eventName: 'NAV_calendar',
                    label: 'goToCalendar',
                    shortcut: ['shift', 'a'],
                },
                {
                    eventName: 'NAV_pipeline',
                    label: 'goToPipeline',
                    shortcut: ['shift', 'p'],
                },
                {
                    eventName: 'NAV_billing',
                    label: 'goToBilling',
                    shortcut: ['shift', '$'],
                },
                {
                    eventName: 'NAV_broadcasts',
                    label: 'goToBroadcasts',
                    shortcut: ['shift', 'b'],
                },
                {
                    eventName: 'NAV_mycampaigns',
                    label: 'goToMyCampaigns',
                    shortcut: ['shift', 'm'],
                },
                {
                    eventName: 'NAV_reports',
                    label: 'goToReports',
                    shortcut: ['shift', 'r'],
                },
                {
                    eventName: 'NAV_settings',
                    label: 'goToSettings',
                    shortcut: ['shift', 's'],
                },
            ],
        },
        {
            name: 'everywhere',
            icon: 'info-circle',
            shortcuts: [
                {
                    label: 'toggleHelp',
                    shortcut: ['?'],
                },
                {
                    eventName: 'SHORTKEY_N',
                    label: 'toggleNotifications',
                    shortcut: ['n'],
                },
                {
                    eventName: 'SHORTKEY_H',
                    label: 'toggleSupport',
                    shortcut: ['h'],
                },
                {
                    eventName: 'SHORTKEY_T',
                    label: 'toggleNav',
                    shortcut: ['t'],
                },
                {
                    eventName: 'SHORTKEY_S',
                    label: 'search',
                    shortcut: ['s'],
                },
            ],
        },
        {
            name: 'dashboard',
            icon: 'home',
            shortcuts: [],
        },
        {
            name: 'contacts.list',
            icon: 'user',
            shortcuts: [
                {
                    label: 'addContact',
                    shortcut: ['a'],
                },
                {
                    label: 'filter',
                    shortcut: ['f'],
                },
                {
                    label: 'import',
                    shortcut: ['i'],
                },
                {
                    label: 'createList',
                    shortcut: ['c'],
                },
            ],
        },
        {
            name: 'tasks',
            icon: 'tasks',
            shortcuts: [
                {
                    label: 'addTask',
                    shortcut: ['a'],
                },
                {
                    label: 'toggleTasks',
                    shortcut: ['c'],
                },
            ],
        },
        {
            name: 'broadcasts',
            icon: 'megaphone',
            shortcuts: [
                {
                    label: 'createBroadcast',
                    shortcut: ['c'],
                },
            ],
        },
        {
            name: 'mycampaigns',
            icon: 'campaign',
            shortcuts: [
                {
                    label: 'createCampaign',
                    shortcut: ['c'],
                },
                {
                    label: 'exploreCampaigns',
                    shortcut: ['e'],
                },
            ],
        },
    ],
};
