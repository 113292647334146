<template>
    <div>
        <ds-tooltip v-if="showEmailChip" :disabled="hasValidEmail" position="top">
            <template #reference>
                <ds-chip
                    :class="{ 'invalid-email': !hasValidEmail }"
                    with-interaction
                    @click.native="handleEmailClick"
                >
                    <template #default>
                        <span>{{ email }}</span>
                    </template>

                    <template #trailing>
                        <ds-icon
                            class="remove"
                            name="x-circle-fill"
                            @click.native.stop="handleRemove"
                        />
                    </template>
                </ds-chip>
            </template>

            <template #default>
                <span>{{ $t('invalidEmailAddress') }}</span>
            </template>
        </ds-tooltip>

        <ds-input-field
            v-else
            ref="emailInput"
            v-model="email"
            type="email"
            name="email"
            :placeholder="$t('emailPlaceholder')"
            @blur="validateEmail"
            @keyup.enter="validateEmail"
            @keyup.space="validateEmail"
        />
    </div>
</template>

<script>
import { patterns } from '@infusionsoft/vue-utils';

export default {
    props: {
        value: Array,
    },

    data() {
        return {
            email: '',
            hasValidEmail: false,
            showEmailChip: false,
        };
    },

    computed: {
        contact() {
            return this.value[0];
        },
    },

    methods: {
        clearEmail() {
            this.showEmailChip = false;
            this.contact.email = null;
            this.$emit('input', [this.contact]);
            this.$nextTick(() => {
                this.$refs.emailInput.input_focus();
            });
        },

        handleEmailClick() {
            this.clearEmail();
        },

        handleRemove() {
            this.email = '';
            this.clearEmail();
        },

        validateEmail() {
            this.hasValidEmail = RegExp(patterns.email).test(this.email);

            if (this.email) {
                this.showEmailChip = true;
            }

            if (this.hasValidEmail) {
                this.contact.email = this.email;

                this.$emit('input', [this.contact]);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .remove {
        --icon-size: #{px-to-rem(12px)};
        --icon-color: var(--chip-text-color, #{$color-gray-700});
    }

    .invalid-email {
        --chip-background-color: #{$color-red};
        --chip-text-color: #{$color-paper};
    }
</style>

<i18n>
{
    "en-us": {
        "emailPlaceholder": "Enter an email address",
        "invalidEmailAddress": "Email address isn't valid"
    }
}
</i18n>
