import moment from 'moment-timezone';
import { rrulestr } from 'rrule';

export const processRawAppts = (rawAppts, apptsRange) => {
    const appts = [];
    const rangeStart = moment(apptsRange.startDate).toDate();
    const rangeEnd = moment(apptsRange.endDate).toDate();

    let localId = 0;

    rawAppts.forEach((appt) => {
        let duration;

        if (appt.start && appt.start.date) {
            duration = moment(appt.end.date).diff(appt.start.date, 'days');

            if (duration > 0) {
                duration--;
            }
        } else if (appt.end) {
            duration = moment(appt.end.dateTime).diff(appt.start.dateTime, 'minutes');
        }

        if (appt.recurrence) {
            appt.recurrence.forEach((recurrence) => {
                let dtstart;

                if (appt.start && appt.start.date) {
                    dtstart = moment(appt.start.date).startOf('day').toDate();
                } else if (appt.end) {
                    dtstart = moment.tz(appt.start.dateTime, appt.start.timeZone).tz(moment.tz.guess()).toDate();
                }

                const rule = rrulestr(recurrence, {
                    dtstart,
                });

                const startTimes = rule.between(rangeStart, rangeEnd, true);

                startTimes.forEach((time) => {
                    const instance = { ...appt };

                    if (appt.start.date) {
                        instance.start.date = moment(time).format('YYYY-MM-DD');
                        instance.end.date = moment(time).add(duration, 'days').format('YYYY-MM-DD');
                    } else {
                        instance.start.dateTime = moment(time).format();
                        instance.end.dateTime = moment(time).add(duration, 'minutes').format();
                    }

                    appt.localId = `${appt.id}-${localId}`;

                    localId++;

                    appts.push(processRawAppt(instance));
                });
            });
        } else {
            appt = processRawAppt(appt);
            appt.localId = `${appt.id}-${localId}`;

            localId++;

            appts.push(appt);
        }
    });

    return appts;
};

export const processRawAppt = (appt) => {
    if (appt.start) {
        if (appt.start.date) {
            appt.startDate = moment(appt.start.date).startOf('day').format();
        } else if (appt.start.timeZone) {
            appt.startDate = moment.tz(appt.start.dateTime, appt.start.timeZone).tz(moment.tz.guess()).format();
        } else {
            appt.startDate = moment(appt.start.dateTime).format();
        }
    }

    if (appt.end) {
        if (appt.end.date) {
            // Note: when all-day appts have no recurrence value their end-date is listed as the start
            // of the next day, so we need to roll it back a day to render it as the end of the correct day
            if (appt.recurrence) {
                appt.endDate = moment(appt.end.date).endOf('day').format();
            } else {
                appt.endDate = moment(appt.end.date).subtract(1, 'day').endOf('day').format();
            }
        } else if (appt.end.timeZone) {
            appt.endDate = moment.tz(appt.end.dateTime, appt.end.timeZone).tz(moment.tz.guess()).toISOString();
        } else {
            appt.endDate = moment(appt.end.dateTime).format();
        }
    }

    return appt;
};
