import axios from 'axios';
import gql from 'graphql-tag';
import { Device } from '@twilio/voice-sdk';
import Vue from 'vue';
import uuid from 'uuid/v4';

import sentry from '@/analytics/sentry';
import { normalizePhoneNumber } from '@/communication/utils/sms-utils';
import { PHONE_KEYS } from '@/communication/communication.constants';
import { queryContactDetails } from '@/contacts/store/queries';
import {
    callAccessTokenQuery,
    createVoipDeviceMutation,
    deleteVoipDeviceMutation,
    kblAccountQuery,
} from '../api';
import { CALL_DEVICE_STATUSES, CALL_STATUSES } from '../constants/calls.constants';

export default {
    LOAD_ACCOUNT(context, payload) {
        return loadAccount(context, payload);
    },

    ADD_TRUSTED_NUMBER(context, payload) {
        return addTrustedNumber(context, payload);
    },

    VERIFY_TRUSTED_NUMBER(context, payload) {
        return verifyTrustedNumber(context, payload);
    },

    CLEAR_CONTACT_INFO(context, payload) {
        return clearContactInfo(context, payload);
    },

    LOAD_CONVERSATION(context, payload) {
        return loadConversation(context, payload);
    },

    LOAD_CONVERSATIONS(context, payload) {
        return loadConversations(context, payload);
    },

    LOAD_SMS_MESSAGES(context, payload) {
        return loadSmsMessages(context, payload);
    },

    LOAD_MORE_SMS_MESSAGES(context, payload) {
        return loadMoreSmsMessages(context, payload);
    },

    SEND_MESSAGE(context, payload) {
        return sendMessage(context, payload);
    },

    SMS_RECIPIENT_SEARCH(context, payload) {
        return smsRecipientSearch(context, payload);
    },

    START_POLL_CONVERSATIONS(context, payload) /* istanbul ignore next */ {
        return startPollConversations(context, payload);
    },

    START_POLL_MESSAGES(context, payload) /* istanbul ignore next */ {
        return startPollMessages(context, payload);
    },

    STOP_POLL_CONVERSATIONS(context, payload) /* istanbul ignore next */ {
        return stopPollConversations(context, payload);
    },

    STOP_POLL_MESSAGES(context, payload) /* istanbul ignore next */ {
        return stopPollMessages(context, payload);
    },

    UPDATE_CONVERSATION(context, payload) {
        return updateConversation(context, payload);
    },

    DELETE_CONVERSATION(context, payload) {
        return deleteConversation(context, payload);
    },

    SEND_SYSTEM_MESSAGE(context, payload) {
        return sendSystemMessage(context, payload);
    },

    MERGE_CONTACT_AND_CONVERSATION(context, payload) {
        return mergeContactAndConversation(context, payload);
    },

    START_CALL(context, payload) {
        return startCall(context, payload);
    },

    END_CALL(context) {
        return endCall(context);
    },

    SETUP_CALL_DEVICE(context) {
        return setupCallDevice(context);
    },

    DESTROY_CALL_DEVICE(context) {
        return destroyCallDevice(context);
    },

    TOGGLE_MUTE(context) {
        return toggleMute(context);
    },

    ADD_CONTACT_FROM_CALL(context, payload) {
        return addContactFromCall(context, payload);
    },

    SEND_DIGIT(context, payload) {
        return sendDigit(context, payload);
    },

    LOAD_AUDIO_DEVICES(context) {
        return loadAudioDevices(context);
    },

    SET_DEFAULT_AUDIO_DEVICES(context) {
        return setDefaultAudioDevices(context);
    },

    SET_MIC_DEVICE(context, payload) {
        return setMicDevice(context, payload);
    },

    SET_SPEAKER_DEVICE(context, payload) {
        return setSpeakerDevice(context, payload);
    },

    SUBMIT_FEEDBACK(context, payload) {
        return submitFeedback(context, payload);
    },
};

const loadAccount = async ({ commit }) => {
    const accountInfo = await kblAccountQuery();

    commit('auth/SET_KEAP_PHONE_ACCOUNT_INFO', accountInfo, { root: true });
};

const addTrustedNumber = async ({ commit }, { phoneNumber }) => {
    commit('auth/ADD_TRUSTED_NUMBER', { phoneNumber }, { root: true });
};

const verifyTrustedNumber = async ({ commit }, { phoneNumber }) => {
    commit('auth/VERIFY_TRUSTED_NUMBER', { phoneNumber }, { root: true });
};

let conversationsIntervalId = null;
let messagesIntervalId = null;

const CONVERSATION_POLL_DELAY = 60000;
const MESSAGES_POLL_DELAY = 30000;

const clearContactInfo = ({ commit, state: { sms: { conversations } } }, { ids }) => {
    ids.forEach((id) => {
        for (const [phoneNumber, conversation] of Object.entries(conversations)) {
            if (parseInt(conversation.contactId, 10) === parseInt(id, 10)) {
                updateConversation({ commit }, {
                    phoneNumber,
                    payload: {
                        contactId: 0,
                        firstName: '',
                        lastName: '',
                    },
                });
            }
        }
    });
};

const loadConversations = async ({ commit, dispatch, getters }, {
    limit = 20,
    offset = 0,
    updatedSince = null,
} = {}) => {
    try {
        const { data: { conversations } } = await Vue.prototype.$graphql.query({
            query: gql`
                query smsConversations($limit: Int, $offset: Int, $updatedSince: String) {
                    conversations(limit: $limit, offset: $offset, updatedSince: $updatedSince) {
                        contactId,
                        createTime,
                        email,
                        firstName,
                        lastMessage,
                        lastMessageTime,
                        lastName,
                        phoneNumber,
                        unreadCount,
                        updateTime,
                    }
                }
            `,
            variables: {
                limit,
                offset,
                updatedSince,
            },
            fetchPolicy: 'no-cache',
        });

        const oldUnreadCount = getters.totalUnreadCount;

        commit('SET_CONVERSATIONS', { conversations });

        const newUnreadCount = getters.totalUnreadCount;

        handleBrowserTab(dispatch, oldUnreadCount, newUnreadCount);

        return conversations;
    } catch (error) {
        sentry.log('Load conversations failed in communication actions');
        throw error;
    }
};

const loadConversation = async ({ commit, dispatch }, { phoneNumber } = {}) => {
    try {
        const { data: { conversation } } = await Vue.prototype.$graphql.query({
            query: gql`
                query conversation($phoneNumber: String!) {
                    conversation(phoneNumber: $phoneNumber) {
                        contactId
                        createTime
                        email
                        firstName
                        lastMessage
                        lastMessageTime
                        lastName
                        phoneNumber
                        unreadCount
                        updateTime
                    }
                }
            `,
            variables: { phoneNumber },
            fetchPolicy: 'no-cache',
        });

        commit('SET_CONVERSATIONS', { conversations: [conversation] });
        dispatch('LOAD_SMS_MESSAGES', { phoneNumber, hasRead: true });
    } catch (error) {
        sentry.log('Load conversation failed in communication actions');
        throw error;
    }
};

const loadSmsMessages = async ({ commit, dispatch, getters }, { phoneNumber, hasRead = false } = {}) => {
    try {
        const { data: { conversationMessages } } = await Vue.prototype.$graphql.query({
            query: gql`
                query smsMessages($phoneNumber: String!, $hasRead: Boolean) {
                    conversationMessages(phoneNumber: $phoneNumber, hasRead: $hasRead) {
                        body,
                        createTime,
                        mediaUrls {
                            contentType
                            filename
                            mediaUrl
                        }
                        messageId
                        outgoing
                        updateTime
                    }
                }
            `,
            variables: {
                phoneNumber,
                hasRead,
            },
            fetchPolicy: 'no-cache',
        });

        const oldUnreadCount = getters.totalUnreadCount;

        commit('SET_CONVERSATION_MESSAGES', { messages: conversationMessages, phoneNumber });
        dispatch('START_POLL_MESSAGES', { phoneNumber });

        if (conversationMessages.length < 100) {
            commit('SET_NO_MORE_DATA', { phoneNumber, value: true });
        }

        if (hasRead) {
            commit('SET_CONVERSATION_READ', { phoneNumber });
        }

        const newUnreadCount = getters.totalUnreadCount;

        handleBrowserTab(dispatch, oldUnreadCount, newUnreadCount);

        return conversationMessages;
    } catch (error) {
        sentry.log('Load conversation messages failed in communication actions');
        throw error;
    }
};

const loadMoreSmsMessages = async ({ commit }, { phoneNumber, limit, offset }) => {
    const { data: { conversationMessages } } = await Vue.prototype.$graphql.query({
        query: gql`
            query smsMessages($phoneNumber: String!, $limit: Int, $offset: Int) {
                conversationMessages(phoneNumber: $phoneNumber, limit: $limit, offset: $offset) {
                    body
                    createTime
                    mediaUrls {
                        contentType
                        filename
                        mediaUrl
                    }
                    messageId
                    outgoing
                    updateTime
                }
            }
        `,
        variables: {
            phoneNumber,
            limit,
            offset,
        },
        fetchPolicy: 'no-cache',
    });

    commit('ADD_MESSAGES_TO_CONVERSATION', { phoneNumber, messages: conversationMessages });

    if (conversationMessages.length < limit) {
        commit('SET_NO_MORE_DATA', { phoneNumber, value: true });
    }
};

const uploadFileToStorage = async (appName, uploadedFile) => {
    const formData = new FormData();

    formData.append('media', uploadedFile);

    try {
        const { data: { data } } = await axios.post(`${process.env.VUE_APP_COMMUNICATION_API_URL}/v1/system/upload-files`, formData, {
            headers: {
                'Content-Type': 'multipart/data-form',
                'X-IS-App-Name': appName,
            },
        });

        return data;
    } catch (error) {
        sentry.log('Uploading message file failed in communication actions');
        throw error;
    }
};

const sendMessage = async ({ commit, getters, rootState }, {
    contactData,
    message,
    phoneNumber,
    uploadedFile,
    mediaUrls,
}) => {
    try {
        if (uploadedFile != null) {
            mediaUrls = [await uploadFileToStorage(rootState.auth.session.coreAppId, uploadedFile)];
        }

        const {
            contactId,
            firstName,
            lastName,
        } = contactData;

        const payload = {
            ...(contactId && { contactId }),
            ...(firstName && { firstName }),
            kid: uuid(),
            ...(lastName && { lastName }),
            ...(mediaUrls && { mediaUrls }),
            message: message.body,
        };

        await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation sendMessage($phoneNumber: String!, $payload: MessageDispatchInput) {
                    sendMessage(phoneNumber: $phoneNumber, payload: $payload) {
                        userId,
                    }
                }
            `,
            variables: {
                phoneNumber,
                payload,
            },
            fetchPolicy: 'no-cache',
        });

        if (getters.isExistingConversation(phoneNumber)) {
            commit('UPDATE_CONVERSATION_MESSAGES', {
                message,
                phoneNumber,
                mediaUrls,
            });
        } else {
            const newMessage = {
                contactId: contactData.contactId,
                firstName: contactData.firstName,
                lastName: contactData.lastName,
                message: message.body,
                mediaUrls,
            };

            commit('CREATE_CONVERSATION', { ...newMessage, phoneNumber });
        }
    } catch (error) {
        sentry.log('Send SMS message failed in communication actions');
        throw error;
    }
};

const smsRecipientSearch = async (_, { query, limit = 20, offset = 0 } = {}) => {
    try {
        const { data: { contactsByNameOrPhoneNumber } } = await Vue.prototype.$graphql.query({
            query: gql`
                query contactsByNameOrPhoneNumber($query: String!, $limit: Int, $offset: Int) {
                    contactsByNameOrPhoneNumber(query: $query, limit: $limit, offset: $offset) {
                        id,
                        label,
                        labelSubtext,
                        additionalInfo {
                            itemName,
                            itemSuffix,
                            itemType,
                            itemValue,
                        }
                    }
                }
            `,
            variables: {
                query,
                limit,
                offset,
            },
            fetchPolicy: 'no-cache',
        });

        const hasPhoneNumber = (a, b) => {
            const aHasPhone = Boolean(a.labelSubtext);
            const bHasPhone = Boolean(b.labelSubtext);

            if (aHasPhone < bHasPhone) {
                return 1;
            }

            if (aHasPhone > bHasPhone) {
                return -1;
            }

            return 0;
        };

        return Array.isArray(contactsByNameOrPhoneNumber) ? contactsByNameOrPhoneNumber.sort(hasPhoneNumber) : [];
    } catch (error) {
        sentry.log('Recipient search failed in communication actions');
        throw error;
    }
};

/* istanbul ignore next */
const startPollConversations = ({ dispatch }, payload) => {
    dispatch('STOP_POLL_CONVERSATIONS', payload);

    conversationsIntervalId = setInterval(() => {
        dispatch('LOAD_CONVERSATIONS', payload);
    }, CONVERSATION_POLL_DELAY);
};

/* istanbul ignore next */
const startPollMessages = ({ dispatch }, payload) => {
    dispatch('STOP_POLL_MESSAGES');

    messagesIntervalId = setInterval(() => {
        dispatch('LOAD_SMS_MESSAGES', payload);
    }, MESSAGES_POLL_DELAY);
};

/* istanbul ignore next */
const stopPollConversations = () => {
    if (conversationsIntervalId) {
        clearInterval(conversationsIntervalId);
        conversationsIntervalId = null;
    }
};

/* istanbul ignore next */
const stopPollMessages = () => {
    if (messagesIntervalId) {
        clearInterval(messagesIntervalId);
        messagesIntervalId = null;
    }
};

const updateConversation = async ({ commit }, { payload, phoneNumber }) => {
    try {
        const { data: { updateConversation: conversation } } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation updateConversation($phoneNumber: String!, $payload: UpdateConversationInput) {
                    updateConversation(phoneNumber: $phoneNumber, payload: $payload) {
                        contactId,
                        createTime,
                        firstName,
                        lastMessage,
                        lastMessageTime,
                        lastName,
                        phoneNumber,
                        unreadCount,
                        updateTime,
                    }
                }
            `,
            variables: {
                phoneNumber,
                payload,
            },
            fetchPolicy: 'no-cache',
        });

        commit('UPDATE_CONVERSATION', { conversation, phoneNumber });
    } catch (error) {
        sentry.log('Update conversation failed in communication actions');
        throw error;
    }
};

const deleteConversation = async ({ commit }, { phoneNumber }) => {
    try {
        const { data: { deleteConversation: conversationDeleted } } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation deleteConversation($phoneNumber: String!) {
                    deleteConversation(phoneNumber: $phoneNumber)
                }
            `,
            variables: {
                phoneNumber,
            },
            fetchPolicy: 'no-cache',
        });

        if (conversationDeleted) {
            commit('DELETE_CONVERSATION', { phoneNumber });
        }

        return conversationDeleted;
    } catch (error) {
        sentry.log('Delete conversation failed in communication actions');
        throw error;
    }
};

const sendSystemMessage = async (_, payload) => {
    try {
        const { data: { sendSystemMessage: { remainingAttempts } } } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation sendSystemMessage($payload: SystemMessageDispatchInput) {
                    sendSystemMessage(payload: $payload) {
                        remainingAttempts
                    }
                }
            `,
            variables: {
                payload,
            },
            fetchPolicy: 'no-cache',
        });

        return remainingAttempts;
    } catch (error) {
        sentry.log('Send system SMS message failed in communication actions');
        throw error;
    }
};

const mergeContactAndConversation = async ({ commit, dispatch }, { id, phoneNumber }) => {
    const { contact } = await queryContactDetails(id);

    const normalizedPhone = normalizePhoneNumber(phoneNumber);
    const phoneExists = PHONE_KEYS.some((key) => {
        return normalizePhoneNumber(contact[key].value) === normalizedPhone;
    });

    if (!phoneExists) {
        for (const key of PHONE_KEYS) {
            if (contact[key].value == null || key === 'phone5') {
                contact[key].value = phoneNumber;
                contact[key].type = 'MOBILE';
                break;
            }
        }

        // These state changes have to happen in this order, because SAVE_CONTACT_DETAILS
        // needs to have the contact id in state to work.  Then, the contact id needs to be cleared
        // in case the user goes to the contact record, which will only query contact details
        // if the current contact id in state is NOT the one it's querying.

        // Ideally, something similar to the queries file, but for mutations, would be created
        // and implemented here to simply update a contact.
        commit('contacts/SET_CONTACT', { id }, { root: true });
        dispatch('contacts/SAVE_CONTACT_DETAILS', { contactDetails: contact }, { root: true });
        commit('contacts/SET_CONTACT', {}, { root: true });
    }

    const { firstName, lastName } = contact;
    const payload = {
        contactId: id,
        ...(firstName && { firstName }),
        ...(lastName && { lastName }),
    };

    dispatch('UPDATE_CONVERSATION', { payload, phoneNumber });
};

/* istanbul ignore next */
const handleBrowserTab = (dispatch, oldUnreadCount, newUnreadCount) => {
    if (newUnreadCount - oldUnreadCount > 0 && !document.hasFocus()) {
        dispatch('UPDATE_FAVICON', { hasBadge: true }, { root: true });
    }

    if (newUnreadCount !== oldUnreadCount) {
        dispatch('UPDATE_DOCUMENT_TITLE', { unreadCount: newUnreadCount }, { root: true });
    }
};

let disconnectedTimeout;
const DISCONNECTING_DELAY = 700;
let closeWidgetTimeout;
const POST_CALL_CLOSE_DELAY = 10000;

const startCallTimer = ({ commit }) => {
    const startTime = Date.now();
    const durationIntervalId = setInterval(() => {
        const duration = Date.now() - startTime;

        commit('SET_CALL_DURATION', duration);
    }, 100);

    commit('SET_CALL_DURATION_INTERVAL_ID', durationIntervalId);
};

const startCall = async ({
    commit,
    dispatch,
    rootState,
    state: { call: { device, accessTokenExpiration } },
}, contact) => {
    clearTimeout(disconnectedTimeout);
    clearTimeout(closeWidgetTimeout);
    const {
        id, firstName, lastName, phoneNumber,
    } = contact;

    if (!device) {
        sentry.log('Unable to start VOIP call with invalid device');
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.ERROR);

        return;
    }

    dispatch('LOAD_AUDIO_DEVICES');
    dispatch('SET_DEFAULT_AUDIO_DEVICES');

    if ((+new Date()) > accessTokenExpiration) {
        await updateCallDeviceAccessToken({ commit, rootState, device });
    }

    commit('SET_CALL_STATUS', CALL_STATUSES.PENDING);
    const call = await device?.connect({
        params: {
            To: normalizePhoneNumber(phoneNumber),
        },
    });

    addCallEventListeners({ commit, call });

    commit('SET_ACTIVE_CALL', call);
    commit('SET_CALL_CONTACT_DATA', {
        id,
        firstName,
        lastName,
        phoneNumber,
    });
};

const endCall = async ({ commit, state: { call: { activeCall } } }) => {
    activeCall?.disconnect();
    activeCall?.removeAllListeners();

    commit('SET_MUTE_STATUS', false);
    commit('SET_CALL_VOLUME', { inputVolume: 0, outputVolume: 0 });
};

const getDeviceVariables = (rootState) => {
    return {
        deviceIdentifier: `app_id_${rootState?.auth?.account?.appName}_keap_web`,
        deviceType: 'keap-web-app',
        identity: `cas_id_${rootState?.auth?.user?.casId}`,
    };
};

const setupCallDevice = async ({ commit, dispatch, rootState }) => {
    const { deviceIdentifier, deviceType, identity } = getDeviceVariables(rootState);

    let callAccessToken;

    try {
        callAccessToken = await callAccessTokenQuery({ deviceIdentifier, deviceType, identity });
    } catch (error) {
        sentry.log('Retrieving VoIP access token failed in communication actions', error);
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.ERROR);

        return false;
    }

    if (!callAccessToken) {
        sentry.log(`callAccessTokenQuery received empty callAccessToken ("${callAccessToken}") in communication actions`);
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.ERROR);

        return false;
    }

    commit('SET_CALL_ACCESS_TOKEN_EXPIRATION', (+new Date()) + 3600);

    try {
        const device = await new Device(callAccessToken, {
            allowIncomingWhileBusy: true,
            answerOnBridge: true,
            codecPreferences: ['opus', 'pcmu'],
            debug: process.env.NODE_ENV === 'development',
            enableRingingState: true,
            fakeLocalDTMF: true,
        });

        commit('SET_CALL_DEVICE', device);
        await addDeviceEventListeners({
            commit,
            dispatch,
            device,
            rootState,
        });
        await device.register();
    } catch (error) {
        sentry.log('Call device setup failed in communication actions', error);
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.ERROR);

        return false;
    }

    return true;
};

const addCallEventListeners = async ({ commit, call }) => {
    call.on('accept', () => {
        commit('SET_CALL_STATUS', CALL_STATUSES.ACCEPTED);
        startCallTimer({ commit });
    });

    call.on('cancel', () => {
        commit('SET_CALL_STATUS', CALL_STATUSES.CANCELLED);
        commit('RESET_CALL_DURATION');
        commit('SET_CALL_VOLUME', { inputVolume: 0, outputVolume: 0 });
    });

    call.on('disconnect', () => {
        commit('SET_CALL_STATUS', CALL_STATUSES.DISCONNECTING);
        commit('RESET_CALL_DURATION');
        commit('SET_CALL_VOLUME', { inputVolume: 0, outputVolume: 0 });

        disconnectedTimeout = setTimeout(() => {
            commit('SET_CALL_STATUS', CALL_STATUSES.DISCONNECTED);
        }, DISCONNECTING_DELAY);

        closeWidgetTimeout = setTimeout(() => {
            commit('SET_ACTIVE_CALL', null);
            commit('SET_CALL_STATUS', null);
        }, POST_CALL_CLOSE_DELAY);
    });

    call.on('reject', () => {
        commit('SET_CALL_STATUS', CALL_STATUSES.REJECTED);
        commit('RESET_CALL_DURATION');
    });

    call.on('error', () => {
        commit('SET_CALL_STATUS', CALL_STATUSES.ERROR);
        commit('RESET_CALL_DURATION');
    });

    call.on('volume', (inputVolume, outputVolume) => {
        commit('SET_CALL_VOLUME', { inputVolume, outputVolume });
    });
};

const addDeviceEventListeners = async ({
    commit, dispatch, device, rootState,
}) => {
    if (!device || typeof device !== 'object' && Object.keys(device).length > 0) {
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.ERROR);

        return;
    }

    device.on('registering', () => {
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.REGISTERING);
    });

    device.on('registered', () => {
        createVoipDeviceMutation(getDeviceVariables(rootState));
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.REGISTERED);
    });

    device.on('unregistered', () => {
        const { identity } = getDeviceVariables(rootState);

        deleteVoipDeviceMutation({ identity });
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.UNREGISTERED);
    });

    device.on('destroyed', () => {
        const { identity } = getDeviceVariables(rootState);

        deleteVoipDeviceMutation({ identity });
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.DESTROYED);
    });

    device.on('error', (error) => {
        commit('SET_CALL_DEVICE_STATUS', CALL_DEVICE_STATUSES.ERROR);
        sentry.log('An error occurred with the web call device in communication actions', error);
    });

    device.on('incoming', (call) => {
        commit('SET_CALL_STATUS', CALL_STATUSES.INCOMING);

        addCallEventListeners({ commit, call });
    });

    device.audio.on('deviceChange', () => {
        dispatch('LOAD_AUDIO_DEVICES');
    });
};

const destroyCallDevice = async ({ commit, state: { call: { device } } }) => {
    device?.destroy();
    device?.removeAllListeners();

    commit('SET_CALL_DEVICE', null);
};

const updateCallDeviceAccessToken = async ({ commit, rootState, device }) => {
    if (typeof device?.updateToken !== 'function') {
        sentry.log(`Unable to update Web Call device access token. Retrieved unexpected device from state - type: ${typeof device} (${Object.keys(device).length} keys)`);

        return;
    }

    const { deviceIdentifier, deviceType, identity } = getDeviceVariables(rootState);

    try {
        const callAccessToken = await callAccessTokenQuery({ deviceIdentifier, deviceType, identity });

        commit('SET_CALL_ACCESS_TOKEN_EXPIRATION', (+new Date()) + 3600);

        device.updateToken(callAccessToken);
    } catch (error) {
        sentry.log('Unable to update call device access token in communication action: ', error);
    }
};

const toggleMute = ({ commit, state: { call: { activeCall } } }) => {
    activeCall.mute(!activeCall.isMuted());
    commit('SET_MUTE_STATUS', activeCall.isMuted());
};

const addContactFromCall = ({ commit, dispatch }, {
    id, firstName, lastName, phoneNumber,
}) => {
    commit('SET_CALL_CONTACT_DATA', {
        id,
        firstName,
        lastName,
    });
    dispatch('MERGE_CONTACT_AND_CONVERSATION', { id, phoneNumber });
};

const sendDigit = ({ commit, state: { call: { activeCall } } }, value) => {
    if (!activeCall) {
        sentry.log('Unable to send digits with no active call');
        commit('SET_CALL_STATUS', CALL_STATUSES.ERROR);

        return;
    }

    activeCall.sendDigits(value);
};

const loadAudioDevices = ({ commit, state: { call: { device } } }) => {
    const micDevices = [...device.audio.availableInputDevices.values()].map(({ deviceId, label }) => ({
        label,
        value: deviceId,
    }));

    commit('SET_MIC_DEVICES', micDevices);

    const speakerDevices = [...device.audio.availableOutputDevices.values()].map(({ deviceId, label }) => ({
        label,
        value: deviceId,
    }));

    commit('SET_SPEAKER_DEVICES', speakerDevices);
};

const setDefaultAudioDevices = ({ dispatch, state }) => {
    const { call: { mic: { devices: micDevices }, speaker: { devices: speakerDevices } } } = state;
    const defaultMicDevice = micDevices.find((device) => device.value === 'default');
    const defaultSpeakerDevice = speakerDevices.find((device) => device.value === 'default');

    dispatch('SET_MIC_DEVICE', defaultMicDevice);
    dispatch('SET_SPEAKER_DEVICE', defaultSpeakerDevice);
};

const setMicDevice = async ({ commit, state: { call: { device } } }, micDevice) => {
    await device.audio.setInputDevice(micDevice.value);

    commit('SET_MIC_DEVICE', micDevice);
};

const setSpeakerDevice = async ({ commit, state: { call: { device } } }, speakerDevice) => {
    await device.audio.speakerDevices.set([speakerDevice.value]);

    commit('SET_SPEAKER_DEVICE', speakerDevice);
};

const submitFeedback = async ({ state: { call: { activeCall } } }, { score }) => {
    if (!activeCall) {
        sentry.log('Unable to submit feedback with invalid call');

        return;
    }

    activeCall.postFeedback(score);
};
