<template>
    <div class="configure">
        <configure-details
            :title="title"
            :subtitle="subtitle"
            :has-error="hasError"
            :trigger-action="triggerAction"
            :error-message="errorMessage || $t('error')"
            @edit="(payload) => $emit('edit', { trigger: value })"
            @remove="$emit('remove')"
        />

        <div class="button-row multiple-buttons">
            <manage-configuration
                :title="$t('manage')"
                :manage-props="manageProps"
            />

            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import redirectMixin from '@/shared/mixins/redirect.mixin';
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';
import ManageConfiguration from '@/automations/components/configure/ManageConfiguration';
import { ANY_PRODUCT_PURCHASED } from '@/automations/constants/automations.constants';

export default {
    components: {
        ConfigureDetails,
        ManageConfiguration,
    },

    mixins: [redirectMixin],

    props: {
        value: Object,
        hasError: Boolean,
        errorMessage: String,
        triggerAction: String,
    },

    data() {
        return {
            manageProps: {
                confirmTitle: this.$t('edit.title'),
                confirmMessage: this.$t('edit.message'),
                confirmButtonLabel: this.$t('edit.confirm'),
                route: { name: 'settings.products' },
            },
        };
    },

    computed: {
        ...mapState({
            products: ({ billing }) => billing.products,
        }),

        ...mapGetters({
            getProductById: 'billing/getProductById',
        }),

        product() {
            return this.getProductById(this.value?.sourceId ?? 0);
        },

        title() {
            return this.value.type === ANY_PRODUCT_PURCHASED
                ? this.$t(this.value.name)
                : this.product?.productName ?? this.$t('emptyLabel');
        },

        subtitle() {
            return this.value.type === ANY_PRODUCT_PURCHASED
                ? this.$t('anyProductDescription')
                : this.product?.productDesc ?? '';
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>

<i18n>
{
    "en-us": {
        "manage": "Manage products",
        "emptyLabel": "Purchase is made",
        "anyProductDescription": "When anything is purchased",
        "edit": {
            "title": "Navigate to the billing page to manage your products?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Manage products"
        },
        "error": "Please select a product"
    }
}
</i18n>
