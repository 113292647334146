<template>
    <contact-query v-slot="{ results, loading, query, search }" :search-params="searchParams">
        <ds-multiselect
            :value="value"
            searchable
            show-search-icon
            value-prop="id"
            label-prop="fullName"
            help-text
            help-text-prop="email"
            :options="normalizeOptions(results, search)"
            :loading="loading"
            v-bind="$attrs"
            v-on="$listeners"
            @updateSearch="query"
        />
    </contact-query>
</template>

<script>
import ContactQuery from './ContactQuery';

export default {
    components: {
        ContactQuery,
    },

    props: {
        value: [Object, Array, String, Number],
        filter: {
            type: Function,
            default: () => true,
        },
        searchParams: Object,
    },

    methods: {
        normalizeOptions(contacts, search) {
            const { value: contact } = this;

            if (contact?.id && !search) {
                // eslint-disable-next-line eqeqeq
                return [contact].concat(contacts.filter((c) => c.id != contact.id)).filter(this.filter);
            }

            return contacts.filter(this.filter);
        },
    },
};
</script>
