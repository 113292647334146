import domMixin from '@/shared/mixins/dom.mixin';

export default {
    mixins: [domMixin],

    methods: {
        mobileInterceptor_checkMobile(id = 'default', resourceId) {
            return new Promise((resolve) => {
                if (this.dom_isTouchDevice()) {
                    this.$bus.$emit('OPEN_MOBILE_INTERCEPTOR', id, resourceId);
                } else {
                    resolve();
                }
            });
        },
    },
};
