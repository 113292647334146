<template>
    <div class="appointment-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <div v-if="!showSelectUser">
            <header class="configure-editor-header">
                <ds-icon-button name="arrow-left" data-qa="close-button" @click.native="close" />

                <span class="configure-editor-header-text">{{ $t('header') }}</span>
            </header>

            <section class="select-option">
                <div class="button-container">
                    <ds-text-button
                        v-if="showCreateAppointment"
                        trailing-icon="external-link"
                        data-qa="add-new-appointment-type"
                        @click="goToAppoinmentsConfirm"
                    >
                        {{ $t('buttonText') }}
                    </ds-text-button>

                    <ds-input-field
                        v-model="search"
                        :class="['search-input', { 'hide-create': !showCreateAppointment }]"
                        type="search"
                        name="search"
                        :label="$t('search')"
                    />
                </div>

                <ds-list-item
                    v-if="showAnyResult"
                    as="a"
                    class="list-item-option"
                    data-qa="appointments_any"
                    :title="isScheduledType ? $t('anyAppointmentScheduled.title') : $t('anyAppointmentCancelled.title')"
                    :description="isScheduledType ? $t('anyAppointmentScheduled.description') : $t('anyAppointmentCancelled.description')"
                    @click="handleSelectAnyAppointment(false)"
                />

                <p v-if="!search" class="list-header">
                    {{ $t('appointmentTypesHeader') }}
                </p>

                <p v-if="noAppointmentTypes" class="empty-list">
                    {{ $t('emptyAppointmentTypes') }}
                </p>

                <p v-if="noSearchResults" class="empty-search">
                    {{ $t('emptySearch', { search }) }}
                </p>

                <ds-list-item
                    v-for="(appointmentType, i) in filteredAppointmentTypes"
                    :key="i"
                    as="a"
                    class="list-item-option"
                    :data-qa="`appointmentType_${appointmentType.id}`"
                    :title="appointmentType.name"
                    :description="getDurationDisplay(appointmentType.durationMinutes)"
                    @click="handleSelection(appointmentType)"
                />
            </section>
        </div>

        <div v-else>
            <header class="configure-editor-header">
                <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                <span class="configure-editor-header-text">{{ $t('userHeader') }}</span>

                <save-status :status="saveStatus" class="shift-right save-status" />

                <ds-filled-button data-qa="next-button" @click="handleNext">
                    {{ $t('global.next') }}
                </ds-filled-button>
            </header>

            <div class="form user-form">
                <p class="for-title">
                    {{ forTitle }}
                </p>

                <ds-multiselect
                    v-model="appointmentFor"
                    searchable
                    class="appointment-for"
                    bind-value-only
                    :placeholder="$t('selectUser')"
                    :options="forOptions"
                    @input="handleAnyUpdate"
                />
            </div>
        </div>
    </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';

import { mapState, mapGetters } from 'vuex';

import {
    APPOINTMENT_SCHEDULED,
    ANY_APPOINTMENT_SCHEDULED,
    APPOINTMENT_CANCELLED,
    ANY_APPOINTMENT_CANCELLED,
    TRIGGER_ACTION_TYPES,
    USER_APPOINTMENT_SCHEDULED,
    USER_APPOINTMENT_CANCELLED,
} from '@/automations/constants/automations.constants';
import SaveStatus from '@/automations/components/SaveStatus';
import { NEW_APPOINTMENT_TYPE_ROUTE_NAME } from '@/appointments/appointments.constants';

const status = { LOADING: 'LOADING', ERROR: 'ERROR', SUCCESS: 'SUCCESS' };
const FOR_ANY_USER = 'ANY_USER';

export default {
    components: {
        SaveStatus,
    },

    props: {
        scheduledOrCancelledType: {
            type: String,
            default: APPOINTMENT_SCHEDULED,
        },

        automationProperties: {
            type: Object,
            default: () => ({}),
        },

        triggerAction: String,
        saveStatus: String,
    },

    data() {
        return {
            selectedOption: {},
            status: status.LOADING,
            search: '',
            showSelectUser: false,
            appointmentFor: FOR_ANY_USER,
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapGetters({
            hasAppointmentType: 'calendar/hasApptType',
        }),

        ...mapState({
            appointmentTypes: ({ calendar }) => calendar.apptTypes,
            user: ({ auth }) => auth.user,
            users: ({ auth }) => auth.users,
        }),

        showCreateAppointment() {
            return this.$route.name !== 'calendar' && this.$route.name !== NEW_APPOINTMENT_TYPE_ROUTE_NAME;
        },

        loading() {
            return this.status === status.LOADING;
        },

        forTitle() {
            return this.isScheduledType ? this.$t('scheduledFor') : this.$t('cancelledFor');
        },

        anyAppointmentTrigger() {
            const useAppointmentFor = this.appointmentFor !== FOR_ANY_USER;

            if (this.isScheduledType) {
                return {
                    type: useAppointmentFor ? USER_APPOINTMENT_SCHEDULED : ANY_APPOINTMENT_SCHEDULED,
                    sourceId: useAppointmentFor ? this.appointmentFor : null,
                    name: this.$t('anyAppointmentScheduled.name'),
                };
            }

            return {
                type: useAppointmentFor ? USER_APPOINTMENT_CANCELLED : ANY_APPOINTMENT_CANCELLED,
                sourceId: useAppointmentFor ? this.appointmentFor : null,
                name: this.$t('anyAppointmentCancelled.name'),
            };
        },

        appointmentTrigger() {
            const { id } = this.selectedOption;

            return {
                type: this.scheduledOrCancelledType,
                sourceId: id,
                name: this.$t(this.isScheduledType ? 'singleAppointmentScheduled' : 'singleAppointmentCancelled', { id }),
                configJson: {
                    userId: this.user.id,
                },
            };
        },

        showAnyResult() {
            const search = this.search?.trim()?.toLowerCase();

            if (this.isScheduledType) {
                return this.$t('anyAppointmentScheduled.title').toLowerCase().includes(search)
                    || this.$t('anyAppointmentScheduled.description').toLowerCase().includes(search);
            }

            return this.$t('anyAppointmentCancelled.title').toLowerCase().includes(search)
                || this.$t('anyAppointmentCancelled.description').toLowerCase().includes(search);
        },

        filteredAppointmentTypes() {
            const search = this.search?.trim()?.toLowerCase();

            return this.appointmentTypes.filter(({ name, durationMinutes }) => {
                return name.toLowerCase().includes(search)
                    || this.getDurationDisplay(durationMinutes).toLowerCase().includes(search);
            });
        },

        noAppointmentTypes() {
            return !this.loading && !this.appointmentTypes.length;
        },

        noSearchResults() {
            return !this.loading && this.search && !this.filteredAppointmentTypes.length && !this.showAnyResult;
        },

        isScheduledType() {
            return this.scheduledOrCancelledType === APPOINTMENT_SCHEDULED;
        },

        isUserType() {
            const type = this.automationProperties?.trigger?.type ?? '';

            return type === USER_APPOINTMENT_SCHEDULED || type === USER_APPOINTMENT_CANCELLED;
        },

        forOptions() {
            const filteredUsers = this.users.filter(({ casId }) => casId !== this.user.casId);

            const userOptions = filteredUsers.map(({ casId, fullName }) => {
                return { value: casId, label: fullName };
            });

            return [
                { value: FOR_ANY_USER, label: this.$t('anyUser') },
                { value: this.user.casId, label: this.user.fullName },
                ...userOptions,
            ];
        },
    },

    methods: {
        init() {
            if (this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE && !this.isScheduledType) {
                this.close();
            } else {
                this.setTrigger();
            }
        },

        setTrigger() {
            const { trigger } = this.automationProperties;

            if (trigger) {
                if (this.isUserType) {
                    this.appointmentFor = trigger.sourceId;
                } else {
                    this.selectedOption = {
                        type: trigger.type,
                        id: trigger.sourceId,
                        name: trigger.name,
                    };
                }
            } else {
                this.selectedOption = {
                    type: this.scheduledOrCancelledType,
                    id: null,
                    name: this.$t(this.isScheduledType ? 'appointmentScheduled' : 'appointmentCancelled'),
                };
            }

            if (!this.hasAppointmentType) {
                this.loadAppointmentTypes();
            } else {
                this.status = status.SUCCESS;
            }
        },

        async loadAppointmentTypes() {
            this.status = status.LOADING;

            try {
                await this.$store.dispatch('calendar/LOAD_PROVIDER_INTEGRATIONS');
                await this.$store.dispatch('calendar/LOAD_APPT_TYPES');
                this.status = status.SUCCESS;
            } catch {
                this.status = status.ERROR;
            }
        },

        handleSelection(appointmentType) {
            this.selectedOption = clonedeep(appointmentType);
            this.$emit('updated', this.appointmentTrigger);
            this.handleAppointmentChange();

            this.$emit('close');
        },

        handleSelectAnyAppointment(closing) {
            this.$emit('updated', this.anyAppointmentTrigger);
            this.handleAppointmentChange();

            if (closing) {
                this.$emit('close');
            } else {
                this.showSelectUser = true;
            }
        },

        handleAppointmentChange() {
            this.$bus.$emit('AUTOMATION_APPOINTMENT_UPDATED');
        },

        handleAnyUpdate() {
            this.handleAppointmentChange();

            this.$emit('updated', this.anyAppointmentTrigger);
        },

        handleNext() {
            this.showSelectUser = false;

            this.handleAppointmentChange();
            this.$emit('updated', this.anyAppointmentTrigger);
            this.$emit('close');
        },

        handleBack() {
            this.showSelectUser = false;
        },

        getDurationDisplay(durationMinutes) {
            return this.$tc(
                'global.time.interval.abbr.minute',
                durationMinutes,
                { count: durationMinutes },
            );
        },

        goToAppoinmentsConfirm() {
            return this.$confirm({
                optionTitle: this.$t('create.title'),
                optionMessage: this.$t('create.message'),
                optionConfirmButtonLabel: this.$t('create.confirm'),
                size: 'sm',
            })
                .then(() => {
                    this.$router.push({ name: NEW_APPOINTMENT_TYPE_ROUTE_NAME });
                }).catch(() => {});
        },

        close() {
            const { type, sourceId } = this.appointmentTrigger;

            if ([APPOINTMENT_SCHEDULED, APPOINTMENT_CANCELLED].includes(type) && !sourceId) {
                this.handleSelectAnyAppointment(true);
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';

    .for-title {
        font-size: $font-size-med;
        margin-bottom: $gp / 2;
    }

    .configure-editor .form.user-form {
        @include padding-start($gp * 3.5);
    }

    .appointment-for {
        width: px-to-rem(350px);
        padding-top: $gp;
    }

    @mixin small-appointment-view() {
        .configure-editor .form.user-form {
            @include padding-start($gp);
            @include padding-end($gp);
        }
    }

    @media($extra-small) {
        @include small-appointment-view();
    }

    .contextual-view {
        @include small-appointment-view();
    }
</style>

<i18n>
{
    "en-us": {
        "anyAppointmentScheduled": {
            "name": "Any appointment is scheduled",
            "title": "Any appointment",
            "description": "When any appointment is scheduled or rescheduled"
        },
        "anyAppointmentCancelled": {
            "name": "Any appointment is cancelled",
            "title": "Any appointment",
            "description": "When any appointment is cancelled"
        },
        "appointmentScheduled": "Appointment is scheduled",
        "appointmentCancelled": "Appointment is cancelled",
        "singleAppointmentScheduled": "({id}) is scheduled",
        "singleAppointmentCancelled": "({id}) is cancelled",
        "emptyAppointmentTypes": "None available",
        "header": "Choose appointment type",
        "appointmentTypesHeader": "Appointment types",
        "buttonText": "Create a new appointment type",
        "create": {
            "title": "Navigate to the appointments page to create a new type?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Go to appointments"
        },
        "search": "Search",
        "emptySearch": "No results found for \"{search}\"",
        "userHeader": "Choose a user",
        "scheduledFor": "When any appointment is scheduled or rescheduled for:",
        "cancelledFor": "When any appointment is cancelled for:",
        "anyUser": "Any user",
        "selectUser": "Select user"
    }
}
</i18n>
