<template>
    <div class="recurring-billing">
        <ds-checkbox
            v-if="!forceIsRecurring"
            v-model="recurringBilling"
            :label="$t('contactRecord.sales.addPaymentModalContent.isRecurringBilling')"
            class="recurring-billing-checkbox"
        />

        <div v-if="recurringBilling" class="recurring-billing-fields">
            <ds-multiselect
                v-model="recurringOption"
                required
                :placeholder="$t('billing.product.modal.recurring')"
                :options="recurringBilling_options"
                data-qa="recurring-frequency"
                @input="updateOccurenceAndEndDate"
            />

            <ds-input-field
                v-if="payment"
                name="start-recurring"
                :label="$t('recurring.start')"
                :value="formattedStartDate"
                readonly
            />

            <ds-date-picker
                v-model="startDate"
                required
                static
                name="start-recurring"
                class="start-recurring"
                data-qa="start-recurring"
                :label="config.startRecurringDate"
                :disable-before-date="config.startDisableDate"
                @input="updateOccurenceAndEndDate"
            />

            <div class="end-recurring">
                <div class="occurrences-text">
                    {{ $t('recurring.end') }}
                </div>

                <ds-select-field
                    v-model="occurrenceSelectValue"
                    bind-value-only
                    class="occurrence-options"
                    data-qa="occurrence-options"
                    :options="occurrenceOptions"
                    @input="updateOccurenceAndEndDate"
                />

                <div v-show="occurrenceSelectValue === occurrenceValues.AFTER" class="occurrences-input">
                    <ds-input-field
                        v-model="occurrences"
                        type="number"
                        data-qa="occurrences-input-field"
                        :min="occurrencesMinLength"
                        :invalid="!isOccurrencesLessThan99Years"
                        @input="updateEndDateFromOccurrence"
                    />
                </div>

                <div v-show="occurrenceSelectValue === occurrenceValues.DATE" class="occurrences-date">
                    <ds-date-picker
                        v-model="displayEndDate"
                        static
                        name="end-recurring"
                        data-qa="end-recurring"
                        :disable-before-date="config.startDisableDate"
                        @input="updateOccurrenceFromEndDate"
                    />
                </div>

                <div v-show="occurrenceSelectValue === occurrenceValues.AFTER" class="occurrences-text">
                    {{ config.occurrenceWording }}
                </div>
            </div>
        </div>

        <i18n
            v-if="payment"
            :path="updateStatementPath"
            tag="p"
            class="charge-statement"
        >
            <template #name>
                <span>{{ config.chargeStatementData.name }}</span>
            </template>

            <template #occurrencesLeft>
                <span>{{ config.chargeStatementData.occurrencesLeft }}</span>
            </template>

            <template #charge>
                <span>{{ config.chargeStatementData.charge }}</span>
            </template>

            <template #productName>
                <span>{{ config.chargeStatementData.productName }}</span>
            </template>

            <template #endDate>
                <span>{{ config.chargeStatementData.endDate }}</span>
            </template>

            <template #recurringOption>
                <span>{{ config.chargeStatementData.recurringOption }}</span>
            </template>
        </i18n>

        <i18n
            v-else-if="newSubscription"
            :path="statementPath"
            tag="p"
            class="charge-statement"
        >
            <template #name>
                <span>{{ config.chargeStatementData.name }}</span>
            </template>

            <template #amount>
                <span class="bold">{{ config.chargeStatementData.amount }}</span>
            </template>

            <template #recurringOption>
                <span>{{ config.chargeStatementData.recurringOption }}</span>
            </template>

            <template #date>
                <span>{{ config.chargeStatementData.date }}</span>
            </template>

            <template #occurrences>
                <span>{{ config.chargeStatementData.occurrences }}</span>
            </template>

            <template #occurrenceString>
                <span>{{ config.chargeStatementData.occurrenceString }}</span>
            </template>

            <template #endDate>
                <span>{{ config.chargeStatementData.endDate }}</span>
            </template>
        </i18n>
    </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import dateMixin from '@/appointments/mixins/date.mixin';
import recurringBilling from '@/money/mixins/recurring-billing';
import { frequencyStatus, getBillingCycleStatus, timeUnitStatus } from '@/money/constants/order-status';
import { DISPLAY_DATE_FORMAT_UNABRIDGED } from '@/shared/constants/dateFormats.constants';

const occurrenceValues = {
    DATE: 'date',
    AFTER: 'after',
    NEVER: 'never',
};

export default {
    mixins: [dateMixin, recurringBilling],

    props: {
        displayAmount: String,
        forceIsRecurring: Boolean,
        payment: Object,
    },

    data() {
        return {
            occurrenceSelectValue: occurrenceValues.AFTER,
            occurrenceValues,
            recurringBilling: this.forceIsRecurring,
            occurrences: 12,
            startDate: new Date(),
            tomorrow: moment().add(1, 'd').format(),
            endDate: null,
            occurrencesMinLength: 1,
            occurrenceOptions: [
                { value: occurrenceValues.AFTER, label: this.$t('global.after') },
                { value: occurrenceValues.DATE, label: this.$t('global.on') },
                { value: occurrenceValues.NEVER, label: this.$t('global.never') },
            ],
            recurringOption: {
                label: this.$t('contactRecord.sales.payments.recurring.options.monthly'),
                value: '2',
            },
            placeholder: [
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
            ],
            displayEndDate: null,
        };
    },

    mounted() {
        this.setInitialData();
    },

    watch: {
        recurringBilling(val) {
            this.$emit('updateRecurringBilling', val);
        },

        occurrences() {
            this.checkForRequiredFields();
        },

        startDate() {
            this.checkForRequiredFields();
        },
    },

    computed: {
        ...mapGetters({
            displayFullName: 'contacts/displayFullName',
        }),

        isOccurrencesLessThan99Years() {
            const numberOfOccurrences = this.occurrences;
            const occurrencesUnit = this.recurringOption.value;

            if (numberOfOccurrences < 0) {
                return false;
            }

            switch (occurrencesUnit) {
            case frequencyStatus.YEARLY:
                return numberOfOccurrences <= 99;
            case frequencyStatus.MONTHLY:
                return numberOfOccurrences <= 1188;
            case frequencyStatus.WEEKLY:
                return numberOfOccurrences <= 5162;
            case frequencyStatus.DAILY:
                return numberOfOccurrences <= 36159;
            default:
                return false;
            }
        },

        formattedStartDate() {
            return this.date_formatDate(this.payment.startDate);
        },

        config() {
            return this.payment
                ? {
                    startRecurringDate: this.$t('recurring.next'),
                    startDisableDate: moment().add(1, 'days').format(),
                    occurrenceWording: this.$tc('billing.product.modal.moreOccurrence', this.occurrences),
                    chargeStatementData: {
                        name: `${this.payment?.contact?.firstName} ${this.payment?.contact?.lastName}`,
                        occurrencesLeft: this.occurrences,
                        charge: this.$tc('billing.product.modal.charge', this.occurrences),
                        productName: this.payment?.product?.productName,
                        recurringOption: this.recurringOption.label ? this.recurringOption.label.toLowerCase() : '',
                        endDate: moment(this.calculateDisplayEndDate()).format(DISPLAY_DATE_FORMAT_UNABRIDGED),
                    },
                }
                : {
                    startRecurringDate: this.$t('billing.product.modal.startRecurring'),
                    startDisableDate: 'today',
                    occurrenceWording: this.$tc('billing.product.modal.occurrence', this.occurrences),
                    chargeStatementData: {
                        name: this.displayFullName,
                        amount: this.displayAmount,
                        recurringOption: this.recurringOption.label ? this.recurringOption.label.toLowerCase() : '',
                        occurrences: this.occurrences,
                        occurrenceString: this.$tc('billing.product.modal.occurrence', this.occurrences),
                        date: moment(this.startDate).format(DISPLAY_DATE_FORMAT_UNABRIDGED),
                        endDate: moment(this.calculateDisplayEndDate()).format(DISPLAY_DATE_FORMAT_UNABRIDGED),
                    },
                };
        },

        newSubscription() {
            return Boolean(this.recurringBilling && !this.payment);
        },

        statementPath() {
            return this.occurrenceSelectValue === occurrenceValues.NEVER
                ? 'contactRecord.sales.addPaymentModalContent.subscriptionStatementNoEnding'
                : 'contactRecord.sales.addPaymentModalContent.subscriptionStatement';
        },

        updateStatementPath() {
            return this.occurrenceSelectValue === occurrenceValues.NEVER
                ? 'contactRecord.sales.addPaymentModalContent.subscriptionUpdateStatementNoEnding'
                : 'contactRecord.sales.addPaymentModalContent.subscriptionUpdateStatement';
        },
    },

    methods: {
        getData() {
            return this.payment
                ? {
                    billingCycle: this.recurringOption.value,
                    nextBillDate: this.startDate,
                    endDate: this.endDate,
                }
                : {
                    recurringBilling: this.recurringBilling,
                    numberOfPayments: this.occurrences,
                    frequency: 1,
                    billingCycle: this.recurringOption.value,
                    startDate: this.startDate,
                };
        },

        getRequiredFields() {
            return [
                {
                    name: 'occurrences',
                    isSatisfied: this.isOccurrencesLessThan99Years,
                },
                {
                    name: 'startDate',
                    isSatisfied: Boolean(this.startDate),
                },
            ];
        },

        checkForRequiredFields() {
            const requiredFields = this.getRequiredFields();
            const hasMissingRequiredFields = requiredFields.some((elem) => elem.isSatisfied === false);

            return this.$emit('update:hasRequiredFields', !hasMissingRequiredFields);
        },

        calculateEndDate(
            occurrences = this.occurrences,
            startDate = this.startDate,
            recurringFrequency = this.recurringOption.value,
        ) {
            const timeUnit = timeUnitStatus(recurringFrequency);

            return moment(startDate)
                .startOf('day')
                .add(occurrences, timeUnit)
                .format();
        },

        calculateDisplayEndDate(endDate = this.endDate, recurringFrequency = this.recurringOption.value) {
            const timeUnit = timeUnitStatus(recurringFrequency);

            return moment(endDate)
                .startOf('day')
                .subtract(1, timeUnit)
                .format();
        },

        calculateOccurrence(
            endDate = this.endDate,
            startDate = this.startDate,
            recurringFrequency = this.recurringOption.value,
        ) {
            const day1 = moment(startDate).startOf('day');
            const day2 = moment(endDate);
            const timeUnit = timeUnitStatus(recurringFrequency);

            return day2.diff(day1, timeUnit);
        },

        calculateOccurrenceFromDisplayEndDate(
            endDate = this.displayEndDate,
            startDate = this.startDate,
            recurringFrequency = this.recurringOption.value,
        ) {
            const timeUnit = timeUnitStatus(recurringFrequency);
            const day1 = moment(startDate).startOf('day');
            const day2 = moment(endDate).add(1, timeUnit);

            this.endDate = day2;

            return day2.diff(day1, timeUnit);
        },

        checkEndIsBeforeStart(endDate = this.displayEndDate) {
            if (moment(endDate).isBefore(moment(this.startDate))) {
                this.startDate = endDate;
            }
        },

        updateOccurrenceFromEndDate(val) {
            if (this.occurrenceSelectValue === occurrenceValues.DATE) {
                this.checkEndIsBeforeStart(val);
                this.occurrences = this.calculateOccurrenceFromDisplayEndDate(val);
            }
        },

        updateEndDateFromOccurrence(val) {
            if (this.occurrenceSelectValue === occurrenceValues.AFTER) {
                this.endDate = this.calculateEndDate(val);
                this.displayEndDate = this.calculateDisplayEndDate();
            }
        },

        updateOccurenceAndEndDate() {
            if (this.occurrenceSelectValue === occurrenceValues.NEVER) {
                this.occurrences = 0;
                this.endDate = null;
            } else {
                if (this.occurrences === 0) {
                    this.occurrences = 12;
                }

                this.updateOccurrenceFromEndDate();
                this.updateEndDateFromOccurrence();
            }
        },

        setInitialData() {
            if (this.payment) {
                this.startDate = this.getInitialStartDate();
                this.recurringOption = {
                    label: this.$t(`contactRecord.sales.payments.recurring.options.${getBillingCycleStatus(this.payment.billingCycle)}`),
                    value: this.payment.billingCycle,
                };

                if (this.payment.endDate) {
                    this.endDate = this.payment.endDate;
                    this.occurrences = this.calculateOccurrence();
                    this.displayEndDate = this.payment.finalPaymentDate;
                } else {
                    this.occurrences = 0;
                    this.occurrenceSelectValue = occurrenceValues.NEVER;
                }
            } else {
                this.endDate = this.calculateEndDate(this.occurrences);
                this.displayEndDate = this.calculateDisplayEndDate(this.endDate);
            }

            this.checkForRequiredFields();
        },

        getInitialStartDate() {
            return this.payment.status === 'Inactive' && moment().isAfter(this.payment.nextBillDate)
                ? this.tomorrow
                : this.date_formatDate(this.payment.nextBillDate);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import '~@/money/styles/charge-statement';

    .occurrences-holder {
        display: flex;
        align-items: center;
    }

    .occurrences-input {
        width: px-to-rem(75px);
    }

    .recurring-billing-checkbox {
        --checkbox-padding: #{$gp 0};
    }

    .recurring-billing-fields {
        margin-bottom: $gp;
    }

    .start-recurring {
        --input-margin-bottom: 0;
    }

    .occurrence-options {
        max-width: px-to-rem(140px);
    }

    .end-recurring {
        display: inline-flex;
        align-items: center;
        margin-top: $gp;
        --input-margin-bottom: $gp / 2;

        > *:not(:last-child) {
            @include margin-end($gp / 2);
            word-wrap: none;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "errors": {
            "loadCountryOptions": "Something went wrong and we couldn't load the country options. Please try again."
        },
        "recurring": {
            "end": "End payments",
            "start": "Created date",
            "next": "Next payment"
        }
    }
}
</i18n>
