<template>
    <transition name="slide-left" mode="out-in">
        <div v-if="open" :class="['build-automation', { 'builder-view': !isQuickview, 'contextual-view': contextType }]">
            <section :class="['builder-section', { 'only-desktop': sidePanelComponent && (!isQuickview || contextType), 'left-panel': sidePanelComponent && !contextType }]">
                <header v-if="!isQuickview" class="builder-header">
                    <ds-icon-button name="x" data-qa="close-button" @click="close" />

                    <span class="builder-header-text">{{ $t(isTemplate ? 'templateHeader': 'header') }}</span>

                    <save-status v-if="!sidePanelComponent" :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button
                        v-if="canGoNextHeader"
                        data-qa="next-header-button"
                        @click="showNext"
                    >
                        {{ $t('global.next') }}
                    </ds-filled-button>

                    <template v-else-if="!sidePanelComponent">
                        <ds-filled-button
                            v-if="showErrors"
                            data-qa="save-draft-header-button"
                            :loading="showButtonLoading"
                            @click="saveDraft"
                        >
                            {{ $t('saveDraft') }}
                        </ds-filled-button>

                        <ds-filled-button
                            v-else-if="isAutomationInvalid"
                            data-qa="preview-button"
                            @click="showPreview"
                        >
                            {{ $t('global.preview') }}
                        </ds-filled-button>

                        <div v-else class="button-row">
                            <send-test-email :is-template="isTemplate" :automation="localAutomation" />

                            <ds-outline-button
                                v-if="isPublished && !canPublishEdits"
                                data-qa="exit-button"
                                @click="close"
                            >
                                {{ $t('exit') }}
                            </ds-outline-button>

                            <ds-filled-button
                                v-else
                                :loading="showButtonLoading"
                                data-qa="publish-button"
                                @click="handlePublish"
                            >
                                {{ $t( canPublishEdits ? 'publishEdits' : 'publish') }}
                            </ds-filled-button>
                        </div>
                    </template>
                </header>

                <div class="builder-content">
                    <rename-automation
                        v-if="!isQuickview && !sidePanelComponent"
                        v-model="localAutomation.name"
                        class="rename-form"
                        :is-template="isTemplate"
                        @input="(name) => $emit('rename', name)"
                    />

                    <participant-count
                        v-if="isPublished && isQuickview"
                        :id="localAutomation.id"
                        :triggers="localAutomation.triggers"
                        @view-participants="$emit('view-participants')"
                    />

                    <template v-if="showErrors || displayWarnings">
                        <ds-inline-alert
                            v-if="isAutomationInvalid && !hasFrontendAutomationErrors"
                            class="error-banner-container"
                            type="critical"
                            leading-icon
                        >
                            {{ $t('errorBanner') }}
                        </ds-inline-alert>

                        <ds-inline-alert
                            v-for="({ messageCode }, i) in frontendValidationErrors"
                            v-else-if="hasFrontendAutomationErrors"
                            :key="`${messageCode}-${i}`"
                            class="error-banner-container"
                            leading-icon
                            type="critical"
                        >
                            <template #default>
                                {{ $t(getValidationMessage(messageCode).validationMessage) }}
                            </template>

                            <template v-if="getValidationMessage(messageCode).action" #footer>
                                <ds-button
                                    gray
                                    dense
                                    class="action"
                                    :leading-icon="getValidationMessage(messageCode).icon"
                                    @click="handleErrorAction(messageCode)"
                                >
                                    {{ $t(getValidationMessage(messageCode).action) }}
                                </ds-button>
                            </template>
                        </ds-inline-alert>

                        <ds-inline-alert
                            v-if="hasValidationErrors && !validating"
                            class="error-banner-container"
                            type="critical"
                            leading-icon
                        >
                            {{ $t(getValidationMessage(validationErrors[0].messageCode).validationMessage) }}
                        </ds-inline-alert>

                        <template v-if="hasValidationWarnings && !validating">
                            <ds-inline-alert
                                v-for="({ messageCode }, i) in validationWarnings"
                                :key="`${messageCode}-${i}`"
                                class="error-banner-container"
                                type="warning"
                                leading-icon
                            >
                                {{ $t(getValidationMessage(messageCode).validationMessage) }}
                            </ds-inline-alert>
                        </template>

                        <sms-automation-setup-modal
                            :is-open="isSmsSetupModalOpen"
                            @close="isSmsSetupModalOpen = false"
                            @complete="completeSmsSetup"
                        />
                    </template>

                    <when-card
                        :triggers="localAutomation.triggers"
                        :active="isSelected(TRIGGER)"
                        :inactive="isSelected(STEP) || isSelected(REMOVE_TRIGGER)"
                        :show-errors="showErrors"
                        @selected="handleSelect(TRIGGER)"
                    />

                    <ds-icon class="spacer-arrow" name="arrow-down" />

                    <then-card
                        :steps="localAutomation.steps"
                        :active="isSelected(STEP)"
                        :inactive="isSelected(TRIGGER) || isSelected(REMOVE_TRIGGER)"
                        :show-errors="showErrors"
                        @selected="handleSelect(STEP)"
                        @select-step="handleSelectStep"
                    />

                    <stop-card
                        v-if="showStop"
                        :remove-triggers="localAutomation.removeTriggers"
                        :active="isSelected(REMOVE_TRIGGER)"
                        :inactive="isSelected(STEP) || isSelected(TRIGGER)"
                        :show-errors="showErrors"
                        @selected="handleSelect(REMOVE_TRIGGER)"
                    />

                    <!-- For debugging -->
                    <!-- <pre>{{ localAutomation }}</pre> -->
                </div>
            </section>

            <transition name="slide-left">
                <div v-if="sidePanelComponent" class="side-panel">
                    <header class="builder-header side-panel-mobile-header">
                        <ds-icon-button name="arrow-left" @click="hideSidePanel" />

                        <span class="builder-header-text">{{ $t(isTemplate ? 'templateHeader': 'header') }}</span>

                        <ds-filled-button
                            v-if="canPreview"
                            class="right-button"
                            data-qa="preview-button"
                            @click="showPreview"
                        >
                            {{ $t('global.preview') }}
                        </ds-filled-button>

                        <ds-filled-button
                            v-else-if="canGoNext"
                            class="right-button"
                            data-qa="next-button"
                            @click="showNext"
                        >
                            {{ $t('global.next') }}
                        </ds-filled-button>
                    </header>

                    <header class="side-panel-header">
                        <span :class="['nav-text', { selected: isSelected(TRIGGER) }]" @click="handleSelect(TRIGGER)">
                            {{ $t('when') }}
                        </span>

                        <ds-icon name="arrow-long-right" :class="['header-arrow', { selected: isSelected(STEP) }]" />

                        <span :class="['nav-text', { selected: isSelected(STEP) }]" @click="handleSelect(STEP)">{{ $t('then') }}</span>

                        <template v-if="showStop">
                            <ds-icon name="arrow-long-right" :class="['.header-arrow', { selected: isSelected(REMOVE_TRIGGER) }]" />

                            <span :class="['nav-text', { selected: isSelected(REMOVE_TRIGGER) }]" @click="handleSelect(REMOVE_TRIGGER)">{{ $t('stop') }}</span>
                        </template>

                        <save-status :status="saveStatus" class="shift-right save-status" />

                        <ds-filled-button
                            v-if="canPreview"
                            class="only-desktop"
                            data-qa="preview-button"
                            @click="showPreview"
                        >
                            {{ $t('global.preview') }}
                        </ds-filled-button>

                        <ds-filled-button
                            v-else-if="canGoNext"
                            class="right-button only-desktop"
                            data-qa="next-button"
                            @click="showNext"
                        >
                            {{ $t('global.next') }}
                        </ds-filled-button>
                    </header>

                    <div :class="['scroll-wrapper', { 'is-editing': isEditing }]">
                        <component
                            :is="sidePanelComponent"
                            :value="componentData"
                            :show-errors="showErrors || hasValidationWarnings"
                            :is-editing="isEditing"
                            :active-index="activeIndex"
                            @set-active-index="setActiveIndex"
                            @updated="handleUpdateAutomation"
                            @toggle-editing="(editing) => isEditing = editing"
                            @reset-timers-and-remove-trigger="handleResetTimersAndRemoveTrigger"
                        />
                    </div>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import isequal from 'lodash.isequal';
import amplitude from '@/analytics/amplitude';

import {
    AUTOMATION_STATUS,
    TIMER_STEPS,
    SAVE_STATUS,
    ERROR_LEVELS,
    RELATIVE_TIMER,
    APPOINTMENT_SCHEDULED,
    ANY_APPOINTMENT_SCHEDULED,
    USER_APPOINTMENT_SCHEDULED,
    ANY_APPOINTMENT_CANCELLED,
    TRIGGERS,
    VALIDATION_MESSAGES,
    validationMessageCodes,
    ANY_INVOICE_PAID,
    ANY_INVOICE_SENT,
    ANY_QUOTE_SENT,
    ANY_QUOTE_ACCEPTED,
    OPEN_COMPONENT,
    DEAL_ENTERED,
    DEAL_EXITED,
} from '@/automations/constants/automations.constants';
import { isValidStep, isValidTrigger } from '@/automations/utils/automations.utils';
import StepSetup from '@/automations/components/StepSetup';
import TriggerSetup from '@/automations/components/TriggerSetup';
import StopSetup from '@/automations/components/StopSetup';
import ThenCard from '@/automations/components/ThenCard';
import WhenCard from '@/automations/components/WhenCard';
import StopCard from '@/automations/components/StopCard';
import RenameAutomation from '@/automations/components/RenameAutomation';
import SaveStatus from '@/automations/components/SaveStatus';
import ParticipantCount from '@/automations/components/participants/ParticipantCount';
import SendTestEmail from '@/automations/components/SendTestEmail';
import SmsAutomationSetupModal from '@/automations/components/SmsAutomationSetupModal';
import { SAVING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import { BLENDR_TYPES } from '@/shared/constants/integrations.constants';

const sidePanelComponents = {
    TRIGGER: TriggerSetup,
    STEP: StepSetup,
    REMOVE_TRIGGER: StopSetup,
};
const { TRIGGER, STEP, REMOVE_TRIGGER } = OPEN_COMPONENT;

export default {
    components: {
        ThenCard,
        WhenCard,
        StopCard,
        SaveStatus,
        SendTestEmail,
        ParticipantCount,
        RenameAutomation,
        SmsAutomationSetupModal,
    },

    props: {
        open: Boolean,
        buttonLoading: Boolean,
        isQuickview: Boolean,
        isTemplate: Boolean,
        openComponent: String,
        automation: {
            type: Object,
            required: true,
        },
        validationCheckDelay: {
            type: Number,
            default: SAVING_DEBOUNCE_DELAY,
        },
    },

    data() {
        return {
            displayErrors: false,
            displayWarnings: false,
            sidePanelComponent: null,
            TRIGGER,
            STEP,
            REMOVE_TRIGGER,
            componentToOpen: TRIGGER,
            activeIndex: null,
            isEditing: false,
            localAutomation: clonedeep(this.automation),
            validated: false,
            validating: false,
            isSmsSetupModalOpen: false,
            disabling: false,
        };
    },

    mounted() {
        if (this.$route.params.openComponent) {
            this.handleSelect(this.$route.params.openComponent);
        }

        if (this.openComponent) {
            this.handleSelect(this.openComponent);
        }

        this.$store.commit('automations/SET_VALIDATED_AUTOMATION', {});
    },

    watch: {
        openComponent: {
            handler(val) {
                this.handleSelect(val);
            },
        },

        automation: {
            handler(value) {
                this.localAutomation = clonedeep(value);
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            validatedAutomation: ({ automations }) => automations.validatedAutomation,
            contextType: ({ automations }) => automations.context?.type,
            saveStatus: ({ automations }) => automations.saveStatus,
        }),

        ...mapGetters({
            automationWithFrontendErrors: 'automations/automationWithFrontendErrors',
        }),

        showButtonLoading() {
            return Boolean(this.buttonLoading || this.saveStatus === SAVE_STATUS.SAVING || this.validating || this.disabling);
        },

        validationErrors() {
            return this.validatedAutomation?.messages?.filter(({ severity, messageCode }) => severity === ERROR_LEVELS.ERROR && this.isKnownMessageCode(messageCode));
        },

        validationWarnings() {
            return this.validatedAutomation?.messages?.filter(({ severity, messageCode }) => severity === ERROR_LEVELS.WARNING && this.isKnownMessageCode(messageCode));
        },

        hasParticipantChangeWarning() {
            return this.validationWarnings.some(({ messageCode }) => messageCode === validationMessageCodes.PARTICIPANT_CHANGE);
        },

        frontendValidationErrors() {
            return this.automationWithFrontendErrors?.messages?.filter(({ severity }) => severity === ERROR_LEVELS.ERROR);
        },

        hasValidationErrors() {
            return this.validationErrors?.length > 0;
        },

        hasValidationWarnings() {
            return this.validationWarnings?.length > 0;
        },

        hasFrontendAutomationErrors() {
            return this.frontendValidationErrors?.length > 0;
        },

        componentData() {
            if (this.isSelected(TRIGGER)) return this.localAutomation.triggers;
            if (this.isSelected(REMOVE_TRIGGER)) return this.localAutomation.removeTriggers;

            return this.localAutomation.steps;
        },

        canPreview() {
            return this.localAutomation.steps?.length && this.localAutomation.triggers?.length;
        },

        canGoNext() {
            const { steps, triggers } = this.localAutomation;

            return !steps?.length && triggers?.length && (this.isSelected(TRIGGER) || this.isSelected(REMOVE_TRIGGER))
                || !triggers?.length && steps?.length && (this.isSelected(STEP) || this.isSelected(REMOVE_TRIGGER));
        },

        canGoNextHeader() {
            return Boolean((!this.localAutomation.steps?.length || !this.localAutomation.triggers?.length) && !this.sidePanelComponent);
        },

        isPublished() {
            return Boolean(this.localAutomation.status === AUTOMATION_STATUS.ENABLED) || this.isTemplate;
        },

        canPublishEdits() {
            return this.isPublished && this.localAutomation?.hasChanges;
        },

        showErrors() {
            return this.displayErrors && (this.isAutomationInvalid || this.hasValidationErrors);
        },

        isAutomationInvalid() {
            return this.localAutomation.steps?.some((step) => !isValidStep(step))
                || this.localAutomation.triggers?.some((trigger) => !isValidTrigger(trigger))
                || this.localAutomation.removeTriggers?.some((trigger) => !isValidTrigger(trigger))
                || this.hasFrontendAutomationErrors;
        },

        showStop() {
            const hasTimerStep = this.localAutomation.steps?.some(({ type }) => TIMER_STEPS.includes(type));

            return hasTimerStep || this.localAutomation.removeTriggers?.length || this.isSelected(REMOVE_TRIGGER);
        },

        automationHasNoTimers() {
            return !this.automation.steps.some(({ type }) => TIMER_STEPS.includes(type));
        },

        automationHasNoStops() {
            return !this.automation.removeTriggers || this.automation.removeTriggers.length === 0;
        },
    },

    methods: {
        isKnownMessageCode(code) {
            return Object.keys(validationMessageCodes).some((key) => validationMessageCodes[key] === code);
        },

        saveDraft() {
            this.$emit('save');
            this.close();
        },

        close() {
            this.$emit('close');
            this.reset();
        },

        showPreview() {
            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_PREVIEWED);

            if (this.hasValidationWarnings) {
                this.displayWarnings = true;
            }

            if (this.isAutomationInvalid) {
                this.displayErrors = true;
            } else {
                this.validateAutomation();
            }

            this.hideSidePanel();
        },

        async handlePublish() {
            if (!this.validated) {
                await this.validateAutomation();

                if (this.validated && !this.hasValidationErrors && !this.hasValidationWarnings) {
                    this.publish();
                }
            } else {
                this.publish();
            }
        },

        async publish() {
            const disableAutomation = this.canPublishEdits && this.hasParticipantChangeWarning;

            if (disableAutomation) {
                this.disabling = true;

                await this.$store.dispatch('automations/DISABLE_AUTOMATION', this.automation.id);

                this.disabling = false;
            }

            this.$emit('publish');
        },

        getValidationMessage(messageCode) {
            return VALIDATION_MESSAGES[messageCode];
        },

        async disableConfirm() {
            try {
                await this.$confirm({
                    optionTitle: this.$t('timerDisable.confirmTitle'),
                    optionMessage: this.$t('timerDisable.confirmMessage'),
                    optionConfirmButtonLabel: this.$t('timerDisable.button'),
                    destructive: true,
                    size: 'sm',
                });

                this.disable();

                return true;
            } catch {
                return false;
            }
        },

        disable() {
            this.$emit('disable');
        },

        handleSelect(component) {
            this.isEditing = false;

            if (this.isQuickview) {
                this.$emit('open', { id: this.localAutomation.id, openComponent: component });
            } else {
                this.sidePanelComponent = sidePanelComponents[component];
            }
        },

        handleSelectStep(index) {
            this.handleSelect(STEP);
            this.activeIndex = index;
        },

        setActiveIndex(i) {
            this.activeIndex = i;
        },

        hideErrors() {
            if ((this.displayErrors || this.displayWarnings) && !this.isAutomationInvalid) {
                this.$store.commit('automations/SET_VALIDATED_AUTOMATION', {});
                this.displayErrors = false;
                this.displayWarnings = false;
            }
        },

        showNext() {
            if (this.isSelected(TRIGGER) || this.localAutomation.triggers.length) {
                this.handleSelect(STEP);
            } else {
                this.handleSelect(TRIGGER);
            }
        },

        hideSidePanel() {
            this.sidePanelComponent = null;
        },

        isSelected(component) {
            return this.sidePanelComponent === sidePanelComponents[component];
        },

        isUpdatingTimer(oldSteps, newSteps) {
            const oldTimerSteps = oldSteps.filter(({ type }) => TIMER_STEPS.includes(type));
            const newTimerSteps = newSteps.filter(({ type }) => TIMER_STEPS.includes(type));

            return !isequal(oldTimerSteps, newTimerSteps);
        },

        async handleUpdateAutomation(events) {
            if (this.isPublished
                && !this.isTemplate
                && this.isSelected(STEP)
                && this.isUpdatingTimer(this.automation.steps, events)) {
                const shouldDisable = await this.disableConfirm();

                if (shouldDisable) {
                    this.updateAutomation(events);
                } else {
                    this.localAutomation = clonedeep(this.automation);
                }
            } else {
                this.updateAutomation(events);
            }
        },

        shouldAddAppointmentCancelledStopTrigger(events) {
            const hasAppointmentScheduledTrigger = this.automation.triggers.some(({ type }) => type === APPOINTMENT_SCHEDULED || type === ANY_APPOINTMENT_SCHEDULED || type === USER_APPOINTMENT_SCHEDULED);

            return hasAppointmentScheduledTrigger && this.shouldAutoAddStop(events);
        },

        shouldAddInvoicePaidStopTrigger(events) {
            const hasInvoiceSentTrigger = this.automation.triggers.some(({ type }) => type === ANY_INVOICE_SENT);

            return hasInvoiceSentTrigger && this.shouldAutoAddStop(events);
        },

        shouldAddQuoteAcceptedStopTrigger(events) {
            const hasQuoteSentTrigger = this.automation.triggers.some(({ type }) => type === ANY_QUOTE_SENT);

            return hasQuoteSentTrigger && this.shouldAutoAddStop(events);
        },

        shouldAddDealExitedStopTrigger(events) {
            const hasDealEnteredTrigger = this.automation.triggers.some(({ type }) => type === DEAL_ENTERED);

            return hasDealEnteredTrigger && this.shouldAutoAddStop(events);
        },

        shouldAutoAddStop(events) {
            const stepsHasTimer = events.some(({ type }) => TIMER_STEPS.includes(type));
            const isAddingTimer = this.automationHasNoTimers && stepsHasTimer;

            return isAddingTimer && this.automationHasNoStops;
        },

        updateAutomation(events) {
            if (this.isSelected(TRIGGER)) {
                this.$emit('update:automation', { ...this.localAutomation, triggers: events });
            } else if (this.isSelected(REMOVE_TRIGGER)) {
                this.$emit('update:automation', { ...this.localAutomation, removeTriggers: events });
            } else {
                if (this.shouldAddAppointmentCancelledStopTrigger(events)) {
                    amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                        'Stop Added': `${ANY_APPOINTMENT_CANCELLED} (automatic)`,
                    });
                    this.localAutomation.removeTriggers = [{ ...TRIGGERS[ANY_APPOINTMENT_CANCELLED] }];
                } else if (this.shouldAddInvoicePaidStopTrigger(events)) {
                    amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                        'Stop Added': `${ANY_INVOICE_PAID} (automatic)`,
                    });
                    this.localAutomation.removeTriggers = [{ ...TRIGGERS[ANY_INVOICE_PAID] }];
                } else if (this.shouldAddQuoteAcceptedStopTrigger(events)) {
                    amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                        'Stop Added': `${ANY_QUOTE_ACCEPTED} (automatic)`,
                    });
                    this.localAutomation.removeTriggers = [{ ...TRIGGERS[ANY_QUOTE_ACCEPTED] }];
                } else if (this.shouldAddDealExitedStopTrigger(events)) {
                    amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                        'Stop Added': `${DEAL_EXITED} (automatic)`,
                    });
                    const dealEnteredTrigger = this.localAutomation.triggers[0];

                    this.localAutomation.removeTriggers = [{
                        ...TRIGGERS[DEAL_EXITED],
                        sourceId: dealEnteredTrigger.sourceId,
                        configJson: dealEnteredTrigger.configJson,
                    }];
                }

                this.$emit('update:automation', { ...this.localAutomation, steps: events });
            }

            this.save();
        },

        handleErrorAction(messageCode) {
            switch (messageCode) {
            case validationMessageCodes.STOP_TRIGGER_SAME_AS_TRIGGER:
            case validationMessageCodes.STOP_TRIGGER_MISSING_TIMER:
            case validationMessageCodes.STOP_TRIGGER_INVALID:
                this.removeStopTrigger();
                break;
            case validationMessageCodes.SMS_STEP_MISSING_SMS_ACCOUNT:
                this.setupSmsAccount();
                break;
            case validationMessageCodes.HELLOSIGN_NOT_CONNECTED:
                this.$router.push({ name: 'settings.integrations', params: { integration: BLENDR_TYPES.HELLO_SIGN } });
                break;
            case validationMessageCodes.TYPEFORM_NOT_CONNECTED:
                this.$router.push({ name: 'settings.integrations', params: { integration: BLENDR_TYPES.TYPEFORM } });
                break;
            case validationMessageCodes.SHOPIFY_NOT_CONNECTED:
                this.$router.push({ name: 'settings.integrations', params: { integration: BLENDR_TYPES.SHOPIFY } });
                break;
            case validationMessageCodes.SHOPIFY_MISSING_FEATURE:
                this.$router.push({ name: 'settings.account' });
                break;
            default:
                break;
            }
        },

        removeStopTrigger() {
            this.localAutomation.removeTriggers = [];
            this.$emit('update:automation', { ...this.localAutomation });

            this.save();
        },

        setupSmsAccount() {
            this.isSmsSetupModalOpen = true;
        },

        completeSmsSetup() {
            this.save();
        },

        save() {
            this.hideErrors();
            this.$emit('save');
            this.validated = false;
        },

        async validateAutomation() {
            if (this.isTemplate) {
                this.validated = true;
                this.displayErrors = this.hasValidationErrors || this.isAutomationInvalid;
                this.displayWarnings = this.hasValidationWarnings;
            } else {
                this.validating = true;

                if (this.saveStatus === SAVE_STATUS.SAVING) {
                // we don't want to call validate while it's saving,
                // so wait a second and try again
                    setTimeout(() => {
                        this.validateAutomation();
                    }, this.validationCheckDelay);
                } else {
                    await this.callValidateAutomation();
                }
            }
        },

        async callValidateAutomation() {
            const { id } = this.localAutomation;

            try {
                await this.$store.dispatch('automations/VALIDATE_AUTOMATION', { id });

                this.validated = true;

                this.displayErrors = this.hasValidationErrors || this.isAutomationInvalid;
                this.displayWarnings = this.hasValidationWarnings;
            } catch (error) {
                this.$error({ message: this.$t('errorValidating') });
                this.validated = false;
            }

            this.validating = false;
        },

        reset() {
            this.displayErrors = false;
            this.displayWarnings = false;
            this.sidePanelComponent = null;
        },

        async handleResetTimersAndRemoveTrigger(i) {
            if (this.isPublished) {
                const shouldDisable = await this.disableConfirm();

                if (shouldDisable) {
                    this.resetTimers(i);
                }
            } else {
                this.resetTimers(i);
            }
        },

        resetTimers(i) {
            this.localAutomation?.triggers.splice(i, 1);
            const stepEvents = this.localAutomation?.steps.filter(({ type }) => type !== RELATIVE_TIMER);

            this.$emit('update:automation', { ...this.localAutomation, steps: stepEvents });

            this.save();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/header';
    $card-width: px-to-rem(343px);

    .build-automation {
        display: flex;

        &.builder-view {
            height: calc(100vh - var(--top-nav-height, 0rem));
            width: calc(100vw - var(--side-nav-width));
            position: fixed;
            top: 0;
            margin-top: var(--top-nav-height);
            z-index: 2;

            @media($small) {
                width: 100%;
            }
        }
    }

    .builder-section {
        width: 100%;

        &.left-panel {
            max-width: var(--split-layout-side-panel-width);
        }
    }

    .builder-content {
        @include overflowY;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        background-color: $color-gray-050;
        padding: $gp $gp $gp * 5;
    }

    .spacer-arrow {
        margin: $gp 0;
    }

    .shift-right {
        @include margin-start(auto);
    }

    .save-status {
        padding: 0 $gp;
    }

    .side-panel {
        @include border-start(1px solid $color-gray-200);
        @include overflowY;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .side-panel-header {
            height: px-to-rem(57px);
            display: flex;
            align-items: center;
            padding: $gp;
            border-bottom: 1px solid $color-gray-200;
            width: 100%;
        }
    }

    .nav-text {
        @include transition(color);
        cursor: pointer;
        padding: 0 $gp / 2;
        color: $color-text-subtle;
        font-size: $font-size-huge;
        font-weight: $font-weight-bold;
        font-family: $font-family-secondary-title;

        &.selected {
            color: $color-ink;
        }
    }

    .header-arrow {
        --icon-color: #{$color-text-subtle};

        &.selected {
            --icon-color: #{$color-ink};
        }
    }

    .scroll-wrapper {
        @include overflowY;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .error-banner-container {
        width: 100%;
        max-width: $card-width;
        margin-bottom: $gp;
    }

    .input-field.rename-form {
        --input-margin-bottom: #{$gp};
        width: $card-width;
        margin-top: $gp / 2;
    }

    .side-panel-mobile-header {
        display: none;
    }

    @mixin small-automation-view() {
        .scroll-wrapper {
            padding-top: $gp;
            background-color: $color-gray-050;

            &.is-editing {
                position: fixed;
            }
        }

        .side-panel {
            border: 0;

            .side-panel-header {
                padding: $gp $gp / 2;
                border-bottom: none;
            }
        }

        .side-panel-mobile-header {
            display: flex;
        }

        .build-automation.builder-view,
        &.build-automation.builder-view {
            margin-top: 0;
            left: 0;
            width: 100%;
        }

        .builder-content,
        .input-field.rename-form {
            width: 100%;
        }
    }

    @media($extra-small) {
        @include small-automation-view();

        .build-automation.builder-view {
            height: 100%;
        }
    }

    .contextual-view {
        --automation-header-height: #{px-to-rem(56px)};
        @include small-automation-view();

        .builder-header {
            position: fixed;
        }

        &.build-automation {
            width: 100%;
        }

        &.builder-view .builder-content,
        .side-panel-header {
            margin-top: var(--automation-header-height);
        }

        .builder-content {
            padding-bottom: $gp * 8; // for footer
        }

        .only-desktop {
            display: none;
        }

        @media($extra-small) {
            .scroll-wrapper {
                margin-bottom: $gp * 5;
            }
        }
    }
</style>

<i18n>
{
    "en-us": {
        "header": "Build automation",
        "templateHeader": "Edit template",
        "publishedMessage": "Disable this published automation to continue editing.",
        "when": "When",
        "then": "Then",
        "stop": "Stop",
        "exit": "Exit",
        "disableButton": "Disable",
        "saveDraft": "Save draft",
        "publish": "Publish",
        "publishEdits": "Publish edits",
        "errorValidating": "There was a problem validating the automation",
        "errorBanner": "Please resolve errors before publishing.",
        "timerDisable": {
            "button": "Deactivate and edit",
            "confirmTitle": "Deactivate this automation to continue editing timers?",
            "confirmMessage": "If you deactivate this automation, it will prevent the automation from running until published again."
        }
    }
}
</i18n>
