import Vue from 'vue';

import amplitude from '@/analytics/amplitude';

function track() {
    amplitude.v1.track(this.getAttribute('track-name'));
}

Vue.directive('track', {
    bind: (el, { value }) => {
        el.setAttribute('track-name', value);
        el.addEventListener('mousedown', track);
    },

    update: (el, { value }) => {
        el.setAttribute('track-name', value);
    },

    unbind: (el) => {
        el.removeEventListener('mousedown', track);
    },
});
