import Vue from 'vue';
import gql from 'graphql-tag';

export const availableNumbersQuery = async ({ areaCode }) => {
    const { data: { availableNumbers } } = await Vue.prototype.$graphql.query({
        query: gql`
            query availableNumbers($areaCode: String!) {
                availableNumbers(areaCode: $areaCode) {
                    areaCode
                    availableNumbers {
                        friendlyName
                        phoneNumber
                        isFromPool
                    }
                    location
                    country
                }
            }
        `,
        variables: { areaCode },
        fetchPolicy: 'no-cache',
    });

    return availableNumbers;
};

export const createKblAccountMutation = async ({ phoneNumber, isFromPool = false }) => {
    const { data: { createKblAccount } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createKblAccount($phoneNumber: String!, $isFromPool: Boolean) {
                createKblAccount(phoneNumber: $phoneNumber, isFromPool: $isFromPool) {
                    phoneNumber
                    success
                }
            }
        `,
        variables: { phoneNumber, isFromPool },
    });

    return createKblAccount;
};

export const kblAccountQuery = async () => {
    const { data: { accountInfo } } = await Vue.prototype.$graphql.query({
        query: gql`
            query accountInfo {
                accountInfo {
                    id
                    hasBeenDeleted
                    status
                    phoneNumber
                    globalCallerId
                    appId
                    conversationApiEnabled
                    createTime
                    messagingServiceSid
                    termsStatus {
                        email
                        latestTimeAccepted
                        casId
                        isAccepted
                        latestVersionAccepted
                    }
                    globalCallerIdVerified
                    updateTime
                    details {
                        callForwardingNumber
                        trustedNumberId
                        callForwardingNumbers {
                            id
                            phoneNumber
                            createTime
                            updateTime
                        }
                    }
                    incomingPhoneNumber
                    trustedNumbers {
                        appId
                        createTime
                        confirmed
                        id
                        updateTime
                        addedByCasId
                        name
                        new
                    }
                }
            }
        `,
    });

    return accountInfo;
};

export const acceptKblTermsAndConditionsMutation = async () => {
    const { data: { acceptKblTermsAndConditions } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation acceptKblTermsAndConditions {
               acceptKblTermsAndConditions
            }
        `,
    });

    return acceptKblTermsAndConditions;
};

export const addTrustedNumberMutation = async ({ phoneNumber }) => {
    const { data: { addTrustedNumber } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation addTrustedNumber($phoneNumber: String!) {
                addTrustedNumber(phoneNumber: $phoneNumber) {
                    appId
                    id
                    updateTime
                    addedByCasId
                    name
                }
            }
        `,
        variables: {
            phoneNumber,
        },
    });

    return addTrustedNumber;
};

export const verifyTrustedNumberMutation = async ({ phoneNumber, code }) => {
    const { data: { verifyTrustedNumber } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation verifyTrustedNumber($phoneNumber: String!, $code: String!) {
               verifyTrustedNumber(phoneNumber: $phoneNumber, code: $code)
            }
        `,
        variables: {
            phoneNumber,
            code,
        },
    });

    return verifyTrustedNumber;
};
