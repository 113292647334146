import Vue from 'vue';
import gql from 'graphql-tag';

export default () => {
    return Vue.prototype.$graphql.query({
        query: QUERY,
        variables: {
            conversationLimit: 20,
        },
        fetchPolicy: 'no-cache',
    });
};

const QUERY = gql`
query boot($conversationLimit: Int) {
    conversations(limit: $conversationLimit) {
        contactId,
        createTime,
        firstName,
        lastMessage,
        lastMessageTime,
        lastName,
        phoneNumber,
        unreadCount,
        updateTime,
    }
    accountInfo {
        id
        hasBeenDeleted
        status
        phoneNumber
        globalCallerId
        appId
        conversationApiEnabled
        createTime
        messagingServiceSid
        termsStatus {
            email
            latestTimeAccepted
            casId
            isAccepted
            latestVersionAccepted
        }
        globalCallerIdVerified
        updateTime
        details {
            callForwardingNumber
            trustedNumberId
            callForwardingNumbers {
                id
                phoneNumber
                createTime
                updateTime
            }
        }
        incomingPhoneNumber
        trustedNumbers {
            appId
            createTime
            confirmed
            id
            updateTime
            addedByCasId
            name
            new
        }
    }
}`;
