import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import { CONTACT_TYPES } from '@/contacts/contacts.constants';

export const trackAddToCampaign = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.CONTACT_ADDED_TO_CAMPAIGN, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.CONTACT_ADDED_TO_CAMPAIGN);
};

export const trackCallMade = (eventSource, type) => {
    amplitude.v2.logEvent(amplitude.v2.events.CALL_MADE, { 'Event Source': eventSource, Type: type });

    intercom.logEvent(intercom.events.CALL_PLACED);
};

export const trackEmailSent = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.EMAIL_SENT, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.EMAIL_SENT);
};

export const trackFileUploaded = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.CONTACT_FILE_UPLOADED, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.CONTACT_FILE_UPLOADED);
};

export const trackInvoicePaid = (eventSource, paymentMethod) => {
    amplitude.v2.logEvent(amplitude.v2.events.INVOICE_PAID, { 'Event Source': eventSource, 'Payment Method': paymentMethod });

    intercom.logEvent(intercom.events.INVOICE_PAID);
};

export const trackInvoiceSent = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.INVOICE_SENT, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.INVOICE_SENT);
};

export const trackNoteAdded = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.NOTE_ADDED, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.NOTE_ADDED);
};

export const trackUnsavedChangedNote = (eventSource, value) => {
    amplitude.v2.logEvent(amplitude.v2.events.NOTE_UNSAVED, { 'Event Source': eventSource, Value: value });

    intercom.logEvent(intercom.events.NOTE_UNSAVED);
};

export const trackNoteEdited = (eventSource, value) => {
    amplitude.v2.logEvent(amplitude.v2.events.NOTE_EDITED, { 'Event Source': eventSource, Value: value });

    intercom.logEvent(intercom.events.NOTE_EDITED);
};

export const trackQuoteSent = (eventSource = 'Unknown') => {
    amplitude.v2.logEvent(amplitude.v2.events.QUOTE_SENT, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.QUOTE_SENT);
};

export const trackTagApplied = (eventSource = 'Unknown') => {
    amplitude.v2.logEvent(amplitude.v2.events.TAG_APPLIED, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.TAG_APPLIED);
};

export const trackTagRemoved = (eventSource = 'Unknown') => {
    amplitude.v2.logEvent(amplitude.v2.events.TAG_REMOVED, { 'Event Source': eventSource });

    intercom.logEvent(intercom.events.TAG_REMOVED);
};

export const trackEditContact = (eventSource = 'Unknown', properties = {}) => {
    amplitude.v2.logEvent(amplitude.v2.events.CONTACT_EDITED, { 'Event Source': eventSource, ...properties });

    intercom.logEvent(intercom.events.CONTACT_EDITED);
};

export const trackContactTypeChanged = ({
    eventSource,
    from,
    to,
    properties = {},
}) => {
    const mapContactType = (type) => {
        const legacyUnassignedValues = ['None', 'Add to', 'Null'];

        if (!type || legacyUnassignedValues.includes(type)) {
            return 'Unassigned';
        }

        if (type === CONTACT_TYPES.CUSTOMER) {
            return 'Client';
        }

        return type;
    };

    amplitude.v2.logEvent(amplitude.v2.events.CONTACT_TYPE_CHANGED, {
        'Event Source': eventSource,
        'Contact Type Prior to Change': mapContactType(from),
        'Contact Type After Change': mapContactType(to),
        ...properties,
    });
};

export const trackCompanyCreated = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.COMPANY_CREATED, { 'Event Source': eventSource });
};

export const trackCompanyEdited = () => {
    amplitude.v2.logEvent(amplitude.v2.events.COMPANY_EDITED);
};

export const trackCompanyDeleted = () => {
    amplitude.v2.logEvent(amplitude.v2.events.COMPANY_DELETED);
};

export const trackContactAdded = (eventSource, properties = {}) => {
    amplitude.v2.logEvent(amplitude.v2.events.CONTACT_ADDED, { 'Event Source': eventSource, ...properties });
};

export const trackContactsBroadcastStarted = (eventSource, contactCount) => {
    amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_BROADCAST_STARTED, {
        'Event Source': eventSource,
        'Contact Count': contactCount,
    });
};

export const trackCustomFieldCreated = ({ recordType, eventSource, fieldType }) => {
    const event = {
        company: amplitude.v2.events.COMPANY_CUSTOM_FIELD_CREATED,
        contact: amplitude.v2.events.CONTACT_CUSTOM_FIELD_CREATED,
    }[recordType];

    amplitude.v2.logEvent(event, {
        'Event Source': eventSource,
        'Custom Field Type': fieldType,
    });
};

export const trackCustomFieldEdited = ({ recordType, eventSource }) => {
    const event = {
        company: amplitude.v2.events.COMPANY_CUSTOM_FIELD_EDITED,
        contact: amplitude.v2.events.CONTACT_CUSTOM_FIELD_EDITED,
    }[recordType];

    amplitude.v2.logEvent(event, {
        'Event Source': eventSource,
    });
};

export const trackCustomFieldDeleted = ({ recordType, eventSource }) => {
    const event = {
        company: amplitude.v2.events.COMPANY_CUSTOM_FIELD_DELETED,
        contact: amplitude.v2.events.CONTACT_CUSTOM_FIELD_DELETED,
    }[recordType];

    amplitude.v2.logEvent(event, {
        'Event Source': eventSource,
    });
};

export const trackContactsExported = (eventSource, exportCount) => {
    amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_EXPORTED, { 'Event Source': eventSource, 'Contact Export Count': exportCount });
};

export const trackCompanyAssociated = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.COMPANY_ASSOCIATED, { 'Event Source': eventSource });
};

export const trackCompanyContactsTransferred = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.COMPANY_CONTACTS_TRANFSERRED, { 'Event Source': eventSource });
};

export const trackFormsViewed = (eventSource, formType) => {
    amplitude.v2.logEvent(amplitude.v2.events.FORM_VIEWED, {
        'Event Source': eventSource,
        'Form Type': formType,
    });
};

export const trackFormsSubmitted = (eventSource, formType) => {
    amplitude.v2.logEvent(amplitude.v2.events.FORM_SUBMITTED, {
        'Event Source': eventSource,
        'Form Type': formType,
    });
};
