<template>
    <ds-text-button
        data-qa="task-mark-complete"
        :loading="loading"
        @click="clickMarkComplete"
    >
        {{ completedActionLabel }}
    </ds-text-button>
</template>

<script>
import { mapState } from 'vuex';
import { TASKS_TASK_UPDATED_EVENT } from '@/shared/constants/events.constants';
import { FF_TASK_OUTCOMES_ENDPOINT_FROM_CORE_TO_KEAP } from '@/shared/constants/featureFlag.constants';

export default {
    props: {
        task: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },

    computed: {
        ...mapState({
            taskOutcomesEndpointEnabled: ({ featureFlags }) => featureFlags[FF_TASK_OUTCOMES_ENDPOINT_FROM_CORE_TO_KEAP],
        }),

        completedActionLabel() {
            if (this.task && this.task.completionDate) {
                return this.$t('markIncomplete');
            }

            return this.$t('markComplete');
        },

        hasOutcomeOptions() {
            return this.task?.outcomeOptions?.length > 0 && this.taskOutcomesEndpointEnabled;
        },

        hasOutcomeSelection() {
            return this.task?.outcomeSelections?.length > 0;
        },
    },

    methods: {
        clickMarkComplete() {
            if (this.hasOutcomeOptions) {
                if (this.hasOutcomeSelection) {
                    this.completeTaskWithOutcomes(this.task);
                } else {
                    const infoMessage = this.$t('errorCompletingTask');

                    this.$error({ message: infoMessage });
                }
            } else {
                this.completeTaskWithNoOutcomes();
            }
        },

        async completeTaskWithNoOutcomes() {
            this.loading = true;
            const eventType = !this.task.completionDate ? 'taskcompleted' : 'taskmarkedincomplete';

            try {
                await this.$store.dispatch('contacts/UPDATE_TASK', {
                    eventType,
                    taskId: this.task.id,
                    updatedTask: {
                        contactId: this.task.contactId,
                        title: this.task.title,
                        dueDate: this.task.dueDate,
                        description: this.task.notes,
                        completed: !this.task.completionDate,
                    },
                });
                this.$emit('marked-complete', eventType === 'taskcompleted');
                this.$bus.$emit(TASKS_TASK_UPDATED_EVENT);
            } catch {
                this.$error({ message: this.$t('errorUpdatingTask') });
            }

            this.loading = false;
        },

        async completeTaskWithOutcomes(task) {
            this.loading = true;

            if (this.contact) {
                this.$store.commit('contacts/SET_CONTACT', this.contact);
            }
            const errorMessage = this.$t('errorUpdatingTask');
            const selectedOutcomeIds = task.outcomeSelections.map(({ value }) => value);
            const eventType = !task.completionDate ? 'taskcompleted' : 'taskmarkedincomplete';

            try {
                await this.$store.dispatch('contacts/UPDATE_TASK_WITH_OUTCOME', {
                    eventType,
                    taskId: task.id,
                    updatedTaskWithOutcome: {
                        contactId: task.contactId,
                        title: task.title,
                        dueDate: task.dueDate,
                        description: task.notes,
                        completed: !task.completionDate,
                        outcomeIds: selectedOutcomeIds,
                    },
                });
                this.$emit('marked-complete', eventType === 'taskcompleted');
            } catch {
                this.$error({ message: errorMessage });
            }

            this.loading = false;
        },
    },
};
</script>
<i18n>
{
    "en-us": {
        "markComplete": "Mark as complete",
        "markIncomplete": "Mark as incomplete",
        "errorUpdatingTask": "Error updating task",
        "errorCompletingTask": "At least one task outcome must be selected"
    }
}
</i18n>
