<template lang="html">
    <div class="reviews-success">
        <h4>{{ conditionalSubtitle }}</h4>
        <h5>{{ $t('message') }}</h5>

        <review-score v-if="reviews" :rating="reviews.rating" />

        <div class="button-row">
            <ds-filled-button data-qa="setup-success-request-review" @click="reviews_requestReview">
                {{ $t(`requestReview`) }}
            </ds-filled-button>

            <review-link-button />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import ReviewScore from './ReviewScore';
import ReviewLinkButton from './ReviewLinkButton';
import reviewsMixin from '../mixins/reviews.mixin';

export default {
    components: {
        ReviewScore,
        ReviewLinkButton,
    },

    mixins: [reviewsMixin],

    data() {
        return {
            nestedModal_showClose: true,
            nestedModal_title: this.$t('title'),
        };
    },

    beforeDestroy() {
        this.$bus.$emit('POP_NESTED_MODAL');
    },

    computed: {
        ...mapState({
            reviews: ({ reviews }) => reviews.reviews,
            userfirstName: ({ auth }) => auth.user.givenName,
        }),

        conditionalSubtitle() {
            const rating = this.reviews?.rating;
            const name = this.userfirstName;

            if (rating >= 4) {
                return this.$t('subTitleGreat', { name });
            }

            if (rating <= 3.9 && rating >= 3.0) {
                return this.$t('subTitleGood', { name });
            }

            return this.$t('subTitleNeutral', { name });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .reviews-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    h4 {
        margin-bottom: $gp;
        text-align: center;
    }

    .review-score {
        margin: $gp 0 $gp * 2;
    }

    .review-link-button {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Take a look at your score",
        "subTitleGreat": "You're off to a great start, {name}",
        "subTitleGood": "You're off to a good start, {firstName}",
        "subTitleNeutral": "Here we go, {firstName}",
        "message": "Keep building your score by getting more reviews.",
        "requestReview": "Request a review"
    }
}
</i18n>
