import {
    createAutomatedSmsAccountMutation,
    updateAutomatedSmsAccountMutation,
    automatedSmsAccountQuery,
} from '@/shared/api/automatedSms.api';

import { FF_KEAP_SMS_BROADCAST } from '@/shared/constants/featureFlag.constants';

export default {
    CREATE_AUTOMATED_SMS_ACCOUNT(context) {
        return createAutomatedSmsAccount(context);
    },

    LOAD_AUTOMATED_SMS_ACCOUNT(context) {
        return loadAutomatedSmsAccount(context);
    },

    UPDATE_AUTOMATED_SMS_ACCOUNT(context, payload) {
        return updateAutomatedSmsAccount(context, payload);
    },
};

const createAutomatedSmsAccount = async ({ commit, rootState }) => {
    if (rootState.featureFlags[FF_KEAP_SMS_BROADCAST]) {
        const { companyName } = rootState.auth.account.companyProfile;
        const userId = rootState.auth.user.casId; // or id

        const automatedSmsAccount = await createAutomatedSmsAccountMutation({ companyName, userId });

        commit('auth/SET_AUTOMATED_SMS_ACCOUNT', automatedSmsAccount, { root: true });
    }
};

const loadAutomatedSmsAccount = async ({ commit, rootState }) => {
    if (rootState.featureFlags[FF_KEAP_SMS_BROADCAST]) {
        const automatedSmsAccount = await automatedSmsAccountQuery();

        commit('auth/SET_AUTOMATED_SMS_ACCOUNT', automatedSmsAccount, { root: true });
    }
};

const updateAutomatedSmsAccount = async ({ commit, rootState }, payload) => {
    if (rootState.featureFlags[FF_KEAP_SMS_BROADCAST]) {
        const automatedSmsAccount = await updateAutomatedSmsAccountMutation(payload);

        commit('auth/SET_AUTOMATED_SMS_ACCOUNT', automatedSmsAccount, { root: true });
    }
};
