<template lang="html">
    <nav>
        <main-nav-links />
    </nav>
</template>

<script>
import MainNavLinks from '@/nav/components/MainNavLinks';

export default {
    components: {
        MainNavLinks,
    },
};
</script>

<style lang="scss" scoped>
    nav {
        position: fixed;
        top: var(--top-nav-height);
        bottom: 0;
        padding: $gp / 2 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: var(--side-nav-width);
        overflow-x: hidden;
        @include overflowY;
        @include border-end(solid 1px $color-gray-200);
        @include transition(max-width);

        @media ($small) {
            display: none;
        }
    }
</style>
