<template>
    <div class="upload-stage">
        <h3 class="secondary-title" data-qa="upload-source-title">
            {{ $t('title') }}
        </h3>

        <div class="integration-import-group">
            <constant-contact-integration-connected
                v-if="isIntegrationConnectedAndSetup"
                data-qa="constant-contact-integration-connected"
                class="constant-contact-integration"
                :integration-blend="integrationBlend"
                :is-loading="loadingBlend"
            />
            <constant-contact-integration-not-connected
                v-else
                data-qa="constant-contact-integration-not-connected"
                class="constant-contact-integration"
                :integration-blend="integrationBlend"
                :is-loading="loadingBlend"
                @setup-complete="handleSetupComplete"
            />

            <div
                v-if="!isIntegrationConnectedAndSetup"
                class="choose-description"
                data-qa="divider-word"
            >
                {{ $t('divider') }}
            </div>

            <ds-outline-button
                v-if="!isIntegrationConnectedAndSetup"
                data-qa="csv-import-btn"
                @click="handleCsvImport"
            >
                {{ $t('csvImport.upload') }}
            </ds-outline-button>
        </div>

        <contact-count-indicator />

        <small>
            {{ $t('helpText.help1') }}

            <a :href="`${articleBaseUrl}/help/import-contacts`" target="_blank" rel="nooponer noreferrer">{{ $t('helpText.helpLink') }}</a>
        </small>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ContactCountIndicator from '@/import/components/csv/ContactCountIndicator';
import ImportCsv from '@/import/components/csv/ImportCsv';
import ConstantContactIntegrationConnected from '@/import/components/constantContact/ConstantContactIntegrationConnected';
import ConstantContactIntegrationNotConnected from '@/import/components/constantContact/ConstantContactIntegrationNotConnected';
import { IMPORT_TYPES } from '@/import/import.constants';
import { BLENDR_TYPES } from '@/shared/constants/integrations.constants';
import amplitude from '@/analytics/amplitude';

export default {
    components: {
        ContactCountIndicator,
        ConstantContactIntegrationConnected,
        ConstantContactIntegrationNotConnected,
    },

    data() {
        return {
            articleBaseUrl: process.env.VUE_APP_KNOWLEDGEOWL_URL,
            integrationBlend: null,
            nestedModal_title: this.$t('nestedModalTitle'),
            loadingBlend: false,
        };
    },

    created() {
        this.loadBlendrTemplates();
    },

    computed: {
        ...mapState({
            blendrTemplates: ({ settings: { integrations } }) => integrations.blendrTemplates,
        }),

        isIntegrationConnected() {
            return Boolean(this.integrationBlend?.adminUrl);
        },

        isIntegrationConnectedAndSetup() {
            return this.isIntegrationConnected && this.integrationBlend.setupComplete;
        },
    },

    methods: {
        async loadBlendrTemplates() {
            this.loadingBlend = true;
            await this.$store.dispatch('settings/LOAD_BLENDR_TEMPLATES');
            this.integrationBlend = this.blendrTemplates.find((t) => t.name?.includes(BLENDR_TYPES.CONSTANT_CONTACT_IMPORT));
            this.loadingBlend = false;
        },

        handleCsvImport() {
            const modalDefaults = {
                interceptClose: this.handleInterceptor,
                modalSize: 'fullscreen',
                showBack: true,
                showRootClose: true,
            };

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                ...modalDefaults,
                component: ImportCsv,
                data: IMPORT_TYPES.CONSTANT_CONTACT,
            });
        },

        async handleSetupComplete() {
            this.loadingBlend = true;

            // Reload blendr templates to grab the new admin URL and guid for the connected blend
            await this.$store.dispatch('settings/LOAD_BLENDR_TEMPLATES');

            this.integrationBlend = this.blendrTemplates.find((t) => t.name?.includes(BLENDR_TYPES.CONSTANT_CONTACT_IMPORT));
            this.loadingBlend = false;

            amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTED, {
                'Event Source': 'Contact Import',
                Integration: this.integrationBlend.name,
                'Connected Integration': this.integrationBlend.name,
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .upload-stage {
        @include centeredContainer;
        text-align: center;
        max-width: 47 * $gp;

        @media ($small) {
            max-width: 20 * $gp;
        }
    }

    h2 {
        margin-bottom: $gp;
        text-align: center;
    }

    .choose-description {
        color: $color-gray-800;
        margin-bottom: 2 * $gp;
    }

    .integration-import-group {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .constant-contact-integration {
        width: px-to-rem(500);
        grid-column: 2 / 3;
        margin-bottom: 2 * $gp;

        @media ($small) {
            width: px-to-rem(330);
        }
    }

    .constant-contact-integration-connected {
        grid-column: 2 / -1;
    }

    .constant-contact-integration-not-connected {
        width: px-to-rem(288);
        grid-column: 2 / 3;
    }

    .integration-icon {
        color: $color-green-300;
        --icon-size: #{2.5 * $gp};
        width: px-to-rem(85);
    }

    .integration-description {
        padding: $gp 0;
        font-size: $font-size-xs;
        font-weight: $font-weight-light;
    }

    .secondary-title {
        font-size: $font-size-jumbo;
        text-align: center;

        @media ($medium) {
            font-size: $font-size-mega;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Add Constant Contact contacts to Keap",
        "nestedModalTitle": "Import from Constant Contact",
        "divider": "or",
        "csvImport": {
            "upload": "Upload a spreadsheet of contacts"
        },
        "helpText": {
            "help1": "Have questions? Visit our ",
            "helpLink": "Help Center"
        }
    }
}
</i18n>
