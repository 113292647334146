import axios from 'axios';
import gql from 'graphql-tag';
import Vue from 'vue';
import sentry from '@/analytics/sentry';

export default {
    LOAD_AGGREGATED_ALL_SALES_DATA(context, payload) {
        return loadAggregatedAllSalesData(context, payload);
    },

    LOAD_ALL_SALES(context, payload) {
        return loadAllSales(context, payload);
    },

    LOAD_AGGREGATED_EMAIL_ENGAGEMENT_DATA(context, payload) {
        return loadAggregatedEmailEngagementData(context, payload);
    },

    LOAD_EMAIL_ENGAGEMENT_EMAILS_DATA(context, payload) {
        return loadEmailEngagementEmailsData(context, payload);
    },

    LOAD_EMAIL_ENGAGEMENT_CONTACTS_DATA(context, payload) {
        return loadEmailEngagementContactsData(context, payload);
    },

    DOWNLOAD_ALL_SALES(context, payload) {
        return downloadAllSales(context, payload);
    },
};

const loadAggregatedAllSalesData = ({ commit }, payload) => {
    return requestAggregatedData({ commit }, payload)
        .then(({ data: { reportData } }) => {
            commit('SET_AGGREGATED_ALL_SALES_DATA', reportData);

            return reportData;
        })
        .catch((e) => {
            sentry.log('Load report aggregated all sales data failed', { message: e.message });

            return Promise.reject();
        });
};

const loadAllSales = ({ commit, state }, {
    ascending, endDateUTC, sortType, startDateUTC, limit, offset, search,
}) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query reportListData(
                $ascending: Boolean,
                $endDateUTC: String,
                $sortType: String,
                $startDateUTC: String,
                $limit: Int,
                $offset: Int,
                ${search ? '$search: String,' : ''},
            ) {
                reportListData(
                    ascending: $ascending
                    endDateUTC: $endDateUTC,
                    sortType: $sortType,
                    startDateUTC: $startDateUTC,
                    limit: $limit,
                    offset: $offset,
                    ${search ? 'search: $search,' : ''},
                    ) {
                    ascending,
                    endDateUTC,
                    sortType,
                    startDateUTC,
                    data {
                        id,
                        firstName,
                        lastName,
                        saleDate,
                        paymentType,
                        amountPaid
                        }
                    }
                }
            `,
        variables: {
            ascending,
            endDateUTC,
            sortType,
            startDateUTC,
            limit,
            offset,
            search,
        },
        fetchPolicy: 'no-cache',
    })
        .then(({ data: { reportListData } }) => {
            const existing = state.allSales.data;

            const merged = existing.concat(reportListData.data);

            commit('SET_ALL_SALES', merged);

            return reportListData;
        })
        .catch((e) => {
            sentry.log('Load all sales report failed', { message: e.message });

            return Promise.reject();
        });
};

const requestAggregatedData = ({ commit }, payload) => {
    commit('SET_IS_LOADING_CHART', true);

    return Vue.prototype.$graphql.query({
        query: gql`
            query reportData(
                $startDateUTC: String,
                $endDateUTC: String,
                $dataTypes: [String],
                $dataGroupingType: String,
                $dataGroupingAmount: Int,
            ) {
                reportData(
                    startDateUTC: $startDateUTC,
                    endDateUTC: $endDateUTC,
                    dataTypes: $dataTypes,
                    dataGroupingType: $dataGroupingType,
                    dataGroupingAmount: $dataGroupingAmount,
                    ) {
                    startDateUTC,
                    endDateUTC,
                    data {
                        dataType,
                        valueType,
                        values {
                            dateUTC,
                            value
                        }
                    }
                }
            }
        `,
        variables: {
            startDateUTC: payload.startDateUTC,
            endDateUTC: payload.endDateUTC,
            dataTypes: payload.dataTypes,
            dataGroupingType: payload.dataGrouping.type,
            dataGroupingAmount: payload.dataGrouping.amount,
        },
        fetchPolicy: 'no-cache',
    })
        .then((responseData) => {
            commit('SET_IS_LOADING_CHART', false);

            return responseData;
        });
};

const loadAggregatedEmailEngagementData = ({ commit }, payload) => {
    return requestAggregatedData({ commit }, payload)
        .then(({ data: { reportData } }) => {
            commit('SET_AGGREGATED_EMAIL_ENGAGEMENT_DATA', reportData);

            return reportData;
        })
        .catch((e) => {
            sentry.log('Load aggregated email report failed', { message: e.message });

            return Promise.reject();
        });
};

const loadEmailEngagementEmailsData = ({ commit, state }, payload) => {
    // TODO: Update after implementing BFF layer to support this.
    return Vue.prototype.$graphql.query({
        query: gql`
            query emailEngagementReportEmailsListData(
                $ascending: Boolean,
                $endDateUTC: String,
                $sortType: String,
                $startDateUTC: String,
                $limit: Int,
                $offset: Int,
            ) {
                emailEngagementReportEmailsListData(
                    ascending: $ascending
                    endDateUTC: $endDateUTC,
                    sortType: $sortType,
                    startDateUTC: $startDateUTC,
                    limit: $limit,
                    offset: $offset,
                    ) {
                    ascending,
                    endDateUTC,
                    sortType,
                    startDateUTC,
                    data {
                        id,
                        emailName,
                        openRate,
                        clickRate,
                        optOutRate
                        }
                    }
                }
            `,
        variables: {
            ascending: payload.ascending,
            endDateUTC: payload.endDateUTC,
            sortType: payload.sortType,
            startDateUTC: payload.startDateUTC,
            limit: payload.limit,
            offset: payload.offset,
        },
        fetchPolicy: 'no-cache',
    })
        .then(({ data: { emailEngagementReportEmailsListData } }) => {
            const existing = state.emailEngagement.emails.data;

            const merged = existing.concat(emailEngagementReportEmailsListData.data);

            commit('SET_EMAIL_ENGAGEMENT_EMAILS_DATA', merged);

            return emailEngagementReportEmailsListData;
        })
        .catch((e) => {
            sentry.log('Load email report failed', { message: e.message });

            return Promise.reject();
        });
};

const loadEmailEngagementContactsData = ({ commit, state }, payload) => {
    // TODO: Update after implementing BFF layer to support this.
    return Vue.prototype.$graphql.query({
        query: gql`
            query emailEngagementReportContactsListData(
                $ascending: Boolean,
                $endDateUTC: String,
                $sortType: String,
                $startDateUTC: String,
                $limit: Int,
                $offset: Int,
            ) {
                emailEngagementReportContactsListData(
                    ascending: $ascending
                    endDateUTC: $endDateUTC,
                    sortType: $sortType,
                    startDateUTC: $startDateUTC,
                    limit: $limit,
                    offset: $offset,
                    ) {
                    ascending,
                    endDateUTC,
                    sortType,
                    startDateUTC,
                    data {
                        contactId,
                        contactName,
                        openRate,
                        clickRate
                        }
                    }
                }
            `,
        variables: {
            ascending: payload.ascending,
            endDateUTC: payload.endDateUTC,
            sortType: payload.sortType,
            startDateUTC: payload.startDateUTC,
            limit: payload.limit,
            offset: payload.offset,
        },
        fetchPolicy: 'no-cache',
    })
        .then(({ data: { emailEngagementReportContactsListData } }) => {
            const existing = state.emailEngagement.contactEngagement.data;

            const merged = existing.concat(emailEngagementReportContactsListData.data);

            commit('SET_EMAIL_ENGAGEMENT_CONTACTS_DATA', merged);

            return emailEngagementReportContactsListData;
        })
        .catch((e) => {
            sentry.log('Load email contacts report failed', { message: e.message });

            return Promise.reject();
        });
};

const downloadAllSales = ({ commit }, { startDateUtc, endDateUtc }) => {
    commit('SET_REPORT_IS_DOWNLOADING', true);

    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/reports/sales/details/download?startDateUTC=${startDateUtc}&endDateUTC=${endDateUtc}&zipFile=false`)
        .then(({ data }) => {
            commit('SET_REPORT_IS_DOWNLOADING', false);

            return Promise.resolve(data);
        })
        .catch((e) => {
            commit('SET_REPORT_IS_DOWNLOADING', false);
            sentry.log('Report download failed', { message: e.message });

            return Promise.reject();
        });
};
