export const CALL_DEVICE_STATUSES = {
    REGISTERING: 'registering',
    REGISTERED: 'registered',
    UNREGISTERED: 'unregistered',
    DESTROYED: 'destroyed',
    ERROR: 'error',
};

export const CALL_STATUSES = {
    PENDING: 'pending',
    INCOMING: 'incoming',
    ACCEPTED: 'accepted',
    CANCELLED: 'cancelled',
    DISCONNECTING: 'disconnecting',
    DISCONNECTED: 'disconnected',
    REJECTED: 'rejected',
    ERROR: 'error',
};
