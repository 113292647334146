<template>
    <div>
        <marketing-sites-page
            :loading="loading"
            :no-more-results="noMoreResults"
        />
        <marketing-site-provider
            v-if="isModalOpen"
            :is-open="isModalOpen"
            :is-loading="isLoadingSite"
            :load-site="site"
            @cancel-site="closeSiteModal"
        />
        <choose-site-name-modal
            :is-open="chooseNameModalOptions.isOpen"
            :initial-name="chooseNameModalOptions.initialName"
            :title="chooseNameModalOptions.title"
            :helper-text="chooseNameModalOptions.helperText"
            :name-input-label="chooseNameModalOptions.nameInputLabel"
            @close="handleCancelChooseName"
            @rename="handleChooseName"
        />
    </div>
</template>

<script>

import Vue from 'vue';
import MarketingSiteProvider from '@/marketingSites/provider/MarketingSiteProvider';
import MarketingSitesPage from '@/marketingSites/pages/MarketingSitesPage';
import { buildMarketingSiteUrl, siteIdOf } from '@/marketingSites/components/site/site.utils';
import ChooseSiteNameModal from '@/marketingSites/components/modal/ChooseSiteNameModal';
import {
    deleteMarketingPage,
    deleteSite,
    getMarketingSite,
    listSites,
    updateMarketingSite,
} from '@/marketingSites/api/sites-datasource-graphql';
import unionWith from 'lodash.unionwith';
import isEqual from 'lodash.isequal';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';

import {
    MARKETING_SITES_CREATE_ROUTE,
    MARKETING_SITES_EDIT_ROUTE,
    MARKETING_SITES_ROUTE,
} from '@/shared/constants/features.constants';
import { MarketingSiteTemplateSource } from '@/marketingSites/provider/site-provider.utils';

export default {
    components: {
        ChooseSiteNameModal,
        MarketingSitesPage,
        MarketingSiteProvider,
    },

    inject: ['$createSite'],

    provide() {
        return {
            $sites: {
                refreshSites: this.refreshSites,
                clearData: this.clearData,
                showCreateSite: this.showCreateSite,
                deleteSite: this.deleteSite,
                copySiteUrl: this.copySiteUrl,
                openSitePreview: this.openSitePreview,
                focusSite: this.focusSite,
                showEditSite: this.showEditSite,
                copySite: this.copySite,
                showRenameSite: this.showRenameSite,
                saveSite: this.saveSite,
                createAndLoadSiteFromTemplate: this.createAndLoadSiteFromTemplate,
                findSite: this.findSite,
                chooseName: this.chooseName,
                closeChooseNameModal: this.closeChooseNameModal,
                getMarketingSiteList: this.getMarketingSiteList,
            },
            $focusedSite: this.site,
            sites: this.sites,
        };
    },

    props: {
        appId: String,
        isEnabled: Boolean,
    },

    data() {
        return {
            chooseNameModalOptions: {},
            selectedTemplate: {},
            isModalOpen: false,
            noMoreResults: false,
            loading: true,
            site: {},
            isLoadingSite: false,
            sites: [],
            currentFilters: {},
        };
    },

    mounted() {
        if (this.$route.name === MARKETING_SITES_CREATE_ROUTE) {
            this.openSiteModal();
        }

        if (this.$route.name === MARKETING_SITES_EDIT_ROUTE) {
            const { id } = this.$route.params;

            this.showEditSite(id);
        }
    },

    methods: {
        clearData() {
            this.sites.splice(0);
        },

        async syncStore(newData) {
            if (!newData) {
                newData = await listSites(this?.currentFilters);
            }

            const loaded = unionWith(newData?.data, this.sites, (a, b) => {
                return a.id === b.id;
            });

            this.noMoreResults = isEqual(this.sites, loaded);

            if (!this.noMoreResults) {
                this.clearData();

                for (let i = 0; i < loaded.length; i++) {
                    Vue.set(this.sites, i, loaded[i]);
                }
            }
        },

        /**
         * Initiates the process of creating a new site from a template, or updating an existing site by selecting a
         * new template.  From a UI standpoint, this method will prompt the user for a name, and then once the site
         * is created, the newly created site will be opened up in the site editor.
         *
         * The value for `template` can be either an existing site or an unlayer template, but it must be match the
         * expected format based on the `templateType` parameter and must not be null or undefined
         *
         * @param {object} sourceTemplate The template used to create the new site
         * @param {string} templateType What type of template is being provided: unlayer, site, global, or blank
         * @return {Promise<void>}
         */
        async createAndLoadSiteFromTemplate(sourceTemplate, templateType) {
            const template = MarketingSiteTemplateSource({ sourceTemplate, templateType });
            const { name } = template;

            if (this.site.id && this.site.status !== SITE_STATUS.SAVED) {
                try {
                    await this.$confirm({
                        optionConfirmButtonLabel: this.$t('changeTemplateConfirm'),
                        optionCancel: this.$t('changeTemplateCancel'),
                        destructive: true,
                        optionTitle: this.$t('changeTemplateTitle'),
                        optionMessage: this.$t('changeTemplateMessage'),
                    });

                    // // Delete all pages
                    await Promise.all(this.site.pages.map(({ id }) => deleteMarketingPage(id)));

                    this.$createSite.clonePagesIntoExistingSite(this.site, template);

                    const refreshedSite = await getMarketingSite(this.site.id);

                    this.site = refreshedSite;

                    await this.syncStore();
                } catch (e) {
                    // They didn't confirm the change, return to the select template page
                }
            } else {
                this.selectedTemplate = template;

                const siteName = await this.chooseName(name);

                if (!siteName) {
                    return;
                }

                try {
                    const newSite = await this.$createSite.createNewSite(this.selectedTemplate, siteName);

                    this.closeChooseNameModal();
                    this.focusSite(newSite);
                    await this.syncStore();
                } catch (e) {
                    this.$error({ message: this.$t('createSiteErrorMessage') });
                } finally {
                    this.selectedTemplate = {};
                    this.closeChooseNameModal();
                }
            }
        },

        /**
         * Opens modal window for choosing a name, and returns a promise that resolves to the chosen name, or undefined
         * if the user cancelled the operation without enter a name.
         *
         * @param initialName the initial value for the input
         * @param title
         * @param nameInputLabel
         * @param helperText
         * @return {Promise<string|undefined>} The new name, or undefined if the user cancelled the modal without entering a name
         */
        chooseName(initialName, title = this.$t('nameModalTitle'), nameInputLabel = this.$t('nameInputLabel'), helperText = this.$t('helperText')) {
            this.chooseNameModalOptions?.resolve?.call();

            return new Promise((resolve) => {
                this.chooseNameModalOptions = {
                    isOpen: true,
                    initialName,
                    resolve,
                    title,
                    nameInputLabel,
                    helperText,
                };
            });
        },

        /**
         * Resolves the chooseName promise with the name that was entered in the modal.
         * @param name
         */
        handleChooseName(name) {
            this.chooseNameModalOptions?.resolve?.call(this, name);
        },

        /**
         * Resolves the chooseName promise with undefined to indicate that the operation was cancelled.
         */
        handleCancelChooseName() {
            if (this.chooseNameModalOptions?.resolve) {
                this.chooseNameModalOptions?.resolve?.call(this);
                this.selectedTemplate = {};
                this.chooseNameModalOptions = {};
            }
        },

        /**
         * Closes the choose name modal.  This gives the operation the control over how long the modal
         * and spinning button is displayed and helps avoid unnecessary lag between the choose name operation,
         * and whatever comes after.
         */
        closeChooseNameModal() {
            this.chooseNameModalOptions = {};
        },

        getMarketingSiteList() {
            return this.sites ?? [];
        },

        async openSitePreview(siteOrId) {
            const siteId = siteIdOf(siteOrId);
            const site = await getMarketingSite(siteId);
            const linkUrl = buildMarketingSiteUrl(this.appId, site?.slug);

            if (!linkUrl) {
                this.$toast({
                    message: this.$t('errorOpeningPreview'),
                });
            } else {
                window.open(linkUrl, 'site-preview');
            }
        },

        async refreshSites(filters) {
            this.loading = true;

            if (filters) {
                this.currentFilters = filters;
            }

            try {
                const loaded = await listSites(this?.currentFilters);

                this.loading = false;

                return await this.syncStore(loaded);
            } catch (e) {
                this.$error({ message: this.$t('loadSitesErrorMessage') });
            }

            return this.sites;
        },

        async showCreateSite() {
            this.site = {};
            this.selectedTemplate = {};
            this.isModalOpen = true;
        },

        openSiteModal() {
            if (!this.isModalOpen) {
                this.isModalOpen = true;
            }
        },

        async showEditSite(siteOrId) {
            const siteId = siteIdOf(siteOrId);

            this.isLoadingSite = true;
            this.openSiteModal();

            try {
                const loadedSite = await getMarketingSite(siteId);

                await this.focusSite(loadedSite);
            } catch (e) {
                this.closeSiteModal();
            } finally {
                this.isLoadingSite = false;
            }
        },

        findSite(siteOrId) {
            const siteId = siteIdOf(siteOrId);

            return this.sites?.find((s) => s.id === siteId);
        },

        /**
         * Updates a MarketingSite, including the main page html
         * @param site The site details to be updated
         * @param {string|undefined|null} html The main page HTML that should be used to update the MarketingSite/TemplateGroup
         * @return {Promise<MarketingSite>}
         */
        async saveSite(site, html = null) {
            const {
                id, slug, name, mainPageId, pages = [],
            } = site;

            // in-place update for the one site, to avoid stale data while waiting for server
            const idx = this.sites.findIndex((s) => s.id === id);

            if (idx > -1) {
                Vue.set(this.sites, idx, {
                    ...this.sites[idx],
                    slug,
                    name,
                    mainPageId,
                });
            }

            let saved;

            try {
                saved = await updateMarketingSite(site.id, {
                    slug, name, mainPageId, pages, html,
                });
                await this.syncStore();

                return saved;
            } catch (e) {
                this.$error({ message: this.$t('saveSiteError') });
                throw e;
            }
        },

        async refreshSite() {
            await this.syncStore();
        },

        focusSite(site) {
            if (site) {
                const {
                    id, pages = [], name = this.$t('marketingSites.defaultSiteName'), ...other
                } = site;

                if (pages.length === 0) {
                    return;
                }

                this.openSiteModal();

                this.site = {
                    ...other, id, pages, name,
                };
            } else {
                this.closeSiteModal();
            }
        },

        async copySiteUrl(siteOrId) {
            const site = this.findSite(siteOrId);
            const publicUrl = buildMarketingSiteUrl(this.appId, site?.slug);

            if (!publicUrl) {
                this.$error({ message: this.$t('copySiteUrlMessage') });
            } else {
                await this.$copyText(publicUrl);
                this.$toast({ message: this.$t('urlCopiedMessage') });
            }
        },

        async deleteSite(siteId) {
            const deletingFocused = this.site?.id === siteId;

            try {
                await this.$confirm({
                    optionTitle: this.$t('deleteConfirmTitle'),
                    optionMessage: this.$t('deleteConfirmMessage'),
                });
            } catch (error) {
                return;
            }

            try {
                await deleteSite(siteId);
            } catch (error) {
                this.$error({ message: this.$t('deleteErrorMessage') });

                return;
            }

            if (deletingFocused) {
                this.closeSiteModal();
            }

            const index = this.sites.findIndex((site) => site.id === siteId);

            this.sites.splice(index, 1);
        },

        async copySite(siteOrId) {
            const sourceId = siteIdOf(siteOrId);
            let sourceSite = this.findSite(sourceId);

            if (!sourceSite) {
                sourceSite = getMarketingSite(sourceId);
            }
            const { name: oldName } = sourceSite;
            const name = await this.chooseName(this.$t('copyOf', { name: oldName }));

            try {
                if (name) {
                    this.closeChooseNameModal();
                    const clonedSite = await this.$createSite.cloneSiteFromSite(sourceSite, name);

                    this.focusSite(clonedSite);
                    await this.syncStore();
                    this.selectedTemplate = {};
                }
            } catch (err) {
                this.$error({ message: this.$t('errorCopyingSite') });
            } finally {
                this.chooseNameModalOptions = {};
            }
        },

        async showRenameSite(siteOrId) {
            const site = this.findSite(siteOrId);
            const name = await this.chooseName(site?.name);

            this.closeChooseNameModal();

            if (!name) {
                return site;
            }

            return this.saveSite({
                ...site,
                name,
            });
        },

        closeSiteModal() {
            if (this.$route.name === MARKETING_SITES_CREATE_ROUTE || this.$route.name === MARKETING_SITES_EDIT_ROUTE) {
                this.$router.replace({ name: MARKETING_SITES_ROUTE });
            }

            this.isModalOpen = false;
            this.site = null;
        },
    },
};

</script>

<i18n>
{
    "en-us": {
        "changeTemplateTitle": "Are you sure you want to choose a new template?",
        "changeTemplateMessage": "You are about to change to a new template. Any changes you have saved will be deleted. ",
        "changeTemplateCancel": "Go back",
        "changeTemplateConfirm": "Change template",
        "errorOpeningPreview": "There was an error opening the preview",
        "errorCopyingSite": "There was an error copying this site",
        "notImplemented": "This is not implemented yet",
        "saveSiteError": "There was a problem saving this page",
        "pageTitle": "Landing Pages",
        "createMarketingSite": "Create a landing page",
        "urlCopiedMessage": "Your url was copied to your clipboard",
        "copySiteUrlMessage": "There was a problem copying this URL",
        "deleteConfirmTitle": "Delete Landing Page",
        "deleteConfirmMessage": "Are you sure you want to delete this landing page?",
        "deleteErrorMessage": "There was a problem deleting",
        "loadSitesErrorMessage": "There was a problem loading your landing pages",
        "createSiteErrorMessage": "There was a problem creating your landing page",
        "nameInputLabel": "Page name",
        "helperText": "This will appear in the browser title bar and landing page url.",
        "nameModalTitle": "Name your landing page",
        "copyOf": "Copy of {name}"
    }
}
</i18n>
