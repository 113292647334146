<template lang="html">
    <controlled-modal
        :is-open="open"
        :title="$t('title')"
        show-close
        @close="close"
    >
        <i18n path="message" tag="p">
            <template #boldedText>
                <strong>{{ $t('allAppointments') }}</strong>
            </template>
        </i18n>

        <p>
            <em>{{ $t('availabilityWarning') }}</em>
        </p>

        <i18n tag="p" class="note" path="note">
            <template #emphasizedText>
                <em>{{ $t('their') }}</em>
            </template>
        </i18n>

        <ds-multiselect
            v-model="selectedTimezone"
            :placeholder="$t('placeholder')"
            :options="timeZones"
            data-qa="timezone-multiselect"
            required
            searchable
        />

        <div class="button-row">
            <ds-filled-button
                :disabled="!selectedTimezone"
                :loading="loading"
                data-qa="save-timezone"
                @click="saveTimezone"
            >
                {{ $t('global.save') }}
            </ds-filled-button>

            <ds-text-button @click="close">
                {{ $t('global.cancel') }}
            </ds-text-button>
        </div>
    </controlled-modal>
</template>

<script>
import { mapState } from 'vuex';
import ControlledModal from '@/shared/components/ControlledModal';
import moment from 'moment';

export default {
    components: {
        ControlledModal,
    },

    props: {
        open: Boolean,
        provider: Object,
    },

    data() {
        return {
            selectedTimezone: null,
            loading: false,
        };
    },

    watch: {
        open(value) {
            if (value) {
                this.setTimezone();
            }
        },
    },

    computed: {
        ...mapState({
            userId: ({ auth }) => auth.user.casId,
        }),

        timeZones() {
            const timeZones = moment.tz.names().map((timeZone) => {
                const { name } = moment.tz.zone(timeZone);

                return {
                    label: `(GMT${moment.tz(timeZone).format('Z')}) ${name}`,
                    value: name,
                };
            });

            const browserTimezone = moment.tz.guess();

            return [
                {
                    label: `(GMT${moment.tz(browserTimezone).format('Z')}) ${browserTimezone}`,
                    value: browserTimezone,
                },
                ...timeZones,
            ];
        },
    },

    methods: {
        setTimezone() {
            this.selectedTimezone = this.provider?.timeZone
                ? this.timeZones.find(({ value }) => value === this.provider.timeZone)
                : null;
        },

        close() {
            this.$emit('close');
        },

        async saveTimezone() {
            try {
                const { selectedTimezone: { value: timeZone }, userId } = this;

                this.loading = true;

                await this.$store.dispatch('calendar/SAVE_PROVIDER_INTEGRATION', { userId, timeZone });

                this.loading = false;
                this.$toast({ message: this.$t('success', { timeZone }) });
                this.close();
            } catch (e) {
                this.loading = false;
                this.$error({ message: this.$t('errors.saving') });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.note {
    color: $color-gray-800;
    margin-bottom: $gp * 2;
}
</style>

<i18n>
{
    "en-us": {
        "title": "Select your appointments timezone",
        "message": "The selection you make here will apply to {boldedText}",
        "allAppointments": "all of your appointment types",
        "availabilityWarning": "Please verify your hours of availability in each appointment type",
        "note": "NOTE: Your invitees will always see your availability in {emphasizedText} timezone",
        "their": "their",
        "success": "Timezone changed to {timeZone}",
        "placeholder": "Your timezone"
    }
}
</i18n>
