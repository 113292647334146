import Vue from 'vue';
import gql from 'graphql-tag';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';

export const reset = '';


export async function getUnlayerSiteTemplate(templateId) {
    const { data: { stockFunnelTemplate } } = await Vue.prototype.$graphql.query({
        query: gql`
            query StockFunnelTemplate($templateId: ID!) {
                stockFunnelTemplate(templateId: $templateId) {
                    id
                    name
                    previewUrl
                    design
                    pages
                }
            }
        `,
        variables: { templateId },
    });

    return stockFunnelTemplate;
}

export async function listUnlayerSiteTemplates({ pageToken, pageSize }) {
    const { data: { stockFunnelTemplates } } = await Vue.prototype.$graphql.query({
        query: gql`
            query StockFunnelTemplates($pageToken: String, $pageSize: Int!) {
                stockFunnelTemplates(pageToken: $pageToken, pageSize: $pageSize) {
                    nextPageToken
                    templates {
                        id
                        name
                        previewUrl
                    }
                }
            }
        `,
        variables: {
            pageToken,
            pageSize,
        },
    });

    return stockFunnelTemplates;
}

export async function getGlobalTemplate(templateId) {
    const { data: { marketingGlobalSite } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingGlobalSite($templateId: ID!) {
                marketingGlobalSite(templateId: $templateId) {
                    id
                    name
                    slug
                    html
                    mainPageId
                    status
                    updatedTime
                    pages {
                        id
                        slug
                        name
                        groupOrder
                        seoDescription
                        siteId
                    }
                }
            }
        `,
        variables: {
            templateId,
        },
    });

    return marketingGlobalSite;
}

export async function listGlobalTemplates({
    pageToken, industry, usage, media,
}) {
    const { data: { marketingGlobalSites } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingGlobalSites($pageToken: String, $industry: String, $usage: String, $media: [String]) {
                marketingGlobalSites(pageToken: $pageToken, industry: $industry, usage: $usage, media: $media) {
                    nextPageToken
                    templates {
                        id
                        name
                        html
                        previewUrl
                    }
                }
            }
        `,
        variables: {
            pageToken,
            industry,
            usage,
            media,
        },
    });

    return marketingGlobalSites;
}

/**
 * Creates a new marketing site.
 *
 * @param {CreateMarketingSiteInput} payload
 * @returns Promise<MarketingSite>
 */
export async function createSite(payload) {
    const { data: { createMarketingSite } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createMarketingSite($payload: CreateMarketingSiteInput!) {
                createMarketingSite(payload: $payload) {
                    id
                    slug
                    name
                    isDeleted
                    mainPageId
                    createdDate
                    updatedDate
                    publishedDate
                    status
                    pages {
                        id
                        name
                        slug
                        groupOrder
                        seoDescription
                        content {
                            chunks
                            design
                            html
                        }
                        isDeleted
                        siteId
                    }
                }
            }
        `,
        variables: {
            payload,
        },
    });

    const {
        id, slug, name,
    } = createMarketingSite;

    const { mainPageId, sortedPages } = sortPagesForCreate(payload.pages, createMarketingSite);

    const { data: { updateMarketingSite: result } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateMarketingSite($siteId: String!, $payload: UpdateMarketingSiteInput!) {
                updateMarketingSite(siteId: $siteId, payload: $payload) {
                    id
                    slug
                    name
                    isDeleted
                    mainPageId
                    createdDate
                    updatedDate
                    publishedDate
                }
            }
        `,
        variables: {
            siteId: id,
            payload: { slug, name, mainPageId },
        },
    });

    return {
        ...result,
        pages: sortedPages,
    };
}

/**
 * Fetches a MarketingPage record from the backend
 *
 * @param {string} pageId The primary key for the page you are requesting
 * @returns Promise<MarketingPage>
 */
export async function getMarketingPage(pageId) {
    const { data: { marketingPage } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingPage($pageId: String!) {
                marketingPage(pageId: $pageId) {
                    id
                    slug
                    seoDescription
                    groupOrder
                    name
                    isDeleted
                    content {
                        chunks
                        design
                        html
                    }
                    siteId
                }
            }
        `,
        variables: {
            pageId,
        },
        fetchPolicy: 'no-cache',
    });

    return marketingPage;
}


/**
 * Fetches a GlobalTemplatePage record from the backend
 *
 * @param {ID!} pageId The primary key for the page you are requesting
 * @returns Promise<GlobalTemplatePage>
 */
export async function getGlobalTemplatePage(pageId) {
    const { data: { marketingGlobalPage } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingGlobalPage($pageId: ID!) {
                marketingGlobalPage(pageId: $pageId) {
                    id
                    slug
                    seoDescription
                    groupOrder
                    name
                    content {
                        chunks
                        design
                        html
                    }
                    siteId
                }
            }
        `,
        variables: {
            pageId,
        },
        fetchPolicy: 'no-cache',
    });

    return marketingGlobalPage;
}

/**
 * List existing marketing sites.
 *
 * @param {MarketingSiteListFiltersInput} args
 * @returns Promise<MarketingSiteSummaries>
 */
export async function listSites(args) {
    const { data: { marketingSites } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingSites($args: MarketingSiteListFiltersInput) {
                marketingSites(args: $args) {
                    data {
                        id
                        name
                        createdDate
                        updatedDate
                        publishedDate
                        slug
                        status
                    }
                }
            }
        `,
        variables: {
            args: {
                page: args?.page ?? 0,
                name: args?.name ?? '',
                statuses: args?.status ?? [SITE_STATUS.PUBLISHED, SITE_STATUS.PUBLISHED_CHANGES, SITE_STATUS.DRAFT],
            },
        },
        fetchPolicy: 'no-cache',
    });

    const { data = [], ...other } = marketingSites;

    return { data: data.map(sortPagesForSite), ...other };
}

/**
 * List saved templates.
 *
 * @param {MarketingSiteListFiltersInput} args
 * @returns Promise<MarketingSiteSummaries>
 */
export async function getSavedTemplates(args) {
    const { data: { marketingSites } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingSites($args: MarketingSiteListFiltersInput) {
                marketingSites(args: $args) {
                    data {
                        id
                        name
                        createdDate
                        updatedDate
                        publishedDate
                        slug
                        status
                        html
                    },
                    nextPageToken,
                }
            }
        `,
        variables: {
            args: {
                page: args?.page,
                name: args?.name,
                statuses: [args?.status],
            },
        },
        fetchPolicy: 'no-cache',
    });

    const { data = [], nextPageToken } = marketingSites;

    return { templates: data.map(sortPagesForSite), nextPageToken };
}

/**
 * Retrieves an existing marketing site.
 *
 * @param {string} siteId
 * @returns Promise<MarketingSite>
 */
export async function getMarketingSite(siteId) {
    const { data: { marketingSite } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingSite($siteId: String!) {
                marketingSite(siteId: $siteId) {
                    id
                    name
                    slug
                    mainPageId
                    status
                    createdDate
                    updatedDate
                    publishedDate
                    isDeleted
                    pages {
                        id
                        slug
                        name
                        isDeleted
                        groupOrder
                        seoDescription
                        content {
                            design
                            html
                            chunks
                        }
                        siteId
                    }
                }
            }
        `,
        variables: {
            siteId,
        },
        fetchPolicy: 'no-cache',
    });

    return sortPagesForSite(marketingSite);
}

/**
 * Updates an existing MarketingSite
 *
 * @param {string} siteId Primary key of site being updated
 * @param {UpdateMarketingSiteInput} payload
 * @returns Promise<MarketingSite>
 */
export async function updateMarketingSite(siteId, payload) {
    const { data: { updateMarketingSite: result } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateMarketingSite($siteId: String!, $payload: UpdateMarketingSiteInput!) {
                updateMarketingSite(siteId: $siteId, payload: $payload) {
                    id
                    name
                    slug
                    mainPageId
                    status
                    createdDate
                    updatedDate
                    publishedDate
                    isDeleted
                }

            }
        `,
        variables: {
            siteId,
            payload,
        },
    });

    return result;
}

/**
 * Updates an existing MarketingSite
 *
 * @param {string} pageId Primary key of site being updated
 * @param {UpdateMarketingPageInput} payload
 * @returns Promise<MarketingPage>
 */
export async function updateMarketingPage(pageId, payload) {
    const { data: { updateMarketingPage: result } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateMarketingPage($pageId: ID!, $payload: UpdateMarketingPageInput!) {
                updateMarketingPage(pageId: $pageId, payload: $payload) {
                    id
                    name
                    slug
                    groupOrder
                    seoDescription
                    content {
                        chunks
                        design
                        html
                    }
                    isDeleted
                    siteId
                }
            }
        `,
        variables: {
            pageId,
            payload,
        },
    });

    return result;
}

export async function deleteSite(siteId) {
    const { data: { deleteMarketingSite } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteMarketingSite($siteId: ID!) {
                deleteMarketingSite(siteId: $siteId)
            }
        `,
        variables: {
            siteId,
        },
    });

    return deleteMarketingSite;
}

export async function createMarketingPage(siteId, payload) {
    const { data: { createMarketingPage: result } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createMarketingPage($siteId: ID!, $payload: CreateMarketingPageInput!) {
                createMarketingPage(siteId: $siteId, payload: $payload) {
                    content {
                        chunks
                        design
                        html
                    }
                    groupOrder
                    seoDescription
                    id
                    isDeleted
                    name
                    siteId
                    slug
                }
            }
        `,
        variables: {
            siteId,
            payload,
        },
    });

    return result;
}

export async function deleteMarketingPage(pageId) {
    const { data: { deleteMarketingPage: result } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteMarketingPage($pageId: ID!) {
                deleteMarketingPage(pageId: $pageId)
            }
        `,
        variables: {
            pageId,
        },
    });

    return result;
}

export async function publishSite(siteId) {
    const { data: { publishMarketingSite } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation publishMarketingSite($siteId: String!) {
                publishMarketingSite(siteId: $siteId) {
                    id
                    name
                    slug
                    mainPageId
                    status
                    createdDate
                    updatedDate
                    publishedDate
                    isDeleted
                }
            }
        `,
        variables: {
            siteId,
        },
    });

    return sortPagesForSite(publishMarketingSite);
}

/**
 * Sorts pages for a newly created site
 * @param {[CreateMarketingPageInput]} originalPages
 * @param {MarketingSite} savedSite
 */
export function sortPagesForCreate(originalPages, savedSite) {
    const mainPageSlug = originalPages[0].slug;
    const sortedPages = [...savedSite.pages];

    sortedPages.sort((page, page2) => (page2.slug === mainPageSlug) - (page.slug === mainPageSlug));

    return {
        mainPageId: sortedPages[0].id,
        sortedPages,
    };
}

/**
 * In-place sort pages for an existing site i
 *
 * @param {MarketingSite} site
 * @return {MarketingSite}
 */
export function sortPagesForSite(site) {
    const { mainPageId, pages = [] } = site;

    if (mainPageId) {
        pages?.sort((page, page2) => (page2.id === mainPageId) - (page.id === mainPageId));
        site.pages = pages;
    }

    return site;
}


export async function unpublishSite(siteId) {
    const { data: { unpublishMarketingSite } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation unpublishMarketingSite($siteId: String!) {
                unpublishMarketingSite(siteId: $siteId) {
                    id
                    name
                    slug
                    mainPageId
                    status
                    createdDate
                    updatedDate
                    publishedDate
                    isDeleted
                }
            }
        `,
        variables: {
            siteId,
        },
    });

    return unpublishMarketingSite;
}

export async function createMarketingForm(payload) {
    const { data: { createMarketingForm: result } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createMarketingForm($payload: CreateMarketingFormInput!) {
                createMarketingForm(payload: $payload) {
                    formId
                    formSource
                    formType
                    name
                    pageId
                }
            }
        `,
        variables: {
            payload,
        },
    });

    return result;
}

export async function getSitePageViews() {
    const { data: { marketingSitePageViews: { pageViews } } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingSitePageViews {
                marketingSitePageViews {
                    pageViews {
                        id
                        pageViews
                        uniqueVisitors
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
    });

    const pageViewMap = Object.assign({}, ...pageViews.map((group) => ({ [group.id]: group })));

    return pageViewMap;
}

export async function getSiteFormSubmissions() {
    const { data: { marketingSiteFormSubmissions: { formSubmissions } } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingSiteFormSubmissions {
                marketingSiteFormSubmissions {
                    formSubmissions {
                        id
                        submissions
                    }
                }
            }
        `,
        fetchPolicy: 'no-cache',
    });

    const formSubmissionsMap = Object.assign({}, ...formSubmissions.map((group) => ({ [group.id]: group })));

    return formSubmissionsMap;
}

export async function getMarketingPageForms(templateIds) {
    const { data: { marketingPageForms } } = await Vue.prototype.$graphql.query({
        query: gql`
            query marketingPageForms($templateIds: [ID]) {
                marketingPageForms(templateIds: $templateIds) {
                    data {
                        formId
                        formSource
                        formType
                        pageId
                        name
                    }
                }
            }
        `,
        variables: { templateIds },
    });

    return marketingPageForms;
}

export async function deleteTemplateForm(templateFormExternalId) {
    const { data: { deleteTemplateForm: result } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteTemplateForm($templateFormExternalId: ID!) {
                deleteTemplateForm(templateFormExternalId: $templateFormExternalId)
            }
        `,
        variables: {
            templateFormExternalId,
        },
    });

    return result;
}
