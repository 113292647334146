<template>
    <div class="sms-broadcast-preview-message">
        <template v-if="!message">
            {{ $t('defaultMessage') }}
        </template>

        <template v-else-if="messageValues.length === 1">
            {{ messageValues[0].value }}
        </template>

        <template v-else>
            <span v-for="({ value, showError }, i) in messageValues" :key="i" :class="{ error: showError }">{{ value }}</span>
        </template>
    </div>
</template>

<script>
import { getUnsupportedMergeFields } from '../../utils/smsBroadcast.util';
import { sliceTextByMergeFields } from '../../utils/rogue.util';

export default {
    props: {
        message: String,
        mergeFields: {
            type: Object,
            default: () => ({}),
        },
    },

    computed: {
        messageValues() {
            const supportedMergeFields = Object.keys(this.mergeFields);

            // If there are no given merge fields,
            // the preview is being used outside of an editor and should not highlight.
            if (supportedMergeFields.length === 0) {
                return [{ value: this.message }];
            }

            const unsupportedMergeFields = getUnsupportedMergeFields(this.message, supportedMergeFields);

            const values = sliceTextByMergeFields({ text: this.message, supportedMergeFields });

            const valuesWithErrors = values.map((value) => {
                if (unsupportedMergeFields.includes(value)) {
                    return {
                        value,
                        showError: true,
                    };
                }

                return { value };
            });

            return valuesWithErrors;
        },
    },
};
</script>

<style lang="scss" scoped>
.error {
    color: $color-red;
}
</style>

<i18n>
{
    "en-us": {
        "defaultMessage": "Enter message to see preview..."
    }
}
</i18n>
