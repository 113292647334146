import firebase from 'firebase/app';
import 'firebase/database';

export default {
    LOAD_PREFERENCES: async ({ commit, rootState }) => {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        const userSnapshot = await firebase.database().ref(`preferences/${appId}/${userId}`).once('value');
        const globalSnapshot = await firebase.database().ref(`preferences/${appId}/global`).once('value');

        const preferences = {
            ...userSnapshot.val() || {},
            ...globalSnapshot.val() || {},
        };

        commit('SET_PREFERENCES', preferences);
    },

    SAVE_USER_PREFERENCE: ({ commit, rootState }, preference) => {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        const [preferenceKey] = Object.keys(preference);
        const [preferenceValue] = Object.values(preference);

        commit('UPDATE_PREFERENCE', preference);

        return firebase.database().ref(`preferences/${appId}/${userId}/${preferenceKey}`).set(preferenceValue);
    },

    SAVE_GLOBAL_PREFERENCE: ({ commit, rootState }, preference) => {
        const appId = rootState.auth.session.coreAppId;

        const [preferenceKey] = Object.keys(preference);
        const [preferenceValue] = Object.values(preference);

        commit('UPDATE_PREFERENCE', preference);

        return firebase.database().ref(`preferences/${appId}/global/${preferenceKey}`).set(preferenceValue);
    },
};
