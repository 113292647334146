import * as tasksApi from '@/tasks/api/';
import sentry from '@/analytics/sentry';

const loadTaskList = ({ state, commit }, id) => {
    commit('LOAD_TASK_LIST_START');

    return tasksApi.loadTaskList(id || state.contact.id)
        .then(({ data }) => {
            commit('LOAD_TASK_LIST_SUCCESS', data.taskList);
        })
        .catch((err) => {
            sentry.log('Loading task list failed', { message: err.message });
            commit('LOAD_TASK_LIST_ERROR');
            throw err;
        });
};

const addTask = async ({ state, commit, dispatch }, { task, currentContactId }) => {
    try {
        const { data: { createTask: { tasks, total } } } = await tasksApi.addTask(task);

        const taskAddedForCurrentContact = tasks.find((newTask) => {
            return +newTask.contactId === +currentContactId;
        });

        if (taskAddedForCurrentContact) {
            commit('ADD_TASK', taskAddedForCurrentContact);
        }

        if (state.contact.id && task.contactIds[0]) {
            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: 'taskcreated',
                details: {
                    body: taskAddedForCurrentContact.title,
                    eventAuthorId: taskAddedForCurrentContact.assignedUserId,
                },
            });
        }

        await dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'addTask', { root: true });

        await dispatch('tasks/ADD_APP_TASKS', { tasks, total }, { root: true });
    } catch (e) {
        sentry.log('Adding a task failed', { message: e.message });
        throw e;
    }
};

const addTasks = ({
    commit,
    dispatch,
    rootState,
}, { currentContactId, tasks }) => {
    return tasksApi.addTasks(tasks)
        .then(({ data: { createTasks } }) => {
            const created = createTasks.tasks;
            const total = createTasks.tasks.length;
            const tasksAddedForCurrentContact = tasks.find((newTask) => {
                return newTask.contactId === currentContactId;
            });

            if (tasksAddedForCurrentContact) {
                commit('ADD_TASKS', tasksAddedForCurrentContact);

                if (rootState.auth.user.id && created.length && tasks[0].contactIds[0]) {
                    const feedItems = [];

                    tasksAddedForCurrentContact.forEach((taskAdded) => {
                        feedItems.push({
                            type: 'taskcreated',
                            details: {
                                body: taskAdded.title,
                                eventAuthorId: taskAdded.assignedUserId,
                            },
                        });
                    });
                    commit('ADD_ACTIVITY_FEED_ITEMS', feedItems);
                }
            }

            dispatch('tasks/ADD_APP_TASKS', { tasks: created, total }, { root: true });
        }).catch((err) => {
            sentry.log('Adding tasks failed', { message: err.message });
            throw err;
        });
};

const updateTask = ({ commit, dispatch }, { taskId, updatedTask, eventType }) => {
    return tasksApi.updateTask(updatedTask, taskId)
        .then(({ data: { updateTask: task } }) => {
            commit('UPDATE_TASK', task);

            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: eventType || 'taskupdated',
                details: {
                    body: task.title,
                    eventAuthorId: task.assignedUserId,
                },
            });

            dispatch('tasks/UPDATE_APP_TASK', task, { root: true });

            return task;
        }).catch((err) => {
            sentry.log('Updating task failed', { message: err.message });
            throw err;
        });
};

const updateTaskWithOutcome = ({ commit, dispatch }, { taskId, updatedTaskWithOutcome, eventType }) => {
    return tasksApi.updateTaskWithOutcome(updatedTaskWithOutcome, taskId)
        .then(({ data: { updateTaskWithOutcome: task } }) => {
            commit('UPDATE_TASK', task);

            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: eventType || 'taskupdated',
                details: {
                    body: task.title,
                    eventAuthorId: task.assignedUserId,
                },
            });

            dispatch('tasks/UPDATE_APP_TASK', task, { root: true });

            return task;
        }).catch((error) => {
            sentry.captureException(error);
            throw error;
        });
};

const deleteTask = ({ commit, dispatch, rootState }, { id, title }) => {
    commit('REMOVE_TASK', id);
    dispatch('tasks/REMOVE_APP_TASK', id, { root: true });

    commit('ADD_ACTIVITY_FEED_ITEM', {
        type: 'taskdeleted',
        details: {
            body: title,
            eventAuthorId: rootState.auth.user.id,
        },
    });

    return tasksApi.deleteTask(id)
        .catch((err) => {
            sentry.log('Delete task failed', { message: err.message });
            throw err;
        });
};

export {
    loadTaskList as LOAD_TASK_LIST,
    addTask as ADD_TASK,
    addTasks as ADD_TASKS,
    updateTask as UPDATE_TASK,
    updateTaskWithOutcome as UPDATE_TASK_WITH_OUTCOME,
    deleteTask as DELETE_TASK,
};
