<script>
/**
 * Mixin that provides some utility and conversion functions
 */
import { KEAP_CUSTOM_LINK_TYPES, KEAP_TOOLS, TEMPLATE_FORM } from '@/marketingSites/unlayer/unlayer.constants';
import {
    buildPageHtml,

} from '@/marketingSites/components/site/site.utils';
import clonedeep from 'lodash.clonedeep';
import {
    clearKeapFormsFromPages,
    loadFullGlobalTemplate,
    loadFullSite,
    mapMarketingSiteToCreateSiteInput,
} from '@/marketingSites/unlayer/clone-marketing-site';
import {
    createMarketingForm,
    createSite,
    getMarketingPage,
    updateMarketingSite,
} from '@/marketingSites/api/sites-datasource-graphql';
import {
    MARKETING_SITE_TEMPLATE_TYPES,
} from '@/marketingSites/provider/site-provider.utils';
import { convertUnlayerFormsToKeapForms } from '@/marketingSites/unlayer/unlayer-form-converters';
import { convert as slugify } from 'url-slug';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';
import { unlayerDesign } from '@/marketingSites/unlayer/unlayer-design-processor';
import {
    convertUrlSlugsForClone,
    processAllUrlsInHtmlBlock,
    processAllUrlsInUnlayerDesign,
} from '@/marketingSites/provider/update-page-links';


export default {
    inject: ['$customForms'],

    provide() {
        return {
            $createSite: {
                cloneSiteFromSite: this.cloneSiteFromSite,
                createUserSiteTemplate: this.createUserSiteTemplate,
                clonePagesIntoExistingSite: this.clonePagesIntoExistingSite,
                cloneSiteFromGlobalTemplate: this.cloneSiteFromGlobalTemplate,
                createNewSite: this.createNewSite,
            },
        };
    },

    props: {
        appId: String,
    },

    methods: {

        /**
         * Converts an unlayer funnel template model into a MarketingSite instance.
         *
         * This function also converts all forms to custom forms
         *
         * @param unlayer
         * @param siteName

         * @return {Promise<CreateMarketingSiteInput>}
         */
        async convertUnlayerToCreateInput(unlayer, siteName) {
            const {
                pages: templatePages = [],
            } = unlayer;

            const defaultPageName = this.$t('marketingSites.defaultPageName');
            const formNamePrefix = this.$t('marketingSites.formAutoNamePrefix');
            let formCount = 0;
            const pages = await Promise.all(templatePages.map(async (page) => {
                const { html: pageHtml, name: pageName, design: pageDesign } = page;
                const name = pageName === 'default' ? defaultPageName : pageName;
                const design = await this.$customForms.updateCustomFormsWithinPage({
                    page,
                    formNamePrefix: siteName,
                    formNameGenerator: () => `${formNamePrefix} ${++formCount}`,
                    design: convertUnlayerFormsToKeapForms(pageDesign),
                });

                return {
                    name,
                    slug: slugify(name ?? 'page'),
                    content: {
                        design,
                        html: pageHtml,
                    },
                };
            }));

            return {
                name: siteName,
                slug: slugify(siteName),
                status: SITE_STATUS.DRAFT,
                pages,
            };
        },

        async cloneSiteFromSite(marketingSite, siteName) {
            const fullyLoadedSite = await loadFullSite(marketingSite);

            return this.cloneSite(fullyLoadedSite, siteName, SITE_STATUS.DRAFT);
        },

        async createUserSiteTemplate(marketingSite, siteName) {
            const fullyLoadedSite = await loadFullSite(marketingSite);

            return this.cloneSite(fullyLoadedSite, siteName, SITE_STATUS.SAVED);
        },

        async cloneSiteFromGlobalTemplate(globalTemplate, siteName) {
            const fullyLoadedGlobalTemplate = await loadFullGlobalTemplate(globalTemplate);

            return this.cloneSite(fullyLoadedGlobalTemplate, siteName, SITE_STATUS.DRAFT);
        },

        /**
         * Clones a MarketingSite instance with a new name.
         *
         * @param {MarketingSite} cloneSource A fully loaded resource, either a GlobalTemplate or MarketingSite
         * @param {string} siteName The name of the new cloned site
         * @param {string} status The status to use
         * @return {Promise<MarketingSite>}
         */
        async cloneSite(cloneSource, siteName, status) {
            const siteToCopy = {
                ...cloneSource,
                status: status ?? SITE_STATUS.DRAFT,
                pages: clearKeapFormsFromPages(cloneSource.pages),
            };

            // This initial save is to generate the page IDs
            const firstSaveSite = await createSite(mapMarketingSiteToCreateSiteInput(siteToCopy, siteName, status));

            return this.clonePages(firstSaveSite, siteToCopy.pages, siteName);
        },


        async clonePagesIntoExistingSite(existingSite, pagesToClone) {
            return this.clonePages(existingSite, pagesToClone, existingSite.name);
        },

        async createMarketingFormsForSite(pages, name) {
            await Promise.all(pages.map(async (page) => {
                const pageFromSite = await getMarketingPage(page.id);
                const { content: { design } } = pageFromSite;

                let formCount = 0;

                const updatedDesign = await this.$customForms.updateCustomFormsWithinPage({
                    pageFromSite,
                    design,
                    formNamePrefix: name,
                    formNameGenerator: () => `${this.$t('marketingSites.formAutoNamePrefix')} ${++formCount}`,
                });

                await Promise.all(unlayerDesign(updatedDesign)
                    .findByType(KEAP_TOOLS.keapForm).map(({ content }) => {
                        const form = content?.values?.keapForm;

                        return createMarketingForm({
                            formId: form.id,
                            formSource: TEMPLATE_FORM.TemplateFormSource,
                            formType: TEMPLATE_FORM.TemplateFormType,
                            name: content?.values?.formName,
                            pageId: page.id,
                        });
                    }));

                return pageFromSite;
            }));
        },


        /**
         * Clones a collection of pages into a site.  This function takes care of also mapping all the links from the old
         * site into the new site, as well as creating marketing forms.
         *
         * @param {MarketingSite} targetSite The site where the pages should reside
         * @param {[MarketingPage]} sourcePages A collection of pages to clone
         * @param siteName The name of the new site
         * @param oldMainPageId The mainPageId of the previous site
         * @return {Promise<MarketingSite>}
         */
        async clonePages(targetSite, sourcePages, siteName, oldMainPageId) {
            const preparedPages = clearKeapFormsFromPages(sourcePages);
            // After pageIDs are generated, we need to replace all references to old page IDs
            const updateSiteData = this.convertReferencesInClonedSite(preparedPages, targetSite, oldMainPageId);

            // After updating references, we need to save the new site
            const updatedSite = await updateMarketingSite(targetSite.id, updateSiteData);

            // Finally, sync the marketing forms
            await this.createMarketingFormsForSite(updateSiteData.pages, siteName);

            return {
                ...updatedSite,
                pages: updateSiteData.pages,
            };
        },

        async createNewSite(selectedTemplate, siteName) {
            const { sourceTemplate, templateType } = selectedTemplate;

            const {
                site, global, blank, unlayer,
            } = MARKETING_SITE_TEMPLATE_TYPES;

            let newSite;

            // eslint-disable-next-line default-case
            switch (templateType) {
            case site:
                newSite = await this.cloneSiteFromSite(sourceTemplate, siteName);
                break;

            case global:
                newSite = await this.cloneSiteFromGlobalTemplate(sourceTemplate, siteName);
                break;

            case unlayer: {
                // If the content is directly from unlayer, we need to convert forms
                const siteInput = await this.convertUnlayerToCreateInput(sourceTemplate, siteName);

                newSite = await createSite(siteInput);
                break;
            }

            case blank: {
                // In the case of blank, there's nothing to convert or process about the source input, just hte name
                newSite = await createSite({
                    ...sourceTemplate,
                    name: siteName,
                    slug: slugify(siteName),
                });
                break;
            }
            }

            // create marketing forms for site already happens inside of cloneSite, so we don't need to
            // do it again here
            if (templateType !== site && templateType !== global) {
                await this.createMarketingFormsForSite(newSite.pages, siteName);
            }

            return newSite;
        },

        /**
         * For a cloned site/template, this method will update all the references within the content.  This includes:
         *
         * 1. The enclosing <keap-scope> and <keap-hosting> tags
         * 2. Any links between pages, including form submission urls
         * 3. mainPageId references
         *
         * @param {[MarketingPage]} originalPages The page content that needs to be converted
         * @param newSite The newly saved site.  Note, `newSite` site will not contain any content values since those
         * @param oldMainPageId The mainPageId of the previous site
         * are not returned with the API
         *
         * @return {UpdateMarketingSiteInput}
         */
        convertReferencesInClonedSite(originalPages, newSite, oldMainPageId) {
            const { appId } = this;

            originalPages = clonedeep(originalPages);
            newSite = clonedeep(newSite);

            // Creates a mapping of
            // oldPageId -> newPage
            const oldPageIdToNewPageMap = originalPages.reduce((map, { slug: oldPageSlug, id: oldPageId }) => {
                const newPage = newSite.pages.find(({ slug }) => slug === oldPageSlug);

                map[oldPageId] = newPage;

                return map;
            }, {});

            // For each page, we need to regenerate the html, and update the design as well.
            const pages = originalPages.map((oldPage) => {
                const { id: oldPageId, content } = oldPage;
                const newPage = oldPageIdToNewPageMap[oldPageId];

                if (!newPage) {
                    throw Error(`Can not find page map for old page id='${oldPageId}`);
                }

                newPage.content = content;
                content.chunks.body = convertLinksInHtml(content.chunks.body, appId, newSite, oldPageIdToNewPageMap);
                content.html = buildPageHtml(appId, newSite, oldPage, content.chunks, content.chunks.body);
                content.design = convertLinksInDesign(content.design, appId, newSite, oldPageIdToNewPageMap);

                return newPage;
            });

            const mainPageId = oldPageIdToNewPageMap[oldMainPageId ?? newSite.mainPageId]?.id ?? pages.first?.id;

            const {
                name, slug,
            } = newSite;

            return {
                name,
                slug,
                pages,
                mainPageId,
            };
        },
    },
    render() {
        return this.$slots.default;
    },
};


/**
 * Examines an HTML string to find all links to other pages within this site, and updates their references
 * to point at the latest * slugs.
 *
 * @param html Raw html string to be processed
 * @param appId The appId for the newly cloned site
 * @param site Details for the new site
 * @param {object} oldPageToNewPageMap Map of old pageId to new page instance
 * @return {string} The converted HTML
 */
function convertLinksInHtml(html, appId, site, oldPageToNewPageMap) {
    return processAllUrlsInHtmlBlock(html, (oldUrl, linkType) => {
        if (linkType === KEAP_CUSTOM_LINK_TYPES.marketingPage) {
            return convertUrlSlugsForClone(oldUrl, appId, site, oldPageToNewPageMap);
        }

        return null;
    });
}

function convertLinksInDesign(design, appId, site, oldPageToNewPageMap) {
    return processAllUrlsInUnlayerDesign(design, (url, urlType) => {
        if (urlType === KEAP_CUSTOM_LINK_TYPES.marketingPage) {
            return convertUrlSlugsForClone(url, appId, site, oldPageToNewPageMap);
        }

        return null;
    });
}
</script>
