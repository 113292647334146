import { IDLE } from '@/contacts/loadingStatuses';

export default {
    tasks: null,
    hiddenDashboardWidgets: {},
    customDashboard: {},
    sharedWidgets: {},
    sharedWidgetUsers: {},
    dashboardConfig: {},
    configSaved: false,
    totalContacts: 0,
    totalContactsStatus: IDLE,
    dashboardMoveState: false,
    toDos: [],
    toDosStatus: IDLE,
};
