import axios from 'axios';
import gql from 'graphql-tag';
import Vue from 'vue';
import sentry from '@/analytics/sentry';
import { CUSTOM_FIELD_CATEGORY, ALLOWED_MERGE_SERVICE_FIELDS } from '@/shared/constants/mergeFields.constants';

export default {
    // useMergeService if coming from automation context, which resolves merge fields on the backend using the new merge service
    LOAD_MERGE_FIELDS(context, { useMergeService = false }) {
        if (useMergeService) {
            loadAllMergeFields(context);
        } else {
            loadMergeFields(context);
        }
    },
};

const loadMergeFields = ({ commit }) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/mergefields`)
        .then(({ data }) => {
            commit('SET_INITIAL_MERGE_FIELDS', data);
        })
        .catch((e) => {
            sentry.log('Error getting merge fields');
            throw e;
        });
};

const loadAllMergeFields = async ({ commit }) => {
    const mergeFieldCategory = `{
        label
        options {
            label
            value
            type
        }
    }`;

    try {
        const { data: { mergeFields } } = await Vue.prototype.$graphql.query({
            query: gql`
                query mergeFields {
                    mergeFields {
                        categories {
                            contact ${mergeFieldCategory}
                            customFields ${mergeFieldCategory}
                            profile ${mergeFieldCategory}
                            today ${mergeFieldCategory}
                            user ${mergeFieldCategory}
                        }
                    }
                }
            `,
            fetchPolicy: 'no-cache',
        });

        const transformedMergeFields = transformMergeFields(mergeFields);

        commit('SET_INITIAL_MERGE_SERVICE_FIELDS', transformedMergeFields);

        return transformedMergeFields;
    } catch (e) {
        sentry.log('Load all merge fields failed');
        throw e;
    }
};

const transformMergeFields = (mergeFields) => {
    Object.keys(mergeFields.categories).forEach((key) => {
        const { label, options } = mergeFields.categories[key];

        mergeFields.categories[key] = {
            label,
            options: options.filter((option) => {
                return key === CUSTOM_FIELD_CATEGORY || ALLOWED_MERGE_SERVICE_FIELDS.includes(option.value);
            }),
        };
    });

    return mergeFields;
};
