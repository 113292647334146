<template>
    <ds-modal
        ref="modal"
        class="rename-modal"
        size="xs"
        show-close
        :title="$t('modalTitle')"
        @close="close"
    >
        <form @submit.prevent="update">
            <ds-input-field
                v-model.trim="name"
                data-qa="automation-rename"
                required
                autofocus
                :maxlength="MAXLENGTH"
                :label="$t('inputLabel')"
                :invalid="isNameInvalid"
                submitted
            />

            <div class="button-row">
                <ds-filled-button
                    type="submit"
                    data-qa="automation-rename-button"
                    :disabled="isNameInvalid"
                >
                    {{ $t('confirm') }}
                </ds-filled-button>

                <ds-text-button @click="close">
                    {{ $t('global.cancel') }}
                </ds-text-button>
            </div>
        </form>
    </ds-modal>
</template>

<script>
import { MAXLENGTH } from '@/automations/constants/automations.constants';

export default {
    props: {
        automation: Object,
        open: Boolean,
    },

    data() {
        return {
            name: this.automation.name,
        };
    },

    beforeDestroy() {
        this.close();
    },

    watch: {
        open: {
            handler(newValue, oldValue) {
                if (this.$refs.modal && newValue !== oldValue) {
                    if (newValue) {
                        this.name = this.automation.name;
                        this.$refs.modal.toggleable_open();
                    } else {
                        this.$refs.modal.toggleable_close();
                    }
                }
            },
            immediate: true,
        },
    },

    computed: {
        isNameInvalid() {
            return !this.name;
        },

        MAXLENGTH() {
            return MAXLENGTH.NAME;
        },
    },

    methods: {
        close() {
            this.$emit('close');
            this.$refs.modal.toggleable_close();
        },

        update() {
            this.$emit('update', { ...this.automation, name: this.name });
            this.close();
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "modalTitle": "Rename automation",
        "confirm": "Update",
        "inputLabel": "Name"
    }
}
</i18n>
