import axios from 'axios';
import gql from 'graphql-tag';
import Vue from 'vue';
import { isArray } from 'lodash';

import amplitude from '@/analytics/amplitude';
import { CUSTOM_COMPANY_FIELD_COUNT_USER_PROPERTIES } from '@/customFields/customFields.api';

const companyReturnType = `{
    id
    companyName
    emailAddress
    website
    notes
    emailStatus
    address {
        countryCode
        line1
        line2
        region
        locality
        zipCode
    }
    phoneNumber {
        number
        type
        extension
    }
    faxNumber {
        number
        type
    }
    customFields {
        id
        content
    }
}`;

export const getCompanyInfo = (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query companyDetails($id: ID!) {
                company(id: $id) {
                    id
                    companyName
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const addCompany = (company) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/companies/`, company)
        .then(({
            data: {
                id: value,
                companyName: label,
            },
        }) => ({ value, label }));
};

export const getCompanyDetails = (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query companyDetails($id: ID!) {
                company(id: $id) ${companyReturnType}
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const getCompanyContactCount = (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query companyContactList($id: ID!) {
                company(id: $id) {
                    contacts {
                        id
                    }
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    }).then(({ data: { company: { contacts } } }) => contacts.length);
};

export const updateCompany = (companyId, company) => {
    const customFields = [];

    if (company.customFields?.length) {
        customFields.push(...company.customFields.map(({ id, content }) => {
            let processedContent = content;

            if (processedContent !== null) {
                processedContent = isArray(content) ? content.join(',') : String(processedContent);
            }

            return {
                id, content: processedContent,
            };
        }));
    }

    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateCompany($id: ID!, $company: CompanyInput!) {
                updateCompany(id: $id, company: $company) ${companyReturnType}
            }
        `,
        variables: {
            id: companyId,
            company: {
                ...company,
                customFields,
            },
        },
    });
};

export const assignCompany = (id, contactIds) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation assignCompany($id: ID!, $contactIds: [ID]!) {
                assignCompany(id: $id, contactIds: $contactIds) {
                    id
                }
            }
        `,
        variables: {
            id,
            contactIds,
        },
    }).then(({ data: { assignCompany: contacts } }) => contacts);
};

export const transferContacts = ({
    companyId,
    targetCompanyId,
    checkAll,
    selectedIds,
}) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation transferContacts(
                $id: ID!,
                $targetCompanyId: ID!,
                $checkAll: Boolean,
                $selectedIds: [ID]
            ) {
                transferContacts(
                    id: $id,
                    targetId: $targetCompanyId,
                    checkAll: $checkAll,
                    selectedIds: $selectedIds
                ) {
                    id
                }
            }
        `,
        variables: {
            id: String(companyId),
            targetCompanyId: String(targetCompanyId),
            checkAll: Boolean(checkAll),
            selectedIds: (selectedIds || []).map((id) => String(id)),
        },
    }).then(({ data: { transferContacts: contacts } }) => contacts);
};

export const getCompanyCustomFields = () => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/customfield?recordType=Company`)
        .then(({ data: { customFields } }) => {
            amplitude.v2.setUserProperties({
                [CUSTOM_COMPANY_FIELD_COUNT_USER_PROPERTIES.count]: customFields.length,
                [CUSTOM_COMPANY_FIELD_COUNT_USER_PROPERTIES.alwaysShownCount]: customFields.filter(({ alwaysShown }) => alwaysShown).length,
            });

            return customFields;
        });
};

export const deleteCompanyCustomField = ({ fieldId }) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/customfield/${fieldId}`);
};

export const getCompanies = ({
    term,
    offset = 0,
    limit = 1000,
    sortBy = 'companyName',
    sortIsAsc = true,
    includeCount = true,
}) => {
    const query = includeCount
        ? gql`
    query companyList(
        $term: String
        $limit: Int
        $offset: Int
        $sortBy: String
        $sortIsAsc: Boolean
    ) {
        companies(
            term: $term,
            limit: $limit,
            offset: $offset,
            sortBy: $sortBy,
            sortIsAsc: $sortIsAsc
        ) {
            id
            companyName
        }
        companyList {
            totalCount
        }
    }
`
        : gql`
    query companyList(
        $term: String
        $limit: Int
        $offset: Int
        $sortBy: String
        $sortIsAsc: Boolean
    ) {
        companies(
            term: $term,
            limit: $limit,
            offset: $offset,
            sortBy: $sortBy,
            sortIsAsc: $sortIsAsc
        ) {
            id
            companyName
        }
    }
`;

    return Vue.prototype.$graphql.query({
        query,
        variables: {
            term,
            limit,
            offset,
            sortBy,
            sortIsAsc,
        },
        fetchPolicy: 'no-cache',
    }).then(({ data: { companies, companyList = {} } }) => {
        const { totalCount = 0 } = companyList;

        if (includeCount) {
            amplitude.v2.setUserProperties({ 'Total Number of Companies': totalCount });
        }

        return { companies, totalCount };
    });
};
