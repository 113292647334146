<template>
    <form ref="form" novalidate @submit.prevent="handleSubmit">
        <ds-text-area-field
            ref="body"
            v-model="form.body"
            type="text"
            name="body"
            :label="$t('global.note')"
            required
            :submitted="isSubmitted"
            :maxlength="null"
            class="fs-block note"
            @input="onInput"
        />

        <div class="buttons">
            <slot :form="form" name="buttons" />
        </div>
    </form>
</template>

<script>
export default {
    name: 'NoteForm',

    props: {
        note: Object,
    },

    data() {
        const { note } = this;

        return {
            form: { body: note ? note.body : '' },
            isSubmitted: false,
        };
    },

    mounted() {
        this.$nextTick(() => {
            this.$refs.body.input_focus();
        });
    },

    methods: {
        handleSubmit() {
            this.isSubmitted = true;

            if (this.$refs.form.checkValidity()) {
                this.$emit('submit', this.form);
            }
        },

        onInput() {
            this.$emit('input', this.form.body);
        },
    },
};
</script>

<style lang="scss" scoped>
    .note {
        --textarea-min-height: #{px-to-rem(200px)};
    }

    .buttons {
        display: flex;
    }

    .buttons > *:not(:last-child) {
        margin-right: $gp / 2;
    }
</style>
