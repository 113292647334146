<template>
    <div class="set-availability-stage">
        <h2 class="headline">
            {{ $t('title') }}
        </h2>

        <div class="fields-container">
            <div class="field">
                <h4>{{ $t('nameTitle') }}</h4>

                <p>{{ $t('nameDescription') }}</p>

                <appointment-name-field
                    v-model="apptType.name"
                    :title.sync="apptType.title"
                    :is-valid.sync="apptTypeNameValid"
                    class="input-field appointment-name-field"
                    data-qa="appointment-name-field"
                    :title-link-part.sync="apptType.titleLinkPart"
                    :host-link-part.sync="apptType.hostLinkPart"
                    :booking-link.sync="bookingLink"
                />
            </div>

            <div class="field">
                <h4>{{ $t('locationTitle') }}</h4>

                <p>{{ $t('locationDescription') }}</p>

                <location-field
                    v-model="apptType.location"
                    class="location"
                    block
                    :location-type.sync="apptType.locationType"
                    :is-valid.sync="locationFieldValid"
                    :booking-link="bookingLink"
                />
            </div>
        </div>

        <help-box v-track="'Appointments - Which calendars modal - clicked : How does this work link'">
            {{ $t('infoMessage') }}
        </help-box>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import HelpBox from '@/appointments/components/HelpBox';
import AppointmentNameField from '@/appointments/components/AppointmentNameField';
import LocationField from '@/appointments/components/LocationField';

export default {
    components: {
        HelpBox,
        AppointmentNameField,
        LocationField,
    },

    props: {
        calendarProviderId: {
            type: String,
            required: true,
            default: 'GOOGLE',
        },
        title: String,
        name: String,
        locationType: String,
        location: String,
        hostLinkPart: String,
        titleLinkPart: String,
    },

    data() {
        return {
            apptType: {
                name: this.name,
                title: this.title,
                location: this.location,
                locationType: this.locationType,
                hostLinkPart: this.hostLinkPart,
                titleLinkPart: this.titleLinkPart,
            },
            bookingLink: '',
            apptTypeNameValid: false,
            locationFieldValid: false,
        };
    },

    watch: {
        areRequiredFieldsCompleted(value) {
            this.$emit('update:ready', value);
        },

        apptType: {
            handler(value) {
                this.$emit('update:locationType', value.locationType);
                this.$emit('update:location', value.location);
                this.$emit('update:name', value.name);
                this.$emit('update:title', value.title);
                this.$emit('update:titleLinkPart', value.titleLinkPart);
                this.$emit('update:hostLinkPart', value.hostLinkPart);
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            tempApptType: ({ calendar }) => calendar.tempApptType,
        }),

        areRequiredFieldsCompleted() {
            return this.locationFieldValid && this.apptTypeNameValid;
        },
    },

};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .set-availability-stage {
        margin: 0 auto;
        max-width: $form-width;
        width: 100%;
    }

    .headline {
        margin: $gp * 2 0;
        text-align: center;
    }

    .validation-msg {
        color: $color-red;
        font-size: $font-size-small;
        margin-top: $gp / 3;
        text-align: center;
    }

    .fields-container {
        margin-top: $gp * 2.5;
    }

    .field {
        margin-bottom: $gp * 2;
    }

    .input-field {
        --input-margin-bottom: #{$gp / 3};
    }

    .invalid {
        --input-border: 1px solid #{$color-red};
    }

    .help-link {
        margin: 0 auto;
    }

    @media($small) {
        .location {
            margin-bottom: $gp * 5;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Appointment info",
        "nameTitle": "Name",
        "nameDescription": "What do you want to name your appointment?",
        "locationTitle": "Location",
        "locationDescription": "Where will these appointments take place?",
        "infoMessage": "Set the days & times of the week that you want to show your availability for this type of appointment. Clients will only be able to schedule within these selected times."
    }
}
</i18n>
