<script>
import { fetchPrices } from '../api/lifecycle';

export default {
    props: {
        appName: String,
        pollInterval: {
            type: Number,
            default: 5000,
        },
    },

    data() {
        return {
            checkingUpgradeAvailability: null,
        };
    },

    created() {
        this.checkUpgradeAvailability();

        if (this.pollInterval) {
            this.checkingUpgradeAvailability = setInterval(this.checkUpgradeAvailability, this.pollInterval);
        }
    },

    beforeDestroy() {
        this.clearInterval();
    },

    methods: {
        async checkUpgradeAvailability() {
            try {
                await fetchPrices({ appName: this.appName });

                this.clearInterval();
            } catch (e) {
                // swallow error
            }
        },

        clearInterval() {
            if (this.checkingUpgradeAvailability) {
                clearInterval(this.checkingUpgradeAvailability);
                this.checkingUpgradeAvailability = null;
            }
        },
    },

    render() {
        if (this.checkingUpgradeAvailability) return null;

        return this.$slots.default;
    },
};
</script>
