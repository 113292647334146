import gql from 'graphql-tag';
import Vue from 'vue';

import sentry from '@/analytics/sentry';

export default {
    COPY_LEGACY_BROADCAST(_, id) {
        return copyLegacyBroadcast(id);
    },

    DELETE_DRAFT(_, payload) {
        return deleteDraft(payload);
    },

    LOAD_BROADCASTS(context, payload) {
        return loadBroadcasts(context, payload);
    },

    LOAD_BROADCAST_REPORT(_, mailBatchId) {
        return loadBroadcastReport(mailBatchId);
    },

    LOAD_MAIL_BATCH_BODY(_, mailBatchId) {
        return loadMailBatchBody(mailBatchId);
    },

    LOAD_BROADCAST_ERRORS(_, payload) {
        return loadBroadcastErrors(payload);
    },

    START_MAIL_BATCH(_, mailBatchId) {
        return startMailBatch(mailBatchId);
    },

    STOP_MAIL_BATCH(_, mailBatchId) {
        return stopMailBatch(mailBatchId);
    },
};

const copyLegacyBroadcast = async (broadcastId) => {
    const payload = {
        broadcastId,
        listId: 0,
        isSplitTest: false,
    };

    try {
        const { data } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation copyLegacyBroadcast($payload: CopyBroadcastInput!) {
                    copyLegacyBroadcast(payload: $payload) {
                        broadcastId
                    }
                }
            `,
            variables: {
                payload,
            },
            fetchPolicy: 'no-cache',
        });

        return data.copyLegacyBroadcast.broadcastId;
    } catch (error) {
        sentry.log('Copy broadcast failed in broadcasts actions');
        throw error;
    }
};

const deleteDraft = async ({ id }) => {
    try {
        await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation deleteDraft($broadcastId: ID!) {
                    deleteDraft(broadcastId: $broadcastId)
                }
            `,
            variables: {
                broadcastId: id,
            },
            fetchPolicy: 'no-cache',
        });
    } catch (error) {
        sentry.log('Delete broadcast draft failed in broadcasts actions');
        throw error;
    }
};

const loadBroadcasts = async (_, {
    filter = null,
    limit = 20,
    offset = 0,
    search = '',
} = {}) => {
    const { data: { broadcastsAndDrafts } } = await Vue.prototype.$graphql.query({
        query: gql`
            query getBroadcastAndDraftList($filter: BroadcastStatus, $limit: Int, $offset: Int, $search: String) {
                broadcastsAndDrafts(filter: $filter, limit: $limit, offset: $offset, search: $search) {
                    broadcastRecipientStatistics {
                        duplicateRecipients
                        eligibleRecipients
                        ineligibleRecipients
                        throttled
                        totalRecipients
                    }
                    clickCount
                    draftProgressStatus {
                        isAudienceComplete
                        isDetailsComplete
                        isTimingComplete
                    }
                    editor
                    id
                    lastEdit
                    mailBatchId
                    openCount
                    recipients {
                        count
                        id
                        name
                        type
                    }
                    scheduleDate
                    sentCount
                    sentDate
                    splitBroadcastType
                    status
                    subject
                }
            }
        `,
        variables: {
            filter,
            limit,
            offset,
            search,
        },
        fetchPolicy: 'no-cache',
    });

    return broadcastsAndDrafts;
};

const loadBroadcastReport = async (mailBatchId) => {
    try {
        const { data: { broadcastReport } } = await Vue.prototype.$graphql.query({
            query: gql`
                query getBroadcastReport($mailBatchId: ID!) {
                    broadcastReport(mailBatchId: $mailBatchId) {
                        reportDetails {
                            body
                            broadcastId
                            createDate
                            endDate
                            fromAddress
                            mailBatchId
                            numBounced
                            numClicked
                            numComplaints
                            numDelivered
                            numError
                            numOpened
                            numOptedIn
                            numOptedOut
                            numScheduled
                            numSent
                            numSkipped
                            numUniqueClicked
                            numUniqueOpened
                            numUnopened
                            recipients {
                                count
                                id
                                name
                                type
                            }
                            scheduleDate
                            splitBroadcastType
                            startDate
                            status
                            subject
                        }
                        splitTestDetails {
                            endDate
                            linkedBroadcasts {
                                broadcastId
                                body
                                createDate
                                endDate
                                fromAddress
                                mailBatchId
                                numBounced
                                numClicked
                                numComplaints
                                numDelivered
                                numError
                                numOpened
                                numOptedIn
                                numOptedOut
                                numScheduled
                                numSent
                                numSkipped
                                numUniqueClicked
                                numUniqueOpened
                                numUnopened
                                recipients {
                                    count
                                    id
                                    name
                                    type
                                }
                                scheduleDate
                                splitBroadcastType
                                startDate
                                status
                                subject
                            }
                            sendWinningEmailAutomatic
                            startDate
                            status
                            winningBroadcastId
                            winningMetric
                        }
                    }
                }
            `,
            variables: {
                mailBatchId,
            },
            fetchPolicy: 'no-cache',
        });

        return broadcastReport;
    } catch (error) {
        sentry.log('Loading broadcast report failed in broadcasts actions');
        throw error;
    }
};

const loadMailBatchBody = async (mailBatchId) => {
    const { data: { broadcastReport: { reportDetails, splitTestDetails } } } = await Vue.prototype.$graphql.query({
        query: gql`
            query getMailBatchBody($mailBatchId: ID!) {
                broadcastReport(mailBatchId: $mailBatchId) {
                    reportDetails {
                        mailBatchId
                        body
                    }
                    splitTestDetails {
                        linkedBroadcasts {
                            mailBatchId
                            body
                        }
                    }
                }
            }
        `,
        variables: {
            mailBatchId,
        },
        fetchPolicy: 'no-cache',
    });

    if (mailBatchId === reportDetails.mailBatchId) {
        return reportDetails.body;
    }

    const linkedBroadcast = splitTestDetails.linkedBroadcasts.find((broadcast) => mailBatchId === broadcast.mailBatchId);

    return linkedBroadcast?.body;
};

const loadBroadcastErrors = async ({
    limit,
    offset,
    sortBy,
    sortAscending,
    mailBatchId,
}) => {
    try {
        const { data: { broadcastErrors: { errors, total } } } = await Vue.prototype.$graphql.query({
            query: gql`
                query getBroadcastErrors($mailBatchId: ID!, $limit: Int, $offset: Int, $sortBy: String, $sortIsAsc: Boolean) {
                    broadcastErrors(mailBatchId: $mailBatchId, limit: $limit, offset: $offset, sortBy: $sortBy, sortIsAsc: $sortIsAsc) {
                        errors {
                            contactId
                            contactName
                            errorMessage
                        }
                        total
                    }
                }
            `,
            variables: {
                mailBatchId,
                limit,
                offset,
                sortBy,
                sortIsAsc: sortAscending,
            },
            fetchPolicy: 'no-cache',
        });

        return { errors, totalErrors: total };
    } catch (error) {
        sentry.log('Loading broadcast error list failed in broadcasts actions');
        throw error;
    }
};

const startMailBatch = async (mailBatchId) => {
    try {
        const { data: { startMailBatch: { status } } } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation startMailBatch($mailBatchId: ID!) {
                    startMailBatch(mailBatchId: $mailBatchId) {
                        status
                    }
                }
            `,
            variables: {
                mailBatchId,
            },
            fetchPolicy: 'no-cache',
        });

        return status;
    } catch (error) {
        sentry.log('Starting mail batch failed in broadcasts actions');
        throw error;
    }
};

const stopMailBatch = async (mailBatchId) => {
    try {
        const { data: { stopMailBatch: { status } } } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation stopMailBatch($mailBatchId: ID!) {
                    stopMailBatch(mailBatchId: $mailBatchId) {
                        status
                    }
                }
            `,
            variables: {
                mailBatchId,
            },
            fetchPolicy: 'no-cache',
        });

        return status;
    } catch (error) {
        sentry.log('Stopping mail batch failed in broadcasts actions');
        throw error;
    }
};
