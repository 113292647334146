<template>
    <div class="credit-card-fields">
        <div v-if="showForm">
            <ds-input-field
                v-if="showEmailField"
                v-model="email"
                class="fs-block"
                autocomplete="cc-email"
                type="email"
                name="email"
                :label="$t('billing.paymentProcessorCard.email')"
                required
                :submitted="submitted"
                @input="handleFormInput('email')"
                @blur="handleFormBlur('email')"
            />

            <ds-input-field
                v-if="showNameField"
                v-model="nameOnCard"
                class="fs-block"
                autocomplete="cc-name"
                type="text"
                name="nameOnCard"
                :label="$t('contactRecord.sales.addPaymentModalContent.creditCardName')"
                required
                :submitted="submitted"
                @input="handleFormInput('nameOnCard')"
                @blur="handleFormBlur('nameOnCard')"
            />

            <div class="input-line-group">
                <div class="cc-number">
                    <ds-input-field
                        ref="cardNumberField"
                        v-model="cardNumber"
                        autocomplete="cc-number"
                        type="text"
                        name="cardNumber"
                        :label="$t('contactRecord.sales.addPaymentModalContent.creditCardNumber')"
                        required
                        :invalid="!isCreditCardValid"
                        :maxlength="cardNumberMaxLength"
                        :submitted="submitted"
                        class="fs-block"
                        @input="handleCardNumberInput"
                        @blur="handleFormBlur('cardNumber')"
                    />
                </div>

                <div class="expiration">
                    <ds-input-field
                        ref="expirationField"
                        v-model="formattedExpiration"
                        type="text"
                        autocomplete="cc-exp"
                        name="expiration"
                        :label="$t('contactRecord.sales.addPaymentModalContent.creditCardExpiration')"
                        required
                        :invalid="!isExpirationValid"
                        :maxlength="expirationMaxLength"
                        :submitted="submitted"
                        class="fs-block"
                        @input="handleFormInput('expiration')"
                        @blur="expirationOnBlur"
                        @focus="expirationOnFocus"
                    />
                </div>

                <div class="cvv">
                    <ds-input-field
                        ref="verificationCodeField"
                        v-model="verificationCode"
                        type="text"
                        autocomplete="cc-csc"
                        name="verificationCode"
                        :label="$t('contactRecord.sales.addPaymentModalContent.creditCardCVV')"
                        required
                        :maxlength="verificationMaxLength"
                        :invalid="!isVerificationCodeValid"
                        :submitted="submitted"
                        class="fs-block"
                        @input="handleVerificationCodeInput"
                        @blur="handleFormBlur('verificationCode')"
                    />
                </div>
            </div>

            <ds-multiselect
                v-model="country"
                searchable
                :placeholder="$t('forms.countryCode')"
                required
                :options="countryOptions"
                tabindex="-1"
                :submitted="submitted"
                class="fs-block"
                @input="countryChanged"
                @blur="handleFormBlur('country')"
            />

            <div class="input-line-group">
                <div v-if="showFullAddress" class="address1">
                    <ds-input-field
                        v-model="address1"
                        type="text"
                        name="address1"
                        autocomplete="address-line1"
                        :label="$t('forms.address1')"
                        required
                        :submitted="submitted"
                        class="fs-block"
                        @input="handleFormInput('address1')"
                        @blur="handleFormBlur('address1')"
                    />
                </div>

                <div v-if="showFullAddress" class="address2">
                    <ds-input-field
                        v-model="address2"
                        type="text"
                        name="address2"
                        autocomplete="address-line2"
                        :label="$t('forms.address2')"
                        :submitted="submitted"
                        class="fs-block"
                        @input="handleFormInput('address2')"
                        @blur="handleFormBlur('address2')"
                    />
                </div>
            </div>

            <div class="input-line-group">
                <div v-if="showFullAddress" class="locality">
                    <ds-input-field
                        v-model="locality"
                        type="text"
                        name="locality"
                        autocomplete="address-level2"
                        :label="$t('forms.locality')"
                        required
                        :submitted="submitted"
                        class="fs-block"
                        @input="handleFormInput('locality')"
                        @blur="handleFormBlur('locality')"
                    />
                </div>

                <div v-if="isRegionRequired" class="region">
                    <ds-multiselect
                        v-model="region"
                        searchable
                        :placeholder="$t('forms.region')"
                        required
                        autocomplete="address-level1"
                        :options="regionOptions"
                        tabindex="-1"
                        :submitted="submitted"
                        class="fs-block"
                        @input="handleRegionInput"
                        @blur="handleFormBlur('region')"
                    />
                </div>

                <div class="postal-code">
                    <ds-input-field
                        v-model="postalCode"
                        type="text"
                        name="postalCode"
                        :label="$t('forms.postalCodeGeneric')"
                        required
                        autocomplete="postal-code"
                        :submitted="submitted"
                        class="fs-block"
                        @input="handlePostalCodeInput"
                        @blur="handleFormBlur('postalCode')"
                    />
                </div>
            </div>

            <i18n
                v-if="showChargeStatement && !showRecurringInfo"
                path="contactRecord.sales.addPaymentModalContent.chargeStatement"
                tag="p"
                class="charge-statement"
            >
                <template #name>
                    <span>{{ chargeStatementData.name }}</span>
                </template>

                <template #amount>
                    <b class="primary-text semibold">{{ chargeStatementData.amount }}</b>
                </template>
            </i18n>
        </div>

        <ds-placeholder v-else :rows="placeholder" />
    </div>
</template>

<script>
import { patterns } from '@infusionsoft/vue-utils';
import debounce from 'lodash.debounce';
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import luhn from 'luhn';
import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

const YEAR_PLACEHOLDER = 'MM/YY';

export default {
    props: {
        readonly: Boolean,
        paymentFrom: String,
        postalCodeFrom: String,
        expirationFrom: String,
        expirationMonthFormat: {
            type: String,
            default: 'MM',
        },
        expirationYearFormat: {
            type: String,
            default: 'YYYY',
        },
        rate: [Number, String],
        showChargeStatement: Boolean,
        showNameField: Boolean,
        showEmailField: Boolean,
        showFullAddress: {
            type: Boolean,
            default: false,
        },
        hasRequiredFields: Boolean,
        loadCountryDispatch: {
            type: String,
            default: 'LOAD_COUNTRY_OPTIONS',
        },
        loadRegionDispatch: {
            type: String,
            default: 'LOAD_REGION_OPTIONS',
        },
        companyProfile: {
            type: Object,
            default () { return {}; },
        },
        submitted: {
            type: Boolean,
            default: false,
        },
        debounceDelay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
        showRecurringInfo: Boolean,
    },

    data() {
        return {
            email: '',
            nameOnCard: this.paymentFrom,
            cardNumber: '',
            address1: '',
            address2: '',
            country: '',
            locality: '',
            region: '',
            postalCode: this.postalCodeFrom,
            verificationCode: '',
            expirationString: this.expirationFrom || YEAR_PLACEHOLDER,
            expirationMoment: null,
            cardNumberMaxLength: 19,
            expirationMaxLength: 5,
            verificationMaxLength: 4,
            startDate: new Date(),
            placeholder: [
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
            ],
        };
    },

    created() {
        if (this.debounceDelay) {
            this.handleCardNumberInput = debounce(this.handleCardNumberInput, this.debounceDelay);
            this.handleVerificationCodeInput = debounce(this.handleVerificationCodeInput, this.debounceDelay);
            this.handlePostalCodeInput = debounce(this.handlePostalCodeInput, this.debounceDelay);
        }

        if (!this.readonly) {
            this.loadCountryOptions();
        }
    },

    computed: {
        ...mapState({
            countryOptions: ({ global }) => global.countryOptions,
            regionOptions: ({ global }) => global.regionOptions,
            regionRequired: ({ sales }) => sales.appSalesInfo.regionRequired,
        }),

        ...mapGetters({
            displayFullName: 'contacts/displayFullName',
        }),

        formattedExpiration: {
            get() {
                return typeof this.expirationMoment === 'object' && this.expirationMoment
                    ? this.expirationMoment.format('MM/YY')
                    : this.expirationString;
            },

            set: debounce(function(value) {
                this.checkForRequiredFields();
                this.formatExpiration(value);
                this.$nextTick(() => {
                    this.$refs.expirationField.input_reportValidity();
                });
            }, INPUT_DEBOUNCE_DELAY),
        },

        chargeStatementData() {
            return {
                name: this.displayFullName,
                amount: this.$n(this.rate, 'currency'),
            };
        },

        isCreditCardValid() {
            const regex = RegExp(patterns.creditCardNumber);

            return regex.test(this.cardNumber) && this.cardNumber !== '' && luhn.validate(this.cardNumber);
        },

        isExpirationValid() {
            const regex = RegExp(patterns.creditCardExpiration);

            return regex.test(this.formattedExpiration);
        },

        isVerificationCodeValid() {
            const regex = RegExp(patterns.creditCardCVV);

            return regex.test(this.verificationCode);
        },

        isRegionRequired() {
            return Boolean((this.regionRequired || this.showFullAddress) && this.regionOptions.length);
        },

        showForm() {
            return Boolean(this.readonly || this.countryOptions?.length);
        },
    },

    methods: {
        getData() {
            const data = {
                nameOnCard: this.nameOnCard,
                cardNumber: this.cardNumber,
                region: this.region ? this.region.label : null,
                country: this.country ? this.country.label : null,
                postalCode: this.postalCode,
                verificationCode: this.verificationCode,
                expirationMonth: this.expirationMoment ? this.expirationMoment.format(this.expirationMonthFormat) : '',
                expirationYear: this.expirationMoment ? this.expirationMoment.format(this.expirationYearFormat) : '',
            };

            if (this.showEmailField) {
                data.email = this.email;
            }

            if (this.showFullAddress) {
                data.address1 = this.address1;
                data.address2 = this.address2;
                data.locality = this.locality;
            }

            return data;
        },

        countryChanged() {
            this.handleFormInput('country');
            this.checkForRequiredFields();
            this.loadRegionOptions(this.country.value);
        },

        getRequiredFields() {
            const requiredFields = [
                {
                    name: 'nameOnCard',
                    isSatisfied: this.nameOnCard !== '' || (this.paymentFrom != null && this.paymentFrom !== ''),
                },
                {
                    name: 'cardNumber',
                    isSatisfied: this.cardNumber !== '',
                },
                {
                    name: 'expirationString',
                    isSatisfied: this.validateExpirationDate(),
                },
                {
                    name: 'verificationCode',
                    isSatisfied: this.verificationCode !== '',
                },
                {
                    name: 'postalCode',
                    isSatisfied: Boolean(this.postalCode),
                },
                {
                    name: 'country',
                    isSatisfied: Boolean(this.country) && this.country.value !== '',
                },
            ];

            if (this.showFullAddress) {
                requiredFields.push({
                    name: 'address1',
                    isSatisfied: this.address1 !== '',
                });
            }

            if (this.showFullAddress) {
                requiredFields.push({
                    name: 'locality',
                    isSatisfied: this.locality !== '',
                });
            }

            if (this.isRegionRequired) {
                requiredFields.push({
                    name: 'region',
                    isSatisfied: Boolean(this.region) && this.region.value !== '',
                });
            }

            return requiredFields;
        },

        validateExpirationDate() {
            if (this.expirationString === YEAR_PLACEHOLDER) {
                return false;
            }

            this.formatExpiration(this.expirationString);

            return this.expirationMoment !== null;
        },

        handleFormBlur(field) {
            this.emitFormEvent({ type: 'blur', field });
        },

        handleFormInput(field) {
            this.emitFormEvent({ type: 'input', field });
            this.checkForRequiredFields();
        },

        handleRegionInput(val) {
            if (val && val.value) {
                this.handleFormInput('region');
            }
        },

        checkForRequiredFields() {
            const requiredFields = this.getRequiredFields();
            const hasMissingRequiredFields = requiredFields.some(({ isSatisfied }) => isSatisfied !== true);

            return this.$emit('update:hasRequiredFields', !hasMissingRequiredFields);
        },

        handleCardNumberInput() {
            this.handleFormInput('cardNumber');
            this.checkForRequiredFields();
            this.$refs.cardNumberField.input_reportValidity();
        },

        handleVerificationCodeInput() {
            this.handleFormInput('verificationCode');
            this.checkForRequiredFields();
            this.$refs.verificationCodeField.input_reportValidity();
        },

        handlePostalCodeInput() {
            this.handleFormInput('postalCode');
        },

        expirationOnFocus() {
            if (this.expirationString === YEAR_PLACEHOLDER) {
                this.expirationString = '';
            }
        },

        expirationOnBlur() {
            if (this.expirationString === '') {
                this.expirationString = YEAR_PLACEHOLDER;
            }

            this.handleFormBlur('expiration');
        },

        formatExpiration(value) {
            let dateString = value;

            // Stash user input in state
            this.expirationString = dateString;

            // Attempt to parse user input into something useful
            dateString = dateString.replace(/\D/g, '');
            this.expirationString = dateString;

            if (dateString.length > 2) {
                dateString = dateString.length === 3 ? `0${dateString}` : dateString;
                this.expirationMoment = moment(dateString, 'MMYY');
            } else {
                this.expirationMoment = null;
            }
        },

        loadCountryOptions() {
            const message = this.$t('errors.loadCountryOptions');

            return this.$store.dispatch(this.loadCountryDispatch)
                .then(() => {
                    this.setLocalCountry();
                }).catch(() => {
                    this.$error({ message });
                });
        },

        loadRegionOptions(countryCode) {
            return this.$store.dispatch(this.loadRegionDispatch, { countryCode })
                .then(() => {
                    this.region = null;
                    this.checkForRequiredFields();
                });
        },

        setLocalCountry() {
            const found = this.countryOptions.find(({ value }) => {
                return value === this.companyProfile.country;
            });

            if (found) {
                this.country = found;
                this.loadRegionOptions(this.country.value);
            }
        },

        emitFormEvent(eventObj) {
            this.$emit('form-event', eventObj);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import '~@/money/styles/charge-statement';

    .credit-card-fields {
        --input-height: #{px-to-rem(44px)};
        margin-bottom: var(--credit-card-fields-bottom-margin, #{$gp});
    }

    @media (min-width: 601px) {
        .input-line-group {
            display: flex;
            margin-right: -#{$gp};

            > * {
                @include margin-end($gp);
                flex: 1;
            }
        }

        .cc-number {
            flex: 2;
        }

        .address1 {
            flex: 3;
        }
    }

    [dir=rtl] {
        .credit-card-fields {
            --input-height: #{px-to-rem(40px)};
        }
    }
</style>

<i18n>
{
    "en-us": {
        "errors": {
            "loadCountryOptions": "Something went wrong and we couldn't load the country options. Please try again."
        }
    }
}
</i18n>
