<template>
    <ds-modal
        v-if="formattedShortcuts"
        ref="modal"
        :title="$t('title')"
        size="fluid"
        show-close
    >
        <div
            v-shortkey="formattedShortcuts"
            class="shortcuts"
            @shortkey="handleAction"
        >
            <div
                v-for="{ name, icon, shortcuts } in filteredShortcuts"
                :key="name"
                :class="['section', { 'active': name === currentRoute }]"
            >
                <header>
                    <ds-icon :name="icon" />
                    <h6>{{ $t(name) }}</h6>
                </header>

                <div
                    v-for="({ shortcut, label }, i) in shortcuts"
                    :key="i"
                    class="shortcut"
                >
                    <ds-chip
                        v-for="key in shortcut"
                        :key="key"
                    >
                        {{ key }}
                    </ds-chip>

                    {{ $t(label) }}
                </div>
            </div>
        </div>
    </ds-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
    mounted() {
        this.$bus.$on('SHORTKEY_ESC', this.closeHelpModal);
        this.$bus.$on('SHORTKEY_?', this.toggleHelpModal);
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_ESC', this.closeHelpModal);
        this.$bus.$off('SHORTKEY_?', this.toggleHelpModal);
    },

    computed: {
        ...mapState({
            shortcuts: ({ shortcuts }) => shortcuts.shortcuts,
            currentRoute: ({ route }) => route.name,
        }),

        formattedShortcuts() {
            const formattedShortcuts = {};

            this.shortcuts.forEach(({ shortcuts }) => {
                shortcuts.forEach(({ eventName, shortcut }) => {
                    if (eventName) {
                        formattedShortcuts[eventName] = shortcut;
                    }
                });
            });

            return formattedShortcuts;
        },

        filteredShortcuts() {
            return this.shortcuts.filter(({ shortcuts, name }) => shortcuts.length > 0 && name !== 'shared');
        },
    },

    methods: {
        handleAction({ srcKey }) {
            if (srcKey.includes('NAV')) {
                this.handleNavigation(srcKey.substr(4));
            } else {
                this.$bus.$emit(srcKey);
            }
        },

        handleNavigation(name) {
            this.$bus.$emit('POP_ALL_NESTED_MODALS');
            this.$router.push({ name });
        },

        toggleHelpModal() {
            this.$refs.modal.toggleable_toggle();
        },

        closeHelpModal() {
            if (this.$refs.modal.toggleable_active) {
                this.$refs.modal.toggleable_close();
            }
        },
    },
};
</script>


<style lang="scss" scoped>
    .shortcuts {
        columns: auto 3;

        @media($medium-down) { column-count: 2; }
        @media($small) { column-count: 1; }
    }

    .section {
        --icon-size: #{px-to-rem(20px)};

        color: $color-gray-800;
        padding: $gp;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        border-radius: $border-radius;

        &.active {
            background: $color-gray-050;
            color: $color-gray-600;
        }
    }

    .shortcut {
        display: flex;
        align-items: center;
        font-size: $font-size-small;
        margin-bottom: $gp / 2;

        > * {
            @include margin-end($gp / 2);
        }
    }

    header {
        display: flex;
        align-items: center;
        color: $color-ink;
        border-bottom: 1px solid $color-gray-800;
        margin-bottom: $gp / 2;
        padding: $gp / 2;

        h6 {
            margin: 0 $gp / 2;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "addContact": "Add a contact",
        "addDeal": "Add a deal",
        "addTask": "Add a task",
        "broadcasts": "Broadcasts",
        "contacts.list": "Contacts",
        "createBroadcast": "Create a Broadcast",
        "createCampaign": "Create a campaign",
        "createList": "Create a list",
        "everywhere": "Everywhere",
        "exploreCampaigns": "Explore Campaigns",
        "filter": "Filter",
        "goToBilling": "Go to Payments",
        "goToBroadcasts": "Go to Broadcasts",
        "goToContacts": "Go to Contacts",
        "goToCalendar": "Go to Calendar",
        "goToDashboard": "Go to Dashboard",
        "goToMyCampaigns": "Go to My Campaigns",
        "goToPipeline": "Go to Pipeline",
        "goToReports": "Go to Reports",
        "goToSettings": "Go to Settings",
        "goToTasks": "Go to Tasks",
        "import": "Import",
        "mycampaigns": "Campaigns",
        "navigation": "Navigation",
        "pipeline": "Pipeline",
        "search": "Search",
        "title": "Keyboard Shortcuts",
        "tasks": "Tasks",
        "toggleHelp": "Toggle this menu",
        "toggleSupport": "Toggle help & support",
        "toggleNav": "Toggle side nav",
        "toggleNotifications": "Toggle notifications",
        "toggleSideNav": "Toggle side nav",
        "toggleTasks": "Toggle completed tasks",
        "toggleThisMenu": "Toggle this menu"
    }
}
</i18n>
