export default {
    reviews: null,
    googleBusinessEmail: '',
    googleBusinessAccountName: '',
    googleReviewLink: '',
    googleBusinessName: '',
    googleBusinessLocationId: '',
    googleBusinessListings: [],
    googleBusinessReviews: {},
    googleBusinessListingsLoadingStatus: 'IDLE',
};
