<template>
    <div class="business-address-modal">
        <business-address ref="businessAddress" />

        <div class="button-row">
            <ds-filled-button
                class="modal-button"
                :loading="saving"
                @click="save"
            >
                {{ $t('global.save') }}
            </ds-filled-button>

            <ds-text-button
                class="modal-button"
                :disabled="saving"
                @click="close"
            >
                {{ $t('global.cancel') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import BusinessAddress from '@/settings/components/BusinessAddress';

export default {
    components: {
        BusinessAddress,
    },

    data() {
        return {
            nestedModal_title: this.$t('title.edit'),
            saving: false,
        };
    },

    methods: {
        nestedModal_open() {
            this.$refs.businessAddress.load();
        },

        close() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        save() {
            this.saving = true;

            return this.$refs.businessAddress.saveProfile()
                .then(() => {
                    this.saving = false;

                    if (!this.$refs.businessAddress.error) {
                        this.close();
                    }
                });
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "title": {
            "edit": "Edit business information"
        }
    }
}
</i18n>
