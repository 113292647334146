import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import accountManagement from '@/accountManagement/store';
import auth from './auth';
import automatedSms from './automatedSms';
import automations from '@/automations/store';
import broadcasts from '@/broadcasts/store/broadcasts';
import broadcastBuilder from '@/broadcasts/store/builder';
import broadcastWizard from '@/broadcasts/store/wizard';
import billing from './billing';
import booking from '@/appointments/store/booking';
import calendar from '@/appointments/store/calendar';
import communication from '@/communication/store';
import communicationTemplates from './communicationTemplates';
import customForms from '@/customForms/store';
import contacts from '@/contacts/store';
import contactImport from '@/import/store';
import campaigns from '@/campaigns/store/campaigns';
import dashboard from '@/dashboard/store';
import ehawk from '@/ehawk/store';
import email from './email';
import featureFlags from './featureFlags';
import fields from './fields';
import global from './global';
import mergeFields from './mergeFields';
import notifications from './notifications';
import onboarding from '@/onboarding/store';
import preferences from './preferences';
import pipeline from '@/pipeline/store';
import reports from '@/reports/store';
import reviews from '@/reviews/store';
import shortcuts from './shortcuts';
import settings from '@/settings/store';
import sales from '@/money/store';
import sampleData from '@/sampleData/store';
import smartForms from '@/smartForms/store';
import smsBroadcasts from '@/broadcasts/store/smsBroadcasts';
import tags from '@/tags/store';
import tasks from '@/tasks/store';
import templates from '@/campaigns/store/templates';
import tutorials from './tutorials';

Vue.use(Vuex);

export const modules = {
    accountManagement,
    auth,
    automatedSms,
    automations,
    booking,
    broadcasts,
    broadcastBuilder,
    broadcastWizard,
    calendar,
    communication,
    communicationTemplates,
    customForms,
    dashboard,
    billing,
    campaigns,
    ehawk,
    email,
    contacts,
    contactImport,
    featureFlags,
    fields,
    global,
    mergeFields,
    notifications,
    onboarding,
    pipeline,
    preferences,
    reports,
    reviews,
    shortcuts,
    sales,
    sampleData,
    settings,
    smartForms,
    smsBroadcasts,
    tags,
    tasks,
    templates,
    tutorials,
};

const vuexPersistPathsAllowList = [
    'auth.session',
    'auth.lastSession',
    'auth.exitIntentComplete',
    'broadcastBuilder.lastBroadcastSource',
    'broadcastBuilder.newestBroadcastId',
    'broadcastWizard.schedules',
    'broadcastWizard.statuses',
    'calendar.connectRedirect',
    'contactImport.lastImportSource',
    'contacts.contactListSortField',
    'contacts.contactListSortAscending',
    'contacts.activeFeedToggle',
    'global.locale',
    'global.googlePlaceId',
    'global.navOpen',
    'onboarding.showBadge',
    'onboarding.activeAppointmentTypeTask',
    'preferences',
    'sales.selectedUpsell',
];

const localStorageKey = process.env.VUE_APP_ENV !== 'production'
    ? `keap-web-${process.env.VUE_APP_ENV}`
    : 'keap-web';

export default new Vuex.Store({
    modules,
    plugins: [
        createPersistedState({
            key: localStorageKey,
            paths: vuexPersistPathsAllowList,
        }),
    ],
});
