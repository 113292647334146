<template lang="html">
    <div class="log-in-as-load-error">
        <div class="sad-browser">
            <ds-illustration name="oops-color" width="200" height="180" />
        </div>

        <h2>{{ $t('title') }}</h2>

        <pre class="help-text-error">
            <li v-for="(error, index) in localizedLogInAsErrors" :key="`error-${index}`">{{ error.message }}</li>
        </pre>

        <h4>1. {{ $t('description') }}</h4>
        <h4 class="logout">
            <span> 2.
                <router-link to="/logout">
                    {{ $t('logout') }}
                </router-link>
            </span>
        </h4>
        <h4>3. {{ $t('retry') }}</h4>
    </div>
</template>

<script>
export default {
    props: {
        errorMessages: {
            type: Array,
            default: () => [],
        },
    },

    computed: {
        localizedLogInAsErrors() {
            const errorOutput = [];

            this.errorMessages.forEach((error) => {
                if (!error.message) {
                    errorOutput.push({
                        message: `UNKNOWN ERROR GIVEN: ${error}`,
                    });

                    return;
                }

                if (error.message.includes('VPN')) {
                    errorOutput.push({
                        message: this.$t('logInAsErrors.vpnMessage'),
                    });
                } else if (error.message.includes('Stealth plugin disabled')) {
                    errorOutput.push({
                        message: this.$t('logInAsErrors.stealthPluginMissingMessage'),
                    });
                } else if (error.message.includes('proper roles')) {
                    errorOutput.push({
                        message: this.$t('logInAsErrors.repMissingRolesMessage'),
                    });
                } else if (error.message.includes('no active users')) {
                    errorOutput.push({
                        message: this.$t('logInAsErrors.noActiveUsersMessage'),
                    });
                } else if (error.message.includes('does not have an active consent')) {
                    errorOutput.push({
                        message: this.$t('logInAsErrors.noConsentMessage'),
                    });
                } else if (error.message.includes('User not found')) {
                    errorOutput.push({
                        message: this.$t('logInAsErrors.noUserFoundMessage'),
                    });
                } else {
                    errorOutput.push({
                        message: `UNKNOWN ERROR: ${error.message}`,
                    });
                }
            });

            return errorOutput;
        },
    },
};
</script>

<style lang="scss" scoped>
    .log-in-as-load-error {
        margin-top: $gp * -8;
    }

    .help-text-error {
        background-color: $color-red-050;
        border: 1px solid $color-red;
    }

    .sad-browser {
        text-align: center;
    }

    h2 {
        margin: $gp * 1.5 0;
    }

    h4 {
        margin-bottom: $gp * 2;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Oops! Looks like we cannot use Log-In-As for this application right now.",
        "description": "Check the Support Tool and ensure consent has been granted.",
        "logout": "Log out before trying again, otherwise it will not work.",
        "retry": "If this does not work again when consent is active, contact #help-keap-support-tool.",
        "logInAsErrors": {
            "vpnMessage": "You are not on the the VPN.",
            "stealthPluginMissingMessage": "This app does not have Log In As enabled (Stealth plugin disabled).",
            "repMissingRolesMessage": "You do not have have the proper roles for Log In As.",
            "noActiveUsersMessage": "This app has no active users.",
            "noConsentMessage": "This app does not have an active consent.",
            "noUserFoundMessage": "User Global ID was not found."
        }
    }
}
</i18n>
