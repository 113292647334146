import Vue from 'vue';
import moment from 'moment';
import { DEFAULT_SESSION_STATE } from './state';

export default {
    SET_USER(state, user) {
        // Transforms array of objects into single object, because GraphQL doesn't do maps
        const permissions = Array.isArray(user?.permissions)
            ? user.permissions.reduce((userPermissions, { name, granted }) => {
                userPermissions[name] = granted;

                return userPermissions;
            }, {})
            : {};

        state.user = { ...user, permissions };
        state.lastSession.email = user.email;
    },

    UPDATE_USER(state, userUpdate) {
        Object.assign(state.user, userUpdate);
    },

    SET_USERS(state, users) {
        state.users = users;
    },

    SET_ACCOUNTS_LIST(state, accounts) {
        state.accounts = accounts;
    },

    SET_JWT(state, jwt) {
        state.session.jwt = jwt;
    },

    SET_REFERRED_BY_PARTNER(state, referredByPartner) {
        state.session.referredByPartner = referredByPartner;
    },

    SET_REDIRECT_URL_CONFIG(state, redirectUrlConfig) {
        state.session.casRedirectUrlConfig = redirectUrlConfig;
    },

    SET_CORE_APP_ID(state, coreAppId) {
        state.session.coreAppId = coreAppId;
        state.lastSession.coreAppId = coreAppId;
    },

    CLEAR_LAST_SESSION(state) {
        state.lastSession = {};
    },

    SET_LOGOUT(state) {
        Vue.set(state, 'session', { ...DEFAULT_SESSION_STATE });
        Vue.set(state, 'user', {});
        Vue.set(state, 'users', []);
        Vue.set(state, 'account', { trial: {}, companyProfile: {} });
    },

    SET_ACCOUNT(state, account) {
        state.account = account;

        if (account.referredByPartner != null) {
            state.session.referredByPartner = account.referredByPartner;
        }
    },

    SET_ACCOUNT_TRIAL(state, trial) {
        state.account.trial = trial;
    },

    SET_KEAP_PHONE_ACCOUNT_INFO(state, keapPhoneAccountInfo) {
        Vue.set(state.account, 'keapPhoneAccountInfo', keapPhoneAccountInfo);
    },

    ADD_TRUSTED_NUMBER({ account: { keapPhoneAccountInfo } }, { phoneNumber }) {
        const newTrustedNumber = {
            id: phoneNumber,
            confirmed: false,
        };
        const trustedNumbers = keapPhoneAccountInfo.trustedNumbers || [];

        if (!trustedNumbers.some(({ id }) => id.includes(phoneNumber))) {
            keapPhoneAccountInfo.trustedNumbers = [...trustedNumbers, newTrustedNumber];
        }
    },

    VERIFY_TRUSTED_NUMBER({ account: { keapPhoneAccountInfo } }, { phoneNumber }) {
        const { trustedNumbers } = keapPhoneAccountInfo;

        if (Array.isArray(trustedNumbers)) {
            keapPhoneAccountInfo.trustedNumbers = trustedNumbers.map((trustedNumber) => {
                if (trustedNumber.id.includes(phoneNumber)) {
                    return {
                        ...trustedNumber,
                        confirmed: true,
                    };
                }

                return trustedNumber;
            });
        }
    },

    SET_AUTOMATED_SMS_ACCOUNT(state, automatedSmsAccount) {
        Vue.set(state.account, 'automatedSmsAccount', automatedSmsAccount);
    },

    SET_USER_PROFILE_PHOTO(state, photoUrl) {
        state.user.emailSignatureURL = photoUrl;
    },

    SET_BUSINESS_TYPE(state, businessType) {
        state.account.companyProfile = {
            ...state.account.companyProfile,
            businessType,
        };
    },

    SET_GOALS(state, goals) {
        state.account.companyProfile = {
            ...state.account.companyProfile,
            goals,
        };
    },

    SET_COMPANY_NAME(state, companyName) {
        state.account.companyProfile = {
            ...state.account.companyProfile,
            companyName,
        };
    },

    SET_COMPANY_PROFILE(state, companyProfile) {
        state.account.companyProfile.address1 = companyProfile.address1;
        state.account.companyProfile.address2 = companyProfile.address2;
        state.account.companyProfile.businessType = companyProfile.businessType;
        state.account.companyProfile.companyName = companyProfile.companyName;
        state.account.companyProfile.country = companyProfile.country;
        state.account.companyProfile.email = companyProfile.email;
        state.account.companyProfile.locality = companyProfile.locality;
        state.account.companyProfile.phone = companyProfile.phone;
        state.account.companyProfile.postalCode = companyProfile.postalCode;
        state.account.companyProfile.region = companyProfile.region;
        state.account.companyProfile.website = companyProfile.website;
    },

    SET_COMPANY_LOGO(state, companyLogo) {
        let logoUrl = companyLogo;

        if (companyLogo && companyLogo.includes('default-logo-explanation.png')) {
            logoUrl = '';
        }

        Vue.set(state.account.companyProfile, 'logoUrl', logoUrl);
    },

    SET_EXIT_INTENT_COMPLETE(state, complete) {
        state.exitIntentComplete = complete;
    },

    SET_SESSION_AFFINITY(state, sessionAffinity) {
        const sessionAffinityExists = state.session.sessionAffinity != null && Boolean(state.session.sessionAffinity.value);
        let sessionAffinityIsExpired = false;

        if (sessionAffinityExists) {
            const expires = moment(new Date(state.session.sessionAffinity.expires));
            const now = moment();

            sessionAffinityIsExpired = expires.isValid() && now.diff(expires) > 0;
        }

        if (!sessionAffinityExists || sessionAffinityIsExpired) {
            state.session.sessionAffinity = sessionAffinity;
        }
    },

    SET_SESSION_LOG_IN_AS(state, isLogInAs) {
        state.session.logInAsSession = isLogInAs;
    },
};
