import Vue from 'vue';
import gql from 'graphql-tag';

const DEAL_QUERY = `
{
    id
    name
    value {
        amount
        currency
    }
    contacts {
        id
        givenName
        familyName
        email
        ownerId
        primaryContact
    }
    stage {
        id
        name
        order
        pipelineId
    }
    stageAssignmentDate
    order
    owner {
        id
        givenName
        familyName
        email
    }
    owners {
        id
        givenName
        familyName
        email
    }
    status
    estimatedCloseDate
    closedDate
    outstandingTasks
    taskIds
    activityFeed {
        activityFeedEvents {
            id
            originParentReferenceId
            originParentReferenceType
            accountId
            accountEventId
            type
            publishRequestDate
            originReferenceId
            batchId
            details {
                eventAuthorId
                eventAuthorGivenName
                previousStageId
                previousStageName
                currentStageName
                noteBody
                updatedNoteBody
                subject
                eventAuthorEmailAddress
                contactId
            }
        }
        cursor
    }
}`;

const DEAL_NOTE_QUERY = `{
    id
    dealId
    body
    created
    modified
    createdBy
}`;

export const createDeal = (deal) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createDeal($deal: CreateDealInput!) {
                createDeal(deal: $deal)
                    ${DEAL_QUERY}
            }
        `,
        variables: {
            deal,
        },
        fetchPolicy: 'no-cache',
    });
};

export const deleteDeal = (dealId) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteDeal($dealId: ID!) {
                deleteDeal(dealId: $dealId)
            }
        `,
        variables: {
            dealId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const loadDeal = (dealId) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query deal($dealId: ID!) {
                deal(dealId: $dealId)
                    ${DEAL_QUERY}
            }
        `,
        variables: {
            dealId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const updateDeal = (dealId, deal) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateDeal($dealId: ID!, $deal: UpdateDealInput!) {
                updateDeal(dealId: $dealId, deal: $deal)
                    ${DEAL_QUERY}
            }
        `,
        variables: {
            dealId,
            deal,
        },
        fetchPolicy: 'no-cache',
    });
};

export const createDealNote = (dealId, note) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createDealNote($dealId: ID!, $note: CreateDealNoteInput!) {
                createDealNote(dealId: $dealId, note: $note)
                    ${DEAL_NOTE_QUERY}
            }
        `,
        variables: {
            dealId,
            note,
        },
        fetchPolicy: 'no-cache',
    });
};

export const deleteDealNote = (dealId, noteId) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteDealNote($dealId: ID!, $noteId: ID!) {
                deleteDealNote(dealId: $dealId, noteId: $noteId)
            }
        `,
        variables: {
            dealId,
            noteId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const loadDealNote = (dealId, noteId) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query dealNote($dealId: ID!, $noteId: ID!) {
                dealNote(dealId: $dealId, noteId: $noteId)
                    ${DEAL_NOTE_QUERY}
            }
        `,
        variables: {
            dealId,
            noteId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const loadDealNotes = (dealId) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query dealNotes($dealId: ID!) {
                dealNotes(dealId: $dealId)
                    ${DEAL_NOTE_QUERY}
            }
        `,
        variables: {
            dealId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const updateDealNote = (dealId, noteId, note) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateDealNote($dealId: ID!, $noteId: ID!, $note: UpdateDealNoteInput!) {
                updateDealNote(dealId: $dealId, noteId: $noteId, note: $note)
                    ${DEAL_NOTE_QUERY}
            }
        `,
        variables: {
            dealId,
            noteId,
            note,
        },
        fetchPolicy: 'no-cache',
    });
};
