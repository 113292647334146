<template>
    <component
        :is="as"
        v-if="!loading"
        class="automation-display-name"
        :title="getDisplayName()"
    >
        {{ getDisplayName() }}
    </component>

    <component :is="as" v-else class="placeholder">
        <ds-placeholder :rows="[{ height: '1 rem', boxes: [1]}]" />
    </component>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import {
    TRIGGER_ACTION_TYPES, TIMER_STEPS, TAG_APPLIED, REMOVE_TAG, APPLY_TAG, CREATE_DEAL,
    SEND_SMS, PRODUCT_PURCHASED, APPOINTMENT_SCHEDULED, APPOINTMENT_CANCELLED,
    ANY_PUBLIC_FORM_SUBMITTED, PUBLIC_FORM_SUBMITTED,
    ANY_PRODUCT_PURCHASED, ANY_APPOINTMENT_SCHEDULED, ANY_APPOINTMENT_CANCELLED,
    ANY_INTERNAL_FORM_SUBMITTED, INTERNAL_FORM_SUBMITTED, ANY_INVOICE_SENT, ANY_INVOICE_PAID,
    USER_APPOINTMENT_SCHEDULED, USER_APPOINTMENT_CANCELLED,
    ANY_QUOTE_ACCEPTED, ANY_QUOTE_SENT, ANY_HELLOSIGN_DOCUMENT_SIGNED, HELLOSIGN_DOCUMENT_SIGNED,
    HELLOSIGN_SEND_DOCUMENT, SHOPIFY_ANY_PRODUCT_PURCHASED, SHOPIFY_PRODUCT_PURCHASED,
    SHOPIFY_ANY_CART_ABANDONED, SHOPIFY_CART_ABANDONED, DEAL_ENTERED, DEAL_EXITED,
    ANY_LANDING_PAGE_FORM_SUBMITTED, LANDING_PAGE_FORM_SUBMITTED,
    TYPEFORM_ANY_FORM_SUBMITTED, TYPEFORM_FORM_SUBMITTED,
} from '@/automations/constants/automations.constants';
import productOptions from '@/money/mixins/product-options';
import automationDisplayName from './automationDisplayName.mixin.js';
import { getPipelineStageIds } from '@/automations/utils/automations.utils';
import { FORM_TYPE_CONTACTS, FORM_TYPE_LANDING_PAGE } from '@/customForms/customForm.constants';

export default {
    mixins: [productOptions, automationDisplayName],

    props: {
        value: {
            type: Object,
            default: () => ({ name: '' }),
        },

        triggerAction: {
            type: String,
            default: '',
            options: ['', TRIGGER_ACTION_TYPES.ADD, TRIGGER_ACTION_TYPES.REMOVE],
        },

        capitalize: Boolean,

        /**
         * The element type that will render for the root element.
        */
        as: {
            type: String,
            default: 'div',
            options: ['div', 'span'],
        },
    },

    data() {
        return {
            loading: true,
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapGetters({
            tagById: 'tags/tagById',
            stageById: 'pipeline/stageById',
            getProductById: 'billing/getProductById',
            hasAppointmentType: 'calendar/hasApptType',
            getAppointmentTypeById: 'calendar/getAppointmentTypeById',
            formById: 'customForms/formById',
            internalFormById: 'smartForms/getFormById',
            documentById: 'settings/hellosignDocumentById',
            shopifyProductById: 'settings/shopifyProductById',
            typeformFormById: 'settings/typeformFormById',
        }),

        ...mapState({
            tags: ({ tags }) => tags.tags,
            users: ({ auth }) => auth.users,
            publicForms: ({ customForms }) => customForms.forms[FORM_TYPE_CONTACTS],
            landingPageForms: ({ customForms }) => customForms.forms[FORM_TYPE_LANDING_PAGE],
            internalForms: ({ smartForms }) => smartForms.forms,
            pipelineList: ({ pipeline }) => pipeline.pipelineList,
            hellosignDocuments: ({ settings: { integrations } }) => integrations.hellosignDocuments,
            shopifyProducts: ({ settings: { integrations } }) => integrations.shopifyProducts,
            typeformForms: ({ settings: { integrations } }) => integrations.typeformForms,
        }),

        isTimerStep() {
            return TIMER_STEPS.includes(this.value.type);
        },
    },

    methods: {
        getDisplayName() {
            const name = this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE ? this.$t('removeTrigger', { title: this.getTitle() }) : this.getTitle();

            return this.capitalize ? `${name.charAt(0).toUpperCase()}${name.slice(1)}` : `${name.charAt(0).toLowerCase()}${name.slice(1)}`;
        },

        getTitle() {
            const {
                sourceId, step, name, configJson, type,
            } = this.value;

            if (type === ANY_PUBLIC_FORM_SUBMITTED) {
                return this.$t('leadFormSubmitted.title');
            }

            if (type === PUBLIC_FORM_SUBMITTED || type === LANDING_PAGE_FORM_SUBMITTED) {
                if (sourceId) {
                    const form = this.formById(sourceId);

                    return form?.title
                        ? this.$t('formSubmitted.title', { title: form.title })
                        : this.$t('invalidForm');
                }

                return this.$t('formSubmitted.empty');
            }

            if (type === ANY_LANDING_PAGE_FORM_SUBMITTED) {
                return this.$t('landingPageFormSubmitted.title');
            }

            if (type === ANY_HELLOSIGN_DOCUMENT_SIGNED) {
                return this.$t('anyHellosignDocumentSigned.title');
            }

            if (type === HELLOSIGN_DOCUMENT_SIGNED) {
                if (sourceId) {
                    const document = this.documentById(sourceId);

                    return document?.title
                        ? this.$t('hellosignDocumentSigned.title', { title: document.title })
                        : this.$t('invalidDocument');
                }

                return this.$t('hellosignDocumentSigned.empty');
            }

            if (type === TYPEFORM_ANY_FORM_SUBMITTED) {
                return this.$t('typeform.anyFormSubmitted.title');
            }

            if (type === TYPEFORM_FORM_SUBMITTED) {
                if (sourceId) {
                    const form = this.typeformFormById(sourceId);

                    return form?.title
                        ? this.$t('typeform.formSubmitted.title', { title: form.title })
                        : this.$t('typeform.invalidDocument');
                }

                return this.$t('typeform.formSubmitted.empty');
            }

            if (type === HELLOSIGN_SEND_DOCUMENT) {
                if (configJson?.documentId) {
                    const document = this.documentById(configJson.documentId);

                    return document?.title
                        ? this.$t('hellosignSendDocument.title', { title: document.title })
                        : this.$t('invalidDocument');
                }

                return this.$t('hellosignSendDocument.empty');
            }

            if (type === SHOPIFY_ANY_PRODUCT_PURCHASED) {
                return this.$t('shopifyAnyProductPurchased.title');
            }

            if (type === SHOPIFY_ANY_CART_ABANDONED) {
                return this.$t('shopifyAnyCartAbandoned.title');
            }

            if (type === SHOPIFY_PRODUCT_PURCHASED || type === SHOPIFY_CART_ABANDONED) {
                if (sourceId) {
                    const product = this.shopifyProductById(sourceId);

                    if (!product && name) {
                        return name;
                    }

                    return product?.title
                        ? type === SHOPIFY_PRODUCT_PURCHASED ? this.$t('shopifyProductPurchased.title', { title: product.title }) : this.$t('shopifyCartAbandoned.title', { title: product.title })
                        : this.$t('shopifyProductPurchased.invalid');
                }

                return type === SHOPIFY_PRODUCT_PURCHASED
                    ? this.$t('shopifyProductPurchased.empty')
                    : this.$t('shopifyCartAbandoned.empty');
            }

            if (type === SEND_SMS) {
                return this.$t('sendSms.title', { title: configJson?.title ?? '' });
            }

            if (type === ANY_INTERNAL_FORM_SUBMITTED) {
                return this.$t('internalFormSubmitted.title');
            }

            if (type === INTERNAL_FORM_SUBMITTED) {
                if (sourceId) {
                    const form = this.internalFormById(sourceId);

                    return form?.title
                        ? this.$t('formSubmitted.title', { title: form.title })
                        : this.$t('invalidForm');
                }

                return this.$t('formSubmitted.empty');
            }

            if (type === ANY_PRODUCT_PURCHASED) {
                return this.$t('anyProduct.title');
            }

            if (type === ANY_APPOINTMENT_SCHEDULED) {
                return this.$t('anyAppointmentScheduled.title');
            }

            if (type === ANY_APPOINTMENT_CANCELLED) {
                return this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE ? this.$t('anyAppointmentCancelledStop.title') : this.$t('anyAppointmentCancelled.title');
            }

            if (type === CREATE_DEAL) {
                if (configJson?.stageId) {
                    const stage = this.stageById(configJson.stageId);

                    return stage?.name
                        ? this.$t('createDeal.title', { name: stage.name })
                        : this.$t('invalidStage');
                }

                return this.$t('createDeal.empty');
            }

            if (type === DEAL_ENTERED || type === DEAL_EXITED) {
                if (sourceId) {
                    const ids = getPipelineStageIds(sourceId);
                    const stage = this.stageById(ids.stageId);

                    if (stage?.name) {
                        return this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE
                            ? this.$t(type === DEAL_ENTERED ? 'dealEnteredStop.title' : 'dealExitedStop.title', { name: stage.name })
                            : this.$t(type === DEAL_ENTERED ? 'dealEntered.title' : 'dealExited.title', { name: stage.name });
                    }

                    return this.$t('invalidStage');
                }

                return this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE
                    ? type === DEAL_ENTERED ? this.$t('dealEnteredStop.empty') : this.$t('dealExitedStop.empty')
                    : type === DEAL_ENTERED ? this.$t('dealEntered.empty') : this.$t('dealExited.empty');
            }

            if (type === TAG_APPLIED) {
                return this.automationDisplayName_getTagTriggerDescription(this.value);
            }

            if (type === APPLY_TAG || type === REMOVE_TAG) {
                return this.automationDisplayName_getTagStepDescription(this.value);
            }

            if (type === PRODUCT_PURCHASED) {
                if (sourceId) {
                    const product = this.getProductById(sourceId);

                    return product?.productName
                        ? this.$t('product.title', { name: product.productName })
                        : this.$t('product.invalid');
                }

                return this.$t('product.empty');
            }

            if (type === APPOINTMENT_SCHEDULED || type === APPOINTMENT_CANCELLED) {
                if (sourceId) {
                    const appointmentType = this.getAppointmentTypeById(sourceId);

                    if (appointmentType?.name) {
                        return this.$t(type === APPOINTMENT_SCHEDULED ? 'appointmentScheduled.title' : 'appointmentCancelled.title', { name: appointmentType.name });
                    }

                    if (configJson?.userId) {
                        const user = this.users.find(({ id }) => id === configJson.userId);

                        return user
                            ? this.$t(type === APPOINTMENT_SCHEDULED ? 'appointmentScheduled.user' : 'appointmentCancelled.user', { user: user.givenName })
                            : this.$t('invalidAppointmentType');
                    }

                    return this.$t('invalidAppointmentType');
                }

                return type === APPOINTMENT_SCHEDULED ? this.$t('appointmentScheduled.empty') : this.$t('appointmentCancelled.empty');
            }

            if (sourceId && (type === USER_APPOINTMENT_SCHEDULED || type === USER_APPOINTMENT_CANCELLED)) {
                const userName = this.users.find(({ casId }) => casId === sourceId)?.fullName ?? this.$t('invalidUser');

                return this.$t(type === USER_APPOINTMENT_SCHEDULED
                    ? 'appointmentScheduledFor.title'
                    : 'appointmentCancelledFor.title',
                { userName });
            }

            if (type === ANY_INVOICE_SENT) {
                return this.$t('invoiceSent.title');
            }

            if (type === ANY_INVOICE_PAID) {
                return this.$t('invoicePaid.title');
            }

            if (type === ANY_QUOTE_SENT) {
                return this.$t('quoteSent.title');
            }

            if (type === ANY_QUOTE_ACCEPTED) {
                return this.$t('quoteAccepted.title');
            }

            if (this.isTimerStep) {
                return this.automationDisplayName_timerStepName(this.value);
            }

            return this.$t(name) || this.$t(step?.name) || '';
        },

        async init() {
            const { type, sourceId, configJson } = this.value;

            if ((type === TAG_APPLIED || type === APPLY_TAG || type === REMOVE_TAG) && !this.tags.length && sourceId) {
                await this.$store.dispatch('tags/LOAD_TAGS');
            } else if (type === PRODUCT_PURCHASED && !this.productOptions_products.length && sourceId) {
                await this.productOptions_loadProducts();
            } else if ((type === APPOINTMENT_SCHEDULED || type === APPOINTMENT_CANCELLED) && !this.hasAppointmentType && sourceId) {
                await this.$store.dispatch('calendar/LOAD_PROVIDER_INTEGRATIONS');
                await this.$store.dispatch('calendar/LOAD_APPT_TYPES');
            } else if (type === PUBLIC_FORM_SUBMITTED && !this.publicForms.length && sourceId) {
                await this.$store.dispatch('customForms/LOAD_FORMS');
            } else if (type === INTERNAL_FORM_SUBMITTED && !this.internalForms.length && sourceId) {
                await this.$store.dispatch('smartForms/LOAD_SMART_FORMS');
            } else if (type === LANDING_PAGE_FORM_SUBMITTED && !this.landingPageForms.length && sourceId) {
                await this.$store.dispatch('customForms/LOAD_FORMS', FORM_TYPE_LANDING_PAGE);
            } else if ((type === CREATE_DEAL && configJson?.stageId) || ((type === DEAL_ENTERED || type === DEAL_EXITED) && sourceId) && !this.pipelineList.length) {
                await this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST');
            } else if (!this.hellosignDocuments?.length
                && ((type === HELLOSIGN_DOCUMENT_SIGNED && sourceId) || (type === HELLOSIGN_SEND_DOCUMENT && configJson?.documentId))) {
                await this.$store.dispatch('settings/LOAD_HELLOSIGN_DOCUMENTS');
            } else if ((type === SHOPIFY_PRODUCT_PURCHASED || type === SHOPIFY_CART_ABANDONED) && !this.shopifyProducts?.length && sourceId) {
                await this.$store.dispatch('settings/LOAD_SHOPIFY_PRODUCTS');
            } else if (!this.typeformForms?.length && type === TYPEFORM_FORM_SUBMITTED && sourceId) {
                await this.$store.dispatch('settings/LOAD_TYPEFORM_FORMS');
            }

            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    div.placeholder {
        width: 100%;
    }
</style>

<!-- WARNING: this component contains translation tests. -->
<!-- Keep the unit test file in sync with these messages -->
<i18n>
{
    "en-us": {
        "removeTrigger": "when {title}",
        "invalidForm": "Invalid form",
        "leadFormSubmitted": {
            "title": "any public form is submitted"
        },
        "formSubmitted": {
            "title": "({title}) is submitted",
            "empty": "form is submitted"
        },
        "landingPageFormSubmitted": {
            "title": "any landing page form is submitted"
        },
        "internalFormSubmitted": {
            "title": "any internal form is submitted"
        },
        "createDeal": {
            "title": "create a deal in ({name})",
            "empty": "create a deal"
        },
        "dealEntered": {
            "title": "a deal enters ({name})",
            "empty": "a deal enters stage"
        },
        "dealExited": {
            "title": "a deal exits ({name})",
            "empty": "a deal exits stage"
        },
        "dealEnteredStop": {
            "title": "the deal enters ({name})",
            "empty": "the deal enters stage"
        },
        "dealExitedStop": {
            "title": "the deal exits ({name})",
            "empty": "the deal exits stage"
        },
        "invalidStage": "Invalid stage",
        "sendSms": {
            "title": "send ({title}) text"
        },
        "product": {
            "title": "({name}) is purchased",
            "invalid": "Invalid product",
            "empty": "product is purchased"
        },
        "anyProduct": {
            "title": "any product is purchased"
        },
        "invoiceSent": {
            "title": "an invoice is sent"
        },
        "invoicePaid": {
            "title": "an invoice is paid"
        },
        "quoteSent": {
            "title": "a quote is sent"
        },
        "quoteAccepted": {
            "title": "a quote is accepted"
        },
        "appointmentScheduledFor": {
            "title": "any of {userName}'s appointments are scheduled"
        },
        "appointmentCancelledFor": {
            "title": "any of {userName}'s appointments are cancelled"
        },
        "invalidUser": "Invalid user",
        "anyAppointmentScheduled": {
            "title": "any appointment is scheduled"
        },
        "anyAppointmentCancelled": {
            "title": "any appointment is cancelled"
        },
        "anyAppointmentCancelledStop": {
            "title": "the appointment is cancelled"
        },
        "invalidAppointmentType": "Invalid appointment type",
        "appointmentScheduled": {
            "title": "({name}) is scheduled",
            "empty": "appointment is scheduled",
            "user": "({user}'s appointment) is scheduled"
        },
        "appointmentCancelled": {
            "title": "({name}) is cancelled",
            "empty": "appointment is cancelled",
            "user": "({user}'s appointment) is cancelled"
        },
        "anyHellosignDocumentSigned": {
            "title": "any HelloSign document is signed"
        },
        "invalidDocument": "Invalid HelloSign document",
        "hellosignDocumentSigned": {
            "title": "({title}) is signed",
            "empty": "HelloSign document is signed"
        },
        "typeform": {
            "invalidDocument": "Invalid Typeform form",
            "anyFormSubmitted": {
                "title": "any Typeform is submitted"
            },
            "formSubmitted": {
                "title": "({title}) is submitted",
                "empty": "Typeform is submitted"
            }
        },
        "hellosignSendDocument": {
            "title": "send ({title}) document",
            "empty": "send HelloSign document"
        },
        "shopifyAnyProductPurchased": {
            "title": "Any Shopify product is purchased"
        },
        "shopifyProductPurchased": {
            "title": "({title}) is purchased",
            "invalid": "Invalid product",
            "empty": "Shopify product is purchased"
        },
        "shopifyAnyCartAbandoned": {
            "title": "Any Shopify cart is abandoned"
        },
        "shopifyCartAbandoned": {
            "title": "a cart with ({title}) is abandoned",
            "invalid": "Invalid product",
            "empty": "Shopify cart is abandoned"
        }
    }
}
</i18n>
