import gql from 'graphql-tag';
import Vue from 'vue';
import sentry from '@/analytics/sentry';
import firebase from 'firebase/app';
import 'firebase/database';
import amplitude from '@/analytics/amplitude';
import { EHAWK_NOT_PASSED_ERROR } from '@/ehawk/constants/ehawk.constants';

export default {
    VALIDATE_EMAIL_EHAWK(context, { email, revet }) {
        return validateEmailEhawk(context, email, revet);
    },

    CHECK_EHAWK(context, payload) {
        return checkEhawk(context, payload);
    },

    FETCH_EHAWK_CHECK(context) {
        return fetchEhawkCheck(context);
    },

    SAVE_EHAWK_FORM_SUBMISSION(context) {
        return saveEhawkFormSubmission(context);
    },
};

const validateEmailEhawk = async (context, userEmail, revet = false) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation vetUser($userEmail: String!, $revet: Boolean) {
                vetUser(userEmail: $userEmail, revet: $revet) {
                    passed
                }
            }
        `,
        variables: {
            userEmail,
            revet,
        },
        fetchPolicy: 'no-cache',
    })
        .then(async ({ data: { vetUser: { passed } } }) => {
            await saveEhawkCheck(context, passed);
            await fetchEhawkCheck(context);
        })
        .catch(async (e) => {
            context.commit('SET_EHAWK_PASSED', true);
            sentry.log('Failed to reach eHawk, temporarily passing user', { error: JSON.stringify(e) });
        });
};

const saveEhawkCheck = async ({ rootState }, passed) => {
    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;

    firebase.database().ref(`ehawk/${appId}/${userId}/checked`).set(true);

    return firebase.database().ref(`ehawk/${appId}/${userId}/passed`).set(passed);
};

const checkEhawk = async ({
    rootState, commit, getters, state, dispatch,
}, { eventSource, modalTitle, modalBody }) => {
    if (getters.requiresEhawkCheck) {
        await dispatch('VALIDATE_EMAIL_EHAWK', { email: rootState.auth.user.email, revet: state.checked });

        amplitude.v2.logEvent(amplitude.v2.events.EHAWK_CHECKED, {
            'Event Source': eventSource,
            'eHawk Passed': state.passed,
        });

        if (!state.passed) {
            commit('SET_EHAWK_MODAL_CONTENT', { modalTitle, modalBody });
            commit('SET_EHAWK_MODAL_OPEN', true);

            throw new Error(EHAWK_NOT_PASSED_ERROR);
        }
    }
};

const fetchEhawkCheck = async ({ rootState, commit }) => {
    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;
    const snapshot = await firebase.database().ref(`ehawk/${appId}/${userId}`).once('value');

    const ehawkStatus = snapshot.val() ? { ...snapshot.val() } : {};

    commit('SET_EHAWK_PASSED', ehawkStatus?.passed ? ehawkStatus.passed : false);
    commit('SET_EHAWK_CHECKED', ehawkStatus?.checked ? ehawkStatus.checked : false);
    commit('SET_EHAWK_FORM_SUBMITTED', ehawkStatus?.formSubmitted ? ehawkStatus.formSubmitted : false);
};

const saveEhawkFormSubmission = async({ rootState, commit }) => {
    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;

    await firebase.database().ref(`ehawk/${appId}/${userId}/formSubmitted`).set(true);

    commit('SET_EHAWK_FORM_SUBMITTED', true);
};
