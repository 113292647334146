<template>
    <side-nav-menu-panel :is-expanded="isExpanded" class="secondary-nav">
        <template v-if="isExpanded">
            <ds-icon-button
                class="nav-icon-button-inverted toggle-expand-button"
                :aria-label="$t('nav.collapse')"
                name="collapse"
                dense
                @click="$emit('collapse')"
            />

            <side-nav-menu-secondary-panel-content
                :heading="secondary.heading"
                :links="secondary.links"
            />

            <template v-if="secondary.relatedLinks">
                <div class="secondary-nav-divider" />

                <side-nav-related-links :links="secondary.relatedLinks" />
            </template>
        </template>
    </side-nav-menu-panel>
</template>

<script>
import SideNavMenuPanel from './SideNavMenuPanel';
import SideNavMenuSecondaryPanelContent from './SideNavMenuSecondaryPanelContent';
import SideNavRelatedLinks from './SideNavRelatedLinks';

export default {
    components: {
        SideNavMenuPanel,
        SideNavMenuSecondaryPanelContent,
        SideNavRelatedLinks,
    },

    props: {
        secondary: Object,
        isExpanded: Boolean,
    },
};
</script>

<style lang="scss" scoped>
@import "./side-nav-variables";

.nav-icon-button-inverted {
    color: $color-paper;

    &:hover,
    &:focus {
        background-color: $side-nav-color-tertiary;
    }
}

.toggle-expand-button {
    position: absolute;
    top: $gp / 2;
    right: $gp / 2;
}

.secondary-nav {
    background: $side-nav-color-secondary;
}

.secondary-nav-divider {
    border-bottom: px-to-rem(1) solid $color-paper-600;
    @include margin($gp / 2 0 $gp);
    @include padding($gp / 2 $gp 0 px-to-rem(42));
    width: 100%;
}

.secondary-nav-expanded {
    transform: translateX(0);
}
</style>

<i18n>
{
    "en-us": {
        "nav.collapse": "Collapse Nav"
    }
}
</i18n>
