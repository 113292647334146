<template>
    <controlled-modal
        :is-open="isOpen"
        class="share-feedback-modal"
        size="med"
        show-close
        :title="$t('modalTitle')"
        @close="close"
    >
        <div class="form-header">
            <h3>{{ $t('header.line1') }}</h3>

            <h3>{{ $t('header.line2') }}</h3>
        </div>

        <form
            ref="form"
            data-qa="feedback-form"
            novalidate
            @submit.prevent="submitFeedback"
        >
            <label>{{ $t('feature.label') }}</label>

            <ds-select-field
                v-model="selectedFeature"
                bind-value-only
                :options="featureOptions"
                :label="$t('feature.placeholder')"
                required
                :submitted="submitted"
            />

            <label>{{ $t('comments.label') }}</label>

            <ds-text-area-field
                v-model="feedback"
                :label="$t('comments.placeholder')"
                required
                :submitted="submitted"
            />

            <label>{{ $t('contact.label') }}</label>

            <ds-radio-group
                v-model="contactConfirm"
                :options="contactConfirmOptions"
            />

            <div class="button-row">
                <ds-filled-button :loading="submitting" type="submit">
                    {{ $t('submitButton') }}
                </ds-filled-button>

                <ds-text-button data-qa="cancel-button" @click="close">
                    {{ $t('global.cancel') }}
                </ds-text-button>
            </div>
        </form>
    </controlled-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import { SLACK_CHANNELS } from '@/shared/constants/slack.constants';

import ControlledModal from '@/shared/components/ControlledModal';

const defaultFeatures = {
    contacts: { label: 'nav.contacts', slackChannel: SLACK_CHANNELS.CONTACTS_FEEDBACK },
    dashboard: { label: 'homeLabel' },
    messages: { label: 'nav.communication', slackChannel: SLACK_CHANNELS.COMMUNICATIONS_FEEDBACK },
    settings: { label: 'nav.settings.label' },
    tasks: { label: 'nav.tasks' },
};

const appSpecificFeatures = {
    appointments: { label: 'nav.calendar' },
    automations: { label: 'nav.automations', slackChannel: SLACK_CHANNELS.AUTOMATIONS_FEEDBACK },
    broadcasts: { label: 'nav.broadcasts' },
    companies: { label: 'contactList.companies.title', slackChannel: SLACK_CHANNELS.COMPANIES_FEEDBACK },
    money: { label: 'nav.money' },
    pipeline: { label: 'nav.pipeline' },
    reports: { label: 'nav.reports' },
    other: { label: 'global.other' },
};

export default {
    components: {
        ControlledModal,
    },

    data() {
        return {
            selectedFeature: '',
            feedback: '',
            contactConfirm: 'Yes',
            submitted: false,
            submitting: false,
        };
    },

    watch: {
        isOpen(value) {
            if (value) {
                this.reset();
            }
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            appName: ({ auth }) => auth.account.appName,
            editionName: ({ auth }) => auth.account.editionName,
            isOpen: ({ global }) => global.feedbackModal.isOpen,
            feature: ({ global }) => global.feedbackModal.feature,
        }),

        ...mapGetters({
            hasAppointmentsFeature: 'auth/hasAppointmentsFeature',
            hasAnyAutomationsFeature: 'automations/hasAnyAutomationsFeature',
            hasSmsBroadcastFeature: 'auth/hasSmsBroadcastFeature',
            hasEmailBroadcastFeature: 'auth/hasEmailBroadcastFeature',
            hasUnlayerEmailBuilderFeature: 'auth/hasUnlayerEmailBuilderFeature',
            hasCompaniesFeature: 'auth/hasCompaniesFeature',
            hasInvoicesAndQuotesFeature: 'auth/hasInvoicesAndQuotesFeature',
            hasAnyPipelineFeature: 'pipeline/hasAnyPipelineFeature',
            hasReportsFeature: 'auth/hasReportsFeature',
            hasAnalyticsFeature: 'auth/hasAnalyticsFeature',
        }),

        featureOptions() {
            return Object.keys(this.features).map((value) => ({
                value,
                label: this.$t(this.features[value].label),
            }));
        },

        contactConfirmOptions() {
            return [
                { value: 'Yes', label: this.$t('contact.yes') },
                { value: 'No', label: this.$t('contact.no') },
            ];
        },

        features() {
            const featureOptions = defaultFeatures;
            const {
                appointments, automations, broadcasts, companies, money, pipeline, reports, other,
            } = appSpecificFeatures;

            if (this.hasAppointmentsFeature) {
                featureOptions.appointments = appointments;
            }

            if (this.hasAnyAutomationsFeature) {
                featureOptions.automations = automations;
            }

            if (this.hasSmsBroadcastFeature || this.hasEmailBroadcastFeature || this.hasUnlayerEmailBuilderFeature) {
                featureOptions.broadcasts = broadcasts;
            }

            if (this.hasCompaniesFeature) {
                featureOptions.companies = companies;
            }

            if (this.hasInvoicesAndQuotesFeature) {
                featureOptions.money = money;
            }

            if (this.hasAnyPipelineFeature) {
                featureOptions.pipeline = pipeline;
            }

            if (this.hasReportsFeature || this.hasAnalyticsFeature) {
                featureOptions.reports = reports;
            }

            featureOptions.other = other;

            return featureOptions;
        },
    },

    methods: {
        async submitFeedback() {
            this.submitted = true;

            if (!this.$refs.form.checkValidity()) {
                return;
            }

            this.submitting = true;

            const [first, ...rest] = this.selectedFeature;
            const featureFormatted = [first.toUpperCase(), ...rest].join('');

            const text = [
                `User Name: ${this.user.givenName} ${this.user.familyName}`,
                `Feature: ${featureFormatted}`,
                `Permission to Contact: ${this.contactConfirm}`,
                `Edition: ${this.editionName}`,
                `Feedback: ${this.feedback}`,
            ];

            if (process.env.VUE_APP_ENV === 'production') {
                if (this.features[this.selectedFeature].slackChannel) {
                    await this.$slack(this.features[this.selectedFeature].slackChannel, {
                        pretext: `App *${this.appName}* left feedback.`,
                        text: text.join('\n'),
                        user: this.user,
                    });
                }

                await this.$slack(SLACK_CHANNELS.IN_APP_FEEDBACK, {
                    pretext: `App *${this.appName}* left feedback.`,
                    text: text.join('\n'),
                    user: this.user,
                });

                await fetch(`${process.env.VUE_APP_IN_APP_FEEDBACK_FORM_URL}?entry.1471726154=${this.appName}&entry.2018143641=${this.user.email}&entry.1422067615=${this.user.givenName} ${this.user.familyName}&entry.1025761983=${featureFormatted}&entry.1543871348=${this.contactConfirm}&entry.1927302053=${this.editionName}&entry.830614976=${this.feedback}&submit=Submit`, {
                    method: 'GET',
                    mode: 'no-cors',
                    cache: 'no-cache',
                    credentials: 'omit',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                });
            } else {
                await this.$slack(SLACK_CHANNELS.IN_APP_FEEDBACK_DEV, {
                    pretext: `App *${this.appName}* left feedback.`,
                    text: text.join('\n'),
                    user: this.user,
                });
            }

            this.$toast({ message: this.$t('successMessage') });

            this.close();
        },

        close() {
            this.$store.commit('CLOSE_FEEDBACK_MODAL');
        },

        reset() {
            this.submitted = false;
            this.submitting = false;
            this.feedback = '';
            this.contactConfirm = 'Yes';
            this.selectedFeature = this.features[this.feature] ? this.feature : '';
        },
    },
};
</script>

<style lang="scss" scoped>
    .share-feedback-modal {
        --radio-padding: 0 0 #{$gp} #{$gp};
    }

    .form-header {
        text-align: center;
        margin-bottom: $gp * 1.5;

        @media ($extra-small) {
            h3 {
                font-size: $font-size-lg;
            }
        }
    }

    form {
        width: 100%;
        max-width: $form-width;
        margin: 0 auto;

        label {
            margin-bottom: $gp;
        }
    }

    .button-row {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "modalTitle": "Submit feedback",
        "header": {
            "line1": "Our team thrives on feedback.",
            "line2": "Please tell us about your experience."
        },
        "feature": {
            "label": "Select a feature to provide feedback about:",
            "placeholder": "Select a feature"
        },
        "comments": {
            "label": "What would you like us to know?",
            "placeholder": "Add your comments"
        },
        "contact": {
            "label": "Can we contact you if we have follow-up questions?",
            "yes": "Yes, please contact me",
            "no": "No thanks"
        },
        "submitButton": "Submit feedback",
        "homeLabel": "Home (Dashboard)",
        "successMessage": "Thank you! Your feedback has been submitted."
    }
}
</i18n>
