export default {
    isCountrySupported(state, getters, rootState) {
        const { appCountryCode } = rootState.global;
        const supportedCounties = ['CA', 'GB', 'UK', 'US'];

        return supportedCounties.some((country) => country === appCountryCode);
    },

    isUK(state, getters, rootState) {
        const { appCountryCode } = rootState.global;
        const supportedCounties = ['GB', 'UK'];

        return supportedCounties.some((country) => country === appCountryCode);
    },

    isCanada(state, getters, rootState) {
        const { appCountryCode } = rootState.global;

        return appCountryCode === 'CA';
    },

    isUS(state, getters, rootState) {
        const { appCountryCode } = rootState.global;

        return appCountryCode === 'US';
    },

    wePayCountryCode(state, getters, rootState) {
        const { appCountryCode } = rootState.global;

        if (appCountryCode === 'GB') {
            return 'UK';
        }

        return appCountryCode;
    },

    hasActivePaymentAccount(state) {
        const { paymentAccounts } = state;

        return paymentAccounts.some(({ accountState }) => accountState === 'active');
    },

    getProductById: ({ products }) => (id) => {
        return products.find((product) => {
            // eslint-disable-next-line eqeqeq
            return product.id == id;
        });
    },
};
