<template>
    <ds-multiselect
        v-model="internalValue"
        bind-value-only
        data-qa="sms-phone-type-selector"
        multiple
        :options="options"
        :placeholder="$t('placeholder')"
        required
        searchable
        :submitted="submitted"
        @input="handleInput"
    >
        <template #help>
            {{ helpText }}
        </template>
    </ds-multiselect>
</template>

<script>
export default {
    props: {
        showError: Boolean,
        value: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            internalValue: [],
            options: [
                { value: 'Mobile', label: this.$t('types.mobile') },
                { value: 'Work', label: this.$t('types.work') },
                { value: 'Home', label: this.$t('types.home') },
                { value: 'Other', label: this.$t('types.other') },
            ],
        };
    },

    watch: {
        value: {
            handler(value) {
                this.internalValue = value;
            },
            immediate: true,
            deep: true,
        },
    },

    computed: {
        helpText() {
            return this.$t(
                this.submitted
                    ? 'errorText'
                    : 'helpText',
            );
        },

        submitted() {
            return this.showError && this.internalValue.length === 0;
        },
    },

    methods: {
        handleInput(value) {
            this.internalValue = value;
            this.$emit('input', value);
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "placeholder": "Send to these phone types",
        "contactPhoneType": "Contact phone type",
        "types": {
            "mobile": "Mobile",
            "work": "Work",
            "home": "Home",
            "other": "Other"
        },
        "helpText": "Phone types are managed on the contact record",
        "errorText": "Please select phone type(s) to send to."
    }
}
</i18n>
