import snippetTemplate from '@/shared/utils/embedSnippet.raw';

export const embedContent = (form, appId) => {
    const div = document.createElement('div');
    const script = document.createElement('script');
    const scriptText = snippetTemplate.replace(/\${appId}/g, appId)
        .replace(/\${FORMS_URL}/g, process.env.VUE_APP_FORMS_URL);

    div.dataset.formSlug = form.slug;
    div.dataset.env = process.env.VUE_APP_ENV_NAME;
    div.dataset.path = `contact-us/${div.dataset.formSlug}`;
    div.className = 'keap-custom-form';
    script.text = scriptText;

    return div.outerHTML + script.outerHTML;
};

export const checkoutFormEmbedContent = (slug, appId) => {
    const div = document.createElement('div');
    const script = document.createElement('script');
    const scriptText = snippetTemplate.replace(/\${appId}/g, appId)
        .replace(/\${FORMS_URL}/g, process.env.VUE_APP_PUBLIC_URL);

    div.dataset.formSlug = `${appId}/${slug}`;
    div.dataset.env = process.env.VUE_APP_ENV_NAME;
    div.dataset.path = `checkout/${div.dataset.formSlug}?embed=true`;
    div.className = 'keap-checkout-form';
    script.text = scriptText;

    return div.outerHTML + script.outerHTML;
};
