import Vue from 'vue';

export default {
    SET_COMPANY_INFO(state, companyInfo) {
        state.companyInfo = companyInfo;
    },

    SET_LOCALIZATION_INFO(state, localizationInfo) {
        state.localizationInfo = localizationInfo;
    },

    SET_CAS_EDIT_PROFILE_URL(state, url) {
        state.editProfileUrl = url;
    },

    SET_MAX_TOTAL_CUSTOM_FIELDS(state, maxTotalCustomFields) {
        state.maxTotalCustomFields = maxTotalCustomFields;
    },

    SET_NOTIFICATION_PREFERENCES(state, preference) {
        state.notificationPreferences = preference;
    },

    SET_USERS(state, users) {
        state.users = users;
    },

    SET_USER(state, user) {
        state.user = user;
    },

    SET_ROLES(state, roles) {
        state.roles = roles;
    },

    SET_USER_ROLE(state, role) {
        state.user.userRole = role;
    },

    SET_NUM_USERS_LEFT(state, numUsersLeft) {
        state.numUsersLeft = numUsersLeft;
    },

    SET_EMAIL_DOMAINS(state, domains) {
        state.domains = domains;
    },

    SET_INTEGRATIONS(state, integrations) {
        state.integrations = integrations;
    },

    SET_BLENDR_TEMPLATES(state, templates) {
        Vue.set(
            state.integrations,
            'blendrTemplates',
            templates.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (b.name > a.name) return -1;

                return 0;
            }),
        );
    },

    SET_BLENDR_BLENDS(state, blends) {
        Vue.set(
            state.integrations,
            'blendrBlends',
            blends.sort((a, b) => {
                if (a.name > b.name) return 1;
                if (b.name > a.name) return -1;

                return 0;
            }),
        );
    },

    SET_BLENDR_SSO_JWT(state, jwt) {
        state.integrations.blendrSsoJWT = jwt;
    },

    SET_HELLOSIGN_DOCUMENTS(state, documents) {
        Vue.set(state.integrations, 'hellosignDocuments', documents || []);
    },

    SET_TYPEFORM_FORMS(state, forms) {
        Vue.set(state.integrations, 'typeformForms', forms || []);
    },

    SET_SHOPIFY_PRODUCTS(state, products) {
        Vue.set(state.integrations, 'shopifyProducts', products || []);
    },

    SET_ALL_BLENDR_DATASOURCES(state, dataSources) {
        Vue.set(state.integrations, 'allBlendrDataSources', dataSources || []);
    },

    SET_USED_BLENDR_DATASOURCES(state, dataSources) {
        Vue.set(state.integrations, 'usedBlendrDataSources', dataSources || []);
    },
};
