<template lang="html">
    <form data-qa="new-appointment-from-calendar" @submit.prevent="submit">
        <contact-search-field
            v-model="contact"
            allow-add
            :placeholder="$t('placeholders.search')"
            :submitted="submitted"
            required
            data-qa="select-contact-new-appointment"
            @add="handleAddNewContact"
            @input="resetPhone"
        />

        <ds-input-field
            v-if="showAddEmailField"
            v-model.trim="email"
            type="email"
            :invalid="emailInvalid"
            :label="emailFieldPlaceholder"
            :submitted="emailValidated"
            autocomplete="no"
            data-qa="add-email-for-contact"
            name="addContactEmail"
            required
            @input="handleEmailInput"
            @blur="handleEmailBlur"
        >
            <template #help>
                <small class="help-text">
                    {{ emailFieldHelpText }}
                </small>
            </template>

            <template #error>
                <small class="error-text">
                    {{ $t('helpText.validEmail') }}
                </small>
            </template>
        </ds-input-field>

        <ds-multiselect
            v-model="appointmentType"
            :placeholder="$t('placeholders.appointmentType')"
            :options="appointmentTypeDropdownOptions"
            :submitted="submitted"
            required
            data-qa="select-appointment-type"
            action-type="schedule"
            @input="location = null"
        />

        <template v-if="selectedAppointmentType">
            <ds-multiselect
                v-if="displayPhoneDropdown"
                v-model="phoneNumber"
                :allow-add="phoneNumberOptions.length<5"
                :add-text="$t('phoneOptions.ADD')"
                :loading="contactDetailsLoading"
                :placeholder="$t('placeholders.phone')"
                :options="phoneNumberOptions"
                data-qa="select-phone-number"
                action-type="schedule"
                required
                @add="addPhone"
            />

            <ds-phone-input
                v-else-if="displayPhoneInput"
                v-model="phoneNumber"
                :label="$t('placeholders.phone')"
                :submitted="submitted"
                required
                type="tel"
                class="fs-block"
                data-qa="location-field"
            />

            <address-autocomplete
                v-else-if="selectedAppointmentType.locationType === LOCATION_TYPES.CLIENT_SET_IN_PERSON"
                v-model.trim="location"
                required
                use-selected-as-value
                data-qa="location-field"
                :label="$t('placeholders.address')"
            >
                <template #help>
                    <small>
                        {{ $t('helpText.address') }}
                    </small>
                </template>
            </address-autocomplete>

            <ds-input-field
                v-else-if="selectedAppointmentType.locationType === LOCATION_TYPES.CLIENT_SET_ONLINE"
                v-model="location"
                type="url"
                required
                class="fs-block"
                data-qa="location-field"
                :label="$t('placeholders.online')"
                @blur="handleBlur(location)"
            >
                <template #help>
                    <small>{{ $t('helpText.online') }}</small>
                </template>
            </ds-input-field>
        </template>

        <ds-filled-button
            :loading="submitting"
            :disabled="buttonDisabled"
            type="submit"
            data-qa="continue-to-select-time"
        >
            {{ $t('submitLabel') }}
        </ds-filled-button>
    </form>
</template>

<script>
import { mapState } from 'vuex';
import { urlUtils, patterns } from '@infusionsoft/vue-utils';
import { queryGetContact } from '@/contacts/store/queries';
import { displayFullName } from '@/contacts/contact-info-utils';
import { LOCATION_TYPES } from '@/appointments/appointments.constants';
import { IDLE, LOADING, SUCCESS } from '@/shared/constants/loadingStatuses.constants';
import AddContactModal from '@/contacts/components/add/AddContactModal';
import BookAppointmentModalContent from '@/appointments/components/BookAppointmentModalContent';
import ContactSearchField from '@/contacts/components/ContactSearchField';
import AddressAutocomplete from '@/shared/components/Fields/AddressAutocomplete';
import sentry from '@/analytics/sentry';
import axios from 'axios';
import { patchContact } from '@/contacts/api';

const phoneLines = ['phone1', 'phone2', 'phone3', 'phone4', 'phone5'];

const fetchContact = async (id) => {
    const contact = await queryGetContact(id);

    return {
        id,
        ...contact,
    };
};

const fetchContactDetails = (id) => {
    const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordDetails/${id}`;

    return axios.get(url).then(({ data }) => data.contact);
};

const UNSUBMITTED = 'UNSUBMITTED';
const SUBMITTED = 'SUBMITTED';
const SUBMITTING = 'SUBMITTING';

export default {
    name: 'NewAppointmentFromCalendar',

    components: {
        ContactSearchField,
        AddressAutocomplete,
    },

    data() {
        return {
            LOCATION_TYPES,
            location: null,
            contact: null,
            email: '',
            appointmentType: null,
            phoneNumber: null,
            status: UNSUBMITTED,
            emailValidated: false,
            useNewPhone: false,
            emailInvalid: false,
            contactDetails: null,
            contactDetailsLoadingStatus: IDLE,
            patchContact: false,
            createdContactIsSet: false,
        };
    },

    mounted() {
        if (this.appointmentTypeDropdownOptions.length > 0) {
            this.setAppointmentType(this.appointmentTypeDropdownOptions[0]);
        }
    },

    created() {
        this.$bus.$on('CONTACT_ADDED', this.setCreatedContact);
    },

    beforeDestroy() {
        this.$bus.$off('CONTACT_ADDED');
    },

    watch: {
        contact({ fullName, email }) {
            if (!fullName && email) {
                this.contact.fullName = email;
            }
        },
    },

    computed: {
        ...mapState({
            appointmentTypes: ({ calendar }) => calendar.apptTypes,
        }),

        isLocationClientSet() {
            return [
                LOCATION_TYPES.CLIENT_SET_PHONE,
                LOCATION_TYPES.CLIENT_SET_IN_PERSON,
                LOCATION_TYPES.CLIENT_SET_ONLINE,
            ].includes(this.selectedAppointmentType.locationType);
        },

        nestedModal_title() {
            return this.$t('title');
        },

        nestedModal_subTitle() {
            return this.$t('subtitle');
        },

        appointmentTypeDropdownOptions() {
            return this.appointmentTypes.map((apptType) => {
                const duration = this.$tc('global.time.interval.abbr.minute', apptType.durationMinutes, { count: apptType.durationMinutes });

                return {
                    value: apptType.id,
                    label: `${apptType.name} | ${duration}`,
                };
            });
        },

        selectedContactName() {
            return this.contact && this.contact.fullName;
        },

        submitted() {
            return this.status === SUBMITTED;
        },

        submitting() {
            return this.status === SUBMITTING;
        },

        selectedAppointmentType() {
            return this.appointmentType
                ? this.appointmentTypes.find(({ id }) => id === this.appointmentType.value)
                : null;
        },

        hasContact() {
            return this.contact && Object.keys(this.contact).length > 0;
        },

        phoneNumberOptions() {
            const phoneOptions = [];

            if (this.contactDetails != null) {
                phoneLines.forEach((phoneLine) => {
                    if (this.contactDetails[phoneLine]?.value) {
                        const phoneLabel = `phoneOptions.${this.contactDetails[phoneLine].type}`;

                        phoneOptions.push({
                            label: `${this.$t(phoneLabel)} ${this.contactDetails[phoneLine].value}`,
                            value: this.contactDetails[phoneLine].value,
                        });
                    }
                });
            }

            return phoneOptions;
        },

        isValidEmail() {
            return RegExp(patterns.email).test(this.email);
        },

        updateContactPhoneNumber() {
            return this.phoneNumberOptions.length && (this.selectedAppointmentType?.locationType === LOCATION_TYPES.CLIENT_SET_PHONE);
        },

        displayPhoneDropdown() {
            return (this.selectedAppointmentType?.locationType === LOCATION_TYPES.CLIENT_SET_PHONE && this.contact && this.phoneNumberOptions.length && !this.useNewPhone) || (this.selectedAppointmentType.locationType === LOCATION_TYPES.CLIENT_SET_PHONE && this.contactDetailsLoading);
        },

        displayPhoneInput() {
            return this.selectedAppointmentType?.locationType === LOCATION_TYPES.CLIENT_SET_PHONE && this.contact && (!this.phoneNumberOptions.length || this.useNewPhone);
        },

        contactDetailsSuccess() {
            return this.contactDetailsLoadingStatus === SUCCESS;
        },

        contactDetailsLoading() {
            return this.contactDetailsLoadingStatus === LOADING;
        },

        formEmailValid() {
            if (!this.contact?.email) {
                if ((this.email && this.emailValidated && !this.emailInvalid)) {
                    return true;
                }

                return false;
            }

            return true;
        },

        buttonDisabled() {
            if (this.selectedAppointmentType?.locationType === LOCATION_TYPES.CLIENT_SET_PHONE) {
                if (!this.formEmailValid || !this.phoneNumber) {
                    return true;
                }

                return false;
            }

            return !this.formEmailValid;
        },

        emailFieldHelpText() {
            if (this.emailValidated && !this.emailInvalid) {
                return '';
            }

            return this.$t('helpText.contactEmail');
        },

        emailFieldPlaceholder() {
            const name = this.contactDetails?.firstName || this.contact.fullName;

            return this.$t('placeholders.email', { name });
        },

        showAddEmailField() {
            return this.hasContact
                && !this.contact.email
                && !this.contactDetailsLoading
                && (this.contactDetailsSuccess || this.createdContactIsSet);
        },
    },

    methods: {
        nestedModal_open({ id }) {
            if (id) {
                const option = this.appointmentTypeDropdownOptions.find(({ value }) => value === id);

                this.setAppointmentType(option);
            }
        },

        async setCreatedContact(contact) {
            this.contact = {
                ...contact,
                fullName: displayFullName(contact),
            };

            this.contactDetails = await fetchContactDetails(contact.id);
            this.createdContactIsSet = true;
        },

        nestedModal_close() {
            this.$bus.$emit('POP_ALL_NESTED_MODALS');
        },

        handleAddNewContact() {
            this.createdContactIsSet = false;

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddContactModal,
                data: { hideSuccessModal: true },
            });
        },

        setAppointmentType(option) {
            this.appointmentType = option;
        },

        handleBlur(location) {
            this.location = urlUtils.formatUrl(location);
        },

        addPhone() {
            this.useNewPhone = true;
            this.phoneNumber = null;
        },

        async submit() {
            this.status = SUBMITTING;

            if (this.email && !this.contact.email) {
                this.contactDetails.email = this.email;
                this.patchContact = true;
            }

            if (this.selectedAppointmentType?.locationType === LOCATION_TYPES.CLIENT_SET_PHONE) {
                if (this.useNewPhone || !this.phoneNumberOptions.length) {
                    this.location = this.phoneNumber;
                    this.setContactPhone();
                    this.patchContact = true;
                } else {
                    this.location = this.phoneNumber.value;
                }
            }

            if (this.patchContact) {
                try {
                    this.contactDetailsLoadingStatus = LOADING;
                    await patchContact({ id: this.contact.id, ...this.contactDetails });
                } catch (e) {
                    sentry.log('Unable to update contact when booking appointment', { error: JSON.stringify(e) });
                }
            }

            const appointmentContact = await fetchContact(this.contact.id);

            const appointmentType = {
                ...this.selectedAppointmentType,
                location: this.isLocationClientSet ? this.location : this.selectedAppointmentType.location,
            };

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: BookAppointmentModalContent,
                props: {
                    appointmentType,
                    contact: appointmentContact,
                },
                showRootClose: true,
                modalSize: 'xl',
            });

            this.email = '';
            this.status = UNSUBMITTED;
            this.contactDetailsLoadingStatus = SUCCESS;
        },

        handleEmailBlur() {
            this.emailValidated = true;

            if (this.email && !this.isValidEmail) {
                this.emailInvalid = true;
            } else if (!this.email) {
                this.emailInvalid = false;
            }
        },

        handleEmailInput() {
            this.emailInvalid = false;
            this.emailValidated = false;
        },

        async resetPhone(contact) {
            this.useNewPhone = false;
            this.phoneNumber = null;

            try {
                this.contactDetailsLoadingStatus = LOADING;
                this.contactDetails = await fetchContactDetails(contact.id);
                this.contactDetailsLoadingStatus = SUCCESS;
            } catch (e) {
                sentry.log('Unable to fetch conatct details for appointment booking', { error: JSON.stringify(e) });
            }
        },

        setContactPhone() {
            const phoneToAdd = `phone${this.phoneNumberOptions.length + 1}`;

            this.contactDetails[phoneToAdd] = {
                value: this.phoneNumber,
                type: 'OTHER',
            };
        },
    },
};

</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    @import '~@/appointments/styles/appointments';

    form {
        height: px-to-rem(412px);
        max-width: px-to-rem(960px);
        width: 100%;
        @include centeredContainer;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "New appointment",
        "subtitle": "Select a contact and appointment type",
        "submitLabel": "Continue and select time",
        "placeholders": {
            "phone": "Contact's phone number",
            "online": "Your online meeting link",
            "address": "Where will we meet?",
            "search": "Search for a contact to meet with",
            "appointmentType": "Appointment type",
            "email": "{name}'s email address",
            "emailAddress": "Email address"
        },
        "helpText": {
            "online": "Link to your Zoom, Webex, Skype, etc...",
            "contactEmail": "Please add an email address for this contact to book an appointment",
            "validEmail": "Please enter a valid email address",
            "address": "Select an address or location"
        },
        "phoneOptions": {
            "PHONE": "Phone",
            "MOBILE": "Mobile",
            "HOME": "Home",
            "WORK": "Work",
            "OTHER": "Other",
            "ADD": "Add new phone number"
        }
    }
}
</i18n>
