import Vue from 'vue';
import intercom from '@/analytics/intercom';

export default {
    SET_APP_TASKS(state, { tasks, total }) {
        state.totalTasks = total;
        tasks.forEach((task) => {
            task.completed = Boolean(task.completionDate);
        });

        state.tasks = tasks;
    },

    CLEAR_APP_TASKS(state) {
        state.totalTasks = 0;
        state.tasks = [];
    },

    ADD_APP_TASKS(state, { tasks, total }) {
        state.totalTasks = total;
        tasks.forEach((task) => {
            const index = state.tasks.findIndex((t) => t.id === task.id);

            task.completed = Boolean(task.completionDate);

            if (index > -1) {
                Vue.set(state.tasks, index, task);
            } else {
                state.tasks.push(task);
            }
        });

        intercom.logEvent(intercom.events.TASK_CREATED);
    },

    SET_TASK(state, task) {
        state.task = task;
    },

    SET_FILTERED_BY(state, filteredBy) {
        state.filteredBy = filteredBy;
    },

    UPDATE_APP_TASK(state, task) {
        const index = state.tasks.findIndex((t) => t.id === task.id);

        task.completed = Boolean(task.completionDate);

        Vue.set(state.tasks, index, task);
    },

    REMOVE_APP_TASK(state, taskId) {
        const index = state.tasks.findIndex((t) => t.id === taskId);

        if (index > -1) {
            state.totalTasks--;
            state.tasks.splice(index, 1);
        }
    },
};
