import axios from 'axios';
import createRenderer from '@infusionsoft/bard-email-renderer';
import Vue from 'vue';
import sentry from '@/analytics/sentry';
import intercom from '@/analytics/intercom';
import gql from 'graphql-tag';
import moment from 'moment';

import { legacyBardJsonFromHtml } from '@/shared/utils/bard.util';
import * as pipelineApi from '@/pipeline/store/api/pipeline';
import * as dealApi from '@/pipeline/store/api/deal';
import * as stageApi from '@/pipeline/store/api/stage';
import {
    addPipelineStage,
    deletePipelineStage,
    updatePipelineStage,
    deletePipelineStageAndMoveDeals,
} from '@/pipeline/store/api/stage';

import {
    FF_KEAP_SAMPLE_DATA,
    FF_KEAP_PIPELINE_MIGRATION,
    FF_KEAP_SINGLE_PIPELINE,
} from '@/shared/constants/featureFlag.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { PREFERENCE_TYPES } from '@/shared/constants/preferences.constants';
import { filterOptions, operators } from '@/pipeline/pipeline.constants';
import { DATE_FORMAT } from '@/shared/constants/dateFormats.constants';
import { STATUS_OPTIONS } from '@/pipeline/components/deal/DealStatus';

export default {
    ADD_PIPELINE_DEAL(context, payload) {
        return addPipelineDeal(context, payload);
    },

    ADD_PIPELINE_STAGE({ state }, name) {
        return addPipelineStage({
            name,
            pipelineId: state.pipeline.id,
        })
            .then(({ data }) => {
                return data.addPipelineStage;
            })
            .catch((e) => {
                sentry.log('Add pipeline stage failed', { message: e.message });

                throw e;
            });
    },

    async DELETE_PIPELINE_STAGE({ commit }, { id, newId }) {
        if (newId) {
            await deletePipelineStageAndMoveDeals({ stageId: id, newStageId: newId });
        } else {
            await deletePipelineStage({ stageId: id });
        }

        commit('DELETE_STAGE', { id, newId });
    },

    LOAD_PIPELINE_DETAILS(context, payload) {
        return loadPipeline(context, payload);
    },

    LOAD_PIPELINE_LIST(context, payload) {
        return loadPipelineList(context, payload);
    },

    LOAD_PIPELINE_TASKS(context, payload) {
        return loadPipelineTasks(context, payload);
    },

    SAVE_PIPELINE_LIST(context, payload) {
        return savePipelines(context, payload);
    },

    CREATE_PIPELINE(context, payload) {
        return createPipeline(context, payload);
    },

    DELETE_PIPELINE(context, payload) {
        return deletePipeline(context, payload);
    },

    DELETE_DEAL(context, payload) {
        return deleteDeal(context, payload);
    },

    GET_DEAL({ commit, state, rootState }, id) {
        return new Promise((resolve, reject) => {
            if (state.pipeline.id) {
                getDeal({ commit, rootState }, id)
                    .then(resolve)
                    .catch(reject);
            } else {
                loadPipeline({ commit, rootState })
                    .then(() => {
                        getDeal({ commit, rootState }, id)
                            .then(resolve)
                            .catch(reject);
                    })
                    .catch(reject);
            }
        });
    },

    REFRESH_DEAL(context, payload) {
        return refreshDeal(context, payload);
    },

    UPDATE_DEAL(context, payload) {
        return updateDeal(context, payload);
    },

    UPDATE_PIPELINE_STAGE({ state }, { stage, newIndex }) {
        const { pipeline } = state;
        const previousStage = newIndex > 0 ? pipeline.stages[newIndex - 1] : null;
        const nextStage = newIndex < pipeline.stages.length ? pipeline.stages[newIndex + 1] : null;

        const previousStageId = previousStage ? previousStage.id : null;
        const nextStageId = nextStage && !previousStageId ? nextStage.id : null;

        const payload = {
            name: stage.name,
            pipelineId: pipeline.id,
            previousStageId: previousStageId || undefined,
            nextStageId: !previousStageId ? nextStageId : undefined,
        };

        return updatePipelineStage({ stageId: stage.id, payload });
    },

    UPDATE_PIPELINE_STAGE_NAME({ commit, state: { pipeline } }, stage) {
        const payload = {
            pipelineId: pipeline.id,
            name: stage.name,
        };

        commit('SET_PIPELINE_STAGE_NAME', stage);

        return updatePipelineStage({ stageId: stage.id, payload });
    },

    SET_DEAL(context, deal) {
        return setDeal(context, deal);
    },

    SET_ACTIVE_DEAL(context, deal) {
        return setActiveDeal(context, deal);
    },

    GET_DEAL_NOTES(context) {
        return getDealNotes(context);
    },

    CREATE_DEAL_NOTE(context, payload) {
        return createDealNote(context, payload);
    },

    UPDATE_DEAL_NOTE(context, payload) {
        return updateDealNote(context, payload);
    },

    LOAD_DEAL_TASKS(context, payload) {
        return loadDealTasks(context, payload);
    },

    DELETE_DEAL_NOTE(context, payload) {
        return deleteDealNote(context, payload);
    },

    REMOVE_CONTACT({ dispatch, commit }, { deal, contactId }) {
        return new Promise((resolve, reject) => {
            commit('REMOVE_CONTACT', { deal, contactId });

            dispatch('UPDATE_DEAL', { deal })
                .then(resolve)
                .catch(reject);
        });
    },

    SET_PRIMARY_CONTACT({ dispatch, commit }, { deal, contactId }) {
        return new Promise((resolve, reject) => {
            commit('SET_PRIMARY_CONTACT', { deal, contactId });

            dispatch('UPDATE_DEAL', { deal })
                .then(resolve)
                .catch(reject);
        });
    },

    SEND_DEAL_EMAIL(context, payload) {
        return sendDealEmail(context, payload);
    },

    SEND_DEAL_EMAIL_WITH_BODY(context, payload) {
        return sendDealEmailWithBody(context, payload);
    },

    LOAD_MORE_ACTIVITIES(context, payload) {
        return loadMoreActivities(context, payload);
    },

    UPDATE_OUTCOME_OPTIONS(context, payload) {
        return updateOutcomeOptions(context, payload);
    },

    GET_OUTCOME_OPTIONS(context, payload) {
        return getOutcomeOptions(context, payload);
    },

    SAVE_PIPELINE_FILTERS(context, payload) {
        return savePipelineFilters(context, payload);
    },

    INITIALIZE_PIPELINE_FILTERS(context, payload) {
        return initializePipelineFilters(context, payload);
    },

    LOAD_PIPELINE_DEAL_COUNT(context, payload) {
        return loadPipelineDealCount(context, payload);
    },

    LOAD_STAGE_DEAL_COUNT(context, payload) {
        return loadStageDealCount(context, payload);
    },
};

const DEFAULT_FILTER = { fieldName: filterOptions.STATUS.value, operator: operators.INCLUDES_ANY, values: [STATUS_OPTIONS.ACTIVE] };

const loadMoreActivities = ({ state, commit }, { limit, cursor }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/deals/${state.deal.id}/activityFeed?limit=${limit}&cursor=${cursor || ''}`)
            .then(({ data: { activityFeedEvents, cursor: newCursor } }) => {
                commit('ADD_ACTIVITIES', activityFeedEvents);
                commit('SET_ACTIVITY_CURSOR', newCursor);
                resolve(activityFeedEvents);
            })
            .catch((e) => {
                sentry.log('Load pipeline deal activities failed', { message: e.message });

                reject(e);
            });
    });
};

const loadPipelineList = async ({
    dispatch,
    commit,
    rootState,
    rootGetters,
}, shouldUpdateIntercom = false) => {
    commit('START_PIPELINE_LIST_LOADING');
    const pipelineMigrationEnabled = rootState.featureFlags[FF_KEAP_PIPELINE_MIGRATION];
    const singlePipelineEnabled = rootState.featureFlags[FF_KEAP_SINGLE_PIPELINE];

    try {
        const { data } = await Vue.prototype.$graphql.query({
            query: gql`
                query getPipelines{
                    ${pipelineMigrationEnabled ? 'pipelinesV2' : 'pipelines'}{
                        id
                        name
                        stages {
                            id
                            name
                            order
                            pipelineId
                        }
                    }
                }
            `,
            fetchPolicy: 'no-cache',
        });

        let pipelines = pipelineMigrationEnabled ? data.pipelinesV2 : data.pipelines;

        if ((singlePipelineEnabled || rootGetters['auth/hasSinglePipelineFeature']) && !rootGetters['auth/hasMultiplePipelinesFeature']) {
            pipelines = pipelines.slice(0, 1);
        }

        commit('SET_PIPELINE_LIST', pipelines);
        commit('STOP_PIPELINE_LIST_LOADING');

        if (shouldUpdateIntercom) {
            intercom.updateUserProperties({
                company: {
                    id: rootState.auth.account.appName,
                    number_of_pipelines: rootState.pipeline.pipelineList.length,
                },
            });
        }

        const pipelineFilters = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.PIPELINE_FILTERS);

        if (!pipelineFilters?.initialized) {
            await dispatch('INITIALIZE_PIPELINE_FILTERS');
        }
    } catch (e) {
        sentry.log('Load pipelines failed', { message: e.message });

        commit('STOP_PIPELINE_LIST_LOADING');

        throw e;
    }
};

const savePipelines = async ({ commit, rootState, rootGetters }, pipelines) => {
    const pipelineMigrationEnabled = rootState.featureFlags[FF_KEAP_PIPELINE_MIGRATION];
    const singlePipelineEnabled = rootState.featureFlags[FF_KEAP_SINGLE_PIPELINE];

    try {
        const { data } = await Vue.prototype.$graphql.mutate({
            mutation: gql`
                mutation updatePipelineList($request: UpdatePipelineListInput) {
                    ${pipelineMigrationEnabled ? 'updatePipelineListV2' : 'updatePipelineList'}(updateRequest: $request) {
                        pipelineList {
                            id
                            name
                            stages {
                                id
                                name
                                order
                                pipelineId
                            }
                        }
                    }
                }
            `,
            variables: {
                request: { pipelineList: pipelines },
            },
            fetchPolicy: 'no-cache',
        });

        const response = pipelineMigrationEnabled ? data.updatePipelineListV2 : data.updatePipelineList;

        let { pipelineList } = response;

        if ((singlePipelineEnabled || rootGetters['auth/hasSinglePipelineFeature']) && !rootGetters['auth/hasMultiplePipelinesFeature']) {
            pipelineList = pipelineList.slice(0, 1);
        }
        commit('SET_PIPELINE_LIST', pipelineList);

        return response.pipelineList;
    } catch (e) {
        sentry.log('Save pipelines failed', { message: e.message });

        throw e;
    }
};

const loadPipeline = async ({
    commit,
    rootState,
}, id) => {
    commit('SET_PIPELINE_LOADING', true);

    try {
        const pipelineFilters = rootState.preferences.preferences[PREFERENCE_TYPES.PIPELINE_FILTERS];

        const { data: { filteredPipeline } } = await pipelineApi.loadPipelineWithFilter(id, transformFiltersRequest(pipelineFilters?.[id]));

        commit('SET_PIPELINE', filteredPipeline);
    } catch (e) {
        commit('SET_PIPELINE', {});
        sentry.log('Load pipeline details failed', { message: e.message });

        throw e;
    } finally {
        commit('SET_PIPELINE_LOADING', false);
    }
};

const createPipeline = async ({ commit, dispatch }, { name, stages }) => {
    try {
        const { data } = await pipelineApi.createPipeline({ name, stages });

        const newPipeline = data.createPipeline;

        commit('ADD_PIPELINE', newPipeline);
        commit('SET_PIPELINE', newPipeline);

        await dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'createPipeline', { root: true });

        await dispatch(
            'tutorials/UPDATE_TUTORIAL_ITEM',
            {
                key: TUTORIAL_TYPES.ONBOARDING.TOURS.PIPELINE,
                value: true,
                forUser: true,
            },
            { root: true },
        );

        dispatch('SAVE_PIPELINE_FILTERS', { pipelineId: newPipeline.id, filters: [DEFAULT_FILTER] });

        intercom.logEvent(intercom.events.PIPELINE_CREATED);

        return newPipeline;
    } catch (e) {
        sentry.log('Create pipeline failed', { message: e.message });
        throw e;
    }
};

const deletePipeline = async ({ dispatch, commit, rootState }, { pipelineId, newStageId }) => {
    const pipelineMigrationEnabled = rootState.featureFlags[FF_KEAP_PIPELINE_MIGRATION];

    try {
        const mutation = gql`
            mutation deletePipeline($pipelineId: ID!, $newStageId: ID) {
                ${pipelineMigrationEnabled ? 'deletePipelineV2' : 'deletePipeline'}(pipelineId: $pipelineId, newStageId: $newStageId)
            }`;

        await Vue.prototype.$graphql.mutate({
            mutation,
            variables: {
                pipelineId,
                newStageId,
            },
            fetchPolicy: 'no-cache',
        });

        dispatch('SAVE_PIPELINE_FILTERS', { pipelineId, filters: [] });

        commit('DELETE_PIPELINE', pipelineId);
    } catch (e) {
        sentry.log('Deleting pipeline failed', { message: e.message });

        throw e;
    }
};

const refreshDeal = ({ commit }, id) => {
    return dealApi.loadDeal(id)
        .then(({ data: { deal } }) => {
            commit('SET_DEAL', { deal });

            return deal;
        })
        .catch((e) => {
            sentry.log('Get pipeline deal failed', { message: e.message });

            throw e;
        });
};

const getDeal = ({ commit }, id) => {
    return dealApi.loadDeal(id)
        .then(({ data: { deal } }) => {
            commit('SET_ACTIVE_DEAL', deal);
        })
        .catch((e) => {
            sentry.log('Get pipeline deal failed', { message: e.message });

            throw e;
        });
};

const setDeal = ({ getters, commit }, deal) => {
    deal.contacts = deal.contacts.map(getters.appendFullName);

    commit('SET_DEAL', { deal });
};

const setActiveDeal = ({ getters, commit }, deal) => {
    deal.owners = deal.owners ? deal.owners.map(getters.appendFullName) : [];
    deal.contacts = deal.contacts.map(getters.appendFullName);
    commit('SET_ACTIVE_DEAL', deal);
};

const updateDeal = async ({
    commit,
    getters,
    state,
    rootState: { auth },
}, {
        deal,
        previousStage,
        newIndex,
        stageId,
    }) => {
    let previousDealId;
    let nextDealId;
    let stage;

    // get the stage with deals in it, to update its position in the stage
    stage = state.pipeline.stages.find((s) => s.id === stageId);

    if (!stage) {
        // if the stage is in another pipeline
        stage = getters.getAllStages.find((s) => s.id === stageId);
    }

    const {
        contacts,
        estimatedCloseDate,
        closedDate,
        name,
        owners,
        status,
        value,
    } = deal;

    if (stage && stage.deals) {
        const previousDeal = newIndex > 0 ? stage.deals[newIndex - 1] : null;
        const nextDeal = newIndex < stage.deals.length ? stage.deals[newIndex + 1] : null;

        previousDealId = previousDeal ? previousDeal.id : null;
        nextDealId = nextDeal && !previousDeal ? nextDeal.id : null;
    }

    const payload = {
        amount: parseFloat(value.amount),
        contacts: contacts.map(({ id: contactId, primaryContact = false }) => ({ id: contactId, primaryContact })),
        currencyCode: value.currency,
        estimatedCloseDate,
        closedDate,
        name,
        owners: owners ? owners.map(({ id: ownerId }) => ({ id: ownerId })) : [],
        stageId: stageId || deal.stage.id,
        status,
        previousDealId: previousDealId || undefined,
        nextDealId: !previousDealId ? nextDealId : undefined,
    };

    try {
        const { data } = await dealApi.updateDeal(deal.id, payload);
        const updatedDeal = data.updateDeal;

        updatedDeal.contacts = updatedDeal.contacts.map(getters.appendFullName);
        updatedDeal.owners = updatedDeal.owners ? updatedDeal.owners.map(getters.appendFullName) : [];
        commit('SET_ACTIVE_DEAL', updatedDeal);
        commit('SET_DEAL', { deal: updatedDeal, newIndex });
        commit('REMOVE_DEAL_FROM_STAGE', { previousStage, deal });

        const eventAuthorUser = deal.owners[0] || auth.user;

        if (previousStage) {
            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: 'dealmovedstage',
                details: {
                    currentStageName: deal.stage.name,
                    eventAuthorFamilyName: eventAuthorUser.familyName,
                    eventAuthorGivenName: eventAuthorUser.givenName,
                    eventAuthorId: eventAuthorUser.id,
                    previousStageName: previousStage.name,
                },
            });
        }

        return updatedDeal;
    } catch (e) {
        sentry.log('Update pipeline deal failed', { message: e.message });

        return e;
    }
};

const getDealNotes = ({ state }) => {
    return dealApi.loadDealNotes(state.deal.id)
        .then(({ data }) => {
            return data.dealNotes;
        })
        .catch((e) => {
            sentry.log('Get pipeline deal notes failed', { message: e.message });

            throw e;
        });
};

const createDealNote = ({ state, commit }, payload) => {
    const dealId = payload.dealId || state.deal.id;

    const request = {
        createdBy: payload.createdBy,
        body: payload.body,
    };

    return dealApi.createDealNote(dealId, request)
        .then(({ data: { createDealNote: note } }) => {
            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: 'dealnotecreated',
                details: {
                    noteBody: note.body,
                    eventAuthorId: note.createdBy,
                },
            });
        })
        .catch((e) => {
            sentry.log('Create pipeline deal note failed', { message: e.message });

            throw e;
        });
};

const updateDealNote = ({ commit }, { dealId, id, body }) => {
    const payload = {
        dealId,
        body,
    };

    return dealApi.updateDealNote(dealId, id, payload)
        .then(({ data: { updateDealNote: note } }) => {
            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: 'dealnoteupdated',
                details: {
                    updatedNoteBody: note.body,
                    eventAuthorId: note.createdBy,
                },
            });
        })
        .catch((e) => {
            sentry.log('Update pipeline deal note failed', { message: e.message });

            throw e;
        });
};

const deleteDealNote = ({ commit }, note) => {
    return dealApi.deleteDealNote(note.dealId, note.id)
        .then(() => {
            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: 'dealnotedeleted',
                details: {
                    noteBody: note.body,
                    eventAuthorId: note.createdBy,
                },
            });
        })
        .catch((e) => {
            sentry.log('Delete pipeline deal note failed', { message: e.message });

            throw e;
        });
};

const loadDealTasks = (context, {
    dealId,
    limit = 1000,
}) => {
    const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/deals/${dealId}/tasks?limit=${limit}`;

    return axios.get(url)
        .then(({ data }) => {
            return data.tasks;
        })
        .catch((e) => {
            sentry.log('Load pipeline deal tasks failed', { message: e.message });

            throw e;
        });
};

const loadPipelineTasks = (context, pipelineId) => {
    const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/pipeline/${pipelineId}/tasks`;

    return axios.get(url)
        .then(({ data }) => {
            return data.total;
        })
        .catch((e) => {
            sentry.log('Load pipeline deal tasks failed', { message: e.message });

            throw e;
        });
};

const sendDealEmail = ({
    commit,
    rootState: { email: { currentEmail } },
}, {
    dealId,
    to,
    from,
    recipients,
}) => {
    const { bardJson, subject } = currentEmail;
    const renderer = createRenderer(bardJson);

    const payload = {
        body: renderer.renderHtml(),
        from,
        recipients,
        subject,
        to,
    };

    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/deals/${dealId}/email`, payload)
            .then(() => {
                payload.recipients.forEach((contactId) => {
                    commit('ADD_ACTIVITY_FEED_ITEM', {
                        type: 'dealemailsent',
                        details: {
                            subject,
                            eventAuthorEmailAddress: from,
                            contactId,
                        },
                    });
                });

                resolve();
            })
            .catch((e) => {
                sentry.log('Send pipeline deal email failed', { message: e.message });

                reject(e);
            });
    });
};

const sendDealEmailWithBody = async ({ commit }, {
    body,
    dealId,
    from,
    recipients,
    signature,
    subject,
    to,
}) => {
    const bardJson = legacyBardJsonFromHtml({ html: body, signatureHtml: signature });
    const renderer = createRenderer(bardJson);

    const payload = {
        body: renderer.renderHtml(),
        from,
        recipients,
        subject,
        to,
    };

    try {
        await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/deals/${dealId}/email`, payload);

        recipients.forEach((contactId) => {
            commit('ADD_ACTIVITY_FEED_ITEM', {
                type: 'dealemailsent',
                details: {
                    contactId,
                    eventAuthorEmailAddress: from,
                    subject,
                },
            });
        });
    } catch (error) {
        sentry.log('Send pipeline deal email failed', { message: error.message });

        throw error;
    }
};

const addPipelineDeal = ({ dispatch }, {
    name,
    owner,
    contacts,
    stage,
    value,
    estimatedCloseDate,
    closedDate,
}) => {
    const preppedContacts = contacts.map(({ id, primaryContact }) => {
        return { id, primaryContact };
    });

    const payload = {
        name,
        ownerId: owner.id,
        stageId: stage.id,
        amount: parseFloat(value.amount),
        estimatedCloseDate,
        closedDate,
        currencyCode: value.currencyCode,
        contacts: preppedContacts,
    };

    return dealApi.createDeal(payload)
        .then(({ data: { createDeal: deal } }) => {
            dispatch('SET_DEAL', deal);

            return deal;
        })
        .catch((e) => {
            sentry.log('Add pipeline deal failed', { message: e.message });

            throw e;
        });
};

const deleteDeal = async ({ rootState, commit, dispatch }, deal) => {
    try {
        await dealApi.deleteDeal(deal.id);

        commit('DELETE_DEAL', deal);

        if (rootState.featureFlags[FF_KEAP_SAMPLE_DATA]) {
            dispatch('sampleData/DELETE_DEAL_SAMPLE_DATA', deal.id, { root: true });
        }
    } catch (e) {
        sentry.log('Deleting deal failed', { message: e.message });

        throw e;
    }
};

const updateOutcomeOptions = async ({ commit }, { pipelineId, options }) => {
    try {
        await pipelineApi.updateOutcomeOptions(pipelineId, options);

        commit('SET_OUTCOME_OPTIONS', { pipelineId, options });

        return options;
    } catch (e) {
        sentry.log('Saving pipeline outcomes failed', { message: e.message });

        throw e;
    }
};

const getOutcomeOptions = async ({ commit }, id) => {
    try {
        const { data: { pipelineOutcomeOptions } } = await pipelineApi.getOutcomeOptions(id);

        commit('SET_OUTCOME_OPTIONS', { pipelineId: id, options: pipelineOutcomeOptions?.outcomes || [] });

        return pipelineOutcomeOptions;
    } catch (e) {
        sentry.log('Loading pipeline outcomes failed', { message: e.message });

        throw e;
    }
};

const initializePipelineFilters = async ({ dispatch, state }) => {
    const filters = {};

    state.pipelineList?.forEach(({ id }) => {
        filters[id] = [DEFAULT_FILTER];
    });

    filters.initialized = true;

    await dispatch('preferences/SAVE_USER_PREFERENCE', {
        [PREFERENCE_TYPES.PIPELINE_FILTERS]: filters,
    }, { root: true });
};

const savePipelineFilters = async ({ dispatch, rootState }, { pipelineId, filters }) => {
    const filtersToSave = transformFilters(filters);

    let pipelineFilters = rootState.preferences.preferences[PREFERENCE_TYPES.PIPELINE_FILTERS];

    if (!pipelineFilters) {
        pipelineFilters = { [pipelineId]: filtersToSave };
    } else {
        pipelineFilters[pipelineId] = filtersToSave;
    }

    await dispatch('preferences/SAVE_USER_PREFERENCE', {
        [PREFERENCE_TYPES.PIPELINE_FILTERS]: pipelineFilters,
    }, { root: true });
};

const loadPipelineDealCount = async (context, { pipelineId, filters }) => {
    try {
        const { data: { pipelineDealCount } } = await pipelineApi.getPipelineDealCount(pipelineId, transformFiltersRequest(filters));

        return pipelineDealCount.count;
    } catch (e) {
        sentry.log('Loading pipeline deal count failed', { message: e.message });

        throw e;
    }
};

const loadStageDealCount = async (context, { stageId }) => {
    try {
        const { data: { stageDealCount } } = await stageApi.getStageDealCount(stageId);

        return stageDealCount.count;
    } catch (e) {
        sentry.log('Loading stage deal count failed', { message: e.message });

        throw e;
    }
};

const transformFilters = (filters) => {
    return filters?.map(({ fieldName, values, ...rest }) => {
        let valuesToSave = values;

        // format the date so firebase will save it
        if ((fieldName === filterOptions.CREATED.value || fieldName === filterOptions.CLOSE_DATE.value) && values.length) {
            valuesToSave = values?.map((val) => moment(val).format(DATE_FORMAT));
        }

        return {
            fieldName,
            values: valuesToSave || [],
            ...rest,
        };
    });
};

const transformFiltersRequest = (filters) => {
    if (!filters) {
        return [];
    }

    return filters.reduce((filterList, {
        fieldName,
        values,
        operator,
        ...rest
    }) => {
        // dont send filter if value is empty
        if ((operator === operators.BETWEEN && values?.length === 2)
            || (operator !== operators.BETWEEN && values?.length)) {
            let transformedValues = values;

            // format the date to utc for the backend
            if (fieldName === filterOptions.CREATED.value || fieldName === filterOptions.CLOSE_DATE.value) {
                transformedValues = [moment(values[0]).toISOString()];

                if (values.length > 1) {
                    transformedValues.push(moment(values[1]).endOf('day').toISOString());
                }
            }

            filterList.push({
                fieldName,
                values: transformedValues,
                operator,
                ...rest,
            });
        }

        return filterList;
    }, []);
};
