import { mapState, mapGetters } from 'vuex';
import {
    DELAY_TIMER, APPLY_TAG,
} from '@/automations/constants/automations.constants';
import { EVENT_TYPES, RANGE_OPTIONS, OFFSET_TYPES } from '@/automations/constants/automations.timer.constants';
import { DISPLAY_DATE_FORMAT, DISPLAY_DATE_FORMAT_WITH_YEAR, TIME_FORMAT } from '@/shared/constants/dateFormats.constants';
import moment from 'moment';

export default {
    computed: {
        ...mapGetters({
            tagById: 'tags/tagById',
        }),

        ...mapState({
            tags: ({ tags }) => tags.tags,
        }),
    },

    methods: {
        automationDisplayName_getTagTriggerDescription({ sourceId }) {
            if (sourceId) {
                const tag = this.tagById(sourceId);

                return tag?.name
                    ? this.$t('automation.description.tagApplied.title', { name: tag.name })
                    : this.$t('automation.description.invalidTag');
            }

            return this.$t('automation.description.tagApplied.empty');
        },

        automationDisplayName_getTagStepDescription({ configJson, type }) {
            if (configJson?.tagIds) {
                const tag = this.tagById(configJson.tagIds[0]);

                return tag?.name
                    ? this.$t(type === APPLY_TAG ? 'automation.description.applyTag.title' : 'automation.description.removeTag.title', { name: tag.name })
                    : this.$t('automation.description.invalidTag');
            }

            return type === APPLY_TAG ? this.$t('automation.description.applyTag.empty') : this.$t('automation.description.removeTag.empty');
        },

        automationDisplayName_timerStepName(value) {
            const { configJson, type } = value;
            const eventType = configJson?.eventType;

            if (type === DELAY_TIMER) {
                return this.automationDisplayName_delayTimerLabel(value);
            }

            if (eventType === EVENT_TYPES.DATE) {
                return this.automationDisplayName_exactTimerLabel(value);
            }

            if (eventType === EVENT_TYPES.APPOINTMENT || eventType === EVENT_TYPES.INVOICE_DUE) {
                return this.automationDisplayName_eventTimerLabel(value);
            }

            return '';
        },

        automationDisplayName_delayTimerLabel(value) {
            if (value.configJson) {
                const { startTime, count, unit } = value.configJson;

                return this.$t('automation.timing.delayTimerLabel', {
                    wait: this.$t('automation.timing.wait'),
                    count: `${count} `,
                    unit: this.$tc(`automation.timing.${unit.toLowerCase()}`, count > 1),
                    andRange: startTime ? `${this.$t('automation.timing.andRange')}` : '',
                    range: this.automationDisplayName_rangeLabel(value),
                }).trim();
            }

            return '';
        },

        automationDisplayName_exactTimerLabel(value) {
            const { exactDate } = value.configJson;

            const format = moment(exactDate).isAfter(moment().add(1, 'years').subtract(1, 'days')) || moment(exactDate).isSameOrBefore(moment())
                ? DISPLAY_DATE_FORMAT_WITH_YEAR
                : DISPLAY_DATE_FORMAT;

            return this.$t('automation.timing.exactTimerLabel', {
                wait: this.$t('automation.timing.waitUntil'),
                date: exactDate ? `${moment(exactDate).format(format)}` : this.$t('automation.timing.chooseDate'),
                range: this.automationDisplayName_rangeLabel(value),
            }).trim();
        },

        automationDisplayName_eventTimerLabel(value) {
            const {
                offsetType, eventType, count, unit,
            } = value.configJson;

            return this.$t('automation.timing.eventTimerLabel', {
                wait: this.$t('automation.timing.waitUntil'),
                count: count > 0 ? `${count} ` : '',
                unit: unit ? this.$tc(`automation.timing.${unit.toLowerCase()}`, count > 1) : '',
                offsetType: offsetType === OFFSET_TYPES.NONE ? '' : this.$t(`automation.timing.${offsetType}`),
                event: this.$t(`automation.timing.${eventType}`),
                range: this.automationDisplayName_rangeLabel(value),
            }).trim();
        },

        automationDisplayName_rangeLabel(value) {
            const { startTime, endTime } = value.configJson;
            const rangeOption = endTime ? RANGE_OPTIONS.BETWEEN : RANGE_OPTIONS.AT;

            return this.$t('automation.timing.rangeLabel', {
                rangeType: startTime ? this.$t(`automation.timing.${rangeOption}`) : '',
                startTime: startTime ? moment(startTime, 'HHmm').format(TIME_FORMAT) : '',
                endTime: endTime ? this.$t('automation.timing.endTime', { endTime: moment(endTime, 'HHmm').format(TIME_FORMAT) }) : '',
            });
        },
    },
};
