<template>
    <div class="configure">
        <configure-details
            :title="tagName"
            :subtitle="tagDescription"
            :has-error="hasError"
            :error-message="errorMessage || $t('error')"
            :trigger-action="triggerAction"
            @edit="(payload) => $emit('edit', { trigger: value })"
            @remove="$emit('remove')"
        />

        <div class="button-row multiple-buttons">
            <manage-configuration
                :title="$t('manageTags')"
                :manage-props="manageProps"
            />

            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import redirectMixin from '@/shared/mixins/redirect.mixin';
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';
import ManageConfiguration from '@/automations/components/configure/ManageConfiguration';

export default {
    components: {
        ConfigureDetails,
        ManageConfiguration,
    },

    mixins: [redirectMixin],

    props: {
        value: Object,
        hasError: Boolean,
        errorMessage: String,
        triggerAction: String,
    },

    data() {
        return {
            manageProps: {
                confirmTitle: this.$t('edit.title'),
                confirmMessage: this.$t('edit.message'),
                confirmButtonLabel: this.$t('edit.confirm'),
                route: { name: 'settings.tags' },
            },
        };
    },

    computed: {
        ...mapState({
            tags: ({ tags }) => tags.tags,
        }),

        ...mapGetters({
            tagById: 'tags/tagById',
        }),

        tag() {
            return this.tagById(this.value?.sourceId ?? 0);
        },

        tagName() {
            return this.tag?.name ?? this.$t('emptyTagLabel');
        },

        tagDescription() {
            return this.tag?.category?.name ?? '';
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>

<i18n>
{
    "en-us": {
        "manageTags": "Manage tags",
        "emptyTagLabel": "Tag is added",
        "edit": {
            "title": "Navigate to the tags page to manage your tags?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Manage tags"
        },
        "error": "Please select a tag"
    }
}
</i18n>
