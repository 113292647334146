import axios from 'axios';

const loadNotes = ({ state, commit }, contactId) => {
    return new Promise((resolve, reject) => {
        const url = contactId
            ? `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${contactId}/notes`
            : `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${state.contact.id}/notes`;

        return axios.get(url)
            .then(({ data: notes }) => {
                commit('SET_NOTES', notes);
                resolve();
            })
            .catch(reject);
    });
};

const addNote = ({ commit }, note) => {
    return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/notes`, note)
            .then(({ data: createdNote }) => {
                commit('ADD_NOTE', createdNote);

                commit('ADD_ACTIVITY_FEED_ITEM', {
                    type: 'notecreated',
                    details: {
                        body: createdNote.body,
                        eventAuthorId: createdNote.ownedByUserId,
                    },
                });

                resolve(createdNote);
            }).catch(reject);
    });
};

const updateNote = ({ commit }, { noteId, body }) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/notes/${noteId}`, { body })
            .then(({ data: note }) => {
                commit('UPDATE_NOTE', note);

                commit('ADD_ACTIVITY_FEED_ITEM', {
                    type: 'noteupdated',
                    details: {
                        body: note.body,
                        eventAuthorId: note.lastUpdatedByUserId,
                    },
                });

                resolve();
            })
            .catch(reject);
    });
};

const deleteNote = ({ commit }, { id, body, lastUpdatedByUserId }) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/notes/${id}`)
            .then(() => {
                commit('REMOVE_NOTE', id);

                commit('ADD_ACTIVITY_FEED_ITEM', {
                    type: 'notedeleted',
                    details: {
                        body,
                        eventAuthorId: lastUpdatedByUserId,
                    },
                });

                resolve();
            })
            .catch(reject);
    });
};

export {
    loadNotes as LOAD_NOTES,
    addNote as ADD_NOTE,
    updateNote as UPDATE_NOTE,
    deleteNote as DELETE_NOTE,
};
