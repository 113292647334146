export const MANUAL_LIST_EVENT_SOURCE = 'Manual List';
export const CONTACT_LISTS_TAB_EVENT_SOURCE = 'Contact Lists Tab';
export const CONTACT_LISTS_MODAL_EVENT_SOURCE = 'Contacts Lists Modal';

// Contact List Event Sources
export const ALL_CONTACTS_LIST_EVENT_SOURCE = 'All Contacts List';
export const SAVED_CONTACTS_LIST_EVENT_SOURCE = 'Saved Contact List';
export const COMPANY_CONTACTS_LIST_EVENT_SOURCE = 'Company Contacts List';
export const FILTERED_CONTACTS_LIST_EVENT_SOURCE = 'Filtered Contacts List';

// Import sources
export const CONTACTS_IMPORTED_EVENT_SOURCE = 'Contacts Imported';
