<template lang="html">
    <controlled-modal
        :is-open="isOpen"
        :title="modalTitle"
        :sub-title="subtitle"
        show-close
        data-qa="task-details"
        @close="$emit('cancel')"
    >
        <template #header>
            <task-form-modal-header
                v-if="editing"
                :task="task"
                @marked-complete="(payload) => $emit('marked-complete', payload)"
            />
        </template>

        <form v-if="formModel" ref="form">
            <bus-event name="SHORTKEY_ESC" @event="$emit('cancel')" />

            <div>
                <ds-input-field
                    ref="taskTitle"
                    v-model="formModel.title"
                    type="text"
                    name="title"
                    :label="$t('contactRecord.pages.task.taskTitle')"
                    required
                    data-qa="task-form-title"
                    :maxlength="100"
                    :submitted="submitted"
                />

                <div class="date-fields">
                    <ds-date-picker
                        v-model="formModel.dueDate"
                        class="date-field"
                        static
                        data-qa="task-form-due-date"
                        :label="$t('contactRecord.pages.task.dueDate')"
                    />

                    <span class="at">{{ $t('global.at') }}</span>

                    <time-input
                        v-model="dueTime.value"
                        name="time-input"
                        class="date-field"
                        data-qa="task-form-due-time"
                        submitted
                        :placeholder="currentTime"
                    />
                </div>

                <ds-select-field
                    v-model="formModel.remindTimeOffsetInMillis"
                    allow-null
                    bind-value-only
                    data-qa="task-form-reminder"
                    :label="$t('reminder')"
                    :options="reminderOptions"
                />

                <ds-multiselect
                    v-if="hasOutcomeOptions"
                    v-model="formModel.outcomeSelections"
                    multiple
                    searchable
                    :placeholder="$t('outcomeSelectLabel')"
                    :options="outcomeOptions"
                    type="text"
                    data-qa="task-form-outcome-options"
                    @input="updateOutcomeSelection"
                />

                <ds-text-area-field
                    v-model="formModel.notes"
                    type="text"
                    name="note"
                    :label="$t('contactRecord.pages.task.taskNote')"
                    data-qa="task-form-note"
                    :submitted="submitted"
                    :maxlength="2000"
                />

                <task-contact-field
                    v-if="!contact || editing"
                    v-model="formModel.contact"
                    @selected-contact="handleSelectedContact"
                />

                <ds-select-field
                    v-if="showAssignedUserField"
                    v-model="formModel.assignedUserId"
                    class="assigned-user"
                    :options="userOptions"
                    :label="$t('contactRecord.pages.task.assignedTo')"
                    data-qa="task-form-assigned-user"
                    bind-value-only
                />

                <small
                    v-if="showNotificationMessage"
                    class="assigned-user-text"
                >
                    {{ $t('assignedUser.helpText', { fullName: getAssignedUserName }) }}
                </small>

                <div class="action-buttons">
                    <ds-filled-button
                        :disabled="disableSave"
                        :loading="saving"
                        data-qa="save-task"
                        @click="save"
                    >
                        {{ $t('global.save') }}
                    </ds-filled-button>

                    <ds-text-button class="cancel" data-qa="cancel-button" @click="$emit('cancel')">
                        {{ $t('global.cancel') }}
                    </ds-text-button>

                    <ds-outline-button
                        v-if="editing"
                        negative
                        class="delete"
                        :loading="deleting"
                        data-qa="delete-task"
                        @click="deleteTask"
                    >
                        {{ $t('global.delete') }}
                    </ds-outline-button>
                </div>
            </div>
        </form>
    </controlled-modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import BusEvent from '@/shared/components/BusEvent';
import TimeInput from '@/shared/components/TimeInput';
import dateMixin from '@/appointments/mixins/date.mixin';
import { displayFullName } from '@/contacts/contact-info-utils';
import amplitude from '@/analytics/amplitude';
import ControlledModal from '@/shared/components/ControlledModal';
import TaskFormModalHeader from '@/tasks/components/TaskFormModalHeader';

import TaskContactField from '@/tasks/components/TaskContactField';
import { ROLE_CONTACTACTION_CAN_ASSIGN_ACTION } from '@/settings/settings.constants';
import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';
import {
    TASKS_TASK_CREATED_EVENT,
    TASKS_TASK_UPDATED_EVENT,
    TASKS_TASK_DELETED_EVENT,
} from '@/shared/constants/events.constants';
import { FF_TASK_OUTCOMES_ENDPOINT_FROM_CORE_TO_KEAP } from '@/shared/constants/featureFlag.constants';

const MINUTE_MILLISECONDS = 60000;

const getContactId = (contact) => (contact ? contact.id : null);

export default {
    components: {
        BusEvent,
        TaskContactField,
        TimeInput,
        ControlledModal,
        TaskFormModalHeader,
    },

    mixins: [dateMixin],

    props: {
        task: Object,
        contact: Object,
        eventSource: String,
        isOpen: Boolean,
    },

    data() {
        return {
            formModel: null,
            submitted: false,
            title: null,
            deleting: false,
            assignedUserId: null,
            time: null,
            dueTime: {},
            saving: false,
            selectedContact: {},
        };
    },

    created() {
        if (this.isOpen) {
            this.onOpen();
        }
    },

    watch: {
        isOpen(val) {
            if (val) {
                this.onOpen();
            }
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            users: ({ auth }) => auth.users,
            taskOutcomesEndpointEnabled: ({ featureFlags }) => featureFlags[FF_TASK_OUTCOMES_ENDPOINT_FROM_CORE_TO_KEAP],
        }),

        ...mapGetters({
            hasPermission: 'auth/hasPermission',
        }),

        showAssignedUserField() {
            return this.users.length > 1 && this.hasPermission(ROLE_CONTACTACTION_CAN_ASSIGN_ACTION);
        },

        showNotificationMessage() {
            return +this.formModel.assignedUserId !== +this.user.id;
        },

        getAssignedUserName() {
            const assignedUser = this.users.find((user) => {
                return +user.id === +this.formModel.assignedUserId;
            });

            return assignedUser ? assignedUser.givenName : '';
        },

        reminderOptions() {
            return [
                {
                    label: this.$t('none'),
                    value: null,
                }, {
                    label: this.$t('atDueTime'),
                    value: 0,
                }, {
                    label: this.$t('time', { amount: 5, unit: 'min' }),
                    value: MINUTE_MILLISECONDS * 5,
                }, {
                    label: this.$t('time', { amount: 15, unit: 'min' }),
                    value: MINUTE_MILLISECONDS * 15,
                }, {
                    label: this.$t('time', { amount: 30, unit: 'min' }),
                    value: MINUTE_MILLISECONDS * 30,
                }, {
                    label: this.$t('time', { amount: 1, unit: 'hr' }),
                    value: MINUTE_MILLISECONDS * 60,
                }, {
                    label: this.$t('time', { amount: 1, unit: 'day' }),
                    value: MINUTE_MILLISECONDS * 60 * 24,
                },
            ];
        },

        disableSave() {
            return !this.formModel.title || !this.dueTime.value;
        },

        userOptions() {
            const users = this.users || [];

            return users.map(({ email, fullName, id }) => {
                const label = email
                    ? `${fullName} (${email})`
                    : `${fullName}`;

                return { label, value: +id };
            });
        },

        editing() {
            return this.task && this.task.id;
        },

        modalTitle() {
            return this.editing
                ? this.$t('contactRecord.pages.task.edit')
                : this.$t('contactRecord.pages.task.addTask');
        },

        subtitle() {
            return this.contact ? displayFullName(this.contact) : '';
        },

        currentTime() {
            const roundedTime = Math.ceil(moment().minute() / 15) * 15;

            return moment().minute(roundedTime).format(TIME_FORMAT);
        },

        outcomeOptions() {
            return this.task.outcomeOptions.map(({
                id, name, value, label,
            }) => {
                return {
                    value: id || value,
                    label: name || label,
                };
            });
        },

        hasOutcomeOptions() {
            return this.task?.outcomeOptions?.length > 0 && this.taskOutcomesEndpointEnabled;
        },

        hasOutcomeSelection() {
            return this.task?.outcomeSelections?.length > 0 && this.taskOutcomesEndpointEnabled;
        },
    },

    methods: {
        onOpen() {
            const { task, contact } = this;

            this.$store.commit('tasks/SET_TASK', task);

            if (this.editing) {
                this.formModel = {
                    ...this.getModel(),
                    ...task,
                    assignedUserId: +task.assignedUserId || +this.user.id,
                    dueDate: moment(task.dueDate).toDate(),
                };

                const time = moment(task.dueDate).format(TIME_FORMAT);

                this.dueTime = { label: time, value: time };

                if (this.hasOutcomeOptions) {
                    this.formModel.outcomeSelections = this.task.outcomeSelections.map(({
                        id, name, value, label,
                    }) => {
                        return {
                            value: id || value,
                            label: name || label,
                        };
                    });
                    this.task.outcomeSelections = this.formModel.outcomeSelections;
                }
            } else {
                this.formModel = this.getModel();

                if (contact) {
                    this.formModel.contact = {
                        id: contact.id,
                        email: contact.email,
                        fullName: displayFullName(contact),
                    };
                }

                this.dueTime = { value: this.currentTime, label: this.currentTime };

                this.time = moment().minute(Math.ceil(moment().minute() / 15) * 15).format(TIME_FORMAT);
            }

            this.$nextTick(() => {
                if (this.$refs.taskTitle && typeof this.$refs.taskTitle.input_focus === 'function') {
                    this.$refs.taskTitle.input_focus();
                }
            });
        },

        close() {
            this.reset();
            this.$emit('close');
        },

        reset() {
            this.submitted = false;
            this.saving = false;
            this.deleting = false;
            this.formModel = this.getModel();
        },

        updateDateWithDueTime(date) {
            let momentDate = moment(date).hours(moment(this.dueTime.value, TIME_FORMAT).get('hour'));

            momentDate = momentDate.minutes(moment(this.dueTime.value, TIME_FORMAT).get('minute'));

            return momentDate.toDate();
        },

        editTask(task) {
            if (this.hasOutcomeOptions) {
                this.editTaskWithOutcomes(task);
            } else {
                this.editTaskNoOutcomes(task);
            }
        },

        async editTaskNoOutcomes(task) {
            this.saving = true;

            task.completed = Boolean(this.formModel.completionDate);
            task.contactId = getContactId(this.formModel.contact);
            task.outcomeSelections = undefined;

            try {
                await this.$store.dispatch('contacts/UPDATE_TASK', {
                    taskId: this.formModel.id,
                    updatedTask: task,
                });

                if (+task.assignedUserId !== +this.user.id) {
                    amplitude.v2.logEvent(amplitude.v2.events.TASK_UPDATED, {
                        'Assigned User': task.assignedUserId,
                    });
                }

                amplitude.v2.logEvent(amplitude.v2.events.TASK_UPDATED);
                this.$bus.$emit(TASKS_TASK_UPDATED_EVENT);

                this.$toast({ message: this.$t('successEdit') });
                this.close();
            } catch {
                this.$error({ message: this.$t('errorEdit'), bottom: true });
            }
            this.saving = false;
        },

        async editTaskWithOutcomes(task) {
            this.saving = true;
            const errorMessage = this.$t('errorEdit');
            const selectedOutcomeIds = this.hasOutcomeSelection ? task.outcomeSelections.map(({ value }) => value) : [];

            try {
                await this.$store.dispatch('contacts/UPDATE_TASK_WITH_OUTCOME', {
                    eventType: task.completionDate ? 'taskcompleted' : 'taskmarkedincomplete',
                    taskId: this.formModel.id,
                    updatedTaskWithOutcome: {
                        contactId: this.formModel.contact.id,
                        assignedUserId: this.formModel.assignedUserId,
                        title: this.formModel.title,
                        dueDate: task.dueDate,
                        description: this.formModel.notes,
                        remindTimeOffsetInMillis: this.formModel.remindTimeOffsetInMillis,
                        outcomeIds: selectedOutcomeIds,
                    },
                });
                this.$emit('close');
            } catch {
                this.$error({ message: errorMessage });
            }
            this.saving = false;
        },

        async addTask(task) {
            this.saving = true;
            const contactId = getContactId(this.formModel.contact);

            task.contactIds = [contactId];

            try {
                await this.$store.dispatch('contacts/ADD_TASK', {
                    task,
                    currentContactId: contactId,
                });

                this.$bus.$emit(TASKS_TASK_CREATED_EVENT);

                if (+task.assignedUserId !== +this.user.id) {
                    amplitude.v2.logEvent(amplitude.v2.events.TASK_UPDATED, {
                        'Assigned User': task.assignedUserId,
                    });
                }

                amplitude.v2.logEvent(amplitude.v2.events.TASK_CREATED, {
                    'Event Source': this.eventSource,
                });

                this.$toast({ message: this.$t('successAdd') });
                this.close();
            } catch {
                this.$error({ message: this.$t('errorAdd'), bottom: true });
            }

            this.saving = false;
        },

        save() {
            this.submitted = true;

            if (this.$refs.form.checkValidity()) {
                const task = {
                    title: this.formModel.title,
                    dueDate: this.formModel.dueDate,
                    description: this.formModel.notes,
                };

                task.remindTimeOffsetInMillis = this.formModel.remindTimeOffsetInMillis;
                task.dueDate = this.updateDateWithDueTime(task.dueDate);
                task.assignedUserId = this.formModel.assignedUserId;

                if (this.taskOutcomesEndpointEnabled) {
                    task.outcomeSelections = this.formModel.outcomeSelections;
                }

                if (this.editing) {
                    this.editTask(task);
                } else {
                    this.addTask(task);
                }
            }
        },

        deleteTask() {
            return this.$confirm({
                optionTitle: this.$t('deleteConfirmation.title'),
                optionMessage: this.$t('deleteConfirmation.message'),
                optionConfirmButtonLabel: this.$t('global.delete'),
                destructive: true,
            })
                .then(() => {
                    this.callDelete();
                })
                .catch(() => {
                    this.deleting = false;
                });
        },

        async callDelete() {
            this.deleting = true;
            await this.$store.dispatch('contacts/DELETE_TASK', this.formModel);

            this.$toast({ message: this.$t('successDelete') });
            this.$bus.$emit(TASKS_TASK_DELETED_EVENT);
            this.$emit('deleted');
            this.deleting = false;
            this.close();
        },

        getModel() {
            return {
                title: null,
                dueDate: moment().millisecond(0).add(1, 'days').toDate(),
                notes: null,
                id: null,
                contact: null,
                completionDate: null,
                assignedUserId: +this.user.id,
                remindTimeOffsetInMillis: null,
            };
        },

        handleSelectedContact(selectedContact) {
            this.formModel.contact = selectedContact;
        },

        updateOutcomeSelection() {
            this.task.outcomeSelections = this.formModel.outcomeSelections;
        },
    },
};
</script>

<style lang="scss" scoped>
    .date-fields {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;
    }

    .date-field {
        flex: 2;
    }

    .at {
        margin: $gp /2 $gp 0 $gp;
        vertical-align: center;
    }

    .assigned-user {
        margin-bottom: $gp / 4;
    }

    .assigned-user-text {
        @include margin-start($gp / 4);
    }

    .button-row{
        margin-top: $gp;
    }

    .menu-item .checkbox {
        --checkbox-padding: $gp /4;

        .option-text  {
            --text-highlighter-matching-color: #{$color-blue};
        }
    }

    .delete {
        float: right;
    }

    .action-buttons {
        margin-top: $gp;
    }

    .cancel {
        margin-left: $gp / 2;
    }
</style>

<i18n>
{
    "en-us": {
        "deleteConfirmation": {
            "title": "Delete task",
            "message": "Are you sure you want to delete this task?"
        },
        "assignedUser": {
            "helpText": "{fullName} will be notified by email or mobile app as soon as you hit Save."
        },
        "reminder": "Reminder",
        "none": "None",
        "atDueTime": "at the time it's due",
        "time": "{amount} {unit} before",
        "outcomeSelectLabel": "Task outcomes",
        "errorAdd": "Something went wrong and we couldn't add this task. Please try again.",
        "errorEdit": "Something went wrong and we couldn't save your changes to this task. Please try again.",
        "successAdd": "Task added successfully",
        "successEdit": "Task successfully updated",
        "successDelete": "This task has been deleted"
    }
}
</i18n>
