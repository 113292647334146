<template>
    <section class="contact-links section-padding">
        <h4 class="section-title heading">
            {{ $t('title') }}
        </h4>

        <div
            v-for="field in fieldsToShow"
            :key="field"
            class="field-type"
        >
            <ds-input-field
                v-model.trim="contact[field]"
                autocomplete="no"
                :label="$t(`forms.${field}`)"
                :submitted="submitted"
                class="fs-block"
                @blur="handleBlur(field)"
                @input="$emit('input')"
            />

            <div>
                <contact-field-remove-button
                    :button-label="$t('global.delete')"
                    data-qa="clear-link-button"
                    class="remove-button"
                    @action="clearLink(field)"
                />
            </div>
        </div>

        <ds-dropdown v-if="showAddButton">
            <span
                class="add-field"
                data-qa="add-link"
                @click="addField"
            >
                <ds-icon name="add" />
                <span>{{ $t('addLink') }}</span>
            </span>

            <template #menu>
                <ul class="dropdown-menu-list">
                    <li
                        v-for="field in emptyFields"
                        :key="field"
                        class="dropdown-menu-item"
                        @click="addField(field)"
                    >
                        {{ $t(`forms.${field}`) }}
                    </li>
                </ul>
            </template>
        </ds-dropdown>
    </section>
</template>

<script>
import customFieldUtils from '@/contacts/components/details/custom-field-utils';
import ContactFieldRemoveButton from './ContactFieldRemoveButton';

export default {
    components: { ContactFieldRemoveButton },

    mixins: [customFieldUtils],

    props: {
        contact: Object,
        submitted: Boolean,
    },

    data() {
        return {
            fields: [
                'website',
                'linkedin',
                'twitter',
                'facebook',
            ],
            emptyFields: [],
        };
    },

    mounted() {
        this.getEmptyFields();
    },

    computed: {
        showAddButton() {
            return this.emptyFields.length > 0;
        },

        fieldsToShow() {
            return this.fields.filter((field) => (this.contact[field] || !this.emptyFields.includes(field)));
        },
    },

    methods: {
        handleBlur(field) {
            this.contact[field] = this.formatUrl(this.contact[field]);
        },

        getEmptyFields() {
            this.emptyFields = this.fields.filter((field) => !this.contact[field]);

            if (this.emptyFields.length === this.fields.length) {
                this.emptyFields.shift();
            }
        },

        clearLink(field) {
            this.contact[field] = '';

            this.emptyFields.push(field);
        },

        addField(field) {
            const linkFieldIndex = this.emptyFields.length === 1
                ? this.emptyFields.indexOf(this.emptyFields[0])
                : this.emptyFields.indexOf(field);

            if (linkFieldIndex !== -1) {
                this.emptyFields.splice(linkFieldIndex, 1);
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .field-grid {
        margin-bottom: $gp;
    }

    .heading {
        margin-top: $gp / 2;
        padding-bottom: $gp / 2;
    }

    .field-type {
        display: flex;
    }

    .remove-button {
        margin-left: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Links",
        "addLink": "Add a link"
    }
}
</i18n>
