<template>
    <ds-dropdown
        icons
        position="bottom-end"
        class="site-options"
        :class="[dropDownClass]"
    >
        <template #default>
            <ds-icon-button name="more-horizontal" />
        </template>

        <template #menu>
            <ul class="dropdown-menu-list">
                <li
                    v-if="isPublished"
                    :data-qa="siteOptions.preview"
                    class="option dropdown-menu-item"
                    @click.prevent="$sites.openSitePreview(siteId)"
                >
                    <div class="preview-container">
                        <div class="preview-label">
                            {{ $t('goToLandingPage') }}
                        </div>
                        <div class="preview-icon">
                            <ds-icon class="end-icon" name="external-link" />
                        </div>
                    </div>
                </li>
                <li
                    v-if="isPublished"
                    :data-qa="siteOptions.copyUrl"
                    class="option dropdown-menu-item"
                    @click.prevent="$sites.copySiteUrl(siteId)"
                >
                    <span>{{ $t('copyLink') }}</span>
                </li>
                <li
                    :data-qa="siteOptions.edit"
                    class="option dropdown-menu-item"
                    @click.prevent="$sites.showEditSite(siteId)"
                >
                    <span>{{ $t('editLandingPage') }}</span>
                </li>

                <li
                    class="option dropdown-menu-item"
                    :data-qa="siteOptions.duplicate"
                    @click.prevent="$sites.copySite(siteId)"
                >
                    <span>{{ $t('copy') }}</span>
                </li>
                <li
                    :data-qa="siteOptions.rename"
                    class="option dropdown-menu-item"
                    @click.prevent="$sites.showRenameSite(siteId)"
                >
                    <span>{{ $t('renameLandingPage') }}</span>
                </li>
                <li
                    :data-qa="siteOptions.delete"
                    class="option dropdown-menu-item delete"
                    @click.prevent="$sites.deleteSite(siteId)"
                >
                    <span>{{ $t('deleteLandingPage') }}</span>
                </li>
            </ul>
        </template>
    </ds-dropdown>
</template>

<script>

import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';
import { SITE_OPTIONS } from './site-options.constants';

export default {
    inject: ['$sites'],

    props: {
        siteId: {
            type: String,
            defaultValue: '',
        },
        siteStatus: String,
    },

    computed: {
        siteOptions() {
            return SITE_OPTIONS;
        },

        isPublished() {
            return this.siteStatus === SITE_STATUS.PUBLISHED;
        },

        dropDownClass() {
            switch (this.siteStatus) {
            case SITE_STATUS.DRAFT:
                return 'site-unpublished';
            case SITE_STATUS.PUBLISHED:
                return 'site-published';
            case SITE_STATUS.PUBLISHED_CHANGES:
                return 'site-published-changes';
            default:
                return SITE_STATUS.DRAFT;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $options-min-width: px-to-rem(250px);
    $options-height-published: px-to-rem(331px);
    $options-height-unpublished: px-to-rem(228px);
    $options-height-published-changes: px-to-rem(279px);

    .site-options {
        --dropdown-minwidth: #{$options-min-width};
    }

    .site-unpublished {
        --dropdown-height: #{$options-height-unpublished};
    }

    .site-published {
        --dropdown-height: #{$options-height-published};
    }

    .site-published-changes {
        --dropdown-height: #{$options-height-published-changes};
    }

    .option {
        --icon-color: $color-gray-700;

        &.delete {
            --icon-color: $color-red;
        }
    }

    .preview-container {
        height: px-to-rem(20);
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .preview-icon {
        flex: 1;
        text-align: end;
    }

    .dropdown-menu.icons  .icon.end-icon {
        margin: 0;
    }
</style>

<i18n>
{
    "en-us": {
        "goToLandingPage": "Go to landing page",
        "viewReport": "View report",
        "editLandingPage": "Edit",
        "renameLandingPage": "Rename",
        "deleteLandingPage": "Delete",
        "copy": "Make a copy",
        "copyLink": "Copy link"
    }
}
</i18n>
