<template lang="html">
    <div v-if="loading" class="preview">
        <ds-placeholder class="empty-state" :rows="placeholderRows" />
    </div>

    <!-- eslint-disable vue/no-v-html -->
    <div
        v-else
        class="preview"
        v-html="signature"
    />
    <!-- eslint-enable vue/no-v-html -->
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        userId: [Number, String],
        update: Boolean,
    },

    data() {
        return {
            loading: false,
            placeholderRows: [
                { height: '.5rem', boxes: [1] },
                { height: '.5rem', boxes: [1] },
            ],
        };
    },

    mounted() {
        this.loadSignature();
    },

    watch: {
        update: {
            handler(val) {
                if (val) this.loadSignature();
            },
            immediate: true,
        },

        userId() {
            this.loadSignature();
        },
    },

    computed: {
        ...mapGetters({
            getUserSignature: 'email/getUserSignature',
        }),

        signature() {
            return this.getUserSignature(this.userId);
        },
    },

    methods: {
        async loadSignature() {
            if (this.userId) {
                this.loading = true;
                const message = this.$t('signature.error');

                try {
                    await this.$store.dispatch('email/GET_USER_SIGNATURE', this.userId);
                } catch (e) {
                    this.$error({ message });
                }

                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .empty-state {
        width: px-to-rem(160);
    }
</style>

<i18n>
{
    "en-us": {
        "signature": {
            "error": "We were unable to load your email signature."
        }
    }
}
</i18n>
