var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.formattedShortcuts)?_c('ds-modal',{ref:"modal",attrs:{"title":_vm.$t('title'),"size":"fluid","show-close":""}},[_c('div',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(_vm.formattedShortcuts),expression:"formattedShortcuts"}],staticClass:"shortcuts",on:{"shortkey":_vm.handleAction}},_vm._l((_vm.filteredShortcuts),function(ref){
var name = ref.name;
var icon = ref.icon;
var shortcuts = ref.shortcuts;
return _c('div',{key:name,class:['section', { 'active': name === _vm.currentRoute }]},[_c('header',[_c('ds-icon',{attrs:{"name":icon}}),_c('h6',[_vm._v(_vm._s(_vm.$t(name)))])],1),_vm._l((shortcuts),function(ref,i){
var shortcut = ref.shortcut;
var label = ref.label;
return _c('div',{key:i,staticClass:"shortcut"},[_vm._l((shortcut),function(key){return _c('ds-chip',{key:key},[_vm._v(" "+_vm._s(key)+" ")])}),_vm._v(" "+_vm._s(_vm.$t(label))+" ")],2)})],2)}),0)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }