<template>
    <ds-status
        v-if="showStatus"
        :type="statusType"
        data-qa="phone-opt-status"
    >
        {{ statusText }}
    </ds-status>
</template>

<script>
import { SMS_OPT_STATUSES } from '@/broadcasts/smsBroadcast.constants';
import { STATUS_TYPE } from '@/shared/constants/status.constants';

const {
    SINGLE_OPT_IN, DOUBLE_OPT_IN, OPT_OUT, UNCONFIRMED,
} = SMS_OPT_STATUSES;
const { GOOD, ERROR, DEFAULT } = STATUS_TYPE;

export default {
    props: {
        status: {
            type: String,
            default: '',
        },
    },

    computed: {
        showStatus() {
            return [SINGLE_OPT_IN, DOUBLE_OPT_IN, OPT_OUT, UNCONFIRMED].includes(this.status);
        },

        statusText() {
            switch (this.status) {
            case SINGLE_OPT_IN:
            case DOUBLE_OPT_IN:
                return this.$t('subscribed');
            case OPT_OUT:
                return this.$t('unsubscribed');
            case UNCONFIRMED:
                return this.$t('pending');
            default:
                return '';
            }
        },

        statusType() {
            switch (this.status) {
            case SINGLE_OPT_IN:
            case DOUBLE_OPT_IN:
                return GOOD;
            case OPT_OUT:
                return ERROR;
            case UNCONFIRMED:
                return DEFAULT;
            default:
                return null;
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "subscribed": "Opted in to your Marketing Number",
        "unsubscribed": "Opted out of your Marketing Number",
        "pending": "Pending opt in to your Marketing Number"
    }
}
</i18n>
