import { mapState, mapGetters } from 'vuex';

import mobileInterceptor from '@/shared/mixins/mobile-interceptor.mixin';

export default {
    mixins: [mobileInterceptor],

    computed: {
        ...mapState({
            jwt: ({ auth }) => auth.session.jwt,
            callStatus: ({ communication }) => communication.call.callStatus,
        }),

        ...mapGetters({
            coreBaseUrl: 'auth/coreBaseUrl',
        }),
    },

    methods: {
        redirect_url(url) {
            window.location.assign(url);
        },

        redirect_getCoreUrl(url) {
            let urlString = `${this.coreBaseUrl}${url}`;
            let params = `ticket=${this.jwt}`;

            if (process.env.NODE_ENV === 'development') {
                params += '&isLocalFrontend=true';
            }

            if (url.includes('?')) {
                urlString += `&${params}`;
            } else {
                urlString += `?${params}`;
            }

            return urlString;
        },

        redirect_coreUrl(url) /* istanbul ignore next */ {
            const coreUrl = this.redirect_getCoreUrl(url);

            // If there is an active call, open in new tab so call does not disconnect
            if (this.callStatus) {
                this.redirect_url_new_tab(coreUrl);
            } else {
                this.redirect_url(coreUrl);
            }
        },

        redirect_broadcastGallery({ isSplitTest = false, listId = 0, returnTo = 'email-broadcast' } = {}) {
            this.$track('Broadcasts - redirect : Redirected to Bard');

            let url = `/app/email/broadcast/gallery?listId=${listId}&spaReturnTo=${returnTo}`;

            if (isSplitTest) {
                url += '&isSplitTest=true';
            }

            this.redirect_coreUrl(url);
        },

        redirect_url_new_tab(url) /* istanbul ignore next */ {
            window.open(url, '_blank', 'noopener,noreferrer');
        },
    },
};
