import appointmentRoutes from '@/appointments/appointments.routes';
import automationRoutes from '@/automations/automations.routes';
import broadcastsRoutes from '@/broadcasts/broadcasts.routes';
import campaignRoutes from '@/campaigns/campaigns.routes';
import communicationRoutes from '@/communication/communication.routes';
import contactsRoutes from '@/contacts/contacts.routes';
import dashboardRoutes from '@/dashboard/dashboard.routes';
import formsRoutes from '@/customForms/forms.routes';
import { accountRoutes } from '@/account/account.routes';
import marketingSitesRoutes from '@/marketingSites/marketingSites.routes';
import leadFormRoutes from '@/leadForms/leadForms.routes';
import { moneyRoutes } from '@/money/money.routes';
import onboardingRoutes from '@/onboarding/onboarding.routes';
import pipelineRoutes from '@/pipeline/pipeline.routes';
import reportsRoutes from '@/reports/reports.routes';
import settingsRoutes from '@/settings/settings.routes';
import supportRoutes from '@/support/support.routes';
import tasksRoutes from '@/tasks/tasks.routes';

import store from '@/shared/store';

const routes = [
    {
        path: '/',
        name: 'home',
        redirect: '/dashboard',
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            isPublic: true,
        },
        beforeEnter: (to) => {
            if (to.query?.checkCas) {
                store.dispatch('auth/LOGOUT');

                return window.location.assign(`${process.env.VUE_APP_CAS_URL}/login?service=${window.location.origin}`);
            }

            const appId = store.state.auth.session.coreAppId;
            const logoutUrl = appId
                ? `${process.env.VUE_APP_CORE_URL_PATTERN.replace('{id}', appId)}/j_spring_security_logout`
                : `${process.env.VUE_APP_CAS_URL}/logout?service=${window.location.origin}`;

            store.dispatch('auth/LOGOUT');

            return window.location.assign(logoutUrl);
        },
    },
    {
        path: '/killSession',
        name: 'killSession',
        meta: {
            isPublic: true,
        },
        beforeEnter: (to, from, next) => {
            store.dispatch('auth/LOGOUT');

            return next();
        },
    },
    {
        path: '/signup',
        name: 'signup',
        beforeEnter: () => {
            const queryParams = window.location.search ? `?${window.location.search}` : '';

            return window.location.assign(`${process.env.VUE_APP_FREE_TRIAL_SIGNUP_URL}${queryParams}`);
        },
    },
    ...appointmentRoutes,
    ...automationRoutes,
    ...broadcastsRoutes,
    ...campaignRoutes,
    ...communicationRoutes,
    ...contactsRoutes,
    ...dashboardRoutes,
    ...formsRoutes,
    ...accountRoutes,
    ...leadFormRoutes,
    ...marketingSitesRoutes,
    ...moneyRoutes,
    ...onboardingRoutes,
    ...pipelineRoutes,
    ...reportsRoutes,
    ...settingsRoutes,
    ...supportRoutes,
    ...tasksRoutes,
];

routes.push({
    path: '*',
    redirect: '/dashboard',
});

export default routes;
