<template>
    <div
        v-click-outside="handleBlur"
        class="message-container"
        :class="{ focus: hasFocus || isMergeFieldMenuOpen, submitted }"
    >
        <textarea
            ref="message"
            v-model="localValue"
            class="message"
            data-qa="message"
            :placeholder="placeholder"
            :submitted="submitted"
            :maxlength="maxlength"
            @focus="handleFocus"
            @input="handleInput"
        />

        <div class="text-area-footer">
            <merge-field-dropdown
                class="merge-field-dropdown"
                position="bottom-start"
                :use-merge-service="useMergeService"
                :additional-merge-fields="additionalMergeFields"
                @select="insertMergeField"
                @change="handleChange"
            >
                <template #action="{ toggle }">
                    <div>
                        <ds-icon-button
                            name="merge-field"
                            data-qa="merge-button"
                            class="merge-button"
                            @click="toggle"
                        />
                    </div>
                </template>
            </merge-field-dropdown>
        </div>
    </div>
</template>

<script>
import MergeFieldDropdown from '@/shared/components/MergeField/MergeFieldDropdown';

export default {
    components: {
        MergeFieldDropdown,
    },

    props: {
        value: {
            type: String,
            default: '',
        },

        maxlength: {
            type: Number,
        },

        placeholder: {
            type: String,
            default: '',
        },

        additionalMergeFields: {
            type: Array,
            default: () => [],
        },

        submitted: Boolean,

        useMergeService: Boolean,
    },

    data() {
        return {
            hasFocus: false,
            localValue: this.value,
            isMergeFieldMenuOpen: false,
        };
    },

    watch: {
        value(val) {
            this.localValue = val;
        },
    },

    methods: {
        handleBlur() {
            this.hasFocus = false;
        },

        handleFocus() {
            this.hasFocus = true;
        },

        handleInput(e) {
            this.handleMessageChange(e.target.value);
        },

        handleMessageChange(message) {
            this.localValue = message;
            this.$emit('input', this.localValue);
        },

        handleChange(val) {
            this.isMergeFieldMenuOpen = val;
        },

        async insertMergeField({ value = '' }) {
            const { message } = this.$refs;
            const { selectionStart, selectionEnd } = message;
            const before = this.localValue.substring(0, selectionStart);
            const after = this.localValue.substring(selectionEnd, this.localValue.length);

            this.handleMessageChange(`${before}${value}${after}`);

            // Set cursor to end of merge field
            message.selectionStart = selectionStart + value.length;
            message.selectionEnd = selectionStart + value.length;

            message.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
    .message-container {
        cursor: text;
        display: flex;
        flex-direction: column;
        min-height: px-to-rem(160px);
        border: 1px solid $color-gray-500;
        border-radius: $gp / 2;
        padding: $gp / 2 0;
        background-image: linear-gradient(to bottom, transparent, transparent 70%, $color-gray-050 70%, $color-gray-050 100%);

        .message {
            flex: 1;
            padding: $gp / 4 $gp $gp *.75;
            width: 100%;
            border: none;
            outline: none;
            font-size: $font-size-med;
            resize: none;
        }

        .text-area-footer {
            height: px-to-rem(36px);
            cursor: auto;
        }

        &.focus {
            @include transition(border);
            border-color: $color-blue;

            .message {
                @include transition(border);
                border-color: $color-blue;
            }
        }

        &.submitted {
            @include transition(border);
            border-color: $input-error-color;

            .message {
                @include transition(border);
                border-color: $input-error-color;
            }
        }

        button.merge-button {
            padding: $gp / 4;
            margin: $gp / 4 $gp / 2;
        }

        .merge-field-icon {
            --icon-size: #{px-to-rem($gp)};
            --icon-color: #{$color-gray-900};
        }

        .merge-field-dropdown {
            --merge-field-dropdown-width: auto;
            border-radius: 0 0 $border-radius $border-radius;
        }
    }
</style>
