import axios from 'axios';
import Vue from 'vue';
import gql from 'graphql-tag';
import sentry from '@/analytics/sentry';
import firebase from 'firebase/app';
import {
    IDLE,
    LOADING,
    SUCCESS,
    ERROR,
} from '@/shared/constants/loadingStatuses.constants';
import { GOOGLE_MY_BUSINESS_ERROR_STATUS } from '../reviews.constants';

const processGoogleErrorCode = (error) => {
    let processedError = error;

    if (
        error.graphQLErrors
        && Array.isArray(error.graphQLErrors)
        && error.graphQLErrors[0]?.extensions?.code === GOOGLE_MY_BUSINESS_ERROR_STATUS.UNAUTHORIZED
    ) {
        processedError = new Error(error.graphQLErrors[0].message);
        processedError.code = GOOGLE_MY_BUSINESS_ERROR_STATUS.UNAUTHORIZED;
    }

    return processedError;
};

export default {
    LOAD_BUSINESS_REVIEWS(context, payload) {
        return loadBusinessReviews(context, payload);
    },

    LOAD_GOOGLE_MY_BUSINESSES(context, payload) {
        return loadGoogleMyBusiness(context, payload);
    },

    LOAD_GOOGLE_MY_BUSINESS_REVIEWS(context, payload) {
        return loadGoogleMyBusinessReviews(context, payload);
    },

    LOAD_GOOGLE_BUSINESS_ACCOUNT(context, email) {
        return loadGoogleMyBusinessAccount(context, email);
    },

    LOAD_GOOGLE_MY_BUSINESS_PREFERENCES({ rootState }) {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        return fetchGoogleMyBusinessPreferences(appId, userId);
    },

    LOAD_GOOGLE_MY_BUSINESS_EMAIL({ rootState }) {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        return fetchGoogleMyBusinessEmail(appId, userId);
    },

    UPDATE_GOOGLE_MY_BUSINESS_EMAIL(context, payload) {
        return updateGoogleMyBusinessEmail(context, payload);
    },

    UPDATE_GOOGLE_MY_BUSINESS_PREFERENCES({ rootState, commit }, preferences) {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        return updateGoogleMyBusinessPreferences(appId, userId, preferences)
            .then(() => {
                commit('SET_GOOGLE_BUSINESS_NAME', preferences.locationName);
                commit('SET_GOOGLE_BUSINESS_LOCATION_ID', preferences.locationId);
                commit('SET_GOOGLE_REVIEW_LINK', preferences.reviewLink);
            });
    },

    REMOVE_GOOGLE_MY_BUSINESS_PREFERENCES({ rootState }) {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        return removeGoogleMyBusinessPreferences(appId, userId);
    },

    INITIALIZE_GOOGLE_MY_BUSINESS_LISTINGS(context) {
        const { rootState } = context;

        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        return initializeGoogleMyBusinessListings(context, appId, userId);
    },

    RESET_GOOGLE_MY_BUSINESS_LISTINGS({ commit }) {
        commit('SET_GOOGLE_BUSINESS_EMAIL', '');
        commit('SET_GOOGLE_BUSINESS_ACCOUNT_NAME', '');
        commit('SET_GOOGLE_BUSINESS_NAME', '');
        commit('SET_GOOGLE_BUSINESS_LOCATION_ID', '');
        commit('SET_GOOGLE_REVIEW_LINK', '');
        commit('SET_GOOGLE_BUSINESS_LISTINGS', []);
        commit('SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', IDLE);
    },

    SAVE_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS(context, payload) {
        return saveGoogleBusinessListingLoadingStatus(context, payload);
    },
};

const loadBusinessReviews = async ({ state, commit, rootState }, refresh = false) => {
    try {
        if (state.reviews && !refresh) {
            return state.reviews;
        }

        const { data: { result } } = await axios.get(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/google/maps/place-detail?placeid=${rootState.global.googlePlaceId}&fields=reviews,rating`);

        if (rootState.global.googlePlaceId !== 'DISCONNECTED') {
            commit('SET_BUSINESS_REVIEWS', {
                lastUpdated: new Date(),
                ...result,
            });
        }

        return result;
    } catch (e) {
        return e;
    }
};

const loadGoogleMyBusiness = async ({ commit }, payload) => {
    try {
        const { data: { googleBusinessListings: { locations } } } = await Vue.prototype.$graphql.query({
            query: gql`
                query business($googleBusinessEmail: String!, $accountName: String!){
                    googleBusinessListings(googleBusinessEmail: $googleBusinessEmail, accountName: $accountName) {
                        locations {
                            name
                            locationName
                            primaryPhone
                            address {
                                addressLines
                            }
                            metadata {
                                newReviewUrl
                            }
                        }
                    }
                }
            `,
            variables: {
                googleBusinessEmail: payload.email,
                accountName: payload.accountName,
            },
            fetchPolicy: 'no-cache',
        });

        commit('SET_GOOGLE_BUSINESS_LISTINGS', locations);
    } catch (error) {
        const simplifiedError = processGoogleErrorCode(error);

        sentry.log('Failed go fetch google business listings', { error: JSON.stringify(error) });
        throw simplifiedError;
    }
};

const loadGoogleMyBusinessReviews = async ({ commit }, payload) => {
    commit('SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', LOADING);

    try {
        const { data: { googleBusinessReviews } } = await Vue.prototype.$graphql.query({
            query: gql`
                query businessReviews($googleBusinessEmail: String!, $locationId: String!){
                    googleBusinessReviews(googleBusinessEmail: $googleBusinessEmail, locationId: $locationId) {
                        reviews {
                            comment
                            starRating
                            createTime
                            reviewer {
                                displayName
                                isAnonymous
                                profilePhotoUrl
                            }
                        }
                        averageRating
                        totalReviewCount
                    }
                }
            `,
            variables: {
                googleBusinessEmail: payload.businessEmail,
                locationId: payload.locationId,
            },
            fetchPolicy: 'no-cache',
        });

        commit('SET_GOOGLE_BUSINESS_REVIEWS', googleBusinessReviews);
        commit('SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', SUCCESS);
    } catch (error) {
        const simplifiedError = processGoogleErrorCode(error);

        sentry.log('Failed go fetch google business reviews', { error: JSON.stringify(error) });
        commit('SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', ERROR);
        throw simplifiedError;
    }
};

const loadGoogleMyBusinessAccount = async (_, email) => {
    try {
        const { data: { googleBusinessAccount: { accounts } } } = await Vue.prototype.$graphql.query({
            query: gql`
                query businessAccount($googleBusinessEmail: String!){
                    googleBusinessAccount(googleBusinessEmail: $googleBusinessEmail) {
                        accounts {
                            name
                            accountName
                        }
                    }
                }
            `,
            variables: {
                googleBusinessEmail: email,
            },
            fetchPolicy: 'no-cache',
        });

        return accounts;
    } catch (error) {
        const simplifiedError = processGoogleErrorCode(error);

        sentry.log('Unable to fetch Google Business account', JSON.stringify(error));
        throw simplifiedError;
    }
};

const fetchGoogleMyBusinessEmail = async () => {
    const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/settings/appUserSetting/GoogleBusinessEmail`;

    return axios.get(url)
        .then(({ data }) => {
            return data;
        })
        .catch((error) => {
            sentry.log('Unable to fetch Google Business email', JSON.stringify(error));
            throw error;
        });
};


const updateGoogleMyBusinessEmail = async ({ commit }, email) => {
    const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/settings/appSetting/GoogleBusinessEmail`;

    return axios.put(url, { userSetting: true, value: email }).then(() => {
        commit('SET_GOOGLE_BUSINESS_EMAIL', email);
    }).catch((error) => {
        sentry.log('Unable to update Google Business email', JSON.stringify(error));
        throw error;
    });
};

const fetchGoogleMyBusinessPreferences = async (appId, userId) => {
    try {
        const snapshot = await firebase.database().ref(`googleMyBusiness/${appId}/${userId}/preferences`).once('value');

        return snapshot.val() ? { ...snapshot.val() } : {};
    } catch (error) {
        sentry.log('Unable to fetch Google Business preferences', JSON.stringify(error));
        throw error;
    }
};

const removeGoogleMyBusinessPreferences = async (appId, userId) => {
    return firebase.database().ref(`googleMyBusiness/${appId}/${userId}/preferences`).remove();
};

const updateGoogleMyBusinessPreferences = async (appId, userId, preferences) => {
    try {
        await firebase.database().ref(`googleMyBusiness/${appId}/${userId}/preferences`).update(preferences);

        return preferences;
    } catch (error) {
        sentry.log('Unable to update Google Business preferences', JSON.stringify(error));
        throw error;
    }
};

const initializeGoogleMyBusinessListings = async(context, appId, userId) => {
    const { commit } = context;

    commit('SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', LOADING);

    const email = await fetchGoogleMyBusinessEmail();

    if (!email.length) {
        commit('SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', SUCCESS);

        return;
    }

    commit('SET_GOOGLE_BUSINESS_EMAIL', email);

    const preferences = await fetchGoogleMyBusinessPreferences(appId, userId);

    commit('SET_GOOGLE_BUSINESS_ACCOUNT_NAME', preferences.accountName);
    commit('SET_GOOGLE_BUSINESS_LOCATION_ID', preferences.locationId);
    commit('SET_GOOGLE_REVIEW_LINK', preferences.reviewLink);
    commit('SET_GOOGLE_BUSINESS_NAME', preferences.locationName);

    await loadGoogleMyBusiness(context, { email, accountName: preferences.accountName });


    await loadGoogleMyBusinessReviews(context, { businessEmail: email, locationId: preferences.locationId });
};

const saveGoogleBusinessListingLoadingStatus = async({ commit }, value) => {
    commit('SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS', value);
};
