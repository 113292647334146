import axios from 'axios';
import { captureException } from '@sentry/browser';
import { CONTACT_TYPES } from '@/contacts/contacts.constants';

export const UPDATE_CONTACT_TYPE = async ({ commit }, { id, contactType }) => {
    try {
        const contactPayload = {
            contactType,
            updateMask: ['contact_type'],
        };

        if (!Object.values(CONTACT_TYPES).includes(contactType)) {
            contactPayload.contactType = null;
        }

        commit('UPDATE_CONTACT_TYPE', { id, contactType: contactPayload.contactType });

        await axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/${id}`, contactPayload);
    } catch (e) {
        captureException(e);
    }
};

export const BULK_UPDATE_CONTACT_TYPE = async ({ commit }, {
    ids, contactType, checkAll, filterItems, savedFilterId,
}) => {
    try {
        await axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/contactType/${contactType}`, {
            ids, checkAll, filterItems, savedFilterId,
        });

        commit('BULK_UPDATE_CONTACT_TYPE', { ids, contactType, checkAll });
    } catch (e) {
        captureException(e);
    }
};
