import { calculateInterval } from '@/reports/reports.utils';

export default {
    SET_INTERVAL(state, days) {
        state.interval = calculateInterval(days);
    },

    SET_AGGREGATED_ALL_SALES_DATA(state, data) {
        state.allSales.aggregatedData = data;
    },

    SET_ALL_SALES(state, data) {
        state.allSales.data = data;
    },

    SET_ALL_SALES_LIST_SORT_FIELD(state, field) {
        state.allSales.sortField = field;
    },

    SET_ALL_SALES_LIST_SORT_ASCENDING(state, ascending) {
        state.allSales.ascending = ascending;
    },

    SET_AGGREGATED_EMAIL_ENGAGEMENT_DATA(state, data) {
        state.emailEngagement.aggregatedData = data;
    },

    SET_EMAIL_ENGAGEMENT_EMAILS_DATA(state, data) {
        state.emailEngagement.emails.data = data;
    },

    SET_EMAIL_ENGAGEMENT_EMAILS_DATA_LIST_SORT_FIELD(state, field) {
        state.emailEngagement.emails.sortField = field;
    },

    SET_EMAIL_ENGAGEMENT_EMAILS_DATA_LIST_SORT_ASCENDING(state, ascending) {
        state.emailEngagement.emails.ascending = ascending;
    },

    SET_EMAIL_ENGAGEMENT_CONTACTS_DATA(state, data) {
        state.emailEngagement.contactEngagement.data = data;
    },

    SET_EMAIL_ENGAGEMENT_CONTACTS_DATA_LIST_SORT_FIELD(state, field) {
        state.emailEngagement.contactEngagement.sortField = field;
    },

    SET_EMAIL_ENGAGEMENT_CONTACTS_DATA_LIST_SORT_ASCENDING(state, ascending) {
        state.emailEngagement.contactEngagement.ascending = ascending;
    },

    SET_REPORT_IS_DOWNLOADING(state, flag) {
        state.isDownloading = flag;
    },

    SET_IS_LOADING_CHART(state, flag) {
        state.isLoadingChart = flag;
    },
};
