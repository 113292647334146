import ConnectCalendarModal from '@/appointments/components/ConnectCalendarModal';
import { INTEGRATION_STATUS } from '@/appointments/appointments.constants';

export const getActiveProvider = ({ state }) => {
    const { providers } = state.calendar;
    const activeProvider = providers.find(({ status }) => {
        return status === INTEGRATION_STATUS.READY || status === true;
    });

    return activeProvider?.name;
};

export const handleClose = ({ bus, dispatch, router }) => {
    dispatch('calendar/REDIRECT_AFTER_CONNECT', router);
    bus.$emit('POP_NESTED_MODAL');
};

export const connectToProvider = ({ bus, router, store: { commit, dispatch, state } }) => {
    const calendarProviderId = getActiveProvider({ state });

    commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);

    bus.$emit('PUSH_NESTED_MODAL', {
        component: ConnectCalendarModal,
        modalSize: 'fullscreen',
        props: {
            calendarProviderId,
        },
        showRootClose: true,
        interceptBack: () => bus.$emit('CONNECT_GOOGLE_MODAL_BACK'),
        interceptClose: () => handleClose({ bus, dispatch, router }),
    });
};
