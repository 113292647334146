<script>
import { unlayerDesign } from '@/marketingSites/unlayer/unlayer-design-processor';
import {
    DEFAULT_LANDINGPAGE_FORM,
    FORM_TYPE_LANDING_PAGE,
    KEAP_TOOLS,
} from '@/marketingSites/unlayer/unlayer.constants';
import { loadCustomFormBySlug } from '@/customForms/forms.api';
import { convertFromUnlayerFieldToCustomFormField } from '@/marketingSites/unlayer/unlayer-form-converters';

export default {
    provide() {
        return {
            $customForms: {
                updateCustomFormsWithinPage: this.updateCustomFormsWithinPage,
                loadCustomForm: this.loadCustomForm,
                updateCustomForm: this.updateCustomForm,
                createCustomForm: this.createCustomForm,
            },
        };
    },
    methods: {
        /**
         * Converts all unlayer forms to keap forms, and creates a corresponding record in smart-forms-api
         * if one doesn't already exist.
         * @param {function} formNameGenerator
         * @param {string} formNamePrefix
         * @param page
         * @param design
         *
         * @return {Promise<object>}
         */
        async updateCustomFormsWithinPage({
            formNamePrefix, formNameGenerator, page, design,
        }) {
            const designHelper = unlayerDesign(design);

            await Promise.all(designHelper.findByType(KEAP_TOOLS.keapForm)
                .map(async (cell) => {
                    const { content } = cell;
                    const updatedForm = await this.updateCustomFormFromUnlayerForm({
                        page,
                        content,
                        formNamePrefix,
                        defaultFormName: formNameGenerator(),
                    });

                    Object.assign(content, updatedForm);

                    return updatedForm;
                }));

            return designHelper.design;
        },

        /**
         * Creates a new custom form
         * @param title
         * @param description
         * @param slug
         * @return {Promise<object>}
         */
        createCustomForm({ title, description, slug }) {
            return this.$store.dispatch('customForms/CREATE_FORM', {
                ...DEFAULT_LANDINGPAGE_FORM,
                title,
                description,
                slug,
            });
        },

        /**
         * Loads a custom form by id
         * @param formId
         * @return {Promise<object>}
         */
        loadCustomForm(formId) {
            return this.$store.dispatch('customForms/LOAD_FORM', { formId });
        },

        /**
         * Updates a custom form
         * @param savedForm
         * @return {Promise<object>}
         */
        updateCustomForm(savedForm) {
            return this.$store.dispatch('customForms/SAVE_FORM', savedForm);
        },

        /**
         * Takes in the unlayer tool configuration values for a keap_forms block, and creates or updates
         * a corresponding record in the smart-form-api.  This record id is stored in the tool configuration
         * under the key "keapForm" along with other meaningful data.
         *
         * It's important that this value is set _before_ unlayer renders the markup for the form.  Otherwise,
         * the final page will not have the correct URL to submit to.
         *
         * @param input The values passed from unlayer
         * @param input.content The configuration values for the form
         * @param input.defaultFormName The title of the smart-form
         * @param input.formNamePrefix The hard-coded prefix for the form name
         * @param input.description The description of the smart-form
         * @return {Promise<*>} The modified copy of the original content
         */
        async updateCustomFormFromUnlayerForm({
            content = {}, defaultFormName, formNamePrefix, description = '',
        } = {}) {
            const { values = {}, ...rest } = content;
            const { keapForm = {}, fields = [] } = values;
            let { formName } = values;

            formName = formName ?? defaultFormName;

            const title = `${formNamePrefix} - ${formName}`;

            if (keapForm.slug) {
                if (!keapForm?.id) {
                    // Try to find the form with a matching slug first.  It may have been created previously, but
                    // the unlayer document just hasn't been synced up yet.
                    const bySlug = await loadCustomFormBySlug(keapForm.slug);

                    if (bySlug) {
                        keapForm.id = bySlug.id;
                    } else {
                        // The form doesn't appear to exist, so let's create it using the generated slug
                        const {
                            id, formType, accountId, slug,
                        } = (await this.createCustomForm({ title, description, slug: keapForm.slug })) ?? {};

                        if (id) {
                            Object.assign(keapForm, {
                                id, formType, title, accountId, slug,
                            });
                            values.keapForm = keapForm;
                        }
                    }
                }

                if (keapForm.id) {
                    const savedForm = await this.loadCustomForm(keapForm.id);

                    // Update the form with the fields and name
                    savedForm.fields = fields.map(convertFromUnlayerFieldToCustomFormField).filter((field) => field);
                    savedForm.title = title;
                    savedForm.description = description;
                    savedForm.formType = FORM_TYPE_LANDING_PAGE;

                    await this.updateCustomForm(savedForm);
                }
            }

            return {
                values: {
                    ...values,
                    keapForm,
                    formName,
                },
                ...rest,
            };
        },
    },
    render() { return this.$slots.default; },
};
</script>
