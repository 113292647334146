<template lang="html">
    <div v-if="active" class="mobile-interceptor">
        <section class="wrapper">
            <ds-icon-button name="x" class="corner-navigation" @click="close" />

            <img src="@/shared/images/mobile-responsive.png" width="240" />

            <h4>{{ $t(`mobileInterceptor.${transformedId}`) }}</h4>

            <ds-filled-button class="block large" @click="continueAnyway">
                {{ $t('mobileInterceptor.continueButton') }}
            </ds-filled-button>

            <ds-text-button class="block large" @click="close">
                {{ $t('mobileInterceptor.backButton') }}
            </ds-text-button>
        </section>
    </div>
</template>

<script>
import redirectMixin from '@/shared/mixins/redirect.mixin';
import { RETURN_TO_ROUTES } from '@/shared/constants/routes.constants';

export default {
    mixins: [redirectMixin],

    data() {
        return {
            active: false,
            id: 'default',
            resourceId: null,
        };
    },

    mounted() {
        /* istanbul ignore next */
        this.$bus.$on('OPEN_MOBILE_INTERCEPTOR', this.open);
    },

    beforeDestroy() {
        /* istanbul ignore next */
        this.$bus.$off('OPEN_MOBILE_INTERCEPTOR');
    },

    computed: {
        transformedId() {
            return this.id === 'existing campaign' ? 'campaign' : this.id;
        },
    },

    methods: {
        close() {
            this.active = false;
        },

        open(id, resourceId) {
            this.id = id;
            this.active = true;
            this.resourceId = resourceId;
        },

        continueAnyway() {
            this.active = false;

            switch (this.id) {
            case 'campaign':
                this.redirect_coreUrl('/app/funnel/create');
                break;
            case 'existing campaign':
                this.redirect_coreUrl(`/app/funnel/funnelEditor?funnelId=${this.resourceId}&view=default`);
                break;
            case 'email-broadcast':
                this.redirect_broadcastGallery({
                    listId: 0,
                    returnTo: RETURN_TO_ROUTES.BROADCASTS,
                });
                break;
            case 'split broadcast':
                this.redirect_broadcastGallery({
                    listId: 0,
                    returnTo: RETURN_TO_ROUTES.BROADCASTS,
                    isSplitTest: true,
                });
                break;
            default:
                break;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .mobile-interceptor {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $color-gray-050;
        z-index: $zindex-modal;
        padding: $gp * 5 $gp * 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        h4 {
            margin: $gp * 1.5 0;
        }
    }

    .wrapper {
        max-width: px-to-rem(280px);
    }
</style>

<i18n>
{
    "en-us": {
        "mobileInterceptor": {
            "default": "We recommend using a laptop or desktop to use this feature.",
            "broadcast": "We recommend using a non-touchscreen device to enjoy full functionality",
            "campaign": "We recommend using a non-touchscreen device to enjoy full functionality",
            "continueButton": "Got it, let's go",
            "backButton": "Got it, let's go back"
        }
    }
}
</i18n>
