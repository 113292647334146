<template>
    <custom-forms-provider v-if="appId && isEnabled">
        <create-site-provider :app-id="appId">
            <marketing-sites-provider :app-id="appId" :is-enabled="isEnabled" />
        </create-site-provider>
    </custom-forms-provider>
    <div v-else>
        <div class="spinner-container">
            <ds-spinner />
        </div>
    </div>
</template>
<script>

import MarketingSitesProvider from '@/marketingSites/provider/MarketingSitesProvider';
import CustomFormsProvider from '@/marketingSites/provider/CustomFormsProvider';
import CreateSiteProvider from '@/marketingSites/provider/CreateSiteProvider';
import { mapGetters, mapState } from 'vuex';
import { FF_KEAP_LANDING_PAGES, FF_KEAP_UNLAYER_EMAIL_TEMPLATE_ADMIN } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        CreateSiteProvider,
        CustomFormsProvider,
        MarketingSitesProvider,
    },

    mounted() {
        if (!this.isEnabled) {
            this.$router.replace({ name: 'dashboard' });
        }
    },

    computed: {
        isEnabled() {
            return Boolean((this.hasLandingPagesFeature && this.isLandingPagesEnabled) || this.isGoldenApp);
        },

        ...mapGetters({
            hasLandingPagesFeature: 'auth/hasLandingPagesFeature',
        }),

        ...mapState({
            appId: ({ auth }) => auth.session.coreAppId,
            isLandingPagesEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_LANDING_PAGES],
            isGoldenApp: ({ featureFlags }) => featureFlags[FF_KEAP_UNLAYER_EMAIL_TEMPLATE_ADMIN],
        }),
    },
};
</script>
