<template lang="html">
    <div
        :class="['automation-item', { active }]"
        :data-qa="`automation_${automation.name}`"
        @click="handleListItemClick"
    >
        <div class="automation-icon">
            <ds-icon :name="iconName" :class="['icon', { published: isPublished }]" />
        </div>

        <div class="automation-details">
            <div>
                <div class="automation-subject" :title="automation.name">
                    {{ automation.name }}
                </div>

                <div :class="['automation-status', { published: isPublished, edited: automation.hasChanges }]" :title="createDate">
                    {{ automationStatus }}
                </div>
            </div>
        </div>

        <div ref="dropdown" class="automation-actions">
            <automation-list-item-dropdown
                :automation="automation"
                @open="$emit('open', { automation, index })"
                @delete="$emit('delete', automation)"
                @rename="$emit('rename', automation)"
                @edit="$emit('edit', automation)"
                @revert="$emit('revert', automation)"
                @deactivate="$emit('deactivate', automation)"
                @share="$emit('share', automation)"
                @copy="$emit('copy', automation)"
            />
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { AUTOMATION_STATUS } from '@/automations/constants/automations.constants';
import { targetContainsClickEvent } from '@/shared/utils/events';
import AutomationListItemDropdown from '@/automations/components/list/AutomationListItemDropdown';

export default {
    components: {
        AutomationListItemDropdown,
    },

    props: {
        automation: {
            type: Object,
            required: true,
        },

        active: Boolean,
    },

    computed: {
        createDate() {
            const date = moment(this.isPublished ? this.automation.enabledDate : this.automation.createdDate).format('L');

            return this.$t(this.isPublished ? 'publishDate' : 'createDate', { date });
        },

        iconName() {
            return this.isPublished ? 'zap' : 'zap-off';
        },

        isPublished() {
            return Boolean(this.automation.status === AUTOMATION_STATUS.ENABLED);
        },

        automationStatus() {
            if (this.isPublished) {
                return this.automation.hasChanges ? this.$t('publishedEdited') : this.$t('published');
            }

            return this.$t('draft');
        },
    },

    methods: {
        handleListItemClick(event) {
            if (!targetContainsClickEvent(event, this.$refs.dropdown)) {
                this.$emit('open');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/listItem';
</style>

<i18n>
{
    "en-us": {
        "draft": "Draft",
        "published": "Active",
        "publishedEdited": "Active - Unpublished edits",
        "moreActions": {
            "rename": "Rename",
            "delete": "Delete"
        },
        "createDate": "Created: {date}",
        "publishDate": "Published: {date}"
    }
}
</i18n>
