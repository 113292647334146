import Vue from 'vue';
import { LOADING, SUCCESS, ERROR } from '@/contacts/loadingStatuses';

export default {
    SET_DASHBOARD_TASKS(state, tasks) {
        state.tasks = tasks;
    },

    SET_HIDDEN_DASHBOARD_WIDGETS(state, hiddenWidgets) {
        state.hiddenDashboardWidgets = hiddenWidgets;
    },

    SET_CUSTOM_DASHBOARD(state, customDashboard) {
        state.customDashboard = customDashboard;
    },

    SET_SHARED_WIDGETS(state, sharedWidgets) {
        state.sharedWidgets = sharedWidgets;
    },

    SET_SHARED_WIDGET_USERS(state, sharedWidgetUsers) {
        state.sharedWidgetUsers = sharedWidgetUsers;
    },

    SET_DASHBOARD_CONFIG(state, dashboardConfig) {
        state.dashboardConfig = dashboardConfig;
    },

    SET_CONFIG_SAVED(state, saved) {
        state.configSaved = saved;
    },

    SET_DASHBOARD_MOVE_STATE(state, value) {
        state.dashboardMoveState = value;
    },

    LOAD_CONTACT_TOTAL_START(state) {
        state.totalContacts = 0;
        state.totalContactsStatus = LOADING;
    },

    LOAD_CONTACT_TOTAL_SUCCESS(state, count) {
        state.totalContacts = count;
        state.totalContactsStatus = SUCCESS;
    },

    LOAD_CONTACT_TOTAL_ERROR(state) {
        state.totalContactsStatus = ERROR;
    },

    LOAD_TODOS_START(state) {
        state.toDosStatus = LOADING;
    },

    LOAD_TODOS_SUCCESS(state, toDos) {
        state.toDos = toDos;
        state.toDosStatus = SUCCESS;
    },

    LOAD_TODOS_ERROR(state) {
        state.toDosStatus = ERROR;
    },

    UPDATE_CUSTOM_DASHBOARD_WIDGET(state, widget) {
        Vue.set(state.customDashboard, widget.id, widget);
    },

    UPDATE_SHARED_DASHBOARD_WIDGET(state, widget) {
        Vue.set(state.sharedWidgets, widget.id, widget);
    },

    DELETE_CUSTOM_DASHBOARD_WIDGET(state, id) {
        Vue.delete(state.customDashboard, id);
    },

    DELETE_SHARED_DASHBOARD_WIDGET(state, id) {
        Vue.delete(state.sharedWidgets, id);
    },
};
