<template>
    <div class="template-manager">
        <section class="template-nav">
            <header class="template-nav-header">
                <ds-icon-button name="arrow-left" data-qa="close-manager" @click="back" />

                <h3 class="header-text">
                    {{ $t('emailTemplates') }}
                </h3>

                <ds-icon-button name="add-circle" data-qa="template-manager-create-template" @click="createTemplate" />
            </header>

            <div class="template-items">
                <ds-list-item
                    v-for="(template, i) in sortedEmailTemplates"
                    :key="i"
                    :class="['template-item', { selected: isTemplateSelected(template)} ]"
                    :title="displayTitle(template)"
                    :data-qa="displayTitle(template)"
                    :short-description="displayDescription(template)"
                    @click.native="handleTemplateItemClick(template)"
                />
            </div>
        </section>

        <section v-if="selectedTemplate" :class="['template-editor', { open: editorOpen }]">
            <header class="template-editor-header">
                <ds-icon
                    name="arrow-left"
                    @click.native="setEditorOpen(false)"
                />

                <h4 class="edit-template">
                    {{ $t('editTemplate') }}
                </h4>

                <ds-icon
                    v-if="showDelete"
                    :class="['delete-button-mobile', { 'disable-delete': saving }]"
                    name="trash-2"
                    @click.native="deleteTemplate"
                />
            </header>

            <section :class="['template-editor-title', { 'hide-delete': !showDelete }]">
                <span class="label">{{ $t('titleLabel') }}</span>

                <ds-input-field
                    ref="titleInput"
                    v-model="title"
                    :placeholder="$t('defaultTitle')"
                    @input="handleEdit"
                />

                <ds-icon-button
                    v-if="showDelete"
                    name="trash-2"
                    class="delete-button"
                    :disabled="saving"
                    @click="deleteTemplate"
                />
            </section>

            <section class="template-editor-subject">
                <span class="label">{{ $t('subjectLabel') }}</span>

                <ds-input-field ref="subjectInput" v-model="subject" @input="handleEdit" />
            </section>

            <quill-editor
                ref="quillEditor"
                hide-borders
                mergeable
                class="quill-editor-wrapper"
                @input="handleContentEdit"
            />

            <section class="template-button-row">
                <div class="action-buttons">
                    <appointment-button
                        @setup-appointments="setupAppointments"
                        @appointment-link-insert="insertAppointmentLink"
                    />
                </div>

                <div v-if="saving" class="saving semibold">
                    <ds-icon name="loader" />

                    <span>{{ $t('saving') }}</span>
                </div>

                <div v-if="saved" class="saved">
                    <ds-status type="good" emphasis="medium">
                        {{ $t('saved') }}
                    </ds-status>
                </div>

                <ds-filled-button class="insert-template" @click="insertTemplate">
                    {{ $t('insertTemplate') }}
                </ds-filled-button>

                <ds-filled-button class="insert-template-mobile" @click="insertTemplate">
                    {{ $t('insert') }}
                </ds-filled-button>
            </section>
        </section>

        <section v-if="showFtu" class="ftu">
            <ds-icon name="x" class="close-ftu" @click.native="closeFtu" />

            <svg class="keap-arrow-group">
                <use xlink:href="#keap-arrow-group" />
            </svg>

            <div class="ftu-content">
                <h3>{{ $t('ftuHeader') }}</h3>

                <i18n path="overAndOver" tag="div" class="and-over">
                    <template #huge>
                        <span class="huge">{{ $t('over') }}</span>
                    </template>

                    <template #lg>
                        <span class="lg">{{ $t('andOver') }}</span>
                    </template>

                    <template #med>
                        <span class="med">{{ $t('andOver') }}</span>
                    </template>

                    <template #small>
                        <span class="small">{{ $t('andOver') }}</span>
                    </template>

                    <template #xs>
                        <span class="xs">{{ $t('andOver') }}</span>
                    </template>

                    <template #xs2>
                        <span class="xs2">{{ $t('andOver') }}</span>
                    </template>
                </i18n>

                <br />

                <div class="ftu-description">
                    <span>{{ $t('ftuDescription.1') }}</span>

                    <br />

                    <span class="description2">
                        <ds-icon name="templates" />

                        {{ $t('ftuDescription.2') }}
                    </span>
                </div>
            </div>

            <footer class="ftu-footer">
                <span @click="closeFtu">{{ $t('global.okgotit') }}</span>
            </footer>
        </section>
    </div>
</template>

<script>
import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';

import AppointmentButton from '@/communication/components/AppointmentButton';
import QuillEditor from '@/shared/components/Text/QuillEditor';

import { MEDIA_TYPES, TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import { gmbReviewLinkText } from '@/reviews/reviewUtils';
import reviewsMixin from '@/reviews/mixins/reviews.mixin';

import '@/shared/images/keap-arrow-group.svg';

export default {
    components: {
        AppointmentButton,
        QuillEditor,
    },

    mixins: [reviewsMixin],

    props: {
        editDelay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
        templateManagerOptions: {
            type: Object,
            default: () => ({
                shouldOpen: false,
                createTemplateOptions: {
                    title: '',
                    content: '',
                    subject: '',
                },
            }),
        },
        source: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            selectedTemplate: null,
            editorOpen: false,
            nestedModal_removeInnerPadding: true,
            nestedModal_showBack: false,
            saving: false,
            saved: false,
            subject: '',
            timeout: null,
            title: '',
        };
    },

    created() {
        if (this.editDelay > 0) {
            this.handleEdit = debounce(this.handleEdit, this.editDelay);
        }
    },

    beforeMount() {
        if (this.templateManagerOptions?.createTemplateOptions?.title) {
            this.createTemplate();
        }
    },

    mounted() {
        intercom.logEvent(intercom.events.MANAGE_EMAIL_TEMPLATES_VIEWED);
    },

    computed: {
        ...mapState({
            tutorials: ({ tutorials }) => tutorials.items,
            googleReviewLink: ({ reviews }) => reviews.googleReviewLink,
        }),

        ...mapGetters({
            bookingUrl: 'calendar/bookingUrl',
            sortedEmailTemplates: 'communicationTemplates/sortedEmailTemplates',
            displayTitle: 'communicationTemplates/displayTitle',
            displayDescription: 'communicationTemplates/displayDescription',
        }),

        showDelete() {
            return Boolean(
                this.selectedTemplate
                && this.selectedTemplate.id
                && typeof this.selectedTemplate.id === 'string'
                && !this.selectedTemplate.id.startsWith('default'),
            );
        },

        showFtu() {
            return !this.templatesTutorialComplete && !this.selectedTemplate;
        },

        templatesTutorialComplete() {
            return Boolean(this.tutorials[TUTORIAL_TYPES.EMAIL_TEMPLATES]);
        },
    },

    methods: {
        back() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        closeFtu() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.EMAIL_TEMPLATES,
                value: true,
            });
            this.setTemplate(this.sortedEmailTemplates[0]);
        },

        async createTemplate() {
            const payload = {
                mediaType: MEDIA_TYPES.HTML,
                templateKind: TEMPLATE_KINDS.CUSTOM,
                title: this.templateManagerOptions?.createTemplateOptions?.title || '',
                content: this.templateManagerOptions?.createTemplateOptions?.content || '',
                subject: this.templateManagerOptions?.createTemplateOptions?.subject || '',
            };

            const createdTemplate = await this.$store.dispatch('communicationTemplates/CREATE_EMAIL_TEMPLATE', { payload });

            await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'editEmailTemplate');

            if (this.templateManagerOptions?.createTemplateOptions?.title === 'Request referral') {
                await this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                    key: TUTORIAL_TYPES.REFERRAL_EMAIL_TEMPLATE,
                    value: true,
                });
            }

            this.$track('Email Templates - Manager - Created template');
            amplitude.v2.logEvent(amplitude.v2.events.EMAIL_TEMPLATE_CREATED);

            this.setEditorOpen(true);
            this.setTemplate(createdTemplate);
        },

        async deleteTemplate() {
            this.saving = false;
            const templateId = this.selectedTemplate.id;

            await this.$confirm({
                optionTitle: this.$t('deleteTemplate.title'),
                optionMessage: this.$t('deleteTemplate.message'),
                optionConfirmButtonLabel: this.$t('deleteTemplate.confirm'),
                destructive: true,
                optionCancel: this.$t('deleteTemplate.cancel'),
            });

            await this.$store.dispatch('communicationTemplates/DELETE_EMAIL_TEMPLATE', { templateId });

            this.$track('Email Templates - Manager - Deleted template');
            this.setEditorOpen(false);
            this.setTemplate(this.sortedEmailTemplates[0]);
        },

        handleTemplateItemClick(template) {
            this.setEditorOpen(true);
            this.setTemplate(template);
        },

        handleContentEdit() {
            if (this.$refs.quillEditor.getContent() === this.selectedTemplate.content) {
                return;
            }

            this.handleEdit();
        },

        async handleEdit() {
            this.saving = true;
            this.saved = false;
            const templateKind = this.selectedTemplate.templateKind || TEMPLATE_KINDS.CUSTOM;
            const { subject, title } = this;
            const templateId = this.selectedTemplate.id;

            const payload = {
                mediaType: MEDIA_TYPES.HTML,
                templateKind,
                title,
                subject,
                content: this.$refs.quillEditor.getContent(),
            };

            await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'editEmailTemplate');

            if (templateId.startsWith('default')) {
                const createdTemplate = await this.$store.dispatch('communicationTemplates/CREATE_EMAIL_TEMPLATE', { payload });

                this.selectedTemplate = createdTemplate;
            } else {
                await this.$store.dispatch('communicationTemplates/UPDATE_EMAIL_TEMPLATE', {
                    payload,
                    templateId,
                });
            }

            amplitude.v2.logEvent(amplitude.v2.events.EMAIL_TEMPLATE_EDITED);

            this.saving = false;
            this.saved = true;
        },

        insertAppointmentLink(appointmentType) {
            const apptUrl = this.bookingUrl(appointmentType);
            const apptLink = `<a href="${apptUrl}" target="_blank" rel="noopener noreferrer">${apptUrl}</a>`;

            this.$refs.quillEditor.insertValueAtCursor(apptLink, true);
        },

        insertTemplate() {
            const { id, title } = this.selectedTemplate;
            const { subject } = this;
            const template = {
                id,
                title,
                subject,
                content: this.$refs.quillEditor.getContent(),
            };

            this.$bus.$emit('INSERT_COMMUNICATION_TEMPLATE', template);

            this.$store.commit('communicationTemplates/SET_CURRENT_EMAIL_TEMPLATE', {
                ...template,
                templateKind: this.selectedTemplate.templateKind,
            });

            this.back();
        },

        isTemplateSelected(template) {
            return this.selectedTemplate
                ? this.selectedTemplate.id === template.id
                : false;
        },

        nestedModal_open() {
            if (this.templatesTutorialComplete && this.sortedEmailTemplates && this.sortedEmailTemplates.length > 0) {
                this.setTemplate(this.sortedEmailTemplates[0]);
            }
        },

        setEditorOpen(value) {
            this.editorOpen = value;
        },

        setTemplate(template = {}) {
            this.selectedTemplate = template;
            this.title = template.title || '';
            this.subject = template.subject || '';
            this.saved = false;

            if (template.templateKind === 'REVIEW') {
                template.content = this.$t('defaultEmailTemplates.6.body', {
                    reviewUrl: this.googleReviewLink ? gmbReviewLinkText(this.googleReviewLink)
                        : this.reviews_reviewLinkText,
                });
            }

            this.$nextTick(() => {
                template.content
                    ? this.$refs.quillEditor.setContent(template.content)
                    : this.$refs.quillEditor.clearContent();
            });
        },

        setupAppointments() {
            this.$router.push({ name: 'calendar' });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $template-manager-height: #{px-to-rem(700px)};
    $template-nav-width: #{px-to-rem(360px)};
    $ftu-confirm-background-color: #297f29;

    .template-manager {
        display: flex;
        height: $template-manager-height;

        @media($small) {
            height: 100vh;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .template-nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: $template-nav-width;

        @media($small) {
            flex: 1;
            min-height: 0;
            max-width: 100%;
        }
    }

    .header-text {
        padding: 0 $gp / 2;
        flex: 1;
    }

    .hide-template-nav,
    .insert-template,
    .keap-arrow-group,
    .empty-state {
        @media($small) {
            display: none;
        }
    }

    .template-nav-header {
        display: flex;
        padding: $gp;
        align-items: center;

        @media($small) {
            width: 100%;
        }
    }

    .template-items {
        @include overflowY;
    }

    .template-item {
        cursor: pointer;

        &.selected {
            background-color: $color-gray-050;
        }

        &:hover {
            background-color: $color-gray-050;
        }

        @media ($small) {
            &.selected {
                background-color: $color-paper;
            }
        }
    }

    .template-editor {
        display: flex;
        flex-direction: column;
        background-color: $color-paper;
        width: 100%;
        @include border-start(1px solid $color-gray-200);
        @include border-radius(0 $border-radius $border-radius 0);
        --input-margin-bottom: 0;
        --input-padding: 0;
        --input-border: none;
        --input-focus-border: none;
        --input-height: #{$gp * 1.5};

        @media($small) {
            @include transition(transform);

            transform: translateX(100%);
            @include position(absolute 0 0 auto auto);
            z-index: 5;
            height: 100vh;

            &.open {
                transform: translateX(0);
            }

            &:not(.open) {
                transform: translateX(100%);
            }
        }
    }

    .template-editor-header,
    .insert-template-mobile {
        display: none;

        @media($small) {
            display: flex;
        }
    }

    .edit-template {
        flex: 1;
        margin: 0 $gp / 2;
    }

    .template-nav-header,
    .template-editor-header,
    .template-editor-title,
    .template-editor-subject {
        border-bottom: 1px solid $color-gray-200;
    }

    .template-editor-subject {
        padding: $gp $gp / 2;

        @media($small) {
            padding: $gp / 2;
        }
    }

    .template-editor-header,
    .template-button-row {
        padding: $gp;
    }

    .template-editor-title,
    .template-editor-subject {
        display: flex;
        align-items: center;
        font-size: $font-size-med;

        .label {
            padding: 0 $gp / 2;
            color: $color-gray-700;
        }
    }

    .template-editor-title {
        padding: $gp / 2 $gp / 2;
        white-space: nowrap;

        &.hide-delete {
            padding: $gp $gp / 2;
        }
    }

    .delete-button {
        &-mobile {
            display: none;

            &.disable-delete {
                --icon-color: #{$color-gray-700};
            }
        }

        &:disabled {
            background-color: $color-paper;
        }

        @media($small) {
            display: none;

            &-mobile {
                display: block;
            }
        }
    }

    .quill-editor-wrapper {
        flex: 1;
        justify-content: flex-end;
        --quill-editor-max-height: #{px-to-rem(473px)};
    }

    .template-button-row {
        display: flex;
        align-items: center;
    }

    .action-buttons {
        flex: 1;
    }

    .saving {
        display: flex;
        align-items: center;
        color: $color-gray-700;
        --icon-size: #{$gp};
        font-size: $font-size-xs;
        padding: 0 $gp;

        .icon {
            animation: spin 4s infinite;
            padding: $gp / 8;
        }
    }

    .saved {
        padding: 0 $gp;
    }

    .ftu {
        display: flex;
        overflow: hidden;
        flex-direction: column;
        width: 100%;
        align-items: center;
        border-radius: $border-radius;
        box-shadow: $elevation-z12;
        color: $color-paper;
        background-color: $color-green;

        h3,
        .and-over {
            font-family: $font-family-empty-state-title;
            font-weight: bold;
        }

        @media($small) {
            flex: 1;
        }
    }

    .close-ftu {
        --icon-cursor: pointer;
        align-self: flex-start;
        --icon-margin: #{$gp};
        z-index: $zindex-modal + 1;
    }

    .keap-arrow-group {
        transform: scale(3)
            rotate(180deg)
            translate(-10px, -5px);
    }

    .ftu-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: center;
        justify-content: center;

        @media($small) {
            margin: $gp;
        }
    }

    .huge {
        font-size: $font-size-huge;
    }

    .lg {
        font-size: $font-size-lg;
    }

    .med {
        font-size: $font-size-med;
    }

    .small {
        font-size: $font-size-small;
    }

    .xs {
        font-size: $font-size-xs;
    }

    .xs2 {
        font-size: $font-size-xs / 2;
    }

    .ftu-description {
        --icon-size: #{$icon-size};
        text-align: center;
        margin: 0 $gp;

        span {
            line-height: 1.5;
        }

        .description2 {
            display: flex;
            margin: $gp / 2;
        }
    }

    .ftu-footer {
        padding: $gp;
        text-align: center;
        width: 100%;
        border-radius: 0 0 $border-radius $border-radius;

        // TODO: According to Kristen Giamello, this color is missing in design system and will be added in the future
        background-color: $ftu-confirm-background-color;

        span {
            cursor: pointer;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "emailTemplates": "Email templates",
        "defaultTitle": "New template",
        "defaultSubject": "(no subject)",
        "editTemplate": "Edit template",
        "deleteTemplate": {
            "title": "Delete template?",
            "message": "Once you delete this template, it's gone forever.",
            "confirm": "Yes, delete",
            "cancel": "No, cancel"
        },
        "titleLabel": "Template name: ",
        "subjectLabel": "Subject: ",
        "saving": "Saving",
        "saved": "Saved",
        "insertTemplate": "Insert template",
        "insert": "Insert",
        "ftuHeader": "Stop writing the same email",
        "overAndOver": "{huge} {lg} {med} {small} {xs} {xs2}",
        "over": "over",
        "andOver": "and over",
        "ftuDescription" : {
            "1": "Start with an easy-to-edit template. Or create your own.",
            "2": "Access your templates whenever you see this icon."
        }
    }
}
</i18n>
