<template>
    <ds-dropdown position="bottom-end" class="new-button-dropdown">
        <template #default>
            <ds-icon-button
                name="add"
                floating
                class="new-icon-button"
                :aria-label="$t('global.new')"
            />
        </template>

        <template #menu>
            <ul class="dropdown-menu-list">
                <li class="dropdown-menu-item" @click="openAddContactModal">
                    <ds-icon name="contact-add-user-plus" class="new-menu-item-icon" />
                    <span>{{ $t('global.contact') }}</span>
                </li>

                <li
                    v-if="isKeapPhoneEnabled"
                    class="dropdown-menu-item"
                    @click="navigateToNewSMS"
                >
                    <ds-icon name="message" class="new-menu-item-icon" />
                    <span>{{ $t('global.message') }}</span>
                </li>

                <li
                    v-if="hasApptType"
                    class="dropdown-menu-item"
                    @click="openAppointmentModal"
                >
                    <ds-icon name="calendar" class="new-menu-item-icon" />
                    <span>{{ $t('global.appointment') }}</span>
                </li>

                <li class="dropdown-menu-item" @click="openTaskModal">
                    <ds-icon name="tasks" class="new-menu-item-icon" />
                    <span>{{ $t('global.task') }}</span>
                </li>

                <li class="dropdown-menu-item" @click="openSingleEmailModal">
                    <ds-icon name="email" class="new-menu-item-icon" />
                    <span>{{ $t('global.email') }}</span>
                </li>

                <li class="dropdown-menu-item" @click="openNoteModal">
                    <ds-icon name="note" class="new-menu-item-icon" />
                    <span>{{ $t('global.note') }}</span>
                </li>
            </ul>

            <portal to="root">
                <task-form
                    event-source="Global Action Button"
                    :is-open="isTaskModalOpen"
                    @close="isTaskModalOpen = false"
                    @cancel="isTaskModalOpen = false"
                />
            </portal>
        </template>
    </ds-dropdown>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import NewAppointmentFromCalendar from '@/appointments/components/NewAppointmentFromCalendar';
import NewNoteForm from '@/shared/components/Note/NewNoteForm';
import SingleEmailModal from '@/contacts/components/email/SingleEmailModal';
import TaskForm from '@/tasks/components/TaskForm';

import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';

import { CONTACT_RECORD } from '../../contacts/contactEmailTypes';
import { PREFERENCE_TYPES } from '@/shared/constants/preferences.constants';

export default {
    components: {
        TaskForm,
    },

    mixins: [closeConfirmation],

    props: {
        isExpanded: Boolean,
    },

    data() {
        return { isTaskModalOpen: false };
    },

    computed: {
        ...mapState({
            apptTypesLoaded: ({ calendar }) => calendar.apptTypesLoaded,
            keapPhoneAccount: ({ auth }) => auth.account.keapPhoneAccountInfo,
        }),

        ...mapGetters({
            getPreference: 'preferences/getPreference',
            hasApptType: 'calendar/hasApptType',
        }),

        isKeapPhoneEnabled() {
            return this.keapPhoneAccount != null && Boolean(this.keapPhoneAccount.phoneNumber);
        },
    },

    methods: {
        navigateToNewSMS() {
            this.$router.push({ name: 'communication-sms-new' });
        },

        openAddContactModal() {
            this.$bus.$emit('OPEN_ADD_CONTACT_MODAL', { id: this.getPreference(PREFERENCE_TYPES.ADD_CONTACT_FORM_ID) });
        },

        openAppointmentModal() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: NewAppointmentFromCalendar,
                showRootClose: true,
                modalSize: 'xl',
                props: { eventSource: 'Global Action Button' },
            });
        },

        openNoteModal() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: NewNoteForm,
                showRootClose: true,
            });
        },

        openSingleEmailModal() {
            this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);
            this.$store.commit('email/RESET_CURRENT_EMAIL');

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SingleEmailModal,
                showRootClose: true,
                props: {
                    source: CONTACT_RECORD,
                },
                interceptClose: () => this.confirmCloseDraft(() => this.$bus.$emit('POP_NESTED_MODAL')),
            });
        },

        openTaskModal() {
            this.isTaskModalOpen = true;
        },
    },
};
</script>

<style lang="scss" scoped>
nav {
    color: $color-paper;
}

.new-button-dropdown {
    --dropdown-height: #{px-to-rem(600)};
}

.new-icon-button {
    margin-bottom: $gp / 2;
    --icon-color: #{$color-green};
}

.new-menu-item-icon {
    @include margin-end(px-to-rem(20));
}

li.dropdown-menu-item {
    --icon-color: #{$color-ink-800};
}
</style>
