<template>
    <portal to="root">
        <controlled-modal
            :is-open="isOpen"
            show-close
            intercept-close
            @close="$emit('close')"
        >
            <template #title>
                <slot name="title" />
            </template>

            <template #default>
                <slot name="description" />

                <custom-field-edit-form
                    v-if="isOpen"
                    :record-type="recordType"
                    :custom-field="customField"
                    :custom-field-loading-status="customFieldLoadingStatus"
                    :update-custom-field="updateCustomField"
                    :delete-custom-field="deleteCustomField"
                    :custom-fields="customFields"
                    name-prop-name="label"
                    type-prop-name="fieldType"
                    values-prop-name="options"
                    :object-name="$t('objectName')"
                    :parent-name="$t(`parentName.${recordType}`)"
                    :custom-field-to-set="customField"
                    is-modal-context
                    @close="$emit('close')"
                    @updated="$emit('updated')"
                />
            </template>
        </controlled-modal>
    </portal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';
import CustomFieldEditForm from '@/customFields/components/CustomFieldEditForm.vue';
import {
    IDLE,
    LOADING,
    SUCCESS,
    ERROR,
} from '@/shared/constants/loadingStatuses.constants';

import { CUSTOM_FIELD_RECORD_TYPES } from '@/customFields/customFields.constants';

import {
    getCustomField,
    deleteCustomField,
    updateCustomField,
} from '@/customFields/customFields.api';

export default {
    components: {
        ControlledModal,
        CustomFieldEditForm,
    },

    props: {
        isOpen: Boolean,
        recordType: {
            type: String,
            validator: (value) => CUSTOM_FIELD_RECORD_TYPES.includes(value),
        },
        id: {
            type: [String, Number],
            required: false,
        },
        customFields: {
            type: Array,
            required: true,
        },
        customFieldMax: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            customField: {},
            customFieldLoadingStatus: IDLE,
        };
    },

    mounted() {
        this.loadCustomField();
    },

    watch: {
        id() {
            this.loadCustomField();
        },
    },

    computed: {
        customFieldCount() {
            return this.customFields.length;
        },
    },

    methods: {
        loadCustomField() {
            if (this.id) {
                this.customFieldLoadingStatus = LOADING;

                return getCustomField({ fieldId: this.id })
                    .then((customField) => {
                        this.customFieldLoadingStatus = SUCCESS;
                        this.customField = customField;
                    })
                    .catch(() => {
                        this.customField = {};
                        this.customFieldLoadingStatus = ERROR;
                        this.$error({ message: this.$t('loadError') });
                    });
            }

            this.customField = {};

            return Promise.resolve();
        },

        updateCustomField,
        deleteCustomField,
    },
};
</script>

<i18n>
{
    "en-us": {
        "objectName": "custom field",
        "parentName": {
            "contact": "contacts",
            "company": "companies"
        },
        "loadError": "Something went wrong and we couldn't load the custom field. Please try again.",
        "description": "You can add up to {remaining} custom fields to your {recordType} records. Be sure to select a field type that best matches the data you plan to enter."
    }
}
</i18n>
