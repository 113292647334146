<template>
    <div class="quill-editor-container">
        <div v-show="editor" :class="['quill-editor', { 'hide-borders': hideBorders, submitted }]">
            <div ref="editorContainer" :class="containerClass" />

            <div v-show="!hideToolbar" id="toolbar-container" class="quill-toolbar">
                <div :id="`toolbar-${toolbarId}`">
                    <span class="ql-formats">
                        <button class="ql-bold" />
                        <button class="ql-italic" />
                        <button class="ql-underline" />
                        <button class="ql-strike" />
                    </span>

                    <span class="ql-formats">
                        <select class="ql-font">
                            <option value="sans-serif" selected>Sans Serif</option>
                            <option value="serif">Serif</option>
                            <option value="monospace">Monospace</option>
                        </select>
                    </span>

                    <span class="ql-formats">
                        <select class="ql-size">
                            <option value="small">Small</option>
                            <option selected>Normal</option>
                            <option value="large">Large</option>
                            <option value="huge">Huge</option>
                        </select>
                    </span>

                    <span id="ql-align-bar" class="ql-formats">
                        <button class="ql-list" value="ordered" />
                        <button class="ql-list" value="bullet" />

                        <button class="ql-align" value="" />
                        <button class="ql-align" value="center" />
                        <button class="ql-align" value="right" />
                    </span>

                    <span class="ql-formats end">
                        <button class="ql-clean" />
                        <button class="ql-link last" />

                        <merge-field-dropdown
                            v-if="mergeable"
                            class="merge-field-dropdown"
                            :use-merge-service="useMergeService"
                            :additional-merge-fields="additionalMergeFields"
                            @menu-open="handleMenuOpen"
                            @menu-closed="handleMenuClosed"
                            @select="insertMergeFieldOptionIntoInput"
                        >
                            <template #action="{ toggle }">
                                <div>
                                    <button type="button" @click="toggle" @focus="handleMenuOpen">
                                        <ds-icon class="merge-field-icon" name="merge-field" />
                                    </button>
                                </div>
                            </template>
                        </merge-field-dropdown>
                    </span>
                </div>
            </div>
        </div>

        <div v-if="hasChunkError" class="error-banner-container">
            <error-banner
                :message="$t('chunkError')"
                :action-message="$t('refresh')"
                action-icon="refresh-cw"
                @action="refresh"
            />
        </div>
    </div>
</template>

<script>
import { loadQuill, getLineBreakModule } from '@/shared/components/Text/quill';

import { uniqueKey } from '@infusionsoft/design-system/src/mixins';
import { normalizeHtml } from '@/shared/utils/quill.util';

import MergeFieldDropdown from '@/shared/components/MergeField/MergeFieldDropdown';
import sentry from '@/analytics/sentry';
import ErrorBanner from '../Email/ErrorBanner.vue';

export const DEFAULT_FORMATS = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
];

export const IMAGE_FORMAT = 'image';

const ALL_FORMATS = [
    ...DEFAULT_FORMATS,
    IMAGE_FORMAT,
    'formula',
    'video',
];

export default {
    name: 'QuillEditor',

    components: {
        MergeFieldDropdown,
        ErrorBanner,
    },

    mixins: [uniqueKey],

    props: {
        value: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: '',
        },

        mergeable: {
            type: Boolean,
            default: false,
        },

        customBlot: Function,

        formatHtml: Function,

        initOnMount: {
            type: Boolean,
            default: true,
        },

        hideToolbar: Boolean,

        hideBorders: Boolean,

        containerClass: String,

        disabled: Boolean,

        submitted: Boolean,

        additionalMergeFields: {
            type: Array,
            default: () => [],
        },

        useMergeService: Boolean,

        formats: {
            type: Array,
            default: () => ALL_FORMATS,
        },
    },

    data() {
        return {
            editor: null,
            inputFocused: false,
            menuOpened: false,
            hasChunkError: false,
        };
    },

    beforeDestroy() {
        if (this.editor != null && typeof this.editor.off === 'function') {
            this.editor.off('text-change', this.emitInput);
            this.editor = null;
        }
    },

    mounted() {
        if (this.initOnMount) {
            this.initEditor();
        }
    },

    computed: {
        hasContent() {
            return this.editor != null && this.editor.getLength() > 1;
        },

        toolbarId() {
            return this.uniqueKey_generate({});
        },
    },

    methods: {
        clearContent() {
            if (this.editor != null) {
                this.editor.setText('');
            }
        },

        getContent() {
            return normalizeHtml(this.$refs.editorContainer.innerHTML);
        },

        handleMenuOpen() {
            this.menuOpen = true;
        },

        handleMenuClosed() {
            this.menuOpen = false;
        },

        emitInput() {
            this.$emit('input', this.getContent());
        },

        initEditor() /* istanbul ignore next */ {
            return loadQuill()
                .then((Quill) => {
                    if (typeof this.customBlot === 'function') {
                        this.registerCustomBlot(Quill);
                    }

                    // Code around a possible race condition where the quilljs bundle may load,
                    // but then the QuillEditor component may be destroyed during the render
                    // process of a parent component.
                    if (this.$refs.editorContainer != null) {
                        const lineBreakModule = getLineBreakModule(Quill);

                        this.editor = new Quill(this.$refs.editorContainer, {
                            bounds: this.$refs.editorContainer,
                            modules: {
                                ...lineBreakModule,
                                toolbar: `#toolbar-${this.toolbarId}`,
                            },
                            placeholder: this.placeholder,
                            theme: 'snow',
                            formats: this.formats,
                        });
                        this.editor.enable(!this.disabled);

                        this.setContent(this.value);
                        this.editor.on('text-change', this.emitInput);
                    }
                }).catch((error) => {
                    sentry.captureException(error);
                    this.hasChunkError = true;
                });
        },

        insertMergeFieldOptionIntoInput(mergeFieldOption = { value: '' }) {
            const { value } = mergeFieldOption;

            this.insertValueAtCursor(value);
        },

        insertValueAtCursor(value, shouldEmbedHtml = false) {
            if (value && this.editor) {
                const { index, length } = this.editor.getSelection(true);

                if (length) {
                    this.editor.deleteText(index, length, 'api');
                }

                if (shouldEmbedHtml) {
                    // NOTE: It is possible to embed dangerous HTML in Quill editor.
                    // https://jira.infusionsoft.com/browse/FS-10156
                    this.editor.clipboard.dangerouslyPasteHTML(index, value, 'api');
                } else {
                    this.editor.insertText(index, value, 'api');
                }

                this.editor.setSelection(index, value.length, 'api');
            }
        },

        registerCustomBlot(quillInstance) {
            return quillInstance.register(this.customBlot(quillInstance));
        },

        setContent(content) {
            return loadQuill().then(() => {
                if (content && this.editor != null) {
                    // NOTE: It is possible to embed dangerous HTML in Quill editor.
                    // https://jira.infusionsoft.com/browse/FS-10156
                    this.editor.root.innerHTML = content;

                    this.editor.getSelection(true);

                    if (typeof this.formatHtml === 'function') {
                        const formattedHtml = this.formatHtml(this.getContent());

                        this.editor.clipboard.dangerouslyPasteHTML(formattedHtml, 'api');
                    }

                    this.editor.setSelection(content.length, 0, 'api');
                }
            });
        },

        refresh() {
            window.location.reload();
        },
    },
};
</script>

<!--
    NOTE: This is purposefully un-scoped to allow us to override how Quill editor
    styles dynamically created elements.
-->
<style lang="scss" rel="stylesheet/scss" type="text/scss">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";

$editor-height: px-to-rem(216px);
$editor-max-height: auto;
$editor-border-radius: 0 0 $border-radius $border-radius;
$quill-icon-hover-color: #0066cc;

.quill-editor {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    p {
        margin: $gp / 2 0;
    }

    .ql-toolbar {
        border-color: $color-gray-200;
        border-bottom: none;
        padding: 0;
        border-radius: $border-radius $border-radius 0 0;
        display: flex;

        #toolbar {
            width: 100%;
            display: flex;
            flex-direction: row;
        }

        &.ql-snow .ql-formats {
            margin: 0;
        }

        button,
        svg {
            width: px-to-rem(32px);
        }

        .merge-field-dropdown {
            height: px-to-rem(24px);
            --merge-field-dropdown-width: #{px-to-rem(24px)};
        }

        .merge-field-icon {
            --icon-size: #{px-to-rem(18px)};
            --icon-color: #{$color-gray-700};
            height: 100%;

            &:hover {
                --icon-color: #{$quill-icon-hover-color};
            }
        }
    }

    .ql-container {
        @include overflowY;
        min-height: var(--quill-editor-height, #{$editor-height});
        max-height: var(--quill-editor-max-height, #{$editor-max-height});
        border-radius: var(--quill-editor-border-radius, #{$editor-border-radius});
        border-color: $color-gray-200;
        border-bottom-color: var(--quill-editor-bottom-border-color, #{$color-gray-200});
        background: $color-paper;
        font-size: inherit;
    }

    .ql-toolbar.ql-snow + .ql-container.ql-snow {
        border: $input-border;
    }

    .ql-formats {
        padding: $gp / 2 0;
        display: flex;
        color: $color-gray-600;

        &.end {
            @include border-end(none);

            &:after {
                display: none;
            }
        }
    }

    .ql-editor {
        min-height: var(--quill-editor-height, #{$editor-height});
    }

    .ql-blank:before {
        padding-top: px-to-rem(9px);
        font-style: normal;
        color: $color-gray-500;
    }

    .ql-fill {
        fill: $color-gray-600;
    }

    .ql-stroke {
        stroke: $color-gray-600;
    }

    .ql-picker-label {
        color: $color-gray-600;
    }

    .ql-tooltip {
        z-index: 5;
    }

    &.hide-borders {
        .ql-container {
            border: none;
        }

        .ql-toolbar.ql-snow {
            border: none;
            border-bottom: 1px solid $color-gray-200;
        }
    }

    &.submitted {
        .ql-toolbar, .ql-container {
            border-color: $color-red;
        }
    }
}
</style>

<style lang="scss" scoped>
    .error-banner-container {
        padding: $gp / 4 $gp * .375;
    }

    @mixin small-quill-editor-view() {
        .ql-toolbar.ql-snow {
            .ql-strike,
            .ql-clean,
            #ql-align-bar {
                display: none;
            }
        }

        // hides the text style picker
        .ql-toolbar.ql-snow .ql-formats:nth-child(2) {
            display: none;
        }

        .ql-container {
            border-radius: var(--quill-editor-mobile-border-radius, #{$border-radius $border-radius 0 0});
        }
    }

    @media($small) {
        @include small-quill-editor-view();
    }

    .contextual-view {
        @include small-quill-editor-view();
    }
</style>

<i18n>
{
    "en-us": {
        "chunkError": "Oops, our email editor didn’t load. Please refresh the page to write an email.",
        "refresh": "Refresh"
    }
}
</i18n>
