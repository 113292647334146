<template>
    <ds-input-field
        :value="value"
        :pattern="pattern"
        v-bind="$attrs"
        v-on="$listeners"
        @blur="timeInputAutoFormat"
        @input="$emit('input', value)"
    />
</template>

<script>
import moment from 'moment-timezone';
import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';

const AUTOFORMAT_TIMEOUT = 100;

export default {
    props: {
        defaultTime: {
            type: String,
            default() {
                return this.currentTime;
            },
        },
        value: String,
    },

    data() {
        return {
            pattern: '((1[0-2]|0?[1-9]):([0-5][0-9]) ?([AaPp][Mm]))',
        };
    },

    computed: {
        currentTime() {
            const roundedTime = Math.ceil(moment().minute() / 15) * 15;

            return moment().minute(roundedTime).format(TIME_FORMAT);
        },
    },

    methods: {
        timeInputAutoFormat() {
            setTimeout(() => {
                const time = this.value;

                const validFormats = [
                    'hh:mma',
                    'hh:mmA',
                    'h:mma',
                    'h:mmA',
                    'LT',
                    'hh:mm',
                    'h:mm',
                    'HH:mm',
                    'H:mm',
                    'kk',
                    'k',
                    'HH',
                    'hh',
                    'H',
                    'h',
                ];

                const detectedFormat = validFormats.find((format) => moment(time, format).isValid());

                const formattedValue = detectedFormat
                    ? moment(time, detectedFormat).format(TIME_FORMAT)
                    : moment(this.defaultTime, TIME_FORMAT).format(TIME_FORMAT);

                this.$emit('input', formattedValue);
            }, AUTOFORMAT_TIMEOUT);
        },
    },
};
</script>
