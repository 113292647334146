export default {
    computed: {
        participantListItem_nextStep() {
            return this.automation?.steps && this.automation.steps.length > 0 && this.participant.nextStepIndex >= 0 && this.automation.steps[this.participant.nextStepIndex];
        },
    },

    methods: {
        participantListItem_getEmail({ emailAddresses }) {
            return emailAddresses && emailAddresses[0] ? emailAddresses[0].value : '';
        },

    },
};
