var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('contact-query',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var results = ref.results;
var loading = ref.loading;
var query = ref.query;
var search = ref.search;
return [_c('ds-multiselect',{ref:"taskContactSelect",attrs:{"value":_vm.value,"allow-add":"","searchable":"","label-prop":"fullName","value-prop":"id","help-text":"","help-text-prop":"email","placeholder":_vm.$t('contactRecord.pages.task.associatedContact'),"add-text":_vm.$t('addNewContact'),"options":_vm.normalizeOptions(results, search),"loading":loading,"data-qa":"task-associated-contact"},on:{"updateSearch":query,"add":_vm.handleAddNewContact,"input":_vm.$listeners.input},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
var highlight = ref.highlight;
var isSelected = ref.isSelected;
var filter = ref.filter;
return [_c('div',{class:['menu-item', {
                    highlight: highlight,
                    separator: isSelected,
                }]},[(isSelected)?_c('div',{staticClass:"option-row prevent-select",attrs:{"data-qa":option.fullName},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.removeContact($event)}}},[_c('ds-checkbox',{attrs:{"value":isSelected},on:{"input":_vm.removeContact}},[_c('div',{staticClass:"option-text"},[(option.fullName)?_c('ds-text-highlighter',{attrs:{"highlight":filter,"content":option.fullName}}):_vm._e()],1)])],1):_c('div',{staticClass:"option-row prevent-select",attrs:{"data-qa":option.fullName}},[_c('div',{staticClass:"option-text"},[(option.fullName)?_c('ds-text-highlighter',{attrs:{"highlight":filter,"content":option.fullName}}):_vm._e()],1)])])]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }