<script>
import { mapState } from 'vuex';

/**
 * This mixin provides hooks that will ask the user for confirmation if they
 * attempt to navigate away from a page after making changes to a record.
 * This should prevent users from accidentally wiping away changes if they forget
 * to hit "Save".
 *
 * Usage:
 *
 * Including this mixin in a page will automatically add the confirmation dialog.
 * Since this mixin hooks into the `beforeRouteUpdate` and `beforeRouteLeave` hooks,
 * the automatic functionality will only work if this is added to a router page.
 *
 * If you want to use this for other use cases (e.g. modals), you can manually
 * call the `this.dirty_confirmNavigationWhenDirty` method as needed on your
 * components.
 *
 * This mixin does not many any assumptions as to what the record being watched,
 * but instead uses two mutations in the global store to track whether or not the
 * page is considered "dirty" and should ask for confirmation:
 *
 * 1. `CONFIRM_NAVIGATION_MARK_DIRTY` will mark an object as "dirty" and will require
 *    user confirmation before navigating away when the route changes.  This mutation
 *    takes an object name as an argument and it should be specific to what object
 *    is being changed.
 *
 * 2. `CONFIRM_NAVIGATION_MARK_CLEAN` will reset the object as clean and will not
 *    require user confirmation before navigating.This mutation
 *    takes an object name as an argument and it should be specific to what object
 *    is being changed.
 *
 * There are default translations for the confirmation dialog in the global i18n
 * configuration.  Individual components can override them as needed.
 */
export default {
    beforeRouteUpdate(from, to, next) {
        return this.dirty_confirmNavigationWhenDirty()
            .then(next)
            .catch(() => {});
    },

    beforeRouteLeave(from, to, next) {
        return this.dirty_confirmNavigationWhenDirty()
            .then(next)
            .catch(() => {});
    },

    computed: {
        ...mapState({
            dirty_confirmNavigationFlags: ({ global }) => global.confirmNavigationFlags,
        }),
    },

    methods: {
        /**
         * @return {Promise} Promise resolves successfully if navigation is confirmed.  Raises an
         *    error if navigation is cancelled.
         */
        dirty_confirmNavigationWhenDirty() {
            if (this.dirty_confirmNavigationFlags.size > 0) {
                return this.$confirm({
                    optionTitle: this.$t('confirmNavigationWhenDirty.title'),
                    optionMessage: this.$t('confirmNavigationWhenDirty.message'),
                    optionConfirmButtonLabel: this.$t('confirmNavigationWhenDirty.confirm'),
                    optionCancel: this.$t('confirmNavigationWhenDirty.cancel'),
                }).then(() => {
                    this.$emit('confirm-navigation:accept');

                    this.dirty_confirmNavigationFlags.forEach((object) => {
                        this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', object);
                    });
                }).catch((error) => {
                    this.$emit('confirm-navigation:reject');
                    throw error;
                });
            }

            return Promise.resolve();
        },
    },
};
</script>
