<template lang="html">
    <div class="setup">
        <div class="wrapper">
            <div v-if="isConfigured" class="configured-card-container">
                <configure-automation-card
                    v-for="(trigger, i) in triggerList"
                    :key="i"
                    :value="trigger"
                    :data-qa="`config_${trigger.name}`"
                    :active="activeIndex === i"
                    :has-error="hasError(trigger, i)"
                    :error-message="getConfigErrorMessage(i)"
                    :trigger-action="triggerAction"
                    :class="[{ 'next-expanded': activeIndex === i + 1, 'last-expanded': activeIndex === i - 1 }]"
                    icon="cloud-lightning"
                    @active="activeIndex = activeIndex === i ? null : i"
                    @remove="(payload) => removeTrigger(i, payload)"
                    @updated="handleUpdate"
                    @save="handleSave"
                    @edit="handleEdit"
                />
            </div>

            <template v-else>
                <div class="input-container">
                    <ds-multiselect
                        data-qa="select-trigger-type"
                        searchable
                        show-search-icon
                        :placeholder="$t('whatStartsAutomation')"
                        :options="availableTriggerOptions"
                        value-prop="type"
                        label-prop="name"
                        @input="handleSelected"
                    />
                </div>

                <section class="select-card">
                    <div class="suggested-cards">
                        <p class="suggested-options">
                            {{ $t('suggestedOptions') }}
                        </p>

                        <div class="trigger-cards" data-qa="suggested-cards">
                            <automation-type-card
                                v-for="trigger in allTriggers.slice(0, SUGGESTED_MAX)"
                                :key="trigger.type"
                                :data-qa="`trigger-type_${trigger.type}`"
                                :automation-item="trigger"
                                class="trigger-card suggested"
                                @selected="handleSelected(trigger)"
                            />
                        </div>
                    </div>

                    <automation-type-section
                        v-for="section in triggersBySection"
                        :key="section.title"
                        automation-type="trigger"
                        :section="section"
                        @selected="handleSelected"
                    />
                </section>

                <automation-feedback />
            </template>
        </div>

        <transition name="slide-left">
            <div v-if="actionComponent" class="action-panel">
                <component
                    :is="actionComponent"
                    :automation-properties="automationProperties"
                    :save-status="saveStatus"
                    :show-errors="showErrors"
                    @close="closeActionPanel"
                    @updated="handleComponentEdit"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import amplitude from '@/analytics/amplitude';
import {
    SUGGESTED_MAX, TRIGGER_ACTION_TYPES, INTEGRATION_TYPES, VALIDATION_MESSAGES, ERROR_LEVELS,
    CONTEXT_TYPE, SHOPIFY,
} from '@/automations/constants/automations.constants';
import { triggerComponents } from '@/automations/constants/automations.component.constants';
import AutomationTypeCard from '@/automations/components/AutomationTypeCard';
import AutomationTypeSection from '@/automations/components/AutomationTypeSection';
import ConfigureAutomationCard from '@/automations/components/configure/ConfigureAutomationCard';
import AutomationFeedback from '@/automations/components/AutomationFeedback';
import { isValidTrigger, getSortedTriggerList } from '@/automations/utils/automations.utils';

export default {
    components: {
        AutomationTypeCard,
        AutomationTypeSection,
        ConfigureAutomationCard,
        AutomationFeedback,
    },

    props: {
        value: {
            type: Array,
            default: () => ([]),
        },
        isEditing: Boolean,
        saveStatus: String,
        showErrors: Boolean,
    },

    data() {
        return {
            triggerList: this.value,
            actionComponent: null,
            automationProperties: {},
            activeIndex: 0,
            SUGGESTED_MAX,
        };
    },

    watch: {
        value: {
            handler(value) {
                if (value) {
                    this.$set(this, 'triggerList', value);
                }
            },
            deep: true,
        },

        isEditing(val) {
            if (!val && this.actionComponent) {
                this.closeActionPanel();
            }
        },
    },

    computed: {
        ...mapState({
            contextType: ({ automations }) => automations.context?.type,
        }),

        ...mapGetters({
            automationWithFrontendErrors: 'automations/automationWithFrontendErrors',
            triggersBySection: 'automations/triggersBySection',
            hasShopifyIntegrationFeature: 'auth/hasShopifyIntegrationFeature',
        }),

        triggerAction() {
            return TRIGGER_ACTION_TYPES.ADD;
        },

        isConfigured() {
            return this.triggerList.length;
        },

        allTriggers() {
            const allTriggers = [];

            Object.keys(this.triggersBySection).forEach((key) => {
                const section = this.triggersBySection[key];

                allTriggers.push(...section.automationItems);
            });

            return getSortedTriggerList(allTriggers, this.hasContext ? this.contextType : null);
        },

        hasContext() {
            return this.contextType && this.contextType !== CONTEXT_TYPE.UNKNOWN;
        },

        availableTriggerOptions() {
            return Object.keys(this.allTriggers).map((key) => ({
                ...this.allTriggers[key],
                name: this.$t(this.allTriggers[key].name),
            }));
        },
    },

    methods: {
        closeActionPanel () {
            this.actionComponent = null;

            this.$emit('toggle-editing', false);
        },

        removeTrigger(i, payload) {
            if (payload?.resetTimers) {
                this.$emit('reset-timers-and-remove-trigger', i);
            } else {
                this.triggerList.splice(i, 1);
                this.automationProperties = {};
                this.$emit('updated', this.triggerList);
            }
        },

        handleComponentEdit(events) {
            this.handleUpdate(events);
        },

        handleUpdate(trigger) {
            this.triggerList = [trigger];
            this.$emit('updated', this.triggerList);
        },

        handleEdit({ trigger }) {
            this.actionComponent = triggerComponents[trigger.type];

            this.automationProperties = { trigger };

            if (this.actionComponent) {
                this.$emit('toggle-editing', true);
            }
        },

        handleSave(events) {
            this.handleUpdate(events);
            this.$emit('updated', this.triggerList);
        },

        handleSelected(trigger) {
            if (INTEGRATION_TYPES[trigger.type]) {
                this.handleIntegrationSelected(trigger);
            } else {
                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                    'Trigger Added': trigger.type,
                    'Automation Context': this.contextType,
                });

                this.actionComponent = triggerComponents[trigger.type];
                this.triggerList.push(trigger);

                this.$emit('updated', this.triggerList);

                if (this.actionComponent) {
                    this.$emit('toggle-editing', true);
                }
            }
        },

        handleIntegrationSelected({ type, name }) {
            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                'Trigger added': `${type} Integration (not connected)`,
            });

            const isShopifyIntegration = type === INTEGRATION_TYPES[SHOPIFY].type;

            if (isShopifyIntegration && !this.hasShopifyIntegrationFeature) {
                return this.$confirm({
                    optionTitle: this.$t('integrations.shopify.feature.confirmTitle'),
                    optionMessage: this.$t('integrations.shopify.feature.confirmMessage'),
                    optionConfirmButtonLabel: this.$t('integrations.shopify.feature.confirmButton'),
                    optionCancel: this.$t('global.cancel'),
                    size: 'sm',
                })
                    .then(() => {
                        amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_UPGRADE, {
                            'Event Source': 'Automations',
                            Clicked: 'Upgrade',
                            Integration: type,
                        });

                        this.$router.push({ name: 'settings.account' });
                    }).catch(() => {});
            }

            return this.$confirm({
                optionTitle: this.$t('integrations.navigate.title'),
                optionMessage: this.$t('integrations.navigate.message', { name: this.$t(name) }),
                optionConfirmButtonLabel: this.$t('integrations.navigate.confirm'),
                size: 'sm',
            }).then(() => {
                this.$router.push({ name: 'settings.integrations', params: { integration: type } });
            }).catch(() => {});
        },

        hasError(trigger, i) {
            return Boolean(this.showErrors && (!isValidTrigger(trigger) || this.getConfigErrorMessage(i)));
        },

        getConfigErrorMessage(i) {
            const triggerMessages = this.automationWithFrontendErrors?.triggers?.[i]?.messages;
            const hasError = triggerMessages?.some(({ severity }) => severity === ERROR_LEVELS.ERROR);

            return hasError ? this.$t(VALIDATION_MESSAGES[triggerMessages?.[0]?.messageCode]?.configureMessage) : '';
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/setup';

    .suggested-cards {
        background-color: $color-green-050;
    }
</style>

<i18n>
{
    "en-us": {
        "whatStartsAutomation": "What starts your automation?",
        "suggestedOptions": "Suggested options",
        "integrations": {
            "navigate": {
                "title": "Navigate to integrations settings?",
                "message": "Connect {name} to Keap within settings. Once connected, available automation triggers will appear in the list. A draft will be saved of this automation.",
                "confirm": "Manage integrations"
            }
        }
    }
}
</i18n>
