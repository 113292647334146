<template>
    <div>
        <div class="marketing-sites-page">
            <page-header :title="$t('pageTitle')">
                <header-actions data-qa="header-actions">
                    <template v-slot:default>
                        <ds-filled-button
                            data-qa="create-site-button"
                            @click="$sites.showCreateSite"
                        >
                            {{ $t('createMarketingSite') }}
                        </ds-filled-button>
                    </template>

                    <template #mobile>
                        <ds-icon-button data-qa="create-site-button" name="add-circle" @click="$sites.showCreateSite" />
                    </template>
                </header-actions>
            </page-header>

            <div data-qa="sites-list" class="sites-container">
                <div class="sites">
                    <sites-list
                        :loading="loading"
                        :no-more-results="noMoreResults"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PageHeader from '@/shared/components/PageHeader';
import HeaderActions from '@/shared/components/HeaderActions';
import SitesList from '@/marketingSites/components/sites/SitesList';

export default {
    components: {
        SitesList,
        PageHeader,
        HeaderActions,
    },

    inject: ['$sites'],

    props: {
        loading: {
            type: Boolean,
            required: true,
        },
        noMoreResults: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            isModalOpen: false,
        };
    },
};
</script>

<style lang="scss" scoped>
    .marketing-sites-page {
        @include container;
        height: calc(100vh - var(--top-nav-height));
    }
</style>

<i18n>
{
    "en-us": {
        "pageTitle": "Landing Pages",
        "createMarketingSite": "Create a landing page"
    }
}
</i18n>
