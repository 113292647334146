const CommunicationPage = () => import(/* webpackChunkName: "communication" */ '@/communication/pages/CommunicationPage');
const ConversationPage = () => import(/* webpackChunkName: "communication" */ '@/communication/pages/ConversationPage');
const NewConversationPage = () => import(/* webpackChunkName: "communication" */ '@/communication/pages/NewConversationPage');
const CommunicationDashboardPage = () => import(/* webpackChunkName: "communication" */ '@/communication/pages/CommunicationDashboardPage');
const ProvisioningModal = () => import(/* webpackChunkName: "communication" */ '@/communication/components/ProvisioningModal');

const routes = [
    {
        path: '/communication',
        component: CommunicationPage,
        children: [
            {
                path: '/communication/sms/new',
                name: 'communication-sms-new',
                component: NewConversationPage,
                meta: {
                    title: 'nav.communication',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: '/communication/sms/:id',
                name: 'communication-sms',
                component: ConversationPage,
                meta: {
                    title: 'nav.communication',
                    hideHeaderOnMobile: true,

                },
            },
            {
                path: '',
                name: 'communication-dashboard',
                component: CommunicationDashboardPage,
                meta: {
                    title: 'nav.communication',
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
            },
            {
                path: 'business-line',
                name: 'communication-provision',
                component: ProvisioningModal,
                meta: {
                    title: 'nav.communication',
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
                children: [
                    {
                        path: 'select',
                        name: 'communication-provision-select',
                        meta: {
                            title: 'nav.communication',
                            hideHeaderOnMobile: true,
                            resetIntercom: true,
                        },
                    },
                    {
                        path: 'setup',
                        name: 'communication-provision-setup',
                        meta: {
                            title: 'nav.communication',
                            hideHeaderOnMobile: true,
                            resetIntercom: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default routes;
