export default {
    APPEND_CAMPAIGNS(state, campaigns) {
        state.campaigns.push(...campaigns);
    },

    DELETE_CAMPAIGN(state, deleteId) {
        state.campaigns = state.campaigns.filter(({ id }) => {
            return id !== deleteId;
        });
    },

    SET_CAMPAIGNS(state, campaigns) {
        state.campaigns = campaigns;
    },

    RESET_CAMPAIGNS(state) {
        state.campaigns = [];
    },
};
