<template lang="html">
    <aside :class="['notifications-panel', { open: notificationsPanelOpen }]">
        <header>
            <ds-icon-button name="x" data-qa="close-button" @click="toggleNotifications" />

            <h4>{{ $t('title') }}</h4>

            <ds-dropdown position="bottom-end" data-qa="bulk-actions">
                <template #default>
                    <ds-icon-button name="more-vertical" />
                </template>

                <template #menu>
                    <ul class="dropdown-menu-list">
                        <li class="dropdown-menu-item" @click="markAllAsRead">
                            {{ $t('global.markAsRead') }}
                        </li>
                    </ul>
                </template>
            </ds-dropdown>
        </header>

        <div v-if="showEmptyState" class="empty-state-wrapper">
            <notifications-empty-state />
        </div>

        <section v-else class="notifications-container">
            <div
                v-for="notification in notifications"
                :key="`notification-${notification.id}`"
                class="notification-wrapper"
            >
                <notification :notification="notification" />
            </div>

            <ds-infinite-scroll
                ref="notificationScroll"
                key="notificationScroll"
                :no-more-data="noMoreData"
                :loading="loading"
                :limit.sync="limit"
                @load="handleInfiniteScroll"
            >
                <div class="placeholder-container">
                    <notifications-panel-placeholder class="placeholder" />
                </div>
            </ds-infinite-scroll>

            <div v-if="noMoreData" class="all-loaded-container">
                <p>{{ $t('notification.allLoaded') }}</p>
            </div>
        </section>
    </aside>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash.debounce';

import Notification from '@/shared/components/Notifications/Notification';
import NotificationsEmptyState from '@/shared/components/Notifications/NotificationsEmptyState';
import NotificationsPanelPlaceholder from '@/shared/components/Notifications/NotificationsPanelPlaceholder';
import notificationMixin from '@/shared/mixins/notification.mixin';
import { NOTIFICATION_STATUS } from '@/shared/constants/notifications.constants';
import { LOADING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

const BROWSER_NOTIFICATION_SUB_TYPES = ['quoteViewed', 'quoteAccepted'];

export default {
    components: {
        Notification,
        NotificationsEmptyState,
        NotificationsPanelPlaceholder,
    },

    mixins: [notificationMixin],

    props: {
        debounceDelay: {
            type: Number,
            default: LOADING_DEBOUNCE_DELAY,
        },

        refetchThreshold: {
            type: Number,
            default: 7, // Half the page size
        },
    },

    data() {
        return {
            loading: false,
            loaded: false,
            notificationInterval: null,
            cachedNotificationId: null,
            limit: 1,
        };
    },

    mounted() {
        this.onMounted();
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_N', this.toggleNotifications);
        this.$store.dispatch('notifications/STOP_POLL_NOTIFICATIONS');
    },

    watch: {
        notifications() {
            if (this.hasNewNotifications && !this.notificationsPanelOpen) {
                this.displayBrowserNotification();
                this.updateCachedNotificationId();
            }
        },
    },

    computed: {
        ...mapState({
            notificationsPanelOpen: ({ notifications }) => notifications.notificationsPanelOpen,
            notificationsNextPageToken: ({ notifications }) => notifications.notificationsNextPageToken,
        }),

        ...mapGetters({
            notifications: 'notifications/filteredNotifications',
        }),

        hasNewNotifications() {
            return this.notifications.length > 0
                && this.notifications[0].id !== this.cachedNotificationId;
        },

        noMoreData() {
            return this.notificationsNextPageToken === 'allLoaded';
        },

        showEmptyState() {
            return this.loaded && this.noMoreData && this.notifications.length === 0;
        },
    },

    methods: {
        onMounted() {
            if (this.debounceDelay > 0) {
                this.handleInfiniteScroll = debounce(this.handleInfiniteScroll, this.debounceDelay);
            }

            this.loadNotifications();
            this.$store.dispatch('notifications/START_POLL_NOTIFICATIONS');
            this.$bus.$on('SHORTKEY_N', this.toggleNotifications);
        },

        resetNotifications() {
            this.$store.commit('notifications/RESET_NOTIFICATIONS_NEXT_PAGE_TOKEN');
            this.$store.commit('notifications/RESET_NOTIFICATIONS');
        },

        toggleNotifications() {
            this.$store.commit('notifications/SET_NOTIFICATIONS_PANEL_OPEN', !this.notificationsPanelOpen);

            this.$store.dispatch('notifications/SET_BULK_NOTIFICATION_STATUS', {
                notifications: this.notifications.filter(({ status }) => status === NOTIFICATION_STATUS.NEW),
                oldStatus: NOTIFICATION_STATUS.NEW,
                newStatus: NOTIFICATION_STATUS.UNREAD,
            });
        },

        handleInfiniteScroll() {
            if (this.notificationsPanelOpen) {
                this.loadNotifications();
            }
        },

        async loadNotifications() {
            this.loading = true;

            try {
                await this.$store.dispatch('notifications/LOAD_NOTIFICATIONS', {
                    pageToken: this.notificationsNextPageToken,
                });

                this.loaded = true;

                // NOTE: Because we're filtering unsupported notification subtypes on the client
                // it's possible the initial load of notifications will result in an empty page.
                // If the initial page of filtered notifications will not scroll and there is
                // more to load then proactively fetch more data.
                if (this.notifications.length <= this.refetchThreshold && !this.noMoreData) {
                    await this.loadNotifications();
                }

                this.updateCachedNotificationId();
            } catch (e) {
                // do nothing
            }

            this.loading = false;
        },

        updateCachedNotificationId() {
            if (this.notifications.length > 0) {
                if (this.notifications[0].id !== this.cachedNotificationId) {
                    this.cachedNotificationId = this.notifications[0].id;
                }
            } else {
                this.cachedNotificationId = null;
            }
        },

        isBrowserNotification(notification) {
            return BROWSER_NOTIFICATION_SUB_TYPES.indexOf(notification.subType) !== -1;
        },

        displayBrowserNotification() {
            const newNotifications = this.notifications.filter(({ status }) => status === NOTIFICATION_STATUS.NEW);
            const notificationToDisplay = newNotifications.find((checkNotification) => {
                return this.isBrowserNotification(checkNotification);
            });

            let dictionary = {};
            let subType = 'generic';
            let actionUrl = '';

            if (notificationToDisplay) {
                dictionary = this.notificationUtils_getContentDetailsAsDictionary(notificationToDisplay);
                ({ subType, actionUrl } = notificationToDisplay);
            }

            return this.$notify({
                title: this.$t(`browserNotification.${subType}.title`, dictionary),
                body: this.$t(`browserNotification.${subType}.description`, dictionary),
                url: actionUrl,
            });
        },

        markAllAsRead() {
            return this.$store.dispatch('notifications/SET_ALL_AS_READ')
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .notifications-panel {
        @include side-panel;

        display: flex;
        flex-direction: column;
    }

    header {
        display: flex;
        align-items: center;
        padding: 0 $gp;
        height: $nav-height;
        min-height: $nav-height;
        border-bottom: px-to-rem(1px) solid $color-ink-200;
        margin-bottom: $gp;

        h4 {
            flex: 1;
            margin: 0 $gp / 2;
            font-family: $font-family;
            font-weight: bold;
        }
    }

    .notification-wrapper {
        padding: 0 $gp;
        margin-bottom: $gp / 2;
    }

    .all-loaded-container {
        padding: $gp;
        text-align: center;
    }

    .placeholder-container {
        height: 70vh;
    }

    .empty-state-wrapper {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Notification center",
        "notification": {
            "allLoaded": "All notifications loaded"
        },
        "browserNotification": {
            "generic": {
                "title": "Keap",
                "description": "You have new notifications!"
            },
            "quoteViewed": {
                "title": "Quote #{quoteId} is on the move!",
                "description": "{contactFullName} just viewed your quote"
            },
            "quoteAccepted": {
                "title": "Quote #{quoteId} was just accepted!",
                "description": "{contactFullName} accepted your quote for {quoteTotal}"
            },
            "subscriptionFailed": {
                "title": "Recurring payment failed for {contactFullName}",
                "description": ""
            }
        }
    }
}
</i18n>
