import Vue from 'vue';
import moment from 'moment';
import groupBy from 'lodash.groupby';
import { PHONE_TYPES, CONTACT_TYPES } from '@/contacts/contacts.constants';
import { displayFullName } from '@/contacts/contact-info-utils';
import { KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT } from '@/shared/constants/featureFlag.constants';

const BLOCKED_TYPES = ['MONTH', 'YEAR'];

const filterLabelMap = {
    NONE: 'forms.modifiers.please',
    CONTACT: 'forms.filters.contact',
    CUSTOM: 'forms.filters.custom',
    EVENT: 'forms.filters.event',
    OTHER: 'forms.filters.other',
};

const filterModifierLabelMap = () => {
    return {
        FIRST_NAME: 'forms.firstName',
        LAST_NAME: 'forms.lastName',
        MIDDLE_NAME: 'forms.middleName',
        FAMILY_NAME: 'forms.familyName',
        TITLE: 'forms.title',
        EMAIL: 'forms.email',
        LEAD_SCORE: 'forms.leadscore',
        WEBSITE: 'forms.website',
        LINKEDIN: 'forms.linkedin',
        TWITTER: 'forms.twitter',
        FACEBOOK: 'forms.facebook',
        TIMEZONE: 'forms.timeZone',
        LOCALE: 'forms.locale',
        COMPANY: 'forms.company',
        CONTACT_TYPE: 'forms.contactType',
        JOB_TITLE: 'forms.jobTitle',
        OWNER: 'forms.owner',
        BIRTH_MONTH: 'forms.birthdayMonth',
        BIRTH_YEAR: 'forms.birthdayYear',
        ANNIVERSARY_MONTH: 'forms.anniversaryMonth',
        ANNIVERSARY_YEAR: 'forms.anniversaryYear',
        DATE_CREATED: Vue.prototype.$i18nInstance.t(
            'forms.dateCreated',
            {
                productName: Vue.prototype.$i18nInstance.t(
                    'global.productName',
                ),
            },
        ),
        LAST_UPDATED: 'forms.lastUpdated',
        BIRTHDAY: 'forms.birthday',
        ANNIVERSARY: 'forms.anniversary',
        BILLING_ADDRESS_STREET: 'forms.billingAddressStreet',
        BILLING_ADDRESS_LOCALITY: 'forms.billingAddressLocality',
        BILLING_ADDRESS_REGION: 'forms.billingAddressRegion',
        BILLING_ADDRESS_COUNTRY: 'forms.billingAddressCountry',
        BILLING_ADDRESS_POSTAL_CODE: 'forms.billingAddressPostalCode',
        SHIPPING_ADDRESS_STREET: 'forms.shippingAddressStreet',
        SHIPPING_ADDRESS_LOCALITY: 'forms.shippingAddressLocality',
        SHIPPING_ADDRESS_REGION: 'forms.shippingAddressRegion',
        SHIPPING_ADDRESS_COUNTRY: 'forms.shippingAddressCountry',
        SHIPPING_ADDRESS_POSTAL_CODE: 'forms.shippingAddressPostalCode',
        OTHER_ADDRESS_STREET: 'forms.otherAddressStreet',
        OTHER_ADDRESS_LOCALITY: 'forms.otherAddressLocality',
        OTHER_ADDRESS_REGION: 'forms.otherAddressRegion',
        OTHER_ADDRESS_COUNTRY: 'forms.otherAddressCountry',
        OTHER_ADDRESS_POSTAL_CODE: 'forms.otherAddressPostalCode',
        PHONE: 'forms.phone',
        FAX: 'forms.fax',
        TAG: 'forms.tags',
        LAST_EMAILED: 'forms.lastEmailed',
    };
};

const modifierLabelMap = {
    IS: 'forms.modifiers.equals',
    NOT_IS: 'forms.modifiers.notequals',
    EQUALS: 'forms.modifiers.equals',
    NOT_EQUALS: 'forms.modifiers.notequals',
    CONTAINS: 'forms.modifiers.contains',
    NOT_CONTAINS: 'forms.modifiers.notcontains',
    STARTS_WITH: 'forms.modifiers.startswith',
    ENDS_WITH: 'forms.modifiers.endswith',
    INCLUDES_ANY: 'forms.modifiers.includesany',
    INCLUDES_ALL: 'forms.modifiers.includesall',
    EXCLUDES_ANY: 'forms.modifiers.excludesany',
    EXCLUDES_ALL: 'forms.modifiers.excludesall',
    FROM_DATE: 'forms.modifiers.fromdate',
    TO_DATE: 'forms.modifiers.todate',
    RANGE: 'forms.modifiers.range',
    BOOLEAN: 'forms.modifiers.equals',
    EMPTY: 'forms.modifiers.empty',
    NOT_EMPTY: 'forms.modifiers.filled',
    NULL: 'forms.modifiers.empty',
    NOT_NULL: 'forms.modifiers.filled',
    AFTER: 'forms.modifiers.after',
    BEFORE: 'forms.modifiers.before',
    ON: 'forms.modifiers.on',
    LESS_THAN_DAYS_AGO: 'forms.modifiers.lessthandaysago',
    MORE_THAN_DAYS_AGO: 'forms.modifiers.morethandaysago',
    EXACTLY_DAYS_AGO: 'forms.modifiers.exactlydaysago',
    NEVER: 'forms.modifiers.never',
    ANYTIME: 'forms.modifiers.anytime',
};

export default {
    totalContacts({ contactTotal }) {
        return contactTotal;
    },

    getEmailsByDate({ emails }, { hasEmails }) {
        if (!hasEmails) {
            return {};
        }

        const sortedEmails = emails.sort((a, b) => {
            return new Date(b.sentDate) - new Date(a.sentDate);
        });

        return groupBy(sortedEmails, ({ sentDate }) => {
            return new Date(sentDate).toDateString();
        });
    },

    hasEmails({ emails }) {
        return Array.isArray(emails) && emails.length > 0;
    },

    displayName({ contact: { firstName, lastName } }) {
        if (firstName) {
            return firstName;
        }

        if (lastName) {
            return lastName;
        }

        return '';
    },

    displayFullName({ contact }) {
        return displayFullName(contact);
    },

    fieldOptions(
        {
            countries,
            companies,
            contact,
            contactDetails,
            customRegionOptions,
        },
        _,
        {
            auth: { users },
            global: { countryOptions },
        },
    ) {
        const filteredCompanies = companies || [];

        if (contactDetails.contact?.company && contact.company) {
            filteredCompanies.push({
                id: contactDetails.contact.company,
                companyName: contact.company,
            });
        }

        return {
            leadSource: [
                { value: 'option1', label: 'Lead Source' },
                { value: 'option2', label: 'Lead Source1' },
            ],
            weekDays: moment.weekdays().map((day, index) => {
                return {
                    value: index + 1,
                    label: day,
                };
            }),
            months: moment.months().map((month, index) => {
                return {
                    value: index + 1,
                    label: month,
                };
            }),
            phone: [
                { value: PHONE_TYPES.WORK, label: Vue.prototype.$i18nInstance.t('global.phones.work') },
                { value: PHONE_TYPES.HOME, label: Vue.prototype.$i18nInstance.t('global.phones.home') },
                { value: PHONE_TYPES.MOBILE, label: Vue.prototype.$i18nInstance.t('global.phones.mobile') },
                { value: PHONE_TYPES.OTHER, label: Vue.prototype.$i18nInstance.t('global.phones.other') },
            ],
            fax: [
                { value: PHONE_TYPES.WORK, label: Vue.prototype.$i18nInstance.t('global.phones.work') },
                { value: PHONE_TYPES.HOME, label: Vue.prototype.$i18nInstance.t('global.phones.home') },
                { value: PHONE_TYPES.OTHER, label: Vue.prototype.$i18nInstance.t('global.phones.other') },
            ],
            yesNo: [
                { label: 'Not selected', value: null },
                { label: 'No', value: 0 },
                { label: 'Yes', value: 1 },
            ],
            yesNoSelected: [
                { label: 'No', value: 0 },
                { label: 'Yes', value: 1 },
            ],
            boolean: [
                { label: 'Not selected', value: null },
                { label: 'No', value: false },
                { label: 'Yes', value: true },
            ],
            booleanSelected: [
                { label: 'No', value: false },
                { label: 'Yes', value: true },
            ],
            countries: countries.length > 0 ? countries : countryOptions,
            billingRegion: contactDetails.billingRegionOptions,
            shippingRegion: contactDetails.shippingRegionOptions,
            otherRegion: contactDetails.otherRegionOptions,
            customRegion: customRegionOptions,
            companies: filteredCompanies,
            owners: users.map((user) => {
                return {
                    value: user.id,
                    label: `${user.givenName} ${user.familyName}`,
                };
            }),
            title: [
                { value: 'Ms.', label: Vue.prototype.$i18nInstance.t('global.titles.ms') },
                { value: 'Mr.', label: Vue.prototype.$i18nInstance.t('global.titles.mr') },
                { value: 'Mrs.', label: Vue.prototype.$i18nInstance.t('global.titles.mrs') },
                { value: 'Dr.', label: Vue.prototype.$i18nInstance.t('global.titles.dr') },
            ],
            contactType: [
                { value: CONTACT_TYPES.LEAD, label: Vue.prototype.$i18nInstance.t('global.contactType.lead') },
                { value: CONTACT_TYPES.CUSTOMER, label: Vue.prototype.$i18nInstance.t('global.contactType.customer') },
                { value: CONTACT_TYPES.OTHER, label: Vue.prototype.$i18nInstance.t('global.contactType.other') },
            ],
        };
    },

    // Can possibly be removed with removal of KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT by doing a computed property in the caller where needed.
    customFieldCount({ fieldGroups, customFields }, _getters, { featureFlags }) {
        const isCustomFieldTechDebtEnabled = featureFlags[KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT];

        if (isCustomFieldTechDebtEnabled) {
            return customFields.length || 0;
        }

        return fieldGroups.map(({ fields }) => {
            return fields
                ? fields.filter((group) => group.customField).length
                : 0;
        }).reduce((a, b) => a + b, 0);
    },

    // Can be removed in preference of directly getting customFields from state when KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT is removed
    customFields({ fieldGroups, customFields }, _getters, { featureFlags }) {
        const isCustomFieldTechDebtEnabled = featureFlags[KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT];

        if (isCustomFieldTechDebtEnabled) {
            return customFields || [];
        }

        const { fields: fieldsToReturn } = fieldGroups.find(({ name, fields }) => fields && name === 'Custom fields') || {};

        return fieldsToReturn || [];
    },

    fileById: ({ files }) => (id) => {
        return files.find((file) => {
            return file.id === id;
        });
    },

    modifierDoesntRequireValue: () => (modifier) => {
        return [
            'EMPTY',
            'NOT_EMPTY',
            'NULL',
            'NOT_NULL',
            'ANYTIME',
            'NEVER',
        ].includes(modifier);
    },

    filterOptions({ contactFilterMetadata }, _, rootState, rootGetters) {
        return !contactFilterMetadata ? [] : contactFilterMetadata.map(({ label, filterItems }) => {
            return {
                label: filterLabelMap[label],
                options: filterItems
                    .filter(({ key, type }) => {
                        const notBlocked = !BLOCKED_TYPES.includes(type);
                        const allowLeadScore = key !== 'LEAD_SCORE' || rootGetters['auth/hasLeadScoreFeature'];

                        return notBlocked && allowLeadScore;
                    })
                    .map(({
                        key,
                        type,
                        modifiers,
                        options,
                        name,
                    }) => {
                        let calculatedModifierValues;

                        if (options) {
                            if (options.length > 1 && options[0].label !== null) {
                                calculatedModifierValues = options.map(({ label: modifierLabel, options: modifierOptions }) => {
                                    return {
                                        label: Vue.prototype.$i18nInstance.t(modifierLabel !== '' ? modifierLabel : 'forms.uncategorized'),
                                        options: modifierOptions.map(({ label: optionLabel, id }) => {
                                            return {
                                                label: Vue.prototype.$i18nInstance.t(optionLabel),
                                                value: id,
                                            };
                                        }),
                                    };
                                });
                            } else {
                                calculatedModifierValues = options.length ? options[0].options
                                    .map(({ label: optionLabel, id }) => {
                                        return {
                                            label: Vue.prototype.$i18nInstance.t(optionLabel),
                                            value: id,
                                        };
                                    }) : [];
                            }
                        }

                        let filteredModifiers = modifiers;

                        if (key === 'LEAD_SCORE') {
                            filteredModifiers = filteredModifiers.filter(({ id }) => {
                                return id === 'INCLUDES_ANY' || id === 'EXCLUDES_ANY';
                            });
                        }

                        return {
                            key,
                            label: Vue.prototype.$i18nInstance.t(filterModifierLabelMap()[key] || name || key),
                            type,
                            options,
                            modifiers: filteredModifiers.map((modifier) => {
                                return {
                                    value: modifier,
                                    label: Vue.prototype.$i18nInstance.t(modifierLabelMap[modifier.id] || modifier),
                                };
                            }),
                            value: null,
                            modifier: null,
                            modifierValues: calculatedModifierValues,
                        };
                    }),
            };
        });
    },

    contactImportResultCount({ contactImport }) {
        return contactImport && contactImport.results ? contactImport.results.length : 0;
    },

    contactSearchResults({ contacts, searchResults }) {
        const hasResults = Array.isArray(searchResults) && searchResults.length > 0;

        return hasResults ? searchResults : contacts;
    },

    allContactLists({ contactLists, newLeadsContactListId, customersListId }) {
        return contactLists.filter(({ id, forSmartList }) => {
            return !forSmartList
                && id !== parseInt(newLeadsContactListId, 10)
                && id !== parseInt(customersListId, 10);
        });
    },

    smartLists({ contactLists }) {
        return contactLists.filter(({ forSmartList, count }) => forSmartList && count > 0);
    },

    isLeadsContactList({ leadsContactListId }, _, { route }) {
        const filter = route.params.id;

        return filter === leadsContactListId.toString();
    },

    isClientsContactList({ clientsContactListId }, _, { route }) {
        const filter = route.params.id;

        return filter === clientsContactListId.toString();
    },

    isContactPage(_, __, { route }) {
        if (!route.path) {
            return false;
        }

        return route.path.indexOf('/contacts/list') > -1;
    },

    dealCount({ contact }) {
        let total = 0;

        if (contact.pipelines) {
            contact.pipelines.forEach((pipeline) => {
                total += pipeline.deals.length;
            });
        }

        return total;
    },
};
