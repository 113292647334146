<script>
import { mapState } from 'vuex';
import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';
import { APPOINTMENT } from '@/contacts/contactEmailTypes';
import CommunicationModal from '@/communication/components/CommunicationModal';

const buildLink = (appointmentType) => `${process.env.VUE_APP_BOOKING_BASE_URL}${appointmentType.bookingLink}`;

export default {
    name: 'BookingActions',

    mixins: [closeConfirmation],

    computed: {
        ...mapState({
            appointmentTypes: ({ calendar }) => calendar.apptTypes,
        }),
    },

    methods: {
        copyLink(appointmentType) {
            const successHeadline = this.$t('booking.copy.successHeadline');
            const successMessage = this.$t('booking.copy.successMessage');
            const errorMessage = this.$t('booking.copy.error');

            const link = buildLink(appointmentType);

            return this.$copyText(link).then(
                () => {
                    this.$toast({
                        headline: successHeadline,
                        message: successMessage,
                    });
                },
                () => {
                    this.$error({
                        message: errorMessage,
                    });
                },
            );
        },

        sendInvite(appointmentType) {
            const link = buildLink(appointmentType);

            const modalOptions = {
                recipientList: [],
                link,
                displayLink: link,
            };

            this.openSendModal(modalOptions);
        },

        sendInviteToContact(appointmentType, contact) {
            const link = buildLink(appointmentType);
            const linkWithContactQueryParams = encodeURI(`${link}?firstName=${contact.firstName}&lastName=${contact.lastName}&email=${contact.email}&phone=${contact.phone}`);

            const modalOptions = {
                recipientList: [contact],
                link: linkWithContactQueryParams,
                displayLink: link,
            };

            this.openSendModal(modalOptions);
        },

        openSendModal({ recipientList, link, displayLink }) {
            this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: CommunicationModal,
                props: {
                    initialBody: this.$t('booking.invite.message', { link, displayLink }),
                    initialSmsMessage: this.$t('booking.invite.text', { link }),
                    initialSubject: this.$t('booking.invite.subject'),
                    recipientList,
                    title: this.$t('global.sendInvite'),
                    source: APPOINTMENT,
                },
                interceptBack: () => this.confirmCloseDraft(() => this.$bus.$emit('POP_NESTED_MODAL')),
                interceptClose: () => this.confirmCloseDraft(() => this.$bus.$emit('POP_NESTED_MODAL')),
            });
        },
    },

    render() {
        const {
            appointmentTypes,
            copyLink,
            sendInvite,
            sendInviteToContact,
        } = this;

        return this.$scopedSlots.default({
            appointmentTypes,
            copyLink,
            sendInvite,
            sendInviteToContact,
        });
    },
};
</script>
