<template>
    <configure-step-with-timer
        :value="value"
        :title="templateTitle"
        :subtitle="subject"
        :has-error="hasError"
        :has-timer-warning="hasTimerWarning"
        :error-message="emailErrorMessage"
        @edit="(payload) => $emit('edit', payload)"
        @save="(payload) => $emit('save', payload)"
        @remove="$emit('remove')"
        @done="$emit('done')"
    />
</template>

<script>
import ConfigureStepWithTimer from './ConfigureStepWithTimer';
import { validate } from '@/automations/utils/automations.utils';
import { SEND_EMAIL } from '@/automations/constants/automations.constants';

export default {
    components: {
        ConfigureStepWithTimer,
    },

    props: {
        value: Object,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        errorMessage: String,
    },

    data() {
        return {
            step: this.value?.step ?? this.value,
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.step = newVal.step ?? newVal;
            },
            deep: true,
        },
    },

    computed: {
        templateTitle() {
            return this.step.configJson?.email?.title || this.$t(this.step.name);
        },

        subject() {
            return this.step.configJson?.subject || null;
        },

        emailErrorMessage() {
            return !validate[SEND_EMAIL].bodySize(this.step) ? this.$t('sizeError') : this.errorMessage;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "sizeError": "Email content is too large"
    }
}
</i18n>
