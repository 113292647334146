<template>
    <configure-step-with-timer
        :value="value"
        :title="title"
        :subtitle="body"
        :has-error="hasError"
        :has-timer-warning="hasTimerWarning"
        :error-message="errorMessage"
        @edit="(payload) => $emit('edit', payload)"
        @save="(payload) => $emit('save', payload)"
        @remove="$emit('remove')"
        @done="$emit('done')"
    />
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ConfigureStepWithTimer from './ConfigureStepWithTimer';

export default {
    components: {
        ConfigureStepWithTimer,
    },

    props: {
        value: Object,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        errorMessage: String,
    },

    data() {
        return {
            step: this.value?.step ?? this.value,
        };
    },

    created() {
        this.init();
    },

    watch: {
        value: {
            handler(newVal) {
                this.step = newVal.step ?? newVal;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            pipelineList: ({ pipeline }) => pipeline.pipelineList,
        }),

        ...mapGetters({
            stageById: 'pipeline/stageById',
            getPipelineById: 'pipeline/getPipelineById',
        }),

        title() {
            if (!this.hasStage) {
                return this.$t(this.step.name);
            }

            return this.stageById(this.step.configJson?.stageId)?.name || this.$t('invalidStage');
        },

        hasStage() {
            return this.step.configJson?.stageId;
        },

        body() {
            if (!this.step.configJson?.pipelineId) {
                return null;
            }

            const name = this.getPipelineById(this.step.configJson?.pipelineId)?.name;

            return this.$t('subtitle', { name: name || this.$t('invalidPipeline') });
        },
    },

    methods: {
        async init() {
            if (!this.pipelineList.length && this.hasStage) {
                await this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST');
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "subtitle": "Pipeline: {name}",
        "invalidStage": "Invalid stage",
        "invalidPipeline": "Invalid pipeline"
    }
}
</i18n>
