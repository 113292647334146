import Vue from 'vue';
import isEqual from 'lodash.isequal';
import clonedeep from 'lodash.clonedeep';

import {
    NOT_SHOWN,
    TRIGGERS,
    INTEGRATION_TYPES,
    TIMER_STEPS,
    APPOINTMENT_TRIGGERS,
    DELAY_TIMER,
    RELATIVE_TIMER,
    ERROR_LEVELS,
    SEND_EMAIL, SEND_NOTIFICATION, SEND_SMS, APPLY_TAG, REMOVE_TAG, CREATE_TASK, CREATE_DEAL,
    ANY_APPOINTMENT_SCHEDULED, APPOINTMENT_SCHEDULED, ANY_APPOINTMENT_CANCELLED, APPOINTMENT_CANCELLED,
    ANY_PRODUCT_PURCHASED, DEAL_ENTERED, DEAL_EXITED,
    ANY_PUBLIC_FORM_SUBMITTED, PUBLIC_FORM_SUBMITTED,
    TAG_APPLIED, PRODUCT_PURCHASED, INTERNAL_FORM_SUBMITTED, ANY_INTERNAL_FORM_SUBMITTED,
    ANY_INVOICE_SENT, ANY_INVOICE_PAID, USER_APPOINTMENT_SCHEDULED, USER_APPOINTMENT_CANCELLED,
    ANY_QUOTE_SENT, ANY_QUOTE_ACCEPTED, HELLOSIGN_DOCUMENT_SIGNED, HELLOSIGN_SEND_DOCUMENT,
    ANY_HELLOSIGN_DOCUMENT_SIGNED, LANDING_PAGE_FORM_SUBMITTED,
    SHOPIFY_ANY_PRODUCT_PURCHASED, SHOPIFY_PRODUCT_PURCHASED, SHOPIFY, SHOPIFY_ANY_CART_ABANDONED, SHOPIFY_CART_ABANDONED,
    HELLOSIGN, validationMessageCodes,
    TYPEFORM, TYPEFORM_FORM_SUBMITTED, TYPEFORM_ANY_FORM_SUBMITTED,
} from '@/automations/constants/automations.constants';
import { AUTOMATION_TIMER_TYPES, EVENT_TYPES } from '@/automations/constants/automations.timer.constants';
import { AUTOMATION_FILTERS } from '@/automations/constants/automations.filter.constants';
import {
    FF_KEAP_SIMPLE_AUTOMATION_STEP_SEND_SMS,
    FF_KEAP_SINGLE_PIPELINE,
    FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ORDER_PAID,
    FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ABANDONED_CART,
    FF_KEAP_LANDING_PAGES,
    FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_LANDING_PAGE_FORM_SUBMITTED,
    FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_TYPEFORM,
} from '@/shared/constants/featureFlag.constants';
import { BLENDR_TYPES } from '@/shared/constants/integrations.constants';
import { FORM_TYPE_CONTACTS } from '@/customForms/customForm.constants';

export default {
    hasAppointmentTrigger({ automation }) {
        return automation.triggers?.some(({ type }) => {
            return APPOINTMENT_TRIGGERS.includes(type);
        });
    },

    hasInvoiceTrigger({ automation }) {
        return automation.triggers?.some(({ type }) => {
            return type === ANY_INVOICE_SENT || type === ANY_INVOICE_PAID;
        });
    },

    hasDealTrigger({ automation }) {
        return automation.triggers?.some(({ type }) => {
            return type === DEAL_ENTERED || type === DEAL_EXITED;
        });
    },

    hasInternalFormTrigger({ automation }) {
        return automation.triggers?.some(({ type }) => {
            return type === INTERNAL_FORM_SUBMITTED || type === ANY_INTERNAL_FORM_SUBMITTED;
        });
    },

    hasPublicFormTrigger({ automation }) {
        return automation.triggers?.some(({ type }) => {
            return type === ANY_PUBLIC_FORM_SUBMITTED || type === PUBLIC_FORM_SUBMITTED;
        });
    },

    additionalMergeFields({ automation }, getters) {
        const instance = Vue.prototype.$i18nInstance;
        const appointmentMergePath = 'automation.mergeFields.appointments';
        const invoiceMergePath = 'automation.mergeFields.invoices';
        const quoteMergePath = 'automation.mergeFields.quotes';
        const dealMergePath = 'automation.mergeFields.deals';

        const mergeFields = [];

        if (getters.hasAppointmentTrigger) {
            mergeFields.push({
                category: {
                    label: instance.t(`${appointmentMergePath}.categoryLabel`),
                    value: 'appointments',
                },
                options: [
                    {
                        label: instance.t(`${appointmentMergePath}.name.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.name.chipLabel`),
                        value: '[[appointment.name]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.location.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.location.chipLabel`),
                        value: '[[appointment.location]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.date.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.date.chipLabel`),
                        value: '[[appointment.date]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.time_range.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.time_range.chipLabel`),
                        value: '[[appointment.time_range]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.start_time.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.start_time.chipLabel`),
                        value: '[[appointment.start_time]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.end_time.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.end_time.chipLabel`),
                        value: '[[appointment.end_time]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.duration.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.duration.chipLabel`),
                        value: '[[appointment.duration]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.pre_appointment_instructions.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.pre_appointment_instructions.chipLabel`),
                        value: '[[appointment.pre_appointment_instructions]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.cancel_link.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.cancel_link.chipLabel`),
                        value: '[[appointment.cancel_link]]',
                    },
                    {
                        label: instance.t(`${appointmentMergePath}.reschedule_link.displayLabel`),
                        chipLabel: instance.t(`${appointmentMergePath}.reschedule_link.chipLabel`),
                        value: '[[appointment.reschedule_link]]',
                    },
                ],
            });
        }

        if (automation.triggers?.some(({ type }) => ANY_QUOTE_ACCEPTED === type || ANY_QUOTE_SENT === type)) {
            mergeFields.push({
                category: {
                    label: instance.t(`${quoteMergePath}.categoryLabel`),
                    value: 'quotes',
                },
                options: [
                    {
                        label: instance.t(`${quoteMergePath}.link.displayLabel`),
                        chipLabel: instance.t(`${quoteMergePath}.link.chipLabel`),
                        value: '[[quote.link]]',
                    },
                    {
                        label: instance.t(`${quoteMergePath}.amount.displayLabel`),
                        chipLabel: instance.t(`${quoteMergePath}.amount.chipLabel`),
                        value: '[[quote.amount]]',
                    },
                    {
                        label: instance.t(`${quoteMergePath}.number.displayLabel`),
                        chipLabel: instance.t(`${quoteMergePath}.number.chipLabel`),
                        value: '[[quote.number]]',
                    },
                ],
            });
        }

        if (automation.triggers?.some(({ type }) => ANY_INVOICE_SENT === type || ANY_INVOICE_PAID === type)) {
            mergeFields.push({
                category: {
                    label: instance.t(`${invoiceMergePath}.categoryLabel`),
                    value: 'invoices',
                },
                options: [
                    {
                        label: instance.t(`${invoiceMergePath}.link.displayLabel`),
                        chipLabel: instance.t(`${invoiceMergePath}.link.chipLabel`),
                        value: '[[invoice.link]]',
                    },
                    {
                        label: instance.t(`${invoiceMergePath}.amount.displayLabel`),
                        chipLabel: instance.t(`${invoiceMergePath}.amount.chipLabel`),
                        value: '[[invoice.amount]]',
                    },
                    {
                        label: instance.t(`${invoiceMergePath}.dueDate.displayLabel`),
                        chipLabel: instance.t(`${invoiceMergePath}.dueDate.chipLabel`),
                        value: '[[invoice.due_date]]',
                    },
                    {
                        label: instance.t(`${invoiceMergePath}.number.displayLabel`),
                        chipLabel: instance.t(`${invoiceMergePath}.number.chipLabel`),
                        value: '[[invoice.number]]',
                    },
                ],
            });
        }

        if (getters.hasDealTrigger) {
            mergeFields.push({
                category: {
                    label: instance.t(`${dealMergePath}.categoryLabel`),
                    value: 'deals',
                },
                options: [
                    {
                        label: instance.t(`${dealMergePath}.name.displayLabel`),
                        chipLabel: instance.t(`${dealMergePath}.name.chipLabel`),
                        value: '[[deal.name]]',
                    },
                    {
                        label: instance.t(`${dealMergePath}.value.displayLabel`),
                        chipLabel: instance.t(`${dealMergePath}.value.chipLabel`),
                        value: '[[deal.amount]]',
                    },
                    {
                        label: instance.t(`${dealMergePath}.primaryContact.displayLabel`),
                        chipLabel: instance.t(`${dealMergePath}.primaryContact.chipLabel`),
                        value: '[[deal.primary_contact.first_name]] [[deal.primary_contact.last_name]]',
                    },
                    {
                        label: instance.t(`${dealMergePath}.estimatedClosedDate.displayLabel`),
                        value: '[[deal.estimated_closed_date]]',
                    },
                    {
                        label: instance.t(`${dealMergePath}.actualClosedDate.displayLabel`),
                        value: '[[deal.actual_closed_date]]',
                    },
                    {
                        label: instance.t(`${dealMergePath}.status.displayLabel`),
                        value: '[[deal.status]]',
                    },
                    {
                        label: instance.t(`${dealMergePath}.pipelineName.displayLabel`),
                        value: '[[deal.pipeline.name]]',
                    },
                ],
            });
        }

        return mergeFields;
    },

    automationTimerType: ({ automation }) => (stepIndexToRemove) => {
        const timerSteps = automation.steps?.filter(({ type }, i) => {
            return TIMER_STEPS.includes(type) && i !== stepIndexToRemove;
        });

        if (timerSteps?.some(({ type, configJson }) => type === RELATIVE_TIMER && configJson?.eventType === EVENT_TYPES.DATE)) {
            return AUTOMATION_TIMER_TYPES.EXACT;
        }

        if (timerSteps?.some(({ type, configJson }) => type === RELATIVE_TIMER && configJson?.eventType === EVENT_TYPES.APPOINTMENT)) {
            return AUTOMATION_TIMER_TYPES.APPOINTMENT;
        }

        if (timerSteps?.some(({ type, configJson }) => type === RELATIVE_TIMER && configJson?.eventType === EVENT_TYPES.INVOICE_DUE)) {
            return AUTOMATION_TIMER_TYPES.INVOICE_DUE;
        }

        if (timerSteps?.some(({ type }) => type === DELAY_TIMER)) {
            return AUTOMATION_TIMER_TYPES.DELAY;
        }

        return AUTOMATION_TIMER_TYPES.ANY;
    },

    hasRelativeAppointmentTimer({ automation }) {
        return automation.steps?.some(({ type, configJson }) => {
            return type === RELATIVE_TIMER && configJson.eventType === EVENT_TYPES.APPOINTMENT;
        });
    },

    hasRelativeInvoiceTimer({ automation }) {
        return automation.steps?.some(({ type, configJson }) => {
            return type === RELATIVE_TIMER && configJson.eventType === EVENT_TYPES.INVOICE_DUE;
        });
    },

    automationWithFrontendErrors({ automation }, _, { auth, featureFlags }, rootGetters) {
        let automationWithErrors = {
            ...clonedeep(automation),
            messages: [],
        };

        const { steps, triggers } = automationWithErrors;

        // has sms step without setting up sms account (can happen with template sharing)
        // has hello sign step but integration is not connected (can happen if got disconnected, or with template sharing)
        const needsSmsSetup = !auth.account.automatedSmsAccount?.termsAndConditionsTimeStamp;
        const hellosignConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.HELLO_SIGN);
        const shopifyConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.SHOPIFY);
        const typeformConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.TYPEFORM);
        const hasShopifyFeature = rootGetters['auth/hasShopifyIntegrationFeature'];

        if (needsSmsSetup || !hellosignConnected) {
            steps?.forEach((step, i) => {
                if (needsSmsSetup && step.type === SEND_SMS) {
                    const message = {
                        messageCode: validationMessageCodes.SMS_STEP_MISSING_SMS_ACCOUNT,
                        severity: ERROR_LEVELS.ERROR,
                    };

                    steps[i].messages = [message];

                    if (!automationWithErrors.messages.some(({ messageCode }) => messageCode === validationMessageCodes.SMS_STEP_MISSING_SMS_ACCOUNT)) {
                        automationWithErrors.messages.push(message);
                    }
                }

                if (!hellosignConnected && step.type === HELLOSIGN_SEND_DOCUMENT) {
                    const message = {
                        messageCode: validationMessageCodes.HELLOSIGN_NOT_CONNECTED,
                        severity: ERROR_LEVELS.ERROR,
                    };

                    steps[i].messages = [message];

                    if (!automationWithErrors.messages.some(({ messageCode }) => messageCode === validationMessageCodes.HELLOSIGN_NOT_CONNECTED)) {
                        automationWithErrors.messages.push(message);
                    }
                }
            });
        }


        if (!hellosignConnected) {
            triggers?.forEach((trigger, i) => {
                if ([HELLOSIGN_DOCUMENT_SIGNED, ANY_HELLOSIGN_DOCUMENT_SIGNED].includes(trigger.type)) {
                    const message = {
                        messageCode: validationMessageCodes.HELLOSIGN_NOT_CONNECTED,
                        severity: ERROR_LEVELS.ERROR,
                    };

                    triggers[i].messages = [message];

                    if (!automationWithErrors.messages.some(({ messageCode }) => messageCode === validationMessageCodes.HELLOSIGN_NOT_CONNECTED)) {
                        automationWithErrors.messages.push(message);
                    }
                }
            });
        }

        const typeformTriggerEnabled = featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_TYPEFORM];

        if (!typeformConnected && typeformTriggerEnabled) {
            triggers?.forEach((trigger, i) => {
                if ([TYPEFORM_FORM_SUBMITTED, TYPEFORM_ANY_FORM_SUBMITTED].includes(trigger.type)) {
                    const message = {
                        messageCode: validationMessageCodes.TYPEFORM_NOT_CONNECTED,
                        severity: ERROR_LEVELS.ERROR,
                    };

                    triggers[i].messages = [message];

                    if (!automationWithErrors.messages.some(({ messageCode }) => messageCode === validationMessageCodes.TYPEFORM_NOT_CONNECTED)) {
                        automationWithErrors.messages.push(message);
                    }
                }
            });
        }

        const shopifyTriggerEnabled = featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ORDER_PAID]
            || featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ABANDONED_CART];

        const shopifyTriggers = [
            SHOPIFY_ANY_PRODUCT_PURCHASED,
            SHOPIFY_PRODUCT_PURCHASED,
            SHOPIFY_ANY_CART_ABANDONED,
            SHOPIFY_CART_ABANDONED,
        ];

        if (shopifyTriggerEnabled && !shopifyConnected) {
            triggers?.forEach((trigger, i) => {
                if (shopifyTriggers.includes(trigger.type)) {
                    const message = {
                        messageCode: hasShopifyFeature
                            ? validationMessageCodes.SHOPIFY_NOT_CONNECTED
                            : validationMessageCodes.SHOPIFY_MISSING_FEATURE,
                        severity: ERROR_LEVELS.ERROR,
                    };

                    triggers[i].messages = [message];

                    if (!automationWithErrors.messages.some(({ messageCode }) => (
                        messageCode === (validationMessageCodes.SHOPIFY_MISSING_FEATURE || validationMessageCodes.SHOPIFY_NOT_CONNECTED)))) {
                        automationWithErrors.messages.push(message);
                    }
                }
            });
        }

        automationWithErrors = getAutomationWithStopTriggerErrors(automationWithErrors, auth.users);

        return automationWithErrors.messages?.length ? automationWithErrors : {};
    },

    triggersBySection(_, __, rootState, rootGetters) {
        const { featureFlags } = rootState;
        const triggersBySection = {};
        const showForms = rootGetters['auth/hasFormsFeature'];
        const showPipeline = rootGetters['auth/hasMultiplePipelinesFeature'] || rootGetters['auth/hasSinglePipelineFeature'] || featureFlags[FF_KEAP_SINGLE_PIPELINE];
        const showLandingPageFormSubmitted = featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_LANDING_PAGE_FORM_SUBMITTED] && featureFlags[FF_KEAP_LANDING_PAGES] && rootGetters['auth/hasLandingPagesFeature'];

        const hellosignConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.HELLO_SIGN);
        const shopifyConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.SHOPIFY);
        const typeformConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.TYPEFORM);
        const typeformTriggerEnabled = featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_TYPEFORM];
        const shopifyPurchaseEnabled = featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ORDER_PAID];
        const shopifyAbandonedCartEnabled = featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ABANDONED_CART];

        const showThirdPartySection = (!hellosignConnected
            || (!shopifyConnected && shopifyPurchaseEnabled)
            || (!shopifyConnected && shopifyAbandonedCartEnabled)
            || (!typeformConnected && typeformTriggerEnabled)) && rootGetters['auth/hasIntegrationsSettingsFeature'];

        const formTriggers = [];

        if (showForms) {
            formTriggers.push(TRIGGERS[INTERNAL_FORM_SUBMITTED]);

            formTriggers.push(TRIGGERS[PUBLIC_FORM_SUBMITTED]);
        }

        if (showLandingPageFormSubmitted) {
            formTriggers.push(TRIGGERS[LANDING_PAGE_FORM_SUBMITTED]);
        }

        if (rootGetters['auth/hasAppointmentsFeature']) {
            triggersBySection.appointments = {
                title: 'automation.triggers.sections.appointments',
                automationItems: [TRIGGERS[APPOINTMENT_SCHEDULED], TRIGGERS[APPOINTMENT_CANCELLED]],
            };
        }

        if (formTriggers.length > 0) {
            triggersBySection.forms = {
                title: 'automation.triggers.sections.forms',
                automationItems: formTriggers,
            };
        }

        if (hellosignConnected) {
            triggersBySection.hellosign = {
                title: 'automation.triggers.sections.hellosign',
                automationItems: [TRIGGERS[HELLOSIGN_DOCUMENT_SIGNED]],
            };
        }

        if (typeformConnected && typeformTriggerEnabled) {
            triggersBySection.typeform = {
                title: 'automation.triggers.sections.typeform',
                automationItems: [TRIGGERS[TYPEFORM_FORM_SUBMITTED]],
            };
        }

        if (shopifyConnected && (shopifyPurchaseEnabled || shopifyAbandonedCartEnabled)) {
            const automationItems = [];

            if (shopifyPurchaseEnabled) {
                automationItems.push(TRIGGERS[SHOPIFY_ANY_PRODUCT_PURCHASED]);
            }

            if (shopifyAbandonedCartEnabled) {
                automationItems.push(TRIGGERS[SHOPIFY_ANY_CART_ABANDONED]);
            }

            triggersBySection.shopify = {
                title: 'automation.triggers.sections.shopify',
                automationItems,
            };
        }

        if (rootGetters['auth/hasInvoicesAndQuotesFeature']) {
            triggersBySection.money = {
                title: 'automation.triggers.sections.money',
                automationItems: [
                    { ...TRIGGERS[PRODUCT_PURCHASED] },
                    { ...TRIGGERS[ANY_INVOICE_SENT] },
                    { ...TRIGGERS[ANY_INVOICE_PAID] },
                    { ...TRIGGERS[ANY_QUOTE_SENT] },
                    { ...TRIGGERS[ANY_QUOTE_ACCEPTED] },
                ],
            };
        }

        if (showPipeline) {
            triggersBySection.pipeline = {
                title: 'automation.triggers.sections.pipeline',
                automationItems: [
                    { ...TRIGGERS[DEAL_ENTERED] },
                    { ...TRIGGERS[DEAL_EXITED] },
                ],
            };
        }

        triggersBySection.tags = {
            title: 'automation.triggers.sections.tags',
            automationItems: [TRIGGERS[TAG_APPLIED]],
        };

        if (showThirdPartySection) {
            const automationItems = [];

            if (!hellosignConnected) {
                automationItems.push({ ...INTEGRATION_TYPES[HELLOSIGN] });
            }

            if (!typeformConnected && typeformTriggerEnabled) {
                automationItems.push({ ...INTEGRATION_TYPES[TYPEFORM] });
            }

            if (!shopifyConnected && (shopifyPurchaseEnabled || shopifyAbandonedCartEnabled)) {
                automationItems.push({ ...INTEGRATION_TYPES[SHOPIFY] });
            }

            if (automationItems.length) {
                triggersBySection.thirdParty = {
                    title: 'automation.integrations.section.title',
                    automationItems,
                };
            }
        }

        return triggersBySection;
    },

    allSteps(state, getters) {
        const allSteps = [];

        Object.keys(getters.stepsBySection).forEach((key) => {
            const section = getters.stepsBySection[key];

            allSteps.push(...section.automationItems);
        });

        return allSteps.sort((a, b) => a.order - b.order);
    },

    stepsBySection(state, getters, { featureFlags }, rootGetters) {
        const stepsBySection = {};
        const showSms = rootGetters['automatedSms/showSmsMarketing'] && featureFlags[FF_KEAP_SIMPLE_AUTOMATION_STEP_SEND_SMS];
        const showPipeline = rootGetters['auth/hasMultiplePipelinesFeature'] || rootGetters['auth/hasSinglePipelineFeature'] || featureFlags[FF_KEAP_SINGLE_PIPELINE];

        const hellosignConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.HELLO_SIGN);

        const showThirdPartySection = !hellosignConnected && rootGetters['auth/hasIntegrationsSettingsFeature'];

        // sections in alphabetical order
        stepsBySection.communications = {
            title: 'automation.steps.sections.communications',
            automationItems: [
                {
                    type: SEND_EMAIL,
                    name: 'automation.steps.sendEmail.title',
                    description: 'automation.steps.sendEmail.description',
                    image: 'send-email.svg',
                    order: 1,
                },
            ],
        };

        if (hellosignConnected && rootGetters['auth/hasIntegrationsSettingsFeature']) {
            stepsBySection.hellosign = {
                title: 'automation.steps.sections.hellosign',
                automationItems: [
                    {
                        type: HELLOSIGN_SEND_DOCUMENT,
                        name: 'automation.steps.hellosignSendDocument.title',
                        description: 'automation.steps.hellosignSendDocument.description',
                        image: 'hellosign-send-document.svg',
                        order: NOT_SHOWN,
                    },
                ],
            };
        }

        if (showPipeline) {
            stepsBySection.pipeline = {
                title: 'automation.steps.sections.pipeline',
                automationItems: [
                    {
                        type: CREATE_DEAL,
                        name: 'automation.steps.createDeal.title',
                        description: 'automation.steps.createDeal.description',
                        image: 'create-deal.svg',
                        order: 4,
                    },
                ],
            };
        }

        stepsBySection.tags = {
            title: 'automation.steps.sections.tags',
            automationItems: [
                {
                    type: APPLY_TAG,
                    name: 'automation.steps.applyTag.title',
                    description: 'automation.steps.applyTag.description',
                    image: 'apply-tag.svg',
                    order: showSms ? 5 : 3,
                },
                {
                    type: REMOVE_TAG,
                    name: 'automation.steps.removeTag.title',
                    description: 'automation.steps.removeTag.description',
                    image: 'remove-tag.svg',
                    order: NOT_SHOWN,
                },
            ],
        };

        stepsBySection.tasks = {
            title: 'automation.steps.sections.tasks',
            automationItems: [
                {
                    type: CREATE_TASK,
                    name: 'automation.steps.createTask.title',
                    description: 'automation.steps.createTask.description',
                    image: 'create-task.svg',
                    order: showPipeline ? NOT_SHOWN : 4,
                },
            ],
        };

        if (showSms) {
            stepsBySection.communications.automationItems.push({
                type: SEND_SMS,
                name: 'automation.steps.sendSms.title',
                description: 'automation.steps.sendSms.description',
                image: 'send-sms.svg',
                order: 3,
            });
        }

        stepsBySection.communications.automationItems.push({
            type: SEND_NOTIFICATION,
            name: 'automation.steps.sendNotification.title',
            description: 'automation.steps.sendNotification.description',
            image: 'send-notification.svg',
            order: 2,
        });

        if (showThirdPartySection) {
            const automationItems = [];

            if (!hellosignConnected) {
                automationItems.push({ ...INTEGRATION_TYPES[HELLOSIGN] });
            }

            stepsBySection.thirdParty = {
                title: 'automation.integrations.section.title',
                automationItems,
            };
        }

        return stepsBySection;
    },

    removeTriggersBySection({ automation }, getters, { featureFlags }, rootGetters) {
        const removeTriggersBySection = {};
        const useAppointmentCancelled = automation.triggers?.some(({ type }) => ANY_APPOINTMENT_SCHEDULED === type || APPOINTMENT_SCHEDULED === type || USER_APPOINTMENT_SCHEDULED === type);
        const showForms = rootGetters['auth/hasFormsFeature'];
        const showPipeline = rootGetters['auth/hasMultiplePipelinesFeature'] || rootGetters['auth/hasSinglePipelineFeature'] || featureFlags[FF_KEAP_SINGLE_PIPELINE];
        const showLandingPageFormSubmitted = featureFlags[FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_LANDING_PAGE_FORM_SUBMITTED] && featureFlags[FF_KEAP_LANDING_PAGES] && rootGetters['auth/hasLandingPagesFeature'];

        const showInvoicePaidStop = automation.triggers?.some(({ type }) => ANY_INVOICE_SENT === type);

        const allowQuoteAccepted = automation.triggers?.some(({ type }) => ANY_QUOTE_SENT === type);
        const allowQuoteSent = !automation.triggers?.some(({ type }) => ANY_QUOTE_SENT === type);
        const quoteTriggers = [];

        const showHellosignDocSignedStop = !automation.triggers?.some(({ type }) => ANY_HELLOSIGN_DOCUMENT_SIGNED === type || HELLOSIGN_DOCUMENT_SIGNED === type);

        if (allowQuoteSent) {
            quoteTriggers.push({ ...TRIGGERS[ANY_QUOTE_SENT], image: 'stop/quote-sent.svg' });
        }

        if (allowQuoteAccepted) {
            quoteTriggers.push({ ...TRIGGERS[ANY_QUOTE_ACCEPTED], image: 'stop/quote-accepted.svg' });
        }

        const appointmentTriggers = [{
            ...TRIGGERS[APPOINTMENT_SCHEDULED],
            order: useAppointmentCancelled ? 5 : 2,
            image: 'stop/appointment-scheduled.svg',
        }];

        const productTriggers = [{
            ...TRIGGERS[PRODUCT_PURCHASED],
            order: 4,
            image: 'stop/product-purchased.svg',
        }];

        const invoiceTriggers = showInvoicePaidStop ? [{
            ...TRIGGERS[ANY_INVOICE_PAID],
            image: 'stop/invoice-paid.svg',
        }] : [];

        if (useAppointmentCancelled) {
            appointmentTriggers.push({
                ...TRIGGERS[APPOINTMENT_CANCELLED],
                order: 2,
                image: 'stop/appointment-cancelled.svg',
            });
        }

        if (rootGetters['auth/hasAppointmentsFeature']) {
            removeTriggersBySection.appointments = {
                title: 'automation.triggers.sections.appointments',
                automationItems: appointmentTriggers,
            };
        }

        const formTriggers = [];

        if (showForms) {
            formTriggers.push({
                ...TRIGGERS[PUBLIC_FORM_SUBMITTED],
                order: 1,
                image: 'stop/public-form-submitted.svg',
            });

            formTriggers.push({
                ...TRIGGERS[INTERNAL_FORM_SUBMITTED],
                image: 'stop/internal-form-submitted.svg',
                order: 6,
            });
        }

        if (showLandingPageFormSubmitted) {
            formTriggers.push({
                ...TRIGGERS[LANDING_PAGE_FORM_SUBMITTED],
                image: 'stop/landing-page-form-submitted.svg',
                order: 6,
            });
        }

        if (showForms) {
            removeTriggersBySection.forms = {
                title: 'automation.triggers.sections.forms',
                automationItems: formTriggers,
            };
        }

        const isHellosignConnected = rootGetters['settings/isBlendrConnected'](BLENDR_TYPES.HELLO_SIGN);

        if (isHellosignConnected && showHellosignDocSignedStop && rootGetters['auth/hasIntegrationsSettingsFeature']) {
            removeTriggersBySection.hellosign = {
                title: 'automation.triggers.sections.hellosign',
                automationItems: [{
                    ...TRIGGERS[HELLOSIGN_DOCUMENT_SIGNED],
                    order: 5,
                    image: 'stop/hellosign-document-signed.svg',
                }],
            };
        }

        if (rootGetters['auth/hasInvoicesAndQuotesFeature']) {
            removeTriggersBySection.money = {
                title: 'automation.triggers.sections.money',
                automationItems: [
                    ...invoiceTriggers,
                    ...productTriggers,
                    ...quoteTriggers,
                ],
            };
        }

        if (showPipeline && getters.hasDealTrigger) {
            removeTriggersBySection.pipeline = {
                title: 'automation.triggers.sections.pipeline',
                automationItems: [
                    { ...TRIGGERS[DEAL_ENTERED], image: 'stop/deal-entered.svg' },
                    { ...TRIGGERS[DEAL_EXITED], image: 'stop/deal-exited.svg' },
                ],
            };
        }

        removeTriggersBySection.tags = {
            title: 'automation.triggers.sections.tags',
            automationItems: [
                {
                    ...TRIGGERS[TAG_APPLIED],
                    order: 3,
                    image: 'stop/tag-applied.svg',
                },
            ],
        };

        const showThirdPartySection = showHellosignDocSignedStop && !isHellosignConnected;

        if (showThirdPartySection && rootGetters['auth/hasIntegrationsSettingsFeature']) {
            const automationItems = [];

            if (!isHellosignConnected) {
                automationItems.push({ ...INTEGRATION_TYPES[HELLOSIGN] });
            }

            removeTriggersBySection.thirdParty = {
                title: 'automation.integrations.section.title',
                automationItems,
            };
        }

        return removeTriggersBySection;
    },

    automationFilterOptions(state, getters, {
        pipeline, featureFlags, customForms, smartForms,
    }, rootGetters) {
        const filterOptions = [];

        const instance = Vue.prototype.$i18nInstance;

        if (rootGetters['auth/hasAppointmentsFeature']) {
            filterOptions.push({ category: { value: AUTOMATION_FILTERS.ALL_APPOINTMENTS, label: instance.t('automation.filter.appointments') } });
        }

        if (rootGetters['auth/hasFormsFeature']) {
            filterOptions.push({
                category: { value: 'form', label: instance.t('automation.filter.forms') },
                groups: [{
                    label: instance.t('automation.filter.all'),
                    options: [
                        { value: AUTOMATION_FILTERS.ALL_FORMS, label: instance.t('automation.filter.allForms') },
                        { value: AUTOMATION_FILTERS.ALL_PUBLIC_FORMS, label: instance.t('automation.filter.allPublicForms') },
                        { value: AUTOMATION_FILTERS.ALL_INTERNAL_FORMS, label: instance.t('automation.filter.allInternalForms') },
                    ],
                }, {
                    label: instance.t('automation.filter.publicForms'),
                    options: customForms.forms[FORM_TYPE_CONTACTS]?.map(({ title, id }) => {
                        return {
                            value: `${AUTOMATION_FILTERS.FORM}${id}`,
                            label: title,
                        };
                    }),
                }, {
                    label: instance.t('automation.filter.internalForms'),
                    options: smartForms.forms?.map(({ title, id }) => {
                        return {
                            value: `${AUTOMATION_FILTERS.FORM}${id}`,
                            label: title,
                        };
                    }),
                }],
            });
        }

        if (rootGetters['auth/hasInvoicesAndQuotesFeature']) {
            filterOptions.push({ category: { value: AUTOMATION_FILTERS.ALL_MONEY, label: instance.t('automation.filter.money') } });
        }

        if (rootGetters['auth/hasMultiplePipelinesFeature'] || rootGetters['auth/hasSinglePipelineFeature'] || featureFlags[FF_KEAP_SINGLE_PIPELINE]) {
            const pipelineGroups = [{
                label: instance.t('automation.filter.all'),
                options: [
                    { value: AUTOMATION_FILTERS.ALL_PIPELINES, label: instance.t('automation.filter.allPipelines') },
                ],
            }];

            pipeline.pipelineList?.forEach(({ id, name, stages }) => {
                const stageOptions = [{ value: `${AUTOMATION_FILTERS.ALL_STAGES}${id}`, label: instance.t('automation.filter.allStages') }];

                stages.forEach((stage) => {
                    stageOptions.push({
                        value: `${AUTOMATION_FILTERS.STAGE}${stage.id}`,
                        label: stage.name,
                    });
                });

                pipelineGroups.push({
                    label: name,
                    options: stageOptions,
                });
            });

            filterOptions.push({
                category: { value: 'pipeline', label: instance.t('automation.filter.pipeline') },
                groups: pipelineGroups,
            });
        }

        filterOptions.push({ category: { value: AUTOMATION_FILTERS.ALL_TAGS, label: instance.t('automation.filter.tags') } });

        return filterOptions;
    },

    hasAnyAutomationsFeature: (_, __, ___, rootGetters) => {
        return rootGetters['auth/hasEasyAutomationsFeature'] || rootGetters['auth/hasCampaignBuilderFeature'];
    },
};

const getAutomationWithStopTriggerErrors = (automation, authUsers) => {
    const { steps, triggers, removeTriggers } = automation;

    if (!automation.removeTriggers) {
        return automation;
    }

    // remove trigger without a timer
    if (removeTriggers?.length > 0 && !steps?.some(({ type }) => TIMER_STEPS.includes(type))) {
        const message = {
            messageCode: validationMessageCodes.STOP_TRIGGER_MISSING_TIMER,
            severity: ERROR_LEVELS.ERROR,
        };

        removeTriggers[0].messages = [message];
        automation.messages.push(message);

        return automation;
    }

    const cancelledStopInvalid = removeTriggers?.some(({ type }) => type === APPOINTMENT_CANCELLED || type === ANY_APPOINTMENT_CANCELLED || type === USER_APPOINTMENT_CANCELLED)
                                  && !triggers?.some(({ type }) => type === APPOINTMENT_SCHEDULED || type === ANY_APPOINTMENT_SCHEDULED || type === USER_APPOINTMENT_SCHEDULED);
    const invoicePaidInvalid = removeTriggers?.some(({ type }) => type === ANY_INVOICE_PAID)
                                && !triggers?.some(({ type }) => type === ANY_INVOICE_SENT);
    const quoteAcceptedInvalid = removeTriggers?.some(({ type }) => type === ANY_QUOTE_ACCEPTED)
                                && !triggers?.some(({ type }) => type === ANY_QUOTE_SENT);
    const dealMoveInvalid = removeTriggers?.some(({ type }) => type === DEAL_ENTERED || type === DEAL_EXITED)
                                && !triggers?.some(({ type }) => type === DEAL_ENTERED || type === DEAL_EXITED);
    const hellosignDocSignStopInvalid = removeTriggers?.some(({ type }) => type === HELLOSIGN_DOCUMENT_SIGNED)
                                && triggers?.some(({ type }) => type === HELLOSIGN_DOCUMENT_SIGNED);

    if (cancelledStopInvalid || invoicePaidInvalid || quoteAcceptedInvalid || dealMoveInvalid || hellosignDocSignStopInvalid) {
        const message = {
            messageCode: validationMessageCodes.STOP_TRIGGER_INVALID,
            severity: ERROR_LEVELS.ERROR,
        };

        removeTriggers[0].messages = [message];
        automation.messages.push(message);

        return automation;
    }

    // remove trigger is the same type and config as start trigger
    let hasMatch = false;

    removeTriggers.forEach((removeTrigger, i) => {
        triggers.forEach((trigger) => {
            const isSameConfig = removeTrigger.type === trigger.type
                // eslint-disable-next-line eqeqeq
                && removeTrigger.sourceId == trigger.sourceId
                && isEqual(removeTrigger.configJson || {}, trigger.configJson || {});

            if (!hasMatch && (isSameConfig || includesSameType(removeTrigger, trigger, authUsers))) {
                hasMatch = true;

                const message = {
                    messageCode: validationMessageCodes.STOP_TRIGGER_SAME_AS_TRIGGER,
                    severity: ERROR_LEVELS.ERROR,
                };

                removeTriggers[i].messages = [message];
                automation.messages.push(message);
            }
        });
    });

    return automation;
};

const includesSameType = (removeTrigger, trigger, users) => {
    const matchingTypes = {
        [ANY_PUBLIC_FORM_SUBMITTED]: [PUBLIC_FORM_SUBMITTED],
        [PUBLIC_FORM_SUBMITTED]: [ANY_PUBLIC_FORM_SUBMITTED],
        [APPOINTMENT_SCHEDULED]: [ANY_APPOINTMENT_SCHEDULED],
        [ANY_APPOINTMENT_SCHEDULED]: [APPOINTMENT_SCHEDULED, USER_APPOINTMENT_SCHEDULED],
        [USER_APPOINTMENT_SCHEDULED]: [ANY_APPOINTMENT_SCHEDULED],
        [APPOINTMENT_CANCELLED]: [ANY_APPOINTMENT_CANCELLED],
        [ANY_APPOINTMENT_CANCELLED]: [APPOINTMENT_CANCELLED, USER_APPOINTMENT_CANCELLED],
        [USER_APPOINTMENT_CANCELLED]: [ANY_APPOINTMENT_CANCELLED],
        [ANY_PRODUCT_PURCHASED]: [PRODUCT_PURCHASED],
        [PRODUCT_PURCHASED]: [ANY_PRODUCT_PURCHASED],
        [ANY_INTERNAL_FORM_SUBMITTED]: [INTERNAL_FORM_SUBMITTED],
        [INTERNAL_FORM_SUBMITTED]: [ANY_INTERNAL_FORM_SUBMITTED],
        [ANY_HELLOSIGN_DOCUMENT_SIGNED]: [HELLOSIGN_DOCUMENT_SIGNED],
        [HELLOSIGN_DOCUMENT_SIGNED]: [ANY_HELLOSIGN_DOCUMENT_SIGNED],
        [TYPEFORM_FORM_SUBMITTED]: [TYPEFORM_ANY_FORM_SUBMITTED],
        [TYPEFORM_ANY_FORM_SUBMITTED]: [TYPEFORM_FORM_SUBMITTED],
    };

    // if the user for the USER_APPOINTMENT_SCHEDULED is the same as the specific APPOINTMENT_SCHEDULED
    // the appointments can overlap, we need to prevent this. we already prevent a cancelled trigger with a cancelled
    // remove trigger so we only need to check for scheduled types
    let userId;
    let casUserId;

    if (removeTrigger.type === USER_APPOINTMENT_SCHEDULED && trigger.type === APPOINTMENT_SCHEDULED) {
        userId = trigger?.configJson?.userId;
        casUserId = removeTrigger?.sourceId;
    }

    if (trigger.type === USER_APPOINTMENT_SCHEDULED && removeTrigger.type === APPOINTMENT_SCHEDULED) {
        userId = removeTrigger?.configJson?.userId;
        casUserId = trigger?.sourceId;
    }

    if (userId && casUserId) {
        if (users.find(({ id, casId }) => +id === +userId && +casId === +casUserId)) {
            return true;
        }
    }

    return removeTrigger.type && matchingTypes[removeTrigger.type]?.includes(trigger.type);
};
