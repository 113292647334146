<template lang="html">
    <div v-if="contact" class="contact-saved-modal">
        <h3>{{ $t(contactAddedTitleKey) }}</h3>

        <div class="contact-card" @click="openContactRecord">
            <div class="avatar-wrapper">
                <contact-avatar
                    :size="80"
                    :name="displayName"
                    :email="contact.email"
                />

                <ds-icon
                    class="avatar-info-icon"
                    name="info-circle-fill"
                />
            </div>

            <h5>{{ fullName }}</h5>

            <div v-if="contact.email || hasPhone" class="email-phone-wrapper">
                <span v-if="contact.email">{{ contact.email }}</span>

                <span v-if="hasPhone">
                    {{ formatPhone(contact.phone.value) }}
                </span>
            </div>

            <div class="view-more-wrapper">
                <div v-if="loading" class="spinner-container">
                    <ds-spinner />
                </div>

                <div v-else-if="hasNotes" class="notes-wrapper">
                    <header>
                        {{ $t('notesTitle') }}
                    </header>

                    <div class="note">
                        <ds-icon class="note-icon" name="note" />

                        <header>
                            {{ $t('dateNoteAdded', { noteDate }) }}
                        </header>

                        <p>{{ noteBody }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons">
            <div class="button-wrapper">
                <ds-filled-button
                    class="block"
                    data-qa="view-contact-action"
                    @click="openContactRecord"
                >
                    {{ $t('viewContactRecordButton') }}
                </ds-filled-button>
            </div>

            <div v-if="!isOther" class="button-wrapper">
                <ds-outline-button
                    class="block"
                    data-qa="follow-up-action"
                    @click="followUp"
                >
                    {{ $t(followUpButtonKey) }}
                </ds-outline-button>
            </div>

            <div v-if="!isOther" class="button-wrapper">
                <ds-outline-button
                    class="block"
                    data-qa="invite-action"
                    @click="inviteToMeet"
                >
                    {{ $t('inviteToMeet') }}
                </ds-outline-button>
            </div>

            <ds-outline-button
                class="block"
                data-qa="add-another-contact"
                @click="addAnotherContact"
            >
                {{ $t('addAnotherContact') }}
            </ds-outline-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { CONTACT_TYPES } from '@/contacts/contacts.constants';
import { TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';
import { CONTACT_RECORD } from '@/contacts/contactEmailTypes';

import displayMixin from '@/shared/mixins/display.mixin';
import dateMixin from '@/appointments/mixins/date.mixin';
import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';
import { displayFullName, getFullName } from '@/contacts/contact-info-utils';

import SingleEmailModal from '@/contacts/components/email/SingleEmailModal';
import ContactAvatar from '@/shared/components/ContactAvatar';

import intercom from '@/analytics/intercom';
import fullstory from '@/analytics/fullstory';

export default {
    components: {
        ContactAvatar,
    },

    mixins: [closeConfirmation, displayMixin, dateMixin],

    data() {
        return {
            contact: {},
            loading: false,
            baseUrl: process.env.BASE_URL,
            nestedModal_hideHeaderBorder: true,
            nestedModal_removeInnerPaddingTop: true,
            nestedModal_showBack: false,
            nestedModal_showClose: true,
        };
    },

    computed: {
        ...mapState({
            notes: ({ contacts }) => contacts.notes,
        }),

        isLead() {
            return this.contact.contactType === CONTACT_TYPES.LEAD;
        },

        isCustomer() {
            return this.contact.contactType === CONTACT_TYPES.CUSTOMER;
        },

        isOther() {
            return !this.isLead && !this.isCustomer;
        },

        contactAddedTitleKey() {
            if (this.isLead) {
                return 'contactAddedLead';
            }

            if (this.isCustomer) {
                return 'contactAddedCustomer';
            }

            return 'contactAddedOther';
        },

        followUpButtonKey() {
            if (this.isLead) {
                return 'leadFollowUpButton';
            }

            return 'customerFollowUpButton';
        },

        hasNotes() {
            return Boolean(this.notes.length);
        },

        noteDate() {
            return Boolean(this.notes.length) && this.date_formatDate(this.notes[0].dateCreated, 'LLL');
        },

        noteBody() {
            return Boolean(this.notes.length) && this.notes[0].body;
        },

        hasPhone() {
            return Boolean(this.contact.phone && this.contact.phone.value);
        },

        hasEmail() {
            return Boolean(this.contact && this.contact.email);
        },

        fullName() {
            return displayFullName(this.contact);
        },

        displayName() {
            return getFullName(this.contact);
        },

    },

    methods: {
        nestedModal_open(newContact) {
            this.contact = newContact;
            this.loadNotes();
        },

        async loadNotes() {
            if (this.contact && this.contact.notes) {
                this.loading = true;

                await this.$store.dispatch('contacts/LOAD_NOTES', this.contact.id)
                    .catch((error) => {
                        this.$log('Load notes failed', { data: error.response.data });
                    });

                this.loading = false;
            }
        },

        openContactRecord() {
            this.$router.push({
                name: 'contact.record',
                params: {
                    contactId: this.contact.id,
                },
            });

            intercom.logEvent(intercom.events.CONTACT_RECORD_VIEWED);

            fullstory.event('Contact Record Viewed', {
                'Event Source': 'Contact Added Success Modal',
            });

            this.$bus.$emit('POP_ALL_NESTED_MODALS');
        },

        followUp() {
            this.$track(`Contacts - add a single contact - nudge - clicked: follow up email (${this.contact.contactType})`);
            this.$bus.$emit('POP_ALL_NESTED_MODALS');

            if (this.isLead) {
                this.openEmail(TEMPLATE_KINDS.INTRODUCTION);
            } else {
                this.openEmail(TEMPLATE_KINDS.FOLLOW_UP);
            }
        },

        inviteToMeet() {
            this.$track('Contacts - add a single contact - nudge - clicked: invite email');
            this.$bus.$emit('POP_ALL_NESTED_MODALS');
            this.openEmail(TEMPLATE_KINDS.INVITATION);
        },

        addAnotherContact() {
            this.$track(`Contacts - add a single contact - nudge - clicked: Add another contact (${this.contact.contactType})`);
            this.$bus.$emit('POP_NESTED_MODAL');
            this.$bus.$emit('ADD_ANOTHER_CONTACT');
        },

        openEmail(template = '') {
            this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);
            this.$store.commit('email/RESET_CURRENT_EMAIL');

            const recipientList = [{
                ...this.contact,
                email: this.contact.email,
            }];

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SingleEmailModal,
                showRootClose: true,
                props: {
                    recipientList,
                    initialTemplate: template,
                    source: CONTACT_RECORD,
                },
                interceptClose: () => this.confirmCloseDraft(() => this.$bus.$emit('POP_NESTED_MODAL')),
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .contact-saved-modal {
        text-align: center;
        padding-bottom: $gp;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
    }

    h3, h4 {
        font-family: $font-family-secondary-title;
        font-weight: bold;
    }

    h3 {
        font-size: px-to-rem(28px);

        @media ($medium) {
            font-size: px-to-rem(32px);
        }
    }

    h4 {
        font-size: px-to-rem(24px);

        @media ($medium) {
            font-size: px-to-rem(28px);
        }
    }

    h5 {
        font-size: px-to-rem(18px);

        @media ($medium) {
            font-size: px-to-rem(22px);
        }
    }

    h6 {
        font-weight: $font-weight-regular;
        font-size: px-to-rem(12px);

        @media ($medium) {
            font-size: px-to-rem(16px);
        }
    }

    .buttons {
        margin: $gp * 2 auto 0;
        max-width: px-to-rem(200px);
    }

    .button-wrapper {
        margin-bottom: $gp;
    }

    .email-phone-wrapper {
        color: $color-gray-700;
        margin-top: $gp / 4;
        width: 100%;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        span {
            margin: 0 $gp / 4;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .contact-card {
        @include card;

        max-width: px-to-rem(375px);
        background-color: $color-gray-050;
        padding: $gp / 2 0;
        margin: $gp * 4 auto $gp;
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    .view-more-wrapper {
        width: 90%;
        margin: $gp auto 0;
    }

    .spinner-container {
        position: relative;
        min-height: px-to-rem(100px);
    }

    .notes-wrapper {
        border-top: 1px $color-gray-300 solid;
        padding-top: $gp;
    }

    .notes-wrapper header {
        font-size: $font-size-md
    }

    .note {
        @include text-align-start;

        margin: $gp / 4 0 0;
        display: grid;
        grid-template-columns: 1fr 10fr;
        align-items: center;
    }

    .note-icon {
        grid-column: 1;
        grid-row: 1;

        --icon-color: #{$color-gray-800};
    }

    .note header {
        font-size: $font-size-xs;
        color: $color-gray-700;
        max-height: $gp * 2;
        grid-column: 2;
        grid-row: 1;
        padding: $gp / 2;
    }

    .note p {
        grid-column: 2;
        grid-row: 2;
        padding: 0 $gp / 2;
    }

    .avatar-wrapper {
        @include transition(box-shadow);

        margin: -55px 0 $gp;
        position: relative;
        cursor: pointer;
        border-radius: 100%;

        &:hover {
            box-shadow: $elevation-z8;
        }
    }

    .avatar-info-icon {
        position: absolute;
        bottom: 0;
        right: px-to-rem(5px);
        border: px-to-rem(2px) solid $color-gray-050;
        background-color: $color-gray-050;
        color: $color-blue;
        border-radius: 50%;
    }
</style>

<i18n>
{
    "en-us": {
        "contactAddedLead": "Take a peek at your new lead.",
        "contactAddedCustomer": "Take a peek at your new client.",
        "contactAddedOther": "Check out your new contact.",
        "addAnotherContact": "Add another contact",
        "notesTitle": "Notes",
        "dateNoteAdded": "Note added {noteDate}",
        "inviteToMeet": "Invite to meet",
        "leadFollowUpButton": "Introduce yourself",
        "customerFollowUpButton": "Send follow up email",
        "viewContactRecordButton": "View contact record"
    }
}
</i18n>
