<template lang="html">
    <section class="contact-addresses">
        <h4 class="section-title editing">
            {{ $t('contactDetails.sectionHeaders.addresses') }}
        </h4>

        <div v-for="addressType in addresses" :key="addressType">
            <div v-if="isEditable(addressType)" class="address-type">
                {{ $t(`contactDetails.fieldHeaders.${addressType}`) }}
            </div>

            <div v-if="isEditable(addressType)" :data-qa="addressType" class="addresses">
                <div class="field-grid editing">
                    <ds-multiselect
                        v-model="contact[addressType].countryCode"
                        class="wide-field fs-block"
                        searchable
                        show-search-icon
                        bind-value-only
                        :placeholder="$t('forms.countryCode')"
                        :options="countries"
                        @input="handleCountrySelect(addressType)"
                    />

                    <address-autocomplete
                        v-model.trim="contact[addressType].street1"
                        class="wide-field fs-block"
                        autocomplete-value="nofill"
                        :label="$t('street1')"
                        :field-name="standardFields[addressType].street1"
                        @input="$emit('input')"
                    />

                    <ds-input-field
                        v-model.trim="contact[addressType].street2"
                        class="wide-field fs-block"
                        autocomplete="no"
                        :label="$t('street2')"
                        @input="$emit('input')"
                    />

                    <ds-input-field
                        v-model="contact[addressType].locality"
                        class="wide-field fs-block"
                        autocomplete="no"
                        :label="$t('locality')"
                        @input="$emit('input')"
                    />

                    <ds-multiselect
                        v-model="contact[addressType].region"
                        bind-value-only
                        class="fs-block"
                        searchable
                        show-search-icon
                        :placeholder="$t('region')"
                        :options="getRegionOptions(addressType)"
                        :label="$t('region')"
                        :readonly="!contact[addressType].countryCode"
                        @input="$emit('input')"
                    />

                    <ds-input-field
                        v-model.trim="contact[addressType].postalCode"
                        class="fs-block"
                        autocomplete="no"
                        :label="$t('postalCode')"
                        @input="$emit('input')"
                    />
                </div>

                <div>
                    <contact-field-remove-button
                        :data-qa="`clear-${addressType}`"
                        @action="clearAddress(addressType)"
                    />
                </div>
            </div>
        </div>

        <ds-dropdown
            v-if="showAddButton"
            class="dropdown add-address-button"
            data-qa="add-address-button"
        >
            <span class="add-field" @click="addAddress">
                <ds-icon name="add" />
                <span>{{ $t('addAddress') }}</span>
            </span>

            <template #menu>
                <ul class="dropdown-menu-list">
                    <li
                        v-for="address in emptyAddresses"
                        :key="address"
                        class="dropdown-menu-item"
                        @click="addAddress(address)"
                    >
                        {{ $t(`addAddressOptions.${address}`) }}
                    </li>
                </ul>
            </template>
        </ds-dropdown>
    </section>
</template>

<script>
import AddressAutocomplete from '@/shared/components/Fields/AddressAutocomplete';
import ContactFieldRemoveButton from './ContactFieldRemoveButton';

const DEFAULT_COUNTRY = 'USA';

export default {
    components: {
        AddressAutocomplete,
        ContactFieldRemoveButton,
    },

    props: {
        contact: Object,
        companyCountry: String,
        fieldOptions: Object,
        delay: {
            type: Number,
            default: 500,
        },
    },

    data() {
        return {
            addresses: [
                'billingAddress',
                'shippingAddress',
                'otherAddress',
            ],
            emptyAddresses: [],
            standardFields: {
                shippingAddress: {
                    street1: 'STREET_ADDRESS1',
                    street2: 'STREET_ADDRESS2',
                },
                billingAddress: {
                    street1: 'ADDRESS2_STREET1',
                    street2: 'ADDRESS2_STREET2',
                },
                otherAddress: {
                    street1: 'ADDRESS3_STREET1',
                    street2: 'ADDRESS3_STREET2',
                },
            },
        };
    },

    mounted() {
        this.getEmptyAddresses();
    },

    created() {
        this.$bus.$on('AUTOFILL_CONTACT_ADDRESS', (addressData) => this.handleAutoFill(addressData));
    },

    beforeDestroy() {
        this.$bus.$off('AUTOFILL_CONTACT_ADDRESS');
    },

    computed: {
        showAddButton() {
            return this.emptyAddresses.length > 0;
        },

        countries() {
            const country = this.companyCountry || DEFAULT_COUNTRY;
            const firstCountryOption = this.fieldOptions.countries.find(({ value }) => value === country);

            return firstCountryOption
                ? [firstCountryOption, ...this.fieldOptions.countries]
                : this.fieldOptions.countries;
        },
    },

    methods: {
        handleCountrySelect(addressType) {
            this.$emit('input');
            this.loadRegionOptions(addressType);
        },

        clearAddress(addressType) {
            Object.keys(this.contact[addressType]).forEach((fieldName) => {
                this.contact[addressType][fieldName] = '';
            });

            this.emptyAddresses.push(addressType);
        },

        handleAutoFill({ addressFields, addressObject }) {
            const [addressField] = addressFields;

            const addressTypeMappings = {
                STREET_ADDRESS1: 'shippingAddress',
                ADDRESS2_STREET1: 'billingAddress',
                ADDRESS3_STREET1: 'otherAddress',
            };

            const addressType = addressTypeMappings[addressField];

            this.autoFill(addressType, addressObject);
        },

        async autoFill(addressType, addressObject) {
            const { fields } = addressObject;
            const contactAddress = this.contact[addressType];
            const countryRegex = new RegExp(`^${fields.countryName}$`, 'i');
            const regionRegex = new RegExp(`^${fields.region}$`, 'i');

            const matchedCountry = this.fieldOptions.countries.find(({ label, value }) => {
                return value && label?.match(countryRegex);
            });

            if (matchedCountry) {
                contactAddress.countryCode = matchedCountry.value;
                await this.loadRegionOptions(addressType);
            }

            const matchedRegion = this.getRegionOptions(addressType).find(({ label, value }) => {
                return value && label?.match(regionRegex);
            });

            contactAddress.region = matchedRegion?.value || null;
            contactAddress.locality = fields.locality;
            contactAddress.postalCode = fields.postalCode;
            contactAddress.street1 = fields.streetAddress1;
            contactAddress.street2 = fields.streetAddress2;
        },

        addAddress(address) {
            const addressType = this.emptyAddresses.length === 1
                ? this.emptyAddresses[0]
                : address;

            const addressIndex = this.emptyAddresses.indexOf(addressType);

            if (addressIndex !== -1) {
                this.emptyAddresses.splice(addressIndex, 1);

                this.loadRegionOptions(addressType);
            }
        },

        isEditable(addressType) {
            return !this.emptyAddresses.includes(addressType);
        },

        getEmptyAddresses() {
            this.emptyAddresses = this.addresses.map((addressType) => {
                const addressTypeFields = Object.values(this.contact[addressType]).filter((value) => Boolean(value));

                return addressTypeFields.length === 0
                    ? addressType
                    : null;
            }).filter((value) => Boolean(value));

            if (this.emptyAddresses.length === this.addresses.length) {
                const [address] = this.emptyAddresses;

                this.addAddress(address);
            }
        },

        loadRegionOptions(addressType) {
            this.contact[addressType].region = '';
            const { countryCode } = this.contact[addressType];

            [addressType] = addressType.toLowerCase().split('address');

            return this.$store.dispatch('contacts/LOAD_REGION_OPTIONS', { countryCode, addressType });
        },

        getRegionOptions(addressType) {
            [addressType] = addressType.toLowerCase().split('address');

            return this.fieldOptions[`${addressType}Region`] || [];
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .addresses {
        display: grid;
        grid-gap: $gp;
        grid-template-columns: 1fr auto;
    }

    .address-type {
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        margin: 0 0 $gp;
        color: $color-ink-800;
    }

    .add-address-button {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "street1": "Address",
        "street2": "Apt/Suite/Other",
        "locality": "City",
        "region": "State",
        "postalCode": "Zip code",
        "addAddress": "Add an address",
        "addAddressOptions": {
            "shippingAddress": "Shipping address",
            "billingAddress": "Billing address",
            "otherAddress": "Optional address"
        }
    }
}
</i18n>
