import Vue from 'vue';
import gql from 'graphql-tag';

export const addPipelineStage = ({ name, pipelineId }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation addPipelineStage($pipelineId: ID!, $name: String!) {
                addPipelineStage(pipelineId: $pipelineId, name: $name) {
                    id,
                    name,
                    order,
                    pipelineId,
                }
            }
        `,
        variables: {
            pipelineId,
            name,
        },
    });
};

export const deletePipelineStageAndMoveDeals = ({ stageId, newStageId }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deletePipelineStage($stageId: ID!, $newStageId: ID) {
                deletePipelineStage(stageId: $stageId, newStageId: $newStageId)
            }`,
        variables: {
            stageId,
            newStageId,
        },
    });
};

export const deletePipelineStage = ({ stageId }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deletePipelineStage($stageId: ID!) {
                deletePipelineStage(stageId: $stageId)
            }`,
        variables: {
            stageId,
        },
    });
};

export const updatePipelineStage = ({ stageId, payload }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updatePipelineStage($stageId: ID!, $stage: StageInput!) {
                updatePipelineStage(stageId: $stageId, stage: $stage) {
                    id,
                    name,
                    order,
                    pipelineId,
                }
            }
        `,
        variables: {
            stageId,
            stage: payload,
        },
    });
};

export const getStageDealCount = (stageId) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query stageDealCount($stageId: ID!) {
                stageDealCount(stageId: $stageId) {
                    count
                }
            }
        `,
        variables: {
            stageId,
        },
        fetchPolicy: 'no-cache',
    });
};
