<script>
import debounce from 'lodash.debounce';
import axios from 'axios';
import sentry from '@/analytics/sentry';

import { queryContacts } from '@/contacts/store/queries';
import { SEARCHING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

const fetchInitialContacts = async () => {
    const result = await queryContacts(0, '', 20, 0, 'createDate', false, null);

    const { contacts } = result.data.listContacts;

    for (const contact of contacts) {
        if (contact.email == null) {
            contact.email = contact.emailAddresses != null && contact.emailAddresses[0] != null
                ? contact.emailAddresses[0].value
                : null;
        }
    }

    return contacts;
};

const searchContacts = async (term, searchParams) => {
    const queryString = new URLSearchParams({
        ...searchParams,
        term,
    });

    const { data } = await axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/search?${queryString.toString()}`);

    return data;
};

export default {
    props: {
        delay: {
            type: Number,
            default: SEARCHING_DEBOUNCE_DELAY,
        },
        searchParams: Object,
    },

    data() {
        return {
            initialResults: [],
            searchResults: [],
            loading: false,
            search: '',
        };
    },

    mounted() {
        this.query = debounce(this.query, this.delay);

        this.loading = true;

        fetchInitialContacts()
            .then((results) => {
                this.initialResults = results;
                this.loading = false;
            }).catch((e) => {
                sentry.captureException(e);
            });
    },

    computed: {
        results() {
            return this.search ? this.searchResults : this.initialResults;
        },
    },

    methods: {
        query(term) {
            this.searchResults = [];

            if (term) {
                this.search = term;
                this.loading = true;

                searchContacts(term, this.searchParams)
                    .then((results) => {
                        this.searchResults = results;
                        this.loading = false;
                    }).catch((e) => {
                        sentry.captureException(e);
                    });
            } else {
                this.search = '';
            }
        },
    },

    render() {
        return this.$scopedSlots.default({
            results: this.results,
            loading: this.loading,
            query: this.query,
            search: this.search,
        })[0];
    },
};
</script>
