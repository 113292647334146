const defaultIcon = '/img/icons/favicon-96x96.png';

function createNotification({
    title,
    body,
    icon = defaultIcon,
    url,
}) {
    const notification = new Notification(title, { icon, body });

    notification.onclick = function () {
        if (url) {
            window.open(url);
        } else {
            window.focus();
        }

        notification.close();
    };
}

export function notify(options) {
    // Only run on supported browsers https://developer.mozilla.org/en-US/docs/Web/API/notification
    if ('Notification' in window) {
        if (Notification.permission === 'granted') {
            createNotification(options);
        } else {
            const callback = (permission) => {
                if (permission === 'granted') {
                    createNotification(options);
                }
            };

            // pass as callback for legacy API
            const promise = Notification.requestPermission(callback);

            // use promise for current standards
            if (promise) {
                promise.then(callback);
            }
        }
    }
}
