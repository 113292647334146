export default {
    SET_BUSINESS_REVIEWS(state, reviews) {
        state.reviews = reviews;
    },

    SET_GOOGLE_BUSINESS_LISTINGS(state, listings) {
        state.googleBusinessListings = listings;
    },

    SET_GOOGLE_BUSINESS_EMAIL(state, email) {
        state.googleBusinessEmail = email;
    },

    SET_GOOGLE_BUSINESS_ACCOUNT_NAME(state, accountName) {
        state.googleBusinessAccountName = accountName;
    },

    SET_GOOGLE_BUSINESS_LOCATION_ID(state, locationId) {
        state.googleBusinessLocationId = locationId;
    },

    SET_GOOGLE_BUSINESS_LISTINGS_LOADING_STATUS(state, status) {
        state.googleBusinessListingsLoadingStatus = status;
    },

    SET_GOOGLE_REVIEW_LINK(state, link) {
        state.googleReviewLink = link;
    },

    SET_GOOGLE_BUSINESS_REVIEWS(state, reviews) {
        state.googleBusinessReviews = reviews;
    },

    SET_GOOGLE_BUSINESS_NAME(state, businessName) {
        state.googleBusinessName = businessName;
    },
};
