export const getQueryParamValue = (key, search) => {
    const urlParams = new URLSearchParams(search);
    const value = urlParams.get(key);

    return value;
};

export const addQueryParamToUrlString = (url, { key, value }) => {
    return url.includes('?')
        ? `${url}&${key}=${value}`
        : `${url}?${key}=${value}`;
};
