<template>
    <div
        v-if="!loading"
        :class="['message', { clickable }]"
        data-qa="participant-count"
        @click="goToList"
    >
        <span data-qa="participant-count-message">
            {{ message }}
        </span>

        <ds-text-button v-if="clickable" data-qa="click-participant-list" @click="goToList">
            {{ $t('view') }}
        </ds-text-button>
    </div>

    <ds-placeholder v-else :rows="rows" class="message" />
</template>

<script>
import { TRIGGERS, PARTICIPANT_TYPE } from '@/automations/constants/automations.constants';

export default {
    props: {
        id: {
            type: String,
            required: true,
        },
        triggers: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            uniqueContacts: 0,
            loading: true,
            participantCount: 0,
            rows: [{ height: '1rem', boxes: [1] }],
        };
    },

    mounted() {
        if (!this.$route.params.id || this.$route.params.id === this.id) {
            this.load();
        }
    },

    watch: {
        id() {
            this.load();
        },
    },

    computed: {
        isDealType() {
            // TODO automation needs to be published version to display properly
            return PARTICIPANT_TYPE.DEAL === TRIGGERS[this.triggers?.[0]?.type]?.participantType;
        },

        clickable() {
            return this.uniqueContacts > 0;
        },

        message() {
            const countFormatted = this.$n(this.isDealType ? this.participantCount : this.uniqueContacts);

            return this.isDealType
                ? this.$tc('deals', this.participantCount !== 1, { deals: countFormatted })
                : this.$tc('contacts', this.uniqueContacts !== 1, { contacts: countFormatted });
        },
    },

    methods: {
        async load() {
            this.loading = true;

            try {
                const { uniqueContacts, participants } = await this.$store.dispatch('automations/LOAD_PARTICIPANT_COUNT', this.id);

                this.uniqueContacts = uniqueContacts || participants || 0;
                this.participantCount = participants;
            } catch { /* empty */ }

            this.loading = false;
        },

        goToList() {
            if (this.clickable) {
                this.$emit('view-participants');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .message {
        --placeholder-width: #{px-to-rem(343px)};
        width: px-to-rem(343px);
        border-radius: $border-radius;
        border: 1px solid $color-gray-200;
        background-color: $color-paper;
        margin-bottom: $gp;
        padding: $gp / 2 $gp;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.clickable {
            padding: $gp / 6 $gp;
            cursor: pointer;
        }
    }

    .contextual-view .message {
        --placeholder-width: 100%;
        width: 100%;
    }
</style>

<i18n>
{
    "en-us": {
        "contacts": "{contacts} active contacts in this automation. | 1 active contact in this automation.",
        "deals": "{deals} deals in this automation. | 1 deal in this automation.",
        "view": "View"
    }
}
</i18n>
