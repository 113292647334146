<template lang="html">
    <div>
        <main
            v-if="appLoaded"
            :class="{
                'show-nav': showNav,
                'hide-header-mobile': hideHeaderOnMobile,
                'nav-open': navOpen,
                'nav-expanded': isNavExpanded,
                'nav-redesign': isNavRedesignEnabled,
            }"
        >
            <template v-if="showNav">
                <ds-media-query v-slot="{ isMatch }" breakpoint="medium">
                    <side-nav-menu
                        v-if="isMatch && isNavRedesignEnabled"
                        :user="user"
                    />

                    <div v-else>
                        <nav-header class="nav-header" />
                        <desktop-nav-menu />
                    </div>
                </ds-media-query>
                <mobile-nav-menu />
            </template>

            <section id="content" class="content">
                <router-view v-if="showRouterView" />
                <expired-block v-else-if="showExpired" />
            </section>

            <mobile-interceptor />
            <keyboard-shortcuts />
            <help-and-support-panel />
            <analytics-handler />
            <import-contacts />
            <onboarding-tracker />
            <notifications-panel v-if="showSidePanel" />

            <contextual-automation-panel v-if="showSidePanel" />

            <ehawk-check-modal
                :is-open="isEhawkModalOpen"
                @close="$store.commit('ehawk/SET_EHAWK_MODAL_OPEN', false)"
            />

            <account-switcher
                ref="accountSwitcher"
                :local-port="localPort"
            />

            <call-widget v-if="callsEnabled && hasKblNumber" />
        </main>

        <div v-else-if="shouldShowLogInAsError" class="page-overlay" data-qa="log-in-as-load-error">
            <log-in-as-load-error :error-messages="logInAsLoadErrors" />
        </div>

        <div v-else-if="bootLoadError" class="page-overlay" data-qa="boot-load-error">
            <boot-load-error />
        </div>

        <app-placeholder v-else />

        <div v-if="shouldShowMobileOverlay" class="page-overlay">
            <mobile-only />
        </div>

        <img v-if="hasSession" :src="coreAppUrl" style="display: none;" />

        <portal-target name="behind-nested-modal" multiple />
        <ds-nested-modal ref="nestedModal" />
        <portal-target name="root" multiple />
        <ds-focus-on-keyboard-only />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import AccountSwitcher from '@/nav/components/AccountSwitcher';
import AppPlaceholder from '@/shared/components/AppPlaceholder';
import BootLoadError from '@/shared/components/BootLoadError';
import AnalyticsHandler from '@/analytics/components/AnalyticsHandler';
import MobileOnly from '@/nav/components/MobileOnly';
import ExpiredBlock from '@/account/components/ExpiredBlock';
import KeyboardShortcuts from '@/shared/components/KeyboardShortcuts';
import NavHeader from '@/nav/components/NavHeader';
import DesktopNavMenu from '@/nav/components/DesktopNavMenu';
import MobileNavMenu from '@/nav/components/MobileNavMenu';
import SideNavMenu from '@/nav/components/SideNavMenu';
import HelpAndSupportPanel from '@/support/components/HelpAndSupportPanel';
import NotificationsPanel from '@/shared/components/Notifications/NotificationsPanel';
import ContextualAutomationPanel from '@/automations/components/ContextualAutomationPanel';
import MobileInterceptor from '@/shared/components/MobileInterceptor';
import ImportContacts from '@/import/components/ImportContacts';
import LogInAsLoadError from '@/shared/components/LogInAsLoadError';
import OnboardingTracker from '@/onboarding/components/OnboardingTracker';
import EhawkCheckModal from '@/ehawk/components/EhawkCheckModal';
import CallWidget from '@/communication/components/calls/CallWidget';

import { connectToProvider } from '@/appointments/utils/appt-connect.util';
import { getQueryParamValue } from '@/shared/utils/query-param.util';
import { PREFERENCE_TYPES } from '@/shared/constants/preferences.constants';
import {
    FF_KEAP_SIMPLE_AUTOMATION_STEP_SEND_SMS,
    FF_KEAP_SMS_ADVANCED_AUTOMATION,
    FF_KEAP_NEW_MOBILE_ONLY_EDITION,
    FF_KEAP_NAV_IA_REDESIGN,
    KBL_WEBCALLS_ENABLED,
} from '@/shared/constants/featureFlag.constants';

import amplitude from '@/analytics/amplitude';
import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';

export default {
    components: {
        AnalyticsHandler,
        AppPlaceholder,
        BootLoadError,
        EhawkCheckModal,
        ExpiredBlock,
        MobileInterceptor,
        MobileOnly,
        KeyboardShortcuts,
        NavHeader,
        DesktopNavMenu,
        MobileNavMenu,
        SideNavMenu,
        HelpAndSupportPanel,
        NotificationsPanel,
        AccountSwitcher,
        ImportContacts,
        LogInAsLoadError,
        OnboardingTracker,
        ContextualAutomationPanel,
        CallWidget,
    },
    mixins: [closeConfirmation],

    created() {
        this.setIsMobileApp();
    },

    mounted() {
        this.$bus.$on('OPEN_ACCOUNT_SWITCHER', this.openAccountSwitcher);
        this.$bus.$on('OPEN_ADD_CONTACT_MODAL', this.openAddContactModal);
        this.$bus.$on('VIEW_CONTACT', this.viewContact);
        this.$bus.$on('ADD_APPOINTMENT_TYPE', this.addAppointmentType);

        window.onfocus = () => {
            this.updateFavicon();
        };
    },

    beforeDestroy() {
        this.$bus.$off('OPEN_ACCOUNT_SWITCHER', this.openAccountSwitcher);
        this.$bus.$off('OPEN_ADD_CONTACT_MODAL', this.openAddContactModal);
        this.$bus.$off('VIEW_CONTACT', this.viewContact);
        this.$bus.$off('ADD_APPOINTMENT_TYPE', this.addAppointmentType);

        window.onfocus = undefined;
    },

    watch: {
        $route() {
            const errorBannerInstance = document.querySelector('.error-banner');

            if (errorBannerInstance && errorBannerInstance.offsetHeight > 0) {
                this.$bus.$emit('CLOSE_ERROR_BANNER');
            }

            this.trackLogin();
        },
    },

    computed: {
        ...mapState({
            apptTypes: ({ calendar }) => calendar.apptTypes,
            navOpen: ({ global }) => global.navOpen,
            isNavExpanded: ({ global }) => global.isSideMenuExpanded,
            user: ({ auth }) => auth.user,
            jwt: ({ auth }) => auth.session.jwt,
            isPublic: ({ route }) => route.meta.isPublic,
            isMobileApp: ({ global }) => global.isMobileApp,
            hideHeaderOnMobile: ({ route }) => route.meta.hideHeaderOnMobile,
            isLocal: ({ global }) => global.isLocal,
            hideNav: ({ route }) => route.meta.hideNav,
            routeName: ({ route }) => route.name,
            appLoaded: ({ global }) => global.appLoaded,
            bootLoadError: ({ global }) => global.bootLoadError,
            authSession: ({ auth }) => auth.session,
            companyName: ({ auth }) => (auth.account.companyProfile ? auth.account.companyProfile.companyName : undefined),
            logInAsLoadErrors: ({ global }) => global.logInAsLoadErrors,
            isEhawkModalOpen: ({ ehawk }) => ehawk.isModalOpen,
            hasKblNumber: ({ auth }) => Boolean(auth.account?.keapPhoneAccountInfo?.phoneNumber),
            smsEasyAutomationEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_STEP_SEND_SMS],
            smsAdvancedAutomationEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SMS_ADVANCED_AUTOMATION],
            newMobileOnlyEditionEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_NEW_MOBILE_ONLY_EDITION],
            isNavRedesignEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_NAV_IA_REDESIGN],
            callsEnabled: ({ featureFlags }) => featureFlags[KBL_WEBCALLS_ENABLED],
        }),

        ...mapGetters({
            getSmartFormById: 'smartForms/getFormById',
            hasMobileOnlyFeature: 'auth/hasMobileOnlyFeature',
            hasStealthAccess: 'auth/hasStealthAccess',
            isContactPage: 'contacts/isContactPage',
            isExpired: 'auth/isExpired',
            isOverlayActive: 'isOverlayActive',
        }),

        // Embed hidden image from infusionsoft-core in order to set cross-domain GCLB cookie
        coreAppUrl() {
            if (!this.authSession || !this.authSession.coreAppId) {
                return '';
            }

            const coreUrl = process.env.VUE_APP_CORE_URL_PATTERN?.replace('{id}', this.authSession.coreAppId);

            return `${coreUrl}/resources/component/topnav/images/k-logo.svg`;
        },

        hasSession() {
            return Boolean(this.authSession && this.authSession.coreAppId);
        },

        isAuthenticated() {
            return Boolean(this.jwt);
        },

        showNav() {
            return Boolean(this.appLoaded && !this.isMobileApp && this.routeName && !this.hideNav && !this.isPublic);
        },

        showExpired() {
            const isSettingsPage = this.routeName
                && typeof this.routeName.includes === 'function'
                && (this.routeName.includes('settings')
                || this.routeName.includes('upgrade'));

            return Boolean(this.isExpired && !this.isMobileApp && !this.isPublic && !isSettingsPage && !this.hasStealthAccess);
        },

        showRouterView() {
            return Boolean(this.appLoaded && (this.isPublic || this.authSession.coreAppId) && !this.showExpired);
        },

        localPort() {
            return process.env.NODE_ENV === 'development' && !this.isLocal ? '10239' : '';
        },

        shouldShowMobileOverlay() {
            return this.hasMobileOnlyFeature
                && this.showRouterView
                && !this.isPublic
                && !this.newMobileOnlyEditionEnabled;
        },

        showSidePanel() {
            return this.isAuthenticated && !this.shouldShowMobileOverlay && !this.isPublic;
        },

        shouldShowLogInAsError() {
            return this.logInAsLoadErrors && this.logInAsLoadErrors.length > 0;
        },
    },

    methods: {
        trackLogin() {
            if (this.$route.query.loginSuccessful) {
                this.$track('Dashboard - success : Login');
                amplitude.v2.incrementUserProp('Number of Logins', 1);

                this.$router.replace({ query: { ...this.$route.query, loginSuccessful: undefined } });
            }
        },

        setIsMobileApp() {
            const paramValue = getQueryParamValue('isMobileApp', window.location.search);
            const isMobileApp = paramValue === 'true';

            this.$store.commit('SET_IS_MOBILE_APP', isMobileApp);
        },

        openAccountSwitcher() {
            this.$refs.accountSwitcher.open();
        },

        confirmAddContactModalClose() {
            const confirmDialogTitle = this.$t('confirmCloseDialog.title');
            const confirmDialogBody = this.$t('confirmCloseDialog.body');
            const confirmDialogConfirmButtonLabel = this.$t('confirmCloseDialog.confirmButtonLabel');
            const confirmCancelButtonLabel = this.$t('confirmCloseDialog.cancelButtonLabel');

            this.confirmClose(
                () => this.$bus.$emit('POP_NESTED_MODAL', 2), {
                    confirmDialogTitle,
                    confirmDialogBody,
                    confirmDialogConfirmButtonLabel,
                    confirmCancelButtonLabel,
                    destructive: true,
                },
            );
        },

        async openAddContactModal({
            id = null,
            contact = {},
            data = {},
            showRootClose = true,
            useCompanyContext = false,
        } = {}) {
            await this.$store.dispatch('preferences/SAVE_USER_PREFERENCE', { [PREFERENCE_TYPES.ADD_CONTACT_FORM_ID]: id });

            const form = id ? this.getSmartFormById(id) : null;

            return Promise.all([
                (form
                    ? import(/* webpackChunkName: "smartForms" */ '@/smartForms/components/submission/SmartForm')
                        .then(({ default: AddContactForm }) => AddContactForm)
                    : import(/* webpackChunkName: "addContact" */ '@/contacts/components/add/AddContactModal')
                        .then(({ default: AddContactForm }) => AddContactForm)
                ),
                (useCompanyContext
                    ? import(/* webpackChunkName: "addContact" */ '@/contacts/components/add/AddContactTitleCompanyWrapper')
                        .then(({ default: AddContactTitle }) => AddContactTitle)
                    : import(/* webpackChunkName: "addContact" */ '@/contacts/components/add/AddContactTitleContactWrapper')
                        .then(({ default: AddContactTitle }) => AddContactTitle)
                ),
            ]).then(([AddContactForm, AddContactTitle]) => {
                this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', true);

                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: AddContactForm,
                    titleComponent: AddContactTitle,
                    data: {
                        form,
                        contact,
                        ...data,
                    },
                    showRootClose,
                    modalSize: 'med',
                    interceptClose: this.confirmAddContactModalClose,
                });
            });
        },

        async addAppointmentType() {
            if (this.apptTypes.length === 0) {
                connectToProvider({ bus: this.$bus, router: this.$router, store: this.$store });
            }
        },

        viewContact({
            id,
            tab,
        }) {
            this.$router.push({
                name: 'contact.record',
                params: {
                    id: 'all',
                    contactId: id,
                    tab,
                },
            });
        },

        updateFavicon() {
            this.$store.dispatch('UPDATE_FAVICON', { hasBadge: false });
        },
    },
};
</script>

<style lang="scss">
main {
    --top-nav-height: 3.5rem;
    --side-nav-width: 3.5rem;
}

main.nav-open {
    --side-nav-width: 14rem;
}

@media ($medium) {
    main.nav-redesign {
        --top-nav-height: 0rem; // This must have rem unit because calc(100vh - 0) is not valid and will be ignored
        --side-nav-width: #{px-to-rem(100)};
    }

    main.nav-redesign.nav-expanded {
        --side-nav-width: #{px-to-rem(360)};
    }
}
</style>

<style lang="scss" scoped>
    main {
        overflow-x: hidden;

        @media print {
            overflow-x: visible;
        }

        .content {
            height: 100vh;
            width: 100vw;
        }

        &.show-nav .content {
            padding-top: var(--top-nav-height);
        }

        @media ($medium) {
            &.show-nav .content {
                @include transition(padding);
                @include padding-start(var(--side-nav-width));
            }
        }

        &.hide-header-mobile {
            @media($extra-small) {
                .nav-header {
                    display: none;
                }

                .content {
                    padding: 0;
                }
            }
        }
    }

    .page-overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

<i18n>
{
    "en-us": {
        "confirmCloseDialog": {
            "title": "Leave and discard?",
            "body": "All of the changes you made will be lost.",
            "confirmButtonLabel": "Leave and discard",
            "cancelButtonLabel": "Go back"
        }
    }
}
</i18n>
