import {
    FORM_AUDIENCE_PUBLIC,
    FORM_STATUSES,
    INPUT_OPTIONS_TYPE_COUNTRY,
    INPUT_TYPE_ADDRESS_GROUP,
    INPUT_TYPE_CHECKBOX,
    INPUT_TYPE_COMPANY,
    INPUT_TYPE_CREDIT_CARD,
    INPUT_TYPE_CURRENCY,
    INPUT_TYPE_DATE,
    INPUT_TYPE_DECIMAL,
    INPUT_TYPE_EMAIL,
    INPUT_TYPE_MULTISELECT,
    INPUT_TYPE_NUMBER,
    INPUT_TYPE_PHONE,
    INPUT_TYPE_PHONE_GROUP,
    INPUT_TYPE_RADIO,
    INPUT_TYPE_SELECT,
    INPUT_TYPE_TEXT,
    INPUT_TYPE_TEXT_AREA,
    INPUT_TYPE_URL,
    INPUT_TYPE_YEAR,
} from '@/customForms/customForm.constants';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';
import { convert as slugify } from 'url-slug';

export const KEAP_CUSTOM_LINK_TYPES = {
    marketingPage: 'marketingPage',
    marketingSite: 'marketingSite',
    keapCheckoutForm: 'checkoutForm',
    keapAppointmentType: 'appointmentType',
};

export const KEAP_TOOLS = {
    keapForm: 'keap#form',
    unlayerForm: 'form',
};

export const TEMPLATE_FORM = {
    TemplateFormType: 'WEB',
    TemplateFormSource: 'SMART',
};

export const KEAP_TOOLS_FORM_COUNTER_KEY = `u_content_custom_${KEAP_TOOLS.keapForm}`;

export const BLANK_DESIGN = {
    location: { collection: 'bodies', id: '2' },
    counters: {
        u_column: 2,
        u_row: 2,
        u_content_image: 1,
    },
    body: {
        rows: [
            {
                cells: [
                    1,
                ],
                columns: [
                    {
                        contents: [],
                        values: {
                            backgroundColor: '',
                            padding: '0px',
                            border: {},
                            _meta: {
                                htmlID: 'u_column_2',
                                htmlClassNames: 'u_column',
                            },
                        },
                    },
                ],
                values: {
                    displayCondition: null,
                    columns: false,
                    backgroundColor: '',
                    columnsBackgroundColor: '',
                    backgroundImage: {
                        url: '',
                        fullWidth: true,
                        repeat: false,
                        center: true,
                        cover: false,
                    },
                    padding: '0px',
                    _meta: {
                        htmlID: 'u_row_2',
                        htmlClassNames: 'u_row',
                    },
                    selectable: true,
                    draggable: true,
                    duplicatable: true,
                    deletable: true,
                },
            },
        ],
        values: {
            backgroundColor: '#e7e7e7',
            backgroundImage: {
                url: '',
                fullWidth: true,
                repeat: false,
                center: true,
                cover: false,
            },
            contentWidth: '1024px',
            contentAlign: 'center',
            fontFamily: {
                label: 'Arial',
                value: 'arial,helvetica,sans-serif',
            },
            linkStyle: {
                body: true,
                linkColor: '#0000ee',
                linkHoverColor: '#0000ee',
                linkUnderline: true,
                linkHoverUnderline: true,
            },
            _meta: {
                htmlID: 'u_body',
                htmlClassNames: 'u_body',
            },
        },
    },
    schemaVersion: 5,
};

/**
 * Creates a default blank unlayer MarketingSite
 * @param name The site name
 * @return {CreateMarketingSiteInput}
 */
export function createBlankSiteUnlayerSiteTemplate({
    name,
    defaultSiteName,
    defaultLandingPageName,
    defaultThankYouPageName,
}) {
    name = name || defaultSiteName;

    return {
        slug: slugify(name),
        name,
        status: SITE_STATUS.DRAFT,
        pages: [
            {
                name: defaultLandingPageName,
                slug: slugify(defaultLandingPageName),
                content: {
                    design: BLANK_DESIGN,
                    html: '<html></html>',
                },
            },
            {
                name: defaultThankYouPageName,
                slug: slugify(defaultThankYouPageName),
                content: {
                    design: BLANK_DESIGN,
                    html: '<html></html>',
                },
            },

        ],
    };
}

export const FORM_TYPE_LANDING_PAGE = 'LandingPage';
export const DEFAULT_LANDINGPAGE_FORM = {
    formType: FORM_TYPE_LANDING_PAGE,
    description: '',
    status: FORM_STATUSES.NOT_PUBLISHED,
    audience: FORM_AUDIENCE_PUBLIC,
    usesCustomFieldDataFormFieldId: true,
};

const INPUT_TYPE_YESNO = 'YesNo';
const INPUT_TYPE_DAY_OF_WEEK = 'DayOfWeek';
const INPUT_TYPE_NUMBER_DECIMAL = 'NumberDecimal';
const INPUT_TYPE_STATE = 'State';
const INPUT_TYPE_PERCENT = 'Percent';

export const UNLAYER_INPUT_TYPES = {
    DROPDOWN: 'dropdown',
    CHECKBOX: 'checkbox',
    BOOLEAN: 'boolean',
    TEXTAREA: 'textarea',
    DATE: 'date',
    NUMBER: 'number',
    RADIO: 'radio',
    TEXT: 'text',
    DATETIME: 'datetime',
    EMAIL: 'email',
    PHONE: 'tel',
};

/**
 * Maps keap custom input types to their corresponding unlayer types
 */
export const KEAP_INPUT_TYPE_MAPPING = {
    [INPUT_TYPE_SELECT]: UNLAYER_INPUT_TYPES.DROPDOWN,
    [INPUT_TYPE_RADIO]: UNLAYER_INPUT_TYPES.DROPDOWN,
    [INPUT_TYPE_CHECKBOX]: UNLAYER_INPUT_TYPES.CHECKBOX,
    [INPUT_TYPE_TEXT]: UNLAYER_INPUT_TYPES.TEXT,
    [INPUT_TYPE_TEXT_AREA]: UNLAYER_INPUT_TYPES.TEXTAREA,
    [INPUT_TYPE_DATE]: UNLAYER_INPUT_TYPES.DATE,
    [INPUT_TYPE_CURRENCY]: UNLAYER_INPUT_TYPES.NUMBER,
    [INPUT_TYPE_DECIMAL]: UNLAYER_INPUT_TYPES.NUMBER,
    [INPUT_TYPE_EMAIL]: UNLAYER_INPUT_TYPES.EMAIL,
    [INPUT_TYPE_MULTISELECT]: UNLAYER_INPUT_TYPES.CHECKBOX,
    [INPUT_TYPE_NUMBER]: UNLAYER_INPUT_TYPES.NUMBER,
    [INPUT_TYPE_PHONE]: UNLAYER_INPUT_TYPES.TEXT,
    [INPUT_TYPE_URL]: UNLAYER_INPUT_TYPES.TEXT,
    [INPUT_TYPE_YEAR]: UNLAYER_INPUT_TYPES.NUMBER,
    [INPUT_TYPE_YESNO]: UNLAYER_INPUT_TYPES.BOOLEAN,
    [INPUT_TYPE_NUMBER_DECIMAL]: UNLAYER_INPUT_TYPES.NUMBER,
    [INPUT_TYPE_PERCENT]: UNLAYER_INPUT_TYPES.NUMBER,
    // todo Implement all these
    [INPUT_OPTIONS_TYPE_COUNTRY]: null, // should be dropdown but we don't have it working in unlayer
    [INPUT_TYPE_CREDIT_CARD]: null,
    [INPUT_TYPE_DAY_OF_WEEK]: null,
    [INPUT_TYPE_STATE]: null,
    [INPUT_TYPE_COMPANY]: null,
    [INPUT_TYPE_PHONE_GROUP]: null,
    [INPUT_TYPE_ADDRESS_GROUP]: null,
};
