import moment from 'moment';
import { normalizePhoneNumber } from '@/communication/utils/sms-utils';
import {
    ERROR_MESSAGE_STATUS,
    SUPPORTED_COUNTRIES,
} from '@/communication/communication.constants';
import { FF_KEAP_KBL_IN_CANADA } from '@/shared/constants/featureFlag.constants';

export default {
    showCommunicationCenter(state, getters, rootState) {
        const { appCountryCode } = rootState.global;
        const code = appCountryCode || '';
        const supportedCountries = [...SUPPORTED_COUNTRIES];

        if (rootState.featureFlags[FF_KEAP_KBL_IN_CANADA]) {
            supportedCountries.push('CA');
        }

        return supportedCountries.includes(code.toUpperCase());
    },

    activeConversation: (state) => (phoneNumber) => {
        return getActiveConversation(state, phoneNumber);
    },

    activeConversationMessageGroups: (state) => (phoneNumber) => {
        const conversation = getActiveConversation(state, phoneNumber);

        if (conversation == null) {
            return [];
        }

        const { messages } = conversation;

        messages.sort((a, b) => {
            const aMoment = moment(a.createTime);
            const bMoment = moment(b.createTime);

            if (aMoment > bMoment) {
                return -1;
            }

            if (aMoment < bMoment) {
                return 1;
            }

            return 0;
        });

        return messages
            .reduce(groupByTimeAndSender, [])
            .reduce(groupByDay, []);
    },

    conversationsByUpdateTime: ({ sms: { conversations } }) => {
        return Object.values(conversations).sort((a, b) => {
            return new Date(b.lastMessageTime || b.updateTime) - new Date(a.lastMessageTime || a.updateTime);
        });
    },

    encodedFile: ({ sms: { drafts } }) => (phoneNumber) => {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);
        const draft = drafts[normalizedPhone];

        if (draft == null) {
            return Promise.resolve(null);
        }

        const { uploadedFile } = draft;

        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = (e) => {
                if (reader.error || !reader.result) {
                    reject(e);
                } else {
                    resolve(reader.result);
                }
            };

            reader.readAsDataURL(uploadedFile);
        });
    },

    hasUnreadMessages: ({ sms: { conversations } }) => {
        return Object.values(conversations).some(({ unreadCount }) => unreadCount > 0);
    },

    isExistingConversation: (state) => (phoneNumber) => {
        return getActiveConversation(state, phoneNumber) != null;
    },

    lastUpdated: ({ sms: { conversations } }) => {
        let lastUpdateTime = null;

        Object.entries(conversations).forEach(([_, conversation]) => { // eslint-disable-line no-unused-vars
            if (!lastUpdateTime) {
                lastUpdateTime = conversation.updateTime;
            } else if (moment(conversation.updateTime).isBefore(moment(lastUpdateTime))) {
                lastUpdateTime = conversation.updateTime;
            }
        });

        return lastUpdateTime;
    },

    totalUnreadCount: ({ sms: { conversations } }) => {
        return Object.values(conversations).reduce((total, { unreadCount }) => {
            return unreadCount > 0 ? total + unreadCount : total;
        }, 0);
    },

    draftByPhoneNumber: ({ sms: { drafts } }) => (phoneNumber) => {
        const normalizedPhone = normalizePhoneNumber(phoneNumber);

        return drafts[normalizedPhone];
    },
};

const SPLIT_MESSAGE_SECONDS = 60;

const getActiveConversation = ({ sms: { conversations } }, phoneNumber) => {
    const normalizedPhone = normalizePhoneNumber(phoneNumber);

    return conversations[normalizedPhone];
};

const groupByTimeAndSender = (accumulator, currentMessage) => {
    const { body, ...currentProps } = currentMessage;

    if (accumulator[0] == null) {
        return [{ ...currentProps, messages: [currentMessage] }];
    }

    const lastMessage = accumulator[accumulator.length - 1];

    const currentCreateTime = moment(currentMessage.createTime);
    const lastCreateTime = moment(lastMessage.createTime);
    const isWithinBreakpoint = lastCreateTime.diff(currentCreateTime, 'seconds') <= SPLIT_MESSAGE_SECONDS;
    const isSentBySamePerson = currentMessage.outgoing === lastMessage.outgoing;
    const hasError = currentMessage.status === ERROR_MESSAGE_STATUS || lastMessage.status === ERROR_MESSAGE_STATUS;
    const shouldSplit = !isSentBySamePerson || !isWithinBreakpoint || hasError;

    let messageGroup;

    if (shouldSplit) {
        messageGroup = { ...currentProps, messages: [currentMessage] };
    } else {
        const { body: lastBody, messages: lastMessages, ...lastProps } = accumulator.pop();

        messageGroup = { ...lastProps, messages: [currentMessage, ...lastMessages] };
    }

    return [...accumulator, messageGroup];
};

const groupByDay = (accumulator, current) => {
    const startOfCurrentDay = moment(current.createTime).startOf('day');

    if (accumulator.length === 0) {
        return [{ day: startOfCurrentDay, data: [current] }];
    }

    const lastDay = accumulator[0].day;
    const isNewDay = moment(lastDay).diff(startOfCurrentDay) > 0;

    if (isNewDay) {
        return [{ day: startOfCurrentDay, data: [current] }, ...accumulator];
    }

    const last = accumulator.shift();

    return [{ day: last.day, data: [current, ...last.data] }, ...accumulator];
};
