import moment from 'moment';
import groupBy from 'lodash.groupby';

export default {
    tasksByDate({ tasks }) {
        if (tasks && tasks.length) {
            const yesterday = moment().subtract(1, 'days').startOf('day').toDate();
            const today = moment();

            return groupBy(tasks, ({ dueDate }) => {
                const momentDate = moment(new Date(dueDate));
                const diff = momentDate.diff(today, 'days');

                return (diff < 0) ? yesterday : momentDate.startOf('day').toDate();
            });
        }

        return {};
    },
};
