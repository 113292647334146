import axios from 'axios';

import {
    addCompany, assignCompany, updateCompany, transferContacts, getCompanies,
} from './companies';
import {
    fetchContactListTotalCount,
    fetchContactLists,
} from './list';
import {
    fetchContactTags,
    addContactTags,
    removeContactTag,
    addTagToContacts,
    removeTagFromContacts,
} from './tags';


const patchContact = ({ id, ...contactToUpdate }) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/${id}`, contactToUpdate);
};

export {
    fetchContactListTotalCount,
    fetchContactLists,
    fetchContactTags,
    addContactTags,
    removeContactTag,
    addTagToContacts,
    removeTagFromContacts,
    patchContact,
    updateCompany,
    addCompany,
    assignCompany,
    transferContacts,
    getCompanies,
};
