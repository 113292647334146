import moment from 'moment';
import { DataGroupingType } from '@/reports/reports.constants';

export const calculateInterval = (days) => {
    const todayInterval = days === 1;
    const startDays = todayInterval ? 0 : days;
    const previousDays = todayInterval ? 1 : days * 2;

    const today = moment().endOf('day');
    const start = moment().startOf('day').subtract(startDays, DataGroupingType.Days);
    const previous = moment().startOf('day').subtract(previousDays, DataGroupingType.Days);

    return {
        endDateUtc: today.toISOString(),
        startDateUtc: start.toISOString(),
        previousPeriodStartUtc: previous.toISOString(),
        numberOfDays: days,
        dataGrouping: {
            type: todayInterval ? DataGroupingType.Hours : DataGroupingType.Days,
            amount: 1,
        },
    };
};
