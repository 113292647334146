<template lang="html">
    <div>
        <slot name="header" />

        <slot name="description" />

        <form
            v-if="canAddFields"
            ref="form"
            class="add-custom-field"
            novalidate
            @input="handleFormInput"
            @submit.prevent="submit"
        >
            <ds-input-field
                ref="fieldName"
                v-model="customField.name"
                type="text"
                required
                autofocus
                :label="$t('fieldName')"
                :maxlength="$options.CUSTOM_FIELD_NAME_MAX_LENGTH"
                :submitted="submitted || isNameDuplicate"
                :invalid="!submitted && isNameDuplicate"
                data-qa="field-name"
            >
                <template #help>
                    <small class="name-length">
                        {{ customfieldNameLength }}/{{ $options.CUSTOM_FIELD_NAME_MAX_LENGTH }}
                    </small>
                </template>

                <template #error>
                    <small v-if="isNameDuplicate" data-qa="duplicate-error">
                        {{ $t('duplicateFieldNameError' ) }}
                    </small>
                </template>
            </ds-input-field>

            <ds-multiselect
                v-model="customField.type"
                label-prop="name"
                value-prop="id"
                bind-value-only
                required
                searchable
                :placeholder="$t('fieldType')"
                :options="filteredFieldTypes"
                :submitted="submitted"
                data-qa="field-type"
                @input="resetCustomFieldValues"
            />

            <span class="help" @click="helpLink">
                {{ $t('helpText') }}
            </span>

            <section v-if="hasOptions">
                <h6 class="semibold">
                    {{ $t('fieldOptions.title') }}
                </h6>

                <div class="field-options">
                    <ds-text-area-field
                        v-model="customField.values"
                        data-qa="custom-field-values"
                        required
                        :label="$t('fieldOptions.label')"
                        :submitted="submitted"
                        @input="updateCustomFieldValues"
                    />

                    <small>{{ $t('fieldOptions.helpText') }}</small>

                    <div v-if="hasSettings" class="field-settings">
                        <!-- eslint-disable vue/no-v-html -->
                        <p v-html="$t('fieldSettings.title')" />
                        <!-- eslint-enable vue/no-v-html -->

                        <ds-checkbox
                            v-model="customField.defaultToFirstOption"
                            :label="$t('fieldSettings.label')"
                        />

                        <small>{{ $t('fieldSettings.helpText') }}</small>
                    </div>
                </div>
            </section>

            <div v-if="isCustomFieldSettingsEnabled" class="always-shown">
                <div class="advanced">
                    <b>{{ $t('advanced') }}</b>
                </div>

                <ds-checkbox
                    v-model="customField.alwaysShown"
                    :label="$t('customFields.alwaysShown.label')"
                    data-qa="always-shown"
                />

                <p class="caption">
                    {{ $t(`customFields.alwaysShown.description.${recordType}`) }}
                </p>
            </div>

            <div class="button-row">
                <ds-filled-button
                    :loading="loading"
                    :disabled="disabled"
                    type="submit"
                    data-qa="submit-button"
                >
                    {{ $t('saveLabel') }}
                </ds-filled-button>

                <ds-text-button
                    :disabled="loading"
                    data-qa="cancel-button"
                    @click="back"
                >
                    {{ $t('global.cancel') }}
                </ds-text-button>
            </div>
        </form>

        <div v-else class="button-row">
            <ds-filled-button @click="back">
                {{ $t('gotIt') }}
            </ds-filled-button>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import redirectMixin from '@/shared/mixins/redirect.mixin';
import contactFieldTypes from './contactFieldTypes';
import { trackCustomFieldCreated } from '@/contacts/analytics';
import { CUSTOM_FIELD_TYPES } from '@/customFields/customFields.constants.js';
import { FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS } from '@/shared/constants/featureFlag.constants';

const RESERVED_WORD = 'id';
const CUSTOM_FIELD_NAME_MAX_LENGTH = 64;

export default {
    CUSTOM_FIELD_NAME_MAX_LENGTH,

    mixins: [redirectMixin],

    props: {
        recordType: {
            type: String,
            default: 'contact',
        },
        standalone: Boolean,
        fieldName: String,
        fieldCount: Number,
        fieldMax: Number,
        createCustomField: Function,
        eventSource: {
            type: String,
            required: true,
        },
        existingFields: {
            type: Array,
            default: () => [],
        },
        existingFieldNameProp: {
            type: String,
            default: 'label',
        },
    },

    data() {
        return {
            nestedModal_title: this.$t('title'),
            customField: {},
            groupId: null,
            loading: false,
            submitted: false,
            fieldTypes: CUSTOM_FIELD_TYPES.map((fieldType) => ({
                id: fieldType,
                name: this.$t(`customFields.fieldTypes.${fieldType}`),
            })),
        };
    },

    mounted() {
        if (this.standalone && this.fieldName) {
            const trimmedFieldName = this.fieldName.replace(/_/g, '').toLowerCase().trim();

            const name = trimmedFieldName === RESERVED_WORD
                ? 'Contact ID'
                : this.fieldName;

            this.customField = {
                name,
                type: 'Text',
                values: null,
            };
        }
    },

    computed: {
        ...mapState({
            fieldGroups: ({ contacts }) => contacts.fieldGroups,
            isCustomFieldSettingsEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS],
        }),

        customfieldNameLength() {
            return this.customField.name?.length || 0;
        },

        canAddFields() {
            return this.fieldCount < this.fieldMax;
        },

        isNameDuplicate() {
            if (this.customField.name == null) {
                return false;
            }

            return this.existingFields.some(({ [this.existingFieldNameProp]: name }) => name.match(this.getCustomFieldPattern));
        },

        getCustomFieldPattern() {
            const customFieldName = this.customField.name.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const customFieldPattern = new RegExp(`^${customFieldName}$`, 'i');

            return customFieldPattern;
        },

        disabled() {
            return this.customField.name === '' || this.isNameDuplicate || (this.hasOptions && this.isCustomFieldValuesEmpty);
        },

        isCustomFieldValuesEmpty() {
            return this.customField.values === '' || this.customField.values == null;
        },

        customFieldsAvailable() {
            return this.fieldMax - this.fieldCount;
        },

        hasOptions() {
            const { customField } = this;

            return customField.type && contactFieldTypes.CUSTOM[customField.type]
                ? contactFieldTypes.CUSTOM[customField.type].hasOptions
                : false;
        },

        hasSettings() {
            const { customField: { type } } = this;

            return type
                ? contactFieldTypes.CUSTOM[type].hasSettings
                : false;
        },

        filteredFieldTypes() {
            return this.fieldTypes.filter(({ id }) => id !== 'User');
        },
    },

    methods: {
        handleFormInput() {
            const isFormDirty = this.customField.name !== '' || (this.hasOptions && !this.isCustomFieldValuesEmpty);

            this.$emit('contentUpdated', isFormDirty);
        },

        nestedModal_open({ groupId, fieldName }) {
            this.groupId = groupId;

            if (fieldName) {
                this.nestedModal_title = this.$t('titleWithFieldName', { fieldName });
            }

            this.nestedModal_reset();

            this.$nextTick(() => {
                this.$refs.fieldName.input_focus();
            });
        },

        back(e) {
            if (this.standalone && e) {
                this.$emit('cancel');
            }

            if (!this.standalone) {
                this.$bus.$emit('POP_NESTED_MODAL');
            }
        },

        submit() {
            this.submitted = true;

            const additionalInfoGroup = this.fieldGroups.find(({ name }) => {
                const groupName = name.toLowerCase();

                return ['additional info', 'custom fields'].includes(groupName);
            });

            const standaloneGroupId = additionalInfoGroup?.id || this.fieldGroups[this.fieldGroups.length - 1]?.id;

            const groupId = this.standalone && standaloneGroupId
                ? standaloneGroupId
                : this.groupId;

            const newCustomField = { ...this.customField };

            if (this.hasOptions) {
                newCustomField.values = newCustomField.values.length
                    ? newCustomField.values.replace(/,/g, '').trim().split('\n').filter((value) => value.length > 0)
                    : [];
            }

            const payload = {
                customField: newCustomField,
                groupId,
            };

            if (this.$refs.form.checkValidity() && this.customField.type && !this.isNameDuplicate) {
                this.loading = true;

                const trimmedFieldName = this.customField.name.replace(/_/g, '').toLowerCase().trim();

                if (trimmedFieldName === RESERVED_WORD) {
                    this.loading = false;

                    this.$refs.fieldName.input_focus();

                    return this.$error({
                        message: this.$t('reservedName'),
                        bottom: true,
                    });
                }

                const successMessage = this.$t('success');
                const errorMessage = this.$t('error');

                return this.createCustomField(payload)
                    .then((field) => {
                        this.$emit('added', field);
                        this.$toast({ message: successMessage });

                        trackCustomFieldCreated({
                            recordType: this.recordType,
                            eventSource: this.eventSource,
                            fieldType: this.$t(`fieldTypes.${this.customField.type}`),
                        });

                        this.back();
                        this.loading = false;
                    })
                    .catch(() => {
                        this.$error({
                            message: errorMessage,
                            bottom: true,
                        });
                        this.loading = false;
                    });
            }

            return Promise.resolve();
        },

        helpLink() {
            this.redirect_url_new_tab(`${process.env.VUE_APP_KNOWLEDGEOWL_URL}/help/custom-field-types`);
        },

        nestedModal_reset() {
            this.submitted = false;

            this.customField = {
                name: '',
                type: '',
                values: null,
            };
        },

        resetCustomFieldValues() {
            if (!this.hasOptions) {
                delete this.customField.values;
            }
        },

        updateCustomFieldValues(values) {
            if (this.hasOptions && values) {
                this.customField.values = values;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .multiselect {
        --input-margin-bottom: 0;
    }

    .help {
        color: $color-blue;
        font-size: $font-size-xsmall;
        cursor: pointer;
    }

    .name-length {
        display: block;
        text-align: right;
    }

    h6.semibold {
        margin: 0 0 $gp;
    }

    .field-options {
        --input-margin-bottom: #{$gp / 2};
        background: $color-gray-050;
        border-radius: $border-radius;
        border: 1px solid $color-gray-400;
        padding: $gp;

        small {
            color: $color-gray-800;
        }
    }

    .field-settings {
        --checkbox-padding: 0;
        margin-top: $gp;

        small {
            display: block;
            margin: 0 $gp * 2;
        }
    }

    .add-custom-field {
        display: grid;
        grid-template-columns: auto;
        grid-gap: $gp * 1.5;
        --input-margin-bottom: 0;
        grid-template-rows: $gp * 2.5 $gp * 1.4 $gp;
    }

    .always-shown {
        padding: $gp 0 $gp / 2 0;
        border-bottom: 1px solid $color-gray-200;
        border-top: 1px solid $color-gray-200;

        .caption {
            @include padding-start(#{$gp * 2.25});
            color: $color-ink-800;
            padding-left: $gp * 1.8;
            padding-bottom: $gp / 4;
            margin-bottom: $gp / 2;
        }

        .checkbox {
            padding-left: 0;
            padding-bottom: $gp / 2;
        }
    }

    .advanced {
        color: $color-gray-900;
        font-size: $font-size-md;
        font-weight: $font-weight-semibold;
        padding-bottom: $gp / 4;
    }
</style>

<i18n>
{
    "en-us": {
        "duplicateFieldNameError": "This field name is already being used",
        "title": "Add a custom field",
        "titleWithFieldName": "Add a custom field - {fieldName}",
        "fieldName": "Field name",
        "fieldType": "Field type",
        "helpText": "Which type of field should I use?",
        "saveLabel": "Create field",
        "error": "There was an error creating the custom field",
        "reservedName": "ID is a reserved field name. Please choose another name for your custom field.",
        "success": "Custom field created",
        "fieldOptions": {
            "title": "Build your field",
            "label": "Field Options",
            "helpText": "Enter one option per line. Field options will be displayed as a list in the order you create them."
        },
        "fieldSettings": {
            "title": "<strong>Settings</strong> (Optional)",
            "label": "Display the first listed option as the default selection.",
            "helpText": "(If unchecked, the default selection will display the field title, such as “office location.“)"
        },
        "gotIt": "Ok, got it",
        "advanced": "Advanced"
    }
}
</i18n>
