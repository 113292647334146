import { orderStatus } from '@/money/constants/order-status';

export default {
    SET_PAYMENTS(state, payments) {
        state.payments = payments;
    },

    SET_RECURRING_PAYMENTS(state, payments) {
        state.recurringPayments = payments;
    },

    SET_HISTORICAL_RECURRING_PAYMENTS(state, payments) {
        state.historicalRecurringPayments = payments;
    },

    SET_INVOICES(state, invoices) {
        state.invoices = invoices;
    },

    SET_SELECTED_UPSELL(state, selectedUpsell) {
        state.selectedUpsell = {
            ...selectedUpsell,
            productName: selectedUpsell.upsellName,
            productDesc: selectedUpsell.upsellDescription,
            productPrice: parseFloat(selectedUpsell.upsellPrice),
            recurringBilling: selectedUpsell.recurring,
        };
    },

    CLEAR_SELECTED_UPSELL(state) {
        state.selectedUpsell = null;
    },

    SET_CHECKOUT_FORMS(state, checkoutForms) {
        state.checkoutForms = checkoutForms;
    },

    SET_ALL_INVOICES(state, invoices) {
        state.allInvoices = invoices;
    },

    SET_RECENT_INVOICES(state, invoices) {
        state.recentInvoices = invoices;
    },

    SET_RECENT_INVOICES_DIRTY(state, flag) {
        state.recentInvoicesDirty = flag;
    },

    SET_APP_SALES_INFO(state, appSalesInfo) {
        state.appSalesInfo = appSalesInfo;
    },

    ADD_INVOICE(state, invoice) {
        state.invoices.splice(0, 0, invoice);
        state.allInvoices.splice(0, 0, invoice);
    },

    ADD_QUOTE(state, quote) {
        state.quotes.splice(0, 0, quote);
        state.allQuotes.splice(0, 0, quote);
    },

    SET_QUOTES(state, quotes) {
        state.quotes = quotes;
    },

    SET_ALL_QUOTES(state, quotes) {
        state.allQuotes = quotes;
    },

    SET_INVOICE(state, invoice) {
        state.invoice = invoice;
    },

    SET_QUOTE(state, invoice) {
        state.quote = invoice;
    },

    SET_INVOICE_FTU(state, ftu) {
        state.invoiceFtu = ftu;
    },

    SET_QUOTE_FTU(state, ftu) {
        state.quoteFtu = ftu;
    },

    SET_PUBLIC_INVOICE(state, publicInvoice) {
        state.publicInvoice = publicInvoice;
    },

    SET_PUBLIC_QUOTE(state, publicQuote) {
        state.publicQuote = publicQuote;
    },

    SET_QUOTE_EDITING(state, editing) {
        state.editingQuote = editing;
    },

    SET_INVOICE_EDITING(state, editing) {
        state.editingInvoice = editing;
    },

    SET_ALL_RECURRING_PAYMENTS(state, payments) {
        state.allRecurringPayments = payments;
    },

    ADD_RECURRING_PAYMENT(state, payment) {
        state.allRecurringPayments.unshift(payment);
    },

    SET_RECURRING_PAYMENT_HISTORY(state, history) {
        state.recurringPaymentHistory = history;
    },

    SET_PAYPAL_MERCHANT(state, id) {
        state.paypalMerchant = id;
    },

    SET_PAYMENT_SUBTYPE(state, paymentSubtype) {
        state.paymentSubtype = paymentSubtype;
    },

    SET_PUBLIC_MONEY_FLAGS(state, flags) {
        state.publicMoneyFlags = flags;
    },

    SET_SALES_TOTAL(state, salesTotal) {
        state.salesTotal = salesTotal;
    },

    ADD_INVOICE_LIST_ITEM(state, { orderItem }) {
        if (state.invoice && state.invoice.orderItems) {
            state.invoice.orderItems.push(orderItem);
        } else if (state.invoice && orderItem && (!state.invoice.lineItems || !state.invoice.orderItems.length)) {
            state.invoice.orderItems = [orderItem];
        }
    },

    DELETE_INVOICE(state, orderId) {
        const index = state.invoices.findIndex(({ id }) => id === orderId);

        if (index >= 0) {
            state.invoices.splice(index, 1);
        }

        const allIndex = state.allInvoices.findIndex(({ id }) => id === orderId);

        if (allIndex >= 0) {
            state.allInvoices.splice(allIndex, 1);
        }
    },

    DELETE_QUOTE(state, orderId) {
        const index = state.quotes.findIndex(({ id }) => id === orderId);

        if (index >= 0) {
            state.quotes.splice(index, 1);
        }

        const allIndex = state.allQuotes.findIndex(({ id }) => id === orderId);

        if (allIndex >= 0) {
            state.allQuotes.splice(allIndex, 1);
        }
    },

    DELETE_INVOICE_LIST_ITEM(state, { index }) {
        if (state.invoice && state.invoice.orderItems && state.invoice.orderItems[index]) {
            state.invoice.orderItems.splice(index, 1);
        }
    },

    SET_INVOICE_LIST_ITEM(state, { index, orderItem }) {
        if (state.invoice && state.invoice.orderItems) {
            state.invoice.orderItems.splice(index, 1, orderItem);
        }
    },

    SYNC_INVOICES(state) {
        const {
            invoices, invoice, allInvoices, recentInvoices,
        } = state;

        syncList(invoices, invoice);
        syncList(allInvoices, invoice);
        syncList(recentInvoices, invoice);
    },

    SYNC_QUOTES(state) {
        const { quotes, quote, allQuotes } = state;

        syncList(quotes, quote);
        syncList(allQuotes, quote);
    },

    SET_QUOTE_ORDER_ID(state, { quoteId, orderId }) {
        if (state.quote && state.quote.id === quoteId) {
            state.quote.orderId = orderId;
        }
    },

    SET_QUOTE_SENT(state, quoteId) {
        if (state.quote && state.quote.id === quoteId && state.quote.status !== orderStatus.ACCEPTED) {
            state.quote.status = orderStatus.SENT;
        }

        if (state.allQuotes) {
            const listQuote = state.allQuotes.find((quote) => quote.id === quoteId && quote.status !== orderStatus.ACCEPTED);

            if (listQuote) {
                listQuote.status = orderStatus.SENT;
            }
        }
    },

    SET_INVOICE_SENT(state, invoiceId) {
        if (state.invoice && state.invoice.id === invoiceId && state.invoice.status === orderStatus.DRAFT) {
            state.invoice.status = orderStatus.SENT;
        }

        if (state.allInvoices) {
            const listInvoice = state.allInvoices.find((invoice) => invoice.id === invoiceId && invoice.status === orderStatus.DRAFT);

            if (listInvoice) {
                listInvoice.status = orderStatus.SENT;
            }
        }

        if (state.recentInvoices) {
            const recentInvoice = state.recentInvoices.find((invoice) => invoice.id === invoiceId && invoice.status === orderStatus.DRAFT);

            if (recentInvoice) {
                recentInvoice.status = orderStatus.SENT;
            }
        }
    },

    SET_INVOICE_ATTACHMENTS(state, { data, invoice }) {
        state.invoice = invoice;
        state.invoice.files = data.filter((file) => invoice.attachments.indexOf(file.id) !== -1);
    },

    SET_QUOTE_ATTACHMENTS(state, { data, quote }) {
        state.quote = quote;
        state.quote.files = data.filter((file) => quote.attachments.indexOf(file.id) !== -1);
    },

    SET_CHECKOUT_DISCOUNTS(state, checkoutDiscounts) {
        state.checkoutDiscounts = checkoutDiscounts;
    },

    SET_APPLIED_PROMO_CODES(state, appliedPromoCodes) {
        state.appliedPromoCodes = appliedPromoCodes;
    },

    CLEAR_APPLIED_PROMO_CODES(state) {
        state.appliedPromoCodes = null;
    },
};

const syncList = (list, listItem) => {
    if (list && listItem) {
        const index = list.findIndex(({ id }) => listItem.id === id);

        if (index >= 0) {
            list.splice(index, 1, listItem);
        }
    }
};
