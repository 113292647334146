import moment from 'moment';

export default {
    methods: {
        getCollisionGroups(appts) {
            const groups = [];

            for (let i = 0; i < appts.length; i++) {
                const appt = appts[i];

                if (!groups.length) {
                    // first collision group
                    groups.push([appt]);
                } else {
                    // check the groups to see if it fits in
                    // if not, start a new group
                    let groupFound = false;

                    groups.forEach((g, index) => {
                        g.forEach((a) => {
                            if (!groupFound && this.hasCollision(appt, a)) {
                                groups[index].push(appt);
                                groupFound = true;
                            }
                        });
                    });

                    if (!groupFound) {
                        groups.push([appt]);
                    }
                }
            }

            return groups;
        },

        getCollisionMap(appts) {
            // method: adapted version of https://github.com/taterbase/calendar-puzzle
            const map = [];
            const groups = this.getCollisionGroups(appts);

            groups.forEach((group) => {
                const groupLayout = [];

                group.forEach((appt) => {
                    // check the last column
                    if (groupLayout.length) {
                        const lastCol = groupLayout.length - 1;
                        let placed = false;

                        for (let col = 0; col < lastCol; col++) {
                            const checkAppt = groupLayout[col][groupLayout[col].length - 1];

                            if (!placed) {
                                if (!this.hasCollision(appt, checkAppt)) {
                                    // no collision in this column, let's add it
                                    groupLayout[col].push(appt);
                                    placed = true;
                                }
                            }
                        }

                        if (!placed) {
                            // has collisions with all existing columns
                            // create a new column
                            groupLayout.push([appt]);
                        }
                    } else {
                        groupLayout.push([appt]);
                    }
                });

                map.push(groupLayout);
            });

            return map;
        },

        getIntervals(day, intervalMins) {
            const intervals = [];
            const today = moment(day);

            let offset = 0;

            let interval = moment(day).startOf('day');

            while (today.isSame(interval, 'day')) {
                intervals.push(interval);

                offset += intervalMins;
                interval = moment(day).startOf('day').add(offset, 'minutes');
            }

            // add one more interval to span the end of the day
            intervals.push(interval);

            return intervals;
        },

        hasCollision(appt1, appt2) {
            const start1 = moment(appt1.startDate);
            const end1 = moment(appt1.endDate);
            const start2 = moment(appt2.startDate);
            const end2 = moment(appt2.endDate);

            return ((start1.isSameOrBefore(start2, 'second') && end1.isAfter(start2))
                || (start1.isAfter(start2) && start1.isBefore(end2))
                || (start1.isSameOrAfter(start2, 'second') && end1.isSameOrBefore(end2, 'second')));
        },

        isToday(day) {
            return moment().isSame(day, 'day');
        },

        isAllDayAppt(day, appt) {
            const dayStart = moment(day).startOf('day');
            const dayEnd = moment(day).endOf('day');

            const checkStartTime = dayStart.isSameOrAfter(appt.startDate, 'second');
            const checkEndTime = dayEnd.isSameOrBefore(appt.endDate, 'second');

            return checkStartTime && checkEndTime;
        },

        hasAppt(day, appts) {
            return this.countApptsForDay(day, appts) > 0;
        },

        countApptsForDay(day, appts) {
            const dayEvents = this.getApptsForDay(day, appts);

            return dayEvents.length;
        },

        getApptsForDay(day, appts, allday = true) {
            const dayStart = moment(day).startOf('day');
            const dayEnd = moment(day).endOf('day');

            const todaysAppts = appts.filter((val) => {
                const apptStart = moment(val.startDate);
                const apptEnd = moment(val.endDate);

                if (apptStart.isSame(day, 'day')
                    || apptEnd.isSame(day, 'day')
                    || (apptStart.isBefore(dayStart) && apptEnd.isAfter(dayEnd))
                ) {
                    // appointment falls on this day
                    return allday ? true : !this.isAllDayAppt(day, val);
                }

                return false;
            });

            return todaysAppts.sort(((a, b) => {
                if (moment(a.startDate).isAfter(b.startDate)) {
                    return 1;
                }

                if (moment(a.startDate).isBefore(b.startDate)) {
                    return -1;
                }

                return 0;
            }));
        },

        apptTimeFormat(day, time) {
            const date = moment(time);
            const testDate = moment(day);

            if (date.isSame(testDate, 'day')) {
                return date.format('h:mma');
            }

            return date.format('MMM D YYYY, h:mma');
        },

        dateFormatFrom(appt) {
            const start = moment(appt.startDate);

            if (moment(appt.startDate).isSame(appt.endDate, 'day')) {
                return start.format('ddd, MMM D, YYYY');
            }

            return start.format('ddd, MMM D, YYYY h:mma');
        },

        dateFormatTo(appt) {
            const start = moment(appt.startDate);
            const end = moment(appt.endDate);

            const startTime = start.format('h:mma');
            const endTime = end.format('h:mma');

            if (moment(appt.startDate).isSame(appt.endDate, 'day')) {
                return `${startTime} - ${endTime}`;
            }

            return end.format('ddd, MMM D, YYYY h:mma');
        },

        apptLengthFormat(appt) {
            const intervals = [];

            const minDiff = moment(appt.endDate).diff(appt.startDate, 'minutes');

            const duration = moment.duration(minDiff, 'minutes');

            if (duration.years()) {
                intervals.push(this.$i18nInstance.tc('global.time.interval.abbr.year', duration.years(), { count: duration.years() }));
            }

            if (duration.months()) {
                intervals.push(this.$i18nInstance.tc('global.time.interval.abbr.month', duration.months(), { count: duration.months() }));
            }

            if (duration.weeks()) {
                intervals.push(this.$i18nInstance.tc('global.time.interval.abbr.week', duration.weeks(), { count: duration.weeks() }));
            }

            if (duration.days()) {
                intervals.push(this.$i18nInstance.tc('global.time.interval.abbr.day', duration.days(), { count: duration.days() }));
            }

            if (duration.hours()) {
                intervals.push(this.$i18nInstance.tc('global.time.interval.abbr.hour', duration.hours(), { count: duration.hours() }));
            }

            if (duration.minutes()) {
                intervals.push(this.$i18nInstance.tc('global.time.interval.abbr.minute', duration.minutes(), { count: duration.minutes() }));
            }

            return intervals.join(' ');
        },
    },
};
