export default {
    title: '',
    appCountryCode: null,
    appCurrencyCode: 'USD',
    appLoaded: false,
    bootLoadError: false,
    deferredBootLoaded: false,
    publicCoreAppId: '',
    isLocal: false,
    isMobileApp: false,
    locale: null,
    navOpen: true,
    mobileNavOpen: false,
    supportPanelOpen: false,
    globalSearchOpen: false,
    overlaysActive: 0,
    rtl: false,
    companyLogoUrl: '',
    googlePlaceId: null,
    regionOptions: [],
    countryOptions: [],
    timeZoneOptions: [],
    nestedModalStatus: {
        editModeEnabled: false,
        dirty: false,
    },
    confirmNavigationFlags: new Set(),
    logInAsLoadErrors: [],
    cachedRegionOptions: {},
    feedbackModal: {
        isOpen: false,
        feature: '',
    },
    isSideMenuExpanded: false,
};
