<template>
    <configure-step-with-timer
        :value="value"
        :title="documentTitle"
        :has-error="hasError"
        :has-timer-warning="hasTimerWarning"
        :manage-title="$t('manage')"
        :manage-props="manageProps"
        :error-message="errorMessage || $t('error')"
        :hide-edit="Boolean(errorMessage)"
        @edit="(payload) => $emit('edit', payload)"
        @save="(payload) => $emit('save', payload)"
        @remove="$emit('remove')"
        @done="$emit('done')"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import ConfigureStepWithTimer from './ConfigureStepWithTimer';
import { BLENDR_TYPES } from '@/shared/constants/integrations.constants';

export default {
    components: {
        ConfigureStepWithTimer,
    },

    props: {
        value: Object,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        errorMessage: String,
    },

    data() {
        return {
            step: this.value?.step ?? this.value,
            manageProps: {
                confirmTitle: this.$t('manageIntegrations.title'),
                confirmMessage: this.$t('manageIntegrations.message'),
                confirmButtonLabel: this.$t('manageIntegrations.confirm'),
                route: { name: 'settings.integrations', params: { integration: BLENDR_TYPES.HELLO_SIGN } },
            },
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.step = newVal.step ?? newVal;
            },
            deep: true,
        },
    },

    computed: {
        ...mapGetters({
            documentById: 'settings/hellosignDocumentById',
        }),

        document() {
            return this.documentById(this.step?.configJson?.documentId) || null;
        },

        documentTitle() {
            return this.document?.title ?? this.$t('emptyDocumentLabel');
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "manage": "Manage integrations",
        "emptyDocumentLabel": "Send HelloSign document",
        "manageIntegrations": {
            "title": "Navigate to your integrations list?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Manage integrations"
        },
        "error": "Please select a document"
    }
}
</i18n>
