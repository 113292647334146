<template>
    <div class="error-messages">
        <message
            v-for="message in messages"
            :key="message.id"
            :message="message"
        />

        <div v-if="sending" class="sending">
            <ds-icon name="loader" />

            <span class="sending-message">{{ $t('sending') }}</span>
        </div>

        <div v-else class="error-message-bar">
            <ds-icon name="alert-triangle" class="error-icon" />

            <span class="error-message semibold">{{ $t('errorMessage') }}</span>

            <span class="retry" @click="retryErrorMessage">{{ $t('retry') }}</span>

            <span>|</span>

            <span class="delete" @click="deleteErrorMessage">{{ $t('global.delete') }}</span>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import uuid from 'uuid/v4';

import Message from '@/communication/components/Message';
import { ERROR_MESSAGE_STATUS } from '@/communication/communication.constants';
import smsMixins from '@/communication/mixins/sms.mixin';

export default {
    components: {
        Message,
    },

    mixins: [smsMixins],

    props: {
        messages: {
            type: Array,
            required: true,
        },

        messageId: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            sending: false,
        };
    },

    methods: {
        async retryErrorMessage() {
            this.sending = true;
            this.$track('Communication - SMS - Clicked retry failed message');

            const now = moment();
            const phoneNumber = this.sms_activeConversationPhoneNumber;
            const { body, mediaUrls } = (Array.isArray(this.messages) && this.messages[0]) || {};

            const message = {
                createTime: now,
                updateTime: now,
                body,
                outgoing: true,
            };
            const payload = {
                contactData: {
                    contactId: this.contactId,
                    firstName: this.firstName,
                    lastName: this.lastName,
                },
                message,
                phoneNumber,
                mediaUrls,
            };

            await this.$store.dispatch('communication/SEND_MESSAGE', payload)
                .then(() => {
                    this.$track('Communication - SMS - Message sent successfully from retry');
                })
                .catch(() => {
                    message.status = ERROR_MESSAGE_STATUS;
                    message.messageId = uuid();
                    this.$track('Communication - SMS - Sending error');
                    this.$store.commit('communication/UPDATE_CONVERSATION_MESSAGES', { message, phoneNumber });
                });

            this.deleteErrorMessage();
            this.sending = false;
        },

        deleteErrorMessage() {
            const phoneNumber = this.sms_activeConversationPhoneNumber;

            this.$store.commit('communication/DELETE_ERROR_MESSAGE', { messageId: this.messageId, phoneNumber });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .error-messages {
        display: inline-flex;
        flex-direction: column;
        width: auto;
        flex: 1;
        align-items: flex-start;
        padding: 0;
    }

    .sending,
    .error-message-bar {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: $font-size-xs;
        width: 100%;
        --icon-size: #{$gp * .8};
    }

    .sending {
        color: $color-gray-700;

        .icon {
            animation: spin 4s infinite;
        }
    }

    .sending-message {
        @include margin-start($gp / 2);
    }

    .error-message-bar {
        color: $color-gray-600;
        --icon-color: #{$color-red};
    }

    .error-message {
        color: $color-red;
        @include margin-start($gp / 4);
    }

    .retry {
        color: $color-blue;
        cursor: pointer;
        @include margin(0 $gp * .25 0 $gp);
    }

    .delete {
        color: $color-blue;
        cursor: pointer;
        @include margin-start($gp / 4);
    }
</style>

<i18n>
{
    "en-us": {
        "errorMessage": "Message not sent",
        "retry": "Retry",
        "sending": "Sending"
    }
}
</i18n>
