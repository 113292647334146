<template>
    <share-feedback :feature="feature" class="share-feedback-wrapper">
        <template v-slot:default="{openModal}">
            <ds-text-button
                leading-icon="message-square"
                class="share-feedback-button"
                @click="openModal"
            >
                {{ $t('shareFeedback') }}
            </ds-text-button>
        </template>
    </share-feedback>
</template>

<script>
import ShareFeedback from './ShareFeedback';

export default {
    components: {
        ShareFeedback,
    },

    props: {
        feature: String,
    },
};
</script>

<style lang="scss" scoped>
    .share-feedback-button {
        --icon-color: #{$color-ink-900};
        color: $color-ink-900;

        &:hover {
            background-color: $color-ink-200;
            --icon-color: #{$color-ink};
            color: $color-ink;
        }

        &:active {
            background-color: $color-ink-400;
            --icon-color: #{$color-ink};
            color: $color-ink;
        }
    }

    .share-feedback-wrapper {
        margin: $gp auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>

<i18n>
{
    "en-us": {
        "shareFeedback": "Share feedback"
    }
}
</i18n>
