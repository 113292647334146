import {
    FORM_FIELDS,
    GROUPED_FIELDS,
    INTERNAL_CUSTOM_CONTACT_FIELD_TYPES,
    REGION_OPTION_GBR,
    REGION_OPTION_USA,
} from '@/smartForms/smartform.constants';
import {
    COMPOSITE_FIELDS_GROUP_ID,
    CUSTOM_FIELD_GROUP_ID,
    FIELD_GROUP_IDS,
    FORM_AUDIENCE_INTERNAL,
    FORM_ONLY_FIELDS_GROUP_ID,
    FORM_STATUSES,
    STANDARD_FIELDS,
    FORM_AUDIENCE_PUBLIC,
} from '@/customForms/customForm.constants';
import { KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT } from '@/shared/constants/featureFlag.constants';

import orderBy from 'lodash.orderby';

import { convertIso2Code } from 'convert-country-codes';

export default {
    smartForms: ({ forms, smartFormListSortField, smartFormListSortAscending }) => {
        return Array.isArray(forms)
            ? orderBy(forms, smartFormListSortField, smartFormListSortAscending ? 'asc' : 'desc')
            : [];
    },

    getFormById: ({ forms }) => (formId) => {
        return Array.isArray(forms)
            ? forms.find(({ id }) => id === formId)
            : null;
    },

    publishedSmartForms({ forms }) {
        return forms.filter((form) => form.status === FORM_STATUSES.PUBLISHED);
    },

    sortedSmartFormsByTitle: ({ forms }) => {
        const publishedSmartForms = Array.isArray(forms) ? forms.filter((form) => form.status === FORM_STATUSES.PUBLISHED) : [];

        return publishedSmartForms.length !== 0
            ? publishedSmartForms.sort((a, b) => ((a.title.toLowerCase() > b.title.toLowerCase()) ? 1 : -1))
            : publishedSmartForms;
    },

    fieldCountryCode: (state, getters, { global }) => {
        const { appCountryCode } = global;

        if (appCountryCode === 'UK') {
            return REGION_OPTION_GBR;
        }

        const countryCode = convertIso2Code(appCountryCode);

        if (countryCode) {
            return countryCode.iso3;
        }

        return REGION_OPTION_USA;
    },

    smartFormFields(state, { smartFormFieldGroups }) {
        // This is only used by smartForms and can be removed when we merge into customForms
        return smartFormFieldGroups(FORM_AUDIENCE_INTERNAL).reduce((allFields, group) => {
            if (group.id === COMPOSITE_FIELDS_GROUP_ID || group.id === FORM_ONLY_FIELDS_GROUP_ID) {
                return allFields;
            }

            return allFields.concat(group.fields);
        }, []);
    },

    smartFormFieldGroups: (state, _, { contacts, featureFlags }, rootGetters) => (formAudience) => {
        const fieldGroups = [];
        const customFields = [];
        const campaignBuilderEnabled = rootGetters['auth/hasCampaignBuilderFeature'];
        const isCustomFieldTechDebtEnabled = featureFlags[KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT];

        if (isCustomFieldTechDebtEnabled) {
            const formattedCustomFields = contacts.customFields.map(({
                id,
                label,
                fieldType,
                options,
                defaultToFirstOption,
            }) => ({
                id: id.toString(),
                label,
                group: CUSTOM_FIELD_GROUP_ID,
                customField: true,
                // for backwards compatibility
                type: fieldType,
                values: options?.map(({ value }) => value) || [],
                dataFormFieldId: id,
                defaultToFirstOption,
            }));

            customFields.push(...formattedCustomFields);
        } else {
            contacts.fieldGroups.forEach((group) => {
                group.fields.forEach((field) => {
                    if (field.customField && (formAudience === FORM_AUDIENCE_INTERNAL || !INTERNAL_CUSTOM_CONTACT_FIELD_TYPES.includes(field.type))) {
                        customFields.push({
                            id: field.dataFormFieldId?.toString(),
                            label: field.name,
                            group: CUSTOM_FIELD_GROUP_ID,
                            customField: true,
                            // for backwards compatibility
                            type: field.type,
                            values: field.values,
                            dataFormFieldId: field.dataFormFieldId,
                            defaultToFirstOption: field.defaultToFirstOption,
                        });
                    }
                });
            });
        }

        FIELD_GROUP_IDS.forEach((fieldGroupId) => {
            let fields;

            if (fieldGroupId === CUSTOM_FIELD_GROUP_ID) {
                fields = customFields;
            } else if (fieldGroupId === COMPOSITE_FIELDS_GROUP_ID) {
                fields = formAudience === FORM_AUDIENCE_INTERNAL ? GROUPED_FIELDS : [];
            } else if (fieldGroupId === FORM_ONLY_FIELDS_GROUP_ID) {
                fields = campaignBuilderEnabled && formAudience === FORM_AUDIENCE_INTERNAL ? FORM_FIELDS : [];
            } else {
                fields = STANDARD_FIELDS.filter((field) => {
                    if (field.group === fieldGroupId) {
                        // If field does not have the feature flag attribute then there is no flag associated so continue,
                        // otherwise check the flag and make sure it's on

                        if (!field.featureFlag || featureFlags[field.featureFlag] === true) {
                            // show fields only on internal forms if they have this feature flag on
                            if (field.reverseInternalOnlyFeatureFlag && featureFlags[field.reverseInternalOnlyFeatureFlag] === true) {
                                return formAudience === FORM_AUDIENCE_INTERNAL;
                            }

                            if (formAudience === FORM_AUDIENCE_INTERNAL) {
                                return !field.publicOnly;
                            }

                            if (formAudience === FORM_AUDIENCE_PUBLIC) {
                                return !field.internalOnly;
                            }

                            return !field.publicOnly && !field.internalOnly;
                        }
                    }

                    return false;
                });

                fields.forEach((field) => {
                    field.label = `customForms.fields.${field.id}`;
                });
            }

            if (fields.length > 0) {
                fieldGroups.push({
                    id: fieldGroupId,
                    fields,
                    label: `customForms.fieldGroups.${fieldGroupId}`,
                });
            }
        });

        return fieldGroups;
    },
};
