<template>
    <section>
        <h4 class="section-title editing">
            {{ $t('title') }}
        </h4>

        <div class="field-grid editing">
            <ds-input-field
                v-model.trim="contact.firstName"
                data-qa="first-name"
                autocomplete="no"
                :label="$t('forms.firstName')"
                @input="$emit('input')"
            />

            <div v-if="!hiddenFields.includes('middleName')" class="field-type">
                <ds-input-field
                    v-model.trim="contact.middleName"
                    data-qa="middle-name"
                    autocomplete="no"
                    :label="$t('forms.middleName')"
                    @input="$emit('input')"
                />
                <contact-field-remove-button
                    class="remove-button"
                    @action="clearField('middleName')"
                />
            </div>

            <ds-input-field
                v-model.trim="contact.lastName"
                data-qa="last-name"
                autocomplete="no"
                :label="$t('forms.lastName')"
                @input="$emit('input')"
            />

            <div v-if="!hiddenFields.includes('title') || !hiddenFields.includes('suffix')" class="field-grid">
                <div v-if="!hiddenFields.includes('title')" class="field-type">
                    <ds-select-field
                        v-model="contact.title"
                        bind-value-only
                        :label="$t('forms.title')"
                        :options="fieldOptions.title"
                        @input="$emit('input')"
                    />
                    <contact-field-remove-button
                        class="remove-button"
                        @action="clearField('title')"
                    />
                </div>

                <div v-if="!hiddenFields.includes('suffix')" class="field-type">
                    <ds-input-field
                        v-model.trim="contact.suffix"
                        autocomplete="no"
                        :label="$t('forms.suffix')"
                        @input="$emit('input')"
                    />
                    <contact-field-remove-button
                        class="remove-button"
                        @action="clearField('suffix')"
                    />
                </div>
            </div>

            <async-contact-company-dropdown
                v-model="contact.company"
                :companies="fieldOptions.companies"
                :event-source="eventSource"
                :readonly="lockCompany"
                allow-add
            />

            <ds-input-field
                v-model.trim="contact.jobTitle"
                data-qa="job-title"
                autocomplete="no"
                :label="$t('forms.jobTitle')"
                @input="$emit('input')"
            />

            <div v-if="!hiddenFields.includes('birthday')" class="field-type">
                <ds-date-picker
                    v-model="contact.birthday"
                    class="date-picker date-field"
                    :label="$t('forms.birthday')"
                    @input="$emit('input')"
                />

                <contact-field-remove-button
                    class="remove-button"
                    @action="clearField('birthday')"
                />
            </div>

            <div v-if="!hiddenFields.includes('anniversary')" class="field-type">
                <ds-date-picker
                    v-model="contact.anniversary"
                    class="date-picker date-field"
                    :label="$t('forms.anniversary')"
                    @input="$emit('input')"
                />
                <contact-field-remove-button
                    class="remove-button"
                    @action="clearField('anniversary')"
                />
            </div>

            <ds-select-field
                v-if="showOwnerField"
                v-model="contact.owner"
                data-qa="owner"
                bind-value-only
                :label="$t('assignedTo')"
                :options="owners"
                @input="$emit('input')"
            />
        </div>

        <ds-dropdown
            v-if="hiddenFields.length"
            class="dropdown"
            pin-bottom
            @input="$emit('input')"
        >
            <span class="add-field">
                <ds-icon name="add" />
                <span>{{ $t('addField') }}</span>
            </span>

            <template #menu>
                <ul class="dropdown-menu-list">
                    <li
                        v-for="field in hiddenFields"
                        :key="field"
                        class="dropdown-menu-item"
                        data-qa="add-field"
                        @click="showField(field)"
                    >
                        {{ $t(`forms.${field}`) }}
                    </li>
                </ul>
            </template>
        </ds-dropdown>
    </section>
</template>

<script>
import ContactFieldRemoveButton from './ContactFieldRemoveButton';
import dateMixin from '@/appointments/mixins/date.mixin';
import AsyncContactCompanyDropdown from '@/contacts/components/simple/AsyncContactCompanyDropdown';

export default {
    components: {
        AsyncContactCompanyDropdown,
        ContactFieldRemoveButton,
    },

    mixins: [dateMixin],

    props: {
        contact: Object,
        fieldOptions: Object,
        eventSource: {
            type: String,
            default: 'Contact Record',
        },
        lockCompany: Boolean,
    },

    data() {
        return {
            hiddenFields: ['middleName', 'title', 'suffix', 'birthday', 'anniversary'],
        };
    },

    mounted() {
        this.showHiddenFields();
    },

    computed: {
        showOwnerField() {
            return this.owners.length > 1;
        },

        owners() {
            return this.fieldOptions && this.fieldOptions.owners
                ? this.fieldOptions.owners.map(({ label, value }) => {
                    return {
                        label,
                        value: Number(value),
                    };
                })
                : [];
        },
    },

    methods: {
        showField(field) {
            const fieldIndex = this.hiddenFields.indexOf(field);

            if (fieldIndex !== -1) {
                this.hiddenFields.splice(fieldIndex, 1);
            }
        },

        showHiddenFields() {
            this.hiddenFields = this.hiddenFields.filter((field) => !this.contact[field]);
        },

        clearField(field) {
            this.contact[field] = '';
            this.hiddenFields.push(field);
        },

    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
@import "~@/contacts/styles/contact-details";

.add-field {
    margin-top: $gp;
}

.field-type {
    display: flex;
}

.field-grid {
    &.editing {
        grid-template-columns: auto;
    }

    .date-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
    }
}

.remove-button {
    @include margin-start($gp);
}
</style>

<i18n>
{
    "en-us": {
        "title" : "General",
        "assignedTo": "Assigned to",
        "addField": "Add a field"
    }
}
</i18n>
