<template>
    <configure-step-with-timer
        :value="value"
        :title="title"
        :subtitle="message"
        :has-error="hasError"
        :has-timer-warning="hasTimerWarning"
        :error-message="smsErrorMessage"
        @edit="(payload) => $emit('edit', payload)"
        @save="(payload) => $emit('save', payload)"
        @remove="$emit('remove')"
        @done="$emit('done')"
    />
</template>

<script>
import ConfigureStepWithTimer from './ConfigureStepWithTimer';
import { validate } from '@/automations/utils/automations.utils';
import { SEND_SMS } from '@/automations/constants/automations.constants';
import { MAX_CHARACTER_COUNT } from '@/broadcasts/smsBroadcast.constants';

export default {
    components: {
        ConfigureStepWithTimer,
    },

    props: {
        value: Object,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        errorMessage: String,
    },

    data() {
        return {
            step: this.value?.step ?? this.value,
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.step = newVal.step ?? newVal;
            },
            deep: true,
        },
    },

    computed: {
        title() {
            return this.step.configJson?.title || this.$t(this.step.name);
        },

        message() {
            return this.step.configJson?.message;
        },

        smsErrorMessage() {
            if (!validate[SEND_SMS].messageLength(this.step)) {
                return this.$t('lengthError', { maxCharacterCount: MAX_CHARACTER_COUNT });
            }

            if (!validate[SEND_SMS].messageEncoding(this.step)) {
                return this.$t('encodingError');
            }

            return this.errorMessage;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "encodingError": "Message contains unsupported characters",
        "lengthError": "Message exceeds {maxCharacterCount}-character limit"
    }
}
</i18n>
