import { render, staticRenderFns } from "./ContactAddressesView.vue?vue&type=template&id=d7cc6a56&scoped=true&lang=html&"
import script from "./ContactAddressesView.vue?vue&type=script&lang=js&"
export * from "./ContactAddressesView.vue?vue&type=script&lang=js&"
import style0 from "./ContactAddressesView.vue?vue&type=style&index=0&id=d7cc6a56&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7cc6a56",
  null
  
)

export default component.exports