<template>
    <ds-input-field
        v-model="localSearch"
        type="search"
        name="search"
        data-qa="sites-search-input"
        class="filter-search"
        :label="$t('search')"
        @input="handleSearchChange"
    />
</template>

<script>
import debounce from 'lodash.debounce';
import { SEARCHING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

export default {
    props: {
        search: {
            type: String,
            default: '',
            required: true,
            sync: true,
        },

        debounceDelay: {
            type: Number,
            default: SEARCHING_DEBOUNCE_DELAY,
        },
    },

    data() {
        return {
            localSearch: this.search,
        };
    },

    created() {
        if (this.debounceDelay > 0) {
            this.handleSearchChange = debounce(this.handleSearchChange, this.debounceDelay);
        }
    },

    methods: {
        handleSearchChange(value) {
            this.$emit('update:search', value);
        },
    },
};
</script>

<style lang="scss" scoped>
    .filter-search {
        --input-margin-bottom: 0;
    }
</style>

<i18n>
{
    "en-us": {
        "search": "Search landing pages"
    }
}
</i18n>
