<template>
    <ds-card
        class="template-gallery-html-card"
    >
        <header v-if="showHeader" class="template-card-header">
            <div class="title">
                {{ template.name }}
            </div>

            <div v-if="$slots.menu" ref="menu" class="menu">
                <slot name="menu" />
            </div>
        </header>

        <div>
            <iframe
                class="card-html-frame"
                scrolling="no"
                :srcdoc="template.html"
                sandbox="allow-scripts"
                frameborder="0"
                loading="lazy"
            />

            <div class="template-details">
                <ds-filled-button class="preview-button" data-qa="select-button" @click="$emit('select')">
                    <ds-icon name="edit" />
                    <span>{{ $t('select') }}</span>
                </ds-filled-button>
                <ds-filled-button
                    class="preview-button preview"
                    data-qa="preview-button"
                    :loading="isPreviewing"
                    @click="$emit('preview')"
                >
                    <ds-icon name="external-link" />
                    <span>{{ $t('preview') }}</span>
                </ds-filled-button>
            </div>
        </div>
    </ds-card>
</template>

<script>

export default {
    props: {
        template: {
            type: Object,
            required: true,
        },
        isPreviewing: {
            type: Boolean,
            required: true,
        },
        showHeader: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style lang="scss" scoped>
    $card-size: px-to-rem(300);

    .template-gallery-html-card {
        padding: 0;
        overflow: hidden;
        position: relative;
        &:hover {
            .template-details {
                display: flex;
            }
        }
    }

    .template-details {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba($color-paper, .7);
    }

    .template-card-header {
        background: $color-paper;
        color: $color-ink-900;
        border-bottom: 1px solid $color-ink-100;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: px-to-rem(48);
        padding: px-to-rem(12);
        z-index: 3;
        display: flex;
        align-items: center;
    }

    .template-gallery-html-card:not(:hover) .template-details {
        display: none;
    }

    .title {
        font-weight: 700;
        line-height: $line-height-sm;
        flex: 1;
        max-width: calc(100% - #{$gp * 2});
    }

    .menu {
        flex: 0;
        width: $gp * 2;
        margin: px-to-rem(-6) 0;
    }

    .card-html-frame {
        height: $card-size * 2;
        width: $card-size * 2;
        transform: scale(.5);
        transform-origin: 0 0;
    }

    .preview-button {
        display: flex;
        width: px-to-rem(120);
        margin-bottom: $gp / 2;
        flex-direction: row;
        align-items: center;
        --icon-size: #{$gp};

        span {
            @include margin-start($gp / 2);
        }
    }

    .placeholder {
        padding: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "select": "Select",
        "preview": "Preview"
    }
}
</i18n>
