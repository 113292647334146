import Vue from 'vue';

import numberFormats from '@/shared/i18n/number.json';

const mapToArray = (map) => {
    return Object.keys(map).map((key) => {
        return { value: key, label: map[key] };
    });
};

export default {
    SET_TITLE(state, title) {
        state.title = title || Vue.prototype.$i18nInstance.t('global.productName');
    },

    SET_LOCALE(state, locale) {
        state.locale = locale;
    },

    SET_GOOGLE_PLACE_ID(state, id) {
        state.googlePlaceId = id;
    },

    CLEAR_GOOGLE_PLACE_ID(state) {
        state.googlePlaceId = null;
    },

    SET_OVERLAY_ACTIVE(state, active) {
        checkOverlay(state, active, 'overlay-active');
    },

    TOGGLE_NAV_OPEN(state) {
        state.navOpen = !state.navOpen;
    },

    TOGGLE_MOBILE_NAV_OPEN(state) {
        state.mobileNavOpen = !state.mobileNavOpen;
    },

    SET_NAV_OPEN(state, open) {
        state.navOpen = (open === 'true');
    },

    SET_MOBILE_NAV_OPEN(state, open) {
        state.mobileNavOpen = open;
    },

    SET_SUPPORT_PANEL_OPEN(state, open) {
        state.supportPanelOpen = open;
    },

    SET_APP_COUNTRY_CODE(state, appCountryCode) {
        state.appCountryCode = appCountryCode;
    },

    SET_APP_LOADED(state, loaded = true) {
        state.appLoaded = loaded;
    },

    SET_BOOT_LOAD_ERROR(state, error) {
        state.bootLoadError = error;
    },

    SET_DEFERRED_BOOT_LOADED(state, loaded = true) {
        state.deferredBootLoaded = loaded;
    },

    SET_IS_LOCAL(state) {
        state.isLocal = true;
    },

    SET_COUNTRY_OPTIONS(state, countryOptions) {
        state.countryOptions = mapToArray(countryOptions);
    },

    SET_PUBLIC_CORE_APP_ID(state, appId) {
        state.publicCoreAppId = appId;
    },

    SET_REGION_OPTIONS(state, regionOptions) {
        state.regionOptions = mapToArray(regionOptions);
    },

    SET_TIME_ZONE_OPTIONS(state, timeZoneOptions) {
        state.timeZoneOptions = mapToArray(timeZoneOptions);
    },

    SET_APP_CURRENCY_CODE(state, appCurrencyCode) {
        state.appCurrencyCode = appCurrencyCode;
        updateAppCurrency(state, appCurrencyCode);
    },

    SET_IS_MOBILE_APP(state, isMobileApp) {
        state.isMobileApp = isMobileApp;
    },

    SET_NESTED_MODAL_EDIT_MODE_ENABLED(state, editModeEnabled) {
        state.nestedModalStatus.editModeEnabled = editModeEnabled;
    },

    SET_NESTED_MODAL_DIRTY(state, dirty) {
        state.nestedModalStatus.dirty = dirty;
    },

    SET_LOG_IN_AS_LOAD_ERRORS(state, errorArray) {
        state.logInAsLoadErrors = errorArray;
    },

    OPEN_FEEDBACK_MODAL(state, feature = '') {
        state.feedbackModal.isOpen = true;
        state.feedbackModal.feature = feature;
    },

    CLOSE_FEEDBACK_MODAL(state) {
        state.feedbackModal.isOpen = false;
        state.feedbackModal.feature = '';
    },

    CONFIRM_NAVIGATION_MARK_CLEAN(state, object) {
        state.confirmNavigationFlags.delete(object);
    },

    CONFIRM_NAVIGATION_MARK_DIRTY(state, object) {
        state.confirmNavigationFlags.add(object);
    },

    SIDE_NAV_MENU_EXPANDED(state) {
        state.isSideMenuExpanded = true;
    },

    SIDE_NAV_MENU_COLLAPSED(state) {
        state.isSideMenuExpanded = false;
    },
};

const checkOverlay = (state, active, className) => {
    if (active) {
        state.overlaysActive++;
    } else if (state.overlaysActive > 0) {
        state.overlaysActive--;
    }

    setTimeout(() => {
        if (state.overlaysActive > 0) {
            document.body.classList.add(className);
        } else {
            document.body.classList.remove(className);
        }
    });
};

const updateAppCurrency = (_, currency) => {
    if (typeof currency === 'string') {
        for (const localeName in numberFormats) {
            if (Object.prototype.hasOwnProperty.call(numberFormats, localeName)) {
                const locale = numberFormats[localeName];

                for (const optionName in locale) {
                    if (Object.prototype.hasOwnProperty.call(locale, optionName)) {
                        const option = locale[optionName];

                        if (Object.prototype.hasOwnProperty.call(option, 'currency')) {
                            option.currency = currency;
                        }
                    }
                }

                Vue.prototype.$i18nInstance.setNumberFormat(localeName, locale);
            }
        }
    }
};
