<template lang="html">
    <div class="setup-reviews">
        <svg class="business-img">
            <use xlink:href="#google-business" />
        </svg>

        <h4>{{ $t('subTitle') }}</h4>

        <address-autocomplete
            v-model="businessName"
            search-on-load
            place-type="establishment"
            provider="google"
            autofocus
            :label="$t('forms.business')"
            @select="select"
        />

        <div class="new-biz-container">
            <span class="new-biz-container-item">{{ this.$t('businessMissing') }}</span>

            <ds-text-button
                v-track="'Reviews - Create Business - clicked : Create a Google My Business listing'"
                class="new-biz-container-item"
                as="a"
                href="https://business.google.com/create"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ $t(`createListing`) }}
            </ds-text-button>

            <span class="new-biz-container-item three-days">{{ this.$t('threeDays') }}</span>
        </div>
    </div>
</template>

<script>
import VerifyListing from './VerifyListing';
import AddressAutocomplete from '@/shared/components/Fields/AddressAutocomplete';

import '../images/google-business.svg';

export default {
    components: {
        AddressAutocomplete,
    },

    props: {
        onSuccess: {
            type: Function,
            default: () => {},
        },
    },

    data() {
        return {
            nestedModal_showClose: true,
            nestedModal_title: this.$t('title'),
            businessName: '',
        };
    },

    methods: {
        reset() {
            this.businessName = '';
        },

        select(data) {
            this.$track('Reviews - Setup Reviews - submitted : business name');
            this.reset();

            return this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: VerifyListing,
                props: {
                    onSuccess: this.onSuccess,
                },
                data,
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .setup-reviews {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }

    h4 {
        margin: $gp * 2 0;
    }

    .address-autocomplete {
        width: px-to-rem(320px);
    }

    .new-biz-container {
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        max-width: px-to-rem(330px);
    }

    .new-biz-container-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: $gp / 4;
        box-sizing: border-box;
    }

    .new-biz-container-item:nth-child(3) {
        color: $color-gray-700;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Setup Google Reviews",
        "subTitle": "What's the name of your business?",
        "businessMissing": "Is your business missing?",
        "createListing": "Create a Google listing",
        "threeDays": "It may take up to 3 business days for Google to review and approve your business."
    }
}
</i18n>
