import * as allJobs from '../constants/job.constants';
import * as allTasks from '../constants/task.constants';
import { PREFERENCE_TYPES } from '@/shared/constants/preferences.constants';

export default {
    jobs: (_, getters, rootState, rootGetters) => {
        const leadCaptureSlideData = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ONBOARDING_LEAD_CAPTURE_OPTIONS);
        const appointmentsSlideData = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ONBOARDING_APPOINTMENTS_OPTIONS);
        const automationSlideData = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ONBOARDING_AUTOMATION_OPTIONS);

        if (leadCaptureSlideData && appointmentsSlideData && automationSlideData) {
            return getters.jobsQ1Improvements;
        }

        return getters.jobsOld;
    },

    jobsOld: (_, __, rootState, rootGetters) => {
        const hasContacts = rootGetters['onboarding/hasContacts'];
        const preferredJobId = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.SELECTED_ONBOARDING_JOB);
        const allowDKIM = rootGetters['settings/hasDomainThatRequiresDkim'];
        const unlayerBuilderEnabled = rootGetters['broadcasts/unlayerBuilderEnabled'];

        const jobsInUse = [
            allJobs.ADD_CONTACTS,
            allJobs.COLLECT_LEADS,
            allJobs.COLLECT_PAYMENT,
            allJobs.TRACK_ACTIVITIES,
            allJobs.PROMOTE_MARKET,
            allJobs.MANAGE_FOLLOW_UP,
        ];

        const filteredJobsAndTasks = jobsInUse.map((job) => {
            switch (job.id) {
            case allJobs.ADD_CONTACTS.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.ADD_CONTACT,
                        allTasks.ADD_CONTACT_HAS_CONTACTS,
                        allTasks.IMPORT_CONTACTS,
                        allTasks.ADD_TAG,
                        allTasks.REVIEW_MY_PROFILE,
                        allTasks.REVIEW_MY_BUSINESS_PROFILE,
                    ].filter((task) => {
                        if (hasContacts) {
                            return task.id !== allTasks.ADD_CONTACT.id;
                        }

                        return task.id !== allTasks.IMPORT_CONTACTS.id
                            && task.id !== allTasks.ADD_TAG.id
                            && task.id !== allTasks.ADD_CONTACT_HAS_CONTACTS.id;
                    }),
                };

            case allJobs.COLLECT_LEADS.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.SETUP_LEAD_FORM,
                        allTasks.SETUP_LEAD_FORM_NO_CONTACTS,
                        allTasks.CREATE_APPOINTMENT_TYPE,
                        allTasks.SEND_REFERRAL_EMAIL,
                        allTasks.APPOINTMENT_INVITE_EASY_AUTOMATION,
                    ].filter((task) => {
                        if (hasContacts) {
                            return task.id !== allTasks.SETUP_LEAD_FORM_NO_CONTACTS.id;
                        }

                        return task.id !== allTasks.SETUP_LEAD_FORM.id;
                    }),
                };

            case allJobs.TRACK_ACTIVITIES.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.CONNECT_TOOLS,
                        allTasks.ADD_TASK,
                        allTasks.CREATE_PIPELINE,
                    ],
                };

            case allJobs.MANAGE_FOLLOW_UP.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.CREATE_APPOINTMENT_TYPE_FOLLOW_UP,
                        allTasks.EDIT_EMAIL_TEMPLATE,
                        allTasks.DOWNLOAD_MOBILE_APP,
                    ],
                };

            case allJobs.PROMOTE_MARKET.id:
                return {
                    ...job,
                    tasks: [
                        unlayerBuilderEnabled
                            ? allTasks.SEND_UNLAYER_BROADCAST
                            : allTasks.SEND_BROADCAST,
                        allTasks.SETUP_GOOGLE_REVIEWS,
                        allTasks.SETUP_DKIM,
                    ].filter((task) => {
                        if (!allowDKIM) {
                            return task.id !== allTasks.SETUP_DKIM.id;
                        }

                        return true;
                    }),
                };

            case allJobs.COLLECT_PAYMENT.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.SEND_TEST_INVOICE,
                        allTasks.ADD_PRODUCTS,
                        allTasks.CONNECT_PAYMENT_PROCESSOR,
                        allTasks.CONNECT_QBO,
                    ],
                };

            default:
                return job;
            }
        });

        return [
            ...filteredJobsAndTasks.filter(({ id }) => id === allJobs.ADD_CONTACTS.id),
            ...filteredJobsAndTasks.filter(({ id }) => id === preferredJobId && id !== allJobs.ADD_CONTACTS.id),
            ...filteredJobsAndTasks.filter(({ id }) => id !== preferredJobId && id !== allJobs.ADD_CONTACTS.id),
        ];
    },

    jobsQ1Improvements: (_, __, rootState, rootGetters) => {
        const hasContacts = rootGetters['onboarding/hasContacts'];
        const preferredJobId = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.SELECTED_ONBOARDING_JOB);
        const leadCaptureSlideData = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ONBOARDING_LEAD_CAPTURE_OPTIONS);
        const appointmentsSlideData = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ONBOARDING_APPOINTMENTS_OPTIONS);
        const automationSlideData = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ONBOARDING_AUTOMATION_OPTIONS);
        const allowDKIM = rootGetters['settings/hasDomainThatRequiresDkim'];
        const unlayerBuilderEnabled = rootGetters['broadcasts/unlayerBuilderEnabled'];

        const jobsInUse = [
            allJobs.ADD_CONTACTS,
            allJobs.COLLECT_LEADS,
            allJobs.BOOK_APPOINTMENTS,
            allJobs.AUTOMATE_WORKFLOWS,
            allJobs.COLLECT_PAYMENT,
            allJobs.TRACK_ACTIVITIES,
            allJobs.PROMOTE_MARKET,
        ];

        const filteredJobsAndTasks = jobsInUse.map((job) => {
            switch (job.id) {
            case allJobs.ADD_CONTACTS.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.ADD_CONTACT,
                        allTasks.ADD_CONTACT_HAS_CONTACTS,
                        allTasks.IMPORT_CONTACTS,
                        allTasks.ADD_TAG,
                        allTasks.REVIEW_MY_PROFILE,
                        allTasks.REVIEW_MY_BUSINESS_PROFILE,
                    ].filter((task) => {
                        if (hasContacts) {
                            return task.id !== allTasks.ADD_CONTACT.id;
                        }

                        return task.id !== allTasks.IMPORT_CONTACTS.id
                            && task.id !== allTasks.ADD_TAG.id
                            && task.id !== allTasks.ADD_CONTACT_HAS_CONTACTS.id;
                    }),
                };

            case allJobs.COLLECT_LEADS.id:
                return (() => {
                    const onlySelectedOther = leadCaptureSlideData?.other
                    && Object.values(leadCaptureSlideData).filter((data) => data).length === 1;

                    const onlySelectedNone = leadCaptureSlideData?.none
                    && Object.values(leadCaptureSlideData).filter((data) => data).length === 1;

                    const onlySelectedOtherAndNone = leadCaptureSlideData?.none
                    && leadCaptureSlideData?.other
                    && Object.values(leadCaptureSlideData).filter((data) => data).length === 2;

                    const visibleTasksMapping = {
                        [allTasks.SETUP_PUBLIC_LEAD_FORM_OTHER.id]: onlySelectedOther || onlySelectedOtherAndNone,
                        [allTasks.SETUP_PUBLIC_LEAD_FORM_NOT_COLLECTING.id]: onlySelectedNone,
                        [allTasks.SEND_REFERRAL_EMAIL.id]: leadCaptureSlideData?.referrals,
                        [allTasks.SETUP_PUBLIC_LEAD_FORM_COLLECTING_WEBSITE.id]: leadCaptureSlideData?.website,
                        [allTasks.SETUP_PUBLIC_LEAD_FORM_COLLECTING_ONLINE.id]: leadCaptureSlideData?.forms,
                        [allTasks.SCAN_BUSINESS_CARD.id]: leadCaptureSlideData?.businessCards,
                    };

                    return {
                        ...job,
                        tasks: [
                            allTasks.SETUP_PUBLIC_LEAD_FORM_OTHER,
                            allTasks.SETUP_PUBLIC_LEAD_FORM_NOT_COLLECTING,
                            allTasks.SETUP_PUBLIC_LEAD_FORM_COLLECTING_WEBSITE,
                            allTasks.SETUP_PUBLIC_LEAD_FORM_COLLECTING_ONLINE,
                            allTasks.SCAN_BUSINESS_CARD,
                            allTasks.SEND_REFERRAL_EMAIL,
                        ].filter((task) => {
                            return visibleTasksMapping[task.id];
                        }),
                    };
                })();

            case allJobs.BOOK_APPOINTMENTS.id:
                return (() => {
                    const hasTool = appointmentsSlideData?.hasTool;
                    const none = appointmentsSlideData?.none;
                    const learnAboutAppointments = appointmentsSlideData?.learnAboutAppointments;

                    const visibleTasksMapping = {
                        [allTasks.TOUR_APPOINTMENTS.id]: !hasTool && learnAboutAppointments,
                        [allTasks.TOUR_APPOINTMENTS_HAS_TOOL.id]: hasTool && learnAboutAppointments,
                        [allTasks.CREATE_APPOINTMENT_TYPE_NEEDS_TOOL.id]: !none && learnAboutAppointments,
                        [allTasks.CREATE_APPOINTMENT_TYPE_NONE.id]: none && learnAboutAppointments,
                    };

                    return {
                        ...job,
                        tasks: [
                            allTasks.TOUR_APPOINTMENTS,
                            allTasks.TOUR_APPOINTMENTS_HAS_TOOL,
                            allTasks.CREATE_APPOINTMENT_TYPE_NEEDS_TOOL,
                            allTasks.CREATE_APPOINTMENT_TYPE_NONE,
                        ].filter((task) => {
                            return visibleTasksMapping[task.id];
                        }),
                    };
                })();

            case allJobs.AUTOMATE_WORKFLOWS.id:
                return (() => {
                    const selectedNone = automationSlideData?.none
                        && Object.values(automationSlideData).filter((data) => data).length === 1;

                    const onlySelectedOther = automationSlideData?.other
                        && Object.values(automationSlideData).filter((data) => data).length === 1;

                    const visibleTasksMapping = {
                        [allTasks.QUICK_FOLLOW_UP_LEADS_AUTOMATION.id]: automationSlideData?.quickFollowUp && !onlySelectedOther && !selectedNone,
                        [allTasks.KEEP_LEADS_ENGAGED_AUTOMATION.id]: automationSlideData?.keepLeadsEngaged && !onlySelectedOther && !selectedNone,
                        [allTasks.SCHEDULE_CONSULTATION_LEADS_AUTOMATION.id]: automationSlideData?.scheduleConsultations && !onlySelectedOther && !selectedNone,
                        [allTasks.REQUEST_REVIEWS_AUTOMATION.id]: automationSlideData?.requestReviews && !onlySelectedOther && !selectedNone,
                    };

                    return {
                        ...job,
                        tasks: [
                            allTasks.QUICK_FOLLOW_UP_LEADS_AUTOMATION,
                            allTasks.KEEP_LEADS_ENGAGED_AUTOMATION,
                            allTasks.SCHEDULE_CONSULTATION_LEADS_AUTOMATION,
                            allTasks.REQUEST_REVIEWS_AUTOMATION,
                        ].filter((task) => {
                            return visibleTasksMapping[task.id];
                        }),
                    };
                })();

            case allJobs.TRACK_ACTIVITIES.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.CONNECT_TOOLS,
                        allTasks.ADD_TASK,
                        allTasks.CREATE_PIPELINE,
                    ],
                };

            case allJobs.MANAGE_FOLLOW_UP.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.EDIT_EMAIL_TEMPLATE,
                        allTasks.DOWNLOAD_MOBILE_APP,
                    ],
                };

            case allJobs.PROMOTE_MARKET.id:
                return {
                    ...job,
                    tasks: [
                        unlayerBuilderEnabled
                            ? allTasks.SEND_UNLAYER_BROADCAST
                            : allTasks.SEND_BROADCAST,
                        allTasks.SETUP_GOOGLE_REVIEWS,
                        allTasks.SETUP_DKIM,
                    ].filter((task) => {
                        if (!allowDKIM) {
                            return task.id !== allTasks.SETUP_DKIM.id;
                        }

                        return true;
                    }),
                };

            case allJobs.COLLECT_PAYMENT.id:
                return {
                    ...job,
                    tasks: [
                        allTasks.SEND_TEST_INVOICE,
                        allTasks.ADD_PRODUCTS,
                        allTasks.CONNECT_PAYMENT_PROCESSOR,
                        allTasks.CONNECT_QBO,
                    ],
                };

            default:
                return job;
            }
        });

        return [
            ...filteredJobsAndTasks.filter(({ id }) => id === allJobs.ADD_CONTACTS.id),
            ...filteredJobsAndTasks.filter(({ id }) => id === preferredJobId && id !== allJobs.ADD_CONTACTS.id),
            ...filteredJobsAndTasks.filter(({ id }) => id !== preferredJobId && id !== allJobs.ADD_CONTACTS.id),
        ];
    },

    taskById: (_, { jobById }) => (jobId, taskId) => {
        const job = jobById(jobId);

        return {
            ...job.tasks.find(({ id }) => id === taskId),
            jobId: job.id,
            image: job.image,
        };
    },

    jobById: (_, { jobs }) => (jobId) => {
        return jobs.find(({ id }) => id === jobId);
    },

    jobIdsByTaskId: (_, { jobs }) => (taskId) => {
        const jobIds = [];

        jobs.forEach((job) => {
            const jobHasTask = job.tasks.filter((task) => task.id === taskId).length > 0;

            if (jobHasTask) {
                jobIds.push(job.id);
            }
        });

        return jobIds;
    },

    allTasksCompletedForJob: (_, { jobById }, { tutorials }, rootGetters) => (jobId) => {
        const job = jobById(jobId);

        const remainingTasks = job.tasks
            .filter((task) => !task.requiredFeature || rootGetters['auth/hasFeature'](task.requiredFeature))
            .filter((task) => !tutorials.items[`onboarding|tasks|${task.id}`]);

        return remainingTasks.length === 0;
    },

    allJobsCompleted: (_, { allTasksCompletedForJob, jobs }) => {
        for (const job of jobs) {
            if (!allTasksCompletedForJob(job.id)) {
                return false;
            }
        }

        return true;
    },

    totalNumberOfTasks: (_, { jobs }, __, rootGetters) => {
        return jobs.reduce((count, { tasks }) => {
            return count + tasks
                .filter((task) => !task.requiredFeature || rootGetters['auth/hasFeature'](task.requiredFeature))
                .length;
        }, 0);
    },

    totalNumberOfCompletedTasks: (_, { jobs }, { tutorials }, rootGetters) => {
        return jobs.reduce((count, { tasks }) => {
            return count + tasks
                .filter((task) => !task.requiredFeature || rootGetters['auth/hasFeature'](task.requiredFeature))
                .filter((task) => tutorials.items[`onboarding|tasks|${task.id}`])
                .length;
        }, 0);
    },

    tasksInUseByType: (_, getters) => (type = '') => {
        const { jobs } = getters;
        const tasksInUse = [];

        jobs.forEach((job) => {
            job.tasks.forEach((task) => tasksInUse.push({ ...task, jobId: job.id }));
        });

        return tasksInUse.filter((task) => task?.type?.toLowerCase().includes(type.toLowerCase()));
    },

    hasContacts: (_, __, ___, rootGetters) => {
        return rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ONBOARDING_HAS_CONTACTS)
            || rootGetters['preferences/getPreference'](PREFERENCE_TYPES.ADD_CONTACTS_ONBOARDING_JOB_ID) === PREFERENCE_TYPES.ADD_CONTACTS_HAS_EXISTING_CONTACTS;
    },
};
