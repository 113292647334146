import moment from 'moment';


/**
 * Assigns a unique slug for the page.
 *
 * @param {MarketingSite} site
 * @param {MarketingPage} page
 */
export const inspectPage = (site, page) => {
    page.slug = page.slug ?? 'page';

    let nextId = 1;
    let suffix = '';

    while (isNotUnique(site, `${page.slug}${suffix}`)) {
        suffix = `${++nextId}`;
    }

    return {
        ...page,
        slug: `${page.slug}${suffix}`,
    };
};

export function sleep(duration = 1000) {
    return new Promise((resolve) => setTimeout(resolve, duration));
}

export function buildMarketingSiteUrl(appId, siteSlug) {
    return `${process.env.VUE_APP_LANDING_PAGE_HOSTING_URL}/${appId}/${siteSlug}.html`;
}

export function buildMarketingPageUrl(appId, siteSlug, pageSlug) {
    return `${process.env.VUE_APP_LANDING_PAGE_HOSTING_URL}/${appId}/${siteSlug}/${pageSlug}.html`;
}

export function pageIdOf(obj) {
    if (!obj) return null;

    switch (typeof obj) {
    case 'object':
        return obj.id;
    case 'string':
        return obj;
    default:
        throw Error('Must be object or string');
    }
}

export function siteIdOf(obj) {
    if (!obj) return null;

    switch (typeof obj) {
    case 'object':
        return obj.id;
    case 'string':
        return obj;
    default:
        throw Error('Must be object or string');
    }
}

export function isNotUnique(site, slug) {
    return site?.pages?.some((page) => page.slug === slug) ?? false;
}

/**
 * Creates a sort function for [Site] instances that's based on a field from the site.
 * @param {string} field The field name to sort on.  Defaults to 'name'
 * @param {boolean} ascending Whether we should sort ascending. Default true
 * @return {function([MarketingSite], [MarketingSite]): number}
 */
export function createSiteComparator({ field = 'name', ascending = true } = {}) {
    return (a, b) => {
        const compare = (a[field] ?? '').localeCompare(b[field] ?? '');

        return !ascending ? 0 - compare : compare;
    };
}

/**
 * Creates a sort function for [Site] instances that's based on a date field from the site.
 * @param {string} field The field name to sort on.  Defaults to 'updatedDate'
 * @param {boolean} ascending Whether we should sort ascending. Default true
 * @return {function([MarketingSite], [MarketingSite]): number}
 */
export function createDateComparator({ field = 'updatedDate', ascending = true } = {}) {
    return (a, b) => {
        const compare = moment(a[field]).isBefore(b[field]) ? -1 : 1;

        return !ascending ? 0 - compare : compare;
    };
}

/**
 * Builds the final markup for an unlayer page
 *
 * - injects the tracking script into the page markup.
 * - Injects the site name into the title tag
 *
 * @param {string} appId
 * @param {MarketingSite} site
 * @param {MarketingPage} page
 * @param {UnlayerChunks} chunks
 * @param {string} convertedBody The HTML body, which has already been processed and had links replaced and whatnot
 * @return (string|null)
 */
export function buildPageHtml(appId, site, page, chunks, convertedBody) {
    if (!chunks) return null;
    convertedBody = convertedBody || chunks.body;
    const thankYouSlug = site.pages.find((p) => p.id !== site.mainPageId)?.slug;
    const successAttribute = thankYouSlug ? `success-redirect="${site.slug}/${thankYouSlug}.html"` : '';

    let html = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="utf-8">
            <meta http-equiv="x-ua-compatible" content="ie=edge">
            <title>${site.name ?? ''}</title>
            <meta name="description" content="${page.seoDescription ?? ''}">
            <meta name="viewport" content="width=device-width, initial-scale=1">`;

    chunks.fonts?.forEach((font) => {
        html += `  <link href="${font.url}" rel="stylesheet" type="text/css">
`;
    });
    chunks.jsModules?.forEach((jsmod) => {
        if (jsmod) {
            const type = jsmod.endsWith('esm.js') ? 'module' : 'text/javascript';

            html += `  <script type="${type}" src="${jsmod}"></script>
`;
        }
    });

    if (chunks.css) {
        html += `
  <style>
    ${chunks.css}
  </style>`;
    }

    if (chunks.js) {
        html += `
  <script type="text/javascript">
    ${chunks.js}
  </script>
`;
    }

    html += `
  <script src="${process.env.VUE_APP_HOSTING_SCRIPT_URL}"></script>
</head>
<body>
    <keap-hosting
            app-id="${appId}"
            version="${process.env.VUE_APP_UNLAYER_TOOLS_VERSION}"
            ${successAttribute}
            env="${process.env.VUE_APP_ENV}">
        <keap-scope
                marketing-site-slug="${site.slug}"
                marketing-site-name="${site.name}"
                marketing-page-slug="${page.slug}"
                marketing-page-name="${page.name}">
            <keap-event name="marketing-page-viewed">
                ${convertedBody}
            </keap-event>
        </keap-scope>
    </keap-hosting>
</body>
</html>`;

    return html;
}

/**
 * Doctors the preview markup to tell keap-hosting to not treat the form as live.  Submissions will not occur, but
 * we will attempt to validate the form as we should.
 *
 * @param {string} html The current rendered html for hte page
 * @param appId
 * @param {MarketingSite} site
 * @param {MarketingPage} page
 * @return {string}
 */
export function buildPreviewHtml(html, appId, site, page) {
    const previewElement = document.createElement('html');

    previewElement.innerHTML = (html ?? '')
        .replace(/.*<html>/, '')
        .replace(/<\/html>/, '');
    const head = previewElement.getElementsByTagName('head')[0];

    if (head) {
        head.innerHTML = `${head?.innerHTML ?? ''}
    <script src="${process.env.VUE_APP_HOSTING_SCRIPT_URL}"></script>`;
    }

    const body = previewElement.getElementsByTagName('body')[0];

    if (body) {
        body.innerHTML = `
<keap-hosting app-id="${appId}" is-preview="true" version="${process.env.VUE_APP_UNLAYER_TOOLS_VERSION}" env="${process.env.VUE_APP_ENV}">
    <keap-scope
            marketing-site-slug="${site.slug}"
            marketing-site-name="${site.name}"
            marketing-page-slug="${page.slug}"
            marketing-page-name="${page.name}">
       ${body?.innerHTML ?? ''}
    </keap-scope>
</keap-hosting>`;
    }

    return previewElement.outerHTML;
}
