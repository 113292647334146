import state from './state';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
    state,
    actions,
    getters,
    mutations,
    namespaced: true,
};
