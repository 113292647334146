import axios from 'axios';

import amplitude from '@/analytics/amplitude';
import sentry from '@/analytics/sentry';
import { normalizeContacts, mergeContactLists } from '@/contacts/utils/actions';
import { addCompany, updateCompany, transferContacts } from '../../api';

const ADD_COMPANY = ({ commit }, companyName) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/companies`, { companyName })
        .then(({ data: company }) => {
            const savedCompany = {
                value: company.id,
                label: company.companyName,
            };

            amplitude.v2.incrementUserProp('Total Number of Companies', 1);

            commit('ADD_COMPANY', savedCompany);

            return savedCompany;
        });
};

const ADD_COMPANY_DETAILS = ({ commit }, company) => {
    return addCompany(company)
        .then((savedCompany) => {
            commit('ADD_COMPANY', savedCompany);
            amplitude.v2.incrementUserProp('Total Number of Companies', 1);

            return savedCompany;
        });
};

const DELETE_COMPANY = async ({ commit }, {
    companyId,
    targetCompanyId,
}) => {
    if (targetCompanyId) {
        try {
            await transferContacts({
                companyId,
                targetCompanyId,
                checkAll: true,
                selectedIds: [],
            });
        } catch (error) {
            sentry.captureException(error);
            throw error;
        }
    }

    try {
        await axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/companies/${companyId}`);
    } catch (error) {
        sentry.captureException(error);
        throw error;
    }

    amplitude.v2.incrementUserProp('Total Number of Companies', -1);

    commit('DELETE_COMPANY', companyId);
};

const GET_COMPANIES = async ({ commit }) => {
    const allCompanies = [];
    const initOffset = 0;

    commit('LOAD_COMPANIES_START');

    try {
        await companyRequest(initOffset, allCompanies);
    } catch (error) {
        sentry.log('Could not get companies');

        commit('LOAD_COMPANIES_ERROR');
    }

    commit('LOAD_COMPANIES_SUCCESS', allCompanies);

    return allCompanies;
};

const UPDATE_COMPANY = ({ commit }, { id, companyName }) => {
    return updateCompany(id, { companyName })
        .then(({ data: { updateCompany: updatedCompany } }) => {
            const savedCompany = {
                value: updatedCompany.id,
                label: updatedCompany.companyName,
            };

            commit('UPDATE_COMPANY', savedCompany);

            return savedCompany;
        });
};


const LOAD_COMPANY_CONTACT_LIST = async ({ commit, state }, {
    limit = 20,
    pageToken,
    sortBy: orderBy = 'DateCreated',
    sortAscending: sortIsAsc = true,
    companyId,
}) => {
    const { contacts: existing } = state;

    const params = new URLSearchParams({
        companyId,
        limit,
        orderBy,
        sortIsAsc,
        next: pageToken || '0',
    });

    commit('SET_LOADING_CONTACT_LIST', true);

    try {
        const {
            data: {
                contacts,
                next: nextPageToken,
            },
        } = await axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/companies/list/contacts?${params.toString()}`);

        normalizeContacts(contacts);

        commit('SET_CONTACTS', [...mergeContactLists(existing, contacts)]);

        commit('SET_LOADING_CONTACT_LIST', false);

        return Promise.resolve({
            contacts,
            isPagingByToken: true,
            nextPageToken,
        });
    } catch (e) {
        return Promise.reject(e);
    }
};

const companyRequest = (offset, companyCollector) => {
    const limit = 1000;

    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/companies/list`, {
        params: {
            limit,
            offset,
        },
    })
        .then(({ data: { companies } }) => {
            companyCollector.push(...companies);

            if (companies.length === limit) {
                return companyRequest((offset + limit), companyCollector);
            }

            return companyCollector;
        });
};

export default {
    ADD_COMPANY,
    ADD_COMPANY_DETAILS,
    DELETE_COMPANY,
    GET_COMPANIES,
    UPDATE_COMPANY,
    LOAD_COMPANY_CONTACT_LIST,
};
