<template>
    <div class="page-builder">
        <div v-if="!isReady" class="templates-spinner-container">
            <ds-spinner />
        </div>
        <unlayer-editor
            v-else
            ref="pageBuilder"
            display-mode="web"
            :unlayer-user-id="appId"
            :options="unlayerOptions"
            v-on="{ 'design-updated': updatePageDesignFn(page) }"
        />
        <portal to="root">
            <create-custom-field-modal
                v-if="isCreatingCustomField"
                data-qa="create-custom-field-modal"
                :field-count="customFieldCount"
                :field-max="customFieldMax"
                :custom-fields="customFields"
                event-source="Form Builder"
                @close="cancelCreateCustomField"
                @new-field-added="onNewFieldAdded"
            />
        </portal>
    </div>
</template>

<script>

import UnlayerEditor from '@/shared/components/Unlayer/UnlayerEditor';
import { BLANK_DESIGN } from '@/marketingSites/unlayer/unlayer.constants';
import { mapGetters, mapState } from 'vuex';
import CreateCustomFieldModal from '@/customFields/components/CreateCustomFieldModal';
import {
    convertCustomFieldToUnlayerField,
    convertKeapStandardFieldToUnlayerField,
} from '@/marketingSites/unlayer/unlayer-form-converters';
import { FORM_TYPE_CHECKOUT, STANDARD_FIELDS } from '@/customForms/customForm.constants';
import clonedeep from 'lodash.clonedeep';
import merge from 'lodash.merge';
import {
    buildMarketingPageUrl,
    buildMarketingSiteUrl,
    buildPreviewHtml,
} from '@/marketingSites/components/site/site.utils';
import { checkoutFormEmbedContent } from '@/shared/utils/forms-embedContent';
import { createBaseOptions } from '@/shared/components/Unlayer/unlayer.util';
import { KeapEvents } from '@/marketingSites/components/site/unlayer-event-names.const';
import {
    KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT,
    FF_KEAP_LANDING_PAGES_MAX,
    FF_KEAP_UNLAYER_EMAIL_TEMPLATE_ADMIN,
} from '@/shared/constants/featureFlag.constants';
import { convert as slugify } from 'url-slug';

export default {
    components: {
        CreateCustomFieldModal,
        UnlayerEditor,
    },

    provide() {
        return {
            editorProvider: {
                register: this.registerEditor,
                unregister: this.unregisterEditor,
                previewHtml: this.previewHtml,
            },
        };
    },

    inject: ['$site', '$sites', '$focusedSite'],

    props: {
        config: {
            type: Object,
        },

        // / Amount of time to wait between exportAndSave retries.  Used only for testing
        retryDuration: {
            type: Number,
            default: 1000,
        },

        siteForms: Array,

        /**
         * @type {MarketingPage}
         */
        page: {
            type: Object,
            required: true,
        },

        /**
         * @type {MarketingSite}
         */
        site: {
            type: Object,
            required: true,
        },

        isDraft: Boolean,
    },

    data() {
        return {
            currentPageId: this.page?.id,

            createCustomFieldContext: {},

            // Since the unlayer frame isn't reactive, we need to wait until all the options are loaded from the
            // vuex store before we respond to any cross-frame request.  This promise resolves once all the vuex
            // store operations are completed.
            optionsLoadedPromise: null,
            editor: null,
        };
    },

    created() {
        window.addEventListener('message', this.dispatchUnlayerRequest);

        this.optionsLoadedPromise = new Promise(async (resolve, reject) => {
            try {
                await this.$store.dispatch('calendar/LOAD_PROVIDER_INTEGRATIONS');
                await this.$store.dispatch('calendar/LOAD_APPT_TYPES');

                try {
                    await this.$store.dispatch('sales/LOAD_CHECKOUT_FORM_LIST');
                    await this.$store.dispatch('customForms/LOAD_FORMS', FORM_TYPE_CHECKOUT);
                } catch (e) {
                    this.$error({ message: this.$t('errorLoading') });
                }

                resolve();
            } catch (e) {
                reject(e);
            }
        });
    },

    beforeDestroy() {
        this.cancelCreateCustomField();
        this.removeWindowListener();
    },

    watch: {
        page: {
            immediate: true,
            handler(newVal) {
                if (this.editor && this.currentPageId !== newVal?.id) {
                    // / Then we need to reinitialize unlayer
                    this.currentPageId = newVal?.id;
                    const newDesign = newVal?.content?.design;

                    this.loadDesign(newVal?.id, newDesign ?? {});
                }
            },
        },
    },

    computed: {
        ...mapState({
            appointmentTypes: ({ calendar }) => calendar.apptTypes,
            checkoutForms: ({ sales, auth }) => sales.checkoutForms.map((value) => {
                const { checkoutFormUrl } = value;

                value.embedCode = checkoutFormEmbedContent(checkoutFormUrl, auth.session.coreAppId);
                value.appId = auth.session.coreAppId;
                value.environment = process.env.VUE_APP_ENV_NAME;

                return ({ value, label: value.checkoutFormName ?? '' });
            }),
            appId: ({ auth }) => auth.session.coreAppId,
            isCustomFieldTechDebtEnabled: ({ featureFlags }) => featureFlags[KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT],
            isUnlayerLandingPageMaxEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_LANDING_PAGES_MAX],
            isGoldenAppEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_UNLAYER_EMAIL_TEMPLATE_ADMIN],
        }),

        ...mapGetters({
            hasUnlayerLandingPageMaxFeature: 'auth/hasUnlayerLandingPageMaxFeature',
            customFieldCount: 'contacts/customFieldCount',
            customFieldMax: 'settings/maxCustomFields',
            customFields: 'contacts/customFields',
        }),

        isUnlayerLandingPageMax() {
            return (this.isUnlayerLandingPageMaxEnabled && this.hasUnlayerLandingPageMaxFeature)
                || this.isGoldenAppEnabled;
        },

        appointmentTypeOptions() {
            return this.appointmentTypes.map((apptType) => ({
                label: `${apptType.name} | ${apptType.durationMinutes} ${this.$t('minutes')}`,
                value: {
                    environment: process.env.VUE_APP_ENV_NAME,
                    ...apptType,
                    formUrl: `${process.env.VUE_APP_FORMS_URL}/booking/${apptType.bookingLink}`,
                },
            }));
        },

        isEditorReady() {
            return this.editor != null;
        },

        keapFormFields() {
            return [
                ...STANDARD_FIELDS.map((standardField) => convertKeapStandardFieldToUnlayerField(
                    {},
                    standardField,
                )),
                ...this.customFields.map((customField) => convertCustomFieldToUnlayerField({
                    customField,
                    isCustomFieldTechDebtEnabled: this.isCustomFieldTechDebtEnabled,
                })),
            ].filter((fld) => fld);
        },

        isCreatingCustomField() {
            return Boolean(this.createCustomFieldContext.sendResponse);
        },

        design() {
            return this.page?.content?.design || BLANK_DESIGN;
        },

        isReady() {
            return this.config;
        },

        unlayerOptions() {
            if (!this.isReady) {
                return {};
            }

            const intlPlaceholders = [
                'unlayer.tool.form.label',
                'unlayer.tool.checkoutForm.label',
                'unlayer.tool.checkoutForm.panel.label',
                'unlayer.tool.checkoutForm.select.label',
                'unlayer.tool.bookingForm.label',
                'unlayer.tool.bookingForm.panel.label',
                'unlayer.tool.bookingForm.select.label',
                'unlayer.tool.sharedOpts.layout',
                'unlayer.tool.appointmentForm.label',
                'unlayer.tool.form.fields.group.fields.title',
                'unlayer.tool.form.fields.givenName',
                'unlayer.tool.form.fields.familyName',
                'unlayer.tool.properties.border',
                'unlayer.tool.properties.roundedBorder',
                'unlayer.tool.properties.padding',
                'unlayer.tool.form.fields.givenName.label',
                'unlayer.tool.form.fields.givenName.placeholder',
                'unlayer.tool.form.fields.familyName.placeholder',
                'unlayer.tool.form.fields.email.label',
                'unlayer.tool.form.fields.email.placeholder',
                'unlayer.tool.form.fields.phone.label',
                'unlayer.tool.form.fields.phone.placeholder',
                'unlayer.tool.properties.backgroundColor',
                'unlayer.tool.properties.textColor',
                'unlayer.tool.properties.fontSize',
                'unlayer.tool.properties.layout',
                'unlayer.tool.properties.stackOnMobile',
                'unlayer.tool.properties.formWidth',
                'unlayer.tool.properties.formAlignment',
                'unlayer.tool.properties.spaceBetweenFields',
                'unlayer.tool.properties.labels',
                'unlayer.tool.properties.color',
                'unlayer.tool.properties.button',
                'unlayer.tool.properties.buttonTextLabel',
                'unlayer.tool.properties.formPosition',
                'unlayer.tool.properties.width',
                'unlayer.tool.properties.margin',
                'unlayer.customLinks.marketingPage.label',
                'unlayer.customLinks.marketingPage.field.label',
                'unlayer.customLinks.marketingPage.field.placeholder',
                'unlayer.customLinks.marketingSite.label',
                'unlayer.customLinks.marketingSite.field.label',
                'unlayer.customLinks.marketingSite.field.placeholder',
                'unlayer.customLinks.checkoutForm.label',
                'unlayer.customLinks.checkoutForm.field.label',
                'unlayer.customLinks.checkoutForm.field.placeholder',
                'unlayer.customLinks.appointmentType.label',
                'unlayer.customLinks.appointmentType.field.label',
                'unlayer.customLinks.appointmentType.field.placeholder',
                'unlayer.placeholder.default',
                'unlayer.placeholder.appointmentType',
                'unlayer.placeholder.checkoutForm',
                'unlayer.pagesPanel.settings.label',
            ];

            const localeData = {
                [this.$i18n.locale]: intlPlaceholders.reduce((acc, next) => {
                    acc[next.replace(/^unlayer\./, '')] = this.$t(next);

                    return acc;
                }, {}),
            };

            const pageList = this.site?.pages?.map(({ id, name }) => ({
                id, name: name === 'default' ? this.$t('marketingSites.defaultPageName') : name,
            })) ?? [];

            const unlayerConfig = merge(
                createBaseOptions({
                    controlPanelRightAlign: true,
                }), {
                    displayMode: 'web',
                    features: {
                        preheaderText: false,
                        textEditor: {
                            emojis: false,
                        },
                    },
                    customJS: [
                        process.env.VUE_APP_UNLAYER_TOOLS_URL,
                        `keapUnlayerTools.addLocaleData(${JSON.stringify(localeData)})`,
                        `keapUnlayerTools.setCurrentLocale("${this.$i18n.locale}")`,
                        'unlayer.registerPropertyEditor(keapUnlayerTools.KeapFormNamePropEditor)',
                        'unlayer.registerPropertyEditor(keapUnlayerTools.KeapFieldsPropEditor)',
                        'unlayer.registerPropertyEditor(keapUnlayerTools.KeapSelectPropEditor)',
                        'unlayer.registerTool(keapUnlayerTools.KeapFormTool())',
                        'unlayer.registerTool(keapUnlayerTools.KeapAppointmentTool())',
                        'unlayer.registerTool(keapUnlayerTools.KeapCheckoutFormTool())',
                        `unlayer.registerTab(keapUnlayerTools.createPagesPanelSettings(${JSON.stringify(pageList)}, ${this.isUnlayerLandingPageMax}))`,
                        'keapUnlayerTools.registerMarketingSiteLinkType()',
                        'keapUnlayerTools.registerMarketingPageLinkType()',
                        'keapUnlayerTools.registerAppointmentTypeLinkType()',
                        'keapUnlayerTools.registerCheckoutFormLinkType()',

                    ],
                    tools: {
                        form: {
                            enabled: false,
                        },
                        'custom#keap#form': {
                            properties: {
                                fields: {
                                    editor: {
                                        data: {
                                            // This forces the unlayer form tool to use our contact fields and custom fields
                                            // as the default list of fields to pick from
                                            defaultFields: this.keapFormFields,
                                        },
                                    },
                                },
                            },
                        },

                    },
                },
            );

            return unlayerConfig;
        },
    },

    methods: {
        /**
         * Listens to messages sent to this window via window.postMessage, processes the request and sends a response back.
         *
         * @param {MessageEvent} event
         * @param {string} keapRequestType A key for the type of request being made
         * @param {*} requestData Any request data sent for the request.
         * @param {MessagePort} responsePort A port used to send a response or error to the requesting frame
         */
        async dispatchUnlayerRequest({ data: { keapRequestType, requestData = {}, testPort }, ports: [responsePort] }) {
            // The explicit testPort is only used for testing
            const sendPort = responsePort ?? testPort;

            const dispatchWithResponse = async (handler, errorCode = 'error.saving') => {
                try {
                    const response = await handler();

                    sendPort.postMessage({ success: true, response });
                } catch (error) {
                    this.$error({ message: this.$t(errorCode) });
                    sendPort.postMessage({ success: false, error: `${error}` });
                }
            };

            if (keapRequestType) {
                switch (keapRequestType) {
                case KeapEvents.createFormField:
                    this.cancelCreateCustomField();
                    this.createCustomFieldContext = {
                        sendResponse: (response) => sendPort.postMessage({ success: true, response }),
                        sendError: (error) => sendPort.postMessage({ success: false, error }),
                        requestData,
                    };

                    return true;

                case KeapEvents.getMarketingPageOptions:
                    return dispatchWithResponse(
                        async () => {
                            await this.optionsLoadedPromise;

                            return this.site.pages.map((page) => ({
                                label: page.name,
                                value: {
                                    ...page,
                                    appId: this.appId,
                                    pageUrl: buildMarketingPageUrl(this.appId, this.site.slug, page.slug),
                                },
                            }));
                        },
                    );


                case KeapEvents.getMarketingSiteOptions:
                    return dispatchWithResponse(
                        async () => {
                            await this.optionsLoadedPromise;

                            return this.$sites.getMarketingSiteList().map((site) => ({
                                label: site.name,
                                value: {
                                    ...site,
                                    appId: this.appId,
                                    siteUrl: buildMarketingSiteUrl(this.appId, site.slug),
                                },
                            }));
                        },
                    );

                case KeapEvents.focusUnlayerPage: {
                    return dispatchWithResponse(
                        () => {
                            const { pageId } = requestData;

                            try {
                                this.$site.changePageFocus(pageId);
                            } catch {
                                // Ignore
                            }

                            return true;
                        },
                    );
                }

                case KeapEvents.createUnlayerPage:
                    return dispatchWithResponse(
                        () => {
                            return this.$site.addPage({ name: `${this.$t('newPagePrefix')} ${this.site.pages.length + 1}` });
                        },
                        'error.creating',
                    );

                case KeapEvents.sortUnlayerPages: {
                    return dispatchWithResponse(
                        async () => {
                            const { pageIds } = requestData;

                            await this.$site.sortPages(pageIds);

                            return true;
                        },
                    );
                }

                case KeapEvents.getUnlayerPageDetails: {
                    return dispatchWithResponse(async () => {
                        const { pageId } = requestData;

                        return this.$site.getMarketingPage(pageId);
                    }, 'error.fetching');
                }

                case KeapEvents.saveUnlayerPage: {
                    return dispatchWithResponse(
                        async () => {
                            const { pageId, pageData: { pageName: name, seoDescription } = {} } = requestData;

                            const savedPage = await this.$site.updatePage(pageId, { name, slug: slugify(name), seoDescription });

                            return { savedPage };
                        },
                    );
                }

                case KeapEvents.deleteUnlayerPage: {
                    return dispatchWithResponse(
                        async () => {
                            const { pageId } = requestData;

                            await this.$site.deletePage(pageId);

                            return true;
                        },
                        'error.deleting',
                    );
                }

                case KeapEvents.getAppointmentTypeOptions:
                    return dispatchWithResponse(
                        async () => {
                            await this.optionsLoadedPromise;

                            return this.appointmentTypeOptions;
                        },
                        'error.loading',
                    );

                case KeapEvents.getCheckoutFormOptions:
                    return dispatchWithResponse(
                        async () => {
                            await this.optionsLoadedPromise;

                            return this.checkoutForms;
                        },
                        'error.loading',
                    );

                case KeapEvents.getFunnelFormCount: {
                    return dispatchWithResponse(
                        async () => {
                            const totalFormCount = await this.$site.getTotalFormCount();

                            return { totalFormCount, prefix: this.$t('formAutoNamePrefix') };
                        },
                        'error.loading',
                    );
                }
                default:
                    // This will let the other side know that we don't know how to handle this request, so the
                    // port can be freed.
                    sendPort.postMessage({ success: false, error: `Missing handler for ${keapRequestType}` });

                    return true;
                }
            }

            return false;
        },

        cancelCreateCustomField() {
            if (this.isCreatingCustomField) {
                // Sending a blank response indicates to the caller that the user cancelled the operation
                this.createCustomFieldContext.sendResponse();
                this.createCustomFieldContext = {};
            }
        },

        onNewFieldAdded(customField) {
            if (this.isCreatingCustomField) {
                const unlayerField = convertCustomFieldToUnlayerField({
                    customField,
                    isCustomFieldTechDebtEnabled: this.isCustomFieldTechDebtEnabled,
                });

                if (unlayerField) {
                    this.createCustomFieldContext.sendResponse(unlayerField);
                    this.createCustomFieldContext = {};
                } else {
                    this.createCustomFieldContext.sendError('Could not convert field');
                    this.createCustomFieldContext = {};
                }
            }
        },

        /**
         * Higher-order-function that captures the value of "page" to avoid race conditions when the
         * page property changes.  There may be some in-flight changes that need to be applied, and we don't
         * want those changes to go to the wrong page.
         */
        updatePageDesignFn(page) {
            const clonedPage = clonedeep(page);

            return ({ design, chunks }) => this.updatePageDesign({ design, chunks }, clonedPage);
        },

        updatePageDesign({ design, chunks }, page) {
            page = page ?? this.page;
            this.$site.updatePage(page, { content: { design, chunks } });
        },

        previewHtml(params, done) {
            if (params.html) {
                const newHTML = buildPreviewHtml(params.html, this.appId, this.$focusedSite, this.page);

                done({ html: newHTML });
            }
        },

        registerEditor(editor) {
            this.editor = editor;
            this.loadDesign(this.page.id, this.design);
        },

        unregisterEditor() {
            this.editor = null;
        },

        loadDesign(pageId, design) {
            this.editor?.loadDesign(design);

            // If we are draft mode, we have to make sure we export the proper HTML and save it, regardless of
            // whether the user manually makes any changes or not.
            if (this.isDraft && pageId) {
                this.exportAndSave(pageId, true);
            }
        },

        // Exports this page in its current state.  Checks the exported HTML for the dreaded
        // Missing div, and retries up to 4 times.
        exportAndSave(pageId, forceUpdate = false, attempt = 1) {
            this.editor.exportHtml(async ({ chunks, design, html }) => {
                if (html?.includes('<div>Missing</div>')) {
                    if (attempt < 4) {
                        // Something went wrong... try again.
                        setTimeout(() => this.exportAndSave(pageId, forceUpdate, attempt + 1), this.retryDuration);
                    }
                } else {
                    await this.$site.updatePage(pageId, { content: { design, chunks } }, { forceUpdate });
                }
            });
        },

        /**
         * Only for testing
         */
        removeWindowListener() {
            window.removeEventListener('message', this.dispatchUnlayerRequest);
        },
    },
};
</script>

<style lang="scss" scoped>
    .page-builder {
        height: 100%;
    }
</style>
<i18n>
{
    "en-us": {
        "modalTitle": "Choose condition",
        "formAutoNamePrefix": "Lead capture form",
        "newPagePrefix": "Page",
        "minutes": "min",
        "placeholder": {
            "default": "Select...",
            "appointmentType": "Select appointment type...",
            "checkoutForm": "Select checkout form..."
        },
        "error": {
            "creating": "There was an error creating a new page",
            "deleting": "There was an error deleting this page",
            "saving": "There was an error saving this page"
        }
    }
}
</i18n>
