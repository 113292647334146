import moment from 'moment-timezone';
import groupBy from 'lodash.groupby';
import { dateSegment, isDateInvalid } from '@/shared/utils/date.util';

const dateObjectToShortDateString = (date) => {
    if (date) {
        if (date instanceof moment) {
            return moment(date).format('YYYY-MM-DD');
        }

        if (date instanceof Date) {
            const zero = '0';
            const day = (zero + date.getDate()).slice(-2);
            const month = (zero + (date.getMonth() + 1)).slice(-2);

            return `${date.getFullYear()}-${month}-${day}`;
        }
    }

    return date;
};

const dateFormatDate = (date, format = 'll') => {
    const formattedDate = moment(date).isValid() ? date : new Date(date);

    return moment(formattedDate).format(format);
};

export const formatBirthdayDate = (date) => {
    if (date) {
        const birthdayDate = moment(date).isValid() ? date : new Date(date);

        return moment(birthdayDate).format('MMM Do, YYYY');
    }

    return date;
};

export const formatAnniversaryDate = (date) => {
    if (date) {
        const anniversaryDate = moment(date).isValid() ? date : new Date(date);

        return moment(anniversaryDate).format('MMM Do, YYYY');
    }

    return date;
};

export default {
    // Expose as plain JS
    dateObjectToShortDateString,
    dateFormatDate,

    methods: {
        date_dateSegment(date, hasTime) {
            return dateSegment(date, hasTime);
        },

        date_fromNow(date) {
            return moment(date).fromNow(true);
        },

        date_timeAgo(date) {
            moment.relativeTimeThreshold('ss', 0);

            return moment(date).fromNow();
        },

        date_formatDateOrNull(date, format = 'll') {
            if (date !== null) {
                const formattedDate = moment(date).isValid() ? date : new Date(date);

                return moment(formattedDate).format(format);
            }

            return null;
        },

        date_formatDate(date, format = 'll') {
            return dateFormatDate(date, format);
        },

        date_formatBirthdayDate(date) {
            return formatBirthdayDate(date);
        },

        date_dateObjectToShortDateString(date) {
            return dateObjectToShortDateString(date);
        },

        date_isDateInvalid(date) {
            return isDateInvalid(date);
        },

        date_activitiesByDate(activities) {
            if (Array.isArray(activities) && activities.length > 0) {
                const sortedActivities = activities.sort((a, b) => {
                    return new Date(b.publishRequestDate) - new Date(a.publishRequestDate);
                });

                return groupBy(sortedActivities, ({ publishRequestDate }) => {
                    return moment(publishRequestDate).startOf('day').toDate();
                });
            }

            return {};
        },

        date_getTimeDropdownOptions(offset, timeFormat) {
            const times = [];
            const dayStart = moment().startOf('day');
            const dayEnd = moment().endOf('day');

            while (dayStart.isSameOrBefore(dayEnd)) {
                const time = moment(dayStart);

                times.push(time.format(timeFormat));
                dayStart.add(offset, 'm');
            }

            return times;
        },

        date_getTimeMultiselectOptions(offset, timeFormat) {
            return this.date_getTimeDropdownOptions(offset, timeFormat).map((entry) => ({ value: entry, label: entry }));
        },
    },
};
