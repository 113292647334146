export default {
    methods: {
        bookingAvailability_isAvailable(selectedDate, availability) {
            const { start } = selectedDate;

            return start && availability
                ? availability.times && availability.times.some((block) => (block.start === start))
                : true;
        },
    },
};
