<template>
    <section class="content-area participant-list">
        <header class="builder-header">
            <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

            <span class="builder-header-text mobile-header">{{ $t(`${messageType}.headerMobile`) }}</span>
            <span class="builder-header-text desktop-header">{{ $t(`${messageType}.header`) }}</span>

            <ds-outline-button
                class="right-button"
                data-qa="remove-participants"
                :loading="removing"
                :disabled="!selectedParticipants.length && !checkAll"
                @click="removeParticipants"
            >
                {{ $t('remove') }}
            </ds-outline-button>
        </header>

        <div class="participant-container">
            <ds-card class="participants">
                <div
                    v-if="!loading"
                    data-qa="select-all-participants"
                    :class="['contact-item select-all circle-checkbox-hover', { checked: checkAll }, { 'partial-checked': showPartialSelected }]"
                    @click.stop.prevent="handleSelectAll"
                >
                    <ds-list-item :title="$t('global.selectAll')" border-style="none">
                        <template #leadingSlot>
                            <div class="circle-checkbox-container">
                                <span class="circle-checkbox">
                                    <ds-icon v-if="showAllCheckedOption" class="circle-check" name="check" />

                                    <ds-icon v-else class="circle-check" name="minus" />
                                </span>
                            </div>
                        </template>
                    </ds-list-item>
                </div>

                <component
                    :is="listItemComponent(participant.type)"
                    v-for="(participant, index) in participants"
                    :key="`${participant.id}_${index}`"
                    :participant="participant"
                    :automation="automation"
                    class="participant-list-item"
                    @select="(payload) => selectParticipant(payload, index)"
                />

                <ds-infinite-scroll
                    :no-more-data="noMoreData"
                    :loading="loading"
                    :limit.sync="limit"
                    @load="load"
                >
                    <ds-placeholder
                        v-for="n in 2"
                        :key="n"
                        :rows="placeholder"
                        class="placeholder"
                    />
                </ds-infinite-scroll>
            </ds-card>
        </div>
    </section>
</template>

<script>
import ContactParticipantListItem from './ContactParticipantListItem';
import DealParticipantListItem from './DealParticipantListItem';
import { TRIGGERS, PARTICIPANT_TYPE } from '@/automations/constants/automations.constants';

export default {
    components: {
        ContactParticipantListItem,
        DealParticipantListItem,
    },

    props: {
        automation: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            participants: [],
            loading: true,
            limit: 20,
            cursor: '',
            noMoreData: false,
            removing: false,
            checkAll: false,
            placeholder: [
                { height: '4rem', boxes: [1] },
            ],
        };
    },

    mounted() {
        this.init();
    },

    beforeDestroy() {
        this.reset();
    },

    computed: {
        messageType() {
            return this.isDealType ? 'deals' : 'contacts';
        },

        isDealType() {
            // TODO automation needs to be the published version in order to display properly
            return TRIGGERS[this.automation.triggers?.[0]?.type]?.participantType === PARTICIPANT_TYPE.DEAL;
        },

        automationId() {
            return this.automation.id;
        },

        selectedCount() {
            return this.participants.reduce((total, { selectedContactIds, selected }) => {
                if (selected) {
                    return total + selected;
                }

                return total + (selectedContactIds?.length ? 1 : 0);
            }, 0);
        },

        showAllCheckedOption() {
            return !this.checkAll || (this.noMoreData && this.participants.length === this.selectedCount);
        },

        showPartialSelected() {
            return this.checkAll && this.participants.some((participant) => participant.selectedContactIds?.length || participant.selected);
        },

        selectedParticipants() {
            return this.participants.reduce((arr, {
                id,
                contacts,
                selectedContactIds,
                selected,
            }) => {
                if (this.checkAll && !this.isParticipantSelected(selectedContactIds, selected)) {
                    arr.push({ id, contactIds: contacts.map(({ id: contactId }) => contactId) });
                } else if (!this.checkAll && this.isParticipantSelected(selectedContactIds, selected)) {
                    arr.push({ id, contactIds: selectedContactIds });
                }

                return arr;
            }, []);
        },

        isPipelinePage() {
            return this.$route.name.includes('pipeline');
        },
    },

    methods: {
        init() {
            if (this.isDealType && !this.isPipelinePage) {
                try {
                    this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST');
                } catch { /* dont show error to user */ }
            }
        },

        isParticipantSelected(selectedContactIds, selected) {
            return (selectedContactIds?.length && !this.isDealType) || (this.isDealType && selected);
        },

        listItemComponent(type) {
            if (type === PARTICIPANT_TYPE.DEAL) {
                return DealParticipantListItem;
            }

            return ContactParticipantListItem;
        },

        reset() {
            this.loading = true;
            this.removing = false;
            this.participants = [];
            this.cursor = '';
            this.checkAll = false;
            this.noMoreData = false;
        },

        async load() {
            this.loading = true;

            try {
                const { limit, cursor } = this;

                const participants = await this.$store.dispatch('automations/LOAD_PARTICIPANT_LIST', { id: this.automationId, limit, cursor });
                const loadedParticipants = this.checkAll
                    ? participants.map((participant) => ({ ...participant, selected: this.isDealType, selectedContactIds: participant.contacts.map(({ id }) => id) }))
                    : participants;

                this.participants = [
                    ...this.participants,
                    ...loadedParticipants,
                ];
                this.noMoreData = limit > participants.length;

                if (this.noMoreData) {
                    this.cursor = '';
                } else {
                    this.cursor = participants[participants.length - 1].id;
                }
            } catch {
                this.noMoreData = true;
                this.cursor = '';
                this.$error({ message: this.$t('error') });
            }

            this.loading = false;
        },

        selectParticipant(payload, participantIndex) {
            const { contacts, selectedContactIds } = this.participants[participantIndex];

            // TODO currently adding/removing all the contacts in the participant
            this.$set(this.participants[participantIndex], 'selectedContactIds', selectedContactIds?.length ? [] : contacts.map(({ id }) => id));

            if (this.isDealType) {
                this.$set(this.participants[participantIndex], 'selected', payload.selected);
            }
        },

        handleSelectAll() {
            if (this.showPartialSelected) {
                this.checkAll = false;
            } else {
                this.checkAll = !this.checkAll;
            }

            this.participants.forEach((participant, index) => {
                this.$set(this.participants[index], 'selectedContactIds', this.checkAll ? participant.contacts.map(({ id }) => id) : []);

                if (this.isDealType) {
                    this.$set(this.participants[index], 'selected', this.checkAll);
                }
            });
        },

        removeParticipants() {
            return this.$confirm({
                optionTitle: this.$t('removeConfirm.confirmTitle'),
                optionMessage: this.$t('removeConfirm.confirmMessage'),
                optionConfirmButtonLabel: this.$t('removeConfirm.button'),
                destructive: true,
            }).then(() => {
                return this.callRemoveParticipants();
            }).catch(() => {});
        },

        async callRemoveParticipants() {
            this.removing = true;

            try {
                await this.$store.dispatch('automations/REMOVE_PARTICIPANTS', { automationId: this.automationId, participants: this.selectedParticipants, checkAll: this.checkAll });
                this.$toast({ message: this.$t('removeSuccess') });

                this.reset();
                await this.load();

                if (this.participants.length === 0) {
                    this.$emit('close');
                }
            } catch {
                this.$error({ message: this.$t('removingError') });
            }

            this.removing = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/header';
    @import "~@/shared/styles/circle-checkbox";

    .builder-header {
        --automation-header-height: #{px-to-rem(66px)};

        @media ($extra-small) {
            --automation-header-height: #{px-to-rem(56px)};
        }
    }

    .mobile-header {
        display: none;
    }

    .participants {
        margin: 0 auto;
        max-width: px-to-rem(450px);
    }

    .participant-container {
        margin: $gp;
    }

    .participant-list-item:not(:first-child) {
        border-top: 1px solid $color-gray-200;
    }

    .placeholder {
        &:not(:first-child) {
            margin-top: $gp;
        }

        &:first-child {
            border-top-left-radius: $border-radius;
            border-top-right-radius: $border-radius;
        }

        &:last-child {
            border-bottom-left-radius: $border-radius;
            border-bottom-right-radius: $border-radius;
        }
    }

    @mixin small-participant-view() {
        .mobile-header {
            display: flex;
        }

        .desktop-header {
            display: none;
        }
    }

    @media($extra-small) {
        @include small-participant-view();
    }

    .contexual-automation-panel {
        @include small-participant-view();

        .builder-header {
            --automation-header-height: #{px-to-rem(56px)};
        }
    }
</style>

<i18n>
{
    "en-us": {
        "contacts": {
            "header": "Contacts in automation",
            "headerMobile": "Contacts"
        },
        "deals": {
            "header": "Deals in automation",
            "headerMobile": "Deals"
        },
        "remove": "Remove from automation",
        "error": "There was an error loading contact list",
        "removeSuccess": "Successfully removed.",
        "removingError": "There was an error removing contacts",
        "removeConfirm": {
            "button": "Yes, remove",
            "confirmTitle": "Remove from automation?",
            "confirmMessage": "This action cannot be undone."
        }
    }
}
</i18n>
