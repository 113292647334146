<template lang="html">
    <section v-if="contact" class="contact-phone-email">
        <h4 class="section-title editing">
            {{ $t('contactDetails.sectionHeaders.phoneEmailFax') }}
        </h4>

        <section class="field-section">
            <div class="field-grid phone-fields editing">
                <div v-for="{ id, phone, fieldName } in filteredPhoneList" :key="id">
                    <div class="field-grid">
                        <ds-input-field
                            v-model.trim="phone.value"
                            type="tel"
                            autocomplete="no"
                            :label="$t('forms.phone')"
                            :submitted="submitted"
                            :data-qa="`phone-${id}`"
                            class="fs-block"
                            @input="$emit('input')"
                        />

                        <div class="field-type">
                            <ds-select-field
                                v-model.trim="phone.type"
                                bind-value-only
                                :label="$t('forms.type')"
                                :options="phoneOptions"
                                :submitted="submitted"
                                @input="$emit('input')"
                            />

                            <div>
                                <contact-field-remove-button
                                    class="remove-button"
                                    @action="clearPhoneField(fieldName)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span
                v-if="showAddPhoneButton"
                class="add-field"
                data-qa="add-phone"
                @click="addPhoneField"
            >
                <ds-icon name="add" />
                <span>{{ $t('addPhone') }}</span>
            </span>
        </section>

        <section class="field-section">
            <div class="field-grid email-fields editing">
                <div v-for="emailAddress in filteredEmailList" :key="emailAddress.fieldName">
                    <div class="field-type">
                        <ds-input-field
                            v-model.trim="contact[emailAddress.fieldName]"
                            type="email"
                            autocomplete="no"
                            :data-qa="emailAddress.fieldName"
                            :label="$t(emailAddress.label)"
                            :submitted="submitted"
                            class="fs-block"
                            @input="$emit('input')"
                        />

                        <div>
                            <contact-field-remove-button
                                class="remove-button"
                                @action="clearEmailField(emailAddress.fieldName)"
                            />
                        </div>
                    </div>

                    <contact-email-marketability
                        v-if="displayMarketability(emailAddress.fieldName)"
                        :email-marketability="contactEmailMarketability[emailAddress.fieldName]"
                    />
                </div>
            </div>

            <ds-dropdown
                v-if="showAddEmailButton && availableEmailFields.length > 1"
                class="dropdown"
                data-qa="add-email"
            >
                <span class="add-field">
                    <ds-icon name="add" />
                    <span>{{ $t('addEmail') }}</span>
                </span>

                <template #menu>
                    <ul class="dropdown-menu-list">
                        <li
                            v-for="fieldName in availableEmailFields"
                            :key="fieldName"
                            class="dropdown-menu-item"
                            @click="addEmailField(fieldName)"
                        >
                            {{ $t(`contactDetails.fieldHeaders.${fieldName}`) }}
                        </li>
                    </ul>
                </template>
            </ds-dropdown>

            <span
                v-if="showAddEmailButton && availableEmailFields.length === 1"
                class="add-field"
                @click="addEmailField(availableEmailFields[0])"
            >
                <ds-icon name="add" />
                <span>{{ $t('addEmail') }}</span>
            </span>
        </section>

        <section class="field-section">
            <div class="field-grid fax-fields editing">
                <div v-for="{ id, fax, fieldName } in filteredFaxList" :key="id">
                    <div class="field-grid">
                        <ds-input-field
                            v-model.trim="fax.value"
                            type="tel"
                            autocomplete="no"
                            :label="$t('forms.fax')"
                            :submitted="submitted"
                            :data-qa="`fax-${id}`"
                            class="fs-block"
                            @input="$emit('input')"
                        />

                        <div class="field-type">
                            <ds-select-field
                                v-model.trim="fax.type"
                                bind-value-only
                                :label="$t('forms.type')"
                                :options="faxOptions"
                                :submitted="submitted"
                                @input="$emit('input')"
                            />

                            <div>
                                <contact-field-remove-button
                                    class="remove-button"
                                    @action="clearFaxField(fieldName)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <span
                v-if="showAddFaxButton"
                class="add-field"
                data-qa="add-fax"
                @click="addFaxField"
            >
                <ds-icon name="add" />
                <span>{{ $t('addFax') }}</span>
            </span>
        </section>
    </section>
</template>

<script>
import ContactFieldRemoveButton from './ContactFieldRemoveButton';
import ContactEmailMarketability from './ContactEmailMarketability';

export default {
    components: { ContactFieldRemoveButton, ContactEmailMarketability },

    props: {
        contact: Object,
        phoneOptions: Array,
        faxOptions: Array,
        submitted: Boolean,
        contactEmailMarketability: Object,
    },

    data() {
        return {
            availablePhoneFields: [],
            availableEmailFields: [],
            availableFaxFields: [],
        };
    },

    mounted() {
        if (this.contact) {
            this.getAvailableFields();
        }
    },

    computed: {
        showAddEmailButton() {
            return this.availableEmailFields.length > 0;
        },

        showAddPhoneButton() {
            return this.availablePhoneFields.length > 0;
        },

        showAddFaxButton() {
            return this.availableFaxFields.length > 0;
        },

        filteredPhoneList() {
            if (!this.contact) {
                return [];
            }

            const phoneList = [];

            ['phone1', 'phone2', 'phone3', 'phone4', 'phone5']
                .forEach((fieldName) => {
                    if (!this.availablePhoneFields.includes(fieldName)) {
                        phoneList.push({
                            phone: this.contact[fieldName],
                            fieldName,
                            id: fieldName.charAt(fieldName.length - 1),
                        });
                    }
                });

            return phoneList;
        },

        filteredEmailList() {
            if (!this.contact) {
                return [];
            }

            const emailList = [];

            ['email', 'email2', 'email3']
                .forEach((fieldName) => {
                    if (!this.availableEmailFields.includes(fieldName)) {
                        emailList.push({
                            email: this.contact[fieldName],
                            fieldName,
                            label: this.$t(`contactDetails.fieldHeaders.${fieldName}`),
                        });
                    }
                });

            return emailList;
        },

        filteredFaxList() {
            if (!this.contact) {
                return [];
            }

            const faxList = [];

            ['fax1', 'fax2']
                .forEach((fieldName) => {
                    if (!this.availableFaxFields.includes(fieldName)) {
                        faxList.push({
                            fax: this.contact[fieldName],
                            fieldName,
                            id: fieldName.charAt(fieldName.length - 1),
                        });
                    }
                });

            return faxList;
        },
    },

    methods: {
        getAvailableFields() {
            this.availablePhoneFields = Object.entries(this.contact).map((field) => {
                const isPhoneField = field[0].includes('phone');

                if (isPhoneField) {
                    return field[1] && !field[1].value ? field[0] : null;
                }

                return null;
            }).filter((value) => Boolean(value));

            if (this.availablePhoneFields.length === 5) {
                this.addPhoneField();
            }

            this.availableEmailFields = Object.entries(this.contact).map((field) => {
                const isEmailField = field[0].includes('email');

                if (isEmailField) {
                    return !field[1] ? field[0] : null;
                }

                return null;
            }).filter((value) => Boolean(value));

            if (this.availableEmailFields.length === 3) {
                this.addEmailField();
            }

            this.availableFaxFields = Object.entries(this.contact).map((field) => {
                const isFaxField = field[0].includes('fax');

                if (isFaxField) {
                    return field[1] && !field[1].value ? field[0] : null;
                }

                return null;
            }).filter((value) => Boolean(value));

            if (this.availableFaxFields.length === 2) {
                this.addFaxField();
            }
        },

        addPhoneField() {
            this.availablePhoneFields.shift();
        },

        addEmailField(field) {
            const fieldIndex = field
                ? this.availableEmailFields.indexOf(field)
                : 0;

            this.availableEmailFields.splice(fieldIndex, 1);
        },

        addFaxField() {
            this.availableFaxFields.shift();
        },

        clearPhoneField(fieldName) {
            this.contact[fieldName].value = '';
            this.contact[fieldName].type = null;

            this.availablePhoneFields.push(fieldName);
            this.availablePhoneFields.sort();
        },

        clearEmailField(fieldName) {
            this.contact[fieldName] = null;

            this.availableEmailFields.push(fieldName);
            this.availableEmailFields.sort();
        },

        clearFaxField(fieldName) {
            this.contact[fieldName].value = '';
            this.contact[fieldName].type = null;

            this.availableFaxFields.push(fieldName);
            this.availableFaxFields.sort();
        },

        displayMarketability(fieldName) {
            if (!(this.contactEmailMarketability && this.contactEmailMarketability[fieldName])) {
                return false;
            }

            return this.contact[fieldName] === this.contactEmailMarketability[fieldName].email;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .contact-phone-email {
        padding-bottom: $gp;
    }

    .section-title {
        margin-top: $gp / 2;
    }

    .field-section {
        &:not(:last-of-type) {
            margin-bottom: $gp * 2;
        }
    }

    .field-grid {
        &.editing {
            grid-template-columns: auto;
        }
    }

    .field-type {
        display: flex;
    }

    .remove-button {
        margin-left: $gp;
    }

    .add-field {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "addPhone": "Add a phone",
        "addEmail": "Add an email",
        "addFax": "Add a fax"
    }
}
</i18n>
