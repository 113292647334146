<template>
    <ds-popover
        :is-open="popoverOpen"
        position-fixed
        :position="position"
        class="sms-link-popover"
        @close="popoverOpen = false"
    >
        <template #reference>
            <ds-icon-button
                name="link"
                :aria-label="$t('shortenLinkLabel')"
                @click="togglePopover"
            />
        </template>

        <template #default>
            <div class="link-shortener">
                <div class="popover-header">
                    {{ $t('popoverHeader') }}
                </div>

                <form @submit.prevent="shortenLink">
                    <ds-input-field
                        v-model="linkToShorten"
                        name="urlToShorten"
                        data-qa="shorten-link-input"
                        :label="$t('destinationLink')"
                    />

                    <ds-filled-button data-qa="shorten-link" :disabled="!Boolean(linkToShorten.length)" @click="shortenLink">
                        {{ $t('shortenLink') }}
                    </ds-filled-button>

                    <div v-if="shortenError" class="link-error">
                        {{ $t('shortenError') }}
                    </div>
                </form>
            </div>
        </template>
    </ds-popover>
</template>

<script>
import { getShortenedLink } from '@/shared/api/automatedSms.api';
import sentry from '@/analytics/sentry';

export default {
    props: {
        position: {
            type: String,
            default: 'top',
        },
    },

    data() {
        return {
            popoverOpen: false,
            linkToShorten: '',
            shortenError: false,
        };
    },

    methods: {
        togglePopover() {
            this.popoverOpen = !this.popoverOpen;
        },

        async shortenLink() {
            this.shortenError = false;

            try {
                const shortenLink = await getShortenedLink({ linkToShorten: this.linkToShorten });

                if (!shortenLink.shortURL) {
                    this.shortenError = true;
                } else {
                    this.$emit('shorten-link', shortenLink.shortURL);
                    this.linkToShorten = '';
                    this.popoverOpen = false;
                }
            } catch (e) {
                this.shortenError = true;
                sentry.captureException(e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .popover-header {
        font-size: $font-size-lg;
        padding-bottom: $gp / 2;
    }

    .link-shortener {
        padding: $gp * 2;
    }

    .link-error {
        color: $color-red;
    }
</style>

<i18n>
{
    "en-us": {
        "popoverHeader": "Shorten your link",
        "destinationLink": "Destination link",
        "shortenLink": "Shorten link",
        "shortenError": "Unable to shorten link. Please try again.",
        "shortenLinkLabel": "toggle link shortener"
    }
}
</i18n>
