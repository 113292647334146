import axios from 'axios';
import gql from 'graphql-tag';
import moment from 'moment';
import Vue from 'vue';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import sentry from '@/analytics/sentry';
import { trackQuoteSent } from '@/contacts/analytics';
import { timeUnitStatus } from '@/money/constants/order-status';
import { SAVE_FIELDS } from '@/shared/constants/money.constants';

export default {
    PROCESS_PAYMENT(context, paymentInfo) {
        return processPayment(context, paymentInfo);
    },

    PROCESS_PUBLIC_PAYMENT(context, paymentInfo) {
        return processPublicPayment(context, paymentInfo);
    },

    COMPLETE_AUTHORIZED_PAYMENT(context, authorizationDetails) {
        return completeAuthorizedPayment(context, authorizationDetails);
    },

    LOAD_SALES_TOTALS(context, startDate) {
        return loadSalesTotals(context, startDate);
    },

    LOAD_BILLING_AMOUNTS(context, payload) {
        return loadBillingAmounts(context, payload);
    },

    REFUND_PAYMENT(context, payment) {
        return refundPayment(context, payment);
    },

    LOAD_CONTACT_PAYMENTS(context, payload) {
        return loadContactPayments(context, payload);
    },

    /* invoices */
    LOAD_CONTACT_INVOICES(context, payload) {
        return loadContactInvoices(context, payload);
    },

    CREATE_INVOICE(context, payload) {
        return createInvoice(context, payload);
    },

    LOAD_INVOICES(context, payload) {
        return loadInvoices(context, payload);
    },

    LOAD_INVOICES_PAGINATED(context, payload) {
        return loadInvoicesPaginated(context, payload);
    },

    LOAD_AMOUNTS(context) {
        return loadInvoicesAmounts(context);
    },

    LOAD_RECENT_INVOICES(context, payload) {
        return loadRecentInvoices(context, payload);
    },

    UPDATE_INVOICE(context, payload) {
        return editInvoice(context, payload);
    },

    DELETE_INVOICE(context, payload) {
        return deleteInvoice(context, payload);
    },

    LOAD_INVOICE(context, payload) {
        return loadInvoice(context, payload);
    },

    LOAD_INVOICE_DASHBOARD_STATS() {
        return loadInvoiceDashboardStats();
    },

    GET_PUBLIC_INVOICE_URL(context, payload) {
        return getPublicInvoiceUrl(context, payload);
    },

    UPDATE_INVOICE_LIST_ITEM(context, data) {
        return updateInvoiceListItem(context, data);
    },

    ADD_INVOICE_LIST_ITEM(context, data) {
        return addInvoiceListItem(context, data);
    },

    DELETE_INVOICE_LIST_ITEM(context, data) {
        return deleteInvoiceListItem(context, data);
    },

    PUBLIC_LOAD_INVOICE(context, payload) {
        return publicLoadInvoice(context, payload);
    },

    PUBLIC_SETUP_PAYPAL_ORDER(context, payload) {
        return publicSetupPaypalOrder(payload);
    },

    PUBLIC_SETUP_PAYPAL_BILLING_AGREEMENT(context, payload) {
        return publicSetupPaypalBillingAgreement(payload);
    },

    SEND_INVOICE(context, payload) {
        return sendInvoice(context, payload);
    },

    SEND_INVOICE_REMINDER(context, payload) {
        return sendInvoiceReminder(context, payload);
    },

    SEND_INVOICE_EMAIL(context, payload) {
        return sendInvoiceEmail(context, payload);
    },

    SET_INVOICE_AS_SENT(context, payload) {
        return setInvoiceAsSent(context, payload);
    },

    ADD_INVOICE_FILE(context, payload) {
        return addInvoiceFile(context, payload);
    },

    DELETE_INVOICE_FILE(context, payload) {
        return deleteInvoiceFile(context, payload);
    },

    LOAD_INVOICE_ATTACHMENTS(context, payload) {
        return loadInvoiceAttachments(context, payload);
    },

    /* quotes */
    LOAD_CONTACT_QUOTES(context, payload) {
        return loadContactQuotes(context, payload);
    },

    CREATE_QUOTE(context, payload) {
        return createQuote(context, payload);
    },

    LOAD_QUOTES(context, payload) {
        return loadQuotes(context, payload);
    },

    UPDATE_QUOTE(context, payload) {
        return editQuote(context, payload);
    },

    DELETE_QUOTE(context, payload) {
        return deleteQuote(context, payload);
    },

    LOAD_QUOTE(context, payload) {
        return loadQuote(context, payload);
    },

    SEND_QUOTE(context, payload) {
        return sendQuote(context, payload);
    },

    SEND_QUOTE_REMINDER(context, payload) {
        return sendQuoteReminder(context, payload);
    },

    CONVERT_QUOTE_TO_INVOICE(context, payload) {
        return convertQuoteToInvoice(context, payload);
    },

    LOAD_APP_SALES_INFO(context, payload) {
        return loadAppSalesInfo(context, payload);
    },

    LOAD_QUOTE_DASHBOARD_STATS() {
        return loadQuoteDashboardStats();
    },

    ADD_QUOTE_LIST_ITEM(context, data) {
        return addQuoteListItem(context, data);
    },

    UPDATE_QUOTE_LIST_ITEM(context, data) {
        return updateQuoteListItem(context, data);
    },

    DELETE_QUOTE_LIST_ITEM(context, data) {
        return deleteQuoteListItem(context, data);
    },

    PUBLIC_LOAD_QUOTE(context, payload) {
        return publicLoadQuote(context, payload);
    },

    PUBLIC_ACCEPT_QUOTE(context, payload) {
        return publicAcceptQuote(context, payload);
    },

    SEND_QUOTE_EMAIL(context, payload) {
        return sendQuoteEmail(context, payload);
    },

    SET_QUOTE_AS_SENT(context, payload) {
        return setQuoteAsSent(context, payload);
    },

    ADD_QUOTE_FILE(context, payload) {
        return addQuoteFile(context, payload);
    },

    LOAD_QUOTE_ATTACHMENTS(context, payload) {
        return loadQuoteAttachments(context, payload);
    },

    DELETE_QUOTE_FILE(context, payload) {
        return deleteQuoteFile(context, payload);
    },

    /* recurring payments */
    LOAD_RECURRING_PAYMENT(context, payload) {
        return loadRecurringPayment(context, payload);
    },

    LOAD_RECURRING_PAYMENTS(context, payload) {
        return loadRecurringPayments(context, payload);
    },

    LOAD_CONTACT_HISTORICAL_RECURRING_PAYMENTS(context, payload) {
        return loadContactHistoricalRecurringPayments(context, payload);
    },

    LOAD_CONTACT_RECURRING_PAYMENTS(context, payload) {
        return loadContactRecurringPayments(context, payload);
    },

    SAVE_SUBSCRIPTION(context, payload) {
        return saveSubscription(context, payload);
    },

    LOAD_JOB_RECURRING_PAYMENTS(context, payload) {
        return loadJobRecurringPayments(context, payload);
    },

    /* checkout forms */
    LOAD_CHECKOUT_FORM_LIST(context, payload) {
        return loadCheckoutFormList(context, payload);
    },

    CREATE_CHECKOUT_FORM(context, payload) {
        return createCheckoutForm(context, payload);
    },

    EDIT_CHECKOUT_FORM(context, payload) {
        return editCheckoutForm(context, payload);
    },

    GET_CHECKOUT_FORM(context, payload) {
        return getCheckoutForm(context, payload);
    },

    PUBLIC_CHECKOUT_APPLY_PROMO_CODE(context, data) {
        return publicCheckoutApplyPromoCode(context, data);
    },

    PUBLIC_LOAD_CHECKOUT_FORM(context, payload) {
        return publicLoadCheckoutForm(context, payload);
    },

    PUBLIC_CHECKOUT_SETUP_PAYPAL_ORDER(context, payload) {
        return publicCheckoutSetupPaypalOrder(payload);
    },

    PUBLIC_CHECKOUT_SETUP_PAYPAL_BILLING_AGREEMENT(context, payload) {
        return publicCheckoutSetupPaypalBillingAgreement(payload);
    },

    DELETE_CHECKOUT_FORM(context, payload) {
        return deleteCheckoutForm(context, payload);
    },

    DOES_CHECKOUT_URL_EXIST(context, payload) {
        return doesCheckoutUrlExist(context, payload);
    },

    PROCESS_CHECKOUT_PAYMENT(context, paymentInfo) {
        return processCheckoutPayment(context, paymentInfo);
    },

    RETRY_PROCESS_CHECKOUT_PAYMENT(context, paymentInfo) {
        return retryProcessCheckoutPayment(context, paymentInfo);
    },

    LOAD_CHECKOUT_FORM_UPSELLS(context, payload) {
        return loadCheckoutFormUpsells(context, payload);
    },

    ADD_CHECKOUT_FORM_UPSELL(context, payload) {
        return addCheckoutFormUpsell(context, payload);
    },

    UPDATE_CHECKOUT_FORM_UPSELL(context, payload) {
        return updateCheckoutFormUpsell(context, payload);
    },

    DELETE_CHECKOUT_FORM_UPSELL(context, payload) {
        return deleteCheckoutFormUpsell(context, payload);
    },
};

const loadContactInvoices = ({ commit }, contactId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/${contactId}`)
            .then(({ data }) => {
                commit('SET_INVOICES', data.orders);
                resolve(data);
            })
            .catch(reject);
    });
};

const loadContactPayments = async ({ commit }, contactId) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query contactPayments($contactId: ID!) {
                contactPayments(contactId: $contactId) {
                    invoicePayments {
                        amount,
                        id,
                        invoiceId,
                        note,
                        lastUpdated,
                        payDate,
                        paymentId,
                        payStatus,
                        skipCommission,
                        refundInvoicePaymentId,
                    }
                }
            }
        `,
        variables: {
            contactId,
        },
        fetchPolicy: 'no-cache',
    })
        .then(({ data: { contactPayments: { invoicePayments } } }) => {
            commit('SET_PAYMENTS', invoicePayments);
        })
        .catch((error) => {
            sentry.log('Loading contact payments failed in actions');
            throw error;
        });
};

const loadContactRecurringPayments = ({ commit }, contactId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/currentRecurringPayments?contactId=${contactId}`)
            .then(({ data }) => {
                commit('SET_RECURRING_PAYMENTS', data.subscriptions.map((payment) => transformRecurringPayment(payment)));
                resolve();
            })
            .catch(reject);
    });
};

const loadContactHistoricalRecurringPayments = ({ commit }, contactId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/historicalRecurringPayments?contactId=${contactId}`)
            .then(({ data }) => {
                commit('SET_HISTORICAL_RECURRING_PAYMENTS', data.subscriptions.map((payment) => transformRecurringPayment(payment)));
                resolve();
            })
            .catch(reject);
    });
};

const saveSubscription = ({ dispatch }, { contactId, payment }) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/recurringPayments/${payment.id}`, { ...payment, contactId })
            .then(() => {
                dispatch('LOAD_CONTACT_RECURRING_PAYMENTS', contactId);
                dispatch('LOAD_RECURRING_PAYMENTS', contactId);
                resolve();
            })
            .catch(reject);
    });
};

const loadContactQuotes = ({ commit }, contactId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${contactId}`)
            .then(({ data }) => {
                commit('SET_QUOTES', data.quotes || []);
                resolve();
            })
            .catch(reject);
    });
};

const loadSalesTotals = ({ commit }, startDate) => {
    return new Promise((resolve, reject) => {
        const params = `startDate=${startDate.toISOString()}`;

        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/totalSales?${params}`)
            .then(({ data: { salesTotal } }) => {
                commit('SET_SALES_TOTAL', salesTotal);
                resolve();
            })
            .catch(reject);
    });
};

const createInvoice = ({ commit }, { orderInfo, eventSource }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders`, orderInfo)
            .then((response) => {
                commit('SET_INVOICE', response.data);
                commit('ADD_INVOICE', response.data);

                amplitude.v2.logEvent(amplitude.v2.events.INVOICE_CREATED, {
                    'Event Source': eventSource,
                });

                intercom.logEvent(intercom.events.INVOICE_CREATED);

                resolve(response);
            })
            .catch(reject);
    });
};

const createQuote = ({ commit }, { orderInfo, quoteSource }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes`, orderInfo)
            .then((response) => {
                commit('SET_QUOTE', response.data);
                commit('ADD_QUOTE', response.data);

                amplitude.v2.logEvent(amplitude.v2.events.QUOTE_CREATED, {
                    'Event Source': quoteSource,
                });

                intercom.logEvent(intercom.events.QUOTE_CREATED);

                resolve(response);
            })
            .catch(reject);
    });
};

const deleteInvoice = ({ commit }, orderId) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/${orderId}`)
            .then(() => {
                commit('DELETE_INVOICE', orderId);
                resolve();
            })
            .catch(reject);
    });
};

const deleteQuote = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${id}`)
            .then(() => {
                commit('DELETE_QUOTE', id);
                resolve();
            })
            .catch(reject);
    });
};

const refundPayment = (context, payment) => {
    const invoicePaymentId = payment.id;

    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/payments/${invoicePaymentId}/refund`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const editInvoice = ({ commit, dispatch }, invoice) => {
    const orderRequest = {
        orderId: invoice.id,
        contactId: invoice.contactId,
        title: invoice.title,
        orderDueDate: invoice.dueDate,
        shippingInformation: invoice.shippingInformation,
        discount: invoice.discount,
        payPlan: invoice.payPlan,
        notes: invoice.notes,
        terms: invoice.terms,
        allowPayment: invoice.allowPayment,
        allowPaypal: invoice.allowPaypal,
    };

    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/${invoice.id}`, orderRequest)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_INVOICE_ATTACHMENTS', data);
            } else {
                commit('SET_INVOICE', data);
            }

            commit('SYNC_INVOICES');
        })
        .catch(() => {});
};

const editQuote = ({ commit, dispatch }, quote) => {
    const request = {
        quoteId: quote.id,
        contactId: quote.contactId,
        title: quote.title,
        terms: quote.terms,
        notes: quote.notes,
        quoteDate: quote.quoteDate,
        invoiceToCompany: quote.invoiceToCompany,
    };

    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${quote.id}`, request)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_QUOTE_ATTACHMENTS', data);
            } else {
                commit('SET_QUOTE', data);
            }
            commit('SYNC_QUOTES');
        })
        .catch(() => {});
};

const loadInvoice = ({ commit, dispatch }, invoiceId) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/order/${invoiceId}`)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_INVOICE_ATTACHMENTS', data);
            } else {
                commit('SET_INVOICE', data);
            }
        })
        .catch(() => {});
};

const loadInvoiceDashboardStats = () => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/invoices/dashboardStats`);
};

const getPublicInvoiceUrl = (_, id) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/invoices/url/${id}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const loadQuote = ({ commit, dispatch }, id) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/quote/${id}`)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_QUOTE_ATTACHMENTS', data);
            } else {
                commit('SET_QUOTE', data);
            }
        })
        .catch(() => {});
};

const loadBillingAmounts = (context, startDate) => {
    return new Promise((resolve, reject) => {
        const params = `startDate=${startDate.toISOString()}`;

        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/amounts?${params}`)
            .then(({ data }) => resolve(data))
            .catch((e) => reject(e));
    });
};

const loadInvoices = ({ commit }, payload) => {
    const value = payload.contactName || '';

    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders?contactName=${value}`)
            .then(({ data }) => {
                commit('SET_ALL_INVOICES', data.orders);
                resolve(data.orders);
            })
            .catch((e) => reject(e));
    });
};

const loadInvoicesPaginated = async ({ commit, state }, payload) => {
    try {
        const value = `contactName=${payload.contactName || ''}`;
        const status = `status=${payload.status || ''}`;
        const params = `limit=${payload.limit}&offset=${payload.offset}&${value}&${status}&orderBy=${payload.sortField}`;
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders?${params}`);
        const { orders } = data;
        const existing = payload.offset === 0 ? [] : state.allInvoices;
        const merged = existing.concat(orders);

        commit('SET_ALL_INVOICES', merged);

        return orders;
    } catch (e) {
        sentry.log('Loading paginated invoices failed', { message: e.message });
        throw (e);
    }
};

const loadInvoicesAmounts = () => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/amountValues`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const loadRecentInvoices = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/recent`)
            .then(({ data }) => {
                commit('SET_RECENT_INVOICES', data.orders);
                resolve();
            })
            .catch((e) => reject(e));
    });
};

const loadQuotes = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes`)
            .then(({ data }) => {
                commit('SET_ALL_QUOTES', data.quotes || []);
                resolve(data.quotes || []);
            })
            .catch((e) => reject(e));
    });
};

const publicLoadInvoice = ({ commit }, { appId, xid }) => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${xid}`)
            .then(({ data }) => {
                if (appId) {
                    amplitude.v2.setUserId(appId);
                    amplitude.v2.logEvent(amplitude.v2.events.INVOICE_VIEWED);
                }

                commit('SET_PUBLIC_INVOICE', data.invoice);
                commit('SET_APP_CURRENCY_CODE', data.appCurrencyCode, { root: true });
                commit('SET_APP_SALES_INFO', data.appSalesInfo);
                commit('SET_PAYPAL_MERCHANT', data.paypalMerchant);
                commit('SET_PAYMENT_SUBTYPE', data.paymentSubtype);
                commit('SET_PUBLIC_MONEY_FLAGS', data.featureFlags);
                resolve();
            })
            .catch((e) => reject(e));
    });
};

const publicSetupPaypalOrder = ({ invoiceId, amount }) => {
    return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${invoiceId}/payPal/orders`, { payAmount: amount })
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            throw e;
        });
};

const publicSetupPaypalBillingAgreement = ({ invoiceId }) => {
    return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${invoiceId}/payPal/billingAgreements`)
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            throw e;
        });
};

const publicAcceptQuote = ({ commit }, { appId, xid }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/quote/${xid}/accept`)
            .then(({ data }) => {
                if (appId) {
                    amplitude.v2.setUserId(appId);
                }

                amplitude.v2.logEvent(amplitude.v2.events.QUOTE_ACCEPTED);

                commit('SET_PUBLIC_QUOTE', { quote: data.quote, companyProfile: data.companyProfile });
                resolve();
            })
            .catch((e) => reject(e));
    });
};

const sendQuoteEmail = async ({ commit, dispatch }, {
    body,
    buttonLink,
    buttonText,
    from,
    userId,
    fromAddress,
    quoteId,
    quoteSource,
    recipient,
    signature,
    subject,
    to,
}) => {
    await dispatch('contacts/SEND_EMAIL', {
        body,
        buttonLink,
        buttonText,
        from,
        userId,
        fromAddress,
        recipient,
        signature,
        subject,
        to,
    }, { root: true });

    trackQuoteSent(quoteSource);

    await dispatch('SET_QUOTE_AS_SENT', { quoteId });
    commit('SET_NESTED_MODAL_DIRTY', false, { root: true });
};

const setQuoteAsSent = async ({ commit }, { quoteId }) => {
    const { data: { setQuoteAsSent: quoteWasSent } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation setQuoteAsSent($quoteId: ID!) {
                setQuoteAsSent(quoteId: $quoteId)
            }
        `,
        variables: {
            quoteId,
        },
        fetchPolicy: 'no-cache',
    });

    if (quoteWasSent) {
        commit('SET_QUOTE_SENT', quoteId);
        commit('SYNC_QUOTES');
    }
};

const publicLoadQuote = ({ commit }, { appId, xid }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/quote/${xid}`)
            .then(({ data }) => {
                if (appId) {
                    amplitude.v2.setUserId(appId);
                }

                amplitude.v2.logEvent(amplitude.v2.events.QUOTE_VIEWED);

                commit('SET_PUBLIC_QUOTE', { quote: data.quote, companyProfile: data.companyProfile });
                commit('SET_APP_CURRENCY_CODE', data.appCurrencyCode, { root: true });
                commit('SET_PUBLIC_MONEY_FLAGS', data.featureFlags);
                resolve();
            })
            .catch((e) => reject(e));
    });
};

const addInvoiceListItem = ({ commit, dispatch }, {
    product,
    billingCycle,
    recurringBilling,
    frequency,
    numberOfPayments,
    quantity,
    orderId,
}) => {
    const orderItem = transformProductToOrderItem(
        product,
        billingCycle,
        recurringBilling,
        frequency,
        numberOfPayments,
        quantity,
    );

    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/${orderId}/orderItems`, orderItem)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_INVOICE_ATTACHMENTS', data);
            } else {
                commit('SET_INVOICE', data);
            }

            commit('SYNC_INVOICES');
        })
        .catch(() => {});
};


const updateInvoiceListItem = ({ commit, dispatch }, { orderItem, orderId }) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/${orderId}/orderItems/${orderItem.id}`, orderItem)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_INVOICE_ATTACHMENTS', data);
            } else {
                commit('SET_INVOICE', data);
            }

            commit('SYNC_INVOICES');
        })
        .catch((e) => { throw e; });
};

const deleteInvoiceListItem = ({ commit, dispatch }, { orderItemId, orderId }) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/${orderId}/orderItems/${orderItemId}`)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_INVOICE_ATTACHMENTS', data);
            } else {
                commit('SET_INVOICE', data);
            }

            commit('SYNC_INVOICES');
        })
        .catch(() => {});
};

const addQuoteListItem = ({ commit, dispatch }, { product, quantity, quoteId }) => {
    const quoteItem = transformProductToQuoteItem(product, quantity);

    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${quoteId}/quoteItems`, quoteItem)
        .then(async({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_QUOTE_ATTACHMENTS', data);
            } else {
                commit('SET_QUOTE', data);
            }
            commit('SYNC_QUOTES');
        })
        .catch(() => {});
};

const updateQuoteListItem = ({ commit, dispatch }, { quoteItem, quoteId }) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${quoteId}/quoteItems/${quoteItem.id}`, quoteItem)
        .then(async({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_QUOTE_ATTACHMENTS', data);
            } else {
                commit('SET_QUOTE', data);
            }

            commit('SYNC_QUOTES');
        })
        .catch(() => {});
};

const deleteQuoteListItem = ({ commit, dispatch }, { id, quoteId }) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${quoteId}/quoteItems/${id}`)
        .then(async ({ data }) => {
            if (data.attachments && data.attachments.length) {
                await dispatch('LOAD_QUOTE_ATTACHMENTS', data);
            } else {
                commit('SET_QUOTE', data);
            }

            commit('SYNC_QUOTES');
        })
        .catch(() => {});
};

const processPublicPayment = (_, paymentInfo) => {
    return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${paymentInfo.invoiceXID}/acceptPayment`, paymentInfo)
        .then(({ data }) => {
            return data;
        });
};

const completeAuthorizedPayment = (_, authorizationDetails) => {
    return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/sales/invoice/${authorizationDetails.invoiceXID}/completeAuthorizedPayment`, authorizationDetails)
        .then(({ data }) => {
            return data;
        });
};

const processPayment = ({ dispatch, rootGetters }, paymentInfo) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/payments`, paymentInfo)
            .then((response) => {
                if (rootGetters['auth/hasRecurringPaymentsFeature']) {
                    dispatch('LOAD_CONTACT_RECURRING_PAYMENTS', paymentInfo.contactId);
                    dispatch('LOAD_RECURRING_PAYMENTS');
                }

                dispatch('LOAD_CONTACT_PAYMENTS', paymentInfo.contactId);
                dispatch('LOAD_CONTACT_INVOICES', paymentInfo.contactId);

                resolve(response.data);
            })
            .catch((e) => reject(e));
    });
};

const sendInvoice = ({ commit }, { invoiceId, email }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/invoices/${invoiceId}/send`, email)
            .then(({ data }) => {
                commit('SET_INVOICE_SENT', invoiceId);
                commit('SYNC_INVOICES');
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const sendInvoiceReminder = (_, { invoiceId, email }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/invoices/${invoiceId}/send`, email)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const sendInvoiceEmail = async ({ commit, dispatch }, {
    body,
    buttonLink,
    buttonText,
    from,
    userId,
    fromAddress,
    invoiceId,
    recipient,
    signature,
    subject,
    to,
}) => {
    await dispatch('contacts/SEND_EMAIL', {
        body,
        buttonLink,
        buttonText,
        from,
        userId,
        fromAddress,
        recipient,
        signature,
        subject,
        to,
    }, { root: true });
    dispatch('SET_INVOICE_AS_SENT', { invoiceId });
    commit('SET_NESTED_MODAL_DIRTY', false, { root: true });
};

const setInvoiceAsSent = async ({ commit }, { invoiceId }) => {
    const { data: { setInvoiceAsSent: invoiceWasSent } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation setInvoiceAsSent($invoiceId: ID!) {
                setInvoiceAsSent(invoiceId: $invoiceId)
            }
            `,
        variables: {
            invoiceId,
        },
        fetchPolicy: 'no-cache',
    });

    if (invoiceWasSent) {
        commit('SET_INVOICE_SENT', invoiceId);
        commit('SYNC_INVOICES');
    }
};

const convertQuoteToInvoice = ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${id}/convert`)
            .then(({ data }) => {
                commit('ADD_INVOICE', data);
                commit('SET_INVOICE', data);
                commit('SET_QUOTE_ORDER_ID', { quoteId: id, orderId: data.id });
                commit('SYNC_QUOTES');
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const loadAppSalesInfo = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/appSalesInfo`)
            .then(({ data }) => {
                commit('sales/SET_APP_SALES_INFO', data, { root: true });
                resolve(data);
            })
            .catch(reject);
    });
};

const loadQuoteDashboardStats = () => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/dashboardStats`);
};

const sendQuote = ({ commit }, { quoteId, email }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${quoteId}/send`, email)
            .then(({ data }) => {
                commit('SET_QUOTE_SENT', quoteId);
                commit('SYNC_QUOTES');
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const sendQuoteReminder = (_, { quoteId, email }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/${quoteId}/send`, email)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const loadRecurringPayment = (_, paymentId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/recurringPayments/${paymentId}`)
            .then(({ data }) => {
                resolve(transformRecurringPayment(data));
            })
            .catch(reject);
    });
};

const loadRecurringPayments = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/recurringPayments`)
            .then(({ data }) => {
                commit('SET_ALL_RECURRING_PAYMENTS', data.subscriptions.map((payment) => transformRecurringPayment(payment)));
                resolve();
            })
            .catch(reject);
    });
};

const loadJobRecurringPayments = ({ commit }, paymentId) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/orders/jobRecurring/${paymentId}`)
        .then(({ data }) => {
            commit('SET_RECURRING_PAYMENT_HISTORY', data);
        });
};

const addInvoiceFile = ({ dispatch }, payload) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/invoices/fileId`, payload)
        .then(() => {
            dispatch('LOAD_INVOICE', payload.invoiceId);
        });
};

const deleteInvoiceFile = ({ dispatch }, payload) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/invoices/deleteFileId`, payload)
        .then(() => {
            dispatch('LOAD_INVOICE', payload.invoiceId);
        });
};

const loadInvoiceAttachments = ({ commit }, invoice) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${invoice.contactId}/files`)
        .then(({ data }) => {
            commit('SET_INVOICE_ATTACHMENTS', { data, invoice });
        })
        .catch((e) => e);
};

const addQuoteFile = ({ dispatch }, payload) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/fileId`, payload)
        .then(() => {
            dispatch('LOAD_QUOTE', payload.quoteId);
        });
};

const deleteQuoteFile = ({ dispatch }, payload) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/quotes/deleteFileId`, payload)
        .then(() => {
            dispatch('LOAD_QUOTE', payload.quoteId);
        });
};

const loadQuoteAttachments = ({ commit }, quote) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${quote.contactId}/files`)
        .then(({ data }) => {
            commit('SET_QUOTE_ATTACHMENTS', { data, quote });
        })
        .catch((e) => e);
};

const transformRecurringPayment = (payment) => {
    let finalPaymentDate = null;
    const { endDate, billingCycle } = payment;

    if (endDate && billingCycle) {
        finalPaymentDate = moment(endDate)
            .startOf('day')
            .subtract(0, timeUnitStatus(billingCycle))
            .format('YYYY-MM-DDTHH:mm:ss');
    }

    return {
        ...payment,
        finalPaymentDate,
    };
};

const transformProductToOrderItem = (
    product,
    billingCycle,
    recurringBilling,
    frequency,
    numberOfPayments,
    quantity = 1,
) => {
    return {
        name: product.productName,
        description: product.productDesc,
        cost: product.productPrice,
        price: product.productPrice,
        billingCycle,
        recurringBilling,
        frequency,
        numberOfPayments,
        quantity,
        product,
    };
};

const transformProductToQuoteItem = (product, quantity = 1) => {
    return {
        name: product.productName,
        description: product.productDesc,
        price: product.productPrice,
        quantity,
        productId: product.id,
    };
};

const loadCheckoutFormList = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/checkoutForm`)
            .then(({ data }) => {
                commit('SET_CHECKOUT_FORMS', data);
                resolve(data);
            })
            .catch(reject);
    });
};

const publicLoadCheckoutForm = ({ commit }, { appId, id }) => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/checkoutForm/${id}`)
            .then(({ data }) => {
                if (appId) {
                    amplitude.v2.setUserId(appId);
                    amplitude.v2.logEvent(amplitude.v2.events.CHECKOUT_FORM_VIEWED);
                }

                commit('SET_APP_CURRENCY_CODE', data.appCurrencyCode, { root: true });
                commit('SET_PUBLIC_MONEY_FLAGS', data.featureFlags);
                resolve(data);
            })
            .catch(reject);
    });
};

const publicCheckoutSetupPaypalOrder = ({ checkoutFormId, context }) => {
    return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/checkoutForm/${checkoutFormId}/payPal/orders`, context)
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            throw e;
        });
};

const publicCheckoutSetupPaypalBillingAgreement = ({ checkoutFormId, context }) => {
    return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/checkoutForm/${checkoutFormId}/payPal/billingAgreements`, context)
        .then(({ data }) => {
            return data;
        })
        .catch((e) => {
            throw e;
        });
};

const getCheckoutForm = (_, id) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/checkoutForm/${id}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(reject);
    });
};

const createCheckoutForm = (_, { form, saveField }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/checkoutForm`, form)
            .then(({ data }) => {
                if (saveField) {
                    amplitudeEvent(saveField);
                }

                resolve(data);
            })
            .catch(reject);
    });
};

const editCheckoutForm = (_, { form, saveField }) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/checkoutForm`, form)
            .then(({ data }) => {
                if (saveField) {
                    amplitudeEvent(saveField);
                }

                resolve(data);
            })
            .catch(reject);
    });
};

const deleteCheckoutForm = (_, checkoutFormId) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/checkoutForm/${checkoutFormId}`);
};

const doesCheckoutUrlExist = async (_, checkoutUrl) => {
    if (!checkoutUrl || typeof checkoutUrl !== 'string') {
        return Promise.reject();
    }

    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/checkoutForm/verifyUniqueUrl/${checkoutUrl}`)
        .then(() => true)
        .catch((e) => {
            if (e.response.status === 404) {
                return false;
            }

            sentry.log('Failed to search checkout url');

            return Promise.reject();
        });
};

const publicCheckoutApplyPromoCode = ({ commit }, formData) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/checkoutForm/applyPromoCode`, formData)
            .then(({ data }) => {
                commit('SET_CHECKOUT_DISCOUNTS', data);
                resolve(data);
            }).catch((e) => reject(e));
    });
};

const processCheckoutPayment = (_, paymentInfo) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/checkoutForm/processPayment`, paymentInfo)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const retryProcessCheckoutPayment = (_, paymentInfo) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_PUBLIC_SPA_API_URL}/v1/checkoutForm/retryProcessPayment`, paymentInfo)
            .then(({ data }) => {
                resolve(data);
            })
            .catch((e) => reject(e));
    });
};

const loadCheckoutFormUpsells = (_, { checkoutFormId, options = { limit: 1000, offset: 0 } }) => {
    return new Promise((resolve) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/upsell/list/${checkoutFormId}`, options)
            .then(({ data }) => {
                if (!data) {
                    resolve([]);
                } else {
                    resolve(data.map((upsell) => {
                        return {
                            upsellId: upsell.upsellId,
                            productId: upsell.productId,
                            productName: upsell.upsellName,
                            productPrice: upsell.upsellPrice,
                            productDesc: upsell.upsellDescription,
                            recurringBilling: upsell.recurring,
                            frequency: upsell.frequency,
                            billingCycle: upsell.billingCycle,
                            numberOfPayments: upsell.numberOfPayments,
                        };
                    }));
                }
            });
    });
};

const addCheckoutFormUpsell = (_, { checkoutFormId, upsell }) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/upsell/${checkoutFormId}`, createUpsellRequest(upsell));
};

const updateCheckoutFormUpsell = (_, { checkoutFormId, upsell }) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/upsell/${checkoutFormId}`, createUpsellRequest(upsell));
};

const deleteCheckoutFormUpsell = (_, { upsellId }) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/upsell/${upsellId}`);
};

const createUpsellRequest = (upsell) => {
    return {
        upsellId: upsell.upsellId,
        productId: upsell.productId,
        upsellProductId: upsell.productId,
        upsellName: upsell.productName,
        upsellPrice: upsell.productPrice,
        upsellDescription: upsell.productDesc,
        recurring: upsell.recurringBilling,
        frequency: upsell.frequency,
        billingCycle: upsell.billingCycle,
        numberOfPayments: upsell.numberOfPayments,
    };
};

const amplitudeEvent = (saveField) => {
    let event;

    switch (saveField) {
    case SAVE_FIELDS.PRODUCTS:
        event = amplitude.v2.events.CHECKOUT_FORM_V2_PRODUCT_ADDED;
        break;
    case SAVE_FIELDS.EDIT_PRODUCTS:
        event = amplitude.v2.events.CHECKOUT_FORM_V2_PRODUCT_EDITED;
        break;
    case SAVE_FIELDS.UPSELLS:
        event = amplitude.v2.events.CHECKOUT_FORM_V2_PRODUCT_ADDED;
        break;
    default:
        return;
    }

    amplitude.v2.logEvent(event);
};
