import { IGNORED_SUB_TYPES, NOTIFICATION_STATUS } from '@/shared/constants/notifications.constants';

export default {
    hasNotifications(_, getters) {
        return getters.filteredNotifications
            .some(({ status }) => status === NOTIFICATION_STATUS.NEW || status === NOTIFICATION_STATUS.UNREAD);
    },

    filteredNotifications({ notifications }) {
        return notifications
            .filter(({ subType }) => !IGNORED_SUB_TYPES.includes(subType))
            .sort((a, b) => new Date(b.updateTime) - new Date(a.updateTime));
    },
};
