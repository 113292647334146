import {
    requiredBootQuery,
    deferredBootQuery,
    fetchCountryOptions,
    fetchGlobalSearch,
    fetchPublicCountryOptions,
    fetchPublicRegionOptions,
    fetchRegionOptions,
    updatePlaceId,
} from '@/shared/api';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import fullstory from '@/analytics/fullstory';
import sentry from '@/analytics/sentry';

export default {
    GLOBAL_SEARCH(context, payload) {
        return globalSearch(context, payload);
    },

    LOAD_BOOT(context, payload) {
        return loadBoot(context, payload);
    },

    LOAD_REQUIRED_BOOT(context, payload) {
        return loadRequiredBoot(context, payload);
    },

    LOAD_DEFERRED_BOOT(context, payload) {
        return loadDeferredBoot(context, payload);
    },

    LOAD_PUBLIC_BOOT(context, payload) {
        return loadPublicBoot(context, payload);
    },

    LOAD_REGION_OPTIONS(context, payload) {
        return loadRegionOptions(context, payload);
    },

    LOAD_PUBLIC_REGION_OPTIONS(context, payload) {
        return loadPublicRegionOptions(context, payload);
    },

    LOAD_COUNTRY_OPTIONS(context, payload) {
        return loadCountryOptions(context, payload);
    },

    LOAD_PUBLIC_COUNTRY_OPTIONS(context, payload) {
        return loadPublicCountryOptions(context, payload);
    },

    SAVE_GOOGLE_PLACE_ID(context, payload) {
        return savePlaceId(context, payload);
    },

    UPDATE_FAVICON(context, payload) {
        return updateFavicon(context, payload);
    },

    UPDATE_DOCUMENT_TITLE(context, payload) {
        return updateDocumentTitle(context, payload);
    },
};

const globalSearch = async (_, term) => {
    if (term === '') {
        return [];
    }

    const { data } = await fetchGlobalSearch(term);

    return data;
};

const loadBoot = async ({
    state,
    commit,
    dispatch,
    rootState,
}, { reload = false } = {}) => {
    if (state.appLoaded && !reload) {
        return;
    }

    if (process.env.VUE_APP_APP_ID) {
        commit('SET_IS_LOCAL');
    }

    try {
        await dispatch('LOAD_REQUIRED_BOOT');
        dispatch('LOAD_DEFERRED_BOOT');
    } catch (error) {
        const data = { message: error.message };

        sentry.log('LOAD BOOT FAILED', data);

        if (rootState.auth.session.logInAsSession) {
            handleLogInAsError(error, commit);
        } else {
            if (rootState && rootState.auth) {
                if (rootState.auth.lastSession) {
                    data.lastSessionApp = rootState.auth.lastSession.coreAppId;
                    data.lastSessionEmail = rootState.auth.lastSession.email;
                }

                if (rootState.auth.session) {
                    data.currentSessionApp = rootState.auth.session.coreAppId;
                    data.currentSessionJwt = rootState.auth.session.jwt;
                    data.currentSessionReferredByPartner = rootState.auth.session.referredByPartner;
                }
            }

            commit('SET_BOOT_LOAD_ERROR', true);

            // We are clearing the last session data here so that an unauthorized boot call clears
            // any saved session data in the case of people attempting to login with two different CAS accounts.
            commit('auth/CLEAR_LAST_SESSION', null, { root: true });
            commit('auth/SET_CORE_APP_ID', '', { root: true });
        }

        throw error;
    }
};

const loadRequiredBoot = async ({
    state,
    commit,
    dispatch,
    rootState,
}, { reload = false } = {}) => {
    const isLogInAsSession = rootState.auth.session.logInAsSession;
    const { data } = await requiredBootQuery({
        reload,
        isLogInAsSession,
    });

    let { account } = data;
    const {
        boot: {
            users,
            user,
            account: bootAccount,
            legacyAppLocale,
            appCountryCode,
            appSettings,
            appCurrencyCode,
            appSalesInfo,
            customersListId,
            clientsContactListId,
            leadsContactListId,
            newLeadsContactListId,
            sessionAffinity,
            subscriptionInfo,
        },
        featureFlags,
    } = data;

    account = {
        ...account,
        ...bootAccount,
        subscriptionInfo,
        features: mapAccountFeatures(account.features),
        limits: mapAccountLimits(account.limits),
    };

    if (sessionAffinity != null) {
        commit('auth/SET_SESSION_AFFINITY', sessionAffinity);
    }

    const hasStealthAccess = user && (user.isLocal || user.hasStealth);

    if (!hasStealthAccess && legacyAppLocale != null && state.locale !== legacyAppLocale) {
        // This forces the user into using en-us locale.
        commit('SET_LOCALE', legacyAppLocale);
    }

    commit('SET_APP_COUNTRY_CODE', appCountryCode);
    commit('SET_GOOGLE_PLACE_ID', appSettings.googlePlaceId);
    commit('auth/SET_USER', user);
    commit('auth/SET_USERS', users);
    commit('featureFlags/SET_FEATURE_FLAGS', featureFlags, { root: true });
    commit('auth/SET_ACCOUNT', account);
    commit('sales/SET_APP_SALES_INFO', appSalesInfo, { root: true });
    commit('settings/SET_MAX_TOTAL_CUSTOM_FIELDS', appSettings.maxTotalCustomFields);
    commit('auth/SET_COMPANY_LOGO', account.companyProfile.logoUrl);
    commit('SET_APP_CURRENCY_CODE', appCurrencyCode);
    commit('contacts/SET_LEADS_CONTACT_LIST_ID', leadsContactListId);
    commit('contacts/SET_CLIENTS_CONTACT_LIST_ID', clientsContactListId);
    commit('contacts/SET_NEW_LEADS_CONTACT_LIST_ID', newLeadsContactListId);
    commit('contacts/SET_CUSTOMERS_LIST_ID', customersListId);

    await Promise.all([
        dispatch('tutorials/LOAD_TUTORIALS'),
        dispatch('dashboard/LOAD_CUSTOM_DASHBOARD'),
        dispatch('preferences/LOAD_PREFERENCES'),
    ]);
};

const loadDeferredBoot = async ({
    commit,
    dispatch,
    rootState,
}) => {
    const { data } = await deferredBootQuery();

    const {
        conversations,
        accountInfo,
    } = data;

    const hasKeapPhone = accountInfo != null && accountInfo.phoneNumber;

    if (hasKeapPhone) {
        commit('communication/SET_CONVERSATIONS', { conversations });
        commit('auth/SET_KEAP_PHONE_ACCOUNT_INFO', accountInfo);
        dispatch('communication/START_POLL_CONVERSATIONS');
    }

    try {
        await Promise.all([
            dispatch('tags/LOAD_TAG_COUNT'),
            dispatch('automatedSms/LOAD_AUTOMATED_SMS_ACCOUNT'),
            dispatch('contacts/LOAD_CONTACT_LIST_COUNT', {}),
        ]);
    } finally {
        commit('SET_DEFERRED_BOOT_LOADED');
    }

    amplitude.v2.setUserProperties({
        'Has Keap Phone': Boolean(hasKeapPhone),
        'Total Number of Contacts': rootState.contacts.contactTotal,
        'Total Number of Contact Tags': rootState.tags.tagCount,
    });

    intercom.updateUserProperties({
        total_contacts: rootState.contacts.contactTotal,
    });

    fullstory.identify(rootState.auth.user.casId, {
        totalNumberOfContacts: rootState.contacts.contactTotal,
    });
};

const loadPublicBoot = ({ commit }, { appId = '' }) => {
    if (process.env.VUE_APP_APP_ID) {
        commit('SET_PUBLIC_CORE_APP_ID', process.env.VUE_APP_APP_ID);
    }

    if (appId) {
        commit('SET_PUBLIC_CORE_APP_ID', appId);
    }
};

const loadRegionOptions = async ({ commit, state }, { countryCode }) => {
    if (countryCode && state.cachedRegionOptions[countryCode]) {
        commit('SET_REGION_OPTIONS', state.cachedRegionOptions[countryCode]);

        return state.cachedRegionOptions[countryCode];
    }

    const { data } = await fetchRegionOptions(countryCode);

    state.cachedRegionOptions[countryCode] = data;

    commit('SET_REGION_OPTIONS', data);

    return data;
};

const loadPublicRegionOptions = async ({ commit }, { countryCode }) => {
    const { data } = await fetchPublicRegionOptions(countryCode);

    commit('SET_REGION_OPTIONS', data);

    return data;
};

const loadCountryOptions = async ({ commit, state: { countryOptions } }) => {
    if (Array.isArray(countryOptions) && countryOptions.length > 0) {
        return countryOptions;
    }

    const { data } = await fetchCountryOptions();

    commit('SET_COUNTRY_OPTIONS', data);

    return data;
};

const loadPublicCountryOptions = async ({ commit }) => {
    const { data } = await fetchPublicCountryOptions();

    commit('SET_COUNTRY_OPTIONS', data);

    return data;
};

const savePlaceId = async ({ commit }, { key, value }) => {
    const { data: { result } } = await updatePlaceId(key, value);

    commit('SET_GOOGLE_PLACE_ID', value);

    return result;
};

const FAVICON_URL_32 = '/img/icons/favicon-32x32.png';
const FAVICON_URL_16 = '/img/icons/favicon-16x16.png';
const HAS_BADGE_FAVICON_URL_32 = '/img/icons/badge-favicon-32x32.png';
const HAS_BADGE_FAVICON_URL_16 = '/img/icons/badge-favicon-16x16.png';

/* istanbul ignore next */
const updateFavicon = (_, { hasBadge }) => {
    const favicon32 = document.querySelector("head > link[rel='icon'][sizes='32x32']");
    const favicon16 = document.querySelector("head > link[rel='icon'][sizes='16x16']");

    favicon32.href = hasBadge
        ? HAS_BADGE_FAVICON_URL_32
        : FAVICON_URL_32;
    favicon16.href = hasBadge
        ? HAS_BADGE_FAVICON_URL_16
        : FAVICON_URL_16;
};

/* istanbul ignore next */
const updateDocumentTitle = ({ state }, { unreadCount }) => {
    const { title } = state;

    unreadCount
        ? document.title = `(${unreadCount}) ${title}`
        : document.title = title;
};

const mapAccountFeatures = (accountFeatures) => {
    const features = {};

    accountFeatures.forEach((feature) => {
        features[feature] = true;
    });

    return features;
};

const mapAccountLimits = (accountLimits) => {
    const limits = {};

    accountLimits.forEach((limit) => {
        limits[limit.id] = limit.value;
    });

    return limits;
};

const handleLogInAsError = (error, commit) => {
    const logInAsErrors = fetchLogInAsErrors(error.graphQLErrors);

    commit('SET_LOG_IN_AS_LOAD_ERRORS', logInAsErrors, { root: true });

    // We are clearing any saved session data in this particular case
    commit('auth/CLEAR_LAST_SESSION', null, { root: true });
    commit('auth/SET_CORE_APP_ID', '', { root: true });
    commit('auth/SET_JWT', null, { root: true });

    throw error;
};

const fetchLogInAsErrors = (graphQLErrors) => {
    let outputArray = [];

    graphQLErrors.forEach((error) => {
        if (error.extensions.code === 'LOG_IN_AS_ERROR') {
            outputArray = outputArray.concat(error.extensions.exception.customErrorMessages);
        } else if (error.extensions.code === 'INTERNAL_SERVER_ERROR') {
            outputArray = outputArray.concat(error.message);
        }
    });

    return outputArray;
};
