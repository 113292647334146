export default {
    SET_TAGS(state, tags) {
        state.tags = tags;
    },

    SET_TAG_CATEGORIES(state, tagCategories) {
        state.tagCategories = tagCategories;
    },

    SET_TAG_COUNT(state, tagCount) {
        state.tagCount = tagCount;
    },

    ADD_TAG(state, tag) {
        const hasCategoryIdButNeedsName = tag.category && tag.category.id && !tag.category.name && state.tagCategories;

        if (hasCategoryIdButNeedsName) {
            const storedCategory = state.tagCategories.find((category) => category.id === tag.category.id);

            if (storedCategory) {
                tag.category.name = storedCategory.name;
            }
        }

        state.tags = [...state.tags, tag];
    },

    ADD_TAG_CATEGORY(state, tagCategory) {
        state.tagCategories = [...state.tagCategories, tagCategory];
    },

    UPDATE_TAG(state, tagUpdate) {
        state.tags = [...state.tags.map((tag) => {
            if (tag.id === tagUpdate.id) {
                return tagUpdate;
            }

            return tag;
        })];
    },

    DELETE_TAG(state, tagId) {
        state.tags = [...state.tags.filter(({ id }) => id !== tagId)];
    },
};
