<template>
    <div class="step-options">
        <div class="input-container">
            <ds-multiselect
                data-qa="select-step-type"
                searchable
                show-search-icon
                :placeholder="$t('whatWillBeAutomated')"
                :options="availableStepOptions"
                value-prop="type"
                label-prop="name"
                @input="(payload) => $emit('selected', payload)"
            />
        </div>

        <section class="select-card">
            <div class="suggested-cards step-cards" data-qa="suggested-cards">
                <p class="suggested-options">
                    {{ $t('suggestedOptions') }}
                </p>

                <div class="trigger-cards">
                    <automation-type-card
                        v-for="step in allSteps.slice(0, $options.SUGGESTED_MAX)"
                        :key="step.type"
                        :automation-item="step"
                        :data-qa="`step-type_${step.type}`"
                        class="step-card suggested"
                        @selected="$emit('selected', step)"
                    />
                </div>
            </div>

            <automation-type-section
                v-for="section in stepsBySection"
                :key="section.title"
                automation-type="step"
                :section="section"
                @selected="(payload) => $emit('selected', payload)"
            />
        </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AutomationTypeCard from '@/automations/components/AutomationTypeCard';
import AutomationTypeSection from '@/automations/components/AutomationTypeSection';
import { SUGGESTED_MAX } from '@/automations/constants/automations.constants';

export default {
    components: {
        AutomationTypeCard,
        AutomationTypeSection,
    },

    SUGGESTED_MAX,

    data() {
        return {
            automationProperties: {},
        };
    },

    computed: {
        ...mapGetters({
            stepsBySection: 'automations/stepsBySection',
            allSteps: 'automations/allSteps',
        }),

        availableStepOptions() {
            return this.allSteps.map((step) => ({
                ...step,
                name: this.$t(step.name),
            }));
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/setup';

    .suggested-cards {
        background-color: $color-blue-050;
    }

    .step-options {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
</style>

<i18n>
{
    "en-us": {
        "whatWillBeAutomated": "What will be automated?",
        "suggestedOptions": "Suggested options"
    }
}
</i18n>
