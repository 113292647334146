<template>
    <div>
        <ds-input-field
            v-model="product.name"
            data-qa="product-name"
            name="billing.product.modal.name"
            :label="$t('billing.product.modal.name')"
            required
            autofocus
            :submitted="submitted"
        />

        <ds-text-area-field
            v-model="product.description"
            data-qa="product-description"
            name="billing.product.modal.description"
            :label="$t('billing.product.modal.description')"
            :submitted="submitted"
        />

        <div class="input-group">
            <ds-input-field
                v-model="product.rate"
                data-qa="product-price"
                type="number"
                name="billing.product.modal.rate"
                :label="$t('billing.product.modal.rate')"
                :submitted="submitted"
            />

            <ds-input-field
                v-if="showSaleOptions"
                v-model="product.quantity"
                data-qa="product-quantity"
                type="number"
                name="billing.product.modal.quantity"
                :label="$t('billing.product.modal.quantity')"
                required
                :min="1"
                :submitted="submitted"
            />
        </div>
    </div>
</template>

<script>

export default {
    props: {
        value: Object,
        showSaleOptions: Boolean,
        submitted: Boolean,
    },

    data() {
        return {
            product: this.value,
        };
    },

    watch: {
        value: {
            handler(val) {
                this.product = val;
            },
            deep: true,
        },

        product: {
            handler(val) {
                this.$emit('input', val);
            },
            deep: true,
        },
    },
};
</script>
