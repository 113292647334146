<template lang="html">
    <ds-chip with-interaction :class="['chip-wrapper', { active, readonly }]">
        <template #default>
            <span
                :data-qa="`${valueLowerCase}-contact-type`"
                class="label"
            >
                {{ $t(`global.contactType.${contactType}`) }}
            </span>
        </template>

        <template #trailing>
            <span
                class="contact-type-chip"
                :class="[{'contact-type-chip-circle' : Boolean(value) }, `contact-type-${valueLowerCase}-bg`]"
            />
        </template>
    </ds-chip>
</template>

<script>
import { CONTACT_TYPES } from '@/contacts/contacts.constants';

const stripContactType = (str) => str && str.replace(/\s/g, '').trim();

export default {
    props: {
        active: Boolean,
        value: String,
        readonly: Boolean,
    },

    computed: {
        contactType() {
            return this.value && Object.values(CONTACT_TYPES).includes(this.value)
                ? stripContactType(this.value.toLowerCase())
                : 'addto';
        },

        valueLowerCase() {
            return this.value ? this.value.toLowerCase() : 'addto';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $contact-type-lead-color: #FF7B00;
    $contact-type-customer-color: #00A3FF;
    $contact-type-other-color: $color-gray-800;

    .label {
        @include transition(opacity);

        opacity: 0.5;
    }

    .contact-type-chip {
        @include transition(background-color);
    }

    .contact-type-chip-circle {
        @include circle($icon-size-small);

        background-color: $color-paper;
    }

    .chip-wrapper {
        &:hover {
            .label {
                opacity: 1;
            }
        }

        &.readonly,
        &.active {
            .label {
                opacity: 1;
            }

            .contact-type-lead-bg {
                background-color: $contact-type-lead-color;
            }

            .contact-type-customer-bg {
                background-color: $contact-type-customer-color;
            }

            .contact-type-other-bg {
                background-color: $contact-type-other-color;
            }
        }
    }
</style>
