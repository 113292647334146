import axios from 'axios';

import { PAYMENT_REFERENCE_TYPES } from '@/money/money.constants';
import sentry from '@/analytics/sentry';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';

import {
    FF_KEAP_SAMPLE_DATA,
} from '@/shared/constants/featureFlag.constants';

export default {
    LOAD_PRODUCTS(context, payload) {
        return loadProducts(context, payload);
    },

    CONNECT_PAYMENT_PROCESSOR(context, processorInfo) {
        return connectPaymentProcessor(context, processorInfo);
    },

    ADD_PRODUCT(context, payload) {
        return saveProduct(context, payload);
    },

    DELETE_PRODUCT(context, productId) {
        return deleteProduct(context, productId);
    },

    LOAD_SALES_TAX_RATES(context, payload) {
        return loadSalesTaxRates(context, payload);
    },

    LOAD_PROMO_CODES(context) {
        return loadPromoCodes(context);
    },

    LOAD_PROMO_CODE(context, promoCodeId) {
        return loadPromoCode(context, promoCodeId);
    },

    LOAD_PROMO_CODE_PERFORMANCE(context, promoCodeId) {
        return loadPromoCodePerformance(context, promoCodeId);
    },

    SAVE_PROMO_CODE(context, promoCode) {
        return savePromoCode(context, promoCode);
    },

    EDIT_PROMO_CODE(context, promoCode) {
        return editPromoCode(context, promoCode);
    },

    DELETE_PROMO_CODE(context, promoCode) {
        return deletePromoCode(context, promoCode);
    },

    ADD_SALES_TAX_RATE(context, payload) {
        return addSalesTaxRate(context, payload);
    },

    EDIT_SALES_TAX_RATE(context, productId) {
        return editSalesTaxRate(context, productId);
    },

    DELETE_SALES_TAX_RATE(context, productId) {
        return deleteSalesTaxRate(context, productId);
    },

    DELETE_PROCESSOR(context, payload) {
        return deleteProcessor(context, payload);
    },

    DELETE_PAYPAL(context, payload) {
        return deletePaypal(context, payload);
    },

    EDIT_PRODUCT(context, productInfo) {
        return editProduct(context, productInfo);
    },

    LOAD_PAYMENT_PROCESSORS(context, payload) {
        return loadPaymentProcessors(context, payload);
    },

    CREATE_INFUSION_PAY_ACCOUNT(context, request) {
        return createInfusionPayAccount(context, request);
    },

    SET_DEFAULT_PROCESSOR(context, paymentAccount) {
        return setDefaultProcessor(context, paymentAccount);
    },

    SET_PAYPAL_SETTINGS(context, paypalSettings) {
        return setPaypalSettings(context, paypalSettings);
    },

    GET_PAYPAL_SETTINGS(context) {
        return getPaypalSettings(context);
    },

    GET_ACCOUNT_MANAGEMENT_URI(context) {
        return getAccountManagementUri(context);
    },

    LOAD_CURRENCIES(context) {
        return loadCurrencies(context);
    },

    SET_APP_CURRENCY(context, code) {
        return setAppCurrency(context, code);
    },

    LOAD_INFUSION_PAY_SETUP_INFO(context) {
        return loadInfusionPaySetupInfo(context);
    },

    INITIATE_PAYPAL_ONBOARDING(_, payload) {
        return initiatePaypalOnboarding(payload);
    },

    SAVE_PAYPAL_ONBOARD_DATA(context, payload) {
        return savePaypalOnboardData(context, payload);
    },

    INITIATE_STRIPE_ONBOARDING(_, payload) {
        return initiateStripeOnboarding(payload);
    },

    SAVE_STRIPE_ONBOARD_DATA(context, payload) {
        return saveStripeOnboardData(context, payload);
    },

    CONNECT_NEXUS_PROCESSOR(context, processorInfo) {
        return connectNexusProcessor(context, processorInfo);
    },

    CONNECT_EWAY_PROCESSOR(context, processorInfo) {
        return connectEWayProcessor(context, processorInfo);
    },
};

const createInfusionPayAccount = ({ dispatch }, request) => {
    return new Promise((resolve, reject) => {
        if (request === null) {
            request = {
                wePayRiskToken: null,
                adminId: null,
                country: null,
                industry: null,
                acceptsDebit: null,
            };
        }

        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/createNewInfusionPayAccount`, request)
            .then(() => {
                dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'connectPaymentProcessor', { root: true });

                dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });

                amplitude.v2.logEvent(amplitude.v2.events.PAYMENT_PROCESSOR_CONNECTED, {
                    'Payment Processor': 'WePay',
                });

                intercom.logEvent(intercom.events.PAYMENT_PROCESSOR_CONNECTED);

                intercom.logEvent(intercom.events.WEPAY_CONNECTED);

                resolve();
            })
            .catch(reject);
    });
};

const getAccountManagementUri = () => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/getAccountManagementUri`).then((data) => {
            resolve(data.data);
        })
            .catch(reject);
    });
};

const connectPaymentProcessor = ({ dispatch }, processorInfo) => {
    return new Promise((resolve, reject) => {
        if (processorInfo.processorType === 'authorize') {
            delete processorInfo.processorType;

            return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/createOrUpdateAuthorizeAccount`, processorInfo)
                .then(({ data }) => {
                    amplitude.v2.setUserProperties({
                        'Payment Processor': 'Autorize.Net',
                    });

                    amplitude.v2.logEvent(amplitude.v2.events.PAYMENT_PROCESSOR_CONNECTED, {
                        'Payment Processor': 'Autorize.Net',
                    });

                    intercom.updateUserProperties({
                        'Payment Processor': 'Autorize.Net',
                    });

                    intercom.logEvent(intercom.events.PAYMENT_PROCESSOR_CONNECTED);

                    intercom.logEvent(intercom.events.AUTHORIZE_NET_CONNECTED);

                    dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'connectPaymentProcessor', { root: true });

                    dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });

                    resolve(data);
                })
                .catch(reject);
        }

        return reject;
    });
};

const loadProducts = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/products/list`)
            .then(({ data: { productList } }) => {
                commit('SET_PRODUCTS', productList);
                resolve();
            })
            .catch(reject);
    });
};

const loadPaymentProcessors = ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/processors`)
            .then(({ data: paymentAccounts }) => {
                commit('SET_PAYMENT_PROCESSORS', paymentAccounts);

                dispatch('GET_PAYPAL_SETTINGS');

                resolve();
            })
            .catch(reject);
    });
};

const deleteProcessor = ({ dispatch }, { accountIdentifier }) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/merchant/${accountIdentifier}`)
            .then(() => {
                dispatch('LOAD_PAYMENT_PROCESSORS');
                dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
                resolve();
            })
            .catch(reject);
    });
};

const deletePaypal = ({ dispatch }) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/paypal`)
            .then(() => {
                dispatch('LOAD_PAYMENT_PROCESSORS');
                dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
                resolve();
            })
            .catch(reject);
    });
};

const setDefaultProcessor = ({ dispatch }, paymentAccount) => {
    const defaultRegularMerchantUrl = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/defaultMerchant/${paymentAccount.accountIdentifier}%7C${paymentAccount.accountType}`;

    if (paymentAccount.accountType === 'WePay') {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/defaultMerchant/infusionpay`)
            .then(() => {
                dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
                dispatch('LOAD_PAYMENT_PROCESSORS');
            })
            .catch((e) => { throw e; });
    }

    return axios.put(defaultRegularMerchantUrl)
        .then(() => {
            dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
            dispatch('LOAD_PAYMENT_PROCESSORS');
        })
        .catch((e) => { throw e; });
};

const setPaypalSettings = ({ dispatch }, paypalSettings) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/paypal/settings`, paypalSettings)
        .then(() => {
            dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
            dispatch('LOAD_PAYMENT_PROCESSORS');
        })
        .catch((error) => {
            sentry.log('Updating PayPal settings failed in money actions');
            throw error;
        });
};

const getPaypalSettings = ({ commit }) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/paypal/settings`)
        .then(({ data }) => {
            commit('SET_PAYPAL_SETTINGS', data);
        })
        .catch((e) => {
            sentry.log('Get Paypal settings failed', { message: e.message });
            throw (e);
        });
};

const saveProduct = ({ commit, dispatch }, { productInfo, eventSource }) => {
    return new Promise((resolve, reject) => {
        const transformedProduct = {
            productName: productInfo.name,
            productPrice: productInfo.rate,
            productDesc: productInfo.description,
            status: 1,
            subscriptionOnly: false,
            defaultTaxes: productInfo.defaultTaxes,
        };

        if (productInfo.recurringOption) {
            transformedProduct.subscriptionOnly = true;
            transformedProduct.subscriptionPlans = [
                {
                    cycle: productInfo.recurringOption.value,
                    frequency: 1,
                    active: true,
                },
            ];
        }

        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/products`, transformedProduct)
            .then((response) => {
                productInfo.value = response.data.id;
                productInfo.label = productInfo.name;
                productInfo.type = PAYMENT_REFERENCE_TYPES.product;

                dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'addProducts', { root: true });

                commit('SET_PAYMENT_REFERENCE', productInfo);

                amplitude.v2.logEvent(amplitude.v2.events.PRODUCT_ADDED, {
                    'Event Source': eventSource,
                });

                intercom.logEvent(intercom.events.PRODUCT_ADDED);

                dispatch('LOAD_PRODUCTS')
                    .then(() => {
                        resolve(response.data);
                    });
            })
            .catch(reject);
    });
};

const editProduct = ({ dispatch }, productInfo) => {
    return new Promise((resolve, reject) => {
        const transformedProduct = {
            id: productInfo.id,
            productName: productInfo.name,
            productPrice: productInfo.rate,
            productDesc: productInfo.description,
            status: 1,
            subscriptionOnly: false,
            defaultTaxIds: productInfo.defaultTaxes?.map((tax) => tax.id),
        };

        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/products/${transformedProduct.id}`, transformedProduct)
            .then(() => {
                dispatch('LOAD_PRODUCTS');
                resolve(transformedProduct);
            })
            .catch(reject);
    });
};

const deleteProduct = ({ rootState, dispatch }, id) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/products/${id}`)
            .then(() => {
                dispatch('LOAD_PRODUCTS');

                if (rootState.featureFlags[FF_KEAP_SAMPLE_DATA]) {
                    dispatch('sampleData/DELETE_PRODUCT_SAMPLE_DATA', id, { root: true });
                }
                resolve();
            })
            .catch(reject);
    });
};

const loadCurrencies = () => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/currencies`)
            .then((response) => {
                resolve(response.data);
            })
            .catch(reject);
    });
};

const setAppCurrency = ({ dispatch }, code) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/currencyLocale`, code)
            .then(() => {
                dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
                resolve();
            })
            .catch(reject);
    });
};

const loadInfusionPaySetupInfo = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/infusionPaySetupInfo`)
            .then(({ data }) => {
                commit('SET_INFUSION_PAY_SETUP_INFO', data);
                resolve(data);
            })
            .catch(reject);
    });
};

const initiatePaypalOnboarding = (redirectUrl) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/paypal/signup?redirectUrl=${redirectUrl}`)
        .then(({ data: { actionUrl } }) => {
            return actionUrl;
        })
        .catch((e) => {
            sentry.log('Load Paypal information failed', { message: e.message });
            throw (e);
        });
};

const savePaypalOnboardData = ({ dispatch }, paypalData) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/paypal/signup/complete`, paypalData)
        .then(() => {
            amplitude.v2.setUserProperties({
                'Payment Processor': 'PayPal',
            });

            amplitude.v2.logEvent(amplitude.v2.events.PAYMENT_PROCESSOR_CONNECTED, {
                'Payment Processor': 'PayPal',
            });

            amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTED, {
                'Connected Integration': 'PayPal Payment Processor',
            });

            intercom.updateUserProperties({
                'Payment Processor': 'PayPal',
            });

            intercom.logEvent(intercom.events.PAYMENT_PROCESSOR_CONNECTED);

            intercom.logEvent(intercom.events.PAYPAL_CONNECTED);

            dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'connectPaymentProcessor', { root: true });

            dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
        })
        .catch((error) => {
            sentry.log('Saving PayPal Onboarding Data failed in money actions');
            throw error;
        });
};

const initiateStripeOnboarding = (redirectUrl) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/stripe/signup?redirectUrl=${redirectUrl}`)
        .then(({ data: { onboardingUrl } }) => {
            return onboardingUrl;
        })
        .catch((e) => {
            sentry.log('Load Stripe information failed', { message: e.message });
            throw (e);
        });
};

const saveStripeOnboardData = ({ dispatch }, stripeData) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/stripe/signup/complete`, stripeData)
        .then(() => {
            amplitude.v2.setUserProperties({
                'Payment Processor': 'Stripe',
            });

            amplitude.v2.logEvent(amplitude.v2.events.PAYMENT_PROCESSOR_CONNECTED, {
                'Payment Processor': 'Stripe',
            });

            intercom.updateUserProperties({
                'Payment Processor': 'Stripe',
            });

            intercom.logEvent(intercom.events.PAYMENT_PROCESSOR_CONNECTED);

            intercom.logEvent(intercom.events.STRIPE_CONNECTED);

            dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'connectPaymentProcessor', { root: true });

            dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
        })
        .catch((error) => {
            sentry.log('Saving Stripe Onboarding Data failed in money actions');
            throw error;
        });
};

const connectNexusProcessor = ({ dispatch }, processorInfo) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/createNexusAccount`, processorInfo)
        .then(() => {
            amplitude.v2.setUserProperties({
                'Payment Processor': 'Nexus',
            });

            amplitude.v2.logEvent(amplitude.v2.events.PAYMENT_PROCESSOR_CONNECTED, {
                'Payment Processor': 'Nexus',
            });

            intercom.updateUserProperties({
                'Payment Processor': 'Nexus',
            });

            intercom.logEvent(intercom.events.PAYMENT_PROCESSOR_CONNECTED);

            intercom.logEvent(intercom.events.NEXUS_CONNECTED);

            dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'connectPaymentProcessor', { root: true });

            dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
        })
        .catch((error) => {
            sentry.log('Saving Nexus data failed in money actions');
            throw error;
        });
};

const connectEWayProcessor = ({ dispatch }, processorInfo) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/sales/createEWayAccount`, processorInfo)
        .then(() => {
            amplitude.v2.setUserProperties({
                'Payment Processor': 'EWay',
            });

            amplitude.v2.logEvent(amplitude.v2.events.PAYMENT_PROCESSOR_CONNECTED, {
                'Payment Processor': 'EWay',
            });

            intercom.updateUserProperties({
                'Payment Processor': 'EWay',
            });

            intercom.logEvent(intercom.events.PAYMENT_PROCESSOR_CONNECTED);

            intercom.logEvent(intercom.events.EWAY_CONNECTED);

            dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'connectPaymentProcessor', { root: true });

            dispatch('sales/LOAD_APP_SALES_INFO', null, { root: true });
        })
        .catch((error) => {
            sentry.log('Saving EWay data failed in money actions');
            throw error;
        });
};

const loadPromoCodes = ({ commit }) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/promocode`)
        .then(({ data }) => {
            commit('SET_PROMO_CODES', data);
        })
        .catch();
};

const savePromoCode = (({ dispatch }, promoCode) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/promocode`, promoCode)
        .then(() => {
            dispatch('LOAD_PROMO_CODES');
        })
        .catch();
});

const loadPromoCodePerformance = ({ commit }, promoCodeId) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/promocode/${promoCodeId}/performance`)
        .then(({ data }) => {
            commit('SET_PROMOCODE', data);
        })
        .catch();
};

const loadPromoCode = (context, promoCodeId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/promocode/${promoCodeId}`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(reject);
    });
};

const editPromoCode = (({ dispatch }, promoCode) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/promocode`, promoCode)
        .then(() => {
            dispatch('LOAD_PROMO_CODES');
        })
        .catch();
});

const deletePromoCode = ({ commit }, id) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/promocode/${id}`)
        .then(() => {
            commit('DELETE_PROMO_CODE', id);
        })
        .catch((error) => {
            sentry.log('Deleting a promo code failed');
            throw (error);
        });
};

const loadSalesTaxRates = ({ commit }) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/taxes`)
        .then(({ data }) => {
            commit('SET_SALES_TAXES', data);
        })
        .catch();
};

const addSalesTaxRate = ({ dispatch }, { taxInfo }) => {
    return new Promise((resolve, reject) => {
        const taxRate = {
            name: taxInfo.name,
            rate: taxInfo.rate,
        };

        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/taxes`, taxRate)
            .then((response) => {
                taxInfo.value = response.data.id;

                dispatch('LOAD_SALES_TAX_RATES')
                    .then(() => {
                        resolve(response.data);
                    });
            })
            .catch(reject);
    });
};

const editSalesTaxRate = ({ dispatch }, taxInfo) => {
    const updatedTaxInfo = {
        id: taxInfo.id,
        name: taxInfo.name,
        rate: taxInfo.rate,
    };

    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/taxes/${updatedTaxInfo.id}`, updatedTaxInfo)
        .then(() => {
            dispatch('LOAD_SALES_TAX_RATES');
        })
        .catch((error) => {
            sentry.log('Editing a sales tax rate failed');
            throw (error);
        });
};

const deleteSalesTaxRate = ({ commit }, id) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/taxes/${id}`)
        .then(() => {
            commit('DELETE_SALES_TAX_RATE', id);
        })
        .catch((error) => {
            sentry.log('Deleting a sales tax rate failed');
            throw (error);
        });
};
