<template>
    <div class="setup">
        <div :class="['wrapper', { 'adding-card': showAdd }]">
            <div v-if="isConfigured && removeTriggerList.length" class="configured-card-container">
                <configure-automation-card
                    v-for="(trigger, i) in removeTriggerList"
                    :key="`remove-trigger_${i}`"
                    :value="trigger"
                    :data-qa="`config_${trigger.name}`"
                    :active="activeIndex === i"
                    :has-error="hasError(trigger, i)"
                    :error-message="getErrorMessage(i)"
                    :trigger-action="triggerAction"
                    :class="[{ 'next-expanded': activeIndex === i + 1, 'last-expanded': activeIndex === i - 1 }]"
                    icon="octagon"
                    @active="activeIndex = activeIndex === i ? null : i"
                    @remove="handleRemove(i)"
                    @updated="(event) => handleUpdate(event, i)"
                    @save="(event) => handleSave(event, i)"
                    @edit="(event) => handleClickEdit(event, i)"
                />
            </div>

            <div v-if="canAdd" class="add-trigger">
                <div class="and-then">
                    {{ $t('andThen') }}
                </div>

                <ds-icon-button
                    data-qa="add-trigger"
                    name="add-fill"
                    @click="addTrigger"
                />

                <p class="what-else">
                    {{ $t('whatElse') }}
                </p>
            </div>

            <template v-if="!isConfigured || showAdd">
                <div class="input-container">
                    <ds-multiselect
                        data-qa="select-trigger-type"
                        searchable
                        show-search-icon
                        :placeholder="$t('whatWillBeAutomated')"
                        :options="availableOptions"
                        value-prop="type"
                        label-prop="name"
                        @input="handleSelected"
                    />
                </div>

                <section class="select-card">
                    <div class="stop-cards suggested-cards" data-qa="suggested-cards">
                        <p class="suggested-options">
                            {{ $t('suggestedOptions') }}
                        </p>

                        <div class="trigger-cards">
                            <automation-type-card
                                v-for="trigger in allTriggers.slice(0, SUGGESTED_MAX)"
                                :key="trigger.type"
                                :automation-item="trigger"
                                :data-qa="`stop-type_${trigger.type}`"
                                class="trigger-card suggested"
                                @selected="handleSelected(trigger)"
                            />
                        </div>
                    </div>

                    <automation-type-section
                        v-for="section in removeTriggersBySection"
                        :key="section.title"
                        automation-type="stop"
                        :section="section"
                        @selected="handleSelected"
                    />
                </section>
            </template>
        </div>

        <transition name="slide-left">
            <div v-if="actionComponent" class="action-panel">
                <component
                    :is="actionComponent"
                    :save-status="saveStatus"
                    :show-errors="showErrors"
                    :automation-properties="automationProperties"
                    :trigger-action="triggerAction"
                    @close="closeActionPanel"
                    @updated="handleComponentEdit"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import amplitude from '@/analytics/amplitude';

import AutomationTypeCard from '@/automations/components/AutomationTypeCard';
import AutomationTypeSection from '@/automations/components/AutomationTypeSection';
import ConfigureAutomationCard from '@/automations/components/configure/ConfigureAutomationCard';

import {
    VALIDATION_MESSAGES, MAX_TRIGGERS, SUGGESTED_MAX, TRIGGER_ACTION_TYPES, ERROR_LEVELS,
    CONTEXT_TYPE,
} from '@/automations/constants/automations.constants';
import { isValidTrigger, getSortedTriggerList } from '@/automations/utils/automations.utils';
import { triggerComponents } from '@/automations/constants/automations.component.constants';

export default {
    components: {
        AutomationTypeCard,
        AutomationTypeSection,
        ConfigureAutomationCard,
    },

    props: {
        // remove trigger list
        value: {
            type: Array,
            default: () => ([]),
        },

        saveStatus: String,
        showErrors: Boolean,
        isEditing: Boolean,
    },

    data() {
        return {
            actionComponent: null,
            SUGGESTED_MAX,
            baseUrl: process.env.BASE_URL,
            removeTriggerList: this.value,
            automationProperties: {},
            showAdd: false,
            editingIndex: null,
            activeIndex: 0,
            enableMultipleStops: false, // turn this on when backend allows it
        };
    },

    watch: {
        value: {
            handler(value) {
                if (value) {
                    this.$set(this, 'removeTriggerList', value);
                    this.updateAutomationProperties(value);
                }
            },
            deep: true,
        },

        isEditing(val) {
            if (!val && this.actionComponent) {
                this.closeActionPanel();
            }
        },
    },

    computed: {
        ...mapState({
            contextType: ({ automations }) => automations.context?.type,
        }),

        ...mapGetters({
            removeTriggersBySection: 'automations/removeTriggersBySection',
            automationWithFrontendErrors: 'automations/automationWithFrontendErrors',
        }),

        triggerAction() {
            return TRIGGER_ACTION_TYPES.REMOVE;
        },

        triggerComponents() {
            return triggerComponents;
        },

        allTriggers() {
            const allTriggers = [];

            Object.keys(this.removeTriggersBySection).forEach((key) => {
                const section = this.removeTriggersBySection[key];

                allTriggers.push(...section.automationItems);
            });

            return getSortedTriggerList(allTriggers, this.hasContext ? this.contextType : null);
        },

        hasContext() {
            return this.contextType && this.contextType !== CONTEXT_TYPE.UNKNOWN;
        },

        canAdd() {
            return this.enableMultipleStops && this.removeTriggerList.length <= MAX_TRIGGERS && !this.showAdd && this.isConfigured;
        },

        isConfigured() {
            return this.removeTriggerList.length;
        },

        availableOptions() {
            return this.allTriggers.map((trigger) => ({
                ...trigger,
                name: this.$t(trigger.name),
            }));
        },
    },

    methods: {
        closeActionPanel() {
            this.actionComponent = null;
            this.showAddStep = false;
            this.editingIndex = null;

            this.$emit('toggle-editing', false);
        },

        handleComponentEdit(events) {
            this.handleSave(events, this.editingIndex);
        },

        handleRemove(i) {
            this.removeTriggerList.splice(i, 1);
            this.automationProperties = {};
            this.$emit('updated', this.removeTriggerList);
        },

        handleSelected(trigger) {
            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                'Stop Added': trigger.type,
                'Automation Context': this.contextType,
            });
            this.automationProperties = {};

            if (this.editingIndex === null) {
                this.editingIndex = 0;
            }

            this.actionComponent = this.triggerComponents[trigger.type];
            this.removeTriggerList.push(trigger);
            this.activeIndex = null;

            if (this.actionComponent) {
                this.$emit('toggle-editing', true);
            }

            this.$emit('updated', this.removeTriggerList);
        },

        handleUpdate(trigger, i) {
            this.removeTriggerList.splice(i, 1, trigger);
        },

        handleSave(trigger, index) {
            this.handleUpdate(trigger, index);

            this.$emit('updated', this.removeTriggerList);
        },

        hasError(trigger, i) {
            return Boolean(this.showErrors && (!isValidTrigger(trigger) || this.hasFrontendValidationError(i)));
        },

        hasFrontendValidationError(i) {
            return this.automationWithFrontendErrors?.removeTriggers?.[i]?.messages?.some(({ severity }) => severity === ERROR_LEVELS.ERROR);
        },

        getErrorMessage(i) {
            if (this.hasFrontendValidationError(i)) {
                const messageCode = this.automationWithFrontendErrors.removeTriggers[i].messages[0]?.messageCode;

                return this.$t(VALIDATION_MESSAGES[messageCode]?.configureMessage) || '';
            }

            return '';
        },

        handleClickEdit({ trigger }, index) {
            this.automationProperties = { trigger };
            this.actionComponent = this.triggerComponents[trigger.type];
            this.editingIndex = index;

            if (this.actionComponent) {
                this.$emit('toggle-editing', true);
            }
        },

        addTrigger() {
            this.showAdd = true;
            this.editingIndex = this.removeTriggerList.length;
        },

        updateAutomationProperties(removeTriggerList) {
            if (this.editingIndex !== null) {
                const trigger = removeTriggerList[this.editingIndex];

                this.automationProperties = {
                    ...this.automationProperties,
                    trigger,
                };
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/setup';

    .suggested-cards {
        background-color: $color-red-025;
    }
</style>

<i18n>
{
    "en-us": {
        "whatWillBeAutomated": "What stops your automation?",
        "suggestedOptions": "Suggested options",
        "andThen": "or when",
        "whatElse": "What else should stop this automation?"
    }
}
</i18n>
