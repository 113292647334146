export default {
    SET_PREFERENCES(state, preferences) {
        state.preferences = preferences;
    },

    UPDATE_PREFERENCE(state, preference) {
        const [preferenceKey] = Object.keys(preference);
        const [preferenceValue] = Object.values(preference);

        state.preferences = {
            ...state.preferences,
            [preferenceKey]: preferenceValue,
        };
    },
};
