export default {
    companyInfo: {},
    localizationInfo: {},
    editProfileUrl: '',
    maxTotalCustomFields: 100,
    notificationPreferences: {},
    users: [],
    user: {},
    roles: [],
    domains: [],
    integrations: {
        emailSync: [],
        contactSync: null,
        appointment: [],
        contactImport: [],
        blendrTemplates: [],
        blendrBlends: [],
        blendrSsoJWT: '',
        hellosignDocuments: [],
        typeformForms: [],
        shopifyProducts: [],
        usedBlendrDataSources: [],
        allBlendrDataSources: [],
    },
};
