import { ROLE_APPLICATION_CAN_MANAGE_APP_ACCOUNT } from '@/settings/settings.constants';

const UpgradePage = () => import(/* webpackChunkName: "account" */ './pages/UpgradePage');
const SettingsAccountPage = () => import(/* webpackChunkName: "account" */ './pages/AccountPage');

export const accountRoutes = [
    {
        path: '/upgrade',
        name: 'upgrade',
        component: UpgradePage,
        meta: {
            title: 'nav.upgradeFromTrial',
            hideNav: true,
        },
    },
];

export const accountSettingsRoutes = [
    {
        path: 'account',
        name: 'settings.account',
        component: SettingsAccountPage,
        meta: {
            title: 'settings.account.title',
            hideHeaderOnMobile: true,
            permission: ROLE_APPLICATION_CAN_MANAGE_APP_ACCOUNT,
            preRouteFeatureCheck: (getters) => Boolean(!getters['auth/hasMobileOnlyFeature']),
        },
    },
];
