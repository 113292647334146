import { LOADING, SUCCESS, ERROR } from '@/contacts/loadingStatuses';

export const LOAD_CONTACT_RECORD_START = (state) => {
    state.contactLoadingStatus = LOADING;
    state.contact = {};
    state.contactDetails = {
        fieldGroups: [],
        billingRegionOptions: [],
        shippingRegionOptions: [],
        otherRegionOptions: [],
    };
};

export const LOAD_CONTACT_RECORD_SUCCESS = (state) => {
    state.contactLoadingStatus = SUCCESS;
};

export const LOAD_CONTACT_RECORD_ERROR = (state) => {
    state.contactLoadingStatus = ERROR;
};
