export const NOTIFICATION_TYPES = {
    EMAIL: 'Email',
    NONE: 'None',
};

export const IGNORED_SUB_TYPES = [
    'appointmentCreated',
    'appointmentDeleted',
    'appointmentUpdated',
];

export const NOTIFICATION_STATUS = {
    NEW: 'New',
    UNREAD: 'Unread',
};
