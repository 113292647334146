import Vue from 'vue';
import { mapState } from 'vuex';

// Consider use-cases before committing to using this mixin, it may make more sense to just use $confirm.
export default {
    computed: {
        ...mapState({
            nestedModalStatus: ({ global }) => global.nestedModalStatus,
        }),
    },

    data() {
        return {
            closing: false,
        };
    },

    methods: {
        confirmClose(callBack, options = {}) {
            return this.handler(callBack, {
                optionTitle: options.confirmDialogTitle ?? Vue.prototype.$i18nInstance.t('global.nestedModal.confirmClose.title'),
                optionMessage: options.confirmDialogBody ?? Vue.prototype.$i18nInstance.t('global.nestedModal.confirmClose.message'),
                optionConfirmButtonLabel: options.confirmDialogConfirmButtonLabel ?? Vue.prototype.$i18nInstance.t('global.close'),
                optionCancel: options.confirmCancelButtonLabel,
                destructive: options.destructive ?? false,
            });
        },

        confirmCloseDraft(callBack) {
            return this.handler(callBack, {
                optionTitle: Vue.prototype.$i18nInstance.t('global.nestedModal.confirmCloseDraft.title'),
                optionMessage: Vue.prototype.$i18nInstance.t('global.nestedModal.confirmCloseDraft.message'),
                optionConfirmButtonLabel: Vue.prototype.$i18nInstance.t('global.nestedModal.confirmCloseDraft.confirm'),
                optionCancel: Vue.prototype.$i18nInstance.t('global.nestedModal.confirmCloseDraft.cancel'),
                destructive: true,
            });
        },

        handler(callBack, data) {
            const { editModeEnabled, dirty } = this.nestedModalStatus;

            if (editModeEnabled && dirty && !this.closing) {
                this.closing = true;

                return this.$confirm(data)
                    .then(() => {
                        this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', false);
                        this.$store.commit('SET_NESTED_MODAL_DIRTY', false);

                        this.closing = false;

                        if (typeof callBack === 'function') {
                            callBack();
                        }
                    })
                    .catch(() => {
                        this.closing = false;
                    });
            }

            return new Promise((resolve) => {
                if (typeof callBack === 'function') {
                    callBack();
                }

                resolve();
            });
        },
    },
};
