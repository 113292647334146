import {
    COMPANIES_FEATURE,
} from '@/shared/constants/features.constants';
import {
    APPOINTMENTS,
    AUTOMATIONS,
    FILES,
    AUTOMATED_MESSAGE_THREAD,
    AUTOMATED_MESSAGES,
    MONEY,
    PIPELINE,
    PIPELINE_V2,
    FORMS,
} from '@/contacts/activityTypes';

const CompaniesPage = () => import(/* webpackChunkName: "contacts" */ '@/contacts/pages/CompaniesPage');
const CompanyRecordPage = () => import(/* webpackChunkName: "contacts" */ '@/contacts/pages/CompanyRecordPage');
const CompanyRecordEmpty = () => import(/* webpackChunkName: "contacts" */ '@/contacts/pages/CompanyRecordEmpty');
const ContactsPage = () => import(/* webpackChunkName: "contacts" */ '@/contacts/pages/ContactsPage');
const CompaniesContactRecordPage = () => import(/* webpackChunkName: "contacts" */ '@/contacts/pages/CompaniesContactRecordPage');
const ContactsContactRecordPage = () => import(/* webpackChunkName: "contacts" */ '@/contacts/pages/ContactsContactRecordPage');

const ContactRecordEmpty = () => import(/* webpackChunkName: "contacts" */ '@/contacts/pages/ContactRecordEmpty');

export const ALL_CONTACTS_PATH = '/contacts/list/all';
export const CONTACT_LISTS_PATH = '/contacts/list/view';
export const COMPANIES_PATH = '/contacts/companies';

const routeTabToFeature = {
    [APPOINTMENTS]: 'auth/hasAppointmentsFeature',
    [AUTOMATIONS]: 'automations/hasAnyAutomationsFeature',
    [FILES]: 'auth/hasUploadFileFeature',
    [MONEY]: 'auth/hasInvoicesAndQuotesFeature',
    [PIPELINE]: 'pipeline/hasAnyPipelineFeature',
    [PIPELINE_V2]: 'auth/hasMultiplePipelinesFeature',
    [AUTOMATED_MESSAGE_THREAD]: 'auth/hasSmsBroadcastFeature',
    [AUTOMATED_MESSAGES]: 'auth/hasSmsBroadcastFeature',
    [FORMS]: 'auth/hasFormsFeature',
};

const routes = [
    {
        path: '/contacts',
        name: 'contacts',
        redirect: ALL_CONTACTS_PATH,
        meta: {
            hideHeaderOnMobile: true,
        },
    },
    {
        path: COMPANIES_PATH,
        component: CompaniesPage,
        meta: {
            hideHeaderOnMobile: true,
            feature: COMPANIES_FEATURE,
        },
        children: [
            {
                path: '',
                name: 'contacts.company.list',
                component: CompanyRecordEmpty,
                meta: {
                    title: 'nav.contacts',
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
            },
            {
                path: ':companyId',
                name: 'contacts.company.record',
                component: CompanyRecordPage,
                props: true,
                meta: {
                    title: 'nav.contacts',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: ':companyId/contact/:contactId/:tab?/:smartFormId?',
                name: 'contacts.company.contact.record',
                component: CompaniesContactRecordPage,
                props: true,
                meta: {
                    title: 'nav.contacts',
                    hideHeaderOnMobile: true,
                },
            },
        ],
    },
    {
        path: '/contacts/forms/:smartFormId',
        name: 'contact.form.add',
        component: ContactsPage,
        meta: {
            title: 'nav.contacts',
            hideHeaderOnMobile: true,
        },
    },
    {
        path: '/contacts/list/:id?',
        component: ContactsPage,
        meta: {
            title: 'nav.contacts',
            hideHeaderOnMobile: true,
        },
        children: [
            {
                path: 'contact/:contactId/:tab?/:smartFormId?',
                name: 'contact.record',
                component: ContactsContactRecordPage,
                props: true,
                meta: {
                    title: 'nav.contacts',
                    hideHeaderOnMobile: true,
                    preRouteFeatureCheck: (getters, to) => {
                        const featureCheck = routeTabToFeature[to.params.tab];

                        if (featureCheck && (typeof getters[featureCheck] === 'boolean')) {
                            return getters[featureCheck];
                        }

                        return true;
                    },
                },
            },
            {
                path: '',
                name: 'contacts.list',
                component: ContactRecordEmpty,
                meta: {
                    title: 'nav.contacts',
                    hideHeaderOnMobile: true,
                    resetIntercom: true,
                },
            },
        ],
    },
];

export default routes;
