export const STATUS_EMPHASIS = {
    LOW: 'low',
    MEDIUM: 'medium',
    HIGH: 'high',
};

export const STATUS_TYPE = {
    DEFAULT: 'default',
    GOOD: 'good',
    WARNING: 'warning',
    ERROR: 'error',
};
