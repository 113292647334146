import firebase from 'firebase/app';
import 'firebase/database';

export default {
    LOAD_TUTORIALS: async ({ commit, rootState }) => {
        const appId = rootState.auth.session.coreAppId;
        const userId = rootState.auth.user.id;

        let tutorials = await fetchTutorials(appId);

        tutorials = flattenTutorialsForUser(tutorials, userId);

        commit('SET_TUTORIALS', tutorials);
    },

    UPDATE_TUTORIAL_ITEM: (context, { key, value, forUser }) => {
        return updateTutorials(context, { [key]: value }, forUser);
    },

    UPDATE_TUTORIALS_FOR_USER: (context, updates) => {
        return updateTutorials(context, updates, true);
    },
};

const fetchTutorials = async (appId) => {
    const snapshot = await firebase.database().ref(`tutorials/${appId}`).once('value');

    return snapshot.val() ? { ...snapshot.val() } : {};
};

const updateTutorials = async ({ commit, rootState }, updates, forUser) => {
    commit('UPDATE_TUTORIALS', updates);

    const appId = rootState.auth.session.coreAppId;
    const userId = rootState.auth.user.id;

    const tutorials = await fetchTutorials(appId);

    Object.keys(updates).forEach((key) => {
        if (forUser && tutorials[key] && tutorials[key][userId] === updates[key]) return;

        if (!forUser && tutorials[key] === updates[key]) return;

        tutorials[key] = forUser ? {
            ...tutorials[key],
            [userId]: updates[key],
        } : updates[key];
    });

    await firebase.database().ref(`tutorials/${appId}`).set(tutorials);
};

const flattenTutorialsForUser = (tutorials, userId) => {
    Object.keys(tutorials).forEach((key) => {
        if (typeof tutorials[key] === 'object') {
            if (tutorials[key][userId] !== undefined) {
                tutorials[key] = tutorials[key][userId];
            } else {
                delete tutorials[key];
            }
        }
    });

    return tutorials;
};
