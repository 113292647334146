<template>
    <div>
        <controlled-modal
            data-qa="sms-automation-setup-modal"
            :is-open="isOpen"
            show-close
            @close="$emit('close')"
        >
            <template #title>
                <h4>
                    {{ $t('title') }}
                </h4>
            </template>

            <template #default>
                <sms-broadcast-setup-state
                    :footer-text="$t('automationFooter')"
                    event-source="Easy Automation"
                    @setup="submitIntro"
                />
            </template>
        </controlled-modal>

        <sms-broadcast-setup-modal
            :is-open="isSmsSetupModalOpen"
            event-source="Easy Automation"
            @close="isSmsSetupModalOpen = false"
            @complete="isSmsSetupCompleteModalOpen = true"
        />

        <sms-broadcast-setup-complete-modal
            :is-open="isSmsSetupCompleteModalOpen"
            :button-text="$t('completeButton')"
            @close="isSmsSetupCompleteModalOpen = false"
            @complete="$emit('complete')"
        />
    </div>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';
import SmsBroadcastSetupModal from '@/broadcasts/components/sms/SmsBroadcastSetupModal';
import SmsBroadcastSetupCompleteModal from '@/broadcasts/components/sms/SmsBroadcastSetupCompleteModal';
import SmsBroadcastSetupState from '@/broadcasts/components/sms/SmsBroadcastSetupState';

export default {
    components: {
        ControlledModal,
        SmsBroadcastSetupModal,
        SmsBroadcastSetupCompleteModal,
        SmsBroadcastSetupState,
    },

    props: {
        isOpen: Boolean,
    },

    data() {
        return {
            isSmsSetupModalOpen: false,
            isSmsSetupCompleteModalOpen: false,
        };
    },

    methods: {
        submitIntro() {
            this.$emit('close');
            this.isSmsSetupModalOpen = true;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "title": "Marketing Number setup",
        "completeButton": "Continue building automation",
        "automationFooter": "Can I use my Keap Business Line for automations?"
    }
}
</i18n>
