<template>
    <ds-dropdown
        :icons="icons"
        class="basic-dropdown-list"
        v-bind="$attrs"
    >
        <template #default>
            <slot />
        </template>

        <template #menu>
            <ul class="dropdown-menu-list">
                <li
                    v-for="option in options"
                    :key="option.value"
                    class="dropdown-menu-item"
                    :class="option.className"
                    :data-qa="option.value"
                    @click="$emit('select', option.value)"
                >
                    <ds-icon v-if="option.icon" :name="option.icon" />

                    {{ option.label }}
                </li>
            </ul>
        </template>
    </ds-dropdown>
</template>

<script>
export default {
    inheritAttrs: false,

    props: {
        options: {
            type: Array,
            required: true,
        },
    },

    computed: {
        icons() {
            return this.options.some((x) => x.icon);
        },
    },
};
</script>

<style lang="scss" scoped>
    .basic-dropdown-list {
        --dropdown-minwidth: #{px-to-rem(200px)};
    }
</style>
