<template>
    <controlled-modal
        dialog
        is-open
        @close="$emit('close')"
    >
        <span class="title">{{ $t('title') }}</span>

        <span class="content">{{ $t('content') }}</span>

        <div class="button-row">
            <ds-text-button @click="$emit('close')">
                {{ $t('okGotIt') }}
            </ds-text-button>
        </div>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: { ControlledModal },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: $line-height-lg;
    margin-bottom: $gp;
}

.content {
    font-size: $font-size-med;
    line-height: $line-height-lg;
    color: $color-text-subtle;
    margin-bottom: $gp;
}

.button-row {
    display: flex;
    justify-content: flex-end;
}
</style>

<i18n>
{
    "en-us": {
        "title": "Tour continues on next screen",
        "content": "Please complete writing your text message broadcast. Your tour will automatically continue on the next screen.",
        "okGotIt": "Ok, got it"
    }
}
</i18n>
