<template>
    <deal-moved-trigger
        :enter-or-exit-type="$options.type"
        :automation-properties="automationProperties"
        :save-status="saveStatus"
        :show-errors="showErrors"
        :trigger-action="triggerAction"
        @updated="(payload) => $emit('updated', payload)"
        @close="$emit('close')"
    />
</template>

<script>
import { DEAL_ENTERED } from '@/automations/constants/automations.constants';
import DealMovedTrigger from './DealMovedTrigger';

export default {
    type: DEAL_ENTERED,

    components: {
        DealMovedTrigger,
    },

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },

        saveStatus: String,
        showErrors: Boolean,
        triggerAction: String,
    },
};
</script>
