export const DEFAULT_FROM_VALUE = '"~Owner.FirstName~ ~Owner.LastName~" <~Owner.Email~>';

export const BATCH_STATUS = {
    cancelled: 'CANCELLED',
    processing: 'PROCESSING',
    queued: 'QUEUED',
};

export const BROADCAST_FILTER_VALUES = {
    SENT: 'Done',
    DRAFT: 'Draft',
    SCHEDULED: 'Queued',
};

export const BROADCAST_STATUS = {
    SCHEDULING: 'Scheduling',
    QUEUED: 'Queued',
    PROCESSING: 'Processing',
    DONE: 'Done',
    CANCELLED: 'Cancelled',
    ERROR: 'Error',
};

export const EDITOR_KINDS = {
    bardEmailBuilder: 'BardEmailBuilder',
    codeMirror: 'CodeMirror',
};

export const WIZARD_STEP_STATUS = {
    COMPLETED: 'completed',
    ERROR: 'error',
    WARNING: 'warning',
};

const AUTOCOMPLETE_ICONS = {
    LIST: 'list',
    USER: 'user',
    TAG: 'tag',
};

export const WIZARD_STAGE = {
    GALLERY: -1,
    BUILDER: 0,
    RECIPIENT: 1,
    TIMING: 2,
};

export const STAGE_STATUS = {
    IN_PROGRESS: 'inProgress',
    COMPLETE: 'complete',
    INVALID: 'invalid',
};

export const BUILDER_WIDTH = 600;

export const MIN_WIDTH_PERCENT = 40;

export const BUILDER_BLOCK_ALIGNMENTS = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
};

export const BLOCK_TYPES = {
    LOGO: 'logo',
    BUTTON: 'button',
    IMAGE: 'image',
    TEXT: 'text',
    FOOTER: 'emailFooter',
};

export const DAYS_OF_WEEK = {
    SUNDAY: 0,
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    NEXT_MONDAY: 8,
};

export const EMAIL_IMAGE_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/gif',
];

export const AUTOCOMPLETE_ICON_BY_CATEGORY = {
    LIST: AUTOCOMPLETE_ICONS.LIST,
    SAVEDSEARCH: AUTOCOMPLETE_ICONS.LIST,
    TAG: AUTOCOMPLETE_ICONS.TAG,
    SINGLECONTACTTAG: AUTOCOMPLETE_ICONS.USER,
    LEADSOURCE: AUTOCOMPLETE_ICONS.LIST,
    STAGE: AUTOCOMPLETE_ICONS.LIST,
    PRODUCT: AUTOCOMPLETE_ICONS.LIST,
};
