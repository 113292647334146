<template>
    <side-nav-menu-panel :is-expanded="isExpanded" class="search-panel">
        <template v-if="isExpanded">
            <div class="search-panel-header">
                <ds-icon-button
                    class="nav-icon-button-inverted"
                    :aria-label="$t('global.back')"
                    name="arrow-left"
                    @click="$emit('collapse')"
                />

                <ds-input-field
                    ref="search"
                    :value="search"
                    :placeholder="$t('nav.search.placeholder')"
                    class="search-field"
                    @input="onSearch"
                    @keyup.esc="onEscape"
                >
                    <template #trailing>
                        <ds-icon
                            v-if="search"
                            name="x-circle-fill"
                            class="search-field-clear"
                            @click.native="onClear"
                        />
                    </template>
                </ds-input-field>
            </div>

            <div class="search-panel-results">
                <template v-if="isLoading">
                    <div v-for="i in 5" :key="i" class="search-panel-placeholder">
                        <ds-placeholder :rows="[{ height: '4rem', boxes: [1] }]" />
                    </div>
                </template>

                <template v-if="isSuccess">
                    <router-link
                        v-for="contact in results"
                        :key="contact.id"
                        v-slot="{ href, navigate }"
                        :to="{ name: 'contact.record', params: { contactId: contact.id } }"
                    >
                        <ds-list-item
                            as="a"
                            :href="href"
                            class="search-panel-list-item"
                            @click="onClickResult(navigate, $event)"
                        >
                            <template #title>
                                <ds-text-highlighter
                                    v-if="contact.fullName"
                                    :content="contact.fullName"
                                    :highlight="search"
                                    class="search-panel-list-item-title"
                                />
                            </template>

                            <template #description>
                                <div v-if="contact.email">
                                    <ds-text-highlighter
                                        :content="contact.email"
                                        :highlight="search"
                                    />
                                </div>

                                <div v-if="contact.phone1">
                                    <ds-text-highlighter
                                        :content="contact.phone1"
                                        :highlight="search"
                                    />
                                </div>
                            </template>

                            <template #leadingSlot>
                                <contact-avatar
                                    :email="contact.email"
                                    :name="contact.fullName"
                                />
                            </template>
                        </ds-list-item>
                    </router-link>
                </template>
            </div>
        </template>
    </side-nav-menu-panel>
</template>

<script>
import axios from 'axios';
import debounce from 'lodash.debounce';
import SideNavMenuPanel from './SideNavMenuPanel';
import ContactAvatar from '@/shared/components/ContactAvatar';
import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import {
    IDLE,
    LOADING,
    SUCCESS,
    ERROR,
} from '@/shared/constants/loadingStatuses.constants';

const cancellableGet = (url, options) => {
    const source = axios.CancelToken.source();
    const promise = axios.get(url, {
        ...options,
        cancelToken: source.token,
    });

    return { source, promise };
};

export default {
    components: {
        SideNavMenuPanel,
        ContactAvatar,
    },

    props: {
        isExpanded: Boolean,
    },

    data() {
        return {
            search: '',
            status: IDLE,
            results: [],
            request: null,
        };
    },

    created() {
        this.fetchSearchResults = debounce(this.fetchSearchResults, INPUT_DEBOUNCE_DELAY);
    },

    watch: {
        isExpanded(isExpanded) {
            if (isExpanded) {
                this.focusSearch();
            } else {
                this.reset();
            }
        },
    },

    computed: {
        isLoading() {
            return this.status === LOADING;
        },

        isSuccess() {
            return this.status === SUCCESS;
        },
    },

    methods: {
        focusSearch() {
            this.$nextTick(() => {
                this.$refs.search.input_focus();
            });
        },

        onEscape() {
            this.reset();
        },

        onClear() {
            this.reset();

            this.focusSearch();
        },

        onSearch(search) {
            this.search = search.trim();

            this.fetchSearchResults(this.search);
        },

        fetchSearchResults(search) {
            if (search === '') {
                this.status = IDLE;
                this.results = [];
            } else {
                if (this.request) {
                    this.request.source.cancel();
                }

                this.status = LOADING;

                this.request = cancellableGet(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/search?term=${search}`);

                this.request.promise
                    .then((res) => {
                        this.results = res.data;
                        this.status = SUCCESS;
                        this.request = null;
                    })
                    .catch((err) => {
                        if (!axios.isCancel(err)) {
                            this.status = ERROR;
                        }
                        this.request = null;
                    });
            }
        },

        reset() {
            this.fetchSearchResults.cancel();

            if (this.request) {
                this.request.source.cancel();
            }

            this.search = '';
            this.status = IDLE;
            this.results = [];
        },

        onClickResult(navigate, event) {
            navigate(event);
            this.$emit('collapse');
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./side-nav-variables";

.search-panel {
    z-index: $side-nav-z-index-panel-over;
    padding: $gp;
}

.search-panel-header {
    display: flex;
    gap: $gp / 2;
    padding-bottom: $gp;
}

.nav-icon-button-inverted {
    color: $color-paper;

    &:hover,
    &:focus {
        background-color: $side-nav-color-tertiary;
    }
}

.search-field {
    --input-margin-bottom: 0;
    --input-background: #{$side-nav-color-secondary};
    --input-border: none;
    --input-focus-border: none;
}

.search-field-clear {
    --icon-size: 1rem;
    --icon-color: #{$color-paper-800};
    margin-top: 0.25rem;
    cursor: pointer;
}

.search-panel-results {
    margin-left: -$gp;
    margin-right: -$gp;
}

.search-panel-list-item {
    --list-item-padding: #{$gp / 2};
    --list-item-title-color: #{$color-paper};
    --list-item-description-color: #{$color-paper-900};
    --text-highlighter-matching-color: #{$color-paper};
}

// having to nest to win out on specificity over list item styles
.search-panel .search-panel-list-item {
    @include padding-start(px-to-rem(56));
}

.search-panel-list-item-title {
    --text-highlighter-matching-weight: #{$font-weight-bold};
}

.search-panel-placeholder {
    @include padding-start(px-to-rem(56));
    padding-bottom: $gp / 2;
}

// deep overrides where CSS variable configs do not exist
/* stylelint-disable selector-pseudo-element-no-unknown */
.search-panel::v-deep {
    .search-field input {
        caret-color: $color-paper;
        color: $color-paper;
    }

    .search-panel-list-item.clickable:hover,
    .search-panel-list-item.clickable:focus {
        background-color: $side-nav-color-secondary;
    }

    .search-panel-list-item .list-item-info {
        border-bottom-color: $color-paper-400;
    }

    .search-panel-list-item .matching {
        text-decoration: underline;
    }

    .search-panel-placeholder .animated-background {
        background: linear-gradient(to right, #{$color-paper-200} 4%, #{$color-paper-050} 20%, #{$color-paper-200} 40%);
    }
}
</style>

<i18n>
{
    "en-us": {
        "nav.search.placeholder": "Search contacts"
    }
}
</i18n>
