import { LOADING, SUCCESS, ERROR } from '@/contacts/loadingStatuses';

const ADD_COMPANY = (state, company) => {
    state.companies.push(company);
};

const DELETE_COMPANY = (state, companyId) => {
    const targetIndex = state.companies.findIndex(({ value: id }) => Number(id) === Number(companyId));

    if (targetIndex > -1) {
        state.companies.splice(targetIndex, 1);
    }
};

const SET_COMPANIES = (state, companies) => {
    state.companies = companies.map((company) => {
        return {
            label: company.companyName,
            value: company.id,
        };
    });
};

const UPDATE_COMPANY = (state, updatedCompany) => {
    const targetIndex = state.companies.findIndex(({ value: id }) => Number(id) === Number(updatedCompany.value));

    if (targetIndex > -1) {
        state.companies.splice(targetIndex, 1, updatedCompany);
    } else {
        state.companies.push(updatedCompany);
    }
};

const LOAD_COMPANIES_START = (state) => {
    state.companyLoadingStatus = LOADING;
};

const LOAD_COMPANIES_ERROR = (state) => {
    state.companyLoadingStatus = ERROR;
};

const LOAD_COMPANIES_SUCCESS = (state, companies) => {
    state.companies = companies.map((company) => {
        return {
            label: company.companyName,
            value: company.id,
        };
    });
    state.companyLoadingStatus = SUCCESS;
};

export default {
    ADD_COMPANY,
    DELETE_COMPANY,
    SET_COMPANIES,
    UPDATE_COMPANY,
    LOAD_COMPANIES_START,
    LOAD_COMPANIES_ERROR,
    LOAD_COMPANIES_SUCCESS,
};
