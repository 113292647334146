const routes = [
    {
        path: '/campaigns',
        name: 'campaigns',
        redirect: '/automations/advanced',
    },
    {
        path: '/campaigns/forms',
        name: 'forms',
        redirect: '/forms/internal',
    },
    {
        path: '/campaigns/forms/:formId/:step?',
        name: 'forms.smartform',
        redirect: '/forms/internal/:formId/:step?',
    },
];

export default routes;
