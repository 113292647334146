<template>
    <div class="provider-card" :data-qa="`calendar-provider-card-${id}`">
        <section class="provider-card-main">
            <div class="title">
                <div class="image">
                    <img :src="`${baseUrl}images/providers/${imageFile}`" />
                </div>

                <div class="name">
                    {{ $t(`providers.${id}.name`) }}

                    <div v-if="!isConnected" class="description">
                        {{ $t(`providers.${id}.description`) }}
                    </div>
                </div>
            </div>

            <div class="options">
                <ds-dropdown v-if="isConnected" data-qa="provider-options">
                    <template #default>
                        <ds-icon-button name="more-vertical" />
                    </template>

                    <template #menu>
                        <ul class="dropdown-menu-list">
                            <li
                                v-if="timeZoneSelectorEnabled"
                                class="dropdown-menu-item"
                                data-qa="change-timezone"
                                @click="isTimeZoneModalOpen = true"
                            >
                                {{ $t('changeTimezone') }}
                            </li>

                            <li
                                class="dropdown-menu-item"
                                data-qa="disconnect"
                                @click="disconnectConfirmation"
                            >
                                {{ $t('global.disconnect') }}
                            </li>

                            <li
                                class="dropdown-menu-item"
                                data-qa="privacy-policy"
                                @click="viewPrivacyPolicy"
                            >
                                {{ $t('global.viewPrivacyPolicy') }}
                            </li>
                        </ul>
                    </template>
                </ds-dropdown>

                <time-zone-modal
                    v-if="isConnected"
                    :open="isTimeZoneModalOpen"
                    :provider="provider"
                    @close="isTimeZoneModalOpen = false"
                />

                <ds-popover
                    v-if="otherProviderConnected"
                    position="bottom"
                >
                    <template #reference>
                        <div class="unavailable">
                            <ds-icon name="alert-circle" class="unavailable-icon" />
                            <span>{{ $t('global.unavailable') }}</span>
                        </div>
                    </template>

                    <template #default>
                        <div class="popover-content">
                            {{ $t('unavailable') }}
                        </div>
                    </template>
                </ds-popover>

                <template v-else-if="!isConnected">
                    <ds-text-button v-if="isBroken" @click="disconnectCalendarProvider">
                        {{ $t('global.remove') }}
                    </ds-text-button>

                    <ds-text-button
                        data-qa="calendar-provider-button"
                        :loading="isConnecting"
                        @click="handleClick"
                    >
                        {{ buttonLabel }}
                    </ds-text-button>
                </template>
            </div>
        </section>

        <div v-if="isBroken || isConnected" class="details">
            <div class="provider-overview">
                <small>{{ provider.email }}</small>

                <ds-status
                    :emphasis="isBroken ? 'high' : null"
                    :type="isConnected ? 'good' : 'error'"
                >
                    {{ $t(`status.${providerStatus}`) }}
                </ds-status>
            </div>

            <ds-status v-if="isBroken" type="error">
                {{ $t('disconnected') }}
            </ds-status>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import amplitude from '@/analytics/amplitude';
import fullstory from '@/analytics/fullstory';

import authMixin from '@/shared/mixins/auth.mixin';
import redirectMixin from '@/shared/mixins/redirect.mixin';

import { FF_KEAP_APPOINTMENTS_TIMEZONE } from '@/shared/constants/featureFlag.constants';
import { PROVIDERS } from '@/shared/constants/integrations.constants';
import { INTEGRATION_STATUS } from '@/appointments/appointments.constants';
import TimeZoneModal from './TimeZoneModal';

export default {
    components: {
        TimeZoneModal,
    },

    mixins: [authMixin, redirectMixin],

    props: {
        id: String,
        routerQueryOnSuccess: Object,
    },

    data() {
        return {
            baseUrl: process.env.BASE_URL,
            privacyPolicyUrl: process.env.VUE_APP_GOOGLE_API_PRIVACY_POLICY,
            isConnecting: false,
            isTimeZoneModalOpen: false,
        };
    },

    mounted() {
        this.auth_handleCalendarAuth(this.provider.name, true, this.routerQueryOnSuccess);
    },

    computed: {
        ...mapState({
            providers: ({ calendar }) => calendar.providers,
            timeZoneSelectorEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_APPOINTMENTS_TIMEZONE],
        }),

        provider() {
            return this.providers.find(({ name }) => name === this.id) || {};
        },

        providerStatus() {
            return this.provider.status;
        },

        isBroken() {
            return this.providerStatus === INTEGRATION_STATUS.BROKEN;
        },

        isPending() {
            return this.providerStatus === INTEGRATION_STATUS.PENDING;
        },

        isConnected() {
            return this.providerStatus === INTEGRATION_STATUS.READY;
        },

        buttonLabel() {
            return this.isBroken
                ? this.$t('global.reconnect')
                : this.$t('global.connect');
        },

        otherProviderConnected() {
            return this.providers.some(({ name, status }) => name !== this.provider.name && this.provider.name !== PROVIDERS.ZOOM && status === INTEGRATION_STATUS.READY);
        },

        imageFile() {
            return this.id === PROVIDERS.GOOGLE ? 'google-calendar.svg' : 'outlook-calendar.svg';
        },
    },

    methods: {
        handleClick() {
            if (this.isConnected && !this.isBroken) {
                return this.disconnectConfirmation();
            }

            this.isConnecting = true;

            const scope = 'calendar';
            const redirectUrl = this.auth_getOauthReturnUrl({
                provider: this.id,
                authType: 'calendar',
                scope,
            });

            return this.$store.dispatch('auth/AUTHORIZE_PROVIDER', { provider: this.id, scope, redirectUrl })
                .then((location) => {
                    const { provider } = this.provider;

                    fullstory.event(`Settings - Integrations - clicked : connect calendar ${provider}`);
                    this.$track('Settings - Integrations - clicked : connect calendar', { provider });

                    amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTED, {
                        'Connected Integration': `${provider} Calendar`,
                        'Event Source': 'Integrations Page',
                    });

                    this.redirect_url(location);
                });
        },

        disconnectConfirmation() {
            const confirmOptions = {
                optionTitle: this.$t('disconnectConfirm.title', { providerBrand: this.$t(`providers.${this.id}.brand`) }),
                optionMessage: this.$t('disconnectConfirm.message', { providerName: this.$t(`providers.${this.id}.name`) }),
                optionConfirmButtonLabel: this.$t('disconnectConfirm.disconnectButtonLabel'),
                optionCancel: this.$t('global.goback'),
                destructive: true,
            };

            this.$confirm(confirmOptions)
                .then(() => this.disconnectCalendarProvider())
                .catch(() => {});
        },

        disconnectCalendarProvider() {
            const headline = this.$t('success.integrations.calendar.disconnect.headline');
            const message = this.$t('success.integrations.calendar.disconnect.message');
            const errorMessage = this.$t('errors.integration.calendar.disconnect');

            return this.$store.dispatch('calendar/DELETE_PROVIDER_INTEGRATION', this.provider)
                .then(() => {
                    this.$toast({ headline, message });

                    const { provider } = this.provider;

                    fullstory.event(`Settings - Integrations - clicked : disconnect calendar ${provider}`);
                    this.$track('Settings - Integrations - clicked : disconnect calendar', { provider });
                })
                .catch(() => {
                    this.$error({
                        message: errorMessage,
                    });
                });
        },

        viewPrivacyPolicy() {
            this.redirect_url_new_tab(this.privacyPolicyUrl);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import './provider-card';

    .unavailable {
        padding: $gp / 2 $gp;
        border-radius: px-to-rem(30px);
        font-size: $font-size-small;
        cursor: pointer;
        display: flex;
        align-items: center;
        --icon-size: #{px-to-rem(18px)};

        span {
            margin-top: px-to-rem(1px);
        }

        &:hover {
            background-color: $color-gray-050;
        }
    }

    .unavailable-icon {
        @include margin-end($gp / 4);
    }
</style>

<i18n>
{
    "en-us": {
        "providers": {
            "GOOGLE": {
                "name": "Google Calendar",
                "brand": "Google",
                "description": "Create appointments in Keap and add booked appointments directly to your Google Calendar."
            },
            "MICROSOFT": {
                "name": "Outlook Calendar",
                "brand": "Microsoft",
                "description": "Create appointments in Keap and add booked appointments directly to your Outlook Calendar."
            }
        },
        "disconnected": "Your calendar isn't connected. This may be the result of a change to your password.",
        "status": {
            "READY": "Connected",
            "BROKEN": "Disconnected",
            "DISCONNECTED": "Disconnected"
        },
        "disconnectConfirm": {
            "title": "Disconnect {providerBrand}?",
            "disconnectButtonLabel": "Disconnect",
            "message": "If you disconnect {providerName}, contacts with your booking link won't be able to book new appointments. However, you won't lose any existing data (like appointment types, existing appointments, etc)."
        },
        "unavailable": "You may only connect 1 calendar at a time. If you'd like to connect, please disconnect your other calendar.",
        "changeTimezone": "Change timezone"
    }
}
</i18n>
