<template>
    <div class="tag-applied-trigger choose-tag configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div v-if="!showAddTag">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-text-button
                            leading-icon="add"
                            data-qa="add-new-tag"
                            @click="toggleAddTag"
                        >
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-for="(tag, i) in filteredTags"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :data-qa="`tag_${tag.name}`"
                        :title="tag.name"
                        :description="getCategoryName(tag.category)"
                        @click="handleTagSelection(tag)"
                    />

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>
                </section>
            </div>

            <automation-add-tag
                v-else
                data-qa="show-add-option"
                @back="toggleAddTag"
                @tag-created="handleTagCreated"
            />
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import AutomationAddTag from '@/automations/components/AutomationAddTag';

import { TAG_APPLIED } from '@/automations/constants/automations.constants';

export default {
    components: {
        AutomationAddTag,
    },

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedTag: {},
            showAddTag: false,
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapState({
            tags: ({ tags }) => tags.tags,
        }),

        tagTrigger() {
            const { id, name } = this.selectedTag;

            return {
                name,
                type: TAG_APPLIED,
                sourceId: id,
            };
        },

        filteredTags() {
            const search = this.search?.trim()?.toLowerCase();

            return this.tags.filter(({ name, category }) => {
                return name.toLowerCase().includes(search) || category?.name?.toLowerCase()?.includes(search);
            });
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredTags.length;
        },
    },

    methods: {
        async init() {
            this.selectedTag = {
                name: this.$t('emptyTagApplied'),
                type: TAG_APPLIED,
            };

            if (!this.tags.length) {
                this.loading = true;

                await this.$store.dispatch('tags/LOAD_TAGS');

                this.loading = false;
            }
        },

        handleTagSelection(tag) {
            this.selectedTag = {
                ...clonedeep(tag),
                name: this.$t('tagApplied', { id: tag.id }),
            };
            this.$emit('updated', this.tagTrigger);
            this.$emit('close');
        },

        toggleAddTag() {
            this.showAddTag = !this.showAddTag;
        },

        getCategoryName(category) {
            return category?.name ?? '';
        },

        handleTagCreated(tag) {
            this.toggleAddTag();
            this.handleTagSelection(tag);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "header": "Choose a tag",
        "buttonText": "Create a new tag",
        "emptyTagApplied": "Tag is added",
        "tagApplied": "({id}) tag is added",
        "search": "Search",
        "emptySearch": "No results found for \"{search}\""
    }
}
</i18n>
