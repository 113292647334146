export const LOOKER_REPORT_IDS = {
    DAILY_SNAPSHOT: '4',
    DAILY_SNAPSHOT_V2: '18',
    LEAD_CAPTURE: '5',
    LEAD_CAPTURE_V2: '13',
    EMAIL: '3',
    EMAIL_V2: '14',
    MONEY: '6',
    MONEY_V2: '15',
    PIPELINE: process.env.VUE_APP_ANALYTICS_ID_PIPELINE,
};

export const DataTypes = Object.freeze({
    TotalSales: 'totalSales',
    NumberOfSales: 'numberOfSales',
    SentCount: 'sentCount',
    OpenCount: 'openCount',
    OpenRate: 'openRate',
    ClickCount: 'clickCount',
    ClickRate: 'clickRate',
    OptOutCount: 'optOutCount',
    OptOutRate: 'optOutRate',
});

export const ValueTypes = Object.freeze({
    Number: 'number',
    Currency: 'currency',
    Percentage: 'percentage',
});

export const DataGroupingType = Object.freeze({
    Years: 'years',
    Months: 'months',
    Days: 'days',
    Hours: 'hours',
});

export const ReportTimeSpanOptionValues = Object.freeze({
    TODAY: 1,
    LAST_7_DAYS: 7,
    LAST_30_DAYS: 30,
});

export const ReportTimeSpanOptions = Object.freeze([
    { value: ReportTimeSpanOptionValues.TODAY, label: 'global.time.today' },
    { value: ReportTimeSpanOptionValues.LAST_7_DAYS, label: 'global.time.last7' },
    { value: ReportTimeSpanOptionValues.LAST_30_DAYS, label: 'global.time.last30' },
]);

export const AxisTypes = Object.freeze({
    time: 'time',
    money: 'money',
    number: 'number',
});

export const AllSalesFields = {
    SALE_DATE: 'SALE_DATE',
    ORDER_ID: 'ORDER_ID',
    PAYMENT_TYPE: 'PAYMENT_TYPE',
    CONTACT_NAME: 'CONTACT_NAME',
    AMOUNT_PAID: 'AMOUNT_PAID',
};

export const EmailEngagementEmailFields = {
    EMAIL_NAME: 'EMAIL_NAME',
    OPEN_RATE: 'OPEN_RATE',
    CLICK_RATE: 'CLICK_RATE',
    OPT_OUT_RATE: 'OPT_OUT_RATE',
};

export const EmailEngagementContactEngagementFields = {
    CONTACT_NAME: 'CONTACT_NAME',
    OPEN_RATE: 'OPEN_RATE',
    CLICK_RATE: 'CLICK_RATE',
};

const DATE_ADDED_FIELD = {
    key: 'dateadded',
    label: 'filters.fields.dateadded',
    type: 'date',
    modifier: 'within',
    value: '',
    query: '',
};

const DATE_SENT_FIELD = {
    key: 'email_sent_on',
    label: 'filters.fields.email_sent_on',
    type: 'date',
    modifier: 'within',
    value: '',
    query: '',
};

const DATE_PAID_FIELD = {
    key: 'datepaid',
    label: 'filters.fields.datepaid',
    type: 'date',
    modifier: 'within',
    value: '',
    query: '',
};

const TAGS_FIELD = {
    key: 'tags',
    label: 'filters.fields.tags',
    type: 'tags',
    modifier: 'includesany',
    value: '',
    query: '',
};

const EMAIL_DOMAIN_FIELD = {
    key: 'emaildomain',
    label: 'filters.fields.emaildomain',
    type: 'text',
    modifier: 'equals',
    value: '',
    query: '',
};

const COUNTRY_FIELD = {
    key: 'country',
    label: 'filters.fields.country',
    type: 'text',
    modifier: 'equals',
    value: '',
    query: '',
};

const STATE_FIELD = {
    key: 'state',
    label: 'filters.fields.state',
    type: 'text',
    modifier: 'equals',
    value: '',
    query: '',
};

const CITY_FIELD = {
    key: 'city',
    label: 'filters.fields.city',
    type: 'text',
    modifier: 'equals',
    value: '',
    query: '',
};

const POSTAL_CODE_FIELD = {
    key: 'postalcode',
    label: 'filters.fields.postalcode',
    type: 'text',
    modifier: 'equals',
    value: '',
    query: '',
};

const ANNIVERSARY_FIELD = {
    key: 'anniversary',
    label: 'filters.fields.anniversary',
    type: 'date',
    modifier: 'between',
    value: '',
    query: '',
};

const AMOUNT_PAID_FIELD = {
    key: 'totalamountpaid',
    label: 'filters.fields.totalamountpaid',
    type: 'currency',
    modifier: 'greaterthan',
    value: '',
    query: '',
};

const BROADCAST_OR_CAMPAIGN_FIELD = {
    key: 'broadcastorcampaign',
    label: 'filters.fields.broadcastorcampaign',
    type: 'select',
    modifier: 'equals',
    value: 'Broadcast',
    query: 'Broadcast',
    valueOptions: [
        { label: 'filters.valueOptions.broadcast', value: 'Broadcast' },
        { label: 'filters.valueOptions.campaign', value: 'Campaign' },
    ],
};

const DATE_FIELD = {
    key: 'date',
    label: 'filters.fields.date',
    type: 'date',
    modifier: 'within',
    value: '',
    query: '',
};

const USER_FIELD = {
    key: 'userid',
    label: 'filters.fields.user',
    type: 'user',
    modifier: 'equals',
    value: '',
    query: '',
    valueOptions: [],
};

export const CONTACT_GROWTH_FILTER_OPTIONS = [
    DATE_ADDED_FIELD,
    TAGS_FIELD,
    EMAIL_DOMAIN_FIELD,
    COUNTRY_FIELD,
    STATE_FIELD,
    CITY_FIELD,
    POSTAL_CODE_FIELD,
    ANNIVERSARY_FIELD,
];

export const EMAIL_DELIVERABILITY_FILTER_OPTIONS = [
    DATE_SENT_FIELD,
    BROADCAST_OR_CAMPAIGN_FIELD,
];

export const PAYMENTS_FILTER_OPTIONS = [
    DATE_PAID_FIELD,
    AMOUNT_PAID_FIELD,
    TAGS_FIELD,
];

export const PIPELINE_FILTER_OPTIONS = [DATE_FIELD, USER_FIELD];

export const DATE_MODIFERS = [
    { value: 'between', label: 'modifiers.between' },
    { value: 'before', label: 'modifiers.before' },
    { value: 'after', label: 'modifiers.after' },
    { value: 'within', label: 'modifiers.within' },
];

export const TEXT_MODIFERS = [
    { value: 'equals', label: 'modifiers.equals' },
    { value: 'notequals', label: 'modifiers.notequals' },
    { value: 'contains', label: 'modifiers.contains' },
    { value: 'notcontain', label: 'modifiers.notcontain' },
    { value: 'startswith', label: 'modifiers.startswith' },
    { value: 'endswith', label: 'modifiers.endswith' },
    { value: 'isempty', label: 'modifiers.isempty' },
    { value: 'isfilled', label: 'modifiers.isfilled' },
];

export const TAGS_MODIFERS = [
    { value: 'includesany', label: 'modifiers.includesany' },
    { value: 'excludesall', label: 'modifiers.excludesall' },
];

export const SELECT_MODIFERS = [
    { value: 'equals', label: 'modifiers.equals' },
];

export const USER_MODIFIERS = [
    { value: 'equals', label: 'modifiers.equals' },
];

export const CURRENCY_MODIFERS = [
    { value: 'greaterthan', label: 'modifiers.greaterthan' },
    { value: 'lessthan', label: 'modifiers.lessthan' },
    { value: 'between', label: 'modifiers.between' },
    { value: 'equals', label: 'modifiers.equals' },
];
