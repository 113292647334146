import gql from 'graphql-tag';
import Vue from 'vue';

const automationReturnType = `{
    id
    description
    name
    enabledDate
    updatedDate
    createdDate
    status
    hasChanges
    steps {
        id
        configJson
        name
        type
    }
    triggers {
        id
        configJson
        name
        type
        sourceId
        action
    }
}`;

export const createAutomation = (payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createAutomation($payload: AutomationInput!) {
                createAutomation(payload: $payload) {
                    id
                }
            }
        `,
        variables: {
            payload,
        },
    });
};

export const installAutomation = (payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation installAutomation($payload: InstallAutomationInput!) {
                installAutomation(payload: $payload) {
                    id
                }
            }
        `,
        variables: {
            payload,
        },
    });
};

export const deleteAutomation = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteAutomation($id: ID!) {
                deleteAutomation(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};

export const deleteAutomationDraft = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteAutomationDraft($id: ID!) {
                deleteAutomationDraft(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};

export const contactAutomationList = (contactId, limit, cursor) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query contactAutomationList($contactId: ID!, $limit: Int, $cursor: ID) {
                contactAutomationList(contactId: $contactId, limit: $limit, cursor: $cursor) {
                    automations {
                        id
                        name
                        participant {
                            id
                            type
                            nextStepIndex
                            dateAdded
                            contactIds
                        }
                    }
                    noMoreData
                }
            }
        `,
        variables: {
            contactId,
            limit,
            cursor,
        },
        fetchPolicy: 'no-cache',
    });
};

export const summaryListAutomations = (limit, cursor, tags) => {
    const automationSummary = ` {
        id
        description
        name
        enabledDate
        updatedDate
        createdDate
        status
        hasChanges
    }`;

    return Vue.prototype.$graphql.query({
        query: gql`
            query automations($limit: Int, $cursor: ID, $tags: [String]) {
                automationList(limit: $limit, cursor: $cursor, tags: $tags) ${automationSummary}
            }
        `,
        variables: {
            limit,
            cursor,
            tags,
        },
        fetchPolicy: 'no-cache',
    });
};

export const automationStatusSummary = (tags) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query automationStatusSummary($tags: [String]) {
                automationStatusSummary(tags: $tags) {
                    tag
                    hasAutomation
                    hasEnabled
                    hasChanges
                }
            }
        `,
        variables: {
            tags,
        },
        fetchPolicy: 'no-cache',
    });
};

export const loadAutomation = (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query automation($id: ID!) {
                automation(id: $id) ${automationReturnType}
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const participantCount = (id) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query participantCount($id: ID!) {
                participantCount(id: $id) {
                    uniqueContacts
                    participants
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });
};

export const removeParticipants = (id, payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation removeParticipants($id: ID!, $payload: RemoveParticipantsInput!) {
                removeParticipants(id: $id, payload: $payload)
            }
        `,
        variables: {
            id,
            payload,
        },
    });
};

export const participantList = ({ id, limit, cursor }) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query participantList($id: ID!, $limit: Int, $cursor: ID) {
                participantList(id: $id, limit: $limit, cursor: $cursor) {
                    participants {
                        id
                        type
                        nextStepIndex
                        contacts {
                            id
                            familyName
                            middleName
                            givenName
                            displayName
                            emailAddresses {
                                type
                                value
                            }
                        }
                        name
                        metadata
                    }
                }
            }
        `,
        variables: {
            id,
            limit,
            cursor,
        },
        fetchPolicy: 'no-cache',
    });
};

export const publishAutomation = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation publishAutomation($id: ID!) {
                publishAutomation(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};

export const disableAutomation = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation disableAutomation($id: ID!) {
                disableAutomation(id: $id)
            }
        `,
        variables: {
            id,
        },
    });
};

export const updateAutomation = (id, payload) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateAutomation($id: ID!, $payload: AutomationInput!) {
                updateAutomation(id: $id, payload: $payload) ${automationReturnType}
            }
        `,
        variables: {
            id,
            payload,
        },
    });
};

export const renameAutomation = (id, name) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation renameAutomation($id: ID!, $name: String!) {
                renameAutomation(id: $id, name: $name)
            }
        `,
        variables: {
            id,
            name,
        },
    });
};

export const sendTestAutomation = (id, emailTo, contactId, isTemplate) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation sendTestAutomation($id: ID!, $emailTo: String!, $contactId: ID!, $isTemplate: Boolean) {
                sendTestAutomation(id: $id, emailTo: $emailTo, contactId: $contactId, isTemplate: $isTemplate)
            }
        `,
        variables: {
            id,
            emailTo,
            contactId,
            isTemplate,
        },
    });
};

export const validateAutomation = (id) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation validateAutomation($id: ID!) {
                validateAutomation(id: $id) {
                    id
                    messages {
                        messageCode
                        severity
                    }
                    steps {
                        type
                        messages {
                            messageCode
                            severity
                        }
                    }
                    triggers {
                        type
                        action
                        messages {
                            messageCode
                            severity
                        }
                    }
                }
            }
        `,
        variables: {
            id,
        },
    });
};
