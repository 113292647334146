<template>
    <controlled-modal
        ref="modal"
        :is-open="isOpen"
        class="rename-modal"
        size="med"
        dialog
        show-close
        data-qa="modal-title"
        :title="title"
        @close="close"
    >
        <form data-qa="choose-site-name-form" @submit.prevent="rename">
            <ds-input-field
                v-model="name"
                class="choose-site-name-modal-form"
                data-qa="site-name"
                :maxlength="maxLength"
                :label="nameInputLabel"
            >
                <template #help>
                    {{ helperText }}
                </template>
            </ds-input-field>

            <div class="button-row">
                <ds-filled-button
                    type="submit"
                    data-qa="site-name-button"
                    :disabled="!name"
                    :loading="isLoading"
                >
                    {{ $t('nameModalSubmitButton') }}
                </ds-filled-button>
            </div>
        </form>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';

const SITE_NAME_MAX_LENGTH = 80;

export default {
    components: { ControlledModal },

    props: {
        initialName: {
            type: String,
            default: '',
        },
        isOpen: Boolean,
        title: String,
        nameInputLabel: String,
        helperText: String,
    },

    data() {
        return {
            name: this.initialName || this.$t('marketingSites.defaultPageName'),
            isLoading: false,
        };
    },

    watch: {
        // When the modal is closed, reset the button spinner state
        isOpen(newValue) {
            if (!newValue) {
                this.isLoading = false;
            }
        },

        initialName(newValue) {
            this.name = newValue;
        },
    },

    computed: {
        maxLength() {
            return SITE_NAME_MAX_LENGTH;
        },

        isNameInvalid() {
            return !this.name;
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        rename() {
            this.isLoading = true;
            this.$emit('rename', this.name);
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "nameModalSubmitButton": "Continue"
    }
}
</i18n>
