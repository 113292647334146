<template lang="html">
    <ds-popover position="bottom-start" @popover-active="toggleChevron">
        <template #reference>
            <button class="keap-toggle-button">
                {{ $t('toggle.buttonLabel') }}
                <ds-icon v-if="!isPopoverActive" name="chevron-down" />
                <ds-icon v-if="isPopoverActive" name="chevron-up" />
            </button>
        </template>

        <template #default>
            <div class="popover-content">
                <div class="popover-toggle bold">
                    <ds-toggle-switch
                        value
                        no-label
                        @input="restoreUiSetting"
                    />
                    <div class="toggle-label">
                        {{ keapMaxRebrand ? $t('toggle.switchLabel') : $t('toggle.switchLabelOld') }}
                    </div>
                </div>
                <i18n tag="p" path="toggle.description" class="popover-description">
                    <template #bold1>
                        <span class="bold">{{ $t('toggle.bold1') }}</span>
                    </template>

                    <template #bold2>
                        <span class="bold">{{ $t('toggle.bold2') }}</span>
                    </template>

                    <template #link>
                        <a
                            :href="keapMaxRebrand ? helpUrl : helpUrlOld"
                            target="_blank"
                            rel="noopener noreferrer"
                        >{{ $t('toggle.learnMore') }}</a>
                    </template>
                </i18n>
            </div>
        </template>
    </ds-popover>
</template>

<script>
import { mapState } from 'vuex';

import KeapToggleInfoModal from '@/shared/components/KeapToggleInfoModal';
import redirectMixin from '@/shared/mixins/redirect.mixin';

import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { FF_FLAGSHIP_FS_24167_FREE_TOGGLE_INFUSIONSOFT_KEAP, FF_KEAP_MAX_REBRAND } from '@/shared/constants/featureFlag.constants';

export default {
    mixins: [redirectMixin],

    data() {
        return {
            isPopoverActive: false,
            helpUrlOld: 'https://help.keap.com/help/infusionsoft-keap-experience',
            helpUrl: 'https://help.keap.com/help/keap-max',
        };
    },

    mounted() {
        this.load();
    },

    computed: {
        ...mapState({
            keapToggleEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_FS_24167_FREE_TOGGLE_INFUSIONSOFT_KEAP],
            keapMaxRebrand: ({ featureFlags }) => featureFlags[FF_KEAP_MAX_REBRAND],
            keapToggleModalViewed: ({ tutorials }) => tutorials.items[TUTORIAL_TYPES.KEAP_TOGGLE],
        }),
    },

    methods: {
        load() {
            if (!this.keapMaxRebrand && this.keapToggleEnabled && !this.keapToggleModalViewed) {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: KeapToggleInfoModal,
                });
            }
        },

        restoreUiSetting() {
            this.redirect_coreUrl('/app/spa/v1/appSettings/restoreUiSetting');
        },

        toggleChevron() {
            this.isPopoverActive = !this.isPopoverActive;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $toggle-button-border-radius: 15px;

    .popover-container {
        --popover-min-width: #{px-to-rem(320px)};
    }

    .popover-toggle {
        font-size: $font-size-lg;
        display: flex;
        align-items: center;
    }

    .toggle-label {
        @include margin-start($gp);
    }

    .popover-description {
        margin-top: $gp;
        margin-bottom: 0;
        font-size: $font-size-med;
        @include margin-end($gp);
    }

    .keap-toggle-button {
        display: flex;
        align-items: center;
        background-color: $color-green;
        height: $gp * 1.4;
        width: $gp * 3.6;
        border-radius: $toggle-button-border-radius;
        font-size: $gp * 0.75;
        padding: 0 px-to-rem(9px);
        --icon-size: #{$gp};
    }
</style>

<i18n>
{
    "en-us": {
        "toggle": {
            "buttonLabel": "New",
            "switchLabelOld": "Try Infusionsoft: The Keap Experience",
            "switchLabel": "Try Keap Max",
            "description": "Enjoy more {bold1} and a {bold2} Toggle on and off without losing any of your data. {link}",
            "bold1": "intuitive features,",
            "bold2": "cleaner, brighter interface, and more features, too...",
            "learnMore": "Learn more"
        }
    }
}
</i18n>
