export const ADD_CONTACTS = {
    id: 'addContacts',
    image: 'combine-contact-sources-color',
    tasks: [],
};

export const COLLECT_LEADS = {
    id: 'leadsAndOpportunities',
    image: 'contacts-color',
    tasks: [],
};

export const BOOK_APPOINTMENTS = {
    id: 'bookAppointments',
    image: 'calendar-color',
    tasks: [],
};

export const AUTOMATE_WORKFLOWS = {
    id: 'automateWorkflows',
    image: 'setup-messenger-color',
    tasks: [],
};

export const TRACK_ACTIVITIES = {
    id: 'trackActivitesProcesses',
    image: 'completed-tasks-color',
    tasks: [],
};

export const MANAGE_FOLLOW_UP = {
    id: 'manageFollowUp',
    image: 'followup-color',
    tasks: [],
};

export const PROMOTE_MARKET = {
    id: 'promoteMarket',
    image: 'promote-color',
    tasks: [],
};

export const COLLECT_PAYMENT = {
    id: 'requestCollectPayments',
    image: 'successful-payment-color',
    tasks: [],
};
