<template>
    <ds-avatar
        :email="email"
        :name="avatarName || ''"
        :size="size"
        :src="src"
    />
</template>

<script>
export default {
    props: {
        email: String,
        name: String,
        size: Number,
        src: String,
    },

    computed: {
        avatarName() {
            return this.name?.startsWith('(Sample)') ? this.name.substr(9) : this.name;
        },
    },
};
</script>
