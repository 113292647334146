import BusinessAddressModalContent from '@/settings/components/BusinessAddressModalContent';

export default {
    methods: {
        orderUtils_editBusinessAddress() {
            this.$track('Money - Invoice - clicked : Add invoice address');

            if (this.editing) {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: BusinessAddressModalContent,
                    showRootClose: true,
                });
            }
        },

        async orderUtils_deleteAttachmentFromContact(id) {
            const message = this.$t('error.deleteAttachment');

            try {
                await this.$store.dispatch('contacts/DELETE_CONTACT_FILE', id);
            } catch (error) {
                this.$error({ message, bottom: true });
            }
        },

        orderUtils_addAttachmentsToEmail(order) {
            this.$store.commit('email/DELETE_ATTACHMENTS');

            if (order?.files) {
                order.files.forEach((attachment) => {
                    this.$store.commit('email/ADD_ATTACHMENT', attachment);
                });
            }
        },
    },
};
