<template>
    <div v-show="callStatus" class="call-widget" :class="{ disconnected: isCallDisconnected }">
        <div class="call-widget-content">
            <call-widget-tooltip class="drag-icon" :tooltip="$t('tooltip.dragToMove')">
                <button @mousedown="handleMouseDown">
                    <svg>
                        <use xlink:href="#drag" />
                    </svg>
                </button>
            </call-widget-tooltip>

            <call-widget-tooltip v-if="hasContactId" :tooltip="$t('tooltip.viewContact')">
                <router-link
                    class="view-contact-link"
                    :to="{
                        name: 'contact.record',
                        params: { contactId }
                    }"
                >
                    <span class="call-contact">
                        {{ displayName }}
                    </span>

                    <ds-icon name="user-fill" />
                </router-link>
            </call-widget-tooltip>

            <span v-else class="view-contact-link">{{ displayName }}</span>

            <div class="call-status">
                <span>{{ callStatusLabel }}</span>

                <ds-status v-if="isMuted" emphasis="high" type="error">
                    {{ $t('muted') }}
                </ds-status>
            </div>

            <div
                v-if="showCallButtons"
                key="call-widget-buttons"
                class="call-widget-buttons"
                :class="{ disconnected: isCallDisconnected }"
            >
                <call-widget-tooltip class="call-button-tooltip" :class="{ disconnected: isCallDisconnected }" :tooltip="$t('tooltip.settings')">
                    <button
                        :aria-label="$t('buttonLabels.settings')"
                        class="call-widget-button settings-button"
                        :class="{ toggled: isSettingsOpen }"
                        @click="handleSettingsClick"
                    >
                        <ds-icon name="settings" />
                    </button>
                </call-widget-tooltip>

                <call-widget-tooltip
                    v-if="hasContactId"
                    class="call-button-tooltip"
                    :class="{ disconnected: isCallDisconnected }"
                    :tooltip="$t('tooltip.callNotes')"
                >
                    <button :aria-label="$t('buttonLabels.notes')" class="call-widget-button notes-button" @click="isNoteModalOpen = true">
                        <ds-icon name="note" />
                    </button>
                </call-widget-tooltip>

                <call-widget-tooltip
                    v-else
                    class="call-button-tooltip"
                    :class="{ disconnected: isCallDisconnected }"
                    :tooltip="$t('tooltip.addContact')"
                >
                    <button :aria-label="$t('buttonLabels.addContact')" class="call-widget-button add-contact-button" @click="handleAddContactClick">
                        <ds-icon name="contact-add-user-plus" />
                    </button>
                </call-widget-tooltip>

                <call-widget-tooltip class="call-button-tooltip" :class="{ disconnected: isCallDisconnected }" :tooltip="$t('tooltip.dialpad')">
                    <button
                        :aria-label="$t('buttonLabels.dialpad')"
                        class="call-widget-button dialpad-button"
                        :class="{ toggled: isDialerOpen }"
                        @click="handleDialerClick"
                    >
                        <ds-icon name="phone-dialpad" />
                    </button>
                </call-widget-tooltip>

                <call-widget-tooltip
                    data-qa="call-widget-mute-button-tooltip"
                    class="call-button-tooltip"
                    :class="{ disconnected: isCallDisconnected }"
                    :tooltip="muteTooltip"
                >
                    <button
                        :aria-label="$t(`buttonLabels.${isMuted ? 'unmute' : 'mute'}`)"
                        class="call-widget-button mute-button"
                        :class="{ toggled: isMuted }"
                        @click="handleMuteClick"
                    >
                        <ds-icon :name="isMuted ? 'mic-off' : 'mic-on'" />
                    </button>
                </call-widget-tooltip>

                <call-widget-tooltip class="call-button-tooltip" :class="{ disconnected: isCallDisconnected }" :tooltip="$t('tooltip.endCall')">
                    <button :aria-label="$t('buttonLabels.end')" class="call-widget-button end-call-button" @click="handleEndCallClick">
                        <ds-icon name="phone-fill" />
                    </button>
                </call-widget-tooltip>
            </div>

            <div
                v-if="showPostCall"
                ref="postCall"
                key="post-call"
                class="post-call"
            >
                <div class="post-call-buttons">
                    <template v-if="hasContactId">
                        <ds-text-button
                            class="post-call-button"
                            dense
                            leading-icon="check-circle"
                            @click="isTaskModalOpen = true"
                        >
                            {{ $t('postCall.addTask') }}
                        </ds-text-button>

                        <ds-text-button
                            class="post-call-button"
                            dense
                            leading-icon="note-fill"
                            @click="isNoteModalOpen = true"
                        >
                            {{ $t('postCall.callNote') }}
                        </ds-text-button>
                    </template>

                    <template v-else>
                        <ds-text-button
                            class="post-call-button"
                            dense
                            leading-icon="phone-fill"
                            @click="handleCallBackClick"
                        >
                            {{ $t('postCall.callBack') }}
                        </ds-text-button>

                        <ds-text-button
                            class="post-call-button"
                            dense
                            leading-icon="user-fill"
                            @click="handleAddContactClick"
                        >
                            {{ $t('postCall.addContact') }}
                        </ds-text-button>
                    </template>
                </div>

                <hr />

                <div class="call-feedback">
                    <span class="call-quality">{{ $t('postCall.callQuality') }}</span>

                    <div v-if="!feedbackSubmitted" class="call-feedback-buttons">
                        <button :aria-label="$t('postCall.goodCallQuality')" class="call-feedback-button thumbs-up" @click="handleGoodFeedback">
                            <ds-icon name="thumbs-up" />
                        </button>

                        <button :aria-label="$t('postCall.badCallQuality')" class="call-feedback-button thumbs-down" @click="handleBadFeedback">
                            <ds-icon name="thumbs-down" />
                        </button>
                    </div>

                    <span v-else class="feedback-submitted">
                        {{ $t('postCall.thanks') }}
                    </span>
                </div>
            </div>

            <div ref="dialer" class="dialer">
                <div class="dialer-buttons">
                    <button
                        v-for="({ value, letters }, i) in $options.DIALER_OPTIONS"
                        :key="i"
                        class="dialer-button"
                        @click="handleDialerButtonClick(value)"
                    >
                        <span class="dialer-number">{{ value }}</span>

                        <span class="dialer-letters">{{ letters }}</span>
                    </button>
                </div>
            </div>

            <div v-if="showError" class="error">
                <ds-icon name="alert-triangle" />

                <span class="error-message">{{ $t('error') }}</span>
            </div>
        </div>

        <div ref="settings" class="settings">
            <div class="settings-content">
                <div class="settings-header">
                    <span>{{ $t('settings.microphone') }}</span>

                    <call-volume :value="micVolume" />
                </div>

                <ds-select-field
                    dense
                    data-qa="call-mic-device"
                    :placeholder="$t('settings.noDevices')"
                    :options="micDevices"
                    :value="activeMicDevice"
                    @input="handleMicChange"
                />

                <span class="settings-header">{{ $t('settings.speakers') }}</span>

                <ds-select-field
                    dense
                    data-qa="call-speaker-device"
                    :placeholder="$t('settings.noDevices')"
                    :options="speakerDevices"
                    :value="activeSpeakerDevice"
                    @input="handleSpeakerChange"
                />

                <ds-inline-alert class="settings-alert" leading-icon type="subtle">
                    <span>{{ $t('settings.alert') }}</span>
                </ds-inline-alert>
            </div>
        </div>

        <portal to="root">
            <add-note-modal
                v-if="isNoteModalOpen"
                data-qa="call-note-modal"
                :contact="contact"
                @close="isNoteModalOpen = false"
                @saved="isNoteModalOpen = false"
            />
        </portal>

        <portal to="root">
            <task-form
                data-qa="call-task-modal"
                :contact="contact"
                :event-source="'Call Widget'"
                :is-open="isTaskModalOpen"
                @cancel="isTaskModalOpen = false"
                @close="isTaskModalOpen = false"
            />
        </portal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import CallWidgetTooltip from './CallWidgetTooltip';
import CallVolume from './CallVolume';
import AddNoteModal from '@/contacts/components/record/notes/AddNoteModal';
import AddContactModal from '@/contacts/components/add/AddContactModal';
import TaskForm from '@/tasks/components/TaskForm';

import { CALL_STATUSES } from '@/communication/constants/calls.constants';

import smsMixins from '@/communication/mixins/sms.mixin';

import '@/communication/images/drag.svg';

const formatDuration = (duration) => {
    const durationInSeconds = Math.floor(duration / 1000);
    const seconds = durationInSeconds % 60;
    const minutes = Math.floor(durationInSeconds / 60);

    const formattedSeconds = seconds.toLocaleString(undefined, { minimumIntegerDigits: 2 });
    const formattedMinutes = minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 });

    return `${formattedMinutes}:${formattedSeconds}`;
};

const DIALER_OPTIONS = [
    { value: '1', letters: '' },
    { value: '2', letters: 'ABC' },
    { value: '3', letters: 'DEF' },
    { value: '4', letters: 'GHI' },
    { value: '5', letters: 'JKL' },
    { value: '6', letters: 'MNO' },
    { value: '7', letters: 'PQRS' },
    { value: '8', letters: 'TUV' },
    { value: '9', letters: 'WXYZ' },
    { value: '*', letters: ' ' },
    { value: '0', letters: '+' },
    { value: '#', letters: ' ' },
];

const {
    PENDING,
    ACCEPTED,
    DISCONNECTING,
    DISCONNECTED,
    ERROR,
} = CALL_STATUSES;

export default {
    name: 'CallWidget',

    DIALER_OPTIONS,

    components: {
        CallVolume,
        CallWidgetTooltip,
        AddNoteModal,
        TaskForm,
    },

    mixins: [smsMixins],

    data() {
        return {
            isDialerOpen: false,
            isNoteModalOpen: false,
            isTaskModalOpen: false,
            isSettingsOpen: false,
            openingDialer: false,
            openingSettings: false,
            feedbackSubmitted: false,
            mouseX: 0,
            mouseY: 0,
        };
    },

    mounted() {
        this.$store.dispatch('communication/SETUP_CALL_DEVICE');
    },

    destroyed() {
        this.$store.dispatch('communication/DESTROY_CALL_DEVICE');
    },

    watch: {
        callStatus: {
            async handler(callStatus) {
                await this.$nextTick();

                switch (callStatus) {
                case ACCEPTED:
                case PENDING:
                case ERROR:
                    this.feedbackSubmitted = false;
                    break;
                case DISCONNECTING:
                    this.reset();
                    break;
                case DISCONNECTED:
                    this.handlePostCallExpand();
                    break;
                default:
                    this.$el.style.left = null;
                    this.$el.style.top = null;
                    break;
                }
            },
            immediate: true,
        },
    },

    computed: {
        ...mapState({
            callDuration: ({ communication }) => communication.call.duration,
            callStatus: ({ communication }) => communication.call.callStatus,
            contact: ({ communication }) => communication.call.contact,
            contactId: ({ communication }) => communication.call.contact.id,
            isMuted: ({ communication }) => communication.call.isMuted,
            micDevices: ({ communication }) => communication.call.mic.devices,
            speakerDevices: ({ communication }) => communication.call.speaker.devices,
            activeMicDevice: ({ communication }) => communication.call.mic.activeDevice,
            activeSpeakerDevice: ({ communication }) => communication.call.speaker.activeDevice,
            micVolume: ({ communication }) => communication.call.mic.volume,
        }),

        callStatusLabel() {
            switch (this.callStatus) {
            case PENDING:
                return this.$t('callStatus.calling');
            case ACCEPTED:
                return formatDuration(this.callDuration);
            case DISCONNECTING:
            case DISCONNECTED:
            case ERROR:
                return this.$t('callStatus.callEnded');
            default:
                return '';
            }
        },

        displayName() {
            const { firstName, lastName, phoneNumber } = this.contact || {};

            return this.sms_getDisplayName(firstName, lastName, phoneNumber);
        },

        hasContactId() {
            return Boolean(parseInt(this.contactId, 10));
        },

        muteTooltip() {
            return this.isMuted
                ? this.$t('tooltip.muted')
                : this.$t('tooltip.mute');
        },

        isCallDisconnected() {
            return [DISCONNECTING, DISCONNECTED].includes(this.callStatus);
        },

        showCallButtons() {
            return [ACCEPTED, PENDING, DISCONNECTING, ERROR].includes(this.callStatus);
        },

        showPostCall() {
            return this.callStatus === DISCONNECTED;
        },

        showError() {
            return this.callStatus === ERROR;
        },
    },

    methods: {
        // Used https://codepen.io/normancarcamo/pen/pVzWJR as example
        handleMouseDown(event) {
            event.preventDefault();
            document.addEventListener('mousemove', this.handleMouseMove);
            document.addEventListener('mouseup', this.handleMouseUp);

            this.mouseX = Math.abs(this.$el.offsetLeft - event.clientX);
            this.mouseY = Math.abs(this.$el.offsetTop - event.clientY);
        },

        handleMouseUp() {
            document.removeEventListener('mousemove', this.handleMouseMove);
            document.removeEventListener('mouseup', this.handleMouseUp);
        },

        handleMouseMove(event) {
            this.$el.style.left = `${event.clientX - this.mouseX}px`;
            this.$el.style.top = `${event.clientY - this.mouseY}px`;
        },

        handleAddContactClick() {
            const successCallback = ({
                id,
                givenName,
                familyName,
                phone,
            }) => {
                this.$store.dispatch('communication/ADD_CONTACT_FROM_CALL', {
                    id,
                    firstName: givenName,
                    lastName: familyName,
                    phoneNumber: phone.value,
                });
            };

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddContactModal,
                data: {
                    contact: {
                        phone1: {
                            value: this.contact.phoneNumber,
                        },
                    },
                    hideSuccessModal: true,
                    successCallback,
                },
                showRootClose: true,
            });
        },

        handleDialerClick() {
            if (this.openingDialer) return;

            this.openingDialer = true;
            setTimeout(() => { this.openingDialer = false; }, 350);

            if (!this.isDialerOpen) {
                const { dialer, settings } = this.$refs;

                const dialerHeight = dialer.scrollHeight;

                dialer.style.height = `${dialerHeight}px`;

                const { height: parentHeight } = this.$parent.$el.getBoundingClientRect();
                const { top: widgetTop, bottom: widgetBottom } = this.$el.getBoundingClientRect();

                const shouldOpenUpward = widgetBottom + dialerHeight > parentHeight;
                const settingsOffset = this.isSettingsOpen ? settings.style.height : 0;

                if (shouldOpenUpward) {
                    this.$el.style.transition = 'top .35s ease';
                    this.$el.style.top = `${widgetTop - dialerHeight - settingsOffset}px`;

                    setTimeout(() => {
                        this.$el.style.transition = null;
                    }, 350);
                }

                this.collapseSettings();

                this.isDialerOpen = true;
            } else {
                this.collapseDialer();
            }
        },

        collapseDialer() {
            this.$refs.dialer.style.height = null;

            this.isDialerOpen = false;
        },

        handleDialerButtonClick(value) {
            this.$store.dispatch('communication/SEND_DIGIT', value);
        },

        handleMuteClick() {
            this.$store.dispatch('communication/TOGGLE_MUTE');
        },

        handleSettingsClick() {
            if (this.openingSettings) return;

            this.openingSettings = true;
            setTimeout(() => { this.openingSettings = false; }, 350);

            if (!this.isSettingsOpen) {
                const { dialer, settings } = this.$refs;

                const settingsHeight = settings.scrollHeight;

                settings.style.height = `${settingsHeight}px`;
                setTimeout(() => {
                    settings.style.overflow = 'initial';
                }, 350);

                const { height: parentHeight } = this.$parent.$el.getBoundingClientRect();
                const { top: widgetTop, bottom: widgetBottom } = this.$el.getBoundingClientRect();

                const shouldOpenUpward = widgetBottom + settingsHeight > parentHeight;
                const dialerOffset = this.isDialerOpen ? dialer.style.height : 0;

                if (shouldOpenUpward) {
                    this.$el.style.transition = 'top .35s ease';
                    this.$el.style.top = `${widgetTop - settingsHeight - dialerOffset}px`;

                    setTimeout(() => {
                        this.$el.style.transition = null;
                    }, 350);
                }

                this.collapseDialer();

                this.isSettingsOpen = true;
            } else {
                this.collapseSettings();
            }
        },

        collapseSettings() {
            this.$refs.settings.style.height = null;
            this.$refs.settings.style.overflow = null;

            this.isSettingsOpen = false;
        },

        handleMicChange(value) {
            this.$store.dispatch('communication/SET_MIC_DEVICE', value);
        },

        handleSpeakerChange(value) {
            this.$store.dispatch('communication/SET_SPEAKER_DEVICE', value);
        },

        handleEndCallClick() {
            this.reset();
            this.$store.dispatch('communication/END_CALL');
        },

        handleCallBackClick() {
            this.$store.dispatch('communication/START_CALL', this.contact);
        },

        handlePostCallExpand() {
            const { postCall } = this.$refs;

            const postCallHeight = postCall.scrollHeight;

            postCall.style.height = `${postCallHeight}px`;
            setTimeout(() => {
                postCall.style.overflow = 'initial';
            }, 350);

            const { height: parentHeight } = this.$parent.$el.getBoundingClientRect();
            const { top: widgetTop, bottom: widgetBottom } = this.$el.getBoundingClientRect();

            const shouldOpenUpward = widgetBottom + postCallHeight > parentHeight;

            if (shouldOpenUpward) {
                this.$el.style.transition = 'top .35s ease';
                this.$el.style.top = `${widgetTop - postCallHeight - 36}px`;

                setTimeout(() => {
                    this.$el.style.transition = null;
                }, 350);
            }
        },

        handleGoodFeedback() {
            this.$store.dispatch('communication/SUBMIT_FEEDBACK', { score: 5 });
            this.feedbackSubmitted = true;
        },

        handleBadFeedback() {
            this.$store.dispatch('communication/SUBMIT_FEEDBACK', { score: 1 });
            this.feedbackSubmitted = true;
        },

        reset() {
            this.collapseDialer();
            this.collapseSettings();
        },
    },
};
</script>

<style lang="scss" scoped>
.call-widget {
    position: absolute;
    left: 4%;
    top: 78%;
    z-index: 1000;
    width: px-to-rem(276);
    background: transparent;
    box-shadow: $elevation-z16;
    border-radius: $border-radius;
    backdrop-filter: blur(6px);
    animation: .35s ease 0s enter;
    will-change: transform;

    &.disconnected {
        transform-origin: bottom left;
        animation: scaleOutBottomLeft .35s ease-out 9.65s forwards;
    }
}

@keyframes enter {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes scaleOutBottomLeft {
    from {
        transform: scale(100%);
        opacity: 1;
    }

    to {
        transform: scale(0);
        opacity: 0;
    }
}

.call-widget-content {
    padding: $gp;
    background: linear-gradient(204.83deg, rgba(0, 0, 0, 0.825) 49.09%, rgba(0, 0, 0, 0.495) 105.48%);
    border-radius: $border-radius;
}

.drag-icon {
    @include margin((-$gp / 2) (-$gp / 2) 0 0);
    float: right;

    button {
        cursor: move;
        padding: 0;
        background: transparent;
    }

    svg {
        color: $color-gray-700;
        height: px-to-rem(20);
        width: px-to-rem(20);
    }
}

.view-contact-link {
    color: $color-paper;
    font-size: px-to-rem(18);
    font-weight: $font-weight-bold;
    --icon-size: #{px-to-rem(16)};
    margin-bottom: $gp * .75;
    text-decoration: none;
}

.call-status {
    display: flex;
    color: $color-paper;
    font-size: $font-size-med;

    :not(:first-child) {
        @include margin-start($gp / 4);
    }
}

.call-widget-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: $gp * .75;
    opacity: 1;
    transition: opacity .35s ease .35s;

    &.disconnected {
        opacity: 0;
    }
}

.call-button-tooltip {
    transform: translateX(0) rotate(0);
    transition: transform .35s ease;
    will-change: transform;

    --button-width-plus-gap: 52px;

    &.disconnected:nth-child(1) {
        transform: translateX(calc(var(--button-width-plus-gap) * 4)) rotate(90deg);
    }

    &.disconnected:nth-child(2) {
        transform: translateX(calc(var(--button-width-plus-gap) * 3)) rotate(90deg);
    }

    &.disconnected:nth-child(3) {
        transform: translateX(calc(var(--button-width-plus-gap) * 2)) rotate(90deg);
    }

    &.disconnected:nth-child(4) {
        transform: translateX(var(--button-width-plus-gap)) rotate(90deg);
    }
}

.call-widget-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    background: $color-gray-900;
    border-radius: 50%;
    height: 2.25rem;
    width: 2.25rem;
    --icon-size: #{px-to-rem(18)};

    &:hover,
    &:active {
        background: $color-ink;
    }
}

.toggled {
    background: $color-paper;
    --icon-color: #{$color-ink};

    &:hover {
        background: $color-paper-900;
    }

    &:active {
        background: $color-paper;
    }
}

.add-contact-button {
    --icon-size: #{px-to-rem(22)};

    svg {
        transform: scale(77%);
    }
}

.end-call-button {
    transform: rotate(137.65deg);
    background: linear-gradient(210.26deg, #E02500 6.75%, #E90070 91.12%);
    --icon-color: #{$color-paper};

    &:hover,
    &:active {
        background: linear-gradient(210.26deg, #830101 6.75%, #A80303 91.12%);
    }
}

.settings {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
    will-change: transform;
}

.settings-content {
    display: flex;
    flex-direction: column;
    background: $color-paper;
    padding: $gp * .75;
    border-radius: $border-radius;
    --input-font-size: #{$font-size-sm};
}

.settings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $gp / 2;
    font-size: $font-size-xs;
    font-weight: $font-weight-semibold;
}

.settings-alert {
    color: $color-text-normal;

    span {
        font-size: $font-size-xs;
    }
}

.dialer {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
    will-change: transform;
}

.dialer-buttons {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: $gp;
}

.dialer-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: px-to-rem(60);
    width: px-to-rem(60);
    border-radius: 50%;
    background: transparent;
    color: $color-paper;
    padding: 0;

    &:hover {
        background: $color-gray-800;
    }

    &:active {
        background: $color-gray-700;
    }
}

.dialer-number {
    font-size: px-to-rem(22);
}

.dialer-letters {
    display: inline-block;
    font-size: px-to-rem(10);

    &:empty::before {
        content: "\200b";
    }
}

.error {
    display: flex;
    margin: $gp (-$gp) (-$gp);
    padding: $gp / 2 $gp;
    background: $color-red;
    color: $color-paper;
    border-radius: 0 0 $border-radius $border-radius;
    --icon-size: #{1rem};
}

.error-message {
    @include padding-start($gp / 2);
}

.post-call {
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
    will-change: transform;

    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadeIn .35s ease-in;

    hr {
        margin: $gp * .75 0;
        border-color: $color-ink;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.post-call-buttons {
    display: flex;
    justify-content: space-between;
    padding-top: $gp / 2;
    margin: 0 (-$gp / 2);
}

.post-call-button {
    --button-text-color: #{$color-paper};

    &:hover {
        --button-background-color: #{$color-paper-200};
    }

    &:active {
        --button-text-color: #{$color-paper};
        --button-background-color: #{$color-paper-100};
    }
}

.call-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.call-quality {
    color: $color-paper-800;
    margin-bottom: $gp / 4;
}

.call-feedback-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    height: 1.875rem;
    width: 1.875rem;
    --icon-size: 1rem;

    &:not(:first-child) {
        @include margin-start($gp * .75);
    }

    &.thumbs-up {
        --button-background-color: #{$color-green};

        &:hover {
            --button-background-color: #{$color-green-700};
        }

        &:active {
            --button-background-color: #{$color-green-800};
        }
    }

    &.thumbs-down {
        --button-background-color: #{$color-red};

        &:hover {
            --button-background-color: #{$color-red-900};
        }

        &:active {
            --button-background-color: #{$color-red-950};
        }
    }
}

.feedback-submitted {
    padding-top: $gp / 4;
    font-size: $font-size-med;
    color: $color-paper;
    font-weight: $font-weight-bold;
    animation: fadeIn .35s ease-in;
}
</style>

<i18n>
{
    "en-us": {
        "tooltip": {
            "dragToMove": "Drag to move",
            "viewContact": "View contact",
            "settings": "Settings",
            "callNotes": "Call notes",
            "addContact": "Add contact",
            "dialpad": "Keypad",
            "mute": "Mute",
            "muted": "Unmute",
            "endCall": "End call"
        },
        "muted": "muted",
        "buttonLabels": {
            "settings": "Call settings",
            "notes": "Call notes",
            "addContact": "Add contact",
            "dialpad": "Phone dialpad",
            "mute": "Mute call",
            "unmute": "Unmute call",
            "end": "End call"
        },
        "callStatus": {
            "calling": "Calling...",
            "callEnded": "Call ended"
        },
        "settings": {
            "microphone": "Microphone",
            "speakers": "Speakers",
            "noDevices": "No devices detected",
            "alert": "Calling and voicemail settings are available in the Keap mobile app."
        },
        "postCall": {
            "addTask": "Add task",
            "callNote": "Take call note",
            "callBack": "Call back",
            "addContact": "Add as contact",
            "callQuality": "How was the call quality?",
            "thanks": "Thanks for the feedback",
            "goodCallQuality": "Call quality was good",
            "badCallQuality": "Call quality was bad"
        },
        "error": "A connection error occurred"
    }
}
</i18n>
