<template lang="html">
    <div class="stage-indicators">
        <div
            v-for="(stage, key) in stages"
            v-show="!stage.hidden"
            :key="key"
            :class="['stage', getStatusClasses(stage)]"
            @click="changeStage(stage)"
        >
            <div class="circle">
                <ds-icon name="check" />
            </div>

            <p class="label">
                {{ $t(stage.label) }}
            </p>
        </div>
    </div>
</template>

<script>
import isEqual from 'lodash.isequal';

export default {
    props: {
        active: {
            type: Object,
            required: true,
        },

        stages: {
            type: Object,
            required: true,
        },
    },

    methods: {
        getStatusClasses (stage) {
            return {
                active: isEqual(this.active, stage),
                ready: stage.ready,
            };
        },

        changeStage(stage) {
            this.$emit('changeStage', stage);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    $circle-radius: 13px;

    .stage-indicators {
        display: flex;
        margin: 0 auto;
        max-width: px-to-rem(500px);
        width: 100%;
    }

    .stage {
        cursor: pointer;
        flex: 1;
        position: relative;
    }

    .stage + .stage {
        &:after {
            content: "";
            width: calc(100% - #{px-to-rem($circle-radius * 2)});
            height: px-to-rem(2px);
            background: $color-gray-200;
            @include position(absolute px-to-rem(12px) calc(50% + #{px-to-rem($circle-radius)}) auto auto);
        }

        &.active:after, &.ready:after {
            background: $color-blue;
        }
    }

    div.circle {
        @include circle(px-to-rem($circle-radius * 2));
        display: flex;
        margin: 0 auto;
        border: solid 2px $color-gray-200;
        overflow: hidden;
    }

    .active .circle {
        border-color: $color-blue;
    }

    .ready .circle {
        border-color: $color-blue;
        background-color: $color-blue;
    }

    .circle .icon {
        --icon-color: #{$color-paper};
        --icon-size: #{$gp};
    }

    .label {
        text-align: center;
        margin-top: $gp / 4;
        font-weight: $font-weight-semibold;
    }
</style>
