<template>
    <div :class="['then-card', { 'configured': stepSelected, active, inactive }]" @click="$emit('selected')">
        <header class="header">
            <ds-icon class="zap-icon" name="zap" />

            <span class="header-text">{{ $t('header') }}</span>
        </header>

        <div v-if="!stepSelected" class="description">
            <p class="description-title">
                {{ $t('description') }}
            </p>

            <ds-icon-button class="add-button" name="add-fill" />
        </div>

        <template v-else>
            <div
                v-for="(display, i) in stepDisplay"
                :key="display.stepIndex"
                :class="['description', { 'first-step' : i === 0, 'timer-description': isTimerStep(display.step) }]"
                @click.stop="handleSelect(display.selectableStepIndex)"
            >
                <invalid-icon v-if="isInvalid(display.step, display.stepIndex)" class="invalid-icon" />

                <ds-icon
                    v-else-if="isTimerStep(display.step)"
                    :name="hasTimerWarning(display.stepIndex) ? 'alert-triangle' : 'clock'"
                    :class="['timer-icon', { 'warning': hasTimerWarning(display.stepIndex) }]"
                />

                <automation-display-name
                    :class="['description-title', {
                        'timer-description-title': isTimerStep(display.step),
                        flash: showFlash(display.children ? display.children[0].step : display.step),
                        'alert-text': isInvalid(display.step, display.stepIndex),
                        warning: hasTimerWarning(display.stepIndex)
                    }]"
                    :value="display.step"
                    :data-qa="`step_${display.stepIndex}`"
                />

                <div
                    v-for="(child, j) in display.children"
                    :key="child.stepIndex"
                    :class="['nested-step', { 'first-child-step' : j === 0, 'last-step' : j + 1 === display.children.length }]"
                    @click.stop="handleSelect(child.selectableStepIndex)"
                >
                    <invalid-icon v-if="isInvalid(child.step, child.stepIndex)" class="invalid-icon" />

                    <automation-display-name
                        :class="['description-title', {
                            flash: showFlash(child.step),
                            'alert-text': isInvalid(child.step, child.stepIndex)
                        }]"
                        :value="child.step"
                        :data-qa="`step_${child.stepIndex}`"
                    />

                    <div v-if="j < display.children.length - 1" class="divider">
                        <ds-icon class="icon" name="chevron-down" />
                    </div>
                </div>

                <div v-if="i < stepDisplay.length - 1" class="divider">
                    <ds-icon class="icon" name="chevron-down" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ERROR_LEVELS, DELAY_TIMER, RELATIVE_TIMER } from '@/automations/constants/automations.constants';
import { isValidStep } from '@/automations/utils/automations.utils';
import InvalidIcon from '@/shared/components/Email/InvalidIcon';
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import { FLASH_DELAY } from '@/shared/constants/timing.constants';

export default {
    components: {
        AutomationDisplayName,
        InvalidIcon,
    },

    props: {
        steps: {
            type: Array,
            default: () => ([]),
        },
        active: Boolean,
        inactive: Boolean,
        showErrors: Boolean,
        // set flash delay long enough for animation to complete
        flashDelay: {
            type: Number,
            default: FLASH_DELAY,
        },
    },

    data() {
        return {
            flash: false,
        };
    },

    mounted() {
        this.$bus.$on('AUTOMATION_STEPS_REORDERED', this.toggleFlash);
    },

    beforeDestroy() {
        this.$bus.$off('AUTOMATION_STEPS_REORDERED', this.toggleFlash);
    },

    computed: {
        ...mapState({
            validatedAutomation: ({ automations }) => automations.validatedAutomation,
        }),

        ...mapGetters({
            automationWithFrontendErrors: 'automations/automationWithFrontendErrors',
        }),

        stepSelected() {
            return this.steps.length;
        },

        stepDisplay() {
            const display = [];
            let displayIndex = 0;
            let selectableStepIndex = 0;
            let isInsideTimer = false;

            for (let i = 0; i < this.steps.length; i++) {
                const step = this.steps[i];

                if (this.isTimerStep(step)) {
                    if (isInsideTimer) {
                        displayIndex++;
                    }
                    isInsideTimer = true;
                    display.push({
                        step, stepIndex: i, children: [], selectableStepIndex,
                    });
                } else if (isInsideTimer) {
                    display[displayIndex].children.push({
                        step, stepIndex: i, selectableStepIndex,
                    });
                    selectableStepIndex++;
                } else {
                    display.push({
                        step, stepIndex: i, selectableStepIndex,
                    });
                    displayIndex++;
                    selectableStepIndex++;
                }
            }

            return display;
        },
    },

    methods: {
        toggleFlash() {
            this.flash = true;

            setTimeout(() => {
                this.flash = false;
            }, this.flashDelay);
        },

        showFlash(step) {
            return this.flash && step.flash;
        },

        isInvalid(step, i) {
            return Boolean(this.showErrors
                && (!isValidStep(step) || this.hasValidationError(step, i)));
        },

        hasValidationError(step, i) {
            return this.automationWithFrontendErrors?.steps?.[i]?.messages?.some(({ severity }) => severity === ERROR_LEVELS.ERROR)
                || this.validatedAutomation?.steps?.[i]?.messages?.some(({ severity }) => severity === ERROR_LEVELS.ERROR);
        },

        hasTimerWarning(i) {
            return this.validatedAutomation?.steps?.[i]?.messages?.some(({ severity }) => severity === ERROR_LEVELS.WARNING);
        },

        isTimerStep({ type }) {
            return type === RELATIVE_TIMER || type === DELAY_TIMER;
        },

        handleSelect(index) {
            this.$emit('select-step', index);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/whenThenCard';
</style>

<i18n>
{
    "en-us": {
        "header": "Then",
        "description": "...this will be automated."
    }
}
</i18n>
