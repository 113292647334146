
export const KeapEvents = {
    createFormField: 'createFormField',
    getAppointmentTypeOptions: 'getAppointmentTypeOptions',
    getCheckoutFormOptions: 'getCheckoutFormOptions',
    getFunnelFormCount: 'getFunnelFormCount',
    getMarketingSiteOptions: 'getMarketingSiteOptions',
    getMarketingPageOptions: 'getMarketingPageOptions',
    createUnlayerPage: 'createUnlayerPage',
    focusUnlayerPage: 'focusUnlayerPage',
    sortUnlayerPages: 'sortUnlayerPages',
    saveUnlayerPage: 'saveUnlayerPage',
    deleteUnlayerPage: 'deleteUnlayerPage',
    getUnlayerPageDetails: 'getUnlayerPageDetails',
};
