import { LOADING, SUCCESS, ERROR } from '@/contacts/loadingStatuses';

export const LOAD_TASK_LIST_START = (state) => {
    state.tasksLoadingStatus = LOADING;
    state.tasks = [];
};

export const LOAD_TASK_LIST_SUCCESS = (state, tasks) => {
    state.tasksLoadingStatus = SUCCESS;
    state.tasks = tasks.map((task) => {
        return {
            ...task,
            completed: !!task.completionDate,
        };
    });
};

export const LOAD_TASK_LIST_ERROR = (state) => {
    state.tasksLoadingStatus = ERROR;
};
