<template lang="html">
    <div class="contact-count-indicator">
        <span class="subtle">
            {{ $t('yourAccountLabel') }}
        </span>

        <ds-progress-bar :value="contactTotal" :max="contactLimit" />

        {{ $t('contactSummary', { contactTotal, contactLimit }) }}
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    created() {
        this.$store.dispatch('contactImport/GET_CONTACT_LIMIT');
    },

    computed: {
        ...mapState({
            contactTotal: ({ contacts }) => contacts.contactTotal,
            contactLimit: ({ contactImport }) => contactImport.contactLimit,
        }),
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .contact-count-indicator {
        text-align: center;
        max-width: px-to-rem(300px);
        margin: $gp * 2 auto;
        font-size: $font-size-xsmall;
        display: grid;
        grid-gap: $gp / 4;
    }

    .progress-bar {
        margin-bottom: 0;
    }

    .bar-label {
        color: $color-gray-700;
    }
</style>

<i18n>
{
    "en-us": {
        "yourAccountLabel": "Your account",
        "contactSummary": "{contactTotal} of {contactLimit} contacts used"
    }
}
</i18n>
