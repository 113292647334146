<template>
    <section v-if="loaded" class="timing-step">
        <p class="step-name">
            <slot />
        </p>

        <ds-button-toggle
            v-model="timing"
            :options="timingOptions"
            prevent-single-deselect
            class="timing-toggle"
            @input="handleTimingUpdate"
        />

        <div v-if="showTimingDelay" class="timing-step-delay">
            <ds-select-field
                v-model="delayType"
                class="delay-type-input"
                name="delayType"
                bind-value-only
                data-qa="delay-type"
                :options="delayTypeOptions"
                :label="$t('delayType.label')"
                @input="handleUpdateDelayType"
            >
                <template #help>
                    <div v-if="timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY" data-qa="delay-help-text">
                        {{ $t('delayTypeMessage' ) }}
                    </div>
                </template>
            </ds-select-field>

            <div v-if="showOffset" class="offset">
                <ds-input-field
                    v-model="count"
                    data-qa="timing-count"
                    type="text"
                    class="offset-count"
                    :readonly="disableOffset"
                    @input="handleUpdate"
                    @blur="setDefaultCount"
                />

                <ds-select-field
                    v-model="unit"
                    data-qa="timing-unit"
                    class="offset-select"
                    :options="timingUnits"
                    :readonly="disableOffset"
                    bind-value-only
                    @input="handleUpdate"
                />
            </div>

            <ds-button-toggle
                v-if="isEventType"
                v-model="offsetType"
                :options="offsetTypeOptions"
                prevent-single-deselect
                class="offset-toggle"
                @input="handleUpdateOffset"
            />

            <ds-select-field
                v-if="isEventType"
                v-model="eventType"
                class="event-type-input"
                name="eventType"
                bind-value-only
                data-qa="event-type"
                :options="eventTypeOptions"
                :label="$t('eventType.label')"
                @input="handleUpdate"
            />

            <ds-date-picker
                v-if="showExactDatePicker"
                v-model="exactDate"
                class="exact-date"
                name="exactDatePicker"
                :label="$t('exactDate')"
                date-only
                disable-before-date="today"
                :force-invalid="showExactDateError"
                @input="handleUpdate"
                @close="setDefaultExactTimer"
            >
                <template v-if="showExactDateError" #error>
                    {{ $t('pastDateError') }}
                </template>
            </ds-date-picker>
        </div>

        <div v-if="showTimingDelay && !isAppointmentEvent" class="time-options-container">
            <ds-checkbox
                v-model="exactTime"
                :label="$t('range.label')"
                class="checkbox-container"
                @input="handleUpdateTime"
            />

            <div v-if="exactTime" class="time-options">
                <ds-button-toggle
                    v-model="rangeOption"
                    data-qa="range-toggle"
                    class="range-toggle"
                    :options="rangeOptions"
                    prevent-single-deselect
                    @input="handleUpdateRange"
                />

                <div class="range-options">
                    <ds-date-picker
                        v-model="startTime"
                        :label="$t('range.timeLabel')"
                        class="start-time"
                        time-only
                        :force-invalid="showExactTimeError"
                        @input="handleUpdate"
                    >
                        <template v-if="showExactTimeError" #error>
                            {{ $t('pastDateError') }}
                        </template>
                    </ds-date-picker>

                    <template v-if="showBetweenOptions">
                        <span class="range-and">{{ $t('range.and') }}</span>

                        <ds-date-picker
                            v-model="endTime"
                            :label="$t('range.timeLabel')"
                            class="end-time"
                            time-only
                            @input="handleUpdate"
                        />
                    </template>
                </div>
            </div>
        </div>

        <div v-if="showReorderMessage" class="reorder-message">
            <div class="details">
                <ds-icon name="alert-octagon" />

                <p class="message">
                    {{ $t('reorder.message') }}
                </p>
            </div>

            <ds-text-button
                class="dismiss-button"
                @click="dismissReorderMessage"
            >
                {{ $t('global.dismiss') }}
            </ds-text-button>
        </div>
    </section>
</template>

<script>
import moment from 'moment';
import amplitude from '@/analytics/amplitude';
import { mapGetters } from 'vuex';
import { DELAY_TIMER, RELATIVE_TIMER, TIMING_UNIT } from '@/automations/constants/automations.constants';
import {
    AUTOMATION_TIMER_TYPES, EVENT_TYPES, RANGE_OPTIONS, OFFSET_TYPES,
} from '@/automations/constants/automations.timer.constants';
import { DATE_FORMAT, TIME_FORMAT } from '@/shared/constants/dateFormats.constants';

const DELAY_TYPES = {
    DELAY: 'delay',
    EXACT: 'exact',
    EVENT: 'event',
};

const TIMING_TOGGLE = {
    IMMEDIATELY: 'immediately',
    DELAYED: 'delayed',
};

const DEFAULT_UNIT = TIMING_UNIT.DAYS;
const DEFAULT_COUNT = 1;
const DEFAULT_START_TIME = '0800';
const DEFAULT_END_TIME = '2000';

export default {
    props: {
        delayStep: Object,
        stepListIndex: Number,
    },

    data() {
        return {
            loaded: false,
            delayTypeOptions: [],
            eventTypeOptions: [],
            timing: TIMING_TOGGLE.IMMEDIATELY,
            delayType: DELAY_TYPES.DELAY,
            eventType: EVENT_TYPES.APPOINTMENT,
            exactDate: moment().toDate(),
            offsetType: OFFSET_TYPES.BEFORE,

            count: DEFAULT_COUNT,
            unit: DEFAULT_UNIT,

            exactTime: Boolean(this.delayStep?.configJson?.startTime),
            rangeOption: this.delayStep?.configJson?.endTime ? RANGE_OPTIONS.BETWEEN : RANGE_OPTIONS.AT,
            startTime: this.delayStep?.configJson?.startTime,
            endTime: this.delayStep?.configJson?.endTime,

            timerTypeAllowed: '',
            AUTOMATION_TIMER_TYPES,
            showReorderMessage: false,
        };
    },

    mounted() {
        this.setDelayTypeOptions();
        this.setTimingOptions(this.delayStep);
        this.loaded = true;

        this.$bus.$on('AUTOMATION_STEPS_REORDERED', this.toggleReorderMessage);
    },

    beforeDestroy() {
        this.$bus.$off('AUTOMATION_STEPS_REORDERED', this.toggleReorderMessage);
    },

    watch: {
        delayStep: {
            handler(newVal) {
                this.setTimingOptions(newVal);
            },
            deep: true,
        },
    },

    computed: {
        ...mapGetters({
            automationTimerType: 'automations/automationTimerType',
            hasAppointmentTrigger: 'automations/hasAppointmentTrigger',
            hasInvoiceTrigger: 'automations/hasInvoiceTrigger',
        }),

        showTimingDelay() {
            return this.timing === TIMING_TOGGLE.DELAYED;
        },

        showOffset() {
            return this.delayType === DELAY_TYPES.DELAY || this.delayType === DELAY_TYPES.EVENT;
        },

        isEventType() {
            return this.delayType === DELAY_TYPES.EVENT;
        },

        showExactDatePicker() {
            return this.delayType === DELAY_TYPES.EXACT;
        },

        showExactDateError() {
            return moment(this.exactDate, DATE_FORMAT).isBefore(moment().format(DATE_FORMAT));
        },

        showExactTimeError() {
            return this.exactTime
                && moment(this.exactDate, DATE_FORMAT).isSame(moment().format(DATE_FORMAT))
                && !this.endTime && moment(this.startTime, TIME_FORMAT).isBefore(moment());
        },

        showBetweenOptions() {
            return this.rangeOption === RANGE_OPTIONS.BETWEEN;
        },

        disableDelayTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.DELAY
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableExactTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.EXACT
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableAppointmentTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.APPOINTMENT
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableInvoiceTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.INVOICE_DUE
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableEventTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.APPOINTMENT
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.INVOICE_DUE
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableOffset() {
            return this.delayType === DELAY_TYPES.EVENT && this.offsetType === OFFSET_TYPES.NONE;
        },

        isAppointmentEvent() {
            return this.delayType === DELAY_TYPES.EVENT && this.eventType === EVENT_TYPES.APPOINTMENT;
        },

        isInvoiceEvent() {
            return this.delayType === DELAY_TYPES.EVENT && this.eventType === EVENT_TYPES.INVOICE_DUE;
        },

        timingUnits() {
            const shortUnits = [
                { label: this.$tc('minutes', this.count > 1), value: TIMING_UNIT.MINUTES },
                { label: this.$tc('hours', this.count > 1), value: TIMING_UNIT.HOURS },
            ];

            const longUnits = [
                { label: this.$tc('days', this.count > 1), value: TIMING_UNIT.DAYS },
                { label: this.$tc('weeks', this.count > 1), value: TIMING_UNIT.WEEKS },
                { label: this.$tc('months', this.count > 1), value: TIMING_UNIT.MONTHS },
            ];

            return this.isInvoiceEvent ? longUnits : [...shortUnits, ...longUnits];
        },

        timingOptions() {
            return [
                { label: this.$t('timing.immediately'), value: TIMING_TOGGLE.IMMEDIATELY },
                { label: this.$t('timing.delayed'), value: TIMING_TOGGLE.DELAYED },
            ];
        },

        offsetTypeOptions() {
            const noneLabel = this.isAppointmentEvent ? 'offsetType.none' : 'offsetType.noneDay';

            return [
                { label: this.$t('offsetType.before'), value: OFFSET_TYPES.BEFORE },
                { label: this.$t(noneLabel), value: OFFSET_TYPES.NONE },
                { label: this.$t('offsetType.after'), value: OFFSET_TYPES.AFTER },
            ];
        },

        rangeOptions() {
            return [
                { label: this.$t('range.at'), value: RANGE_OPTIONS.AT },
                { label: this.$t('range.between'), value: RANGE_OPTIONS.BETWEEN },
            ];
        },

        timerStep() {
            let delayStep = null;

            if (this.timing === TIMING_TOGGLE.DELAYED) {
                const {
                    delayType, offsetType, exactDate, count, unit, startTime, endTime,
                } = this;

                let configJson = {};

                if (delayType === DELAY_TYPES.DELAY || (delayType === DELAY_TYPES.EVENT && offsetType !== OFFSET_TYPES.NONE)) {
                    configJson = {
                        count: count || DEFAULT_COUNT,
                        unit: unit || DEFAULT_UNIT,
                    };
                }

                if (delayType === DELAY_TYPES.EXACT) {
                    configJson = {
                        ...configJson,
                        eventType: EVENT_TYPES.DATE,
                        exactDate: exactDate ? moment(exactDate).format(DATE_FORMAT) : '',
                    };
                }

                if (delayType === DELAY_TYPES.EVENT) {
                    configJson = {
                        ...configJson,
                        eventType: this.hasAppointmentTrigger ? EVENT_TYPES.APPOINTMENT : EVENT_TYPES.INVOICE_DUE,
                        offsetType: delayType === DELAY_TYPES.EVENT ? offsetType : OFFSET_TYPES.NONE,
                    };
                }

                if (this.exactTime && !this.isAppointmentEvent) {
                    configJson.startTime = startTime;

                    if (this.rangeOption === RANGE_OPTIONS.BETWEEN) {
                        configJson.endTime = endTime;
                    }
                }

                delayStep = {
                    type: delayType === DELAY_TYPES.DELAY ? DELAY_TIMER : RELATIVE_TIMER,
                    name: 'timer',
                    configJson,
                };
            }

            return delayStep;
        },
    },

    methods: {
        setTimingOptions(value) {
            if (value && value.type) {
                const { type, configJson } = value;

                this.timing = TIMING_TOGGLE.DELAYED;

                this.setDelayType(type, configJson);

                this.exactDate = configJson?.exactDate ?? moment().toDate();
                this.offsetType = configJson?.offsetType ?? OFFSET_TYPES.NONE;
                this.eventType = configJson?.eventType;

                if (this.disableOffset) {
                    this.count = 0;
                    this.unit = '';
                } else {
                    this.count = configJson?.count || DEFAULT_COUNT;
                    this.unit = configJson?.unit || DEFAULT_UNIT;
                }

                this.exactTime = Boolean(configJson?.startTime);

                if (this.exactTime) {
                    this.rangeOption = configJson.endTime ? RANGE_OPTIONS.BETWEEN : RANGE_OPTIONS.AT;
                    this.startTime = configJson.startTime;
                    this.endTime = configJson.endTime;
                }
            } else {
                this.timing = TIMING_TOGGLE.IMMEDIATELY;
                this.showReorderMessage = false;
                this.setDefaultDelayType();
            }
        },

        setDelayTypeOptions() {
            // user can only have similar types of timers in an automation (either delay, exact, or event)
            this.timerTypeAllowed = this.automationTimerType(this.stepListIndex);

            this.$set(this, 'delayTypeOptions', [
                {
                    label: this.$t('delayType.delay'),
                    value: DELAY_TYPES.DELAY,
                    className: this.disableDelayTimer ? 'disabled' : '',
                },
                {
                    label: this.$t('delayType.exact'),
                    value: DELAY_TYPES.EXACT,
                    className: this.disableExactTimer ? 'disabled' : '',
                },
            ]);

            if (this.hasAppointmentTrigger || this.hasInvoiceTrigger) {
                this.delayTypeOptions.push({
                    label: this.$t('delayType.event'),
                    value: DELAY_TYPES.EVENT,
                    className: this.disableEventTimer ? 'disabled' : '',
                });

                if (!this.disableEventTimer) {
                    this.setEventTypeOptions();
                }
            }
        },

        setEventTypeOptions() {
            if (this.hasAppointmentTrigger) {
                this.eventTypeOptions.push({
                    label: this.$t('eventType.appointment'),
                    value: EVENT_TYPES.APPOINTMENT,
                    className: this.disableAppointmentTimer ? 'disabled' : '',
                });
            }

            if (this.hasInvoiceTrigger) {
                this.eventTypeOptions.push({
                    label: this.$t('eventType.invoiceDue'),
                    value: EVENT_TYPES.INVOICE_DUE,
                    className: this.disableInvoiceTimer ? 'disabled' : '',
                });
            }
        },

        setDelayType(type, configJson) {
            const eventType = configJson?.eventType;

            if (type === DELAY_TIMER && !this.disableDelayTimer) {
                this.delayType = DELAY_TYPES.DELAY;
            } else if (!this.disableExactTimer && eventType !== EVENT_TYPES.APPOINTMENT && eventType !== EVENT_TYPES.INVOICE_DUE) {
                this.delayType = DELAY_TYPES.EXACT;
            } else if (!this.disableEventTimer && eventType !== EVENT_TYPES.DATE) {
                this.delayType = DELAY_TYPES.EVENT;
            } else {
                this.delayType = DELAY_TYPES.DELAY;
            }
        },

        setDefaultDelayType() {
            if (!this.disableDelayTimer) {
                this.delayType = DELAY_TYPES.DELAY;
            } else if (!this.disableExactTimer) {
                this.delayType = DELAY_TYPES.EXACT;
            } else if (!this.disableEventTimer) {
                this.delayType = DELAY_TYPES.EVENT;
            } else {
                this.delayType = DELAY_TYPES.DELAY;
            }
        },

        setDefaultCount() {
            this.count = +this.count;

            if (!this.count || this.count < DEFAULT_COUNT) {
                this.count = DEFAULT_COUNT;
                this.handleUpdate();
            }
        },

        setDefaultExactTimer() {
            if (!this.exactDate) {
                this.exactDate = moment().format(DATE_FORMAT);
                this.handleUpdate();
            }
        },

        handleUpdate() {
            this.$emit('updated', this.timerStep);
        },

        handleTimingUpdate(val) {
            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                'Event Source': 'Configure Timing Step',
                'Wait type': val,
            });

            this.handleUpdate();
        },

        handleUpdateDelayType(delayType) {
            this.offsetType = delayType === DELAY_TYPES.EVENT
                ? OFFSET_TYPES.BEFORE
                : OFFSET_TYPES.NONE;

            if (delayType === DELAY_TYPES.DELAY) {
                this.count = this.count || DEFAULT_COUNT;
                this.unit = this.unit || DEFAULT_UNIT;
            }

            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                'Event Source': 'Configure Timing Step',
                'Delay Type': delayType,
            });

            this.handleUpdate();
        },

        handleUpdateTime(checked) {
            if (checked && !this.startTime) {
                this.startTime = DEFAULT_START_TIME;
            }
            this.handleUpdate();
        },

        handleUpdateOffset(offset) {
            if (offset === OFFSET_TYPES.NONE) {
                this.count = 0;
                this.unit = '';
            } else {
                this.count = this.count === 0 ? DEFAULT_COUNT : this.count;
                this.unit = this.unit || DEFAULT_UNIT;
            }

            this.handleUpdate();
        },

        handleUpdateRange(selected) {
            if (selected === RANGE_OPTIONS.BETWEEN && !this.endTime) {
                this.endTime = DEFAULT_END_TIME;
            }
            this.handleUpdate();
        },

        toggleReorderMessage() {
            this.showReorderMessage = true;
        },

        dismissReorderMessage() {
            this.showReorderMessage = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .timing-step {
        max-width: px-to-rem(384px);
        margin: $gp * 1.5;
    }

    .timing-toggle {
        margin: $gp * 1.5 0;
        width: 100%;
    }

    .offset {
        display: flex;
        --input-margin-bottom: 0;

        &-select {
            flex: 2;
            @include margin-start($gp / 2);
        }

        &-count {
            flex: 1;
            @include margin-end($gp / 2)
        }
    }

    .offset-toggle {
        margin: $gp * 1.5 0;
    }

    .time-options-container {
        --checkbox-padding: #{0 $gp / 2};
        margin-top: $gp * 1.5;

        .checkbox-container {
            display: inline-flex;
        }
    }

    .range-toggle {
        margin: $gp * 1.5 0;
    }

    .range-options {
        --input-margin-bottom: 0;
        display: flex;
        align-items: center;
    }

    .range-and {
        margin: 0 $gp / 4;
    }

    .start-time,
    .end-time {
        flex: 1;
    }

    .reorder-message {
        padding: $gp;
        margin-top: $gp * 1.5;
        border-radius: $border-radius;
        border: 1px solid $color-gray-200;
        --icon-color: #{$color-orange-400};

        .details {
            display: flex;
            align-items: center;
        }

        .message {
            @include margin-start($gp);
            margin-bottom: 0;
        }

        .dismiss-button {
            display: flex;
            margin-top: $gp / 2;
            margin-left: auto;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "minutes": "minutes | minute",
        "hours": "hours | hour",
        "days": "days | day",
        "weeks": "weeks | week",
        "months": "months | month",
        "timing": {
            "immediately": "Immediately",
            "delayed": "Delayed"
        },
        "delayType": {
            "label": "Delay type",
            "delay": "Wait",
            "exact": "Wait until a specific date",
            "event": "Wait until an event"
        },
        "eventType": {
            "label": "Choose an event",
            "appointment": "Appointment",
            "invoiceDue": "Invoice due date"
        },
        "offsetType": {
            "before": "Before",
            "none": "Time of event",
            "noneDay": "Day of event",
            "after": "After"
        },
        "exactDate": "Choose a date",
        "range": {
            "label": "At a specific time of day",
            "at": "At",
            "between": "Between",
            "and": "and",
            "timeLabel": "Time"
        },
        "delayTypeMessage": "Only related timer types available in a single automation.",
        "reorder": {
            "message": "This automation's steps have been reordered to fit your delay settings."
        },
        "pastDateError": "Date cannot be in the past."
    }
}
</i18n>
