<template>
    <ds-multiselect
        ref="multiselect"
        v-model="localContact"
        data-qa="choose-contact"
        required
        searchable
        allow-add
        label-prop="fullNameText"
        value-prop="id"
        :options="options"
        :add-text="$t('addContact')"
        :placeholder="$t('contact')"
        :loading="searchContacts_loading"
        @add="showAddContact"
        @input="updateContact"
        @updateSearch="searchContacts_query"
    />
</template>

<script>
import AddContactModal from '@/contacts/components/add/AddContactModal';
import searchContacts from '@/shared/mixins/search-contacts.mixin';
import { displayFullName } from '@/contacts/contact-info-utils';

export default {
    mixins: [searchContacts],

    data() {
        return {
            localContact: {},
        };
    },

    created() {
        this.loadContactList();
    },

    mounted() {
        this.$bus.$on('CONTACT_ADDED', this.storeContact);
    },

    beforeDestroy() {
        this.$bus.$off('CONTACT_ADDED', this.storeContact);
    },

    computed: {
        options() {
            return this.searchContacts_results.map((contact) => {
                return {
                    ...contact,
                    fullNameText: this.fullNameText(contact),
                };
            });
        },
    },

    methods: {
        async loadContactList(activateDropdown = true) {
            this.searchContacts_loading = true;

            await this.$store.dispatch('contacts/LOAD_CONTACT_LIST', {
                limit: 100,
                sortBy: 'fullName',
                sortAscending: true,
            })
                .then(() => {
                    if (this.$refs.multiselect && activateDropdown) {
                        this.$refs.multiselect.activate();
                    }
                });
            this.searchContacts_loading = false;
        },

        updateContact(contact) {
            this.$emit('update-order-contact', contact?.id);
        },

        showAddContact() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddContactModal,
                data: { hideSuccessModal: true, hideAddAnother: true },
            });
        },

        async storeContact(contact) {
            await this.loadContactList(false);
            this.localContact = {
                ...contact,
                fullNameText: displayFullName(contact),
            };
            this.updateContact(this.localContact);
        },

        fullNameText(contact) {
            return contact?.fullName || contact?.email;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "contact": "Choose contact",
        "addContact": "Add a new contact"
    }
}
</i18n>
