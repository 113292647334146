import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import 'firebase/database';

import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';

export default {
    COMPLETE_ONBOARDING_TASK: (context, taskId) => {
        return completeOnboardingTask(context, { taskId });
    },

    DISMISS_ONBOARDING_TASK: (context, taskId) => {
        return completeOnboardingTask(context, {
            taskId,
            dismissed: true,
        });
    },

    SET_ACTIVE_LEAD_FORM_TASK: (context, taskId) => {
        return setActiveLeadFormTask(context, taskId);
    },

    SET_ACTIVE_APPOINTMENT_TYPE_TASK: (context, taskId) => {
        return setActiveAppointmentTypeTask(context, taskId);
    },
};

const completeOnboardingTask = async ({
    rootState, commit, dispatch, getters,
}, { taskId, dismissed }) => {
    if (window.sampleDataInProgress) return;

    if (rootState.tutorials.items[`onboarding|tasks|${taskId}`] === true) return;

    await dispatch(
        'tutorials/UPDATE_TUTORIAL_ITEM',
        {
            key: `onboarding|tasks|${taskId}`,
            value: true,
            forUser: true,
        },
        { root: true },
    );

    commit('SET_SHOW_BADGE', true);

    amplitude.v2.logEvent(
        dismissed
            ? amplitude.v2.events.GETTING_STARTED_TASK_DISMISSED
            : amplitude.v2.events.GETTING_STARTED_TASK_COMPLETED,
        { Task: taskId },
    );

    intercom.logEvent(
        dismissed
            ? intercom.events.GETTING_STARTED_TASK_DISMISSED
            : intercom.events.GETTING_STARTED_TASK_COMPLETED,
    );

    getters.jobIdsByTaskId(taskId).forEach((jobId) => {
        if (getters.allTasksCompletedForJob(jobId)) {
            amplitude.v2.logEvent(amplitude.v2.events.GETTING_STARTED_JOB_COMPLETED, {
                Job: jobId,
            });

            intercom.logEvent(intercom.events.GETTING_STARTED_JOB_COMPLETED);
        }
    });

    if (getters.allJobsCompleted) {
        await dispatch(
            'tutorials/UPDATE_TUTORIAL_ITEM',
            {
                key: TUTORIAL_TYPES.ONBOARDING.GETTING_STARTED_COMPLETED,
                value: true,
                forUser: true,
            },
            { root: true },
        );
    }
};

const setActiveLeadFormTask = ({ commit }, taskId) => {
    commit('SET_ACTIVE_LEAD_FORM_TASK', taskId);
};

const setActiveAppointmentTypeTask = ({ commit }, taskId) => {
    commit('SET_ACTIVE_APPOINTMENT_TYPE_TASK', taskId);
};
