const ReportsPage = () => import(/* webpackChunkName: "reports" */ '@/reports/pages/ReportsPage');
const AllSalesReportPage = () => import(/* webpackChunkName: "reports" */ '@/reports/pages/AllSalesReportPage');
const EmailEngagementReportPage = () => import(/* webpackChunkName: "reports" */ '@/reports/pages/EmailEngagementReportPage');
const AnalyticsReportPage = () => import(/* webpackChunkName: "reports" */ '@/reports/pages/AnalyticsReportPage');
const PipelineReportPage = () => import(/* webpackChunkName: "reports" */ '@/reports/pages/PipelineReportPage');

import { ROLE_NAVIGATION_CAN_ACCESS_REPORTING } from '@/settings/settings.constants';
import { REPORTS_FEATURE, ANALYTICS_FEATURE, PIPELINE_ANALYTICS_FEATURE } from '@/shared/constants/features.constants';
import { FF_KEAPPRO_EMAILENGAGEMENTREPORT_ENABLED, FF_PIPELINE_ANALYTICS } from '@/shared/constants/featureFlag.constants';

const routes = [
    {
        path: '/reports',
        name: 'reports',
        component: ReportsPage,
        meta: {
            title: 'nav.reports',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_REPORTING,
            feature: REPORTS_FEATURE,
            resetIntercom: true,
        },
    },
    {
        path: '/reports/all-sales',
        name: 'reports.all-sales',
        component: AllSalesReportPage,
        meta: {
            title: 'nav.reports',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_REPORTING,
            feature: REPORTS_FEATURE,
        },
    },
    {
        path: '/reports/email-engagement',
        name: 'reports.email-engagement',
        redirect: '/reports/email-engagement/emails',
    },
    {
        path: '/reports/email-engagement/:listType',
        name: 'reports.email-engagement.with-list',
        component: EmailEngagementReportPage,
        meta: {
            title: 'nav.reports',
            hideHeaderOnMobile: true,
            featureFlag: FF_KEAPPRO_EMAILENGAGEMENTREPORT_ENABLED,
            feature: REPORTS_FEATURE,
        },
    },
    {
        path: '/analytics/pipeline',
        name: 'analytics.pipeline',
        component: PipelineReportPage,
        meta: {
            title: 'nav.analytics',
            hideHeaderOnMobile: true,
            featureFlag: FF_PIPELINE_ANALYTICS,
            permission: ROLE_NAVIGATION_CAN_ACCESS_REPORTING,
            feature: PIPELINE_ANALYTICS_FEATURE,
        },
    },
    {
        path: '/analytics/:id',
        name: 'analytics',
        component: AnalyticsReportPage,
        meta: {
            title: 'nav.analytics',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_REPORTING,
            feature: ANALYTICS_FEATURE,
        },
    },
];

export default routes;
