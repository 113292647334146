import { render, staticRenderFns } from "./ConfigureTypeformTrigger.vue?vue&type=template&id=9ad8837e&scoped=true&"
import script from "./ConfigureTypeformTrigger.vue?vue&type=script&lang=js&"
export * from "./ConfigureTypeformTrigger.vue?vue&type=script&lang=js&"
import style0 from "./ConfigureTypeformTrigger.vue?vue&type=style&index=0&id=9ad8837e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9ad8837e",
  null
  
)

/* custom blocks */
import block0 from "./ConfigureTypeformTrigger.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports