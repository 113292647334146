/* istanbul ignore file */

export const FF_KEAPPRO_EMAILENGAGEMENTREPORT_ENABLED = 'keappro.emailengagementreport.enabled';
export const FF_FLAGSHIP_SPA_VIDEOCALLS_ENABLED = 'flagship.spa.videocalls.enabled';
export const FF_KEAP_MONEY_PAGES_TABLE_PAGINATION = 'keap.money.pages.table.pagination';
export const FF_FLAGSHIP_SPA_NOTIFICATIONPREFERENCES_ENABLED = 'flagship.spa.notificationPreferences.enabled';
export const FF_PLATFORM_KEAPWEB_NEWAPI_CONTACTS_LOADCONTACTDETAILS_ENABLED = 'platform.keapweb.newapi.contacts.loadcontactdetails.enabled';
export const FF_KEAP_APPTS_OUTLOOK_SYNC_ENABLED = 'keap.appointments.outlook.sync.enabled';
export const FF_FLAGSHIP_FS_24167_FREE_TOGGLE_INFUSIONSOFT_KEAP = 'flagship.fs.24167.free.toggle.infusionsoft.keap';
export const FF_KEAP_MERGE_SERVICE = 'keap.merge.service.mergefields';
export const FF_KEAP_SIMPLE_AUTOMATION_STEP_SEND_SMS = 'keap.simple.automation.step.sendSms';
export const FF_KEAP_SIMPLE_AUTOMATION_SHARING = 'keap.simple.automation.sharing';
export const FF_TASK_OUTCOMES_ENDPOINT_FROM_CORE_TO_KEAP = 'flagship.fs.4775.task.outcomes.endpoint.from.core.to.keap';
export const FF_KEAP_SIMPLE_AUTOMATION_FILTER = 'keap.simple.automation.filter';
export const FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ORDER_PAID = 'keap.simple.automation.trigger.shopify.order-paid';
export const FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_LANDING_PAGE_FORM_SUBMITTED = 'keap.simple.automation.trigger.landingPageFormSubmitted';
export const FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_SHOPIFY_ABANDONED_CART = 'keap.simple.automation.trigger.shopify.abandoned-cart';
export const FF_KEAP_SIMPLE_AUTOMATION_INSERT_STEP = 'keap.simple.automation.insert.step';
export const FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_FORMS = 'keap.simple.automation.contextual.forms';
export const FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_FORMS_PUBLIC_FLOW = 'keap.simple.automation.contextual.forms.public.flow';
export const FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS = 'keap.simple.automation.contextual.appointments';
export const FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS_FLOW = 'keap.simple.automation.contextual.appointments.flow';
export const FF_KEAP_SIMPLE_AUTOMATION_MERGE_SUGGESTIONS = 'keap.simple.automation.merge.suggestions';
export const FF_SIMPLE_AUTOMATION_TIMING_REFACTOR = 'keap.simple.automation.timer.add';
export const FF_FLAGSHIP_FS_27692_EMAIL_OPEN_STATUS = 'flagship.fs.27692.include.email.open.status';
export const FF_KEAP_SMS_AUTOMATION_PHONE_TYPES = 'keap.easy.automation.sms.step.phone.types';
export const FF_KEAP_SMS_BROADCAST = 'keap.sms.broadcast';
export const FF_KEAP_SMS_BROADCAST_PHONE_TYPES = 'keap.marketing.sms.phone.choice';
export const FF_KEAP_SMS_RICH_TEXT_BOX = 'keap.sms.rich.text.box';
export const FF_KEAP_SMS_BROADCAST_PRICING = 'keap.sms.broadcast.beta.pricing.statement';
export const FF_KEAP_SMS_ADVANCED_AUTOMATION = 'flagship.sms.automation';
export const FF_KEAP_SMS_NONCUSTOM_MERGEFIELDS = 'keap.sms.noncustom.mergefields';
export const FF_KEAP_SMS_MARKETING_TEMPLATES = 'keap.text.marketing.templates';
export const FF_KEAP_KBL_IN_CANADA = 'keap.kbl.in.canada';
export const FF_KEAP_MARKETING_TEXT_REPLY_NOTIFICATION = 'keap.marketing.text.reply.notification';
export const FF_KEAP_HERE_API_ENABLED = 'keap.here.api';
export const FF_KEAP_SAMPLE_DATA = 'keap.sample.data';
export const FF_KEAP_APPOINTMENTS_TIMEZONE = 'keap.appointments.timezone';
export const FF_KEAP_ZAPIER_INTEGRATIONS = 'keap.zapier.integrations';
export const FF_KEAP_INTEGRATION_TYPEFORM = 'keap.integrations.typeform';
export const FF_KEAP_SIMPLE_AUTOMATION_TRIGGER_TYPEFORM = 'keap.simple.automation.trigger.typeform';
export const FF_KEAP_INTEGRATION_SHOPIFY = 'keap.integrations.shopify';
export const FF_KEAP_INTEGRATION_COOKIE_CHECK = 'keap.integrations.cookiecheck';
export const FF_KEAP_INTEGRATION_SOCIAL_TEMPLATES = 'keap.social.integrations';
export const FF_KEAP_INTEGRATIONS_API_DELETE_DATASOURCE = 'keap.integrations.api.delete.datasource';
export const FF_KEAP_INTEGRATIONS_BFF_MIGRATION = 'keap.integrations.bff.migration';
export const FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS = 'keap.custom.field.settings';
export const FF_TAG_PERFORMANCE_DELETE = 'fs.37750.37952.tag.performance.delete';
export const FF_KEAP_TASK_OUTCOMES = 'fs.30687.task.outcomes.keap';
export const FF_PLATFORM_TENANT_ACCESS_TOKENS = 'platform.3273.tenant.access.tokens';
export const FF_KEAP_CONTACT_RECORD_FOURTH_PANEL = 'keap.contact.record.fourth.panel';
export const FF_KEAP_CUSTOM_FORMS_FIELD_SELECTOR = 'keap.customforms.fieldSelector';
export const FLAGSHIP_CUS_7863_FIX_TO_SAVE_WHOLE_NUMBER_CUSTOM_FIELD_AS_NULL = 'flagship.cus.7863.fix.to.save.whole.number.custom.field.as.null';
export const FF_KEAP_LANDING_PAGES = 'keap.landingpages';
export const FF_KEAP_SINGLE_PIPELINE = 'keap.single.pipeline';
export const FF_PIPELINE_ANALYTICS = 'keap.pipeline.reporting';
export const FF_KEAP_PIPELINE_DEAL_OUTCOME_LABELS = 'keap.pipeline.outcome.labels';
export const FF_KEAP_PIPELINE_MIGRATION = 'flagship.fs.33046.sales.pipeline.migration.and.end.states';
export const FF_KEAP_GMB = 'keap.gmb';
export const FF_KEAP_MAX_REBRAND = 'fs.35534.max.rebrand';
export const FF_KEAP_ANALYTICS_BLUE_GREEN_TOGGLE = 'keap.analytics.bluegreen.toggle';
export const FF_KEAP_DASHBOARD_NEW = 'keap.dashboard.newui';
export const FF_KEAP_CHECKOUT_FORMS_API = 'keap.checkout.forms.api';
export const FF_KEAP_CHECKOUT_FORMS_BILLING_AGREEMENTS = 'keap.checkout.forms.billing.agreements';
export const FF_KEAP_UNLAYER_EMAIL_PHASE1 = 'keap.unlayer.email.phase1.enabled';
export const FF_KEAP_UNLAYER_EMAIL_TEMPLATE_ADMIN = 'keap.unlayer.email.template.admin.enabled';
export const FF_KEAP_UNLAYER_EMAIL_PHASE2_HTML_EXPORT = 'keap.unlayer.email.phase2.html.export.enabled';
export const FF_KEAP_UNLAYER_EMAIL_PHASE2_CONTACT_RECORD_BROADCAST_BUTTON_ENABLED = 'keap.unlayer.email.phase2.contact.record.broadcast.button.enabled';
export const FF_KEAP_UNLAYER_EMAIL_PHASE2_CONTROL_PANEL_RIGHT_ALIGN = 'keap.unlayer.email.phase2.control.panel.right.align.enabled';
export const FF_KEAP_UNLAYER_EMAIL_PHASE2_CONTENT_RISK_MODAL_ENABLED = 'keap.unlayer.email.phase2.content.risk.modal.enabled';
export const FF_KEAP_CHARGEBEE = 'keap.chargebee';
export const FF_CHARGEBEE_PORTAL_BUTTONS = 'chargebee.portal.buttons';
export const FF_KEAP_DASHBOARD_CONTACT_METRICS = 'keap.dashboard.contact.metrics';
export const FF_KEAP_MERCHANT_EWAY = 'keap.merchant.eway';
export const FF_CONTACT_TYPE_DROPDOWN = 'keap.contact.list.contact.type.dropdown';
export const FF_36267_TAG_PERFORMANCE_ADD = 'fs.36267.tag.performance.add';
export const FLAGSHIP_FS_34481_CHANGE_CONTACT_IN_INVOICE = 'flagship.fs.34481.change.contact.in.invoice';
export const FF_KEAP_COMPANY_RECORD_ABOUT_FIELD = 'keap.company.record.about.field';
export const FF_COMMUNICATION_TEMPLATES_K8S_URL = 'keap.bff.communication.template.api.url';
export const FF_CUSTOM_FORM_GROUPED_FIELDS = 'keap.custom.forms.grouped.fields';
export const KEAP_PUBLIC_FORMS_DESCRIPTION_DISABLE = 'keap.public.forms.remove.description.disable';
export const FF_CHECKOUT_FORM_UPSELL = 'keap.fs.37173.upsell.checkout.form';
export const FF_CHECKOUT_FORM_RECURRING_UPSELLS = 'fs.40622.checkout.form.purchasing.an.upsell.fails.if.recurring';
export const FF_CHECKOUT_FORM_PROMOS = 'fs.37209.checkout.form.discounts';
export const FF_KEAP_ADD_SEARCH_TO_ADD_LINEITEM_INVOICE_QUOTE = 'flagship.fs.36765.add.search.to.adding.line.items.in.invoices.and.quotes';
export const KEAP_SMS_LINK_SHORTENING = 'keap.sms.link.shortening.tracking';
export const FF_KEAP_DASHBOARD_MOVE_WIDGETS = 'keap.dashboard.move.widgets';
export const FF_SMS_FORM_OPTIN = 'keap.sms.marketing.opt.in.public.forms';
export const KEAP_SHARE_DASHBOARD_WIDGETS = 'keap.shareable.widgets';
export const KEAP_TO_DO_DASHBOARD_WIDGET = 'keap.to.do.dashboard.widget';
export const KEAP_FREE_TRIAL_ONBOARDING = 'fs.39918.free.trial.onboarding';
export const KEAP_MOVE_EHAWK = 'keap.move.ehawk';
export const FS_40005_DECODE_COMPANY_NAME_IN_CONTACTS_AVATAR = 'fs.40005.decode.company.name.in.contacts.avatar';
export const FF_KEAP_NEW_MOBILE_ONLY_EDITION = 'keap.new.mobile.web.edition';
export const KEAP_CONTACT_TYPE_BULK_UPDATE = 'keap.contact.type.bulk.update';
export const KEAP_UPDATE_CONTACT_INFO_BUTTON = 'keap.update.contact.info.button';
export const FF_KEAP_NAV_IA_REDESIGN = 'keap.nav.ia.redesign';
export const CUS_8973_FIX_PATCH_CONTACT_BIRTHDAY_AND_ANNIVERSARY_STRIPPING = 'cus.8973.fix.patch.contact.birthday.and.anniversary.stripping';
export const KEAP_INVOICES_OR_QUOTES_SHOW_COMPANY_NAME_IN_CONTACT_COLUMN = 'fs.37435.invoices.or.quotes.show.company.name.in.contact.column';
export const KEAP_ADD_COMPANY_FORM_DUPLICATE_NOTIFICATION = 'keap.add.company.form.duplicate.notification';
export const TEXT_MARKETING_BILLABLE_AUTOMATION_MESSAGE = 'text.marketing.billable.automation.message';
export const EO_3088_APPLE_PRIVACY_ISSUES = 'eo.3088.apple.privacy.issues';
export const CUS_8954_SEND_BROADCAST_IN_UTC = 'cus.8954.send.broadcast.in.utc';
export const KEAP_CUSTOM_FIELDS_NAVIGATION_GUARD = 'keap.custom.fields.navigation.guard';
export const FS_40057_SORTING_AND_FILTERING_WITH_NAVIGATION = 'fs.40057.sorting.and.filtering.with.navigation';
export const FF_41175_KEAP_CONTACT_TYPE_REFRESH = 'keap.contact.type.refresh';
export const KEAP_AUTOMATIC_CONTACT_TYPE_UPDATES = 'keap.automatic.contact.type.updates';
export const FF_INVOICE_TAXES_LISTED_SEPARATELY = 'fs.39421.invoice.taxes.listed.separately';
export const KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT = 'keap.contact.custom.fields.tech.debt';
export const FF_KEAP_DASHBOARD_SALES_WIDGET = 'keap.dashboard.sales.widget';
export const KEAP_ADD_COMPANY_FORM_ABOUT_FIELD = 'keap.add.company.form.about.field';
export const CUS_9138_CONTACT_LISTS_NOT_DISPLAYING_CORRECT_RESULTS = 'cus.9138.contact.lists.not.displaying.correct.results';
export const FF_KEAP_LANDING_PAGES_MAX = 'keap.landingpages.max';
export const FF_KEAP_DASHBOARD_REPEAT_CLIENTS_WIDGET = 'keap.dashboard.repeat.clients.widget';
export const KEAP_UNLAYER_EMAIL_PHASE2_ENABLED = 'keap.unlayer.email.phase2.enabled';
export const FF_39078_TEXT_BILLING_DATES_UPDATE = 'keap.fs.39078.text.billing.dates.update';
export const KEAP_CONTACT_RECOMMENDED_ACTIONS = 'keap.contact.recommended.actions';

// KMOB
export const KBL_WEBCALLS_ENABLED = 'kbl.webcalls.enabled';
export const KBL_NUMBERPROVISIONING_ENABLED = 'kbl.numberprovisioning.enabled';
