<template lang="html">
    <div class="import-contacts-list">
        <div class="content">
            <section v-if="isSelectStep">
                <h2 v-if="!hideTitle" class="secondary-title">
                    {{ $t('title') }}
                </h2>

                <div class="search-container">
                    <ds-input-field
                        v-model="filteredContacts_search"
                        type="search"
                        name="search"
                        :label="$t('global.searchContacts')"
                    />
                </div>

                <div v-show="totalContacts" class="counts">
                    <i18n class="total" path="total">
                        <strong>{{ totalContacts }}</strong>
                    </i18n>

                    <i18n v-show="selectedContacts.length" class="selected" path="selected">
                        <strong>{{ selectedContacts.length }}</strong>
                    </i18n>
                </div>

                <div v-if="failed" class="error-text">
                    <span>{{ $t('failed') }}</span>
                </div>

                <section v-else-if="ready && totalContacts" class="list">
                    <div
                        :class="['contact', 'circle-checkbox-hover', { checked: selectAllChecked }]"
                        @click.stop="selectAll"
                    >
                        <div class="circle-checkbox-container">
                            <span class="circle-checkbox">
                                <ds-icon class="circle-check" name="check" />
                            </span>
                        </div>

                        <div class="details">
                            <h5>{{ $t('global.selectAll') }}</h5>
                        </div>
                    </div>

                    <div
                        v-for="{ givenName, familyName, id } in filteredContacts_list"
                        :key="id"
                        :class="['contact', 'circle-checkbox-hover', { checked: isChecked(id) }]"
                        @click.stop="select(id)"
                    >
                        <div class="circle-checkbox-container">
                            <span class="circle-checkbox">
                                <ds-icon class="circle-check" name="check" />
                            </span>

                            <div class="circle-checkbox-avatar">
                                <contact-avatar :name="givenName" />
                            </div>
                        </div>

                        <div class="details">
                            <h5>{{ givenName }} {{ familyName }}</h5>
                            <span>{{ id }}</span>
                        </div>
                    </div>
                </section>

                <p v-else-if="ready" class="subtle">
                    {{ $t('nocontacts') }}
                </p>

                <ds-placeholder v-else-if="importing" :rows="rows" />
            </section>

            <section v-if="isPermissionsStep" class="permission-step">
                <div v-if="completing" class="spinner-container">
                    <ds-spinner />
                </div>

                <import-contacts-permission v-model="permission" />
            </section>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import clone from 'lodash.clonedeep';

import amplitude from '@/analytics/amplitude';
import { EMAIL_STATUS } from '@/contacts/contacts.constants';
import ImportContactsPermission from '@/import/components/ImportContactsPermission';
import { IMPORT_TYPES, AMPLITUDE_IMPORT_EVENT_SOURCES } from '@/import/import.constants';
import ContactAvatar from '@/shared/components/ContactAvatar';
import filteredContactsMixin from '@/shared/mixins/filter-contacts.mixin';

export default {
    components: {
        ImportContactsPermission,
        ContactAvatar,
    },

    mixins: [filteredContactsMixin],

    props: {
        hideTitle: Boolean,
    },

    data() {
        return {
            title: this.$t('importContacts.title'),
            nestedModal_title: this.$t('importContacts.title'),
            nestedModal_actionMethod: 'next',
            nestedModal_stickyDesktopHeader: true,
            completing: false,
            selected: [],
            permission: null,
            importSteps: {
                STEP_SELECT: 'SELECT',
                STEP_PERMISSIONS: 'PERMISSIONS',
            },
            step: 'SELECT',
            rows: [{ height: '5rem', boxes: [1] }, { height: '5rem', boxes: [1] }, { height: '5rem', boxes: [1] }],
        };
    },

    watch: {
        nestedModal_actionDisabled(value) {
            this.$emit('disableChanged', value);
        },
    },

    computed: {
        ...mapState({
            contactImportList: ({ contactImport }) => contactImport.contactList,
            importing: ({ contactImport }) => contactImport.states.importing,
            ready: ({ contactImport }) => contactImport.states.ready,
            failed: ({ contactImport }) => contactImport.states.failed,
        }),

        contactList() {
            return this.contactImportList.map((contact) => {
                contact.id = contact.email || contact.phone.value;

                return contact;
            });
        },

        selectedContacts() {
            return this.selected.map((id) => {
                return this.contactList.find(({ id: contactId }) => {
                    return id === contactId;
                });
            });
        },

        totalContacts() {
            return this.contactList.length;
        },

        nestedModal_actionDisabled() {
            if (this.isPermissionsStep) {
                return this.permission === null || this.completing;
            }

            if (this.isSelectStep) {
                return this.selected.length === 0;
            }

            return false;
        },

        nestedModal_actionText() {
            return this.isPermissionsStep ? this.$t('importContacts.actions.finishImport') : this.$t('global.next');
        },

        isSelectStep() {
            return this.step === this.importSteps.STEP_SELECT;
        },

        isPermissionsStep() {
            return this.step === this.importSteps.STEP_PERMISSIONS;
        },

        selectAllChecked() {
            return this.selectedContacts.length === this.contactList.length;
        },
    },

    methods: {
        next() {
            if (document.querySelector('.modal-overlay.open')) {
                document.querySelector('.modal-overlay.open').scrollTop = 0;
            }

            if (this.isSelectStep) {
                this.$track('Contact import - selected contacts: Google upload', { count: this.selectedContacts });
                amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORT_CONTACTS_SELECTED, {
                    'Event Source': AMPLITUDE_IMPORT_EVENT_SOURCES[IMPORT_TYPES.GOOGLE],
                });
                this.step = this.importSteps.STEP_PERMISSIONS;
            } else if (this.isPermissionsStep) {
                this.$track('Contact import - finished import: Google upload');
                this.complete();
            }
        },

        isChecked(id) {
            return this.selected.includes(id);
        },

        complete() {
            this.completing = true;

            const contactsToSave = clone(this.selectedContacts).map((contact) => {
                this.permission ? contact.optInReason = EMAIL_STATUS.SINGLE_OPT_IN : contact.optInReason = EMAIL_STATUS.NON_MARKETABLE;

                delete contact.id;

                return contact;
            });

            this.$bus.$emit('IMPORT_STARTED');

            this.$store.dispatch('contactImport/BULK_ADD_CONTACTS', contactsToSave);
        },

        select(id) {
            const index = this.selected.indexOf(id);

            if (index > -1) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(id);
            }
        },

        selectAll() {
            if (this.selectAllChecked) {
                this.selected = [];
            } else {
                this.selected = [];

                this.contactList.forEach(({ id }) => {
                    this.select(id);
                });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/shared/styles/circle-checkbox";

    .content {
        display: flex;
        justify-content: center;

        section {
            width: 100%;
            color: $color-gray-800;
            max-width: px-to-rem(400px);

            h2 {
                margin-bottom: $gp;
                text-align: center;
            }
        }
    }

    .list {
        margin-top: $gp;
    }

    .search-container {
        position: sticky;
        z-index: 2;
        top: 0;
        background-color: $color-paper;
        padding: $gp / 2;

        @media($small) {
            top: #{3.5rem};
        }
    }

    .contact,
    .counts {
        display: flex;
        align-items: center;
        padding-bottom: $gp;
        cursor: pointer;
    }

    .details {
        @include margin-start($gp);
        @include text-align-start;

        span {
            color: $color-gray-600;
        }
    }

    .total {
        @include margin-end($gp / 2);
    }

    .counts {
        border-bottom: solid px-to-rem(1px) $color-gray-200;
        padding-bottom: $gp;
    }

    .permission-step {
        position: relative;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Who would you like to import as a contact?",
        "total": "{0} contacts",
        "selected": "({0} selected)",
        "almostTitle": "You're almost done",
        "almostDescription": "Before we import your contacts please answer the following question.",
        "failed": "There was a problem retrieving your contacts from Google",
        "nocontacts": "Looks like you don't have any contacts with that account"
    }
}
</i18n>
