<template lang="html">
    <div>
        <div class="search-container">
            <ds-input-field v-model="search" :placeholder="$t('search')" />
        </div>

        <div v-if="showEmptyResults" class="empty">
            {{ $t('empty') }}
        </div>

        <div v-else-if="loaded" class="articles-list">
            <a
                v-for="article in articles"
                :key="article.url_hash"
                class="article"
                target="_blank"
                rel="noopener noreferrer"
                :href="`${articleBaseUrl}/help/${article.url_hash}`"
            >
                {{ article.name }}
            </a>
        </div>

        <div v-else class="placeholder-container">
            <div v-for="n in 8" :key="n">
                <ds-placeholder class="placeholder-item" :rows="placeholder" />
            </div>
        </div>
    </div>
</template>

<script>
import debounce from 'lodash.debounce';

import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

export default {
    props: {
        delay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
        routePath: String,
    },

    data() {
        return {
            articleBaseUrl: process.env.VUE_APP_KNOWLEDGEOWL_URL,
            search: '',
            articles: [],
            loaded: false,
            placeholder: [{ height: '1.2rem', boxes: [1] }],
            cacheMap: {},
        };
    },

    created() {
        if (this.delay > 0) {
            this.getArticles = debounce(this.getArticles, this.delay);
        }
    },

    mounted() {
        this.getArticles();
    },

    watch: {
        search(value) {
            if (!value) {
                this.loaded = false;
                this.articles = [];
            }

            this.getArticles();
        },
    },

    computed: {
        showEmptyResults() {
            return this.articles.length === 0 && this.loaded;
        },
    },

    methods: {
        async getArticles() {
            if (!this.search && this.cacheMap[this.routePath]) {
                this.articles = this.cacheMap[this.routePath];
                this.loaded = true;

                return;
            }

            this.loaded = false;

            const { data } = await this.$store.dispatch('settings/GET_SUPPORT_ARTICLES', {
                page: this.routePath,
                search: this.search,
            });

            this.loaded = true;
            this.articles = data.data;

            if (!this.search) {
                this.cacheMap[this.routePath] = this.articles;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .search-container {
        --input-margin-bottom: 0;
        padding: 0 $gp $gp;
        display: flex;
        align-items: center;
    }

    .article {
        @include transition(background-color);

        display: block;
        padding: $gp;
        text-decoration: none;
        font-size: $font-size-med;
        color: $color-gray-900;

        &:hover {
            background-color: $color-gray-050;
        }
    }

    .placeholder-container,
    .empty {
        padding: 0 $gp;
    }

    .placeholder-item {
        margin-bottom: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "search": "Search help center",
        "empty": "There are no relevant articles for this page. Please use the search feature."
    }
}
</i18n>
