<template>
    <div class="custom-field-status">
        <p v-if="!chipOnly && statusType === $options.STATUS.ERROR" data-qa="error">
            {{ $t('additional.error', { objectName }) }}
            <a
                v-if="showMoreInfo"
                href="https://help.keap.com/help/company-records#custom-fields"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ $t('additional.moreInfo') }}
            </a>
        </p>

        <div class="tooltip">
            <ds-tooltip
                position="bottom"
                data-qa="status-tooltip"
                :disabled="!customFieldFlagEnabled"
                wrap
            >
                <template #reference>
                    <ds-status
                        :class="[statusType, { 'chip-only': chipOnly}]"
                        emphasis="high"
                        :type="statusType"
                        data-qa="chip"
                    >
                        {{ $t('status', { existing, max, objectName }) }}
                    </ds-status>
                </template>

                <template #default>
                    <span class="tooltip-info">
                        {{ $t('tooltipInfo', { count, recordType, tooltipInfoType }) }}
                    </span>
                </template>
            </ds-tooltip>
        </div>

        <p v-if="!chipOnly && statusType === $options.STATUS.WARNING" data-qa="warning">
            {{ $t('additional.warning', { objectName }) }}
            <a
                v-if="showMoreInfo"
                href="https://help.keap.com/help/company-records#custom-fields"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ $t('additional.moreInfo') }}
            </a>
        </p>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS } from '@/shared/constants/featureFlag.constants';
import { CUSTOM_FIELD_RECORD_TYPES, CUSTOM_FIELD_RECORD_TYPE_CONTACT, CUSTOM_FIELD_RECORD_TYPE_COMPANY } from '@/customFields/customFields.constants';

const STATUS = {
    WARNING: 'warning',
    ERROR: 'error',
    DEFAULT: 'default',
};

export default {
    STATUS,

    props: {
        existing: Number,
        max: Number,
        objectName: String,
        showMoreInfo: Boolean,
        chipOnly: Boolean,
        recordType: {
            type: String,
            validator: (value) => CUSTOM_FIELD_RECORD_TYPES.includes(value),
        },
    },

    computed: {
        ...mapState({
            customFieldFlagEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS],
        }),

        statusType() {
            if (this.existing === this.max) {
                return STATUS.ERROR;
            }

            if (this.existing >= this.max * 0.9) {
                return STATUS.WARNING;
            }

            return STATUS.DEFAULT;
        },

        count() {
            return this.max - this.existing;
        },

        tooltipInfoType() {
            if (this.recordType === CUSTOM_FIELD_RECORD_TYPE_CONTACT) {
                return this.$t('contact');
            }

            if (this.recordType === CUSTOM_FIELD_RECORD_TYPE_COMPANY) {
                return this.$t('company');
            }

            return '';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .custom-field-status {
        margin-bottom: $gp * 1.5;
    }

    .warning:not(.chip-only) {
        margin-bottom: $gp;
    }

    .tooltip {
        --tooltip-width: #{px-to-rem(320px)};
    }

</style>

<i18n>
{
    "en-us": {
        "status": "{existing}/{max} {objectName}s used",
        "additional": {
            "error": "If you delete {objectName}s, they’ll be removed from every location where they’re being used.",
            "warning": "Are there any {objectName}s you could delete? If you do, they’ll be removed from every location where they’re being used.",
            "moreInfo": "More info"
        },
        "tooltipInfo": "You can create up to {count} more {recordType} fields. This is a global action. Any fields you add here will be accessible from all of your {tooltipInfoType}.",
        "company": "companies",
        "contact": "contacts"
    }
}
</i18n>
