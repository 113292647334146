<template lang="html">
    <ds-file-upload-input
        id="attachment-upload-input"
        ref="uploader"
        v-model="uploader.file"
        data-qa="contact-email-file-uploader"
        :progress.sync="uploader.progress"
        :max-file-size-bytes="maxFileSize"
        @input="uploadFile($event)"
        @error="handleError"
    />
</template>

<script>
export default {
    props: {
        callback: Function,
    },

    data() {
        return {
            nestedModal_title: this.$t('attachFileModalContent.title'),
            maxFileSize: 10485760,

            uploader: {
                progress: 0,
                file: null,
                interval: 0,
            },
        };
    },

    methods: {
        handleError(errorType) {
            const error = errorType === 'fileSize'
                ? this.$t('attachFileModalContent.fileSizeError')
                : this.$t('attachFileModalContent.generalError');

            this.$refs.uploader.reset();
            this.$bus.$emit('UPLOAD_ERROR', { error, errorType });
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        async uploadFile(file) {
            if (file != null) {
                try {
                    if (typeof this.callback === 'function') {
                        this.callback(file);
                    } else {
                        const attachment = await this.$store.dispatch('contacts/ADD_CONTACT_FILE', { file });

                        this.$store.commit('email/ADD_ATTACHMENT', attachment);
                    }
                    this.$refs.uploader.reset();
                    this.$bus.$emit('POP_NESTED_MODAL');
                } catch {
                    this.handleError();
                }
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "attachFileModalContent": {
            "generalError": "Sorry, something went wrong. Please try again. Your file type may not be supported",
            "fileSizeError": "Your attachment is too large. It must be under 10MB.",
            "title": "Attach a file"
        }
    }
}
</i18n>
