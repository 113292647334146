import moment from 'moment';

export default {
    SET_EMAIL_TEMPLATES(state, emailTemplates) {
        state.emailTemplates = emailTemplates;
    },

    ADD_EMAIL_TEMPLATE(state, emailTemplate) {
        state.emailTemplates = [emailTemplate, ...state.emailTemplates];
    },

    UPDATE_EMAIL_TEMPLATE(state, emailTemplate) {
        const updatedTemplates = state.emailTemplates.map((t) => {
            if (t.id === emailTemplate.id) {
                emailTemplate.updatedOn = moment();

                return emailTemplate;
            }

            return t;
        });

        state.emailTemplates = updatedTemplates;
    },

    DELETE_EMAIL_TEMPLATE(state, templateId) {
        const remainingTemplates = state.emailTemplates.filter(({ id }) => id !== templateId);

        state.emailTemplates = remainingTemplates;
    },

    SET_SMS_TEMPLATES(state, smsTemplates) {
        state.smsTemplates = smsTemplates;
    },

    ADD_SMS_TEMPLATE(state, smsTemplate) {
        state.smsTemplates = [smsTemplate, ...state.smsTemplates];
    },

    UPDATE_SMS_TEMPLATE(state, smsTemplate) {
        const updatedTemplates = state.smsTemplates.map((t) => {
            if (t.id === smsTemplate.id) {
                smsTemplate.updatedOn = moment();

                return smsTemplate;
            }

            return t;
        });

        state.smsTemplates = updatedTemplates;
    },

    DELETE_SMS_TEMPLATE(state, templateId) {
        const remainingTemplates = state.smsTemplates.filter(({ id }) => id !== templateId);

        state.smsTemplates = remainingTemplates;
    },

    SET_CURRENT_EMAIL_TEMPLATE(state, template) {
        state.currentEmailTemplate = template;
    },

    SET_KEAP_MARKETING_NUMBER_TEMPLATES(state, keapMarketingNumberTemplates) {
        state.keapMarketingNumberTemplates = keapMarketingNumberTemplates;
    },

    ADD_KEAP_MARKETING_NUMBER_TEMPLATE(state, keapMarketingNumberTemplates) {
        state.keapMarketingNumberTemplates = [keapMarketingNumberTemplates, ...state.keapMarketingNumberTemplates];
    },

    UPDATE_KEAP_MARKETING_NUMBER_TEMPLATE(state, keapMarketingNumberTemplates) {
        const updatedTemplates = state.keapMarketingNumberTemplates.map((t) => {
            if (t.id === keapMarketingNumberTemplates.id) {
                keapMarketingNumberTemplates.updatedOn = moment();

                return keapMarketingNumberTemplates;
            }

            return t;
        });

        state.keapMarketingNumberTemplates = updatedTemplates;
    },

    DELETE_KEAP_MARKETING_NUMBER_TEMPLATE(state, templateId) {
        const remainingTemplates = state.keapMarketingNumberTemplates.filter(({ id }) => id !== templateId);

        state.keapMarketingNumberTemplates = remainingTemplates;
    },
};
