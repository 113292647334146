<template>
    <div class="empty-state">
        <ds-illustration
            v-if="image"
            class="image"
            data-qa="image"
            :name="image"
        />

        <slot name="title" />

        <h3 v-if="title && !$slots.title" data-qa="title">
            {{ title }}
        </h3>

        <p v-if="description" data-qa="description">
            {{ description }}
        </p>

        <div v-if="$slots.default" class="slot">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        description: String,
        image: String,
    },
};
</script>

<style lang="scss" scoped>
    .empty-state {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .image {
        margin-bottom: $gp * 1.5;
    }

    h3 {
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        max-width: $form-width;
    }

    p {
        color: $color-gray-800;
        margin: $gp 0 0;
        max-width: $form-width;
    }

    .slot {
        margin-top: $gp * 1.5;
    }
</style>
