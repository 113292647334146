import axios from 'axios';

export const fetchContactListTotalCount = async ({ id, filter } = {}) => {
    const countUrl = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/count`;

    if (id) {
        return axios.get(`${countUrl}?filterId=${id}`).then(({ data }) => data);
    }

    if (filter) {
        return axios.get(`${countUrl}?${encodeURIComponent(filter)}`).then(({ data }) => data);
    }

    return axios.get(countUrl).then(({ data }) => data);
};

export const fetchContactLists = () => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/filter`)
        .then(({ data: { contactFilters } }) => contactFilters);
};
