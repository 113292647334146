<template>
    <div class="deal-step configure-editor">
        <transition name="fade">
            <div v-if="showEditor" class="deal-form">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-header-text">{{ $t('editContent') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button :disabled="loading || showEmptyState" data-qa="deal-editor-next" @click="handleNext">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <ds-placeholder
                    v-if="loading"
                    data-qa="form-loading"
                    class="form"
                    :rows="[{ height: '2rem', boxes: [1] }]"
                />

                <div v-else class="form">
                    <automation-pipeline-empty-state v-if="showEmptyState" />

                    <template v-else>
                        <ds-inline-alert
                            v-if="showErrorBanner"
                            class="error-banner-container short-error-banner"
                            type="critical"
                            leading-icon
                        >
                            {{ $t('error.banner') }}
                        </ds-inline-alert>

                        <div class="create-title">
                            {{ $t('createTitle') }}
                        </div>

                        <ds-multiselect
                            v-model="stageSelection"
                            class="select-field"
                            data-qa="stage-select"
                            required
                            categorized
                            :placeholder="$t('stage')"
                            :options="categorizedStageOptions"
                            :submitted="submitted || showStageError"
                            @input="stageHandler"
                        />

                        <template v-if="deal.stageId">
                            <div class="details-title">
                                {{ $t('details') }}
                            </div>

                            <div class="input-row">
                                <mergeable-input
                                    v-model="deal.dealName"
                                    data-qa="deal-name"
                                    class="deal-name"
                                    type="text"
                                    name="name"
                                    use-merge-service
                                    :maxlength="MAXLENGTH.NAME"
                                    :label="$t('deal.name')"
                                    :additional-merge-fields="additionalMergeFields"
                                    @input="handleEdit"
                                />

                                <ds-input-field
                                    v-model="deal.amount"
                                    type="number"
                                    class="deal-amount"
                                    data-qa="deal-amount"
                                    :step=".01"
                                    :label="$t('deal.amount', { currency: appCurrencyCode })"
                                    :min="0"
                                    @input="handleEdit"
                                />
                            </div>

                            <ds-multiselect
                                v-model="assignToSelection"
                                bind-value-only
                                searchable
                                required
                                class="assign-to"
                                data-qa="assign-to-user"
                                :placeholder="$t('deal.assignTo')"
                                :options="assignToOptions"
                                @input="assignToHandler"
                            />
                        </template>
                    </template>
                </div>
            </div>

            <div v-if="showTimingStep" class="timing-editor">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editOptions') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="step-done" @click="handleDone(true)">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="timing-body">
                    <timing-step
                        v-if="timingRefactorEnabled"
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ stepName }}
                    </timing-step>

                    <configure-timing-step
                        v-else
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ stepName }}
                    </configure-timing-step>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ConfigureTimingStep from '@/automations/components/configure/ConfigureTimingStep';
import MergeableInput from '@/shared/components/MergeField/MergeableInput';
import TimingStep from '@/automations/components/steps/TimingStep';
import { FF_SIMPLE_AUTOMATION_TIMING_REFACTOR } from '@/shared/constants/featureFlag.constants';

import {
    CREATE_DEAL, EDITOR_STEP, TIMING_STEP, MAXLENGTH,
} from '@/automations/constants/automations.constants';
import SaveStatus from '@/automations/components/SaveStatus';
import AutomationPipelineEmptyState from '@/automations/components/AutomationPipelineEmptyState';
import { validate } from '@/automations/utils/automations.utils';

const ASSIGN_TO_CONTACT_OWNER = 'CONTACT_OWNER';

export default {
    components: {
        SaveStatus,
        ConfigureTimingStep,
        TimingStep,
        MergeableInput,
        AutomationPipelineEmptyState,
    },

    props: {
        saveStatus: String,
        showErrors: Boolean,

        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            currentStep: EDITOR_STEP,
            timingDelay: this.automationProperties.delayStep || null,
            deal: {
                dealName: '',
                pipelineId: '',
                stageId: '',
                assignToContactOwner: true,
                amount: 0,
            },
            submitted: false,
            loading: true,
            assignToSelection: ASSIGN_TO_CONTACT_OWNER,
            stageSelection: {},
            showEmptyState: false,
        };
    },

    created() {
        this.init();
    },

    watch: {
        'automationProperties.delayStep': {
            handler(value) {
                this.timingDelay = value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            users: ({ auth }) => auth.users,
            appCurrencyCode: ({ global }) => global.appCurrencyCode,
            pipelineList: ({ pipeline }) => pipeline.pipelineList,
            timingRefactorEnabled: ({ featureFlags }) => featureFlags[FF_SIMPLE_AUTOMATION_TIMING_REFACTOR],
        }),

        ...mapGetters({
            additionalMergeFields: 'automations/additionalMergeFields',
            getStageOptionsWithPipeline: 'pipeline/getStageOptionsWithPipeline',
            stageById: 'pipeline/stageById',
        }),

        showEditor() {
            return this.currentStep === EDITOR_STEP;
        },

        showTimingStep() {
            return this.currentStep === TIMING_STEP;
        },

        MAXLENGTH() {
            return MAXLENGTH;
        },

        showStageError() {
            return (this.submitted || this.showErrors) && (!validate[CREATE_DEAL].stageId(this.dealStep) || !this.stageSelection.value);
        },

        showErrorBanner() {
            return (this.submitted || this.showErrors) && !this.isDealValid;
        },

        isDealValid() {
            return validate[CREATE_DEAL].isValid(this.dealStep) && this.stageSelection.value;
        },

        stepName() {
            const title = this.stageName;

            return !title ? this.$t('stepName.default') : this.$t('stepName.hasTitle', { title });
        },

        stageName() {
            return this.stageById(this.deal.stageId)?.name;
        },

        categorizedStageOptions() {
            return this.getStageOptionsWithPipeline();
        },

        flattenedStageOptions() {
            return this.categorizedStageOptions.reduce((list, { options }) => {
                list.push(...options);

                return list;
            }, []);
        },

        assignToOptions() {
            const options = [{ value: ASSIGN_TO_CONTACT_OWNER, label: this.$t('contactOwner') }];

            const userOptions = this.users.map((user) => {
                return {
                    label: user.fullName,
                    value: +user.id,
                };
            });

            return [...options, ...userOptions];
        },

        dealStep() {
            const {
                dealName, pipelineId, stageId, amount, assignToContactOwner, assignTo,
            } = this.deal;

            return {
                type: CREATE_DEAL,
                name: this.stepName,
                configJson: {
                    assignToContactOwner,
                    assignTo: assignTo ? [assignTo] : [],
                    pipelineId,
                    stageId,
                    dealName,
                    value: {
                        amount: amount || 0,
                        currency: this.appCurrencyCode,
                    },
                },
            };
        },
    },

    methods: {
        async init() {
            const { initialStep, step, delayStep } = this.automationProperties;

            this.timingDelay = delayStep || null;
            this.currentStep = initialStep || EDITOR_STEP;

            if (step?.configJson) {
                const {
                    dealName, pipelineId, stageId, value, assignToContactOwner, assignTo,
                } = step.configJson;

                this.deal = {
                    dealName,
                    pipelineId,
                    stageId,
                    amount: value?.amount ?? 0,
                    assignToContactOwner,
                    assignTo: assignTo?.length ? assignTo[0] : 0,
                };
                this.setAssignOption();
                this.setStageOption();
            }

            if (!this.pipelineList.length) {
                await this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST');

                if (!this.pipelineList.length) {
                    this.showEmptyState = true;
                }
            }
            this.loading = false;
        },

        assignToHandler(selection) {
            this.$set(this.deal, 'assignToContactOwner', selection === ASSIGN_TO_CONTACT_OWNER);
            this.$set(this.deal, 'assignTo', selection === ASSIGN_TO_CONTACT_OWNER ? undefined : +selection);
            this.handleEdit();
        },

        stageHandler(selection) {
            this.$set(this.deal, 'stageId', selection.value);
            this.$set(this.deal, 'pipelineId', selection.pipelineId);
            this.handleEdit();
        },

        setAssignOption() {
            this.assignToSelection = this.deal.assignToContactOwner || !this.deal.assignTo
                ? ASSIGN_TO_CONTACT_OWNER
                : +this.deal.assignTo;
        },

        setStageOption() {
            if (this.deal.stageId) {
                this.stageSelection = this.flattenedStageOptions.find(({ value }) => value === this.deal.stageId) || {};
            }
        },

        handleBack() {
            this.currentStep = EDITOR_STEP;
        },

        handleEdit() {
            this.handleDone(false);
        },

        handleDone(close = false) {
            const steps = [this.dealStep];
            const hasDelay = this.timingDelay && Object.keys(this.timingDelay).length;

            if (hasDelay) {
                steps.unshift(this.timingDelay);
            }

            this.$emit('updated', steps);

            if (close) {
                this.$emit('close');
            }
        },

        handleNext() {
            if (!this.isDealValid) {
                this.submitted = true;
            } else {
                this.handleEdit();
                this.submitted = false;
                this.currentStep = TIMING_STEP;
            }
        },

        updateTiming(timingDelay) {
            this.timingDelay = timingDelay;
            this.handleEdit();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';

    .create-title,
    .details-title {
        padding-bottom: $gp;
        font-size: $font-size-med;
    }

    .error-banner-container,
    .select-field.multiselect,
    .deal-name.input-field,
    .assign-to.multiselect {
        width: px-to-rem(300);
    }

    .deal-amount.input-field {
        width: px-to-rem(175);
    }

    .input-row {
        width: px-to-rem(475) + $gp;
        grid-gap: $gp;
        display: grid;
        grid-template-columns: 2fr 1fr;
    }

    .error-banner-container {
        margin: 0 0 $gp 0;
    }

    @mixin small-deal-view() {
        .input-row {
            display: block;
            width: 100%;
        }

        .error-banner-container,
        .select-field.multiselect,
        .deal-name.input-field,
        .assign-to.multiselect,
        .deal-amount.input-field {
            width: 100%;
        }
    }

    .contextual-view {
        @include small-deal-view();
    }

    @media($extra-small) {
        @include small-deal-view();
    }
</style>

<i18n>
{
    "en-us": {
        "editContent": "Edit deal",
        "editOptions": "Edit options",
        "createTitle": "Create deal in:",
        "stage": "Choose stage",
        "details": "Deal details",
        "contactOwner": "Contact owner",
        "deal": {
            "name": "Deal name",
            "amount": "Deal value ({currency})",
            "assignTo": "Assigned to"
        },
        "error": {
            "banner": "Please fill out the required fields."
        },
        "stepName": {
            "hasTitle": "Create a deal in ({title})",
            "default": "Create deal"
        }
    }
}
</i18n>
