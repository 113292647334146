export const SCOPES = {
    CALENDAR: 'calendar',
    CONTACTS: 'contacts',
    EMAIL: 'email',
    ACCOUNTING: 'accounting',
    BUSINESS: 'plus.business.management',
    ZOOM: 'meeting',
};

export const PROVIDERS = {
    MICROSOFT: 'MICROSOFT',
    GOOGLE: 'GOOGLE',
    INTUIT: 'INTUIT',
    ZOOM: 'ZOOM',
};

export const BLENDR_TYPES = {
    HELLO_SIGN: 'HelloSign',
    MAILCHIMP_IMPORT: 'Mailchimp Import',
    CONSTANT_CONTACT_IMPORT: 'Constant Contact Import',
    SHOPIFY: 'Shopify',
    TYPEFORM: 'Typeform',
};

export const BLENDR_RESERVED_DATASOURCES = [
    'Keap SSO',
    'Data Store',
    'CDP',
];

export const BLENDR_SHOPIFY_SPECIFIC_PRODUCT_KEY = 'Specific Product Purchase';
export const BLENDR_MAILCHIMP_START_IMPORT_INSTANCE_NAME = 'Mailchimp Import - Configuration & Start Import';
export const BLENDR_CONSTANT_CONTACT_START_IMPORT_INSTANCE_NAME = 'Constant Contact Import - Configuration & Start Import';
export const BLENDR_HELLOSIGN_BLEND_DESCRIPTION_REGEX = /document_title:(?<documentTitle>.*)\ndocument_id:(?<documentId>.*)/;
export const BLEND_DESCRIPTION_REGEX = {
    TYPEFORM: /form_title:(?<formTitle>.*)\nform_id:(?<formId>.*)/,
    HELLOSIGN: /document_title:(?<documentTitle>.*)\ndocument_id:(?<documentId>.*)/,
};
