import getters from './getters';
import state from './state';
import mutations from './mutations';

export default {
    getters,
    state,
    mutations,
    namespaced: true,
};
