<template lang="html">
    <controlled-modal
        :is-open="isOpen"
        class="sms-settings-confirm"
        dialog
        size="xs"
        @close="close"
    >
        <span data-qa="settings-validation-title" class="title">{{ title }}</span>

        <span class="description">
            <span v-if="!optInIntro && !noReplyMessage" data-qa="intro-reply-description">
                {{ $t('introAndReplyDescription.1') }}
                <strong> {{ $t('introAndReplyDescription.2') }} </strong>
                {{ $t('introAndReplyDescription.3') }}
                <strong> {{ $t('introAndReplyDescription.4') }} </strong>
                {{ $t('introAndReplyDescription.5') }}
            </span>

            <span v-else-if="!optInIntro" data-qa="intro-description">
                {{ $t('introDescription.1') }}
                <strong> {{ $t('introDescription.2') }} </strong>
                {{ $t('introDescription.3') }}
                <strong> {{ $t('introDescription.4') }} </strong>
            </span>

            <span v-else data-qa="no-reply-description">
                {{ $t('noReplyDescription.1') }}
                <strong> {{ $t('noReplyDescription.2') }} </strong>
                {{ $t('noReplyDescription.3') }}
            </span>
        </span>

        <div class="actions">
            <ds-text-button @click="close">
                {{ $t('okGotIt') }}
            </ds-text-button>
        </div>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: {
        ControlledModal,
    },

    props: {
        isOpen: Boolean,
        optInIntro: String,
        noReplyMessage: String,
    },

    computed: {
        title() {
            if (!this.optInIntro && !this.noReplyMessage) {
                return this.$t('introAndReplyTitle');
            }

            if (!this.optInIntro) {
                return this.$t('introTitle');
            }

            return this.$t('noReplyTitle');
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },

    },
};
</script>

<style lang="scss" scoped>
.title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: $line-height-lg;
    padding-bottom: $gp;
}

.description {
    font-size: $font-size-med;
    color: $color-text-subtle;
    padding-bottom: $gp;
    line-height: $line-height-lg;
}

.actions {
    display: flex;
    justify-content: flex-end;
}

</style>

<i18n>
{
    "en-us": {
        "okGotIt": "Ok, got it",
        "introTitle": "You must include an opt-in message",
        "noReplyTitle": "You must include a no-reply message",
        "introAndReplyTitle": "You must include an opt-in and a no-reply message",
        "introDescription": {
            "1": "Your opt-in message can’t be blank. Please include an opt-in message, and be sure to",
            "2": "identify yourself or your company",
            "3": "in the text of the message",
            "4": "before saving."
            },
        "noReplyDescription": {
            "1": "Your no-reply message can’t be blank. This is the message that",
            "2": "will be automatically sent to recipients who try to respond",
            "3": "to your no-reply Keap Marketing Number."
            },
        "introAndReplyDescription": {
            "1": "Both your opt-in and no-reply messages are blank. Please",
            "2": "include an opt-in message",
            "3": "that identifies yourself or your company as well as a",
            "4": "no-reply message",
            "5": "before saving."
        }
    }
}
</i18n>
