const LearningAndSupportPage = () => import(/* webpackChunkName: "support" */ './pages/LearningAndSupportPage');

export default [
    {
        path: '/learning-and-support',
        name: 'learningAndSupport',
        component: LearningAndSupportPage,
        meta: {
            title: 'learningAndSupport.title',
        },
    },
];
