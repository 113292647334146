<template lang="html">
    <div class="text-download-link-form">
        <p
            v-if="showPrompt"
            class="setup-cta-prompt"
        >
            {{ ctaText }}
        </p>

        <form class="setup-input-form" @submit.prevent="handleSend">
            <ds-input-field
                ref="phoneNumber"
                v-model.trim="phone"
                class="setup-input-field"
                :label="$t('forms.phoneNumber')"
                :placeholder="$t('phonePlaceholder')"
            />

            <ds-filled-button
                :loading="loading"
                :disabled="disableSend"
                @click="handleSend"
            >
                {{ $t('global.send') }}
            </ds-filled-button>
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { SYSTEM_MESSAGE_KINDS } from '@/communication/communication.constants';

const ZERO_ATTEMPTS_REMAINING = 0;

export default {
    props: {
        description: String,
        showPrompt: Boolean,
        autoFocus: Boolean,
    },

    data() {
        return {
            phone: '',
            loading: false,
        };
    },

    mounted() {
        this.load();

        if (this.autoFocus) {
            this.focusPhoneNumber();
        }
    },

    computed: {
        ...mapState({
            userPhone: ({ auth }) => auth.user.phone,
        }),

        ctaText() {
            return this.description || this.$t('textLink');
        },

        disableSend() {
            return this.phone === '';
        },
    },

    methods: {
        load() {
            if (this.userPhone) {
                this.phone = this.userPhone;
            }
        },

        handleSend() {
            this.loading = true;

            const downloadAttemptsExhausted = this.$t('downloadAttemptsExhausted');
            const downloadLinkSent = this.$t('downloadLinkSent');
            const downloadLinkError = this.$t('downloadLinkError');

            return this.$store.dispatch('communication/SEND_SYSTEM_MESSAGE', {
                messageKind: SYSTEM_MESSAGE_KINDS.downloadKeap,
                phoneNumber: this.phone,
            })
                .then(async (response) => {
                    this.$track('Communication - SMS - Requested Mobile Download Link');

                    if (response === ZERO_ATTEMPTS_REMAINING) {
                        this.$toast({ message: downloadAttemptsExhausted });
                        this.$emit('limitReached');
                    } else {
                        this.$toast({ message: downloadLinkSent });
                        this.$emit('success');

                        await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'downloadMobileApp');
                        await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'scanBusinessCard');
                    }

                    this.loading = false;
                })
                .catch(() => {
                    this.$toast({ message: downloadLinkError });
                    this.$emit('error');
                    this.loading = false;
                });
        },

        async focusPhoneNumber() {
            await this.$nextTick();

            /* eslint-disable-next-line camelcase */
            this.$refs.phoneNumber?.input_focus?.();
        },
    },
};
</script>

<style lang="scss" scoped>
    .setup-cta-text {
        font-weight: $font-weight-semibold;
    }

    .setup-input-form {
        display: flex;
    }

    .setup-input-field {
        max-width: px-to-rem(375px);
        @include margin(0 $gp / 2 0 0);
    }
</style>

<i18n>
{
    "en-us": {
        "textLink": "Text me the link",
        "phonePlaceholder": "(XXX) XXX-XXXX",
        "downloadLinkSent": "Download link sent",
        "downloadAttemptsExhausted": "Message limit reached, please try again tomorrow",
        "downloadLinkError": "There was an error texting you the link, please try again later"
    }
}
</i18n>
