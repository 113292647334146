import Vue from 'vue';
import gql from 'graphql-tag';

export const smsBroadcastQuery = async ({ broadcastId }) => {
    const { data: { smsBroadcast: broadcast } } = await Vue.prototype.$graphql.query({
        query: gql`
            query smsBroadcast($broadcastId: ID!) {
                smsBroadcast(broadcastId: $broadcastId) {
                    id
                    created
                    messageContent
                    phoneTypes
                    recipientCriteria {
                        id
                        name
                        icon
                        helpText
                        type
                    }
                    scheduleDate
                    statistics {
                        failed
                        sent
                        stopped
                        total
                        pendingOptIn
                        unsubscribed
                    }
                    status
                    title
                    updated
                    createdBy
                    updatedBy
                    stoppedBy
                }
            }
        `,
        variables: {
            broadcastId,
        },
        fetchPolicy: 'no-cache',
    });

    return broadcast;
};

export const smsBroadcastListQuery = async ({
    filter,
    limit,
    offset,
    search,
}) => {
    const { data: { smsBroadcasts: broadcasts } } = await Vue.prototype.$graphql.query({
        query: gql`
            query smsBroadcasts($filter: String, $limit: Int, $offset: Int, $search: String) {
                smsBroadcasts(filter: $filter, includeStats: true, limit: $limit, offset: $offset, search: $search) {
                    id
                    created
                    messageContent
                    phoneTypes
                    recipientCriteria {
                        id
                        name
                        icon
                        helpText
                        type
                    }
                    scheduleDate
                    statistics {
                        failed
                        sent
                        stopped
                        total
                        pendingOptIn
                        unsubscribed
                    }
                    status
                    title
                    updated
                }
            }
        `,
        variables: {
            filter,
            limit,
            offset,
            search,
        },
        fetchPolicy: 'no-cache',
    });

    return broadcasts;
};

export const createSmsBroadcastMutation = async ({
    messageContent = '',
    recipientCriteria = [],
    phoneTypes = [],
    scheduleDate = '',
    title = '',
    createdBy,
} = {}) => {
    const { data: { createSmsBroadcast: createdBroadcast } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createSmsBroadcast($payload: SmsBroadcastInput!) {
                createSmsBroadcast(payload: $payload) {
                    id
                    created
                    messageContent
                    recipientCriteria {
                        id
                        name
                        icon
                        helpText
                        type
                    }
                    scheduleDate
                    status
                    title
                    updated
                    createdBy
                }
            }
        `,
        variables: {
            payload: {
                messageContent,
                recipientCriteria,
                phoneTypes,
                scheduleDate,
                title,
                createdBy,
            },
        },
        fetchPolicy: 'no-cache',
    });

    return createdBroadcast;
};

export const updateSmsBroadcastMutation = async ({
    id = 0,
    messageContent = '',
    phoneTypes = [],
    recipientCriteria = [],
    scheduleDate = '',
    title = '',
    updatedBy = '',
} = {}) => {
    const { data: { updateSmsBroadcast: updatedBroadcast } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateSmsBroadcast($broadcastId: ID!, $payload: SmsBroadcastInput!) {
                updateSmsBroadcast(broadcastId: $broadcastId, payload: $payload) {
                    id
                    created
                    messageContent
                    phoneTypes
                    recipientCriteria {
                        id
                        name
                        icon
                        helpText
                        type
                    }
                    scheduleDate
                    status
                    title
                    updated
                    updatedBy
                }
            }
        `,
        variables: {
            broadcastId: id,
            payload: {
                // Backend only allows messages up to 255 characters long
                messageContent: messageContent.slice(0, 255),
                phoneTypes,
                recipientCriteria,
                scheduleDate,
                title,
                updatedBy,
            },
        },
        fetchPolicy: 'no-cache',
    });

    return updatedBroadcast;
};

export const deleteSmsBroadcastMutation = async ({ id }) => {
    const { data: { deleteSmsBroadcast } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteSmsBroadcast($broadcastId: ID!) {
                deleteSmsBroadcast(broadcastId: $broadcastId)
            }
        `,
        variables: {
            broadcastId: id,
        },
        fetchPolicy: 'no-cache',
    });

    return deleteSmsBroadcast;
};

export const stopSmsBroadcastMutation = async ({ id, updatedBy }) => {
    const { data: { stopSmsBroadcast } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation stopSmsBroadcast($broadcastId: ID!, $payload: StopBroadcastInput) {
                stopSmsBroadcast(broadcastId: $broadcastId, payload: $payload){
                    id
                    created
                    messageContent
                    recipientCriteria {
                        id
                        name
                        icon
                        helpText
                        type
                    }
                    scheduleDate
                    status
                    title
                    updated
                }
            }
        `,
        variables: {
            broadcastId: id,
            payload: { updatedBy },
        },
        fetchPolicy: 'no-cache',
    });

    return stopSmsBroadcast;
};

export const resumeSmsBroadcastMutation = async ({ id, updatedBy }) => {
    const { data: { resumeSmsBroadcast } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation resumeSmsBroadcast($broadcastId: ID!, $payload: ResumeBroadcastInput!) {
                resumeSmsBroadcast(broadcastId: $broadcastId, payload: $payload) {
                    id
                    created
                    messageContent
                    recipientCriteria {
                        id
                        name
                        icon
                        helpText
                        type
                    }
                    scheduleDate
                    status
                    title
                    updated
                }
            }
        `,
        variables: {
            broadcastId: id,
            payload: { updatedBy },
        },
    });

    return resumeSmsBroadcast;
};

export const contactNumbersByRecipientCriteriaQuery = async ({ tag, savedSearch, singleContactTag }) => {
    const { data: { contactPhonesByRecipientCriteria } } = await Vue.prototype.$graphql.query({
        query: gql`
            query contactPhonesByRecipientCriteria($tag: String!, $savedSearch: String!, $singleContactTag: String!) {
                contactPhonesByRecipientCriteria(tag: $tag, savedSearch: $savedSearch, singleContactTag: $singleContactTag) {
                    contactId
                    firstName
                    lastName
                    phoneNumber
                    phoneNumbers {
                        number
                        type
                    }
                }
            }
        `,
        variables: {
            tag,
            savedSearch,
            singleContactTag,
        },
        fetchPolicy: 'no-cache',
    });

    return contactPhonesByRecipientCriteria;
};

export const smsOptInStatusesQuery = async ({ fromPhoneNumber, phoneNumbers }) => {
    const { data: { smsOptInStatuses } } = await Vue.prototype.$graphql.query({
        query: gql`
            query smsOptInStatuses($fromPhoneNumber: String!, $phoneNumbers: [String]!) {
                smsOptInStatuses(fromPhoneNumber: $fromPhoneNumber, phoneNumbers: $phoneNumbers) {
                    phoneNumber
                    statusKind
                }
            }
        `,
        variables: {
            fromPhoneNumber,
            phoneNumbers,
        },
        fetchPolicy: 'no-cache',
    });

    return smsOptInStatuses;
};

export const sendSmsBroadcastMutation = async ({
    id,
    recipients,
    scheduleDate,
    updatedBy,
}) => {
    const payload = {
        recipients,
        scheduleDate,
        updatedBy,
    };

    const { data: { scheduleSmsBroadcast: scheduledSmsBroadcast } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation scheduleSmsBroadcast($broadcastId: ID!, $payload: ScheduleSmsBroadcastInput!) {
                scheduleSmsBroadcast(broadcastId: $broadcastId, payload: $payload) {
                    id
                    created
                    messageContent
                    recipientCriteria {
                        id
                        name
                        icon
                        helpText
                        type
                    }
                    scheduleDate
                    status
                    title
                    updated
                    updatedBy
                }
            }
        `,
        variables: {
            broadcastId: id,
            payload,
        },
    });

    return scheduledSmsBroadcast;
};

export const searchSmsRecipientsQuery = async ({ query }) => {
    const { data: { smsBroadcastRecipientCriteria } } = await Vue.prototype.$graphql.query({
        query: gql`
            query smsBroadcastRecipientCriteria($query: String!) {
                smsBroadcastRecipientCriteria(query: $query) {
                    category
                    id
                    label
                    labelSubtext
                    includeCount
                    additionalInfo {
                        itemName
                        itemValue
                        itemSuffix
                        itemType
                    }
                }
            }
        `,
        variables: {
            query,
        },
        fetchPolicy: 'no-cache',
    });

    return smsBroadcastRecipientCriteria;
};

export const sendTestMessageMutation = async ({
    from,
    to,
    message,
}) => {
    const { data: { sendTestMessage } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation sendTestMessage($to: String!, $from: String!, $message: String!){
                sendTestMessage(from: $from, to: $to, message: $message) {
                    message
                    from
                    to
                    sid
                    status
                }
            }
        `,
        variables: {
            from,
            to,
            message,
        },
        fetchPolicy: 'no-cache',
    });

    return sendTestMessage;
};
