<template lang="html">
    <div class="pro-tip">
        <!-- eslint-disable vue/no-v-html -->
        <span v-if="title" class="title" v-html="title" />
        <p v-if="description" v-html="description" />
        <!-- eslint-enable vue/no-v-html -->
        <slot name="content" />
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        description: String,
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .pro-tip {
        color: $color-gray-800;
        box-shadow: $drop-shadow-z2;
        border-radius: $border-radius;
        width: px-to-rem(190px);
        padding: $gp;
        @include text-align-start;
        @include position(absolute $gp * 5 auto auto $gp * 1.5);

        @media($medium-down) {
            margin: $gp auto;
            position: static;
            width: px-to-rem(320px);
            max-width: 100%;
            display: none;
        }
    }

    p {
        margin: 0;
    }

    .title {
        font-size: $font-size-lg;
        margin-bottom: $gp / 2;
        display: flex;
    }
</style>
