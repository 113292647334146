<template>
    <controlled-modal
        :is-open="isOpen"
        dialog
        @close="$emit('close')"
    >
        <slot v-if="showTitle" name="title">
            <span class="confirm-title">{{ title }}</span>
        </slot>

        <slot v-if="showDescription" name="description">
            <span class="confirm-description">{{ description }}</span>
        </slot>

        <slot />

        <div class="confirm-buttons">
            <ds-text-button gray @click="$emit('close')">
                {{ cancelText || $t('global.cancel') }}
            </ds-text-button>

            <ds-text-button :destructive="destructive" @click="$emit('confirm')">
                {{ confirmText || $t('global.ok') }}
            </ds-text-button>
        </div>
    </controlled-modal>
</template>

<script>
import ControlledModal from './ControlledModal';

export default {
    components: { ControlledModal },

    props: {
        isOpen: Boolean,
        title: String,
        description: String,
        cancelText: String,
        confirmText: String,
        destructive: Boolean,
    },

    computed: {
        showDescription() {
            return Boolean(this.description || this.$slots.description);
        },

        showTitle() {
            return Boolean(this.title || this.$slots.title);
        },
    },
};
</script>

<style lang="scss" scoped>
.confirm-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-bold;
    line-height: $line-height-lg;
    padding-bottom: $gp;
}

.confirm-description {
    font-size: $font-size-med;
    color: $color-text-subtle;
    padding-bottom: $gp;
}

.confirm-buttons {
    display: flex;
}
</style>
