
const DashboardPage = () => import(/* webpackChunkName: "dashboard" */ '@/dashboard/pages/DashboardPage');

const PageNotFound = () => import(/* webpackChunkName: "settings" */ '@/dashboard/pages/PageNotFound');

const NimoCampaignsPage = () => import(/* webpackChunkName: "nimoCampaigns" */ '@/dashboard/pages/NimoCampaignsPage');

const routes = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardPage,
        meta: {
            title: 'nav.dashboard',
            resetIntercom: true,
        },
    },
    {
        path: '/page-not-found',
        name: 'page.not.found',
        component: PageNotFound,
        meta: {
            title: 'public.notFound',
            hideNav: true,
            isPublic: true,
        },
    },
    {
        path: '/nimo/contact/:contactId/campaigns',
        name: 'nimo.campaigns',
        component: NimoCampaignsPage,
        meta: {
            hideNav: true,
        },
    },
];

export default routes;
