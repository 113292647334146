export default {
    SET_PRODUCTS(state, products) {
        state.products = products;
    },

    SET_PAYMENT_PROCESSORS(state, processors) {
        state.paymentAccounts = processors;
    },

    SET_PAYMENT_REFERENCE(state, paymentReference) {
        state.paymentReference = paymentReference;
    },

    SET_SALES_TOTAL(state, salesTotal) {
        state.salesTotal = salesTotal;
    },

    SET_PAYPAL_SETTINGS(state, paypalSettings) {
        state.paypalSettings = paypalSettings;
    },

    SET_INFUSION_PAY_SETUP_INFO(state, payload) {
        setInfusionPaySetupInfo(state, payload);
    },

    SET_SALES_TAXES(state, taxes) {
        state.taxes = taxes;
    },

    SET_SALES_TAX(state, tax) {
        state.tax = tax;
    },

    DELETE_SALES_TAX_RATE(state, taxId) {
        state.taxes = state.taxes.filter(({ id }) => id !== taxId);
    },

    SET_PROMO_CODES(state, promoCodes) {
        state.promoCodes = promoCodes;
    },

    SET_PROMOCODE(state, promoCode) {
        state.promoCode = promoCode;
    },

    DELETE_PROMO_CODE(state, codeId) {
        state.promoCodes = state.promoCodes.filter(({ id }) => id !== codeId);
    },
};

const setInfusionPaySetupInfo = (state, payload) => {
    const { industryOptions, adminEmails } = payload;

    const options = Object.keys(industryOptions).map((industryId) => {
        return { value: Number(industryId), label: industryOptions[industryId] };
    });

    const emails = Object.keys(adminEmails).map((userId) => {
        return { value: Number(userId), label: adminEmails[userId] };
    });

    state.infusionPaySetupInfo = {
        ...payload,
        adminEmails: emails,
        industryOptions: options,
    };
};
