import actions from './actions';
import state from './state';
import mutations from './mutations';

export default {
    actions,
    mutations,
    state,
    namespaced: true,
};
