import moment from 'moment';

import { urlUtils } from '@infusionsoft/vue-utils';
import contactFieldTypes from './contactFieldTypes';
import dateMixin from '@/appointments/mixins/date.mixin';

export default {
    methods: {
        updateInput(ref, value) {
            if (this.$refs[ref]) {
                this.$refs[ref].input_updateInput(value);
                this.$refs[ref].input_updateValueExternal(value);
            }
        },

        interceptInput(field, ref) {
            if (field && field.value && field.fieldType === 'PhoneNumber') {
                const newValue = field.value.replace(/\D+\./g, '');

                this.updateInput(ref, newValue);
            }

            if (field && field.value && (field.fieldType === 'DecimalNumber' || field.fieldType === 'Currency' || field.fieldType === 'Percent')) {
                const inputArray = field.value.toString().replace(/[^\d.]/g, '').split('');

                if (inputArray.slice(inputArray.indexOf('.')).length > 3) {
                    this.updateInput(ref, Number(field.value).toFixed(2));
                }
            }
        },

        checkType(field, type) {
            return Boolean(field && field.fieldType === type);
        },

        formatWebsiteUrl(field, ref) {
            if (field && field.value && field.fieldType === 'Website') {
                const hasPrefix = field.value.includes('http') || field.value.includes('https');
                const hasWww = field.value.includes('www.');
                const isDomainOnly = field.value.includes('.') && !hasPrefix && !hasWww;

                if ((hasWww && !hasPrefix) || isDomainOnly) {
                    const link = `http://${field.value}`;

                    this.updateInput(ref, link);
                }
            }
        },

        formatCustomFieldDisplayValue(field, value) {
            if (field.fieldType === 'Date') {
                return dateMixin.dateFormatDate(value, 'LL');
            }

            if (field.fieldType === 'DecimalNumber') {
                return this.formatDecimal(value);
            }

            if (field.fieldType === 'Percent') {
                return this.formatPercent(value);
            }

            if (field.fieldType === 'Currency') {
                return this.formatCurrency(value);
            }

            if (field.fieldType === 'Website') {
                return this.formatUrl(value);
            }

            if (field.fieldType === 'PhoneNumber') {
                return this.formatPhone(value);
            }

            if (field.fieldType === 'DayOfWeek') {
                return moment.weekdays()[value - 1];
            }

            if (field.fieldType === 'Month') {
                return moment.months()[value - 1];
            }

            if (field.fieldType === 'State') {
                return field.options.find((option) => {
                    return option.value === value;
                }).label;
            }

            if (field.fieldType === 'YesNo') {
                return field.value
                    ? this.$t('global.yes')
                    : this.$t('global.no');
            }

            return value;
        },

        adjustDateUsingUtcOffset(date) {
            let adjustedDate = date;

            if (date) {
                const minutesPerHour = 60;

                const momentDate = moment(date).isValid()
                    ? moment(date)
                    : moment(new Date(date));

                adjustedDate = momentDate.hour(momentDate.utcOffset() / minutesPerHour);
            }

            return adjustedDate;
        },

        formatCurrency(value, decimalPlaces = 2) {
            if (value) {
                const temp = Number(value);
                const result = temp.toFixed(decimalPlaces);

                return this.$n(result, 'currency');
            }

            return value;
        },

        formatDecimal(value, decimalPlaces = 2) {
            if (value) {
                const temp = Number(value);

                return temp.toFixed(decimalPlaces);
            }

            return value;
        },

        formatPercent(value, decimalPlaces = 2) {
            if (value) {
                const temp = Number(value);
                const result = temp.toFixed(decimalPlaces);

                return `${result}%`;
            }

            return value;
        },

        formatUrl(url) {
            return urlUtils.formatUrl(url);
        },

        formatValue(name, type, value) {
            let formattedValue;
            const result = type || contactFieldTypes.STANDARD[name].type;

            const isTextField = result.toLowerCase() === 'text';
            const isDate = type === 'Date' || name === 'BIRTHDAY';
            const isDecimal = ['NumberDecimal', 'DecimalNumber', 'Percent', 'Currency'].includes(type);
            const isOwner = name === 'OWNER';

            if (isDate) {
                formattedValue = this.adjustDateUsingUtcOffset(value);
            } else if (isTextField) {
                formattedValue = !value ? '' : value;
            } else if (isDecimal) {
                formattedValue = this.formatDecimal(value);
            } else if (isOwner) {
                formattedValue = Number(value);
            } else {
                formattedValue = value;
            }

            return formattedValue;
        },
    },
};
