<template lang="html">
    <div class="mobile-only">
        <svg class="not-web-access">
            <use xlink:href="#no-web-access" />
        </svg>

        <div class="title">
            <h2>{{ $t('title.line1') }}</h2>
        </div>

        <div class="description">
            <h4>{{ $t('description.line1') }}</h4>

            <h4>{{ $t('description.line2') }}</h4>

            <h4>{{ $t('description.line3') }}</h4>
        </div>

        <ds-filled-button
            v-track="trackSeePlans"
            as="a"
            :href="appPricingUrl"
            class="large"
        >
            {{ $t('seePlans') }}
        </ds-filled-button>
    </div>
</template>

<script>
import '@/shared/images/no-web-access.svg';

export default {
    data() {
        return {
            appPricingUrl: 'https://keap.com/pricing',
        };
    },

    mounted() {
        this.$track('Mobile Only - viewed');
    },

    computed: {
        trackSeePlans() {
            return 'Mobile Only - See Plans - clicked';
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.mobile-only {
    padding: $gp * 2;
    text-align: center;
}
.title {
    margin: $gp * 1.5 0;
}
.description {
    margin-bottom: $gp * 2;
}
</style>

<i18n>
{
    "en-us": {
        "description": {
            "line1": "Your current plan only provides access to the",
            "line2": "mobile app. If you're interested in using even more",
            "line3": "features there's a plan for that, too."
        },

        "seePlans": "Explore all Keap plans",
        "title": {
            "line1": "You're trying to access a web plan"
        }
    }
}
</i18n>
