export const UPDATE_CONTACT_TYPE = (state, payload) => {
    const { contactType, id } = payload;

    // eslint-disable-next-line eqeqeq
    if (state.contact && state.contact.id == id) {
        state.contactDetails.contact.contactType = contactType;
    }

    // eslint-disable-next-line eqeqeq
    const listContact = state.contacts.find((c) => c.id == id);

    if (listContact) {
        listContact.contactType = contactType;
    }
};

export const BULK_UPDATE_CONTACT_TYPE = (state, payload) => {
    const { contactType, ids, checkAll } = payload;
    const { contacts } = state;

    contacts.forEach((contact) => {
        const contactSelected = ids.includes(contact.id);

        if (!checkAll && contactSelected) {
            contact.contactType = contactType;
        } else if (checkAll && !contactSelected) {
            contact.contactType = contactType;
        }
    });
};
