
const TasksPage = () => import(/* webpackChunkName: "tasks" */ '@/tasks/pages/TasksPage');

const routes = [
    {
        path: '/tasks',
        name: 'tasks',
        component: TasksPage,
        meta: {
            title: 'nav.tasks',
            hideHeaderOnMobile: true,
            resetIntercom: true,
        },
        children: [
            {
                path: ':id',
                name: 'task',
                component: TasksPage,
                meta: {
                    title: 'nav.tasks',
                    hideHeaderOnMobile: true,
                },
            },
        ],
    },
];

export default routes;
