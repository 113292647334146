<template lang="html">
    <div
        class="global-search"
        :class="{ open: mobileOpen, focused: inputFocused }"
        @mouseover="() => setMouseOver(true)"
        @mouseleave="() => setMouseOver(false)"
    >
        <div class="input-container">
            <ds-icon name="search" class="search-icon-desktop" />

            <ds-icon-button
                v-if="mobileOpen"
                name="arrow-left"
                class="close-icon"
                @click="close"
            />

            <ds-icon-button
                v-else
                name="search"
                class="search-icon-mobile"
                @click="open"
            />

            <ds-input-field
                ref="input"
                v-model="searchInput"
                class="search-input"
                :placeholder="$t('global.searchContacts')"
                @focus="onInputFocus"
                @blur="onInputBlur"
            />

            <ds-icon
                v-show="searchInput"
                name="x"
                class="clear-icon"
                @click.native="clear"
            />
        </div>

        <div v-show="inputFocused" class="results-container">
            <div v-show="showSearchResults" class="results">
                <ul class="results-list">
                    <li v-for="i in placeholderLoops" :key="i" class="result-item placeholder">
                        <ds-placeholder class="placeholder-avatar" :rows="placeholder.avatarRows" />

                        <div class="text">
                            <ds-placeholder class="placeholder-name" :rows="placeholder.nameRows" />
                            <ds-placeholder class="placeholder-email" :rows="placeholder.emailRows" />
                        </div>
                    </li>

                    <li
                        v-for="result in searchResults"
                        :key="result.id"
                        class="result-item"
                        :data-qa="result.fullName"
                        @click="viewContact(result.id)"
                    >
                        <contact-avatar
                            :size="32"
                            :email="result.email"
                            :name="result.fullName"
                        />

                        <div class="text">
                            <div v-if="result.fullName" class="name-field">
                                <ds-text-highlighter :content="result.fullName" :highlight="searchInput" />
                            </div>

                            <div v-if="result.email" :class="['email-field', { 'no-name': !result.fullName }]">
                                <ds-text-highlighter :content="result.email" :highlight="searchInput" />
                            </div>

                            <div v-if="result.phone1" :class="['phone-field', { 'no-name': !result.fullName && !result.email }]">
                                <ds-text-highlighter :content="result.phone1" :highlight="searchInput" />
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <section v-show="showEmptyState" class="results-empty">
                <p class="empty-results status">
                    {{ $t('contactList.empty.noResults', { query: searchInput }) }}
                </p>

                <ds-dropdown v-if="showSmartFormsButton" data-qa="add-contact">
                    <template #default>
                        <ds-filled-button
                            v-track="'Global Search - No Results - clicked : Add a contact dropdown'"
                            trailing-icon="chevron-down"
                        >
                            {{ $t('contactList.empty.addContactPrompt', { name: getAddContactPreviewTextSafeLength() }) }}
                        </ds-filled-button>
                    </template>

                    <template #menu>
                        <ul class="dropdown-menu-list">
                            <li class="dropdown-menu-item" @click="handleAddContactFromSearch">
                                {{ $t('addAContact') }}
                            </li>

                            <li
                                v-for="form in smartForms"
                                :key="form.id"
                                class="dropdown-menu-item"
                                @click="selectSmartForm(form.id)"
                            >
                                {{ form.title }}
                            </li>
                        </ul>
                    </template>
                </ds-dropdown>

                <ds-filled-button
                    v-else
                    v-track="'Global Search - No Results - clicked : Add a contact'"
                    @click="handleAddContactFromSearch"
                >
                    {{ $t('contactList.empty.addContactPrompt', { name: getAddContactPreviewTextSafeLength() }) }}
                </ds-filled-button>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash.debounce';

import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

import ContactAvatar from '@/shared/components/ContactAvatar';

export default {
    components: {
        ContactAvatar,
    },

    props: {
        delay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
    },

    data() {
        return {
            searchInput: '',
            searchResults: [],
            loaded: false,
            inputFocused: false,
            mobileOpen: false,
            mouseOver: false,
            placeholder: {
                avatarRows: [{ height: '4rem', boxes: [1] }],
                nameRows: [{ height: '1rem', boxes: [1] }],
                emailRows: [{ height: '.75rem', boxes: [1] }],
            },
        };
    },

    created() {
        if (this.delay > 0) {
            this.updateQuery = debounce(this.updateQuery, this.delay);
        }
    },

    watch: {
        searchInput(value) {
            this.handleSearchInputChange(value);
        },
    },

    computed: {
        ...mapGetters({
            smartForms: 'smartForms/sortedSmartFormsByTitle',
        }),

        showEmptyState() {
            return Boolean(this.loaded && this.searchInput && this.searchResults.length === 0);
        },

        placeholderLoops() {
            return !this.loaded && this.searchInput && this.searchResults.length === 0 ? 3 : 0;
        },

        showSearchResults() {
            return Boolean(this.searchResults.length > 0
                || (!this.loaded && this.searchInput));
        },

        showSmartFormsButton() {
            return Boolean(this.smartForms && this.smartForms.length);
        },
    },

    methods: {
        open() {
            this.mobileOpen = true;

            return this.$nextTick(() => {
                this.$refs.input.input_focus();
            });
        },

        close() {
            this.searchInput = '';
            this.mobileOpen = false;
            this.mouseOver = false;

            return this.$nextTick(() => {
                this.$refs.input.input_blur();
            });
        },

        clear() {
            this.searchInput = '';

            return this.$nextTick(() => {
                this.$refs.input.input_focus();
            });
        },

        updateQuery() {
            const sanitizedSearchInput = this.searchInput.trim();

            const searchError = this.$t('searchError');

            return this.$store.dispatch('GLOBAL_SEARCH', sanitizedSearchInput)
                .then((results) => {
                    this.loaded = true;
                    this.searchResults = results ? results.slice(0, 5) : [];

                    if (this.showEmptyState) {
                        this.loadSmartForms();
                    }
                })
                .catch(() => {
                    this.$log('Error loading global search from GlobalSearch component');
                    this.$error({ message: searchError });
                });
        },

        viewContact(id) {
            this.$bus.$emit('VIEW_CONTACT', { id, source: 'Global Search' });
            this.close();
        },

        async handleAddContactFromSearch() {
            const contact = this.inferAddContactInfoFromString(this.searchInput);

            await this.$nextTick();

            this.$bus.$emit('OPEN_ADD_CONTACT_MODAL', { contact });

            this.close();
        },

        inferAddContactInfoFromString(checkStr) {
            const contact = {
                givenName: '',
                familyName: '',
                email: '',
            };

            const searchTokens = checkStr.trim().split(' ');
            const emailTokenIndex = searchTokens.findIndex((checkEntry) => {
                return checkEntry.indexOf('@') > 0;
            });

            if (emailTokenIndex >= 0) {
                [contact.email] = searchTokens.splice(emailTokenIndex, 1);
            }

            [contact.givenName, ...contact.familyName] = searchTokens;
            contact.familyName = contact.familyName.join(' ');

            return contact;
        },

        getAddContactPreviewText() {
            const contact = this.inferAddContactInfoFromString(this.searchInput);

            if (!contact.givenName
                && contact.email) {
                return contact.email;
            }

            return contact.givenName;
        },

        getAddContactPreviewTextSafeLength() {
            const previewStr = this.getAddContactPreviewText();
            const lengthMax = 25;

            if (previewStr.length > lengthMax) {
                return `${previewStr.substr(0, lengthMax)}…`;
            }

            return previewStr;
        },

        openSmartFormFromSearch(id) {
            const contact = this.inferAddContactInfoFromString(this.searchInput);

            this.$bus.$emit('OPEN_ADD_CONTACT_MODAL', {
                id,
                contact,
            });

            this.close();
        },

        selectSmartForm(smartFormId) {
            return this.openSmartFormFromSearch(smartFormId);
        },

        loadSmartForms() {
            const errorMessage = this.$t('loadSmartForms.loadError');

            return this.$store.dispatch('smartForms/LOAD_SMART_FORMS')
                .catch(() => {
                    this.$error({ message: errorMessage });
                });
        },

        onInputFocus() {
            this.inputFocused = true;
        },

        onInputBlur() {
            if (this.mouseOver) {
                this.$refs.input.input_focus();
            } else {
                this.inputFocused = false;
            }
        },

        setMouseOver(value) {
            this.mouseOver = value;
        },

        handleSearchInputChange(value) {
            this.loaded = false;

            if (!value) {
                this.searchResults = [];
            } else {
                this.updateQuery();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    $input-height: px-to-rem(40px);

    .global-search {
        @include transition(box-shadow, background-color);

        border-radius: $gp / 2;

        @media($small) {
            &:not(.open) {
                .search-input {
                    display: none;
                }
            }

            &.open {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 800;
                background-color: $color-paper;
                padding: 0;

                .input-container {
                    background-color: $color-paper;
                    width: 100%;
                    border-bottom: 1px solid $color-gray-200;
                    height: $nav-height;
                }
            }
        }

        @media($medium) {
            background-color: $color-gray-100;
            border: solid 1px $color-gray-100;

            &:not(.focused) {
                height: $input-height;
            }

            &.focused {
                background-color: $color-paper;
                box-shadow: $elevation-z3;
            }

            &:hover:not(.focused) {
                background-color: $color-gray-200;
            }
        }
    }

    .input-container {
        @include transition(all);

        --input-border: 0;
        --input-background: transparent;

        display: flex;
        align-items: center;
        height: $input-height;
        flex: 1;

        @media ($medium) {
            @include padding-start($gp / 2);
            @include padding-end($gp / 2);
        }

        @media ($small) {
            .global-search.open & {
                @include padding-start($gp / 2);
                @include padding-end($gp);
            }
        }
    }

    .search-input {
        --input-field-margin-top: 0;
        --input-margin-bottom: 0;
        --input-focus-border: 0;
        --input-height: #{$input-height};
        --input-padding-left: #{$gp / 2};
        --input-padding-right: #{$gp / 2};
        --input-font-size: #{px-to-rem(14px)};

        height: $input-height;

        ::placeholder {
            color: rgba(0, 0, 0, 0.6);
            opacity: 1;
        }

        :-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.6);
        }

        ::-ms-input-placeholder {
            color: rgba(0, 0, 0, 0.6);
        }
    }

    .results-empty {
        padding: $gp;
    }

    .empty-results {
        word-wrap: break-word;
    }

    .results-list {
        display: flex;
        flex-direction: column;
        margin: $gp / 2 0;
    }

    .result-item {
        @include ellipsis;
        --icon-color: #{$color-gray-800};

        color: $color-gray-800;
        font-size: $font-size-med;
        padding: $gp * 2 $gp;
        cursor: pointer;
        height: px-to-rem(50px);
        display: flex;
        align-items: center;

        &:hover {
            background-color: $color-gray-050;
            text-decoration: none;
        }

        &:active {
            background-color: $color-gray-200;
        }

        .text {
            @include margin-start($gp);
        }
    }

    .phone-field,
    .email-field {
        font-size: $font-size-xsmall;

        &.no-name {
            font-size: $font-size-med;
        }
    }

    .placeholder {
        --placeholder-width: auto;
    }

    .placeholder-avatar {
        height: px-to-rem(32px);
        --placeholder-width: #{px-to-rem(32px)};
        --placeholder-border-radius: 50%;
    }

    .placeholder-name {
        --placeholder-width: #{$gp * 12};
    }

    .placeholder-email {
        --placeholder-width: #{$gp * 8};
        margin-top: $gp / 2;
    }

    .clear-icon {
        cursor: pointer;
    }

    .search-icon-desktop {
        color: rgba(0, 0, 0, 0.4);

        @media ($small) {
            display: none;
        }
    }

    .search-icon-mobile {
        @media ($medium) {
            display: none;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "addAContact": "Add a contact (default)",
        "searchError": "Something went wrong while searching, please try again."
    }
}
</i18n>
