<template>
    <div class="task-step configure-editor">
        <transition name="fade">
            <div v-if="showEditor" class="task-form">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-header-text">{{ $t('editContent') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="task-editor-next" @click="handleNext">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <automated-task
                    v-model="task"
                    use-merge-service
                    :show-title-error="showTitleError"
                    :show-error-banner="showErrorBanner"
                    :additional-merge-fields="additionalMergeFields"
                    class="form automated-task"
                    @input="handleEdit"
                />
            </div>

            <div v-if="showTimingStep" class="timing-editor">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editOptions') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="step-done" @click="handleDone(true)">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="timing-body">
                    <timing-step
                        v-if="timingRefactorEnabled"
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ stepName }}
                    </timing-step>

                    <configure-timing-step
                        v-else
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        {{ stepName }}
                    </configure-timing-step>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import ConfigureTimingStep from '@/automations/components/configure/ConfigureTimingStep';
import AutomatedTask from '@/shared/components/AutomatedTask';

import {
    CREATE_TASK, EDITOR_STEP, TIMING_STEP, MAXLENGTH, TIMING_UNIT,
} from '@/automations/constants/automations.constants';
import SaveStatus from '@/automations/components/SaveStatus';
import { validate } from '@/automations/utils/automations.utils';
import TimingStep from '@/automations/components/steps/TimingStep';
import { FF_SIMPLE_AUTOMATION_TIMING_REFACTOR } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        SaveStatus,
        ConfigureTimingStep,
        AutomatedTask,
        TimingStep,
    },

    props: {
        saveStatus: String,
        showErrors: Boolean,

        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            currentStep: EDITOR_STEP,
            timingDelay: this.automationProperties.delayStep || null,
            task: {},
            submitted: false,
        };
    },

    created() {
        this.init();
    },

    watch: {
        'automationProperties.delayStep': {
            handler(value) {
                this.timingDelay = value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            timingRefactorEnabled: ({ featureFlags }) => featureFlags[FF_SIMPLE_AUTOMATION_TIMING_REFACTOR],
            user: ({ auth }) => auth.user,
            users: ({ auth }) => auth.users,
        }),

        ...mapGetters({
            additionalMergeFields: 'automations/additionalMergeFields',
        }),

        steps() {
            return [
                EDITOR_STEP,
                TIMING_STEP,
            ];
        },

        showEditor() {
            return this.currentStep === EDITOR_STEP;
        },

        showTimingStep() {
            return this.currentStep === TIMING_STEP;
        },

        MAXLENGTH() {
            return MAXLENGTH;
        },

        showTitleError() {
            return (this.submitted || this.showErrors) && !validate[CREATE_TASK].title(this.taskStep);
        },

        showErrorBanner() {
            return this.showErrors && !this.isTaskValid;
        },

        isTaskValid() {
            return validate[CREATE_TASK].isValid(this.taskStep);
        },

        stepName() {
            const { title } = this.task;

            return !title ? this.$t('stepName.default') : this.$t('stepName.hasTitle', { title });
        },

        taskStep() {
            const {
                body, title, assignToContactOwner, assignTo, daysUntilDue,
            } = this.task;

            return {
                type: CREATE_TASK,
                name: this.stepName,
                configJson: {
                    title,
                    body,
                    assignToContactOwner,
                    assignTo,
                    untilDue: {
                        count: daysUntilDue || 0,
                        unit: TIMING_UNIT.DAYS,
                    },
                },
            };
        },
    },

    methods: {
        init() {
            const { initialStep, step, delayStep } = this.automationProperties;

            if (step?.configJson) {
                const {
                    title,
                    body,
                    assignToContactOwner,
                    assignTo,
                    untilDue,
                } = step.configJson;

                this.task = {
                    title,
                    body,
                    assignToContactOwner,
                    assignTo,
                    daysUntilDue: untilDue?.count || 0,
                };
                this.timingDelay = delayStep || null;
                this.currentStep = initialStep;
            }
        },

        handleBack() {
            this.currentStep = EDITOR_STEP;
        },

        handleEdit() {
            this.handleDone(false);
        },

        handleDone(close = false) {
            const steps = [this.taskStep];
            const hasDelay = this.timingDelay && Object.keys(this.timingDelay).length;

            if (hasDelay) {
                steps.unshift(this.timingDelay);
            }

            this.$emit('updated', steps);

            if (close) {
                this.$emit('close');
            }
        },

        handleNext() {
            if (!this.isTaskValid) {
                this.submitted = true;
            } else {
                this.submitted = false;
                this.currentStep = TIMING_STEP;
            }
        },

        updateTiming(timingDelay) {
            this.timingDelay = timingDelay;
            this.handleEdit();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "editContent": "Edit task",
        "editOptions": "Edit options",
        "stepName": {
            "hasTitle": "Create task ({title})",
            "default": "Create task"
        }
    }
}
</i18n>
