
export default {
    formById: ({ forms }) => (id) => {
        let matchingForm;

        Object.keys(forms).forEach((key) => {
            if (!matchingForm) {
                matchingForm = forms[key].find((form) => {
                    return `${form.id}` === `${id}`;
                });
            }
        });

        return matchingForm;
    },
};
