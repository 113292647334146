<template>
    <div class="custom-field" data-qa="custom-field">
        <label v-if="isLongLabel(customField.label)" class="long-label" :for="`custom-field-${customField.id}`">
            {{ customField.label }}
        </label>

        <div v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.CHECKBOXES)" class="checkbox-group-container">
            <label v-if="!isLongLabel(customField.label)" :for="`custom-field-${customField.id}`">
                {{ customField.label }}
            </label>

            <ds-checkbox-group
                v-model="customField[valueProp]"
                :boxes="formatOptions(customField)"
                :submitted="submitted"
                @input="onInput"
            />
        </div>

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.CURRENCY)"
            :ref="`currency${customField.id}`"
            v-model.trim="customField[valueProp]"
            type="number"
            autocomplete="no"
            :step="0.01"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            :intercept-on-update="interceptInput(customField, `currency${customField.id}`)"
            @input="onInput"
        />

        <ds-date-picker
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.DATE)"
            v-model.trim="customField[valueProp]"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-select-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.DAY_OF_WEEK)"
            v-model="customField[valueProp]"
            label-prop="label"
            value-prop="value"
            bind-value-only
            searchable
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :options="fieldOptions.weekDays"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.DECIMAL_NUMBER)"
            :ref="`decimalNumber${customField.id}`"
            v-model.trim="customField[valueProp]"
            type="number"
            step="any"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            :intercept-on-update="interceptInput(customField, `decimalNumber${customField.id}`)"
            @input="onInput"
        />

        <ds-select-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.DROPDOWN)"
            v-model="customField[valueProp]"
            label-prop="label"
            value-prop="value"
            bind-value-only
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :options="formatOptions(customField)"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.EMAIL)"
            v-model.trim="customField[valueProp]"
            type="email"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-select-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.MONTH)"
            v-model="customField[valueProp]"
            label-prop="label"
            value-prop="value"
            bind-value-only
            searchable
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :options="fieldOptions.months"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.PERCENT)"
            :ref="`percent${customField.id}`"
            v-model.trim="customField[valueProp]"
            type="number"
            step="any"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            :intercept-on-update="interceptInput(customField, `percent${customField.id}`)"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.PHONE_NUMBER)"
            :ref="`phone${customField.id}`"
            v-model.trim="customField[valueProp]"
            type="tel"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            :intercept-on-update="interceptInput(customField, `phone${customField.id}`)"
            @input="onInput"
        />

        <div v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.RADIO)" class="radio-group-container">
            <label v-if="!isLongLabel(customField.label)" :for="`custom-field-${customField.id}`">
                {{ customField.label }}
            </label>

            <ds-radio-group
                v-model="customField[valueProp]"
                :options="formatOptions(customField)"
                :submitted="submitted"
                @input="onInput"
            />
        </div>

        <ds-select-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.STATE)"
            v-model="customField[valueProp]"
            label-prop="label"
            value-prop="value"
            bind-value-only
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :options="formatOptions(customField)"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.TEXT)"
            v-model="customField[valueProp]"
            type="text"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-text-area-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.TEXT_AREA)"
            v-model.trim="customField[valueProp]"
            type="text"
            class="text-area-format"
            autocomplete="no"
            :maxlength="65000"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.WEBSITE)"
            :ref="`website${customField.id}`"
            v-model.trim="customField[valueProp]"
            type="url"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            @blur="formatWebsiteUrl(customField, `website${customField.id}`)"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.WHOLE_NUMBER)"
            v-model.trim="customField[valueProp]"
            type="number"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-input-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.YEAR)"
            v-model.trim="customField[valueProp]"
            type="number"
            autocomplete="no"
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-select-field
            v-if="checkType(customField, $options.CUSTOM_FIELD_NAMES.YES_NO)"
            v-model="customField[valueProp]"
            label-prop="label"
            value-prop="value"
            bind-value-only
            allow-null
            :label="getCustomFieldLabel(customField)"
            :name="`custom-field-${customField.id}`"
            :options="yesNoOptions(customField)"
            :submitted="submitted"
            @input="onInput"
        />

        <ds-dropdown>
            <ds-icon-button name="more-vertical" :data-qa="customField.label" />

            <template #menu>
                <div
                    data-qa="custom-field-edit"
                    class="more-menu-item"
                    @click="$emit('edit', customField)"
                >
                    <ds-icon name="edit" />
                    <span>{{ editFieldText }}</span>
                </div>

                <div
                    v-if="shouldShowReset"
                    class="more-menu-item"
                    data-qa="custom-field-clear"
                    @click="$emit('clear', customField, defaultValue)"
                >
                    <ds-icon name="rotate-ccw" />
                    <span>{{ $t('clearCustomField') }}</span>
                </div>

                <div
                    v-else-if="shouldShowRemove"
                    class="more-menu-item"
                    data-qa="custom-field-remove"
                    @click="$emit('remove', customField)"
                >
                    <ds-icon name="x-circle-fill" />
                    <span>{{ $t('removeCustomField', { parentName: parentName }) }}</span>
                </div>

                <router-link
                    v-if="isCustomFieldSettingsEnabled"
                    class="more-menu-item"
                    data-qa="custom-field-manage"
                    :to="{ name: `settings.custom-fields.${recordType}.details`, params: { id: customField.id } }"
                >
                    <ds-icon name="settings" />
                    <span>{{ $t('manageAll') }}</span>
                </router-link>
            </template>
        </ds-dropdown>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import dateMixin from '@/appointments/mixins/date.mixin';
import contactFieldTypes from '@/contacts/components/details/contactFieldTypes';
import contactsMixin from '@/contacts/mixins/contacts.mixin';
import customFieldUtils from '@/contacts/components/details/custom-field-utils';
import { CUSTOM_FIELD_RECORD_TYPES } from '@/customFields/customFields.constants';
import { FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS } from '@/shared/constants/featureFlag.constants';
import displayMixin from '@/shared/mixins/display.mixin';
import { urlUtils } from '@infusionsoft/vue-utils';

const { CUSTOM_FIELD_NAMES } = contactFieldTypes;
const {
    YES_NO,
    WHOLE_NUMBER,
    DECIMAL_NUMBER,
    CURRENCY,
    PERCENT,
    CHECKBOXES,
    DATE,
} = CUSTOM_FIELD_NAMES;
const FIELD_TYPES_WITH_NULL_DEFAULT = [
    YES_NO,
    WHOLE_NUMBER,
    DECIMAL_NUMBER,
    CURRENCY,
    PERCENT,
    CHECKBOXES,
    DATE,
];

export default {
    CUSTOM_FIELD_NAMES,

    mixins: [dateMixin, customFieldUtils, contactsMixin, displayMixin],

    props: {
        customField: Object,
        parentName: String,
        parentType: {
            type: String,
            default: 'contact',
        },
        submitted: Boolean,
        valueProp: {
            type: String,
            default: 'value',
        },
        optionsProp: {
            type: String,
            default: 'options',
        },
        fieldIdProp: {
            type: String,
            default: 'dataFormFieldId', // Can probably be removed with FF KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
        },
        editFieldText: String,
        recordType: {
            type: String,
            required: true,
            validator: (value) => CUSTOM_FIELD_RECORD_TYPES.includes(value),
        },
        customFields: {
            type: Array,
            required: true,
        },
    },

    mounted() {
        this.formatWebsiteUrl();
    },

    computed: {
        ...mapState({
            isCustomFieldSettingsEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS],
        }),

        ...mapGetters({
            fieldOptions: 'contacts/fieldOptions',
        }),

        customFieldSpec() {
            return this.customFields.find((customField) => Number(customField[this.fieldIdProp]) === Number(this.customField.id)) || {};
        },

        // This is messy can can be simplified again once we take care of FS-38818 since contacts is the difficult one
        defaultValue() {
            if (this.customFieldSpec?.defaultToFirstOption) {
                const firstOption = this.formatOptions(this.customField)[0];

                return firstOption.value || firstOption;
            }

            if (FIELD_TYPES_WITH_NULL_DEFAULT.includes(this.customField.fieldType)) {
                return null;
            }

            return '';
        },

        shouldShowReset() {
            return this.isCustomFieldSettingsEnabled
            && this.customFieldSpec.alwaysShown
            && Boolean(this.customField[this.valueProp])
            && this.customField[this.valueProp] !== this.defaultValue;
        },

        shouldShowRemove() {
            return !this.isCustomFieldSettingsEnabled || !this.customFieldSpec.alwaysShown;
        },
    },

    methods: {
        formatWebsiteUrl() {
            if (this.customField.fieldType === 'Website') {
                this.customField.value = urlUtils.formatUrl(this.customField.value);
            }
        },

        isLongLabel(label) {
            return Boolean(label?.length > 20);
        },

        getCustomFieldLabel({ label }) {
            return label && this.isLongLabel(label)
                ? null
                : label;
        },

        formatOptions(customField) {
            const allOptions = [...customField[this.optionsProp]];

            allOptions.forEach((option) => {
                if (option.options && !option.options.length) {
                    delete option.options;
                }
            });

            return allOptions;
        },

        yesNoOptions(customField) {
            return customField[this.valueProp] !== null
                ? this.fieldOptions.yesNoSelected
                : this.fieldOptions.yesNo;
        },

        onInput(input) {
            this.$emit('input', {
                field: this.customField,
                input,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .text-area-format {
        white-space: pre-line;
    }

    .more-menu-item {
        display: grid;
        grid-gap: $gp;
        align-items: center;
        grid-template-columns: #{$icon-size} auto;
        padding: $gp;
        cursor: pointer;
        --icon-size: #{$icon-size};
        text-decoration: none;
        color: $color-ink;

        &:hover {
            background-color: $color-gray-050;
            color: $color-ink;
        }
    }

    .custom-field {
        margin-top: $gp;
        display: grid;
        grid-template-columns: 1fr auto;
        grid-column-gap: $gp / 2;
    }

    .long-label {
        grid-column: 1 / span 2;
    }
</style>

<i18n>
{
    "en-us": {
        "removeCustomField": "Remove field from {parentName}",
        "clearCustomField": "Reset field to default state",
        "manageAll": "Manage all custom fields"
    }
}
</i18n>
