<template>
    <email-form
        single-recipient
        :action="send"
        :action-label="$t('global.send')"
        :attach-callback="uploadFile"
        :from-list="usersWithNames"
        :initial-body="initialBody"
        :initial-subject="initialSubject"
        :initial-template-kind="initialTemplate"
        :last-sent-email="lastSentEmail"
        :selected-from="selectedUser"
        :template-manager-options="templateManagerOptions"
        :source="source"
        :to-list="recipientList"
        @template-inserted="handleTemplateInserted"
    />
</template>

<script>
import { mapState } from 'vuex';
import { EMAILS_EMAIL_SENT_EVENT } from '@/shared/constants/events.constants';
import { trackEmailSent } from '@/contacts/analytics';
import intercom from '@/analytics/intercom';
import { APPOINTMENT } from '@/contacts/contactEmailTypes';

import EmailForm from '@/contacts/components/email/EmailForm';
import { getFromAddress, getUserFrom } from '@/contacts/utils/email-utils';
import errorMixin from '@/shared/mixins/error.mixin';

export default {
    components: {
        EmailForm,
    },

    mixins: [errorMixin],

    props: {
        initialBody: String,
        initialSubject: String,
        initialTemplate: String,
        lastSentEmail: Object,
        recipientList: {
            type: Array,
            default: () => [],
        },
        templateManagerOptions: {
            type: Object,
            default: () => ({
                shouldOpen: false,
                createTemplateOptions: {
                    title: '',
                    content: '',
                    subject: '',
                },
            }),
        },
        source: {
            required: true,
            type: String,
        },
        title: String,
    },

    data() {
        return {
            currentTemplateId: '',
            currentTemplateTitle: '',
        };
    },

    computed: {
        ...mapState({
            emailAttachments: ({ email }) => email.attachments,
            user: ({ auth }) => auth.user,
            users: ({ auth }) => auth.users,
        }),

        nestedModal_removeInnerPadding() {
            return true;
        },

        nestedModal_title() {
            return this.title || this.$t('header');
        },

        selectedUser() {
            return getUserFrom(this.user);
        },

        usersWithNames() {
            return this.users.map((user) => getUserFrom(user));
        },
    },

    methods: {
        nestedModal_reset() {
            this.$bus.$emit('ENABLE_EMAIL_LIST_SCROLLING');
        },

        async send({
            body,
            from,
            userId,
            recipients,
            signature,
            subject,
        }) {
            this.$track('Contacts - contact email - clicked : Send', { source: this.source });
            const { emailAttachments } = this;
            const [to] = recipients;
            const fromAddress = getFromAddress(from);
            const gdprErrorMessage = this.$t('sendError');

            try {
                const attachments = await Promise.all(emailAttachments.map(async (file) => {
                    if (!file.id) {
                        const attachment = await this.$store.dispatch('contacts/ADD_CONTACT_FILE', { contactId: to.id, file });

                        return attachment;
                    }

                    return file;
                }));

                this.$store.commit('email/SET_ATTACHMENTS', attachments);

                await this.$store.dispatch('contacts/SEND_EMAIL', {
                    body,
                    userId,
                    fromAddress,
                    recipient: to.id,
                    signature,
                    subject,
                    to: to.email,
                });

                this.$store.commit('email/SET_LAST_SENT_EMAIL', {
                    email: {
                        body,
                        subject,
                    },
                    attachments,
                });

                if (this.currentTemplateTitle === 'Request referral') {
                    await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'sendReferralEmail');
                }

                this.$store.commit('SET_NESTED_MODAL_DIRTY', false);

                this.$toast({ message: this.$t('successMessage') });

                this.$bus.$emit('EMAIL_SENT');

                this.$bus.$emit(EMAILS_EMAIL_SENT_EVENT);

                if (this.source === APPOINTMENT) {
                    intercom.logEvent(intercom.events.BOOKING_LINK_SENT);
                }

                trackEmailSent(this.source);
            } catch (error) {
                this.error_handleGdpr(error, gdprErrorMessage);
            }
        },

        handleTemplateInserted({ id, title }) {
            this.currentTemplateId = id;
            this.currentTemplateTitle = title;
        },

        async uploadFile(file) {
            this.$store.commit('email/ADD_ATTACHMENT', file);
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "header": "Send an email",
        "sendError": "Something went wrong and we couldn't send your email. Please try again.",
        "successMessage": "Email sent"
    }
}
</i18n>
