<template>
    <div class="add-payment-modal-content" data-qa="add-payment-modal">
        <successful-sales-modal
            id="successful-payment-modal"
            ref="successfulPaymentModal"
            :title="$t('successfulPayment.title')"
            :button-label="$t('successfulPayment.ok')"
            :subtitle="$t('successfulPayment.subTitle')"
            @close="handleSuccessModalClose"
        >
            <div v-if="allowAnotherPayment" class="successful-button-container">
                <ds-outline-button class="block" @click="addAnotherPayment">
                    {{ $t('successfulPayment.addAnother') }}
                </ds-outline-button>
            </div>
        </successful-sales-modal>

        <successful-sales-modal
            ref="successfulRecurringPaymentModal"
            :title="$t('successfulRecurringPayment.title')"
            :button-label="$t('successfulRecurringPayment.ok')"
            :subtitle="$t('successfulRecurringPayment.subTitle')"
            @close="handleSuccessModalClose"
        />

        <div v-if="showRecurringInfo" data-qa="recurring-info" class="recurring-info">
            <div class="description">
                {{ $t('recurringPaymentInfo.description') }}
            </div>

            <div class="button-container">
                <ds-text-button
                    class="info-button"
                    dense
                    :trailing-icon="chevronDirection"
                    data-qa="info-button"
                    @click="handleToggle"
                >
                    <span class="button-text">{{ $t('recurringPaymentInfo.list.title', { firstName: firstNameButton }) }}</span>
                </ds-text-button>
            </div>

            <ds-collapsible :opened="isExpanded" class="list-info">
                <div class="list-row">
                    <div class="number-column">
                        <number-icon :number="$t('recurringPaymentInfo.numbers.one')" />
                    </div>

                    <span class="list-text" data-qa="details-one">{{ $t('recurringPaymentInfo.list.detailsOne', { firstName }) }}</span>
                </div>

                <div class="list-row">
                    <div class="number-column">
                        <number-icon :number="$t('recurringPaymentInfo.numbers.two')" />
                    </div>

                    <span class="list-text" data-qa="details-two">{{ $t('recurringPaymentInfo.list.detailsTwo', { firstName }) }}</span>
                </div>
            </ds-collapsible>
        </div>

        <form v-show="!processing" @submit.prevent="processPayment">
            <order-choose-contact
                v-if="showChooseContact && !showRecurringInfo"
                @update-order-contact="setPaymentContact"
            />

            <ds-input-field
                v-if="!showChooseContact && !showRecurringInfo"
                v-model="paymentFrom"
                type="text"
                name="paymentFrom"
                :label="$t('contactRecord.sales.addPaymentModalContent.paymentFromLabel')"
                readonly
            />

            <ds-multiselect
                v-model="paymentReferenceSelected"
                class="product-options"
                categorized
                required
                searchable
                :placeholder="$t('contactRecord.sales.addPaymentModalContent.paymentReferenceLabel')"
                :readonly="lockPaymentReference"
                :options="paymentReferences"
            />

            <div v-if="!showRecurringInfo">
                {{ $t('contactRecord.sales.addPaymentModalContent.paymentAmount') }}
            </div>

            <div v-if="!showRecurringInfo" class="product-price-holder">
                <div :class="['light product-price', { 'primary-text': showPrimary }]">
                    <ds-editable-text
                        v-model="paymentAmountDisplay"
                        class="editable-price"
                        hide-icon
                        :disabled="!hasSelectedPaymentReference"
                    >
                        <span>{{ paymentAmountDisplay }}</span>
                    </ds-editable-text>
                </div>
                <div class="currency-type">
                    {{ appCurrencyCode }}
                </div>
            </div>

            <ds-multiselect
                v-if="showPaymentTypes"
                v-model="paymentType"
                data-qa="payment-type-dropdown"
                required
                :placeholder="$t('contactRecord.sales.addPaymentModalContent.paymentType')"
                :options="paymentTypes"
                @input="handlePaymentTypeChanged"
            />

            <div v-if="showCreditCardFields">
                <credit-card-fields
                    ref="creditCardFields"
                    :class="{ 'show-recurring': showRecurringBilling }"
                    :show-charge-statement="!isRecurring"
                    :company-profile="companyProfile"
                    :rate="paymentAmount"
                    :payment-from="paymentFrom"
                    :has-required-fields.sync="hasCreditCardFields"
                    :show-recurring-info="showRecurringInfo"
                />

                <recurring-payment-fields
                    v-show="showRecurringBilling"
                    ref="recurringBillingFields"
                    :display-amount="paymentAmountDisplay"
                    :force-is-recurring="forceIsRecurring"
                    :has-required-fields.sync="hasRecurringPaymentFields"
                    @updateRecurringBilling="updateRecurringBilling"
                />
            </div>

            <ds-checkbox
                v-if="canShowSendReceipt"
                v-model="sendReceipt"
                :label="$t('invoice.isReceiptSending', { name: displayFullName })"
                class="receipt-checkbox"
            />

            <div class="button-holder">
                <ds-filled-button
                    type="submit"
                    class="add-button"
                    :disabled="!hasRequiredFields"
                >
                    {{ buttonLabel }}
                </ds-filled-button>

                <ds-text-button @click.prevent="closeByCancel">
                    {{ $t('global.cancel') }}
                </ds-text-button>
            </div>
        </form>
        <ds-placeholder v-if="processing" :rows="rows" />
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import amplitude from '@/analytics/amplitude';
import { patterns } from '@infusionsoft/vue-utils';

import NumberIcon from '@/shared/components/NumberIcon';
import { trackInvoicePaid } from '@/contacts/analytics';
import ProductModalContent from '@/money/components/billing/ProductModalContent';
import CreditCardFields from '@/money/components/CreditCardFields';
import SuccessfulSalesModal from '@/money/components/SuccessfulSalesModal';
import OrderChooseContact from '@/money/components/order/OrderChooseContact';
import RecurringPaymentFields from '@/money/components/recurring-payment/RecurringPaymentFields';
import paymentFailureUtils from '@/money/mixins/payment-failure-utils';
import contactModalSubtitle from '@/shared/mixins/contact-modal-subtitle.mixin';
import productOptions from '@/money/mixins/product-options';
import { PAYMENT_REFERENCE_TYPES, PAYMENT_METHOD_TYPES } from '@/money/money.constants';
import { MONEY_PAYMENT_CREATED_EVENT } from '@/shared/constants/events.constants';
import { KEAP_AUTOMATIC_CONTACT_TYPE_UPDATES } from '@/shared/constants/featureFlag.constants';

const NEW_PRODUCT = 'newProduct';

export default {
    components: {
        CreditCardFields,
        SuccessfulSalesModal,
        RecurringPaymentFields,
        OrderChooseContact,
        NumberIcon,
    },

    mixins: [contactModalSubtitle, productOptions, paymentFailureUtils],

    props: {
        eventSource: {
            type: String,
            default: 'Keap App',
        },
        showRecurringInfo: Boolean,
    },

    data() {
        return {
            showChooseContact: false,
            contactId: 0,
            paymentType: {},
            paymentReceivedDate: new Date(),
            selectedProduct: {},
            paymentModalOpened: false,
            showCreditCardFields: false,
            showPaymentTypes: false,
            forceIsRecurring: false,
            isRecurring: false,
            hasCreditCardFields: false,
            hasRecurringPaymentFields: false,
            lockPaymentReference: false,
            resetting: false,
            paymentFrom: '',
            patterns,
            rows: [
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
                { height: '2rem', boxes: [1] },
            ],
            processing: false,
            paymentAmount: 0,
            sendReceipt: true,
            isExpanded: false,
        };
    },

    created() {
        this.productOptions_loadProducts();
        this.paymentFrom = this.displayFullName;
        this.setSendReceipt();
    },

    mounted() {
        this.$bus.$on('PRODUCT_CREATION_SUCCESSFUL', this.productCreated);
        this.$bus.$on('PRODUCT_CREATION_CANCELLED', this.resetSelections);
    },

    beforeDestroy() {
        this.$bus.$off('PRODUCT_CREATION_SUCCESSFUL');
        this.$bus.$off('PRODUCT_CREATION_CANCELLED');
    },

    watch: {
        displayFullName(val) {
            this.paymentFrom = val;
        },
    },

    computed: {
        ...mapState({
            companyProfile: ({ auth }) => auth.account.companyProfile,
            contactEmail: ({ contacts }) => contacts.contact.email,
            invoices: ({ sales }) => sales.invoices,
            paymentReference: ({ billing }) => billing.paymentReference,
            appCurrencyCode: ({ global }) => global.appCurrencyCode,
            contactTypeUpdatesEnabled: ({ featureFlags }) => featureFlags[KEAP_AUTOMATIC_CONTACT_TYPE_UPDATES],
        }),

        ...mapGetters({
            displayFullName: 'contacts/displayFullName',
            displayName: 'contacts/displayName',
            fieldOptions: 'contacts/fieldOptions',
            hasRecurringPaymentsFeature: 'auth/hasRecurringPaymentsFeature',
        }),

        nestedModal_title() {
            if (this.isRecurring && !this.showRecurringInfo) {
                return this.$t('recurringTitle');
            }

            if (this.showRecurringInfo) {
                return this.$t('recurringPaymentInfo.header');
            }

            return this.$t('contactRecord.sales.addPaymentModalContent.title');
        },

        paymentTypes() {
            const defaultPaymentTypes = [
                { label: this.$t('contactRecord.sales.addPaymentModalContent.creditCard'), value: PAYMENT_METHOD_TYPES.creditCard },
                { label: this.$t('contactRecord.sales.addPaymentModalContent.cash'), value: PAYMENT_METHOD_TYPES.cash },
                { label: this.$t('contactRecord.sales.addPaymentModalContent.check'), value: PAYMENT_METHOD_TYPES.check },
            ];

            if (!this.forceIsRecurring) {
                return defaultPaymentTypes;
            }

            return [defaultPaymentTypes[0]];
        },

        chevronDirection() {
            return this.isExpanded ? 'chevron-up' : 'chevron-down';
        },

        showRecurringBilling() {
            return this.hasRecurringPaymentsFeature && this.paymentReference.type !== PAYMENT_REFERENCE_TYPES.invoice;
        },

        buttonLabel() {
            return this.showRecurringInfo ? this.$t('recurringPaymentInfo.button') : this.$t('global.add');
        },

        canShowSendReceipt() {
            return Boolean(this.contactEmail);
        },

        hasRequiredFields() {
            if (this.isRecurring && !this.showRecurringInfo) {
                return this.hasCreditCardFields && this.hasRecurringPaymentFields;
            }

            if (this.paymentType && this.paymentType.value === PAYMENT_METHOD_TYPES.creditCard || this.showRecurringInfo) {
                return this.hasCreditCardFields;
            }

            return Boolean(this.hasSelectedPaymentReference && this.paymentType && this.paymentType.value);
        },

        showPrimary() {
            if (this.paymentAmount > 0) {
                return true;
            }

            return false;
        },

        hasSelectedPaymentReference() {
            return Boolean(this.paymentReferenceSelected.value);
        },

        paymentReferenceSelected: {
            get() {
                return this.paymentReference;
            },

            set(value) {
                this.handlePaymentReferenceChanged(value);
                this.$store.commit('billing/SET_PAYMENT_REFERENCE', value);
            },
        },

        paymentAmountDisplay: {
            get() {
                return this.$n(this.paymentAmount, 'currency');
            },

            set(value) {
                const amount = value.replace(/[^0-9\.-]+/g, ''); // eslint-disable-line

                if (Number.isNaN(amount)) {
                    return;
                }

                this.paymentAmount = Number(amount);
            },
        },

        paymentReferences() {
            const productReferences = [
                {
                    type: '',
                    value: NEW_PRODUCT,
                    label: this.$t('product.add'),
                    rate: 0,
                },
                ...this.productOptions_productOptions,
            ];

            const allReferences = [
                {
                    category: { value: 'selectProduct', label: this.$t('product.select') },
                    groups: [
                        {
                            label: this.$t('product.label'),
                            options: productReferences,
                        },
                    ],
                },
            ];

            if (this.invoices && this.invoices.length && !this.isRecurring || this.showRecurringInfo) {
                const invoiceReferences = this.invoices.reduce((res, invoice) => {
                    const amountDue = invoice.total - invoice.totalPaid;

                    if (Number(this.contactId) === Number(invoice.contactId) && amountDue > 0) {
                        res.push(this.createInvoiceReference(invoice));
                    }

                    return res;
                }, []);

                if (invoiceReferences.length) {
                    allReferences.push({
                        category: { value: 'selectInvoice', label: this.$t('invoice.select') },
                        groups: [
                            {
                                label: this.$t('invoice.label'),
                                options: invoiceReferences,
                            },
                        ],
                    });
                }
            }

            return allReferences;
        },

        paymentPayload() {
            let ccData = {};

            if (this.paymentType && this.paymentType.value === PAYMENT_METHOD_TYPES.creditCard || this.showRecurringInfo) {
                ccData = this.getDesiredCreditCardData();
            }

            const paymentPayload = {
                contactId: this.contactId,
                paymentMethodType: this.paymentType.value,
                ...ccData,
            };

            if (this.paymentReference.type === PAYMENT_REFERENCE_TYPES.product) {
                paymentPayload.orderItems = [{
                    price: this.paymentAmount,
                    productId: this.paymentReference.value,
                }];
            } else if (this.paymentReference.type === PAYMENT_REFERENCE_TYPES.invoice) {
                paymentPayload.orderId = this.paymentReference.value;
                paymentPayload.amount = this.paymentAmount;
            }

            paymentPayload.sendReceipt = this.sendReceipt;

            return paymentPayload;
        },

        allowAnotherPayment() {
            return !this.lockPaymentReference;
        },

        firstName() {
            return this.displayName ? this.displayName : this.$t('recurringPaymentInfo.customer');
        },

        firstNameButton() {
            return this.displayName ? this.displayName : this.$t('recurringPaymentInfo.customer').toLowerCase();
        },
    },

    methods: {
        handleToggle() {
            this.isExpanded = !this.isExpanded;
        },

        setPaymentContact(id) {
            this.$store.dispatch('contacts/LOAD_CONTACT_PAGE', id);

            if (!this.forceIsRecurring) {
                this.$store.dispatch('sales/LOAD_CONTACT_INVOICES', id);
            }

            this.contactId = id;
        },

        setSendReceipt() {
            if (!this.contactEmail) {
                this.sendReceipt = false;
            }
        },

        nestedModal_open(data) {
            this.resetSelections();

            if (data && data.invoice) {
                this.lockPaymentReference = true;
                this.paymentReferenceSelected = this.createInvoiceReference(data.invoice);
            }

            if (data && data.isRecurring || this.showRecurringInfo) {
                this.forceIsRecurring = data.isRecurring;
                this.isRecurring = data.isRecurring;
                [this.paymentType] = this.paymentTypes;
            } else {
                this.forceIsRecurring = false;
                this.isRecurring = false;
            }

            if (data && data.contactId > 0 || this.showRecurringInfo) {
                this.contactId = data.contactId;
                this.showChooseContact = false;
            } else {
                this.contactId = 0;
                this.showChooseContact = true;
            }
        },

        nestedModal_reset() {
            this.resetSelections();
        },

        createInvoiceReference(invoice) {
            const amountDue = invoice.total - invoice.totalPaid;

            return {
                type: PAYMENT_REFERENCE_TYPES.invoice,
                value: invoice.id,
                label: this.$t('invoice.title', {
                    id: invoice.id,
                    total: this.$n(amountDue, 'currency'),
                    date: moment(invoice.dueDate).format('ll'),
                }),
                rate: amountDue,
                invoice,
            };
        },

        resetSelections() {
            this.$store.commit('billing/SET_PAYMENT_REFERENCE', {
                type: '',
                rate: 0,
                value: '',
                label: '',
            });
            this.selectedProduct = {};
            this.showPaymentTypes = false;
            this.paymentType = {};
            this.showCreditCardFields = false;
            this.lockPaymentReference = false;
            this.paymentReceivedDate = new Date();
            this.paymentAmount = 0;
        },

        handlePaymentReferenceChanged(paymentReference) {
            if (paymentReference.value === NEW_PRODUCT) {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: ProductModalContent,
                    data: {
                        actionType: 'add',
                        product: {},
                        showSaleOptions: false,
                    },
                });
            } else if (paymentReference.value === '') {
                this.showPaymentTypes = false;
                this.showCreditCardFields = false;
            } else if (this.showRecurringInfo) {
                this.showCreditCardFields = true;
                this.paymentAmount = paymentReference.rate;
            } else {
                this.showPaymentTypes = true;
                this.paymentAmount = paymentReference.rate;
            }

            if (paymentReference.value === PAYMENT_REFERENCE_TYPES.invoice) {
                this.isRecurring = false;
            }

            if (this.isRecurring) {
                this.showPaymentTypes = false;
                this.showCreditCardFields = true;
            }
        },

        updateRecurringBilling(val) {
            this.isRecurring = val;
        },

        handlePaymentTypeChanged(paymentType) {
            if (paymentType && paymentType.value === PAYMENT_METHOD_TYPES.creditCard) {
                this.showCreditCardFields = true;
                this.creditCardName = this.displayFullName;
            } else {
                this.showCreditCardFields = false;
            }
        },

        closeByCancel() {
            this.resetSelections();
            this.$bus.$emit('POP_NESTED_MODAL');
            this.$emit('done');
        },

        processPayment() {
            if (this.paymentReference.type === PAYMENT_REFERENCE_TYPES.invoice) {
                const amountDue = this.paymentReference.rate;

                if (this.paymentAmount > amountDue) {
                    this.$error({
                        message: this.$t('invoice.amountOverTotal', {
                            total: this.$n(amountDue, 'currency'),
                        }),
                        bottom: true,
                    });

                    return Promise.resolve();
                }
            }

            this.processing = true;

            const paymentType = this.paymentPayload.paymentMethodType;

            const trackingString = this.isRecurring ? 'Recurring' : paymentType;

            this.$track(`Money - Payment - submitted : ${trackingString} payment`);

            return this.$store.dispatch('sales/PROCESS_PAYMENT', this.paymentPayload)
                .then(({ successful, message }) => {
                    this.processing = false;

                    if (successful) {
                        this.$track(`Money - Payment - success : ${trackingString} payment`);

                        trackInvoicePaid(this.eventSource, this.paymentType.value);

                        this.$bus.$emit('INVOICE_PAYMENT_SUCCESSFUL');

                        this.$bus.$emit(MONEY_PAYMENT_CREATED_EVENT);

                        if (this.isRecurring) {
                            if (this.paymentReference.type === PAYMENT_REFERENCE_TYPES.invoice) {
                                amplitude.v2.logEvent(amplitude.v2.events.RECURRING_CREATED_FROM_MANUAL_INVOICE_PAYMENT);
                            }

                            if (this.paymentReference.type === PAYMENT_REFERENCE_TYPES.product) {
                                amplitude.v2.logEvent(amplitude.v2.events.RECURRING_CREATED_FROM_MANUAL_PAYMENT);
                            }
                        }

                        if (this.isRecurring) {
                            this.openSuccessfulRecurringPaymentModal();
                        } else {
                            this.openSuccessfulPaymentModal();
                        }
                    } else {
                        this.$track(`Money - Payment - error : ${trackingString} payment`);
                        this.handleProcessingError(message);
                    }
                })
                .catch(() => this.handleProcessingError());
        },

        handleProcessingError(receivedMessage) {
            const message = this.paymentFailureUtils_displayMessage(receivedMessage);

            this.$error({ message, bottom: true });
        },

        openSuccessfulPaymentModal() {
            if (this.$refs.successfulPaymentModal) {
                this.$refs.successfulPaymentModal.open();
            }
        },

        openSuccessfulRecurringPaymentModal() {
            if (this.$refs.successfulRecurringPaymentModal) {
                this.$refs.successfulRecurringPaymentModal.open();
            }
        },

        addAnotherPayment() {
            this.resetting = true;

            if (this.$refs.successfulPaymentModal) {
                this.$refs.successfulPaymentModal.close();
            }

            if (this.$refs.successfulRecurringPaymentModal) {
                this.$refs.successfulRecurringPaymentModal.close();
            }

            this.resetSelections();
            this.resetting = false;
        },

        handleSuccessModalClose() {
            if (!this.resetting) {
                this.$bus.$emit('POP_NESTED_MODAL');
                this.$emit('done');

                if (this.contactTypeUpdatesEnabled) {
                    this.$bus.$emit('CONTACT_LIST_REFRESH');
                    this.$bus.$emit('CONTACT_RECORD_REFRESH');
                }
            }
        },

        productCreated() {
            this.handlePaymentReferenceChanged(this.paymentReference);
        },

        goToInstantDepositPage() {
            /* istanbul ignore next */
            window.open('https://hi481-a4c79e.pages.infusionsoft.net', '_blank', 'noopener,noreferrer');
        },

        getDesiredCreditCardData() {
            const data = this.$refs.creditCardFields.getData();

            let fieldData = {
                nameOnCard: data.nameOnCard,
                cardNumber: data.cardNumber,
                country: data.country,
                region: data.region,
                postalCode: data.postalCode,
                verificationCode: data.verificationCode,
                expirationMonth: data.expirationMonth,
                expirationYear: data.expirationYear,
            };

            if (this.isRecurring && this.$refs.recurringBillingFields) {
                const recurringData = this.$refs.recurringBillingFields.getData();

                fieldData = {
                    ...fieldData,
                    recurringBilling: recurringData.recurringBilling,
                    numberOfPayments: recurringData.numberOfPayments,
                    frequency: recurringData.frequency,
                    billingCycle: recurringData.billingCycle,
                    startDate: recurringData.startDate,
                };
            }

            return fieldData;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .add-button {
        @include margin-end($gp);
    }

    .button-holder {
        display: flex;
        margin-top: $gp;
    }

    .product-price-holder {
        display: flex;
        color: $color-gray-200;
    }

    .product-price {
        font-size: $font-size-currency;
        @include margin-end($gp / 2);
    }

    .currency-type {
        align-self: flex-end;
        margin-bottom: px-to-rem(11px);
    }

    .product-options {
        margin-top: $gp / 4;
        --dropdown-height: #{px-to-rem(258px)};
    }

    .successful-button-container {
        margin-top: $gp;
    }

    .get-paid-text {
        font-size: $font-size-xsmall;
        text-align: center;
        margin-top: $gp;
        margin-bottom: 0;
    }

    .receipt-checkbox {
        --checkbox-padding: #{$gp 0};
    }

    .show-recurring {
        --credit-card-fields-bottom-margin: 0;
    }

    .description {
        font-size: $font-size-med;
        margin-bottom: $gp / 2;
    }

    .recurring-info {
        margin-bottom: $gp;
    }

    .list-info {
        margin-bottom: $gp / 4;
    }

    .list-row {
        margin: $gp / 2 0;
        display: flex;
        align-items: center;
    }

    .number-column {
        width: px-to-rem(28px);
        align-items: center;
    }

    .list-text {
        margin-left: $gp;
        display: flex;
    }
</style>

<i18n>
{
    "en-us": {
        "recurringTitle": "Add a recurring payment",
        "invoice": {
            "isReceiptSending": "Send a receipt to {name}",
            "label": "Invoices",
            "title": "Invoice #{id}, with {total} due {date}",
            "select": "Select an invoice",
            "amountOverTotal": "The payment amount can't exceed the total due: {total}"
        },
        "product": {
            "add": "Add a product",
            "label": "Products",
            "select": "Select a product"
        },
        "errors": {
            "loadInvoices": "Something went wrong and we couldn't load your invoices. Please try again.",
            "loadProducts": "Something went wrong and we couldn't load your products. Please try again."
        },
        "successfulPayment": {
            "title": "Payment successful",
            "subTitle": "You can see all of your clients' payment activity under the Invoices tab.",
            "ok": "Ok, got it",
            "addAnother": "Add another payment"
        },
        "successfulRecurringPayment": {
            "title": "Recurring payment setup successful",
            "subTitle": "See all of these payments under the Recurring Payments tab.",
            "ok": "Ok, got it",
            "addAnother": "Add another payment"
        },
        "recurringPaymentInfo": {
            "customer": "Your customer",
            "description": "Enter your customer's credit card information below to use for all charges on this recurring invoice.",
            "list": {
                "title": "See how it would work for {firstName}",
                "detailsOne": "{firstName}'s credit card you entered will be charged automatically for each payment.",
                "detailsTwo": "{firstName} will receive a receipt for each payment."
            },
            "numbers": {
                "one": "1",
                "two": "2"
            },
            "header": "Charge manually",
            "button":"Start recurring payment"
        }
    }
}
</i18n>
