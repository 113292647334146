<template>
    <contact-addresses-view
        v-if="!editing"
        :contact="contact"
        :field-options="fieldOptions"
    />

    <contact-addresses-edit
        v-else
        :contact="contact"
        :company-country="companyProfile.country"
        :field-options="fieldOptions"
        @input="$emit('input')"
    />
</template>

<script>
import ContactAddressesView from '@/contacts/components/simple/ContactAddressesView';
import ContactAddressesEdit from '@/contacts/components/simple/ContactAddressesEdit';

import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        ContactAddressesView,
        ContactAddressesEdit,
    },

    props: {
        contact: Object,
        editing: Boolean,
    },

    computed: {
        ...mapState({
            companyProfile: ({ auth }) => auth.account.companyProfile,
        }),

        ...mapGetters({
            fieldOptions: 'contacts/fieldOptions',
        }),
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .field-grid {
        margin-bottom: $gp;
    }
</style>
