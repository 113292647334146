<template>
    <p class="privacy-text">
        {{ $t('privacyText') }}

        <span class="view-privacy-policy" @click="viewPrivacyPolicy">
            {{ $t('global.viewPrivacyPolicy') }}
        </span>
    </p>
</template>

<script>
import redirectMixin from '@/shared/mixins/redirect.mixin';

export default {
    mixins: [redirectMixin],

    data() {
        return {
            privacyPolicyUrl: process.env.VUE_APP_GOOGLE_API_PRIVACY_POLICY,
        };
    },

    methods: {
        viewPrivacyPolicy() {
            this.redirect_url_new_tab(this.privacyPolicyUrl);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .privacy-text {
        color: $color-gray-800;
        font-size: $font-size-xsmall;
        line-height: normal;
        padding: $gp;
        margin-top: $gp;

        .view-privacy-policy {
            color: $color-gray-800;
            text-decoration: underline;
            cursor: pointer;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "privacyText": "We take your privacy seriously. You have full control over what calendars and information you choose to share."
    }
}
</i18n>
