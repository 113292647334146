import { FF_KEAP_SINGLE_PIPELINE } from '@/shared/constants/featureFlag.constants';

const PipelinePage = () => import(/* webpackChunkName: "pipeline" */ '@/pipeline/pages/PipelinePage');

const routes = [
    {
        path: '/pipeline',
        name: 'pipeline',
        component: PipelinePage,
        meta: {
            title: 'nav.pipeline',
            hideHeaderOnMobile: true,
            resetIntercom: true,
            preRouteFeatureCheck: (getters, _, featureFlags) => {
                return (getters['auth/hasSinglePipelineFeature'] || getters['auth/hasMultiplePipelinesFeature'])
                    || Boolean(featureFlags && featureFlags[FF_KEAP_SINGLE_PIPELINE]);
            },
        },
        children: [
            {
                path: '/pipeline/:pipelineId/deal/:id',
                name: 'pipeline-deal',
                meta: {
                    title: 'nav.deal',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: '/pipeline/:pipelineId',
                name: 'pipeline-id',
                meta: {
                    title: 'nav.pipeline',
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: '/pipeline/deal/:id',
                name: 'deal',
                meta: {
                    title: 'nav.deal',
                    hideHeaderOnMobile: true,
                },
            },
        ],
    },
];

export default routes;
