import { render, staticRenderFns } from "./ProductModalContent.vue?vue&type=template&id=97ed3604&scoped=true&"
import script from "./ProductModalContent.vue?vue&type=script&lang=js&"
export * from "./ProductModalContent.vue?vue&type=script&lang=js&"
import style0 from "./ProductModalContent.vue?vue&type=style&index=0&id=97ed3604&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ed3604",
  null
  
)

/* custom blocks */
import block0 from "./ProductModalContent.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports