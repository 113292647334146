const phoneRegex = /(\+?1-?)?(\d{3})(\d{3})(\d{4})/;

export default {
    methods: {
        formatPhone(phone) {
            if (!phone) {
                return null;
            }

            let numbers = phone.replace(/\D/g, '');

            if (numbers.startsWith('1')) {
                numbers = numbers.substring(1);
            }

            const char = { 0: '(', 3: ') ', 6: '-' };

            phone = '';

            for (let i = 0; i < numbers.length; i++) {
                phone += (char[i] || '') + numbers[i];
            }

            return phone;
        },

        formatPhoneTelLink(phone) {
            if (!phone) {
                return null;
            }

            phone = phone.replace('+1', '');

            return phone.replace(/[^0-9]/g, '')
                .replace(phoneRegex, '$2-$3-$4');
        },
    },
};
