<template lang="html">
    <div
        class="contextual-automation-card-item"
        :data-qa="`automation-card_${automation.name}`"
    >
        <div class="automation-details">
            <div>
                <div class="automation-title" :title="automation.name">
                    {{ automation.name }}
                </div>

                <div :class="['automation-status', { published: isPublished, edited: automation.hasChanges }]">
                    <template v-if="isPublished">
                        {{ automationStatus }}
                    </template>

                    <template v-else>
                        <ds-status emphasis="high">
                            {{ automationStatus }}
                        </ds-status>
                    </template>
                </div>
            </div>

            <ds-text-button :data-qa="`edit_${automation.name}`" @click="editAutomation">
                {{ $t('global.edit') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { AUTOMATION_STATUS } from '@/automations/constants/automations.constants';

export default {
    props: {
        automation: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isPublished() {
            return Boolean(this.automation.status === AUTOMATION_STATUS.ENABLED);
        },

        automationStatus() {
            if (this.isPublished) {
                return this.automation.hasChanges ? this.$t('publishedEdited') : this.$t('published');
            }

            return this.$t('draft');
        },
    },

    methods: {
        editAutomation() {
            this.$emit('edit', this.automation.id);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/statusIcon';

    .contextual-automation-card-item {
        padding: $gp / 2 $gp;
        border: 1px solid $color-gray-lighter;
        border-radius: $border-radius;
        margin-bottom: $gp / 2;
    }

    .automation-details {
        display: flex;
        justify-content: space-between;
    }

    .automation-title {
        @include break-word;
        font-size: $font-size-med;
        padding-bottom: $gp / 4;
    }
</style>

<i18n>
{
    "en-us": {
        "draft": "Draft",
        "published": "Active",
        "publishedEdited": "Unpublished changes"
    }
}
</i18n>
