<template>
    <div class="connect-stage">
        <h2 v-if="!hideTitle">
            {{ title }}
        </h2>

        <template v-if="connectionStatus === CONNECT_STATE.CONNECTED">
            <div class="connected-card">
                <svg>
                    <use xlink:href="#google-calendar-connected" />
                </svg>

                <p v-if="provider.email">
                    {{ provider.email }}
                </p>
            </div>

            <h6>{{ connectedDescription }}</h6>

            <br />

            <ds-filled-button
                v-track="`Appointments - Reconnect to ${provider.provider} success modal - clicked : Close button`"
                class="large"
                @click="handleConnectAction"
            >
                {{ connectedButtonLabel }}
            </ds-filled-button>
        </template>

        <template v-if="connectionStatus === CONNECT_STATE.NOT_CONNECTED">
            <calendar-provider-card
                id="GOOGLE"
                v-track="'Appointments - Connect your calendar modal - clicked : Connect Google'"
            />
            <calendar-provider-card
                v-if="outlookSyncEnabled"
                id="MICROSOFT"
                v-track="'Appointments - Connect your calendar modal - clicked : Connect Microsoft'"
            />

            <google-privacy-policy />

            <a
                v-track="'Appointments - Connect your work calendar - clicked : Not using Google or Outlook? link'"
                class="feedback-link"
                :href="feedbackLink"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ $t('notUsingProviders') }}
            </a>
        </template>

        <p v-if="connectionStatus === CONNECT_STATE.SERVICE_UNAVAILABLE">
            {{ $t('serviceUnavailable') }}
        </p>

        <template v-if="connectionStatus === CONNECT_STATE.CONNECTED_WITH_ISSUES">
            <p>{{ $t('connectionIssueDescription') }}</p>

            <ds-filled-button
                v-track="`Appointments - Connect to ${provider.provider} success modal - clicked : Connect Other Account`"
                class="large"
                @click="reconnect"
            >
                {{ $t('connectAnotherAccount') }}
            </ds-filled-button>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import authMixin from '@/shared/mixins/auth.mixin';
import redirectMixin from '@/shared/mixins/redirect.mixin';

import { CONNECT_STATE } from '@/appointments/appointments.constants';
import { FF_KEAP_APPTS_OUTLOOK_SYNC_ENABLED } from '@/shared/constants/featureFlag.constants';

import '@/appointments/images/google-calendar-connected.svg';

import GooglePrivacyPolicy from '@/appointments/components/GooglePrivacyPolicy';
import CalendarProviderCard from '@/settings/components/integrations/CalendarProviderCard';
import amplitude from '@/analytics/amplitude';

const normalizeProvider = (provider) => {
    if (provider === 'GOOGLE') return 'Google';
    if (provider === 'MICROSOFT') return 'Microsoft';

    return provider;
};

export default {
    components: {
        GooglePrivacyPolicy,
        CalendarProviderCard,
    },

    mixins: [authMixin, redirectMixin],

    props: {
        hideTitle: {
            type: Boolean,
            default: false,
        },

        isReconnect: {
            type: Boolean,
            default: false,
        },

        showCalendarModalOnSuccess: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            CONNECT_STATE,
            connecting: false,
            isCredentialsServiceAlive: false,
            supportedProviders: ['MICROSOFT', 'GOOGLE'],
        };
    },

    mounted() {
        this.setIfCredentialsServiceIsAlive();
    },

    watch: {
        isConnected: {
            handler (status) {
                this.$emit('update:ready', status);
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters({
            hasApptType: 'calendar/hasApptType',
            getWriteCalendars: 'calendar/writeCalendars',
            providerCalendars: 'calendar/providerCalendars',
        }),

        ...mapState({
            outlookSyncEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_APPTS_OUTLOOK_SYNC_ENABLED],
        }),

        feedbackLink() {
            return this.outlookSyncEnabled
                ? 'https://docs.google.com/forms/d/1jFA2JD4XEfI347m48FHgeD55N-6G0LWI_cP0vN3htW0/viewform'
                : 'https://goo.gl/forms/nPorq6OhnhYANMCC2';
        },

        connectedButtonLabel() {
            return this.isReconnect
                ? this.$t('global.close')
                : this.$t('setup');
        },

        connectedDescription() {
            if (this.isReconnect) {
                return this.$t('reconnectedDescription');
            }

            if (this.hasApptType) {
                return this.$t('connectedDescription');
            }

            return this.$t('connectedFtu');
        },

        connectionStatus() {
            if (!this.isCredentialsServiceAlive) {
                return CONNECT_STATE.SERVICE_UNAVAILABLE;
            }

            if (this.isConnected && this.hasProviderCalendars && this.hasWriteCalendars) {
                return CONNECT_STATE.CONNECTED;
            }

            if (this.isConnected && ((this.hasProviderCalendars && !this.hasWriteCalendars) || !this.hasProviderCalendars)) {
                // We have a connection, but can't write to your calendars even though they exist, so you probably can't give access to them
                // or we have a connection, but your calendars aren't visible to us, even your default one
                return CONNECT_STATE.CONNECTED_WITH_ISSUES;
            }

            return CONNECT_STATE.NOT_CONNECTED;
        },

        hasProviderCalendars() {
            return this.provider && this.providerCalendars(this.provider.name).length > 0;
        },

        hasWriteCalendars() {
            return this.provider && this.getWriteCalendars(this.provider.name).length > 0;
        },

        provider() {
            return this.providers.find((provider) => this.supportedProviders.includes(provider.name));
        },

        isConnected() {
            return this.provider.isConnected;
        },

        buttonLabel() {
            return this.connecting
                ? this.$t('connecting')
                : this.$t('connect');
        },

        title() {
            return this.isConnected
                ? this.$t('connectedTitle')
                : this.$t('title');
        },
    },

    methods: {
        async setIfCredentialsServiceIsAlive() {
            this.isCredentialsServiceAlive = await this.$store.dispatch('auth/IS_CREDENTIALS_SERVICE_ALIVE');
        },

        createRedirectUrl(scope, provider) {
            let redirectUrl;

            if (this.showCalendarModalOnSuccess) {
                redirectUrl = this.auth_getOauthReturnUrl({
                    authType: 'calendar',
                    setupCalendarModal: 'true',
                    scope,
                    provider,
                });
            } else {
                redirectUrl = this.auth_getOauthReturnUrl({});
            }

            return redirectUrl;
        },

        connect(provider) {
            if (!provider) {
                return;
            }

            const normalizedProvider = normalizeProvider(provider);

            this.connecting = true;
            const scope = 'calendar';

            const payload = {
                provider,
                scope,
                redirectUrl: this.createRedirectUrl(scope, provider),
            };

            this.$store.dispatch('auth/AUTHORIZE_PROVIDER', payload)
                .then((location) => {
                    amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_CONNECTED, {
                        'Connected Integration': `${normalizedProvider} Calendar`,
                        'Event Source': 'Connect Provider Modal',
                    });

                    this.redirect_url(location);
                });
        },

        async reconnect() {
            this.connecting = true;

            const message = this.$t('reconnectError');

            await this.$store.dispatch('calendar/DELETE_PROVIDER_INTEGRATION', this.provider)
                .catch(() => {
                    this.$error({ message });
                    this.connecting = false;
                });

            this.connect();
        },

        handleConnectAction() {
            return this.isReconnect
                ? this.$emit('close')
                : this.$emit('next');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .connect-stage {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
        max-width: $form-width;
        margin: 0 auto;
    }

    h2 {
        margin: $gp * 2 0;
    }

    .connected-card {
        @include card;
        background-color: $color-gray-050;
        padding: 0 $gp;
        margin-bottom: $gp * 3;
    }

    .feedback-link {
        margin-top: $gp;
        font-weight: $font-weight-semibold;
    }

    .provider-card {
        width: 320px;
        max-width: 100%;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Connect your work calendar",
        "connectedTitle": "You're connected",
        "connecteddescription": "Next, let's set up your new appointment type.",
        "connectedFtu": "Next, let's set up your first appointment type.",
        "reconnectedDescription": "Great. Your calendar has been reconnected",
        "connectionIssueDescription": "It appears this account has no writable calendars. Please connect another account with calendar permissions.",
        "reconnectError": "There was a problem reconnecting. Please try again.",
        "serviceUnavailable": "It looks like our connection to your calendar provider is down right now. Don't worry, we're working on getting it back as soon as possible.",
        "connect": "Connect",
        "connecting": "Connecting",
        "setup": "Set up",
        "connectAnotherAccount": "Connect another account",
        "notUsingProviders": "Using a different calendar?",
        "connect": "Connect"
    }
}
</i18n>
