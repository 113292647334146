// For use when searching data from an input
export const SEARCHING_DEBOUNCE_DELAY = 300;
// For use with general inputs
export const INPUT_DEBOUNCE_DELAY = 500;
// Used when loading data such as tables from an input
export const LOADING_DEBOUNCE_DELAY = 1000;
// Used for autosaving
export const SAVING_DEBOUNCE_DELAY = 1200;
// Used for autocompleting such as addresses, important to have a high delay to keep costs low
export const AUTOCOMPLETE_DEBOUNCE_DELAY = 1500;
// Used for delaying blur events
export const BLUR_DELAY = 300;
// Used for animation flashing
export const FLASH_DELAY = 1200;
// Used for remove Copied text
export const COPY_TEXT_AUTOHIDE_DELAY = 3000;

// Integrations

// Used for timeout in Integrations third party cookie checks
export const COOKIE_CHECK_TIMEOUT = 3000;
// Used in reconnecting a Blendr datasource as interval
export const CHECK_DATASOURCE_CONNECTION_STATUS_INTERVAL_MS = 500;
// Used in reconnecting a Blendr datasource as timeout
export const RELOAD_DATASOURCES_DELAY_MS = 200;
// Used to delay Blendr template reloads after a deletion
export const BLENDR_DELETE_RELOAD_DELAY_MS = 1750;
// Used to delete a Blendr data source after deleting the blends it was connected to
export const DELETE_DATASOURCE_DELAY_MS = 250;
