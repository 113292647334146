<template>
    <div class="configure">
        <configure-details
            :title="title"
            :subtitle="subtitle"
            :has-error="hasError"
            :error-message="$t('error')"
            :type="value.type"
            :trigger-action="triggerAction"
            @edit="(payload) => $emit('edit', { trigger: value })"
            @remove="(payload) => $emit('remove', payload)"
        />

        <div class="button-row">
            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';
import { getPipelineStageIds } from '@/automations/utils/automations.utils';

export default {
    components: {
        ConfigureDetails,
    },

    props: {
        value: Object,
        hasError: Boolean,
        errorMessage: String,
        triggerAction: String,
    },

    computed: {
        ...mapState({
            pipelineList: ({ pipeline }) => pipeline.pipelineList,
        }),

        ...mapGetters({
            stageById: 'pipeline/stageById',
            getPipelineById: 'pipeline/getPipelineById',
        }),

        title() {
            if (!this.value?.sourceId) {
                return this.$t(this.value.name);
            }

            return this.stage?.name || this.$t('invalidStage');
        },

        stage() {
            const ids = getPipelineStageIds(this.value.sourceId);

            return this.stageById(ids.stageId) || {};
        },

        subtitle() {
            if (!this.value?.sourceId) {
                return '';
            }

            const ids = getPipelineStageIds(this.value.sourceId);
            const name = this.getPipelineById(ids.pipelineId)?.name;

            return this.$t('subtitle', { name: name || this.$t('invalidPipeline') });
        },
    },

    methods: {
        async init() {
            if (!this.pipelineList.length) {
                await this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>

<i18n>
{
    "en-us": {
        "subtitle": "Pipeline: {name}",
        "invalidStage": "Invalid stage",
        "invalidPipeline": "Invalid pipeline",
        "error": "Please select a stage"
    }
}
</i18n>
