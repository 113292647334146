import components from '@infusionsoft/design-system/src/components';

export { components };

export const icons = [
    'activity',
    'add',
    'add-circle',
    'add-fill',
    'alert-circle',
    'alert-octagon',
    'alert-triangle',
    'alert-triangle-fill',
    'align-center',
    'arrow-down',
    'arrow-down-fill',
    'arrow-left',
    'arrow-long-right',
    'arrow-right',
    'arrow-right-circle',
    'arrow-right-fill',
    'arrow-up',
    'arrow-up-fill',
    'arrow-up-right',
    'attach',
    'award',
    'bar-chart-2',
    'bold',
    'book-open',
    'briefcase',
    'business-card',
    'calendar',
    'calendar-fill',
    'calendar-x',
    'campaign',
    'chart',
    'check',
    'check-circle',
    'check-square',
    'chevron-down',
    'chevron-left',
    'chevron-right',
    'chevron-up',
    'circle-fill',
    'clock',
    'cloud-lightning',
    'code',
    'collapse',
    'contact-add-user-plus',
    'contacts-users',
    'conversation',
    'copy',
    'credit-card',
    'dollar',
    'download',
    'draggable',
    'edit',
    'email',
    'email-fill',
    'email-open',
    'expand',
    'expand-vertical',
    'external-link',
    'eye-hide',
    'eye-show',
    'facebook',
    'file',
    'filter',
    'fire',
    'folder',
    'folder-fill',
    'form',
    'form-fill',
    'gift',
    'hamburger',
    'help-circle',
    'home',
    'image',
    'info-circle',
    'info-circle-fill',
    'instagram',
    'integrations',
    'italic',
    'layout',
    'lightbulb',
    'lightning-fill',
    'link',
    'link-horiz',
    'list',
    'list-cards',
    'list-unordered',
    'loader',
    'location',
    'lock-locked-fill',
    'log-out',
    'maximize',
    'megaphone',
    'merge-field',
    'message',
    'message-fill',
    'message-square',
    'message-square',
    'messages-automated',
    'mic-on',
    'mic-off',
    'minimize',
    'minus',
    'minus-circle',
    'minus-fill',
    'more',
    'more-horizontal',
    'more-vertical',
    'move',
    'note',
    'note-fill',
    'notes',
    'notes-fill',
    'notification',
    'octagon',
    'package',
    'pencil',
    'pencil-edit',
    'phone',
    'phone-call',
    'phone-dialpad',
    'phone-fill',
    'phone-forwarded-fill',
    'phone-incoming',
    'phone-missed',
    'phone-off-fill',
    'phone-outgoing',
    'pipeline',
    'pipeline-fill',
    'play',
    'play-circle',
    'play-fill',
    'playbook',
    'plus-square',
    'printer',
    'profile',
    'quote',
    'receipt',
    'recurring',
    'refresh-cw',
    'replace',
    'rename',
    'rotate-ccw',
    'rss',
    'sales',
    'sales-fill',
    'save',
    'search',
    'send',
    'settings',
    'share-2',
    'shopping-cart',
    'shuffle',
    'slash',
    'sort',
    'star-fill',
    'tag',
    'tag-fill',
    'tasks',
    'tasks-fill',
    'templates',
    'thumbs-down',
    'thumbs-up',
    'trash',
    'trash-2',
    'trending-up',
    'type',
    'user',
    'user-check',
    'user-fill',
    'user-minus',
    'user-plus',
    'users',
    'video',
    'video-fill',
    'video-off-fill',
    'view-mobile',
    'volume-2',
    'volume-x',
    'x',
    'x-circle',
    'x-circle-fill',
    'zap',
    'zap-fill',
    'zap-off',
];

export const illustrations = [
    'activity-color',
    'activity-gray',
    'bank-color',
    'bank-gray',
    'calendar-color',
    'calendar-gray',
    'call-forwarding-color',
    'call-forwarding-gray',
    'campaign-report-color',
    'campaign-report-gray',
    'campaigns-color',
    'campaigns-gray',
    'checkboxes-color',
    'checkboxes-gray',
    'combine-contact-sources-color',
    'combine-contact-sources-gray',
    'completed-tasks-color',
    'completed-tasks-gray',
    'contacts-color',
    'contacts-gray',
    'custom-campaign-color',
    'custom-campaign-gray',
    'custom-fields-color',
    'custom-fields-gray',
    'download-app-color',
    'download-app-gray',
    'download-contacts-color',
    'download-contacts-gray',
    'download-contacts-success-color',
    'download-contacts-success-gray',
    'email-broadcasts-color',
    'email-broadcasts-gray',
    'export-color',
    'export-gray',
    'fast-followup-color',
    'fast-followup-gray',
    'followup-color',
    'followup-gray',
    'form-appointment-color',
    'form-appointment-gray',
    'form-checkout-color',
    'form-checkout-gray',
    'form-internal-color',
    'form-internal-gray',
    'form-public-gray',
    'form-public-color',
    'happy-browser-color',
    'happy-browser-gray',
    'import-contacts-color',
    'import-contacts-gray',
    'leads-color',
    'leads-gray',
    'location-lookup-color',
    'location-lookup-gray',
    'messenger-color',
    'messenger-gray',
    'money-color',
    'money-gray',
    'new-contact-color',
    'new-contact-gray',
    'notes-color',
    'notes-gray',
    'oops-color',
    'oops-gray',
    'phone-number-ready-color',
    'phone-number-ready-gray',
    'pipeline-color',
    'pipeline-gray',
    'promote-color',
    'promote-gray',
    'sad-calendar-color',
    'sad-calendar-gray',
    'scan-contact-color',
    'scan-contact-gray',
    'segment-color',
    'segment-gray',
    'setup-messenger-color',
    'setup-messenger-gray',
    'simple-text-gray',
    'success-confetti-color',
    'success-confetti-gray',
    'success-high-five-color',
    'success-high-five-gray',
    'success-sent-color',
    'success-sent-gray',
    'success-thumbs-up-color',
    'success-thumbs-up-gray',
    'successful-payment-color',
    'successful-payment-gray',
    'tasks-color',
    'tasks-gray',
    'three-emails-color',
    'three-emails-gray',
    'untranscribable-image-color',
    'untranscribable-image-gray',
    'upload-color',
    'upload-gray',
    'voice-messenger-color',
    'voice-messenger-gray',
];
