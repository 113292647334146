<template lang="html">
    <publish-page-section
        class="link-url"
        :title="$t('sectionTitle')"
        :description="$t('copyLandingPageText')"
    >
        <div class="page-url">
            <div class="input-div">
                <ds-input-field
                    v-model="value"
                    class="url-input"
                    data-qa="url-input"
                    type="text"
                    :loading="true"
                    readonly
                    :required="false"
                    input-type="text"
                    field-id="url"
                />
            </div>

            <ds-outline-button
                class="copy-button"
                data-qa="copy-url"
                leading-icon="copy"
                @click="copyLink"
            >
                {{ $t('copyUrlButtonLabel') }}
            </ds-outline-button>

            <div
                v-if="copied"
                data-qa="copy"
                class="copy"
            >
                {{ $t('copied') }}
            </div>
        </div>
    </publish-page-section>
</template>

<script>
import PublishPageSection from './PublishPageSection';
import { COPY_TEXT_AUTOHIDE_DELAY } from '@/shared/constants/timing.constants';

export default {
    components: { PublishPageSection },

    props: {
        linkUrl: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            copied: false,
            value: this.linkUrl,
        };
    },

    methods: {
        async copyLink() {
            this.$emit('copy-url', this.value);
            const errorMessage = this.$t('copyLinkErrorMessage');

            try {
                await this.$copyText(this.linkUrl);
                this.toggleCopyCode();
                this.$track('Link Copied', { source: this.source });
            } catch (error) {
                this.$error({ message: errorMessage });
            }
        },

        toggleCopyCode() {
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, COPY_TEXT_AUTOHIDE_DELAY);
        },
    },
};
</script>
<style lang="scss" scoped>
    .link-url {
        position: relative;
    }

    .input-div {
        width: px-to-rem(350);
        @include margin-end($gp / 2);
    }
    .url-input {
        --input-margin-bottom: 0;
    }
    .page-url {
        display: flex;
        flex-direction: row;
    }
    .icon-row {
        display: flex;
        flex-direction: row;
    }
    .share-icon {
        color: $color-gray-800;
        @include margin-end($gp / 2);
    }
    .copy {
        @include margin-start($gp);
        font-size: $font-size-sm;
        color: $color-text-subtle;
        align-self: center;
    }
</style>
<i18n>
{
    "en-us": {
        "copied": "Copied!",
        "sectionTitle": "Landing Page URL",
        "copyLandingPageText": "Copy a link to your landing page or share it with your fans.",
        "copyUrlButtonLabel": "Copy link",
        "copyLinkErrorMessage": "The link could not be copied"
    }
}
</i18n>
