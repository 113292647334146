<template>
    <configure-step-with-timer
        :value="value"
        :title="title"
        :subtitle="body"
        :has-error="hasError"
        :has-timer-warning="hasTimerWarning"
        :error-message="errorMessage"
        @edit="(payload) => $emit('edit', payload)"
        @save="(payload) => $emit('save', payload)"
        @remove="$emit('remove')"
        @done="$emit('done')"
    />
</template>

<script>
import ConfigureStepWithTimer from './ConfigureStepWithTimer';

export default {
    components: {
        ConfigureStepWithTimer,
    },

    props: {
        value: Object,
        hasError: Boolean,
        hasTimerWarning: Boolean,
        errorMessage: String,
    },

    data() {
        return {
            step: this.value?.step ?? this.value,
        };
    },

    watch: {
        value: {
            handler(newVal) {
                this.step = newVal.step ?? newVal;
            },
            deep: true,
        },
    },

    computed: {
        title() {
            return this.step.configJson?.title || this.$t(this.step.name);
        },

        body() {
            return this.step.configJson?.body || null;
        },
    },
};
</script>
