const BroadcastsPage = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/pages/BroadcastsPage');
const BroadcastReportPage = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/pages/BroadcastReportPage');
const BroadcastWizardPage = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/pages/BroadcastWizardPage');
const EmailBroadcasts = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/components/email/EmailBroadcasts');
const SmsBroadcasts = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/components/sms/SmsBroadcasts');
const UnlayerWizard = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/components/unlayer/UnlayerWizard');
const TemplateSelectionStage = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/components/unlayer/TemplateSelectionStage');
const SendStage = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/components/unlayer/SendStage');
const BroadcastEdit = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/components/unlayer/BroadcastEdit');
const BroadcastEditStage = () => import(/* webpackChunkName: "broadcasts" */ '@/broadcasts/components/unlayer/BroadcastEditStage');

import { FF_KEAP_SMS_BROADCAST } from '@/shared/constants/featureFlag.constants';

import {
    ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
} from '@/settings/settings.constants';
import { SMS_BROADCAST_FEATURE, EMAIL_BROADCAST_FEATURE } from '../shared/constants/features.constants';

const routes = [
    {
        path: '/broadcasts',
        name: 'broadcasts',
        component: BroadcastsPage,
        redirect: '/broadcasts/email',
        meta: {
            title: 'nav.broadcasts',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
            resetIntercom: true,
        },
        children: [
            {
                path: 'email',
                name: 'email-broadcasts',
                component: EmailBroadcasts,
                meta: {
                    title: 'nav.broadcasts',
                    hideHeaderOnMobile: true,
                    feature: EMAIL_BROADCAST_FEATURE,
                },
            },
            {
                path: 'text',
                name: 'sms-broadcasts',
                component: SmsBroadcasts,
                meta: {
                    title: 'nav.broadcasts',
                    feature: SMS_BROADCAST_FEATURE,
                    hideHeaderOnMobile: true,
                    featureFlag: FF_KEAP_SMS_BROADCAST,
                },
            },
        ],
    },
    {
        path: '/broadcasts/email/unlayer',
        component: UnlayerWizard,
        meta: {
            title: 'nav.broadcasts',
            hideHeaderOnMobile: true,
            feature: EMAIL_BROADCAST_FEATURE,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
        },
        children: [
            {
                path: '',
                name: 'unlayer-broadcast',
                props: (route) => ({ tab: route.query.tab }),
                component: TemplateSelectionStage,
            },
            {
                path: 'broadcast/:broadcastId',
                props: true,
                component: BroadcastEdit,
                children: [
                    {
                        path: '',
                        name: 'unlayer-broadcast.broadcast.edit',
                        component: BroadcastEditStage,
                    },
                    {
                        path: 'send',
                        name: 'unlayer-broadcast.broadcast.send',
                        component: SendStage,
                    },
                ],
            },
        ],
    },
    {
        path: '/broadcasts/email/new',
        name: 'new-email-broadcast',
        component: BroadcastWizardPage,
        meta: {
            title: 'nav.broadcasts',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
            feature: EMAIL_BROADCAST_FEATURE,
        },
    },
    {
        path: '/broadcasts/email/:id',
        name: 'edit-email-broadcast',
        component: BroadcastWizardPage,
        meta: {
            title: 'nav.broadcasts',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
            feature: EMAIL_BROADCAST_FEATURE,
        },
    },
    {
        path: '/broadcasts/email/:id/report',
        name: 'email-broadcast',
        component: BroadcastReportPage,
        meta: {
            title: 'nav.broadcasts',
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
            feature: EMAIL_BROADCAST_FEATURE,
        },
    },
    {
        path: '/broadcasts/text/new',
        name: 'new-sms-broadcast',
        component: BroadcastWizardPage,
        meta: {
            title: 'nav.broadcasts',
            feature: SMS_BROADCAST_FEATURE,
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
            featureFlag: FF_KEAP_SMS_BROADCAST,
        },
    },
    {
        path: '/broadcasts/text/:id',
        redirect: '/broadcasts/text/:id/compose',
        component: BroadcastWizardPage,
        meta: {
            title: 'nav.broadcasts',
            feature: SMS_BROADCAST_FEATURE,
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
            featureFlag: FF_KEAP_SMS_BROADCAST,
        },
        children: [
            {
                path: 'compose',
                name: 'compose-sms-broadcast',
                component: BroadcastWizardPage,
                meta: {
                    title: 'nav.broadcasts',
                    feature: SMS_BROADCAST_FEATURE,
                    hideHeaderOnMobile: true,
                    featureFlag: FF_KEAP_SMS_BROADCAST,
                },
            },
            {
                path: 'send',
                name: 'send-sms-broadcast',
                component: BroadcastWizardPage,
                meta: {
                    title: 'nav.broadcasts',
                    feature: SMS_BROADCAST_FEATURE,
                    hideHeaderOnMobile: true,
                    featureFlag: FF_KEAP_SMS_BROADCAST,
                },
            },
        ],
    },
    {
        path: '/broadcasts/text/summary/:id',
        name: 'view-sms-broadcast',
        component: BroadcastWizardPage,
        meta: {
            title: 'nav.broadcasts',
            feature: SMS_BROADCAST_FEATURE,
            hideHeaderOnMobile: true,
            permission: ROLE_NAVIGATION_CAN_ACCESS_BROADCASTS,
            featureFlag: FF_KEAP_SMS_BROADCAST,
        },
    },
];

export default routes;
