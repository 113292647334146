import Vue from 'vue';
import gql from 'graphql-tag';

import * as FEATURE_FLAGS from '@/shared/constants/featureFlag.constants';

const FEATURE_FLAG_NAMES = Object.keys(FEATURE_FLAGS).map((key) => FEATURE_FLAGS[key]);

export default ({ reload = false, isLogInAsSession = false, featureFlagNames = FEATURE_FLAG_NAMES }) => {
    return Vue.prototype.$graphql.query({
        query: BOOT_QUERY,
        variables: {
            reload,
            featureFlagNames,
            isLogInAsSession,
        },
        fetchPolicy: 'no-cache',
    });
};

const BOOT_QUERY = gql`
query boot($reload: Boolean, $featureFlagNames: [String]!, $isLogInAsSession: Boolean) {
    featureFlags(featureFlagNames: $featureFlagNames)
    boot(reload: $reload, isLogInAsSession: $isLogInAsSession) {
        legacyAppLocale
        appCountryCode
        appCurrencyCode
        customersListId
        clientsContactListId
        leadsContactListId
        newLeadsContactListId
        sessionAffinity {
            expires
            name
            value
        }
        appSettings {
            maxTotalCustomFields
            googlePlaceId
        }
        appSalesInfo {
            regionRequired
            merchantSetUp
            paypalPermissionsGranted
            paypalPaymentsReceivable
        }
        user {
            id
            casId
            casUsername
            givenName
            familyName
            fullName
            email
            phone
            phoneType
            phone2
            phone2Type
            title
            company
            emailSignatureURL
            userType
            isPartner
            isLocal
            hasStealth
            permissions {
                name
                granted
            }
            userRole
        }
        users {
            id
            casId
            email
            fullName
            phone
            familyName
            givenName
        }
        account {
            appName
            buildNumber
            blockInfoRole
            isSpaApp
            trial {
                daysLeft
            },
            companyProfile {
                companyName
                email
                website
                phone
                businessType
                goals
                logoUrl
                address1
                address2
                locality
                postalCode
                region
                country
            }
        }
        subscriptionInfo {
            applicationEdition
            subscriptionStatus
            limits {
                marketingTextMessageLimit
                userLimit
                contactLimit
            }
            nextBillDate
            currentTermStart
            currentTermEnd
            monthlyUsageTermStart
            monthlyUsageTermEnd
        }
    }
    account {
        id
        appName
        referredByPartner
        editionName
        features
        limits {
            id
            value
        }
    }
}`;
