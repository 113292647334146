<template>
    <div>
        <sms-broadcast-walkthrough-guide-container :position="beaconPosition" :show-guide="showStep">
            <template #reference>
                <slot />
            </template>

            <template #guide>
                <sms-broadcast-walkthrough-guide
                    :position="guidePosition"
                    :primary-label="primaryLabel"
                    :secondary-label="$t('global.back')"
                    @[secondaryEvent]="handleSecondary"
                    @close="handleClose"
                    @primary="handlePrimary"
                >
                    <template #content>
                        {{ $t(`content.${step}`) }}
                    </template>

                    <template #info>
                        {{ info }}
                    </template>
                </sms-broadcast-walkthrough-guide>
            </template>
        </sms-broadcast-walkthrough-guide-container>

        <portal to="root">
            <sms-broadcast-walkthrough-next-screen-modal v-if="showNextScreenModal" @close="showNextScreenModal = false" />
        </portal>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import SmsBroadcastWalkthroughNextScreenModal from './SmsBroadcastWalkthroughNextScreenModal';
import SmsBroadcastWalkthroughGuide from './SmsBroadcastWalkthroughGuide';
import SmsBroadcastWalkthroughGuideContainer from './SmsBroadcastWalkthroughGuideContainer';

import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';

export const TOTAL_STEP_COUNT = 8;

export default {
    components: { SmsBroadcastWalkthroughGuide, SmsBroadcastWalkthroughGuideContainer, SmsBroadcastWalkthroughNextScreenModal },

    props: {
        disabled: Boolean,
        step: Number,
    },

    data() {
        return { showNextScreenModal: false };
    },

    computed: {
        ...mapState({
            walkthroughStepsComplete: ({ tutorials }) => tutorials.items[TUTORIAL_TYPES.SMS_BROADCASTS_WALKTHROUGH_STEP],
        }),

        secondaryEvent() {
            return this.step === 1 || this.step === 6
                ? null
                : 'secondary';
        },

        info() {
            return this.$t('info', { current: this.step, maximum: TOTAL_STEP_COUNT });
        },

        beaconPosition() {
            return {
                1: 'right',
                2: 'right-start',
                3: 'top',
                4: 'top',
                5: 'left-start',
                6: 'right',
                7: 'right',
                8: 'right',
            }[this.step];
        },

        guidePosition() {
            return {
                1: 'right',
                2: 'top-end',
                3: 'top-start',
                4: 'top-start',
                5: 'bottom-start',
                6: 'right-start',
                7: 'right',
                8: 'right',
            }[this.step];
        },

        primaryLabel() {
            return this.step === TOTAL_STEP_COUNT
                ? this.$t('global.done')
                : this.$t('global.next');
        },

        showStep() {
            if (this.disabled) return false;

            // If step is 1, show if the walkthrough step is undefined
            // Otherwise, show if most recently completed step is 1 higher than number of completed steps
            return this.step === 1
                ? !this.walkthroughStepsComplete
                : this.step === this.walkthroughStepsComplete + 1;
        },
    },

    methods: {
        handleSecondary() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.SMS_BROADCASTS_WALKTHROUGH_STEP,
                value: this.walkthroughStepsComplete - 1,
                forUser: true,
            });
        },

        handleClose() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.SMS_BROADCASTS_WALKTHROUGH_STEP,
                value: -1,
                forUser: true,
            });
        },

        handlePrimary() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.SMS_BROADCASTS_WALKTHROUGH_STEP,
                value: this.step === TOTAL_STEP_COUNT ? -1 : this.step,
                forUser: true,
            });

            if (this.step === 5) {
                this.showNextScreenModal = true;
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "content": {
            "1": "Select recipients individually, or add your audience based on contact groups or tags.",
            "2": "You have control over which phone number types (mobile, work, etc.) your text message will send to. All are turned on by default to help you reach your recipients. Be sure to review your options before sending.",
            "3": "Use merge fields to personalize your message. A merge field replaces a recipient’s unique data based on the fields you select.",
            "4": "Maximize your character count using the link shortener. Adding a link is a great way to get your recipients to take the next step.",
            "5": "Text marketing laws require you to earn consent from your recipients with an opt-in message. Click here to edit and customize  your opt-in message, as well as your no-reply message.",
            "6": "Depending on your recipient list, they fall into two categories: \"Will send\" or \"will attempt to send.\" Click on \"more details\" to see how your message will be sent.",
            "7": "See what you can expect to pay for this broadcast, based on the best-case scenario (if 100% of recipients opted in). You’ll only pay for text messages that were successfully sent and opted into by your recipients.",
            "8": "Send your text message immediately, or select a date and time in the future."
        },
        "info": "Tip {current} of {maximum}"
    }
}
</i18n>
