import { mapState, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapState({
            routeName: ({ route }) => route.name,
            isMyCampaignsRoute: ({ route }) => route.name === 'mycampaigns',
        }),

        ...mapGetters({
            displayFullName: 'contacts/displayFullName',
        }),

        isContactRoute() {
            return this.routeName === 'contact.record';
        },

        nestedModal_subTitle() {
            if (this.isContactRoute) {
                return this.displayFullName;
            }

            if (this.task && this.task.contact) {
                return this.displayFullName;
            }

            return null;
        },
    },
};
