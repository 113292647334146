export default {
    methods: {
        notificationUtils_getContentDetailsAsDictionary(notification) {
            const dictionary = {};

            if (notification
                && notification.contentDetails) {
                const keyValuePairs = Object.entries(notification.contentDetails).filter((kvp) => {
                    const typeOfValue = typeof kvp[1];

                    return typeOfValue === 'string'
                        || typeOfValue === 'number'
                        || typeOfValue === 'boolean';
                });

                for (const kvp of keyValuePairs) {
                    const key = kvp[0];
                    const value = kvp[1];

                    dictionary[key] = value;
                }

                this.notificationUtils_updateContentDetailsDictionaryForCurrency(dictionary);
            }

            return dictionary;
        },

        notificationUtils_updateContentDetailsDictionaryForCurrency(dictionary) {
            const propertyNames = Object.getOwnPropertyNames(dictionary);

            for (const propName of propertyNames) {
                const currencyEntry = dictionary[`${propName}_isCurrency`];

                if (currencyEntry) {
                    const entryValue = dictionary[propName];

                    if (typeof entryValue === 'number') {
                        dictionary[propName] = this.$n(entryValue, 'currency');
                    }
                }
            }
        },

        notificationUtils_getContentDetailsProperty(notification, propertyName) {
            if (notification
                && notification.contentDetails
                && notification.contentDetails[propertyName]) {
                return notification.contentDetails[propertyName];
            }

            return null;
        },

        notificationUtils_handleAction(notification) {
            if (notification
                && this.notificationUtils_hasAction(notification)) {
                const actionFunctionName = this.notificationUtils_getActionFunctionName(notification);
                const notificationAction = this[actionFunctionName];

                notificationAction(notification);
            }
        },

        notificationUtils_getActionFunctionName(notification) {
            return `subTypeAction_${notification.subType}`;
        },

        notificationUtils_hasAction(notification) {
            if (!(notification && notification.actionUrl)) {
                return false;
            }

            const actionFunctionName = this.notificationUtils_getActionFunctionName(notification);
            const notificationAction = this[actionFunctionName];

            if (!notificationAction
                || typeof notificationAction !== 'function') {
                return false;
            }

            if (notification.subType === 'invoicePaid'
                && typeof this.notificationUtils_getContentDetailsProperty(notification, 'invoiceId') !== 'number') {
                return false;
            }

            return true;
        },

        subTypeAction_saEmail({ actionUrl }) {
            this.pushUrlToRouter(actionUrl);
        },

        subTypeAction_saNoEmail({ actionUrl }) {
            this.pushUrlToRouter(actionUrl);
        },

        subTypeAction_newLead({ actionUrl }) {
            this.pushUrlToRouter(actionUrl);
        },

        subTypeAction_marketingTextReply({ actionUrl }) {
            this.pushUrlToRouter(actionUrl);
        },

        subTypeAction_contactImportComplete(notification) {
            const tagId = this.notificationUtils_getContentDetailsProperty(notification, 'tagId');
            const actionUrl = typeof notification.actionUrl === 'string' ? notification.actionUrl.trim() : '';

            if (tagId) {
                this.$router.push({
                    name: 'contacts.list',
                    params: {
                        id: 'all',
                    },
                    query: {
                        filter: `TAG=INCLUDES_ANY|${tagId}`,
                    },
                });
            } else if (actionUrl.length > 0) {
                this.goToUrl(actionUrl);
            } else {
                this.goToUrl('https://www.keap.com');
            }
        },

        subTypeAction_invoiceViewed(notification) {
            this.invoiceAction(notification);
        },

        subTypeAction_invoicePaid(notification) {
            this.invoiceAction(notification);
        },

        subTypeAction_invoiceCancelled(notification) {
            this.invoiceAction(notification);
        },

        invoiceAction(notification) {
            const invoiceId = this.notificationUtils_getContentDetailsProperty(notification, 'invoiceId');
            const actionUrl = typeof notification.actionUrl === 'string' ? notification.actionUrl.trim() : '';

            if (invoiceId) {
                this.$router.push({
                    name: 'invoices.invoice',
                    params: { invoiceId },
                });
            } else if (actionUrl.length > 0) {
                this.goToUrl(actionUrl);
            } else {
                this.goToUrl('https://www.keap.com');
            }
        },

        subTypeAction_externalTaskAction(notification) {
            this.routeToTask(notification);
        },

        subTypeAction_taskReminder(notification) {
            this.routeToTask(notification);
        },

        routeToTask(notification) {
            const taskUrl = notification.actionUrl.split('/');
            const taskId = taskUrl[taskUrl.length - 1];

            this.$router.push({ name: 'task', params: { id: taskId } });
        },

        subTypeAction_deactivateUserNotice() {
            this.$router.push({ name: 'settings.users' });
        },

        subTypeAction_quoteViewed(notification) {
            const quoteId = this.notificationUtils_getContentDetailsProperty(notification, 'quoteId');
            const actionUrl = typeof notification.actionUrl === 'string' ? notification.actionUrl.trim() : '';

            if (quoteId) {
                this.$router.push({
                    name: 'quotes.quote',
                    params: { quoteId },
                });
            } else if (actionUrl.length > 0) {
                this.goToUrl(actionUrl);
            } else {
                this.goToUrl('https://www.keap.com');
            }
        },

        subTypeAction_quoteAccepted(notification) {
            const quoteId = this.notificationUtils_getContentDetailsProperty(notification, 'quoteId');
            const actionUrl = typeof notification.actionUrl === 'string' ? notification.actionUrl.trim() : '';

            if (quoteId) {
                this.$router.push({
                    name: 'quotes.quote',
                    params: { quoteId },
                });
            } else if (actionUrl.length > 0) {
                this.goToUrl(actionUrl);
            } else {
                this.goToUrl('https://www.keap.com');
            }
        },

        subTypeAction_subscriptionFailed(notification) {
            const contactId = this.notificationUtils_getContentDetailsProperty(notification, 'contactId');
            const actionUrl = typeof notification.actionUrl === 'string' ? notification.actionUrl.trim() : '';

            if (contactId) {
                this.$router.push({
                    name: 'contact.record',
                    params: { contactId, tab: 'sales' },
                });
            } else if (actionUrl.length > 0) {
                this.goToUrl(actionUrl);
            } else {
                this.goToUrl('https://www.keap.com');
            }
        },

        goToUrl(url) {
            window.location = url;
        },

        pushUrlToRouter(actionUrl) {
            const { pathname } = new URL(actionUrl);

            this.$router.push(pathname);
        },
    },
};
