import amplitude from '@/analytics/amplitude';

export const trackSmsAccountProvisioningStart = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.SMS_BEGIN_MARKETING_NUMBER_PROVISIONING, {
        'Event Source': eventSource,
    });

    amplitude.v2.setUserProperties({
        [amplitude.v2.events.MARKETING_NUMBER_PROVISIONED]: false,
    });
};

export const trackSmsFeatureDiscovered = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.SMS_MARKETING_FEATURE_DISCOVERED, {
        'Event Source': eventSource,
    });
};

export const trackSmsAutomationCreated = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.SMS_MARKETING_AUTOMATION_CREATED, {
        'Event source': eventSource,
        Type: 'SMS',
    });
};

export const trackSmsAccountProvisioningComplete = (eventSource) => {
    amplitude.v2.logEvent(amplitude.v2.events.SMS_COMPLETE_MARKETING_NUMBER_PROVISIONING, {
        'Event Source': eventSource,
    });

    amplitude.v2.setUserProperties({
        [amplitude.v2.events.MARKETING_NUMBER_PROVISIONED]: true,
    });
};

export const trackSmsTemplateUsed = (eventSource, templateName) => {
    amplitude.v2.logEvent(amplitude.v2.events.SMS_TEMPLATE_USED, {
        'Event Source': eventSource,
        Template: templateName,
    });
};
