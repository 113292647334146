<template>
    <sms-broadcast-walkthrough-guide-container :position="beaconPosition" :show-guide="showStep">
        <template #reference>
            <slot />
        </template>

        <template #guide>
            <sms-broadcast-walkthrough-guide
                :position="guidePosition"
                :primary-label="primaryLabel"
                :secondary-label="$t('global.back')"
                @[secondaryEvent]="handleSecondary"
                @close="handleClose"
                @primary="handlePrimary"
            >
                <template #content>
                    {{ $t(`content.${step}`) }}
                </template>

                <template #info>
                    {{ info }}
                </template>
            </sms-broadcast-walkthrough-guide>
        </template>
    </sms-broadcast-walkthrough-guide-container>
</template>

<script>
import { mapState } from 'vuex';

import amplitude from '@/analytics/amplitude';
import SmsBroadcastWalkthroughGuide from '@/broadcasts/components/sms/walkthrough/SmsBroadcastWalkthroughGuide';
import SmsBroadcastWalkthroughGuideContainer from '@/broadcasts/components/sms/walkthrough/SmsBroadcastWalkthroughGuideContainer';

import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';

export const TOTAL_STEP_COUNT = 5;

export default {
    components: {
        SmsBroadcastWalkthroughGuide,
        SmsBroadcastWalkthroughGuideContainer,
    },

    props: {
        disabled: Boolean,
        step: Number,
    },

    computed: {
        ...mapState({
            walkthroughStepsComplete: ({ tutorials }) => tutorials.items[TUTORIAL_TYPES.TEXT_AUTOMATION_WALKTHROUGH_STEP],
        }),

        secondaryEvent() {
            return this.step === 1 || this.step === 6
                ? null
                : 'secondary';
        },

        info() {
            return this.$t('info', { current: this.step, maximum: TOTAL_STEP_COUNT });
        },

        beaconPosition() {
            return {
                1: 'right',
                2: 'top',
                3: 'top',
                4: 'right',
                5: 'left-start',
            }[this.step];
        },

        guidePosition() {
            return {
                1: 'bottom-end',
                2: 'right',
                3: 'right',
                4: 'right',
                5: 'bottom-start',
            }[this.step];
        },

        primaryLabel() {
            return this.step === TOTAL_STEP_COUNT
                ? this.$t('global.done')
                : this.$t('global.next');
        },

        showStep() {
            if (this.disabled) return false;

            // If step is 1, show if the walkthrough step is undefined
            // Otherwise, show if most recently completed step is 1 higher than number of completed steps
            return this.step === 1
                ? !this.walkthroughStepsComplete
                : this.step === this.walkthroughStepsComplete + 1;
        },
    },

    methods: {
        handleSecondary() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.TEXT_AUTOMATION_WALKTHROUGH_STEP,
                value: this.walkthroughStepsComplete - 1,
                forUser: true,
            });
        },

        handleClose() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.TEXT_AUTOMATION_WALKTHROUGH_STEP,
                value: -1,
                forUser: true,
            });

            this.trackAmplitudeEvent(this.step - 1);
        },

        handlePrimary() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.TEXT_AUTOMATION_WALKTHROUGH_STEP,
                value: this.step === TOTAL_STEP_COUNT ? -1 : this.step,
                forUser: true,
            });

            if (this.step === TOTAL_STEP_COUNT) {
                this.trackAmplitudeEvent(TOTAL_STEP_COUNT);
            }
        },

        trackAmplitudeEvent(steps) {
            amplitude.v2.logEvent(amplitude.v2.events.ONBOARDING_TOUR_COMPLETED, {
                'Event source': 'Easy Automation',
                Type: 'Text Automation',
                'Steps Completed': `${steps} of ${TOTAL_STEP_COUNT}`,
            });
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "content": {
            "1": "You have control over which phone number types (mobile, work, etc.) your text message will send to. All are turned on by default to help you reach your recipients. Be sure to review your options before sending.",
            "2": "Use merge fields to personalize your message. A merge field replaces a recipient’s unique data based on the fields you select.",
            "3": "Maximize your character count using the link shortener. Adding a link is a great way to get your recipients to take the next step.",
            "4": "You’ll only pay for text messages that were successfully sent and opted into by your recipients.",
            "5": "Text marketing laws require you to earn consent from your recipients with an opt-in message. Click here to edit and customize your opt-in message, as well as your no-reply message."
        },
        "info": "Tip {current} of {maximum}"
    }
}
</i18n>
