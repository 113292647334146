<template lang="html">
    <publish-page-section
        :title="$t('sectionTitle')"
        :edit-link="$t('sectionEdit')"
        @edit-section="$emit('edit-site-name')"
    >
        <p class="site-name" data-qa="display-name-section">
            {{ siteName }}
        </p>
    </publish-page-section>
</template>

<script>

import PublishPageSection from './PublishPageSection';

export default {
    components: { PublishPageSection },

    props: {
        siteName: String,
    },
};
</script>
<style lang="scss">
    .site-name {
        padding: 0;
        margin: 0;
        text-align: start;
        font-size: $font-size-med;
        line-height: $line-height-med;
    }
</style>
<i18n>
{
    "en-us": {
        "sectionTitle": "Page name",
        "sectionEdit": "Edit name"
    }
}
</i18n>
