<template lang="html">
    <nav :class="{ 'overlay-active': isOverlayActive }" data-qa="nav-header">
        <section class="logo-wrapper">
            <ds-icon-button
                name="hamburger"
                class="nav-toggle"
                data-qa="nav-toggle-button"
                @click.native="toggle"
            />

            <router-link
                :class="{ 'nav-logo': (keapMaxRebrand || !keapToggleEnabled) , 'nav-logo-infusionsoft': (!keapMaxRebrand && keapToggleEnabled) }"
                :to="{ name: 'dashboard' }"
                data-qa="logo-link"
            >
                <ds-logo v-if="(keapMaxRebrand || !keapToggleEnabled)" brand="keap" data-qa="keap-logo" />

                <ds-logo v-else brand="infusionsoft" data-qa="infusionsoft-logo" />
            </router-link>

            <div v-if="keapToggleEnabled" class="keap-toggle-wrapper">
                <nav-keap-infusionsoft-toggle data-qa="keap-infusionsoft-toggle" />
            </div>

            <div v-if="!isPaid" class="trial-wrapper">
                <i18n
                    v-if="!isNaN(daysLeft)"
                    path="daysLeft"
                    tag="span"
                >
                    <template #number>
                        <strong>{{ daysLeft }}</strong>
                    </template>

                    <template #days>
                        <span>{{ $tc('days', daysLeft === 1) }}</span>
                    </template>
                </i18n>

                <can-upgrade v-if="!chargebeeSubscriptionManagementEnabled && canUpgrade" :app-name="appName">
                    <ds-filled-button
                        class="small"
                        data-qa="upgrade-button"
                        @click="goToUpgrade"
                    >
                        {{ $t('buyNow') }}
                    </ds-filled-button>
                </can-upgrade>

                <ds-filled-button
                    v-if="chargebeeSubscriptionManagementEnabled && canUpgrade"
                    class="small"
                    data-qa="upgrade-button"
                    @click="goToBuyNowCart"
                >
                    {{ $t('buyNow') }}
                </ds-filled-button>
            </div>
        </section>

        <section class="search-wrapper">
            <global-search />

            <global-add-button />
        </section>

        <section class="nav-icons">
            <ds-icon-button
                v-if="hasHelpAndSupportFeature"
                name="help-circle"
                data-qa="support-button"
                :title="this.$t('tooltip.help')"
                @click="openSupportPanel"
            />

            <ds-icon-button
                data-qa="notifications-button"
                :title="this.$t('tooltip.notifications')"
                @click="openNotificationsPanel"
            >
                <ds-icon name="notification" :show-badge="hasNotifications" />
            </ds-icon-button>

            <nav-account-dropdown />
        </section>
    </nav>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import CanUpgrade from '@/account/components/CanUpgrade';
import GlobalAddButton from '@/nav/components/GlobalAddButton';
import GlobalSearch from '@/nav/components/GlobalSearch';
import NavAccountDropdown from '@/nav/components/NavAccountDropdown';
import NavKeapInfusionsoftToggle from '@/nav/components/NavKeapInfusionsoftToggle';
import redirectMixin from '@/shared/mixins/redirect.mixin';

import {
    FF_FLAGSHIP_FS_24167_FREE_TOGGLE_INFUSIONSOFT_KEAP,
    FF_KEAP_MAX_REBRAND,
    FF_KEAP_CHARGEBEE,
} from '@/shared/constants/featureFlag.constants';
import { EHAWK_NOT_PASSED_ERROR } from '@/ehawk/constants/ehawk.constants';

import { createCartToken } from '../../account/api/chargebee';

export default {
    components: {
        CanUpgrade,
        GlobalAddButton,
        GlobalSearch,
        NavAccountDropdown,
        NavKeapInfusionsoftToggle,
    },

    mixins: [redirectMixin],

    created() {
        this.$bus.$on('SHORTKEY_T', this.toggle);
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_T', this.toggle);
    },

    computed: {
        ...mapState({
            appName: ({ auth }) => auth.session.coreAppId,
            keapToggleEnabled: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_FS_24167_FREE_TOGGLE_INFUSIONSOFT_KEAP],
            keapMaxRebrand: ({ featureFlags }) => featureFlags[FF_KEAP_MAX_REBRAND],
            chargebeeSubscriptionManagementEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_CHARGEBEE],
        }),

        ...mapGetters({
            canUpgrade: 'auth/canUpgrade',
            daysLeft: 'auth/daysLeft',
            hasNotifications: 'notifications/hasNotifications',
            isOverlayActive: 'isOverlayActive',
            isPaid: 'auth/isPaid',
            hasHelpAndSupportFeature: 'auth/hasHelpAndSupportFeature',
        }),
    },

    methods: {
        toggle() {
            if (!this.isOverlayActive) {
                this.$store.commit('TOGGLE_NAV_OPEN');
                this.$store.commit('TOGGLE_MOBILE_NAV_OPEN');
            }
        },

        goToUpgrade() {
            this.$router.push({ name: 'upgrade' });
        },

        async goToBuyNowCart() {
            try {
                await this.$store.dispatch('ehawk/CHECK_EHAWK', { eventSource: 'advanced automation', modalTitle: this.$t('ehawkModalTitle'), modalBody: this.$t('ehawkModalBody') });
            } catch (e) {
                if (e.message !== EHAWK_NOT_PASSED_ERROR) {
                    this.$error({ message: this.$t('ehawk.errorMessage') });
                }

                return;
            }

            try {
                const cartToken = await createCartToken();

                this.redirect_url(`${process.env.VUE_APP_BUY_NOW_CART_URL}?token=${cartToken}`);
            } catch (e) {
                const upgradeError = this.$t('settings.account.upgradeError');

                this.$error({ message: upgradeError });
            }
        },

        openNotificationsPanel() {
            this.$store.commit('notifications/SET_NOTIFICATIONS_PANEL_OPEN', true);
        },

        openSupportPanel() {
            this.$store.commit('SET_SUPPORT_PANEL_OPEN', true);
        },
    },
};
</script>

<style lang="scss" scoped>
    nav {
        background-color: $nav-header-color-background;
        border-bottom: solid px-to-rem(1px) $color-gray-200;
        height: var(--top-nav-height);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include padding(0 $gp 0 $gp / 2);

        @media($small) {
            @include padding(0 $gp / 2);
        }

        &.overlay-active {
            z-index: 1;
        }
    }

    section {
        display: flex;
        align-items: center;

        @media($medium) {
            flex: 1;
        }
    }

    .logo-wrapper {
        @include margin-end(auto);
    }

    .nav-toggle {
        @include margin-end($gp);
    }

    .nav-logo {
        display: block;
        width: #{px-to-rem(60px)};

        .logo {
            position: relative;
            top: px-to-rem(2px);
        }
    }

    .nav-logo-infusionsoft {
        --logo-width: px-to-rem(143px);

        display: block;
        width: px-to-rem(143px);
        margin-top: $gp * 0.5;

        .logo {
            position: relative;
            top: px-to-rem(2px);
        }

        @media($small) {
            --logo-width: px-to-rem(100px);
            width: px-to-rem(100px);
        }
    }

    .nav-icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        @media($extra-small) {
            display: none;
        }
    }

    .search-wrapper {
        align-items: flex-start;
        align-self: flex-start;
        margin-top: px-to-rem(7px);

        @media($medium) {
            > *:first-child {
                @include margin-end($gp);
                flex: 1;
            }
        }
    }

    .nav-account-container {
        @include margin-start($gp / 2);
    }

    .trial-wrapper {
        display: flex;
        align-items: center;
        margin: 0 $gp;
        white-space: nowrap;
        font-weight: $font-weight-light;

        button {
            margin: 0 $gp / 2;
        }

        @media($small) {
            margin: 0 $gp / 2;

            span {
                display: none;
            }
        }
    }

    .keap-toggle-wrapper {
        @include margin-start($gp * 1.25);
    }
</style>

<i18n>
{
    "en-us": {
        "daysLeft": "{number} {days} left",
        "days": "day | days",
        "buyNow": "Buy now",
        "tooltip": {
            "help": "Help & support",
            "lcmPlaybook": "LCM playbook",
            "notifications": "Notification center"
        },
        "ehawkModalTitle": "We need some more information before we process your payment",
        "ehawkModalBody": "For security purposes we need to know a little more about your business before we accept payments from you. Share details with us below and someone will get right back to you."
    }
}
</i18n>
