<template>
    <div v-show="invoice.id" class="button-row">
        <invoice-quote-controls-actions-dropdown
            data-qa="actions-dropdown-invoice"
            :options="actionDropdownOptions"
            @select="handleActionSelect"
        />

        <ds-filled-button
            v-track="'Money - Invoice - clicked : Send'"
            class="send-next"
            data-qa="send-invoice"
            :disabled="isNextDisabled"
            :loading="loading"
            @click="send"
        >
            {{ $t('buttonLabels.sendNext') }}
        </ds-filled-button>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import InvoiceQuoteControlsActionsDropdown from '@/money/components/InvoiceQuoteControlsActionsDropdown';
import InvoiceSendingModal from '@/money/components/invoice/InvoiceSendingModal';
import AddPaymentModalContent from '@/money/components/AddPaymentModalContent';
import printMixin from '@/shared/mixins/print.mixin';
import orderUtils from '@/money/mixins/order-utils';

import { orderStatus } from '@/money/constants/order-status';
import { invoiceTotalDue } from '@/money/constants/invoice-total';
import { SENDING_METHODS } from '@/communication/communication.constants';

export default {
    components: { InvoiceQuoteControlsActionsDropdown },

    mixins: [printMixin, orderUtils],

    props: {
        handleSend: Boolean,
        invoiceSource: String,
    },

    data() {
        return {
            loading: false,
        };
    },

    mounted() {
        this.$bus.$on('INVOICE_ADD_PAYMENT', this.addPayment);
        this.$bus.$on('LOADING_ATTACHMENTS', this.loadingAttachments);
    },

    beforeDestroy() {
        this.$bus.$off('INVOICE_ADD_PAYMENT', this.addPayment);
        this.$bus.$off('LOADING_ATTACHMENTS', this.loadingAttachments);
    },

    watch: {
        value: {
            handler() {
                this.invoice = this.value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            invoice: ({ sales }) => sales.invoice,
            editing: ({ sales }) => sales.editingInvoice,
            appId: ({ auth }) => auth.session.coreAppId,
        }),

        readOnly() {
            return this.invoice && this.invoice.status === orderStatus.REFUNDED;
        },

        orderItems() {
            return this.invoice.orderItems;
        },

        hasEmailAddress() {
            return Boolean(this.invoice && this.invoice.contact && this.invoice.contact.email);
        },

        showAddPayment() {
            return invoiceTotalDue(this.invoice) > 0;
        },

        isNextDisabled() {
            return !this.invoice || !this.invoice.orderItems || !this.invoice.orderItems.length;
        },

        canDeleteInvoice() {
            return this.invoice && this.invoice.totalPaid <= 0;
        },

        actionDropdownOptions() {
            const options = [];

            if (!this.readOnly) {
                if (this.editing) {
                    options.push({
                        value: 'previewInvoice', label: this.$t('buttonLabels.preview'), icon: 'eye-show',
                    });
                } else {
                    options.push({ value: 'editInvoice', label: this.$t('buttonLabels.edit'), icon: 'edit' });
                }
            }

            if (this.invoice && this.invoice.xid) {
                options.push({ value: 'copyInvoiceLink', label: this.$t('buttonLabels.copy'), icon: 'copy' });
            }

            if (this.showAddPayment) {
                options.push({ value: 'addPayment', label: this.$t('buttonLabels.addPayment'), icon: 'add' });
            }

            options.push({ value: 'print_windowPrint', label: this.$t('buttonLabels.print'), icon: 'printer' });

            if (this.canDeleteInvoice) {
                options.push({
                    value: 'deleteInvoice', label: this.$t('buttonLabels.delete'), icon: 'trash-2', className: 'delete',
                });
            }

            return options;
        },

        isRecurring() {
            return this.invoice.orderItems?.some(({ recurringBilling }) => recurringBilling);
        },
    },

    methods: {
        deleteInvoice() {
            return this.$confirm({
                optionTitle: this.$t('deleteInvoice.title'),
                optionMessage: this.$t('deleteInvoice.message'),
                optionConfirmButtonLabel: this.$t('buttonLabels.delete'),
                destructive: true,
            })
                .then(() => {
                    const successMessage = this.$t('deleteInvoice.deletedNotification');
                    const errorMessage = this.$t('deleteInvoice.deleteError');

                    return this.$store.dispatch('sales/DELETE_INVOICE', this.invoice.id)
                        .then(() => {
                            this.$bus.$emit('LOAD_RECENT_INVOICES');
                            this.$bus.$emit('REFRESH_INVOICES');
                            this.closeModal();
                            this.$toast({ message: successMessage });
                        })
                        .catch(() => {
                            this.$error({ message: errorMessage });
                        });
                });
        },

        closeModal() {
            this.$bus.$emit('POP_NESTED_MODAL');
            this.$emit('delete');
        },

        loadingAttachments(status) {
            this.loading = status;
        },

        send() {
            this.orderUtils_addAttachmentsToEmail(this.invoice);

            if (this.handleSend) {
                this.$emit('send');
            } else {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: InvoiceSendingModal,
                    props: {
                        invoiceSource: this.invoiceSource,
                        isRecurring: this.isRecurring,
                        openAs: SENDING_METHODS.EMAIL,
                    },
                });
            }
        },

        handleActionSelect(actionValue) {
            // The values for the actions need to be strings
            // and not functions for e2e smoke testing purposes.
            const action = this[actionValue];

            if (typeof action === 'function') {
                action();
            }
        },

        previewInvoice() {
            this.$store.commit('sales/SET_INVOICE_EDITING', false);
        },

        editInvoice() {
            this.$store.commit('sales/SET_INVOICE_EDITING', true);
        },

        copyInvoiceLink() {
            const url = `${process.env.VUE_APP_PUBLIC_URL}/invoice/${this.invoice.xid}?app_id=${this.appId}`;

            const toastMessage = this.$t('linkCopied');

            return this.$copyText(url).then(() => {
                this.$track('Money - Invoice - clicked : Copy invoice link');
                this.$toast({ message: toastMessage });
            });
        },

        addPayment() {
            const data = {
                invoice: this.invoice,
                contactId: this.invoice.contactId,
            };

            if (this.handleSend) {
                this.$emit('handleAddPayment', data);
            } else {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: AddPaymentModalContent,
                    data,
                });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import '~@/money/styles/order-controls';
</style>

<i18n>
{
    "en-us": {
        "deleteInvoice":{
            "title": "Are you sure you want to delete this invoice?",
            "message": "Any payments made to this invoice will also be deleted.",
            "deletedNotification": "Deleted",
            "deleteError": "Error deleting invoice"
        },
        "buttonLabels":{
            "sendNext": "Next",
            "actions": "More actions",
            "preview": "Preview invoice",
            "edit": "Edit invoice",
            "addPayment": "Add a payment",
            "print": "Print invoice",
            "copy": "Copy invoice link",
            "delete": "Delete"
        },
        "linkCopied": "Invoice link copied to clipboard."
    }
}
</i18n>
