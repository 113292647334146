<template>
    <div class="configure">
        <configure-details
            :title="title"
            :subtitle="subtitle"
            :has-error="hasError"
            :error-message="errorMessage || $t('error')"
            :hide-edit="Boolean(errorMessage)"
            :trigger-action="triggerAction"
            @edit="$emit('edit', { trigger: value })"
            @remove="$emit('remove')"
        />

        <div class="button-row multiple-buttons">
            <manage-configuration
                :title="$t('manageIntegrations')"
                :manage-props="manageProps"
            />

            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';
import ManageConfiguration from '@/automations/components/configure/ManageConfiguration';
import { SHOPIFY_ANY_PRODUCT_PURCHASED, SHOPIFY_ANY_CART_ABANDONED } from '@/automations/constants/automations.constants';
import { BLENDR_TYPES } from '@/shared/constants/integrations.constants';

export default {
    components: {
        ConfigureDetails,
        ManageConfiguration,
    },

    props: {
        value: Object,
        hasError: Boolean,
        errorMessage: String,
        triggerAction: String,
    },

    data() {
        return {
            manageProps: {
                confirmTitle: this.$t('manage.title'),
                confirmMessage: this.$t('manage.message'),
                confirmButtonLabel: this.$t('manage.confirm'),
                route: { name: 'settings.integrations', params: { integration: BLENDR_TYPES.SHOPIFY } },
            },
        };
    },

    computed: {
        ...mapGetters({
            getProductById: 'settings/shopifyProductById',
        }),

        product() {
            return this.getProductById(this.value?.sourceId);
        },

        title() {
            if (this.value.type === SHOPIFY_ANY_PRODUCT_PURCHASED) {
                return this.$t('shopifyAnyProductPurchasedTitle');
            }

            if (this.value.type === SHOPIFY_ANY_CART_ABANDONED) {
                return this.$t('shopifyAnyCartAbandonedTitle');
            }

            return this.product?.title ?? this.$t('emptyProductLabel');
        },

        subtitle() {
            if (this.value.type === SHOPIFY_ANY_PRODUCT_PURCHASED) {
                return this.$t('shopifyAnyProductPurchasedDescription');
            }

            if (this.value.type === SHOPIFY_ANY_CART_ABANDONED) {
                return this.$t('shopifyAnyCartAbandonedDescription');
            }

            return this.product?.description ?? '';
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>

<i18n>
{
    "en-us": {
        "manageIntegrations": "Manage integrations",
        "shopifyAnyProductPurchasedTitle": "Any product is purchased",
        "shopifyAnyProductPurchasedDescription": "Any product is purchased",
        "shopifyAnyCartAbandonedTitle": "Any cart is abandoned",
        "shopifyAnyCartAbandonedDescription": "Any cart is abandoned",
        "emptyProductLabel": "Shopify product",
        "manage": {
            "title": "Navigate to your integrations list?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Manage integrations"
        },
        "error": "Please select a product"
    }
}
</i18n>
