<template lang="html">
    <header
        class="page-header"
        :class="{
            'only-mobile': onlyMobile,
            'hide-small': hideSmall,
            'remove-small-margin': removeSmallMargin,
            'small-header': isSmallTextHeader,
        }"
    >
        <ds-icon-button
            v-if="backRoute || showBack"
            name="arrow-left"
            class="arrow"
            :class="{ 'small-arrow': isSmallTextHeader }"
            @click="routeBack"
        />

        <ds-icon-button
            v-else
            name="hamburger"
            class="menu"
            @click="toggle"
        />

        <div :class="['title', { 'split-panel-view': splitPanelView }]">
            <h5 v-if="isSmallTextHeader" class="semibold">
                {{ pageHeader }}
            </h5>

            <h2 v-else :title="renderTitle">
                {{ renderTitle }}
            </h2>

            <slot name="header" />
        </div>

        <div class="actions button-row">
            <slot />
        </div>
    </header>
</template>

<script>
import { mapState } from 'vuex';
import { EDIT } from '@/customForms/customForm.constants';

export default {
    props: {
        backRoute: String,
        showBack: Boolean, // fallback to browser back button behavior
        title: String,
        hideSmall: Boolean,
        removeSmallMargin: Boolean,
        splitPanelView: {
            type: Boolean,
            default: false,
        },
        onlyMobile: Boolean,
        pageType: String,
    },

    computed: {
        ...mapState({
            routeTitle: ({ global }) => global.title,
        }),

        renderTitle() {
            return this.title || this.routeTitle;
        },

        isSmallTextHeader() {
            return this.title === this.$t('promoCode');
        },

        isEditPromoCode() {
            return this.pageType === EDIT;
        },

        pageHeader() {
            return this.isEditPromoCode ? this.$t('edit') : this.$t('create');
        },
    },

    methods: {
        routeBack() {
            if (this.backRoute?.indexOf('/') > -1) {
                this.$router.push(this.backRoute);
            } else if (this.backRoute) {
                this.$router.push({ name: this.backRoute });
            } else if (this.showBack) {
                this.$router.back();
            }
        },

        toggle() {
            this.$store.commit('TOGGLE_MOBILE_NAV_OPEN');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .page-header {
        display: flex;
        align-items: center;
        margin-bottom: var(--page-header-bottom-margin, $gp);
        flex-direction: row;
        width: 100%;

        @media($extra-small) {
            background-color: $color-paper;
            border-bottom: 1px solid $color-gray-200;
            min-height: $nav-height;
            width: calc(100% + #{$gp * 2});
            @include padding-start($gp * 0.5);
            @include padding-end($gp * 0.5);
            margin-left: -$gp;
            margin-right: -$gp;
            margin-top: -$gp;

            &.remove-small-margin {
                margin-bottom: 0;
            }
        }

        .title {
            flex: 1;
            display: flex;
            align-items: center;
            overflow: hidden;

            h2 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            @media($extra-small) {
                justify-content: space-between;

                h2 {
                    font-size: $font-size-lg;
                }
            }
        }

        .split-panel-view h2 {
            font-size: var(--split-panel-font-size, $font-size-huge);

            @media($extra-small) {
                font-size: $font-size-lg;
            }
        }
    }

    .menu,
    .arrow {
        @include margin-end($gp / 2);
    }

    .menu {
        @media($extra-small-up) {
            display: none;
        }
    }

    .actions {
        justify-self: flex-end;
    }

    .small-header {
        padding: $gp * 0.5;
    }

    .small-arrow {
        margin-right: 0;
    }
</style>

<i18n>
{
    "en-us": {
        "create": "Create promo code",
        "edit": "Edit promo code",
        "promoCode": "promocode"
    }
}
</i18n>
