<template>
    <email-form
        :action="send"
        :action-label="$t('global.send')"
        :attach-callback="handleOrderFileUpload"
        :button-text="buttonText"
        :button-tooltip="buttonTooltip"
        :delete-callback="deleteAttachmentFromInvoice"
        :from-list="usersWithNames"
        :initial-body="initialBody"
        :initial-subject="initialSubject"
        :selected-from="selectedUser"
        :should-reset="false"
        show-button
        single-recipient
        :to-list="toList"
        :use-email-input="useEmailInput"
    />
</template>

<script>
import { mapState } from 'vuex';

import EmailForm from '@/contacts/components/email/EmailForm';
import errorMixin from '@/shared/mixins/error.mixin';
import orderUtils from '@/money/mixins/order-utils';

import amplitude from '@/analytics/amplitude';
import { trackInvoiceSent } from '@/contacts/analytics';
import { getFromAddress, getUserFrom } from '@/contacts/utils/email-utils';
import { INVOICE } from '../../../contacts/contactEmailTypes';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { orderStatus } from '@/money/constants/order-status';

export default {
    components: {
        EmailForm,
    },

    mixins: [errorMixin, orderUtils],

    props: {
        title: String,
        invoiceSource: String,
        paymentReminder: Boolean,
    },

    computed: {
        ...mapState({
            attachments: ({ email }) => email.attachments,
            invoice: ({ sales }) => sales.invoice,
            invoiceFtu: ({ sales }) => sales.invoiceFtu,
            user: ({ auth }) => auth.user,
            users: ({ auth }) => auth.users,
        }),

        buttonTooltip() {
            return [
                this.tooltipMessage,
                this.$t('buttonTooltip.2'),
            ];
        },

        buttonText() {
            return this.invoice.status !== orderStatus.PAID
                ? this.$t('buttonText.invoice') : this.$t('buttonText.receipt');
        },

        fullName() {
            const { firstName, lastName } = this.invoice?.contact ?? {};

            return `${firstName || ''} ${lastName || ''}`.trim();
        },

        initialBody() {
            const { firstName } = this.invoice?.contact ?? {};

            if (firstName) {
                return this.$t(`${this.message}.body`, { firstName });
            }

            return this.$t(`${this.message}.bodyWithoutName`);
        },

        initialSubject() {
            const { firstName } = this.invoice?.contact ?? {};

            if (firstName) {
                return this.$t(`${this.message}.subject`, { firstName });
            }

            return this.$t(`${this.message}.subjectWithoutName`);
        },

        message() {
            return this.paymentReminder ? 'paymentReminder'
                : this.invoice.status === orderStatus.PAID ? 'sendReceipt' : 'sendInvoice';
        },

        nestedModal_removeInnerPadding() {
            return true;
        },

        nestedModal_title() {
            return this.title || this.$t('title', { fullName: this.fullName });
        },

        selectedUser() {
            return getUserFrom(this.user);
        },

        toList() {
            return this.invoice && this.invoice.contact
                ? [this.invoice.contact]
                : [];
        },

        tooltipMessage() {
            return this.invoice.status !== orderStatus.PAID
                ? this.$t('buttonTooltip.1.invoice') : this.$t('buttonTooltip.1.receipt');
        },

        useEmailInput() {
            return Boolean(this.invoice) && Boolean(this.invoice.contact) && this.invoice.contact.email == null;
        },

        usersWithNames() {
            return this.users.map((user) => getUserFrom(user));
        },
    },

    methods: {
        attachmentPayload(id) {
            return {
                invoiceId: this.invoice.id,
                attachmentId: id,
            };
        },

        async deleteAttachmentFromInvoice(id) {
            const message = this.$t('error.deleteAttachment');

            try {
                await this.$store.dispatch('sales/DELETE_INVOICE_FILE', this.attachmentPayload(id));
            } catch {
                this.$error({ message, bottom: true });
            }
        },

        async handleOrderFileUpload(file) {
            let id;
            const message = this.$t('error.attachToInvoice');

            try {
                const attachment = await this.$store.dispatch('contacts/ADD_CONTACT_FILE', { file });

                this.$store.commit('email/ADD_ATTACHMENT', attachment);
                ({ id } = attachment);
            } catch {
                this.$error({ message, bottom: true });
            }

            if (id) {
                try {
                    await this.$store.dispatch('sales/ADD_INVOICE_FILE', this.attachmentPayload(id));
                } catch (error) {
                    this.orderUtils_deleteAttachmentFromContact(id);
                    this.$store.commit('email/DELETE_ATTACHMENT', this.attachments.length - 1);
                    this.$error({ message, bottom: true });
                }
            }
        },

        async send({
            body,
            from,
            userId,
            recipients,
            signature,
            subject,
        }) {
            this.$track('Contacts - contact email - clicked : Send', { source: INVOICE });

            const buttonLink = await this.$store.dispatch('sales/GET_PUBLIC_INVOICE_URL', this.invoice.id);
            const [to] = recipients;
            const fromAddress = getFromAddress(from);
            const gdprErrorMessage = this.$t('error.sendError');

            try {
                await this.$store.dispatch('sales/SEND_INVOICE_EMAIL', {
                    body,
                    buttonLink,
                    buttonText: this.buttonText,
                    from,
                    userId,
                    fromAddress,
                    invoiceId: this.invoice.id,
                    recipient: to.id,
                    signature,
                    subject,
                    to: to.email,
                });

                trackInvoiceSent(this.invoiceSource);

                amplitude.v2.logEvent(amplitude.v2.events.INVOICE_SENT, { 'Send Type': 'Email' });

                this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'sendTestInvoice');

                this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                    key: TUTORIAL_TYPES.ONBOARDING.TOURS.MONEY,
                    value: true,
                    forUser: true,
                });

                this.$bus.$emit('EMAIL_SENT');
                this.$bus.$emit(this.invoiceFtu ? 'SUCCESSFUL_FTU_INVOICE_SENT' : 'SUCCESSFUL_INVOICE_SENT');
                this.$bus.$emit('POP_NESTED_MODAL', 2);
            } catch (error) {
                this.error_handleGdpr(error, gdprErrorMessage);
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "title": "Send invoice to {fullName}",
        "sendInvoice": {
            "subject": "Your invoice is ready, {firstName}",
            "subjectWithoutName": "Your invoice is ready",
            "body": "<p>Hi {firstName},</p><p>Here's the invoice. As always, thanks so much for your business! Please let me know if you have questions.</p>",
            "bodyWithoutName": "<p>Hi,</p><p>Here's the invoice. As always, thanks so much for your business! Please let me know if you have questions.</p>"
        },
        "paymentReminder": {
            "subject": "Just a friendly reminder...",
            "body": "<p>Hi {firstName},</p><p>I just wanted to reach out and check on the status of your payment.  You can access and pay the invoice by clicking the link below.  Please let me know if you have any questions.</p>",
            "bodyWithoutName": "<p>Hi,</p><p>I just wanted to reach out and check on the status of your payment.  You can access and pay the invoice by clicking the link below.  Please let me know if you have any questions.</p>"
        },
        "sendReceipt": {
            "subject": "Here's your payment receipt, {firstName}",
            "body": "<p>Hi {firstName},</p><p>Thanks so much for your business!  I've attached your payment receipt.  Please let me know if you have any questions.</p>",
            "bodyWithoutName": "<p>Hi,</p><p>Thanks so much for your business!  I've attached your payment receipt.  Please let me know if you have any questions.</p>"
        },
        "buttonText": {
            "invoice": "View invoice",
            "receipt": "View Receipt"
        },
        "buttonTooltip": {
            "1": {
                "invoice": "Preview your invoice.",
                "receipt": "Preview your receipt."
            },
            "2": "Note: This button text is not editable."
        },
        "error": {
            "sendError": "Something went wrong and we couldn't send your email. Please try again.",
            "attachToInvoice": "Sorry, something went wrong. Please try again.",
            "deleteAttachment": "There was an error while deleting your attachment. Please try again."
        }
    }
}
</i18n>
