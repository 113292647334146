const SLACK_CHANNELS = {
    COMMUNICATIONS_FEEDBACK: 'web-sms-feature-feedback',
    CONTACTS_FEEDBACK: 'pd-contacts-feedback',
    DIRECT_PURCHASE_LOGGING: 'direct-purchase-logging',
    DIRECT_PURCHASE_DEV: 'direct-purchase-dev',
    CUSTOM_DASHBOARD_FEEDBACK: 'custom-dashboard-feedback',
    COMPANIES_FEEDBACK: 'pd-companies-feedback',
    AUTOMATIONS_FEEDBACK: 'simple-automation-feedback',
    IN_APP_FEEDBACK: 'pd-keap-in-app-feedback',
    IN_APP_FEEDBACK_DEV: 'dev-keap-in-app-feedback',
    FREE_TRIAL_RECONSIDERATION: 'free-trial-reconsiderations',
};

const SLACK_MESSAGE_TYPES = {
    ERROR: '#FF0000',
    SUCCESS: '#32CD32',
};

export {
    SLACK_CHANNELS,
    SLACK_MESSAGE_TYPES,
};
