<template lang="html">
    <div v-if="showFilters" class="automation-filters">
        <p class="filter-header">
            {{ $t('filters') }}
        </p>

        <ds-chip
            v-for="filter in automationFilters"
            :key="filter.value"
            class="filter-chip"
        >
            <template #default>
                {{ getFilterDisplay(filter.value) }}
            </template>

            <template #trailing>
                <ds-icon
                    class="remove-icon"
                    name="x-circle-fill"
                    @click.native.stop="removeFilter(filter.value)"
                />
            </template>
        </ds-chip>

        <a v-if="automationFilters.length > 1" class="clear-filters" @click="clearFilters">
            <span>{{ $t('clear') }}</span>
        </a>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { AUTOMATION_FILTERS } from '@/automations/constants/automations.filter.constants';
import { FF_KEAP_SINGLE_PIPELINE, FF_KEAP_SIMPLE_AUTOMATION_FILTER } from '@/shared/constants/featureFlag.constants';
import { FORM_TYPE_CONTACTS } from '@/customForms/customForm.constants';

export default {
    data() {
        return {
            loaded: false,
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapState({
            automationFilters: ({ automations }) => automations.automationFilters,
            publicForms: ({ customForms }) => customForms.forms[FORM_TYPE_CONTACTS],
            internalForms: ({ smartForms }) => smartForms.forms,
            pipelineList: ({ pipeline }) => pipeline.pipelineList,
            singlePipelineEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SINGLE_PIPELINE],
            filterAutomationEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_FILTER],
            users: ({ auth }) => auth.users,
        }),

        ...mapGetters({
            stageById: 'pipeline/stageById',
            getPipelineById: 'pipeline/getPipelineById',
            hasMultiplePipelinesFeature: 'auth/hasMultiplePipelinesFeature',
            hasFormsFeature: 'auth/hasFormsFeature',
            publicFormById: 'customForms/formById',
            internalFormById: 'smartForms/getFormById',
            hasAppointmentType: 'calendar/hasApptType',
            getAppointmentTypeById: 'calendar/getAppointmentTypeById',
        }),

        showFilters() {
            return this.filterAutomationEnabled && this.automationFilters?.length;
        },
    },

    methods: {
        removeFilter(value) {
            const index = this.automationFilters.findIndex((filter) => filter.value === value);

            this.$store.commit('automations/REMOVE_AUTOMATION_FILTER', index);
        },

        clearFilters() {
            this.$store.commit('automations/SET_AUTOMATION_FILTERS', []);
        },

        async init() {
            if (this.filterAutomationEnabled) {
                const promises = [];

                if (!this.pipelineList?.length && (this.hasMultiplePipelinesFeature || this.singlePipelineEnabled)) {
                    promises.push(this.$store.dispatch('pipeline/LOAD_PIPELINE_LIST'));
                }

                if (!this.publicForms.length) {
                    promises.push(this.$store.dispatch('customForms/LOAD_FORMS'));
                }

                if (!this.internalForms.length && this.hasFormsFeature) {
                    promises.push(this.$store.dispatch('smartForms/LOAD_SMART_FORMS'));
                }

                if (!this.hasAppointmentType) {
                    promises.push(this.$store.dispatch('calendar/LOAD_APPT_TYPES'));
                }

                await Promise.all(promises);
            }

            this.loaded = true;
        },

        getFilterDisplay(value) {
            if (value.indexOf(AUTOMATION_FILTERS.ALL_STAGES) === 0) {
                const pipelineId = value.substring(AUTOMATION_FILTERS.ALL_STAGES.length);
                const pipeline = this.getPipelineById(pipelineId);

                return this.$t('filterLabels.pipelineName', { name: pipeline?.name });
            }

            if (value.indexOf(AUTOMATION_FILTERS.STAGE) === 0) {
                const stageId = value.substring(AUTOMATION_FILTERS.STAGE.length);
                const stage = this.stageById(stageId);

                return this.$t('filterLabels.stageName', { name: stage?.name });
            }

            if (value.indexOf(AUTOMATION_FILTERS.FORM) === 0) {
                const formId = value.substring(AUTOMATION_FILTERS.FORM.length);
                const form = this.publicFormById(formId) || this.internalFormById(formId);

                return this.$t('filterLabels.form', { name: form?.title });
            }

            if (value.indexOf(AUTOMATION_FILTERS.USER_APPOINTMENT) === 0) {
                const casUserId = value.substring(AUTOMATION_FILTERS.USER_APPOINTMENT.length);
                const userName = this.users.find(({ casId }) => +casId === +casUserId)?.fullName ?? '';

                return this.$t('filterLabels.appointmentUser', { name: userName });
            }

            if (value.indexOf(AUTOMATION_FILTERS.APPOINTMENT) === 0) {
                const appointmentTypeId = value.substring(AUTOMATION_FILTERS.APPOINTMENT.length);
                const appointmentType = this.getAppointmentTypeById(appointmentTypeId);

                return this.$t('filterLabels.appointment', { name: appointmentType?.name });
            }

            switch (value) {
            case AUTOMATION_FILTERS.ALL_INTERNAL_FORMS:
                return this.$t('filterLabels.internalForms');
            case AUTOMATION_FILTERS.ANY_INTERNAL_FORM_SUBMITTED:
                return this.$t('filterLabels.anyInternalForm');
            case AUTOMATION_FILTERS.ALL_PUBLIC_FORMS:
                return this.$t('filterLabels.publicForms');
            case AUTOMATION_FILTERS.ANY_PUBLIC_FORM_SUBMITTED:
                return this.$t('filterLabels.anyPublicForm');
            case AUTOMATION_FILTERS.ANY_APPOINTMENT:
                return this.$t('filterLabels.anyAppointment');
            default:
                return this.$te(`filterLabels.${value}`) ? this.$t(`filterLabels.${value}`) : value;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .automation-filters {
        border-bottom: 1px solid $color-gray-200;
        @include padding($gp / 2 $gp / 2 $gp / 2 $gp);

        .filter-header {
            margin-bottom: 0;
            color: $color-ink-800;
            font-size: $font-size-xs;
        }
    }

    .clear-filters {
        padding: $gp / 2;
        line-height: $line-height-lg;

        span {
            white-space: nowrap;
        }
    }

    .filter-chip {
        @include margin($gp / 4 $gp / 2 $gp / 4 0);
    }

    .remove-icon {
        cursor: pointer;
    }
</style>

<i18n>
{
    "en-us": {
        "filters": "Filters",
        "clear": "Clear filters",
        "filterLabels": {
            "pipelines": "Pipeline",
            "pipelineName": "Pipeline: {name}",
            "stageName": "Stage: {name}",
            "appointments": "Appointments",
            "appointmentUser": "Appointments: Any of {name}'s types",
            "anyAppointment": "Any appointment",
            "appointment": "Appointment: {name}",
            "forms": "Forms",
            "internalForms": "Internal forms",
            "anyInternalForm": "Any internal form",
            "publicForms": "Public forms",
            "anyPublicForm": "Any public form",
            "form": "Form: {name}",
            "money": "Money",
            "tags": "Tags"
        }
    }
}
</i18n>
