import { render, staticRenderFns } from "./ContactAddressesEdit.vue?vue&type=template&id=3ed312c3&scoped=true&lang=html&"
import script from "./ContactAddressesEdit.vue?vue&type=script&lang=js&"
export * from "./ContactAddressesEdit.vue?vue&type=script&lang=js&"
import style0 from "./ContactAddressesEdit.vue?vue&type=style&index=0&id=3ed312c3&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ed312c3",
  null
  
)

/* custom blocks */
import block0 from "./ContactAddressesEdit.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports