export default {
    paymentAccounts: [],
    paypalSettings: {},
    products: [],
    taxes: [],
    promoCodes: [],
    promoCode: {},
    paymentReference: { rate: 0, label: '', value: '' },
    infusionPaySetupInfo: { industryOptions: [], adminEmails: [] },
};
