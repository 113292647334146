<template>
    <div class="configure">
        <configure-details
            :title="title"
            :subtitle="subtitle"
            :has-error="hasError"
            :error-message="errorMessage || $t('error')"
            :type="value.type"
            :hide-edit="hideEdit"
            :trigger-action="triggerAction"
            @edit="(payload) => $emit('edit', { trigger: value })"
            @remove="(payload) => $emit('remove', payload)"
        />

        <div :class="['button-row', { 'multiple-buttons': showBookingLink }]">
            <manage-configuration
                v-if="showBookingLink"
                :title="$t('goToBooking')"
                data-qa="booking-link"
                @manage="goToBookingLink"
            />

            <ds-text-button @click="$emit('done')">
                {{ $t('global.done') }}
            </ds-text-button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import redirectMixin from '@/shared/mixins/redirect.mixin';
import ConfigureDetails from '@/automations/components/configure/ConfigureDetails';
import ManageConfiguration from '@/automations/components/configure/ManageConfiguration';
import {
    APPOINTMENT_SCHEDULED,
    ANY_APPOINTMENT_SCHEDULED,
    USER_APPOINTMENT_SCHEDULED,
    APPOINTMENT_CANCELLED,
    USER_APPOINTMENT_CANCELLED,
    TRIGGER_ACTION_TYPES,
} from '@/automations/constants/automations.constants';

export default {
    components: {
        ConfigureDetails,
        ManageConfiguration,
    },

    mixins: [redirectMixin],

    props: {
        value: Object,
        hasError: Boolean,
        errorMessage: String,
        triggerAction: String,
    },

    computed: {
        ...mapGetters({
            getAppointmentTypeById: 'calendar/getAppointmentTypeById',
            bookingUrl: 'calendar/bookingUrl',
        }),

        ...mapState({
            users: ({ auth }) => auth.users,
        }),

        hideEdit() {
            return !this.isScheduledType && this.triggerAction === TRIGGER_ACTION_TYPES.REMOVE;
        },

        appointmentType() {
            return this.getAppointmentTypeById(this.value?.sourceId);
        },

        isSingleAppointment() {
            return [APPOINTMENT_SCHEDULED, APPOINTMENT_CANCELLED].includes(this.value.type);
        },

        isUserAppointment() {
            return [USER_APPOINTMENT_SCHEDULED, USER_APPOINTMENT_CANCELLED].includes(this.value.type);
        },

        isScheduledType() {
            return [APPOINTMENT_SCHEDULED, ANY_APPOINTMENT_SCHEDULED, USER_APPOINTMENT_SCHEDULED].includes(this.value.type);
        },

        showBookingLink() {
            return this.isSingleAppointment && this.appointmentType;
        },

        title() {
            if (this.isSingleAppointment) {
                return this.appointmentType?.name ?? this.$t(this.isScheduledType ? 'scheduledEmptyLabel' : 'cancelledEmptyLabel');
            }

            if (this.isUserAppointment) {
                const userName = this.users.find(({ casId }) => casId === this.value.sourceId)?.fullName ?? this.$t('invalidUser');

                return this.isScheduledType
                    ? this.$t('appointmentScheduledForUser', { userName })
                    : this.$t('appointmentCancelledForUser', { userName });
            }

            return this.hideEdit ? this.$t('cancelledEmptyLabel') : this.$t(this.value.name);
        },

        subtitle() {
            if (this.isSingleAppointment) {
                return this.value.sourceId && this.appointmentType?.durationMinutes
                    ? this.$tc(
                        'global.time.interval.abbr.minute',
                        this.appointmentType?.durationMinutes,
                        { count: this.appointmentType?.durationMinutes },
                    )
                    : '';
            }

            return '';
        },
    },

    methods: {
        goToBookingLink() {
            return this.redirect_url_new_tab(this.bookingUrl(this.appointmentType));
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>

<i18n>
{
    "en-us": {
        "goToBooking": "Go to booking link",
        "scheduledEmptyLabel": "Appointment is scheduled",
        "cancelledEmptyLabel": "Appointment is cancelled",
        "appointmentScheduledForUser": "Any of {userName}'s appointments are scheduled",
        "appointmentCancelledForUser": "Any of {userName}'s appointments are cancelled",
        "invalidUser": "Invalid user",
        "error": "Please select an appointment type"
    }
}
</i18n>
