<template>
    <controlled-modal
        show-close
        is-open
        class="rename-modal"
        dialog
        size="med"
        :title="$t('saveAsTemplate')"
        @close="$emit('close')"
    >
        <form data-qa="choose-template-name-form" @submit.prevent="saveTemplate">
            <ds-input-field
                ref="nameInput"
                v-model="name"
                data-qa="template-name"
                name="name"
                required
                :invalid="!isUniqueName"
                :label="$t('name')"
                :submitted="!isUniqueName"
            >
                <template #error>
                    <span v-if="!isUniqueName">{{ $t('name.error.unique') }}</span>
                    <span v-if="!name">{{ $t('name.error.required') }}</span>
                </template>
            </ds-input-field>

            <ds-filled-button
                type="submit"
                data-qa="template-name-button"
                :loading="isSaving"
                :disabled="!isUniqueName || !name"
            >
                {{ $t('save') }}
            </ds-filled-button>

            <div v-if="isError" class="alert-container">
                <ds-inline-alert type="critical" leading-icon>
                    {{ $t('server.error') }}
                </ds-inline-alert>
            </div>
        </form>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';

const STATUS_UNSUBMITTED = 'UNSUBMITTED';
const STATUS_SAVING = 'SAVING';
const STATUS_ERROR = 'ERROR';

export default {
    components: {
        ControlledModal,
    },

    props: {
        savedSitesName: Array,
    },

    data() {
        return {
            name: '',
            status: STATUS_UNSUBMITTED,
        };
    },

    mounted() {
        this.focusNameInput();
    },

    computed: {
        isSaving() {
            return this.status === STATUS_SAVING;
        },

        isError() {
            return this.status === STATUS_ERROR;
        },

        isUniqueName() {
            return !this.savedSitesName || !this.savedSitesName.some((name) => name === this.name);
        },
    },

    methods: {
        saveTemplate() {
            this.status = STATUS_SAVING;
            this.$emit('save-template', this.name);
        },

        focusNameInput() {
            const { nameInput } = this.$refs;

            if (nameInput) {
                this.$nextTick(() => {
                    nameInput.input_focus();
                });
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "name": "Template name",
        "saveAsTemplate": "Save as a template",
        "save": "Save",
        "name.error.required": "This field is required to save your template",
        "name.error.unique": "This template name is already taken, please choose a unique name.",
        "server.error": "There was an error saving your template."
    }
}
</i18n>
