/**
 * Creates a convenience utility for finding cells within an unlayer document
 * @param design
 */
export function unlayerDesign(design = {}) {
    return {
        design,
        /**
         * Flattens all cells within the entire document.
         * @return {[UnlayerCell]}
         */
        allCells() {
            return (this.design.body?.rows ?? []).flatMap((r, rowIndex) => {
                return (r.columns ?? []).flatMap((c, columnIndex) => {
                    return c.contents.map((content, cellIndex) => UnlayerCell({
                        cellType: content.slug ?? content.type,
                        content,
                        rowIndex,
                        columnIndex,
                        cellIndex,
                    }));
                });
            });
        },

        /**
         * Finds all content cells that have a specified type.  Type is defined by the field [cellType], which is a
         * normalized form using type/slug
         *
         * @param toFind
         * @return {[UnlayerCell]}
         */
        findByType(toFind) {
            const cells = this.allCells();

            return cells.filter(({ cellType }) => toFind === cellType);
        },
    };
}

/**
 * @typedef UnlayerCell
 *
 * @property {String} cellType The normalized type name of content at this cell.
 * @property {number} rowIndex The row this cell occurs in
 * @property {number} columnIndex The column this cell appears in
 * @property {number} cellIndex The cell within that column
 * @property {object} content The content and settings for this cell
 */
// eslint-disable-next-line no-unused-vars
function UnlayerCell({
    cellType, rowIndex, columnIndex, cellIndex, content,
} = {}) {
    return {
        cellType, rowIndex, columnIndex, cellIndex, content,
    };
}
