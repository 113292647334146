<template>
    <ds-card
        class="unlayer-template-card"
    >
        <header v-if="showHeader" class="template-card-header">
            <div class="title">
                {{ template.name }}
            </div>

            <div v-if="$slots.menu" ref="menu" class="menu">
                <slot name="menu" />
            </div>
        </header>
        <div v-if="loading" class="placeholder">
            <ds-placeholder
                :rows="[
                    { height: '2rem', boxes: [3, 1] },
                    { height: '3.5rem', boxes: [1] },
                    { height: '3.5rem', boxes: [1] },
                ]"
            />
        </div>

        <img
            class="template-thumbnail"
            :src="template.previewUrl"
            @load="loading = false"
        />

        <div v-if="!loading || forceLoaded" class="template-details">
            <ds-filled-button class="preview-button" data-qa="select-button" @click="$emit('select')">
                <ds-icon name="edit" />
                <span>{{ $t('select') }}</span>
            </ds-filled-button>
            <ds-filled-button
                class="preview-button preview"
                data-qa="preview-button"
                :loading="isPreviewing"
                @click="$emit('preview')"
            >
                <ds-icon name="external-link" />
                <span>{{ $t('preview') }}</span>
            </ds-filled-button>
        </div>
    </ds-card>
</template>

<script>
export default {
    props: {
        template: {
            type: Object,
            required: true,
        },
        isPreviewing: {
            type: Boolean,
            required: true,
        },
        forceLoaded: Boolean,
        showHeader: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            loading: true,
        };
    },
};
</script>

<style lang="scss" scoped>
    .unlayer-template-card {
        padding: 0;
        overflow: hidden;
        position: relative;

        &:hover {
            .template-details {
                display: flex;
            }
        }
    }

    .template-details {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba($color-paper, .7);
    }

    .preview-button {
        display: flex;
        width: px-to-rem(120);
        margin-bottom: $gp / 2;
        flex-direction: row;
        align-items: center;
        --icon-size: #{$gp};

        span {
            @include margin-start($gp / 2);
        }
    }

    .template-card-header {
        background: $color-paper;
        color: $color-ink-900;
        border-bottom: 1px solid $color-ink-100;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: px-to-rem(48);
        padding: px-to-rem(12);
        z-index: 3;
        display: flex;
        align-items: center;
    }

    .template-gallery-html-card:not(:hover) .template-details {
        display: none;
    }

    .title {
        font-weight: 700;
        line-height: $line-height-sm;
        flex: 1;
        max-width: calc(100% - #{$gp * 2});
    }

    .placeholder {
        padding: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "select": "Select",
        "preview": "Preview"
    }
}
</i18n>
