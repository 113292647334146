<template>
    <div :class="['stop-card', { 'configured': removeTriggerSelected, active, inactive }]" @click="$emit('selected')">
        <header class="header">
            <ds-icon class="stop-icon" name="octagon" />

            <span class="header-text">{{ $t('header') }}</span>
        </header>

        <div v-if="!removeTriggerSelected" class="description">
            <p class="description-title">
                {{ $t('description') }}
            </p>

            <ds-icon-button class="add-button" name="add-fill" />
        </div>

        <template v-else>
            <div v-for="(trigger, i) in removeTriggers" :key="i" class="description">
                <invalid-icon v-if="isInvalid(trigger, i)" class="invalid-icon" />

                <automation-display-name
                    :class="['description-title', { 'alert-text': isInvalid(trigger, i) }]"
                    :value="trigger"
                    trigger-action="REMOVE"
                />

                <div v-if="i < removeTriggers.length - 1" class="divider" />
            </div>
        </template>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ERROR_LEVELS } from '@/automations/constants/automations.constants';
import { isValidTrigger } from '@/automations/utils/automations.utils';
import InvalidIcon from '@/shared/components/Email/InvalidIcon';
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';

export default {
    components: {
        AutomationDisplayName,
        InvalidIcon,
    },

    props: {
        removeTriggers: {
            type: Array,
            default: () => ([]),
        },
        active: Boolean,
        inactive: Boolean,
        showErrors: Boolean,
    },

    computed: {
        ...mapGetters({
            automationWithFrontendErrors: 'automations/automationWithFrontendErrors',
        }),

        removeTriggerSelected() {
            return this.removeTriggers.length;
        },
    },

    methods: {
        isInvalid(trigger, i) {
            return Boolean(this.showErrors
                && (!isValidTrigger(trigger) || this.hasFrontendError(i)));
        },

        hasFrontendError(i) {
            return this.automationWithFrontendErrors?.removeTriggers?.[i]?.messages?.some(({ severity }) => severity === ERROR_LEVELS.ERROR);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/whenThenCard';

    .stop-card {
        margin-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "header": "Stop",
        "description": "...when this occurs (optional)"
    }
}
</i18n>
