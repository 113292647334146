const authorizeError = {
    ender: ')',
    splitPoint: ' (',
};

const paypalSplitPoint = ': ';

export default {
    methods: {
        paymentFailureUtils_displayMessage(message = '') {
            if (message.endsWith(authorizeError.ender)) {
                return message.split(authorizeError.splitPoint)[0];
            }

            if (message.includes(paypalSplitPoint)) {
                return message.split(paypalSplitPoint)[1];
            }

            if (message.includes('processing your card')) {
                return this.$t('billing.paymentError.stripeDefault');
            }

            return message === '' || message.includes('_')
                ? this.$t('billing.paymentError.default')
                : message;
        },
    },
};
