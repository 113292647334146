import axios from 'axios';
import gql from 'graphql-tag';
import Vue from 'vue';
import startCase from 'lodash.startcase';
import { IMPORT_TYPES } from '@/import/import.constants';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';

import { PROVIDERS } from '@/shared/constants/integrations.constants';

export default {
    UPLOAD_FILE(context, payload) {
        return uploadContactImportFile(context, payload);
    },

    PREVIEW_CONTACT_IMPORT(context, payload) {
        return previewContactImport(context, payload);
    },

    IMPORT_CONTACTS(context, payload) {
        return importContacts(context, payload);
    },

    BULK_ADD_CONTACTS(context, payload) {
        return bulkAddContacts(context, payload);
    },

    CHECK_IMPORT_IN_PROGRESS(context, payload) {
        return checkImportInProgress(context, payload);
    },

    LOAD_GOOGLE_CONTACTS(context, payload) {
        return loadGoogleContacts(context, payload);
    },

    LOAD_MICROSOFT_CONTACTS(context) {
        return loadMicrosoftContacts(context);
    },

    TRACK_RESULTS(context, payload) {
        return trackResults(context, payload);
    },

    GET_CONTACT_LIMIT(context, payload) {
        return getContactLimit(context, payload);
    },

    LOAD_QBO_INTEGRATION(context, payload) {
        return loadQboIntegration(context, payload);
    },

    SAVE_QBO_INTEGRATION(context, payload) {
        return saveQboIntegration(context, payload);
    },

    RESYNC_QBO_INTEGRATION(context, payload) {
        return resyncQboIntegration(context, payload);
    },

    DELETE_QBO_INTEGRATION(context, payload) {
        return deleteQboIntegration(context, payload);
    },

    CREATE_MICROSOFT_INTEGRATION(context, payload) {
        return createMicrosoftIntegration(context, payload);
    },

    CREATE_MICROSOFT_IMPORT(context, payload) {
        return createMicrosoftImport(context, payload);
    },

    CHECK_MICROSOFT_INTEGRATION(context) {
        return checkMicrosoftIntegration(context);
    },

    DELETE_MICROSOFT_INTEGRATION(context) {
        return deleteMicrosoftIntegration(context);
    },
};

const uploadContactImportFile = ({ commit }, payload) => {
    const isExcelFile = Boolean(payload && payload.excelFileName);

    const file = isExcelFile
        ? payload.csvFile
        : payload;

    const name = isExcelFile
        ? payload.excelFileName
        : payload.name;

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onloadend = function (e) {
            if (e.target.error || !e.target.result) {
                reject(e);
            } else {
                const base64String = e.target.result.split(',').pop();

                axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/file/files`, {
                    file_name: name,
                    file_data: base64String,
                    is_public: false,
                    file_association: 'USER',
                }).then(({ data: { id } }) => {
                    const fileArray = atob(base64String).split('\n');
                    const trimmedFileArray = fileArray.filter((line) => line.replace(/,/g, '').trim().length > 0);

                    const contactCount = trimmedFileArray.length > 0
                        ? trimmedFileArray.length - 1
                        : 0;

                    const fileName = name || '';

                    const firstTwoRows = trimmedFileArray && trimmedFileArray.length > 1
                        ? trimmedFileArray.slice(0, 2)
                        : [];

                    const keys = firstTwoRows && firstTwoRows.length > 1
                        ? firstTwoRows[0].split(',')
                        : [];

                    const values = firstTwoRows && firstTwoRows.length > 1
                        ? firstTwoRows[1].split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/)
                        : [];

                    const previewData = keys.reduce((accumulator, key, index) => ({
                        ...accumulator,
                        [key.replace(/\s/g, '').replace(/["']/g, '')]: values[index],
                    }), {});

                    commit('SET_IMPORT_IN_PROGRESS', { contactCount, fileName, previewData });
                    commit('SET_MAP_TO_OPTIONS', []);
                    commit('SET_IMPORT_MAPPINGS', []);
                    commit('SET_FILE_ID', id);
                    resolve(id);
                }).catch(reject);
            }
        };

        reader.readAsDataURL(file);
    });
};

const trackResults = ({ state }, success) => {
    const result = success ? 'success' : 'error';

    let eventName = '';
    let eventProperties = {};

    if (success) {
        const { numAdded, numUpdated, numSkipped } = state.results;

        eventName = `Contacts - Import - ${result} : contacts imported`;
        eventProperties = {
            importCount: state.count,
            numAdded,
            numSkipped,
            numUpdated,
        };
    } else {
        const { code, reason } = state.error;

        eventName = `Contacts - Import - ${result} : ${reason}`;
        eventProperties = {
            importCount: state.count,
            stage: state.activeStage,
            code,
        };
    }

    amplitude.v1.track(eventName, eventProperties);
};

const previewContactImport = ({ state, commit }, fileboxId) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/import/preview/${fileboxId}?importSource=${state.importSource}`)
        .then(({
            data: {
                mapToOptions,
                mappings,
                customFields,
                count,
            },
        }) => {
            commit('SET_IMPORT_COUNT', count);
            commit('SET_MAP_TO_OPTIONS', mapToOptions);
            commit('SET_IMPORT_MAPPINGS', mappings);
            commit('SET_CUSTOM_FIELDS', customFields);
        });
};

const importContacts = ({ state, commit }) => {
    const {
        importMappings,
        count,
        singleOptIn,
        additionalTags,
    } = state;

    commit('SET_STATE_IMPORTING', true);

    const mappings = importMappings.map((importMapping) => {
        return importMapping.fieldToMapTo && importMapping.fieldToMapTo.startsWith('customField.')
            ? {
                fieldToImport: importMapping.fieldToImport,
                customFieldToMapTo: importMapping.fieldToMapTo,
            }
            : importMapping;
    });

    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/importAsync/file`, {
            mappings,
            singleOptIn,
            count,
            tagIds: additionalTags,
            actionUrl: `${process.env.VUE_APP_PUBLIC_URL}/contacts/list/all?filter=TAG%3DINCLUDES_ANY%7C`,
        }).then(({
            data: {
                tagId,
                numAdded,
                numUpdated,
                numSkipped,
                resultList,
            },
        }) => {
            commit('SET_STATE_IMPORTING', false);
            commit('SET_STATE_IMPORTED', true);
            commit('SET_RESULT_TAGID', tagId);
            commit('SET_IMPORT_RESULTS', {
                numAdded,
                numUpdated,
                numSkipped,
                resultList,
            });

            resolve(true);
        }).catch((error) => {
            let code = 'UNKNOWN';
            const { message, response } = error;

            if (response || error.code) {
                code = response ? response.status : error.code;
            }

            commit('SET_STATE_IMPORTING', false);
            commit('SET_STATE_ERROR', {
                failed: true,
                code,
                reason: message,
            });

            reject(error);
        });
    });
};

const bulkAddContacts = async ({ commit }, contacts) => {
    commit('SET_STATE_IMPORTING', true);

    let mutation;
    const { newContacts, optIns } = contacts.reduce((acc, contact) => {
        const { optInReason, ...newContact } = contact;

        acc.newContacts.push(newContact);

        if (newContact.email) {
            acc.optIns.push({
                email: newContact.email,
                optInReason,
            });
        }

        return acc;
    }, { newContacts: [], optIns: [] });

    const variables = {
        contacts: newContacts.map((contact) => {
            return {
                givenName: contact.givenName,
                familyName: contact.familyName,
                email: contact.email,
                phone: (
                    contact.phone
                        ? {
                            ...contact.phone,
                            type: contact.phone.type
                                ? startCase(contact.phone.type.toLowerCase())
                                : null,
                        }
                        : null
                ),
            };
        }),
        checkForDuplicateBy: 'email',
    };

    if (optIns.length > 0) {
        variables.optIns = optIns;
        mutation = gql`
            mutation addContactsMutation($contacts: [ContactInput]!, $checkForDuplicateBy: String, $optIns: [EmailOptInEmailRequestInput]!) {
                addContacts(contacts: $contacts, checkForDuplicateBy: $checkForDuplicateBy) {
                    id
                    email
                    phone {
                        type
                        value
                    }
                }
                optInEmails(optIns: $optIns)
            }
        `;
    } else {
        mutation = gql`
            mutation addContactsMutation($contacts: [ContactInput]!, $checkForDuplicateBy: String) {
                addContacts(contacts: $contacts, checkForDuplicateBy: $checkForDuplicateBy) {
                    id
                    email
                    phone {
                        type
                        value
                    }
                }
            }
        `;
    }

    try {
        const { data: { addContacts } } = await Vue.prototype.$graphql.mutate({
            mutation,
            variables,
            fetchPolicy: 'no-cache',
        });

        commit('SET_STATE_IMPORTING', false);
        commit('SET_STATE_IMPORTED', true);
        commit('SET_IMPORT_RESULTS', {
            numAdded: contacts.length,
        });

        return addContacts;
    } catch (error) {
        commit('SET_STATE_IMPORTING', false);
        commit('SET_STATE_ERROR', {
            failed: true,
        });

        throw error;
    }
};

const checkImportInProgress = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/importAsync/inProgress`)
            .then(({ data }) => {
                resolve(data);
            })
            .catch(reject);
    });
};

const loadGoogleContacts = async ({ commit }, accountId) => {
    commit('SET_STATE_IMPORTING', true);

    return Vue.prototype.$graphql.query({
        query: gql`
            query googleContacts($googleAccountId: String) {
                googleContacts(googleAccountId: $googleAccountId) {
                    googleContacts {
                        givenName,
                        familyName,
                        email,
                        phone {
                            type,
                            value,
                        },
                    }
                }
            }
        `,
        variables: {
            googleAccountId: accountId,
        },
        fetchPolicy: 'no-cache',
    })
        .then(({ data: { googleContacts } }) => {
            commit('SET_STATE_IMPORTING', false);
            commit('SET_STATE_READY', true);

            if (!googleContacts.googleContacts) {
                commit('SET_CONTACT_LIST', []);
            } else {
                googleContacts.googleContacts.reverse();
                commit('SET_CONTACT_LIST', googleContacts.googleContacts.filter(({ email, phone }) => email || phone));
            }
        })
        .catch(() => {
            commit('SET_STATE_IMPORTING', false);
            commit('SET_STATE_ERROR', {});
        });
};

const loadMicrosoftContacts = async ({ commit, state }) => {
    return new Promise((resolve, reject) => {
        return Vue.prototype.$graphql.query({
            query: gql`
                query getContacts($options: ProviderContactOptions) {
                    getContacts(options: $options) {
                        contacts {id, givenName, familyName, email}
                        nextPageToken
                    }
                }
            `,
            variables: {
                options: { provider: IMPORT_TYPES.MSO, pageToken: state.microsoftPageToken },
            },
            fetchPolicy: 'no-cache',
        })
            .then(({ data: { getContacts: { contacts, nextPageToken } } }) => {
                if (!contacts) {
                    commit('SET_CONTACT_LIST', []);
                } else {
                    commit('APPEND_CONTACT_LIST', contacts.filter(({ email, phone }) => email || phone));
                }
                commit('SET_PAGE_TOKEN', nextPageToken);
                resolve();
            })
            .catch(reject);
    });
};

const getContactLimit = ({ commit }) => {
    return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/limit`)
            .then(({ data }) => {
                commit('SET_CONTACT_LIMIT', data.contactCountSoftThreshold);
                resolve();
            })
            .catch(reject);
    });
};

const loadQboIntegration = ({ commit }, realmId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_QBO_API_URL}/connected/${realmId}`)
            .then(({ data }) => {
                commit('UPDATE_QBO_CONNECTION', data);
                resolve();
            })
            .catch(reject);
    });
};

const saveQboIntegration = ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_QBO_API_URL}/connect/sync`, payload)
            .then(() => {
                intercom.logEvent(intercom.events.CONTACT_IMPORT_COMPLETED);

                amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORTED, {
                    'Event Source': IMPORT_TYPES.QBO,
                });

                commit('UPDATE_QBO_CONNECTION', true);

                dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'connectQbo', { root: true });

                resolve();
            })
            .catch(reject);
    });
};

const resyncQboIntegration = ({ commit }, realmId) => {
    return axios.post(`${process.env.VUE_APP_QBO_API_URL}/resync/${realmId}`)
        .then(() => {
            commit('UPDATE_QBO_CONNECTION', true);
        });
};

const deleteQboIntegration = ({ commit }, realmId) => {
    return axios.delete(`${process.env.VUE_APP_QBO_API_URL}/disconnect/${realmId}`)
        .then(() => {
            commit('UPDATE_QBO_CONNECTION', false);
        });
};

const createMicrosoftIntegration = ({ commit, dispatch }, accountId) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createIntegration($integrationOptions: IntegrationOptions) {
                createIntegration(options: $integrationOptions) {
                    status
                }
            }
        `,
        variables: {
            integrationOptions: { provider: IMPORT_TYPES.MSO, providerAccountId: accountId },
        },
        fetchPolicy: 'no-cache',
    })
        .then(() => {
            dispatch('settings/LOAD_INTEGRATIONS', null, { root: true });

            commit('SET_STATE_IMPORTING', true);
            commit('SET_PAGE_TOKEN', '');

            dispatch('LOAD_MICROSOFT_CONTACTS').then(() => {
                commit('SET_STATE_IMPORTING', false);
                commit('SET_STATE_READY', true);
            }).catch(() => {
                commit('SET_STATE_ERROR', {});
            });
        });
};

const createMicrosoftImport = (_, options) => {
    options.provider = IMPORT_TYPES.MSO;

    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createImport($options: ImportOptions) {
                createImport(options: $options) {
                    status
                }
            }
        `,
        variables: {
            options,
        },
        fetchPolicy: 'no-cache',
    })
        .then(({ data: { createImport } }) => {
            return createImport;
        });
};

const checkMicrosoftIntegration = ({ commit, dispatch }) => {
    commit('SET_STATE_IMPORTING', true);

    return new Promise((resolve, reject) => {
        dispatch('LOAD_MICROSOFT_CONTACTS')
            .then(() => {
                commit('SET_STATE_IMPORTING', false);
                commit('SET_STATE_READY', true);
                resolve();
            })
            .catch(reject);
    });
};

const deleteMicrosoftIntegration = ({ dispatch }) => {
    return Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteIntegration($provider: String) {
                deleteIntegration(provider: $provider) {
                    status
                }
            }
        `,
        variables: {
            provider: PROVIDERS.MICROSOFT,
        },
    })
        .then(() => {
            dispatch('settings/LOAD_INTEGRATIONS', null, { root: true });
        });
};
