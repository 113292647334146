<template lang="html">
    <div class="integration-import">
        <template v-if="!isLoading">
            <div class="integration-group">
                <svg class="mailchimp-svg">
                    <use xlink:href="#mailchimp" />
                </svg>

                <strong>{{ $t('sync.title') }}</strong>
                <div class="mailchimp-status">
                    {{ $t('sync.status') }}
                </div>
            </div>

            <div class="mailchimp-button-group">
                <div class="mailchimp-import-button">
                    <ds-filled-button
                        data-qa="start-sync"
                        @click="startSync"
                    >
                        {{ $t('sync.action') }}
                    </ds-filled-button>
                </div>

                <div class="mailchimp-settings-button">
                    <ds-text-button
                        data-qa="edit-settings"
                        @click="manageIntegration"
                    >
                        {{ $t('edit.action') }}
                    </ds-text-button>
                </div>

                <div class="mailchimp-sync-description">
                    <small>{{ $t('sync.description') }}</small>
                </div>
            </div>

            <blendr-modal
                data-qa="blendr-modal"
                :title="$t('mailchimpModalTitle')"
                :url="integrationUrl"
                :is-open="openModal"
                @setupComplete="$emit('setup-complete')"
                @setupCanceled="handleBlendrModalClosed"
                @setupFailed="handleBlendrModalClosed"
                @settingsComplete="handleBlendrModalClosed"
                @settingsCanceled="handleBlendrModalClosed"
                @settingsFailed="handleSettingsFailed"
                @close="handleBlendrModalClosed"
            />
        </template>

        <div v-else>
            <ds-placeholder class="avatar-placeholder" :rows="avatarPlaceholder" />

            <ds-placeholder class="integration-connect-placeholder" :rows="placeholder" />
        </div>
    </div>
</template>

<script>
import '@/import/images/providers/mailchimp.svg';
import { mapState } from 'vuex';
import BlendrModal from '@/settings/components/integrations/BlendrModal';
import amplitude from '@/analytics/amplitude';
import {
    BLENDR_MAILCHIMP_START_IMPORT_INSTANCE_NAME,
} from '@/shared/constants/integrations.constants';

export default {
    components: {
        BlendrModal,
    },

    props: {
        integrationBlend: Object,
        isLoading: Boolean,
    },

    data() {
        return {
            openModal: false,
            integrationUrl: '',
            loadingBlend: false,
            avatarPlaceholder: [{ boxes: [1] }],
            placeholder: [
                { height: '1rem', boxes: [1] },
                { height: '3rem', boxes: [1] },
                { height: '1rem', boxes: [1] },
            ],
        };
    },

    computed: {
        ...mapState({
            appId: ({ auth }) => auth.session.coreAppId,
            user: ({ auth }) => auth.user,
        }),
    },

    methods: {
        manageIntegration() {
            this.integrationUrl = this.integrationBlend.settingsUrl;
            this.openModal = true;
        },

        async startSync() {
            let error = false;

            const startImportTemplateInstanceGuid = this.integrationBlend
                ?.subBlends
                ?.find((sb) => sb.name?.includes(BLENDR_MAILCHIMP_START_IMPORT_INSTANCE_NAME))
                ?.guid;

            try {
                this.$bus.$emit('POP_NESTED_MODAL', 2);
                amplitude.v2.logEvent(amplitude.v2.events.INTEGRATION_SELECTED, {
                    'Event Source': 'Contacts Import',
                    Clicked: 'Contact Sync',
                    Integration: this.integrationBlend.name,
                });

                // Support customers who might be on the older version of the integration that isn't a bundle template
                // The import should still work, but they won't receive a notification when it completes
                const response = await this.$store.dispatch('settings/RUN_BLENDR_TEMPLATE_INSTANCE', {
                    guid: startImportTemplateInstanceGuid ?? this.integrationBlend.guid,
                    async: true,
                    inputs: {
                        userId: this.user.id,
                        globalUserId: this.user.casId,
                    },
                });

                error = response == null;
            } catch {
                error = true;
            }

            if (error) {
                this.$error({ message: this.$t('importErrorMessage') });
            } else {
                this.$toast({ message: this.$t('importStarted') });
            }
        },

        handleBlendrModalClosed() {
            this.openModal = false;
        },

        handleSettingsFailed() {
            this.openModal = false;
            this.$store.dispatch('settings/LOAD_BLENDR_DATASOURCES');
        },
    },
};
</script>

<style lang="scss" scoped>
    .integration-import {
        @include prevent-select;
        background: $color-paper;
        position: relative;
        border: 1px solid $color-gray-200;
        border-radius: $border-radius;
        padding: $gp;
        overflow-x: hidden;
        box-shadow: $elevation-z1;
        margin: $gp * 2 0;
        width: 100%;
    }

    .integration-connect-placeholder {
        margin-top: $gp;
    }

    .mailchimp-svg {
        width: px-to-rem(40);
        height: px-to-rem(40);
        grid-column: 1 / 2;
        grid-row: 1 / 3;
    }

    .mailchimp-status {
        grid-column: 2 / 3;
        color: $color-text-positive;
    }

    .mailchimp-import-button {
        grid-column: 1 / 2;
        margin-bottom: $gp;
    }

    .mailchimp-settings-button {
        grid-column: 2 / 3;
        margin-bottom: $gp;
        margin-left: 0.5 * $gp;
    }

    .mailchimp-sync-description {
        grid-column: 1 / 4;
    }

    .integration-group {
        display: grid;
        grid-template-columns: repeat(30, auto);
        text-align: left;
        align-items: center;
        margin-bottom: $gp;
    }

    .mailchimp-button-group {
        display: grid;
        grid-template-columns: repeat(10, auto);
        text-align: left;
        align-items: center;
    }

    .mailchimp-sync {
        text-align: left;
    }

    .mailchimp-add-audience {
        padding-left: $gp;
    }

    .mailchimp-import-description {
        padding: $gp 0;
        font-size: $font-size-xs;
        font-weight: $font-weight-light;
    }

    .avatar-placeholder {
        height: px-to-rem(50px);
        --placeholder-width: #{px-to-rem(50px)};
        --placeholder-border-radius: #{px-to-rem(50px)};
        margin: 0 auto 2 * $gp auto;
    }

    [dir=rtl] {
        .mailchimp-sync {
            border-right: 0;
            border-left: 1px solid $color-gray-200;
            padding-left: $gp;
        }

        .mailchimp-add-audience {
            padding-right: $gp;
        }
    }
</style>
<i18n>
{
    "en-us": {
        "sync" :{
            "title": "Mailchimp Import",
            "description": "Any contacts already in Keap will be updated with Mailchimp data to avoid duplicates. We merge contacts with the same email addresses.",
            "action": "Start Import",
            "status": "Connected"
        },
        "add" :{
            "title": "Add new audiences",
            "description": "Do you have new MailChimp audiences to import? Add them now.",
            "action": "Add your audience"
        },
        "edit" :{
            "action": "Edit Settings"
        },
        "importStarted": "Your contacts from Mailchimp are being imported. You'll be notified when the import is finished.",
        "importErrorMessage": "There was an error importing your contacts from Mailchimp.",
        "mailchimpModalTitle": "Connect to Mailchimp"
    }
}
</i18n>
