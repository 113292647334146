export const MARKETING_SITE_TEMPLATE_TYPES = {
    site: 'site',
    unlayer: 'unlayer',
    blank: 'blank',
    global: 'global',
};

/**
 * Represents the source template for creating a marketing site.  We retain the sourceTemplate because it will be used
 * during the creation to map links, IDs, etc.
 * @param sourceTemplate
 * @param templateType
 * @return {{sourceTemplate: object, templateType: string, name: string}}
 * @constructor
 */
export function MarketingSiteTemplateSource({ sourceTemplate, templateType }) {
    const { name } = sourceTemplate;

    return {
        name,
        sourceTemplate,
        templateType,
    };
}
