<template lang="html">
    <provider-card
        :class="{ 'disable-click': isConnected }"
        :image="`${baseUrl}images/providers/qbo.svg?v=3`"
        :title="conditionalDescription"
        :description="$t('qbo.title')"
        @click.native="handleClick"
    />
</template>

<script>
import { mapState } from 'vuex';

import amplitude from '@/analytics/amplitude';
import ProviderCard from '@/shared/components/ProviderCard';
import authMixin from '@/shared/mixins/auth.mixin';
import { IMPORT_TYPES, AMPLITUDE_IMPORT_EVENT_SOURCES } from '@/import/import.constants';

export default {
    components: {
        ProviderCard,
    },

    mixins: [authMixin],

    props: {
        // This prop is just to enable testing without shallowMount hitting the endpoint during unit tests
        loadOnMount: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            loading: true,
            baseUrl: process.env.BASE_URL,
        };
    },

    created() {
        if (this.loadOnMount) {
            this.load();
        }
    },

    computed: {
        ...mapState({
            appId: ({ auth }) => auth.session.coreAppId,
            qboIntegration: ({ contactImport }) => contactImport.qboIntegration,
        }),

        isLoaded() {
            return this.qboIntegration.isLoaded;
        },

        isConnected() {
            return this.qboIntegration.isConnected;
        },

        conditionalDescription() {
            if (!this.loading) {
                if (this.isLoaded && this.isConnected) {
                    return this.$t('qbo.connected');
                }

                return this.$t('qbo.invoices');
            }

            return '';
        },
    },

    methods: {
        load() {
            if (!this.isLoaded) {
                this.loadQboIntegration();
            } else {
                this.loading = false;
            }
        },

        loadQboIntegration() {
            const message = this.$t('errors.integration.INTUIT.api');

            return this.$store.dispatch('contactImport/LOAD_QBO_INTEGRATION', this.appId)
                .then(() => {
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.$error({ message });
                });
        },

        handleClick() {
            if (!this.isConnected) {
                this.$track('Contact import - clicked: Sync contacts from QBO');
                this.auth_authorizeQboContacts()
                    .then(() => {
                        amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORT_ACCOUNT_CONNECTED, {
                            'Event Source': AMPLITUDE_IMPORT_EVENT_SOURCES[IMPORT_TYPES.QBO],
                        });
                    });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .disable-click {
        cursor: default;
        --card-background-color: #{$color-gray-200};
    }

    img {
        max-width: px-to-rem(160px);
        max-height: px-to-rem(40px);
        height: auto;
    }

</style>

<i18n>
{
    "en-us": {
        "qbo": {
            "title": "QuickBooks online",
            "invoices": "Sync your contacts and invoices",
            "connected": "Connected"
        }
    }
}
</i18n>
