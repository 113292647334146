import axios from 'axios';
import moment from 'moment';
import firebase from 'firebase/app';
import 'firebase/database';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import sentry from '@/analytics/sentry';
import { processRawAppts } from '@/appointments/utils/appt.util';

import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { INTEGRATION_STATUS } from '@/appointments/appointments.constants';
import { PROVIDERS } from '@/shared/constants/integrations.constants';

export default {
    LOAD_APPOINTMENT_FILTER(context) {
        return loadAppointmentFilter(context);
    },

    SAVE_APPOINTMENT_VIEW_FILTER(context, filter) {
        return saveAppointmentViewFilter(context, filter);
    },

    SAVE_CALENDAR_FILTER(context, filter) {
        return saveCalendarFilter(context, filter);
    },

    LOAD_CALENDAR_APPTS(context, payload) {
        return loadCalendarAppts(context, payload);
    },

    RELOAD_CALENDAR_APPTS(context) {
        return reloadCalendarAppts(context);
    },

    LOAD_APPT_TYPES(context, pageToken) {
        return loadApptTypes(context, pageToken);
    },

    LOAD_PROVIDER_INTEGRATIONS(context) {
        return loadProviders(context);
    },

    SAVE_PROVIDER_INTEGRATION(context, payload) {
        return saveProviderIntegration(context, payload);
    },

    DELETE_PROVIDER_INTEGRATION(context, payload) {
        return deleteProviderIntegration(context, payload);
    },

    LOAD_PROVIDER_CALENDARS(context) {
        return loadCalendars(context);
    },

    ADD_APPT_TYPE(context, payload) {
        if (payload.data.id) {
            return saveApptType(context, payload);
        }

        return createApptType(context, payload.data);
    },

    REMOVE_APPT_TYPE(context, id) {
        return removeApptType(context, id);
    },

    INITIALIZE_STATE(context) {
        return initializeState(context);
    },

    CANCEL_APPOINTMENT(context, cancelParams) {
        return cancelAppointment(context, cancelParams);
    },

    UPDATE_TEMP_APPT_TYPE(context, payload) {
        return updateTempApptType(context, payload);
    },

    TWILIO_TOKEN(context, payload) {
        return twilioToken(context, payload);
    },

    REDIRECT_AFTER_CONNECT(context, payload) {
        return redirectAfterConnect(context, payload);
    },
};

const loadCalendarAppts = ({ state, commit }, payload) => {
    return new Promise(async (resolve, reject) => {
        commit('SET_APPTS_LOADED', false);

        try {
            const result = payload.useBriefApi ? await getApptsForRangeBrief(state, payload.dateRange) : await getApptsForRange(state, payload.dateRange);
            const processedAppts = processRawAppts(result.appts, payload.dateRange);

            commit('SET_CALENDAR_APPTS', result.appts);
            commit('SET_PROCESSED_APPTS', processedAppts);
            commit('SET_APPTS_RANGE', payload.dateRange);
            commit('SET_APPTS_LOADED', true);

            if (result.errors.length) {
                reject(result.errors);
            } else {
                resolve(result.appts);
            }
        } catch (e) {
            reject(e);
        }
    });
};

const reloadCalendarAppts = ({ state, commit }) => {
    if (state.apptsRange) {
        loadCalendarAppts({ state, commit }, { dateRange: state.apptsRange, useBriefApi: false });
    }
};

const getApptsForRange = (state, range) => {
    const start = moment(range.startDate).startOf('day');
    const diff = moment(range.endDate).diff(start, 'days') + 1;

    // note - currently the appts api only supports getting appts for a single calendar
    // so separate requests must be made for now :/
    const calendars = [];

    state.providers.forEach((provider) => {
        if (provider.calendars && provider.isLoaded && provider.status === INTEGRATION_STATUS.READY) {
            provider.calendars.forEach((calendar) => {
                calendars.push(calendar);
            });
        }
    });

    const promises = [];

    calendars.forEach((calendar) => {
        const promise = new Promise(async (internalResolve, internalReject) => {
            const calendarId = encodeURIComponent(calendar.id);

            const callConfig = {
                params: {
                    startFilter: start.format(),
                    interval: `${diff}D`,
                    pageSize: 1000,
                },
            };

            const getAppts = (callParams) => {
                return axios.get(`${process.env.VUE_APP_APPTS_API_URL}/calendars/${calendarId}/appointments`, callParams)
                    .then((res) => {
                        return res;
                    })
                    .catch(() => {
                        internalReject(new Error(calendar.title));
                    });
            };

            const result = await getAppts(callConfig);

            if (result && !result.data.nextPageToken) {
                internalResolve(result);

                return;
            }

            if (result) {
                let { nextPageToken } = result.data;

                while (nextPageToken) {
                    callConfig.params.pageToken = nextPageToken;

                    // note: await in loop is necessary here since the next call depends on the result
                    // of this call

                    /* eslint-disable-next-line no-await-in-loop */
                    const nextPage = await getAppts(callConfig);

                    if (nextPage) {
                        result.data.appointments = result.data.appointments.concat(nextPage.data.appointments);

                        const token = nextPage.data.nextPageToken;

                        if (token && nextPage.data.appointments.length) {
                            nextPageToken = token;
                        } else {
                            nextPageToken = null;
                        }
                    }
                }
            }

            internalResolve(result);
        })
            .catch((error) => {
                return error;
            });

        promises.push(promise);
    });

    return new Promise((resolve, reject) => {
        return Promise.all(promises)
            .then((res) => {
                const appts = [];
                const errors = [];

                res.forEach((result, index) => {
                    if (!(result instanceof Error)) {
                        if (result.data && result.data.appointments) {
                            result.data.appointments.forEach((appt) => {
                                const apptCopy = { ...appt };

                                apptCopy.calendarId = calendars[index].id;
                                apptCopy.calendarTz = calendars[index].timeZone;

                                if (!apptCopy.start.timeZone) {
                                    apptCopy.start.timeZone = calendars[index].timeZone;
                                }

                                if (!apptCopy.end.timeZone) {
                                    apptCopy.end.timeZone = calendars[index].timeZone;
                                }

                                appts.push(apptCopy);
                            });
                        }
                    } else {
                        errors.push(result.message);
                    }
                });

                resolve({
                    appts,
                    errors,
                });
            })
            .catch(() => {
                reject();
            });
    });
};

const fetchBriefAppointments = (calendar, callParams, appointmentList = []) => {
    return axios.get(`${process.env.VUE_APP_APPTS_API_URL}/calendars/${encodeURIComponent(calendar.id)}/appointmentsBrief`, callParams)
        .then(async ({ data: { appointments, nextPageToken } }) => {
            if (!appointments) {
                return { appointments: [] };
            }

            appointments.forEach((appointment) => {
                appointment.calendarId = calendar.id;
                appointment.calendarTz = calendar.timeZone;

                if (!appointment.start.timeZone) {
                    appointment.start.timeZone = calendar.timeZone;
                }

                if (!appointment.end.timeZone) {
                    appointment.end.timeZone = calendar.timeZone;
                }

                appointmentList.push(appointment);
            });

            if (nextPageToken) {
                callParams.params.pageToken = nextPageToken;

                return fetchBriefAppointments(calendar, callParams, appointmentList);
            }

            return { appointments: appointmentList };
        }).catch((error) => {
            return { appointments: appointmentList, error };
        });
};


const getApptsForRangeBrief = (state, range) => {
    const start = moment(range.startDate).startOf('day');
    const diff = moment(range.endDate).diff(start, 'days') + 1;

    // note - currently the appts api only supports getting appts for a single calendar
    // so separate requests must be made for now :/

    const calendarList = state.providers
        .filter(({ isLoaded, status, calendars }) => isLoaded && calendars && status !== INTEGRATION_STATUS.DISCONNECTED)
        .reduce((calendars, provider) => calendars.concat(provider.calendars), []);

    const promises = [];

    calendarList.forEach((calendar) => {
        const callConfig = {
            params: {
                startFilter: start.format(),
                interval: `${diff}D`,
                pageSize: 1000,
            },
        };

        promises.push(fetchBriefAppointments(calendar, callConfig));
    });

    return Promise.all(promises)
        .then((results) => {
            return results.reduce((response, { appointments, error }) => {
                response.appts.push(...appointments);

                if (error) {
                    response.error.push(error);
                }

                return response;
            }, { appts: [], errors: [] });
        })
        .catch((error) => {
            sentry.captureException(error);
        });
};

const loadApptTypes = ({
    commit,
    dispatch,
    getters,
    state: { providers },
}, pageToken) => {
    const { totalAppointmentTypes } = getters;

    const provider = providers.find(({ status }) => status === INTEGRATION_STATUS.READY);

    const providerName = provider?.name ?? null;

    const pageTokenQuery = pageToken
        ? `&pageToken=${pageToken}`
        : '';

    return new Promise((resolve, reject) => {
        if (!providerName) {
            return resolve();
        }

        return axios.get(`${process.env.VUE_APP_APPTS_API_URL}/appointmentTypes?provider=${providerName}${pageTokenQuery}`)
            .then(({ data }) => {
                if (totalAppointmentTypes !== data.appointmentTypes?.length || data.appointmentTypes?.length === 0) {
                    intercom.updateUserProperties({
                        total_appointment_types: data.appointmentTypes.length,
                    });
                }

                commit('SET_APPT_TYPES', { types: data.appointmentTypes, nextPage: pageToken });
                commit('SET_APPT_TYPES_LOADED', true);

                if (data.nextPageToken) {
                    dispatch('LOAD_APPT_TYPES', data.nextPageToken);
                }

                resolve(data);
            })
            .catch(reject);
    });
};

const createApptType = async ({
    rootState,
    commit,
    dispatch,
    getters,
}, payload) => {
    const { connectedProviders } = getters;

    payload.calendarProvider = connectedProviders[0].name;
    payload.calendarProviderAccountId = connectedProviders[0].email;
    payload.timeZone = moment.tz.guess();

    const res = await axios.post(`${process.env.VUE_APP_APPTS_API_URL}/appointmentTypes`, payload);

    await dispatch(
        'tutorials/UPDATE_TUTORIAL_ITEM',
        {
            key: TUTORIAL_TYPES.ONBOARDING.TOURS.APPOINTMENTS,
            value: true,
            forUser: true,
        },
        { root: true },
    );

    if (rootState?.onboarding?.activeAppointmentTypeTask) {
        await dispatch('onboarding/COMPLETE_ONBOARDING_TASK', rootState.onboarding.activeAppointmentTypeTask, { root: true });
        dispatch('onboarding/SET_ACTIVE_APPOINTMENT_TYPE_TASK', '', { root: true });
    }

    amplitude.v2.logEvent(amplitude.v2.events.APPOINTMENT_TYPE_CREATED);

    commit('ADD_APPT_TYPE', res.data);

    return res.data;
};

const saveApptType = ({ commit, getters }, { data, changedFields }) => {
    const { connectedProviders } = getters;

    data.calendarProvider = connectedProviders[0].name;
    data.calendarProviderAccountId = connectedProviders[0].email;
    data.timeZone = moment.tz.guess();

    const params = {
        updateMask: changedFields.join(','),
    };

    return new Promise((resolve, reject) => {
        return axios.patch(`${process.env.VUE_APP_APPTS_API_URL}/appointmentTypes/${data.id}`, data, { params })
            .then((res) => {
                commit('SET_APPT_TYPE', res.data);
                resolve(res.data);
            })
            .catch(reject);
    });
};

const removeApptType = ({ commit }, id) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_APPTS_API_URL}/appointmentTypes/${id}`)
            .then(() => {
                commit('REMOVE_APPT_TYPE', id);
                resolve();
            })
            .catch(reject);
    });
};

const loadProviders = ({ rootState, commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_APPTS_API_URL}/integrations`, {
            params: {
                userId: rootState.auth.user.casId,
                pageSize: 100,
            },
        })
            .then(({ data }) => {
                const { integrations } = data;
                const browserTimezone = moment.tz.guess();

                integrations.forEach(({
                    id,
                    calendarProvider,
                    calendarProviderAccountId,
                    status,
                    timeZone,
                    zoomAccountId,
                    firstTimeUse,
                    zoomStatus,
                }) => {
                    commit('UPDATE_PROVIDER', {
                        name: calendarProvider,
                        status,
                        isLoaded: true,
                        email: calendarProviderAccountId,
                        integrationId: id,
                        timeZone,
                        firstTimeUse,
                        zoomAccountId,
                        zoomStatus,
                    });

                    if (!timeZone) {
                        const payload = {
                            userId: rootState.auth.user.casId,
                            timeZone: browserTimezone,
                        };

                        axios.post(`${process.env.VUE_APP_APPTS_API_URL}/integrations`, payload)
                            .catch(() => {});
                    }
                });

                commit('SET_PROVIDERS_LOADED');
                resolve(integrations);
            })
            .catch(reject);
    });
};

const saveProviderIntegration = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_APPTS_API_URL}/integrations`, {
            ...payload,
        })
            .then(({ data }) => {
                const provider = {
                    status: data.status,
                    name: data.calendarProvider,
                    isLoaded: true,
                    email: data.calendarProviderAccountId,
                    integrationId: data.id,
                    timeZone: data.timeZone,
                    firstTimeUse: data.firstTimeUse,
                    zoomAccountId: data.zoomAccountId,
                    zoomStatus: data.zoomStatus,
                };

                commit('UPDATE_PROVIDER', provider);
                commit('SET_APPT_TYPES_LOADED', false);

                intercom.logEvent(intercom.events.CALENDAR_CONNECTED);

                resolve();
            })
            .catch(reject);
    });
};

const deleteProviderIntegration = ({ commit }, provider) => {
    const { integrationId, name } = provider;

    const queryString = name === PROVIDERS.ZOOM
        ? '?provider=zoom'
        : '';

    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_APPTS_API_URL}/integrations/${integrationId}${queryString}`)
            .then(() => {
                provider.status = provider.zoomStatus ? 'PENDING' : false;
                provider.email = '';
                provider.calendars = [];

                if (name !== PROVIDERS.ZOOM) {
                    commit('UPDATE_PROVIDER', provider);
                }

                resolve();
            })
            .catch(reject);
    });
};

const loadCalendars = ({ commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_APPTS_API_URL}/calendars`)
            .then((res) => {
                const calendars = Array.isArray(res.data.calendars) ? res.data.calendars : [];

                commit('SET_PROVIDER_CALENDARS', calendars);
                resolve();
            })
            .catch(reject);
    });
};

const initializeState = ({ dispatch, commit }) => {
    return new Promise((resolve, reject) => {
        dispatch('LOAD_PROVIDER_INTEGRATIONS')
            .then((integrations) => {
                const hasConnectedProviders = integrations.find(({ status }) => status === INTEGRATION_STATUS.READY);

                if (!hasConnectedProviders) {
                    commit('SET_STATE_INITIALIZED', true);
                    resolve();

                    return;
                }

                dispatch('LOAD_PROVIDER_CALENDARS')
                    .then(() => {
                        commit('SET_STATE_INITIALIZED', true);
                        resolve();
                    })
                    .catch(() => {
                        commit('SET_STATE_INITIALIZED', true);
                        const error = new Error('errors.appointments.api.calendars');

                        reject(error);
                    });
            })
            .catch(reject);
    });
};

const cancelAppointment = ({ commit }, cancelParams) => {
    return new Promise((resolve, reject) => {
        const {
            apptId,
            bookingLink,
        } = cancelParams;
        const paramsPresent = apptId && bookingLink;
        const paramsStrings = typeof apptId === 'string' && typeof bookingLink === 'string';
        const endpoint = `/appointments/${encodeURIComponent(apptId)}`;
        const query = `?bookingLink=${bookingLink}`;

        if (!paramsPresent || !paramsStrings) {
            this.$log('No params cancelling appointment');
        }

        return axios.delete(`${process.env.VUE_APP_APPTS_API_URL}${endpoint}${query}`)
            .then(({ data }) => {
                commit('REMOVE_CALENDAR_APPT', apptId);

                resolve(data);
            })
            .catch((err) => {
                if (err.response && err.response.status === 404) {
                    // This is not found. Fail gracefully and report this as 'cancelled'.
                    resolve();
                } else {
                    reject(err);
                }
            });
    });
};

const updateTempApptType = ({ commit, state, getters }, { apptType, calendarProviderId }) => {
    const { tempApptType } = state;
    const { orderedCalendars } = getters;

    const t = {
        ...tempApptType,
        ...apptType,
    };

    const cals = orderedCalendars(calendarProviderId);

    // auto-select first calendar if not set
    if ((!t.availabilityCalendars || !t.availabilityCalendars.length) && cals.length) {
        const cid = cals[0].id;

        t.availabilityCalendars = [cid];
        t.calendarId = cid;
    }

    commit('SET_TEMP_APPT_TYPE', t);
};

const twilioToken = (context, { identity, room }) => {
    return axios.get(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/twilio/${identity}/${room}`);
};

const loadAppointmentFilter = async ({ commit, rootState, getters }) => {
    const appId = rootState.auth.session.coreAppId;
    const calendarHashMap = await getters.getCalendarHashMap;

    const response = await firebase.database().ref(`appointmentView/${appId}`)
        .once('value').then((snapshot) => {
            if (snapshot.val()) {
                const { appointmentViewOption } = snapshot.val();

                const visibleCalendars = snapshot.val().visibleCalendars || [];

                const unhashedCalendars = calendarHashMap
                    .filter(({ hash }) => visibleCalendars.includes(hash))
                    .map(({ value }) => value.id);

                commit('SET_APPOINTMENT_VIEWING_FILTER', appointmentViewOption || 'all');
                commit('SET_CALENDAR_FILTER', unhashedCalendars || []);

                return unhashedCalendars;
            }

            return [];
        });

    return response;
};

const saveAppointmentViewFilter = ({ commit, rootState }, filter) => {
    const appId = rootState.auth.session.coreAppId;

    return new Promise((resolve) => {
        firebase.database().ref(`appointmentView/${appId}`)
            .once('value').then(
                firebase.database().ref(`appointmentView/${appId}`).child('appointmentViewOption').set(filter),
            );
        commit('SET_APPOINTMENT_VIEWING_FILTER', filter);
        resolve();
    });
};

const saveCalendarFilter = async ({ commit, rootState, getters }, selectedCalendars) => {
    const appId = rootState.auth.session.coreAppId;
    const calendarHashMap = await getters.getCalendarHashMap;

    const selectedCalendarHashes = calendarHashMap
        .filter(({ value }) => selectedCalendars.includes(value.id))
        .map(({ hash }) => hash);


    return new Promise((resolve) => {
        firebase.database().ref(`appointmentView/${appId}`)
            .once('value').then(
                firebase.database().ref(`appointmentView/${appId}`).child('visibleCalendars').set(selectedCalendarHashes),
            );
        commit('SET_CALENDAR_FILTER', selectedCalendars);

        resolve();
    });
};

const redirectAfterConnect = ({ state, commit }, router) => {
    if (state.connectRedirect) {
        router.push({ name: state.connectRedirect });
        commit('calendar/SET_CONNECT_REDIRECT', null);
    }
};
