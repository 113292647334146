<template lang="html">
    <form
        v-if="showForm"
        ref="formProfile"
        class="business-address"
        novalidate
        @submit.prevent="saveProfile"
    >
        <ds-input-field
            v-model="profile.companyName"
            required
            type="text"
            name="company"
            :label="$t('forms.business')"
            :submitted="submitted"
            @blur="handleBlur('companyName')"
        />

        <ds-select-field
            v-model="profile.businessType"
            data-qa="business-type-dropdown"
            :label="$t('global.businessType')"
            :options="formattedBusinessTypeOptions"
        />

        <ds-input-field
            v-model="profile.email"
            class="fs-block"
            data-qa="email-input"
            type="text"
            required
            name="email"
            :label="$t('forms.businessEmail')"
            :submitted="submitted"
            :invalid="showEmailError"
            @blur="handleBlur('email')"
        >
            <template #error>
                <small v-if="showEmailError" class="email-error">
                    {{ $t('emailError') }}
                </small>
            </template>
        </ds-input-field>

        <ds-phone-input
            v-model="profile.phone"
            name="phone"
            class="phone fs-block"
            :label="$t('businessPhone')"
            :submitted="submitted"
            @blur="handleBlur('phone')"
        />

        <ds-input-field
            v-model="profile.website"
            class="fs-block"
            type="text"
            name="website"
            :label="$t('businessWebsite')"
            :pattern="patterns.website"
            :submitted="submitted"
            @blur="handleBlur('website')"
        />

        <ds-multiselect
            v-model="country"
            data-qa="country-dropdown"
            class="fs-block"
            searchable
            required
            name="country"
            :options="countryOptions"
            :placeholder="$t('forms.countryCode')"
            :submitted="submitted"
            @input="updateCountry"
        />

        <ds-input-field
            v-model="profile.address1"
            class="fs-block"
            type="text"
            name="address1"
            required
            :label="$t('forms.address1')"
            :submitted="submitted"
            @blur="handleBlur('address1')"
        />

        <ds-input-field
            v-model="profile.address2"
            class="fs-block"
            type="text"
            name="address2"
            :label="$t('forms.address2')"
            :submitted="submitted"
            @blur="handleBlur('address2')"
        />

        <ds-input-field
            v-model="profile.locality"
            class="fs-block"
            type="text"
            name="locality"
            required
            :label="$t('forms.locality')"
            :submitted="submitted"
            @blur="handleBlur('locality')"
        />

        <div class="input-group">
            <ds-multiselect
                v-model="region"
                class="fs-block"
                data-qa="region-dropdown"
                searchable
                name="region"
                :required="hasRegionOptions"
                :options="regionOptions"
                :placeholder="$t('forms.region')"
                :submitted="submitted"
                @input="updateRegion"
            />

            <ds-input-field
                v-model="profile.postalCode"
                class="fs-block"
                required
                type="text"
                name="zip"
                :label="$t('forms.postalCodeGeneric')"
                :submitted="submitted"
                @blur="handleBlur('postalCode')"
            />
        </div>
    </form>
</template>

<script>
import { mapState } from 'vuex';
import { urlUtils, patterns } from '@infusionsoft/vue-utils';
import amplitude from '@/analytics/amplitude';
import { hasValidDomain } from '@/shared/utils/email.util';

export const EMAIL_INVALID_ERROR_CODE = 'Email invalid';

export default {
    data() {
        return {
            country: '',
            patterns,
            profile: {
                companyName: '',
                businessType: '',
                email: '',
                phone: '',
                website: '',
                address1: '',
                address2: '',
                locality: '',
                country: '',
                region: '',
                postalCode: '',
            },
            region: '',
            submitted: false,
            error: false,
        };
    },

    created() {
        this.load();
    },

    watch: {
        'countryOptions.length': {
            handler() {
                this.setLocalCountry();
            },
        },

        'profile.country': {
            handler(value) {
                this.loadRegionOptions(value);
            },
        },

        'regionOptions.length': {
            handler() {
                this.setLocalRegion();
            },
        },

        canSpamValid: {
            handler() {
                this.emitCanSpamValid(this.canSpamValid);
            },
        },
    },

    computed: {
        ...mapState({
            businessTypeOptions: ({ auth }) => auth.businessTypeOptions,
            countryOptions: ({ global }) => global.countryOptions,
            companyProfile: ({ auth }) => auth.account.companyProfile,
            regionOptions: ({ global }) => global.regionOptions,
        }),

        formattedBusinessTypeOptions() {
            return this.businessTypeOptions.map((option) => ({ id: option, label: this.$t(option) }));
        },

        // TODO: refactor to use the native checkValidtiy function once DS-77 is addressed.
        // https://jira.infusionsoft.com/browse/DS-77
        canSpamValid() {
            const {
                address1,
                companyName,
                country,
                email,
                locality,
                postalCode,
                region,
            } = this.profile;

            const regionValidOrOmitted = !this.hasRegionOptions
                ? true
                : region;

            return Boolean(address1
                && companyName
                && country
                && email
                && locality
                && postalCode
                && regionValidOrOmitted);
        },

        hasRegionOptions() {
            return Boolean(this.regionOptions && this.regionOptions.length);
        },

        showForm() {
            return Boolean(this.countryOptions && this.countryOptions.length);
        },

        showEmailError() {
            return !hasValidDomain(this.profile.email);
        },
    },

    methods: {
        handleBlur(field) {
            if (field === 'website') {
                this.formatWebsite();
            }

            this.profile[field] = this.profile[field]?.trim();
        },

        emitCanSpamValid(isValid) {
            this.$emit('canSpamValid', { isValid });
        },

        formatWebsite() {
            this.profile.website = urlUtils.formatUrl(this.profile.website);
        },

        load() {
            return this.$store.dispatch('LOAD_COUNTRY_OPTIONS')
                .then(() => {
                    this.profile = {
                        ...this.companyProfile,
                        businessType: this.$t(this.companyProfile.businessType),
                    };

                    return this.loadRegionOptions(this.profile.country);
                })
                .then(() => {
                    this.setLocalCountry();
                    this.setLocalRegion();

                    this.loaded = true;
                });
        },

        loadRegionOptions(countryCode) {
            return this.$store.dispatch('LOAD_REGION_OPTIONS', {
                countryCode,
            }).catch(() => {});
        },

        saveProfile() {
            this.submitted = true;
            this.error = false;

            if (this.showEmailError) {
                return Promise.reject(new Error(EMAIL_INVALID_ERROR_CODE));
            }

            if (this.companyProfile.companyName !== this.profile.companyName) {
                this.$store.dispatch('automatedSms/UPDATE_AUTOMATED_SMS_ACCOUNT', { companyName: this.profile.companyName });
            }

            if (this.canSpamValid) {
                this.$store.commit('auth/SET_COMPANY_PROFILE', {
                    ...this.profile,
                    businessType: this.profile.businessType.id,
                });

                amplitude.v2.setUserProperties({
                    'Business Type': this.profile.businessType.label,
                    'Has Website': Boolean(this.profile.website),
                });

                const successMessage = this.$t('companyInfo.saveSuccess');
                const saveErrorMessage = this.$t('companyInfo.saveError');

                return this.$store.dispatch('auth/SAVE_COMPANY_PROFILE')
                    .then(() => {
                        this.submitted = false;
                        this.$toast({ message: successMessage });
                    })
                    .catch(() => {
                        this.submitted = false;
                        this.$error({ message: saveErrorMessage });
                        this.error = true;
                    });
            }

            const validityErrorMessage = this.$t('companyInfo.validityError');

            this.$error({ message: validityErrorMessage });
            this.error = true;

            return Promise.resolve();
        },

        setLocalCountry() {
            this.country = this.countryOptions.find(({ value }) => {
                return value === this.profile.country;
            });
        },

        setLocalRegion() {
            this.region = this.regionOptions.find(({ value }) => {
                return value === this.profile.region;
            });
        },

        updateCountry({ value }) {
            this.profile.country = value;
        },

        updateRegion({ value }) {
            this.profile.region = value;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "businessPhone": "Business phone",
        "businessWebsite": "Business website",
        "companyInfo": {
            "title": "Update your business details",
            "description": "Please enter your business details. This information will display in your email footer in compliance with international spam laws. ",
            "saveError": "Failed to save your profile. Try again.",
            "saveSuccess": "Saved business information",
            "validityError": "All CAN-SPAM fields must be included."
        },
        "emailError": "Email cannot contain @keap.app or @infusionmail.com"
    }
}
</i18n>
