import Vue from 'vue';

export default {
    SET_AUTOMATION(state, payload) {
        state.automation = payload;
    },

    SET_SAVE_STATUS(state, payload) {
        state.saveStatus = payload;
    },

    SET_AUTOMATION_HAS_CHANGES(state, payload) {
        Vue.set(state.automation, 'hasChanges', payload);
    },

    SET_VALIDATED_AUTOMATION(state, payload) {
        state.validatedAutomation = payload;
    },

    SET_CONTEXT(state, payload) {
        state.context = payload;
    },

    SET_AUTOMATION_FILTERS(state, payload) {
        state.automationFilters = payload;
    },

    REMOVE_AUTOMATION_FILTER(state, index) {
        if (index > -1 && index < state.automationFilters.length) {
            state.automationFilters.splice(index, 1);
        }
    },

    SET_SAVE_AUTOMATION_CALLBACK(state, callback) {
        state.saveAutomationCallback = callback;
    },
};
