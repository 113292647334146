<template lang="html">
    <div class="appointment-name-field">
        <ds-input-field
            v-model.trim="localValue"
            type="text"
            name="appt_name"
            data-qa="appointment-type-name-field"
            :label="$t('label')"
            :placeholder="$t('placeholder')"
            :maxlength="30"
            required
        >
            <template #help>
                {{ apptUrl }}
            </template>
        </ds-input-field>

        <small v-if="!validBookingLink" class="error-text">
            {{ $t('validationMessage' ) }}
        </small>

        <ds-checkbox
            v-if="companyName"
            v-model="useCompanyName"
            :label="$t('useCompanyNameLabel')"
            data-qa="use-company-name"
        />
    </div>
</template>

<script>
import debounce from 'lodash.debounce';
import sentry from '@/analytics/sentry';
import axios from 'axios';

import { mapState } from 'vuex';
import { INPUT_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';

export default {
    props: {
        debounceDelay: {
            type: Number,
            default: INPUT_DEBOUNCE_DELAY,
        },
        value: String,
        title: String,
        hostLinkPart: String,
        bookingLink: String,
    },

    data() {
        return {
            validBookingLink: true,
            localValue: this.value,
            useCompanyName: false,
            dirtied: !this.value,
            parsedOriginalLink: {
                host: '',
                appt: '',
                isCompanyName: false,
            },
        };
    },

    mounted() {
        if (this.debounceDelay > 0) {
            this.validateBookingLink = debounce(this.validateBookingLink, this.debounceDelay);
        }

        const sanitizedCompanyName = this.companyName ? this.sanitize(this.companyName.replace(/ /g, '')) : '';

        const [host, appt] = this.bookingLink ? this.bookingLink.split('/') : ['', ''];

        this.parsedOriginalLink.host = this.hostLinkPart ?? host;
        this.parsedOriginalLink.appt = appt;

        if (this.companyName && this.parsedOriginalLink.host.includes(sanitizedCompanyName)) {
            this.useCompanyName = true;
            this.parsedOriginalLink.isCompanyName = true;
        }

        this.$emit('update:isValid', this.isValid);
    },

    watch: {
        localBookingLink(value) {
            this.validateBookingLink(value);
            this.dirtied = !(this.parsedOriginalLink.appt === this.titleLinkPart && this.parsedOriginalLink.host.includes(this.localHostLinkPart));
        },

        isValid(value) {
            this.$emit('update:isValid', value);
        },
    },

    computed: {
        ...mapState({
            usersFullName: ({ auth }) => auth.user.fullName,
            companyName: ({ auth }) => (auth.account.companyProfile ? auth.account.companyProfile.companyName : undefined),
            apptTypes: ({ calendar }) => calendar.apptTypes,
        }),

        apptTitle() {
            return this.localValue && this.localValue.trim();
        },

        apptUrl() {
            return this.parsedOriginalLink.host ? `${process.env.VUE_APP_BOOKING_BASE_URL}${this.localBookingLink}` : '';
        },

        localBookingLink() {
            return `${this.localHostLinkPart}/${this.titleLinkPart}`;
        },

        titleLinkPart() {
            return this.localValue ? this.sanitize(this.localValue.trim().replace(/\s/g, '-')) : '';
        },

        localHostLinkPart() {
            let part = this.useCompanyName ? this.companyName : this.usersFullName;

            if (this.parsedOriginalLink.host && this.parsedOriginalLink.isCompanyName === this.useCompanyName) {
                part = this.parsedOriginalLink.host;
            }

            return this.sanitize(part.replace(/ /g, ''));
        },

        isValid() {
            return !this.dirtied
                || (this.titleLinkPart && this.localHostLinkPart && this.validBookingLink);
        },
    },

    methods: {
        sanitize(val) {
            if (val.includes('&')) {
                val = val.replace(/&/g, this.$t('validation.and'));
            } else if (val.includes('@')) {
                val = val.replace(/@/g, this.$t('at'));
            }

            return val.trim()
                .replace(/[^a-zA-Z0-9_-\s]/g, '')
                .replace(/\s+/g, ' ')
                .replace(/-+/g, '-')
                .toLowerCase();
        },

        async validateBookingLink(bookingLink) {
            const sanitizedBookingLink = bookingLink.toLowerCase().replace(/ /g, '-');

            const isBookingLinkTaken = await this.doesBookingLinkExist(sanitizedBookingLink);

            this.validBookingLink = !this.dirtied || !isBookingLinkTaken;

            this.$emit('input', this.localValue);

            const host = this.useCompanyName ? this.companyName : this.usersFullName;

            this.$emit('update:title', this.$t('apptTitle', {
                apptTitle: this.apptTitle,
                host,
            }));

            this.$emit('update:bookingLink', this.localBookingLink);
            this.$emit('update:titleLinkPart', this.titleLinkPart);
            this.$emit('update:hostLinkPart', this.localHostLinkPart);
        },

        async doesBookingLinkExist(bookingLink) {
            if (!this.titleLinkPart) {
                return false;
            }

            if (this.apptTypes && this.apptTypes.some(
                (apptType) => {
                    const [usedHostName, usedApptName] = apptType.bookingLink.split('/');

                    return usedApptName === this.titleLinkPart && usedHostName.includes(this.localHostLinkPart);
                },
            )) {
                return true;
            }

            try {
                await axios.get(
                    `${process.env.VUE_APP_APPTS_API_URL}/appointmentTypes/search?bookingLink=${bookingLink}`,
                    {
                        headers: {
                            'X-IS-Ignore-Auth': true,
                        },
                    },
                );

                return true;
            } catch (e) {
                if (!e.response || e.response.status !== 404) {
                    sentry.log('Failed to search booking link', { message: JSON.stringify(e) });
                }

                return false;
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "validation": {
            "and": "and",
            "at": "at"
        },
        "label": "Name",
        "placeholder": "consultation",
        "useCompanyNameLabel": "Use my business name instead of my personal name in the booking link and booking page.",
        "sampleUrlPart": "consultation",
        "apptTitle": "{apptTitle} with {host}",
        "validationMessage": "There is already an appointment type with this name. Please choose a different name."
    }
}
</i18n>
