<template>
    <div class="set-availability-stage">
        <h2 class="headline">
            {{ $t('title') }}
        </h2>

        <h4 v-if="shouldDisplayRequiredFieldsValidationMessage" class="validation-msg">
            {{ $t('allFieldsRequired') }}
        </h4>

        <div class="fields-container">
            <div class="field">
                <h4>{{ $t('durationTitle') }}</h4>

                <p>{{ $t('durationDescription') }}</p>

                <div class="duration">
                    <ds-select-field
                        v-model="localDurationMinutes"
                        class="duration-field"
                        block
                        bind-value-only
                        :label="$t('durationTitle')"
                        :options="durationOptions"
                    />

                    <div v-if="isCustomDuration" class="custom-duration">
                        <ds-select-field
                            v-model="customDurationHours"
                            class="custom-time"
                            block
                            bind-value-only
                            :label="$t('global.time.hours')"
                            :options="customHours"
                        />

                        <ds-select-field
                            v-model="customDurationMinutes"
                            class="custom-time"
                            block
                            bind-value-only
                            :label="$t('global.time.minutes')"
                            :options="customMinutes"
                        />
                    </div>
                </div>
            </div>

            <div class="field">
                <h4>{{ $t('buffers.title') }}</h4>

                <p>{{ $t('buffers.description') }}</p>

                <div class="buffer-fields">
                    <ds-select-field
                        v-model="localBufferTimes.before"
                        block
                        allow-null
                        bind-value-only
                        :label="$t('buffers.beforeLabel')"
                        :options="bufferOptions"
                    />

                    <ds-select-field
                        v-model="localBufferTimes.after"
                        block
                        allow-null
                        bind-value-only
                        :label="$t('buffers.afterLabel')"
                        :options="bufferOptions"
                    />
                </div>
            </div>

            <div class="field">
                <h4>{{ $t('availabilityTitle') }}</h4>

                <p>{{ $t('availabilityDescription') }}</p>

                <time-frames
                    v-model="localAvailabilityTimes"
                    :on-day-select="handleDayClick"
                    :on-add-time-frame="handleAddTimeFrame"
                    :on-remove-time-frame="handleRemoveTimeFrame"
                    :appointment-duration="localDurationMinutes"
                />
            </div>
        </div>

        <help-box v-track="'Appointments - Which calendars modal - clicked : How does this work link'">
            {{ $t('infoMessage') }}
        </help-box>
    </div>
</template>

<script>
import HelpBox from '@/appointments/components/HelpBox';
import TimeFrames from '@/appointments/components/TimeFrames';
import { mapGetters } from 'vuex';

export default {
    components: {
        HelpBox,
        TimeFrames,
    },

    props: {
        duration: Number,
        availabilityTimes: {
            type: Array,
            default: () => [],
        },
        bufferTimes: Object,
        calendarProviderId: {
            type: String,
            required: true,
            default: 'GOOGLE',
        },
    },

    data() {
        return {
            localDurationMinutes: this.duration,
            customDurationHours: this.customDurationQuotient,
            customDurationMinutes: this.customDurationRemainder,
            localAvailabilityTimes: this.availabilityTimes,
            localBufferTimes: {},
            hasAttemptedSubmittal: false,
        };
    },

    created() {
        this.localBufferTimes = this.bufferTimes
            ? { ...this.bufferTimes }
            : { before: 0, after: 0 };
    },

    watch: {
        localAvailabilityTimes(value) {
            this.$emit('update:availabilityTimes', value);
        },

        localBufferTimes: {
            handler(value) {
                this.$emit('update:bufferTimes', value);
            },
            deep: true,
        },

        localDurationMinutes(value) {
            this.$emit('update:duration', value === -1 ? this.customTotalDuration : value);
        },

        customDurationHours() {
            this.$emit('update:duration', this.customTotalDuration);
        },

        customDurationMinutes() {
            this.$emit('update:duration', this.customTotalDuration);
        },

        areRequiredFieldsCompleted: {
            handler(value) {
                this.$emit('update:ready', value);
            },
            immediate: true,
        },
    },

    computed: {
        ...mapGetters({
            durations: 'calendar/durations',
            customHours: 'calendar/customHours',
            customMinutes: 'calendar/customMinutes',
            buffers: 'calendar/buffers',
        }),

        durationOptions() {
            return this.durations.map(({ value, label, tcargs }) => {
                return {
                    value,
                    label: this.$tc(label, tcargs.count),
                };
            });
        },

        bufferOptions() {
            return this.buffers.map(({ value, label, tcargs }) => {
                return {
                    value,
                    label: tcargs ? this.$tc(label, tcargs.count) : this.$t(label),
                };
            });
        },

        areRequiredFieldsCompleted() {
            return Boolean(this.isDurationValid
                && this.isAvailabilityValid);
        },

        isDurationValid() {
            return this.isCustomDuration
                ? Boolean(this.customTotalDuration)
                : Boolean(this.localDurationMinutes && this.localDurationMinutes > 0);
        },

        isAvailabilityValid() {
            return Boolean(this.localAvailabilityTimes && this.localAvailabilityTimes.length > 0);
        },

        shouldDisplayRequiredFieldsValidationMessage() {
            return Boolean(this.hasAttemptedSubmittal && !this.areRequiredFieldsCompleted);
        },

        isCustomDuration() {
            return this.localDurationMinutes === -1;
        },

        customTotalDuration() {
            return (this.customDurationHours > 0 ? this.customDurationHours * 60 : 0) + (this.customDurationMinutes || 0);
        },

        customDurationQuotient() {
            return this.duration > 0 ? Math.floor(this.duration / 60) : 0;
        },

        customDurationRemainder() {
            return this.duration > 0 ? this.duration % 60 : 0;
        },
    },

    methods: {
        handleAddTimeFrame() {
            this.$track('Appointments - Set up availability modal - clicked : + Add another time frame for this day link');
        },

        handleRemoveTimeFrame() {
            this.$track('Appointments - Set up availability modal - clicked : Trash can on [any] time frame');
        },

        handleDayClick(day) {
            this.$track('Appointments - Set up availability modal - clicked : Day link', { day });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $grid-column: calc(50% - 8px);

    .set-availability-stage {
        margin: 0 auto;
        max-width: px-to-rem(500px);
        width: 100%;
    }

    .headline {
        margin: $gp * 2 0;
        text-align: center;
    }

    .title {
        margin-top: $gp;
        text-align: center;
    }

    .validation-msg {
        color: $color-red;
        font-size: $font-size-small;
        margin-top: $gp / 3;
        text-align: center;
    }

    .fields-container {
        margin-top: $gp * 2.5;
    }

    .field {
        margin-bottom: $gp * 2;
    }

    .availablity-field {
        max-width: px-to-rem(360px);
    }

    .input-field {
        --input-margin-bottom: #{$gp / 3};
    }

    .buffer-fields {
        display: grid;
        grid-template-columns: $grid-column $grid-column;
        grid-gap: $gp;
    }

    .invalid {
        --input-border: 1px solid #{$color-red};
    }

    .help-link {
        margin: 0 auto;
    }

    .duration {
        display: flex;
        align-items: baseline;
    }

    .custom-duration {
        display: flex;
        flex: 1;
        justify-content: flex-end;
    }

    .custom-time {
        width: px-to-rem(100px);
        @include margin-start($gp);
        flex: 1;
    }

    .duration-field {
        flex: 1;
    }

    @media($small) {
        .duration {
            flex-direction: column;
        }

        .custom-duration {
            width: 100%;
            justify-content: space-between;
        }

        .custom-time {
            margin: 0;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Hours and availability",
        "durationTitle": "Duration",
        "durationDescription": "How long is this type of appointment?",
        "availabilityTitle": "Availability",
        "availabilityDescription": "When can people schedule with you?",
        "infoMessage": "Set the days & times of the week that you want to show your availability for this type of appointment. Clients will only be able to schedule within these selected times.",
        "allFieldsRequired": "All fields are required.",
        "buffers": {
            "title": "Buffer time",
            "description": "How much time is needed before and after these types of appointments?",
            "beforeLabel": "Before",
            "afterLabel": "After"
        }
    }
}
</i18n>
