import { render, staticRenderFns } from "./AddContactModal.vue?vue&type=template&id=25d6d39b&scoped=true&lang=html&"
import script from "./AddContactModal.vue?vue&type=script&lang=js&"
export * from "./AddContactModal.vue?vue&type=script&lang=js&"
import style0 from "./AddContactModal.vue?vue&type=style&index=0&id=25d6d39b&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25d6d39b",
  null
  
)

/* custom blocks */
import block0 from "./AddContactModal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports