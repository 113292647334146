<template>
    <div class="deal-participant-item">
        <router-link
            :to="to(participant.metadata)"
            class="deal-list-item"
            :title="participant.name"
        >
            <ds-list-item
                clickable
                class="list-item"
                :title="participant.name"
                border-style="none"
            >
                <template #description>
                    <i18n
                        class="automation-next-step"
                        path="nextStep"
                    >
                        <template #nextStep>
                            <automation-display-name :value="participantListItem_nextStep" as="span" />
                        </template>
                    </i18n>

                    <div data-qa="contact-names">
                        {{ contactNames }}
                    </div>
                </template>

                <template #leadingSlot>
                    <div
                        :class="['circle-checkbox-container circle-checkbox-hover', { checked: isChecked }]"
                        :data-qa="participant.name"
                        @click.stop.prevent="select(participant)"
                    >
                        <span class="circle-checkbox">
                            <ds-icon class="circle-check" name="check" />
                        </span>

                        <div class="circle-checkbox-avatar" :data-qa="participant.name">
                            <contact-avatar
                                v-if="participant.contacts.length"
                                :email="participantListItem_getEmail(participant.contacts[0])"
                                :name="contactNames.split(', ')[0]"
                            />

                            <ds-avatar v-else :name="participant.name.charAt(0)" />
                        </div>
                    </div>
                </template>
            </ds-list-item>
        </router-link>
    </div>
</template>

<script>
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import ContactAvatar from '@/shared/components/ContactAvatar';

import { displayFullName } from '@/contacts/contact-info-utils';
import participantListItemMixin from '@/automations/components/participants/participantListItem.mixin';

export default {
    components: {
        AutomationDisplayName,
        ContactAvatar,
    },

    mixins: [participantListItemMixin],

    props: {
        participant: {
            type: Object,
            default: () => ({ contacts: [] }),
        },
        automation: {
            type: Object,
            required: true,
        },
    },

    computed: {
        isChecked() {
            return this.participant.selectedContactIds?.length || this.participant.selected;
        },

        contactNames() {
            const names = this.participant.contacts.map((contact) => displayFullName(contact));

            return names?.join(', ') ?? '';
        },
    },

    methods: {
        to({ id, pipelineId }) {
            const { query, params } = this.$route;

            return {
                name: 'pipeline-deal',
                query,
                params: {
                    ...params,
                    pipelineId,
                    id,
                    tab: null,
                },
            };
        },

        select() {
            this.$emit('select', { selected: !this.isChecked });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/shared/styles/circle-checkbox";
    @import "./participantListItem";
</style>

<i18n>
{
    "en-us": {
        "nextStep": "Next step: {nextStep}"
    }
}
</i18n>
