import axios from 'axios';

export default {
    GET_USER_SIGNATURE(context, userId) {
        return getUserSignature(context, userId);
    },

    SAVE_PROVIDER_INTEGRATION(context, payload) {
        return saveProviderIntegration(context, payload);
    },

    LOAD_PROVIDER_INTEGRATIONS(context, payload) {
        return loadEmailProviders(context, payload);
    },

    DELETE_PROVIDER_INTEGRATION(context, payload) {
        return deleteProviderIntegration(context, payload);
    },

    DELETE_SYNCED_EMAIL(context, payload) {
        return deleteSyncedEmail(context, payload);
    },
};

const getUserSignature = ({ commit }, userId) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/users/${userId}/signature`)
            .then(({ data }) => {
                commit('SET_USER_SIGNATURE', { userId, signature: data.signature });
                resolve(data.signature);
            })
            .catch(reject);
    });
};

const saveProviderIntegration = ({ commit, dispatch }, payload) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_EMAILSYNC_API_URL}/api/v1/syncIntegration/enable`, payload)
            .then(() => {
                commit('UPDATE_PROVIDER', payload);

                dispatch('settings/LOAD_INTEGRATIONS', null, { root: true });

                resolve();
            })
            .catch(reject);
    });
};

const loadEmailProviders = (context) => {
    const { commit, rootState } = context;
    const { coreAppId } = rootState.auth.session;

    return axios.get(`${process.env.VUE_APP_EMAILSYNC_API_URL}/api/v1/syncIntegration/list/${coreAppId}?showDisabled=true`)
        .then(({ data }) => {
            const newProviders = data.syncIntegrationList.map((provider) => ({
                ...provider,
            }));

            commit('SET_PROVIDERS', newProviders);

            return newProviders;
        });
};

const deleteProviderIntegration = ({ dispatch }, payload) => {
    const {
        appId,
        casId,
        deleteOnDisconnect,
        emailProvider,
        emailProviderId,
    } = payload;

    const requestBody = {
        emailProvider,
        emailProviderId,
        tenant: appId,
        userId: casId,
    };

    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_EMAILSYNC_API_URL}/api/v1/syncIntegration/disable?resetStatus=${deleteOnDisconnect}`, requestBody)
            .then(() => {
                dispatch('LOAD_PROVIDER_INTEGRATIONS');

                resolve();
            })
            .catch(reject);
    });
};

const deleteSyncedEmail = (context, { emailProvider, emailProviderId }) => {
    const queryString = `?emailProvider=${emailProvider}&providerSourceId=${emailProviderId}`;

    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v2/email/deleteIntegrationEmails${queryString}`);
};
