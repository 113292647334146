<template>
    <div class="add-step">
        <ds-icon-button
            data-qa="add-step"
            class="add-icon"
            :name="addOpen ? 'minus-fill' : 'add-fill'"
            @click="$emit('open-steps')"
        />

        <ds-collapsible ref="collapsible" :opened="addOpen" :class="{ 'add-step-options': addOpen }">
            <step-options v-if="addOpen" @selected="(payload) => $emit('selected', payload)" />
        </ds-collapsible>
    </div>
</template>

<script>
import StepOptions from './StepOptions';

export default {
    components: {
        StepOptions,
    },

    props: {
        addOpen: Boolean,
    },
};
</script>

<style lang="scss" scoped>
    .add-step {
        margin: $gp / 2 0;
        --collapsible-inner-padding: 0;
        text-align: center;
    }

    .add-step-options {
        margin-bottom: 1.5 * $gp;
    }
</style>
