<template>
    <section class="sms-broadcast-footer" :class="{ center }">
        <hr />

        <span>{{ $t('rulesAndRegulationsInfo') }}</span>

        <div class="sms-broadcast-footer-links">
            <a
                :title="$t('tcpaLawsLink')"
                :href="tcpaLawsLink"
                class="rules-and-regulations-link"
                rel="noopener noreferer"
                target="_blank"
            >
                {{ $t('tcpaLawsLink') }}
                <ds-icon name="external-link" />
            </a>

            <a
                :title="$t('bestPracticesLink')"
                :href="bestPracticesLink"
                class="rules-and-regulations-link"
                rel="noopener noreferer"
                target="_blank"
            >
                {{ $t('bestPracticesLink') }}
                <ds-icon name="external-link" />
            </a>
        </div>
    </section>
</template>

<script>

export default {
    props: {
        center: Boolean,
    },

    data() {
        return {
            tcpaLawsLink: 'https://www.fdic.gov/regulations/compliance/manual/8/viii-5.1.pdf',
            bestPracticesLink: 'https://www.twilio.com/learn/call-and-text-marketing/guide-to-us-sms-compliance',
        };
    },
};
</script>

<style lang="scss" scoped>
    .sms-broadcast-footer {
        padding-bottom: $gp;
        font-size: $font-size-xsmall;
        gap: $gp / 2;
        color: $color-gray-700;
        background-color: $color-paper;
        display: flex;
        flex-direction: column;

        &.center {
            text-align: center;
            align-items: center;
        }
    }

    hr {
        margin: 0 0 $gp / 2;
    }

    .sms-broadcast-footer-links {
        display: flex;
        align-items: center;
        gap: $gp * 2.5;
        text-decoration: underline;

        .rules-and-regulations-link {
            display: flex;
            flex-direction: row;
            gap: $gp / 4;
            --icon-size: #{px-to-rem(13.33px)};
            color: $color-gray-700;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "tcpaLawsLink" : "Read the TCPA laws",
        "bestPracticesLink" : "Review best practices",
        "rulesAndRegulationsInfo" : "Text messaging has many rules and regulations. Make sure you understand them."
    }
}
</i18n>
