<template>
    <div
        :class="{active:activeStep === index, complete: activeStep > index}"
        :data-qa="`step-indicator-${index}`"
    >
        <button
            class="circle"
            @click="$emit('click-step')"
        >
            <ds-icon v-if="activeStep > index" name="check" />
            <span
                v-else-if="stepNumber"
                :data-qa="`step-indicator-number-${index}`"
                class="step-number"
            >
                {{ stepNumber }}
            </span>
        </button>
        <label @click="$emit('click-step')">{{ label }}</label>
    </div>
</template>

<script>
export default {
    props: {
        label: String,
        index: Number,
        stepNumber: Number,
        activeStep: Number,
    },
};
</script>

<style lang="scss" scoped>
    .complete {
        .circle {
            background-color: $color-blue;
            border-color: $color-blue;
        }
    }

    .landing-pages-step-indicator .complete {
        .circle {
            --icon-size: #{$icon-size-small};
        }
    }

    :not(.active) {
        label {
            font-weight: $font-weight-regular;
        }
    }

    .active {
        .circle {
            border-color: $color-blue;
        }
        label {
            font-weight: $font-weight-semibold;
        }
    }

    .circle {
        @include circle(30px);
        @include transition(background-color, border-color);
        border: solid px-to-rem(2px) $color-gray-200;
        background-color: $color-paper;
        color: $color-paper;
        --icon-color: #{$color-paper};
        position: relative;
        padding: 0;
        z-index: 1;

        .step-number {
            color: $color-text-normal;
        }
    }

    label {
        z-index: 1;
        padding: 0 $gp / 4;
        text-align: center;
        cursor: pointer;
        color: $color-gray-900;
        background-color: $color-paper;
        margin-top: var(--step-indicator-margin-top, 0);
    }
</style>
