import { IMPORT_STAGES } from '@/import/import.constants';

export default {
    SET_FILE_ID(state, fileboxId) {
        state.fileboxId = fileboxId;
    },

    SET_IMPORT_FILE(state, file) {
        state.file = file;
    },

    SET_IMPORT_MAPPINGS(state, importMappings) {
        // This is a temporary fix for HubSpot mappings and will be removed once we can update/deploy this field mapping on the backend
        importMappings.forEach((field) => {
            if (field && field.fieldToImport === 'MobilePhoneNumber') {
                field.fieldToMapTo = 'PHONE2';
            }
        });

        state.importMappings = importMappings;
    },

    SET_IMPORT_SOURCE(state, importSource) {
        state.importSource = importSource;
    },

    CLEAR_IMPORT_SOURCE(state) {
        state.importSource = null;
    },

    SET_IMPORT_IN_PROGRESS(state, importInProgress) {
        state.importInProgress = importInProgress;
    },

    SET_IMPORT_COUNT(state, count) {
        state.count = count;
    },

    SET_MAP_TO_OPTIONS(state, options) {
        state.mapToOptions = options;
    },

    SET_IMPORT_RESULTS(state, results) {
        state.results = results;
    },

    SET_RESULT_TAGID(state, tagId) {
        state.tagId = tagId;
    },

    SET_ACTIVE_STAGE(state, stageId) {
        state.activeStage = stageId;
    },

    SET_CONTACT_LIST(state, list) {
        state.contactList = list;
    },

    APPEND_CONTACT_LIST(state, list) {
        state.contactList = state.contactList.concat(list);
    },

    SET_PAGE_TOKEN(state, token) {
        state.microsoftPageToken = token;
    },

    SET_CUSTOM_FIELDS(state, customFields) {
        state.customFields = customFields;
    },

    ADD_CUSTOM_FIELD(state, customField) {
        state.customFields.push(customField);
    },

    SET_STAGE_READY(state, { stage, isReady }) {
        state.stages[stage].ready = isReady;
    },

    SET_OPTIN_STATUS(state, singleOptIn) {
        state.singleOptIn = singleOptIn;
    },

    SET_STATE_IMPORTING(state, importing) {
        state.states.importing = importing;
    },

    SET_STATE_IMPORTED(state, imported) {
        state.states.imported = imported;
    },

    SET_STATE_READY(state, ready) {
        state.states.ready = ready;
    },

    SET_ADDITIONAL_TAGS(state, additionalTags) {
        state.additionalTags = additionalTags;
    },

    SET_STATE_ERROR(state, { failed = true, code, reason }) {
        state.states.importing = false;
        state.states.imported = false;
        state.states.failed = failed;
        state.error.code = code;
        state.error.reason = reason;
    },

    RESET_IMPORT_STATE(state) {
        state.activeStage = IMPORT_STAGES.UPLOAD;
        state.additionalTags = [];
        state.matchedOpened = false;
        state.stages.upload.ready = false;
        state.stages.organize.ready = false;
        state.stages.final.ready = false;
        state.importMappings = [];
        state.customFields = [];
        state.states.importing = false;
        state.states.imported = false;
        state.states.failed = false;
        state.states.ready = false;
        state.error.code = '';
        state.error.reason = '';
    },

    TOGGLE_MATCHED_OPENED(state) {
        state.matchedOpened = !state.matchedOpened;
    },

    SET_CONTACT_LIMIT(state, contactLimit) {
        state.contactLimit = contactLimit;
    },

    SET_LAST_IMPORT_SOURCE(state, lastSource) {
        state.lastImportSource = lastSource;
    },

    UPDATE_QBO_CONNECTION(state, connected) {
        state.qboIntegration.isConnected = connected;
        state.qboIntegration.isLoaded = true;
    },
};
