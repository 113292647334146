<template>
    <div class="automation-add-tag configure-editor">
        <header class="configure-editor-header">
            <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

            <span class="configure-editor-header-text">{{ $t('createHeader') }}</span>

            <ds-filled-button class="shift-right" data-qa="next-button" @click="handleNext">
                {{ $t('global.next') }}
            </ds-filled-button>
        </header>

        <form ref="form" class="form" @submit.prevent="handleNext">
            <ds-input-field
                v-model="tag.name"
                data-qa="tag-name"
                type="text"
                name="title"
                :label="$t('tag.name')"
                required
                autofocus
                :submitted="submitted"
            />

            <ds-text-area-field
                v-model="tag.description"
                data-qa="tag-description"
                type="text"
                name="description"
                :label="$t('tag.description')"
                :submitted="submitted"
            />
        </form>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
        return {
            tag: {
                name: '',
                description: '',
            },
            submitted: false,
        };
    },

    computed: {
        ...mapState({
            tags: ({ tags }) => tags.tags,
        }),

        duplicateTag() {
            return this.tags.find(({ name, category }) => {
                const sameName = name.toLowerCase() === this.tag.name.toLowerCase();
                const sameCategory = !category;

                return sameName && sameCategory;
            });
        },
    },

    methods: {
        handleBack() {
            this.$emit('back');
        },

        handleNext() {
            this.submitted = true;

            if (this.$refs.form.checkValidity()) {
                if (this.duplicateTag) {
                    this.$emit('tag-created', this.duplicateTag);
                } else {
                    this.createTag();
                }
            }
        },

        async createTag() {
            const eventSource = 'Automation Create New Tag';
            const newTag = {
                ...this.tag,
                categoryId: null,
            };

            const tag = await this.$store.dispatch('tags/CREATE_TAG', {
                newTag,
                eventSource,
            });

            this.$emit('tag-created', tag);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "createHeader": "Create new tag"
    }
}
</i18n>
