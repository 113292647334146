<template>
    <div class="task-automation">
        <div v-if="mergeSuggestionEnabled" class="label required">
            {{ $t('task.titleLabel') }}
        </div>

        <merge-field-suggestion-text-box
            v-if="mergeSuggestionEnabled"
            v-model="task.title"
            class="input-field"
            :has-error="showTitleError"
            :placeholder="$t('task.titlePlaceholder')"
            :additional-merge-fields="additionalMergeFields"
            :use-merge-service="useMergeService"
            :merge-fields="getSupportedMergeFields(additionalMergeFields, useMergeService)"
        />

        <mergeable-input
            v-else
            v-model="task.title"
            data-qa="task-title"
            class="task-title"
            type="text"
            name="title"
            tracking="Automations - Create task - clicked : Task Title Merge Field"
            required
            autofocus
            :use-merge-service="useMergeService"
            :maxlength="$options.MAXLENGTH.NAME"
            :submitted="showTitleError"
            :label="$t('task.title')"
            :additional-merge-fields="additionalMergeFields"
        />

        <div v-if="mergeSuggestionEnabled" class="label">
            {{ $t('task.bodyLabel') }}
        </div>

        <merge-field-suggestion-text-box
            v-if="mergeSuggestionEnabled"
            v-model="task.body"
            class="text-area"
            :placeholder="$t('task.bodyPlaceholder')"
            :additional-merge-fields="additionalMergeFields"
            :use-merge-service="useMergeService"
            :merge-fields="getSupportedMergeFields(additionalMergeFields, useMergeService)"
        />

        <automation-textarea
            v-else
            v-model="task.body"
            class="task-body"
            data-qa="task-note"
            :use-merge-service="useMergeService"
            :maxlength="$options.MAXLENGTH.DESCRIPTION"
            :placeholder="$t('task.body')"
            :additional-merge-fields="additionalMergeFields"
        />

        <div class="input-row">
            <ds-select-field
                v-model="daysUntilDueSelection"
                bind-value-only
                required
                data-qa="task-days-selection"
                :label="$t('task.dueDate')"
                :options="daysUntilDueOptions"
                @input="daysUntilDueHandler"
            />

            <ds-input-field
                v-if="daysUntilDueSelection === $options.DAYS_UNTIL_DUE_CUSTOM"
                v-model="task.daysUntilDue"
                data-qa="task-days-until-due"
                class="task-days-until-due"
                type="number"
                required
                :min="0"
                :label="$t('task.daysUntilDue')"
            />
        </div>

        <ds-multiselect
            v-model="assignToSelection"
            bind-value-only
            searchable
            required
            data-qa="task-assign-to"
            :placeholder="$t('task.assignTo')"
            :options="assignToOptions"
            @input="assignToHandler"
        />

        <ds-inline-alert
            v-if="showErrorBanner"
            data-qa="error-banner"
            class="error-banner-container short-error-banner"
            type="critical"
            leading-icon
        >
            {{ $t('error.banner') }}
        </ds-inline-alert>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import MergeableInput from '@/shared/components/MergeField/MergeableInput';
import AutomationTextarea from '@/shared/components/AutomationTextarea';
import MergeFieldSuggestionTextBox from '@/shared/components/Text/MergeFieldSuggestionTextBox';
import { MAXLENGTH } from '@/automations/constants/automations.constants';
import { FF_KEAP_SIMPLE_AUTOMATION_MERGE_SUGGESTIONS } from '@/shared/constants/featureFlag.constants';

const ASSIGN_TO_CONTACT_OWNER = 'CONTACT_OWNER';
const DAYS_UNTIL_DUE_CUSTOM = 'CUSTOM';

const getDefaultTask = () => {
    return {
        title: '',
        body: '',
        assignToContactOwner: true,
        assignTo: 0,
        daysUntilDue: 1,
    };
};

export default {
    components: {
        MergeFieldSuggestionTextBox,
        MergeableInput,
        AutomationTextarea,
    },

    MAXLENGTH,
    DAYS_UNTIL_DUE_CUSTOM,

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        showErrorBanner: Boolean,
        showTitleError: Boolean,
        additionalMergeFields: {
            type: Array,
            default: () => [],
        },
        useMergeService: Boolean,
    },

    data() {
        return {
            assignToSelection: ASSIGN_TO_CONTACT_OWNER,
            daysUntilDueSelection: 1,
            task: Object.keys(this.value).length ? this.value : getDefaultTask(),
        };
    },

    mounted() {
        this.init();
    },

    watch: {
        value: {
            handler(newVal) {
                this.task = newVal;
            },
            deep: true,
        },

        task: {
            handler(val) {
                this.$emit('input', val);
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            users: ({ auth }) => auth.users,
            mergeSuggestionEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_MERGE_SUGGESTIONS],
        }),

        ...mapGetters({
            addMergeFields: 'mergeFields/addMergeFields',
            getSupportedMergeFields: 'mergeFields/getSupportedMergeFields',
        }),

        assignToOptions() {
            const options = [{ value: ASSIGN_TO_CONTACT_OWNER, label: this.$t('contactOwner') }];

            const userOptions = this.users.map((user) => {
                return {
                    label: user.fullName,
                    value: +user.id,
                };
            });

            return [...options, ...userOptions];
        },

        daysUntilDueCustom() {
            return ![0, 1, 7].includes(this.task.daysUntilDue);
        },

        daysUntilDueOptions() {
            return [
                {
                    label: this.$t('daysUntilDue.sameDay'),
                    value: '0',
                },
                {
                    label: this.$t('daysUntilDue.nextDay'),
                    value: '1',
                },
                {
                    label: this.$t('daysUntilDue.inAWeek'),
                    value: '7',
                },
                {
                    label: this.$t('daysUntilDue.custom'),
                    value: DAYS_UNTIL_DUE_CUSTOM,
                },
            ];
        },
    },

    methods: {
        init() {
            this.setAssignOption();
            this.setUntilDueOption();
            this.$emit('input', this.task);
        },

        assignToHandler(selection) {
            this.task.assignToContactOwner = selection === ASSIGN_TO_CONTACT_OWNER;
            this.task.assignTo = selection === ASSIGN_TO_CONTACT_OWNER ? undefined : +selection;
        },

        daysUntilDueHandler(selection) {
            this.daysUntilDueSelection = selection;

            if (selection !== DAYS_UNTIL_DUE_CUSTOM) {
                this.task.daysUntilDue = +selection;
            }
        },

        setAssignOption() {
            this.assignToSelection = this.task.assignToContactOwner || !this.task.assignTo
                ? ASSIGN_TO_CONTACT_OWNER
                : +this.task.assignTo;
        },

        setUntilDueOption() {
            this.daysUntilDueSelection = this.daysUntilDueCustom ? DAYS_UNTIL_DUE_CUSTOM : `${this.task.daysUntilDue}`;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .input-row {
        display: flex;

        > :not(:last-child) {
            @include margin-end($gp);
        }
    }

    .task-body {
        position: relative;
        margin-bottom: $gp * 2;
    }

    .task-title {
        flex: 3;
    }

    .task-days-until-due {
        flex: 1;
        min-width: px-to-rem(120px);
    }

    .label {
        color: $color-text-subtle;
        padding-bottom: $gp / 4;
        display: flex;

        &.required:after {
            @include input-label-required;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "contactOwner": "Contact owner",
        "task": {
            "title": "Task title",
            "body": "Note",
            "dueDate": "Due date",
            "daysUntilDue": "Days until due",
            "assignTo": "Assign task to",
            "titlePlaceholder": "Follow up with contact",
            "titleLabel": "Task title",
            "bodyPlaceholder": "Enter additional information you want to remember when this task is created.",
            "bodyLabel": "Task description (optional)"
        },
        "error": {
            "banner": "Please fill out the required fields",
            "title": "Oops, you need to add a title"
        },
        "daysUntilDue": {
            "sameDay": "Same day",
            "nextDay": "Next day",
            "inAWeek": "In a week",
            "custom": "Custom"
        }
    }
}
</i18n>
