import { render, staticRenderFns } from "./CreateCustomFieldModal.vue?vue&type=template&id=401f9c91&scoped=true&"
import script from "./CreateCustomFieldModal.vue?vue&type=script&lang=js&"
export * from "./CreateCustomFieldModal.vue?vue&type=script&lang=js&"
import style0 from "./CreateCustomFieldModal.vue?vue&type=style&index=0&id=401f9c91&lang=scss&rel=stylesheet%2Fscss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "401f9c91",
  null
  
)

/* custom blocks */
import block0 from "./CreateCustomFieldModal.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports