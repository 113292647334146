<template>
    <ds-tooltip position="top" :disabled="showAppointmentsPopover">
        <template #reference>
            <ds-popover
                :is-open="showAppointmentsPopover"
                position="top-start"
                hide-arrow
                sticky
                @close="closePopover"
            >
                <template #reference>
                    <ds-icon-button
                        :class="{'appointment-disabled': !hasAppointmentType}"
                        name="calendar"
                        @click="handleAppointmentButtonClick"
                    />
                </template>

                <template #default>
                    <div v-if="hasAppointmentType" class="appointment-popover">
                        <ds-list-item border-style="none" :description="$t('addBookingLink')" />

                        <ds-list-item
                            v-for="(appointmentType, i) in appointmentTypes"
                            :key="i"
                            class="appointment-type-list-item"
                            :title="appointmentTitleWithDuration(appointmentType)"
                            :short-description="bookingUrl(appointmentType)"
                            border-style="none"
                            @click.native="handleAppointmentLinkInsert(appointmentType)"
                        >
                            <template #leadingSlot>
                                <ds-icon name="link" />
                            </template>
                        </ds-list-item>
                    </div>

                    <template v-else>
                        <ds-icon-button name="x" class="close-button" @click="closePopover" />

                        <div class="setup-popover">
                            <ds-illustration name="happy-calendar" class="calendar-image" />

                            <h4 class="setup-title">
                                {{ $t('setupPopoverTitle') }}
                            </h4>

                            <p class="setup-message">
                                {{ $t('setupPopoverMessage') }}
                            </p>

                            <ds-outline-button @click="setupAppointments">
                                {{ $t('setUpAppointments') }}
                            </ds-outline-button>
                        </div>
                    </template>
                </template>
            </ds-popover>
        </template>

        <template #default>
            <div>{{ appointmentButtonTooltip }}</div>
        </template>
    </ds-tooltip>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import '@/appointments/images/happy-calendar.svg';

import authMixin from '@/shared/mixins/auth.mixin';

export default {
    mixins: [
        authMixin,
    ],

    data() {
        return {
            showAppointmentsPopover: false,
        };
    },

    /* istanbul ignore next */
    created() {
        this.load();
    },

    /* istanbul ignore next */
    mounted() {
        this.$bus.$on('APPOINTMENT_SETUP_SUCCESS', this.appointmentSetupSuccess);
    },

    /* istanbul ignore next */
    beforeDestroy() {
        this.$bus.$off('APPOINTMENT_SETUP_SUCCESS', this.appointmentSetupSuccess);
    },

    computed: {
        ...mapState({
            appointmentTypes: ({ calendar }) => calendar.apptTypes,
        }),

        ...mapGetters({
            bookingUrl: 'calendar/bookingUrl',
            isConnected: 'calendar/isAnyProviderConnected',
        }),

        hasAppointmentType() {
            return this.isConnected && this.appointmentTypes.length > 0;
        },

        appointmentButtonTooltip() {
            return this.hasAppointmentType
                ? this.$t('addBookingLink')
                : this.$t('setUpAppointments');
        },
    },

    methods: {
        async load() {
            this.auth_authorizeCalendar();
            this.auth_initializeCalendarAuthState();

            if (!this.appointmentTypes.length) {
                await this.$store.dispatch('calendar/LOAD_PROVIDER_INTEGRATIONS');
                await this.$store.dispatch('calendar/LOAD_APPT_TYPES');
            }
        },

        closePopover() {
            this.showAppointmentsPopover = false;
        },

        handleAppointmentButtonClick() {
            this.$emit('appointment-button-clicked');

            this.showAppointmentsPopover = !this.showAppointmentsPopover;
        },

        setupAppointments() {
            this.$emit('setup-appointments');
        },

        appointmentSetupSuccess() {
            this.$toast({ message: this.$t('appointmentSetupSuccess') });
        },

        appointmentTitleWithDuration(appointmentType) {
            const { name, durationMinutes } = appointmentType;
            const durationString = this.$tc('global.time.interval.abbr.minute', durationMinutes, { count: durationMinutes });

            return `${name} | ${durationString}`;
        },

        handleAppointmentLinkInsert(appointmentType) {
            this.$emit('appointment-link-insert', appointmentType);

            this.showAppointmentsPopover = false;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $calendar-image-size: px-to-rem(75px);
    $appointments-popover-height: px-to-rem(310px);

    .appointment-disabled {
        --icon-color: #{$color-gray-600};
    }

    .appointment-popover {
        @include overflowY;
        max-height: $appointments-popover-height;
        max-width: px-to-rem(300px);
        width: 100%;
        padding-bottom: $gp / 2;
    }

    .appointment-type-list-item {
        cursor: pointer;

        &:hover {
            background-color: $color-gray-050;
        }
    }

    .setup-popover {
        padding: 0 $gp $gp;
        display: flex;
        flex-direction: column;
    }

    .close-button {
        margin: $gp / 2 $gp / 2 0;
    }

    .calendar-image,
    .setup-title,
    .setup-message {
        align-self: center;
        text-align: center;
        margin-bottom: $gp;
    }

    .calendar-image {
        height: $calendar-image-size;
        width: $calendar-image-size;
    }

    .setup-message {
        color: $color-gray-700;
    }
</style>

<i18n>
{
    "en-us": {
        "addBookingLink": "Add booking link",
        "setupPopoverTitle": "Simplify appointment planning",
        "setupPopoverMessage": "Take the back-and-forth out of planning. Sync your Google Calendar, share your link, and meet.",
        "setUpAppointments": "Set up appointments",
        "appointmentSetupSuccess": "Booking link created."
    }
}
</i18n>
