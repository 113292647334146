export default {
    apptData: {
        id: '',
        location: '',
        locationType: '',
        calendarId: '',
        startDatetime: new Date(),
        availability: {
            days: [],
            selectedDay: new Date(),
            times: [],
        },
        availabilityCalendars: [],
        availabilityTimes: [],
        bookingLink: '',
        tenantId: '',
        title: '',
        summary: '',
        description: '',
        durationMinutes: 30,
        reminderTimes: [],
    },
    confirmedAppt: {},
};
