<template lang="html">
    <div v-if="notification" :class="['notification', { new: isUnread }]" @click="handleAction">
        <div class="notification-top">
            <div class="notification-info">
                <div :class="['notification-dot', { new: isUnread }]" />

                <span>{{ title }}</span>
            </div>

            <div class="notification-time-ago">
                {{ date_timeAgo(notification.createTime) }}
            </div>
        </div>

        <div class="notification-content">
            <p>{{ description }}</p>
        </div>

        <div class="button-row">
            <ds-filled-button v-if="hasAction()" @click="handleAction">
                {{ actionButtonText }}
            </ds-filled-button>

            <ds-dropdown v-if="isUnread && hasAction()" data-qa="actions">
                <template #default>
                    <ds-icon-button name="more-horizontal" />
                </template>

                <template #menu>
                    <ul class="dropdown-menu-list">
                        <li class="dropdown-menu-item" @click="markAsRead">
                            {{ $t('global.markAsRead') }}
                        </li>
                    </ul>
                </template>
            </ds-dropdown>
        </div>
    </div>
</template>

<script>
import dateMixin from '@/appointments/mixins/date.mixin';
import notificationMixin from '@/shared/mixins/notification.mixin';

export default {
    mixins: [dateMixin, notificationMixin],

    props: {
        notification: Object,
    },

    computed: {
        isUnread() {
            const notReadStatuses = ['New', 'Unread'];

            return this.notification && notReadStatuses.includes(this.notification.status);
        },

        title() {
            if (!this.notification) {
                return '';
            }

            return this.getUIFieldString('title', this.notification.contentTitle);
        },

        description() {
            if (!this.notification) {
                return '';
            }

            if (this.notification.subType === 'marketingTextReply') {
                return this.getUIFieldString('description', this.notification.contentDetails);
            }

            return this.getUIFieldString('description', this.notification.contentText);
        },

        actionButtonText() {
            if (!this.notification) {
                return '';
            }

            return this.getUIFieldString('buttonText', this.notification.actionText);
        },
    },

    methods: {
        getUIFieldString(fieldType, fallbackStr) {
            if (!this.notification) {
                return '';
            }

            const translationKey = `notification.${this.notification.subType}.${fieldType}`;
            const hasTranslationEntry = this.$i18nInstance.te(translationKey);

            if (hasTranslationEntry) {
                const valuesDictionary = this.notificationUtils_getContentDetailsAsDictionary(this.notification);

                return this.$t(translationKey, valuesDictionary);
            }

            return fallbackStr;
        },

        hasAction() {
            return this.notification && this.notificationUtils_hasAction(this.notification);
        },

        handleAction() {
            if (this.hasAction()) {
                if (this.notification) {
                    this.notificationUtils_handleAction(this.notification);
                }

                this.$store.commit('notifications/SET_NOTIFICATIONS_PANEL_OPEN', false);
            }

            if (this.isUnread) {
                this.markAsRead();
            }
        },

        markAsRead() {
            const payload = {
                id: this.notification.id,
                status: 'Read',
            };

            return this.$store.dispatch('notifications/SET_NOTIFICATION_STATUS', payload)
                .catch(() => {});
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .notification {
        background-color: $color-paper;
        user-select: none;
        padding: $gp;
        border-radius: $border-radius;
        box-shadow: $elevation-z1;

        &.new {
            background-color: $color-gray-050;
        }
    }

    .notification-top {
        display: flex;
        align-items: center;
    }

    .notification-info {
        white-space: nowrap;
        display: flex;
        align-items: center;
        flex: 1;
    }

    .notification-dot {
        background: $color-gray-600;
        width: $gp / 2;
        height: $gp / 2;
        border-radius: $border-radius;
        @include margin-end($gp / 2);

        &.new {
            background: $color-red;
        }
    }

    .notification-time-ago {
        font-size: $font-size-xsmall;
        @include margin-start($gp);
    }

    .notification-content {
        margin-top: $gp;

        p {
            margin-bottom: 0;
        }
    }

    .button-row {
        margin-top: $gp;
        align-items: center;
    }
</style>

<i18n>
{
    "en-us": {
        "notification": {
            "contactImportComplete": {
                "title": "Your contact import is complete",
                "description": "{contactsAdded} new contacts added, {contactsUpdated} contacts updated, {contactsSkipped} contacts skipped",
                "buttonText": "View imported contacts"
            },
            "invoiceViewed": {
                "title": "{contactFirstName} viewed your invoice",
                "description": "{contactFullName} viewed Invoice {invoiceId} for {invoiceTotal}",
                "buttonText": "View invoice"
            },
            "invoicePaid": {
                "title": "{payerFirstName} paid an invoice",
                "description": "{payerFullName} paid a total of {totalPaid}",
                "buttonText": "View invoice"
            },
            "quoteViewed": {
                "title": "{contactFirstName} viewed your quote",
                "description": "{contactFullName} viewed Quote {quoteId} for {quoteTotal}",
                "buttonText": "View quote"
            },
            "quoteAccepted": {
                "title": "{contactFirstName} accepted your quote",
                "description": "{contactFullName} accepted Quote {quoteId} for {quoteTotal}",
                "buttonText": "View quote"
            },
            "subscriptionFailed": {
                "title": "Recurring payment failed for {contactFullName}",
                "description": "We unsuccessfully attempted to process the recurring payment for {contactFullName} {attempts} times.",
                "buttonText": "View quote"
            }
        }
    }
}
</i18n>
