<template>
    <div class="document-signed-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div>
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="close" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            :label="$t('global.search')"
                        />
                    </div>

                    <ds-list-item
                        v-if="showAnyResult"
                        as="a"
                        class="list-item-option"
                        data-qa="document-signed-any"
                        :title="$t('anyDocumentSigned.title')"
                        :description="$t('anyDocumentSigned.description')"
                        @click="handleSelectAnyDocumentSigned"
                    />

                    <p v-if="!search" class="list-header">
                        {{ $t('documentsHeader') }}
                    </p>

                    <p v-if="noDocuments" class="empty-list">
                        {{ $t('emptyDocuments') }}
                    </p>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(document, i) in filteredDocuments"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :data-qa="`document_${document.documentId}`"
                        :title="document.title"
                        @click="handleSelection(document)"
                    />
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import { mapState } from 'vuex';
import { ANY_HELLOSIGN_DOCUMENT_SIGNED, HELLOSIGN_DOCUMENT_SIGNED } from '@/automations/constants/automations.constants';

export default {
    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedOption: {},
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapState({
            documents: ({ settings: { integrations } }) => integrations.hellosignDocuments,
        }),

        anyDocumentSignedTrigger() {
            return {
                type: ANY_HELLOSIGN_DOCUMENT_SIGNED,
                sourceId: null,
                name: this.$t('anyDocumentSigned.name'),
            };
        },

        documentSignedTrigger() {
            const id = this.selectedOption.documentId;

            return {
                type: HELLOSIGN_DOCUMENT_SIGNED,
                sourceId: id,
                name: this.$t('singleDocumentSigned', { id }),
            };
        },

        showAnyResult() {
            const search = this.search?.trim()?.toLowerCase();

            return this.$t('anyDocumentSigned.title').toLowerCase().includes(search)
                || this.$t('anyDocumentSigned.description').toLowerCase().includes(search);
        },

        filteredDocuments() {
            const search = this.search?.trim()?.toLowerCase();

            return this.documents?.filter(({ title }) => title?.toLowerCase().includes(search));
        },

        noDocuments() {
            return !this.loading && !this.documents?.length;
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredDocuments.length && !this.showAnyResult;
        },
    },

    methods: {
        init() {
            const { trigger } = this.automationProperties;

            if (trigger) {
                this.selectedOption = {
                    ...trigger,
                    id: trigger.sourceId,
                };
            } else {
                this.selectedOption = {
                    type: ANY_HELLOSIGN_DOCUMENT_SIGNED,
                    id: null,
                    name: this.$t('documentSigned'),
                };
            }

            if (!this.documents?.length) {
                this.loadBlendrDocuments();
            }
        },

        async loadBlendrDocuments() {
            this.loading = true;

            try {
                await this.$store.dispatch('settings/LOAD_HELLOSIGN_DOCUMENTS');
            } catch (e) {
                this.$error({ message: this.$t('errorLoadingDocuments') });
            }

            this.loading = false;
        },

        handleSelection(document) {
            this.selectedOption = clonedeep(document);
            this.$emit('updated', this.documentSignedTrigger);
            this.$emit('close');
        },

        handleSelectAnyDocumentSigned() {
            this.$emit('updated', this.anyDocumentSignedTrigger);
            this.$emit('close');
        },

        close() {
            const { type, sourceId } = this.documentSignedTrigger;

            if (type === HELLOSIGN_DOCUMENT_SIGNED && !sourceId) {
                this.handleSelectAnyDocumentSigned();
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "anyDocumentSigned": {
            "name": "Any HelloSign document is signed",
            "title": "Any document",
            "description": "When any HelloSign document is signed"
        },
        "documentSigned": "HelloSign document is signed",
        "singleDocumentSigned": "HelloSign document ({id}) is signed",
        "emptyDocuments": "None available",
        "header": "Choose HelloSign document",
        "documentsHeader": "Documents",
        "emptySearch": "No results found for \"{search}\"",
        "errorLoadingDocuments": "There was an issue loading your HelloSign templates."
    }
}
</i18n>
