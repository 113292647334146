<template>
    <controlled-modal
        class="setup-complete-modal"
        data-qa="setup-complete-modal"
        :is-open="isOpen"
        show-close
        @close="close"
    >
        <section class="content">
            <h1 class="title">
                {{ $t('title') }}
            </h1>

            <ds-illustration class="success-illustration" name="success-confetti-color" />

            <span class="phone-number">{{ formattedPhoneNumber }}</span>

            <span class="description">{{ $t('description') }}</span>

            <ds-filled-button class="action-button" @click="sendFirstBroadcast">
                {{ actionText }}
            </ds-filled-button>
        </section>

        <section class="resources">
            <h3 class="review-title">
                {{ $t('reviewTitle') }}
            </h3>

            <span class="review-description"> {{ $t('reviewDescription') }} </span>

            <a
                :href="complianceLink"
                class="links"
                rel="noopener noreferer"
                target="_blank"
            >
                {{ $t('complianceLink') }}

                <ds-icon class="link-icon" name="external-link" />
            </a>

            <span class="resources-subtext">{{ $t('compliance') }}</span>

            <a
                :href="bestPracticesLink"
                class="links"
                rel="noopener noreferer"
                target="_blank"
            >
                {{ $t('bestPracticesLink') }}

                <ds-icon class="link-icon" name="external-link" />
            </a>

            <span class="resources-subtext">{{ $t('bestPractices') }}</span>

            <a
                :href="tcpaLink"
                class="links"
                rel="noopener noreferer"
                target="_blank"
            >
                {{ $t('tcpaLink') }}

                <ds-icon class="link-icon" name="external-link" />
            </a>

            <span class="resources-subtext">{{ $t('tcpa') }}</span>
        </section>
    </controlled-modal>
</template>

<script>
import { mapState } from 'vuex';

import ControlledModal from '@/shared/components/ControlledModal';

import { formatPhone } from '@/shared/utils/phone.util';

export default {
    components: { ControlledModal },

    props: {
        isOpen: Boolean,
        buttonText: String,
    },

    data() {
        return {
            complianceLink: 'https://www.twilio.com/learn/call-and-text-marketing/guide-to-us-sms-compliance',
            bestPracticesLink: 'https://www.ctia.org/the-wireless-industry/industry-commitments/messaging-interoperability-sms-mms',
            tcpaLink: 'https://www.fdic.gov/regulations/compliance/manual/8/viii-5.1.pdf',
        };
    },

    computed: {
        ...mapState({
            phoneNumber: ({ auth }) => auth.account.automatedSmsAccount?.phoneNumber,
        }),

        formattedPhoneNumber() {
            return formatPhone(this.phoneNumber);
        },

        actionText() {
            return this.buttonText || this.$t('continue');
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        sendFirstBroadcast() {
            this.close();
            this.$emit('complete');
        },
    },
};
</script>

<style lang="scss" scoped>
.links {
    font-size: px-to-rem(16px);
    color: $color-black;
    padding-bottom: $gp / 8;
}

.link-icon {
    --icon-size: #{$gp};
}

.resources-subtext {
    color: $color-text-disabled;
    padding-bottom: $gp;
}

.setup-complete-modal {
    --modal-header-border: none;
    --modal-padding: 0;
}

.content {
    padding: 0 $gp * 1.5 $gp * 1.5;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.title {
    font-family: $font-family-secondary-title;
    font-weight: 600;
    margin: 0 $gp $gp * 2.5;
}

.success-illustration {
    margin-bottom: $gp * 2.75;
}

.phone-number {
    font-size: $font-size-huge;
    font-weight: 600;
    margin-bottom: $gp;
}

.description {
    color: $color-ink-800;
    padding: 0 $gp * 3 $gp * 1.5;
    font-size: $font-size-small;
}

.resources {
    display: flex;
    flex-direction: column;
    padding: $gp * 1.5 $gp * 2 $gp / 2;
    background-color: $color-orange-050;
    background-clip: border-box;
    border-radius: 0 0 $border-radius $border-radius;
}

.review-title {
    padding-bottom: $gp;
    font-weight: 500;
}

.review-description {
    line-height: $line-height-med;
    padding-bottom: $gp;
}

.link-list {
    @include padding-start($gp);

    list-style: disc outside;
    --icon-size: #{px-to-rem(13.33px)};
}
</style>

<i18n>
{
    "en-us": {
        "title": "Your Marketing Number is ready!",
        "description": "This is your assigned Keap Marketing Number. Your recipients will see this number every time you send an automated or broadcast text message.",
        "continue": "Continue",
        "reviewTitle": "Review this important info",
        "reviewDescription": "Please review the resources below, including the Telephone Consumer Protection Act and carrier rules. They cover permissions, appropriate times to send, opt-in rules, and more. These links open in a new window. Be sure to bookmark them to review later.",
        "complianceLink": "Guide to U.S. SMS Compliance",
        "compliance": "Twilio",
        "bestPracticesLink": "Messaging Principles & Best Practices",
        "bestPractices": "Cellular Telecommunications Industry Association",
        "tcpaLink": "Telephone Consumer Protection Act",
        "tcpa": "TCPA"
    }
}
</i18n>
