import axios from 'axios';

import { KEAP_SALES_NUMBER } from '@/shared/constants/phone.constants';

export const fetchCountries = () => {
    return axios.get(`${process.env.VUE_APP_LIFECYCLE_API_URL}/v2/countries`);
};

export const fetchCurrentPromo = async () => {
    const { data } = await axios.get(`${process.env.VUE_APP_LIFECYCLE_API_URL}/v1/prices?include=promoCode&promoCode=current`);

    return data.promoCode;
};

export const fetchPrices = ({
    appName,
    country,
    currencyToUse,
    globalUserId,
    postalCode,
    promoCode,
}) => {
    const params = {
        appName,
        country,
        currencyToUse,
        globalUserId,
        postalCode,
        promoCode,
    };

    return axios.get(`${process.env.VUE_APP_LIFECYCLE_API_URL}/v1/prices`, { params });
};

export const upgradeApp = async ({
    addressInfo,
    appName,
    currencyIsoCode,
    paymentInfo,
    promoCode,
    selectedContactPlans,
    selectedEdition,
    selectedUserPlans,
    user,
}) => {
    const params = {
        appName,
        billingAddress: {
            street1: addressInfo.address1,
            street2: addressInfo.address2,
            city: addressInfo.city,
            state: addressInfo.state,
            postalCode: addressInfo.postalCode,
            country: addressInfo.country,
        },
        creditCard: {
            nameOnCard: paymentInfo.nameOnCard,
            cardNumber: paymentInfo.cardNumber,
            code: paymentInfo.cvv,
            expirationDate: paymentInfo.exp,
        },
        currencyIsoCode,
        currentUser: {
            firstName: user.givenName,
            lastName: user.familyName,
            email: user.email,
            phone: user.phone,
            globalUserId: user.casId,
            contactId: user.id,
        },
        editionName: selectedEdition.name,
        items: [
            { itemId: selectedEdition.productId, quantity: 1 },
            ...selectedContactPlans.map((plan) => ({ itemId: plan.productId, quantity: plan.quantity })),
            ...selectedUserPlans.map((plan) => ({ itemId: plan.productId, quantity: plan.quantity })),
        ],
        promoCode,
    };

    try {
        return await axios.put(`${process.env.VUE_APP_LIFECYCLE_API_URL}/v1/applications`, params);
    } catch (error) {
        throw new Error(parsePurchaseErrorMessage(error));
    }
};

const parsePurchaseErrorMessage = (error) => {
    if (error.response && error.response.status === 403) {
        return 'We are unable to process your purchase at this time due to the domain/email you are using. '
            + `If you believe you are receiving this message in error, we would love to speak to you at ${KEAP_SALES_NUMBER}.`;
    }

    if (error.response && error.response.data && error.response.data.errors) {
        return error.response.data.errors.map((e) => {
            if (e.toLowerCase().includes('card number')) {
                return 'Invalid card number';
            }

            return e;
        }).join(', ');
    }

    return error.message;
};
