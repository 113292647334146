import moment from 'moment';
import groupBy from 'lodash.groupby';

import { INTEGRATION_STATUS } from '@/appointments/appointments.constants';

export default {
    hasApptType: ({ apptTypes, apptTypesLoaded }) => {
        return Boolean(apptTypesLoaded && apptTypes.length > 0);
    },

    isProviderConnected: (state, { connectedCalendarProvider }) => (providerId) => {
        return Boolean(connectedCalendarProvider(providerId));
    },

    connectedProviders: ({ providers }) => {
        return providers && providers.filter(({ status }) => status === INTEGRATION_STATUS.READY) || [];
    },

    connectedCalendarProvider: (state, { connectedProviders }) => (providerId) => {
        return connectedProviders.filter((p) => p.name === providerId && p.calendars).shift();
    },

    writeCalendars: (state, { connectedCalendarProvider }) => (calendarProviderId) => {
        const p = connectedCalendarProvider(calendarProviderId);

        return p && p.calendars && p.calendars.length && p.calendars.filter((calendar) => {
            return calendar.accessLevel === 'write';
        }) || [];
    },

    orderedCalendars: (state, { writeCalendars, connectedCalendarProvider }) => (calendarProviderId) => {
        const calendars = writeCalendars(calendarProviderId);
        const provider = connectedCalendarProvider(calendarProviderId);

        calendars.sort((a, b) => {
            if (a.id === provider.email) {
                return -1;
            }

            if (b.id === provider.email) {
                return 1;
            }

            if (a.title < b.title) {
                return -1;
            }

            if (a.title > b.title) {
                return 1;
            }

            return 0;
        });

        return calendars;
    },

    allConnectedCalendars: (state, { connectedProviders }) => {
        return connectedProviders
            .reduce((calendars, current) => calendars.concat(current.calendars), [])
            .sort((a, b) => {
                const providerEmaila = connectedProviders.some((p) => a.id === p.email);
                const providerEmailb = connectedProviders.some((p) => b.id === p.email);

                if (providerEmaila) {
                    return -1;
                }

                if (providerEmailb) {
                    return 1;
                }

                if (a.title < b.title) {
                    return -1;
                }

                if (a.title > b.title) {
                    return 1;
                }

                return 0;
            });
    },

    hasProviderWithCalendars: (state, getters) => {
        const { connectedProviders } = getters;

        return Boolean(connectedProviders.find((p) => p.calendars && p.calendars.length > 0));
    },

    isAnyProviderConnected: (state, getters) => {
        const { connectedProviders } = getters;

        return connectedProviders.length > 0;
    },

    isAnyProviderBroken: ({ providers }, { isProviderBroken }) => {
        return providers && providers.some((p) => isProviderBroken(p));
    },

    isProviderBroken: () => (provider) => {
        return provider.status === INTEGRATION_STATUS.BROKEN;
    },

    emailConnected: ({ providers }) => (providerId) => {
        const matchingProviders = providers.filter((p) => {
            return p.name === providerId && p.email;
        });

        return matchingProviders.length ? matchingProviders[0].email : null;
    },

    providerCalendars: ({ providers }) => (providerId) => {
        if (!providers || !providers.length) {
            return [];
        }
        const matchingProviders = providers.filter((p) => {
            return p.name === providerId && p.calendars;
        });

        return matchingProviders.length ? matchingProviders[0].calendars : [];
    },

    totalAppointmentTypes: ({ apptTypes }) => {
        return apptTypes.length;
    },

    filteredAppts: ({ appts, appointmentFilter }) => {
        const { appointmentViewOption, visibleCalendars } = appointmentFilter;

        const result = appts.filter((appt) => {
            if (appointmentViewOption === 'appts') {
                if (!appt.attendees.length) {
                    return false;
                }

                if (!appt.attendees[0].contactId) {
                    return false;
                }
            }

            if (visibleCalendars.length && appointmentViewOption !== 'appts') {
                return visibleCalendars.indexOf(appt.calendarId) !== -1;
            }

            return true;
        });

        return result;
    },

    apptsByDate: ({ appts }) => {
        return groupBy(appts, ({ startDate }) => {
            const isPastDue = moment().diff(startDate, 'days') > 0;
            const date = isPastDue ? moment(new Date()).subtract(1, 'days') : startDate;

            return moment(date).startOf('day').toISOString(true);
        });
    },

    videoCallUrl: () => {
        return `${process.env.VUE_APP_BOOKING_BASE_URL}videocall/~bookingLink~/~appointmentId~`;
    },

    bookingUrl: () => (apptType, display) => {
        const url = `${process.env.VUE_APP_BOOKING_BASE_URL}${apptType.bookingLink}`;

        return display ? url.replace('https://', '').replace('http://', '') : url;
    },

    durations: () => {
        return [
            { value: 15, label: 'global.time.interval.minute', tcargs: { count: 15 } },
            { value: 30, label: 'global.time.interval.minute', tcargs: { count: 30 } },
            { value: 45, label: 'global.time.interval.minute', tcargs: { count: 45 } },
            { value: 60, label: 'global.time.interval.hour', tcargs: { count: 1 } },
            { value: 90, label: 'global.time.interval.minute', tcargs: { count: 90 } },
            { value: 120, label: 'global.time.interval.hour', tcargs: { count: 2 } },
            { value: -1, label: 'global.custom', tcargs: { count: 0 } },
        ];
    },

    customHours: () => {
        return [
            { value: 0, label: '0' },
            { value: 1, label: '1', tcargs: { count: 1 } },
            { value: 2, label: '2', tcargs: { count: 2 } },
            { value: 3, label: '3', tcargs: { count: 3 } },
            { value: 4, label: '4', tcargs: { count: 4 } },
            { value: 5, label: '5', tcargs: { count: 5 } },
            { value: 6, label: '6', tcargs: { count: 6 } },
            { value: 7, label: '7', tcargs: { count: 7 } },
            { value: 8, label: '8', tcargs: { count: 8 } },
            { value: 9, label: '9', tcargs: { count: 9 } },
            { value: 10, label: '10', tcargs: { count: 10 } },
            { value: 11, label: '11', tcargs: { count: 11 } },
            { value: 12, label: '12', tcargs: { count: 12 } },
        ];
    },

    customMinutes: () => {
        return [
            { value: 0, label: '0' },
            { value: 15, label: '15', tcargs: { count: 15 } },
            { value: 30, label: '30', tcargs: { count: 30 } },
            { value: 45, label: '45', tcargs: { count: 45 } },
        ];
    },

    buffers: () => {
        return [
            { value: 0, label: 'appointments.noBuffer' },
            { value: 15, label: 'global.time.interval.minute', tcargs: { count: 15 } },
            { value: 30, label: 'global.time.interval.minute', tcargs: { count: 30 } },
            { value: 45, label: 'global.time.interval.minute', tcargs: { count: 45 } },
        ];
    },

    calendarHash: () => {
        const hashFunction = window.crypto.subtle;
        const hasher = async (value) => {
            const encoder = new TextEncoder();
            const data = encoder.encode(value);
            const buffer = await hashFunction.digest('SHA-256', data);
            const byteArray = new Uint8Array(buffer);
            const hexCodes = [...byteArray].map((val) => {
                const hexCode = val.toString(16);
                const paddedHexCode = hexCode.padStart(2, '0');

                return paddedHexCode;
            });

            return hexCodes.join('');
        };

        return hasher;
    },

    getCalendarHashMap: (state, getters) => {
        return Promise.all(getters.allConnectedCalendars.map(async (c) => {
            return {
                value: c,
                hash: await getters.calendarHash(c.id),
            };
        }));
    },

    getAppointmentTypeById: ({ apptTypes }) => (id) => {
        return apptTypes?.find((type) => type.id === id);
    },
};
