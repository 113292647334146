<template>
    <appointment-trigger
        :scheduled-or-cancelled-type="$options.type"
        :automation-properties="automationProperties"
        :trigger-action="triggerAction"
        :save-status="saveStatus"
        @updated="(payload) => $emit('updated', payload)"
        @close="$emit('close')"
    />
</template>

<script>
import { APPOINTMENT_CANCELLED } from '@/automations/constants/automations.constants';
import AppointmentTrigger from './AppointmentTrigger';

export default {
    type: APPOINTMENT_CANCELLED,

    components: {
        AppointmentTrigger,
    },

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
        triggerAction: {
            type: String,
            default: '',
        },
        saveStatus: String,
    },
};
</script>
