import intercom from '@/analytics/intercom';
import amplitude from '@/analytics/amplitude';
import moment from 'moment';

import { mapState } from 'vuex';
import { SAVING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import { AUTOMATION_STATUS, SAVE_STATUS } from '@/automations/constants/automations.constants';
import asyncDebounce from '@/shared/utils/asyncDebounce';
import { DISPLAY_DATE_FORMAT_WITH_YEAR } from '@/shared/constants/dateFormats.constants';

export default {
    props: {
        automation_updateDelay: {
            type: Number,
            default: SAVING_DEBOUNCE_DELAY,
        },
    },

    data() {
        return {
            automation_buttonLoading: false,
            automations: [],
        };
    },

    mounted() {
        if (this.automation_updateDelay > 0) {
            this.automation_callSave = asyncDebounce(this.automation_callSave, this.automation_updateDelay);
            this.automation_callRename = asyncDebounce(this.automation_callRename, this.automation_updateDelay);
        }
    },

    computed: {
        ...mapState({
            automation_contextType: ({ automations }) => automations.context?.type,
        }),

        automation_defaultAutomation() {
            const date = moment().format(DISPLAY_DATE_FORMAT_WITH_YEAR);

            return {
                id: null,
                name: this.$t('automation.defaultTitle', { date }),
                description: this.$t('automation.defaultTitle'),
                enabledDate: null,
                triggers: [],
                steps: [],
            };
        },
    },

    methods: {
        async automation_load(id) {
            let automation = {};

            try {
                automation = await this.$store.dispatch('automations/LOAD_AUTOMATION', id);

                if (!automation) {
                    this.$router.replace({ name: 'page.not.found' });
                } else {
                    this.$store.commit('automations/SET_AUTOMATION', automation);
                }
            } catch {
                this.$error({ message: this.$t('automation.error.loading') });
            }

            return automation;
        },

        async automation_loadAndSetIndex(id) {
            this.loading = true;
            let automation;

            try {
                automation = await this.$store.dispatch('automations/LOAD_AUTOMATION', id);
                const index = this.automations.findIndex(({ id: i }) => i === id);

                if (index >= 0) {
                    this.$set(this.automations, index, automation);
                }
            } catch {
                this.$error({ message: this.$t('automation.error.loading') });
            }

            this.loading = false;

            return automation;
        },

        async automation_create(automation = this.automation_defaultAutomation, shouldLogAmplitude = true) {
            try {
                const { id } = await this.$store.dispatch('automations/CREATE_AUTOMATION', automation);

                intercom.logEvent(intercom.events.AUTOMATION_CREATED);

                if (shouldLogAmplitude) {
                    amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_CREATED, {
                        'Event Source': 'Easy Automation',
                        'Automation Context': this.automation_contextType,
                    });
                }

                return { id };
            } catch {
                this.$error({ message: this.$t('automation.error.creating') });

                return null;
            }
        },

        automation_confirmRevert({ id }) {
            return this.$confirm({
                optionTitle: this.$t('automation.revertEdits.confirmTitle'),
                optionMessage: this.$t('automation.revertEdits.confirmMessage'),
                optionConfirmButtonLabel: this.$t('automation.revertEdits.button'),
                destructive: true,
                size: 'sm',
            }).then(() => {
                return this.automation_revert({ id });
            }).catch(() => {});
        },

        async automation_revert({ id }) {
            try {
                await this.$store.dispatch('automations/REVERT_EDITS', id);
                this.$toast({ message: this.$t('automation.success.revert') });
                this.automation_loadAndSetIndex(id);
            } catch {
                this.$error({ message: this.$t('automation.error.revert') });
            }
        },

        async automation_confirmDisable({ id }) {
            try {
                await this.$confirm({
                    optionTitle: this.$t('automation.disable.confirmTitle'),
                    optionMessage: this.$t('automation.disable.confirmMessage'),
                    optionConfirmButtonLabel: this.$t('automation.disable.button'),
                    destructive: true,
                    size: 'sm',
                });

                return await this.automation_disableAndUpdateList({ id });
            } catch { return false; }
        },

        // requires automation list object on consuming component
        // TODO return updated automations list instead
        async automation_disableAndUpdateList({ id }) {
            const success = await this.automation_disable({ id });

            if (success) {
                const index = this.automations.findIndex(({ id: i }) => i === id);

                if (index >= 0) {
                    this.$set(this.automations[index], 'status', AUTOMATION_STATUS.DISABLED);
                    this.$set(this.automations[index], 'hasChanges', false);
                }
            }

            return success;
        },

        async automation_publish(automation) {
            this.automation_buttonLoading = true;

            try {
                await this.$store.dispatch('automations/PUBLISH_AUTOMATION', automation.id);
                intercom.logEvent(intercom.events.AUTOMATION_PUBLISHED);

                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                    'Event Source': 'Publish',
                    'Automation Context': this.automation_contextType,
                    'Automation Steps': automation?.steps?.map(({ type }) => type),
                    'Automation Triggers': automation?.triggers?.map(({ type }) => type),
                    'Automation Stop Triggers': automation?.removeTriggers?.map(({ type }) => type),
                });

                this.automation_buttonLoading = false;

                return true;
            } catch (e) {
                this.$error({ message: this.$t('automation.error.publishing') });
            }

            this.automation_buttonLoading = false;

            return false;
        },

        async automation_confirmDelete({ id, name }, list) {
            try {
                await this.$confirm({
                    optionTitle: this.$t('automation.delete.confirmTitle'),
                    optionMessage: this.$t('automation.delete.confirmMessage', { name }),
                    optionConfirmButtonLabel: this.$t('automation.delete.button'),
                    optionCancel: this.$t('automation.delete.cancel'),
                    destructive: true,
                });

                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_DELETED, {
                    'Automation Context': this.automation_contextType,
                });

                return this.automation_delete(id, list);
            } catch { /* cancel */ }

            return { success: false, list };
        },

        async automation_delete(id, list) {
            try {
                const index = list.findIndex(({ id: i }) => i === id);
                const success = await this.$store.dispatch('automations/DELETE_AUTOMATION', { id });

                if (success && index >= 0) {
                    list.splice(index, 1);

                    this.$toast({ message: this.$t('automation.success.deleting') });
                } else {
                    this.$error({ message: this.$t('automation.error.deleting') });
                }

                return { success, list };
            } catch {
                this.$error({ message: this.$t('automation.error.deleting') });
            }

            return { success: false, list };
        },

        // requires automation list object on consuming component
        // TODO return updated automations list instead
        async automation_updateNameAndList(automation, showToast = true) {
            this.automation_setStatus(SAVE_STATUS.SAVING);

            try {
                await this.automation_callRename(automation, automation.name);

                if (this.automations) {
                    const index = this.automations.findIndex(({ id: i }) => i === automation.id);

                    if (index >= 0) {
                        this.$set(this.automations[index], 'name', automation.name);

                        if (showToast) {
                            this.$toast({ message: this.$t('automation.success.updating') });
                        }
                    }
                }

                return true;
            } catch {
                this.$error({ message: this.$t('automation.error.updating') });
            }

            return false;
        },

        automation_setStatus(status) {
            this.$store.commit('automations/SET_SAVE_STATUS', status);
        },

        automation_handleRename(automation, name) {
            this.automation_setStatus(SAVE_STATUS.SAVING);
            this.$store.commit('automations/SET_AUTOMATION', { ...automation, name });
            this.automation_callRename(automation, name);
        },

        async automation_callRename(automation, name) {
            try {
                await this.$store.dispatch('automations/RENAME_AUTOMATION', { id: automation.id, name });
                this.automation_setStatus(SAVE_STATUS.SUCCESS);
            } catch {
                this.automation_setStatus(SAVE_STATUS.FAILED);
                this.$error({ message: this.$t('automation.error.updating') });
            }
        },

        async automation_save(automation) {
            this.automation_setStatus(SAVE_STATUS.SAVING);
            this.$store.commit('automations/SET_AUTOMATION', automation);

            const updatedAutomation = await this.automation_callSave(automation);

            return updatedAutomation;
        },

        async automation_copy({ id }) {
            try {
                const automation = await this.$store.dispatch('automations/LOAD_AUTOMATION', id);

                const automationToCreate = {
                    ...automation,
                    id: undefined,
                    name: this.$t('automation.copyOfTitle', { name: automation.name }),
                };

                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_CREATED, {
                    'Event Source': 'Copy Automation',
                    'Automation Context': this.automation_contextType,
                });

                const createdAutomation = await this.automation_create(automationToCreate, false);

                return createdAutomation;
            } catch {
                this.$error({ message: this.$t('automation.error.copy') });

                return null;
            }
        },

        async automation_callSave(automation) {
            try {
                const updatedAutomation = await this.$store.dispatch('automations/UPDATE_AUTOMATION', automation);

                if (updatedAutomation?.hasChanges !== automation?.hasChanges) {
                    this.$store.commit('automations/SET_AUTOMATION_HAS_CHANGES', updatedAutomation.hasChanges);
                    this.$bus.$emit('PUBLISHED_AUTOMATION_UPDATED');
                }
                this.automation_setStatus(SAVE_STATUS.SUCCESS);

                return updatedAutomation;
            } catch {
                this.automation_setStatus(SAVE_STATUS.FAILED);
                this.$error({ message: this.$t('automation.error.updating') });

                return automation;
            }
        },

        async automation_disable(automation) {
            this.automation_buttonLoading = true;

            try {
                await this.$store.dispatch('automations/DISABLE_AUTOMATION', automation.id);
                amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                    'Event Source': 'Disable',
                    'Automation Context': this.automation_contextType,
                });
                this.$toast({ message: this.$t('automation.success.disabling') });
                this.automation_buttonLoading = false;

                return true;
            } catch {
                this.$error({ message: this.$t('automation.error.disabling') });
            }

            this.automation_buttonLoading = false;

            return false;
        },
    },
};
