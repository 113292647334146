<template>
    <div class="keap-toggle-info">
        <ds-icon-button name="x" class="close-button" @click="close" />

        <h1 class="title">
            {{ keapMaxRebrand ? $t('title') : $t('titleOld') }}
        </h1>

        <span class="description light">{{ $t('description') }}</span>

        <ds-filled-button class="start-button" @click="close">
            {{ $t('buttonText') }}
        </ds-filled-button>

        <div class="video-wrapper">
            <iframe
                :src="videoPlaceholder"
                allowtransparency="true"
                frameborder="0"
                scrolling="no"
                class="wistia_embed"
                name="wistia_embed"
                allowfullscreen
                width="620"
                height="349"
            />
        </div>

        <small class="switch">{{ $t('switch') }}</small>

        <h2 class="sub-title">
            {{ $t('newFeatures') }}
        </h2>

        <ul>
            <li>{{ $t('newFeature.1') }}</li>
            <li>{{ $t('newFeature.2') }}</li>
            <li>{{ $t('newFeature.3') }}</li>
            <li>{{ $t('newFeature.4') }}</li>
            <li>{{ $t('newFeature.5') }}</li>
            <li>{{ $t('newFeature.6') }}</li>
        </ul>

        <h2 class="sub-title">
            {{ $t('existingFeatures') }}
        </h2>

        <ul>
            <li>{{ $t('existingFeature.1') }}</li>
            <li>{{ $t('existingFeature.2') }}</li>
            <li>{{ $t('existingFeature.3') }}</li>
        </ul>

        <hr />

        <i18n path="feedback.1" tag="span">
            <template #link>
                <a
                    class="feedback-link"
                    :href="keapMaxRebrand ? feedbackUrl : feedbackUrlOld"
                    target="_blank"
                    rel="noopener noreferrer"
                >{{ $t('letUsKnow') }}</a>
            </template>
        </i18n>

        <span>{{ $t('feedback.2') }}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { FF_KEAP_MAX_REBRAND } from '@/shared/constants/featureFlag.constants';

export default {
    props: {
        loadOnMount: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            feedbackUrlOld: 'https://help.infusionsoft.com/help/new-infusionsoft-look',
            feedbackUrl: 'https://help.keap.com/help/keap-max',
            videoPlaceholder: '//fast.wistia.net/embed/iframe/n24dlqg4qk',
        };
    },

    mounted() {
        if (this.loadOnMount) {
            this.load();
        }
    },

    computed: {
        ...mapState({
            keapMaxRebrand: ({ featureFlags }) => featureFlags[FF_KEAP_MAX_REBRAND],
        }),
    },

    methods: {
        load() {
            this.$store.dispatch('tutorials/UPDATE_TUTORIAL_ITEM', {
                key: TUTORIAL_TYPES.KEAP_TOGGLE,
                value: true,
            });
        },

        close() {
            this.$bus.$emit('POP_ALL_NESTED_MODALS');
        },
    },
};
</script>

<style lang="scss" scoped>
    .keap-toggle-info {
        display: flex;
        flex-direction: column;
        padding: 0 $gp * 2 $gp * 2;
    }

    .close-button {
        position: absolute;
        top: $gp;
        @include position-start($gp);
    }

    .title {
        padding: $gp 0;
        align-self: center;
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        font-size: $font-size-jumbo;
    }

    .description {
        font-size: $font-size-med;
        text-align: center;
        align-self: center;
    }

    .start-button {
        margin: $gp 0;
        align-self: center;
    }

    .switch {
        align-self: center;
        color: $color-gray-700;
        padding: $gp * 2 0;
    }

    .sub-title {
        padding: $gp / 2 0;
        font-family: $font-family-secondary-title;
        font-weight: $font-weight-bold;
        font-size: $font-size-huge;
    }

    ul {
        @include padding-start($gp);
    }

    li {
        padding: $gp / 2 0 $gp / 2 $gp /2;
        list-style-type: disc;
        font-weight: $font-weight-light;
    }

    hr {
        margin: $gp;
    }

    .feedback-link {
        color: $color-ink;
        text-decoration: underline;
        font-weight: $font-weight-bold;
    }

    .video-wrapper {
        position: relative;
        padding-bottom: 52.25%;
        padding-top: px-to-rem(25px);
        height: auto;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "titleOld": "Infusionsoft is getting a refresh",
        "title": "Keap Max is getting a refresh",
        "description": "It's time to put an updated, refreshed casing on an already powerful engine. Expect more intuitive designs across the board",
        "buttonText": "Start exploring",
        "switch": "Switch between the existing and updated versions as much as you'd like",
        "newFeatures": "Enjoy these new features and updates:",
        "newFeature": {
            "1": "Messages (desktop texting and soon calling)",
            "2": "Pipeline (sales and process management)",
            "3": "Appointments (Easy, hassle-free scheduling)",
            "4": "Quotes and Invoices (Interconnected features that simplify earning work and getting paid for it)",
            "5": "Contact record updates to provide greater visibility in one comprehensive view",
            "6": "Sweeping changes to optimize the mobile experience (which complements the new stand-alone Keap iOS app)"
        },
        "existingFeatures": "Here's what hasn't changed:",
        "existingFeature": {
            "1": "Your data (including the tags, contacts, custom fields, etc.) is untouched and saved in the same database as always",
            "2": "Campaign Builder is the same and just as powerful as ever, still containing your same configured automations",
            "3": "Tags and Broadcasts remain unchanged (for now)"
        },
        "feedback": {
            "1": "Please take a look around and {link} your thoughts.",
            "2": "Your feedback will help us continually improve the experience"
        },
        "letUsKnow": "let us know"
    }
}
</i18n>
