import {
    AllSalesFields,
    EmailEngagementEmailFields,
    EmailEngagementContactEngagementFields,
    ReportTimeSpanOptionValues,
} from '@/reports/reports.constants';
import { calculateInterval } from '@/reports/reports.utils';

export default {
    isDownloading: false,
    isLoadingChart: false,
    allSales: {
        aggregatedData: null,
        data: [],
        sortField: AllSalesFields.SALE_DATE,
        ascending: true,
    },
    emailEngagement: {
        aggregatedData: null,
        emails: {
            data: [],
            sortField: EmailEngagementEmailFields.EMAIL_NAME,
            ascending: true,
        },
        contactEngagement: {
            data: [],
            sortField: EmailEngagementContactEngagementFields.CONTACT_NAME,
            ascending: true,
        },
    },
    interval: calculateInterval(ReportTimeSpanOptionValues.LAST_30_DAYS),
};
