<template lang="html">
    <div>
        <ds-icon-button name="x" class="close-icon" @click="close" />

        <h3 class="company-modal-title">
            {{ $t('companyTitle') }}
        </h3>

        <router-link
            class="company-card"
            data-qa="view-company-record"
            :to="{ name: 'contacts.company.record', params: { companyId: companyId }}"
            @click.native="close"
        >
            <div class="avatar-wrapper">
                <ds-avatar type="company" :size="80" />
            </div>

            <h5>{{ companyName }}</h5>

            <hr v-if="contactsAddedDetails" />

            <div class="data">
                <div v-if="contactsAddedDetails" data-qa="view-contact-actions">
                    <div class="title-text">
                        You added {{ assignedContacts.length }} contacts
                    </div>
                    <ds-chip
                        v-for="contacts in assignedContacts"
                        :key="contacts.fullName"
                        class="icon-space"
                    >
                        <ds-avatar
                            :size="16"
                            class="avatarImage"
                            :email="contacts.email"
                        />

                        {{ contacts.fullName }}
                    </ds-chip>
                </div>
            </div>
        </router-link>

        <div class="buttons">
            <div class="button-wrapper">
                <ds-filled-button
                    class="block"
                    data-qa="view-contact-action"
                    @click="openCompanyRecord"
                >
                    {{ $t("viewCompanyRecordButton") }}
                </ds-filled-button>
            </div>

            <div class="button-wrapper">
                <ds-outline-button data-qa="add-company-button" class="button-style" @click="isAddOpen = true">
                    {{ $t('addAnotherCompany') }}
                </ds-outline-button>
            </div>

            <add-company-modal
                v-if="isAddOpen"
                data-qa="add-company-modal"
                event-source="Company List View"
                @close="isAddOpen = false"
            />
        </div>
    </div>
</template>

<script>
import '@/import/images/import-contacts-status.svg';
import intercom from '@/analytics/intercom';
import fullstory from '@/analytics/fullstory';
import AddCompanyModal from '@/contacts/components/companies/AddCompanyModal';

export default {
    components: {
        AddCompanyModal,
    },

    props: {
        contactsAdded: Array,
    },

    data() {
        return {
            contact: {},
            companyId: '',
            companyName: '',
            assignedContacts: [],
            isAddOpen: false,
            loading: false,
            baseUrl: process.env.BASE_URL,
        };
    },

    computed: {
        contactsAddedDetails() {
            return this.assignedContacts.length > 0;
        },
    },

    methods: {
        nestedModal_open(companyData) {
            this.companyId = companyData.id;
            this.companyName = companyData.companyName;
            this.assignedContacts = companyData.assignedContacts;
        },

        openCompanyRecord () {
            this.$router.push({
                name: 'contacts.company.record',
                params: {
                    companyId: this.companyId,
                },
            });

            intercom.logEvent(intercom.events.COMPANY_RECORD_VIEWED);

            fullstory.event(intercom.events.COMPANY_RECORD_VIEWED, {
                'Event Source': 'Company Added Success Modal',
            });

            this.close();
        },

        close() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },
    },
};
</script>

<style lang='scss' rel='stylesheet/scss' scoped>
.company-modal-title {
    text-align: center;
    padding-bottom: $gp / 2;
}

h3 {
    font-family: $font-family-secondary-title;
    font-weight: bold;
    font-size: px-to-rem(28px);

    @media ($medium) {
        font-size: px-to-rem(32px);
    }
}

.close-icon {
    display: flex;
    margin-bottom: $gp;
}

h5 {
    font-size: px-to-rem(18px);
    font-weight: bold;

    @media ($medium) {
        font-size: px-to-rem(22px);
    }
}

hr {
    width: 320px;
    max-width: 100%;
    margin-top: $gp;
    margin-bottom: $gp;
}

.buttons {
    margin: $gp * 2 auto 0;
    max-width: px-to-rem(200px);
}

.button-wrapper {
    margin-bottom: $gp;
}

.button-style {
    width : 100%;
}

.company-card {
    @include card;
    max-width: px-to-rem(375px);
    background-color: $color-gray-050;
    padding: $gp / 2 0;
    margin: $gp * 4 auto $gp;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: $color-ink;

    h5 {
        text-align: center;
    }
}

.data {
    margin-bottom: $gp;
}

.icon-space {
    margin: $gp / 4;
}

.title-text {
    font-size: $font-size-med;
    margin-bottom: $gp;
    text-align: center;
}

.avatarImage {
    bottom: $gp * -0.1;
    padding: 0 4px;
}

.avatar-wrapper {
    @include transition(box-shadow);

    margin-top: px-to-rem(-55px);
    margin-bottom: px-to-rem(15px);

    position: relative;
    cursor: pointer;
    border-radius: 100%;

    &:hover {
        box-shadow: $elevation-z8;
    }
}
</style>

<i18n>
{
    "en-us": {
        "companyTitle": "Take a peek at your new company",
        "addAnotherCompany": "Add another company",
        "viewCompanyRecordButton": "View company record"
    }
}
</i18n>
