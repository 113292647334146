import {
    MAXLENGTH,
    SEND_EMAIL,
    SEND_SMS,
    CREATE_TASK,
    CREATE_DEAL,
    APPLY_TAG,
    REMOVE_TAG,
    SEND_NOTIFICATION,
    ANY_PUBLIC_FORM_SUBMITTED,
    PUBLIC_FORM_SUBMITTED,
    TAG_APPLIED,
    PRODUCT_PURCHASED,
    APPOINTMENT_SCHEDULED,
    USER_APPOINTMENT_SCHEDULED,
    APPOINTMENT_CANCELLED,
    USER_APPOINTMENT_CANCELLED,
    INTERNAL_FORM_SUBMITTED,
    HELLOSIGN_DOCUMENT_SIGNED,
    HELLOSIGN_SEND_DOCUMENT,
    DEAL_ENTERED,
    DEAL_EXITED,
    SHOPIFY_ANY_PRODUCT_PURCHASED,
    SHOPIFY_PRODUCT_PURCHASED,
    SHOPIFY_ANY_CART_ABANDONED,
    SHOPIFY_CART_ABANDONED, TYPEFORM_FORM_SUBMITTED,
} from '@/automations/constants/automations.constants.js';
import { SMS_ALLOWED_CHARS_REGEX } from '@/shared/sms.constants';
import { MAX_CHARACTER_COUNT } from '@/broadcasts/smsBroadcast.constants';

const stripHtml = (str) => str && str.replace(/(<([^>]+)>)/ig, '').trim();

export const validate = {
    [SEND_EMAIL]: {
        body: (step) => {
            return Boolean(step.configJson && stripHtml(step.configJson.body));
        },

        bodySize: (step) => {
            return !step?.configJson?.body?.length || step.configJson?.body?.length < MAXLENGTH.EMAIL_BODY;
        },

        subject: (step) => {
            return Boolean(step.configJson?.subject?.trim());
        },

        fromUser: (step) => {
            return Boolean(step.configJson?.fromUser || step.configJson?.fromContactOwner);
        },

        isValid: (step) => {
            const isValid = Boolean(step.name && step.configJson);

            return isValid
                && validate[SEND_EMAIL].body(step)
                && validate[SEND_EMAIL].bodySize(step)
                && validate[SEND_EMAIL].subject(step)
                && validate[SEND_EMAIL].fromUser(step);
        },
    },

    [SEND_SMS]: {
        title: (step) => {
            return Boolean(step.configJson?.title?.trim());
        },

        message: (step) => {
            return Boolean(step.configJson?.message?.trim());
        },

        messageEncoding: (step) => {
            const messageContent = step.configJson?.message?.normalize();

            return messageContent ? SMS_ALLOWED_CHARS_REGEX.test(messageContent) : true;
        },

        messageLength: (step) => {
            return MAX_CHARACTER_COUNT >= step.configJson?.message?.length;
        },

        phoneTypes: (step) => {
            // undefined is valid, in order to keep backwards compatibility
            if (typeof step.configJson?.phoneTypes === 'undefined') {
                return true;
            }

            return step.configJson?.phoneTypes?.length > 0;
        },

        isValid: (step) => {
            const isValid = Boolean(step.name && step.configJson);

            return isValid
                && validate[SEND_SMS].title(step)
                && validate[SEND_SMS].message(step)
                && validate[SEND_SMS].messageEncoding(step)
                && validate[SEND_SMS].messageLength(step)
                && validate[SEND_SMS].phoneTypes(step);
        },
    },

    [CREATE_TASK]: {
        title: (step) => {
            return Boolean(step.configJson?.title?.trim());
        },

        isValid: (step) => {
            const isValid = Boolean(step.name && step.configJson);

            return isValid && validate[CREATE_TASK].title(step);
        },
    },

    [CREATE_DEAL]: {
        stageId: (step) => {
            return Boolean(step.configJson?.stageId);
        },

        isValid: (step) => {
            const isValid = Boolean(step.name && step.configJson);

            return isValid
                && validate[CREATE_DEAL].stageId(step);
        },
    },

    [APPLY_TAG]: {
        isValid: (step) => {
            return Boolean(step.configJson?.tagIds?.length);
        },
    },

    [REMOVE_TAG]: {
        isValid: (step) => {
            return Boolean(step.configJson?.tagIds?.length);
        },
    },

    [HELLOSIGN_SEND_DOCUMENT]: {
        isValid: (step) => {
            return Boolean(step.configJson?.documentId);
        },
    },

    [SEND_NOTIFICATION]: {
        sendTo: (step) => {
            return Boolean(step.configJson?.sendToContactOwner
                || step.configJson?.sendToAllUsers
                || Boolean(step.configJson?.sendToUserIds?.length));
        },

        sendMethod: (step) => {
            return Boolean(step.configJson?.sendEmail || step.configJson?.sendInApp || step.configJson?.sendMobile);
        },

        contentTitle: (step) => {
            return Boolean(step.configJson?.contentTitle?.trim());
        },

        contentText: (step) => {
            return step.configJson?.sendInApp || step.configJson?.sendMobile
                ? Boolean(step.configJson?.contentText?.trim())
                : true;
        },

        emailContent: (step) => {
            return Boolean(!step.configJson?.sendEmail
                || (step.configJson?.sendEmail && step.configJson && stripHtml(step.configJson.emailContent)));
        },

        isValid: (step) => {
            const isValid = Boolean(step.name && step.configJson);

            return isValid
                && validate[SEND_NOTIFICATION].contentTitle(step)
                && validate[SEND_NOTIFICATION].contentText(step)
                && validate[SEND_NOTIFICATION].sendTo(step)
                && validate[SEND_NOTIFICATION].sendMethod(step)
                && validate[SEND_NOTIFICATION].emailContent(step);
        },
    },

    [ANY_PUBLIC_FORM_SUBMITTED]: {
        isValid: (trigger) => {
            return Boolean(trigger.name);
        },
    },

    [TAG_APPLIED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [PRODUCT_PURCHASED]: {
        isValid: (product) => {
            return Boolean(product.sourceId);
        },
    },

    [APPOINTMENT_SCHEDULED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [APPOINTMENT_CANCELLED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [USER_APPOINTMENT_SCHEDULED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [USER_APPOINTMENT_CANCELLED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [PUBLIC_FORM_SUBMITTED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [INTERNAL_FORM_SUBMITTED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [HELLOSIGN_DOCUMENT_SIGNED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [TYPEFORM_FORM_SUBMITTED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [DEAL_ENTERED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [DEAL_EXITED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [SHOPIFY_ANY_PRODUCT_PURCHASED]: {
        isValid: (trigger) => {
            return Boolean(trigger.name);
        },
    },

    [SHOPIFY_PRODUCT_PURCHASED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },

    [SHOPIFY_ANY_CART_ABANDONED]: {
        isValid: (trigger) => {
            return Boolean(trigger.name);
        },
    },

    [SHOPIFY_CART_ABANDONED]: {
        isValid: (trigger) => {
            return Boolean(trigger.sourceId);
        },
    },
};

export const isValidStep = (step) => {
    return validate[step.type] ? validate[step.type].isValid(step) : true;
};

export const isValidTrigger = (trigger) => {
    return validate[trigger.type] ? validate[trigger.type].isValid(trigger) : true;
};

export const getPipelineStageIds = (sourceId) => {
    const [pipelineId, stageId] = sourceId?.split('-');

    return { pipelineId, stageId };
};

export const getSortedTriggerList = (list, contextType) => {
    return list.sort((a, b) => {
        if (a.contextType === b.contextType) return a.order - b.order;

        if (contextType) {
            if (a.contextType === contextType) return -1;
            if (b.contextType === contextType) return 1;
        }

        return a.order - b.order;
    });
};
