<template lang="html">
    <div :class="['support-panel', { open: supportPanelOpen }]">
        <header>
            <ds-icon-button data-qa="close-button" name="x" @click="toggleSupportPanel" />

            <h4>{{ $t('title') }}</h4>
        </header>

        <div class="panel-body">
            <section>
                <h5 class="section-title">
                    {{ $t('learningAndSupport.helpResources.title') }}
                </h5>

                <help-resources-panel-items :is-open="supportPanelOpen" />
            </section>

            <section>
                <h5 class="section-title">
                    {{ $t('relevantArticles') }}
                </h5>

                <help-articles v-if="supportPanelOpen" :route-path="routePath" data-qa="help-articles" />
            </section>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import HelpArticles from './HelpArticles';
import HelpResourcesPanelItems from './HelpResourcesPanelItems';

export default {
    components: {
        HelpArticles,
        HelpResourcesPanelItems,
    },

    created() {
        this.$bus.$on('SHORTKEY_H', this.toggleSupportPanel);
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_H', this.toggleSupportPanel);
    },

    computed: {
        ...mapState({
            routePath: ({ route }) => route.path,
            supportPanelOpen: ({ global }) => global.supportPanelOpen,
        }),
    },

    methods: {
        toggleSupportPanel() {
            this.$store.commit('SET_SUPPORT_PANEL_OPEN', !this.supportPanelOpen);
        },
    },
};
</script>

<style lang="scss" scoped>
    .support-panel {
        @include side-panel;

        display: flex;
        flex-direction: column;
    }

    .panel-body {
        @include overflowY
    }

    header {
        display: flex;
        align-items: center;
        padding: 0 $gp / 2;
        height: $nav-height;
        border-bottom: 1px solid $color-gray-200;
        flex-shrink: 0;

        h4 {
            margin: 0 $gp / 2;
        }
    }

    section {
        padding: $gp 0;

        &:not(:last-of-type) {
            border-bottom: solid px-to-rem(1px) $color-gray-200;
        }
    }

    .section-title {
        padding: 0 $gp;
        margin: $gp / 2 0 $gp;
        font-weight: $font-weight-semibold;
        font-size: $font-size-med;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Help and support",
        "relevantArticles": "Relevant articles"
    }
}
</i18n>
