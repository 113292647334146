<template lang="html">
    <section v-if="hasAdditionalInfo" data-qa="contact-additional-info">
        <h4 class="section-title">
            {{ $t('title') }}
        </h4>

        <div class="field-grid">
            <div
                v-if="contact.nickname"
                class="view-field"
                data-qa="nickname"
            >
                <small class="label">{{ $t('forms.nickname') }}</small>
                <span>{{ contact.nickname }}</span>
            </div>

            <div
                v-if="contact.spouseName"
                class="view-field"
                data-qa="spouse-name"
            >
                <small class="label">{{ $t('forms.spouseName') }}</small>
                <span>{{ contact.spouseName }}</span>
            </div>

            <div
                v-if="showLeadScore"
                class="view-field"
                data-qa="lead-score"
            >
                <small class="label">{{ $t('forms.leadScore') }}</small>

                <div>
                    <span v-for="({ flameOn }, index) in contactLeadScore" :key="flameOn" :data-qa="`fire-${index}`">
                        <img src="@/shared/images/fire.png" />
                    </span>

                    <span v-for="({ flameOff }, index) in (5 - contactLeadScore)" :key="flameOff" :data-qa="`no-fire-${index}`">
                        <img src="@/shared/images/no-fire.png" />
                    </span>

                    <span data-qa="lead-score-value">{{ contact.leadScore }}/5</span>
                </div>
            </div>

            <div
                v-for="(customField, i) in viewableCustomFields"
                :key="i"
                class="view-custom-field-container"
            >
                <div
                    :class="['view-field', { 'text-area-format': checkType(customField, 'TextArea') }]"
                    :data-qa="customField.label"
                >
                    <small class="label">{{ customField.label }}</small>

                    <ul v-if="checkType(customField, 'ListBox')">
                        <li v-for="(fieldValue, j) in customField.value" :key="j">
                            {{ fieldValue }}
                        </li>
                    </ul>

                    <a v-else-if="checkType(customField, 'PhoneNumber')" :href="`tel: ${customField.value}`">
                        {{ formatCustomFieldDisplayValue(customField, customField.value) }}
                    </a>

                    <a
                        v-else-if="checkType(customField, 'Website')"
                        :href="customField.value"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {{ formatCustomFieldDisplayValue(customField, customField.value) }}
                    </a>

                    <a v-else-if="checkType(customField, 'Email')" @click="sendEmail(customField.value)">
                        {{ formatCustomFieldDisplayValue(customField, customField.value) }}
                    </a>

                    <span v-else>{{ formatCustomFieldDisplayValue(customField, customField.value) }}</span>
                </div>
            </div>
        </div>

        <send-email-modal
            v-if="email"
            :contact="contact"
            :email="email"
            @close="email = null"
        />
    </section>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import dateMixin from '@/appointments/mixins/date.mixin';
import displayMixin from '@/shared/mixins/display.mixin';
import SendEmailModal from '@/contacts/components/record/email/SendEmailModal';
import { FLAGSHIP_CUS_7863_FIX_TO_SAVE_WHOLE_NUMBER_CUSTOM_FIELD_AS_NULL } from '@/shared/constants/featureFlag.constants';

import customFieldUtils from '@/contacts/components/details/custom-field-utils';

import { customFieldHasValue } from '@/contacts/contact-info-utils';

export default {
    components: {
        SendEmailModal,
    },

    mixins: [dateMixin, customFieldUtils, displayMixin],

    props: {
        contact: Object,
    },

    data() {
        return {
            email: null,
        };
    },

    computed: {
        ...mapState({
            isCustomFieldWholeNumberEnabled: ({ featureFlags }) => featureFlags[FLAGSHIP_CUS_7863_FIX_TO_SAVE_WHOLE_NUMBER_CUSTOM_FIELD_AS_NULL],
        }),

        ...mapGetters({
            hasLeadScoreFeature: 'auth/hasLeadScoreFeature',
        }),

        showLeadScore() {
            return this.contactLeadScore >= 0 && this.hasLeadScoreFeature;
        },

        contactLeadScore() {
            return this.contact?.leadScore ?? 0;
        },

        viewableCustomFields() {
            const customFields = this.contact.customFields || [];

            return customFields.filter((field) => customFieldHasValue(field, this.isCustomFieldWholeNumberEnabled));
        },

        hasAdditionalInfo() {
            let hasAdditionalInfo = false;
            const hadGeneralInfo = this.contact.nickname || this.contact.spouseName || this.showLeadScore;

            if (this.contact.customFields) {
                hasAdditionalInfo = this.contact.customFields.some((field) => {
                    return customFieldHasValue(field, this.isCustomFieldWholeNumberEnabled);
                });
            }

            return hadGeneralInfo || hasAdditionalInfo;
        },
    },

    methods: {
        sendEmail(email) {
            this.email = email;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    .text-area-format {
        white-space: pre-line;
        word-break: break-word;
    }

    label {
        color: $color-text-normal;
        font-weight: normal;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Additional info",
        "customFields": {
            "loadError": "Something went wrong and we couldn't load this contact's custom fields. Please try again."
        }
    }
}
</i18n>
