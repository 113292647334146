<template lang="html">
    <div class="mobile-app-cta">
        <div class="content">
            <img :src="`${baseUrl}${illustrationPath}`" />

            <h2>{{ title }}</h2>

            <h2 class="desktop">
                {{ desktopTitle }}
            </h2>

            <a
                class="app-store-button button large"
                :href="appStoreUrl"
            >
                {{ $t('appStoreButtonText') }}
            </a>

            <p class="light platforms">
                {{ $t('platforms') }}
            </p>

            <div class="card-container">
                <p class="description">
                    {{ $t('description') }}
                </p>

                <text-download-link-form auto-focus />

                <i18n
                    class="platforms-text"
                    tag="span"
                    path="platforms"
                >
                    <template #android>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            :href="playStoreUrl"
                        >
                            {{ $t('android') }}
                        </a>
                    </template>

                    <template #iOS>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            :href="appStoreUrl"
                        >
                            {{ $t('iOS') }}
                        </a>
                    </template>
                </i18n>
            </div>

            <a class="back-link" @click="handleClose">{{ $t('backToSelect') }}</a>
        </div>
    </div>
</template>

<script>
import TextDownloadLinkForm from '@/communication/components/TextDownloadLinkForm';

export default {
    components: {
        TextDownloadLinkForm,
    },

    props: {
        title: {
            type: String,
            required: true,
        },

        desktopTitle: {
            type: String,
            required: true,
        },

        illustrationPath: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            baseUrl: process.env.BASE_URL,
            appStoreUrl: process.env.VUE_APP_NIMO_APP_STORE_URL,
            playStoreUrl: process.env.VUE_APP_NIMO_PLAY_STORE_URL,
        };
    },

    mounted() {
        this.$bus.$on('SHORTKEY_ESC', this.handleClose);
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_ESC', this.handleClose);
    },

    methods: {
        handleClose() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .mobile-app-cta {
        display: flex;
        justify-content: center;
    }

    img {
        max-height: px-to-rem(94px);
        margin: $gp * 2 0;

        @media ($medium) {
            max-height: px-to-rem(122px);
        }
    }

    .card-container {
        border-radius: $border-radius;
        min-width: px-to-rem(120px);
        max-width: px-to-rem(327px);
        background-color: $color-gray-050;
        padding: $gp * 1.5;
        margin: $gp auto;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: $elevation-z1;
        flex-flow: column wrap;

        @media($small) {
            display: none;
        }
    }

    .content {
        color: $color-gray-800;
        max-width: $form-width;
        --radio-padding: 0;
        text-align: center;
    }

    h2,
    .platforms,
    .app-store-button {
        @media ($medium) {
            display: none;
        }
    }

    .desktop {
        @media ($small) {
            display: none;
        }

        @media ($medium) {
            display: block;
        }
    }

    h2 {
        margin-bottom: $gp;
        text-align: center;
        font-size: $font-size-xl;

        &.desktop {
            font-size: $font-size-xxl;
            margin-bottom: $gp * 2;
        }
    }

    .description {
        font-size: $font-size-med;
        margin-bottom: $gp * 2;
    }

    .platforms {
        margin: $gp auto $gp * .5;
        color: $color-gray-800;
    }

    .app-store-button {
        margin: $gp * 2 0;
    }

    .platforms-text {
        margin-top: $gp * 2;
    }

    .back-link {
        position: relative;
        top: 2 * $gp;
        display: inline-block;

        @media($small) {
            display: none;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "description": "Enter your phone number to receive a link to the app store.",
        "platforms": "Available on {iOS} and {android}",
        "android": "Android",
        "iOS": "iOS",
        "backToSelect": "Back to import options",
        "appStoreButtonText": "Go to the App Store"
    }
}
</i18n>
