<template lang="html">
    <div class="import-contacts-selection">
        <template v-if="!hideText">
            <h2 class="secondary-title">
                {{ $t('title') }}
            </h2>

            <h5>{{ $t('description') }}</h5>
        </template>

        <div
            v-for="{ id, image } in options"
            :key="id"
            :data-qa="`import-card-${id}`"
            class="import-card-container"
            @click="handleOptionClick(id)"
        >
            <from-qbo v-if="isQbo(id)" :id="id" />

            <provider-card
                v-else
                :image="image ? `${baseUrl}images/${image}?v=3` : null"
                :title="$t(`${id}.description`)"
                :description="$t(`${id}.title`)"
                @click.native="select(id)"
            />
        </div>

        <div class="bottom-links-container">
            <i18n tag="small" path="helpText.text">
                <template #linkText>
                    <a :href="`${articleBaseUrl}/help/import-contacts`" target="_blank">{{ $t('helpText.linkText') }}</a>
                </template>
            </i18n>

            <google-api-privacy-policy />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import authMixin from '@/shared/mixins/auth.mixin';
import redirectMixin from '@/shared/mixins/redirect.mixin';
import ImportCsv from '@/import/components/csv/ImportCsv';
import FromBizcardModal from '@/import/components/FromBizcardModal';
import FromPhoneModal from '@/import/components/FromPhoneModal';
import FromQbo from '@/import/components/FromQbo';
import FromMailchimp from '@/import/components/mailchimp/FromMailchimp';
import FromConstantContact from '@/import/components/constantContact/FromConstantContact';
import GoogleApiPrivacyPolicy from '@/shared/components/GoogleApiPrivacyPolicy';
import ProviderCard from '@/shared/components/ProviderCard';

import { IMPORT_TYPES, IMPORT_STAGES, AMPLITUDE_IMPORT_EVENT_SOURCES } from '@/import/import.constants';

export default {
    components: {
        FromQbo,
        GoogleApiPrivacyPolicy,
        ProviderCard,
    },

    mixins: [authMixin, redirectMixin],

    props: {
        hideText: Boolean,
        external: Boolean,
        optionsToShow: {
            type: Array,
            default: () => Object.values(IMPORT_TYPES),
        },
    },

    data() {
        return {
            open: false,
            redirecting: false,
            baseUrl: process.env.BASE_URL,
            title: this.$t('importContacts.title'),
            nestedModal_title: this.$t('importContacts.title'),
            importIcons: {
                [IMPORT_TYPES.GOOGLE]: { image: 'providers/google-contacts.svg' },
                [IMPORT_TYPES.MAILCHIMP]: { image: 'providers/mailchimp.svg' },
                [IMPORT_TYPES.CONSTANT_CONTACT]: { image: 'providers/constant-contact.svg' },
                [IMPORT_TYPES.HUBSPOT]: { image: 'providers/hubspot.svg' },
                [IMPORT_TYPES.CSV]: { image: 'dashboard/upload-file.svg' },
                [IMPORT_TYPES.PHONE]: { image: 'dashboard/keap-mobile-app.svg' },
                [IMPORT_TYPES.BIZCARD]: { image: 'dashboard/scan-contact.svg' },
            },
            articleBaseUrl: process.env.VUE_APP_KNOWLEDGEOWL_URL,
        };
    },

    mounted() {
        this.$bus.$on('SHORTKEY_ESC', this.handleClosed);
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_ESC', this.handleClosed);
    },

    computed: {
        ...mapState({
            importStage: ({ contactImport }) => contactImport.activeStage,
            qboIntegration: ({ contactImport }) => contactImport.qboIntegration,
        }),

        ...mapGetters({
            importPending: 'contactImport/importPending',
            enabledImportTypes: 'contactImport/enabledImportTypes',
        }),

        importCloseable() {
            return this.importStage === IMPORT_STAGES.UPLOAD || this.importPending;
        },

        options() {
            return Object.values(IMPORT_TYPES)
                .filter((importType) => this.optionsToShow.includes(importType) && this.enabledImportTypes[importType])
                .map((importType) => Object.assign({}, { id: importType }, this.importIcons[importType])) || [];
        },

        qboIsConnected() {
            return this.qboIntegration.isConnected;
        },
    },

    methods: {
        isQbo(id) {
            return id === IMPORT_TYPES.QBO;
        },

        isMailChimp(id) {
            return id === IMPORT_TYPES.MAILCHIMP;
        },

        handleInterceptor() {
            if (this.open) {
                if (this.importCloseable) {
                    this.$track('Contacts - Import - clicked : Exit');
                    this.handleClosed();
                } else {
                    this.$confirm({
                        optionTitle: this.$t('confirmModal.title'),
                        optionMessage: this.$t('confirmModal.message'),
                        optionConfirmButtonLabel: this.$t('confirmModal.confirm'),
                    })
                        .then(() => {
                            this.$track('Contacts - Import - Stop - clicked : Stop Import');
                            this.handleClosed();
                        })
                        .catch(() => {
                            this.$track('Contacts - Import - Stop - clicked : Cancel');
                        });
                }
            }
        },

        handleClosed() {
            this.open = false;
            this.$bus.$emit('POP_NESTED_MODAL', 5);
        },

        select(id) {
            this.$store.commit('contactImport/CLEAR_IMPORT_SOURCE');

            this.open = true;
            this.$emit('select', id);

            const modalDefaults = {
                interceptClose: this.handleInterceptor,
                modalSize: 'fullscreen',
                showBack: true,
                showRootClose: true,
            };

            if (!this.external) {
                this.$store.commit('contactImport/SET_IMPORT_SOURCE', id);
                amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORT_STARTED, { 'Event Source': AMPLITUDE_IMPORT_EVENT_SOURCES[id] });

                switch (id) {
                case IMPORT_TYPES.CSV:
                    this.$bus.$emit('PUSH_NESTED_MODAL', {
                        ...modalDefaults,
                        component: ImportCsv,
                    });

                    this.$track('Contact import - clicked: CSV upload - manual');
                    break;

                case IMPORT_TYPES.MAILCHIMP:
                    this.$track(`Contact import - clicked: CSV upload - ${id}`);

                    this.$bus.$emit('PUSH_NESTED_MODAL', {
                        ...modalDefaults,
                        component: FromMailchimp,
                        data: id,
                    });
                    break;

                case IMPORT_TYPES.CONSTANT_CONTACT:
                    this.$track(`Contact import - clicked: CSV upload - ${id}`);
                    this.$bus.$emit('PUSH_NESTED_MODAL', {
                        ...modalDefaults,
                        component: FromConstantContact,
                        data: id,
                    });
                    break;

                case IMPORT_TYPES.HUBSPOT:
                    this.$track(`Contact import - clicked: CSV upload - ${id}`);

                    this.$bus.$emit('PUSH_NESTED_MODAL', {
                        ...modalDefaults,
                        component: ImportCsv,
                        data: id,
                    });

                    break;

                case IMPORT_TYPES.BIZCARD:
                    this.$track('Contact import - clicked: Scan business cards');

                    this.$bus.$emit('PUSH_NESTED_MODAL', {
                        ...modalDefaults,
                        component: FromBizcardModal,
                    });
                    break;

                case IMPORT_TYPES.PHONE:
                    this.$track('Contact import - clicked: Sync contacts from iPhone');

                    this.$bus.$emit('PUSH_NESTED_MODAL', {
                        ...modalDefaults,
                        component: FromPhoneModal,
                    });
                    break;

                case IMPORT_TYPES.GOOGLE:
                    this.$track('Contact import - clicked: Google upload');

                    this.auth_authorizeContacts(IMPORT_TYPES.GOOGLE)
                        .then(() => {
                            amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORT_ACCOUNT_CONNECTED, { 'Event Source': AMPLITUDE_IMPORT_EVENT_SOURCES[id] });
                        });
                    break;

                default:
                    this.$log('Unknown import type selected');
                }
            }
        },

        handleOptionClick(importSource) {
            const isMobileImportOption = (importSource === IMPORT_TYPES.BIZCARD || importSource === IMPORT_TYPES.PHONE);

            if (!isMobileImportOption && !(this.isQbo(importSource) && this.qboIsConnected)) {
                this.$store.commit('contactImport/SET_LAST_IMPORT_SOURCE', importSource);

                intercom.logEvent(intercom.events.CONTACT_IMPORT_STARTED);
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .import-contacts-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $color-gray-800;
    }

    .import-card-container {
        width: 100%;

        @media ($medium) {
            max-width: $form-width;
        }
    }

    .secondary-title {
        font-size: $font-size-jumbo;
        text-align: center;

        @media ($medium) {
            font-size: $font-size-mega;
        }
    }

    h5 {
        margin: $gp / 2 0;
    }

    .bottom-links-container {
        display: grid;
        grid-gap: $gp;
        margin: $gp * 2 $gp;
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Import your contacts",
        "description": "Pick a contact source to begin.",
        "google": {
            "title": "Google contacts",
            "description": "Contacts"
        },
        "microsoft": {
            "title": "Microsoft Outlook",
            "description": "Contacts"
        },
        "csv": {
            "title": "My spreadsheet",
            "description": "Excel and CSV files"
        },
        "bizcard": {
            "title": "Scan business cards",
            "description": "Keap mobile app"
        },
        "phone": {
            "title": "My phone contacts",
            "description": "Keap mobile app"
        },
        "constantContact": {
            "title": "Constant Contact",
            "description": "Contact file import"
        },
        "mailchimp": {
            "title": "Mailchimp",
            "description": "Contact file import"
        },
        "hubspot": {
            "title": "HubSpot",
            "description": "Contact file import"
        },
        "confirmModal": {
            "title": "Stop Import?",
            "message": "Are you sure you want to stop the import process? If you stop now, any changes you've made won't be saved.",
            "confirm": "Stop Import"
        },
        "helpText": {
            "linkText": "Help Center",
            "text": "Have questions? Visit our {linkText} for more detailed instructions"
        }
    }
}
</i18n>
