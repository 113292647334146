export const loadQuill = async () => {
    const { default: Quill } = await import(/* webpackChunkName: "quill" */ 'quill');

    const Break = Quill.import('blots/break');
    const Embed = Quill.import('blots/embed');
    const Inline = Quill.import('blots/inline');

    class SmartBreak extends Break {
        blotName = 'break';

        length = () => 1;

        tagName = 'BR';

        value = () => '\n';

        insertInto(parent, ref) {
            Embed.prototype.insertInto.call(this, parent, ref);
        }
    }

    Quill.register(SmartBreak);
    Quill.debug('error');

    class ColorFont extends Inline {
        constructor(domNode, value) {
            super(domNode, value);

            domNode.style.color = domNode.color;

            const span = this.replaceWith(new Inline(Inline.create()));

            span.children.forEach((child) => {
                if (child.attributes) child.attributes.copy(span);
                if (child.unwrap) child.unwrap();
            });

            this.remove();

            return span;
        }
    }

    ColorFont.blotName = 'colorFont';
    ColorFont.tagName = 'FONT';
    Quill.register(ColorFont, true);

    return Quill;
};

export const getLineBreakModule = (Quill) => {
    const Delta = Quill.import('delta');

    const lineBreakMatcher = () => {
        const newDelta = new Delta();

        newDelta.insert({ break: '' });

        return newDelta;
    };

    return {
        clipboard: {
            matchers: [
                ['BR', lineBreakMatcher],
            ],
        },
        keyboard: {
            bindings: {
                linebreak: {
                    key: 13,
                    shiftKey: true,
                    handler (range) {
                        const currentLeaf = this.quill.getLeaf(range.index)[0];
                        const nextLeaf = this.quill.getLeaf(range.index + 1)[0];

                        this.quill.insertEmbed(range.index, 'break', true, 'user');

                        // Insert a second break if:
                        // At the end of the editor, OR next leaf has a different parent (<p>)
                        if (nextLeaf == null || (currentLeaf.parent !== nextLeaf.parent)) {
                            this.quill.insertEmbed(range.index, 'break', true, 'user');
                        }

                        // Now that we've inserted a line break, move the cursor forward
                        this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
                    },
                },
            },
        },
    };
};
