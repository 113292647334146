import Vue from 'vue';

export default {
    filterMergeFields: ({ initialMergeFields, initialMergeServiceFields }) => (useMergeService) => {
        const instance = Vue.prototype.$i18nInstance;
        const { categories } = useMergeService ? initialMergeServiceFields : initialMergeFields;
        const includedCategories = ['contact', 'customFields', 'profile', 'today', 'user'];

        if (categories != null) {
            return Object.keys(categories)
                .filter((key) => includedCategories.includes(key) && categories[key] != null)
                .map((key) => {
                    const { label, options } = categories[key];

                    return {
                        category: {
                            label: instance.t(label),
                            value: key,
                        },
                        options: options.map((option) => {
                            return {
                                ...option,
                                label: instance.te(`${option.label}.displayLabel`) ? instance.t(`${option.label}.displayLabel`) : instance.t(option.label),
                                value: option.value,
                                chipLabel: instance.te(`${option.label}.chipLabel`) ? instance.t(`${option.label}.chipLabel`) : instance.t(option.label),
                            };
                        }),
                    };
                });
        }

        return [];
    },

    addMergeFields: (state, getters) => (additionalFields = [], useMergeService = false) => {
        const mergeFields = getters.filterMergeFields(useMergeService);
        let allMergeFields = [...additionalFields];

        if (mergeFields.length > 0) {
            const mergeFieldBlockList = ['[[contact.uid]]', '{{user.uid}}'];

            const processedMergeFields = mergeFields.map((mergeFieldCategory) => {
                const { category, options } = mergeFieldCategory;

                const filteredOptions = options.filter(({ value }) => {
                    return Boolean(!mergeFieldBlockList.includes(value));
                });

                return { category, options: filteredOptions };
            });

            allMergeFields = additionalFields.concat(processedMergeFields);
        }

        return allMergeFields;
    },

    getSupportedMergeFields: (state, getters) => (additionalFields = [], useMergeService = false) => {
        return getters.addMergeFields(additionalFields, useMergeService)
            .reduce((mergeFields, { options }) => {
                options.forEach(({ label, value, chipLabel }) => {
                    mergeFields[value] = chipLabel || label;
                });

                return mergeFields;
            }, {});
    },
};
