import axios from 'axios';
import moment from 'moment-timezone';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import sentry from '@/analytics/sentry';

import {
    BOOKING_FREEDAYS_START_OFFSET_HOURS,
} from '@/appointments/appointments.constants';

export default {
    PUBLIC_BOOK_APPOINTMENT(context, newApptObj) {
        return publicBookAppointment(context, newApptObj);
    },

    BOOK_APPOINTMENT(context, payload) {
        return bookAppointment(context, payload);
    },

    CANCEL_APPOINTMENT(context, cancelParams) {
        return cancelAppointment(context, cancelParams);
    },

    GET_APPT_TYPE(context, bookingLink) {
        return getApptType(bookingLink);
    },

    GET_APPT_TYPE_DATA(context, payload) {
        return getApptTypeData(context, payload);
    },

    GET_VIDEO_CALL_DATA(context, payload) {
        return getVideoCallData(context, payload);
    },

    PUBLIC_RESCHEDULE_APPOINTMENT(context, newApptObj) {
        return publicRescheduleAppointment(context, newApptObj);
    },

    UPDATE_SELECTED_DATE(context, date) {
        return updateSelectedDate(context, date);
    },

    REFRESH_AVAILABILITY(context) {
        return refreshAvailability(context);
    },

    LOAD_APPOINTMENT_TYPE_AVAILABILITY(context, payload) {
        return loadAppointmentTypeAvailability(context, payload);
    },
};


const getVideoCallData = (context, { apptId, apptTypeId }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_APPTS_API_URL}/video/${apptTypeId}/${apptId}`)
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const publicBookAppointment = async ({ commit, state: { apptData } }, payload) => {
    return new Promise(async (resolve, reject) => {
        const {
            tenantId,
            bookingLink,
            calendarId,
        } = apptData;

        const {
            client,
            end,
            start,
            summary,
            location,
            description,
            recaptchaToken1,
            recaptchaToken2,
        } = payload;

        const {
            email,
            firstName,
            lastName,
            phone,
            subscribed,
        } = client;

        if (!calendarId || !bookingLink) {
            reject();
        }

        if (!email || !firstName || !lastName) {
            reject();
        }

        const newContactPayload = {
            email,
            familyName: lastName,
            givenName: firstName,
            phone,
            marketable: subscribed ? 'Client opted-in through webform' : '',
        };

        let newContactRes;

        try {
            newContactRes = await createNewContact(tenantId, newContactPayload, recaptchaToken1);
        } catch (e) {
            return reject(e);
        }

        if (!newContactRes) {
            sentry.log('newContact was not created and is undefined');

            return reject();
        }

        const newContact = {
            ...client,
            displayName: `${client.firstName} ${client.lastName}`,
            contactId: newContactRes.id,
        };

        const createAppointmentRequest = {
            appointmentStatus: 'CONFIRMED',
            attendees: [
                {
                    attendeeStatus: 'CONFIRMED',
                    comment: '',
                    displayName: newContact.displayName,
                    email: newContact.email,
                    contactId: newContact.contactId,
                },
            ],
            description,
            etag: '',
            location,
            start,
            end,
            summary,
        };

        return axios.post(`${process.env.VUE_APP_APPTS_API_URL}/calendars/${calendarId}/appointments?bookingLink=${bookingLink}`, createAppointmentRequest, {
            headers: {
                'X-IS-Ignore-Auth': true,
                'X-IS-ReCaptcha-Token': recaptchaToken2,
            },
        })
            .then(({ data }) => {
                amplitude.v2.setUserId(tenantId);

                amplitude.v2.logEvent(amplitude.v2.events.APPOINTMENT_BOOKED, {
                    'Event Source': 'Public Booking',
                });

                intercom.logEvent(intercom.events.APPOINTMENT_BOOKED);

                commit('SET_CONFIRMED_APPT', data);

                resolve(data);
            })
            .catch(() => {
                reject();
            });
    });
};

const bookAppointment = ({ commit, rootState }, payload) => {
    const {
        location,
        bookingLink,
        calendarId,
        contact,
        end,
        start,
        summary,
        description,
    } = payload;

    const {
        id,
        email,
        firstName,
        lastName,
    } = contact;

    const createAppointmentRequest = {
        appointmentStatus: 'CONFIRMED',
        attendees: [
            {
                attendeeStatus: 'CONFIRMED',
                comment: '',
                displayName: `${firstName} ${lastName}`,
                email,
                contactId: id,
            },
        ],
        description,
        etag: '',
        location,
        start,
        end,
        summary,
    };

    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_APPTS_API_URL}/calendars/${calendarId}/appointments?bookingLink=${bookingLink}`, createAppointmentRequest)
            .then(({ data }) => {
                amplitude.v2.setUserId(rootState.auth.account.appName);
                amplitude.v2.logEvent(amplitude.v2.events.APPOINTMENT_BOOKED, {
                    'Event Source': payload.eventSource || 'Book Now Appointments Page',
                });

                intercom.logEvent(intercom.events.APPOINTMENT_BOOKED);

                commit('SET_CONFIRMED_APPT', data);

                resolve(data);
            })
            .catch(reject);
    });
};

const createNewContact = async (tenantId, payload, recaptchaToken) => {
    return new Promise((resolve, reject) => {
        const {
            email, familyName, givenName,
        } = payload;

        if (!email || !familyName || !givenName || !tenantId) {
            reject();
        }

        return axios.put(`${process.env.VUE_APP_FIREBASE_CLOUD_FUNCTIONS}/api/${tenantId}/contact`, payload, {
            headers: {
                'X-IS-Ignore-Auth': true,
                'X-IS-ReCaptcha-Token': recaptchaToken,
            },
        })
            .then(({ data }) => {
                resolve(data);
            })
            .catch(reject);
    });
};

const cancelAppointment = async ({ commit }, cancelParams) => {
    const {
        apptId,
        bookingLink,
        recaptchaToken,
    } = cancelParams;
    const paramsPresent = apptId && bookingLink && recaptchaToken;
    const paramsStrings = typeof apptId === 'string' && typeof bookingLink === 'string';
    const endpoint = `/appointments/${encodeURIComponent(apptId)}`;
    const query = `?bookingLink=${bookingLink}`;

    if (!paramsPresent || !paramsStrings) {
        sentry.log('No params cancelling appointment');

        return Promise.reject();
    }

    return axios.delete(`${process.env.VUE_APP_APPTS_API_URL}${endpoint}${query}`, {
        headers: {
            'X-IS-Ignore-Auth': true,
            'X-IS-ReCaptcha-Token': recaptchaToken,
        },
    })
        .then(({ data }) => {
            commit('calendar/REMOVE_CALENDAR_APPT', apptId, { root: true });

            return data;
        })
        .catch((err) => {
            if (err.response && err.response.status === 404) {
                // This is not found. Fail gracefully and report this as 'cancelled'.
            } else {
                throw err;
            }
        });
};

const loadAppointmentTypeAvailability = async ({ commit }, { bookingLink, startDateTime }) => {
    const availability = await getAvailability(bookingLink, startDateTime);

    if (availability) {
        commit('SET_APPOINTMENT_TYPE_AVAILABILITY', availability);
    }

    return availability;
};

const getApptTypeData = async ({ commit }, payload) => {
    const {
        userCode,
        apptType,
        apptId,
        apptTypeGUID,
    } = payload || {};

    if ((!userCode || !apptType) && !apptTypeGUID) {
        sentry.log('Cant get appointment type data');

        return Promise.reject();
    }

    const bookingString = apptTypeGUID || `${userCode}/${apptType}`;

    const [apptTypeRes, availability] = await Promise.all([getApptType(bookingString), getAvailability(bookingString)]);

    const apptData = {
        // older appoinment types do not have names, the field was
        // added to the api at a later point. The workaround before
        // field was added was to derive the name from the title by
        // dropping all text after the last 'with' string.
        name: apptTypeRes.title && apptTypeRes.title.slice(0, apptTypeRes.title.lastIndexOf('with') - 1),
        ...apptTypeRes,
        appointmentId: apptId,
        bookingStr: bookingString,
        availability,
    };

    commit('SET_APPT_DATA', apptData);

    return apptData;
};

const getAvailability = async (bookingString, startDateTime) => {
    if (!startDateTime) {
        startDateTime = moment().add(BOOKING_FREEDAYS_START_OFFSET_HOURS, 'hours').toISOString();
    }

    const { freedays } = await getAvailableDays(bookingString, {
        start: startDateTime,
        timeZone: moment.tz.guess(),
    });

    let nextFreeDayFound = false;

    const defaultStartDate = freedays.length ? freedays[0].day : moment().format('YYYY-MM-DD');

    const startDate = freedays.reduce((val, curr) => {
        if (!nextFreeDayFound && curr.isFree) {
            val = curr.day;

            nextFreeDayFound = true;
        }

        return val;
    }, defaultStartDate);

    let freetimes;

    try {
        ({ freetimes } = await getAvailableTimes(bookingString, {
            start: moment(startDate).startOf('day').toISOString(),
        }));
    } catch (error) {
        sentry.log('Unable to load available times from getAvailability');

        return Promise.reject();
    }

    return {
        days: freedays,
        selectedDay: startDate,
        times: freetimes,
    };
};

const getApptType = async (bookingLink) => {
    if (!bookingLink || typeof bookingLink !== 'string') {
        sentry.log('No booking link getting appt type');
    }

    return axios.get(`${process.env.VUE_APP_APPTS_API_URL}/appointmentTypes/search?bookingLink=${bookingLink}`, {
        headers: {
            'X-IS-Ignore-Auth': true,
        },
    })
        .then(({ data }) => {
            // if name is empty (older appointment types), derive name from title field
            const { name, title } = data;

            data.name = name || (title ? title.slice(0, title.lastIndexOf('with') - 1) : '');

            return data;
        });
};

const getAvailableDays = async (bookingLink, query) => {
    const { start } = query;

    if (!bookingLink || !query || !start) {
        sentry.log('No booking link getting available days');
    }

    return axios.post(`${process.env.VUE_APP_APPTS_API_URL}/free-days?bookingLink=${bookingLink}`, query, {
        headers: {
            'X-IS-Ignore-Auth': true,
        },
    })
        .then(({ data }) => data);
};

const getAvailableTimes = async (bookingLink, query) => {
    const { start } = query;
    const filterTimes = (data) => {
        const times = data.freetimes.filter((time) => {
            return moment(time.start).isAfter(moment().add(BOOKING_FREEDAYS_START_OFFSET_HOURS, 'hours')) && time.isFree;
        });

        times.sort((a, b) => {
            if (moment(a.start).isSame(b.start, 'second')) {
                return 0;
            }

            return moment(a.start).isBefore(b.start, 'second') ? -1 : 1;
        });

        return { ...data, freetimes: times };
    };

    if (!bookingLink || !query || !start) {
        sentry.log('No booking link getting available times', { bookingLink });
    }

    return axios.post(`${process.env.VUE_APP_APPTS_API_URL}/free-times?bookingLink=${bookingLink}`, query, {
        headers: {
            'X-IS-Ignore-Auth': true,
        },
    })
        .then(({ data }) => {
            return filterTimes(data);
        });
};

const publicRescheduleAppointment = ({ commit }, payload) => {
    return new Promise((resolve, reject) => {
        const {
            appointmentId,
            bookingStr,
            end,
            start,
            recaptchaToken1,
        } = payload;
        const paramsPresent = appointmentId && bookingStr && end && start;
        const paramsStrings = typeof appointmentId === 'string' && typeof bookingStr === 'string';

        if (!paramsPresent || !paramsStrings) {
            reject();

            return;
        }

        const rescheduleUrl = `/appointments/${encodeURIComponent(appointmentId)}`;
        const queryParams = `?bookingLink=${bookingStr}&updateMask=start,end`;
        const updateApptRequest = {
            appointmentStatus: 'CONFIRMED',
            start,
            end,
        };

        axios.patch(`${process.env.VUE_APP_APPTS_API_URL}${rescheduleUrl}${queryParams}`, updateApptRequest, {
            headers: {
                'X-IS-Ignore-Auth': true,
                'X-IS-ReCaptcha-Token': recaptchaToken1,
            },
        })
            .then(({ data }) => {
                commit('SET_CONFIRMED_APPT', data);

                resolve(data);
            })
            .catch(reject);
    });
};

const updateSelectedDate = async ({ commit, state: { apptData } }, date) => {
    // updates available times for selected date

    if (!date) sentry.log('date must be defined in updateSelectedDate');

    const { bookingStr } = apptData || {};

    let freetimes;

    try {
        ({ freetimes } = await getAvailableTimes(bookingStr, {
            start: moment(date).startOf('day').toISOString(),
        }));
    } catch (error) {
        sentry.log('Unable to load availalbe times from updateSelectedDate');

        return Promise.reject();
    }

    const selectedDate = moment(date).format('YYYY-MM-DD');

    commit('SET_SELECTED_DATE', { times: freetimes, selectedDate });

    return { times: freetimes, selectedDate };
};

const refreshAvailability = ({ commit, state }) => {
    return updateSelectedDate({ commit, state }, state.apptData.availability.selectedDay);
};
