export const CUSTOM_FIELD_RECORD_TYPE_CONTACT = 'contact';
export const CUSTOM_FIELD_RECORD_TYPE_COMPANY = 'company';

export const CUSTOM_FIELD_RECORD_TYPES = [
    CUSTOM_FIELD_RECORD_TYPE_CONTACT,
    CUSTOM_FIELD_RECORD_TYPE_COMPANY,
];

export const CUSTOM_FIELD_TYPES = [
    'MultiSelect',
    'Currency',
    'Date',
    'DayOfWeek',
    'NumberDecimal',
    'Select',
    'Email',
    'Month',
    'Percent',
    'Phone',
    'Radio',
    'State',
    'Text',
    'TextArea',
    'Website',
    'Number',
    'Year',
    'YesNo',
];
export const CUSTOM_FIELD_SORT_OPTIONS = [
    'label.asc',
    'label.desc',
];
