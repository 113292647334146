<template>
    <tag-step
        :add-or-remove-type="type"
        :automation-properties="automationProperties"
        :save-status="saveStatus"
        @updated="(payload) => $emit('updated', payload)"
        @close="(payload) => $emit('close', payload)"
    />
</template>

<script>
import TagStep from './TagStep';
import { REMOVE_TAG } from '@/automations/constants/automations.constants';

export default {
    components: {
        TagStep,
    },

    props: {
        automationProperties: Object,
        saveStatus: String,
    },

    computed: {
        type() {
            return REMOVE_TAG;
        },
    },
};
</script>
