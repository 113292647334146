import axios from 'axios';
import { trackTagApplied } from '@/contacts/analytics';

export const fetchContactTags = ({ contactId }) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactPage/${contactId}/tags`);
};

export const addContactTags = async ({ contactId, tagsToApply, eventSource }) => {
    const tagIds = tagsToApply.map(({ id }) => id);

    const response = await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactPage/${contactId}/tags`, tagIds);

    if (response.data.length !== tagsToApply.length) {
        const error = new Error();

        error.count = tagsToApply.length - response.data.length;

        throw error;
    }

    trackTagApplied(eventSource);

    return response;
};

export const removeContactTag = ({ contactId, tagId }) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactPage/${contactId}/tags/${tagId}`);
};

export const addTagToContacts = ({
    tagId,
    savedFilterId,
    ids,
    filterItems,
    checkAll,
}) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/tag/${tagId}`, {
        savedFilterId,
        ids,
        filterItems,
        checkAll,
    });
};

export const removeTagFromContacts = ({
    tagId,
    savedFilterId,
    ids,
    filterItems,
    checkAll,
}) => {
    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/tag/${tagId}`, {
        data: {
            savedFilterId,
            ids,
            filterItems,
            checkAll,
        },
    });
};
