import axios from 'axios';
import sentry from '@/analytics/sentry';

export const fetchTags = async () => {
    try {
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/tags?limit=1000&offset=0`);

        return data;
    } catch (e) {
        sentry.log('Loading tags failed', { message: e.message });

        throw e;
    }
};

export const fetchTagCategories = async () => {
    try {
        const { data } = await axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/tags/categories?limit=1000&offset=0`);

        const filteredTagCategories = data.filter(({ name }) => {
            return name.toLowerCase() !== 'smart lists';
        });

        return filteredTagCategories;
    } catch (e) {
        sentry.log('Loading tag categories failed', { message: e.message });

        throw e;
    }
};

export const createTag = async (tagData) => {
    tagData.name = tagData.name.replace(/,/g, '');

    try {
        const { data } = await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/tags`, tagData);

        return data;
    } catch (e) {
        sentry.log('Create tag failed', { message: e.message });

        throw e;
    }
};

export const updateTag = async (tagData) => {
    tagData.name = tagData.name.replace(/,/g, '');

    try {
        const { data } = await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/tags/update`, tagData);

        return data;
    } catch (e) {
        sentry.log('Update tag failed', { message: e.message });

        throw e;
    }
};

export const deleteTag = async (tagId) => {
    try {
        await axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/tags/delete/${tagId}`);
    } catch (e) {
        sentry.log('Delete tag failed', { message: e.message });

        throw e;
    }
};

export const createTagCategory = async (categoryData) => {
    try {
        if (categoryData.name.toLowerCase === 'smart lists') {
            throw new Error('Tag categories cannot be named "smart lists"');
        }

        const { data } = await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/tags/categories`, categoryData);

        return data;
    } catch (e) {
        sentry.log('Create tag category failed', { message: e.message });

        throw e;
    }
};
