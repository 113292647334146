<template lang="html">
    <div>
        <div v-if="!isReady" class="spinner-container">
            <ds-spinner data-qa="not-ready" class="ready-spinner" />
        </div>
        <publish-page-info
            v-else
            :title="pageTitle"
            :description="pageDescription"
            data-qa="publish-page-info"
            class="publish-page-center"
        >
            <ds-button
                v-if="hasChanges"
                :loading="isPublishing"
                class="top-action"
                @click="publish"
            >
                {{ $t(hasChanges ? 'publishSiteButton' : 'updateSiteButton') }}
            </ds-button>
            <ds-text-button v-if="isPublishedWithChanges" class="top-action" @click="close">
                {{ $t('finishLaterButton') }}
            </ds-text-button>

            <ds-outline-button
                v-if="!hasChanges"
                class="top-action"
                @click="$sites.openSitePreview(site)"
            >
                {{ $t('previewButtonLabel') }}
            </ds-outline-button>

            <div class="publish-details">
                <h5>{{ $t('setupDetails') }}</h5>
                <ds-card
                    class="setup-card"
                    elevation="z2"
                >
                    <edit-site-name
                        v-if="isEditingName"
                        :site-name="siteName"
                        @cancel="cancelEditSiteName"
                        @save-site-name="saveSiteName"
                    />
                    <view-site-name v-else :site-name="siteName" @edit-site-name="editSiteName" />
                    <edit-hosted-url
                        v-if="linkUrl"
                        :link-url="linkUrl"
                        @copy-url="$sites.copySiteUrl(site)"
                    />
                    <embed-code-section :embed-url="linkUrl" />
                </ds-card>
            </div>
        </publish-page-info>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import ViewSiteName from './publish/ViewSiteName';
import EditSiteName from './publish/EditSiteName';
import EditHostedUrl from './publish/EditHostedUrl';
import EmbedCodeSection from './publish/EmbedCodeSection';
import PublishPageInfo from './PublishPageInfo';
import { buildMarketingSiteUrl } from '@/marketingSites/components/site/site.utils';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';

export default {
    components: {
        PublishPageInfo,
        ViewSiteName,
        EditSiteName,
        EditHostedUrl,
        EmbedCodeSection,
    },

    inject: ['$site', '$sites'],

    props: {
        site: {
            type: Object,
            required: true,
        },
        isPublishing: Boolean,
        isReady: Boolean,
    },

    data() {
        return {
            isEditingName: false,
        };
    },

    computed: {
        ...mapState({
            appId: ({ auth }) => auth.session.coreAppId,
        }),

        hasChanges() {
            return this.isDraft || this.site?.status === SITE_STATUS.PUBLISHED_CHANGES;
        },

        isPublishedWithChanges() {
            return this.site?.status === SITE_STATUS.PUBLISHED_CHANGES;
        },

        isDraft() {
            return !this.site?.status || this.site?.status === SITE_STATUS.PUBLISHED_CHANGES;
        },

        linkUrl() {
            return this.appId && this.site?.slug ? buildMarketingSiteUrl(this.appId, this.site?.slug) : null;
        },

        pageTitle() {
            return this.$t(!this.hasChanges ? 'publishedTitle' : 'notPublishedTitle');
        },

        pageDescription() {
            return this.$t(!this.hasChanges ? 'publishedSubtitle' : 'notPublishedSubtitle');
        },

        siteName() {
            return this.site?.name ?? '';
        },
    },

    methods: {
        async saveSiteName(name) {
            if (name) {
                this.site.name = name;
                this.isEditingName = false;
                this.$site.changeSiteName(name);
            }
        },

        cancelEditSiteName() {
            this.isEditingName = false;
        },

        editSiteName() {
            this.isEditingName = true;
        },

        async close() {
            await this.$sites.focusSite();
        },

        async publish() {
            try {
                await this.$site.publishActiveSite();
            } catch (error) {
                this.$error({ message: this.$t('publishError') });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .publish-page-center {
        padding-top: $gp * 2.5;
        text-align: center;
    }

    .top-action {
        margin: auto;
        display: block;
    }

    .ready-spinner {
        margin: auto;
    }

    .top-action:first-of-type {
        margin-bottom: $gp / 2;
    }

    .setup-card {
        > :not(:first-child) {
            border-top: solid 1px $color-gray-200;
        }
    }

    .publish-details {
        margin-top: $gp * 2.5;
        margin-bottom: $gp * 3;
        width: px-to-rem(650);

        > h5 {
            margin-bottom: $gp;
            font-weight: 900;
        }
    }

    .public-url {
        display: block;
        margin-top: $gp;
        margin-bottom: $gp;
        font-weight: bold;
    }

    .publish-step {
        padding: ($gp * 2) $gp $gp $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "publishedTitle": "Your landing page is ready to share",
        "publishedSubtitle": "Your landing page is published and hosted on Keap, so no one can see it yet. Follow the steps below to share it or put it on your website.",
        "publishError": "There was a problem publishing.  Please try again later",
        "previewButtonLabel": "Preview landing page",
        "publishSiteButton": "Publish changes",
        "notPublishedTitle": "Are you ready to publish?",
        "notPublishedSubtitle": "Your landing page changes have been saved but not published. Publish your landing page to see your recent changes.",
        "title": "Are you ready to publish?",
        "description": "Your landing page has been saved as a draft but not published. Publish your landing page to make it visible when hosted or embeded.",
        "sitePublicUrlDescription": "Your landing page url is:",
        "alreadyPublishedDescription": "Your site has been published.",
        "finishLaterButton": "Finish later",
        "done": "Done",
        "updateSiteButton": "Update your landing page",
        "linkCopyHeadline": "Link copied!",
        "linkCopyMessage": "Public form link has been copied to your clipboard.",
        "urlCopiedMessage": "Your url was copied to your clipboard",
        "setupDetails": "Set up details"
    }
}
</i18n>
