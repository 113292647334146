<template>
    <controlled-modal
        is-open
        show-close
        intercept-close
        :title="$t('contactRecord.pages.note.new')"
        :sub-title="name"
        @close="handleClose"
    >
        <note-form @submit="handleSubmit" @input="onNoteChanged">
            <template v-slot:buttons="{ form }">
                <ds-filled-button
                    type="submit"
                    :loading="isSaving"
                    :disabled="form.body.trim() === ''"
                    data-qa="save-note"
                >
                    {{ $t('save') }}
                </ds-filled-button>

                <ds-text-button @click="$emit('close')">
                    {{ $t('global.cancel') }}
                </ds-text-button>
            </template>
        </note-form>

        <error-message-in-banner v-if="errorMessage" :message="errorMessage" />

        <toast-message v-if="toastMessage" :message="toastMessage" />
    </controlled-modal>
</template>

<script>
import axios from 'axios';
import ControlledModal from '@/shared/components/ControlledModal';
import ErrorMessageInBanner from '@/shared/components/ErrorMessageInBanner';
import ToastMessage from '@/shared/components/ToastMessage';
import NoteForm from './NoteForm.vue';
import { getFullName } from '../../../contact-info-utils';
import { NOTES_NOTE_CREATED_EVENT } from '@/shared/constants/events.constants';
import ConfirmNavigationIfDirty from '@/shared/mixins/ConfirmNavigationIfDirty.mixin';
import { trackUnsavedChangedNote } from '@/contacts/analytics';

const IDLE = 'IDLE';
const SAVING = 'SAVING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';

export default {
    name: 'AddNoteModal',

    components: {
        ControlledModal,
        ErrorMessageInBanner,
        ToastMessage,
        NoteForm,
    },

    mixins: [ConfirmNavigationIfDirty],

    props: {
        contact: {
            type: Object,
            required: true,
        },
    },

    data() {
        return { status: IDLE, note: '' };
    },

    computed: {
        name() {
            return getFullName(this.contact);
        },

        isSaving() {
            return this.status === SAVING;
        },

        toastMessage() {
            return this.status === SUCCESS ? this.$t('success.addNote') : '';
        },

        errorMessage() {
            return this.status === ERROR ? this.$t('errors.addNote') : '';
        },
    },

    methods: {
        handleSubmit({ body }) {
            this.status = SAVING;

            const payload = {
                body,
                contactId: this.contact.id,
            };

            axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/notes`, payload)
                .then((response) => {
                    this.status = SUCCESS;
                    this.$nextTick(() => {
                        this.$emit('saved', response.data);
                        this.$bus.$emit(NOTES_NOTE_CREATED_EVENT, response.data);
                    });
                })
                .catch(() => {
                    this.status = ERROR;
                });
        },

        handleClose() {
            const changed = this.note !== '';

            if (changed) {
                this.$store.commit('CONFIRM_NAVIGATION_MARK_DIRTY', 'contactNote');
            } else {
                this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'contactNote');
            }

            this.dirty_confirmNavigationWhenDirty()
                .then(() => {
                    trackUnsavedChangedNote('Add Note Modal', 'Leave without saving');
                    this.$emit('close');
                })
                .catch(() => {
                    trackUnsavedChangedNote('Add Note Modal', 'Stay here');
                    this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'contactNote');
                });
        },

        onNoteChanged (value) {
            this.note = value;
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "save": "Save note"
    }
}
</i18n>
