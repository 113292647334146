import Vue from 'vue';

export default {
    ADD_PIPELINE(state, newPipeline) {
        state.pipelineList.push(newPipeline);
    },

    SET_DEAL({ pipeline }, { deal, newIndex }) {
        const stage = pipeline.stages.find(({ id }) => {
            return id === deal.stage.id;
        });

        if (stage?.deals) {
            const dealIndex = stage.deals.findIndex(({ id }) => {
                return id === deal.id;
            });

            if (dealIndex > -1) {
                // remove deal with stale data
                stage.deals.splice(dealIndex, 1);
            }

            if (newIndex !== undefined) {
                // insert updated deal into new position
                stage.deals.splice(newIndex, 0, deal);
            } else if (dealIndex > -1) {
                // insert updated deal if position isn't changed
                stage.deals.splice(dealIndex, 0, deal);
            } else {
                // fallback
                stage.deals.push(deal);
            }
        }
    },

    SET_PIPELINE_STAGES({ pipeline, pipelineList }, stages) {
        pipeline.stages = stages;

        pipelineList.forEach((p) => {
            if (p.id === pipeline.id) {
                p.stages = stages;
            }
        });
    },

    DELETE_DEAL(state, deal) {
        if (!deal.stage) {
            return;
        }

        const { stage } = deal;

        const stageIndex = state.pipeline.stages.findIndex(({ id }) => {
            return id === stage.id;
        });

        const dealIndex = state.pipeline.stages[stageIndex].deals.findIndex(({ id }) => {
            return id === deal.id;
        });

        state.pipeline.stages[stageIndex].deals.splice(dealIndex, 1);
    },


    DELETE_STAGE({ pipeline, pipelineList }, { id }) {
        pipeline.stages = pipeline.stages.filter((stage) => stage.id !== id);

        pipelineList.forEach((p) => {
            p.stages = p.stages.filter((stage) => stage.id !== id);
        });
    },

    SET_ACTIVE_DEAL(state, deal) {
        if (deal && deal.value && deal.value.amount) {
            deal.value.amount = (+deal.value.amount).toFixed(2);
        }

        state.deal = deal;
    },

    RESET_DEAL(state) {
        state.deal = { contacts: [] };
    },

    SET_PIPELINE(state, pipeline) {
        state.pipeline = pipeline;
    },

    SET_PIPELINE_LOADING(state, loading) {
        state.pipelineLoading = loading;
    },

    SET_PIPELINE_LIST(state, pipelineList) {
        state.pipelineList = pipelineList;
    },

    SET_OUTCOME_OPTIONS(state, { pipelineId, options }) {
        Vue.set(state.outcomeOptions, pipelineId, options);
    },

    START_PIPELINE_LIST_LOADING(state) {
        state.pipelineListLoading = true;
    },

    STOP_PIPELINE_LIST_LOADING(state) {
        state.pipelineListLoading = false;
    },

    REMOVE_CONTACT(state, { deal, contactId }) {
        const index = deal.contacts.findIndex((contact) => contact.id === contactId);

        deal.contacts.splice(index, 1);

        const hasPrimary = deal.contacts.some((contact) => contact.primaryContact);

        if (deal.contacts.length && !hasPrimary) {
            deal.contacts[0].primaryContact = true;
        }
    },

    SET_PRIMARY_CONTACT(state, { deal, contactId }) {
        deal.contacts.forEach((contact) => {
            if (contact.primaryContact) {
                contact.primaryContact = false;
            }

            if (contact.id === contactId) {
                contact.primaryContact = true;
            }
        });
    },

    ADD_ACTIVITIES({ deal }, activities) {
        deal.activityFeed.activityFeedEvents.push(...activities);
    },

    SET_ACTIVITY_CURSOR({ deal }, cursor) {
        deal.activityFeed.cursor = cursor;
    },

    ADD_ACTIVITY_FEED_ITEM({ deal }, { type, details }) {
        if (deal.activityFeed) {
            const publishRequestDate = new Date();

            const addActivity = {
                publishRequestDate,
                type,
                details,
            };

            deal.activityFeed.activityFeedEvents.push(addActivity);
        }
    },

    SET_STAGE_CONFIGURATION(state, payload) {
        state.configuration = payload;
    },

    SET_PIPELINE_STAGE_NAME({ pipeline, pipelineList }, stage) {
        pipelineList.forEach(({ id, stages }, i) => {
            if (pipeline.id === id) {
                stages.forEach((s, j) => {
                    if (s.id === stage.id) {
                        pipelineList[i].stages[j].name = stage.name;
                    }
                });
            }
        });
    },

    REMOVE_DEAL_FROM_STAGE({ pipeline }, { previousStage, deal }) {
        if (previousStage) {
            const stage = pipeline.stages.find(({ id }) => {
                return id === previousStage.id;
            });

            if (stage) {
                const dealIndex = stage.deals.findIndex(({ id }) => {
                    return id === deal.id;
                });

                if (dealIndex > -1) {
                    stage.deals.splice(dealIndex, 1);
                }
            }
        }
    },

    DELETE_PIPELINE({ pipelineList }, pipelineId) {
        const index = pipelineList.findIndex(({ id }) => {
            return id === pipelineId;
        });

        if (index > -1) {
            pipelineList.splice(index, 1);
        }
    },
};
