export const AUTOMATIONS_CONTACT_ADDED_EVENT = 'automations.contact.added';
export const AUTOMATIONS_CONTACT_REMOVED_EVENT = 'automations.contact.removed';

export const APPOINTMENTS_APPOINTMENT_CREATED_EVENT = 'appointments.appointment.created';
export const APPOINTMENTS_APPOINTMENT_CANCELED_EVENT = 'appointments.appointment.canceled';

export const EMAILS_EMAIL_SENT_EVENT = 'emails.email.sent';

export const FILES_FILE_CREATED_EVENT = 'files.file.created';
export const FILES_FILE_UPDATED_EVENT = 'files.file.updated';
export const FILES_FILE_DELETED_EVENT = 'files.file.deleted';

export const MESSAGES_MESSAGE_SENT_EVENT = 'messages.message.sent';

export const MONEY_INVOICE_CREATED_EVENT = 'money.invoice.created';
export const MONEY_INVOICE_UPDATED_EVENT = 'money.invoice.updated';
export const MONEY_INVOICE_DELETED_EVENT = 'money.invoice.deleted';
export const MONEY_QUOTE_CREATED_EVENT = 'money.quote.created';
export const MONEY_QUOTE_UPDATED_EVENT = 'money.quote.updated';
export const MONEY_QUOTE_DELETED_EVENT = 'money.quote.deleted';
export const MONEY_PAYMENT_CREATED_EVENT = 'money.payment.created';

export const NOTES_NOTE_CREATED_EVENT = 'notes.note.created';
export const NOTES_NOTE_UPDATED_EVENT = 'notes.note.updated';
export const NOTES_NOTE_DELETED_EVENT = 'notes.note.deleted';

export const PIPELINE_STAGE_UPDATED_EVENT = 'pipeline.stage.updated';

export const CONTACTS_TAGS_APPLIED_EVENT = 'contacts.tags.applied';
export const CONTACTS_TAGS_REMOVED_EVENT = 'contacts.tags.removed';

export const TASKS_TASK_CREATED_EVENT = 'tasks.task.created';
export const TASKS_TASK_UPDATED_EVENT = 'tasks.task.updated';
export const TASKS_TASK_DELETED_EVENT = 'tasks.task.deleted';
