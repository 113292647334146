<template lang="html">
    <div class="add-contact">
        <div class="avatar-wrapper">
            <contact-avatar
                :size="64"
                :name="displayName"
                :email="contact.email"
            />
        </div>

        <div class="contact-type-wrapper">
            <contact-type-selector
                v-model="contact.contactType"
                @input="handleContactTypeInput"
            />
        </div>

        <form
            ref="form"
            class="add-form"
            novalidate
            autocomplete="no"
        >
            <section v-if="!showAllFields">
                <ds-input-field
                    ref="givenName"
                    v-model.trim="contact.givenName"
                    type="text"
                    name="addContactGivenName"
                    autocomplete="no"
                    :label="$t('forms.givenName')"
                    :submitted="submitted"
                    @input="handleNameInput('givenName', contact.givenName)"
                />

                <ds-input-field
                    v-model.trim="contact.familyName"
                    type="text"
                    name="addContactFamilyName"
                    autocomplete="no"
                    :label="$t('forms.familyName')"
                    :submitted="submitted"
                    @input="handleNameInput('familyName', contact.familyName)"
                />

                <ds-input-field
                    v-model.trim="contact.email"
                    type="email"
                    name="addContactEmail"
                    autocomplete="no"
                    :label="$t('workEmail')"
                    :submitted="emailSubmitted"
                    :invalid="emailInvalid"
                    :required="emailRequired"
                    @input="handleEmailInput"
                    @blur="handleEmailBlur"
                >
                    <template #error>
                        <div>
                            <i18n :path="errorText">
                                <a v-if="duplicatesPath" @click="navigateToDuplicates">{{ $t('errors.viewDuplicateContact') }}</a>
                            </i18n>
                        </div>
                    </template>
                </ds-input-field>

                <async-contact-company-dropdown
                    v-model="contact.company"
                    data-qa="company-field"
                    allow-add
                    event-source="Add a Contact Modal"
                    :companies="fieldOptions.companies"
                    :readonly="lockCompany"
                />

                <div class="phone-field-wrapper">
                    <ds-phone-input
                        v-model.trim="contact.phone1.value"
                        class="phone-field"
                        data-qa="phone-input"
                        autocomplete="no"
                        @input="handleInput"
                    />

                    <ds-select-field
                        v-model="contact.phone1.type"
                        class="phone-type-input"
                        name="phoneType"
                        bind-value-only
                        data-qa="phone-type"
                        :options="fieldOptions.phone"
                        :label="$t('forms.type')"
                        @input="handleInput"
                    />
                </div>
            </section>

            <form v-else autocomplete="no">
                <contact-general
                    class="general-section"
                    data-qa="contact-general"
                    editing
                    event-source="Add a Contact Modal"
                    :contact="contact"
                    :lock-company="lockCompany"
                    @input="handleInput"
                />

                <contact-phone-email
                    editing
                    :class="['phone-email-section', { 'section-separator': showAllFields }]"
                    :contact="contact"
                    @input="handleInput"
                />

                <contact-links
                    class="links-section section-separator"
                    editing
                    :contact="contact"
                    @input="handleInput"
                />

                <contact-addresses
                    class="addresses-section section-separator"
                    editing
                    :contact="contact"
                    @input="handleInput"
                />

                <contact-additional-info
                    class="additional-info-section section-separator"
                    editing
                    disable-add-custom-fields
                    :contact="contact"
                    @input="handleInput"
                />
            </form>

            <div :class="{ 'notes-section section-separator': showAllFields }">
                <ds-text-area-field
                    v-model="contact.note"
                    name="note"
                    autocomplete="no"
                    :label="$t('addANote')"
                    @input="handleInput"
                />
            </div>

            <ds-text-button
                v-if="!showAllFields"
                data-qa="show-all-fields"
                @click="handleShowAllFieldsClick"
            >
                {{ this.$t('showAllFields') }}
            </ds-text-button>
        </form>


        <transition name="slideDown">
            <error-box v-if="!saving && showFormLevelError && submitted" class="form-error">
                {{ $t('formError') }}
            </error-box>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import axios from 'axios';

import errorMixin from '@/shared/mixins/error.mixin';
import {
    CONTACT_TYPES,
    EMAIL_STATUS,
    ERROR_CODE_INVALID_EMAIL,
    NOTE_TYPES,
    PHONE_TYPES,
    DUMMY_COMPANY_ID,
} from '@/contacts/contacts.constants';

import { patterns } from '@infusionsoft/vue-utils';
import { getFullName } from '@/contacts/contact-info-utils';
import {
    trackCompanyAssociated,
    trackNoteAdded,
    trackContactTypeChanged,
    trackContactAdded,
} from '@/contacts/analytics';
import fullstory from '@/analytics/fullstory';

import ContactAvatar from '@/shared/components/ContactAvatar';
import ContactTypeSelector from '@/contacts/components/ContactTypeSelector';
import ContactSavedModal from '@/contacts/components/add/ContactSavedModal';
import ContactGeneral from '@/contacts/components/simple/ContactGeneral';
import AsyncContactCompanyDropdown from '@/contacts/components/simple/AsyncContactCompanyDropdown';
import ContactPhoneEmail from '@/contacts/components/simple/ContactPhoneEmail';
import ContactLinks from '@/contacts/components/simple/ContactLinks';
import ContactAddresses from '@/contacts/components/simple/ContactAddresses';
import ContactAdditionalInfo from '@/contacts/components/simple/ContactAdditionalInfo';
import ErrorBox from '@/shared/components/ErrorBox';

const EVENT_NAME_BASE = 'Contacts - Add a contact - clicked :';

const createDefaultContact = () => ({
    anniversary: null,
    birthday: null,
    company: null,
    contactType: CONTACT_TYPES.LEAD,
    email: '',
    email2: '',
    email3: '',
    fax1: {
        type: null,
        value: null,
    },
    fax2: {
        type: null,
        value: null,
    },
    facebook: '',
    firstName: '',
    givenName: '',
    jobTitle: '',
    lastName: '',
    familyName: '',
    linkedin: '',
    locale: '',
    middleName: '',
    nickname: '',
    optInReason: EMAIL_STATUS.SINGLE_OPT_IN,
    phone1: {
        value: '',
        type: PHONE_TYPES.MOBILE,
        extension: null,
    },
    phone2: {
        value: '',
        extension: '',
        type: null,
    },
    phone3: {
        value: '',
        extension: '',
        type: null,
    },
    phone4: {
        value: '',
        extension: '',
        type: null,
    },
    phone5: {
        value: '',
        extension: '',
        type: null,
    },
    shippingAddress: {
        street1: '',
        street2: '',
        locality: '',
        region: '',
        postalCode: '',
        countryCode: '',
    },
    billingAddress: {
        street1: '',
        street2: '',
        locality: '',
        region: '',
        postalCode: '',
        countryCode: '',
    },
    otherAddress: {
        street1: '',
        street2: '',
        locality: '',
        region: '',
        postalCode: '',
        countryCode: '',
    },
    spouseName: '',
    suffix: '',
    title: '',
    twitter: '',
    website: '',
    customFields: [],
});

export default {
    components: {
        AsyncContactCompanyDropdown,
        ContactTypeSelector,
        ContactGeneral,
        ContactPhoneEmail,
        ContactLinks,
        ContactAddresses,
        ContactAdditionalInfo,
        ErrorBox,
        ContactAvatar,
    },

    mixins: [errorMixin],

    props: {
        emailRequired: Boolean,
    },

    data() {
        return {
            contact: createDefaultContact(),
            nestedModal_subTitle: '',
            nestedModal_title: this.$t('contactList.addContact.title'),
            nestedModal_showClose: null,
            nestedModal_showBack: null,
            submitted: false,
            emailInvalid: false,
            errorText: '',
            saving: false,
            hideSuccessModal: false,
            lockCompany: false,
            successCallback: null,
            interceptNavigateToDuplicates: null,
            duplicatesPath: null,
            showAllFields: false,
            showFormLevelError: false,
            analyticsInitialFlow: null,
        };
    },

    mounted() {
        this.$bus.$on('ADD_ANOTHER_CONTACT', this.handleAddAnotherContact);
    },

    beforeDestroy() {
        this.$bus.$off('ADD_ANOTHER_CONTACT', this.handleAddAnotherContact);
    },

    computed: {
        ...mapGetters({
            fieldOptions: 'contacts/fieldOptions',
            isClientsContactList: 'contacts/isClientsContactList',
        }),

        ...mapState({
            user: ({ auth }) => auth.user,
        }),

        displayName() {
            return getFullName(this.contact);
        },

        validForm() {
            return Boolean(
                this.contact.givenName
                    || this.contact.familyName
                    || this.contact.firstName
                    || this.contact.lastName
                    || (this.contact.email && this.isValidEmail),
            );
        },

        nestedModal_actionText() {
            return this.$t('global.save');
        },

        nestedModal_actionMethod() {
            return 'addContact';
        },

        nestedModal_actionDisabled() {
            if (this.emailRequired && !this.isValidEmail) {
                return true;
            }

            return this.emailInvalid || !this.validForm;
        },

        nestedModal_actionLoading() {
            return this.saving;
        },

        emailSubmitted() {
            return this.submitted || this.emailInvalid;
        },

        isValidEmail() {
            return RegExp(patterns.email).test(this.contact.email);
        },
    },

    methods: {
        handleInput() {
            this.$store.commit('SET_NESTED_MODAL_DIRTY', true);
        },

        handleContactTypeInput(newValue, oldValue) {
            trackContactTypeChanged({
                eventSource: 'Add Contact Modal',
                from: oldValue,
                to: newValue,
            });
        },

        handleEmailInput() {
            this.duplicatesPath = null;
            this.submitted = false;
            this.emailInvalid = false;

            this.handleInput();
        },

        handleNameInput(nameType, name) {
            if (nameType === 'givenName') {
                this.contact.firstName = name;
            } else if (nameType === 'familyName') {
                this.contact.lastName = name;
            }

            this.handleInput();
        },

        handleEmailBlur() {
            if (this.contact.email && !this.isValidEmail) {
                this.error_handleAddContactError({ code: ERROR_CODE_INVALID_EMAIL });
            }

            if (!this.contact.email) {
                this.emailInvalid = false;
            }
        },

        handleAddAnotherContact() {
            this.nestedModal_reset();
            this.focusFirstInput();
        },

        navigateToDuplicates() {
            const standardOperations = () => {
                this.$bus.$emit('POP_NESTED_MODAL', 1);
                this.$router.push(this.duplicatesPath);
                this.$track('Contacts - Single Contact Add - clicked: link to view contact from duplicate contact error message');
            };

            if (typeof this.interceptNavigateToDuplicates === 'function') {
                this.interceptNavigateToDuplicates(standardOperations);
            } else {
                standardOperations();
            }
        },

        async addContact() {
            this.$track(`${EVENT_NAME_BASE} Save contact`);

            this.submitted = true;

            if (this.$refs.form.checkValidity()) {
                this.saving = true;

                const builtSaveContact = this.buildSaveContact();

                try {
                    const [newContact] = await this.$store.dispatch('contacts/ADD_CONTACT', builtSaveContact);

                    if (this.analyticsInitialFlow) {
                        trackContactAdded('Add a Contact Modal', {
                            'Initial flow': this.analyticsInitialFlow,
                        });
                    } else {
                        trackContactAdded('Add a Contact Modal');
                    }

                    if ((this.contact.company && newContact.id) || (this.showAllFields && newContact.id)) {
                        const contactDetails = {
                            ...this.contact,
                            firstName: newContact.givenName || this.contact.firstName,
                            lastName: newContact.familyName || this.contact.lastName,
                        };

                        delete contactDetails.givenName;
                        delete contactDetails.familyName;
                        delete contactDetails.optInReason;
                        delete contactDetails.note;

                        if (this.lockCompany) {
                            delete contactDetails.company;
                        }

                        newContact.givenName = contactDetails.firstName;
                        newContact.familyName = contactDetails.lastName;

                        await axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/${newContact.id} `, contactDetails);

                        if (contactDetails.company) {
                            trackCompanyAssociated('Add a Contact Modal');
                        }
                    }

                    newContact.notes = builtSaveContact.notes;

                    if (newContact.notes?.length > 0) {
                        trackNoteAdded('Add a Contact');
                    }

                    this.$store.commit('contacts/INCREMENT_CONTACT_TOTAL');

                    if (typeof this.successCallback === 'function') {
                        this.successCallback(newContact);
                    }

                    this.nestedModal_reset();

                    this.$emit('refresh', true);
                    this.$emit('added', newContact);

                    this.$bus.$emit('CONTACT_ADDED', newContact);

                    if (this.hideSuccessModal) {
                        this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', false);

                        this.$bus.$emit('POP_NESTED_MODAL', 1);
                    } else {
                        this.$store.commit('SET_NESTED_MODAL_EDIT_MODE_ENABLED', false);

                        this.$bus.$emit('PUSH_NESTED_MODAL', {
                            component: ContactSavedModal,
                            showClose: true,
                            data: newContact,
                            modalSize: 'med',
                        });

                        fullstory.event('Contact Added Success Modal Viewed', {
                            'Event Source': 'Add a Contact Modal',
                        });
                    }

                    this.saving = false;
                } catch (error) {
                    this.error_handleAddContactError(error, this.contact.email);
                    this.saving = false;
                }
            }
        },

        buildSaveContact() {
            const {
                givenName,
                familyName,
                phone1,
                email,
                contactType,
                note,
            } = this.contact;

            const saveContact = {
                email,
                contactType,
                optInReason: EMAIL_STATUS.SINGLE_OPT_IN,
                ownerId: this.user.id,
            };

            if (note && note.trim() !== '') {
                saveContact.notes = [{
                    body: note,
                    type: NOTE_TYPES.OTHER,
                    userId: this.user.id,
                }];
            }

            saveContact.phone = phone1 && phone1.value ? phone1 : null;
            saveContact.givenName = givenName;
            saveContact.familyName = familyName;
            saveContact.sourceType = 'MANUAL';

            return saveContact;
        },

        handleChange() {
            if (!this.submitted) {
                this.$track(`${EVENT_NAME_BASE} AA CTA to save and send an email`);
            }
        },

        nestedModal_back() {
            if (this.contact.company === null) {
                this.$store.commit('contacts/DELETE_COMPANY',
                    DUMMY_COMPANY_ID);
            }
        },

        nestedModal_open({
            buttonLabel,
            title,
            subTitle,
            contact = null,
            hideSuccessModal,
            successCallback,
            interceptNavigateToDuplicates,
            showClose = null,
            showBack = null,
            showFormLevelError,
            lockCompany = null,
            analyticsInitialFlow = null,
        } = {}) {
            if (title) {
                this.nestedModal_title = title;
            }

            if (subTitle) {
                this.nestedModal_subTitle = subTitle;
            }

            if (buttonLabel) {
                this.buttonLabel = buttonLabel;
            }

            if (typeof showClose === 'boolean') {
                this.nestedModal_showClose = showClose;
            }

            if (typeof showBack === 'boolean') {
                this.nestedModal_showBack = showBack;
            }

            if (showFormLevelError) {
                this.showFormLevelError = showFormLevelError;
            }

            if (typeof lockCompany === 'boolean') {
                this.lockCompany = lockCompany;
            }

            if (analyticsInitialFlow) {
                this.analyticsInitialFlow = analyticsInitialFlow;
            }

            this.focusFirstInput();
            this.setContactType();

            if (contact) {
                this.contact.givenName = contact.givenName;
                this.contact.familyName = contact.familyName;
                this.contact.email = contact.email;
                this.contact.company = contact.company;

                if (this.contact.phone1 && contact.phone1) {
                    this.contact.phone1.value = contact.phone1.value;
                }

                if (contact.contactType) {
                    this.contact.contactType = contact.contactType;
                }
            }

            if (typeof successCallback === 'function') {
                this.successCallback = successCallback;
            }

            this.hideSuccessModal = hideSuccessModal;

            if (typeof interceptNavigateToDuplicates === 'function') {
                this.interceptNavigateToDuplicates = interceptNavigateToDuplicates;
            }
        },

        nestedModal_reset() {
            this.contact = createDefaultContact();

            this.submitted = false;
            this.emailInvalid = false;
            this.nestedModal_subTitle = '';
            this.$store.commit('contacts/SET_NOTES', []);
            this.setContactType();
        },

        setContactType() {
            if (this.isClientsContactList) {
                this.contact.contactType = CONTACT_TYPES.CUSTOMER;

                return;
            }

            this.contact.contactType = CONTACT_TYPES.LEAD;
        },

        focusFirstInput() {
            const element = this.$refs.givenName;

            if (element) {
                this.$nextTick(() => {
                    element.input_focus();
                });
            }
        },

        handleShowAllFieldsClick() {
            const { givenName, familyName } = this.contact;

            if (givenName) {
                this.contact.firstName = givenName;
            }

            if (familyName) {
                this.contact.lastName = familyName;
            }

            this.showAllFields = true;

            this.$store.dispatch('LOAD_COUNTRY_OPTIONS');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .add-contact {
        display: flex;
        flex-direction: column;
    }

    .add-form {
        display: flex;
        flex-direction: column;
    }

    .phone-field-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $gp;
    }

    .label {
        color: $color-gray-700;
        display: block;
        font-size: --input-label-font-size;
        margin-bottom: $gp / 4;
        align-self: baseline;
        font-weight: $font-weight-regular;
    }

    .button-toggle-field {
        margin-bottom: $gp * 1.5;
    }

    .avatar-wrapper {
        margin: $gp / 2 auto;
    }

    .contact-type-wrapper {
        display: flex;
        justify-content: center;
        margin: $gp / 2 0 $gp;
    }

    .name-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: $gp;
    }

    .customize-form {
        text-align: center;
        color: $color-gray-700;
    }

    .section-separator {
        border-top: px-to-rem(2px) solid #{$color-gray-200};
    }

    .general-section {
        padding: 0 0 $gp * 1.5;
    }

    .phone-email-section {
        padding: $gp 0 $gp * 1.5;
    }

    .links-section {
        padding: $gp 0 $gp * 1.5;
    }

    .addresses-section {
        padding: $gp * 1.5 0 $gp * 1.5;
    }

    .additional-info-section {
        padding: $gp * 1.5 0 $gp * 2;
    }

    .notes-section {
        padding: $gp * 2 0;
    }

    .form-error {
        margin: 0;
    }

    .slideDown-enter-active, .slideDown-leave-active {
        transition: all $animation-duration $animation-in-timing;
        overflow: hidden;
        max-height: px-to-rem(100px);
    }

    .slideDown-enter, .slideDown-leave-to {
        transition: all $animation-duration $animation-in-timing;
        overflow: hidden;
        max-height: 0;
    }
</style>

<i18n>
{
    "en-us": {
        "saveContact": "Save contact",
        "workEmail": "Work email",
        "addANote": "Add a note",
        "company": "Company",
        "showAllFields": "Show more fields",
        "formError": "Something appears to be wrong, and we can't add this contact right now. Please try again in a minute."
    }
}
</i18n>
