<template>
    <controlled-modal
        :is-open="isOpen"
        class="send-test-modal"
        size="med"
        show-close
        @close="close"
    >
        <template #title>
            <h4>
                {{ $t('modalTitle') }}
            </h4>
        </template>

        <template #header>
            <ds-button
                class="send-button"
                :loading="sending"
                :disabled="disableSendTest"
                @click="submit"
            >
                {{ $t('confirm') }}
            </ds-button>
        </template>

        <template #default>
            <form @submit.prevent="submit">
                <label class="email-label">{{ $t('emailLabel') }}</label>

                <ds-input-field
                    v-model="emailTo"
                    data-qa="send-test-email-to"
                    type="email"
                    autofocus
                />

                <label>{{ $t('contactLabel') }}</label>

                <p class="helper-text">
                    {{ $t('contactSubtitle') }}
                </p>

                <contact-search-field
                    v-model="contact"
                    data-qa="select-contact-send-test"
                />
            </form>
        </template>
    </controlled-modal>
</template>

<script>
import { mapState } from 'vuex';
import { patterns } from '@infusionsoft/vue-utils';
import ContactSearchField from '@/contacts/components/ContactSearchField';
import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: {
        ContactSearchField,
        ControlledModal,
    },

    props: {
        isOpen: Boolean,
        sending: Boolean,
    },

    data() {
        return {
            emailTo: this.user?.email,
            contact: {},
        };
    },

    beforeDestroy() {
        this.close();
    },

    mounted() {
        this.emailTo = this.user?.email;
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
        }),

        disableSendTest() {
            return Boolean(!this.isValidEmail || !Object.keys(this.contact).length);
        },

        isValidEmail() {
            return RegExp(patterns.email).test(this.emailTo);
        },
    },

    methods: {
        close() {
            this.$emit('close');

            this.contact = {};
            this.emailTo = this.user?.email;
        },

        submit() {
            const { emailTo, contact } = this;

            this.$emit('send-test', { emailTo, contactId: contact.id });
        },
    },
};
</script>

<style lang="scss" scoped>
    .email-label {
        margin-bottom: $gp / 2;
    }

    .helper-text {
        font-size: $font-size-xs;
        color: $color-text-subtle;
        margin-bottom: $gp / 2;
    }

    .send-button {
        @include margin(0 $gp / 2 0 auto);
    }
</style>

<i18n>
{
    "en-us": {
        "modalTitle": "Send test email",
        "confirm": "Send test",
        "emailLabel": "Test email recipient",
        "contactLabel": "Populate merge fields using this contact's details:",
        "contactSubtitle": "This won't send them an email. It's simply meant to show you how your fields will populate."
    }
}
</i18n>
