import axios from 'axios';

const loadContactFiles = ({ state, commit }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${state.contact.id}/files`)
            .then(({ data }) => {
                commit('SET_FILES', data);
                resolve();
            })
            .catch(reject);
    });
};

const addContactFile = ({
    state, dispatch,
}, { contactId, file }) => {
    const reader = new FileReader();
    const id = contactId || state.contact.id;

    const promise = new Promise((resolve, reject) => {
        reader.onloadend = function (e) {
            if (e.target.error || !e.target.result) {
                reject(e);
            } else {
                resolve(e.target.result);
            }
        };
    });

    reader.readAsDataURL(file);

    return promise.then((result) => {
        const base64String = result.split(',').pop();

        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/file/files`, {
            file_name: file.name,
            file_data: base64String,
            contact_id: id,
            is_public: false,
            file_association: 'CONTACT',
        });
    }).then(({ data }) => {
        if (state.contact.id) {
            dispatch('LOAD_CONTACT_FILES');
        }

        return data;
    });
};

const deleteContactFile = ({ dispatch }, fileId) => {
    return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/file/files/${fileId}`)
            .then(() => {
                dispatch('LOAD_CONTACT_FILES');
                resolve();
            })
            .catch(reject);
    });
};

const updateContactFile = ({ dispatch }, { id, name }) => {
    return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/file/files/${id}`, name)
            .then(() => {
                dispatch('LOAD_CONTACT_FILES');
                resolve();
            })
            .catch(reject);
    });
};

export {
    loadContactFiles as LOAD_CONTACT_FILES,
    addContactFile as ADD_CONTACT_FILE,
    deleteContactFile as DELETE_CONTACT_FILE,
    updateContactFile as UPDATE_CONTACT_FILE,
};
