<template lang="html">
    <div class="automate-step">
        <bus-event name="CONTEXTUAL_AUTOMATION_STATUS_CHANGED" @event="refreshAutomations" />
        <bus-event name="PUBLISHED_AUTOMATION_UPDATED" @event="refreshAutomations" />
        <bus-event name="AUTOMATION_APPOINTMENT_UPDATED" @event="refreshAutomations" />

        <p v-if="editing" class="automate-description">
            {{ $tc('description', automations && automations.length || !editing) }}
        </p>

        <contextual-automation-list
            :limit="$options.CARD_LIST_DISPLAY_COUNT"
            :automations="automations"
            @edit="editAutomation"
            @view-all="viewAll"
            @create="createAutomation"
        />

        <contextual-automation-panel
            class="contextual-automation-panel"
            :is-open="isAutomationsOpen"
            :settings="automationSettings"
            @close="reset"
            @created="refreshAutomations"
            @renamed="renameAutomation"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import amplitude from '@/analytics/amplitude';
import debounce from 'lodash.debounce';

import { LOADING_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import BusEvent from '@/shared/components/BusEvent';
import ContextualAutomationPanel from '@/automations/components/ContextualAutomationPanel';
import ContextualAutomationList from '@/automations/components/list/ContextualAutomationList';
import { AUTOMATION_FILTERS } from '@/automations/constants/automations.filter.constants';
import {
    CONTEXT_TYPE, MAXLENGTH, APPOINTMENT_SCHEDULED, SAVE_STATUS,
} from '@/automations/constants/automations.constants';

const CARD_LIST_DISPLAY_COUNT = 4;
const CONTEXTUAL_CARD_LIST_LIMIT = CARD_LIST_DISPLAY_COUNT + 1;

export default {
    CARD_LIST_DISPLAY_COUNT,

    components: {
        ContextualAutomationList,
        ContextualAutomationPanel,
        BusEvent,
    },

    props: {
        appointmentType: {
            type: Object,
            default: () => ({}),
        },
        debounceDelay: {
            type: Number,
            default: LOADING_DEBOUNCE_DELAY,
        },
        editing: Boolean,
    },

    data() {
        return {
            automations: [],
            isAutomationsOpen: false,
            automationSettings: {},
            shouldRefresh: false,
        };
    },

    created() {
        this.load();

        if (this.debounceDelay > 0) {
            this.refreshAutomations = debounce(this.refreshAutomations, this.debounceDelay);
        }
    },

    watch: {
        automationSaveStatus(newVal, oldVal) {
            if (this.shouldRefresh && newVal !== SAVE_STATUS.SAVING && oldVal === SAVE_STATUS.SAVING) {
                this.load();
                this.shouldRefresh = false;
            }
        },
    },

    computed: {
        ...mapState({
            user: ({ auth }) => auth.user,
            automationSaveStatus: ({ automations }) => automations.saveStatus,
        }),

        defaultSettings() {
            return {
                context: { type: CONTEXT_TYPE.APPOINTMENTS },
            };
        },
    },

    methods: {
        reset() {
            this.isAutomationsOpen = false;
            this.shouldRefresh = false;
        },

        refreshAutomations() {
            // finish saving before calling load
            if (this.automationSaveStatus === SAVE_STATUS.SAVING) {
                this.shouldRefresh = true;
            } else {
                this.load();
            }
        },

        async load() {
            try {
                const automations = await this.$store.dispatch('automations/LOAD_AUTOMATIONS', {
                    limit: CONTEXTUAL_CARD_LIST_LIMIT,
                    cursor: '',
                    tags: [
                        AUTOMATION_FILTERS.ANY_APPOINTMENT,
                        `${AUTOMATION_FILTERS.USER_APPOINTMENT}${this.user.casId}`,
                        `${AUTOMATION_FILTERS.APPOINTMENT}${this.appointmentType.id}`,
                    ],
                });

                this.automations = automations;
            } catch {
                this.$error({ message: this.$t('errorLoadingAutomations') });
            }
        },

        renameAutomation({ id, name }) {
            const automation = this.automations.find(({ id: automationId }) => automationId === id);

            if (automation) {
                automation.name = name;
            }
        },

        createAutomation() {
            this.automationSettings = {
                ...this.defaultSettings,
                createAutomation: {
                    name: this.$t('automationName', {
                        name: this.appointmentType.name.substr(0, MAXLENGTH.NAME),
                    }),
                    triggers: [
                        {
                            name: this.$t('appointment'),
                            type: APPOINTMENT_SCHEDULED,
                            sourceId: this.appointmentType.id,
                        },
                    ],
                },
            };

            this.openContextualPanel('New automation');
        },

        viewAll() {
            this.automationSettings = { ...this.defaultSettings };
            this.openContextualPanel('View all');
        },

        async editAutomation(id) {
            this.automationSettings = { ...this.defaultSettings, automationId: id };
            this.openContextualPanel('Edit');
        },

        openContextualPanel(clickContext) {
            const { id, name } = this.appointmentType;

            this.$store.commit('automations/SET_AUTOMATION_FILTERS', [{
                value: AUTOMATION_FILTERS.ANY_APPOINTMENT,
                label: this.$t('automation.filter.anyAppointment'),
            }, {
                value: `${AUTOMATION_FILTERS.USER_APPOINTMENT}${this.user.casId}`,
                label: this.user.fullName,
            }, {
                value: `${AUTOMATION_FILTERS.APPOINTMENT}${id}`,
                label: name,
            }]);

            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATIONS_LOADED, {
                'Event Source': this.editing ? 'Edit Appointment' : 'Create Appointment',
                Clicked: clickContext,
            });

            this.isAutomationsOpen = true;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .automate-description {
        color: $color-text-subtle;
    }
</style>

<i18n>
{
    "en-us": {
        "appointment": "Appointment scheduled",
        "automationName": "Appointment: {name}",
        "description": "These automations are triggered by this appointment type. | Trigger a follow-up when a contact books or cancels an appointment.",
        "errorLoadingAutomations": "Something went wrong and we couldn't load associated automations."
    }
}
</i18n>
