<template lang="html">
    <section>
        <div class="feedback">
            <span>{{ $t('feedback.message') }}</span>

            <p>
                {{ $t('feedback.tellUs') }}
            </p>

            <share-feedback-button feature="automations" />
        </div>
    </section>
</template>


<script>
import ShareFeedbackButton from '@/support/components/ShareFeedbackButton';

export default {
    components: {
        ShareFeedbackButton,
    },
};
</script>

<style lang="scss" scoped>
    .feedback {
        margin: $gp 0;
        color: $color-text-subtle;
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "feedback": {
            "message": "Not seeing something you wish to automate?",
            "tellUs": "Let us know!"
        }
    }
}
</i18n>
