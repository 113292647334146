<template>
    <empty-state
        class="automation-pipeline-empty-state"
        :title="$t('title')"
        :description="$t('description')"
        image="pipeline-color"
    >
        <ds-filled-button
            data-qa="pipeline-button"
            trailing-icon="external-link"
            @click="goToPipeline"
        >
            {{ $t('action') }}
        </ds-filled-button>
    </empty-state>
</template>

<script>
import EmptyState from '@/shared/components/EmptyState';

export default {
    components: {
        EmptyState,
    },

    methods: {
        goToPipeline() {
            this.$router.push({ name: 'pipeline' });
        },
    },
};
</script>

<style lang="scss" scoped>
    .automation-pipeline-empty-state {
        padding-top: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Set up your stages in Pipeline",
        "description": "Before you can create pipeline automations, you’ll need to set up Pipeline and add your stages.",
        "action": "Set up Pipeline"
    }
}
</i18n>
