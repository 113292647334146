<template>
    <section class="sites-list">
        <sites-no-results
            v-if="showEmptyState"
            data-qa="empty-state"
        />

        <div v-else class="site-items">
            <div class="filter-row">
                <site-search :search.sync="search" />
                <site-filters :status.sync="activeFilter" />
            </div>

            <empty-results
                :filter="activeFilter.label"
                :no-filter-results="noFilterResults"
                :no-search-results="noSearchResults"
                :search="search"
            />

            <ds-table-grid
                v-show="siteTableData.length && siteTableData.length > 0"
                ref="table"
                key="key"
                class="site-table"
                :data="siteTableData"
                infinite
                :debounce-delay="debounceDelay"
                :show-loading="loading"
                :placeholder-height="75"
                sort-field="updatedDate"
                :click-row="openSite"
                @load="load"
                @sort="sortSites"
            >
                <ds-table-grid-column
                    :label="$t('grid.columns.name')"
                    prop="name"
                    sortable
                >
                    <template #default="site">
                        <div>
                            <p class="site-name">
                                {{ site.name }}
                            </p>
                            <p class="site-status" :class="statusClassName(site)">
                                {{ statusLabel(site) }}
                            </p>
                        </div>
                    </template>
                </ds-table-grid-column>

                <ds-table-grid-column
                    :label="$t('grid.columns.updatedDate')"
                    prop="updatedDate"
                    sortable
                />

                <ds-table-grid-column
                    :label="$t('grid.columns.pageViews')"
                    prop="pageViews"
                    sortable
                >
                    <template #default="site">
                        <div class="numeric-cell">
                            {{ site.pageViews !== undefined ? site.pageViews : '-' }}
                        </div>
                    </template>
                </ds-table-grid-column>

                <ds-table-grid-column
                    :label="$t('grid.columns.uniqueVisitors')"
                    prop="uniqueVisitors"
                    sortable
                >
                    <template #default="site">
                        <div class="numeric-cell">
                            {{ site.uniqueVisitors !== undefined ? site.uniqueVisitors : '-' }}
                        </div>
                    </template>
                </ds-table-grid-column>

                <ds-table-grid-column
                    :label="$t('grid.columns.conversionRate')"
                    prop="conversionRate"
                    sortable
                >
                    <template #default="site">
                        <div class="numeric-cell">
                            {{ site.conversionRate !== undefined ? site.conversionRate : '-' }}
                        </div>
                    </template>
                </ds-table-grid-column>

                <ds-table-grid-column width="40">
                    <template #default="site">
                        <div @click.stop>
                            <site-options
                                data-qa="site-options"
                                :site-status="site.status"
                                :site-id="site.id"
                            />
                        </div>
                    </template>
                </ds-table-grid-column>
            </ds-table-grid>
        </div>
    </section>
</template>

<script>

import SitesNoResults from '@/marketingSites/components/sites/SitesNoResults';
import { SITE_STATUS, BACK_END_PAGE_SIZE } from '@/marketingSites/marketingSites.constants';
import moment from 'moment';
import SiteOptions from '@/marketingSites/components/sites/SiteOptions';
import SiteSearch from '@/marketingSites/components/sites/SiteSearch';
import SiteFilters from '@/marketingSites/components/sites/SiteFilters';
import EmptyResults from '@/marketingSites/components/EmptyResults';
import {
    createDateComparator,
    createSiteComparator,
} from '@/marketingSites/components/site/site.utils';
import {
    getSitePageViews,
    getSiteFormSubmissions,
} from '@/marketingSites/api/sites-datasource-graphql';

export default {
    components: {
        EmptyResults,
        SiteFilters,
        SiteSearch,
        SiteOptions,
        SitesNoResults,
    },

    inject: ['$sites', 'sites'],

    props: {
        loading: Boolean,
        noMoreResults: Boolean,
    },

    data() {
        return {
            siteTableData: [],
            activeFilter: {},
            search: '',
            error: false,
            placeholder: [
                { height: '5rem', boxes: [2, 1, 1, 1] },
            ],
            sorter: createDateComparator({ field: 'updatedDate', ascending: false }),
            offset: 0,
            debounceDelay: 500,
            pageViews: {},
            formSubmissions: {},
            loadedSiteStatistics: false,
        };
    },

    watch: {
        search() {
            this.reset();
        },

        activeFilter() {
            this.reset();
        },

        sites: {
            async handler() {
                this.siteTableData = await this.populateSiteTableData();
                await this.$nextTick();
                this.toggleInfiniteScroll(this.siteTableData.length < BACK_END_PAGE_SIZE);
            },
            deep: true,
        },

        noMoreResults: {
            handler(value) {
                this.toggleInfiniteScroll(value);
            },
            deep: true,
        },
    },

    computed: {
        isSearch() {
            return (this.search ?? '') !== '';
        },

        isFilter() {
            return Boolean(this.activeFilter?.value);
        },

        activeFilterValue() {
            return this.activeFilter?.value;
        },

        isFilterOrSearch() {
            return Boolean(this.isFilter || this.isSearch);
        },

        showEmptyState() {
            return !this.isFilterOrSearch && !this.loading && (this.sites ?? []).length === 0;
        },

        noFilterResults() {
            return !this.loading && this.isFilter && this.siteTableData.length === 0;
        },

        noSearchResults() {
            return !this.loading && this.isSearch && this.siteTableData.length === 0;
        },
    },

    methods: {
        statusLabel({ status = SITE_STATUS.DRAFT }) {
            return this.$t(`statusChip.${status}`);
        },

        statusClassName({ status = SITE_STATUS.DRAFT }) {
            return status.toLowerCase();
        },

        sortSites(criteria) {
            if (criteria.field === 'updatedDate') {
                this.siteTableData.sort(createDateComparator(criteria));
            } else {
                this.siteTableData.sort(createSiteComparator(criteria));
            }
        },

        load() {
            const currentFilters = {
                page: this.offset++,
                name: this.search,
                status: this.activeFilter?.value ? [this.activeFilter?.value] : [SITE_STATUS.PUBLISHED, SITE_STATUS.PUBLISHED_CHANGES, SITE_STATUS.DRAFT],
            };

            this.$sites.refreshSites(currentFilters);
        },

        async openSite(site) {
            try {
                this.$sites.showEditSite(site?.id);
            } catch (e) {
                this.$error({ message: this.$t('landingPageLoadError') });
            }
        },

        reset() {
            this.offset = 0;
            this.$sites.clearData();
            this.load();
        },

        toggleInfiniteScroll(hideInfiniteScroll) {
            if (hideInfiniteScroll) {
                this.$refs.table?.finishLoading();
            } else {
                this.$refs.table?.reset();
            }
        },

        async populateSiteTableData() {
            // load site statistics for tenant once
            if (!this.loadedSiteStatistics) {
                await this.loadSiteStatistics();
            }

            const sitesDataTable = [...(this.sites ?? [])]
                .sort(this.sorter)
                .map((site) => {
                    const status = site.status ?? (site.publishedDate ? SITE_STATUS.PUBLISHED : SITE_STATUS.DRAFT);
                    const updatedDate = moment(site.updatedDate).format('ll');
                    const key = `${site.id}-${site.status}`;
                    const { slug } = site;

                    switch (status) {
                    case SITE_STATUS.PUBLISHED:
                        return {
                            ...site,
                            status,
                            key,
                            isPublished: true,
                            hasChanges: false,
                            updatedDate,
                            pageViews: this.pageViews[slug]?.pageViews || 0,
                            uniqueVisitors: this.pageViews[slug]?.uniqueVisitors || 0,
                            conversionRate: this.calculateConversionRate(slug),
                        };
                    case SITE_STATUS.PUBLISHED_CHANGES:
                        return {
                            ...site,
                            key,
                            status,
                            isPublished: true,
                            hasChanges: true,
                            updatedDate,
                            pageViews: this.pageViews[slug]?.pageViews || 0,
                            uniqueVisitors: this.pageViews[slug]?.uniqueVisitors || 0,
                            conversionRate: this.calculateConversionRate(slug),
                        };
                    default:
                        return {
                            ...site,
                            key,
                            status,
                            updatedDate,
                            isPublished: false,
                            hasChanges: false,
                        };
                    }
                });

            return sitesDataTable;
        },

        async loadSiteStatistics() {
            try {
                const [pageViews, formSubmissions] = await Promise.all([getSitePageViews(), getSiteFormSubmissions()]);

                this.pageViews = pageViews;
                this.formSubmissions = formSubmissions;
                this.loadedSiteStatistics = true;
            } catch (e) {
                this.$error({ message: this.$t('landingPageStatsLoadError') });
            }
        },

        calculateConversionRate(siteId) {
            const uniqueVisitors = this.pageViews[siteId]?.uniqueVisitors || 0;

            if (uniqueVisitors <= 0) {
                return undefined;
            }

            const formSubmissions = this.formSubmissions[siteId]?.submissions || 0;
            const conversionRate = ((formSubmissions * 100) / uniqueVisitors).toFixed(2);

            return `${conversionRate}%`;
        },
    },
};
</script>
<style lang="scss">
    .site-table {
        .table-wrapper {
            //I was having an issue where the default .table-wrapper style was truncating overflow and chopping
            // my hover menus
            overflow: visible;

            thead {
                border-top: none;
            }

            td {
                margin: px-to-rem(10);
            }
        }
    }
</style>

<style lang="scss" scoped>
    .site-name {
        margin: 0;
    }

    .site-status {
        margin: px-to-rem(4px) 0 0;
        padding: 0;
        font-size: $font-size-xs;
    }

    .site-status.active {
        color: $color-green-700;
    }

    .site-status.active_changes {
        color: $color-orange-900;
    }

    .site-status.draft {
        color: $color-gray-800;
    }

    .sites-list {
        display: flex;
        justify-content: center;
        padding-top: $gp;
        height: 100%;
    }

    td.numeric {
        margin: auto;
    }

    .site-items {
        flex: 1;
    }

    .placeholders {
        padding: $gp;
    }

    .placeholder {
        padding-bottom: $gp;
    }

    .filter-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: $gp * 1.5;
    }
</style>

<i18n>
{
    "en-us": {
        "landingPageLoadError": "There was an error loading this landing page. Please try again later",
        "landingPageStatsLoadError": "There was an error loading landing page statistics. Please try again later",
        "emptySearch": "No sites found containing \"{search}\"",
        "statusChip": {
            "ACTIVE": "Published",
            "DRAFT": "Draft",
            "ACTIVE_CHANGES": "Unpublished changes"
        },
        "grid": {
            "columns": {
                "name": "Landing page name",
                "updatedDate": "Last edited",
                "status": "Status",
                "pageViews": "Page views",
                "uniqueVisitors": "Unique visitors",
                "conversionRate": "Conversion rate"
            }
        }
    }
}
</i18n>
