<template lang="html">
    <section class="automation-list">
        <contextual-automation-card-item
            v-for="automation in automations.slice(0, limit)"
            :key="automation.id"
            :automation="automation"
            @edit="$emit('edit', automation.id)"
        />

        <div class="buttons">
            <ds-text-button data-qa="new-automation" leading-icon="add" @click="$emit('create')">
                {{ $t('newAutomation') }}
            </ds-text-button>

            <ds-text-button
                v-if="automations.length > limit"
                class="view-all"
                @click="$emit('view-all')"
            >
                {{ $t('viewAll') }}
            </ds-text-button>
        </div>
    </section>
</template>

<script>
import ContextualAutomationCardItem from '@/automations/components/list/ContextualAutomationCardItem';

export default {
    components: {
        ContextualAutomationCardItem,
    },

    props: {
        automations: {
            type: Array,
            default: () => ([]),
        },
        limit: Number,
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .buttons {
        display: flex;
        justify-content: space-between;
    }
</style>

<i18n>
{
    "en-us": {
        "newAutomation": "New automation",
        "viewAll": "View all"
    }
}
</i18n>
