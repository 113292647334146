<template lang="html">
    <section v-if="hasAtLeastOneAddress" class="contact-addresses">
        <h4 class="section-title">
            {{ $t('contactDetails.sectionHeaders.addresses') }}
        </h4>

        <div class="field-grid">
            <div v-for="addressType in addresses" v-show="showAddressMap(addressType)" :key="addressType">
                <template v-if="showAddressMap(addressType)">
                    <google-map :address="getAddress(addressType)" :data-qa="`google-${addressType}`" />

                    <address
                        :data-qa="addressType"
                    >
                        <small>{{ $t(`contactDetails.fieldHeaders.${addressType}`) }}</small>
                        <!-- eslint-disable vue/no-v-html -->
                        <span class="fs-block" v-html="getFormattedAddress(addressType)" />
                        <!-- eslint-enable vue/no-v-html -->
                    </address>
                </template>
            </div>
        </div>
    </section>
</template>

<script>
/* eslint-disable camelcase */
import GoogleMap from '@/shared/components/GoogleMap';

export default {
    components: {
        GoogleMap,
    },

    props: {
        contact: Object,
        fieldOptions: Object,
    },

    data() {
        return {
            addresses: [
                'billingAddress',
                'shippingAddress',
                'otherAddress',
            ],
        };
    },

    computed: {
        hasAtLeastOneAddress() {
            const validAddresses = this.addresses.map((addressType) => {
                const addressTypeFields = Object.values(this.contact[addressType]).filter((value) => Boolean(value));

                return addressTypeFields.length === 0
                    ? addressType
                    : null;
            }).filter((value) => Boolean(value));

            if (validAddresses.length === this.addresses.length) {
                return false;
            }

            return true;
        },
    },

    methods: {
        showAddressMap(addressType) {
            return Object.values(this.contact[addressType]).filter((value) => Boolean(value)).length > 0;
        },

        getFormattedAddress(addressType) {
            const {
                street1,
                street2,
                locality,
                region,
                postalCode,
                countryCode,
            } = this.contact[addressType];

            const states = this.getRegionOptions(addressType) || [];

            const matchedState = states.find(({ value }) => region === value);

            const state = matchedState && matchedState.label
                ? matchedState.label
                : null;

            const matchedCountry = this.fieldOptions.countries.find(({ value }) => value === countryCode);

            const country = matchedCountry && matchedCountry.label
                ? matchedCountry.label
                : null;

            const formattedAddress = [
                street1,
                street2,
                [locality, state, postalCode].filter((value) => Boolean(value)).join(', '),
                country,
            ];

            return formattedAddress.filter((value) => Boolean(value)).join('<br />');
        },

        getAddress(addressType) {
            const fullTextCountryCodes = ['CAN', 'BHS'];
            const addressParts = ['countryCode', 'locality', 'postalCode', 'region', 'street1', 'street2'];

            const address = addressParts.map((key) => {
                if (key === 'countryCode' && fullTextCountryCodes.includes(this.contact[addressType].countryCode)) {
                    const { countryCode } = this.contact[addressType].countryCode;

                    return this.fieldOptions.countries.find(({ value }) => value === this.contact[addressType].countryCode)?.label
                        || countryCode;
                }

                return this.contact[addressType][key];
            }).join(' ');

            return address;
        },

        getRegionOptions(addressType) {
            [addressType] = addressType.toLowerCase().split('address');

            return this.fieldOptions[`${addressType}Region`] || [];
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";

    address {
        font-style: normal;
        padding-top: $gp;
        display: flex;
        font-size: $font-size-med;
        flex-direction: column;
    }

    small {
        color: $color-gray-700;
        font-size: $font-size-xsmall;
    }
</style>
