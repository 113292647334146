export const SMS_DEFAULT_PHONE_TYPES = ['Mobile', 'Work', 'Home', 'Other'];

// REGEX built from GSM-7 compliant characters found here
// https://en.wikipedia.org/wiki/GSM_03.38#GSM_7-bit_default_alphabet_and_extension_table_of_3GPP_TS_23.038_.2F_GSM_03.38
// and Twilio smart encoded characters https://www.twilio.com/docs/messaging/services/smart-encoding-char-list
export const SMS_ALLOWED_CHARS_REGEX = new RegExp('^[A-Za-z0-9 \\r\\n@Δ¡¿£_!$Φ"¥Γ#èΛ¤éΩ%ùΠ&ìΨ\'òΣ(ÇΘ)Ξ*:Ø+;ÄäøÆ,<Ööæ\\-=ÑñÅß.>ÜüåÉ\\\\/?§à|^€{}~\\[\\]\u00ab\u00bb\u201c\u201d\u02ba\u02ee\u201f\u275d\u275e\u301d\u301e\uff02\u2018\u2019\u02bb\u02c8\u02bc\u02bd\u02b9\u201b\uff07\u00b4\u02ca\u0060\u02cb\u275b\u275c\u0313\u0314\ufe10\ufe11\u00f7\u00bc\u00bd\u00be\u29f8\u0337\u0338\u2044\u2215\uff0f\u29f9\u29f5\u20e5\ufe68\uff3c\u0332\uff3f\u20d2\u20d3\u2223\uff5c\u23b8\u23b9\u23d0\u239c\u239f\u23bc\u23bd\u2015\ufe63\uff0d\u2010\u2022\u2043\ufe6b\uff20\ufe69\uff04\u01c3\ufe15\ufe57\uff01\ufe5f\uff03\ufe6a\uff05\ufe60\uff06\u201a\u0326\ufe50\u3001\ufe51\uff0c\uff64\u2768\u276a\ufe59\uff08\u27ee\u2985\u2769\u276b\ufe5a\uff09\u27ef\u2986\u204e\u2217\u229b\u2722\u2723\u2724\u2725\u2731\u2732\u2733\u273a\u273b\u273c\u273d\u2743\u2749\u274a\u274b\u29c6\ufe61\uff0a\u02d6\ufe62\uff0b\u3002\ufe52\uff0e\uff61\uff10\uff11\uff12\uff13\uff14\uff15\uff16\uff17\uff18\uff19\u02d0\u02f8\u2982\ua789\ufe13\uff1a\u204f\ufe14\ufe54\uff1b\ufe64\uff1c\u0347\ua78a\ufe66\uff1d\ufe65\uff1e\ufe16\ufe56\uff1f\uff21\u1d00\uff22\u0299\uff23\u1d04\uff24\u1d05\uff25\u1d07\uff26\ua730\uff27\u0262\uff28\u029c\uff29\u026a\uff2a\u1d0a\uff2b\u1d0b\uff2c\u029f\uff2d\u1d0d\uff2e\u0274\uff2f\u1d0f\uff30\u1d18\uff31\uff32\u0280\uff33\ua731\uff34\u1d1b\uff35\u1d1c\uff36\u1d20\uff37\u1d21\uff38\uff39\u028f\uff3a\u1d22\u02c6\u0302\uff3e\u1dcd\u2774\ufe5b\uff5b\u2775\ufe5c\uff5d\uff3b\uff3d\u02dc\u02f7\u0303\u0330\u0334\u223c\uff5e\u00a0\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u200b\u202f\u205f\u3000\ufeff\u008d\u009f\u0080\u0090\u009b\u2017\u2014\u2013\u2039\u203a\u203c\u201e\u2026\u2028\u2029\u2060]+$');

export const SMS_FEEDBACK_MODAL_SOURCE = {
    KMN_TEMPLATES: 'KMN_TEMPLATES',
};

export const COST_PER_MESSAGE = 0.04;
