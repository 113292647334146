
import { SMART_FORMS_EDITING_FEATURE } from '@/shared/constants/features.constants';

const FormBuilderPage = () => import(/* webpackChunkName: "smartForms" */ '@/customForms/pages/FormBuilderPage');
const FormBuilder = () => import(/* webpackChunkName: "smartForms" */ '@/customForms/components/builder/FormBuilder.vue');
const FormsPage = () => import(/* webpackChunkName: "smartForms" */ '@/customForms/pages/FormsPage');
const PublicFormsPage = () => import(/* webpackChunkName: "smartForms" */ '@/customForms/pages/PublicFormsPage');
const FormCreator = () => import(/* webpackChunkName: "smartForms" */ '@/customForms/pages/FormCreator');
const SmartFormsPage = () => import(/* webpackChunkName: "smartForms" */ '@/smartForms/pages/SmartFormsPage');

const routes = [
    {
        path: '/forms',
        name: 'customForms',
        component: FormsPage,
        redirect: '/forms/internal',
        meta: {
            title: 'customForms.internalFull',
            feature: SMART_FORMS_EDITING_FEATURE,
            hideHeaderOnMobile: true,
        },
        // TODO: route consolidation: https://jira.infusionsoft.com/browse/PT-5808
        children: [
            {
                path: 'new',
                component: FormBuilderPage,
                children: [
                    {
                        path: '',
                        name: 'customForms.new',
                        component: FormCreator,
                        meta: {
                            title: 'customForms.createForm',
                            feature: SMART_FORMS_EDITING_FEATURE,
                        },
                    },
                ],
            },
            {
                path: 'internal',
                name: 'customForms.internal',
                component: SmartFormsPage,
                meta: {
                    title: 'customForms.internalFull',
                    feature: SMART_FORMS_EDITING_FEATURE,
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'internal/:formId',
                name: 'customForms.internal.form',
                redirect: '/forms/internal/:formId/edit/build',
                component: FormBuilderPage,
                meta: {
                    title: 'customForms.internal.form',
                    feature: SMART_FORMS_EDITING_FEATURE,
                    hideHeaderOnMobile: true,
                    newBuilderFlowRedirect: true,
                },
                children: [
                    {
                        path: 'edit/:step',
                        name: 'customForms.internal.edit',
                        component: FormBuilder,
                        props: true,
                        meta: {
                            title: 'customForms.internal.form',
                            feature: SMART_FORMS_EDITING_FEATURE,
                            hideHeaderOnMobile: true,
                        },
                    },
                ],
            },
            {
                path: 'public',
                name: 'customForms.public',
                component: PublicFormsPage,
                meta: {
                    title: 'customForms.publicTitle',
                    feature: SMART_FORMS_EDITING_FEATURE,
                    hideHeaderOnMobile: true,
                },
            },
            {
                path: 'public/:formId',
                component: FormBuilderPage,
                props: true,
                redirect: '/forms/public/:formId/edit/build',
                meta: {
                    title: 'customForms.publicTitle',
                    feature: SMART_FORMS_EDITING_FEATURE,
                    hideHeaderOnMobile: true,
                },
                children: [
                    {
                        path: 'edit/:step',
                        name: 'customForms.public.edit',
                        component: FormBuilder,
                        props: true,
                        meta: {
                            title: 'customForms.public.form',
                            feature: SMART_FORMS_EDITING_FEATURE,
                            hideHeaderOnMobile: true,
                        },
                    },
                ],
            },
        ],
    },
];

export default routes;
