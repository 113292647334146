<template>
    <controlled-modal
        is-open
        class="sms-broadcast-settings"
        intercept-close
        show-close
        :title="$t('modalTitle')"
        @close="close"
    >
        <h4 class="subtitle">
            {{ $t('optInMessage') }}
        </h4>

        <p class="helper-text">
            {{ $t('optInMessageSubtitle') }}
        </p>

        <ds-text-area-field
            ref="optin"
            v-model="optInIntro"
            :label="this.$t('introLabel')"
            class="message-input"
            data-qa="opt-in-message-intro"
            :maxlength="MAX_CHARACTER_COUNT - requiredInfo.length"
            required
            auto-grow
        >
            <template #help>
                <div class="character-count">
                    {{ editableCharacterCount }}
                </div>
            </template>
        </ds-text-area-field>

        <ds-text-area-field
            :value="requiredInfo.trim()"
            :label="this.$t('requiredInfoLabel')"
            class="required-info"
            readonly
            auto-grow
        >
            <template #help>
                <div class="character-count">
                    {{ optInCharacterCount }}
                </div>
            </template>
        </ds-text-area-field>

        <ds-inline-alert
            v-if="showComplianceTip"
            type="warning"
            leading-icon
            class="settings-alert"
            data-qa="company-name-tip"
        >
            {{ $t('companyNameTip') }}
        </ds-inline-alert>

        <h4 class="subtitle">
            {{ $t('noReplyMessage') }}
        </h4>

        <p class="helper-text">
            {{ $t('noReplyMessageSubtitle') }}
        </p>

        <ds-text-area-field
            ref="noreply"
            v-model="noReplyMessage"
            :label="this.$t('noReplyLabel')"
            :maxlength="MAX_CHARACTER_COUNT"
            class="message-input"
            data-qa="no-reply-message"
            required
            auto-grow
        >
            <template #help>
                <div class="character-count">
                    {{ noReplyCharacterCount }}
                </div>
            </template>
        </ds-text-area-field>

        <ds-inline-alert
            v-if="missingPhoneOrEmail"
            leading-icon
            class="settings-alert"
            data-qa="no-reply-tip"
        >
            <i18n tag="div" path="noReplyTip">
                <template #tip>
                    <strong>{{ $t('tip') }}</strong>
                </template>
            </i18n>
        </ds-inline-alert>

        <div data-qa="save-button">
            <ds-filled-button :loading="loading" @click="save">
                {{ $t('global.save') }}
            </ds-filled-button>
        </div>

        <portal to="root">
            <confirm-modal
                :is-open="isConfirmCloseOpen"
                data-qa="sms-settings-confirm-close"
                :title="$t('leaveWithoutSaving')"
                :description="$t('confirmCloseDescription')"
                :cancel-text="$t('stayHere')"
                :confirm-text="$t('leave')"
                destructive
                @close="isConfirmCloseOpen = false"
                @confirm="$emit('close')"
            />
        </portal>

        <portal to="root">
            <sms-settings-confirm-modal
                :is-open="isSettingConfirmModalOpen"
                :opt-in-intro="optInIntro"
                :no-reply-message="noReplyMessage"
                @close="isSettingConfirmModalOpen = false"
            />
        </portal>
    </controlled-modal>
</template>

<script>
import { mapState } from 'vuex';
import ControlledModal from '@/shared/components/ControlledModal';
import ConfirmModal from '@/shared/components/ConfirmModal';
import { MAX_CHARACTER_COUNT } from '@/broadcasts/smsBroadcast.constants';
import SmsSettingsConfirmModal from './SmsSettingsConfirmModal';

// Modified from https://regexr.com/38pvb
const phonePattern = /(?!\s)(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)/g;

// SOURCE: https://emailregex.com/
/* eslint-disable-next-line no-control-regex */
const emailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g;

export default {
    components: {
        ControlledModal,
        ConfirmModal,
        SmsSettingsConfirmModal,
    },

    data() {
        return {
            optInIntro: '',
            noReplyMessage: '',
            hasOptInFocus: false,
            hasNoReplyFocus: false,
            loading: false,
            hasError: false,
            MAX_CHARACTER_COUNT,
            isConfirmCloseOpen: false,
            isSettingConfirmModalOpen: false,
        };
    },

    created() {
        this.optInIntro = this.automatedSmsAccount?.optInMessageIntro;
        this.noReplyMessage = this.automatedSmsAccount?.replyMessage || this.$t('defaultNoReply');
    },

    beforeUpdate() {
        this.$refs.optin.adjustHeight();
        this.$refs.noreply.adjustHeight();
    },

    computed: {
        ...mapState({
            automatedSmsAccount: ({ auth }) => auth.account?.automatedSmsAccount,
            companyName: ({ auth }) => (auth.account.companyProfile ? auth.account.companyProfile.companyName : auth.user.fullName),
        }),

        requiredInfo() {
            return this.automatedSmsAccount?.optInMessage;
        },

        containsCompanyName() {
            if (!this.optInIntro || !this.companyName) return false;

            return this.optInIntro.toLowerCase().includes(this.companyName.toLowerCase());
        },

        showComplianceTip() {
            return !(this.containsCompanyName || this.complianceTipAcknowledged);
        },

        editableCharacterCount() {
            return `${this.optInIntro.length}/${this.MAX_CHARACTER_COUNT - this.requiredInfo.length}`;
        },

        optInCharacterCount() {
            return `${this.optInIntro.length + this.requiredInfo.length}/${this.MAX_CHARACTER_COUNT}`;
        },

        noReplyCharacterCount() {
            return `${this.noReplyMessage.length}/${this.MAX_CHARACTER_COUNT}`;
        },

        missingPhoneOrEmail() {
            const hasPhone = this.noReplyMessage.match(phonePattern)?.length > 0;
            const hasEmail = this.noReplyMessage.match(emailPattern)?.length > 0;

            return !(hasPhone || hasEmail);
        },

        isDirty() {
            return this.optInIntro !== this.automatedSmsAccount?.optInMessageIntro
                || this.noReplyMessage !== (this.automatedSmsAccount?.replyMessage || this.$t('defaultNoReply'));
        },
    },

    methods: {
        close() {
            if (!this.isDirty) {
                this.$emit('close');
            } else {
                this.isConfirmCloseOpen = true;
            }
        },

        async save() {
            if (!this.optInIntro || !this.noReplyMessage) {
                this.isSettingConfirmModalOpen = true;
            } else {
                this.loading = true;

                try {
                    await this.$store.dispatch('automatedSms/UPDATE_AUTOMATED_SMS_ACCOUNT',
                        {
                            optInMessageIntro: this.optInIntro,
                            replyMessage: this.noReplyMessage,
                        });
                } catch (e) {
                    this.$error({ message: this.$t('errorMessage') });
                    this.loading = false;
                }

                this.loading = false;
                this.$toast({ message: this.$t('successMessage') });
                this.close();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .subtitle {
        font-weight: $font-weight-semibold;
        margin-bottom: $gp / 2;
    }

    .intro {
        margin-bottom: $gp;
    }

    .message-input {
        --textarea-min-height: #{px-to-rem(32px)};
        --input-margin-bottom: #{$gp / 4};
    }

    .required-info {
        --textarea-min-height: #{px-to-rem(32px)};
        --input-margin-bottom: #{$gp / 4};
    }

    .helper-text {
        font-size: $font-size-sm;
        color: $color-text-subtle;
        margin-bottom: $gp;
    }

    .company-name-tip {
        background-color: $color-forest-050;
        border-radius: $border-radius / 2;
        margin-bottom: $gp * 1.5;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .company-name-tip-message {
        padding: $gp;
        font-size: $font-size-med;
        line-height: $line-height-lg;
    }

    .company-name-tip-close {
        max-height: px-to-rem(40px);
        margin: $gp / 4;
    }

    .character-count {
        display: flex;
        justify-content: flex-end;
        font-size: $font-size-xs;
    }

    .settings-alert {
        margin-bottom: $gp * 1.5;
    }
</style>

<i18n>
{
    "en-us": {
        "modalTitle": "Marketing Number Settings",
        "noReplyMessage": "Global no-reply message",
        "noReplyMessageSubtitle": "Contacts will receive this message if they reply to a text sent from your Keap Marketing Number.",
        "optInMessageSubtitle": "Contacts will receive this the first time you send a marketing text from your Keap Marketing Number.  They must reply YES to receive more messages from you.",
        "optInMessage": "Global opt-in message",
        "defaultNoReply": "This number doesn't accept replies. Please contact this business or person directly.",
        "tip": "Tip",
        "noReplyTip": "{tip}: Consider adding an alternate phone number or email address here so you can communicate in a 1:1 channel.",
        "companyNameTip": "The law requires you to identify who this message is from. Be sure to identify yourself or your business.",
        "successMessage": "Your Keap Marketing Number Settings have been updated",
        "errorMessage": "There was an error updating your Keap Marketing Number Settings",
        "introLabel": "Intro",
        "requiredInfoLabel": "Required Info*",
        "noReplyLabel": "No-reply message",
        "leaveWithoutSaving": "Leave without saving?",
        "confirmCloseDescription": "If you leave now, you may lose any unsaved changes.",
        "stayHere": "Stay here",
        "leave": "Leave without saving"
    }
}
</i18n>
