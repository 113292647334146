<template lang="html">
    <div>
        <slot name="online" :chatId="onlineChatButtonId" :startChat="startChat" />
        <slot name="offline" :chatId="offlineChatButtonId" :url="url" />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    props: {
        setupOnMount: Boolean,
    },

    data() {
        return {
            scriptLoaded: false,
        };
    },

    mounted() {
        if (this.setupOnMount) {
            this.setup();
        }
    },

    watch: {
        supportPanelOpen(value) {
            if (value && !this.setupOnMount) {
                this.setup();
            }
        },
    },

    computed: {
        ...mapState({
            account: ({ auth }) => auth.account,
            user: ({ auth }) => auth.user,
            supportPanelOpen: ({ global }) => global.supportPanelOpen,
        }),

        ...mapGetters({
            isPaid: 'auth/isPaid',
        }),

        showChat() {
            return this.user && this.isPaid;
        },

        chatButtonId() {
            return this.user && this.user.partner ? '573f1000000k9eL' : '573f1000000k9eK';
        },

        onlineChatButtonId() {
            const id = this.user && this.user.partner ? '573f1000000k9eL' : '573f1000000k9eK';

            return `liveagent_button_online_${id}`;
        },

        offlineChatButtonId() {
            const id = this.user && this.user.partner ? '573f1000000k9eL' : '573f1000000k9eK';

            return `liveagent_button_offline_${id}`;
        },

        url() {
            return 'https://help.infusionsoft.com/help/chat-status';
        },
    },

    methods: {
        loadScript() {
            return new Promise((resolve, reject) => {
                const element = document.createElement('script');

                element.onload = () => {
                    this.scriptLoaded = true;
                    resolve();
                };

                element.onerror = () => {
                    reject();
                };

                element.async = true;
                element.src = 'https://c.la3-c1-dfw.salesforceliveagent.com/content/g/js/41.0/deployment.js';
                document.body.appendChild(element);
            });
        },

        resetLiveAgent() {
            if (window.liveagent) {
                window.liveagent.disconnect();

                delete window.liveagent;
                delete window.liveAgentDeployment;
            }
        },

        async setup() {
            this.resetLiveAgent();

            await this.loadScript();

            if (this.showChat && window.liveagent) {
                const {
                    user: {
                        givenName,
                        familyName,
                        fullName,
                        email,
                        partner,
                    },
                    account: {
                        appName,
                    },
                } = this;

                if (window.liveagent) {
                    window.liveagent.addCustomDetail('First Name', givenName);
                    window.liveagent.addCustomDetail('Last Name', familyName);
                    window.liveagent.addCustomDetail('Email', email);
                    window.liveagent.addCustomDetail('Case Status', 'New', false);
                    window.liveagent.addCustomDetail('App Name', appName);

                    if (partner) {
                        window.liveagent.addCustomDetail('Origin', 'Partner Chat');
                    } else {
                        window.liveagent.addCustomDetail('Origin', 'Chat');
                    }

                    if (window.liveagent.findOrCreate) {
                        window.liveagent.findOrCreate('Asset').map('Name', 'App Name', true, true, true).linkToEntity('Case', 'AssetId');
                        window.liveagent.findOrCreate('Contact')
                            .map('Email', 'Email', true, true, true)
                            .map('FirstName', 'First Name', true, true, true)
                            .map('LastName', 'Last Name', true, true, true)
                            .saveToTranscript('contactId')
                            .linkToEntity('Case', 'ContactId');

                        window.liveagent.findOrCreate('Case')
                            .map('Origin', 'Origin', false, false, true)
                            .map('Status', 'Case Status', false, false, true)
                            .saveToTranscript('CaseId').showOnCreate();
                    }

                    window.liveagent.setName(fullName);
                    window.liveagent.setChatWindowWidth(500);
                    window.liveagent.setChatWindowHeight(500);
                    window.liveagent.init(
                        'https://d.la2w2.salesforceliveagent.com/chat',
                        '572j0000000XZAR',
                        '00Dj0000001omj3',
                    );
                }

                if (!window._laq) { // eslint-disable-line
                    window._laq = []; // eslint-disable-line
                }

                window._laq.push(() => { // eslint-disable-line
                    window.liveagent.showWhenOnline(this.chatButtonId, document.getElementById(this.onlineChatButtonId));
                    window.liveagent.showWhenOffline(this.chatButtonId, document.getElementById(this.offlineChatButtonId));
                });
            }
        },

        startChat() {
            window.liveagent.startChat(this.chatButtonId);
        },
    },
};
</script>
