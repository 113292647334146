import Vue from 'vue';
import gql from 'graphql-tag';

export const createAutomatedSmsAccountMutation = async ({ companyName, userId }) => {
    const response = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createAutomatedSmsAccount($payload: AutomatedSmsAccountInput!) {
                createAutomatedSmsAccount(payload: $payload) {
                    id
                    companyName
                    phoneNumber
                    termsAndConditionsTimeStamp
                    termsAndConditionsVersion
                    status
                    created
                    updated
                    userId
                    optInMessage
                    optInMessageIntro
                    replyMessage
                    optInConfirmationMessage
                }
            }
        `,
        variables: {
            payload: { companyName, userId },
        },
        fetchPolicy: 'no-cache',
    });

    return response.data.createAutomatedSmsAccount;
};

export const updateAutomatedSmsAccountMutation = async ({
    companyName,
    userId,
    termsAndConditionsTimeStamp,
    termsAndConditionsVersion,
    status,
    optInMessageIntro,
    replyMessage,
}) => {
    const response = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateAutomatedSmsAccount($payload: UpdateAutomatedSmsAccountInput!) {
                updateAutomatedSmsAccount(payload: $payload) {
                    id
                    companyName
                    phoneNumber
                    termsAndConditionsTimeStamp
                    termsAndConditionsVersion
                    status
                    created
                    updated
                    userId
                    optInMessage
                    optInMessageIntro
                    replyMessage
                    optInConfirmationMessage
                }
            }
        `,
        variables: {
            payload: {
                companyName,
                userId,
                optInMessageIntro,
                replyMessage,
                termsAndConditionsTimeStamp,
                termsAndConditionsVersion,
                status,
            },
        },
        fetchPolicy: 'no-cache',
    });

    return response.data.updateAutomatedSmsAccount;
};

export const automatedSmsAccountQuery = async () => {
    const response = await Vue.prototype.$graphql.query({
        query: gql`
            query automatedSmsAccount {
                automatedSmsAccount {
                    id
                    companyName
                    phoneNumber
                    termsAndConditionsTimeStamp
                    termsAndConditionsVersion
                    status
                    created
                    updated
                    userId
                    optInMessage
                    optInMessageIntro
                    replyMessage
                    optInConfirmationMessage
                }
            }
        `,
        fetchPolicy: 'no-cache',
    });

    return response.data.automatedSmsAccount;
};

export const automatedSmsAccountUsageQuery = async (startDate = '', endDate = '') => {
    const now = new Date(Date.now());

    const firstDay = new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
    const lastDay = new Date(Date.UTC(now.getFullYear(), now.getMonth() + 1, 1));

    const response = await Vue.prototype.$graphql.query({
        query: gql`
            query automatedSmsAccountUsage($startDate: String!, $endDate: String!) {
                automatedSmsAccountUsage(startDate: $startDate, endDate: $endDate) {
                    messageCount
                }
            }
        `,
        variables: {
            startDate: startDate || firstDay.toISOString(),
            endDate: endDate || lastDay.toISOString(),
        },
        fetchPolicy: 'no-cache',
    });

    return response?.data?.automatedSmsAccountUsage;
};

export const getShortenedLink = async ({ linkToShorten = '' }) => {
    const { data: { shortenLink } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation shortenLink($urlToShorten: String!){
                shortenLink(linkToShorten: $urlToShorten) {
                    shortURL
                }
            }
        `,
        variables: {
            urlToShorten: linkToShorten,
        },
        fetchPolicy: 'no-cache',
    });

    return shortenLink;
};
