import { IDLE } from '@/contacts/loadingStatuses';
import { CONTACT_SORT_FIELDS } from '@/contacts/contacts.constants';

export default {
    accountContactTotal: 0,
    callDetails: [],
    contactTotal: 0,
    contacts: [],
    contactListLoading: false,
    contactLists: [],
    contactListsLoaded: false,
    contactListSortField: CONTACT_SORT_FIELDS.CONTACT_DATE_CREATED,
    contactListSortAscending: false,
    emails: [],
    contactFilterUrl: '/contacts/list/all',
    contactFilterValues: [],
    contactFilterMetadata: [],
    customFields: [],
    filteredContactCount: 0,
    loadingContactCount: false,
    loadingExport: true,
    contact: {},
    contactLoaded: false,
    contactLoadingStatus: IDLE,
    contactDetails: {
        fieldGroups: [],
        billingRegionOptions: [],
        shippingRegionOptions: [],
        otherRegionOptions: [],
    },
    contactEmailMarketability: {},
    fieldGroups: [],
    activities: [],
    activityCursor: '',
    tags: [],
    campaigns: [],
    strategies: [],
    tasks: [],
    tasksLoadingStatus: IDLE,
    countries: [],
    companies: [],
    companyLoadingStatus: IDLE,
    customRegionOptions: [],
    cachedRegionOptions: {},
    appointments: [],
    notes: [],
    files: [],
    lastSentEmailDetails: {
        email: {},
        attachments: [],
    },
    searchResults: [],
    leadsContactListId: 0,
    clientsContactListId: 0,
    newLeadsContactListId: 0,
    customersListId: 0,
    activeFeedToggle: 'grouped',
    isContactRecordDirty: false,
};
