import Vue from 'vue';

import { decodeHtml } from '@/shared/utils/string.util';

export default {
    SET_NOTIFICATIONS(state, { notifications }) {
        notifications.forEach((notification) => {
            try {
                const contentDetails = decodeHtml(notification.contentDetails);

                notification.contentDetails = contentDetails.startsWith('{')
                    ? JSON.parse(contentDetails)
                    : contentDetails;
            } catch (e) {
                // Do nothing if json parse fails
            }
        });

        const dedupedNotifications = notifications
            .filter(({ id }) => !state.notifications.some((notification) => notification.id === id));

        Vue.set(state, 'notifications', state.notifications.concat(dedupedNotifications));
    },

    SET_NOTIFICATION_STATUS(state, { notificationId, status }) {
        const notificationIndex = state.notifications.findIndex(({ id }) => id === notificationId);

        state.notifications[notificationIndex].status = status;
    },

    SET_ALL_AS_READ(state) {
        state.notifications.forEach((_, i) => {
            Vue.set(state.notifications[i], 'status', 'Read');
        });
    },

    SET_NOTIFICATIONS_PANEL_OPEN(state, open) {
        state.notificationsPanelOpen = open;
    },

    RESET_NOTIFICATIONS(state) {
        state.notifications = [];
    },

    SET_NOTIFICATIONS_NEXT_PAGE_TOKEN(state, { nextPageToken }) {
        if (!nextPageToken) {
            state.notificationsNextPageToken = 'allLoaded';
        } else {
            state.notificationsNextPageToken = nextPageToken;
        }
    },

    RESET_NOTIFICATIONS_NEXT_PAGE_TOKEN(state) {
        state.notificationsNextPageToken = null;
    },
};
