import { api, templatesApi } from '../api';
import sentry from '@/analytics/sentry';
import { TRIGGER_ACTION_TYPES } from '@/automations/constants/automations.constants';

export default {
    CREATE_AUTOMATION(context, payload) {
        return createAutomation(context, payload);
    },

    DELETE_AUTOMATION(context, payload) {
        return deleteAutomation(context, payload);
    },

    LOAD_AUTOMATIONS(context, payload) {
        return loadAutomations(context, payload);
    },

    LOAD_AUTOMATION_STATUSES(context, payload) {
        return loadAutomationStatuses(context, payload);
    },

    LOAD_AUTOMATION(context, payload) {
        return loadAutomation(context, payload);
    },

    PUBLISH_AUTOMATION(context, payload) {
        return publishAutomation(context, payload);
    },

    DISABLE_AUTOMATION(context, payload) {
        return disableAutomation(context, payload);
    },

    UPDATE_AUTOMATION(context, payload) {
        return updateAutomation(context, { id: payload.id, payload });
    },

    REVERT_EDITS(context, payload) {
        return deleteAutomationDraft(context, payload);
    },

    RENAME_AUTOMATION(context, { id, name }) {
        return renameAutomation(context, { id, name });
    },

    SEND_TEST_AUTOMATION(context, {
        id, emailTo, contactId, isTemplate,
    }) {
        return sendTestAutomation(context, {
            id, emailTo, contactId, isTemplate,
        });
    },

    VALIDATE_AUTOMATION(context, { id }) {
        return validateAutomation(context, { id });
    },

    LOAD_PARTICIPANT_COUNT(context, id) {
        return participantCount(context, id);
    },

    LOAD_PARTICIPANT_LIST(context, payload) {
        return participantList(context, payload);
    },

    REMOVE_PARTICIPANTS(context, payload) {
        return removeParticipants(context, payload);
    },

    LOAD_CONTACT_AUTOMATIONS(context, payload) {
        return loadContactAutomations(context, payload);
    },

    // templates
    LOAD_AUTOMATION_TEMPLATES(context, id) {
        return loadAutomationTemplates(context, id);
    },

    LOAD_AUTOMATION_TEMPLATE(context, id) {
        return loadAutomationTemplate(context, id);
    },

    CREATE_AUTOMATION_TEMPLATE(context, id) {
        return createAutomationTemplate(context, id);
    },

    UPDATE_AUTOMATION_TEMPLATE_DETAILS(context, payload) {
        return updateAutomationTemplateDetails(context, payload);
    },

    PUBLISH_AUTOMATION_TEMPLATE(context, payload) {
        return publishAutomationTemplate(context, payload);
    },

    UPDATE_AUTOMATION_TEMPLATE(context, payload) {
        return updateAutomationTemplate(context, { id: payload.id, payload });
    },

    DELETE_AUTOMATION_TEMPLATE(context, id) {
        return deleteAutomationTemplate(context, id);
    },

    INSTALL_AUTOMATION(context, request) {
        return installAutomation(context, request);
    },

    SHARE_TEMPLATE(context, payload) {
        return shareTemplate(context, payload);
    },

    REVERT_TEMPLATE_EDITS(context, payload) {
        return deleteAutomationTemplateDraft(context, payload);
    },
};

export const transformAutomationInput = (automation) => {
    if (automation == null) {
        return null;
    }

    const { steps = [], triggers = [], removeTriggers = [] } = automation;

    return {
        name: automation.name,
        description: automation.description,
        triggers: [
            // backend accepts one trigger list (with different action types)
            ...triggers.map(({
                id, type, name, configJson, sourceId,
            }) => ({
                id,
                type,
                name,
                action: TRIGGER_ACTION_TYPES.ADD,
                sourceId,
                configJson: configJson ? JSON.stringify(configJson) : null,
            })),
            ...removeTriggers.map(({
                id, type, name, configJson, sourceId,
            }) => ({
                id,
                type,
                name,
                action: TRIGGER_ACTION_TYPES.REMOVE,
                sourceId,
                configJson: configJson ? JSON.stringify(configJson) : null,
            })),
        ],
        steps: steps.map(({
            id, type, name, configJson,
        }, i) => ({
            id,
            type,
            name,
            sequenceNumber: i,
            configJson: configJson ? JSON.stringify(configJson) : null,
        })),
    };
};

export const transformAutomationOutput = (automation) => {
    if (automation == null) {
        return null;
    }
    const { steps = [], triggers = [] } = automation;

    return {
        ...automation,
        triggers: triggers.reduce((accumulator, trigger) => {
            // backend 'ADD' action triggers == frontend trigger list
            if (trigger.action === TRIGGER_ACTION_TYPES.ADD) {
                accumulator.push({
                    ...trigger,
                    configJson: trigger.configJson ? JSON.parse(trigger.configJson) : null,
                });
            }

            return accumulator;
        }, []),
        removeTriggers: triggers.reduce((accumulator, trigger) => {
            // backend 'REMOVE' action triggers == frontend removeTrigger list
            if (trigger.action === TRIGGER_ACTION_TYPES.REMOVE) {
                accumulator.push({
                    ...trigger,
                    configJson: trigger.configJson ? JSON.parse(trigger.configJson) : null,
                });
            }

            return accumulator;
        }, []),
        steps: steps.map((step) => ({
            ...step,
            configJson: step.configJson ? JSON.parse(step.configJson) : null,
        })),
    };
};

const createAutomation = async (_, payload) => {
    try {
        const input = transformAutomationInput(payload);

        const { data: { createAutomation: automation } } = await api.createAutomation(input);

        return transformAutomationOutput(automation);
    } catch (e) {
        sentry.log('Create automation failed');
        throw e;
    }
};

const deleteAutomation = async (_, { id }) => {
    try {
        const { data: { deleteAutomation: successful } } = await api.deleteAutomation(id);

        return successful;
    } catch (e) {
        sentry.log('Delete automation failed', { id });
        throw e;
    }
};

const deleteAutomationDraft = async (_, id) => {
    try {
        const { data: { deleteAutomationDraft: successful } } = await api.deleteAutomationDraft(id);

        return successful;
    } catch (e) {
        sentry.log('Delete automation draft failed', { id });
        throw e;
    }
};

const deleteAutomationTemplateDraft = async (_, id) => {
    try {
        const { data: { deleteAutomationTemplateDraft: successful } } = await templatesApi.deleteAutomationTemplateDraft(id);

        return successful;
    } catch (e) {
        sentry.log('Delete automation template draft failed', { id });
        throw e;
    }
};

const loadAutomations = async (context, { limit, cursor, tags }) => {
    try {
        const { data: { automationList } } = await api.summaryListAutomations(limit, cursor, tags);

        return automationList;
    } catch (e) {
        sentry.log('Load automation list failed');
        throw e;
    }
};

const loadAutomationStatuses = async (context, { tags }) => {
    try {
        const { data: { automationStatusSummary } } = await api.automationStatusSummary(tags);

        return automationStatusSummary;
    } catch (e) {
        sentry.log('Load automation status summaries failed');
        throw e;
    }
};

const loadContactAutomations = async (_, { contactId, limit, cursor }) => {
    try {
        const { data: { contactAutomationList } } = await api.contactAutomationList(contactId, limit, cursor);

        return contactAutomationList;
    } catch (e) {
        sentry.log('Load contact automation list failed');

        throw e;
    }
};

const loadAutomation = async (context, id) => {
    try {
        const { data: { automation } } = await api.loadAutomation(id);

        return transformAutomationOutput(automation);
    } catch (e) {
        sentry.log('Load automation failed', { id });
        throw e;
    }
};

const participantCount = async (_, id) => {
    try {
        const { data: { participantCount: participants } } = await api.participantCount(id);

        return participants;
    } catch (e) {
        sentry.log('Load participant count failed', { id });
        throw e;
    }
};

const participantList = async (context, payload) => {
    try {
        const { data: { participantList: response } } = await api.participantList(payload);

        return response.participants.map(({ metadata, ...rest }) => {
            return {
                ...rest,
                metadata: metadata ? JSON.parse(metadata) : undefined,
            };
        });
    } catch (e) {
        sentry.log('Load participant count failed', { id: payload.id });
        throw e;
    }
};

const removeParticipants = async (_, { automationId, participants, checkAll }) => {
    try {
        await api.removeParticipants(automationId, { participants, checkAll });
    } catch (e) {
        sentry.log('Remove participants failed', { automationId });
        throw e;
    }
};

const publishAutomation = async (_, id) => {
    try {
        const { data: { publishAutomation: success } } = await api.publishAutomation(id);

        return success;
    } catch (e) {
        sentry.log('Publish automation failed', { id });
        throw e;
    }
};

const disableAutomation = async (_, id) => {
    try {
        const { data: { disableAutomation: success } } = await api.disableAutomation(id);

        return success;
    } catch (e) {
        sentry.log('Disable automation failed', { id });
        throw e;
    }
};

const updateAutomation = async ({ state, commit }, { id, payload }) => {
    try {
        const { data: { updateAutomation: updated } } = await api.updateAutomation(id, transformAutomationInput(payload));

        if (typeof state.saveAutomationCallback === 'function') {
            state.saveAutomationCallback();
            commit('SET_SAVE_AUTOMATION_CALLBACK', null);
        }

        return transformAutomationOutput(updated);
    } catch (e) {
        sentry.log('Update automation failed', { id });
        throw e;
    }
};

const renameAutomation = async (_, { id, name }) => {
    try {
        const { data: { renameAutomation: renamed } } = await api.renameAutomation(id, name);

        return renamed;
    } catch (e) {
        sentry.log('Rename automation failed', { id });
        throw e;
    }
};

const sendTestAutomation = async (_, {
    id, emailTo, contactId, isTemplate,
}) => {
    try {
        const { data: { sendTestAutomation: success } } = await api.sendTestAutomation(id, emailTo, contactId, isTemplate);

        return success;
    } catch (e) {
        sentry.log('Send test automation failed', { id, emailTo, contactId });
        throw e;
    }
};

const validateAutomation = async ({ commit }, { id }) => {
    try {
        const { data: { validateAutomation: validatedAutomation } } = await api.validateAutomation(id);

        const { triggers } = validatedAutomation;
        const startTriggers = triggers?.filter((trigger) => trigger?.action !== TRIGGER_ACTION_TYPES.REMOVE);
        const removeTriggers = triggers?.filter((trigger) => trigger?.action === TRIGGER_ACTION_TYPES.REMOVE);

        commit('SET_VALIDATED_AUTOMATION', {
            ...validatedAutomation,
            triggers: startTriggers,
            removeTriggers,
        });
    } catch (e) {
        sentry.log('Validating automation failed', { id });

        throw e;
    }
};

const createAutomationTemplate = async (context, request) => {
    try {
        const { data: { createAutomationTemplate: template } } = await templatesApi.createAutomationTemplate(request);

        return template;
    } catch (e) {
        sentry.log('Create automation template failed', { automationId: request.automationId });
        throw e;
    }
};

const updateAutomationTemplateDetails = async (_, { id, name, description }) => {
    try {
        const { data: { updateAutomationTemplateDetails: updateTemplateDetails } } = await templatesApi.updateAutomationTemplateDetails(id, { name, description });

        return updateTemplateDetails;
    } catch (e) {
        sentry.log('Updating automation template details failed', { id, name, description });
        throw e;
    }
};


const loadAutomationTemplates = async (_, { limit, cursor }) => {
    try {
        const { data: { automationTemplateList } } = await templatesApi.loadAutomationTemplates(limit, cursor);

        return automationTemplateList;
    } catch (e) {
        sentry.log('Load automation template list failed');
        throw e;
    }
};

const loadAutomationTemplate = async (_, id) => {
    try {
        const { data: { automationTemplate } } = await templatesApi.loadAutomationTemplate(id);

        return transformAutomationOutput(automationTemplate);
    } catch (e) {
        sentry.log('Load automation template failed', { id });
        throw e;
    }
};

const updateAutomationTemplate = async (_, { id, payload }) => {
    try {
        const { data: { updateAutomationTemplate: updated } } = await templatesApi.updateAutomationTemplate(id, transformAutomationInput(payload));

        return transformAutomationOutput(updated);
    } catch (e) {
        sentry.log('Updating automation template failed', { id });
        throw e;
    }
};

const publishAutomationTemplate = async (_, id) => {
    try {
        const { data: { publishAutomationTemplate: success } } = await templatesApi.publishAutomationTemplate(id);

        return success;
    } catch (e) {
        sentry.log('Publishing automation template failed', { id });
        throw e;
    }
};

const deleteAutomationTemplate = async (_, id) => {
    try {
        const { data: { deleteAutomationTemplate: successful } } = await templatesApi.deleteAutomationTemplate(id);

        return successful;
    } catch (e) {
        sentry.log('Delete automation template failed', { id });
        throw e;
    }
};

const installAutomation = async ({ rootState }, request) => {
    try {
        const { data: { installAutomation: automation } } = await api.installAutomation({
            id: request.id,
            userId: request.userId || rootState.auth.user.id,
            enable: request.enable || false,
        });

        return transformAutomationOutput(automation);
    } catch (e) {
        sentry.log('Install automation failed');
        throw e;
    }
};

const shareTemplate = async (_, payload) => {
    try {
        const { data: { shareTemplate: successful } } = await templatesApi.shareTemplate(payload);

        return successful;
    } catch (e) {
        sentry.log('Install automation failed');
        throw e;
    }
};
