export default {
    deal: {
        contacts: [],
    },
    pipeline: {
        stages: [],
    },
    outcomeOptions: {},
    pipelineLoading: false,
    pipelineList: [],
    pipelineListLoading: false,
    dealShowMore: false,
    configuration: {
        intoStageActions: [],
        outOfStageActions: [],
    },
};
