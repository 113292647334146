export const whenAvailable = (name, callback) => {
    if (window.sampleDataInProgress) {
        return;
    }

    const interval = 200;
    let attempts = 0;

    const handle = window.setInterval(() => {
        if (attempts > 20) {
            clearInterval(handle);
        } else if (window[name]) {
            clearInterval(handle);
            callback(window[name]);
        }

        attempts++;
    }, interval);
};

export const dispatchEvent = (name, data) => {
    if ('CustomEvent' in window && typeof window.CustomEvent === 'function') {
        window.dispatchEvent(new CustomEvent(name, data));
    } else {
        const newEvent = document.createEvent('CustomEvent');

        newEvent.initCustomEvent(name, true, true, data);
    }
};

export const downloadFile = (data, fileName, type = 'application/csv') => {
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(new Blob([data], { type }));
    link.download = fileName;
    link.dispatchEvent(new MouseEvent('click'));
};
