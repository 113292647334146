import Vue from 'vue';
import sortBy from 'lodash.sortby';
import { MEDIA_TYPES } from '@/shared/constants/communicationTemplates.constants';
import { FF_KEAP_GMB } from '@/shared/constants/featureFlag.constants';

export default {
    sortedEmailTemplates: ({ defaultEmailTemplates, emailTemplates }, _, rootState) => {
        const existingTemplateKinds = emailTemplates.reduce((acc, template) => {
            if (!acc.includes(template.templateKind)) {
                acc.push(template.templateKind);
            }

            return acc;
        }, []);

        let filteredDefaultTemplates = defaultEmailTemplates;

        const gmbEnabled = rootState.featureFlags[FF_KEAP_GMB];
        let removeTemplate = false;

        if (gmbEnabled) {
            if (!rootState.reviews.googleReviewLink) {
                removeTemplate = true;
            }
        } else if (!rootState.global.googlePlaceId || rootState.global.googlePlaceId === 'DISCONNECTED') {
            removeTemplate = true;
        }

        if (removeTemplate) {
            filteredDefaultTemplates = defaultEmailTemplates.filter((template) => {
                return template.templateKind !== 'REVIEW';
            });
        }

        const remainingDefaultTemplates = filteredDefaultTemplates
            .filter((template) => {
                return !existingTemplateKinds.includes(template.templateKind);
            })
            .map((template) => {
                return {
                    ...template,
                    title: Vue.prototype.$i18nInstance.t(template.title),
                    subject: Vue.prototype.$i18nInstance.t(template.subject),
                    content: Vue.prototype.$i18nInstance.t(template.content),
                };
            });

        return sortBy([...emailTemplates, ...remainingDefaultTemplates], ({ updatedOn }) => {
            return updatedOn ? -new Date(updatedOn) : null;
        });
    },

    displayTitle: () => (template) => {
        return template?.title || Vue.prototype.$i18nInstance.t('templates.defaultTitle');
    },

    displayDescription: () => (template, mediaType = MEDIA_TYPES.HTML) => {
        if (mediaType === MEDIA_TYPES.HTML) {
            return template?.subject || Vue.prototype.$i18nInstance.t('templates.defaultSubject');
        }

        if (mediaType === MEDIA_TYPES.PLAIN_TEXT) {
            return template?.content || Vue.prototype.$i18nInstance.t('templates.defaultContent');
        }

        return '';
    },

    sortedSmsTemplates: ({ defaultSmsTemplates, smsTemplates }, _, rootState) => {
        const existingTemplateKinds = smsTemplates.reduce((acc, template) => {
            if (!acc.includes(template.templateKind)) {
                acc.push(template.templateKind);
            }

            return acc;
        }, []);

        let filteredDefaultSmsTemplates = defaultSmsTemplates;

        if (!rootState.global.googlePlaceId || rootState.global.googlePlaceId === 'DISCONNECTED') {
            filteredDefaultSmsTemplates = defaultSmsTemplates.filter((template) => {
                return template.templateKind !== 'REVIEW';
            });
        }

        const remainingDefaultTemplates = filteredDefaultSmsTemplates
            .filter((template) => {
                return !existingTemplateKinds.includes(template.templateKind);
            })
            .map((template) => {
                return {
                    ...template,
                    title: Vue.prototype.$i18nInstance.t(template.title),
                    subject: Vue.prototype.$i18nInstance.t(template.subject),
                    content: Vue.prototype.$i18nInstance.t(template.content),
                };
            });

        return sortBy([...smsTemplates, ...remainingDefaultTemplates], ({ updatedOn }) => {
            return updatedOn ? -new Date(updatedOn) : null;
        });
    },

    sortedKeapMarketingNumberTemplates: ({ defaultKeapMarketingNumberBroadcastTemplates, keapMarketingNumberTemplates }) => {
        const existingTemplateKinds = keapMarketingNumberTemplates.reduce((acc, template) => {
            if (!acc.includes(template.templateKind)) {
                acc.push(template.templateKind);
            }

            return acc;
        }, []);

        const remainingDefaultTemplates = defaultKeapMarketingNumberBroadcastTemplates
            .filter((template) => {
                return !existingTemplateKinds.includes(template.templateKind);
            })
            .map((template) => {
                return {
                    ...template,
                    title: Vue.prototype.$i18nInstance.t(template.title),
                    subject: Vue.prototype.$i18nInstance.t(template.subject),
                    content: Vue.prototype.$i18nInstance.t(template.content),
                };
            });

        return sortBy([...keapMarketingNumberTemplates, ...remainingDefaultTemplates], ({ updatedOn }) => {
            return updatedOn ? -new Date(updatedOn) : null;
        });
    },
};
