<template>
    <contact-query v-slot="{ results, loading, query, search }">
        <ds-multiselect
            ref="taskContactSelect"
            :value="value"
            allow-add
            searchable
            label-prop="fullName"
            value-prop="id"
            help-text
            help-text-prop="email"
            :placeholder="$t('contactRecord.pages.task.associatedContact')"
            :add-text="$t('addNewContact')"
            :options="normalizeOptions(results, search)"
            :loading="loading"
            data-qa="task-associated-contact"
            @updateSearch="query"
            @add="handleAddNewContact"
            @input="$listeners.input"
        >
            <template v-slot:option="{ option, highlight, isSelected, filter }">
                <div
                    :class="['menu-item', {
                        highlight,
                        separator: isSelected,
                    }]"
                >
                    <div
                        v-if="isSelected"
                        class="option-row prevent-select"
                        :data-qa="option.fullName"
                        @click.prevent.stop="removeContact"
                    >
                        <ds-checkbox :value="isSelected" @input="removeContact">
                            <div class="option-text">
                                <ds-text-highlighter
                                    v-if="option.fullName"
                                    :highlight="filter"
                                    :content="option.fullName"
                                />
                            </div>
                        </ds-checkbox>
                    </div>

                    <div
                        v-else
                        class="option-row prevent-select"
                        :data-qa="option.fullName"
                    >
                        <div class="option-text">
                            <ds-text-highlighter
                                v-if="option.fullName"
                                :highlight="filter"
                                :content="option.fullName"
                            />
                        </div>
                    </div>
                </div>
            </template>
        </ds-multiselect>
    </contact-query>
</template>

<script>
import ContactQuery from '@/contacts/components/ContactQuery';
import AddContactModal from '@/contacts/components/add/AddContactModal';
import { displayFullName } from '@/contacts/contact-info-utils';

export default {
    components: {
        ContactQuery,
    },

    props: {
        value: [Object, Array, String, Number],
    },

    created() {
        this.$bus.$on('CONTACT_ADDED', this.setCreatedContact);
    },

    beforeDestroy() {
        this.$bus.$off('CONTACT_ADDED');
    },

    methods: {
        normalizeOptions(contacts, search) {
            const { value: contact } = this;

            if (contact?.id && !search) {
                // eslint-disable-next-line eqeqeq
                return [contact].concat(contacts.filter((c) => c.id != contact.id));
            }

            return contacts;
        },

        handleAddNewContact() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddContactModal,
                data: { hideSuccessModal: true },
            });
        },

        setCreatedContact(contact) {
            this.$emit('selected-contact', {
                id: contact.id,
                email: contact.email,
                fullName: displayFullName(contact),
            });
        },

        removeContact() {
            this.$emit('input', 0);

            this.$refs.taskContactSelect.toggle();
        },
    },
};
</script>

<style lang="scss" scoped>
    .menu-item .checkbox {
        --checkbox-padding: $gp /4;

        .option-text  {
            --text-highlighter-matching-color: #{$color-blue};
        }
    }
</style>

<i18n>
{
    "en-us": {
        "addNewContact": "Add new contact"
    }
}
</i18n>
