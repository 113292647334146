<template>
    <div>
        <slot :openModal="openModal" />
    </div>
</template>

<script>
export default {
    props: {
        feature: String,
    },

    methods: {
        openModal() {
            this.$store.commit('OPEN_FEEDBACK_MODAL', this.feature);
        },
    },
};
</script>
