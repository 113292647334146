import Vue from 'vue';
import moment from 'moment';
import { SUPPORT_PHONE_NUMBERS } from '@/communication/communication.constants';
import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';

const MAX_PHONE_LENGTH = 11;

export const normalizePhoneNumber = (phoneNumber) => {
    if (phoneNumber == null) {
        return '';
    }

    let strippedPhone = phoneNumber.replace(/\D/g, '');

    // phoneNumber isn't guaranteed to be a phone number, it could be a
    // special id string (e.g. 'support')
    if (strippedPhone.length === 0) {
        return phoneNumber;
    }

    if (strippedPhone.length <= 10) {
        strippedPhone = `1${strippedPhone}`;
    }

    return strippedPhone;
};

export const isValidPhoneNumber = (phoneNumber) => {
    if (isSupportNumber(phoneNumber)) {
        return true;
    }

    return normalizePhoneNumber(phoneNumber).length === MAX_PHONE_LENGTH;
};

export const getConversationDate = ({ lastMessageTime, updateTime }) => {
    const time = moment(lastMessageTime || updateTime);
    const day = moment(lastMessageTime || updateTime).startOf('day');
    const now = moment().startOf('day');
    const daysSince = now.diff(day, 'days');
    let lastUpdated;

    if (daysSince === 0) {
        lastUpdated = time.format(TIME_FORMAT);
    } else if (daysSince >= 0 && daysSince < 2) {
        lastUpdated = Vue.prototype.$i18nInstance.t('yesterday');
    } else if (daysSince >= 2 && daysSince < 7) {
        lastUpdated = time.format('dddd');
    } else if (daysSince >= 7 && daysSince < 365) {
        lastUpdated = time.format('ddd, MMM Do');
    } else {
        lastUpdated = time.format('M/D/YY');
    }

    return lastUpdated;
};

export const isSupportNumber = (phoneNumber) => {
    return SUPPORT_PHONE_NUMBERS.includes(phoneNumber);
};
