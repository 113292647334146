export const DEFAULT_AUTOSAVE_DURATION = 30000;
export const DEFAULT_RETRY_ATTEMPT_COUNT = 3;

/**
 * @callback PerformAutoSave
 *
 * @param {[string]} pages A list of page ids that need to be updated
 * @return {Promise<*>}
 */


/**
 * Creates and starts a timer-based update queue for saving records. This utility is careful to ensure that all records
 * that are marked as dirty are processed, to prevent any data loss due to unsaved records.  It also enforces that no updates
 * should happen concurrently, and that operations like [saveChanges] or [stop] are idempotent.  This utility maintains a
 * queue of dirty record ids, and will pass the list of dirty ids to the callback [performAutoSave] function, which is expected to
 * perform the actual save, and return a Promise that is resolved or rejected.
 *
 * If the performAutoSave operation fails, then each record that was attempted will be scheduled for retry, up to
 * [retryAttemptCount] times retries before giving up.
 *
 * calling `autoSaveTimer.stop()` returns a Promise that ensures that all outstanding dirty records have been saved.
 * This includes cancelling any timers, waiting for any in-flight updates, and finally persisting any outstanding dirty
 * records before completing.
 *
 * @param {PerformAutoSave} performAutoSave Processes a single page update by taking in the list of ids that require updates
 * @param {number} autoSaveDuration The amount of time in millis to wait after the successful completion of the previous update
 * @param {number} retryAttemptCount The number of retry attempts that should be made before giving up
 */
export function autoSaveTimer({
    performAutoSave,
    autoSaveDuration = DEFAULT_AUTOSAVE_DURATION,
    retryAttemptCount = DEFAULT_RETRY_ATTEMPT_COUNT,
} = {}) {
    /**
     * Any current in-flight update, as a promise. Storing this as a variable helps to ensure that we are not executing
     * competing save operations with potentially different data.
     * @type {null|Promise<boolean>}
     */
    let pendingUpdate = null;

    /**
     * Contains a list of all records (by id) that need to be updated, with the retry count.
     */
    const dirtyRecordIds = [];

    /**
     * Number of attempts for each record.  Used to terminate retries after 3
     */
    const attemptCounts = {};

    /**
     * A Promise that can be set/resolved when stopping.
     * @type {Promise<*>}
     */
    let stopPromise = null;

    return {
        get dirtyRecordIds() {
            return [...dirtyRecordIds];
        },

        /**
         * Are we running?
         */
        isRunning: false,

        // The current delay timer, if there is one
        currentTimer: null,

        /**
         * Stops this queue from processing.  This will:
         *
         * - cancel any outstanding timers
         * - set the isRunning flag to false to prevent any future timers
         * - while there are still pending updates, this will save those to the server
         *
         * @return {Promise<void>} Resolved after timers are cleared, any in-flight updates complete, and there are no more updates
         */
        stop() {
            if (stopPromise != null) {
                return stopPromise;
            }

            this.isRunning = false;
            stopPromise = new Promise(async (resolve, reject) => {
                try { // Stop any pending scheduled updates.  No future update should be scheduled until start is called
                    clearTimeout(this.currentTimer);

                    // Ensure there are no in-flight updates by the time we stop.
                    if (pendingUpdate) {
                        await pendingUpdate;
                    }

                    // It's possible to have updates added to the queue while waiting.  Do one last check
                    if (this.hasUpdates()) {
                        await this.saveChanges();
                    }
                    resolve();
                } catch (e) {
                    reject(e);
                } finally {
                    stopPromise = null;
                }
            });

            return stopPromise;
        },

        /**
         * Starts the timer to run [autoSaveDuration] ms after the last update was completed
         */
        start() {
            if (this.isRunning) return;

            this.isRunning = true;
            this.currentTimer = setTimeout(() => this.saveChangesAndScheduleNext(), autoSaveDuration);
        },

        /**
         * Marks a record as "dirty", or needing to be updated on the server
         *
         * @param {string} recordId
         */
        markRecordDirty(recordId) {
            if (dirtyRecordIds.indexOf(recordId) === -1) {
                dirtyRecordIds.push(recordId);
            }
        },

        /**
         * Processes all pending updates, and schedules another update after a timeout of [UNLAYER_UPDATE_INTERVAL]
         *
         * @return {Promise<boolean>}
         */
        saveChangesAndScheduleNext() {
            return this.saveChanges().finally(() => {
                if (this.isRunning) {
                    this.currentTimer = setTimeout(() => this.saveChangesAndScheduleNext(), autoSaveDuration);
                }
            });
        },

        /**
         * Whether there are any pages that still need to be persisted.
         * @return {boolean}
         */
        hasUpdates() {
            return this.dirtyRecordIds.length > 0;
        },

        /**
         * Processes a single batch of dirty records, but doesn't schedule any future updates
         *
         * @return {Promise<boolean|null>} When the process has completed fully
         */
        saveChanges() {
            if (pendingUpdate) {
                // Never stack updates
                return pendingUpdate;
            }

            if (this.hasUpdates()) {
                // Clone the dirtyRecordIds and reset it to avoid any race conditions while processing
                const dirtyRecordsCopy = [...dirtyRecordIds];

                dirtyRecordIds.length = 0;

                // Increment the attempts counter for each record
                for (const recordId of dirtyRecordsCopy) {
                    attemptCounts[recordId] = (attemptCounts[recordId] ?? 0) + 1;
                }

                pendingUpdate = performAutoSave(dirtyRecordsCopy)
                    .then(() => {
                        // Clear attempt counts for each successfully saved record
                        for (const savedRecordId of dirtyRecordsCopy) {
                            delete attemptCounts[savedRecordId];
                        }

                        return true;
                    })
                    .catch(() => {
                        // Reschedule all failed records, as long as they are not above the retry limit.
                        for (const dirtyRecordId of dirtyRecordsCopy) {
                            if (attemptCounts[dirtyRecordId] < retryAttemptCount) {
                                this.markRecordDirty(dirtyRecordId);
                            }
                        }

                        return false;
                    }).finally(() => {
                        pendingUpdate = null;
                    });

                return pendingUpdate;
            }

            return Promise.resolve(null);
        },
    };
}
