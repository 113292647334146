import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase/app';
import VueShortkey from 'vue-shortkey';
import { sync } from 'vuex-router-sync';
import VueClipboard from 'vue-clipboard2';
import smoothscroll from 'smoothscroll-polyfill';
import 'fast-text-encoding';
import PortalVue from 'portal-vue';
import vClickOutside from 'v-click-outside';
import DesignSystem from '@infusionsoft/design-system';

import App from '@/App';
import router from '@/router';
import store from '@/shared/store';
import { i18n } from '@/shared/i18n/index';
import * as errorUtils from '@/shared/utils/error.util';
import amplitude from '@/analytics/amplitude';
import sentry from '@/analytics/sentry';
import fullstory from '@/analytics/fullstory';
import { notify } from '@/shared/utils/browser-notifications.util';
import performance from '@/shared/utils/performance.util';
import slack from '@/shared/utils/slack.util';
import { components, icons, illustrations } from '@/shared/utils/design-system-imports.util';
import '@/shared/styles/main.scss';
import '@/shared/directives';

fullstory.init({
    orgId: process.env.VUE_APP_FULLSTORY_ORG,
    devMode: process.env.NODE_ENV !== 'production',
});

Vue.use(DesignSystem, {
    components,
    icons,
    illustrations,
    errorBanner: {
        selector: '#content',
        topPxOffset: '56px',
    },
    onOverlayActive: () => { store.commit('SET_OVERLAY_ACTIVE', true); },
    onOverlayInactive: () => { store.commit('SET_OVERLAY_ACTIVE', false); },
});
Vue.use(PortalVue);
Vue.use(VueRouter);
Vue.use(VueShortkey, { prevent: ['input', 'textarea', 'div.ql-editor', '[contenteditable="true"]'] });
Vue.use(VueClipboard);
Vue.use(vClickOutside);

smoothscroll.polyfill();

Vue.config.errorHandler = errorUtils.handleError;
errorUtils.handlePromiseRejections();

if (process.env.NODE_ENV === 'development') {
    Vue.config.devtools = true;
    Vue.config.debug = true;
    Vue.config.productionTip = false;
} else {
    sentry.init(Vue);
}

sync(store, router);

amplitude.v1.init();
amplitude.v2.init();

Vue.prototype.$i18nInstance = i18n;
Vue.prototype.$bus = new Vue();
Vue.prototype.$log = sentry.log;
Vue.prototype.$track = amplitude.v1.track;
Vue.prototype.$notify = notify;
Vue.prototype.$sendEvent = performance.sendHoneycombEvent;
Vue.prototype.$slack = slack.sendFeedback;
Vue.prototype.$pagePerformance = performance.pagePerformance;

const config = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    databaseURL: `https://${process.env.VUE_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
};

Vue.prototype.$firebase = firebase.initializeApp(config);

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
