import axios from 'axios';
import Vue from 'vue';
import gql from 'graphql-tag';

import createRenderer from '@infusionsoft/bard-email-renderer';
import { bardJsonFromHtml } from '@/shared/utils/bard.util';
import { ERROR_CODE_DUPLICATE_EMAIL } from '@/contacts/contacts.constants';
import {
    FF_PLATFORM_KEAPWEB_NEWAPI_CONTACTS_LOADCONTACTDETAILS_ENABLED,
    FF_FLAGSHIP_FS_27692_EMAIL_OPEN_STATUS,
    FF_KEAP_SAMPLE_DATA,
    FF_KEAP_PIPELINE_MIGRATION,
    KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT,
} from '@/shared/constants/featureFlag.constants';
import { normalizeContacts, mergeContactLists } from '@/contacts/utils/actions';
import { getCustomFields, CUSTOM_CONTACT_FIELD_COUNT_USER_PROPERTIES } from '@/customFields/customFields.api';

import {
    queryGetContact,
    queryContacts,
    queryContactPage,
    queryContactDetails,
    queryContactEmailHistoryList,
} from '@/contacts/store/queries';

import COMPANY_ACTIONS from './companies/actions';
import {
    LOAD_NOTES,
    ADD_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,
} from './notes/actions';
import {
    LOAD_TASK_LIST,
    ADD_TASK,
    ADD_TASKS,
    UPDATE_TASK,
    UPDATE_TASK_WITH_OUTCOME,
    DELETE_TASK,
} from './tasks/actions';
import {
    LOAD_CONTACT_TAGS,
    REMOVE_CONTACT_TAG,
    APPLY_CONTACT_TAGS,
    APPLY_TAG_TO_CONTACTS,
    REMOVE_TAG_FROM_CONTACTS,
} from './tags/actions';
import {
    LOAD_CONTACT_FILES,
    ADD_CONTACT_FILE,
    DELETE_CONTACT_FILE,
    UPDATE_CONTACT_FILE,
} from './files/actions';
import { UPDATE_CONTACT_TYPE, BULK_UPDATE_CONTACT_TYPE } from './contactTypes/actions';
import { LOAD_CONTACT_RECORD, REFRESH_CONTACT_RECORD } from './contactRecord/actions';

import {
    fetchContactListTotalCount,
    fetchContactLists,
} from '../api';
import { deprecated } from '@/shared/utils/deprecations';

import amplitude from '@/analytics/amplitude';
import intercom from '@/analytics/intercom';
import sentry from '@/analytics/sentry';
import cloneDeep from 'lodash.clonedeep';

export default {
    ...COMPANY_ACTIONS,
    LOAD_CONTACT_LIST(context, payload) {
        return loadContactList(context, payload);
    },

    LOAD_CONTACT_RECORD,
    REFRESH_CONTACT_RECORD,

    LOAD_CALL_DETAILS(context, payload) {
        return loadCallDetails(context, payload);
    },

    LOAD_ACCOUNT_CONTACT_COUNT(context) {
        return loadAccountContactListTotal(context);
    },

    LOAD_CONTACT_LIST_COUNT(context, payload) {
        return loadContactListTotal(context, payload);
    },

    LOAD_CONTACT_LISTS(context) {
        return loadContactLists(context);
    },

    LOAD_CONTACT_LISTS_COUNT(context, payload) {
        return loadContactListsCount(context, payload);
    },

    LOAD_CONTACT_LISTS_COUNTS(context) {
        return loadContactListsCounts(context);
    },

    LOAD_SAVED_CONTACT_LIST(context, payload) {
        return loadSavedContactList(context, payload);
    },

    LOAD_CONTACT_FILTERS_METADATA(context) {
        return loadContactFilterMetadata(context);
    },

    LOAD_FILTERED_CONTACTS_COUNT(context, payload) {
        return loadFilteredContactsCount(context, payload);
    },

    LOAD_SAVED_CONTACT_LIST_COUNT(context, payload) {
        return loadSavedContactListCount(payload);
    },

    CREATE_CONTACT_LIST(context, payload) {
        return createContactList(context, payload);
    },

    UPDATE_CONTACT_LIST(context, payload) {
        return updateContactList(context, payload);
    },

    UPDATE_CONTACT_LIST_VISIBILITY(context, payload) {
        return updateContactListVisibility(context, payload);
    },

    DELETE_CONTACTS(context, payload) {
        return deleteContacts(context, payload);
    },

    DELETE_CONTACT_LIST(context, payload) {
        return deleteContactList(context, payload);
    },

    EXPORT(context, payload) {
        return exportContacts(context, payload);
    },

    LOAD_CONTACT_PAGE(context, id) {
        return loadContactPage(context, id);
    },

    GET_CONTACT(context, id) {
        return getContact(context, id);
    },

    LOAD_CONTACT_DETAILS(context, payload) {
        return loadContactDetails(context, payload);
    },

    LOAD_CONTACT_MARKETABILITY(context, payload) {
        return loadContactMarketability(context, payload);
    },

    SAVE_CONTACT_DETAILS(context, payload) {
        return saveContactDetails(context, payload);
    },

    LOAD_CUSTOM_REGION_OPTIONS(context, payload) {
        return loadCustomRegionOptions(context, payload);
    },

    LOAD_REGION_OPTIONS(context, payload) {
        return loadRegionOptions(context, payload);
    },

    ADD_CONTACT(context, payload) {
        return addContact(context, payload);
    },

    LOAD_CONTACT_FILES,
    ADD_CONTACT_FILE,
    DELETE_CONTACT_FILE,
    UPDATE_CONTACT_FILE,
    LOAD_CONTACT_TAGS,
    REMOVE_CONTACT_TAG,
    APPLY_CONTACT_TAGS,
    APPLY_TAG_TO_CONTACTS,
    REMOVE_TAG_FROM_CONTACTS,

    ADD_APPOINTMENT(context, payload) {
        return addAppointment(context, payload);
    },

    UPDATE_APPOINTMENT(context, payload) {
        return updateAppointment(context, payload);
    },

    DELETE_APPOINTMENT(context, id) {
        return deleteAppointment(context, id);
    },

    SEND_EMAIL(context, email) {
        return sendEmail(context, email);
    },

    CANCEL_EMAIL(context, emailUUID) {
        return cancelEmail(context, emailUUID);
    },

    LOAD_EMAILS(context, payload) {
        return loadEmails(context, payload);
    },

    LOAD_EMAIL_BODY(context, payload) {
        return loadEmailBody(context, payload);
    },

    LOAD_NOTES,
    ADD_NOTE,
    UPDATE_NOTE,
    DELETE_NOTE,

    LOAD_STRATEGIES(context, payload) {
        return loadStrategies(context, payload);
    },

    LOAD_TASK_LIST,
    ADD_TASK,
    ADD_TASKS,
    UPDATE_TASK,
    UPDATE_TASK_WITH_OUTCOME,
    DELETE_TASK,

    LOAD_MORE_ACTIVITIES(context, payload) {
        return loadMoreActivities(context, payload);
    },

    ADD_TO_BROADCAST(context, payload) {
        return addToBroadcast(context, payload);
    },

    ADD_TO_CAMPAIGN_SEQUENCE(context, payload) {
        return addToCampaignSequence(context, payload);
    },

    REMOVE_FROM_CAMPAIGN(context, payload) {
        return removeFromCampaign(context, payload);
    },

    SEARCH_CONTACTS(context, term) {
        return searchContacts(context, term);
    },

    UPDATE_DEAL(context, deal) {
        return updateDeal(context, deal);
    },

    LOAD_CUSTOM_FIELDS(context, payload) {
        return loadCustomFields(context, payload);
    },

    UPDATE_CUSTOM_FIELD_GROUP(context, payload) {
        return updateCustomFieldGroup(context, payload);
    },

    DELETE_CUSTOM_FIELD_GROUP(context, payload) {
        return deleteCustomFieldGroup(context, payload);
    },

    CREATE_CUSTOM_FIELD(context, payload) {
        return createCustomField(context, payload);
    },

    UPDATE_CUSTOM_FIELD(context, payload) {
        return updateCustomField(context, payload);
    },

    DELETE_CUSTOM_FIELD(context, payload) {
        return deleteCustomField(context, payload);
    },

    LOAD_CONTACT_PIPELINES(context, payload) {
        return loadContactPipelines(context, payload);
    },

    UPDATE_CONTACT_TYPE,
    BULK_UPDATE_CONTACT_TYPE,
};

const loadContactList = ({
    commit,
    state,
}, {
    filter = 0,
    limit = 20,
    offset = 0,
    sortBy = 'createDate',
    sortAscending = false,
    filterParams = '',
    doCommit = true,
    pageToken = null,
}) => {
    const { contacts: existing } = state;

    filter = filter === 'all' ? 0 : filter;

    commit('SET_LOADING_CONTACT_LIST', true);

    return new Promise(async (resolve, reject) => {
        try {
            const {
                data: {
                    listContacts: {
                        contacts,
                        isPagingByToken,
                        nextPageToken,
                    },
                },
            } = await queryContacts(
                filter,
                encodeURIComponent(filterParams),
                limit,
                offset,
                sortBy,
                sortAscending,
                pageToken,
            );

            normalizeContacts(contacts);

            if (doCommit) {
                commit('SET_CONTACTS', [...mergeContactLists(existing, contacts)]);
            }

            commit('SET_LOADING_CONTACT_LIST', false);

            resolve({
                contacts,
                isPagingByToken,
                nextPageToken,
            });
        } catch (e) {
            reject(e);
        }
    });
};

const loadAccountContactListTotal = ({ commit }) => {
    return fetchContactListTotalCount().then((totalCount) => {
        commit('LOAD_ACCOUNT_CONTACT_TOTAL_SUCCESS', totalCount);

        return totalCount;
    });
};

const loadContactListTotal = ({ commit }, { filterId, filterQuery }) => {
    return fetchContactListTotalCount({ id: filterId, filter: filterQuery })
        .then((totalCount) => {
            commit('SET_CONTACT_TOTAL', totalCount);

            return totalCount;
        });
};

const loadContactLists = ({ commit }) => {
    commit('LOAD_CONTACT_LISTS_START');

    return fetchContactLists()
        .then((lists) => {
            commit('LOAD_CONTACT_LISTS_SUCCESS', lists);
        })
        .catch(() => {
            commit('LOAD_CONTACT_LISTS_ERROR');
        });
};

const loadContactListsCount = ({ commit }, id) => {
    fetchContactListTotalCount({ id })
        .then((count) => {
            commit('LOAD_CONTACT_LISTS_COUNT_LOADED', { id, count });
        });
};

const loadContactListsCounts = ({ state, commit }) => {
    const { contactLists: lists } = state;

    commit('LOAD_CONTACT_LISTS_COUNTS_START');

    lists.forEach((list) => {
        const { id } = list;

        fetchContactListTotalCount({ id })
            .then((count) => {
                commit('LOAD_CONTACT_LISTS_COUNT_LOADED', { id, count });
            });
    });
};

const loadSavedContactList = (context, id) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/list/${id}`)
            .then(({ data: savedContactList }) => {
                resolve(savedContactList);
            })
            .catch(reject);
    });
};

const loadContactFilterMetadata = ({ commit }) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactList/filter/list`)
        .then(({ data: { filterItemGroups } }) => {
            commit('SET_CONTACT_FILTERS_METADATA', filterItemGroups);
        });
};

const loadCallDetails = ({ commit }, { phoneNumbers, contactId }) => {
    return new Promise((resolve, reject) => {
        return Vue.prototype.$graphql.query({
            query: gql`
                query callList($phoneNumbers: [String!], $contactId: ID!) {
                    callList(contactId: $contactId, phoneNumbers: $phoneNumbers, limit: 100) {
                        callSid,
                        callStatus,
                        contactId,
                        createTime,
                        duration,
                        endTime,
                        from,
                        isUnviewed,
                        location,
                        outgoing,
                        startTime,
                        source,
                        to,
                        updateTime,
                    }
                }
            `,
            fetchPolicy: 'no-cache',
            variables: {
                phoneNumbers,
                contactId,
            },
        })
            .then(({ data: { callList } }) => {
                commit('SET_CALL_DETAILS', callList);

                resolve();
            })
            .catch(reject);
    });
};

const loadFilteredContactsCount = ({ commit }, params) => {
    commit('SET_LOADING_CONTACT_COUNT', true);

    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/count?${params}`)
            .then(({ data: count }) => {
                commit('SET_FILTERED_CONTACT_COUNT', count);
                commit('SET_LOADING_CONTACT_COUNT', false);

                resolve();
            })
            .catch(reject);
    });
};

const loadSavedContactListCount = (id) => {
    return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/count?filterId=${id}`)
        .then((res) => ({
            ...res,
            data: res.data < 0 ? 0 : res.data,
        }));
};

const loadCustomFields = async ({ commit, getters, rootState }) => {
    const isCustomFieldTechDebtEnabled = rootState.featureFlags[KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT];

    if (isCustomFieldTechDebtEnabled) {
        const customFields = await getCustomFields('contact');

        commit('SET_CUSTOM_FIELDS', customFields);

        amplitude.v2.setUserProperties({
            [CUSTOM_CONTACT_FIELD_COUNT_USER_PROPERTIES.count]: customFields.length,
            [CUSTOM_CONTACT_FIELD_COUNT_USER_PROPERTIES.alwaysShownCount]: customFields.filter(({ alwaysShown }) => alwaysShown).length,
        });

        return Promise.resolve(customFields);
    }

    // Can be removed with FF KEAP_CONTACT_CUSTOM_FIELDS_TECH_DEBT
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactfieldgroups`)
            .then(({ data: { fieldGroups } }) => {
                commit('SET_CUSTOM_FIELD_GROUPS', fieldGroups);

                amplitude.v2.setUserProperties({
                    [CUSTOM_CONTACT_FIELD_COUNT_USER_PROPERTIES.count]: getters.customFieldCount,
                    [CUSTOM_CONTACT_FIELD_COUNT_USER_PROPERTIES.alwaysShownCount]: getters.customFields.filter(({ alwaysShown }) => alwaysShown).length,
                });

                resolve(fieldGroups);
            })
            .catch(reject);
    });
};

const createCustomField = ({ commit }, { groupId, customField }) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactfieldgroups/${groupId}/contactfields`, customField)
            .then(({ data }) => {
                commit('ADD_CUSTOM_FIELD', data);
                resolve(data);
            })
            .catch(reject);
    });
};

const updateCustomField = ({ commit }, { fieldId, originGroupId, customField }) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactfieldgroups/${originGroupId}/contactfields/${fieldId}`, customField)
            .then(({ data }) => {
                commit('UPDATE_CUSTOM_FIELD', data);
                resolve();
            })
            .catch(reject);
    });
};

const deleteCustomField = ({ commit }, { fieldId, groupId }) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactfieldgroups/${groupId}/contactfields/${fieldId}`)
            .then(() => {
                commit('DELETE_CUSTOM_FIELD', { fieldId, groupId });
                resolve();
            })
            .catch(reject);
    });
};

const updateCustomFieldGroup = ({ commit }, contactFieldGroup) => {
    const { name, previousContactFieldGroupId, nextContactFieldGroupId } = contactFieldGroup;
    const payload = {
        name,
        previousContactFieldGroupId: previousContactFieldGroupId || null,
        nextContactFieldGroupId: nextContactFieldGroupId || null,
    };

    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactfieldgroups/${contactFieldGroup.id}`, payload)
            .then(() => {
                if (previousContactFieldGroupId || nextContactFieldGroupId) {
                    commit('MOVE_CUSTOM_FIELD_GROUP', contactFieldGroup);
                }

                resolve();
            })
            .catch(reject);
    });
};

const deleteCustomFieldGroup = ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
        return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactfieldgroups/${id}`)
            .then(() => {
                commit('DELETE_CUSTOM_FIELD_GROUP', id);
                resolve();
            })
            .catch(reject);
    });
};

const createContactList = ({ commit }, list) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/list`, list)
        .then(({ data: id }) => {
            commit('ADD_CONTACT_LIST', {
                id,
                name: list.filterName,
                count: 0,
                shown: true,
                forSmartList: false,
            });

            return id;
        });
};

const updateContactList = ({ commit }, { id, ...list }) => {
    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/list/${id}`, list)
        .then(() => {
            commit('UPDATE_CONTACT_LIST_NAME', { id, name: list.filterName });
        });
};

const updateContactListVisibility = ({ commit }, { id, show }) => {
    commit('SET_CONTACT_LIST_VISIBILITY', { id, show });

    return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactList/${id}/userDisplayData`, { show });
};

const deleteContacts = ({ rootState, commit, dispatch }, {
    savedFilterId,
    ids,
    filterItems,
    checkAll,
}) => {
    commit('REMOVE_CONTACTS', { ids, checkAll });
    commit('tasks/CLEAR_APP_TASKS', null, { root: true });
    dispatch('communication/CLEAR_CONTACT_INFO', { ids }, { root: true });

    if (rootState.featureFlags[FF_KEAP_SAMPLE_DATA]) {
        dispatch('sampleData/DELETE_CONTACT_SAMPLE_DATA', ids, { root: true });
    }

    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/list`, {
        data: {
            savedFilterId,
            ids,
            filterItems,
            checkAll,
        },
    });
};

const deleteContactList = ({ commit }, id) => {
    commit('DELETE_CONTACT_LIST', id);

    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactList/${id}`);
};

const exportContacts = ({ commit }, params) => {
    commit('SET_LOADING_EXPORT', true);

    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/export`, params.data)
        .then((response) => {
            commit('SET_LOADING_EXPORT', false);

            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/csv' }));
            link.download = params.filename;
            link.dispatchEvent(new MouseEvent('click'));
        });
};

const loadContactPage = async ({
    state,
    commit,
}, id) => {
    if (state.contact.id === id) {
        return;
    }

    commit('SET_CONTACT_LOADED', false);

    try {
        const data = await queryContactPage(id);

        data.contactInfo.createTime = data.contact.createTime;
        data.contactInfo.updateTime = data.contact.updateTime;

        commit('SET_CONTACT', {
            id,
            ...data.contactInfo,
        });

        commit('UPDATE_CONTACT_IN_LIST', state.contact);

        commit('SET_CONTACT_LOADED', true);
    } catch (error) {
        commit('SET_CONTACT_LOADED', true);

        throw error;
    }
};

const getContact = async (_, id) => {
    deprecated('The GET_CONTACT action has been deprecated. Please use the queryGetContact function directly instead.');
    const contactData = await queryGetContact(id);

    const contact = {
        id,
        ...contactData,
    };

    return contact;
};

const loadContactDetails = async ({
    state,
    rootState,
    commit,
    dispatch,
}, id) => {
    let countryOptionsData;
    let companyData;
    let contactDetailsData;

    const contactId = id || state.contact.id;

    if (rootState.featureFlags[FF_PLATFORM_KEAPWEB_NEWAPI_CONTACTS_LOADCONTACTDETAILS_ENABLED]) {
        const { contactDetails, contact } = await queryContactDetails(contactId);
        const { companies, owners, contactDetails: contactDetailsFieldGroups } = contactDetails;
        const { billingAddress, otherAddress, shippingAddress } = contact;

        const countryCodes = getContactCountryCodes(billingAddress, otherAddress, shippingAddress);

        const countryAndStateOptions = await getCountryAndStateOptions(countryCodes);

        const {
            countryOptions,
            billingStateOptions,
            shippingStateOptions,
            otherStateOptions,
        } = countryAndStateOptions;

        if (billingAddress && billingStateOptions) {
            contact.billingAddress = cleanRegionCodes(billingAddress, billingStateOptions);
        }

        if (shippingAddress && shippingStateOptions) {
            contact.shippingAddress = cleanRegionCodes(shippingAddress, shippingStateOptions);
        }

        if (otherAddress && otherStateOptions) {
            contact.otherAddress = cleanRegionCodes(otherAddress, otherStateOptions);
        }

        companyData = companies;
        countryOptionsData = countryOptions;
        contactDetailsData = {
            contact,
            billingStateOptions,
            shippingStateOptions,
            otherStateOptions,
            owners,
            contactDetails: contactDetailsFieldGroups,
        };
    } else {
        const { data } = await axios
            .get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordDetails/${contactId}`);

        const {
            countryOptions,
            companies,
            ...contactDetails
        } = data;

        countryOptionsData = countryOptions;
        companyData = companies;
        contactDetailsData = contactDetails;

        if (data.contact?.billingAddress && contactDetailsData?.billingStateOptions) {
            data.contact.billingAddress = cleanRegionCodes(data.contact.billingAddress, contactDetailsData.billingStateOptions);
        }

        if (data.contact?.shippingAddress && contactDetailsData?.shippingStateOptions) {
            data.contact.shippingAddress = cleanRegionCodes(data.contact.shippingAddress, contactDetailsData.shippingStateOptions);
        }

        if (data.contact?.otherAddress && contactDetailsData?.otherStateOptions) {
            data.contact.otherAddress = cleanRegionCodes(data.contact.otherAddress, contactDetailsData.otherStateOptions);
        }
    }

    commit('SET_COUNTRIES', countryOptionsData);
    commit('SET_COMPANIES', companyData);
    commit('SET_CONTACT_DETAILS', contactDetailsData);
    dispatch('LOAD_CUSTOM_REGION_OPTIONS', { countryCode: 'USA' });
};

const loadContactMarketability = async ({
    commit,
}, id) => {
    const contactId = id;

    return axios
        .get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${contactId}/emailAddressInfo`)
        .then(({ data }) => {
            commit('LOAD_CONTACT_MARKETABILITY_SUCCESS', data);
        })
        .catch((error) => {
            sentry.log('Error fetching email marketability', error);
            commit('LOAD_CONTACT_MARKETABILITY_ERROR');
        });
};

const cleanRegionCodes = (address, regions) => {
    const clonedAddress = cloneDeep(address);

    if (address?.region && address?.region?.length !== 0 && address?.region?.length !== 5) {
        const cleanRegion = Object.keys(regions).find((item) => item.match(clonedAddress.region));

        if (cleanRegion) {
            clonedAddress.region = cleanRegion;
        }
    }

    return clonedAddress;
};

const getContactCountryCodes = (billingAddress, otherAddress, shippingAddress) => {
    return {
        billingAddressCountryCode: billingAddress != null ? billingAddress.countryCode : null,
        otherAddressCountryCode: otherAddress != null ? otherAddress.countryCode : null,
        shippingAddressCountryCode: shippingAddress != null ? shippingAddress.countryCode : null,
    };
};

const getCountryAndStateOptions = async (countryCodes) => {
    const { billingAddressCountryCode, otherAddressCountryCode, shippingAddressCountryCode } = countryCodes;

    const stateAndCountryOptionsParams = `?billingAddressCountryCode=${billingAddressCountryCode}`
    + `&shippingAddressCountryCode=${shippingAddressCountryCode}`
    + `&otherAddressCountryCode=${otherAddressCountryCode}`;

    const { data } = await axios
        .get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordDetails/stateAndCountryOptions${stateAndCountryOptionsParams}`);

    return data;
};

const saveContactDetails = ({ state, commit, dispatch }, { contactDetails, fieldValues }) => {
    const fieldValuesExist = Boolean(fieldValues && fieldValues.fieldIdValues.length > 0);
    let url;
    let payload;

    if (fieldValuesExist) {
        url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/${state.contact.id}/contactdetails`;
        payload = fieldValues;
    } else {
        url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/${state.contact.id}`;
        payload = contactDetails;
    }

    return new Promise((resolve, reject) => {
        return axios.put(url, payload)
            .then(async () => {
                commit('UPDATE_CONTACT_INFO', contactDetails);
                commit('UPDATE_CONTACT_IN_LIST', state.contact);
                await dispatch('LOAD_CONTACT_MARKETABILITY', state.contact.id);
                resolve(contactDetails);
            })
            .catch(reject);
    });
};

const loadCustomRegionOptions = ({ state, commit }, { countryCode }) => {
    return new Promise((resolve, reject) => {
        const options = state.cachedRegionOptions[countryCode];

        if (options) {
            commit('SET_CUSTOM_REGION_OPTIONS', { options, countryCode });

            return resolve();
        }

        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/localization/${countryCode}/states`)
            .then(({ data }) => {
                commit('SET_CUSTOM_REGION_OPTIONS', { options: data, countryCode });
                resolve();
            })
            .catch(reject);
    });
};

const loadRegionOptions = ({ state, commit }, { countryCode, addressType }) => {
    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
        if (countryCode && state.cachedRegionOptions[countryCode]) {
            const data = state.cachedRegionOptions[countryCode];

            commit('SET_REGION_OPTIONS', { addressType, options: data, countryCode });

            return resolve();
        }

        if (countryCode) {
            return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/localization/${countryCode}/states`)
                .then(({ data }) => {
                    commit('SET_REGION_OPTIONS', { addressType, options: data, countryCode });
                    resolve();
                })
                .catch(reject);
        }
    });
};

const addContact = async ({ dispatch }, contact) => {
    const { optInReason, ...newContact } = contact;
    let mutation;

    const variables = {
        contact: newContact,
        errorOnDuplicate: true,
        checkForDuplicateBy: 'email',
    };

    if (newContact.email) {
        variables.optIn = {
            email: newContact.email,
            optInReason,
        };

        mutation = gql`
            mutation addContact($contact: ContactInput, $checkForDuplicateBy: String, $optIn: EmailOptInEmailRequestInput!) {
                addContact(contact: $contact, checkForDuplicateBy: $checkForDuplicateBy) {
                    id,
                    email,
                    givenName,
                    familyName,
                    phone {
                        value
                        type
                    }
                    company,
                    jobTitle,
                    website,
                    contactType,
                    socialAccounts {
                        name
                        type
                    },
                }
                optInEmail(optIn: $optIn)
            }
        `;
    } else {
        mutation = gql`
            mutation addContact($contact: ContactInput, $checkForDuplicateBy: String) {
                addContact(contact: $contact, checkForDuplicateBy: $checkForDuplicateBy) {
                    id,
                    email,
                    givenName,
                    familyName,
                    phone {
                        value
                        type
                    }
                    company,
                    jobTitle,
                    website,
                    contactType,
                    socialAccounts {
                        name
                        type
                    },
                }
            }
        `;
    }

    try {
        const { data } = await Vue.prototype.$graphql.mutate({
            mutation,
            variables,
            fetchPolicy: 'no-cache',
        });

        dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'addContact', { root: true });
        dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'addContactHasContacts', { root: true });
        dispatch('dashboard/LOAD_CONTACT_TOTAL', {}, { root: true });

        intercom.logEvent(intercom.events.CONTACT_ADDED);

        return data.addContact;
    } catch (error) {
        if (
            error.graphQLErrors
            && Array.isArray(error.graphQLErrors)
            && error.graphQLErrors[0]
            && error.graphQLErrors[0].extensions
            && error.graphQLErrors[0].extensions.code === ERROR_CODE_DUPLICATE_EMAIL
        ) {
            const simplifiedError = new Error(error.graphQLErrors[0].message);

            simplifiedError.code = ERROR_CODE_DUPLICATE_EMAIL;
            simplifiedError.duplicateContactIds = [].concat(
                ...error.graphQLErrors
                    .map((graphQLerror) => graphQLerror.extensions.exception.duplicateContactIds),
            );

            throw simplifiedError;
        }

        throw error;
    }
};

const addAppointment = ({ commit }, appointment) => {
    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/appointments`, appointment)
            .then(({ data: { appointment: createdAppointment } }) => {
                commit('ADD_APPOINTMENT', createdAppointment);

                commit('ADD_ACTIVITY_FEED_ITEM', {
                    type: 'appointmentcreated',
                    details: {
                        title: createdAppointment.title,
                        location: createdAppointment.location,
                        startDate: createdAppointment.startDate,
                        endDate: createdAppointment.endDate,
                        eventAuthorId: createdAppointment.createdById,
                    },
                });

                resolve();
            })
            .catch(reject);
    });
};

const updateAppointment = ({ commit }, { appointmentId, updatedAppointment }) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/appointments/${appointmentId}`, updatedAppointment)
            .then(({ data: { appointment } }) => {
                commit('UPDATE_APPOINTMENT', appointment);

                commit('ADD_ACTIVITY_FEED_ITEM', {
                    type: 'appointmentupdated',
                    details: {
                        title: appointment.title,
                        location: appointment.location,
                        startDate: appointment.startDate,
                        endDate: appointment.endDate,
                        eventAuthorId: appointment.createdById,
                    },
                });

                resolve();
            })
            .catch(reject);
    });
};

const deleteAppointment = ({ commit }, id) => {
    commit('REMOVE_APPOINTMENT', id);

    return axios.delete(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/appointments/${id}`);
};

const sendEmail = async ({
    commit,
    rootState,
    rootState: { email: { attachments = [] } },
}, {
        body,
        buttonLink,
        buttonText,
        userId,
        fromAddress,
        recipient,
        signature,
        subject,
        to,
    }) => {
    const button = buttonLink && buttonText ? { buttonLink, buttonText } : null;
    const bardJson = bardJsonFromHtml({ button, html: body, signatureHtml: signature });
    const attachmentIds = attachments.map((attachment) => attachment.id);
    const renderer = createRenderer(bardJson);

    const payload = {
        attachmentIds,
        body: renderer.renderHtml(),
        userId,
        recipient,
        subject,
        to,
    };

    const { data: pendingEmailUuid } = await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v3/email`, payload);

    intercom.logEvent(intercom.events.EMAIL_SENT);

    if (rootState?.communicationTemplates?.currentEmailTemplate?.templateKind === 'REFERRAL') {
        intercom.logEvent(intercom.events.REQUEST_REFERRAL_EMAIL_SENT);

        commit('communicationTemplates/SET_CURRENT_EMAIL_TEMPLATE', {}, { root: true });
    }

    commit('ADD_ACTIVITY_FEED_ITEM', {
        type: 'emailSent',
        details: {
            subject,
            eventAuthorEmailAddress: fromAddress,
        },
    });

    commit('SET_EMAILS', [{
        ...payload,
        from: fromAddress,
        pendingEmailUuid,
        sentDate: new Date().toISOString(),
    }]);
};

const cancelEmail = (context, uuid) => {
    return new Promise((resolve, reject) => {
        return axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v2/email/cancel/${uuid}`, {})
            .then(({ data: successfullyCancelled }) => {
                resolve(successfullyCancelled);
            })
            .catch(reject);
    });
};

const loadEmails = async ({
    rootState,
    state,
    commit,
}, { withBody = false, limit, offset }) => {
    let emails;

    if (rootState.featureFlags[FF_FLAGSHIP_FS_27692_EMAIL_OPEN_STATUS]) {
        ({ emails } = await queryContactEmailHistoryList({
            contactId: state.contact.id,
            withBody,
            limit,
            offset,
        }));
    } else {
        const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${state.contact.id}/emails/noBody`;

        ({ data: emails } = await axios.get(`${url}?limit=${limit}&offset=${offset}`));
    }
    commit('SET_EMAILS', emails);

    return emails;
};

const loadEmailBody = ({ commit }, { emailId }) => {
    return new Promise((resolve, reject) => {
        const url = `${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/email/${emailId}/body`;

        return axios.get(url)
            .then(({ data }) => {
                commit('SET_EMAIL_BODY', { emailId, body: data });
                resolve(data);
            })
            .catch(reject);
    });
};

const loadStrategies = ({ state, commit }, contactId = null) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${contactId || state.contact.id}/strategies`)
            .then(({ data: response }) => {
                commit('SET_STRATEGIES', response.strategies);
                resolve();
            })
            .catch(reject);
    });
};

const loadMoreActivities = ({ state, commit }, { limit, cursor }) => {
    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contactRecordPage/${state.contact.id}/activityFeed?limit=${limit}&cursor=${cursor}`)
            .then(({ data: { activityFeedEvents, cursor: newCursor } }) => {
                const hasActivity = Boolean(activityFeedEvents.length);

                if (hasActivity) {
                    commit('ADD_ACTIVITIES', activityFeedEvents);
                    commit('SET_ACTIVITY_CURSOR', newCursor);
                }

                resolve(activityFeedEvents);
            })
            .catch(reject);
    });
};

const addToBroadcast = (context, {
    savedFilterId,
    ids,
    filterItems,
    checkAll,
}) => {
    return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/broadcast/list`, {
        savedFilterId,
        ids,
        filterItems,
        checkAll,
    });
};

const addToCampaignSequence = ({
    rootState,
    state,
    dispatch,
    commit,
}, {
    savedFilterId,
    ids,
    filterItems,
    checkAll,
    campaignId,
    sequenceId,
}) => {
    const { auth, campaigns } = rootState;

    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/campaigns/${campaignId}/sequences/${sequenceId}/recipients`, {
            savedFilterId,
            ids,
            filterItems,
            checkAll,
        }).then(({ data }) => {
            if (ids.length === 1 && ids.find((item) => item === state.contact.id)) {
                dispatch('LOAD_STRATEGIES');

                const campaign = campaigns.campaigns.find(({ id }) => campaignId === id);

                const singleAddSuccessful = Boolean(data.contactsSuccessful.find((contactId) => contactId === Number(state.contact.id)))
                    && data.contactsFailed.length === 0;

                if (campaign && singleAddSuccessful) {
                    commit('ADD_ACTIVITY_FEED_ITEM', {
                        type: 'manualaddcampaign',
                        details: {
                            title: campaign ? campaign.name : '',
                            eventAuthorId: auth.user.id,
                        },
                    });
                }
            }

            resolve({ contactsCount: data.contactsSuccessful.length });
        }).catch(reject);
    });
};

const removeFromCampaign = ({ rootState, dispatch, commit }, { contactId, campaignId }) => {
    const { auth, campaigns } = rootState;

    return new Promise((resolve, reject) => {
        return axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/campaigns/${campaignId}/contacts/${contactId}/remove`)
            .then(() => {
                dispatch('LOAD_STRATEGIES');

                const campaign = campaigns.campaigns.find(({ id }) => campaignId === id);

                if (campaign) {
                    commit('ADD_ACTIVITY_FEED_ITEM', {
                        type: 'manualremovecampaign',
                        details: {
                            title: campaign.name,
                            eventAuthorId: auth.user.id,
                        },
                    });
                }

                resolve();
            }).catch(reject);
    });
};

const searchContacts = ({ commit }, term) => {
    commit('SET_SEARCH_RESULTS');

    return new Promise((resolve, reject) => {
        return axios.get(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/search?term=${term}`)
            .then((response) => {
                commit('SET_SEARCH_RESULTS', response.data);
                resolve();
            })
            .catch(reject);
    });
};

const updateDeal = ({ commit }, deal) => {
    return new Promise((resolve) => {
        commit('UPDATE_DEAL', deal);
        resolve();
    });
};

const loadContactPipelines = async ({ commit, rootState }, contactId) => {
    try {
        const pipelineMigrationEnabled = rootState.featureFlags[FF_KEAP_PIPELINE_MIGRATION];

        const { data } = await Vue.prototype.$graphql.query({
            query: gql`
                query ${pipelineMigrationEnabled ? 'flattenedPipelinesV2' : 'flattenedPipelines'}($contactId: ID!) {
                    ${pipelineMigrationEnabled ? 'flattenedPipelinesV2' : 'flattenedPipelines'}(contactId: $contactId) {
                        id
                        stages {
                            id
                            name
                            order
                            pipelineId
                        }
                        deals {
                            id
                            name
                            value {
                                amount
                                currency
                            }
                            contacts {
                                id
                                primaryContact
                            }
                            stage {
                                id
                                name
                                order
                                pipelineId
                            }
                            stageAssignmentDate
                            owners {
                                id
                            }
                            ownerId
                            order
                            status
                            estimatedCloseDate
                            closedDate
                        }
                    }
                }
            `,
            variables: { contactId },
            fetchPolicy: 'no-cache',
        });

        commit('SET_CONTACT_PIPELINES', pipelineMigrationEnabled ? data.flattenedPipelinesV2 : data.flattenedPipelines);
    } catch (e) {
        sentry.log('Updating contact pipelines failed');

        throw e;
    }
};
