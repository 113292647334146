<template>
    <div class="book-appointment-success" data-qa="book-appointment-success">
        <div class="thumbs-image">
            <svg>
                <use xlink:href="#thumbs" />
            </svg>
        </div>

        <h3 class="success-message">
            {{ $t('successMessage1') }}
        </h3>

        <h5 class="success-message">
            {{ $t('successMessage2', { appointmentSummary }) }}
        </h5>

        <ds-filled-button @click="close">
            {{ $t('ok') }}
        </ds-filled-button>


        <ds-text-button
            data-qa="view-appointments"
            @click="viewAppointments"
        >
            {{ $t('viewAppointments') }}
        </ds-text-button>
    </div>
</template>

<script>
import '@/appointments/images/thumbs.svg';

import { mapGetters } from 'vuex';

export default {
    props: {
        appointmentSummary: {
            type: String,
            default: 'appointment',
        },
        contact: Object,
    },

    data() {
        return {
            nestedModal_title: this.$t('success'),
            nestedModal_showClose: true,
        };
    },

    computed: {
        ...mapGetters({
            userName: 'auth/getUserFirstName',
            isContactPage: 'contacts/isContactPage',
        }),
    },

    methods: {
        close() {
            this.$bus.$emit('POP_ALL_NESTED_MODALS');
        },

        viewAppointments() {
            this.$bus.$emit('POP_ALL_NESTED_MODALS');

            if (this.isContactPage) {
                this.$bus.$emit('VIEW_CONTACT', {
                    id: this.contact.id,
                    tab: 'appointments',
                    source: 'Book appointment success',
                });
            } else {
                this.$router.push({ name: 'calendar' });
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" type="text/scss" scoped>
    .book-appointment-success {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .success-message,
    .thumbs-image {
        margin-bottom: $gp * 1.5;
    }

    button {
        margin-bottom: $gp / 2;
    }
</style>

<i18n>
{
    "en-us": {
        "success": "Success",
        "successMessage1": "Appointment Booked!",
        "successMessage2": "Your {appointmentSummary} has been scheduled.",
        "viewAppointments": "See it on your calendar",
        "ok": "Ok, got it"
    }
}
</i18n>
