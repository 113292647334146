import moment from 'moment';

export default {
    appts: [], // stores processed/expanded appt data after rrule parsing
    apptsRaw: [], // stores raw appt data retrieved from API
    apptsLoaded: false,
    apptsRange: {},
    appointmentFilter: {
        appointmentViewOption: 'all',
        visibleCalendars: [],
    },
    providers: [
        {
            name: 'GOOGLE',
            provider: 'Google',
            status: false,
            email: null,
            isLoaded: false,
            integrationId: null,
            calendars: [],
        },
        {
            name: 'MICROSOFT',
            provider: 'Microsoft',
            status: false,
            email: null,
            isLoaded: false,
            integrationId: null,
            calendars: [],
        },
        {
            name: 'ZOOM',
            provider: 'Zoom',
            status: false,
        },
    ],
    selectedDate: moment().startOf('day').toDate(),
    apptTypes: [],
    apptTypesLimit: 100, // the maximum number of Types a user is allowed
    apptTypesLoaded: false,
    activeApptType: null,
    stateInitialized: false,
    wizardActiveStage: 0,
    tempApptType: null,
    connectRedirect: null,
    openAppointment: null,
    apptToDelete: {},
};
