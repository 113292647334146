import EmailStep from '@/automations/components/steps/EmailStep';
import TaskStep from '@/automations/components/steps/TaskStep';
import CreateDealStep from '@/automations/components/steps/CreateDealStep';
import ApplyTagStep from '@/automations/components/steps/ApplyTagStep';
import RemoveTagStep from '@/automations/components/steps/RemoveTagStep';
import SendNotificationStep from '@/automations/components/steps/SendNotificationStep';
import SendSmsStep from '@/automations/components/steps/SendSmsStep';
import HellosignSendDocumentStep from '@/automations/components/steps/HellosignSendDocumentStep';
import TagAppliedTrigger from '@/automations/components/triggers/TagAppliedTrigger';
import ProductPurchasedTrigger from '@/automations/components/triggers/ProductPurchasedTrigger';
import ShopifyProductPurchasedTrigger from '@/automations/components/triggers/ShopifyProductPurchasedTrigger';
import ShopifyAbandonedCartTrigger from '@/automations/components/triggers/ShopifyAbandonedCartTrigger';
import AppointmentScheduledTrigger from '@/automations/components/triggers/AppointmentScheduledTrigger';
import AppointmentCancelledTrigger from '@/automations/components/triggers/AppointmentCancelledTrigger';
import PublicFormSubmittedTrigger from '@/automations/components/triggers/PublicFormSubmittedTrigger';
import InternalFormSubmittedTrigger from '@/automations/components/triggers/InternalFormSubmittedTrigger';
import LandingPageFormSubmittedTrigger from '@/automations/components/triggers/LandingPageFormSubmittedTrigger';
import HellosignDocumentSignedTrigger from '@/automations/components/triggers/HellosignDocumentSignedTrigger';
import TypeformFormSubmittedTrigger from '@/automations/components/triggers/TypeformFormSubmittedTrigger';
import DealEnteredTrigger from '@/automations/components/triggers/DealEnteredTrigger';
import DealExitedTrigger from '@/automations/components/triggers/DealExitedTrigger';
import ConfigureEmailStep from '@/automations/components/configure/ConfigureEmailStep';
import ConfigureCreateDealStep from '@/automations/components/configure/ConfigureCreateDealStep';
import ConfigureTaskStep from '@/automations/components/configure/ConfigureTaskStep';
import ConfigureSendNotificationStep from '@/automations/components/configure/ConfigureSendNotificationStep';
import ConfigureSendSmsStep from '@/automations/components/configure/ConfigureSendSmsStep';
import ConfigureHellosignSendDocumentStep from '@/automations/components/configure/ConfigureHellosignSendDocumentStep';
import ConfigureLeadForm from '@/automations/components/configure/ConfigureLeadForm';
import ConfigureTagAppliedTrigger from '@/automations/components/configure/ConfigureTagAppliedTrigger';
import ConfigureTagStep from '@/automations/components/configure/ConfigureTagStep';
import ConfigureProductPurchasedTrigger from '@/automations/components/configure/ConfigureProductPurchasedTrigger';
import ConfigureAppointmentTrigger from '@/automations/components/configure/ConfigureAppointmentTrigger';
import ConfigureShopifyTrigger from '@/automations/components/configure/ConfigureShopifyTrigger';
import ConfigureInternalFormTrigger from '@/automations/components/configure/ConfigureInternalFormTrigger';
import ConfigureLandingPageFormTrigger from '@/automations/components/configure/ConfigureLandingPageFormTrigger';
import ConfigureMoneyTrigger from '@/automations/components/configure/ConfigureMoneyTrigger';
import ConfigureHellosignTrigger from '@/automations/components/configure/ConfigureHellosignTrigger';
import ConfigureDealMovedTrigger from '@/automations/components/configure/ConfigureDealMovedTrigger';
import ConfigureTypeformTrigger from '@/automations/components/configure/ConfigureTypeformTrigger';

import {
    CREATE_DEAL,
    CREATE_TASK,
    ANY_PUBLIC_FORM_SUBMITTED,
    PUBLIC_FORM_SUBMITTED,
    SEND_EMAIL,
    SEND_SMS,
    TAG_APPLIED,
    PRODUCT_PURCHASED,
    ANY_PRODUCT_PURCHASED,
    SHOPIFY_ANY_PRODUCT_PURCHASED,
    SHOPIFY_PRODUCT_PURCHASED,
    SHOPIFY_ANY_CART_ABANDONED,
    SHOPIFY_CART_ABANDONED,
    APPLY_TAG,
    REMOVE_TAG,
    SEND_NOTIFICATION,
    APPOINTMENT_SCHEDULED,
    USER_APPOINTMENT_SCHEDULED,
    ANY_APPOINTMENT_SCHEDULED,
    APPOINTMENT_CANCELLED,
    USER_APPOINTMENT_CANCELLED,
    ANY_APPOINTMENT_CANCELLED,
    INTERNAL_FORM_SUBMITTED,
    ANY_INTERNAL_FORM_SUBMITTED,
    LANDING_PAGE_FORM_SUBMITTED,
    ANY_LANDING_PAGE_FORM_SUBMITTED,
    ANY_INVOICE_SENT,
    ANY_INVOICE_PAID,
    ANY_QUOTE_ACCEPTED,
    ANY_QUOTE_SENT,
    ANY_HELLOSIGN_DOCUMENT_SIGNED,
    HELLOSIGN_DOCUMENT_SIGNED,
    HELLOSIGN_SEND_DOCUMENT,
    DEAL_ENTERED,
    DEAL_EXITED,
    TYPEFORM_FORM_SUBMITTED,
    TYPEFORM_ANY_FORM_SUBMITTED,
} from '@/automations/constants/automations.constants';

export const stepComponents = {
    [SEND_EMAIL]: EmailStep,
    [CREATE_DEAL]: CreateDealStep,
    [CREATE_TASK]: TaskStep,
    [APPLY_TAG]: ApplyTagStep,
    [REMOVE_TAG]: RemoveTagStep,
    [SEND_NOTIFICATION]: SendNotificationStep,
    [SEND_SMS]: SendSmsStep,
    [HELLOSIGN_SEND_DOCUMENT]: HellosignSendDocumentStep,
};

export const triggerComponents = {
    [ANY_PUBLIC_FORM_SUBMITTED]: PublicFormSubmittedTrigger,
    [PUBLIC_FORM_SUBMITTED]: PublicFormSubmittedTrigger,
    [TAG_APPLIED]: TagAppliedTrigger,
    [PRODUCT_PURCHASED]: ProductPurchasedTrigger,
    [ANY_PRODUCT_PURCHASED]: ProductPurchasedTrigger,
    [SHOPIFY_ANY_PRODUCT_PURCHASED]: ShopifyProductPurchasedTrigger,
    [SHOPIFY_PRODUCT_PURCHASED]: ShopifyProductPurchasedTrigger,
    [SHOPIFY_ANY_CART_ABANDONED]: ShopifyAbandonedCartTrigger,
    [SHOPIFY_CART_ABANDONED]: ShopifyAbandonedCartTrigger,
    [APPOINTMENT_SCHEDULED]: AppointmentScheduledTrigger,
    [USER_APPOINTMENT_SCHEDULED]: AppointmentScheduledTrigger,
    [ANY_APPOINTMENT_SCHEDULED]: AppointmentScheduledTrigger,
    [APPOINTMENT_CANCELLED]: AppointmentCancelledTrigger,
    [ANY_APPOINTMENT_CANCELLED]: AppointmentCancelledTrigger,
    [USER_APPOINTMENT_CANCELLED]: AppointmentCancelledTrigger,
    [ANY_HELLOSIGN_DOCUMENT_SIGNED]: HellosignDocumentSignedTrigger,
    [HELLOSIGN_DOCUMENT_SIGNED]: HellosignDocumentSignedTrigger,
    [TYPEFORM_ANY_FORM_SUBMITTED]: TypeformFormSubmittedTrigger,
    [TYPEFORM_FORM_SUBMITTED]: TypeformFormSubmittedTrigger,
    [INTERNAL_FORM_SUBMITTED]: InternalFormSubmittedTrigger,
    [ANY_INTERNAL_FORM_SUBMITTED]: InternalFormSubmittedTrigger,
    [LANDING_PAGE_FORM_SUBMITTED]: LandingPageFormSubmittedTrigger,
    [ANY_LANDING_PAGE_FORM_SUBMITTED]: LandingPageFormSubmittedTrigger,
    [DEAL_ENTERED]: DealEnteredTrigger,
    [DEAL_EXITED]: DealExitedTrigger,
    [ANY_INVOICE_SENT]: null,
    [ANY_INVOICE_PAID]: null,
    [ANY_QUOTE_ACCEPTED]: null,
    [ANY_QUOTE_SENT]: null,
};

export const configureComponents = {
    [CREATE_DEAL]: ConfigureCreateDealStep,
    [CREATE_TASK]: ConfigureTaskStep,
    [SEND_EMAIL]: ConfigureEmailStep,
    [APPLY_TAG]: ConfigureTagStep,
    [REMOVE_TAG]: ConfigureTagStep,
    [SEND_NOTIFICATION]: ConfigureSendNotificationStep,
    [SEND_SMS]: ConfigureSendSmsStep,
    [HELLOSIGN_SEND_DOCUMENT]: ConfigureHellosignSendDocumentStep,
    [ANY_PUBLIC_FORM_SUBMITTED]: ConfigureLeadForm,
    [PUBLIC_FORM_SUBMITTED]: ConfigureLeadForm,
    [TAG_APPLIED]: ConfigureTagAppliedTrigger,
    [SHOPIFY_ANY_PRODUCT_PURCHASED]: ConfigureShopifyTrigger,
    [SHOPIFY_PRODUCT_PURCHASED]: ConfigureShopifyTrigger,
    [SHOPIFY_ANY_CART_ABANDONED]: ConfigureShopifyTrigger,
    [SHOPIFY_CART_ABANDONED]: ConfigureShopifyTrigger,
    [ANY_PRODUCT_PURCHASED]: ConfigureProductPurchasedTrigger,
    [PRODUCT_PURCHASED]: ConfigureProductPurchasedTrigger,
    [ANY_APPOINTMENT_SCHEDULED]: ConfigureAppointmentTrigger,
    [USER_APPOINTMENT_SCHEDULED]: ConfigureAppointmentTrigger,
    [APPOINTMENT_SCHEDULED]: ConfigureAppointmentTrigger,
    [ANY_APPOINTMENT_CANCELLED]: ConfigureAppointmentTrigger,
    [USER_APPOINTMENT_CANCELLED]: ConfigureAppointmentTrigger,
    [APPOINTMENT_CANCELLED]: ConfigureAppointmentTrigger,
    [INTERNAL_FORM_SUBMITTED]: ConfigureInternalFormTrigger,
    [ANY_INTERNAL_FORM_SUBMITTED]: ConfigureInternalFormTrigger,
    [LANDING_PAGE_FORM_SUBMITTED]: ConfigureLandingPageFormTrigger,
    [ANY_LANDING_PAGE_FORM_SUBMITTED]: ConfigureLandingPageFormTrigger,
    [ANY_INVOICE_SENT]: ConfigureMoneyTrigger,
    [ANY_INVOICE_PAID]: ConfigureMoneyTrigger,
    [ANY_QUOTE_ACCEPTED]: ConfigureMoneyTrigger,
    [ANY_QUOTE_SENT]: ConfigureMoneyTrigger,
    [ANY_HELLOSIGN_DOCUMENT_SIGNED]: ConfigureHellosignTrigger,
    [HELLOSIGN_DOCUMENT_SIGNED]: ConfigureHellosignTrigger,
    [TYPEFORM_ANY_FORM_SUBMITTED]: ConfigureTypeformTrigger,
    [TYPEFORM_FORM_SUBMITTED]: ConfigureTypeformTrigger,
    [DEAL_ENTERED]: ConfigureDealMovedTrigger,
    [DEAL_EXITED]: ConfigureDealMovedTrigger,
};
