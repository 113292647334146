import Vue from 'vue';

export default {
    SET_SCHEDULE(state, { value, broadcastId }) {
        Vue.set(state.schedules, broadcastId, value);
    },

    SET_STATUS(state, { value, broadcastId }) {
        Vue.set(state.statuses, broadcastId, value);
    },

    SET_SEND_NOW_BROADCAST_ID(state, value) {
        state.sendNowBroadcastId = value;
    },
};
