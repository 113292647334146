<template>
    <div class="address-autocomplete">
        <ds-dropdown
            :is-open="isDropdownOpen"
            dropdown-block
            icons
            @close="isDropdownOpen = false"
        >
            <template #default>
                <div class="search-container" @click="toggleDropdown">
                    <ds-input-field
                        ref="input"
                        v-model.trim="localValue"
                        data-qa="address-autocomplete"
                        :class="{ 'auto-filled': autoFilled }"
                        :name="fieldName"
                        :label="$t(label)"
                        :submitted="submitted"
                        :required="required"
                        :autocomplete="autocompleteValue"
                        @input="autocompleteAddress"
                    >
                        <template #help>
                            <slot name="help">
                                <small v-if="isAddressRequired" class="help-text">
                                    {{ $t('addressHelpText' ) }}
                                </small>
                            </slot>
                        </template>

                        <template #error>
                            <small v-if="isAddressRequired" class="error-text">
                                {{ $t('errors.inPerson' ) }}
                            </small>
                        </template>
                    </ds-input-field>
                    <ds-spinner v-show="loading" class="loading-spinner" :size="24" />
                </div>
            </template>

            <template #menu>
                <div
                    v-if="hasPredictions"
                    :class="['menu', { 'pad-bottom': useGoogleApi }]"
                    data-qa="predictions-menu"
                >
                    <ul class="autocomplete-suggestions">
                        <li
                            v-for="{ description, id } in predictions"
                            :key="id"
                            class="dropdown-menu-item"
                            @click="getSuggestionDetail(id)"
                        >
                            <ds-icon name="location" />
                            {{ description }}
                        </li>
                    </ul>
                    <div v-if="useGoogleApi" class="google-credit">
                        <img :src="`${baseUrl}images/google/powered-by-google-on-white.png`" />
                    </div>
                </div>
            </template>
        </ds-dropdown>
    </div>
</template>

<script>
import clone from 'lodash.clone';
import debounce from 'lodash.debounce';

import { mapState } from 'vuex';
import { LOCATION_TYPES } from '@/appointments/appointments.constants';
import { AUTOCOMPLETE_DEBOUNCE_DELAY } from '@/shared/constants/timing.constants';
import { FF_KEAP_HERE_API_ENABLED } from '@/shared/constants/featureFlag.constants';

export default {
    props: {
        value: String,
        label: String,
        fieldName: String,
        autoFilled: Boolean,
        submitted: Boolean,
        required: Boolean,
        placeType: String,
        useSelectedAsValue: Boolean,
        autofocus: Boolean,
        searchOnLoad: Boolean,
        delay: {
            type: Number,
            default: AUTOCOMPLETE_DEBOUNCE_DELAY,
        },
        autocompleteValue: {
            type: [Boolean, String],
            default: false,
        },
        provider: {
            type: String,
            default: 'here',
            options: ['here', 'google'],
        },
    },

    data() {
        return {
            baseUrl: process.env.BASE_URL,
            predictions: [],
            localValue: null,
            loading: false,
            isDropdownOpen: false,
        };
    },

    created() {
        this.localValue = clone(this.value);

        if (this.delay > 0) {
            this.autocomplete = debounce(this.autocomplete, this.delay);
        }
    },

    mounted() {
        setTimeout(() => {
            if (this.autofocus && this.$refs.input) {
                this.$refs.input.input_focus();
            }

            if (this.localValue && this.searchOnLoad) {
                this.autocomplete(this.localValue, false);
            }
        });
    },

    watch: {
        localValue: {
            handler(value) {
                if (value !== this.value) {
                    this.$emit('input', value);
                }
            },
        },

        value: {
            handler(value) {
                this.localValue = value;
            },
        },
    },

    computed: {
        ...mapState({
            locationType: ({ booking }) => booking.apptData.locationType,
            hereApiEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_HERE_API_ENABLED],
        }),

        useGoogleApi() {
            return !this.hereApiEnabled || this.provider === 'google';
        },

        hasPredictions() {
            return this.predictions.length > 0;
        },

        isAddressRequired() {
            return this.locationType === LOCATION_TYPES.CLIENT_SET_IN_PERSON;
        },
    },

    methods: {
        toggleDropdown() {
            this.isDropdownOpen = this.isDropdownOpen ? false : this.hasPredictions;
        },

        autocompleteAddress(input, autoselect) {
            this.loading = true;

            if (input.length > 0) {
                this.autocomplete(input, autoselect);
            } else {
                this.loading = false;
                this.predictions = [];
            }
        },

        async autocomplete(input, autoselect) {
            const { placeType, useGoogleApi } = this;
            const errorMessage = this.$t('errors.searching');

            try {
                const results = await this.$store.dispatch('fields/LOAD_ADDRESS_AUTOCOMPLETE', { input, placeType, useGoogleApi });

                this.predictions = results;

                if (results.length > 0) {
                    if (this.predictions.length > 0 && autoselect) {
                        this.getSuggestionDetail(this.predictions[0].id);
                    }

                    if (!autoselect) {
                        this.isDropdownOpen = true;
                    }
                }
            } catch (e) {
                this.$error({ message: errorMessage });
            }

            this.loading = false;
        },

        async getSuggestionDetail(id) {
            const { useGoogleApi } = this;

            try {
                const details = await this.$store.dispatch('fields/LOAD_PLACE_DETAIL', { id, useGoogleApi });

                if (this.useSelectedAsValue) {
                    this.localValue = details.description;
                }

                this.$bus.$emit('AUTOFILL_CONTACT_ADDRESS', {
                    addressObject: details,
                    addressFields: this.getAddressFields(this.fieldName),
                });

                this.$emit('select', details);
                this.toggleDropdown();
            } catch (e) {
                // swallow error
            }
        },

        getAddressFields(fieldName) {
            if (!fieldName) {
                return null;
            }

            const fieldNumber = fieldName === 'STREET_ADDRESS2'
                ? 1
                : Number(fieldName.split('ADDRESS')[1].substr(0, 1));

            const ADDRESS1 = fieldNumber === 1
                ? 'STREET_ADDRESS1'
                : `ADDRESS${fieldNumber}_STREET1`;

            const ADDRESS2 = fieldNumber === 1
                ? 'STREET_ADDRESS2'
                : `ADDRESS${fieldNumber}_STREET1`;

            const fieldNumberString = fieldNumber === 1 ? '' : fieldNumber;

            return [
                ADDRESS1,
                ADDRESS2,
                `CITY${fieldNumberString}`,
                `STATE${fieldNumberString}`,
                `COUNTRY${fieldNumberString}`,
                `POSTAL_CODE${fieldNumberString}`,
            ];
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@infusionsoft/design-system/src/styles/common";

    $googleCreditHeight: px-to-rem(34px);

    .address-autocomplete {
        @include input-field;
    }

    .menu {
        @include text-align-start;

        &.pad-bottom {
            padding-bottom: $googleCreditHeight;
        }
    }

    .autocomplete-suggestions {
        @include overflowY;
        position: relative;
        max-height: px-to-rem(200px);
    }

    .search-container {
        position: relative;
    }

    .loading-spinner {
        @include position(absolute $gp / 2 $gp / 2 0 auto);
    }

    .google-credit {
        position: fixed;
        bottom: 0;
        background: $color-paper;
        width: 100%;
        height: $googleCreditHeight;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: px-to-rem(14px);
        }
    }

    .help-text {
        color: $color-gray-700;
    }

    .error-text {
        color: $color-red-800;
    }
</style>

<i18n>
{
    "en-us": {
        "errors": {
            "inPerson": "Address or location is required",
            "searching": "There was an error finding this business. Try again."
        },
        "addressHelpText": "Select an address or location"
    }
}
</i18n>
