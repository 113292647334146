<template>
    <section v-if="loaded" class="timing-step">
        <p class="subtitle step-name">
            <slot />
        </p>

        <ds-button-toggle
            v-model="timing"
            :options="timingOptions"
            prevent-single-deselect
            class="timing-toggle"
            @input="handleTimingUpdate"
        />

        <div v-if="showTimingDelay" class="delay-options">
            <p class="base-subtitle subtitle">
                {{ $t('baseDelay') }}
            </p>

            <ds-select-field
                v-model="delayType"
                class="delay-type-input"
                name="delayType"
                bind-value-only
                data-qa="delay-type"
                :options="delayTypeOptions"
                :label="$t('delayType.label')"
                @input="handleUpdateDelayType"
            >
                <template #help>
                    <div v-if="timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY" data-qa="delay-help-text">
                        {{ $t('delayTypeMessage' ) }}
                    </div>
                </template>
            </ds-select-field>

            <div class="timing-step-delay">
                <div v-if="showOffset" class="offset">
                    <p class="offset-label">
                        {{ $t(!isEventType ? 'delayType.delayDuration' : 'delayType.thenSend') }}
                    </p>

                    <div class="offset-options">
                        <ds-input-field
                            v-if="!disableOffset"
                            v-model="count"
                            data-qa="timing-count"
                            type="text"
                            class="offset-count"
                            @input="handleUpdate"
                            @blur="setDefaultCount"
                        />

                        <ds-select-field
                            v-if="!disableOffset"
                            v-model="unit"
                            data-qa="timing-unit"
                            :class="['offset-select', { 'event-type': isEventType }]"
                            :options="timingUnits"
                            bind-value-only
                            @input="handleUpdate"
                        />

                        <ds-select-field
                            v-if="isEventType"
                            v-model="offsetType"
                            :options="offsetTypeOptions"
                            class="offset-toggle"
                            bind-value-only
                            @input="handleUpdateOffset"
                        />
                    </div>

                    <p v-if="isEventType" class="offset-event-label">
                        {{ eventDelayLabel }}
                    </p>
                </div>

                <div v-if="isExactDateType" class="offset">
                    <p class="offset-label">
                        {{ $t('delayType.sendOn') }}
                    </p>

                    <ds-date-picker
                        v-if="isExactDateType"
                        v-model="exactDate"
                        class="exact-date offset-select"
                        name="exactDatePicker"
                        :label="$t('exactDate')"
                        date-only
                        disable-before-date="today"
                        :force-invalid="showExactDateError"
                        @input="handleUpdate"
                        @close="setDefaultExactTimer"
                    >
                        <template v-if="showExactDateError" #error>
                            {{ $t('pastDateError') }}
                        </template>
                    </ds-date-picker>
                </div>

                <div v-if="!isAppointmentEvent" class="time-options-container">
                    <ds-checkbox
                        v-if="!isExactDateType"
                        v-model="exactTime"
                        :label="showTimingDelay ? $t('range.delayLabel') : $t('range.label')"
                        class="checkbox-container"
                        @input="handleUpdateTime"
                    />

                    <div v-if="exactTime || isExactDateType" class="time-options">
                        <ds-button-toggle
                            v-if="!isExactDateType"
                            v-model="rangeOption"
                            data-qa="range-toggle"
                            class="range-toggle"
                            :options="rangeOptions"
                            prevent-single-deselect
                            @input="handleUpdateRange"
                        />

                        <div class="range-options">
                            <ds-date-picker
                                v-model="startTime"
                                :label="$t('range.timeLabel')"
                                class="start-time"
                                time-only
                                required
                                :force-invalid="showExactTimeError"
                                @input="handleUpdate"
                            >
                                <template v-if="showExactTimeError" #error>
                                    {{ $t('pastTimeError') }}
                                </template>
                            </ds-date-picker>

                            <template v-if="showBetweenOptions">
                                <span class="range-and">{{ $t('range.and') }}</span>

                                <ds-date-picker
                                    v-model="endTime"
                                    :label="$t('range.timeLabel')"
                                    class="end-time"
                                    time-only
                                    required
                                    @input="handleUpdate"
                                />
                            </template>
                        </div>

                        <ds-inline-alert
                            v-if="showBetweenOptions"
                            class="between-help-text"
                            leading-icon
                            type="info"
                        >
                            {{ betweenHelpText }}
                        </ds-inline-alert>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="showReorderMessage" class="reorder-message">
            <div class="details">
                <ds-icon name="alert-octagon" />

                <p class="message">
                    {{ $t('reorder.message') }}
                </p>
            </div>

            <ds-text-button
                class="dismiss-button"
                @click="dismissReorderMessage"
            >
                {{ $t('global.dismiss') }}
            </ds-text-button>
        </div>
    </section>
</template>

<script>
import moment from 'moment';
import momentTz from 'moment-timezone';
import amplitude from '@/analytics/amplitude';
import { mapGetters, mapState } from 'vuex';
import { DELAY_TIMER, RELATIVE_TIMER, TIMING_UNIT } from '@/automations/constants/automations.constants';
import {
    AUTOMATION_TIMER_TYPES, EVENT_TYPES, RANGE_OPTIONS, OFFSET_TYPES,
} from '@/automations/constants/automations.timer.constants';
import { DATE_FORMAT, TIME_FORMAT, DISPLAY_DATE_FORMAT_WITH_YEAR } from '@/shared/constants/dateFormats.constants';

const DELAY_TYPES = {
    DELAY: 'delay',
    EXACT: 'exact',
    EVENT: 'event',
};

const TIMING_TOGGLE = {
    IMMEDIATELY: 'immediately',
    DELAYED: 'delayed',
};

const DEFAULT_UNIT = TIMING_UNIT.DAYS;
const DEFAULT_COUNT = 1;
const DEFAULT_START_TIME = '0800';
const DEFAULT_END_TIME = '2000';
const DEFAULT_START_DATE = moment().add(1, 'day').toDate();

export default {
    props: {
        delayStep: Object,
        stepListIndex: Number,
    },

    data() {
        return {
            loaded: false,
            delayTypeOptions: [],
            timing: TIMING_TOGGLE.IMMEDIATELY,
            delayType: DELAY_TYPES.DELAY,
            eventType: EVENT_TYPES.APPOINTMENT,
            exactDate: DEFAULT_START_DATE,
            offsetType: OFFSET_TYPES.BEFORE,

            count: DEFAULT_COUNT,
            unit: DEFAULT_UNIT,

            exactTime: Boolean(this.delayStep?.configJson?.startTime),
            rangeOption: this.delayStep?.configJson?.endTime ? RANGE_OPTIONS.BETWEEN : RANGE_OPTIONS.AT,
            startTime: this.delayStep?.configJson?.startTime,
            endTime: this.delayStep?.configJson?.endTime,

            timerTypeAllowed: '',
            AUTOMATION_TIMER_TYPES,
            showReorderMessage: false,
        };
    },

    mounted() {
        this.setDelayTypeOptions();
        this.setTimingOptions(this.delayStep);

        this.$store.dispatch('settings/LOAD_LOCALIZATION_INFO');

        this.loaded = true;

        this.$bus.$on('AUTOMATION_STEPS_REORDERED', this.toggleReorderMessage);
    },

    beforeDestroy() {
        this.$bus.$off('AUTOMATION_STEPS_REORDERED', this.toggleReorderMessage);
    },

    watch: {
        delayStep: {
            handler(newVal) {
                this.setTimingOptions(newVal);
            },
            deep: true,
        },
    },

    computed: {
        ...mapGetters({
            automationTimerType: 'automations/automationTimerType',
            hasAppointmentTrigger: 'automations/hasAppointmentTrigger',
            hasInvoiceTrigger: 'automations/hasInvoiceTrigger',
        }),

        ...mapState({
            appTimeZone: ({ settings }) => settings.localizationInfo?.appTimeZone,
        }),

        showTimingDelay() {
            return this.timing === TIMING_TOGGLE.DELAYED;
        },

        showOffset() {
            return this.delayType === DELAY_TYPES.DELAY || this.delayType === DELAY_TYPES.EVENT;
        },

        isEventType() {
            return this.delayType === DELAY_TYPES.EVENT;
        },

        isExactDateType() {
            return this.delayType === DELAY_TYPES.EXACT;
        },

        showExactDateError() {
            return moment(this.exactDate, DATE_FORMAT).isBefore(moment().format(DATE_FORMAT));
        },

        showExactTimeError() {
            return moment(this.exactDate, DATE_FORMAT).isSame(moment().format(DATE_FORMAT))
                && !this.endTime && momentTz.tz(this.startTime, TIME_FORMAT, this.appTimeZone).isBefore(momentTz.tz(this.appTimeZone));
        },

        betweenHelpText() {
            return this.$t('betweenHelp', {
                startTime: moment(this.startTime, TIME_FORMAT).format(TIME_FORMAT),
                endTime: moment(this.endTime, TIME_FORMAT).format(TIME_FORMAT),
            });
        },

        showBetweenOptions() {
            return this.rangeOption === RANGE_OPTIONS.BETWEEN;
        },

        disableDelayTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.DELAY
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableExactTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.EXACT
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableEventTimer() {
            return this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.APPOINTMENT
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.INVOICE_DUE
                && this.timerTypeAllowed !== AUTOMATION_TIMER_TYPES.ANY;
        },

        disableOffset() {
            return this.delayType === DELAY_TYPES.EVENT && this.offsetType === OFFSET_TYPES.NONE;
        },

        isAppointmentEvent() {
            return this.delayType === DELAY_TYPES.EVENT && this.eventType === EVENT_TYPES.APPOINTMENT;
        },

        isInvoiceEvent() {
            return this.delayType === DELAY_TYPES.EVENT && this.eventType === EVENT_TYPES.INVOICE_DUE;
        },

        timingUnits() {
            const shortUnits = [
                { label: this.$tc('minutes', this.count !== 1), value: TIMING_UNIT.MINUTES },
                { label: this.$tc('hours', this.count !== 1), value: TIMING_UNIT.HOURS },
            ];

            const longUnits = [
                { label: this.$tc('days', this.count !== 1), value: TIMING_UNIT.DAYS },
                { label: this.$tc('weeks', this.count !== 1), value: TIMING_UNIT.WEEKS },
                { label: this.$tc('months', this.count !== 1), value: TIMING_UNIT.MONTHS },
            ];

            return this.isInvoiceEvent ? longUnits : [...shortUnits, ...longUnits];
        },

        timingOptions() {
            return [
                { label: this.$t('timing.immediately'), value: TIMING_TOGGLE.IMMEDIATELY },
                { label: this.$t('timing.delayed'), value: TIMING_TOGGLE.DELAYED },
            ];
        },

        offsetTypeOptions() {
            const noneLabel = this.isAppointmentEvent ? 'offsetType.none' : 'offsetType.noneDay';

            return [
                { label: this.$t('offsetType.before'), value: OFFSET_TYPES.BEFORE },
                { label: this.$t('offsetType.after'), value: OFFSET_TYPES.AFTER },
                { label: this.$t(noneLabel), value: OFFSET_TYPES.NONE },
            ];
        },

        rangeOptions() {
            return [
                { label: this.$t('range.at'), value: RANGE_OPTIONS.AT },
                { label: this.$t('range.between'), value: RANGE_OPTIONS.BETWEEN },
            ];
        },

        timerStep() {
            let delayStep = null;

            if (this.showTimingDelay) {
                const {
                    delayType, offsetType, exactDate, count, unit, startTime, endTime,
                } = this;

                let configJson = {};

                if (delayType === DELAY_TYPES.DELAY || (delayType === DELAY_TYPES.EVENT && !this.disableOffset)) {
                    configJson = {
                        count: count || DEFAULT_COUNT,
                        unit: unit || DEFAULT_UNIT,
                    };
                }

                if (delayType === DELAY_TYPES.EXACT) {
                    configJson = {
                        ...configJson,
                        eventType: EVENT_TYPES.DATE,
                        exactDate: exactDate ? moment(exactDate).format(DATE_FORMAT) : '',
                        startTime,
                    };
                }

                if (delayType === DELAY_TYPES.EVENT) {
                    configJson = {
                        ...configJson,
                        eventType: this.hasAppointmentTrigger ? EVENT_TYPES.APPOINTMENT : EVENT_TYPES.INVOICE_DUE,
                        offsetType: delayType === DELAY_TYPES.EVENT ? offsetType : OFFSET_TYPES.NONE,
                    };
                }

                if (this.exactTime && !this.isAppointmentEvent) {
                    configJson.startTime = startTime;

                    if (this.rangeOption === RANGE_OPTIONS.BETWEEN) {
                        configJson.endTime = endTime;
                    }
                }

                delayStep = {
                    type: delayType === DELAY_TYPES.DELAY ? DELAY_TIMER : RELATIVE_TIMER,
                    name: 'timer',
                    configJson,
                };
            }

            return delayStep;
        },

        eventDelayLabel() {
            let label = 'delayType.eventHappens';

            if (this.hasAppointmentTrigger) label = 'delayType.appointmentHappens';
            if (this.hasInvoiceTrigger) label = 'delayType.invoiceHappens';

            return this.$t(label);
        },
    },

    methods: {
        setTimingOptions(value) {
            if (value && value.type) {
                const { type, configJson } = value;

                this.timing = TIMING_TOGGLE.DELAYED;

                this.setDelayType(type, configJson);

                this.exactDate = configJson?.exactDate ?? DEFAULT_START_DATE;
                this.startTime = this.exactDate ? configJson?.startTime ?? DEFAULT_START_TIME : undefined;

                this.offsetType = configJson?.offsetType ?? OFFSET_TYPES.NONE;
                this.eventType = configJson?.eventType;

                if (this.disableOffset) {
                    this.count = 0;
                    this.unit = '';
                } else {
                    this.count = configJson?.count || DEFAULT_COUNT;
                    this.unit = configJson?.unit || DEFAULT_UNIT;
                }

                this.exactTime = Boolean(configJson?.startTime);

                if (this.exactTime) {
                    this.rangeOption = configJson.endTime ? RANGE_OPTIONS.BETWEEN : RANGE_OPTIONS.AT;
                    this.endTime = configJson.endTime;
                }
            } else {
                this.timing = TIMING_TOGGLE.IMMEDIATELY;
                this.showReorderMessage = false;
                this.setDefaultDelayType();
            }
        },

        setDelayTypeOptions() {
            // user can only have similar types of timers in an automation (either delay, exact, or event)
            this.timerTypeAllowed = this.automationTimerType(this.stepListIndex);

            this.$set(this, 'delayTypeOptions', [
                {
                    label: this.$t('delayType.delay'),
                    value: DELAY_TYPES.DELAY,
                    className: this.disableDelayTimer ? 'disabled' : '',
                },
                {
                    label: this.$t('delayType.exact', { date: moment('2034-07-03').format(DISPLAY_DATE_FORMAT_WITH_YEAR) }),
                    value: DELAY_TYPES.EXACT,
                    className: this.disableExactTimer ? 'disabled' : '',
                },
            ]);

            if (this.hasAppointmentTrigger || this.hasInvoiceTrigger) {
                let label = 'delayType.event';

                if (this.hasAppointmentTrigger) label = 'delayType.appointment';
                if (this.hasInvoiceTrigger) label = 'delayType.invoice';
                this.delayTypeOptions.push({
                    label: this.$t(label),
                    value: DELAY_TYPES.EVENT,
                    className: this.disableEventTimer ? 'disabled' : '',
                });
            }
        },

        setDelayType(type, configJson) {
            const eventType = configJson?.eventType;

            if (type === DELAY_TIMER && !this.disableDelayTimer) {
                this.delayType = DELAY_TYPES.DELAY;
            } else if (!this.disableExactTimer && eventType !== EVENT_TYPES.APPOINTMENT && eventType !== EVENT_TYPES.INVOICE_DUE) {
                this.delayType = DELAY_TYPES.EXACT;
            } else if (!this.disableEventTimer && eventType !== EVENT_TYPES.DATE) {
                this.delayType = DELAY_TYPES.EVENT;
            } else {
                this.delayType = DELAY_TYPES.DELAY;
            }
        },

        setDefaultDelayType() {
            if (!this.disableDelayTimer) {
                this.delayType = DELAY_TYPES.DELAY;
            } else if (!this.disableExactTimer) {
                this.delayType = DELAY_TYPES.EXACT;
            } else if (!this.disableEventTimer) {
                this.delayType = DELAY_TYPES.EVENT;
            } else {
                this.delayType = DELAY_TYPES.DELAY;
            }
        },

        setDefaultCount() {
            this.count = +this.count;

            if (!this.count || this.count < DEFAULT_COUNT) {
                this.count = DEFAULT_COUNT;
                this.handleUpdate();
            }
        },

        setDefaultExactTimer() {
            if (!this.exactDate) {
                this.exactDate = moment().format(DATE_FORMAT);
                this.handleUpdate();
            }
        },

        handleUpdate() {
            this.$emit('updated', this.timerStep);
        },

        handleTimingUpdate(val) {
            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                'Event Source': 'Configure Timing Step',
                'Wait type': val,
            });

            this.handleUpdate();
        },

        handleUpdateDelayType(delayType) {
            this.offsetType = delayType === DELAY_TYPES.EVENT
                ? OFFSET_TYPES.BEFORE
                : OFFSET_TYPES.NONE;

            if (delayType === DELAY_TYPES.DELAY) {
                this.count = this.count || DEFAULT_COUNT;
                this.unit = this.unit || DEFAULT_UNIT;
            }

            if (delayType === DELAY_TYPES.EXACT) {
                this.startTime = this.startTime || DEFAULT_START_TIME;

                if (this.endTime) {
                    this.endTime = null;
                }
            }

            // If switching to an invoice event trigger with a short wait unit,
            // then reset to default
            if (this.hasInvoiceTrigger && delayType === DELAY_TYPES.EVENT
                && (this.unit === TIMING_UNIT.MINUTES || this.unit === TIMING_UNIT.HOURS)) {
                this.unit = DEFAULT_UNIT;
            }

            amplitude.v2.logEvent(amplitude.v2.events.AUTOMATION_UPDATED, {
                'Event Source': 'Configure Timing Step',
                'Delay Type': delayType,
            });

            this.handleUpdate();
        },

        handleUpdateTime(checked) {
            if (checked && !this.startTime) {
                this.startTime = DEFAULT_START_TIME;
            }
            this.handleUpdate();
        },

        handleUpdateOffset(offset) {
            if (offset === OFFSET_TYPES.NONE) {
                this.count = 0;
                this.unit = '';
            } else {
                this.count = this.count === 0 ? DEFAULT_COUNT : this.count;
                this.unit = this.unit || DEFAULT_UNIT;
            }

            this.handleUpdate();
        },

        handleUpdateRange(selected) {
            if (selected === RANGE_OPTIONS.BETWEEN && !this.endTime) {
                this.endTime = DEFAULT_END_TIME;
            }
            this.handleUpdate();
        },

        toggleReorderMessage() {
            this.showReorderMessage = true;
        },

        dismissReorderMessage() {
            this.showReorderMessage = false;
        },
    },
};
</script>

<style lang="scss" scoped>
    .subtitle {
        font-weight: $font-weight-bold;
    }

    .base-subtitle {
        margin-top: $gp;
    }

    .step-name {
        display: inline-flex;
    }

    .timing-step {
        margin: $gp * 1.5;
    }

    .timing-step-delay {
        border-radius: $border-radius;
        background-color: $color-gray-lightest;
        padding: $gp;
        margin: $gp * 1.5 0;
    }

    .delay-options {
        max-width: px-to-rem(390px);
    }

    .offset-label {
        font-size: $font-size-sm;
        margin-bottom: $gp / 2;
    }

    .offset-event-label {
        font-size: $font-size-sm;
        margin-top: $gp / 2;
        margin-bottom: 0;
    }

    .offset {
        font-size: $font-size-med;
        --input-margin-bottom: 0;

        .offset-options {
            align-items: center;
            display: flex;

            .offset-count {
                flex: 1;
                @include margin-end($gp / 2);
            }

            .offset-select {
                flex: 1;

                &.event-type {
                    @include margin-end($gp / 2);
                    flex: 2;
                }
            }

            .offset-toggle {
                flex: 2;
            }
        }
    }

    .event-type-input {
        --input-margin-bottom: 0;
    }

    .time-options-container {
        --checkbox-padding: 0;
        margin-top: $gp;

        .checkbox-container {
            display: inline-flex;
        }
    }

    .range-toggle {
        margin: $gp 0 $gp * 1.5;
    }

    .range-options {
        --input-margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $gp;
    }

    .range-and {
        margin: 0 $gp;
    }

    .start-time,
    .end-time {
        flex: 1;
        max-width: px-to-rem(150);
    }

    .between-help-text {
        margin-top: $gp;
    }

    .reorder-message {
        padding: $gp;
        margin-top: $gp * 1.5;
        border-radius: $border-radius;
        border: 1px solid $color-gray-200;
        --icon-color: #{$color-orange-400};
        max-width: px-to-rem(390);

        .details {
            display: flex;
            align-items: center;
        }

        .message {
            @include margin-start($gp);
            margin-bottom: 0;
        }

        .dismiss-button {
            display: flex;
            margin-top: $gp / 2;
            margin-left: auto;
        }
    }

    @media($extra-small) {
        .timing-step {
            margin: $gp;
        }

        .timing-step-delay {
            padding: $gp;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "baseDelay": "Base delay on:",
        "minutes": "Minutes | Minute",
        "hours": "Hours | Hour",
        "days": "Days | Day",
        "weeks": "Weeks | Week",
        "months": "Months | Month",
        "timing": {
            "immediately": "Immediately",
            "delayed": "Delayed"
        },
        "delayType": {
            "label": "Delay type",
            "sendOn": "Send on:",
            "thenSend": "Then send:",
            "delayDuration": "Delay duration",
            "delay": "Duration of time (minutes, hours, days)",
            "exact": "Exact date (e.g. {date})",
            "appointment": "Appointment time",
            "invoice": "Invoice due date",
            "event": "Event time",
            "appointmentHappens": "scheduled appointment",
            "invoiceHappens": "invoice due date",
            "eventHappens": "the time of the event"
        },
        "eventType": {
            "label": "Choose an event",
            "appointment": "Appointment",
            "invoiceDue": "Invoice due date"
        },
        "offsetType": {
            "before": "Before",
            "none": "At the time of",
            "noneDay": "The day of",
            "after": "After"
        },
        "exactDate": "Choose a date",
        "range": {
            "label": "At specific time",
            "delayLabel": "At specific time",
            "at": "At",
            "between": "Between",
            "and": "and",
            "timeLabel": "Time"
        },
        "delayTypeMessage": "Only related timer types available in a single automation.",
        "reorder": {
            "message": "This automation's steps have been reordered to fit your delay settings."
        },
        "pastDateError": "Date cannot be in the past.",
        "pastTimeError": "Time cannot be in the past.",
        "betweenHelp": "Your automation will only occur between these hours. Anything scheduled after {endTime} will be delayed until {startTime} the next day."
    }
}
</i18n>
