<template>
    <contact-links-view
        v-if="!editing"
        :contact="contact"
    />

    <contact-links-edit
        v-else
        :contact="contact"
        :submitted="submitted"
        @input="$emit('input')"
    />
</template>

<script>
import ContactLinksView from '@/contacts/components/simple/ContactLinksView';
import ContactLinksEdit from '@/contacts/components/simple/ContactLinksEdit';

export default {
    components: {
        ContactLinksView,
        ContactLinksEdit,
    },

    props: {
        contact: Object,
        editing: Boolean,
        submitted: Boolean,
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import "~@/contacts/styles/contact-details";
</style>
