<template>
    <controlled-modal
        data-qa="sms-broadcast-setup-modal"
        class="sms-broadcast-setup-modal"
        :is-open="isOpen"
        size="med"
        show-close
        @close="close"
    >
        <template #title>
            <span class="modal-title">
                {{ $t('title') }}
            </span>
        </template>

        <div class="content">
            <section class="intro">
                <ds-illustration class="illustration" name="phone-number-ready-color" />
                <i18n tag="span" path="header" class="header" />
                <i18n tag="span" path="description" class="description" />
                <i18n tag="span" path="available" class="available" />
            </section>

            <section class="terms">
                <p class="terms-description">
                    {{ $t('termsDescription') }}
                </p>

                <ds-checkbox
                    v-model="agreeToTerms"
                    class="terms-checkbox"
                >
                    <i18n tag="span" path="agreeToTerms">
                        <template #termsAndConditions>
                            <a
                                :href="termsAndConditionsLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                @click.stop
                            >
                                {{ $t('termsAndConditions') }}
                            </a>
                        </template>
                    </i18n>
                </ds-checkbox>

                <ds-checkbox
                    v-model="agreeToPrivacy"
                    class="privacy-checkbox"
                >
                    <i18n tag="span" path="agreeToPrivacy">
                        <template #privacyPolicy>
                            <a
                                :href="privacyPolicyLink"
                                target="_blank"
                                rel="noopener noreferrer"
                                @click.stop
                            >
                                {{ $t('privacyPolicy') }}
                            </a>
                        </template>
                    </i18n>
                </ds-checkbox>

                <ds-checkbox
                    v-model="agreeToPricing"
                    class="pricing-checkbox"
                >
                    <i18n tag="span" path="pricingTerms" class="pricing-terms">
                        <template #bold>
                            <strong>
                                {{ $t('bold') }}
                            </strong>
                        </template>
                    </i18n>
                </ds-checkbox>

                <div class="action-row">
                    <ds-filled-button
                        class="action-button"
                        :loading="loading"
                        @click="submit"
                    >
                        {{ $t('getMyNumber') }}
                    </ds-filled-button>

                    <ds-status v-if="showError" class="error-status" type="error">
                        {{ $t('errorMessage') }}
                    </ds-status>
                </div>
            </section>
        </div>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';
import { trackSmsAccountProvisioningComplete } from '@/broadcasts/smsAnalytics';

export default {
    components: { ControlledModal },

    props: {
        isOpen: Boolean,
        eventSource: {
            type: String,
            default: 'Broadcasts',
        },
    },

    data() {
        return {
            agreeToPrivacy: false,
            agreeToTerms: false,
            agreeToPricing: false,
            loading: false,
            privacyPolicyLink: 'https://keap.com/legal/privacy-policy',
            showError: false,
            termsAndConditionsLink: '/files/sms-broadcast-tos.pdf',
        };
    },

    watch: {
        hasError(value) {
            if (!value) {
                this.showError = false;
            }
        },
    },

    computed: {
        hasError() {
            return !this.agreeToPrivacy || !this.agreeToTerms || !this.agreeToPricing;
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },

        async submit() {
            if (this.hasError) {
                this.showError = true;
            } else {
                this.loading = true;

                try {
                    await this.$store.dispatch('automatedSms/CREATE_AUTOMATED_SMS_ACCOUNT');

                    this.$emit('complete');
                    trackSmsAccountProvisioningComplete(this.eventSource);
                    this.close();
                } catch (error) {
                    this.$toast({ message: this.$t('accountCreationError') });
                }

                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.sms-broadcast-setup-modal {
    --modal-padding: 0;
}

.modal-title {
    font-size: $font-size-lg;
    font-weight: 600;
}

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $gp * 2 $gp * 2;
}

.terms {
    display: flex;
    flex-direction: column;
    padding: $gp * 2;
    background-color: $color-orange-050;
    border-radius: $border-radius;
}

.illustration {
    align-self: center;
    margin-bottom: $gp * 2;
}

.header {
    line-height: $line-height-xl;
    text-align: center;
    font-size: $font-size-huge;
    font-weight: 500;
    padding: 0 $gp * 3;
}

.error-message {
    margin-bottom: $gp / 2;
}

section {
    display: flex;
    flex-direction: column;
    width: 100%;
}

h2 {
    text-align: center;
    font-family: $font-family-secondary-title;
    font-weight: $font-weight-bold;
    padding-bottom: $gp;
}

.description {
    text-align: center;
    font-size: $font-size-med;
    padding: $gp $gp * 6 $gp / 2;
    margin-bottom: 0;
    line-height: $line-height-md;
}

.available {
    text-align: center;
    font-size: $font-size-med;
    line-height: $line-height-md;
    color: $color-gray-700;
}

.terms-checkbox,
.privacy-checkbox,
.pricing-checkbox {
    --checkbox-padding: #{$gp / 4 0 $gp};
}

.pricing-checkbox {
    display: inline-flex;
    align-items: flex-start;
    margin-bottom: $gp;
}

.terms-description {
    font-size: px-to-rem(16px);
}

.action-row {
    display: flex;
    align-items: flex-start;
    margin: $gp 0;
}

.action-button {
    @include margin-end($gp);
}

.error-status {
    flex: 1;
}
</style>

<i18n>
{
    "en-us": {
        "title": "Keap Marketing Number setup",
        "header": "Let’s set up your Keap Marketing Number",
        "description": " It’s your designated number for sending any automated or broadcast text messages",
        "available": "Only available in the U.S.",
        "agreeToTerms": "I agree to the {termsAndConditions}",
        "termsAndConditions": "terms and conditions",
        "agreeToPrivacy": "I agree to the {privacyPolicy}",
        "privacyPolicy": "privacy policy",
        "pricingTerms": "I understand that I’ll be charged $0.04 each time a message {bold} by the recipient. ",
        "bold": "is successfully sent and opted into",
        "getMyNumber": "Get my number",
        "errorMessage": "You must agree to the terms and conditions, privacy policy, and price confirmation to continue",
        "accountCreationError": "There was an error creating your Keap Marketing Number. Please try again in a few minutes.",
        "termsDescription": "Please review and agree to these terms and policies."
    }
}
</i18n>
