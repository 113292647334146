<template>
    <div class="internal-form-submitted-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div>
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="close" />

                    <span class="configure-editor-header-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-text-button
                            v-if="showCreateForm"
                            trailing-icon="external-link"
                            data-qa="add-new-form"
                            @click="createForm"
                        >
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            :class="['search-input', { 'hide-create': !showCreateForm }]"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-if="showAnyResult"
                        as="a"
                        class="list-item-option"
                        data-qa="internal-forms-any"
                        :title="$t('anyInternalFormSubmitted.title')"
                        :description="$t('anyInternalFormSubmitted.description')"
                        @click="handleSelectAnyInternalForm"
                    />

                    <p v-if="!search" class="list-header">
                        {{ $t('formsHeader') }}
                    </p>

                    <p v-if="noInternalForms" class="empty-list">
                        {{ $t('emptyInternalForms') }}
                    </p>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(form, i) in filteredInternalForms"
                        :key="i"
                        as="a"
                        :class="['list-item-option', { published: form.status === $options.FORM_STATUSES.PUBLISHED }]"
                        :data-qa="`form_${form.id}`"
                        :title="form.title"
                        :description="form.status === $options.FORM_STATUSES.PUBLISHED ? $t('published') : $t('notPublished')"
                        @click="handleSelection(form)"
                    />
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import { mapState } from 'vuex';
import moment from 'moment';
import { FORM_STATUSES } from '@/customForms/customForm.constants';
import { ANY_INTERNAL_FORM_SUBMITTED, INTERNAL_FORM_SUBMITTED } from '@/automations/constants/automations.constants';

export default {
    FORM_STATUSES,

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedOption: {},
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapState({
            internalForms: ({ smartForms }) => smartForms.forms,
        }),

        anyInternalFormTrigger() {
            return {
                type: ANY_INTERNAL_FORM_SUBMITTED,
                sourceId: null,
                name: this.$t('anyInternalFormSubmitted.name'),
            };
        },

        internalFormTrigger() {
            const { id } = this.selectedOption;

            return {
                type: INTERNAL_FORM_SUBMITTED,
                sourceId: id,
                name: this.$t('singleInternalFormSubmitted', { id }),
            };
        },

        showAnyResult() {
            const search = this.search?.trim()?.toLowerCase();

            return this.$t('anyInternalFormSubmitted.title').toLowerCase().includes(search)
                || this.$t('anyInternalFormSubmitted.description').toLowerCase().includes(search);
        },

        filteredInternalForms() {
            const search = this.search?.trim()?.toLowerCase();

            return this.internalForms.filter(({ title, status }) => {
                return title.toLowerCase().includes(search)
                        || (status === this.$options.FORM_STATUSES.PUBLISHED
                            ? this.$t('published').toLowerCase().includes(search)
                            : this.$t('notPublished').toLowerCase().includes(search));
            }).sort((a, b) => {
                // sort by published status, then by updateTime
                if (a.status === b.status) {
                    return moment(b.updateTime).isBefore(a.updateTime) ? -1 : 1;
                }

                return a.status === this.$options.FORM_STATUSES.PUBLISHED ? -1 : 1;
            });
        },

        noInternalForms() {
            return !this.loading && !this.internalForms.length;
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredInternalForms.length && !this.showAnyResult;
        },

        showCreateForm() {
            return this.$route.name !== 'customForms.internal' && this.$route.name !== 'customForms.public';
        },
    },

    methods: {
        init() {
            const { trigger } = this.automationProperties;

            if (trigger) {
                this.selectedOption = {
                    ...trigger,
                    id: trigger.sourceId,
                };
            } else {
                this.selectedOption = {
                    type: ANY_INTERNAL_FORM_SUBMITTED,
                    id: null,
                    name: this.$t('internalFormSubmitted'),
                };
            }

            if (!this.internalForms.length) {
                this.loadInternalForms();
            }
        },

        async loadInternalForms() {
            this.loading = true;
            await this.$store.dispatch('smartForms/LOAD_SMART_FORMS');
            this.loading = false;
        },

        handleSelection(form) {
            this.selectedOption = clonedeep(form);
            this.$emit('updated', this.internalFormTrigger);
            this.$bus.$emit('AUTOMATION_INTERNAL_FORM_UPDATED');
            this.$emit('close');
        },

        handleSelectAnyInternalForm() {
            this.$emit('updated', this.anyInternalFormTrigger);
            this.$bus.$emit('AUTOMATION_INTERNAL_FORM_UPDATED');
            this.$emit('close');
        },

        createForm() {
            return this.$confirm({
                optionTitle: this.$t('createForm.title'),
                optionMessage: this.$t('createForm.message'),
                optionConfirmButtonLabel: this.$t('createForm.confirm'),
                optionCancel: this.$t('global.cancel'),
            })
                .then(() => {
                    this.$router.push({ name: 'customForms.internal' });
                }).catch(() => {});
        },

        close() {
            const { type, sourceId } = this.internalFormTrigger;

            if (type === INTERNAL_FORM_SUBMITTED && !sourceId) {
                this.handleSelectAnyInternalForm();
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';

    .published {
        --list-item-description-color: #{$color-green};
    }
</style>

<i18n>
{
    "en-us": {
        "anyInternalFormSubmitted": {
            "name": "Any internal form is submitted",
            "title": "Any internal form",
            "description": "When any internal form is submitted"
        },
        "internalFormSubmitted": "Internal form is submitted",
        "singleInternalFormSubmitted": "internal form ({id}) is submitted",
        "emptyInternalForms": "None available",
        "header": "Choose a form",
        "formsHeader": "Internal forms",
        "buttonText": "Create new form",
        "published": "Published",
        "notPublished": "Not published",
        "search": "Search",
        "emptySearch": "No results found for \"{search}\"",
        "createForm": {
            "title": "Navigate to the forms page to create new forms?",
            "message": "A draft will be saved of your current automation.",
            "confirm": "Go to forms"
        }
    }
}
</i18n>
