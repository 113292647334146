<template lang="html">
    <div class="signature-toggle">
        <signature-preview v-show="useSignature && showSignature" class="preview" :user-id="userId" />

        <div class="toggle">
            <ds-toggle-switch
                v-model="useSignature"
                :label-on="$t('signature.label')"
                :label-off="$t('signature.label')"
                @input="toggleChange"
            />
        </div>
    </div>
</template>

<script>
import SignaturePreview from '@/shared/components/Email/SignaturePreview';

export default {
    components: {
        SignaturePreview,
    },

    props: {
        userId: [Number, String],
        signatureEnabled: Boolean,
        showSignature: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        useSignature: {
            get() {
                return this.signatureEnabled;
            },

            set(value) {
                this.$bus.$emit('SET_SIGNATURE_ENABLED', value);
            },
        },
    },

    methods: {
        toggleChange() {
            this.$emit('input', this.signatureEnabled);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss"  scoped>
    .preview {
        background: $color-paper;
        padding: $gp;
        --placeholder-background: #{$color-paper};
    }

    .toggle {
        padding: $gp;
        --toggle-switch-margin: #{$gp / 2};
        border-top: 1px solid $color-gray-200;
    }
</style>

<i18n>
{
    "en-us": {
        "signature": {
            "label": "Signature"
        }
    }
}
</i18n>
