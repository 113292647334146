<template>
    <div class="send-document-step configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div v-if="showDocumentSelector">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            data-qa="search-document"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-for="(doc, i) in filteredDocuments"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :data-qa="`document_${doc.documentId}`"
                        :title="doc.title"
                        @click="handleDocumentSelection(doc)"
                    />

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>
                </section>
            </div>

            <div v-if="showTimingStep" class="timing-editor">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editOptions') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="send-document-done" @click="handleDone(true)">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="timing-body">
                    <timing-step
                        v-if="timingRefactorEnabled"
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        <automation-display-name :value="sendDocumentStep" capitalize as="span" />
                    </timing-step>

                    <configure-timing-step
                        v-else
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        <automation-display-name :value="sendDocumentStep" capitalize as="span" />
                    </configure-timing-step>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>

import { HELLOSIGN_SEND_DOCUMENT, EDITOR_STEP, TIMING_STEP } from '@/automations/constants/automations.constants';
import { mapState } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import ConfigureTimingStep from '@/automations/components/configure/ConfigureTimingStep';
import SaveStatus from '@/automations/components/SaveStatus';
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import TimingStep from '@/automations/components/steps/TimingStep';
import { FF_SIMPLE_AUTOMATION_TIMING_REFACTOR } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        ConfigureTimingStep,
        SaveStatus,
        AutomationDisplayName,
        TimingStep,
    },

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },

        saveStatus: String,
    },

    data() {
        return {
            currentStep: EDITOR_STEP,
            selectedDocument: {},
            timingDelay: this.automationProperties.delayStep || null,
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    watch: {
        'automationProperties.delayStep': {
            handler(value) {
                this.timingDelay = value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            timingRefactorEnabled: ({ featureFlags }) => featureFlags[FF_SIMPLE_AUTOMATION_TIMING_REFACTOR],
            documents: ({ settings: { integrations } }) => integrations.hellosignDocuments,
        }),

        sendDocumentStep() {
            return {
                type: HELLOSIGN_SEND_DOCUMENT,
                name: this.$t('sendDocumentEmpty'),
                configJson: {
                    documentId: this.selectedDocument.documentId,
                    title: this.selectedDocument.title,
                },
            };
        },

        showDocumentSelector() {
            return this.currentStep === EDITOR_STEP;
        },

        showTimingStep() {
            return this.currentStep === TIMING_STEP;
        },

        filteredDocuments() {
            const search = this.search?.trim()?.toLowerCase();

            return this.documents?.filter(({ title }) => {
                return title.toLowerCase().includes(search);
            });
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredDocuments.length;
        },
    },

    methods: {
        async init() {
            if (!this.documents?.length) {
                this.loading = true;

                try {
                    await this.$store.dispatch('settings/LOAD_HELLOSIGN_DOCUMENTS');
                } catch (e) {
                    this.$error({ message: this.$t('errorLoadingDocuments') });
                }

                this.loading = false;
            }

            const { initialStep, delayStep, step } = this.automationProperties;

            if (step?.configJson?.documentId) {
                this.selectedDocument = {
                    id: step.configJson.documentId,
                };
            }

            this.timingDelay = delayStep || null;
            this.currentStep = initialStep;
        },

        handleBack() {
            this.currentStep = EDITOR_STEP;
        },

        handleEdit() {
            this.handleDone(false);
        },

        handleDocumentSelection(document) {
            this.selectedDocument = clonedeep(document);
            this.handleEdit();

            this.currentStep = TIMING_STEP;
        },

        handleDone(close) {
            const steps = [this.sendDocumentStep];
            const hasDelay = this.timingDelay && Object.keys(this.timingDelay).length;

            if (hasDelay) {
                steps.unshift(this.timingDelay);
            }

            this.$emit('updated', steps);

            if (close) {
                this.$emit('close');
            }
        },

        updateTiming(timingDelay) {
            this.timingDelay = timingDelay;
            this.handleEdit();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "header": "Choose a template",
        "editOptions": "Edit options",
        "sendDocumentEmpty": "Send HelloSign document",
        "documentsHeader": "Templates",
        "search": "Search",
        "emptySearch": "No results found for \"{search}\"",
        "errorLoadingDocuments": "There was an issue loading your HelloSign documents."
    }
}
</i18n>
