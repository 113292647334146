export const IMPORT_TYPES = {
    GOOGLE: 'google',
    MSO: 'microsoft',
    QBO: 'qbo',
    MAILCHIMP: 'mailchimp',
    CONSTANT_CONTACT: 'constantContact',
    HUBSPOT: 'hubspot',
    CSV: 'csv',
    PHONE: 'phone',
    BIZCARD: 'bizcard',
};

export const CONTACT_IMPORT_TOAST_TIMEOUT = 1000 * 60 * 15;

export const IMPORT_STAGES = {
    FINAL: 'final',
    UPLOAD: 'upload',
    ORGANIZE: 'organize',
};

export const IMPORT_HELP_LINKS = {
    mailchimp: 'https://mailchimp.com/help/export-contacts/',
    constantContact: 'https://knowledgebase.constantcontact.com/articles/KnowledgeBase/5295-exporting-a-contact-list-from-constant-contact',
    hubspot: 'https://knowledge.hubspot.com/articles/kcs_article/contacts/export-contacts-companies-deals-or-tickets',
};

export const IMPORT_MAPPINGS = {
    NO_MATCH: 'NO_MATCH',
    ADD_AS_CUSTOM_FIELD: 'ADD_AS_CUSTOM_FIELD',
    TAG: 'TAG',
    DO_NOT_IMPORT: 'DO_NOT_IMPORT',
};

export const MAX_FILE_UPLOAD_SIZE_IN_BYTES = 10485760;

export const AMPLITUDE_IMPORT_EVENT_SOURCES = {
    [IMPORT_TYPES.GOOGLE]: 'Google',
    [IMPORT_TYPES.QBO]: 'QBO',
    [IMPORT_TYPES.MAILCHIMP]: 'Mailchimp',
    [IMPORT_TYPES.CONSTANT_CONTACT]: 'Constant Contact',
    [IMPORT_TYPES.HUBSPOT]: 'Hubspot',
    [IMPORT_TYPES.CSV]: 'Spreadsheet',
};
