export const IMAGE_CONTENT_TYPES = [
    'image/png',
    'image/gif',
    'image/jpg',
    'image/jpeg',
];

export const EMOJI_PATTERN = /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]){1,4}$/;

export const ERROR_MESSAGE_STATUS = 'error';

export const MAX_FILE_SIZE = 5242880;

export const PHONE_KEYS = [
    'phone1',
    'phone2',
    'phone3',
    'phone4',
    'phone5',
];

export const SUPPORT_PHONE_NUMBERS = ['support'];

export const SUPPORTED_COUNTRIES = ['US'];

export const SYSTEM_MESSAGE_KINDS = {
    downloadKeap: 'DOWNLOAD_KEAP',
};

export const VCARD_CONTENT_TYPES = [
    'text/vcard',
    'text/x-vcard',
];

export const SENDING_METHODS = {
    EMAIL: 'email',
    SMS: 'sms',
};
export const DEFAULT_MESSAGE_PAGE_SIZE = 100;
