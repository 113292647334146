import axios from 'axios';
import sentry from '@/analytics/sentry';
import { trackCustomFormSubmitted } from '@/customForms/analytics';

/*
  This file contains all Axios calls to the public Form Service API. These aren't in the store
  because they are called from public pages.  All internal API calls should be in the store.
 */

export const loadPublicFormData = async (slug) => {
    try {
        const { data: { form, profile, styling } } = await axios.get(`${process.env.VUE_APP_FORM_SERVICE_URL}/public/formPages/${slug}`);

        return { form, companyProfile: profile, formStyling: styling };
    } catch (e) {
        if (!e.response || e.response.status !== 404) {
            sentry.log('Error loading publicly available form data from Forms API');
        }

        return { form: null, companyProfile: null, formStyling: null };
    }
};

export async function loadCustomFormBySlug(slug) {
    const url = `${process.env.VUE_APP_FORM_SERVICE_URL}/public/forms:lookup?slug=${slug}&includeFieldInputDetails=true`;

    try {
        const { data } = await axios.get(url);

        return data;
    } catch (e) {
        return null;
    }
}

export const loadRegionsByCountry = async (formId, countryInputValue) => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_FORM_SERVICE_URL}/public/forms/${formId}/inputOptionsType/Region/options/${countryInputValue}`);

        return response.data.allowedValues;
    } catch (e) {
        if (!e.response || e.response.status !== 404) {
            sentry.log('Error loading publicly available regions data, by country, from Forms API');
        }

        return [];
    }
};

export const submitPublicForm = async (form, submissionData, recaptchaToken) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-disable-redirect': true,
            'x-keap-recaptcha-token': recaptchaToken,
        },
    };

    const { data } = await axios.post(`${process.env.VUE_APP_FORM_SERVICE_URL}/public/forms/${form.id}/submissions`, submissionData, config);

    trackCustomFormSubmitted(form, data);

    return data;
};
