export default {
    STANDARD: {
        ADDRESS2_STREET1: { type: 'text' },
        ADDRESS2_STREET2: { type: 'text' },
        ADDRESS3_STREET1: { type: 'text' },
        ADDRESS3_STREET2: { type: 'text' },
        ANNIVERSARY: { type: 'birthday' },
        BIRTHDAY: { type: 'birthday' },
        CITY: { type: 'text' },
        CITY2: { type: 'text' },
        CITY3: { type: 'text' },
        COMPANY: { type: 'multiselect', id: 'companies' },
        CONTACT_TYPE: { type: 'select', id: 'contactType' },
        COUNTRY: { type: 'select', id: 'countries' },
        COUNTRY2: { type: 'select', id: 'countries' },
        COUNTRY3: { type: 'select', id: 'countries' },
        EMAIL: { type: 'email' },
        EMAIL_ADDRESS2: { type: 'email' },
        EMAIL_ADDRESS3: { type: 'email' },
        FACEBOOK: { type: 'url' },
        FAX1: { type: 'phone' },
        FAX1_TYPE: { type: 'faxType', id: 'fax' },
        FAX2: { type: 'phone' },
        FAX2_TYPE: { type: 'faxType', id: 'fax' },
        FIRST_NAME: { type: 'text' },
        JOB_TITLE: { type: 'text' },
        LAST_NAME: { type: 'text' },
        LINKEDIN: { type: 'url' },
        MIDDLE_NAME: { type: 'text' },
        NICKNAME: { type: 'text' },
        OWNER: { type: 'select', id: 'owners' },
        PHONE1: { type: 'phone' },
        PHONE1_TYPE: { type: 'phoneType', id: 'phone' },
        PHONE2: { type: 'phone' },
        PHONE2_TYPE: { type: 'phoneType', id: 'phone' },
        PHONE3: { type: 'phone' },
        PHONE3_TYPE: { type: 'phoneType', id: 'phone' },
        PHONE4: { type: 'phone' },
        PHONE4_TYPE: { type: 'phoneType', id: 'phone' },
        PHONE5: { type: 'phone' },
        PHONE5_TYPE: { type: 'phoneType', id: 'phone' },
        POSTAL_CODE: { type: 'text' },
        POSTAL_CODE2: { type: 'text' },
        POSTAL_CODE3: { type: 'text' },
        SPOUSE_NAME: { type: 'text' },
        STATE: { type: 'region', id: 'billingRegion' },
        STATE2: { type: 'region', id: 'shippingRegion' },
        STATE3: { type: 'region', id: 'otherRegion' },
        STREET_ADDRESS1: { type: 'text' },
        STREET_ADDRESS2: { type: 'text' },
        SUFFIX: { type: 'text' },
        TITLE: { type: 'select', id: 'title' },
        TWITTER: { type: 'url' },
        WEBSITE: { type: 'url' },
    },

    CUSTOM: {
        Currency: { type: 'decimal' },
        Date: { type: 'date' },
        DayOfWeek: { type: 'select', id: 'weekDays' },
        NumberDecimal: { type: 'decimal' },
        Email: { type: 'email' },
        Month: { type: 'select', id: 'months' },
        MultiSelect: { type: 'checkboxes', hasOptions: true },
        ListBox: { type: 'checkboxes', hasOptions: true },
        Select: { type: 'dropdown', hasOptions: true, hasSettings: true },
        Dropdown: { type: 'dropdown', hasOptions: true, hasSettings: true },
        Percent: { type: 'decimal' },
        Phone: { type: 'phone' },
        Radio: { type: 'radio', hasOptions: true },
        State: { type: 'region', id: 'customRegion' },
        Text: { type: 'text' },
        TextArea: { type: 'textarea' },
        User: { type: 'select', id: 'owners' },
        Website: { type: 'url' },
        Number: { type: 'number' },
        Year: { type: 'year' },
        YesNo: { type: 'select', id: 'yesNo' },
    },

    CUSTOM_FIELD_NAMES: {
        CHECKBOXES: 'ListBox',
        MULTISELECT: 'MultiSelect',
        CURRENCY: 'Currency',
        DATE: 'Date',
        DAY_OF_WEEK: 'DayOfWeek',
        DECIMAL_NUMBER: 'DecimalNumber',
        DROPDOWN: 'Dropdown',
        EMAIL: 'Email',
        MONTH: 'Month',
        PERCENT: 'Percent',
        PHONE_NUMBER: 'PhoneNumber',
        RADIO: 'Radio',
        STATE: 'State',
        TEXT: 'Text',
        TEXT_AREA: 'TextArea',
        WEBSITE: 'Website',
        WHOLE_NUMBER: 'WholeNumber',
        YEAR: 'Year',
        YES_NO: 'YesNo',
    },
};
