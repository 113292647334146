import * as FullStory from '@fullstory/browser';

export default {
    init(initObj) {
        if (process.env.VUE_APP_LOGGING !== 'disabled') {
            FullStory.init(initObj);
        }
    },

    identify(id, identifyObj) {
        if (process.env.VUE_APP_LOGGING !== 'disabled') {
            FullStory.identify(id, identifyObj);
        }
    },

    event(message, sourceObj) {
        if (process.env.VUE_APP_LOGGING !== 'disabled') {
            FullStory.event(message, sourceObj);
        }
    },
};
