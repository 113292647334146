<template>
    <div class="adding-sample-data-modal">
        <ds-illustration
            class="adding-sample-data-image"
            name="innovation"
        />

        <h3 class="add-sample-contacts-text">
            {{ $t('addingSampleContacts') }}
        </h3>

        <ds-progress-bar :value="sampleDataProgress" :max="6" />

        <p class="interact-with-data">
            {{ $t('interactWithData') }}
        </p>

        <i18n tag="p" path="settingsRemove.description" class="settings-remove">
            <template #bold>
                <span class="bold">{{ $t('settingsRemove.bold') }}</span>
            </template>
        </i18n>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { MAX_ERROR_RETRY } from '../constants/sampleData.constants';

import '@/shared/images/innovation.svg';

export default {
    watch: {
        sampleDataProgress(newValue) {
            if (newValue === this.sampleDataTotal) {
                this.close();

                this.$store.commit('sampleData/SET_SAMPLE_DATA_PROGRESS', 0);
                this.$store.commit('sampleData/RESET_ADD_SAMPLE_DATA_ERROR_COUNT');
            }
        },

        addSampleDataErrorCount(newValue) {
            if (newValue > MAX_ERROR_RETRY) {
                this.$error({ message: this.$t('sampleDataErrorMessage') });
            }
        },
    },

    computed: {
        ...mapState({
            sampleDataProgress: ({ sampleData }) => sampleData.sampleDataProgress,
            sampleDataTotal: ({ sampleData }) => sampleData.sampleDataTotal,
            addSampleDataErrorCount: ({ sampleData }) => sampleData.addSampleDataErrorCount,
        }),
    },

    methods: {
        close() {
            this.$bus.$emit('CLOSE_NESTED_MODAL');
        },
    },
};
</script>

<style lang="scss" scoped>
    $max-text-width: px-to-rem(270px);

    .adding-sample-data-modal {
        --progress-bar-width: #{px-to-rem(200px)};

        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .adding-sample-data-image {
        width: 100%;
        max-width: px-to-rem(120px);
        max-height: px-to-rem(120px);
        margin: $gp / 4 $gp $gp;
    }

    .add-sample-contacts-text {
        margin-bottom: $gp * 2;
        max-width: $max-text-width;
        text-align: center;
    }

    .interact-with-data {
        font-size: $font-size-med;
        max-width: px-to-rem(330px);
        margin: auto;
        text-align: center;
    }

    .settings-remove {
        max-width: $max-text-width;
        margin-top: $gp * 2;
        color: $color-gray-700;
        text-align: center;
    }
</style>

<i18n>
{
    "en-us": {
        "addingSampleContacts": "Adding sample contacts to your Keap app...",
        "interactWithData": "Feel free to play around with your sample contacts, send them an email, or an invoice.",
        "settingsRemove": {
            "description": "You can remove the sample contacts from {bold}",
            "bold": "Settings > My account."
        },
        "sampleDataErrorMessage": "Sample Data is currently unavailable. Please try again later."
    }
}
</i18n>
