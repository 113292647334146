<template>
    <div class="error-banner">
        <div>{{ message }}</div>

        <ds-text-button
            v-if="actionMessage"
            class="action"
            @click="$emit('action')"
        >
            <ds-icon
                v-if="actionIcon"
                class="action-icon"
                :name="actionIcon"
            />

            <span>{{ actionMessage }}</span>
        </ds-text-button>
    </div>
</template>

<script>
export default {
    props: {
        message: String,
        actionMessage: String,
        actionIcon: String,
    },
};
</script>

<style lang="scss" scoped>
    .error-banner {
        width: 100%;
        padding: $gp / 2 $gp;
        border: 1px solid $color-red;
        border-radius: $gp / 2;
        background-color: $color-red-050;
    }

    .action {
        display: flex;
        padding: $gp / 2 0;
        color: $color-blue-900;
        font-weight: $font-weight-semibold;
        --icon-size: #{$gp};
        --icon-color: #{$color-blue-900};

        &:hover, &:active, &:focus {
            text-decoration: underline;
            background-color: transparent;
            color: $color-blue-900;
            border-color: transparent;
            --icon-color: #{$color-blue-900};
        }

        .action-icon {
            @include margin-end($gp / 2);
        }
    }
</style>
