<template lang="html">
    <div class="marketing-site-provider">
        <ds-modal
            ref="modal"
            :show-close="true"
            :title="title"
            :sub-title="subtitle"
            sticky-desktop-header
            size="fullscreen"
            @close="closeModal"
        >
            <template #header>
                <header class="stage-indicator-container">
                    <step-indicators-inline
                        :active-step="stage"
                        :steps="siteStageNames"
                        show-step-numbers
                        class="stage-indicator landing-pages-step-indicator"
                        @click-step="goToStage"
                    />
                </header>
                <header class="header-action">
                    <site-wizard-header
                        :is-editing="isEditing"
                        :is-publishing="isPublishingStage"
                        @next-step="goToStage(stage + 1)"
                        @save-template="openSaveTemplateModal"
                    />
                </header>
            </template>
            <template #default>
                <templates-tab
                    v-if="isChoosingTemplate && !isLoading && isOpen"
                    ref="templates-tab"
                    data-qa="choose-a-template"
                    @select="onTemplateSelected"
                    @select-pending="onPendingTemplateSelected"
                />
                <site-builder-page
                    v-show="isEditing"
                    :site="site"
                    :focused-page-id="focusedPageId"
                />
                <publish-page
                    v-if="!isChoosingTemplate && site && site.id"
                    v-show="isPublishingStage"
                    :is-ready="!isProcessing"
                    :is-publishing="isPublishing"
                    :site="site"
                />
                <div v-if="isProcessing || isLoading" class="spinner-container">
                    <ds-spinner />
                </div>

                <portal to="root">
                    <save-as-template-modal
                        v-if="isSaveAsTemplateModalOpen"
                        :saved-sites-name="savedSitesName"
                        @close="handleSaveAsTemplateModalClose"
                        @save-template="saveTemplate"
                    />
                </portal>
            </template>
        </ds-modal>
        <choose-name-modal
            :button-title="chooseNameContext.buttonTitle"
            :modal-title="chooseNameContext.modalTitle"
            :initial-name="chooseNameContext.name"
            :is-open="Boolean(chooseNameContext.resolve)"
            @close="cancelChooseName"
            @choose-name="handleChooseName"
        />
    </div>
</template>

<script>

import clonedeep from 'lodash.clonedeep';
import { buildPageHtml, inspectPage, pageIdOf } from '@/marketingSites/components/site/site.utils';
import SiteWizardHeader from '@/marketingSites/components/wizard/SiteWizardHeader';
import SiteBuilderPage from '@/marketingSites/components/site/SiteBuilderPage';
import PublishPage from '@/marketingSites/components/site/PublishPage';
import { SITE_WIZARD_STAGE, siteStages } from '@/marketingSites/pages/site.stages';
import StepIndicatorsInline from '@/shared/components/StepIndicatorsInline';
import { mapState } from 'vuex';
import { autoSaveTimer } from '@/marketingSites/provider/auto-save-timer';
import { KEAP_TOOLS, TEMPLATE_FORM } from '@/marketingSites/unlayer/unlayer.constants';
import {
    createMarketingForm,
    createMarketingPage,
    deleteMarketingPage,
    deleteTemplateForm,
    getMarketingPage,
    getMarketingPageForms,
    getSavedTemplates,
    publishSite,
    updateMarketingPage,
} from '@/marketingSites/api/sites-datasource-graphql';
import ChooseNameModal from '@/marketingSites/components/listPages/ChooseNameModal';
import { SITE_STATUS, TEMPLATE_TABS } from '@/marketingSites/marketingSites.constants';
import SaveAsTemplateModal from '@/marketingSites/components/modal/SaveAsTemplateModal';
import TemplatesTab from '@/marketingSites/components/wizard/TemplatesTab';
import { unlayerDesign } from '@/marketingSites/unlayer/unlayer-design-processor';
import { updateLinksInDesign, updateLinksInHtml } from '@/marketingSites/provider/update-page-links';

const {
    CREATE,
    CHOOSE_TEMPLATE,
    UNKNOWN,
    PUBLISH,
} = SITE_WIZARD_STAGE;

/**
 * This component provides all the mutation functions to subcomponents of the Site editor subcomponents.  It also
 * provides a reactive instance of the site being edited. As with vuex, any subcomponents should use the $site
 * methods to alter the state of the site instead of making changes directly
 */
export default {
    TEMPLATE_TABS,
    components: {
        TemplatesTab,
        ChooseNameModal,
        StepIndicatorsInline,
        PublishPage,
        SiteWizardHeader,
        SiteBuilderPage,
        SaveAsTemplateModal,
    },

    inject: ['$sites', '$customForms', '$createSite'],

    provide() {
        return {
            site: this.site,
            $site: {
                sortPages: this.sortPages,
                getMarketingPage: this.getMarketingPage,
                addPage: this.addPage,
                renameSite: this.renameSite,
                changePageFocus: this.changePageFocus,
                deletePage: this.deletePage,
                updatePage: this.updatePage,
                changeSiteName: this.changeSiteName,
                startPublishing: this.startPublishing,
                publishActiveSite: this.publishActiveSite,
                goToStage: this.goToStage,
                getTotalFormCount: this.getTotalFormCount,
                deleteOrphanForms: this.deleteOrphanForms,
                getDetailedFormInfo: this.getDetailedFormInfo,
            },
        };
    },

    props: {
        isOpen: Boolean,
        isLoading: Boolean,
        loadSite: Object,
    },

    data() {
        return {
            isProcessing: false,
            templates: [],
            stage: CHOOSE_TEMPLATE,
            chooseNameContext: {
                buttonTitle: '',
                modalTitle: '',
            },
            site: {},
            pageDesignCache: {},
            pageCache: {},
            focusedPageId: '',
            isPublishing: false,
            isSaveAsTemplateModalOpen: false,
            savedSitesName: [],
            pageAutoSave: autoSaveTimer({
                performAutoSave: this.performAutoSave,
            }),
        };
    },

    mounted() {
        this.stage = this.site?.pages?.length > 0 ? CREATE : CHOOSE_TEMPLATE;

        if (this.isOpen) {
            this.$refs.modal.toggleable_open();
        }

        this.loadSavedSitesName();
        this.pageAutoSave.start();
    },

    beforeDestroy() {
        return this.pageAutoSave.stop();
    },

    watch: {
        isOpen(isOpen) {
            const { modal, templatesTab } = this.$refs;

            if (isOpen) {
                modal.toggleable_open();
            } else {
                templatesTab.$data.tab = TEMPLATE_TABS.GALLERY;
                modal.toggleable_close();
            }
        },

        loadSite: {
            immediate: true,
            handler(loadSite) {
                if (loadSite?.id) {
                    this.site = loadSite;
                    this.stage = CREATE;

                    this.onSiteLoaded();
                } else {
                    this.focusedPageId = '';
                    this.site = null;
                    this.stage = CHOOSE_TEMPLATE;
                    this.cancelChooseName();
                }
            },

        },
    },

    computed: {
        ...mapState({
            appId: ({ auth }) => auth.session.coreAppId,
        }),

        siteStageNames() {
            return siteStages({
                designError: this.$t('stages.designError'),
                chooseTemplate: this.$t('stages.chooseTemplate'),
                defaultPageName: this.$t('marketingSites.defaultPageName'),
                createStep: this.$t('stages.create'),
                defaultThankYouPageName: this.$t('marketingSites.defaultThankYouPageName'),
                sendError: this.$t('stages.sendError'),
                publish: this.$t('stages.publish'),
            }).map(({ label }) => label);
        },

        siteStages() {
            return siteStages(this.stage);
        },

        isChoosingTemplate() {
            return this.stage === CHOOSE_TEMPLATE;
        },

        isEditing() {
            return this.stage === CREATE;
        },

        isPublishingStage() {
            return this.stage === PUBLISH;
        },

        focusedSiteId() {
            return this.site?.id;
        },

        title() {
            return this.$t('titleCreateBuilder');
        },

        subtitle() {
            return this.site?.id ? (this.site.name ?? this.$t('marketingSites.defaultSiteName')) : '';
        },
    },

    methods: {
        onSiteLoaded() {
            // Clear out any old promises in case somebody's waiting on them.
            if (this.siteInitResolve && typeof this.siteInitResolve === 'function') {
                this.siteInitResolve();
            }

            // If this site is a draft, we need to enforce that the unlayer builder fully loads and exports valid content,
            // at least for the main page.  `this.siteInitCompleter` is marked done whenever a page is saved (with forceUpdate=true).
            //
            // For a draft site, this should happen immediately after the first page is loaded into the builder.
            const siteStatus = this.site.status || SITE_STATUS.DRAFT;

            if (siteStatus === SITE_STATUS.DRAFT) {
                this.siteInitPromise = new Promise((resolve, reject) => {
                    this.siteInitResolve = resolve;
                    this.siteInitReject = reject;
                });

                // Set a timeout - if the completer is still waiting, then it will be resolved with an error
                setTimeout(
                    () => this.siteInitReject?.call('Timed out waiting for unlayer initialization'),
                    10000,
                );
            } else {
                this.siteInitPromise = Promise.resolve();
            }

            if (!this.focusedPageId) {
                this.changePageFocus(this.site?.pages[0]?.id);
            }
        },

        async closeSite() {
            await this.pageAutoSave.stop();
            this.$emit('cancel-site');
        },

        async loadSavedSitesName() {
            const { templates } = await getSavedTemplates({ status: SITE_STATUS.SAVED });

            this.savedSitesName = templates.map((site) => site.name);
        },

        openSaveTemplateModal() {
            this.isSaveAsTemplateModalOpen = true;
        },

        handleSaveAsTemplateModalClose() {
            this.isSaveAsTemplateModalOpen = false;
        },

        cancelChooseName() {
            if (this.chooseNameContext?.resolve) this.chooseNameContext.resolve();
            this.chooseNameContext = {};
        },

        handleChooseName(newName) {
            if (this.chooseNameContext?.resolve) this.chooseNameContext.resolve({ newName });
            this.chooseNameContext = {};
        },

        /**
         * This is horribly inefficient, but it was a non-negotiable requirement to have the form names auto-name in
         * ascending order based on the funnel/site instead of the page.  So, we have to read all the unlayer json design
         * documents, and look for all forms.  This method will have to suffice until we get the endpoint that lists the
         * linked forms complete.
         */
        async getTotalFormCount() {
            const marketingPageContents = await Promise.all(
                this.site.pages
                    .map((page) => this.getMarketingPageContent(pageIdOf(page))),
            );

            const allForms = marketingPageContents.flatMap((content) => {
                if (content == null) return [];
                const { design } = content;

                return unlayerDesign(design).findByType(KEAP_TOOLS.keapForm);
            });

            return allForms.length;
        },

        async goToStage(stage) {
            const [firstPage] = this.site?.pages ?? [];

            if (!firstPage) {
                stage = CHOOSE_TEMPLATE;
            }

            if (stage === PUBLISH) {
                await this.startPublishing();
            } else {
                this.stage = stage;
            }
        },

        closeModal() {
            this.stage = UNKNOWN;
            this.$emit('cancel-site');
        },

        changePageFocus(newPage) {
            if (!newPage) return false;

            const newFocusPage = pageIdOf(newPage);

            // We pause autosave while on the publish page.  If the user goes back to edit pages after visiting
            // the publish page, we need to restart the autosave
            if (!this.pageAutoSave.isRunning) {
                this.pageAutoSave.start();
            }

            if (newFocusPage !== this.focusedPageId) {
                this.focusedPageId = newFocusPage;
            }

            return true;
        },

        /**
         * Autosaves all pending pages updates
         *
         * @param {[string]} pageIdsToUpdate A list of page ids that require updating
         *
         * @return {Promise<void>}
         */
        async performAutoSave(pageIdsToUpdate) {
            const pages = await Promise.all(pageIdsToUpdate.map((pageId) => {
                return this.saveOrDeletePage(pageId);
            }));

            const countUpdated = pages.filter(({ isDeleted } = {}) => !isDeleted).length;

            // If we updated the content of at least one page, then we need to update the published status of the site
            if (countUpdated > 0) {
                // Here we just force the mainPageId to be the first page
                this.site.mainPageId = this.site.pages[0]?.id;

                // If the main page is among the pages that was saved, then pass along the html so that the
                // marketing site html will reflect the content of this page
                const mainPageHtml = pages.find(({ id }) => id === this.site.mainPageId)?.content?.html;

                if (this.site.status === SITE_STATUS.PUBLISHED || mainPageHtml) {
                    // Update the in-memory site, if it's currently published
                    this.site = {
                        ...this.site,
                        status: (!this.site.status || this.site.status === SITE_STATUS.DRAFT) ? SITE_STATUS.DRAFT : SITE_STATUS.PUBLISHED_CHANGES,
                    };
                    this.$sites.saveSite({
                        ...this.site,
                    }, mainPageHtml);
                }
            }
        },

        async saveTemplate(templateName) {
            if (!templateName) {
                return;
            }

            try {
                // Forces the autoSave to be called.
                //
                // Calling `stop` (instead of directly invoking performAutoSave) ensures that the autoSave
                // will not conflict with any in-flight updates, or any autoSave that might be called while
                // we are creating this template
                await this.pageAutoSave.stop();
                const newSite = await this.$createSite.createUserSiteTemplate(this.site, templateName);

                this.handleSaveAsTemplateModalClose();
                this.savedSitesName.push(newSite.name);
                this.$toast({
                    message: this.$t('toast.template.saved', { name: templateName }),
                });
            } catch (e) {
                this.$error({ message: this.$t('saveTemplateErrorMessage') });
            } finally {
                // Make sure to restart the autoSave after we're done
                await this.pageAutoSave.start();
                this.handleSaveAsTemplateModalClose();
            }
        },

        async saveOrDeletePage(pageOrId, createForms = true) {
            const pageId = pageIdOf(pageOrId);
            const pageData = await this.getMarketingPageData(pageId);
            const {
                id, slug, name, seoDescription, isDeleted, content, groupOrder,
            } = pageData;
            const { site } = this;

            if (isDeleted) {
                await deleteMarketingPage(id);

                // update site without page?
                return pageData;
            }

            let formCount = 0;

            // eslint-disable-next-line prefer-const
            let { design, chunks, html } = content;

            // Deleting forms no longer available in design
            const [existingForms, currentForms] = await this.getDetailedFormInfo(id, design);

            const currentFormIDs = currentForms.reduce((ids, element) => {
                if (typeof element?.content?.values?.keapForm?.id !== 'undefined') {
                    ids.push(element.content.values.keapForm.id);
                }

                return ids;
            }, []);

            if (currentFormIDs.length) {
                await this.deleteOrphanForms(existingForms?.data, currentFormIDs);
            }

            if (!html) {
                if (createForms) {
                    design = await this.$customForms.updateCustomFormsWithinPage({
                        page: pageData,
                        design,
                        formNamePrefix: site?.name,
                        formNameGenerator: () => `${this.$t('marketingSites.formAutoNamePrefix')} ${++formCount}`,
                    });

                    await Promise.all(unlayerDesign(design).findByType(KEAP_TOOLS.keapForm).map(({ content: cellContent }) => {
                        const form = cellContent?.values?.keapForm;

                        return createMarketingForm({
                            formId: form.id,
                            formSource: TEMPLATE_FORM.TemplateFormSource,
                            formType: TEMPLATE_FORM.TemplateFormType,
                            name: cellContent?.values?.formName,
                            pageId: id,
                        });
                    }));
                }

                if (!chunks) {
                    throw Error('Unable to generate html without chunks.  This is an error');
                }
                const convertedBody = updateLinksInHtml(chunks.body, this.appId, site);

                html = buildPageHtml(this.appId, site, pageData, chunks, convertedBody);
                // Right before we update, we need to scan for any dirty links
                updateLinksInDesign(design, this.appId, site);

                this.updatePage(pageId, { content: { design, html, chunks } }, { markDirty: false });
            }

            const pageUpdate = await updateMarketingPage(pageId, {
                isDeleted: isDeleted ?? false,
                id,
                name,
                slug,
                groupOrder,
                siteId: this.site.id,
                seoDescription,
                content: {
                    design,
                    html,
                    chunks,
                },
            });

            // Update this page in memory for the site
            this.persistSiteChanges({
                ...this.site,
                pages: this.site.pages.map((p) => (p.id === pageId ? pageUpdate : p)),
            }, false);

            return pageUpdate;
        },

        /**
         * Convenience for showing a rename modal.  You can customize the modal title and button label.
         *
         * @param {string} currentName The starting value for the rename modal
         * @return Promise<string>
         */
        async chooseName({ modalTitle, buttonTitle, currentName: name }) {
            this.cancelChooseName();
            const { newName } = await new Promise((resolve) => {
                this.chooseNameContext = {
                    name,
                    modalTitle,
                    buttonTitle,
                    resolve,
                };
            });

            return newName;
        },

        findPage(page) {
            const pageId = pageIdOf(page);
            const { site } = this;

            return site?.pages?.find((p) => p.id === pageId);
        },

        onChooseName({ newName }) {
            const { resolve } = this.chooseNameContext;

            if (resolve) resolve({ newName });
            this.chooseNameContext = {};
        },

        renameSite(name) {
            this.persistSiteChanges({ ...this.site, name });
        },

        deleteOrphanForms(existingForms, currentFormIds) {
            const deleteTransactions = existingForms.reduce((transactions, existingForm) => {
                if (!currentFormIds.includes(existingForm.formId)) {
                    transactions.push(deleteTemplateForm(existingForm.formId));
                }

                return transactions;
            }, []);

            return Promise.all(deleteTransactions);
        },

        getDetailedFormInfo(id, design) {
            return Promise.all([getMarketingPageForms([id]), unlayerDesign(design).findByType(KEAP_TOOLS.keapForm)]);
        },

        /**
         * Sorts the list of pages based on an array of page ids
         * @param {[string]} pageIds A sorted list of page ids
         * @return {Promise<void>}
         */
        async sortPages(pageIds) {
            pageIds = pageIds ?? [];
            let groupOrder = 0;

            await Promise.all(pageIds.map(async (pageId) => {
                try {
                    return await this.getMarketingPageData(pageId);
                } catch (e) {
                    return null;
                }
            }));
            const sortedPageList = pageIds
                .reduce((prev, pageId) => {
                    groupOrder++;

                    try {
                        prev.push(this.updatePage(pageId, { groupOrder }));
                    } catch (e) {
                        // Do nothing, don't add the item to the list
                    }

                    return prev;
                }, []);

            if (sortedPageList.length === this.site.pages.length) {
                await this.persistSiteChanges({
                    ...this.site,
                    pages: sortedPageList,
                }, false);
            }
        },

        /**
         * Adds a page to the currently edited site.
         *
         */
        async addPage({ slug = 'page', name, ...other } = {}) {
            name = name || this.$t('marketingSites.defaultPageName');

            if (!this.site) {
                throw Error('You cannot add a page without a site');
            }
            const { name: uniqueName, slug: uniqueSlug } = inspectPage(this.site, {
                slug, name, ...other,
            });

            const {
                design,
                chunks,
            } = await this.getMarketingPageContent(this.site.pages[this.site.pages.length - 1]?.id);

            const newPage = await createMarketingPage(this.site.id, {
                name: uniqueName, slug: uniqueSlug, content: { design, chunks, html: '<html></html>' },
            });

            this.pageCache[newPage.id] = {
                timestamp: Date.now(),
                page: newPage,
            };
            await this.persistSiteChanges({
                ...this.site,
                pages: [
                    ...this.site.pages,
                    newPage,
                ],
            }, false);

            return newPage;
        },

        /**
         * Loads a MarketingPage record from the server, but injects the latest design content from a local cache.  This is
         * because the content may not have been persisted to the server yet due to our use of scheduled timers.
         *
         * @return Promise<MarketingPage|null>
         */
        async getMarketingPage(pageId) {
            const page = await this.getMarketingPageData(pageId);

            if (!page) {
                this.$error({ message: this.$t('error.loadingPage') });

                return null;
            }

            return page;
        },

        async getMarketingPageContent(pageId, freshPageFromServer = null) {
            const { content } = await this.getMarketingPageData(pageId, freshPageFromServer);

            return content;
        },

        /**
         * Returns the current content for a page, pulling from the local page design cache, or fetching from the server.
         * If the page content has been modified, then the html field should be null - this indicates to the calling
         * code that the html needs to be regenerated using the chunks property
         *
         * @param {string} pageId
         * @param {MarketingPage|null} freshPageFromServer
         */
        async getMarketingPageData(pageId, freshPageFromServer = null) {
            // The "fresh" page from the server might actually be stale, since the updates take a few seconds
            // to be applied.  So, we'll use the cached content
            let { page } = this.pageCache[pageId] ?? {};

            if (!page) {
                if (!freshPageFromServer) {
                    freshPageFromServer = await getMarketingPage(pageId);
                }

                if (!freshPageFromServer?.content) {
                    return null;
                }

                page = freshPageFromServer;
                this.pageCache[pageId] = {
                    page,
                    timestamp: Date.now(),
                };
            }

            return page;
        },

        /**
         * Removes a page from the currently edited site
         *
         * @param {!string|object} page The page or pageId to be deleted
         */
        async deletePage(page) {
            const pageId = pageIdOf(page);
            const { isDeleted } = await this.getMarketingPageData(pageId);

            if (!isDeleted) {
                this.updatePage(pageId, { isDeleted: true });
            }
        },

        /**
         * Updates a MarketingPage with the new design content.  This method doesn't immediately persist the new content
         * to the server, but rather will queue the page for an update which will be applied at the next autosave.
         *
         * The timestamp provided will be used to verify that these changes are the most recent.  If they are not the
         * most recent, then the method will terminate without doing anything.
         *
         * @param {string|object} pageOrId The page or id being saved
         * @param {object|function} updates Updates to the page
         * @param {object|function} markDirty Whether to mark the page as being dirty - being dirty causes the page to
         *                                    undergo content processing during the autoSave. Since the autosave can
         *                                    also perform updates to the page, this flag is used to avoid resetting the
         *                                    flag, which would result in infinitely dirty pages
         * @param {timestamp|undefined|null} timestamp When the update occurred.  Used to reject stale changes
         * @param {boolean|undefined} forceUpdate Whether to force an update now.
         */
        updatePage(pageOrId, updates, { markDirty = true, timestamp, forceUpdate = false } = {}) {
            const id = pageIdOf(pageOrId);

            timestamp = timestamp ?? Date.now();
            const { timestamp: cachedTimestamp, page: oldPage } = this.pageCache[id] ?? {};

            if (!oldPage) {
                throw Error('Page not loaded previously.');
            }

            if (cachedTimestamp && cachedTimestamp > timestamp) {
                // We are pushing an older update.  ignore it.
                return oldPage;
            }

            const appliedUpdates = { ...clonedeep(oldPage), ...updates };

            if (oldPage === appliedUpdates) {
                return oldPage;
            }

            this.pageCache[id] = {
                page: appliedUpdates,
                timestamp,
            };

            // As soon as we save the first page, we can mark the site as initialized;
            if (forceUpdate && this.siteInitResolve && typeof this.siteInitResolve === 'function') {
                this.siteInitResolve();
            }

            if (markDirty) {
                this.pageAutoSave.markRecordDirty(id);

                if (!forceUpdate && this.site.status === SITE_STATUS.PUBLISHED) {
                    // Apply updates to the in-memory objects so the UI can look updated without waiting
                    // for the server to complete
                    this.site = {
                        ...clonedeep(this.site),
                        status: SITE_STATUS.PUBLISHED_CHANGES,
                    };
                }

                this.persistSiteChanges({
                    ...this.site,
                    pages: this.site.pages.map((p) => (p.id === id ? appliedUpdates : p)),
                }, false);
            }

            if (forceUpdate) {
                this.pageAutoSave.saveChanges();
            }

            return appliedUpdates;
        },

        async changeSiteName(name) {
            try {
                const { pages, ...others } = this.site;

                await this.persistSiteChanges({
                    ...others,
                    name,
                });
            } catch (e) {
                this.$error({ message: this.$t('changeSiteNameError') });
                throw e;
            }
        },

        /**
         * Signals that we are done editing and ready to start publishing.  Before moving to the publish step,
         * we apply any post-edit processing on the edited pages - for now, this syncs any forms on the page
         * with the smart-forms-api
         */
        async startPublishing() {
            this.isProcessing = true;

            try {
                this.stage = PUBLISH;

                try {
                    // First, ensure that the page is initialized
                    if (this.siteInitPromise) {
                        await this.siteInitPromise;
                    }
                    // This ensures that all pending updates are applied, and that we're not accepting any more
                    // updates while we're on the publish step.
                    await this.pageAutoSave.stop();
                    this.isProcessing = false;
                } catch (err) {
                    this.$error({ message: this.$t('initializeSiteError') });
                    this.stage = CREATE;

                    return;
                }

                if (!this.site.status || this.site.status === SITE_STATUS.DRAFT) {
                    await this.publishActiveSite();
                }
            } finally {
                this.isProcessing = false;
            }
        },

        /**
         * Updates the internal state for [site], and also propagates the change up to the MarketingSitesProvider to
         * update the list page.
         * @param newSite
         * @param {boolean} persist Whether to save this to the service
         */
        async persistSiteChanges(newSite, persist = true) {
            this.site = newSite;
            if (persist) await this.$sites.saveSite(newSite);
        },

        /**
         * Publishes the active site.  This will also save and update the site/page models.
         */
        async publishActiveSite() {
            // Already in the process of publishing.  Don't start it again
            if (this.isPublishing) return;

            let publishedSite;

            try {
                this.isPublishing = true;
                publishedSite = await publishSite(this.site.id);
            } finally {
                this.isPublishing = false;
            }

            this.site = {
                ...publishedSite,
                publishedDate: new Date().toJSON(),
                status: SITE_STATUS.PUBLISHED,
                pages: this.site.pages ?? [],
            };

            await this.$sites.refreshSites();
        },

        /**
         * Code that runs when a template is selected in the unlayer template selector.  This code must convert the template
         * into the appropriate format and then invoke `createAndLoadSiteFromTemplate` in the `$sites` provider.
         *
         * @param {object} templateSource The template that was selected
         * @param {'global'|'site'|'unlayer'|'blank'} templateType See [MARKETING_SITE_TEMPLATE_TYPES]
         * @return {Promise<void>}
         */
        onTemplateSelected({ templateSource, templateType } = {}) {
            this.isProcessing = true;

            try {
                return this.$sites.createAndLoadSiteFromTemplate(templateSource, templateType);
            } finally {
                this.isProcessing = false;
            }
        },

        async onPendingTemplateSelected() {
            this.isProcessing = true;
        },
    },
};
</script>

<style lang="scss" scoped>
    .marketing-site-provider {
        --modal-padding: 0
    }

    .stage-indicator-container {
        position: fixed;
        top: 0;
        height: 1px;
        z-index: 10;
        width: calc(100% + #{$gp * 5});
        text-align: center;
    }

    .stage-indicator {
        display: inline-flex;
        margin-top: $gp * 0.8;
    }
</style>

<i18n>
{
    "en-us": {
        "error.loadingPage": "There was an error loading this page",
        "titleRenamePage": "Rename a page",
        "titleCreatePage": "Create a page",
        "titleCreatePageButton": "Create",
        "changeSiteNameError": "There was an error changing the name",
        "titleChooseTemplate": "Choose a template",
        "titleEditBuilder": "Edit landing page",
        "initializeSiteError": "There was an error setting up this landing page.  Please try again.",
        "titleCreateBuilder": "Create landing page",
        "publishError": "There was an error publishing. Try again later",
        "loadPageError": "There was an error loading this page",
        "stages": {
            "create": "Create",
            "chooseTemplate": "Template",
            "publish": "Publish"
        },
        "saveTemplateErrorMessage": "There was a problem saving your template",
        "toast.template.saved": "Your template {name} has been saved."
    }
}
</i18n>
