<template>
    <div class="number-column">
        <div class="number-container">
            {{ number }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        number: {
            type: String || Number,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.number-column {
    width: px-to-rem(28px);
}

.number-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: px-to-rem(2px) solid $color-forest;
    border-radius: 50%;
    max-width: 100%;
    width: px-to-rem(28px);
    height: px-to-rem(28px);
}
</style>
