import { KEAP_CUSTOM_LINK_TYPES } from '@/marketingSites/unlayer/unlayer.constants';
import {
    buildMarketingPageUrl,
} from '@/marketingSites/components/site/site.utils';
import { unlayerDesign } from '@/marketingSites/unlayer/unlayer-design-processor';

const LINK_TYPE_ATTR = 'data-keap-link-type';
const LINK_VALUE_ATTR = 'data-keap-link';

/**
 * Finds all links to other pages within this document, and updates their references to point at the latest slug
 * slugs.
 * @param html Raw html string to be processed
 * @param appId
 * @param site
 * @return {string}
 */
export function updateLinksInHtml(html, appId, site) {
    return processAllUrlsInHtmlBlock(html, (oldUrl, linkType) => {
        if (linkType === KEAP_CUSTOM_LINK_TYPES.marketingPage) {
            return calculateNewUrl(oldUrl, appId, site);
        }

        return null;
    });
}

export function updateLinksInDesign(design, appId, site) {
    return processAllUrlsInUnlayerDesign(design, (url, urlType) => {
        if (urlType === KEAP_CUSTOM_LINK_TYPES.marketingPage) {
            return calculateNewUrl(url, appId, site);
        }

        return null;
    });
}

/**
 * Given an existing url `href`, ensures that the slugs in the form URL match the site/page that they are pointed at.
 * This method is required since unlayer stores only a single value for customLink form field.  To work around this,
 * we are appending some extra metadata in the form of hash values to the page URI, and then parsing them out.
 *
 * An example URL:
 * `[appId]/old-site-slug/old-page-slug.html#pageRef=[oldPageId];siteRef=[oldSiteId];appId=[appId]`
 *
 * To see where these hash values are appended, see keap-unlayer-tools/unlayer-tools/custom-link-types and search for "siteRef".
 *
 * If the URL is out of date, then the corrected URL will be returned. Otherwise, null is returned.
 * If the URL does not have the siteRef and pageRef parameters, then null will be returned.
 *
 * @param href The url to be converted
 * @param appId The current appId
 * @param site The site containing current metadata for all pages
 * @return {string|null}
 */
export function calculateNewUrl(href, appId, site) {
    const { id: siteId, slug: siteSlug, pages } = site;

    const [, hash = ''] = href?.split('#') ?? [];
    const { pageRef, siteRef } = parseHashParameters(hash);

    if (pageRef && siteRef) {
        if (siteRef !== siteId) {
            throw Error('Site mismatch.  Something strange is going on');
        }
        const { slug: currentPageSlug, id: currentPageId } = pages.find((page) => page.id === pageRef) ?? {};

        const newUrl = `${buildMarketingPageUrl(appId, siteSlug, currentPageSlug)}${buildHashParameters(appId, siteId, currentPageId)}`;

        if (newUrl !== href) {
            return newUrl;
        }
    }

    return null;
}

/**
 * Converts a MarketingPage URL from a cloned site:
 *
 * * Updates the appId/site/page slugs in the path
 * * Updates the hash parameters to reference the new site
 *
 * If processing the URL did not result in a change, or if the URL does not contain hash parameters, then `null` is returned.
 *
 * @param href
 * @param appId
 * @param site
 * @param oldPageToNewPageMap
 * @return {string|null} null if this provided link is invalid, or if processing the link did not produce a change
 */
export function convertUrlSlugsForClone(href, appId, site, oldPageToNewPageMap) {
    const { id: newSiteRef, slug: siteSlug, pages } = site;
    const [url, hash = ''] = href?.split('#') ?? [];
    const { pageRef, siteRef } = parseHashParameters(hash);

    if (pageRef && siteRef) {
        const newPageRef = oldPageToNewPageMap[pageRef]?.id ?? site.pages.find((p) => p.slug === url.replace(/.*\//, '').replace(/\.html$/, ''))?.id;
        const currentPageSlug = pages.find((p) => p.id === newPageRef)?.slug;

        if (!currentPageSlug || !appId || !siteSlug) {
            return null;
        }
        const newUrl = `${buildMarketingPageUrl(appId, siteSlug, currentPageSlug)}${buildHashParameters(appId, newSiteRef, newPageRef)}`;

        if (newUrl !== href) {
            return newUrl;
        }

        return null;
    }

    return null;
}

export function buildHashParameters(appId, siteId, pageId) {
    return `#appId=${appId};siteRef=${siteId};pageRef=${pageId}`;
}

export function parseHashParameters(hash) {
    return hash.split(';').reduce((obj, param) => {
        const [key, value] = param.split('=');

        obj[key] = value;

        return obj;
    }, {});
}

/**
 * Processes all the URLs within an html block.  The `generateNewUrl` callback provides the strategy for inspecting the
 * link, as sometimes we are cloning pages from one site to another, and sometimes we are simply looking for broken
 * links in the same site instance.
 *
 * The generateNewUrl takes in the URL and the linkType and returns a new URL if changed.
 *
 * This function returns the updated HTML
 *
 * @param {string} html
 * @param generateNewUrl
 * @return {string}
 */
export function processAllUrlsInHtmlBlock(html, generateNewUrl) {
    const root = document.createElement('div');

    root.innerHTML = html;
    root.querySelectorAll('a[data-keap-link-type]').forEach((link) => {
        const href = link.getAttribute('href');
        const linkType = link.getAttribute(LINK_TYPE_ATTR) ?? KEAP_CUSTOM_LINK_TYPES.marketingPage;
        const newUrl = generateNewUrl(href, linkType);

        if (newUrl) {
            link.setAttribute('href', newUrl);
            link.setAttribute(LINK_VALUE_ATTR, newUrl);
        }
    });

    root.querySelectorAll('[success-redirect]').forEach((redirector) => {
        const href = redirector.getAttribute('success-redirect');
        const linkType = redirector.getAttribute(LINK_TYPE_ATTR) ?? KEAP_CUSTOM_LINK_TYPES.marketingPage;
        const newUrl = generateNewUrl(href, linkType);

        if (newUrl) {
            redirector.setAttribute('success-redirect', newUrl);
        }
    });

    return root.innerHTML;
}

/**
 * Processes all the URLs within an unlayer design object.  The `generateNewUrl` callback provides the strategy for inspecting the
 * link.  The generateNewUrl takes in the URL and the linkType.
 *
 * The update strategy is:
 * * For each cell, loop over all configuration values and look for things that "look like" link properties
 * * For TinyMCE-based cells, process the `values.text` property as HTML, replacing links
 * * For `menu` cells, each menu item contains a `link` property which needs to be replaced.
 * This function returns the updated design document.
 *
 * @param {object} design Unlayer design document
 * @param {function} generateNewUrl A callback that inspects a URL and returns a new updated URL with references updated
 * @return {object} The updated unlayer design
 */
export function processAllUrlsInUnlayerDesign(design, generateNewUrl) {
    unlayerDesign(design).allCells().forEach((cell) => {
        const { cellType, content: { values = {} } = {} } = cell;

        // eslint-disable-next-line no-unused-vars
        for (const [_, value] of Object.entries(values)) {
            if (value && typeof value === 'object' && value?.attrs?.href) {
                replaceUrlsInLinkProperty(value, generateNewUrl);
            }
        }

        if (cellType === 'text' || cellType === 'heading') {
            values.text = processAllUrlsInHtmlBlock(values?.text ?? '', generateNewUrl);
        } else if (cellType === 'menu') {
            values.menu.items?.forEach((item) => {
                if (item.link) {
                    replaceUrlsInLinkProperty(item.link, generateNewUrl);
                }
            });
        }
    });

    return design;
}

function replaceUrlsInLinkProperty(linkData, generateNewUrl) {
    const { attrs = {}, values = {} } = linkData ?? {};
    const linkType = attrs[LINK_TYPE_ATTR];

    let newUrl;
    let oldUrl;

    if (linkType === KEAP_CUSTOM_LINK_TYPES.marketingPage) {
        oldUrl = values.marketingPage;

        newUrl = generateNewUrl(oldUrl, KEAP_CUSTOM_LINK_TYPES.marketingPage);

        if (newUrl) {
            values.marketingPage = newUrl;
        }
    }
}
