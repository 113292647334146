import Vue from 'vue';
import gql from 'graphql-tag';
import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/database';

export const createBroadcastMutation = async ({
    recipients = [],
    splitTest = false,
    id = 0,
    bardJson = '',
    from = '',
    htmlBody = '',
    previewText = '',
    subject = '',
} = {}) => {
    const payload = {
        id: 0,
        recipients,
        splitTest,
        emailTemplate: {
            id,
            bardJson,
            from,
            htmlBody,
            previewText,
            subject,
        },
    };

    const { data: { createBroadcast: { broadcastId } } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createBroadcast($payload: BroadcastInput!) {
                createBroadcast(payload: $payload) {
                    broadcastId
                }
            }
        `,
        variables: {
            payload,
        },
        fetchPolicy: 'no-cache',
    });

    return broadcastId;
};

export const updateBroadcastMutation = async ({
    bardJson,
    from,
    htmlBody,
    id,
    previewText,
    splitTest = false,
    subject,
}) => {
    const payload = {
        id,
        splitTest,
        emailTemplate: {
            bardJson,
            from,
            htmlBody,
            previewText,
            subject,
        },
    };

    await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateBroadcast($broadcastId: ID!, $payload: BroadcastInput!) {
                updateBroadcast(broadcastId: $broadcastId, payload: $payload) {
                    success
                }
            }
        `,
        variables: {
            broadcastId: id,
            payload,
        },
        fetchPolicy: 'no-cache',
    });
};

export const updateBroadcastScheduleMutation = async ({ appId, broadcastId, scheduleData }) => {
    return firebase.database()
        .ref(`broadcastSchedules/${appId}/${broadcastId}`)
        .set(scheduleData);
};

export const broadcastQuery = async (id) => {
    const { data: { broadcast } } = await Vue.prototype.$graphql.query({
        query: gql`
            query getBroadcast($broadcastId: ID!) {
                broadcast(broadcastId: $broadcastId) {
                    broadcastRecipientStatistics {
                        duplicateRecipients
                        eligibleRecipients
                        ineligibleRecipients
                        throttled
                        totalRecipients
                    }
                    editable
                    emailTemplate {
                        bardJson
                        from
                        htmlBody
                        id
                        previewText
                        subject
                        title
                    }
                    id
                    recipients {
                        count
                        id
                        name
                        type
                    }
                    splitTest
                }
            }
        `,
        variables: {
            broadcastId: id,
        },
        fetchPolicy: 'no-cache',
    });

    return broadcast;
};

export const broadcastListQuery = async ({ limit = 20, offset = 0 }) => {
    const { data: { broadcasts } } = await Vue.prototype.$graphql.query({
        query: gql`
            query broadcasts($limit: Int, $offset: Int) {
                broadcasts(limit: $limit, offset: $offset) {
                    broadcastRecipientStatistics {
                        duplicateRecipients
                        eligibleRecipients
                        ineligibleRecipients
                        throttled
                        totalRecipients
                    }
                    clickCount
                    draftProgressStatus {
                        isAudienceComplete
                        isDetailsComplete
                        isTimingComplete
                    }
                    editor
                    id
                    lastEdit
                    mailBatchId
                    openCount
                    recipients {
                        count
                        id
                        name
                        type
                    }
                    scheduleDate
                    sentCount
                    sentDate
                    splitBroadcastType
                    status
                    subject
                }
            }
        `,
        variables: {
            limit,
            offset,
        },
        fetchPolicy: 'no-cache',
    });

    return broadcasts;
};

export const broadcastScheduleQuery = async ({ appId, broadcastId }) => {
    const snapshot = await firebase.database()
        .ref(`broadcastSchedules/${appId}/${broadcastId}`)
        .once('value');

    return snapshot.val();
};

export const uploadEmailImageMutation = async ({ fileName, base64FileData }) => {
    const { data } = await axios.post(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/file/files`, {
        file_name: fileName,
        file_data: base64FileData,
        is_public: true,
        file_association: 'COMPANY',
    });

    return data;
};

export const searchEmailRecipientsQuery = async ({ query }) => {
    const { data: { emailBroadcastRecipientCriteria } } = await Vue.prototype.$graphql.query({
        query: gql`
            query emailBroadcastRecipientCriteria($query: String!) {
                emailBroadcastRecipientCriteria(query: $query) {
                    category
                    id
                    label
                    labelSubtext
                    includeCount
                    additionalInfo {
                        itemName
                        itemValue
                        itemSuffix
                        itemType
                    }
                }
            }
        `,
        variables: {
            query,
        },
        fetchPolicy: 'no-cache',
    });

    return emailBroadcastRecipientCriteria;
};

export const addRecipientMutation = async ({ broadcastId, recipient }) => {
    const { data: { addRecipient: { eligibleRecipients } } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation addRecipient($broadcastId: ID!, $payload: RecipientCriteriaInput!) {
                addRecipient(broadcastId: $broadcastId, payload: $payload) {
                    duplicateRecipients,
                    eligibleRecipients,
                    ineligibleRecipients,
                    throttled,
                    totalRecipients,
                }
            }
        `,
        variables: {
            broadcastId,
            payload: recipient,
        },
        fetchPolicy: 'no-cache',
    });

    return eligibleRecipients;
};

export const getRecipientCountQuery = async ({ category, id }) => {
    const { data: { recipientCount } } = await Vue.prototype.$graphql.query({
        query: gql`
            query getRecipientCount($category: String!, $id: ID!) {
                recipientCount(category: $category, id: $id)
            }
        `,
        variables: {
            category,
            id,
        },
        fetchPolicy: 'no-cache',
    });

    return recipientCount;
};

export const removeRecipientMutation = async ({ broadcastId, recipient }) => {
    const { data: { removeRecipient: { eligibleRecipients } } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation removeRecipient($broadcastId: ID!, $payload: RecipientCriteriaInput!) {
                removeRecipient(broadcastId: $broadcastId, payload: $payload) {
                    duplicateRecipients
                    eligibleRecipients
                    ineligibleRecipients
                    throttled
                    totalRecipients
                }
            }
        `,
        variables: {
            broadcastId,
            payload: recipient,
        },
        fetchPolicy: 'no-cache',
    });

    return eligibleRecipients;
};

export const sendTestMutation = async ({ payload }) => {
    await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation sendTestEmail($payload: SendTestEmailInput!) {
                sendTestEmail(payload: $payload) {
                    success
                }
            }
        `,
        variables: {
            payload,
        },
        fetchPolicy: 'no-cache',
    });
};

export const sendBroadcastNowMutation = async ({ broadcastId }) => {
    await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation sendNow($broadcastId: ID!) {
                sendNow(broadcastId: $broadcastId) {
                    success
                }
            }
        `,
        variables: {
            broadcastId,
        },
        fetchPolicy: 'no-cache',
    });
};

export const sendBroadcastScheduleMutation = async ({ broadcastId, payload }) => {
    await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation sendAsScheduled($broadcastId: ID!, $payload: ScheduleBroadcastInput!) {
                sendAsScheduled(broadcastId: $broadcastId, payload: $payload) {
                    success
                }
            }
        `,
        variables: {
            broadcastId,
            payload,
        },
        fetchPolicy: 'no-cache',
    });
};

export const sendBroadcastAtOptimalTimeMutation = async ({ broadcastId }) => {
    await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation sendAtOptimalTime($broadcastId: ID!) {
                sendAtOptimalTime(broadcastId: $broadcastId) {
                    success
                }
            }
        `,
        variables: {
            broadcastId,
        },
        fetchPolicy: 'no-cache',
    });
};
