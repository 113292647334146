<template>
    <div>
        <h6 v-if="hasHeader" class="heading">
            <slot name="header" />
            {{ heading }}
        </h6>

        <div class="content">
            <textarea v-model="embedContent" readonly />
        </div>

        <div v-if="helpUrl" class="learn-how">
            <p>{{ $t('helpText') }}</p>
            <a
                :href="helpUrl"
                class="help-text"
                target="_blank"
                rel="noopener noreferrer"
            >
                {{ $t('helpArticle') }}
            </a>
        </div>

        <div class="button-row">
            <slot name="button" :label="$t('copyCode')">
                <component
                    :is="copyButtonComponent"
                    leading-icon="copy"
                    data-qa="lead-form-copy-code"
                    @click="copyEmbed"
                >
                    {{ $t('copyCode') }}
                </component>
            </slot>

            <div
                v-if="copied"
                data-qa="copy"
                class="copy"
            >
                {{ $t('copied') }}
            </div>
        </div>
    </div>
</template>

<script>
import amplitude from '@/analytics/amplitude';
import { trackFormCopiedCodeLink } from '@/customForms/analytics';

import { COPY_TEXT_AUTOHIDE_DELAY } from '@/shared/constants/timing.constants';

export default {
    props: {
        embedContent: String,
        helpUrl: String,
        source: String,
        heading: String,
        isCheckoutForm: Boolean,
        copyButtonComponent: {
            type: String,
            default: 'ds-filled-button',
        },
        helpAtTop: {
            default: false,
            type: Boolean,
        },
        contentType: String,
    },

    data() {
        return {
            copied: false,
        };
    },

    computed: {
        hasHeader() {
            return Boolean(this.heading || this.$slots.header);
        },
    },

    methods: {
        async copyEmbed() {
            const errorMessage = this.$t('errorMessage');

            try {
                await this.$copyText(this.embedContent);
                this.toggleCopyCode();

                if (this.contentType?.toLowerCase()?.includes('form')) {
                    trackFormCopiedCodeLink(this.source, 'Code', this.contentType);
                }

                this.$track('Embed Code Copied', { source: this.source });

                if (this.isCheckoutForm) {
                    amplitude.v2.logEvent(amplitude.v2.events.CHECKOUT_FORM_V2_COPY_CODE);
                }
            } catch (error) {
                this.$error({ message: errorMessage });
            }
        },

        toggleCopyCode() {
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, COPY_TEXT_AUTOHIDE_DELAY);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .content {
        background-color: $color-gray-100;
        border-radius: $border-radius;
        padding: 0 $gp 0;
        margin-bottom: $gp;
    }

    textarea {
        border: none;
        resize: none;
        width: 100%;
        height: px-to-rem(60px);
        margin: $gp 0;
        background-color: $color-gray-100;
        font-family: "Lucida Console", Monaco, monospace;

        @media($small) {
            height: px-to-rem(240px);
        }
    }

    .heading {
        margin-bottom: $gp;
        font-size: $font-size-med;
        font-weight: $font-weight-regular;
    }

    .learn-how {
        display: flex;
        color: $color-text-subtle;
        align-items: baseline;
    }

    .help-text {
        margin-left: $gp / 4;
    }

    .button-row {
        padding: 0 $gp / 4;
        justify-content: left;
        display: flex;
        align-items: baseline;
    }

    .copy {
        margin-left: $gp;
        font-size: $font-size-sm;
        color: $color-text-subtle;
        align-self: center;
    }

</style>

<i18n>
{
    "en-us": {
        "copyCode": "Copy code",
        "copied": "Copied!",
        "helpText": "Copy code and paste it into your website's HTML. Learn how in this",
        "learnHow": "Learn how",
        "errorMessage": "The code could not be copied",
        "helpArticle": "help article."
    }
}
</i18n>
