import Vue from 'vue';

export default {
    SET_FEATURE_FLAGS(state, featureFlags) {
        setFeatureFlags(state, featureFlags);
    },
};

const setFeatureFlags = (state, featureFlags) => {
    for (const flag in featureFlags) {
        if (flag && featureFlags[flag]) {
            Vue.set(state, featureFlags[flag], true);
        }
    }
};
