export const SMS_WIZARD_STAGE = {
    WRITE: 0,
    SEND: 1,
};

export const SMS_BROADCAST_STATUS = {
    SCHEDULED: 'SCHEDULED',
    SENDING: 'SENDING',
    SENT: 'SENT',
    DRAFT: 'DRAFT',
    STOPPED: 'STOPPED',
    ERROR: 'FAILURE',
};

export const SEND_NOW = 'sendNow';
export const SEND_LATER = 'sendLater';

export const SMS_OPT_STATUSES = {
    SINGLE_OPT_IN: 'SINGLE_OPT_IN',
    DOUBLE_OPT_IN: 'DOUBLE_OPT_IN',
    OPT_OUT: 'OPT_OUT',
    UNCONFIRMED: 'UNCONFIRMED',
};

export const MAX_RECIPIENTS = 500;

export const MAX_CHARACTER_COUNT = 160;

export const SUPPORTED_COUNTRIES = ['US'];

export const TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS';

export const SUPPORTED_MERGE_FIELDS = {
    '[[contact.first_name]]': 'contact.firstName',
};
