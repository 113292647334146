<template lang="html">
    <div class="import-csv">
        <stage-indicators
            v-if="!importPending"
            :active="stages[activeStage]"
            :stages="stages"
            @changeStage="changeStage"
        />

        <div>
            <upload-stage
                v-show="isUploadStage"
                ref="uploadStage"
                @next="next"
            />

            <organize-stage
                v-show="isOrganizeStage"
                ref="organizeStage"
                @next="next"
            />

            <final-stage
                v-show="isFinalStage"
                :reset-permission="resetPermission"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import amplitude from '@/analytics/amplitude';
import UploadStage from '@/import/components/csv/UploadStage';
import OrganizeStage from '@/import/components/csv/OrganizeStage';
import FinalStage from '@/import/components/csv/FinalStage';
import StageIndicators from '@/shared/components/StageIndicators';
import { IMPORT_STAGES, AMPLITUDE_IMPORT_EVENT_SOURCES } from '@/import/import.constants';

export default {
    components: {
        UploadStage,
        OrganizeStage,
        FinalStage,
        StageIndicators,
    },

    data() {
        return {
            title: this.$t('importContacts.title'),
            nestedModal_title: this.$t('importContacts.title'),
            nestedModal_actionMethod: 'next',
            nestedModal_stickyDesktopHeader: true,
        };
    },

    mounted() {
        this.$bus.$on('SHORTKEY_ESC', this.handleClose);
    },

    beforeDestroy() {
        this.$bus.$off('SHORTKEY_ESC', this.handleClose);
    },

    watch: {
        activeStage(newStage) {
            if (newStage === IMPORT_STAGES.ORGANIZE && this.mappingOptions) {
                this.$refs.organizeStage.previewImport();
            }
        },

        importPending(newValue) {
            if (newValue) {
                this.notifyStarted();
            }
        },

        nestedModal_actionDisabled(value) {
            this.$emit('disableChanged', value);
        },
    },

    computed: {
        ...mapState({
            activeStage: ({ contactImport }) => contactImport.activeStage,
            importSource: ({ contactImport }) => contactImport.importSource,
            singleOptIn: ({ contactImport }) => contactImport.singleOptIn,
            stages: ({ contactImport }) => contactImport.stages,
        }),

        ...mapGetters({
            stagesArray: 'contactImport/stagesAsArray',
            mappingOptions: 'contactImport/mappingOptions',
            importPending: 'contactImport/importPending',
            isUploadStage: 'contactImport/isUploadStage',
            isOrganizeStage: 'contactImport/isOrganizeStage',
            isFinalStage: 'contactImport/isFinalStage',
        }),

        activeStage: {
            get() {
                return this.$store.state.contactImport.activeStage;
            },

            set(stage) {
                this.$store.commit('contactImport/SET_ACTIVE_STAGE', stage);
            },
        },

        nestedModal_actionText() {
            return this.activeStage !== IMPORT_STAGES.FINAL ? this.$t('global.next') : this.$t('importContacts.actions.finishImport');
        },

        nestedModal_actionDisabled() {
            return !this.stages[this.activeStage] || !this.stages[this.activeStage].ready;
        },

        resetPermission() {
            return this.activeStage === IMPORT_STAGES.FINAL;
        },

        eventSource() {
            return AMPLITUDE_IMPORT_EVENT_SOURCES[this.importSource];
        },
    },

    methods: {
        changeStage({ id }) {
            if (this.stages[id].ready) {
                this.$store.commit('contactImport/SET_ACTIVE_STAGE', id);
            }
        },

        next() {
            if (this.activeStage === IMPORT_STAGES.UPLOAD) {
                this.activeStage = IMPORT_STAGES.ORGANIZE;
                this.$track('Contacts - Import - Upload - clicked : Next');
                amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORT_UPLOADED, { 'Event Source': this.eventSource });
            } else if (this.activeStage === IMPORT_STAGES.ORGANIZE) {
                this.activeStage = IMPORT_STAGES.FINAL;
                this.$track('Contacts - Import - Organize - clicked : Next');
                amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORT_FIELDS_MATCHED, { 'Event Source': this.eventSource });
            } else {
                this.$track('Contacts - Import - Organize - clicked : Finish Import');
                this.importContacts();
                amplitude.v2.logEvent(amplitude.v2.events.CONTACTS_IMPORT_MARKETABILITY_SELECTED, {
                    'Event Source': this.eventSource,
                    'Marketability Permission': this.singleOptIn ? 'Yes' : 'No',
                });
            }
        },

        importContacts() {
            this.$track('Contacts - click : Import started');

            this.$bus.$emit('IMPORT_STARTED');

            this.$store.dispatch('contactImport/IMPORT_CONTACTS');
        },

        handleClose() {
            this.$bus.$emit('POP_NESTED_MODAL');
        },

        nestedModal_open() {
            if (this.$refs.uploadStage) {
                this.$refs.uploadStage.clear();
            }

            this.nestedModal_reset();
        },

        nestedModal_reset() {
            this.activeStage = IMPORT_STAGES.UPLOAD;
        },

        notifyStarted() {
            this.handleClose();
            this.$toast({
                message: this.$t('importContacts.status.importStarted'),
            });
        },
    },
};
</script>
