import { orderStatus } from '@/money/constants/order-status';

export const invoiceIsRefunded = (invoice) => {
    return invoice && (invoice.status === orderStatus.REFUNDED || invoice.totalRefundAmount > 0);
};

export const invoiceTotalDue = (invoice) => {
    return !invoice || invoiceIsRefunded(invoice) ? 0 : invoice.total - invoice.totalPaid;
};

export default {
    invoiceIsRefunded,
    invoiceTotalDue,
};
