export default {
    arrayFromMap(map) {
        const array = [];

        if (map) {
            Object.keys(map).forEach((key) => {
                const object = { uid: key };
                const values = map[key];

                Object.keys(values).forEach((subKey) => {
                    object[subKey] = values[subKey];
                });

                array.push(object);
            });
        }

        return array;
    },
};
