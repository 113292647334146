<template>
    <ds-input-field
        ref="inputField"
        v-model.trim="name"
        data-qa="automation-name"
        required
        :maxlength="maxlength"
        :label="$t(isTemplate ? 'templateLabel' : 'inputLabel')"
        :invalid="!isValid"
        submitted
        @focus="selectAll"
        @input="update"
        @blur="handleBlur"
    />
</template>

<script>
import { MAXLENGTH } from '@/automations/constants/automations.constants';

export default {
    props: {
        value: String,
        isTemplate: Boolean,
    },

    data() {
        return {
            name: this.value,
        };
    },

    computed: {
        maxlength() {
            return this.isTemplate ? MAXLENGTH.TEMPLATE.NAME : MAXLENGTH.NAME;
        },

        isValid() {
            return Boolean(this.name);
        },
    },

    methods: {
        update() {
            if (this.isValid) {
                this.$emit('input', this.name);
            }
        },

        handleBlur() {
            if (!this.isValid) {
                this.name = this.value;
            }
        },

        selectAll() {
            const inputElement = this.$refs.inputField.$refs?.input;

            if (inputElement && typeof inputElement.select === 'function') {
                inputElement.select();
            }
        },
    },
};
</script>

<i18n>
{
    "en-us": {
        "inputLabel": "Automation name",
        "templateLabel": "Template name"
    }
}
</i18n>
