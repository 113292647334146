<template>
    <div class="shopify-trigger configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div>
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="close" />

                    <span class="configure-editor-header-text">{{ isProductType ? $t('productHeader') : $t('cartHeader') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-if="showAnyResult"
                        as="a"
                        class="list-item-option"
                        data-qa="shopify-product"
                        :title="isProductType ? $t('shopifyAnyProductPurchased.title') : $t('shopifyAnyCartAbandoned.title')"
                        :description="isProductType ? $t('shopifyAnyProductPurchased.description') : $t('shopifyAnyCartAbandoned.description')"
                        @click="handleShopifyAny"
                    />

                    <p v-if="!search" class="list-header">
                        {{ $t('productsHeader') }}
                    </p>

                    <p v-if="noProducts" class="empty-list">
                        {{ $t('emptyProducts') }}
                    </p>

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>

                    <ds-list-item
                        v-for="(product, i) in filteredProducts"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :title="product.title"
                        :data-qa="`product_${product.productId}`"
                        :description="product.description"
                        @click="handleShopifyProduct(product)"
                    >
                        <template #trailingSlot>
                            <div>
                                {{ $n(product.price, 'currency') }}
                            </div>
                        </template>
                    </ds-list-item>
                </section>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {
    SHOPIFY_ANY_PRODUCT_PURCHASED,
    SHOPIFY_PRODUCT_PURCHASED,
    SHOPIFY_ANY_CART_ABANDONED,
    SHOPIFY_CART_ABANDONED,
} from '@/automations/constants/automations.constants';
import SaveStatus from '@/automations/components/SaveStatus';

export default {
    components: {
        SaveStatus,
    },

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },

        shopifyTriggerType: {
            type: String,
        },
    },

    data() {
        return {
            loading: false,
            search: '',
            selectedOption: {},
        };
    },

    created() {
        this.init();
    },

    computed: {
        ...mapState({
            shopifyProducts: ({ settings: { integrations } }) => integrations.shopifyProducts,
        }),

        showAnyResult() {
            const search = this.search?.trim()?.toLowerCase();

            if (this.isProductType) {
                return this.$t('shopifyAnyProductPurchased.title').toLowerCase().includes(search)
                    || this.$t('shopifyAnyProductPurchased.description').toLowerCase().includes(search);
            }

            return this.$t('shopifyAnyCartAbandoned.title').toLowerCase().includes(search)
                || this.$t('shopifyAnyCartAbandoned.description').toLowerCase().includes(search);
        },

        shopifyAnyTrigger() {
            if (this.isProductType) {
                return {
                    type: SHOPIFY_ANY_PRODUCT_PURCHASED,
                    sourceId: null,
                    name: this.$t('anyProduct'),
                };
            }

            return {
                type: SHOPIFY_ANY_CART_ABANDONED,
                sourceId: null,
                name: this.$t('anyCart'),
            };
        },

        shopifyProductTrigger() {
            const { title, productId } = this.selectedOption;

            if (this.isProductType) {
                return {
                    type: SHOPIFY_PRODUCT_PURCHASED,
                    sourceId: productId,
                    name: this.$t('specificProduct', { title }),
                };
            }

            return {
                type: SHOPIFY_CART_ABANDONED,
                sourceId: productId,
                name: this.$t('specificCartProduct', { title }),
            };
        },

        noProducts() {
            return !this.loading && !this.shopifyProducts?.length;
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredProducts.length && !this.showAnyResult;
        },

        filteredProducts() {
            const search = this.search?.trim()?.toLowerCase();

            return this.shopifyProducts?.filter(({ title, description, price }) => {
                return title?.toLowerCase().includes(search)
                || description?.toLowerCase().includes(search)
                || this.$n(price, 'currency')?.includes(search);
            });
        },

        isProductType() {
            return this.shopifyTriggerType === SHOPIFY_ANY_PRODUCT_PURCHASED;
        },
    },

    methods: {
        init() {
            const { trigger } = this.automationProperties;

            if (trigger) {
                this.selectedOption = {
                    ...trigger,
                    id: trigger.sourceId,
                };
            } else {
                this.selectedOption = {
                    type: this.shopifyTriggerType,
                    id: null,
                    name: this.$t(this.isProductType ? 'anyProduct' : 'anyCart'),
                };
            }

            if (!this.shopifyProducts?.length) {
                this.loadShopifyProducts();
            }
        },

        async loadShopifyProducts() {
            this.loading = true;

            try {
                await this.$store.dispatch('settings/LOAD_SHOPIFY_PRODUCTS');
            } catch (e) {
                this.$error({ message: this.$t('errorLoadingProducts') });
            }

            this.loading = false;
        },

        handleShopifyAny() {
            this.$emit('updated', this.shopifyAnyTrigger);
            this.$emit('close');
        },

        handleShopifyProduct(product) {
            this.selectedOption = { ...product };
            this.$emit('updated', this.shopifyProductTrigger);
            this.$emit('close');
        },

        close() {
            const { type, sourceId } = this.shopifyProductTrigger;

            if ([SHOPIFY_PRODUCT_PURCHASED, SHOPIFY_CART_ABANDONED].includes(type) && !sourceId) {
                this.handleShopifyAny();
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "shopifyAnyProductPurchased": {
            "name": "Any Shopify product is purchased",
            "title": "Any product",
            "description": "When any product is purchased through Shopify"
        },
        "shopifyAnyCartAbandoned": {
            "name": "Any Shopify cart is abandoned",
            "title": "Any product",
            "description": "When a Shopify cart is abandoned with any product inside"
        },
        "productHeader": "Choose product",
        "cartHeader": "Choose a product in cart",
        "anyProduct": "Any product is purchased",
        "anyCart": "Any cart is abandoned",
        "specificProduct": "({title}) is purchased",
        "specificCartProduct": "a cart with ({title}) is abandoned",
        "productsHeader": "Products",
        "emptyProducts": "None available",
        "search": "Search",
        "emptySearch": "No results found for \"{search}\"",
        "errorLoadingProducts": "There was an issue loading your Shopify products."
    }
}
</i18n>
