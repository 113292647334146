<script>
import { mapState, mapGetters } from 'vuex';

import { api } from '@/automations/api';

import intercom from '@/analytics/intercom';
import sentry from '@/analytics/sentry';

import SingleEmailModal from '@/contacts/components/email/SingleEmailModal';
import ImportContactsSelection from '@/import/components/ImportContactsSelection';
import AddTag from '@/tags/components/AddTag';
import SetupReviews from '@/reviews/components/SetupReviews';
import ProductModalContent from '@/money/components/billing/ProductModalContent';
import InvoiceControls from '@/money/components/invoice/InvoiceControls';
import ReviewsSuccess from '@/reviews/components/ReviewsSuccess';

import { wrapWithProps } from '@/shared/utils/render-functions.util';
import invoiceUtils from '@/money/mixins/invoice-utils';

import { INSTALL_AUTOMATIONS } from '@/automations/constants/automations.constants';
import { TUTORIAL_TYPES } from '@/shared/constants/tutorials.constants';
import { FORM_AUDIENCE_PUBLIC } from '@/customForms/customForm.constants';
import { NEW_APPOINTMENT_TYPE_ROUTE_NAME } from '@/appointments/appointments.constants';

import { trackFormsStartedBuilding } from '@/customForms/analytics';

export default {
    mixins: [invoiceUtils],

    mounted() {
        this.$bus.$on('START_ONBOARDING_TASK', this.startOnboardingTask);
    },

    beforeDestroy() {
        this.$bus.$off('START_ONBOARDING_TASK', this.startOnboardingTask);
    },

    computed: {
        ...mapState({
            tutorials: ({ tutorials }) => tutorials.items,
            userId: ({ auth }) => auth.user.id,

        }),

        ...mapGetters({
            unlayerBuilderEnabled: 'broadcasts/unlayerBuilderEnabled',
        }),

        referralTemplateCreated() {
            return this.tutorials[TUTORIAL_TYPES.REFERRAL_EMAIL_TEMPLATE];
        },
    },

    methods: {
        startOnboardingTask({ taskId }) {
            return this[taskId]();
        },

        setupLeadForm() {
            this.$store.dispatch('onboarding/SET_ACTIVE_LEAD_FORM_TASK', 'setupLeadForm');

            this.createPublicForm();
        },

        setupLeadFormNoContacts() {
            this.$store.dispatch('onboarding/SET_ACTIVE_LEAD_FORM_TASK', 'setupLeadFormNoContacts');

            this.createPublicForm();
        },

        setupPublicLeadForm() {
            this.$store.dispatch('onboarding/SET_ACTIVE_LEAD_FORM_TASK', 'setupPublicLeadForm');

            this.createPublicForm();
        },

        setupPublicLeadFormNotCollecting() {
            this.$store.dispatch('onboarding/SET_ACTIVE_LEAD_FORM_TASK', 'setupPublicLeadFormNotCollecting');

            this.createPublicForm();
        },

        setupPublicLeadFormOther() {
            this.$store.dispatch('onboarding/SET_ACTIVE_LEAD_FORM_TASK', 'setupPublicLeadFormOther');

            this.createPublicForm();
        },

        setupPublicLeadFormIsCollectingWebsite() {
            this.$store.dispatch('onboarding/SET_ACTIVE_LEAD_FORM_TASK', 'setupPublicLeadFormIsCollectingWebsite');

            this.createPublicForm();
        },

        setupPublicLeadFormIsCollectingOnline() {
            this.$store.dispatch('onboarding/SET_ACTIVE_LEAD_FORM_TASK', 'setupPublicLeadFormIsCollectingOnline');

            this.createPublicForm();
        },

        async createPublicForm() {
            trackFormsStartedBuilding('Getting Started task');
            intercom.logEvent(intercom.events.SET_UP_LEAD_FORM_ONBOARDING_TASK_STARTED);

            await this.$store.dispatch('customForms/SET_NEW_FORM_SOURCE', 'Onboarding Tracker');

            this.$router.push({ name: 'customForms.new', params: { audience: FORM_AUDIENCE_PUBLIC.toLowerCase() } });
        },

        async initiateCreateAppointmentType() {
            await this.$store.dispatch('tutorials/UPDATE_TUTORIALS_FOR_USER', {
                [TUTORIAL_TYPES.ONBOARDING.TOURS.APPOINTMENTS]: true,
            });

            this.$router.push({ name: NEW_APPOINTMENT_TYPE_ROUTE_NAME });
        },

        createAppointmentType() {
            this.$store.dispatch('onboarding/SET_ACTIVE_APPOINTMENT_TYPE_TASK', 'createAppointmentType');

            this.initiateCreateAppointmentType();
        },

        createAppointmentTypeConsultation() {
            this.$store.dispatch('onboarding/SET_ACTIVE_APPOINTMENT_TYPE_TASK', 'createAppointmentTypeConsultation');

            this.initiateCreateAppointmentType();
        },

        createAppointmentTypeFollowUp() {
            this.$store.dispatch('onboarding/SET_ACTIVE_APPOINTMENT_TYPE_TASK', 'createAppointmentTypeFollowUp');

            this.initiateCreateAppointmentType();
        },

        createAppointmentTypeNeedsTool() {
            this.$store.dispatch('onboarding/SET_ACTIVE_APPOINTMENT_TYPE_TASK', 'createAppointmentTypeNeedsTool');

            this.initiateCreateAppointmentType();
        },

        createAppointmentTypeNone() {
            this.$store.dispatch('onboarding/SET_ACTIVE_APPOINTMENT_TYPE_TASK', 'createAppointmentTypeNone');

            this.initiateCreateAppointmentType();
        },

        async tourAppointments() {
            await this.$store.dispatch('tutorials/UPDATE_TUTORIALS_FOR_USER', {
                [TUTORIAL_TYPES.ONBOARDING.TOURS.APPOINTMENTS]: false,
            });

            this.$router.push({ name: 'calendar', query: { openTour: true } });
        },

        tourAppointmentsHasTool() {
            this.tourAppointments();
        },

        sendReferralEmail() {
            const blankTemplate = {
                title: '',
                subject: '',
                content: '',
            };

            const referralTemplate = {
                title: this.$t('referralTemplate.title'),
                subject: this.$t('referralTemplate.subject'),
                content: this.$t('referralTemplate.content'),
            };

            intercom.logEvent(intercom.events.SEND_REFERRAL_EMAIL_ONBOARDING_TASK_STARTED);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SingleEmailModal,
                showRootClose: true,
                props: {
                    templateManagerOptions: {
                        shouldOpen: true,
                        createTemplateOptions: this.referralTemplateCreated
                            ? blankTemplate
                            : referralTemplate,
                    },
                    source: 'OnboardingJobs',
                },
            });
        },

        addContact() {
            intercom.logEvent(intercom.events.ADD_A_CONTACT_ONBOARDING_TASK_STARTED);

            this.$bus.$emit('OPEN_ADD_CONTACT_MODAL');
        },

        addContactHasContacts() {
            this.addContact();
        },

        importContacts() {
            intercom.logEvent(intercom.events.IMPORT_CONTACTS_ONBOARDING_TASK_STARTED);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                modalSize: 'fullscreen',
                showBack: true,
                showRootClose: true,
                component: ImportContactsSelection,
            });
        },

        addTag() {
            intercom.logEvent(intercom.events.CREATE_A_TAG_ONBOARDING_TASK_STARTED);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: AddTag,
                data: '',
                modalSize: 'sm',
                showRootClose: true,
            });

            this.$router.push({ name: 'settings.tags' });
        },

        connectTools() {
            intercom.logEvent(intercom.events.CONNECT_YOUR_TOOLS_ONBOARDING_TASK_STARTED);

            this.$router.push({ name: 'settings.integrations' });
        },

        addTask() {
            intercom.logEvent(intercom.events.ADD_A_TASK_ONBOARDING_TASK_STARTED);

            this.$router.push({ name: 'tasks' });
        },

        createPipeline() {
            intercom.logEvent(intercom.events.CREATE_YOUR_PIPELINE_ONBOARDING_TASK_STARTED);

            this.$router.push({
                name: 'pipeline',
                params: {
                    openAddPipelineModal: true,
                },
            });
        },

        editEmailTemplate() {
            intercom.logEvent(intercom.events.EDIT_EMAIL_TEMPLATE_ONBOARDING_TASK_STARTED);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SingleEmailModal,
                showRootClose: true,
                props: {
                    templateManagerOptions: {
                        shouldOpen: true,
                    },
                    source: 'OnboardingJobs',
                },
            });
        },

        sendBroadcast() {
            intercom.logEvent(intercom.events.SEND_A_BROADCAST_ONBOARDING_TASK_STARTED);

            if (this.unlayerBuilderEnabled) {
                this.$router.push({ name: 'unlayer-broadcast' });
            } else {
                this.$router.push({ name: 'email-broadcasts', query: { new: true } });
            }
        },

        setupGoogleReviews() {
            intercom.logEvent(intercom.events.SET_UP_GOOGLE_REVIEWS_ONBOARDING_TASK_STARTED);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: SetupReviews,
                props: {
                    onSuccess: this.openSuccessModal,
                },
            });

            this.$router.push({ name: 'dashboard' });
        },

        sendTestInvoice() {
            const eventSource = 'Getting Started Checklist';

            intercom.logEvent(intercom.events.SEND_TEST_INVOICE_ONBOARDING_TASK_STARTED);

            this.invoiceUtils_openNewInvoice(wrapWithProps(InvoiceControls, { invoiceSource: eventSource }), undefined, undefined, eventSource);

            this.$router.push({ name: 'invoices' });
        },

        addProducts() {
            intercom.logEvent(intercom.events.ADD_PRODUCTS_ONBOARDING_TASK_STARTED);

            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: ProductModalContent,
                showRootClose: true,
                data: {
                    actionType: 'edit',
                    product: {},
                    showSaleOptions: false,
                },
            });

            this.$router.push({ name: 'settings.products' });
        },

        connectPaymentProcessor() {
            intercom.logEvent(intercom.events.CONNECT_PAYMENT_PROCESSOR_ONBOARDING_TASK_STARTED);

            this.$router.push({ name: 'settings.billing' });
        },

        connectQbo() {
            intercom.logEvent(intercom.events.CONNECT_QBO_ONBOARDING_TASK_STARTED);

            this.$router.push({ name: 'settings.integrations' });
        },

        myProfile() {
            intercom.logEvent(intercom.events.REVIEW_YOUR_PROFILE_STARTED);

            this.$router.push({ name: 'settings.profile' });
        },

        businessProfile() {
            intercom.logEvent(intercom.events.REVIEW_MY_BUSINESS_STARTED);

            this.$router.push({ name: 'settings.businessProfile' });
        },

        setupDKIM() {
            intercom.logEvent(intercom.events.SET_UP_DKIM_ONBOARDING_TASK_STARTED);

            this.$router.push({ name: 'settings.emailDeliverability' });
        },

        openSuccessModal() {
            this.$bus.$emit('PUSH_NESTED_MODAL', {
                component: ReviewsSuccess,
            });
        },

        async appointmentInviteEasyAutomation() {
            try {
                const response = await api.installAutomation({
                    id: INSTALL_AUTOMATIONS.NEW_LEAD_FOLLOW_UP_APPOINTMENT_INVITE[process.env.VUE_APP_ENV],
                    userId: this.userId,
                    enable: false,
                });

                const installedAutomationId = response?.data?.installAutomation?.id;

                this.$bus.$emit('ONBOARDING_TASK_CTA_SUCCESS', 'appointmentInviteEasyAutomation');

                await this.$router.push({ name: 'build-automation', params: { id: installedAutomationId } });
                await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'appointmentInviteEasyAutomation');
            } catch (e) {
                this.$error({
                    message: this.$t('automationInstallFailed'),
                    bottom: true,
                });

                sentry.log('Install automation failed', e);
            }
        },

        async quickFollowUpLeadsAutomation() {
            try {
                const response = await this.$store.dispatch('automations/INSTALL_AUTOMATION', {
                    id: INSTALL_AUTOMATIONS.NEW_LEAD_INTRODUCTION[process.env.VUE_APP_ENV],
                    userId: this.userId,
                    enable: false,
                });

                const installedAutomationId = response?.id;

                await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'quickFollowUpLeadsAutomation');

                this.$router.push({ name: 'build-automation', params: { id: installedAutomationId } });
            } catch (e) {
                this.$error({
                    message: this.$t('automationInstallFailed'),
                    bottom: true,
                });

                sentry.log('Install automation failed', e);
            }
        },

        async keepLeadsEngagedAutomation() {
            try {
                const response = await this.$store.dispatch('automations/INSTALL_AUTOMATION', {
                    id: INSTALL_AUTOMATIONS.NEW_LEAD_NURTURE[process.env.VUE_APP_ENV],
                    userId: this.userId,
                    enable: false,
                });

                const installedAutomationId = response?.id;

                await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'keepLeadsEngagedAutomation');

                this.$router.push({ name: 'build-automation', params: { id: installedAutomationId } });
            } catch (e) {
                this.$error({
                    message: this.$t('automationInstallFailed'),
                    bottom: true,
                });

                sentry.log('Install automation failed', e);
            }
        },

        async scheduleConsultationLeadsAutomation() {
            try {
                const response = await this.$store.dispatch('automations/INSTALL_AUTOMATION', {
                    id: INSTALL_AUTOMATIONS.SCHEDULE_CONSULTATION_NEW_LEAD[process.env.VUE_APP_ENV],
                    userId: this.userId,
                    enable: false,
                });

                const installedAutomationId = response?.id;

                await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'scheduleConsultationLeadsAutomation');

                this.$router.push({ name: 'build-automation', params: { id: installedAutomationId } });
            } catch (e) {
                this.$error({
                    message: this.$t('automationInstallFailed'),
                    bottom: true,
                });

                sentry.log('Install automation failed', e);
            }
        },

        async requestReviewsAutomation() {
            try {
                const response = await this.$store.dispatch('automations/INSTALL_AUTOMATION', {
                    id: INSTALL_AUTOMATIONS.PURCHASED_PRODUCT_GET_REVIEWS[process.env.VUE_APP_ENV],
                    userId: this.userId,
                    enable: false,
                });

                const installedAutomationId = response?.id;

                await this.$store.dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'requestReviewsAutomation');

                this.$router.push({ name: 'build-automation', params: { id: installedAutomationId } });
            } catch (e) {
                this.$error({
                    message: this.$t('automationInstallFailed'),
                    bottom: true,
                });

                sentry.log('Install automation failed', e);
            }
        },
    },

    render() {
        return null;
    },
};
</script>

<i18n>
{
    "en-us": {
        "referralTemplate": {
            "title": "Request referral",
            "subject": "A small favor, [[contact.first_name]].",
            "content": "<p>Hi [[contact.first_name]],</p><p>I’ve really enjoyed working with you {add details about the service or project}. I’m asking some of my most trusted clients to help me find more just like you. Is there anyone in your personal network that you think could also benefit from {details about your service}? </p><p>Feel free to share my contact details with them, or simply forward this email and I can take it from there.</p><p>Thanks so much,</p>"
        },
        "automationInstallFailed": "There was a problem installing this automation. Please try again."
    }
}
</i18n>
