import Vue from 'vue';
import gql from 'graphql-tag';

import sentry from '@/analytics/sentry';

export const queryContactDetails = async (id) => {
    const { data: gqlResponse } = await Vue.prototype.$graphql.query({
        query: gql`
            query getContactDetails($id: ID!) {
                contact(id: $id) {
                    id
                    displayName
                    contactType
                    givenName
                    middleName
                    familyName
                    title
                    suffix
                    jobTitle
                    website
                    emailAddresses {
                        type
                        value
                    }
                    phoneNumbers {
                        type
                        value
                    }
                    addresses {
                        type
                        formattedValue
                        streetAddress
                        extendedAddress
                        poBox
                        locality
                        postalCode
                        region
                        regionISO3
                        country
                        countryCode
                    }
                    socialAccounts {
                        type
                        value
                    }
                    faxNumbers {
                        type
                        value
                    }
                    company {
                        id
                        name
                    }
                    birthDate
                    owner
                    spouseName
                    timeZone
                    preferredLocale
                    createTime
                    updateTime
                    anniversary
                }
                contactDetails(contactId: $id) {
                    companies {
                        id
                        companyName
                    }
                    owners {
                        id
                    }
                    contactDetails {
                        fieldGroups {
                            id
                            name
                            fieldValues {
                                contactField {
                                    id
                                    groupId
                                    name
                                    type
                                    values
                                    dataFormFieldId
                                    defaultToFirstOption
                                    customField
                                    visible
                                }
                                value
                            }
                        }
                    }
                    customFields {
                        id
                        label
                        fieldType
                        options {
                            label
                            value
                            options {
                                value
                            }
                        }
                        value
                    }
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });

    const { contact: contactsApiContact, ...response } = gqlResponse;
    const { contactDetails } = response;

    const keapWebContact = transformContactData(contactsApiContact);

    const keapWebResponse = {
        contact: {
            customFields: contactDetails.customFields,
            ...keapWebContact,
        },
        ...response,
    };

    return keapWebResponse;
};

/**
 * Transforms a contact from Contacts API to a contact data shape that will
 * fulfill what Keap Web is currently expecting for a contact.
 *
 * TODO: Once the accompanying feature flag is removed, we should refactor the usage of
 * this data to use Contacts API data natively, and delete this transformer(s).
 *
 * @param object contactsApiContact
 */
export const transformContactData = (contactsApiContact) => {
    const {
        givenName,
        middleName,
        familyName,
        title,
        suffix,
        displayName,
        jobTitle,
        emailAddresses,
        website,
        socialAccounts,
        spouseName,
        timeZone,
        contactType,
        company,
        owner,
        birthDate,
        anniversary,
        addresses,
        phoneNumbers,
        faxNumbers,
    } = contactsApiContact;

    const { email, email2, email3 } = getEmails(emailAddresses);
    const { linkedin, twitter, facebook } = getSocialAccounts(socialAccounts);
    const comapnyId = company
        ? Number(company.id)
        : null;
    const { shippingAddress, billingAddress, otherAddress } = getAddresses(addresses);
    const {
        phone1, phone2, phone3, phone4, phone5,
    } = getPhones(phoneNumbers);
    const { fax1, fax2 } = getFaxes(faxNumbers);

    const keapWebContact = {
        firstName: givenName,
        middleName,
        lastName: familyName,
        title,
        suffix,
        nickname: displayName,
        jobTitle,
        email,
        email2,
        email3,
        website,
        linkedin,
        twitter,
        facebook,
        spouseName,
        timeZone,
        locale: null,
        contactType,
        company: comapnyId,
        owner: Number(owner),
        birthday: birthDate,
        anniversary,
        shippingAddress,
        billingAddress,
        otherAddress,
        phone1,
        phone2,
        phone3,
        phone4,
        phone5,
        fax1,
        fax2,
    };

    return keapWebContact;
};

const getEmails = (emailAddresses) => {
    if (emailAddresses && emailAddresses.length) {
        const emails = emailAddresses.reduce((prev, curr, index) => {
            const currentEmailValue = curr && curr.value
                ? curr.value
                : null;
            const emailKey = index === 0
                ? 'email'
                : `email${index + 1}`;

            prev[emailKey] = currentEmailValue;

            return prev;
        }, {});

        return emails;
    }

    return { email: null, email2: null, email3: null };
};

const getSocialAccounts = (socialAccounts) => {
    const emptySocialAccounts = { linkedin: null, facebook: null, twitter: null };

    if (socialAccounts && socialAccounts.length) {
        const keapWebSocialAccounts = socialAccounts
            .reduce((prev, curr) => {
                switch (curr.type) {
                case 'LINKEDIN':
                    prev.linkedin = curr.value;
                    break;
                case 'FACEBOOK':
                    prev.facebook = curr.value;
                    break;
                case 'TWITTER':
                    prev.twitter = curr.value;
                    break;
                default:
                    // Do nothing
                }

                return prev;
            }, emptySocialAccounts);

        return keapWebSocialAccounts;
    }

    return emptySocialAccounts;
};

const getAddresses = (addresses) => {
    const emptyAddress = {
        street1: null,
        street2: null,
        locality: null,
        region: null,
        postalCode: null,
        countryCode: null,
    };

    const emptyAddresses = {
        shippingAddress: { ...emptyAddress },
        billingAddress: { ...emptyAddress },
        otherAddress: { ...emptyAddress },
    };

    if (addresses && addresses.length) {
        const keapWebAddresses = addresses
            .reduce((prev, curr) => {
                switch (curr.type) {
                case 'SHIPPING':
                    prev.shippingAddress = getAddress(curr);
                    break;
                case 'BILLING':
                    prev.billingAddress = getAddress(curr);
                    break;
                case 'OTHER':
                    prev.otherAddress = getAddress(curr);
                    break;
                default:
                }

                return prev;
            }, emptyAddresses);

        return keapWebAddresses;
    }

    return emptyAddresses;
};

const getAddress = (address) => {
    return {
        street1: address?.streetAddress ?? null,
        street2: address?.extendedAddress ?? null,
        locality: address?.locality ?? null,
        region: address?.regionISO3 ?? address?.region ?? null,
        postalCode: address?.postalCode ?? null,
        countryCode: address?.countryCode ?? null,
    };
};

const getPhones = (phoneNumbers) => {
    const emptyPhoneNumber = {
        value: null,
        extension: null,
        type: null,
    };

    const emptyPhoneNumbers = {
        phone1: { ...emptyPhoneNumber },
        phone2: { ...emptyPhoneNumber },
        phone3: { ...emptyPhoneNumber },
        phone4: { ...emptyPhoneNumber },
        phone5: { ...emptyPhoneNumber },
    };

    if (phoneNumbers && phoneNumbers.length) {
        return phoneNumbers.reduce((prev, curr, index) => {
            if (curr) {
                const currentKeapWebPhone = `phone${index + 1}`;

                prev[currentKeapWebPhone].value = curr.value;
                prev[currentKeapWebPhone].extension = null;

                prev[currentKeapWebPhone].type = typeof curr.type === 'string' && curr.type != null
                    ? curr.type.toUpperCase()
                    : curr.type;

                return prev;
            }

            return prev;
        }, emptyPhoneNumbers);
    }

    return emptyPhoneNumbers;
};

const getFaxes = (faxNumbers) => {
    const emptyFax = {
        value: null,
        type: null,
    };

    const emptyFaxes = {
        fax1: { ...emptyFax },
        fax2: { ...emptyFax },
    };

    if (Array.isArray(faxNumbers) && faxNumbers.length !== 0) {
        return faxNumbers.reduce((prev, curr, index) => {
            if (curr) {
                prev[`fax${index + 1}`].value = curr.value;
                prev[`fax${index + 1}`].type = curr.type;

                return prev;
            }

            return prev;
        }, emptyFaxes);
    }

    return emptyFaxes;
};

export const queryContactPage = async (id) => {
    const { data: gqlResponse } = await Vue.prototype.$graphql.query({
        query: gql`
            query getContactPage($id: ID!) {
                contact(id: $id) {
                    givenName
                    familyName
                    company {
                        name
                    }
                    emailAddresses {
                        value
                    }
                    phoneNumbers {
                        value
                    }
                    createTime
                    updateTime
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });

    const {
        firstName,
        lastName,
        company,
        email,
        phone,
    } = buildGetContactResponse(gqlResponse);

    const contactInfo = {
        firstName,
        lastName,
        company,
        email,
        phone,
    };

    return { contactInfo, ...gqlResponse };
};

export const queryContactPipeline = async (id, pipelineMigrationEnabled) => {
    try {
        const { data: gqlResponse } = await Vue.prototype.$graphql.query({
            query: gql`
                query getContactPipeline($id: ID!) {
                    ${pipelineMigrationEnabled ? 'flattenedPipelinesV2' : 'flattenedPipelines'}(contactId: $id) {
                        id
                        stages {
                            id
                            name
                            order
                            pipelineId
                        }
                        deals {
                            id
                            name
                            value {
                                amount
                                currency
                            }
                            contacts {
                                id
                                primaryContact
                            }
                            stage {
                                id
                                name
                                order
                                pipelineId
                            }
                            stageAssignmentDate
                            owners {
                                id
                            }
                            ownerId
                            order
                            status
                            estimatedCloseDate
                            closedDate
                        }
                    }
                }
            `,
            variables: {
                id,
            },
            fetchPolicy: 'no-cache',
        });

        return {
            pipelines: pipelineMigrationEnabled ? gqlResponse.flattenedPipelinesV2 : gqlResponse.flattenedPipelines,
        };
    } catch (error) {
        sentry.captureException(error);
        throw error;
    }
};

export const queryGetContact = async (id) => {
    const { data: gqlResponse } = await Vue.prototype.$graphql.query({
        query: gql`
            query getContact($id: ID!) {
                contact(id: $id) {
                    givenName
                    familyName
                    company {
                        name
                    }
                    emailAddresses {
                        value
                    }
                    phoneNumbers {
                        value
                    }
                }
            }
        `,
        variables: {
            id,
        },
        fetchPolicy: 'no-cache',
    });

    return buildGetContactResponse(gqlResponse);
};

/**
 * Once the following feature flags are removed, this
 * transform step will no longer be necessary. We'll be
 * able to refactor the contact property usage at the
 * component level to use the canonical names from Contacts API,
 * instead of the contactInfo names from SPA.
 *
 *
 * https://jira.infusionsoft.com/browse/PLAT-2299
 */
const buildGetContactResponse = ({ contact }) => {
    if (!contact) {
        return {};
    }

    const {
        givenName,
        familyName,
        company,
        emailAddresses,
        phoneNumbers,
    } = contact;

    const email = getFirstValue(emailAddresses);
    const phone = getFirstValue(phoneNumbers);

    const webResponse = {
        firstName: givenName,
        lastName: familyName,
        company: company && company.name,
        email,
        phone,
    };

    return webResponse;
};

const getFirstValue = (list) => {
    if (!list || !list.length) {
        return '';
    }

    const firstProperty = list
        .find((property) => property && property.value);

    return firstProperty
        ? firstProperty.value
        : '';
};

export const queryContacts = (filterId, filterParams, limit, offset, sortBy, sortIsAsc, pageToken) => {
    return Vue.prototype.$graphql.query({
        query: gql`
            query getContacts($filterId: ID, $filterParams: String, $limit: Int, $offset: Int, $sortBy: String, $sortIsAsc: Boolean, $pageToken: String) {
                listContacts(filterId: $filterId, filterParams: $filterParams, limit: $limit, offset: $offset, sortBy: $sortBy, sortIsAsc: $sortIsAsc, pageToken: $pageToken) {
                    contacts {
                        id
                        fullName
                        email
                        emailAddresses {
                            value
                            type
                        }
                        phoneNumbers {
                            value
                            type
                        }
                        createDate
                        contactType
                    }
                    nextPageToken
                    isPagingByToken
                }
            }
        `,
        variables: {
            filterId,
            filterParams,
            limit,
            offset,
            sortBy,
            sortIsAsc,
            pageToken,
        },
        fetchPolicy: 'no-cache',
    });
};

export const queryContactEmailHistoryList = async ({
    contactId,
    withBody,
    limit,
    offset,
}) => {
    const { data: { contactEmailHistoryList } } = await Vue.prototype.$graphql.query({
        query: gql`
            query contactEmailHistoryList($contactId: ID!, $withBody: Boolean, $limit: Int, $offset: Int) {
                contactEmailHistoryList(contactId: $contactId, withBody: $withBody, limit: $limit, offset: $offset) {
                    emails {
                        emailId
                        from
                        to
                        subject
                        body
                        sentDate
                        provider
                        dateOpened
                        clickDate
                        bounceDate
                    }
                }
            }
        `,
        variables: {
            contactId,
            withBody,
            limit,
            offset,
        },
        fetchPolicy: 'no-cache',
    });

    return contactEmailHistoryList;
};

export const exportsForTests = {
    queryGetContact,
    buildGetContactResponse,
    getFirstValue,
    transformContactData,
    getEmails,
    getSocialAccounts,
    getAddresses,
    getAddress,
    getPhones,
    getFaxes,
    queryContactEmailHistoryList,
    queryContactPipeline,
};
