<template lang="html">
    <section class="app-placeholder">
        <div class="spinner-wrapper">
            <ds-logo
                v-if="!isPublicRoute"
                data-qa="logo"
                class="logo"
                icon-only
            />

            <ds-spinner :size="spinnerSize" data-qa="spinner" />
        </div>
    </section>
</template>

<script>

import { PUBLIC_ROUTES } from '@/shared/constants/routes.constants';

export default {

    computed: {
        isPublicRoute() {
            const { pathname } = window.location;

            return PUBLIC_ROUTES.some((route) => pathname.includes(route));
        },

        spinnerSize() {
            return this.isPublicRoute ? null : 120;
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .app-placeholder {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;
    }

    .logo {
        --logo-width: #{px-to-rem(30px)};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .spinner-wrapper {
        position: relative;
    }
</style>
