export const PUBLIC_ROUTES = [
    '/logout',
    '/signup',
    '/declined',
    '/page-not-found',
    '/invoice',
    '/quote',
    '/booking',
    '/contact-us',
    '/videocall',
    '/checkout',
    '/payment/auth',
];

export const RETURN_TO_ROUTES = {
    BROADCASTS: 'broadcast',
    CONTACT_LIST: 'contactList',
};
