<template>
    <section class="merge-conversation-modal">
        <div class="search">
            <ds-input-field
                v-model="query"
                autofocus
                :label="$t('search')"
                @input="search"
            />
        </div>

        <div class="create item" @click="createContact">
            <ds-icon class="create-icon" name="user-plus" />

            <span>{{ $t('create') }}</span>
        </div>

        <div
            v-for="(contact, i) in results"
            :key="i"
            class="item"
            @click="merge(contact)"
        >
            <contact-avatar :name="getContactName(contact)" :email="contact.email" />

            <section class="description">
                <span v-if="showFullName(contact)">{{ getContactName(contact) }}</span>

                <span v-if="!showFullName(contact)" class="no-name"><em>{{ $t('noContactName') }}</em></span>
            </section>
        </div>

        <div v-if="noResults" class="no-results">
            <em>{{ $t('noResults') }}</em>
        </div>

        <div v-if="loading" class="loading-spinner">
            <ds-spinner />
        </div>
    </section>
</template>

<script>
import ContactAvatar from '@/shared/components/ContactAvatar';

import displayMixin from '@/shared/mixins/display.mixin';
import searchContactsMixin from '@/shared/mixins/search-contacts.mixin';
import smsMixin from '@/communication/mixins/sms.mixin';

export default {
    components: {
        ContactAvatar,
    },

    mixins: [displayMixin, searchContactsMixin, smsMixin],

    data() {
        return {
            filteredResults: null,
            limit: 20,
            loading: false,
            nestedModal_title: this.$t('title'),
            nestedModal_removeInnerPadding: true,
            phoneNumber: '',
            query: '',
        };
    },

    mounted /* istanbul ignore next */ () {
        this.load();
    },

    computed: {
        noResults() {
            return this.query ? Array.isArray(this.filteredResults) && this.filteredResults.length === 0 : false;
        },

        results() {
            return this.query ? this.filteredResults : this.searchContacts_results;
        },
    },

    methods: {
        async load() {
            this.loading = true;
            await this.searchContacts_loadContactList({ limit: this.limit });
            this.loading = false;
        },

        createContact() {
            this.sms_createNewContact(this.phoneNumber);
        },

        getContactName({ fullName, label }) {
            return fullName || label;
        },

        merge({ id, fullName, label }) {
            const { phoneNumber } = this;
            const formattedPhoneNumber = this.formatPhone(this.phoneNumber);
            const name = fullName || label || this.$t('thisContact');
            const message = this.$t('toastMessage', { fullName: name });
            const action = () => this.viewContact(id);
            const actionText = this.$t('toastAction');

            this.$confirm({
                optionTitle: this.$t('confirm.title'),
                optionMessage: this.$t('confirm.message', { formattedPhoneNumber, fullName: name }),
                optionConfirmButtonLabel: this.$t('confirm.confirmLabel'),
            })
                .then(() => {
                    this.$store.dispatch('communication/MERGE_CONTACT_AND_CONVERSATION', { id, phoneNumber });

                    this.$bus.$emit('POP_NESTED_MODAL');

                    this.$toast({
                        message,
                        action,
                        actionText,
                    });
                });
        },

        nestedModal_open() {
            this.phoneNumber = this.sms_activeConversationPhoneNumber;
        },

        async search() {
            const { query } = this;

            if (query) {
                this.loading = true;
                this.filteredResults = await this.$store.dispatch('communication/SMS_RECIPIENT_SEARCH', { query });
                this.loading = false;
            } else {
                this.filteredResults = null;
            }
        },

        showFullName({ fullName, label }) {
            return fullName != null || label != null;
        },

        viewContact(id) {
            this.$bus.$emit('VIEW_CONTACT', {
                id,
                source: 'Merge Converstion Success',
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $modal-height: px-to-rem(525px);

    .merge-conversation-modal {
        @include overflowY;

        max-height: $modal-height;
        padding-bottom: $gp / 1.5;
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;

        @media($small) {
            max-height: 100%;
        }
    }

    .search {
        padding: $gp;
        --input-margin-bottom: 0;
    }

    .create {
        color: $color-blue;
        --icon-color: #{$color-blue};

        &-icon {
            margin: 0 $gp;
        }
    }

    .item {
        display: flex;
        padding: $gp / 2 $gp;
        font-size: $font-size-med;
        align-items: center;

        &:hover {
            cursor: pointer;
            background: $color-gray-050;
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        padding: 0 $gp;
    }

    .email {
        font-size: $font-size-sm;
        color: $color-gray-700;
    }

    .no-name {
        color: $color-gray-600;
    }

    .no-results {
        display: flex;
        justify-content: center;
        padding: $gp;
        color: $color-gray-600;
    }

    .loading-spinner {
        display: flex;
        justify-content: center;
        padding: $gp;
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Add to contacts",
        "search": "Search contacts",
        "create": "Create new contact",
        "noContactName": "No name",
        "thisContact": "this contact",
        "noResults": "No results",
        "confirm": {
            "title": "Add number?",
            "message": "{formattedPhoneNumber} will be added to {fullName}'s contact record.",
            "confirmLabel": "Add number"
        },
        "toastMessage": "Phone number added to {fullName}",
        "toastAction": "View contact"
    }
}
</i18n>
