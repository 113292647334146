<template>
    <div class="site-builder-page">
        <div v-if="site" class="site-panels">
            <section v-if="!hasPages" class="content-area only-desktop">
                <h2 class="templates-header">
                    {{ $t('addingPage') }}
                </h2>
                <div />
            </section>
            <section v-else-if="ready && focusPage" class="content-area only-desktop">
                <page-builder
                    :site-forms="siteForms"
                    :config="config"
                    :page="focusPage"
                    :site="site"
                    :is-draft="isDraft"
                />
            </section>
            <section v-else class="content-area only-desktop">
                <div class="spinner-container">
                    <ds-spinner />
                </div>
            </section>
        </div>
    </div>
</template>

<script>

import PageBuilder from '@/marketingSites/components/site/PageBuilder';
import clonedeep from 'lodash.clonedeep';

import { mapState } from 'vuex';
import { SITE_STATUS } from '@/marketingSites/marketingSites.constants';

export default {
    components: {
        PageBuilder,
    },

    inject: ['$site'],

    props: {
        site: Object,
        focusedPageId: String,
    },

    data() {
        return {
            ready: false,
            otherSites: [],
            checkoutPages: [],
            focusPage: {},
        };
    },

    async mounted() {
        await this.$store.dispatch('contacts/LOAD_CUSTOM_FIELDS');
        await this.$store.dispatch('customForms/LOAD_FORMS');
        await this.$store.dispatch('smartForms/LOAD_SMART_FORMS');
        this.checkoutPages = await this.$store.dispatch('sales/LOAD_CHECKOUT_FORM_LIST');
        this.ready = true;

        await this.loadFocusPage(this.focusedPageId);
    },

    watch: {
        focusedPageId(newValue, oldValue) {
            this.loadFocusPage(newValue, oldValue);
        },

        site(newValue) {
            if (!this.focusPage && newValue?.pages?.length > 0) {
                this.loadFocusPage(newValue.pages[0].id);
            }
        },
    },

    computed: {
        hasPages() {
            return this.pages.length > 0;
        },

        pages() {
            return this.site?.pages ?? [];
        },

        siteForms() {
            // return extractForms(this.site ?? { pages: [] });
            return [];
        },

        ...mapState({
            companyLogoUrl: ({ auth }) => auth.account.companyProfile.logoUrl,
            appId: ({ auth }) => auth.session.coreAppId,
            customFields: ({ contacts }) => contacts.fieldGroups.flatMap(({ fields = [] }) => clonedeep(fields)),
        }),

        siteName() {
            return this.site?.name ?? this.$t('marketingSites.defaultSiteName');
        },

        isDraft() {
            return !this.site?.status || this.site.status === SITE_STATUS.DRAFT;
        },

        /**
         * TODO: Unlayer plugins
         */
        config() {
            return {
                plugins: [],
            };
        },
    },

    methods: {
        async loadFocusPage(pageToFocus, oldValue) {
            if (!pageToFocus) {
                // Use the first page
                pageToFocus = (this.site?.pages ?? [])[0]?.id;
            }

            if (pageToFocus && pageToFocus !== oldValue) {
                // load the page
                this.focusPage = await this.$site.getMarketingPage(pageToFocus);
            }
        },
    },
};

</script>
<style lang="scss" rel="stylesheet/scss" scoped>
    .site-list-header {
        display: flex;
        align-items: center;
        padding: $gp $gp 0;
        @media ($extra-small-up) {
            padding: $gp * 0.75 $gp;
            --page-header-bottom-margin: #{px-to-rem(1px)};
            border-bottom: 1px solid $color-gray-200;
        }
    }

    .site-builder-page {
        display: block;
        width: calc(100vw);
        height: calc(100vh - 3.5rem);
    }

    .site-panels {
        display: flex;
    }

    .side-panel {
        display: block;
        height: calc(100vh - 3.5rem);
        width: 370px;
        overflow-y: scroll;
    }

    .content-area {
        display: block;
        width: calc(100vw);
        height: calc(100vh - 3.5rem);
    }
</style>
<i18n>
{
    "en-us": {
        "sitePages": "Pages",
        "addingPage": "Adding a Page"
    }
}
</i18n>
