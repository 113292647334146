<template>
    <div class="automation-add-product configure-editor">
        <header class="configure-editor-header">
            <ds-icon-button name="arrow-left" data-qa="back-button" @click="$emit('back')" />

            <span class="configure-editor-header-text">{{ $t('createHeader') }}</span>

            <ds-filled-button
                type="submit"
                class="shift-right"
                data-qa="next-button"
                @click.native="handleNext"
            >
                {{ $t('global.next') }}
            </ds-filled-button>
        </header>

        <form ref="form" class="form" @submit.prevent="handleNext">
            <product-info-form v-model="product" :submitted="submitted" />
        </form>
    </div>
</template>

<script>
import ProductInfoForm from '@/money/components/billing/ProductInfoForm';

export default {
    components: {
        ProductInfoForm,
    },

    data() {
        return {
            product: {
                name: '',
                description: '',
                rate: 0,
                quantity: 1,
            },
            submitted: false,
            eventSource: 'Automations Page',
        };
    },

    methods: {
        handleNext() {
            this.submitted = true;

            if (this.$refs.form.checkValidity()) {
                this.createProduct();
            }
        },

        async createProduct() {
            const product = await this.$store.dispatch('billing/ADD_PRODUCT', {
                productInfo: this.product,
                eventSource: this.eventSource,
            });

            this.$emit('product-created', product);
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "createHeader": "Create new product or service"
    }
}
</i18n>
