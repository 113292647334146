import MarketingSitesRoot from '@/marketingSites/MarketingSitesRoot';

const routes = [
    {
        path: '/marketing-sites',
        name: 'marketing-sites',
        component: MarketingSitesRoot,
        children: [
            {
                path: 'create',
                name: 'marketing.sites.create',
            },
            {
                path: 'edit/:id',
                name: 'marketing.sites.edit',
            },
        ],
    },

    {
        path: '/settings/landing-pages',
        redirect: '/marketing-sites',
    },

];

export default routes;
