import { ERROR, LOADING, SUCCESS } from '@/contacts/loadingStatuses.js';
import Vue from 'vue';

export default {
    LOAD_STYLING_START(state) {
        Vue.set(state.builder, 'stylingLoadingState', LOADING);
    },

    LOAD_STYLING_ERROR(state) {
        Vue.set(state.builder, 'stylingLoadingState', ERROR);
    },

    SAVE_STYLING_START(state) {
        Vue.set(state.builder, 'stylingSavingState', LOADING);
    },

    SAVE_STYLING_ERROR(state) {
        Vue.set(state.builder, 'stylingSavingState', ERROR);
    },

    SET_STYLING_SUCCESS(state, formStyling) {
        Vue.set(state.builder, 'stylingLoadingState', SUCCESS);
        Vue.set(state.builder, 'stylingSavingState', SUCCESS);
        Vue.set(state.builder, 'styling', formStyling);
    },

    LOAD_FORM_START(state) {
        Vue.set(state.builder, 'formLoadingState', LOADING);
    },

    LOAD_FORM_ERROR(state) {
        Vue.set(state.builder, 'formLoadingState', ERROR);
    },

    SAVE_FORM_START(state) {
        Vue.set(state.builder, 'formSavingState', LOADING);
    },

    SAVE_FORM_ERROR(state) {
        Vue.set(state.builder, 'formSavingState', ERROR);
    },

    SET_FORM(state, form) {
        Vue.set(state.builder, 'form', form);
        Vue.set(state.builder, 'formLoadingState', SUCCESS);
        Vue.set(state.builder, 'formSavingState', SUCCESS);
    },

    SYNC_FORMS_LIST(state, updatedForm) {
        const list = updatedForm.formType;
        const matchingIndex = state.forms[list]?.findIndex((form) => `${form.id}` === `${updatedForm.id}`);

        if (matchingIndex >= 0) {
            state.forms[list].splice(matchingIndex, 1, updatedForm);
        }
    },

    REMOVE_FROM_LIST(state, removeId) {
        for (const key in state.forms) {
            if (Object.prototype.hasOwnProperty.call(state.forms, key)) {
                const matchingIndex = state.forms[key].findIndex((form) => `${form.id}` === `${removeId}`);

                if (matchingIndex >= 0) {
                    state.forms[key].splice(matchingIndex, 1);

                    return;
                }
            }
        }
    },

    UPDATE_SORT(state, { field, ascending }) {
        Vue.set(state, 'publicListSortField', field);
        Vue.set(state, 'shouldPublicListPageSortAscending', ascending);
    },

    SET_IS_NEW_FORM(state, isNew) {
        Vue.set(state.builder, 'isNewForm', isNew);
    },

    LOAD_FORMS_START(state) {
        Vue.set(state, 'publicListPageLoadingStatus', LOADING);
    },

    LOAD_FORMS_SUCCESS(state, { forms, formType }) {
        Vue.set(state.forms, formType, forms);
        Vue.set(state, 'publicListPageLoadingStatus', SUCCESS);
        Vue.set(state, 'formType', formType);
    },

    LOAD_FORMS_ERROR(state) {
        Vue.set(state, 'publicListPageLoadingStatus', ERROR);
    },

    SET_NEW_FORM_SOURCE(state, newFormSource) {
        Vue.set(state, 'newFormSource', newFormSource);
    },
};
