import { TEMPLATE_KINDS } from '@/shared/constants/communicationTemplates.constants';

export default {
    currentEmailTemplate: {},
    defaultEmailTemplates: [
        {
            id: 'defaultEmail1',
            templateKind: TEMPLATE_KINDS.INTRODUCTION,
            title: 'defaultEmailTemplates.1.title',
            subject: 'defaultEmailTemplates.1.subject',
            content: 'defaultEmailTemplates.1.body',
        },
        {
            id: 'defaultEmail2',
            templateKind: TEMPLATE_KINDS.INVITATION,
            title: 'defaultEmailTemplates.2.title',
            subject: 'defaultEmailTemplates.2.subject',
            content: 'defaultEmailTemplates.2.body',
        },
        {
            id: 'defaultEmail3',
            templateKind: TEMPLATE_KINDS.FOLLOW_UP,
            title: 'defaultEmailTemplates.3.title',
            subject: 'defaultEmailTemplates.3.subject',
            content: 'defaultEmailTemplates.3.body',
        },
        {
            id: 'defaultEmail4',
            templateKind: TEMPLATE_KINDS.APPOINTMENT,
            title: 'defaultEmailTemplates.4.title',
            subject: 'defaultEmailTemplates.4.subject',
            content: 'defaultEmailTemplates.4.body',
        },
        {
            id: 'defaultEmail5',
            templateKind: TEMPLATE_KINDS.REFERRAL,
            title: 'defaultEmailTemplates.5.title',
            subject: 'defaultEmailTemplates.5.subject',
            content: 'defaultEmailTemplates.5.body',
        },
        {
            id: 'defaultEmail6',
            templateKind: TEMPLATE_KINDS.REVIEW,
            title: 'defaultEmailTemplates.6.title',
            subject: 'defaultEmailTemplates.6.subject',
            content: 'defaultEmailTemplates.6.body',
        },
    ],
    defaultSmsTemplates: [
        {
            id: 'defaultSms1',
            templateKind: TEMPLATE_KINDS.INTRODUCTION,
            title: 'defaultSmsTemplates.1.title',
            content: 'defaultSmsTemplates.1.body',
        },
        {
            id: 'defaultSms2',
            templateKind: TEMPLATE_KINDS.INVITATION,
            title: 'defaultSmsTemplates.2.title',
            content: 'defaultSmsTemplates.2.body',
        },
        {
            id: 'defaultSms3',
            templateKind: TEMPLATE_KINDS.FOLLOW_UP,
            title: 'defaultSmsTemplates.3.title',
            content: 'defaultSmsTemplates.3.body',
        },
        {
            id: 'defaultSms4',
            templateKind: TEMPLATE_KINDS.APPOINTMENT,
            title: 'defaultSmsTemplates.4.title',
            content: 'defaultSmsTemplates.4.body',
        },
        {
            id: 'defaultSms5',
            templateKind: TEMPLATE_KINDS.REFERRAL,
            title: 'defaultSmsTemplates.5.title',
            content: 'defaultSmsTemplates.5.body',
        },
        {
            id: 'defaultSms6',
            templateKind: TEMPLATE_KINDS.REVIEW,
            title: 'defaultSmsTemplates.6.title',
            content: 'defaultSmsTemplates.6.body',
        },
    ],
    defaultKeapMarketingNumberBroadcastTemplates: [
        {
            id: 'defaultKeapMarketingNumberBroadcast1',
            templateKind: TEMPLATE_KINDS.KMN_BROADCAST_APPOINTMENT,
            title: 'defaultKeapMarketingNumberBroadcastTemplates.1.title',
            content: 'defaultKeapMarketingNumberBroadcastTemplates.1.body',
        },
        {
            id: 'defaultKeapMarketingNumberBroadcast2',
            templateKind: TEMPLATE_KINDS.KMN_BROADCAST_REMINDER,
            title: 'defaultKeapMarketingNumberBroadcastTemplates.2.title',
            content: 'defaultKeapMarketingNumberBroadcastTemplates.2.body',
        },
        {
            id: 'defaultKeapMarketingNumberBroadcast3',
            templateKind: TEMPLATE_KINDS.KMN_BROADCAST_FOLLOW_UP,
            title: 'defaultKeapMarketingNumberBroadcastTemplates.3.title',
            content: 'defaultKeapMarketingNumberBroadcastTemplates.3.body',
        },
        {
            id: 'defaultKeapMarketingNumberBroadcast4',
            templateKind: TEMPLATE_KINDS.KMN_BROADCAST_DISCOUNT,
            title: 'defaultKeapMarketingNumberBroadcastTemplates.4.title',
            content: 'defaultKeapMarketingNumberBroadcastTemplates.4.body',
        },
    ],
    emailTemplates: [],
    smsTemplates: [],
    keapMarketingNumberTemplates: [],
};
