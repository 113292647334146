import * as tagsApi from '../tags.api';
import amplitude from '@/analytics/amplitude';

export default {
    LOAD_TAGS: async ({ commit }) => {
        const tags = await tagsApi.fetchTags();

        commit('SET_TAGS', tags);

        return tags;
    },

    LOAD_TAG_CATEGORIES: async ({ commit }) => {
        const tagCategories = await tagsApi.fetchTagCategories();

        commit('SET_TAG_CATEGORIES', tagCategories);

        return tagCategories;
    },

    LOAD_TAG_COUNT: async ({ commit, dispatch }) => {
        const tags = await dispatch('LOAD_TAGS');

        const tagCount = tags.length;

        commit('SET_TAG_COUNT', tagCount);

        return tagCount;
    },

    CREATE_TAG: async ({ commit, dispatch }, { newTag, eventSource }) => {
        const tag = await tagsApi.createTag(newTag);

        commit('ADD_TAG', tag);

        await dispatch('onboarding/COMPLETE_ONBOARDING_TASK', 'addTag', { root: true });

        amplitude.v2.logEvent(amplitude.v2.events.TAG_CREATED, {
            'Event Source': eventSource,
        });

        return tag;
    },

    UPDATE_TAG: async ({ commit }, tagUpdate) => {
        const tag = await tagsApi.updateTag(tagUpdate);

        commit('UPDATE_TAG', tag);

        return tag;
    },

    DELETE_TAG: async ({ commit }, tagId) => {
        await tagsApi.deleteTag(tagId);

        commit('DELETE_TAG', tagId);
    },

    CREATE_TAG_CATEGORY: async ({ commit }, newCategory) => {
        const tagCategory = await tagsApi.createTagCategory(newCategory);

        commit('ADD_TAG_CATEGORY', tagCategory);

        return tagCategory;
    },
};
