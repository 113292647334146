<template lang="html">
    <div :class="['message', { outgoing: message.outgoing }]">
        <div v-if="showImages" class="media">
            <img
                v-for="({ mediaUrl }, i) in imageUrls"
                :key="i"
                :src="mediaUrl"
                class="media-item"
                @load="imageLoaded"
            />
        </div>

        <div v-if="showVCards" class="file-bubble">
            <a
                v-for="({ filename, mediaUrl }, i) in vCardUrls"
                :key="i"
                :href="mediaUrl"
            >
                <div class="file-item">
                    <ds-icon class="file-icon" name="user-fill" />
                    <span>{{ filename }}</span>
                </div>
            </a>
        </div>

        <div v-if="showFiles" class="file-bubble">
            <a
                v-for="({ filename, mediaUrl }, i) in fileUrls"
                :key="i"
                :href="mediaUrl"
                target="_blank"
                rel="noopener noreferrer"
            >
                <div class="file-item">
                    <ds-icon class="file-icon" name="attach" />
                    <span>{{ filename }}</span>
                </div>
            </a>
        </div>

        <div v-if="showText" class="chat-bubble">
            <!-- eslint-disable vue/no-v-html -->
            <p
                v-for="(paragraph, i) in paragraphs"
                :key="i"
                v-html="linkifyParagraph(paragraph)"
            />
            <!-- eslint-enable vue/no-v-html -->
        </div>

        <div v-if="showEmoji" class="emoji-only">
            {{ message.body }}
        </div>
    </div>
</template>

<script>
import linkify from 'linkifyjs/string';

import { EMOJI_PATTERN, VCARD_CONTENT_TYPES } from '@/communication/communication.constants';
import { linkifyPhone } from '@/communication/utils/link-utils';

export default {
    props: {
        message: {
            type: Object,
            required: true,
        },
    },

    computed: {
        fileUrls() {
            const { mediaUrls } = this.message;

            return Array.isArray(mediaUrls) && mediaUrls.length > 0
                ? mediaUrls.filter(({ contentType }) => {
                    return !contentType.startsWith('image') && !VCARD_CONTENT_TYPES.includes(contentType);
                })
                : [];
        },

        imageUrls() {
            const { mediaUrls } = this.message;

            return Array.isArray(mediaUrls) && mediaUrls.length > 0
                ? mediaUrls.filter(({ contentType }) => contentType.startsWith('image'))
                : [];
        },

        vCardUrls() {
            const { mediaUrls } = this.message;

            return Array.isArray(mediaUrls) && mediaUrls.length > 0
                ? mediaUrls.filter(({ contentType }) => VCARD_CONTENT_TYPES.includes(contentType))
                : [];
        },

        paragraphs() {
            const { body } = this.message;

            return body ? body.split('\n').filter((paragraph) => paragraph) : [];
        },

        showEmoji() {
            const { body } = this.message;

            return Boolean(body.match(EMOJI_PATTERN));
        },

        showFiles() {
            const { fileUrls } = this;

            return Array.isArray(fileUrls) && fileUrls.length > 0;
        },

        showImages() {
            const { imageUrls } = this;

            return Array.isArray(imageUrls) && imageUrls.length > 0;
        },

        showText() {
            const { body } = this.message;

            return Boolean(body) && !this.showEmoji;
        },

        showVCards() {
            const { vCardUrls } = this;

            return Array.isArray(vCardUrls) && vCardUrls.length > 0;
        },
    },

    methods: {
        /* istanbul ignore next */
        linkifyParagraph(message) {
            const linkifiedMessage = linkify(message, { className: 'sms-message-link' });

            return linkifyPhone(linkifiedMessage);
        },

        imageLoaded() {
            this.$bus.$emit('CONVERSATION_SCROLL_TO_BOTTOM');
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    $chat-bubble-radius: px-to-rem(10px);

    .message {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;

        &.outgoing {
            align-items: flex-end;
        }

        &:first-child {
            .chat-bubble,
            .file-bubble {
                border-top-left-radius: $chat-bubble-radius;
            }

            &.outgoing {
                .chat-bubble,
                .file-bubble {
                    border-top-right-radius: $chat-bubble-radius;
                }
            }
        }
    }

    .chat-bubble {
        @include text-align-start;
        color: $color-text-normal;
        background: $color-gray-200;
        @include border-radius(0 $chat-bubble-radius $chat-bubble-radius 0);
        padding: $gp $gp 0;
        max-width: 70%;
        margin: $gp / 4 0;
        word-break: break-word;  // Chrome, Safari
        overflow-wrap: anywhere;  // Firefox
    }

    .media {
        width: 100%;
        @include text-align-start;
    }

    .media-item {
        border-radius: $chat-bubble-radius;
        max-width: 70%;
        margin: $gp / 4 0;
    }

    .file-bubble {
        cursor: pointer;
        color: $color-blue-050;
        font-size: $font-size-xs;
        border: 1px solid $color-blue;
        padding: $gp;
        margin: $gp / 4 0;
        @include border-radius(0 $chat-bubble-radius $chat-bubble-radius 0);

        a {
            text-decoration: none;
        }
    }

    .file-item {
        display: flex;
        align-items: center;
    }

    .file-icon {
        @include margin-end($gp / 2);
    }

    .emoji-only {
        font-size: $font-size-mega;
    }

    .outgoing {
        .chat-bubble {
            color: $color-text-normal;
            background: $color-blue-050;
            @include border-radius($chat-bubble-radius 0 0 $chat-bubble-radius);
        }

        .media {
            @include text-align-end;
        }

        .file-bubble {
            @include border-radius($chat-bubble-radius 0 0 $chat-bubble-radius);
        }
    }

    [dir=rtl] {
        .message {
            &:first-child {
                .chat-bubble,
                .file-bubble {
                    border-top-right-radius: $chat-bubble-radius;
                }

                &.outgoing {
                    .chat-bubble,
                    .file-bubble {
                        border-top-left-radius: $chat-bubble-radius;
                    }
                }
            }
        }
    }
</style>
