<template>
    <div class="tag-step choose-tag configure-editor">
        <div v-if="loading" class="spinner-container">
            <ds-spinner />
        </div>

        <transition name="fade">
            <div v-if="showTagSelector">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="close-button" @click="$emit('close')" />

                    <span class="configure-editor-text">{{ $t('header') }}</span>
                </header>

                <section class="select-option">
                    <div class="button-container">
                        <ds-text-button
                            leading-icon="add"
                            data-qa="add-new-tag"
                            @click="toggleAddTag"
                        >
                            {{ $t('buttonText') }}
                        </ds-text-button>

                        <ds-input-field
                            v-model="search"
                            class="search-input"
                            type="search"
                            name="search"
                            data-qa="search-tag"
                            :label="$t('search')"
                        />
                    </div>

                    <ds-list-item
                        v-for="(tag, i) in filteredTags"
                        :key="i"
                        as="a"
                        class="list-item-option"
                        :data-qa="`tag_${tag.name}`"
                        :title="tag.name"
                        :description="getCategoryName(tag.category)"
                        @click="handleTagSelection(tag)"
                    />

                    <p v-if="noSearchResults" class="empty-search">
                        {{ $t('emptySearch', { search }) }}
                    </p>
                </section>
            </div>

            <div v-if="showTimingStep" class="timing-editor">
                <header class="configure-editor-header">
                    <ds-icon-button name="arrow-left" data-qa="back-button" @click="handleBack" />

                    <span class="configure-editor-header-text">{{ $t('editOptions') }}</span>

                    <save-status :status="saveStatus" class="shift-right save-status" />

                    <ds-filled-button data-qa="tag-done" @click="handleDone(true)">
                        {{ $t('global.next') }}
                    </ds-filled-button>
                </header>

                <section class="timing-body">
                    <timing-step
                        v-if="timingRefactorEnabled"
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        <automation-display-name :value="tagStep" capitalize as="span" />
                    </timing-step>

                    <configure-timing-step
                        v-else
                        :delay-step="timingDelay"
                        :step-list-index="automationProperties.stepListIndex"
                        @updated="updateTiming"
                    >
                        <automation-display-name :value="tagStep" capitalize as="span" />
                    </configure-timing-step>
                </section>
            </div>

            <automation-add-tag
                v-if="showAddTag"
                @back="toggleAddTag"
                @tag-created="handleTagCreated"
            />
        </transition>
    </div>
</template>

<script>

import { APPLY_TAG, EDITOR_STEP, TIMING_STEP } from '@/automations/constants/automations.constants';
import { mapState } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import AutomationAddTag from '@/automations/components/AutomationAddTag';
import ConfigureTimingStep from '@/automations/components/configure/ConfigureTimingStep';
import SaveStatus from '@/automations/components/SaveStatus';
import AutomationDisplayName from '@/automations/components/AutomationDisplayName';
import TimingStep from '@/automations/components/steps/TimingStep';
import { FF_SIMPLE_AUTOMATION_TIMING_REFACTOR } from '@/shared/constants/featureFlag.constants';

export default {
    components: {
        AutomationAddTag,
        ConfigureTimingStep,
        SaveStatus,
        AutomationDisplayName,
        TimingStep,
    },

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },

        saveStatus: String,

        addOrRemoveType: {
            type: String,
            default: APPLY_TAG,
        },
    },

    data() {
        return {
            currentStep: EDITOR_STEP,
            selectedTag: {},
            timingDelay: this.automationProperties.delayStep || null,
            showAddTag: false,
            loading: false,
            search: '',
        };
    },

    created() {
        this.init();
    },

    watch: {
        'automationProperties.delayStep': {
            handler(value) {
                this.timingDelay = value;
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            timingRefactorEnabled: ({ featureFlags }) => featureFlags[FF_SIMPLE_AUTOMATION_TIMING_REFACTOR],
            tags: ({ tags }) => tags.tags,
        }),

        tagStep() {
            return {
                type: this.addOrRemoveType,
                name: this.isAddType ? this.$t('applyEmptyTag') : this.$t('removeEmptyTag'),
                configJson: {
                    tagIds: [this.selectedTag.id],
                },
            };
        },

        isAddType() {
            return this.addOrRemoveType === APPLY_TAG;
        },

        showTagSelector() {
            return this.currentStep === EDITOR_STEP && !this.showAddTag;
        },

        showTimingStep() {
            return this.currentStep === TIMING_STEP && !this.showAddTag;
        },

        filteredTags() {
            const search = this.search?.trim()?.toLowerCase();

            return this.tags.filter(({ name, category }) => {
                return name.toLowerCase().includes(search) || category?.name?.toLowerCase()?.includes(search);
            });
        },

        noSearchResults() {
            return this.search && !this.loading && !this.filteredTags.length;
        },
    },

    methods: {
        async init() {
            if (!this.tags.length) {
                this.loading = true;

                await this.$store.dispatch('tags/LOAD_TAGS');

                this.loading = false;
            }

            const { initialStep, delayStep, step } = this.automationProperties;

            if (step?.configJson?.tagIds) {
                this.selectedTag = {
                    id: step.configJson.tagIds[0],
                };
            }

            this.timingDelay = delayStep || null;
            this.currentStep = initialStep;
        },

        handleBack() {
            this.currentStep = EDITOR_STEP;
        },

        handleEdit() {
            this.handleDone(false);
        },

        handleTagSelection(tag) {
            this.selectedTag = clonedeep(tag);
            this.handleEdit();

            this.currentStep = TIMING_STEP;
        },

        handleDone(close) {
            const steps = [this.tagStep];
            const hasDelay = this.timingDelay && Object.keys(this.timingDelay).length;

            if (hasDelay) {
                steps.unshift(this.timingDelay);
            }

            this.$emit('updated', steps);

            if (close) {
                this.$emit('close');
            }
        },

        toggleAddTag() {
            this.showAddTag = !this.showAddTag;
        },

        getCategoryName(category) {
            return category?.name ?? '';
        },

        handleTagCreated(tag) {
            this.toggleAddTag();
            this.handleTagSelection(tag);
        },

        updateTiming(timingDelay) {
            this.timingDelay = timingDelay;
            this.handleEdit();
        },
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/stepEditor';
</style>

<i18n>
{
    "en-us": {
        "header": "Choose a tag",
        "createHeader": "Create new tag",
        "buttonText": "Create a new tag",
        "editOptions": "Edit options",
        "applyEmptyTag": "Add tag",
        "removeEmptyTag": "Remove tag",
        "search": "Search",
        "emptySearch": "No results found for \"{search}\""
    }
}
</i18n>
