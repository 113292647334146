<template>
    <section class="custom-field-edit-advanced">
        <h4 class="section-title">
            {{ $t('global.advanced') }}
        </h4>

        <div class="field-grid">
            <slot name="default" />

            <div>
                <p class="subtitle small">
                    {{ $t('deleteWarningHeader') }}
                </p>
                <p class="caption">
                    {{
                        $t('deleteWarning', {
                            recordType: $t(`${recordType}.singular`),
                            pluralRecordType: $t(`${recordType}.plural`)
                        })
                    }}
                    <strong>{{ $t('permanent') }}</strong>
                </p>
            </div>

            <ds-outline-button
                destructive
                :disabled="isLoading"
                data-qa="delete-button"
                leading-icon="trash-2"
                @click="$emit('delete')"
            >
                {{ $t('deleteAction') }}
            </ds-outline-button>
        </div>
    </section>
</template>

<script>
import { CUSTOM_FIELD_RECORD_TYPES } from '../customFields.constants';

export default {
    props: {
        recordType: {
            type: String,
            required: true,
            validator: (value) => CUSTOM_FIELD_RECORD_TYPES.includes(value),
        },
        isLoading: {
            type: Boolean,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.custom-field-edit-advanced {
    padding: 1.5 * $gp 0;
    border-bottom: 1px solid $color-gray-200;
    border-top: 1px solid $color-gray-200;

    .section-title {
        font-size: $font-size-med;
        font-weight: $font-weight-bold;
        line-height: $line-height-lg;
        margin-bottom: 1.5 * $gp;
    }

    .field-grid {
        --input-margin-bottom: 0;
        align-items: center;
        display: grid;
        font-size: $font-size-med;
        grid-gap: $gp * 1.5 $gp;
        grid-template-columns: 1fr auto;
    }

    .subtitle {
        font-weight: $font-weight-bold;

        &.small {
            font-size: $font-size-small;
            margin-bottom: 0;
        }
    }

    .caption {
        font-size: $font-size-xsmall;
        margin-bottom: 0;
    }
}
</style>

<i18n>
{
    "en-us": {
        "contact": {
            "singular": "contact",
            "plural": "contacts"
        },
        "company": {
            "singular": "company",
            "plural": "companies"
        },
        "deleteWarningHeader": "Delete this custom field",
        "deleteWarning": "If you delete this custom {recordType}, you will also lose all the data stored in it across all {pluralRecordType}.",
        "permanent": "This action is permanent.",
        "deleteAction": "Delete field"
    }
}
</i18n>
