<template>
    <section>
        <ds-icon-button
            name="trash-2"
            data-qa="delete-button"
            class="subtle"
            @click="confirmDelete"
        />

        <portal to="root">
            <ds-confirm-modal
                :ref="modalRef"
                destructive
                :title="title"
                :confirm-button-label="$t('delete.button')"
                :size="hasRelativeTrigger ? 'sm' : 'xs'"
                @confirm="handleDelete"
            >
                <template #default>
                    <div v-if="hasRelativeTrigger" class="message">
                        <p class="message">
                            <i18n path="triggerDelete.eventAndTimerMessage1">
                                <template #when>
                                    <strong>{{ $t('trigger') }}</strong>
                                </template>
                            </i18n>
                        </p>

                        <p class="message second">
                            <i18n path="triggerDelete.eventAndTimerMessage2">
                                <template #wait>
                                    <strong>{{ $t('triggerDelete.wait') }}</strong>
                                </template>
                            </i18n>
                        </p>
                    </div>

                    <p v-else class="message">
                        <i18n path="delete.message">
                            <template #type>
                                <strong>{{ $t(`${automationItemType}`) }}</strong>
                            </template>
                        </i18n>
                    </p>
                </template>
            </ds-confirm-modal>
        </portal>
    </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { APPOINTMENT_TRIGGERS, TRIGGER_ACTION_TYPES } from '@/automations/constants/automations.constants';
import {
    FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS,
    FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS_FLOW,
    FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_FORMS_PUBLIC_FLOW,
} from '@/shared/constants/featureFlag.constants';

export default {
    props: {
        type: String,
        triggerAction: String,
        itemId: String,
    },

    computed: {
        ...mapGetters({
            hasRelativeAppointmentTimer: 'automations/hasRelativeAppointmentTimer',
            hasRelativeInvoiceTimer: 'automations/hasRelativeInvoiceTimer',
            hasInvoiceTrigger: 'automations/hasInvoiceTrigger',
            hasDealTrigger: 'automations/hasDealTrigger',
            hasInternalFormTrigger: 'automations/hasInternalFormTrigger',
            hasPublicFormTrigger: 'automations/hasPublicFormTrigger',
            hasAppointmentTrigger: 'automations/hasAppointmentTrigger',
        }),

        ...mapState({
            contextualAppointmentsEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS],
            contextualAppointmentsFlowEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_APPOINTMENTS_FLOW],
            contextualFormsEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_CONTEXTUAL_FORMS_PUBLIC_FLOW],
        }),

        modalRef() {
            return `confirmDeleteModal_${this.itemId}`;
        },

        isStep() {
            return !this.triggerAction;
        },

        isTrigger() {
            return this.triggerAction === TRIGGER_ACTION_TYPES.ADD;
        },

        automationItemType() {
            if (this.isStep) {
                return this.$t('step');
            }

            if (this.isTrigger) {
                return this.$t('trigger');
            }

            return this.$t('stop');
        },

        hasRelativeTrigger() {
            const isRelativeAppointmentTrigger = APPOINTMENT_TRIGGERS.includes(this.type) && this.isTrigger && this.hasRelativeAppointmentTimer;
            const isRelativeInvoiceTrigger = this.hasInvoiceTrigger && this.isTrigger && this.hasRelativeInvoiceTimer;

            return isRelativeAppointmentTrigger || isRelativeInvoiceTrigger;
        },

        title() {
            return this.hasRelativeTrigger ? this.$t('triggerDelete.title') : this.$t('delete.title', { type: this.automationItemType });
        },

        description() {
            return this.$t('delete.message', { type: this.automationItemType });
        },
    },

    methods: {
        confirmDelete() {
            this.$refs[this.modalRef].open();
        },

        handleDelete() {
            if (this.isTrigger) {
                if (this.hasDealTrigger) {
                    this.$bus.$emit('AUTOMATION_STAGE_UPDATED');
                } else if (this.hasAppointmentTrigger && (this.contextualAppointmentsEnabled || this.contextualAppointmentsFlowEnabled)) {
                    this.$bus.$emit('AUTOMATION_APPOINTMENT_UPDATED');
                } else if (this.hasInternalFormTrigger && this.contextualFormsEnabled) {
                    this.$bus.$emit('AUTOMATION_INTERNAL_FORM_UPDATED');
                } else if (this.hasPublicFormTrigger && this.contextualFormsEnabled) {
                    this.$bus.$emit('AUTOMATION_PUBLIC_FORM_UPDATED');
                }
            }

            this.$emit('remove', { resetTimers: Boolean(this.hasRelativeTrigger) });
        },
    },
};
</script>

<style lang="scss" scoped>
    .subtle {
        --icon-color: #{$color-text-subtle};
    }

    .message {
        color: $color-text-subtle;
        font-size: $font-size-med;
        line-height: $line-height-lg;
        margin: 0;

        &.second {
            margin-top: $gp;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "trigger": "When",
        "step": "Then",
        "stop": "Stop",
        "delete": {
            "title": "Delete this {type} event?",
            "message": "If you delete the {type} event, changes you’ve made will be lost.",
            "button": "Delete event"
        },
        "triggerDelete": {
            "title": "Delete this When event and timer?",
            "eventAndTimerMessage1": "If you delete the {when} event, changes you've made will be lost.",
            "eventAndTimerMessage2": "This will also remove the {wait} timer you set for this event.",
            "wait": "Wait"
        },
        "appointmentTriggerDelete": {
            "confirmMessage": "If you delete the When event, changes you've made will be lost. This will also remove the Wait timer you set for this event.",
            "button": "Delete event"
        }
    }
}
</i18n>
