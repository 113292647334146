<template>
    <tooltip-with-delay position="top">
        <template #reference>
            <ds-popover
                class="popover-container"
                :is-open="isMergeFieldPopoverOpen"
                hide-arrow
                position="right-start"
                @close="isMergeFieldPopoverOpen = false"
            >
                <template #reference>
                    <ds-icon-button
                        data-qa="merge-field-button"
                        name="merge-field"
                        @click="isMergeFieldPopoverOpen = !isMergeFieldPopoverOpen"
                    />
                </template>

                <template #default>
                    <div class="merge-field-menu">
                        <div v-for="category in mergeFieldCategories" :key="category.categoryTitle" class="menu-wrapper">
                            <span class="menu-header">{{ category.categoryTitle }}</span>

                            <span
                                v-for="mergeField in category.mergeFields"
                                :key="mergeField.title"
                                class="menu-item"
                                @click="insertMergeField(mergeField.value)"
                            >
                                {{ mergeField.title }}
                            </span>
                        </div>

                        <div class="merge-footer">
                            <a
                                :title="$t('mergeFieldFeedbackLink')"
                                :href="mergeFieldFeedbackLink"
                                class="merge-field-feedback-link"
                                rel="noopener noreferer"
                                target="_blank"
                            >
                                {{ $t('mergeFieldFeedbackLink') }}
                                <ds-icon name="external-link" />
                            </a>
                        </div>
                    </div>
                </template>
            </ds-popover>
        </template>

        <template #default>
            {{ $t('mergeFieldTooltip') }}
        </template>
    </tooltip-with-delay>
</template>

<script>
import TooltipWithDelay from '@/shared/components/TooltipWithDelay';

export default {
    components: { TooltipWithDelay },

    data() {
        return {
            isMergeFieldPopoverOpen: false,
            mergeFieldCategories: [
                {
                    categoryTitle: this.$t('mergeFields.recipient'),
                    mergeFields: [
                        {
                            title: this.$t('mergeFields.contactFirstName'),
                            value: '[[contact.first_name]]',
                        },
                    ],
                },
            ],
            mergeFieldFeedbackLink: 'https://forms.gle/Nk2GVGkbYcUU1LsZ9',
        };
    },

    methods: {
        insertMergeField(value) {
            this.isMergeFieldPopoverOpen = false;
            this.$emit('input', value);
        },
    },
};
</script>

<style lang="scss" scoped>
    .popover-container {
        --popover-width: #{px-to-rem(290px)};
        font-size: $font-size-sm;
    }

    .merge-field-menu {
        padding: $gp / 2 0;
    }

    .menu-wrapper {
        display: flex;
        flex-direction: column;
    }

    .menu-header {
        padding: $gp * .75 $gp $gp / 4;
        font-size: $font-size-xsmall;
        color: $color-gray-dark;
    }

    .menu-item {
        padding: $gp / 2 $gp $gp;
        cursor: pointer;

        &:hover {
            background-color: $color-gray-lightest;
        }

        &:active {
            background-color: $color-gray-lighter;
        }
    }

    .merge-footer {
        border-top: solid px-to-rem(1) $color-gray-lighter;
    }

    .merge-field-feedback-link {
        display: flex;
        font-weight: $font-weight-semibold;
        --icon-size: #{px-to-rem(15px)};
        color: $color-primary;
        padding: $gp $gp $gp / 2;
        gap: $gp / 4;
    }
</style>

<i18n>
{
    "en-us": {
        "mergeFields": {
            "recipient": "Recipient",
            "contactFirstName": "Contact's first name"
        },
        "mergeFieldFeedbackLink" : "What other fields would be helpful?",
        "mergeFieldTooltip": "Add a merge field to personalize your text message"
    }
}
</i18n>
