export const CONTACT_TYPES = {
    LEAD: 'Lead',
    CUSTOMER: 'Customer',
    OTHER: 'Other',
    NULL: 'null',
};

export const PHONE_TYPES = {
    HOME: 'HOME',
    MOBILE: 'MOBILE',
    WORK: 'WORK',
    OTHER: 'OTHER',
};

// More info: https://help.infusionsoft.com/help/email-status-icons-explained
export const EMAIL_STATUS = {
    SINGLE_OPT_IN: 'SingleOptIn',
    NON_MARKETABLE: '', // endpoint that consumes this checks for any value, if blank will make non-marketable
};

export const NOTE_TYPES = {
    APPOINTMENT: 'Appointment',
    CALL: 'Call',
    EMAIL: 'Email',
    FAX: 'Fax',
    LETTER: 'Letter',
    OTHER: 'Other',
};

export const ERROR_CODE_DUPLICATE_EMAIL = 'DUPLICATE_EMAIL';
export const ERROR_CODE_INVALID_EMAIL = 'INVALID_EMAIL';

export const FIELD_PROPS = {
    FIRST_NAME: 'givenName',
    LAST_NAME: 'familyName',
    EMAIL: 'email',
};

export const DEFAULT_FROM_VALUE = '"~Owner.FirstName~ ~Owner.LastName~" <~Owner.Email~>';
export const DEFAULT_COUNTRY = 'USA';

export const BATCH_STATUS = {
    cancelled: 'CANCELLED',
    processing: 'PROCESSING',
    queued: 'QUEUED',
};

export const EDITOR_KINDS = {
    bardEmailBuilder: 'BardEmailBuilder',
};

export const WIZARD_STEP_STATUS = {
    COMPLETED: 'completed',
    ERROR: 'error',
    WARNING: 'warning',
};

const AUTOCOMPLETE_ICONS = {
    LIST: 'list',
    USER: 'user',
    TAG: 'tag',
};

export const AUTOCOMPLETE_ICON_BY_CATEGORY = {
    LIST: AUTOCOMPLETE_ICONS.LIST,
    SAVEDSEARCH: AUTOCOMPLETE_ICONS.LIST,
    TAG: AUTOCOMPLETE_ICONS.TAG,
    SINGLECONTACTTAG: AUTOCOMPLETE_ICONS.USER,
    LEADSOURCE: AUTOCOMPLETE_ICONS.LIST,
    STAGE: AUTOCOMPLETE_ICONS.LIST,
    PRODUCT: AUTOCOMPLETE_ICONS.LIST,
};

export const COMPANY_NAME_MAX_LENGTH = 50;
export const DUMMY_COMPANY_ID = -9999;

export const CONTACT_SORT_FIELDS = {
    CONTACT_DATE_CREATED: 'createDate',
    COMPANY_CONTACT_DATE_CREATED: 'DateCreated',
    CONTACT_LAST_NAME: 'ContactName.lastName',
    COMPANY_CONTACT_LAST_NAME: 'LastName',
};

export const CONTACT_FIELDS_UPDATE_MASK = [
    'addresses',
    'anniversary',
    'birthday',
    'company',
    'contact_type',
    'custom_fields',
    'email_addresses',
    'family_name',
    'fax_numbers',
    'given_name',
    'job_title',
    'lead_source_id',
    'middle_name',
    'notes',
    'opt_in_reason',
    'origin',
    'owner_id',
    'phone_numbers',
    'preferred_locale',
    'preferred_name',
    'prefix',
    'social_accounts',
    'source_type',
    'spouse_name',
    'suffix',
    'time_zone',
    'website',
];
