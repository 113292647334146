<template>
    <section v-if="noFilterResults || noSearchResults" class="empty-results">
        <span class="empty-results-title">{{ title }}</span>
        <span class="empty-results-description">{{ description }}</span>
    </section>
</template>

<script>
export default {
    props: {
        filter: {
            type: String,
            default: '',
        },
        noFilterResults: Boolean,
        noSearchResults: Boolean,
        search: {
            type: String,
            default: '',
        },
    },

    computed: {
        title() {
            const {
                filter, noFilterResults, noSearchResults, search,
            } = this;

            if (noFilterResults && noSearchResults) {
                return this.$t('title.both');
            }

            if (noFilterResults) {
                return this.$t('title.filter', { filter });
            }

            if (noSearchResults) {
                return this.$t('title.search', { search });
            }

            return '';
        },

        description() {
            const { noFilterResults, noSearchResults } = this;

            if (noFilterResults && noSearchResults) {
                return this.$t('description.both');
            }

            if (noFilterResults) {
                return this.$t('description.filter');
            }

            if (noSearchResults) {
                return this.$t('description.search');
            }

            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
    .empty-results {
        display: flex;
        flex-direction: column;
        padding-top: $gp * 3;
        align-items: center;
    }

    .empty-results-title {
        font-size: $font-size-lg;
        font-weight: 300;
        color: $color-gray-700;
        padding-bottom: $gp * 1.5;
    }

    .empty-results-description {
        color: $color-gray-700;
    }
</style>

<i18n>
{
    "en-us": {
        "title": {
            "both": "There were no results matching your search/filter criteria.",
            "filter": "You have no {filter} landing pages",
            "search": "Your search for \"{search}\" returned no results"
        },
        "description": {
            "both": "Try adjusting your search query or filters",
            "filter": "Try changing your filter settings",
            "search": "Try searching for something else"
        }
    }
}
</i18n>
