<template>
    <controlled-modal
        class="phone-alert-modal"
        :is-open="isOpen"
        size="med"
        @close="$emit('close')"
    >
        <div class="close-button">
            <ds-icon-button name="x" @click="$emit('close')" />
        </div>

        <div class="body">
            <ds-illustration name="setup-messenger-color" />

            <div class="content">
                <h3 class="title">
                    {{ $t('title') }}
                </h3>

                <p class="title-description">
                    {{ $t('titleDescription') }}
                </p>

                <ul class="details-list">
                    <li class="description">
                        <span>
                            {{ $t('suggestion1.1') }}
                            <strong>{{ $t('suggestion1.2') }}</strong>
                            {{ $t('suggestion1.3') }}
                        </span>
                    </li>
                    <li class="description">
                        <span>
                            {{ $t('suggestion2.1') }}
                            <strong>{{ $t('suggestion2.2') }}</strong>
                            {{ $t('suggestion2.3') }}
                        </span>
                    </li>
                    <li class="description">
                        <span>
                            {{ $t('suggestion3.1') }}
                            <strong>{{ $t('suggestion3.2') }}</strong>
                            {{ $t('suggestion3.3') }}
                        </span>
                    </li>
                </ul>
            </div>

            <div class="done-button">
                <ds-filled-button @click="$emit('close')">
                    {{ $t('okGotIt') }}
                </ds-filled-button>
            </div>
        </div>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: { ControlledModal },

    props: {
        isOpen: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.phone-alert-modal {
    --modal-padding: 0;
}

.close-button {
    align-self: flex-start;
    padding: $gp * .375;
}

.body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    text-align: center;
    font-size: $font-size-xl;
    font-weight: $font-weight-semibold;
    padding: $gp $gp $gp / 2 ;

    @media($extra-small) {
        padding: $gp 0;
    }
}

.content {
    display: flex;
    flex-direction: column;
    padding: 0 $gp * 3.5;
}

.description {
    font-size: $font-size-med;
    line-height: $line-height-lg;
    padding: $gp 0;
}

.suggestion-row {
    display: flex;
    padding: 0 $gp / 2 $gp;
}

.title-description {
    padding: 0 $gp * 5;
    text-align: center;
    font-size: $font-size-med;
    line-height: $line-height-lg;
    margin: 0;

    @media($extra-small) {
        padding: 0;
    }
}

.done-button {
    margin: $gp * 1.5 0 $gp * 2.5;
}

.details-list {
    margin: $gp * .75 0;
    padding: 0 $gp * 4;
    list-style: initial;

    @media($extra-small) {
        padding: 0;
    }
}
</style>

<i18n>
{
    "en-us": {
        "title": "How to: Avoid sending multiple texts",
        "titleDescription": "To avoid sending your recipients a text message on more than 1 phone number:" ,
        "suggestion1": {
            "1": "Take a closer look at your phone data to verify you have the",
            "2": "most recent mobile number",
            "3": "for your contacts"
        },
        "suggestion2": {
            "1": "Verify you only have",
            "2": "1 phone number marked as “mobile”",
            "3": "on your contact records"
        },
        "suggestion3": {
            "1": "Consider",
            "2": "removing other phone types",
            "3": "(work, other, home) to reduce the number of recipients who may receive multiple texts"
        },
        "okGotIt": "Ok, got it"
    }
}
</i18n>
