import { mapState } from 'vuex';
import { PAYMENT_REFERENCE_TYPES } from '@/money/money.constants';

export default {
    computed: {
        ...mapState({
            productOptions_products: ({ billing }) => billing.products,
        }),

        productOptions_productOptions() {
            if (this.productOptions_products == null) {
                return [];
            }

            return this.productOptions_products.reduce((res, product) => {
                // remove when subscriptions are implemented
                if (!product.subscriptionOnly) {
                    const productToAdd = {
                        type: PAYMENT_REFERENCE_TYPES.product,
                        value: product.id,
                        label: product.productName,
                        rate: product.productPrice,
                        addText: product.productName, // To ensure data-qa value is populated
                        product,
                    };

                    res.push(productToAdd);
                }

                return res;
            }, []);
        },
    },

    methods: {
        productOptions_loadProducts() {
            return this.$store.dispatch('billing/LOAD_PRODUCTS').catch(() => {
                this.$error({
                    message: this.$t('errors.loadProducts'),
                });
            });
        },
    },
};
