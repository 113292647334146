<template>
    <div class="calendar">
        <div class="calendar-controls">
            <h6 class="semibold" data-qa="calendar-month">
                {{ monthName }}
            </h6>

            <div class="button-group">
                <ds-icon-button
                    name="chevron-left"
                    data-qa="previous-month-button"
                    :disabled="!prevMonthValid(month)"
                    @click="slidePrev"
                />

                <ds-text-button
                    class="today"
                    :disabled="disableToday"
                    @click="goToToday"
                >
                    {{ $t('global.time.today') }}
                </ds-text-button>

                <ds-icon-button
                    name="chevron-right"
                    data-qa="next-month-button"
                    @click="slideNext"
                />
            </div>
        </div>

        <swiper ref="calSwiper" :options="swiperOptions">
            <swiper-slide>
                <div class="week">
                    <div v-for="day in weekdays" :key="day">
                        {{ day }}
                    </div>
                </div>

                <month :month="lastMonthDate" :first-day="firstDay" />
            </swiper-slide>

            <swiper-slide>
                <div class="week">
                    <div v-for="day in weekdays" :key="day">
                        {{ day }}
                    </div>
                </div>

                <month
                    :month="currentMonth"
                    :first-day="firstDay"
                    :on-select="daySelected"
                    :selected-date="selectedDate"
                    :appts="appts"
                    :max-date="maxDate"
                    :min-date="minDate"
                    :availability="availability"
                    :show-availability="showAvailability"
                    :on-month-change="onMonthChange"
                    :loading="!disableSelect"
                />
            </swiper-slide>

            <swiper-slide>
                <div class="week">
                    <div v-for="day in weekdays" :key="day">
                        {{ day }}
                    </div>
                </div>

                <month :month="nextMonthDate" :first-day="firstDay" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import Month from '@/appointments/components/Month';
import moment from 'moment';
import { swiper, swiperSlide } from 'vue-awesome-swiper';

import 'swiper/dist/css/swiper.css';

const SLIDE_PREV = 0;
const SLIDE_CURR = 1;
const SLIDE_NEXT = 2;

export default {
    components: {
        Month,
        swiper,
        swiperSlide,
    },

    props: {
        selected: {
            type: Date,
            default() {
                return new Date();
            },
        },
        disableSelect: Boolean,
        firstDay: {
            type: Number,
            default: 0, // Sunday
        },
        maxDate: {
            type: Date,
            required: false,
        },
        minDate: {
            type: Date,
            required: false,
        },
        onSelect: {
            type: Function,
            default: () => {},
        },
        onTodayClick: {
            type: Function,
            default: () => {},
        },
        onMonthChange: {
            type: Function,
            default: () => {},
        },
        onNextMonthClick: {
            type: Function,
            default: () => {},
        },
        onMonthScroll: {
            type: Function,
            default: () => {},
        },
        onPrevMonthClick: {
            type: Function,
            default: () => {},
        },
        appts: {
            type: Array,
            default: () => [],
        },
        availability: {
            type: Array,
            default: () => [],
        },
        month: {
            type: Date,
            default() {
                return new Date();
            },
        },
        showAvailability: Boolean,
        loading: Boolean,
    },

    data() {
        return {
            currentMonth: new Date(),
            selectedDate: new Date(),
            swiperOptions: {
                // https://idangero.us/swiper/api/#parameters
                slidesPerView: 1,
                spaceBetween: 0,
                longSwipesRatio: 0.4,
                preventInteractionOnTransition: true,
                threshold: 2,
                noSwipingClass: 'swiper-disabled',
            },
            adjecentMonths: [],
        };
    },

    mounted() {
        this.load();
    },

    beforeDestroy() {
        this.unload();
    },

    watch: {
        selected() {
            this.selectedDate = this.selected;

            if (!moment(this.selectedDate).isSame(this.currentMonth, 'month')) {
                this.currentMonth = moment(this.selectedDate).startOf('month').toDate();
            }
        },

        currentMonth(val) {
            this.onMonthChange({
                startDate: moment(val).startOf('month').startOf('week').startOf('day').toDate(),
                endDate: moment(val).endOf('month').endOf('week').endOf('day').toDate(),
            });
        },
    },

    computed: {
        weekdays() {
            return [...Array(7).keys()].map((day) => moment.weekdaysShort(day));
        },

        isToday() {
            return moment().isSame(this.selectedDate, 'day');
        },

        monthName() {
            return moment(this.currentMonth).format('MMM YYYY');
        },

        disableToday() {
            return this.isToday || this.disableSelect;
        },

        nextMonthDate() {
            return moment(this.currentMonth).add(1, 'M').toDate();
        },

        lastMonthDate() {
            return moment(this.currentMonth).subtract(1, 'M').toDate();
        },

        swiper() {
            return this.$refs.calSwiper ? this.$refs.calSwiper.swiper : null;
        },
    },

    methods: {
        load() {
            this.currentMonth = this.month;

            if (this.selected) {
                this.selectedDate = this.selected;
            }

            if (this.swiper) {
                this.swiper.on('slideChangeTransitionEnd', this.onSlideChange);
                this.swiper.slideTo(1, 0);
            }
        },

        unload() {
            if (this.swiper) {
                this.swiper.off('slideChangeTransitionEnd', this.onSlideChange);
            }
        },

        daySelected(date) {
            if (date.isUnavailable || this.disableSelect) {
                return;
            }

            this.selectedDate = date.day;

            this.onSelect(date);
        },

        getDayName(num) {
            return moment().day(num).format('ddd');
        },

        goToToday() {
            this.onTodayClick();
            this.currentMonth = moment().startOf('month').toDate();

            const today = moment().startOf('day').toDate();

            this.selectedDate = today;
            this.onSelect({ day: today });
        },

        prevMonth() {
            if (!this.prevMonthValid(this.month)) {
                return;
            }

            const newMonth = moment(this.currentMonth).subtract(1, 'month').toDate();

            this.currentMonth = newMonth;
            this.onPrevMonthClick(newMonth);
        },

        nextMonth() {
            const newMonth = moment(this.currentMonth).add(1, 'month').toDate();

            this.currentMonth = newMonth;
            this.onNextMonthClick(newMonth);
        },

        scrollToMonth(month) {
            if (moment(this.currentMonth).month() !== moment(month).month()) {
                this.currentMonth = month;
                this.onMonthScroll(month);
            }
        },

        prevMonthValid(date) {
            return !this.minDate || moment(date).isAfter(this.minDate);
        },

        onSlideChange() {
            if (this.swiper.activeIndex === SLIDE_NEXT) {
                this.nextMonth();
            } else if (this.swiper.activeIndex === SLIDE_PREV) {
                this.prevMonth();
            }

            this.swiper.slideTo(SLIDE_CURR, 0);
        },

        slidePrev() {
            if (this.prevMonthValid(this.month)) {
                if (this.swiper) {
                    this.swiper.slideTo(SLIDE_PREV);
                } else {
                    this.prevMonth();
                }
            }
        },

        slideNext() {
            if (this.swiper) {
                this.swiper.slideTo(SLIDE_NEXT);
            } else {
                this.nextMonth();
            }
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import '~@/appointments/styles/appointments';

    .calendar-controls {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        grid-gap: $gp / 2;
        padding-bottom: $gp / 2;
        border-bottom: 1px solid $color-gray-200;

        @media($small) {
            grid-template-columns: 1fr auto auto;
        }
    }

    .button-group {
        justify-self: flex-end;
    }

    .calendar {
        position: relative;

        @media($small) {
            border-bottom: 1px solid $color-gray-200;
        }
    }

    .month-name {
        font-size: $font-size-md;
        font-weight: $font-weight-semibold;
    }

    .week {
        @include weekGrid;
        padding-bottom: $gp / 2;
    }

    .swiper-container {
        padding-top: $gp;
        height: px-to-rem(300px);
    }
</style>

<i18n>
{
    "en-us": {
        "calendar": {
            "go": {
                "today": "Go to today",
                "prevMonth": "Previous Month",
                "nextMonth": "Next Month"
            }
        }
    }
}
</i18n>
