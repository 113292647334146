<template>
    <div
        class="automation-type-card"
        @click.stop="selectTrigger"
    >
        <span class="title">
            {{ $t(automationItem.name) }}
        </span>

        <img
            class="image"
            :src="automationImage"
            :alt="automationItem.name"
        />
    </div>
</template>

<script>
import { SEND_SMS } from '@/automations/constants/automations.constants';
import { trackSmsFeatureDiscovered, trackSmsAutomationCreated } from '@/broadcasts/smsAnalytics';

export default {
    props: {
        automationItem: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            baseUrl: process.env.BASE_URL,
        };
    },

    mounted() {
        if (this.automationItem.type === SEND_SMS) {
            trackSmsFeatureDiscovered('Easy Automations');
        }
    },

    computed: {
        automationImage() {
            return `${this.baseUrl}images/automations/${this.automationItem.image}`;
        },
    },

    methods: {
        selectTrigger() {
            if (this.automationItem.type === SEND_SMS) {
                trackSmsAutomationCreated('Easy Automations');
            }
            this.$emit('selected', this.automationItem);
        },
    },
};
</script>

<style lang="scss" scoped>
    .automation-type-card {
        @include transition(box-shadow);
        @include card;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid $color-gray-200;
        min-height: px-to-rem(100px);
        cursor: pointer;
        padding: $gp / 2;

        @media($extra-small) {
            --card-background-color: #{$color-paper};
        }

        &:hover {
            box-shadow: $elevation-z4;
        }
    }

    .title {
        text-align: center;
        font-size: $font-size-sm;
        padding-bottom: $gp / 2;
    }

    .image {
        height: px-to-rem(42px);
    }
</style>
