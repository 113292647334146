import moment from 'moment';
import { SEND_NOW, SMS_OPT_STATUSES } from '@/broadcasts/smsBroadcast.constants';
import { normalizePhoneNumber, isValidPhoneNumber } from '@/communication/utils/sms-utils';

export const getScheduleDate = ({
    timing,
    timingDetails: {
        date,
        time,
        timeZone,
    },
}) => {
    if (timing === SEND_NOW) {
        return `${moment().unix()}`;
    }

    const dateTime = moment.tz(timeZone.value);
    const mDate = moment(date);
    const mTime = moment(time, 'HHmm');

    dateTime.set({
        year: mDate.year(),
        month: mDate.month(),
        date: mDate.date(),
        hour: mTime.hour(),
        minute: mTime.minutes(),
        second: 0,
        millisecond: 0,
    });

    return `${dateTime.unix()}`;
};

export const recipientHasValidNumber = ({ phoneNumber }) => isValidPhoneNumber(phoneNumber);

export const recipientHasNotOptedOut = (optedOutPhoneNumbers) => ({ phoneNumber }) => !optedOutPhoneNumbers.includes(normalizePhoneNumber(phoneNumber));

export const recipientIsNotDuplicate = ({ phoneNumber }, index, self) => self.findIndex((r) => (normalizePhoneNumber(r.phoneNumber) === normalizePhoneNumber(phoneNumber))) === index;

export const getOptedInRecipients = ({ optStatuses }) => {
    const { SINGLE_OPT_IN, DOUBLE_OPT_IN } = SMS_OPT_STATUSES;

    return optStatuses
        .filter(({ statusKind }) => [SINGLE_OPT_IN, DOUBLE_OPT_IN].includes(statusKind))
        .map(({ phoneNumber }) => normalizePhoneNumber(phoneNumber));
};

export const getOptedOutRecipients = (optStatuses) => {
    const { OPT_OUT } = SMS_OPT_STATUSES;

    return optStatuses
        .filter(({ statusKind }) => statusKind === OPT_OUT)
        .map((optStatus) => normalizePhoneNumber(optStatus.phoneNumber));
};

export const getValidRecipients = ({ optStatuses, recipients }) => {
    const optedOutPhoneNumbers = getOptedOutRecipients(optStatuses);

    return recipients
        .filter(recipientHasValidNumber)
        .filter(recipientHasNotOptedOut(optedOutPhoneNumbers))
        .filter(recipientIsNotDuplicate);
};

export const getValidRecipientCount = ({ optStatuses, recipients }) => getValidRecipients({ optStatuses, recipients }).length;

export const getOptedInRecipientCount = ({ optStatuses }) => getOptedInRecipients({ optStatuses }).length;

export const showEditForStoppedBroadcast = (scheduleDate) => {
    const date = moment(scheduleDate);
    const twoMinutesFromNow = moment().add(2, 'minutes');

    return date < twoMinutesFromNow;
};

export const getLongestMergeableFieldLength = (recipients) => Math.max(...(recipients.map((r) => r?.mergeableField?.length)), 0);

export const getMessageLength = (message, longestMergeableFieldLength = 0) => {
    const mergeFieldOccurrences = message.match(/\[\[contact.first_name\]\]/g) || [];
    const mergeFieldLength = '[[contact.first_name]]'.length;
    const mergeFieldOffset = longestMergeableFieldLength > 0
        ? mergeFieldLength - longestMergeableFieldLength
        : 0;

    return {
        length: message.length - mergeFieldOffset * mergeFieldOccurrences.length,
        occurrences: mergeFieldOccurrences.length,
        offset: mergeFieldOffset,
    };
};

export const getStatisticValueText = (value, total) => {
    return value && value !== 0 && total !== 0
        ? `${value} (${Math.round(value / total * 100)}%)`
        : '0';
};

const MERGE_FIELD_PATTERN = /[[{]+.*?[\]}]+/g;

export const getUnsupportedMergeFields = (text, supportedMergeFields) => {
    const splitSupportedMergeFields = supportedMergeFields.flatMap((mergeField) => mergeField.split(' '));

    return [...text.matchAll(MERGE_FIELD_PATTERN)]
        .map((match) => match[0])
        .filter((match) => !splitSupportedMergeFields.includes(match));
};

export const hasUnsupportedMergeField = (text, supportedMergeFields) => {
    const unsupportedMergeFields = getUnsupportedMergeFields(text, supportedMergeFields);

    return unsupportedMergeFields.length > 0;
};

export const hasSupportedMergeField = (text, supportedMergeFields) => {
    return supportedMergeFields
        .filter((mergeField) => text.includes(mergeField))
        .length > 0;
};
