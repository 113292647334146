<template lang="html">
    <form
        ref="form"
        class="note-add-form"
        novalidate
        @submit.prevent="submit"
    >
        <contact-search-field
            v-model="contact"
            :placeholder="$t('global.contact')"
            required
            data-qa="task-form-contact"
        />

        <ds-text-area-field
            v-model="body"
            class="note"
            :label="$t('global.note')"
            required
            :maxlength="null"
        />

        <section class="button-row">
            <ds-filled-button
                name="button"
                type="submit"
                :disabled="disableSave"
                :loading="saving"
            >
                {{ $t('contactRecord.notes.save') }}
            </ds-filled-button>
        </section>
    </form>
</template>

<script>
import ContactSearchField from '@/contacts/components/ContactSearchField';
import { trackNoteAdded } from '@/contacts/analytics';

export default {
    components: {
        ContactSearchField,
    },

    data() {
        return {
            body: '',
            contact: null,
            saving: false,
        };
    },

    computed: {
        disableSave() {
            return !this.contact || !this.body;
        },

        nestedModal_title() {
            return this.$t('title');
        },
    },

    methods: {
        submit() {
            this.saving = true;

            const payload = {
                contactId: this.contact.id,
                body: this.body,
            };

            return this.$store.dispatch('contacts/ADD_NOTE', payload)
                .then(() => {
                    this.saving = false;

                    this.$toast({
                        message: this.$t('success.addNote'),
                    });

                    trackNoteAdded('Global Action');

                    this.$bus.$emit('CLOSE_NESTED_MODAL');
                })
                .catch(() => {
                    this.saving = false;

                    this.$error({
                        message: this.$t('errors.addNote'),
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .note {
        --textarea-min-height: #{px-to-rem(200px)};
    }
</style>

<i18n>
{
    "en-us": {
        "title": "Add a note"
    }
}
</i18n>
