<template>
    <div>
        <form
            ref="form"
            data-qa="custom-field-form"
            novalidate
            @submit.prevent="saveField"
        >
            <ds-placeholder v-if="useNewLayout && isCustomFieldLoading" data-qa="placeholder" :rows="$options.ROWS" />

            <div v-else-if="!isCustomFieldLoading" class="field-section">
                <ds-input-field
                    v-model="localCustomField[namePropName]"
                    type="text"
                    required
                    autofocus
                    data-qa="field-name"
                    :label="$t('fieldName')"
                    :maxlength="$options.CUSTOM_FIELD_NAME_MAX_LENGTH"
                    :submitted="submitted || isNameDuplicate"
                    :invalid="isNameDuplicate"
                >
                    <template #help>
                        <small
                            class="name-length"
                            :class="{ 'right-anchor': !useNewLayout }"
                        >
                            {{ customfieldNameLength }}/{{ $options.CUSTOM_FIELD_NAME_MAX_LENGTH }}
                            {{ useNewLayout ? $t('characters') : '' }}
                        </small>
                    </template>

                    <template #error>
                        <small v-if="isNameDuplicate">
                            {{ $t('duplicateFieldNameError' ) }}
                        </small>
                    </template>
                </ds-input-field>

                <ds-input-field
                    v-model="customFieldType"
                    :label="$t('fieldType')"
                    readonly
                >
                    <template #help>
                        <small>{{ $t('helpText') }}</small>
                    </template>
                </ds-input-field>

                <ds-text-area-field
                    v-if="hasOptions"
                    v-model="localCustomField[valuesPropName]"
                    data-qa="custom-field-options"
                    required
                    :label="$t('fieldOptions')"
                    :submitted="submitted"
                />

                <ds-checkbox
                    v-if="hasSettings"
                    v-model="localCustomField.defaultToFirstOption"
                    data-qa="default-first-option"
                    :label="$t('setDefaultValue')"
                />

                <i18n
                    v-if="useNewLayout && typeof customField.count === 'number'"
                    path="usageCount"
                    tag="p"
                    data-qa="usage-notice"
                >
                    <template #count>
                        <b>{{ customField.count }}</b>
                    </template>

                    <template #recordType>
                        <span>{{ $tc(`recordTypes.${recordType}`, customField.count) }}</span>
                    </template>
                </i18n>
            </div>

            <custom-field-edit-advanced
                v-if="useNewLayout"
                :record-type="recordType"
                :is-loading="isLoading"
                @delete="confirmDeleteField"
            >
                <template #default>
                    <div class="always-shown">
                        <ds-checkbox
                            v-model="localCustomField.alwaysShown"
                            :label="$t('customFields.alwaysShown.label')"
                            data-qa="always-shown"
                        />

                        <p class="caption">
                            {{ $t(`customFields.alwaysShown.description.${recordType}`) }}
                        </p>
                    </div>
                </template>
            </custom-field-edit-advanced>

            <div class="button-row">
                <div>
                    <ds-filled-button
                        type="submit"
                        :loading="isLoading"
                        :disabled="disabled"
                        data-qa="save-button"
                    >
                        {{ useNewLayout ? $t('saveChanges') : $t('saveLabel') }}
                    </ds-filled-button>

                    <ds-text-button
                        :disabled="!canCancel"
                        data-qa="cancel-button"
                        @click="onCancel"
                    >
                        {{ isModalContext ? $t('global.cancel') : $t('cancelChanges') }}
                    </ds-text-button>
                </div>

                <ds-outline-button
                    v-if="!useNewLayout"
                    destructive
                    :disabled="isLoading"
                    data-qa="delete-button"
                    @click="confirmDeleteField"
                >
                    {{ $t('deleteFieldButtonLabel') }}
                </ds-outline-button>
            </div>
        </form>

        <custom-field-confirm-delete-modal
            :is-open="showConfirmDeleteFieldModal"
            :record-type="recordType"
            :object-name="objectName"
            :parent-name="parentName"
            :usage-count="customField.count"
            @confirm="deleteField"
            @close="closeConfirmDelete"
        />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { isString, cloneDeep, capitalize } from 'lodash';

import {
    IDLE,
    LOADING,
    SUCCESS,
    ERROR,
} from '@/contacts/loadingStatuses';

import { FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS } from '@/shared/constants/featureFlag.constants';

import CustomFieldEditAdvanced from './CustomFieldEditAdvanced';
import CustomFieldConfirmDeleteModal from '@/contacts/components/customFields/CustomFieldConfirmDeleteModal';
import contactFieldTypes from '@/contacts/components/details/contactFieldTypes';
import { trackCustomFieldEdited, trackCustomFieldDeleted } from '@/contacts/analytics';

import { CUSTOM_FIELD_RECORD_TYPES } from '../customFields.constants';

const CUSTOM_FIELD_NAME_MAX_LENGTH = 100;

export default {
    CUSTOM_FIELD_NAME_MAX_LENGTH,
    ROWS: [
        {
            height: '3rem',
            boxes: [1],
        },
        {
            height: '3rem',
            boxes: [1],
        },
        {
            height: '3rem',
            boxes: [1],
        },
    ],

    components: {
        CustomFieldEditAdvanced,
        CustomFieldConfirmDeleteModal,
    },

    props: {
        isOpen: Boolean,
        customFieldLoadingStatus: {
            type: String,
            default: IDLE,
        },
        recordType: {
            type: String,
            required: true,
            validator: (value) => CUSTOM_FIELD_RECORD_TYPES.includes(value),
        },
        customField: Object,
        deleteCustomField: Function,
        updateCustomField: Function,
        valuesPropName: {
            type: String,
            default: 'values',
        },
        namePropName: {
            type: String,
            default: 'name',
        },
        typePropName: {
            type: String,
            default: 'type',
        },
        objectName: String,
        parentName: String,
        customFields: Array,
        isModalContext: Boolean,
    },

    data() {
        return {
            submitted: false,
            localCustomField: {},
            customFieldSaveStatus: IDLE,
            showConfirmDeleteFieldModal: false,

        };
    },

    created() {
        this.setLocalCustomField(this.customField);
    },

    watch: {
        customField(customField) {
            this.setLocalCustomField(customField);
        },
    },

    computed: {
        ...mapState({
            useNewLayout: ({ featureFlags }) => featureFlags[FF_FLAGSHIP_CUSTOM_FIELD_SETTINGS],
        }),

        customfieldNameLength() {
            return this.localCustomField[this.namePropName]?.length || 0;
        },

        hasOptions() {
            const { customField: { [this.typePropName]: type } } = this;

            return type && contactFieldTypes.CUSTOM[type]
                ? contactFieldTypes.CUSTOM[type].hasOptions
                : false;
        },

        hasSettings() {
            const { customField: { [this.typePropName]: type } } = this;

            return type && contactFieldTypes.CUSTOM[type]
                ? contactFieldTypes.CUSTOM[type].hasSettings
                : false;
        },

        hasValueChanged() {
            const { valuesPropName: values, namePropName: name } = this;

            const changed = this.customField[name] !== this.localCustomField[name]
                || this.customFieldValues !== this.localCustomField[values]
                || (this.hasSettings && this.customField.defaultToFirstOption !== this.localCustomField.defaultToFirstOption)
                || this.customField.alwaysShown !== this.localCustomField.alwaysShown;

            if (changed) {
                this.$store.commit('CONFIRM_NAVIGATION_MARK_DIRTY', 'customField');
            } else {
                this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'customField');
            }

            return changed;
        },

        isLoading() {
            return this.customFieldSaveStatus === LOADING;
        },

        isCustomFieldLoading() {
            return this.customFieldLoadingStatus === LOADING;
        },

        isNameDuplicate() {
            if (this.localCustomField[this.namePropName] == null) {
                return false;
            }

            const currentCustomFieldId = Number(this.customField.dataFormFieldId || this.customField.id);

            return this.customFields.some(({ label, id }) => Number(id) !== currentCustomFieldId && label?.match(this.getCustomFieldPattern));
        },

        getCustomFieldPattern() {
            const customFieldName = this.localCustomField[this.namePropName].replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const customFieldPattern = new RegExp(`^${customFieldName}$`, 'i');

            return customFieldPattern;
        },

        fieldValuesEmpty() {
            return !this.localCustomField[this.valuesPropName] || this.localCustomField[this.valuesPropName].length === 0;
        },

        canCancel() {
            return !this.isLoading && (this.isModalContext || this.hasValueChanged);
        },

        disabled() {
            return this.localCustomField[this.namePropName] === '' || !this.hasValueChanged || this.isNameDuplicate || (this.hasOptions && this.fieldValuesEmpty);
        },

        customFieldValues() {
            const { valuesPropName: values } = this;

            if (this.customField[values]) {
                return this.customField[values].map((option) => {
                    if (isString(option)) {
                        return option;
                    }

                    return option.label;
                }).join('\r\n');
            }

            return this.customField[values];
        },

        customFieldType() {
            if (this.localCustomField[this.typePropName] === contactFieldTypes.CUSTOM_FIELD_NAMES.MULTISELECT
                || this.localCustomField[this.typePropName] === contactFieldTypes.CUSTOM_FIELD_NAMES.CHECKBOXES) {
                return 'Checkboxes';
            }

            return this.localCustomField[this.typePropName];
        },

        eventSource() {
            return this.isModalContext
                ? `${capitalize(this.recordType)} Record`
                : 'Custom Fields Settings Page';
        },
    },

    methods: {
        setLocalCustomField(customField) {
            const { valuesPropName: values } = this;

            this.localCustomField = cloneDeep(customField);

            if (this.localCustomField[values]) {
                this.localCustomField[values] = this.localCustomField[values].map((option) => {
                    if (isString(option)) {
                        return option;
                    }

                    return option.label;
                }).join('\r\n');
            }
        },

        saveField() {
            this.submitted = true;

            const { localCustomField: customField } = this;

            const values = customField[this.valuesPropName] && customField[this.valuesPropName].length
                ? customField[this.valuesPropName]
                    .replace(/[\r\n]+/g, '\n')
                    .replace(/,/g, '')
                    .trim()
                    .split('\n')
                    .filter((value) => value.length > 0)
                : [];

            const payload = {
                fieldId: customField.id,
                originGroupId: customField.groupId,
                customField: {
                    name: customField[this.namePropName],
                    groupId: customField.groupId,
                    type: customField[this.typePropName],
                    visible: customField.visible,
                    defaultToFirstOption: customField.defaultToFirstOption,
                    alwaysShown: customField.alwaysShown,
                    values,
                },
            };

            if (this.$refs.form.checkValidity()) {
                this.customFieldSaveStatus = LOADING;

                return this.updateCustomField(payload)
                    .then(() => {
                        trackCustomFieldEdited({
                            recordType: this.recordType,
                            eventSource: this.eventSource,
                        });

                        this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'customField');

                        return this.success('success');
                    })
                    .catch(() => this.error('error'));
            }

            return Promise.reject();
        },

        confirmDeleteField() {
            this.showConfirmDeleteFieldModal = true;
        },

        closeConfirmDelete() {
            this.showConfirmDeleteFieldModal = false;
        },

        deleteField() {
            this.customFieldSaveStatus = LOADING;
            this.showConfirmDeleteFieldModal = false;
            const { id: fieldId, groupId } = this.customField;

            return this.deleteCustomField({ fieldId, groupId })
                .then(() => {
                    trackCustomFieldDeleted({
                        recordType: this.recordType,
                        eventSource: this.eventSource,
                    });

                    this.$store.commit('CONFIRM_NAVIGATION_MARK_CLEAN', 'customField');
                    this.success('deleteConfirm.success');
                    this.$emit('deleted');
                })
                .catch(() => this.error('deleteConfirm.error'));
        },

        success(msgPath) {
            this.customFieldSaveStatus = SUCCESS;
            this.$emit('updated');
            this.$emit('close');
            this.$toast({ message: this.$t(msgPath) });
        },

        error(msgPath) {
            this.customFieldSaveStatus = ERROR;
            this.$error({
                message: this.$t(msgPath),
                bottom: true,
            });
        },

        onCancel() {
            this.setLocalCustomField(this.customField);
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
    form {
        display: grid;
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-gap: $gp * 1.5;
        --input-margin-bottom: 0;
        --checkbox-padding: 0 0.5rem;
        .field-section {
            display: grid;
            grid-template-columns: auto;
            grid-template-rows: auto;
            grid-gap: $gp * 1.5;
        }
    }

    .button-row {
        display: flex;
        justify-content: space-between;
    }

    .name-length.right-anchor {
        display: block;
        text-align: right;
    }

    .always-shown {
        grid-column: 1 / span 2;

        .caption {
            @include padding-start(#{$gp * 2.25});
            color: $color-ink-800;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "characters": "characters",
        "duplicateFieldNameError": "This field name is already being used",
        "fieldName": "Field name",
        "fieldType": "Field type",
        "fieldOptions": "Field Options",
        "helpText": "Field type cannot be changed after creation.",
        "setDefaultValue": "Set the first dropdown option as selected by default",
        "saveLabel": "Save field",
        "deleteFieldButtonLabel": "Delete field",
        "error": "There was an error saving the custom field",
        "success": "Custom field saved",
        "deleteConfirm": {
            "title": "Delete field and all data?",
            "message": "If you delete this field you also will lose all the data stored in it.",
            "success": "Custom field deleted",
            "error": "There was an error deleting the custom field"
        },
        "saveChanges": "Save changes",
        "cancelChanges": "Cancel changes",
        "alwaysShown": {
            "label": "Always shown",
            "description": "If checked, this field will be automatically displayed on every contact record."
        },
        "usageCount": "This field is being used to store data by {count} {recordType}.",
        "recordTypes": {
            "contact": "contact | contacts",
            "company": "company | companies"
        }
    }
}
</i18n>
