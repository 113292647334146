<template>
    <span :class="['feature-label', type]">{{ label }}</span>
</template>

<script>
export const PRO = 'pro';
export const BETA = 'beta';

export default {
    props: {
        type: {
            type: String,
            options: [PRO, BETA],
            default: PRO,
        },
    },

    computed: {
        label() {
            switch (this.type) {
            case BETA:
                return this.$t('beta');

            default:
                return this.$t('pro');
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .feature-label {
        color: $color-paper;
        background-color: $color-gray-700;
        padding: px-to-rem(2px) px-to-rem(5px);
        border-radius: px-to-rem(3px);
        font-size: $font-size-xs;
        font-weight: $font-weight-semibold;

        &.beta {
            background-color: $color-blue-200;
        }
    }
</style>

<i18n>
{
    "en-us": {
        "pro": "Pro",
        "beta": "Beta"
    }
}
</i18n>
