import moment from 'moment';
import Vue from 'vue';
import groupBy from 'lodash.groupby';
import { displayFullName } from '@/contacts/contact-info-utils';
import { STATUS_OPTIONS } from '@/pipeline/components/deal/DealStatus';
import { PREFERENCE_TYPES } from '@/shared/constants/preferences.constants';
import { FF_KEAP_SINGLE_PIPELINE } from '@/shared/constants/featureFlag.constants';

export default {
    getPipelineById: ({ pipelineList }) => (pipelineId) => {
        return pipelineList.find(({ id }) => id === pipelineId) || {};
    },

    pipelineFilters: ({ pipeline }, __, ___, rootGetters) => {
        const filtersByPipeline = rootGetters['preferences/getPreference'](PREFERENCE_TYPES.PIPELINE_FILTERS);

        return filtersByPipeline && filtersByPipeline[pipeline?.id] || [];
    },

    getFilteredStages: ({ pipeline }) => (excludedStageId) => {
        return pipeline.stages?.filter(({ id }) => excludedStageId !== id);
    },

    getStageOptionsWithPipeline: ({ pipelineList }) => (excludedStageId = 0, excludedPipelineId = 0) => {
        return pipelineList.reduce((stageOptions, { id, name, stages }) => {
            if (excludedPipelineId !== id) {
                const category = { value: id, label: name };
                const options = [];

                stages.forEach((stage) => {
                    if (excludedStageId !== stage.id) {
                        options.push({ value: stage.id, label: stage.name, pipelineId: id });
                    }
                });

                if (options.length > 0) {
                    stageOptions.push({ category, options });
                }
            }

            return stageOptions;
        }, []);
    },

    getAllStages: ({ pipelineList }) => {
        return pipelineList.reduce((list, { stages }) => {
            list.push(...stages);

            return list;
        }, []);
    },

    stageById: (state, getters) => (id) => {
        return getters.getAllStages.find((stage) => stage.id === id);
    },

    primaryContact: ({ deal: { contacts } }) => {
        if (contacts) {
            return contacts.find(({ primaryContact }) => primaryContact);
        }

        return null;
    },

    stageTotal: ({ pipeline }) => (stageId) => {
        const stage = pipeline.stages.find((s) => s.id === stageId);

        return stage.deals?.reduce((total, { value }) => {
            const amount = value.amount || 0;

            return +total + +amount;
        }, 0) ?? 0;
    },

    numberOfStages: ({ pipeline }) => {
        return pipeline.stages.length;
    },

    appendFullName: () => (contact) => {
        return {
            fullName: displayFullName(contact),
            ...contact,
        };
    },

    notesByDate({ notes }) {
        if (notes && notes.length) {
            return groupBy(notes, ({ modified }) => {
                return moment(modified).startOf('day').toDate();
            });
        }

        return {};
    },

    dealStatusOptions({ outcomeOptions, pipeline }) {
        const instance = Vue.prototype.$i18nInstance || { t: (str) => str };

        const dealStatus = {
            [STATUS_OPTIONS.ACTIVE]: instance.t(`pipelinePage.outcomes.${STATUS_OPTIONS.ACTIVE}`),
            [STATUS_OPTIONS.WON]: instance.t(`pipelinePage.outcomes.${STATUS_OPTIONS.WON}`),
            [STATUS_OPTIONS.LOST]: instance.t(`pipelinePage.outcomes.${STATUS_OPTIONS.LOST}`),
        };

        outcomeOptions?.[pipeline?.id]?.forEach((option) => {
            if (option.label) {
                dealStatus[option.value] = option.label;
            }
        });

        return Object.keys(dealStatus).map((value) => ({ value, label: dealStatus[value] }));
    },

    getDealById: ({ pipeline }) => (dealId) => {
        for (const stage of pipeline.stages) {
            for (const deal of stage.deals) {
                if (deal.id === dealId) {
                    return deal;
                }
            }
        }

        return null;
    },

    hasAnyPipelineFeature: (_, getters, { featureFlags }, rootGetters) => {
        return rootGetters['auth/hasMultiplePipelinesFeature'] || rootGetters['auth/hasSinglePipelineFeature']
            || featureFlags[FF_KEAP_SINGLE_PIPELINE];
    },
};
