<template>
    <controlled-modal
        :is-open="Boolean(previewHtml)"
        show-close
        :title="previewName || ''"
        size="fullscreen"
        class="preview-modal"
        @close="$emit('close')"
    >
        <template #header>
            <div v-if="allowSelection" class="header">
                <ds-filled-button data-qa="use-this-template" @click="$emit('select')">
                    {{ $t('useThisTemplate') }}
                </ds-filled-button>
            </div>
        </template>

        <div class="preview" data-qa="template-preview">
            <ds-html-preview :html="previewHtml" disable-scripts />
        </div>
    </controlled-modal>
</template>

<script>
import ControlledModal from '@/shared/components/ControlledModal';

export default {
    components: { ControlledModal },

    props: {
        allowSelection: Boolean,

        previewName: {
            type: String,
            required: false,
        },

        previewHtml: {
            type: String,
            required: false,
        },
    },

    data() {
        return {
            selectedPreview: 'desktop',
            previewOptions: [{
                value: 'desktop',
                label: 'Desktop',
            },
            {
                value: 'mobile',
                label: 'Mobile',
            },
            ],
        };
    },

    computed: {
        isMobilePreview() {
            return this.selectedPreview === 'mobile';
        },
    },
};
</script>

<style lang="scss" scoped>
    .preview {
        width: px-to-rem(1024);
        flex: 1;
        margin: $gp * 2 auto;
    }

    .header {
        margin-right: $gp;
    }

    .preview-modal {
        height: 100%;
    }


</style>

<i18n>
{
    "en-us": {
        "useThisTemplate": "Use this template"
   }
}
</i18n>
