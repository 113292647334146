<template>
    <div class="configure-details">
        <div class="configure-header">
            <div class="configure-title">
                {{ title }}
            </div>

            <div v-if="hasError" class="configure-error">
                {{ errorMessage }}
            </div>

            <div v-else-if="subtitle" class="configure-subtitle">
                {{ subtitle }}
            </div>
        </div>

        <div class="configure-edit">
            <ds-icon-button
                v-if="!hideEdit"
                data-qa="configure-edit"
                class="subtle"
                name="edit"
                @click="$emit('edit')"
            />

            <delete-configuration
                data-qa="configure-remove"
                :type="type"
                :item-id="itemId"
                :trigger-action="triggerAction"
                @remove="(payload) => $emit('remove', payload)"
            />
        </div>
    </div>
</template>

<script>
import DeleteConfiguration from './DeleteConfiguration';

export default {
    components: {
        DeleteConfiguration,
    },

    props: {
        title: String,
        subtitle: String,
        hasError: Boolean,
        errorMessage: String,
        type: String,
        hideEdit: Boolean,
        triggerAction: String,
        itemId: String,
    },
};
</script>

<style lang="scss" scoped>
    @import '~@/automations/styles/configure';
</style>
