import Vue from 'vue';
import gql from 'graphql-tag';

export const queryCommunicationTemplateList = async ({ mediaType, k8sUrl = '' } = {}) => {
    const { data: { communicationTemplates } } = await Vue.prototype.$graphql.query({
        query: gql`
            query communicationTemplates($mediaType: String, $k8sUrl: String) {
                communicationTemplates(mediaType: $mediaType, k8sUrl: $k8sUrl) {
                    id
                    mediaType
                    templateKind
                    title
                    subject
                    content
                    createdOn
                    updatedOn
                }
            }
        `,
        variables: {
            mediaType,
            k8sUrl,
        },
        fetchPolicy: 'no-cache',
    });

    return communicationTemplates;
};

export const mutationCreateCommunicationTemplate = async ({ payload, k8sUrl = '' }) => {
    const { data: { createCommunicationTemplate } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation createCommunicationTemplate($payload: CreateCommunicationTemplateInput, $k8sUrl: String) {
                createCommunicationTemplate(payload: $payload, k8sUrl: $k8sUrl) {
                    id
                    mediaType
                    templateKind
                    title
                    subject
                    content
                    createdOn
                    updatedOn
                }
            }
        `,
        variables: {
            payload,
            k8sUrl,
        },
        fetchPolicy: 'no-cache',
    });

    return createCommunicationTemplate;
};

export const queryCommunicationTemplate = async ({ templateId, k8sUrl = '' }) => {
    const { data: { communicationTemplate } } = await Vue.prototype.$graphql.query({
        query: gql`
            query communicationTemplate($templateId: ID!, $k8sUrl: String) {
                communicationTemplate(templateId: $templateId, k8sUrl: $k8sUrl) {
                    id
                    mediaType
                    templateKind
                    title
                    subject
                    content
                    createdOn
                    updatedOn
                }
            }
        `,
        variables: {
            templateId,
            k8sUrl,
        },
        fetchPolicy: 'no-cache',
    });

    return communicationTemplate;
};

export const mutationUpdateCommunicationTemplate = async ({ payload, templateId, k8sUrl = '' }) => {
    const { data: { updateCommunicationTemplate } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation updateCommunicationTemplate($payload: CreateCommunicationTemplateInput, $templateId: ID, $k8sUrl: String) {
                updateCommunicationTemplate(payload: $payload, templateId: $templateId, k8sUrl: $k8sUrl) {
                    id
                    mediaType
                    templateKind
                    title
                    subject
                    content
                    createdOn
                    updatedOn
                }
            }
        `,
        variables: {
            payload,
            templateId,
            k8sUrl,
        },
        fetchPolicy: 'no-cache',
    });

    return updateCommunicationTemplate;
};

export const mutationDeleteCommunicationTemplate = async ({ templateId, k8sUrl = '' }) => {
    const { data: { deleteCommunicationTemplate } } = await Vue.prototype.$graphql.mutate({
        mutation: gql`
            mutation deleteCommunicationTemplate($templateId: ID, $k8sUrl: String) {
                deleteCommunicationTemplate(templateId: $templateId, k8sUrl: $k8sUrl)
            }
        `,
        variables: {
            templateId,
            k8sUrl,
        },
        fetchPolicy: 'no-cache',
    });

    return deleteCommunicationTemplate;
};
