import {
    fetchContactTags,
    addContactTags,
    removeContactTag,
    addTagToContacts,
    removeTagFromContacts,
} from '../../api';

export const LOAD_CONTACT_TAGS = async ({ state, commit }) => {
    const { data: tags } = await fetchContactTags({ contactId: state.contact.id });

    commit('SET_CONTACT_TAGS', tags);
};

export const APPLY_CONTACT_TAGS = async ({ state, commit }, { tagsToApply, eventSource }) => {
    const { data: addedTagIds } = await addContactTags({ contactId: state.contact.id, tagsToApply, eventSource });

    const addedTags = tagsToApply.filter((tag) => addedTagIds.includes(tag.id));

    commit('APPLY_CONTACT_TAGS', addedTags);
};

export const REMOVE_CONTACT_TAG = async ({ state, commit }, tagId) => {
    await removeContactTag({ contactId: state.contact.id, tagId });

    commit('REMOVE_CONTACT_TAG', tagId);
};

// This currently doesn't interact with state.
// Maybe it could be updated to do so,
// or possibly be removed as an action.
export const APPLY_TAG_TO_CONTACTS = (_, {
    tagId,
    savedFilterId,
    ids,
    filterItems,
    checkAll,
}) => {
    return addTagToContacts({
        tagId,
        savedFilterId,
        ids,
        filterItems,
        checkAll,
    });
};

// This currently doesn't interact with state.
// Maybe it could be updated to do so,
// or possibly be removed as an action.
export const REMOVE_TAG_FROM_CONTACTS = (_, {
    tagId,
    savedFilterId,
    ids,
    filterItems,
    checkAll,
}) => {
    return removeTagFromContacts({
        tagId,
        savedFilterId,
        ids,
        filterItems,
        checkAll,
    });
};
