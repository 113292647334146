var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['then-card', { 'configured': _vm.stepSelected, active: _vm.active, inactive: _vm.inactive }],on:{"click":function($event){return _vm.$emit('selected')}}},[_c('header',{staticClass:"header"},[_c('ds-icon',{staticClass:"zap-icon",attrs:{"name":"zap"}}),_c('span',{staticClass:"header-text"},[_vm._v(_vm._s(_vm.$t('header')))])],1),(!_vm.stepSelected)?_c('div',{staticClass:"description"},[_c('p',{staticClass:"description-title"},[_vm._v(" "+_vm._s(_vm.$t('description'))+" ")]),_c('ds-icon-button',{staticClass:"add-button",attrs:{"name":"add-fill"}})],1):_vm._l((_vm.stepDisplay),function(display,i){return _c('div',{key:display.stepIndex,class:['description', { 'first-step' : i === 0, 'timer-description': _vm.isTimerStep(display.step) }],on:{"click":function($event){$event.stopPropagation();return _vm.handleSelect(display.selectableStepIndex)}}},[(_vm.isInvalid(display.step, display.stepIndex))?_c('invalid-icon',{staticClass:"invalid-icon"}):(_vm.isTimerStep(display.step))?_c('ds-icon',{class:['timer-icon', { 'warning': _vm.hasTimerWarning(display.stepIndex) }],attrs:{"name":_vm.hasTimerWarning(display.stepIndex) ? 'alert-triangle' : 'clock'}}):_vm._e(),_c('automation-display-name',{class:['description-title', {
                    'timer-description-title': _vm.isTimerStep(display.step),
                    flash: _vm.showFlash(display.children ? display.children[0].step : display.step),
                    'alert-text': _vm.isInvalid(display.step, display.stepIndex),
                    warning: _vm.hasTimerWarning(display.stepIndex)
                }],attrs:{"value":display.step,"data-qa":("step_" + (display.stepIndex))}}),_vm._l((display.children),function(child,j){return _c('div',{key:child.stepIndex,class:['nested-step', { 'first-child-step' : j === 0, 'last-step' : j + 1 === display.children.length }],on:{"click":function($event){$event.stopPropagation();return _vm.handleSelect(child.selectableStepIndex)}}},[(_vm.isInvalid(child.step, child.stepIndex))?_c('invalid-icon',{staticClass:"invalid-icon"}):_vm._e(),_c('automation-display-name',{class:['description-title', {
                        flash: _vm.showFlash(child.step),
                        'alert-text': _vm.isInvalid(child.step, child.stepIndex)
                    }],attrs:{"value":child.step,"data-qa":("step_" + (child.stepIndex))}}),(j < display.children.length - 1)?_c('div',{staticClass:"divider"},[_c('ds-icon',{staticClass:"icon",attrs:{"name":"chevron-down"}})],1):_vm._e()],1)}),(i < _vm.stepDisplay.length - 1)?_c('div',{staticClass:"divider"},[_c('ds-icon',{staticClass:"icon",attrs:{"name":"chevron-down"}})],1):_vm._e()],2)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }