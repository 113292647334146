<template lang="html">
    <div :class="['review-stars', { small }]">
        <span
            v-for="n in maxRating"
            :key="n"
            :class="{ full: n <= starCount }"
        >
            ★
        </span>
    </div>
</template>

<script>
export default {
    props: {
        rating: [Number, String],
        small: Boolean,
        maxRating: {
            type: Number,
            default: 5,
        },
    },

    computed: {
        starCount() {
            return Number(this.rating) > this.maxRating
                ? this.maxRating
                : Number(this.rating);
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    .review-stars {
        color: $color-gray-600;
        font-size: $font-size-huge;

        &.small {
            font-size: $font-size-small;
        }

        .full {
            color: $color-yellow;
        }
    }
</style>
