const publishedCampaigns = ({ campaigns }) => (offset = 0, count = 5) => {
    if (!Array.isArray(campaigns)) {
        return [];
    }

    return campaigns.filter((campaign) => campaign.publishedStatus).slice(offset, count);
};

const getCampaignById = ({ campaigns }) => (id) => {
    return campaigns.filter((campaign) => campaign.id === id);
};

export default {
    publishedCampaigns,
    getCampaignById,
};
