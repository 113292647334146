<template lang="html">
    <contact-phone-email-view
        v-if="!editing"
        :contact="contact"
        :contact-email-marketability="contactEmailMarketability"
    />

    <contact-phone-email-edit
        v-else
        :contact="contact"
        :phone-options="fieldOptions.phone"
        :fax-options="fieldOptions.fax"
        :contact-email-marketability="contactEmailMarketability"
        :submitted="submitted"
        @input="$emit('input')"
    />
</template>

<script>
import { mapGetters } from 'vuex';
import ContactPhoneEmailView from '@/contacts/components/simple/ContactPhoneEmailView';
import ContactPhoneEmailEdit from '@/contacts/components/simple/ContactPhoneEmailEdit';

export default {
    components: {
        ContactPhoneEmailView,
        ContactPhoneEmailEdit,
    },

    props: {
        contact: Object,
        contactEmailMarketability: Object,
        submitted: Boolean,
        editing: Boolean,
    },

    computed: {
        ...mapGetters({
            fieldOptions: 'contacts/fieldOptions',
        }),
    },
};
</script>
