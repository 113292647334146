<template lang="html">
    <ds-dropdown icons class="automation-list-item-dropdown">
        <template #default>
            <ds-icon-button name="more-vertical" />
        </template>

        <template #menu>
            <ul class="dropdown-menu-list">
                <li
                    v-if="automation.hasChanges"
                    class="dropdown-menu-item"
                    data-qa="revert-automation-edits"
                    @click="$emit('revert')"
                >
                    <ds-icon class="option-icon" name="rotate-ccw" />

                    {{ $t('moreActions.revertEdits') }}
                </li>

                <li
                    v-if="isPublished"
                    class="dropdown-menu-item deactivate-automation"
                    data-qa="deactivate-automation"
                    @click="$emit('deactivate')"
                >
                    <ds-icon class="option-icon" name="zap-off" />

                    {{ $t('moreActions.deactivate') }}
                </li>

                <li class="dropdown-menu-item" data-qa="edit-automation" @click="$emit('edit')">
                    <ds-icon class="option-icon" name="pencil" />

                    {{ $t('global.edit') }}
                </li>

                <li class="dropdown-menu-item" data-qa="rename-automation" @click="$emit('rename')">
                    <ds-icon class="option-icon" name="edit" />

                    {{ $t('moreActions.rename') }}
                </li>

                <li
                    class="dropdown-menu-item"
                    data-qa="copy-automation"
                    @click="$emit('copy')"
                >
                    <ds-icon class="option-icon" name="copy" />

                    {{ $t('moreActions.copy') }}
                </li>

                <li
                    v-if="isPublished && canShare && !automation.hasChanges"
                    class="dropdown-menu-item"
                    data-qa="share-automation"
                    @click="$emit('share')"
                >
                    <ds-icon class="option-icon" name="share-2" />

                    {{ $t('moreActions.share') }}
                </li>

                <li class="dropdown-menu-item delete" data-qa="delete-automation" @click="$emit('delete')">
                    <ds-icon class="option-icon" name="trash-2" />

                    {{ $t('moreActions.delete') }}
                </li>
            </ul>
        </template>
    </ds-dropdown>
</template>

<script>
import { mapState } from 'vuex';
import { FF_KEAP_SIMPLE_AUTOMATION_SHARING } from '@/shared/constants/featureFlag.constants';
import { AUTOMATION_STATUS } from '@/automations/constants/automations.constants';

export default {
    props: {
        automation: {
            type: Object,
            required: true,
        },
    },

    computed: {
        ...mapState({
            canShare: ({ featureFlags }) => featureFlags[FF_KEAP_SIMPLE_AUTOMATION_SHARING],
        }),

        isPublished() {
            return this.automation.status === AUTOMATION_STATUS.ENABLED;
        },
    },
};
</script>

<style lang="scss" scoped>
    .automation-list-item-dropdown {
        --dropdown-height: #{px-to-rem(340px)};

        .dropdown-menu-item {
            --icon-color: #{$color-ink};

            &.deactivate-automation {
                border-bottom: 1px solid $color-gray-200;
            }

            &.delete {
                --icon-color: #{$color-red};
            }
        }
    }
</style>

<i18n>
{
    "en-us": {
        "moreActions": {
            "rename": "Rename",
            "delete": "Delete",
            "deactivate": "Deactivate",
            "revertEdits": "Discard changes",
            "share": "Share",
            "copy": "Make a copy"
        }
    }
}
</i18n>
