import amplitude from '@/analytics/amplitude';
import sentry from '@/analytics/sentry';
import fullstory from '@/analytics/fullstory';
import firebase from 'firebase/app';
import 'firebase/database';
import moment from 'moment';
import axios from 'axios';
import cloneDeep from 'lodash.clonedeep';

import {
    SAMPLE_CONTACTS,
    SAMPLE_TASKS,
    SAMPLE_PIPELINE,
    SAMPLE_DEALS,
    SAMPLE_PRODUCTS,
    SAMPLE_INVOICES,
    SAMPLE_QUOTES,
    MAX_ERROR_RETRY,
} from '../constants/sampleData.constants';

export default {
    DISMISS_SAMPLE_DATA_CTA: (context) => {
        return dismissSampleDataCta(context);
    },

    RETRY_ADD_SAMPLE_DATA: (context) => {
        return retryAddSampleData(context);
    },

    CREATE_SAMPLE_DATA: (context, eventSource) => {
        return createSampleData(context, eventSource);
    },

    DELETE_SAMPLE_DATA: (context, eventSource) => {
        return deleteSampleData(context, eventSource);
    },

    DELETE_CONTACT_SAMPLE_DATA: (context, id) => {
        return deleteContactSampleData(context, id);
    },

    DELETE_DEAL_SAMPLE_DATA: (context, id) => {
        return deleteDealSampleData(context, id);
    },

    DELETE_PRODUCT_SAMPLE_DATA: (context, id) => {
        return deleteProductSampleData(context, id);
    },

    SAVE_SAMPLE_DATA_STARTED(context, value) {
        return saveSampleDataStarted(context, value);
    },

    SAVE_SAMPLE_DATA_SUCCESS(context, value) {
        return saveSampleDataSuccess(context, value);
    },

    LOAD_SAMPLE_DATA_STATUS(context) {
        return loadSampleDataStatus(context);
    },
};

const createSampleData = async ({
    state, rootState, dispatch, commit,
}, eventSource) => {
    if (state.addSampleDataErrorCount > MAX_ERROR_RETRY) {
        dispatch('sampleData/SAVE_SAMPLE_DATA_SUCCESS', false, { root: true });

        await dispatch('sampleData/DELETE_SAMPLE_DATA', null, { root: true });

        commit('SET_SAMPLE_DATA_PROGRESS', 7);

        return;
    }

    await dispatch('sampleData/SAVE_SAMPLE_DATA_STARTED', true, { root: true });

    const appId = rootState?.auth?.session?.coreAppId;
    const userId = rootState?.auth?.user?.id;
    const editionName = rootState?.auth?.account?.editionName;
    const { businessType } = rootState?.auth?.account?.companyProfile;
    const contactIds = [];
    const taskIds = [];
    const dealIds = [];
    const sampleProducts = [];
    const pipelineStagesIds = [];
    const pipelineStagesNames = [];
    const invoiceIds = [];
    const quoteIds = [];

    const hasDynamicContent = [
        'businessType.values.business',
        'businessType.values.software',
    ].includes(businessType);

    window.sampleDataInProgress = true;

    commit('SET_SAMPLE_DATA_PROGRESS', 0);

    async function asyncForEach(key, sampleData, callback) {
        for (let index = 0; index < sampleData.length; index++) {
            try {
                // eslint-disable-next-line no-await-in-loop
                await callback(sampleData[index], index);
            } catch (error) {
                sentry.captureException(error);

                if (key === 'contacts') {
                    throw error;
                }
            }
        }
    }

    async function saveToCoreAndFirebase(key, value, callback, data) {
        try {
            await asyncForEach(key, data, callback);

            await firebase.database().ref(`sampleData/${appId}/${key}`).set(value);
        } catch (error) {
            if (key === 'contacts') {
                await firebase.database().ref(`sampleData/${appId}/${key}`).set(contactIds);

                throw error;
            }
            sentry.captureException(error);
        }
    }

    // CONTACTS
    async function createSampleContact(contact) {
        const newContact = {
            givenName: contact.givenName,
            familyName: contact.familyName,
            email: contact.email,
            phone: contact.phone,
            contactType: contact.contactType,
            optInReason: contact.optInReason,
            notes: cloneDeep(contact.notes).filter((note) => {
                if (hasDynamicContent && businessType === note.noteType) {
                    delete note.noteType;

                    note.userId = userId.toString();

                    return note;
                }

                if (!hasDynamicContent) {
                    note.userId = userId.toString();

                    const isBusiness = note.noteType === 'businessType.values.business';

                    delete note.noteType;

                    return isBusiness;
                }

                return null;
            }),
        };

        const [createdContact] = await dispatch('contacts/ADD_CONTACT', newContact, { root: true });

        contactIds.push(createdContact.id);

        const updatedContact = {
            ...contact,
            firstName: contact.givenName,
            lastName: contact.familyName,
            phone1: contact.phone,
            company: '',
            owner: userId,
        };

        delete updatedContact.givenName;
        delete updatedContact.familyName;
        delete updatedContact.optInReason;
        delete updatedContact.phone;
        delete updatedContact.notes;

        await axios.put(`${process.env.VUE_APP_CORE_SPA_API_URL}/v1/contacts/${createdContact.id} `, updatedContact);
    }

    async function createSampleTasks(task, index) {
        const roundedTime = Math.ceil(moment().minute() / 15) * 15;
        const dueTime = moment().minute(roundedTime).format('LT');
        const dueDate = moment().millisecond(0).add(1, 'days').toDate();
        const momentDate = moment(dueDate).hours(moment(dueTime, 'LT').get('hour'));
        const dueDateAndTime = momentDate.minutes(moment(dueTime, 'LT').get('minute')).toDate();

        const payload = {
            task: {
                ...task,
                assignedUserId: userId,
                contactIds: [contactIds[index]],
                dueDate: dueDateAndTime,
            },
            currentContactId: [contactIds[index]],
        };

        await dispatch('contacts/ADD_TASK', payload, { root: true });
    }

    async function createSampleDeals(deal, index) {
        const newDeal = {
            ...deal,
            owner: { id: userId },
            stage: { id: pipelineStagesIds[index], name: pipelineStagesNames[index] },
        };

        newDeal.contacts[0].id = contactIds[index];

        const createdDeal = await dispatch('pipeline/ADD_PIPELINE_DEAL', newDeal, { root: true });

        dealIds.push(createdDeal.id);
    }

    async function createPipeline() {
        const newPipeline = await dispatch('pipeline/CREATE_PIPELINE', SAMPLE_PIPELINE, { root: true });

        commit('pipeline/SET_PIPELINE', newPipeline, { root: true });

        newPipeline.stages.forEach((stage) => {
            pipelineStagesNames.push(stage.name);
            pipelineStagesIds.push(stage.id);
        });

        await firebase.database().ref(`sampleData/${appId}/pipeline`).set(newPipeline.id);
    }

    async function createSampleProducts(product) {
        const payload = {
            productInfo: {
                description: product.description,
                endBy: null,
                name: product.name,
                rate: product.rate,
            },
        };

        await dispatch('billing/ADD_PRODUCT', payload, { root: true });

        sampleProducts.push(payload.productInfo);

        await firebase.database().ref(`sampleData/${appId}/products`).set(sampleProducts);
    }

    async function createSampleQuotes(quote, index) {
        const payload = {
            contactId: contactIds[index],
        };

        const createdQuote = await dispatch('sales/CREATE_QUOTE', { orderInfo: payload }, { root: true });

        const product = index > 3 ? sampleProducts[0] : sampleProducts[index];

        const productPayload = {
            id: product.value,
            productDesc: product.description,
            productName: product.name,
            productPrice: product.rate,
            status: 1,
            subscriptionOnly: false,
            subscriptionPlans: [],
        };

        await dispatch('sales/ADD_QUOTE_LIST_ITEM', {
            product: productPayload,
            quantity: 1,
            quoteId: createdQuote?.data?.id,
        }, { root: true });
    }

    async function createSampleInvoices(invoice, index) {
        const newInvoice = {
            ...invoice,
            orderItems: [],
            contactId: contactIds[index],
        };

        const createdInvoice = await dispatch('sales/CREATE_INVOICE', { orderInfo: newInvoice }, { root: true });

        const product = index > 3 ? sampleProducts[0] : sampleProducts[index];

        const productPayload = {
            id: product.value,
            productDesc: product.description,
            productName: product.name,
            productPrice: product.rate,
            status: 1,
            subscriptionOnly: false,
            subscriptionPlans: [],
        };

        await dispatch('sales/ADD_INVOICE_LIST_ITEM', {
            product: productPayload,
            quantity: 1,
            orderId: createdInvoice?.data?.id,
        }, { root: true });
    }

    try {
        // CONTACTS
        try {
            await saveToCoreAndFirebase('contacts', contactIds, createSampleContact, SAMPLE_CONTACTS);

            dispatch('contacts/LOAD_CONTACT_LIST', {}, { root: true });
        } catch (error) {
            sentry.captureException(error);

            throw error;
        }

        commit('SET_SAMPLE_DATA_PROGRESS', 2);

        // TASKS
        try {
            await saveToCoreAndFirebase('tasks', taskIds, createSampleTasks, cloneDeep(SAMPLE_TASKS).filter((task) => {
                if (hasDynamicContent && businessType === task.taskType) {
                    delete task.taskType;

                    return task;
                }

                if (!hasDynamicContent) {
                    const isBusiness = task.taskType === 'businessType.values.business';

                    delete task.taskType;

                    return isBusiness;
                }

                return null;
            }));
        } catch (error) {
            sentry.captureException(error);
        }

        commit('SET_SAMPLE_DATA_PROGRESS', 3);

        // PIPELINE
        if (editionName === 'Keap Pro Edition') {
            try {
                await createPipeline();

                await saveToCoreAndFirebase('deals', dealIds, createSampleDeals, cloneDeep(SAMPLE_DEALS).filter((deal) => {
                    if (hasDynamicContent && businessType === deal.dealType) {
                        delete deal.dealType;

                        return deal;
                    }

                    if (!hasDynamicContent) {
                        const isBusiness = deal.dealType === 'businessType.values.business';

                        delete deal.dealType;

                        return isBusiness;
                    }

                    return null;
                }));
            } catch (error) {
                sentry.captureException(error);
            }
        }

        commit('SET_SAMPLE_DATA_PROGRESS', 4);

        // PRODUCTS
        try {
            await saveToCoreAndFirebase('products', sampleProducts, createSampleProducts, cloneDeep(SAMPLE_PRODUCTS).filter((product) => {
                if (hasDynamicContent && businessType === product.productType) {
                    delete product.productType;

                    return product;
                }

                if (!hasDynamicContent) {
                    const isBusiness = product.productType === 'businessType.values.business';

                    delete product.productType;

                    return isBusiness;
                }

                return null;
            }));
        } catch (error) {
            sentry.captureException(error);
        }

        commit('SET_SAMPLE_DATA_PROGRESS', 5);

        // QUOTES
        try {
            await saveToCoreAndFirebase('quotes', quoteIds, createSampleQuotes, SAMPLE_QUOTES);
        } catch (error) {
            sentry.captureException(error);
        }

        commit('SET_SAMPLE_DATA_PROGRESS', 6);

        // INVOICES
        try {
            await saveToCoreAndFirebase('invoices', invoiceIds, createSampleInvoices, SAMPLE_INVOICES);
        } catch (error) {
            sentry.captureException(error);
        }

        commit('SET_SAMPLE_DATA_PROGRESS', 7);

        window.sampleDataInProgress = false;

        fullstory.event('Sample Data Added', {
            'Event Source': eventSource,
        });

        amplitude.v2.logEvent(amplitude.v2.events.SAMPLE_DATA_ADDED, {
            'Event Source': eventSource,
        });

        await dispatch('sampleData/SAVE_SAMPLE_DATA_SUCCESS', true, { root: true });
    } catch (error) {
        commit('INCREMENT_ADD_SAMPLE_DATA_ERROR_COUNT');

        await dispatch('sampleData/RETRY_ADD_SAMPLE_DATA', null, { root: true });
    }
};

const deleteSampleData = async ({ rootState, dispatch, commit }, eventSource) => {
    const appId = rootState.auth.session.coreAppId;
    const editionName = rootState?.auth?.account?.editionName;
    const snapshot = await firebase.database().ref(`sampleData/${appId}`).once('value');
    const {
        contacts,
        pipeline,
        deals,
        products,
    } = snapshot?.val() || {
        contacts: null,
        pipeline: null,
        deals: null,
        products: null,
    };

    // PIPELINE
    if (editionName === 'Keap Pro Edition' && pipeline && deals && deals.length) {
        try {
            await dispatch('pipeline/SAVE_PIPELINE_LIST', [{ id: pipeline.toString(), name: 'Sales Pipeline', order: '0' }], { root: true });

            deals.forEach((dealId) => {
                dispatch('pipeline/DELETE_DEAL', { id: dealId }, { root: true });
            });
        } catch (error) {
            throw error;
        }
    }

    // CONTACTS
    if (contacts && contacts.length) {
        try {
            await dispatch('contacts/DELETE_CONTACTS', { ids: [...contacts] }, { root: true });
        } catch (error) {
            sentry.captureException(error);
        }
    }

    // PRODUCTS
    if (products && products.length) {
        try {
            products.forEach((product) => {
                dispatch('billing/DELETE_PRODUCT', product.value, { root: true });
            });
        } catch (error) {
            sentry.captureException(error);
        }
    }

    fullstory.event('Sample Data Removed', {
        'Event Source': eventSource,
    });

    amplitude.v2.logEvent(amplitude.v2.events.SAMPLE_DATA_REMOVED, {
        'Event Source': eventSource,
    });

    await firebase.database().ref(`sampleData/${appId}`).remove();

    commit('SET_SAMPLE_DATA_STARTED', false);
    commit('SET_SAMPLE_DATA_SUCCESS', false);
};

const retryAddSampleData = async ({ dispatch }) => {
    await dispatch('sampleData/DELETE_SAMPLE_DATA', null, { root: true });
    await dispatch('sampleData/CREATE_SAMPLE_DATA', null, { root: true });
};

const deleteContactSampleData = async ({ rootState }, ids) => {
    const appId = rootState.auth.session.coreAppId;

    const snapshot = await firebase.database().ref(`sampleData/${appId}`).once('value');
    const contacts = snapshot && snapshot.val() && snapshot.val().contacts;

    if (!contacts) {
        return;
    }

    const newValue = contacts.filter((contactId) => {
        return !ids.includes(contactId);
    });

    await firebase.database().ref(`sampleData/${appId}/contacts`).set(newValue);
};

const deleteProductSampleData = async ({ rootState }, id) => {
    const appId = rootState.auth.session.coreAppId;

    const snapshot = await firebase.database().ref(`sampleData/${appId}`).once('value');
    const products = snapshot.val() && snapshot.val().products;

    if (!products) {
        return;
    }

    const newValue = products.filter((product) => {
        return product.value !== id;
    });

    await firebase.database().ref(`sampleData/${appId}/products`).set(newValue);
};

const deleteDealSampleData = async ({ rootState }, id) => {
    const appId = rootState.auth.session.coreAppId;

    const snapshot = await firebase.database().ref(`sampleData/${appId}`).once('value');
    const deals = snapshot.val() && snapshot.val().deals;

    if (!deals) {
        return;
    }

    const newValue = deals.filter((deal) => {
        return deal !== id;
    });

    await firebase.database().ref(`sampleData/${appId}/deals`).set(newValue);
};

const saveSampleDataStarted = async ({ commit, rootState }, value) => {
    const appId = rootState.auth.session.coreAppId;

    await firebase.database().ref(`sampleData/${appId}/sampleDataStarted`).set(value);

    commit('SET_SAMPLE_DATA_STARTED', value);
};

const saveSampleDataSuccess = async ({ commit, rootState }, value) => {
    const appId = rootState.auth.session.coreAppId;

    await firebase.database().ref(`sampleData/${appId}/sampleDataSuccess`).set(value);

    commit('SET_SAMPLE_DATA_SUCCESS', value);
};

const dismissSampleDataCta = async ({ commit, rootState }) => {
    const appId = rootState.auth.session.coreAppId;

    await firebase.database().ref(`sampleData/${appId}/ctaDismissed`).set(true);

    amplitude.v2.logEvent(amplitude.v2.events.SAMPLE_DATA_DISMISSED);

    commit('SET_SAMPLE_DATA_CTA_DISMISSED', true);
};

const loadSampleDataStatus = async ({ commit, rootState }) => {
    const appId = rootState.auth.session.coreAppId;

    await firebase.database().ref(`sampleData/${appId}`)
        .once('value')
        .then((snapshot) => {
            if (snapshot.val()) {
                const {
                    ctaDismissed,
                    sampleDataStarted,
                    sampleDataSuccess,
                } = snapshot.val();

                commit('SET_SAMPLE_DATA_CTA_DISMISSED', ctaDismissed);
                commit('SET_SAMPLE_DATA_STARTED', sampleDataStarted);
                commit('SET_SAMPLE_DATA_SUCCESS', sampleDataSuccess);
            }
        });
};
