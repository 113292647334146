export const ADD_A_CONTACT_ONBOARDING_TASK_STARTED = 'Add a contact onboarding task started';
export const ADD_A_TASK_ONBOARDING_TASK_STARTED = 'Add a task onboarding task started';
export const ADD_PRODUCTS_ONBOARDING_TASK_STARTED = 'Add products onboarding task started';
export const APPOINTMENT_BOOKED = 'Appointment Booked';
export const APPOINTMENT_TYPE_CREATED = 'Appointment Type Created';
export const AUTHORIZE_NET_CONNECTED = 'Autorize.Net Connected';
export const AUTOMATION_CREATED = 'Automation Created';
export const AUTOMATION_PUBLISHED = 'Automation Published';
export const BOOKING_LINK_SENT = 'Booking Link Sent';
export const BROADCAST_SENT = 'Broadcast Sent';
export const CALENDAR_CONNECTED = 'Calendar Connected';
export const CALL_PLACED = 'Call Placed';
export const CONNECT_PAYMENT_PROCESSOR_ONBOARDING_TASK_STARTED = 'Connect payment processor onboarding task started';
export const CONNECT_QBO_ONBOARDING_TASK_STARTED = 'Connect QBO onboarding task started';
export const CONNECT_YOUR_TOOLS_ONBOARDING_TASK_STARTED = 'Connect your tools onboarding task started';
export const CONTACT_ADDED = 'Contact Added';
export const CONTACT_ADDED_TO_CAMPAIGN = 'Contact Added to Campaign';
export const CONTACT_EDITED = 'Contact Edited';
export const CONTACT_FILE_UPLOADED = 'Contact File Uploaded';
export const CONTACT_IMPORT_COMPLETED = 'Contact Import Completed';
export const CONTACT_IMPORT_STARTED = 'Contact Import Started';
export const CONTACT_RECORD_VIEWED = 'Contact Record Viewed';
export const COMPANY_RECORD_VIEWED = 'Company Record Viewed';
export const CREATE_A_TAG_ONBOARDING_TASK_STARTED = 'Create a tag onboarding task stated';
export const CREATE_CONSULTATION_APPOINTMENT_TYPE_ONBOARDING_TASK_STARTED = 'Create consultation appointment type onboarding task started';
export const CREATE_FOLLOW_UP_APPOINTMENT_TYPE_ONBOARDING_TASK_STARTED = 'Create follow up appointment type onboarding task started';
export const CREATE_YOUR_PIPELINE_ONBOARDING_TASK_STARTED = 'Create your pipeline onboarding task started';
export const DOWNLOAD_MOBILE_APP_ONBOARDING_TASK_STARTED = 'Download mobile app onboarding task started';
export const EDIT_EMAIL_TEMPLATE_ONBOARDING_TASK_STARTED = 'Edit email template onboarding task started';
export const EMAIL_SENT = 'Email Sent';
export const EWAY_CONNECTED = 'eWAY Connected';
export const FREE_TRIAL_UPGRADED = 'Free Trial Upgraded';
export const GETTING_STARTED_JOB_COMPLETED = 'Getting Started Job Completed';
export const GETTING_STARTED_TASK_COMPLETED = 'Getting Started Task Completed';
export const GETTING_STARTED_TASK_DISMISSED = 'Getting Started Task Dismissed';
export const GETTING_STARTED_TASK_STARTED = 'Getting Started Task Started';
export const GMAIL_EMAIL_SYNC_CONNECTED = 'Gmail Email Sync Connected';
export const GOOGLE_MY_BUSINESS_LISTING_CONNECTED = 'Google My Business Listing Connected';
export const IMPORT_CONTACTS_ONBOARDING_TASK_STARTED = 'Import contacts onboarding task started';
export const INVOICE_CREATED = 'Invoice Created';
export const INVOICE_PAID = 'Invoice Paid';
export const INVOICE_SENT = 'Invoice Sent';
export const MANAGE_EMAIL_TEMPLATES_VIEWED = 'Manage Email Templates Viewed';
export const MICROSOFT_EMAIL_SYNC_CONNECTED = 'Microsoft Email Sync Connected';
export const NEW_ONBOARDING_JOB_COMPLETED = 'New Onboarding Job Completed';
export const NEXUS_CONNECTED = 'Nexus Connected';
export const NOTE_ADDED = 'Note Added';
export const NOTE_UNSAVED = 'Unsaved Changes';
export const NOTE_EDITED = 'Note Edited';
export const PAYMENT_PROCESSOR_CONNECTED = 'Payment Processor Connected';
export const PAYPAL_CONNECTED = 'PayPal Connected';
export const PIPELINE_CREATED = 'Pipeline Created';
export const PRODUCT_ADDED = 'Product Added';
export const PUBLIC_FORM_CREATED = 'Public Form Created';
export const QBO_CONNECTED = 'QBO Connected';
export const QUOTE_CREATED = 'Quote Created';
export const QUOTE_SENT = 'Quote Sent';
export const REQUEST_REFERRAL_EMAIL_SENT = 'Request Referral Email Sent';
export const REVIEW_MY_BUSINESS_STARTED = 'Review Your Business Profile task started';
export const REVIEW_YOUR_PROFILE_STARTED = 'Review Your Profile task started';
export const SEND_A_BROADCAST_ONBOARDING_TASK_STARTED = 'Send a broadcast onboarding task started';
export const SEND_REFERRAL_EMAIL_ONBOARDING_TASK_STARTED = 'Send referral email onboarding task started';
export const SEND_TEST_INVOICE_ONBOARDING_TASK_STARTED = 'Send test invoice onboarding task started';
export const SET_UP_DKIM_ONBOARDING_TASK_STARTED = 'Set up DKIM onboarding task started';
export const SET_UP_GOOGLE_REVIEWS_ONBOARDING_TASK_STARTED = 'Set up Google reviews onboarding task started';
export const SET_UP_LEAD_FORM_ONBOARDING_TASK_STARTED = 'Set up lead form onboarding task started';
export const STRIPE_CONNECTED = 'Stripe Connected';
export const TAG_APPLIED = 'Tag Applied';
export const TASK_COMPLETED = 'Task Completed';
export const TASK_CREATED = 'Task Created';
export const UPGRADE_PAGE_VIEWED = 'Upgrade Page Viewed';
export const WEPAY_CONNECTED = 'WePay Connected';
export const ZAPIER_INTEGRATION_SELECTED = 'Zapier Integration Selected';
