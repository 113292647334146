<template>
    <shopify-trigger
        :shopify-trigger-type="$options.type"
        :automation-properties="automationProperties"
        :save-status="saveStatus"
        @updated="(payload) => $emit('updated', payload)"
        @close="$emit('close')"
    />
</template>

<script>
import { SHOPIFY_ANY_CART_ABANDONED } from '@/automations/constants/automations.constants';
import ShopifyTrigger from './ShopifyTrigger';

export default {
    type: SHOPIFY_ANY_CART_ABANDONED,

    components: {
        ShopifyTrigger,
    },

    props: {
        automationProperties: {
            type: Object,
            default: () => ({}),
        },
        saveStatus: String,
    },
};
</script>
