import closeConfirmation from '@/shared/mixins/close-confirmation.mixin';

export default {
    mixins: [closeConfirmation],

    computed: {
        invoiceUtils_subtotal() {
            let total = this.invoice && this.invoice.total || 0;

            const hasInternalTaxAmount = this.invoice.orderItems?.filter(({ type }) => type === 'Tax');
            const hasPublicTaxAmount = this.invoice.totalTaxAmount > 0;

            if (hasInternalTaxAmount || hasPublicTaxAmount) {
                if (hasInternalTaxAmount?.length > 0) {
                    total -= hasInternalTaxAmount[0].price;
                }

                if (hasPublicTaxAmount > 0) {
                    total -= this.invoice.totalTaxAmount;
                }
            }

            if (this.invoice && this.invoice.discount && this.invoice.orderItems) {
                const orderItem = this.invoice.orderItems.find(({ id }) => id === this.invoice.discount.lineItemId);

                if (orderItem) {
                    total -= orderItem.price;
                }
            }

            return total;
        },
    },

    methods: {
        invoiceUtils_goToInvoice(invoice, routeName = 'invoices') {
            this.$router.push({ name: `${routeName}.invoice`, params: { invoiceId: invoice.id } });
        },

        async loadProductList(activateDropdown = true) {
            this.searchProducts_loading = true;
            await this.$store.dispatch('billing/LOAD_PRODUCTS', {
                limit: 100,
                sortBy: 'label',
                sortAscending: true,
            })
                .then(() => {
                    if (this.$refs.multiselect && activateDropdown) {
                        this.$refs.multiselect.activate();
                    }
                    this.searchProducts_loading = false;
                });
        },

        async invoiceUtils_openInvoiceModal(
            wrapWithProps,
            invoice,
            showRootClose = true,
            creating = false,
            eventSource,
            callback = () => this.$bus.$emit('POP_NESTED_MODAL'),
        ) {
            this.$store.commit('sales/SET_INVOICE', invoice);

            await Promise.all([
                    import(/* webpackChunkName: "invoiceLazy" */ '@/money/components/invoice/InvoiceModalContentOld'),
            ]).then(([{ default: InvoiceModalContentOld }]) => {
                this.$bus.$emit('PUSH_NESTED_MODAL', {
                    component: InvoiceModalContentOld,
                    headerComponent: wrapWithProps,
                    modalSize: 'fullscreen',
                    showRootClose,
                    props: { eventSource },
                    data: { creating },
                    interceptClose: () => this.confirmClose(callback),
                });
            });
        },

        invoiceUtils_openNewInvoice(wrapWithProps, blankInvoice = { contact: {}, orderItems: [] }, showRootClose = true, eventSource = '') {
            this.invoiceUtils_openInvoiceModal(wrapWithProps, blankInvoice, showRootClose, true, eventSource);
        },
    },
};
