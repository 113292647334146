<template lang="html">
    <div class="mobile-nav-menu" :class="{ 'open': mobileNavOpen }">
        <div class="overlay" @click="close" />

        <nav>
            <header>
                <ds-icon-button name="x" @click="close" />

                <ds-logo />
            </header>

            <div class="links-wrapper">
                <main-nav-links />

                <hr />

                <nav-account-links />
            </div>
        </nav>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import MainNavLinks from '@/nav/components/MainNavLinks';
import NavAccountLinks from '@/nav/components/NavAccountLinks';

export default {
    components: {
        MainNavLinks,
        NavAccountLinks,
    },

    computed: {
        ...mapState({
            mobileNavOpen: ({ global }) => global.mobileNavOpen,
        }),
    },

    methods: {
        close() {
            this.$store.commit('SET_MOBILE_NAV_OPEN', false);
        },
    },
};
</script>

<style lang="scss" scoped>
    .mobile-nav-menu {
        @media ($medium) {
            display: none;
        }
    }

    nav {
        --logo-width: #{px-to-rem(60px)};
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        max-width: px-to-rem(320px);
        transform: translateX(-100%);
        z-index: 7;
        background-color: $color-paper;
        @include overflowY;
        @include transition(transform);

        .open & {
            transform: translateX(0);
            box-shadow: $elevation-z6;
        }
    }

    header {
        min-height: $nav-height;
        display: flex;
        align-items: center;
        padding: 0 $gp * 0.5;

        button {
            @include margin-end($gp);
        }
    }

    hr {
        margin: $gp / 2 0;
        border-color: $color-gray-200;
    }

    .logo {
        position: relative;
        top: px-to-rem(2px);
    }

    .links-wrapper {
        padding: $gp / 2 0;
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 7;
        background-color: rgba($color-ink, 0.5);
        visibility: hidden;
        opacity: 0;
        @include transition(opacity, visibility);

        .open & {
            visibility: visible;
            opacity: 1;
            cursor: pointer;
        }
    }
</style>
