<template>
    <ds-card>
        <ds-card-header>
            <ul class="week">
                <li
                    v-for="(day, key) in days"
                    :key="`day-${key}`"
                    :class="['day', { 'active': isDayActive(key), 'today': isToday(key) }]"
                    @click="onDayClick(key)"
                >
                    {{ getDayShortName(key) }}
                    <span v-if="dayHasFrames(key)">●</span>
                </li>
            </ul>
        </ds-card-header>

        <ds-card-body>
            <div
                v-for="(interval, day) in framesActiveDay"
                :key="day"
                class="time-frame"
            >
                <time-input
                    v-model="interval.start"
                    name="startTime"
                    :placeholder="defaultStartTime"
                    required
                    submitted
                    :default-time="defaultStartTime"
                    :label="$t('global.startTime')"
                    :invalid="Boolean(getValidationMessage(day))"
                    :data-qa="`${day}-start-time`"
                />

                <span>-</span>

                <time-input
                    v-model="interval.end"
                    name="endTime"
                    :placeholder="defaultEndTime"
                    required
                    submitted
                    :default-time="defaultEndTime"
                    :label="$t('global.endTime')"
                    :invalid="Boolean(getValidationMessage(day))"
                    :data-qa="`${day}-end-time`"
                />

                <ds-icon-button
                    name="trash"
                    @click="removeFrame(day)"
                />

                <div v-if="getValidationMessage(day)" class="validation-error">
                    {{ getValidationMessage(day) }}
                </div>
            </div>

            <ds-filled-button
                v-if="emptyDay"
                class="block unavailable"
                disabled
            >
                {{ $t('global.unavailable') }}
            </ds-filled-button>

            <ds-text-button
                v-if="canAddTimeFrame"
                leading-icon="add"
                @click="addFrame"
            >
                {{ $t('addTimeFrame') }}
            </ds-text-button>
        </ds-card-body>

        <ds-card-footer>
            <span class="timezone-text">
                {{ timeZone }}
            </span>
        </ds-card-footer>
    </ds-card>
</template>

<script>
import moment from 'moment-timezone';

import { mapState, mapGetters } from 'vuex';
import { browserTimezone } from '@/shared/utils/date.util';
import TimeInput from '@/shared/components/TimeInput';
import { TIME_FORMAT } from '@/shared/constants/dateFormats.constants';
import { FF_KEAP_APPOINTMENTS_TIMEZONE } from '@/shared/constants/featureFlag.constants';

const APPT_DEFAULT_DAYS = [1, 2, 3, 4, 5]; // M-F
const APPT_DEFAULT_TIME_FRAME = {
    start: moment().hours(9).minutes(0).format(TIME_FORMAT),
    end: moment().hours(17).minutes(0).format(TIME_FORMAT),
};

export default {
    components: {
        TimeInput,
    },

    props: {
        value: {
            type: Array,
            required: true,
            default: () => {
                return [];
            },
        },

        appointmentDuration: Number,

        onDaySelect: {
            type: Function,
            default() {},
        },

        onAddTimeFrame: {
            type: Function,
            default() {},
        },

        onRemoveTimeFrame: {
            type: Function,
            default() {},
        },
    },

    data() {
        return {
            browserTimezone,
            activeDay: '1',
            defaultStartTime: '9:00 am',
            defaultEndTime: '5:00 pm',
            days: {
                0: [],
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
                6: [],
            },
            timeLabelFormat: TIME_FORMAT,
            dataLoaded: false,
        };
    },

    async created() {
        await this.parseAvailableTimes();

        if (!this.value.length) {
            APPT_DEFAULT_DAYS.forEach((d) => this.days[d].push({ ...APPT_DEFAULT_TIME_FRAME }));
        }

        this.dataLoaded = true;
    },

    watch: {
        days: {
            handler () {
                if (this.dataLoaded) {
                    this.$emit('input', this.getAvailabilityItems());
                }
            },
            deep: true,
        },
    },

    computed: {
        ...mapState({
            timeZoneSelectorEnabled: ({ featureFlags }) => featureFlags[FF_KEAP_APPOINTMENTS_TIMEZONE],
        }),

        ...mapGetters({
            connectedProviders: 'calendar/connectedProviders',
        }),

        emptyDay() {
            return this.framesActiveDay.length === 0;
        },

        timeZone() {
            const [provider] = this.connectedProviders;

            return this.timeZoneSelectorEnabled && provider?.timeZone
                ? provider.timeZone
                : this.browserTimezone;
        },

        framesActiveDay() {
            return this.days[this.activeDay];
        },

        daysWithIntervals() {
            return Object.keys(this.days).filter((d) => {
                return this.days[d].length;
            });
        },

        canAddTimeFrame() {
            const day = this.framesActiveDay;

            if (!day.length) {
                return true;
            }

            const lastFrame = day[day.length - 1];
            const start = lastFrame.end;
            const dateTime = moment(start, this.timeLabelFormat);

            const nextDateTime = moment(dateTime).add(1, 'hour');

            return nextDateTime.isSame(dateTime, 'day');
        },
    },

    methods: {
        isToday(day) {
            const today = new Date().getDay();

            return Number(day) === today;
        },

        dayHasFrames(index) {
            return Boolean(this.days[index].length);
        },

        isDayActive(index) {
            return this.activeDay === index;
        },

        onDayClick(index) {
            this.onDaySelect(this.getDayShortName(index));

            this.activeDay = index.toString();
        },

        removeFrame(index) {
            this.onRemoveTimeFrame();

            this.framesActiveDay.splice(index, 1);
        },

        addFrame() {
            if (!this.canAddTimeFrame) {
                return;
            }

            this.onAddTimeFrame();

            const day = this.framesActiveDay;

            // if day already has time-frames
            if (day.length) {
                const lastFrame = day[day.length - 1];
                const start = lastFrame.end;
                const newEnd = moment(start, this.timeLabelFormat).add(1, 'hour').format(this.timeLabelFormat);

                // add new time frame with start time same as previous end time
                day.push({
                    start,
                    end: newEnd,
                });
            } else {
                day.push({ ...APPT_DEFAULT_TIME_FRAME });
            }
        },

        getRawAvailabilityItems () {
            const rawItems = [];

            // loop over all days that have intervals
            this.daysWithIntervals.forEach((dayIndex) => {
                // loop over all intervals in a day
                this.days[dayIndex].forEach((interval) => {
                    // check if interval is already in rawItems
                    const intervalIndex = rawItems.findIndex((t) => {
                        return t.start === interval.start && t.end === interval.end;
                    });

                    if (intervalIndex !== -1) {
                        // if interval exists in rawItems, add day to rDays
                        rawItems[intervalIndex].rDays.push(dayIndex);
                    } else {
                        rawItems.push({
                            ...interval,
                            rDays: [dayIndex],
                        });
                    }
                });
            });

            return rawItems;
        },

        getValidationMessage(day) {
            const { start, end } = this.framesActiveDay[day];

            const startTime = moment(start, TIME_FORMAT);
            const endTime = moment(end, TIME_FORMAT);

            if (endTime.isBefore(startTime)) {
                return this.$t('errors.endBeforeStart');
            }

            const rangeInMinutes = -startTime.diff(endTime, 'minutes');
            const appointmentDuration = this.appointmentDuration > 0
                ? this.appointmentDuration
                : 15;

            if (rangeInMinutes < appointmentDuration) {
                return this.$t('errors.short', { duration: appointmentDuration });
            }

            const previousIntervalEndTime = day > 0
                ? moment(this.framesActiveDay[day - 1].end, TIME_FORMAT)
                : null;

            return previousIntervalEndTime && startTime.isBefore(previousIntervalEndTime)
                ? this.$t('errors.overlap')
                : null;
        },

        getAvailabilityItems() {
            const availability = [];

            this.getRawAvailabilityItems().forEach((item) => {
                item.rDays.forEach((day) => {
                    availability.push({
                        start: moment(item.start, this.timeLabelFormat).day(day).format(),
                        end: moment(item.end, this.timeLabelFormat).day(day).format(),
                        recurrence: 'FREQ=WEEKLY;INTERVAL=1',
                    });
                });
            });

            return availability;
        },

        getDayShortName(index) {
            return moment().day(index).format('ddd');
        },

        parseAvailableTimes() {
            this.value.forEach((availableTime) => {
                const day = moment(availableTime.start).day();
                const start = moment(availableTime.start).format(TIME_FORMAT);
                const end = moment(availableTime.end).format(TIME_FORMAT);

                this.days[day].push({ start, end });
            });
        },
    },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
    @import '~@/appointments/styles/appointments';

    .week {
        @include weekGrid;
        background-color: $color-gray-100;
        padding: $gp $gp 0;
    }

    .day {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        font-weight: $font-weight-bold;
        color: $color-blue;
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: $gp / 2;
        cursor: pointer;

        &.active {
            background-color: $color-paper;
        }

        &:hover:not(.active) {
            background-color: $color-blue-050;
        }
    }

    .time-frame {
        display: grid;
        align-items: flex-start;
        grid-template-columns: px-to-rem(150px) px-to-rem(16px) px-to-rem(150px) px-to-rem(40px);
        grid-gap: $gp / 2;
        margin: $gp 0;
        --input-margin-bottom: 0;

        > span {
            text-align: center;
            align-self: center;
        }
    }

    .validation-error {
        color: $color-red;
        grid-column: span 4;
    }

    .unavailable {
        margin-bottom: $gp;
    }

    .timezone-text {
        font-weight: $font-weight-semibold;
        text-align: center;
        display: block;
    }
</style>

<i18n>
{
    "en-us": {
        "addTimeFrame": "Add timeframe",
        "errors": {
            "endBeforeStart": "Your end time cannot be before your start time",
            "blank": "Timeframes cannot be blank",
            "overlap": "Your timeframes are overlapping",
            "short": "Your timeframes must be at least {duration} minutes"
        }
    }
}
</i18n>
